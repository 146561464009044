import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useDeepCompareEffect from 'use-deep-compare-effect';

import Box from '@mui/material/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import RecommendationsList from '../RecommendationsList/RecommendationsList';

import {
  IMPACT_RESULTS,
  TAGS,
  GROUP_BY_OPTIONS_KEYS,
} from '../../../constants/recommendations';

import styles from './RecommendationsAccordion.module.scss';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../assets/icons/DynamicCaret';

export default function RecommendationsAccordion({
  className,
  impactResultsFilters = [],
  tagFilters = [],
  lifecycleFilters = [],
  groupBy,
  data,
  // recommendations,
  ...props
}) {
  const [groups, setGroups] = useState([]);
  const [openGroups, setOpenGroups] = useState({});

  useDeepCompareEffect(() => {
    const result = [];

    if (groupBy === GROUP_BY_OPTIONS_KEYS.PRACTICE_AREA) {
      TAGS.forEach((tag) => {
        const group = {
          label: tag.label,
          key: tag.label,
          impactResultsFilters,
          tagFilters: [tag.label],
          lifecycleFilters,
        };

        result.push(group);
      });
    }

    if (groupBy === GROUP_BY_OPTIONS_KEYS.IMPACT_RESULT) {
      IMPACT_RESULTS.forEach((impactResults) => {
        result.push({
          ...impactResults,
          impactResultsFilters: [impactResults.key],
          tagFilters,
          lifecycleFilters,
        });
      });
    }

    setGroups(result);
  }, [groupBy, setGroups, lifecycleFilters, impactResultsFilters]);

  useEffect(() => {
    setOpenGroups({});
  }, [groupBy]);

  function handleTitleClick(key) {
    const result = { ...openGroups };

    result[key] = !result[key];

    setOpenGroups(result);
  }

  return (
    <Box
      className={classNames(styles.RecommendationsAccordion, className)}
      {...props}
    >
      {groups.map((group) => (
        <Accordion
          key={group.key}
          className={classNames(
            styles.Accordion,
            openGroups[group.key] ? styles.Expanded : null
          )}
          expanded={openGroups[group.key]}
        >
          <AccordionSummary className={styles.Summary}>
            <Box
              className={styles.AccordionTitle}
              onClick={() => handleTitleClick(group.key)}
            >
              <Box mr={2} className={styles.IconContainer}>
                <DynamicCaretIcon
                  direction={
                    openGroups[group.key]
                      ? CaretDirection.right
                      : CaretDirection.down
                  }
                  className={styles.Icon}
                />
              </Box>
              <h1>{group.label}</h1>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {
              /* eslint-disable-next-line */
              data.competitiveSet.session.recommendations ? (
                <Box className={styles.AccordionContent}>
                  <RecommendationsList
                    impactResultsFilters={group.impactResultsFilters}
                    tagFilters={group.tagFilters}
                    lifecycleFilters={group.lifecycleFilters}
                    recommendations={
                      data.competitiveSet.session.recommendations
                    }
                  />
                </Box>
              ) : null
            }
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

RecommendationsAccordion.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
  /**
   * Impact Result key from constants/recommendations.js
   */
  impactResultsFilters: PropTypes.arrayOf(PropTypes.string),
  /**
   * Tag id from constants/recommendations.js
   */
  tagFilters: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /**
   * Lifecycle keys from constants/recommendations.js
   */
  lifecycleFilters: PropTypes.arrayOf(PropTypes.string),
  groupBy: PropTypes.string,
};
