import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@blueoceanai/react-component-library';
import IconFullScreen from '../../../assets/icons/IconFullScreen.jsx';

import styles from './FullScreenButton.module.scss';

function FullScreenButton({ className, ...props }) {
  return (
    <Button
      className={classNames(className, styles.FullScreenButton)}
      {...props}
    >
      <IconFullScreen className={styles.Icon} />
    </Button>
  );
}

export default FullScreenButton;

FullScreenButton.propTypes = {
  className: PropTypes.string,
};
