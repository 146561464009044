/* eslint-disable import/prefer-default-export */
export enum FeatureFlag {
  BNAccess = 'brand-navigator-app',
  CreativeAccess = 'creative-intelligence-standalone-app',
  ViewMetricsSocialMetrics = 'web-bn.page-market-index-subfactors.section-drivers.social-metrics.can-view',
  ViewDashboards = 'web-bn.page-dashboards.can-view',
  ViewDashboardWidgetContextualHelp = 'web-bn.page-dashboard.section-widget-container.contextual-help.can-view',
  ViewMetricAnalysisV1 = 'web-bn.page-dashboard.section-widget.metricanalysis.can-view',
  ViewMetricCollectionV1 = 'web-bn.page-dashboard.section-widget.metriccollection.can-view',
  ViewExcessShareOfVoiceV1 = 'web-bn.page-dashboard.section-widget.excessshareofvoice.can-view',
  ViewPaceAnalysisV1 = 'web-bn.page-dashboard.section-widget.paceanalysis.can-view',
  ViewSummaryTrendsV1 = 'web-bn.page-dashboard.section-widget.summarytrends.can-view',
  ViewMetricComparisonV1 = 'web-bn.page-dashboard.section-widget.metriccomparison.can-view',
  ViewStackRankingV1 = 'web-bn.page-dashboard.section-widget.stackranking.can-view',
  ViewVisualMapsV1 = 'web-bn.page-dashboard.section-widget.visualmaps.can-view',
  ViewWidgetSettingsGrouping = 'web-bn.page-dashboard-section-widget-settings-metric-selection.grouping.can-view',
  ViewWidgetCustomDateSelector = 'web-bn.page-dashboard.section-widget.custom-date-selector.can-view',
  CreateWidgetTemplates = 'web-bn.page-dashboard.section-dashboard-templates.can-create',
  // Dashboard template can-view
  SocialPerformanceTemplate = 'web-bn.page-dashboard.section-dashboard-template-social-perf.can-view',
  CMOPulseTemplate = 'web-bn.page-dashboard.section-dashboard-template-cmo-pulse.can-view',
  DiagnosticReportTemplate = 'web-bn.page-dashboard.section-dashboard-template-diagnostic-report.can-view',
  ShareOfVoiceTemplate = 'web-bn.page-dashboard.section-dashboard-template-share-of-voice.can-view',
  PoseidonDashboardAccess = 'web-bn.page-dashboard.section-widget-poseidon.can-view',
}
