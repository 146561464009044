import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { Button } from '@blueoceanai/react-component-library';

import { BUTTON_VARIANTS } from '../../../constants/props';

import styles from './ModalBase.module.scss';
import { CloseIcon } from '../../../assets/icons';

export default function ModalBase({
  className,
  onClose,
  title,
  children,
  onPrimaryClick,
  onSecondaryClick,
  primaryText = 'Ok',
  secondaryText = 'Cancel',
  closeX = true,
  ...props
}) {
  return (
    <Dialog
      onClose={onClose}
      className={classNames(styles.ModalBase, className)}
      classes={{
        paper: styles.ModalPaper,
      }}
      {...props}
    >
      <div className={styles.Header} onClick={onClose}>
        <h1 className={styles.Title}>{title}</h1>
        {closeX ? (
          <CloseIcon className={styles.Icon} onClick={onClose} />
        ) : null}
      </div>

      <DialogContent className={styles.Body}>{children}</DialogContent>

      <DialogActions className={styles.Footer}>
        {onSecondaryClick ? (
          <Button
            variant={BUTTON_VARIANTS.SECONDARY}
            onClick={onSecondaryClick}
          >
            {secondaryText}
          </Button>
        ) : null}

        {onPrimaryClick ? (
          <Button
            data-cy="modal-primary-button"
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={onPrimaryClick}
          >
            {primaryText}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

ModalBase.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onPrimaryClick: PropTypes.func,
  onSecondaryClick: PropTypes.func,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  closeX: PropTypes.bool,
};
