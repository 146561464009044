import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { Carousel } from 'react-responsive-carousel';
import { useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import isEmpty from 'lodash/isEmpty';
import { BrandLogo } from '@blueoceanai/react-component-library';

import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import BetaTag from '../../Atoms/BetaTag/BetaTag';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import Dropdown from '../../Molecules/Dropdown/Dropdown';
import ThemesCard from '../../Organisms/ThemesCard/ThemesCard';
import ThemesBrandChart from '../../Organisms/ThemesBrandChart/ThemesBrandChart';
import ThemesAudienceChart from '../../Organisms/ThemesAudienceChart/ThemesAudienceChart';
import InsightsCTA from '../../Molecules/InsightsCTA/InsightsCTA';
import VerbatimBrand from '../../Molecules/VerbatimBrand/VerbatimBrand';
import VerbatimCustomer from '../../Molecules/VerbatimCustomer/VerbatimCustomer';
import RadioSelector from '../../Molecules/RadioSelector/RadioSelector';

import { DROPDOWN_VARIANTS, SIZES } from '../../../constants/props';

import useRouter from '../../../hooks/useRouter';
import BNContext from '../../../contexts/BNContext';
import {
  GET_INSIGHTS,
  GET_THEME_VERBATIMS,
} from '../../../api/queries/Pages/ContentAnalysisThemesOverview';
import {
  GET_THEME_OPTIONS,
  GET_THEMES,
} from '../../../api/queries/Pages/ContentAnalysisThemes';
import {
  prepareCompetitorThemes,
  prepareHeroThemes,
  prepareInsight,
  prepareOverviewTabThemes,
} from '../../../api/transforms/Pages/ContentAnalysisThemes';

import styles from './ContentAnalysisThemesOverview.module.scss';
import ThemesLegend from '../../Molecules/ThemesLegend/ThemesLegend';

export default function ContentAnalysisThemesOverview() {
  const { updateRoute } = useRouter();
  const location = useLocation();

  const {
    competitiveSetID,
    currentSession: sessionKey,
    accountKey,
    timeframe,
  } = useContext(BNContext);

  const queryOptions = {
    variables: {
      id: competitiveSetID,
      sessionKey,
      accountKey,
      type: 4,
    },
  };

  const anchorRef = useRef();

  const [currentAppBrand, setCurrentAppBrand] = useState('');
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [comparedBrand, setComparedBrand] = useState(null);
  const [themeInsight, setThemeInsight] = useState([]);

  const radioItems = [
    { name: 'Overview' },
    { name: 'Brand Content' },
    { name: 'Audience Content' },
  ];

  const getInsightsQueryOptions = useMemo(() => {
    return {
      variables: {
        id: competitiveSetID,
        sessionKey,
        themeName: selectedTheme?.label,
      },
      fetchPolicy: 'no-cache',
    };
  }, [competitiveSetID, sessionKey, selectedTheme?.label]);

  const [filterItem, setFilterItem] = useState(radioItems[0]);
  const [themesOptions, setThemesOptions] = useState(null);
  const [brandDropdown, setBrandDropdown] = useState(null);
  const [currentBrandVerbatims, setCurrentBrandVerbatims] = useState(null);
  const [comparedBrandVerbatims, setComparedBrandVerbatims] = useState(null);
  const [themesOverview, setThemesOverview] = useState(null);
  const [heroThemes, setHeroThemes] = useState(null);
  const [competitorThemes, setCompetitorThemes] = useState(null);

  const [getThemes, { data, loading }] = useLazyQuery(GET_THEMES, queryOptions);

  const [
    getInsights,
    { loading: insightsLoading, error: insightsError, data: insightsData },
  ] = useLazyQuery(GET_INSIGHTS, getInsightsQueryOptions);

  const [getThemeOptions, { data: themeOptionData }] = useLazyQuery(
    GET_THEME_OPTIONS,
    {
      variables: {
        id: competitiveSetID,
        sessionKey,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [
    getThemeVerbatims,
    {
      loading: themeVerbatimsLoading,
      error: themeVerbatimsError,
      data: themeVerbatimsData,
    },
  ] = useLazyQuery(GET_THEME_VERBATIMS, {
    variables: {
      id: competitiveSetID,
      sessionKey,
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!selectedTheme && location?.state?.selectedTheme) {
      setSelectedTheme(location.state.selectedTheme);
    }
  }, [location, selectedTheme]);

  useEffect(() => {
    if (!competitiveSetID || !sessionKey || loading) {
      return;
    }

    getThemes();
    getThemeOptions();
  }, [getThemes, getThemeOptions, competitiveSetID, sessionKey, loading]);

  useEffect(() => {
    if (!competitiveSetID || !sessionKey) {
      return;
    }

    if (
      // eslint-disable-next-line no-undefined
      location.state !== undefined &&
      currentAppBrand !== '' &&
      currentAppBrand !== competitiveSetID
    ) {
      updateRoute({ pathname: '/content-analysis/themes' });
    }

    setCurrentAppBrand(competitiveSetID);

    if (!isEmpty(selectedTheme?.label) && !insightsData && !insightsLoading) {
      getInsights();
    }
  }, [
    getInsights,
    currentAppBrand,
    selectedTheme,
    competitiveSetID,
    sessionKey,
    updateRoute,
    location,
    insightsData,
    insightsLoading,
  ]);

  /**
   * Prepare themes dropdown list options.
   */
  useEffect(() => {
    if (!themeOptionData?.competitiveSet?.session?.themes) {
      return;
    }

    setThemesOptions([
      ...themeOptionData.competitiveSet.session.themes
        .filter((theme) => theme.themeType === 'rational')
        // potentially use nameSort here
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((t) => ({
          label: t.name.trim(),
          value: t.name.trim().replace(' ', '-').toLowerCase(),
        })),
      ...themeOptionData.competitiveSet.session.themes
        .filter((theme) => theme.themeType === 'emotional')
        // potentially use nameSort here
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((t) => ({
          label: t.name.trim(),
          value: t.name.trim().replace(' ', '-').toLowerCase(),
        })),
    ]);
  }, [themeOptionData]);

  useEffect(() => {
    setHeroThemes(prepareHeroThemes(data, timeframe));
    setCompetitorThemes(prepareCompetitorThemes(data, timeframe));
  }, [data, timeframe]);

  /**
   * Select first option whenever themes dropdown list options changes.
   */
  useDeepCompareEffectNoCheck(() => {
    if (selectedTheme || !themesOptions?.[0]) {
      return;
    }

    setSelectedTheme(themesOptions[0]);
  }, [themesOptions]);

  /**
   * Prepare Overview tab data for display.
   */
  useEffect(() => {
    if (!selectedTheme || !heroThemes?.themes || !competitorThemes) {
      return;
    }

    const result = prepareOverviewTabThemes({
      selectedThemeName: selectedTheme.label,
      heroThemes,
      competitorThemes,
    });

    setThemesOverview(result);
  }, [selectedTheme, heroThemes, competitorThemes]);

  useEffect(() => {
    if (!insightsData) {
      return;
    }

    if (insightsData?.competitiveSet?.session?.theme?.insight?.value) {
      setThemeInsight(
        prepareInsight(insightsData.competitiveSet.session.theme.insight.value)
      );
    } else {
      setThemeInsight(['No Insight Available']);
    }
  }, [insightsData]);

  useEffect(() => {
    getThemeVerbatims();
  }, [getThemeVerbatims]);

  useEffect(() => {
    if (competitorThemes !== null) {
      const competitorBrandOptions = competitorThemes.map((c) => ({
        label: c.name,
        value: c.id,
        imgUrl: c.logoUrl,
      }));

      setBrandDropdown(competitorBrandOptions);
      setComparedBrand(competitorBrandOptions[0].value);
    }
  }, [competitorThemes]);

  useEffect(() => {
    if (themeVerbatimsData && !themeVerbatimsLoading) {
      const competitorThemesVerbatim =
        themeVerbatimsData.competitiveSet.session.competitors.filter(
          (brand) => brand.id === comparedBrand
        )[0];

      const competitorThemesVerbatims =
        competitorThemesVerbatim !== undefined
          ? competitorThemesVerbatim.themes.filter(
              (verbatim) => verbatim.name === selectedTheme?.label
            )
          : null;

      setComparedBrandVerbatims(competitorThemesVerbatims);
    }
  }, [themeVerbatimsData, themeVerbatimsLoading, comparedBrand, selectedTheme]);

  useEffect(() => {
    if (themeVerbatimsData?.competitiveSet?.session?.themes) {
      setCurrentBrandVerbatims(
        themeVerbatimsData.competitiveSet.session.themes.filter(
          (theme) => theme.name === selectedTheme?.label
        )
      );
    }
  }, [themeVerbatimsData, selectedTheme]);

  function handleFilterChange(item) {
    if (!brandDropdown?.[0]?.value) {
      // eslint-disable-next-line no-console
      console.error(
        'No brands in brand dropdown when brand content / audience content filter is changed.'
      );
      return;
    }

    setFilterItem(item);
  }

  function dropdownChangeSelectedBrand(brand) {
    setComparedBrand(brand.value);
  }

  function compareBrands(id) {
    setComparedBrand(id);

    setTimeout(() => {
      anchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  }

  if (insightsError || themeVerbatimsError) {
    return <GenericErrorCopy />;
  }

  return (
    <Grid className={`RouteContainer ${styles.ContentAnalysisThemesOverview}`}>
      <Grid container spacing={10} item xs={12}>
        <Grid item xs={12} md={9}>
          <div id="content-analysis-theme-detail-download-container">
            <Grid
              className={`TitleContainer ${styles.TitleContainer}`}
              container
              spacing={0}
            >
              <Grid item xs={12}>
                <div className={styles.TitleContainerTop}>
                  <h5 className={styles.ChangeThemeTitle}>
                    <div
                      className={styles.DropdownBreadcrumb}
                      onClick={() =>
                        updateRoute({ pathname: '/content-analysis/themes' })
                      }
                    >
                      Themes:{' '}
                    </div>

                    <span>
                      {themesOptions !== null ? (
                        <Dropdown
                          variant={DROPDOWN_VARIANTS.NAKED}
                          showArrow
                          options={themesOptions}
                          value={selectedTheme?.value}
                          onChange={setSelectedTheme}
                        />
                      ) : null}
                    </span>
                  </h5>

                  <DownloadDropdown
                    targetId="content-analysis-theme-detail-download-container"
                    padding={100}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container className={`${styles.RadioContainer}`}>
              <RadioSelector
                items={radioItems}
                defaultSelectedIndex={0}
                onChange={handleFilterChange}
              />
            </Grid>

            {filterItem.name === 'Overview' ? (
              <>
                <Box className={styles.Overview} pl={0} pr={0}>
                  <div className={`${styles.ChartContainer}`}>
                    <div className={`${styles.ChartHeader} ${styles.ChartRow}`}>
                      <div
                        className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                      >
                        <h5 className={`${styles.HeaderLabel}`}>Brand</h5>
                        <p className={`${styles.HeaderText}`}>
                          How does your brand compare with the competition?
                        </p>
                      </div>
                      <div
                        className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                      >
                        <h5 className={`${styles.HeaderLabel}`}>
                          Brand Volume
                        </h5>
                        <p className={`${styles.HeaderText}`}>
                          How much is brand talking about these themes in their
                          content?
                        </p>
                      </div>
                      <div
                        className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                      >
                        <h5 className={`${styles.HeaderLabel}`}>
                          Audience Volume
                        </h5>
                        <p className={`${styles.HeaderText}`}>
                          How much is the audience talking about these themes in
                          their conversations?
                        </p>
                      </div>
                      <div
                        className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                      >
                        <h5 className={`${styles.HeaderLabel}`}>
                          Audience Sentiment
                        </h5>
                        <p className={`${styles.HeaderText}`}>
                          How does the audience feel about the brand in regards
                          to the specific theme?
                        </p>
                      </div>
                    </div>
                    <div className={`${styles.ChartBody}`}>
                      {!themesOverview ? (
                        <ComponentLoader minHeight={400} />
                      ) : null}

                      {themesOverview
                        ? themesOverview.map((brand) => (
                            <ThemesCard
                              variant="themes-overview"
                              brandLogo={brand.logoUrl}
                              cardTitle={brand.name}
                              data={brand.scores}
                              key={brand.id}
                            />
                          ))
                        : null}
                    </div>
                  </div>
                </Box>
                <Box mt={4} mb={4}>
                  <ThemesLegend />
                </Box>
              </>
            ) : null}

            {filterItem.name === 'Brand Content' ? (
              <Box className={styles.BrandContent} pl={0} pr={0}>
                <Grid
                  className={`TitleContainer ${styles.TitleContainer}`}
                  container
                  spacing={0}
                >
                  <Grid item xs={12}>
                    <h1 className={styles.Title}>
                      How much do brands talk about {selectedTheme.label}?
                    </h1>
                  </Grid>
                </Grid>
                <div className={`${styles.ChartContainer}`}>
                  <div className={`${styles.ChartHeader} ${styles.ChartRow}`}>
                    <div
                      className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                    />
                    <div
                      className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                    >
                      <h5 className={`${styles.HeaderLabel}`}>Brand Volume</h5>
                      <p className={`${styles.HeaderText}`}>
                        How much is brand talking about these themes in their
                        content?
                      </p>
                    </div>
                    <div
                      className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                    />
                  </div>
                  <div className={`${styles.ChartBody}`}>
                    {!themesOverview ? (
                      <ComponentLoader minHeight={400} />
                    ) : null}

                    {themesOverview !== null
                      ? themesOverview.map((brand) => (
                          <ThemesBrandChart
                            key={brand.id}
                            cardTitle={brand.name}
                            brandLogo={brand.logoUrl}
                            data={brand.scores[0]}
                            chartTimelineData={brand.scores[0].scores}
                            gaugeData={{
                              value: brand.scores[0].value,
                              type: brand.scores[0].type,
                              competitorAverage:
                                brand.scores[0].competitorAverage,
                            }}
                            competitiveSetID={brand.id}
                            compareBrands={compareBrands}
                          />
                        ))
                      : null}
                  </div>
                </div>
              </Box>
            ) : null}

            {filterItem.name === 'Audience Content' ? (
              <Box className="" pl={0} pr={0}>
                <Grid
                  className={`TitleContainer ${styles.TitleContainer}`}
                  container
                  spacing={0}
                >
                  <Grid item xs={12}>
                    <h1 className={styles.Title}>
                      Are your customers satisfied with their interactions with
                      your brand?
                    </h1>
                  </Grid>
                </Grid>
                <div className={`${styles.ChartContainer}`}>
                  <Grid
                    className={`${styles.ChartHeader} ${styles.ChartRow}`}
                    container
                    wrap="nowrap"
                  >
                    <Grid
                      item
                      xs={2}
                      md={3}
                      lg={4}
                      className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                    />
                    <Grid
                      item
                      xs={5}
                      md
                      lg={4}
                      className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                    >
                      <h5 className={`${styles.HeaderLabel}`}>
                        Audience Volume
                      </h5>
                      <Hidden smDown>
                        <p className={`${styles.HeaderText}`}>
                          How much is the audience talking about these themes in
                          their conversations over time?
                        </p>
                      </Hidden>
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      md
                      lg={4}
                      className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                    >
                      <h5 className={`${styles.HeaderLabel}`}>
                        Audience Sentiment
                      </h5>
                      <Hidden smDown>
                        <p className={`${styles.HeaderText}`}>
                          How does the audience feel about the brand in regards
                          to the specific theme over time?
                        </p>
                      </Hidden>
                    </Grid>
                  </Grid>
                  <div className={`${styles.ChartBody}`}>
                    {!themesOverview ? (
                      <ComponentLoader minHeight={400} />
                    ) : null}

                    {themesOverview !== null
                      ? themesOverview.map((brand) => (
                          <ThemesAudienceChart
                            cardTitle={brand.name}
                            audienceVolumeTimelineData={brand.scores[1].scores}
                            audienceVolumeGaugeData={{
                              value: brand.scores[1].value,
                              type: brand.scores[1].type,
                              competitorAverage:
                                brand.scores[1].competitorAverage,
                            }}
                            audienceSentimentTimelineData={
                              brand.scores[2].scores
                            }
                            audienceSentimentGaugeData={{
                              value: brand.scores[2].value,
                              type: brand.scores[2].type,
                              competitorAverage:
                                brand.scores[2].competitorAverage,
                            }}
                            brandLogo={brand.logoUrl}
                          />
                        ))
                      : null}
                  </div>
                </div>
              </Box>
            ) : null}
          </div>
        </Grid>

        <Hidden smDown>
          <Grid item xs={12} md={3} className="hide-from-export">
            <InsightsCTA
              loading={insightsLoading}
              insight={themeInsight}
              data={insightsData}
              modalType="recommendations"
            />
          </Grid>
        </Hidden>

        <Grid ref={anchorRef} item container xs={12}>
          <Hidden smDown>
            {/* desktop brand content */}
            {filterItem.name === 'Brand Content' ? (
              <Box
                id="themes-overview-brand-content-screenshot-container"
                className={styles.BrandContentCompare}
                pl={0}
                pr={0}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <div className={styles.HeaderContainer}>
                      <div className={styles.TitleContainer}>
                        <h2 className={styles.Title}>
                          What is the brand saying?
                        </h2>
                        <BetaTag />
                      </div>

                      <DownloadDropdown
                        targetId="themes-overview-brand-content-screenshot-container"
                        padding={100}
                      />
                    </div>

                    <p>
                      These are samples of brand content from a variety of
                      indexed sources.
                    </p>
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item md={6} className="">
                    <div className={styles.ColumnHeader}>
                      <BrandLogo
                        size={SIZES.SM}
                        name={themesOverview?.[0]?.name}
                        src={themesOverview?.[0]?.logoUrl}
                      />
                    </div>
                    {themeVerbatimsLoading && currentBrandVerbatims === null ? (
                      <ComponentLoader minHeight={400} />
                    ) : null}
                    {!themeVerbatimsLoading &&
                    currentBrandVerbatims !== null &&
                    currentBrandVerbatims.length > 0
                      ? currentBrandVerbatims[0].verbatims
                          .filter((x) => x.verbatim_type === 1)
                          .map((verbatim) => (
                            <VerbatimBrand
                              source={verbatim.source}
                              content={verbatim.content}
                              logoUrl={themesOverview?.[0]?.logoUrl}
                            />
                          ))
                      : null}
                    {!themeVerbatimsLoading &&
                    currentBrandVerbatims !== null &&
                    currentBrandVerbatims.length === 0 ? (
                      <p>No samples available</p>
                    ) : null}
                  </Grid>
                  <Grid item md={6} className="">
                    <div
                      className={`${styles.ColumnHeader} ${styles.ColumnHeaderWithDropdown}`}
                    >
                      {brandDropdown !== null ? (
                        <Dropdown
                          showArrow
                          showListIcons
                          value={comparedBrand || brandDropdown[0].value}
                          options={brandDropdown}
                          onChange={dropdownChangeSelectedBrand}
                          logoSize="SM"
                        />
                      ) : null}
                    </div>
                    {themeVerbatimsLoading ? (
                      <ComponentLoader minHeight={400} />
                    ) : null}
                    {!themeVerbatimsLoading &&
                    comparedBrandVerbatims !== null &&
                    comparedBrandVerbatims.length > 0
                      ? comparedBrandVerbatims[0].verbatims
                          .filter((x) => x.verbatim_type === 1)
                          .map((verbatim) => (
                            <VerbatimBrand
                              source={verbatim.source}
                              content={verbatim.content}
                              logoUrl={
                                brandDropdown.filter(
                                  (brand) => brand.value === comparedBrand
                                )[0]?.imgUrl
                              }
                            />
                          ))
                      : null}
                    {!themeVerbatimsLoading &&
                    comparedBrandVerbatims !== null &&
                    comparedBrandVerbatims.length === 0 ? (
                      <p>No samples available</p>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </Hidden>

          <Hidden mdUp>
            {' '}
            {/* mobile brand content */}
            {filterItem.name === 'Brand Content' ? (
              <Box className={styles.BrandContentCompare} pl={0} pr={0}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className={styles.TitleContainer}>
                      <h2 className={styles.Title}>
                        What is the brand saying?
                      </h2>
                    </div>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={0}>
                  <div className={styles.ColumnHeader}>
                    <Carousel onChange={() => {}}>
                      <div className="slide">
                        <BrandLogo size={SIZES.LG} />
                        <p>{themesOverview?.[0].name}</p>
                      </div>
                      {competitorThemes.map((competitor) => (
                        <div className="slide" key={competitor.id}>
                          <BrandLogo size={SIZES.LG} src={competitor.logoUrl} />
                          <p>{competitor.name}</p>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  <VerbatimBrand />
                  <VerbatimBrand />
                  <VerbatimBrand />
                  <VerbatimBrand />
                  <VerbatimBrand />
                  <VerbatimBrand />
                  <VerbatimBrand />
                  <VerbatimBrand />
                </Grid>
              </Box>
            ) : null}
          </Hidden>

          {filterItem.name === 'Audience Content' ? (
            <Box
              id="themes-overview-audience-content-screenshot-container"
              className={styles.BrandContentCompare}
              pl={0}
              pr={0}
            >
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.HeaderContainer}>
                    <div className={styles.TitleContainer}>
                      <h2 className={styles.Title}>
                        What is the audience saying?
                      </h2>
                      <BetaTag />
                    </div>

                    <DownloadDropdown
                      targetId="themes-overview-audience-content-screenshot-container"
                      padding={100}
                    />
                  </div>

                  <p>
                    These are samples of audience content from a variety of
                    indexed sources.
                  </p>
                </Grid>
              </Grid>
              <Hidden smDown>
                {' '}
                {/* desktop audience content */}
                <Grid container spacing={5}>
                  <Grid item md={6} className="">
                    <div className={styles.ColumnHeader}>
                      <BrandLogo
                        size={SIZES.SM}
                        name={themesOverview?.[0].name}
                        src={themesOverview?.[0].logoUrl}
                      />
                    </div>
                    {themeVerbatimsLoading && currentBrandVerbatims === null ? (
                      <ComponentLoader minHeight={400} />
                    ) : null}
                    {!themeVerbatimsLoading &&
                    currentBrandVerbatims !== null &&
                    currentBrandVerbatims.length > 0
                      ? currentBrandVerbatims[0].verbatims
                          .filter((x) => x.verbatim_type === 2)
                          .map((verbatim) => (
                            <VerbatimCustomer
                              source={verbatim.source}
                              content={verbatim.content}
                            />
                          ))
                      : null}
                    {!themeVerbatimsLoading &&
                    currentBrandVerbatims !== null &&
                    currentBrandVerbatims.length === 0 ? (
                      <p>No samples available</p>
                    ) : null}
                  </Grid>
                  <Grid item md={6} className="">
                    <div
                      className={`${styles.ColumnHeader} ${styles.ColumnHeaderWithDropdown}`}
                    >
                      <Dropdown
                        showArrow
                        showListIcons
                        value={comparedBrand}
                        options={brandDropdown}
                        onChange={dropdownChangeSelectedBrand}
                        logoSize="SM"
                      />
                    </div>
                    {themeVerbatimsLoading ? (
                      <ComponentLoader minHeight={400} />
                    ) : null}
                    {!themeVerbatimsLoading &&
                    comparedBrandVerbatims !== null &&
                    comparedBrandVerbatims.length > 0
                      ? comparedBrandVerbatims[0].verbatims
                          .filter((x) => x.verbatim_type === 2)
                          .map((verbatim) => (
                            <VerbatimCustomer
                              source={verbatim.source}
                              content={verbatim.content}
                            />
                          ))
                      : null}
                    {!themeVerbatimsLoading &&
                    comparedBrandVerbatims !== null &&
                    comparedBrandVerbatims.length === 0 ? (
                      <p>No samples available</p>
                    ) : null}
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                {/* mobile audience content */}
                <Grid container spacing={0}>
                  <Grid item xs={12} className="">
                    <div className={styles.ColumnHeader}>
                      <Carousel onChange={() => {}}>
                        <div className="slide">
                          <BrandLogo size={SIZES.LG} />
                          <p>{themesOverview?.[0].name}</p>
                        </div>
                        {competitorThemes.map((competitor) => (
                          <div className="slide" key={competitor.id}>
                            <BrandLogo
                              size={SIZES.LG}
                              src={competitor.logoUrl}
                            />
                            <p>{competitor.name}</p>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                    <VerbatimCustomer />
                    <VerbatimCustomer />
                    <VerbatimCustomer />
                  </Grid>
                </Grid>
              </Hidden>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
}
