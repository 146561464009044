import { useMemo, useContext } from 'react';
import dayjs from 'dayjs';

import BNContext from '../contexts/BNContext';
import { getDayJsStartDate } from '../utils/timeframe';

const FILLER_AMPLITUDE_MULTIPLIER = 0.15;
const FILLER_FREQUENCY_IN_DAYS = 14;

export default function useColdstartFiller({
  yDomain,
  timelineData,
  type = '',
}) {
  const { timeframe } = useContext(BNContext);

  return useMemo(() => {
    if (!yDomain) {
      return [];
    }

    const result = [];

    const fillerEndDate = timelineData[0] ? dayjs(timelineData[0].x) : dayjs();

    const yPoint = timelineData[0] ? timelineData[0].y : 100;
    let yMin = yDomain.min;
    let yMax = yDomain.max;

    if (yDomain.buffer) {
      yMin = yDomain.min - yDomain.buffer;
      yMax = yDomain.min + yDomain.buffer;
    }

    const amplitude = (yMax - yMin) * FILLER_AMPLITUDE_MULTIPLIER;

    let y = yPoint + amplitude;

    let fillerFrequency = FILLER_FREQUENCY_IN_DAYS;

    if (
      getDayJsStartDate(timeframe).add(FILLER_FREQUENCY_IN_DAYS, 'd') >
        timelineData[0]?.x &&
      type === 'volume'
    ) {
      fillerFrequency = 1;
    }

    if (getDayJsStartDate(timeframe).toDate() && fillerEndDate) {
      for (
        let x = getDayJsStartDate(timeframe);
        dayjs(x).isBefore(fillerEndDate, 'day');
        x = x.add(fillerFrequency, 'day')
      ) {
        if (y === yPoint + amplitude) {
          y = yPoint - amplitude;
        } else {
          y = yPoint + amplitude;
        }

        result.push({
          x: x.valueOf(),
          y,
        });
      }
    }

    if (type === 'volume' && timelineData[0]?.x && timelineData[0]?.y) {
      result.push({
        x: timelineData[0].x,
        y: timelineData[0].y,
      });
    }

    if (timelineData[0] && result[result.length - 1]) {
      result[result.length - 1].y = timelineData[0].y;
    }

    return result;
  }, [timeframe, timelineData, yDomain, type]);
}
