import { BrandLogo } from '@blueoceanai/react-component-library';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { IconDownload } from '../../../assets/icons';
import { SIZES } from "../../../constants/props";
import styles from './CreativeScreenshotCardContent.module.scss';

export interface CreativeScreenshotCardContentProps {
  brand: {
    name: string;
    brandKey: string;
    logoUrl?: string;
  };
  date: string;
  assetUrl?: string;
  configUrl?: string;
  isEmbedded?: boolean;
  isCompareView?: boolean;
  imgOnly?: boolean;
  handleModalOpen?: () => void;
  errorMsg?: string;
  className?: string;
}

const CreativeScreenshotCardContent = ({
  brand,
  date,
  assetUrl = '',
  configUrl = '',
  isEmbedded = false,
  isCompareView = false,
  imgOnly = false,
  handleModalOpen = () => {},
  errorMsg,
  className,
}: CreativeScreenshotCardContentProps) => {
  const [imgLoadingError, setImgLoadingError] = useState<boolean>(false);
  const handleImgLoadingSuccess = useCallback(() => {
    setImgLoadingError(false);
  }, [setImgLoadingError]);
  const handleImgLoadingError = useCallback(() => {
    setImgLoadingError(true);
  }, [setImgLoadingError]);
  const handleModalOpenStrategy = useCallback(() => {
    if (!assetUrl?.length || imgLoadingError) return;
    handleModalOpen();
  }, [assetUrl, handleModalOpen, imgLoadingError]);
  const normalizedErrMsg = useMemo(() => {
    if (imgLoadingError) {
      return 'Screenshot not available';
    }
    return errorMsg;
  }, [errorMsg, imgLoadingError]);
  return (
    <div
      className={
        classNames(styles.CreativeScreenshotCardContent, 
          !assetUrl?.length ?
            styles.EmptyState :
            '',
          isEmbedded ? styles.EmbeddedContent : '',
          isEmbedded && imgOnly ? styles.EmbeddedImgOnly : '',
        className)
      }
    >
      <div
        className={classNames(styles.ImageContainer,
          isEmbedded || !assetUrl?.length || imgLoadingError ?
            styles.NoPointer :
            styles.Pointer,
          imgOnly ?
            styles.ImgOnly :
            '',
          isCompareView && isEmbedded && !imgOnly ? styles.EmbeddedCompare : '',
          className)
        }
        onClick={handleModalOpenStrategy}
      >
        {assetUrl?.length && !imgLoadingError ?
          <img src={assetUrl}
            alt={`creative-screenshot ${assetUrl}`}
            onError={handleImgLoadingError}
            onLoad={handleImgLoadingSuccess}
          /> :
          <div className={styles.EmptyState}><p>{normalizedErrMsg}</p></div>
        }
      </div>
      {(Boolean(!imgOnly) && Boolean(!isCompareView)) && 
        <div className={styles.Footer}>
          <div className={styles.Left}>
            <div className={styles.Logo}>
              <BrandLogo size={SIZES.MD} src={brand?.logoUrl} />
            </div>
            <div className={styles.Text}>
              <p>{brand?.name}</p>
              <div className={styles.ConfigUrl}>
                <a href={configUrl}
                  target="_blank"
                  rel="noreferrer"
                  title={configUrl}>
                  {configUrl}
                </a>
              </div>
            </div>
          </div>
          <div className={styles.Right}>
            {/* TODO: this is a security risk without noreferrer set, but won't work with it set */}
            {Boolean(assetUrl?.length) && !imgLoadingError && 
            // eslint-disable-next-line react/jsx-no-target-blank
            (<a href={assetUrl} target="_blank" rel="noopener" className={styles.DownloadIcon}>
                <IconDownload />
            </a>)}
            {Boolean(date?.length) &&
              (<div className={styles.DatePill}>
                {date}
              </div>)
            }
          </div>
        </div>
      }
     </div>
  );
};

export default CreativeScreenshotCardContent;
