import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import find from 'lodash/find';
import { TimeRange } from '../../../../../interfaces/dashboard-api';
import DropdownSelect, {
  DropdownOption,
} from '../../../Creative/DropdownSelect/DropdownSelect';
import { DateRangeOptions } from '../../WidgetSettingsShared';
import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/Pages/CustomizableDashboards';
import { GET_COMPETITORS } from '../../../../../api/queries/Pages/MarketIndexCompare';
import useCompetitiveSetOptions from '../../../../../hooks/useCompetitiveSetOptions';
import useBrandOptions from '../../../../../hooks/useBrandOptions';
import { FeatureFlag } from '../../../../../utils/featureFlags';
import DateRangeSelectorDropdown from '../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';
import styles from './SummaryTrendsGuide.module.scss';

interface FormFields {
  name: string;
  description: string;
  timeRange: TimeRange;
  competitiveSetKey: string;
  brandKey: string;
  endDate: Date;
}
const SummaryTrendsSettingsGuide = ({
  config,
  onConfigChange,
  onValidationChange,
}: WidgetConfigProps) => {
  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );
  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    { fetchPolicy: 'no-cache' }
  );

  const { name, description, timeRange, competitiveSetKey, brandKey, endDate } =
    config[FlowKey.SummaryTrendsSettings];
  const [inputValues, setInputValues] = useState<FormFields>({
    name: name || 'Trending',
    description:
      description || 'Shows rising and declining metrics with patterns',
    timeRange,
    competitiveSetKey,
    brandKey,
    endDate,
  });

  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  // the following logic is adapted & duplicated from PaceAnalysisSettingsGuide
  const competitiveSetOptions = useCompetitiveSetOptions();
  const brandOptions = useBrandOptions(competitorsResp);
  const trendOptions = useMemo(() => {
    return [
      { label: 'All (Delta, Avg, Isolated) Trending Up/Down', value: 'ALL' },
    ];
  }, []);

  const selectedCompetitorOption = useMemo(() => {
    if (!inputValues.competitiveSetKey && !competitiveSetOptions.length) {
      return null;
    }

    return find(competitiveSetOptions, {
      value: inputValues.competitiveSetKey,
    });
  }, [competitiveSetOptions, inputValues.competitiveSetKey]);

  const selectedBrandOption = useMemo(() => {
    if (!inputValues.brandKey && !brandOptions.length) {
      return null;
    }

    return find(brandOptions, { value: inputValues.brandKey });
  }, [brandOptions, inputValues.brandKey]);

  const selectedTimeRange = useMemo(
    () => DateRangeOptions.find(({ value }) => value === inputValues.timeRange),
    [inputValues.timeRange]
  );

  /* Event Handlers */
  const handleConfigSave = useCallback(
    (newInputValues: FormFields) => {
      const newConfig = structuredClone(config);
      const settingsConfig = newConfig[FlowKey.SummaryTrendsSettings];
      newConfig[FlowKey.SummaryTrendsSettings] = {
        ...settingsConfig,
        ...newInputValues,
      };

      onConfigChange(newConfig);
    },
    [config, onConfigChange]
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    const updatedInputValues = {
      ...inputValues,
      [key]: value,
    };

    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  };

  const handleDropdownChange = (
    updatedOption: SingleValue<DropdownOption>,
    field: string
  ) => {
    if (!updatedOption || !updatedOption?.value) {
      return;
    }

    const newInputValues = {
      ...inputValues,
      [field]: updatedOption.value,
    };

    if (field === 'competitiveSetKey') {
      newInputValues.brandKey = '';
    }

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    const newInputValues = {
      ...inputValues,
      timeRange: (e.timeRange as TimeRange) ?? TimeRange['90_DAYS'],
      endDate: e.endDate,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  /* Fetch Data */
  // if no competitiveSetKey, set first as default competitiveSetKey
  useEffect(() => {
    if (inputValues.competitiveSetKey || !competitiveSetOptions.length) {
      return;
    }

    const updatedInputValues = {
      ...inputValues,
      competitiveSetKey: competitiveSetOptions[0].value,
    };
    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  }, [competitiveSetOptions, handleConfigSave, inputValues]);

  // if no brandKey, set first as default brandKey
  useEffect(() => {
    if (
      brandOptions.length &&
      !find(brandOptions, { value: inputValues.brandKey })
    ) {
      const updatedInputValues = {
        ...inputValues,
        rivalBrands: [],
        brandKey: brandOptions[0].value,
      };
      setInputValues(updatedInputValues);
      handleConfigSave(updatedInputValues);
    }
  }, [brandOptions, handleConfigSave, inputValues]);

  // if no competitiveSet get required sessions
  useEffect(() => {
    if (!selectedCompetitorOption?.value) {
      return;
    }

    getFirstLastSession({ variables: { id: selectedCompetitorOption.value } });
  }, [getFirstLastSession, selectedCompetitorOption]);

  // get cometitiveSet with new SessionKey
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
        ?.sessionKey ||
      !selectedCompetitorOption?.value
    ) {
      return;
    }

    const { sessionKey } =
      firstAndLastSessionResp.competitiveSet.firstAndLastSession[1];
    getCompetitors({
      variables: { id: selectedCompetitorOption.value, sessionKey },
    });
  }, [firstAndLastSessionResp, getCompetitors, selectedCompetitorOption]);

  useEffect(() => {
    const disableSave =
      !name ||
      !description ||
      !timeRange ||
      !competitiveSetKey ||
      brandKey !== selectedBrandOption?.value;

    onValidationChange({
      [FlowKey.SummaryTrendsSettings]: disableSave,
    });
  }, [
    onValidationChange,
    name,
    description,
    timeRange,
    competitiveSetKey,
    brandKey,
    selectedBrandOption,
  ]);

  return (
    <div className={styles.SummaryTrendsSettingsGuide}>
      <div className={styles.Group}>
        <p className={styles.Label}>
          WIDGET NAME
          <span className={styles.Required}>*</span>
        </p>
        <input
          className={styles.Field}
          required
          type="text"
          name="name"
          value={inputValues.name}
          onChange={handleInputChange}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>WIDGET DESCRIPTION</p>
        <input
          className={styles.Field}
          required
          type="text"
          name="description"
          value={inputValues.description}
          onChange={handleInputChange}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>TRENDING TYPE</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={trendOptions}
          value={trendOptions[0]}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>COMPETITIVE SET</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={competitiveSetOptions}
          onChange={(e) => handleDropdownChange(e, 'competitiveSetKey')}
          value={selectedCompetitorOption}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>BRAND</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={brandOptions}
          onChange={(e) => handleDropdownChange(e, 'brandKey')}
          value={selectedBrandOption}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>DATE RANGE</p>
        {showCustomDateRangeSelector ? (
          <DateRangeSelectorDropdown
            onApply={handleApply}
            timeRange={timeRange}
            endDate={endDate}
            fullWidth
          />
        ) : (
          <DropdownSelect
            className={styles.Dropdown}
            options={DateRangeOptions}
            onChange={(e) => handleDropdownChange(e, 'timeRange')}
            value={selectedTimeRange}
          />
        )}
      </div>
    </div>
  );
};

export default SummaryTrendsSettingsGuide;
