import { gql } from '@apollo/client';

export const GET_KEYWORDS = gql`
  query getKeywords($id: ID, $sessionKey: ID, $type: Int, $accountKey: ID) {
    competitiveSet(id: $id) {
      brand {
        id
        name
        logoUrl
        keywordsOverTime {
          sessionDate
          keywords {
            name
            count
          }
        }
      }
      session(sessionKey: $sessionKey) {
        id
        audienceContentKeyWords: keywords(keywordTypeId: 2) {
          ...getKeywords
        }
        brandContentKeyWords: keywords(keywordTypeId: 1) {
          ...getKeywords
        }
        competitors {
          name
          id
          logoUrl
          brandKey
          audienceContentKeyWords: keywords(keywordTypeId: 2) {
            ...getKeywords
          }
          brandContentKeyWords: keywords(keywordTypeId: 1) {
            ...getKeywords
          }
        }
        recommendations(accountKey: $accountKey, type: $type) {
          id
          name
          value
          type
          impact_id
          sourceType_id
          isComplete
          practice_areas
          associatedType_id
        }
      }
    }
  }

  fragment getKeywords on Keyword {
    name
    count
    type_id
    verbatims {
      content
      source
    }
  }
`;
