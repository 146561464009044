import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Box from '@mui/material/Box';

import ArchetypeCardBack from '../ArchetypeCardBack/ArchetypeCardBack';

import styles from './ArchetypeCard.module.scss';

export default function ArchetypeCard({
  className,
  archetype,
  flippable = true,
  ...props
}) {
  const [flipped, setFlipped] = useState(false);

  return (
    <Box
      className={classNames(styles.ArchetypeCard, className)}
      onMouseOver={() => {
        if (!flippable) {
          return;
        }

        setFlipped(true);
      }}
      onMouseOut={() => setFlipped(false)}
      {...props}
    >
      {!isEmpty(archetype) ? (
        <Box
          className={classNames(
            styles.Content,
            flipped ? styles.Flipped : null
          )}
        >
          <Box className={styles.Front}>
            <img src={archetype.cardImgUrl} alt={archetype.name} />
          </Box>
          {flippable && (
            <Box className={styles.Back}>
              <ArchetypeCardBack divider archetype={archetype} />
            </Box>
          )}
        </Box>
      ) : null}
    </Box>
  );
}

ArchetypeCard.propTypes = {
  className: PropTypes.string,
  archetype: PropTypes.shape({
    cardImgUrl: PropTypes.string,
    message: PropTypes.string,
    exampleBrands: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        logoUrl: PropTypes.string,
      })
    ),
    name: PropTypes.string,
    voiceTags: PropTypes.arrayOf(PropTypes.string),
  }),
  flippable: PropTypes.bool,
};
