import random from 'lodash/random';
import last from 'lodash/last';
import forEach from 'lodash/forEach';
import find from 'lodash/find';

import { isBetween } from '../../../utils/timeframe';

const THEME_KEYS = {
  BRAND_VOLUME: 'brandVolume',
  AUDIENCE_VOLUME: 'audienceVolume',
  AUDIENCE_SENTIMENT: 'audienceSentiment',
};

export function prepareHeroThemes(data, timeframe) {
  if (
    !data?.competitiveSet?.brand ||
    !data?.competitiveSet?.session?.themes ||
    !timeframe
  ) {
    return null;
  }

  const { brand } = data?.competitiveSet;
  const { themes } = data?.competitiveSet?.session;

  if (!brand || !themes) {
    return null;
  }

  const result = {
    name: brand.name,
    id: brand.id,
    logoUrl: brand.logoUrl,
    themes: [],
  };

  themes.forEach((theme) => {
    const themesScoresMap = getThemeTrendScores({
      themeTrends: theme.themeTrends,
      timeframe,
    });

    const themeResult = {
      name: theme.name,
      scoresByTheme: themesScoresMap,
      themeType: theme.themeType,
    };

    result.themes.push(themeResult);
  });

  return result;
}

export function prepareCompetitorThemes(data, timeframe) {
  if (!data?.competitiveSet?.session?.competitors) {
    return null;
  }

  const { competitors } = data?.competitiveSet?.session;

  if (!competitors) {
    return null;
  }

  const result = [];

  competitors.forEach((competitor) => {
    const competitorResult = {
      logoUrl: competitor.logoUrl,
      name: competitor.name,
      id: competitor.id,
      themes: [],
    };

    competitor.themes.forEach((theme) => {
      const themesScoresMap = getThemeTrendScores({
        themeTrends: theme.themeTrends,
        timeframe,
      });

      const themeResult = {
        name: theme.name,
        scoresByTheme: themesScoresMap,
      };

      competitorResult.themes.push(themeResult);
    });

    result.push(competitorResult);
  });

  return result;
}

export function prepareLandingThemes(heroThemes, competitorThemes) {
  if (!heroThemes?.themes || !competitorThemes) {
    return null;
  }

  const result = [];

  const themeAveragesByThemeName = getThemeAveragesByThemeName(
    [heroThemes].concat(competitorThemes)
  );

  heroThemes.themes.forEach((theme, index) => {
    const latestThemeValues = getLatestThemeValues(theme);

    const themeResult = [
      {
        name: theme.name,
        theme: theme.themeType,
        type: 'volume',
        value: latestThemeValues[THEME_KEYS.BRAND_VOLUME],
        competitorAverage: themeAveragesByThemeName[theme.name][0],
        key: index * 10 + 1,
      },
      {
        type: 'volume',
        value: latestThemeValues[THEME_KEYS.AUDIENCE_VOLUME],
        competitorAverage: themeAveragesByThemeName[theme.name][1],
        key: index * 10 + 2,
      },
      {
        type: 'sentiment',
        value: latestThemeValues[THEME_KEYS.AUDIENCE_SENTIMENT],
        competitorAverage: themeAveragesByThemeName[theme.name][2],
        key: index * 10 + 3,
      },
    ];

    result.push(themeResult);
  });

  return result;
}

export function prepareOverviewTabThemes({
  selectedThemeName,
  heroThemes,
  competitorThemes,
}) {
  const result = [];

  const brandThemes = [heroThemes].concat(competitorThemes);

  const sums = [
    {
      count: 0,
      sum: 0,
    },
    {
      count: 0,
      sum: 0,
    },
    {
      count: 0,
      sum: 0,
    },
  ];

  brandThemes.forEach((brand) => {
    const brandThemeItem = {
      id: brand.id,
      name: brand.name,
      logoUrl: brand.logoUrl,
      scores: [],
    };

    const selectedBrandTheme = find(brand.themes, {
      name: selectedThemeName,
    });

    if (!selectedBrandTheme) {
      return;
    }

    const latestThemeValues = getLatestThemeValues(selectedBrandTheme);

    sums[0].sum += latestThemeValues[THEME_KEYS.BRAND_VOLUME];
    sums[0].count++;

    sums[1].sum += latestThemeValues[THEME_KEYS.AUDIENCE_VOLUME];
    sums[1].count++;

    sums[2].sum += latestThemeValues[THEME_KEYS.AUDIENCE_SENTIMENT];
    sums[2].count++;

    // brand volume
    brandThemeItem.scores[0] = {
      value: latestThemeValues[THEME_KEYS.BRAND_VOLUME],
      competitorAverage: 0,
      scores: selectedBrandTheme.scoresByTheme[THEME_KEYS.BRAND_VOLUME],
      theme: selectedBrandTheme.themeType,
      type: 'volume',
    };

    // audience volume
    brandThemeItem.scores[1] = {
      value: latestThemeValues[THEME_KEYS.AUDIENCE_VOLUME],
      competitorAverage: 0,
      scores: selectedBrandTheme.scoresByTheme[THEME_KEYS.AUDIENCE_VOLUME],
      theme: selectedBrandTheme.themeType,
      type: 'volume',
    };

    // audience sentiment
    brandThemeItem.scores[2] = {
      value: latestThemeValues[THEME_KEYS.AUDIENCE_SENTIMENT],
      competitorAverage: 100,
      scores: selectedBrandTheme.scoresByTheme[THEME_KEYS.AUDIENCE_SENTIMENT],
      theme: selectedBrandTheme.themeType,
      type: 'sentiment',
    };

    result.push(brandThemeItem);
  });

  result.forEach((brand) => {
    for (let x = 0; x < 3; x++) {
      // eslint-disable-next-line no-param-reassign
      brand.scores[x].competitorAverage = sums[x].sum / sums[x].count;
    }
  });

  return result;
}

export function prepareInsight(insight) {
  if (!insight) {
    return ['No Insight Available'];
  }

  try {
    let sentence;
    if (insight[0] === '[') {
      sentence = JSON.parse(insight);
      sentence = sentence.filter((blankSentence) => /\S/.test(blankSentence));
      return [sentence[random(sentence.length - 1)]];
    }
    return [insight];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function getThemeTrendScores({ themeTrends, timeframe }) {
  if (!themeTrends) {
    return;
  }

  const themesScoresMap = {
    [THEME_KEYS.BRAND_VOLUME]: null,
    [THEME_KEYS.AUDIENCE_VOLUME]: null,
    [THEME_KEYS.AUDIENCE_SENTIMENT]: null,
  };

  const variableIdPrefix = {
    [THEME_KEYS.BRAND_VOLUME]: '7BD',
    [THEME_KEYS.AUDIENCE_VOLUME]: '7AD',
    [THEME_KEYS.AUDIENCE_SENTIMENT]: '7AS',
  };

  themeTrends.forEach((themeTrend) => {
    const scoresJSON = JSON.parse(themeTrend.t4Quarters);
    const scores = scoresJSON
      .map((score) => {
        return {
          date: score.date,
          value: score.value,
        };
      })
      .filter((score) => {
        return isBetween(score.date, timeframe);
      });

    if (
      themeTrend.associatedTypeId.slice(0, 3) ===
      variableIdPrefix[THEME_KEYS.BRAND_VOLUME]
    ) {
      themesScoresMap[THEME_KEYS.BRAND_VOLUME] = scores;
    }

    if (
      themeTrend.associatedTypeId.slice(0, 3) ===
      variableIdPrefix[THEME_KEYS.AUDIENCE_VOLUME]
    ) {
      themesScoresMap[THEME_KEYS.AUDIENCE_VOLUME] = scores;
    }

    if (
      themeTrend.associatedTypeId.slice(0, 3) ===
      variableIdPrefix[THEME_KEYS.AUDIENCE_SENTIMENT]
    ) {
      themesScoresMap[THEME_KEYS.AUDIENCE_SENTIMENT] = scores;
    }
  });

  return themesScoresMap;
}

function getThemeAveragesByThemeName(brands) {
  const sumsAndCountsByThemeName = {};

  brands.forEach((brand) => {
    brand.themes.forEach((theme) => {
      if (!sumsAndCountsByThemeName[theme.name]) {
        sumsAndCountsByThemeName[theme.name] = [
          { sum: 0, count: 0 },
          { sum: 0, count: 0 },
          { sum: 0, count: 0 },
        ];
      }

      const latestThemeValues = getLatestThemeValues(theme);

      sumsAndCountsByThemeName[theme.name][0].sum +=
        latestThemeValues[THEME_KEYS.BRAND_VOLUME];
      sumsAndCountsByThemeName[theme.name][0].count++;

      sumsAndCountsByThemeName[theme.name][1].sum +=
        latestThemeValues[THEME_KEYS.AUDIENCE_VOLUME];
      sumsAndCountsByThemeName[theme.name][1].count++;

      sumsAndCountsByThemeName[theme.name][2].sum +=
        latestThemeValues[THEME_KEYS.AUDIENCE_SENTIMENT];
      sumsAndCountsByThemeName[theme.name][2].count++;
    });
  });

  const result = {};

  forEach(sumsAndCountsByThemeName, (sumsAndCounts, themeName) => {
    for (let x = 0; x < 3; x++) {
      if (!result[themeName]) {
        result[themeName] = [];
      }

      result[themeName][x] = sumsAndCounts[x].sum / sumsAndCounts[x].count;
    }
  });

  return result;
}

function getLatestThemeValues(theme) {
  if (!theme?.scoresByTheme) {
    return {
      [THEME_KEYS.BRAND_VOLUME]: 0,
      [THEME_KEYS.AUDIENCE_VOLUME]: 0,
      [THEME_KEYS.AUDIENCE_SENTIMENT]: 100,
    };
  }

  let brandVolumeValue = last(
    theme.scoresByTheme[THEME_KEYS.BRAND_VOLUME]
  )?.value;

  let audienceVolumeValue = last(
    theme.scoresByTheme[THEME_KEYS.AUDIENCE_VOLUME]
  )?.value;

  let audienceSentimentValue = last(
    theme.scoresByTheme[THEME_KEYS.AUDIENCE_SENTIMENT]
  )?.value;

  brandVolumeValue = brandVolumeValue ? brandVolumeValue * 100 : 0;
  audienceVolumeValue = audienceVolumeValue ? audienceVolumeValue * 100 : 0;
  audienceSentimentValue = audienceSentimentValue || 100;

  return {
    [THEME_KEYS.BRAND_VOLUME]: brandVolumeValue,
    [THEME_KEYS.AUDIENCE_VOLUME]: audienceVolumeValue,
    [THEME_KEYS.AUDIENCE_SENTIMENT]: audienceSentimentValue,
  };
}
