import classNames from 'classnames';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './TopNavDropdownMenu.module.scss';

export default function TopNavDropdownMenu({
  className,
  options,
  onClick,
  anchorEl,
  value,
  open,
  ...props
}) {
  return (
    <Popover
      id="top-navigation-menu"
      anchorEl={anchorEl}
      open={open}
      className={classNames(styles.TopNavDropdownMenu, className)}
      classes={{ paper: styles.Menu, root: styles.Overlay }}
      {...props}
    >
      {options.map((option) => {
        return (
          <MenuItem
            selected={value === option.value}
            onClick={() => onClick(option.value)}
            key={`${option.value}`}
            classes={{
              root: styles.MenuItem,
              selected: styles.SelectedMenuItem,
            }}
          >
            {option.icon ? (
              <div className={styles.Icon}>{option.icon}</div>
            ) : null}
            <span data-cy={`menu-item-${option.value}`}>{option.label}</span>
          </MenuItem>
        );
      })}
    </Popover>
  );
}

TopNavDropdownMenu.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  anchorEl: PropTypes.shape({}),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};
