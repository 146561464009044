import { useState, MouseEvent } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import cn from 'classnames';
import find from 'lodash/find';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FunctionalAreasView } from '../../types';
import { FeatureFlag } from '../../../../../utils/featureFlags';
import {
  MetricCategory,
  GetMetricCategoryResponse,
  getMetricCategories,
} from '../../../../../mocks/data/CustomizableDashboards/metrics';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../../../assets/icons/DynamicCaret';
import styles from './FunctionalAreasHeader.module.scss';

export interface FunctionalAreasHeaderProps {
  activeView: FunctionalAreasView;
  disabledView?: FunctionalAreasView;
  onViewChange: (view: FunctionalAreasView) => void;

  activeMetricCategory?: MetricCategory;
  onMetricCategoryChange: (categoryId: MetricCategory) => void;
  metricCategories: GetMetricCategoryResponse[];
}

const FunctionalAreasHeader = ({
  activeView,
  disabledView = '' as FunctionalAreasView,
  onViewChange,

  activeMetricCategory = MetricCategory.FunctionalAreas,
  onMetricCategoryChange,
  metricCategories,
}: FunctionalAreasHeaderProps) => {
  const flags = useFlags();
  const showGroupDropdown = flags[FeatureFlag.ViewWidgetSettingsGrouping];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleGroupSelectorOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleGroupSelectorClose = () => {
    setAnchorEl(null);
  };

  const viewOptions = Object.values(FunctionalAreasView);

  const handleMetricCategoryChange = (categoryId: MetricCategory) => {
    onMetricCategoryChange(categoryId);
    handleGroupSelectorClose();
  };

  const activeMetricCategoryOption = find(metricCategories, {
    id: activeMetricCategory,
  });

  return (
    <div
      className={cn(
        styles.FunctionalAreasHeader,
        activeView === FunctionalAreasView.List
          ? styles.ListView
          : styles.GridView
      )}
    >
      {showGroupDropdown ? (
        <div className={styles.GroupSelector}>
          <div className={styles.Label}>Group metrics by:</div>
          <button
            className={cn(styles.DropdownBtn, open && styles.Active)}
            onClick={handleGroupSelectorOpen}
            type="button"
          >
            <div>{activeMetricCategoryOption?.name}</div>

            <DynamicCaretIcon
              direction={open ? CaretDirection.up : CaretDirection.down}
            />
          </button>

          <Menu
            id="metric-group-selector"
            anchorEl={anchorEl}
            open={open}
            onClose={handleGroupSelectorClose}
            classes={{
              root: styles.Menu,
            }}
          >
            {getMetricCategories.map((category) => (
              <MenuItem
                key={category.id}
                onClick={() =>
                  handleMetricCategoryChange(category.id as MetricCategory)
                }
                classes={{ root: styles.MenuItem, selected: styles.Selected }}
                selected={category.id === activeMetricCategoryOption?.id}
              >
                {category.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      ) : (
        <div className={styles.Header}>{activeMetricCategoryOption?.name}</div>
      )}

      <div className={styles.RightSection}>
        {/* TODO: Add search ability */}
        <p className={styles.SubTitle}>View:</p>
        {viewOptions.map((areaView) => (
          <button
            onClick={() => onViewChange(areaView)}
            type="button"
            className={cn(
              styles.ViewButton,
              activeView === areaView && styles.Active,
              disabledView === areaView && styles.Disabled
            )}
            key={areaView}
          >
            {areaView}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FunctionalAreasHeader;
