import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalBase from '../ModalBase/ModalBase';

import { AlertTriangleIcon } from '../../../assets/icons';

import styles from './ModalConfirm.module.scss';

export default function ModalConfirm({
  className,
  title,
  message,
  helper,
  onClose,
  confirmText = 'Ok',
  onConfirm,
  cancelText = 'Cancel',
  onCancel,
  ...props
}) {
  return (
    <ModalBase
      title={title}
      className={classNames(styles.ModalConfirm, className)}
      onClose={onClose}
      primaryText={confirmText}
      onPrimaryClick={onConfirm}
      secondaryText={cancelText}
      onSecondaryClick={onCancel}
      {...props}
    >
      <div className={styles.Content}>
        <div className={styles.ColLeft}>
          <AlertTriangleIcon className={styles.Icon} />
        </div>

        <div className={styles.ColRight}>
          {message ? <h5>{message}</h5> : null}
          {helper ? <p>{helper}</p> : null}
        </div>
      </div>
    </ModalBase>
  );
}

ModalConfirm.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  helper: PropTypes.string,
  onClose: PropTypes.func,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
};
