export const USER_ACCOUNT_STATES = {
  ENTERPRISE: 'enterprise',
  PROFESSIONAL: 'professional',
  FREE: 'free',
  PAID: 'paid',
  GOLDEN_LIST: 'golden-list',
};

export const USER_ACCOUNT_TYPES = {
  AIDENTITY_ONLY: 1,
  INDIVIDUAL: 2,
  PROFESSIONAL: 3,
  PREMIUM: 4,
  ENTERPRISE: 5,
};
