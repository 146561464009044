import { gql } from '@apollo/client';

export const UPDATE_GOAL = gql`
  mutation addGoal(
    $competitiveSetKey: ID
    $accountKey: ID
    $archetype_id: Int
  ) {
    addGoal(
      goal: {
        competitiveSetKey: $competitiveSetKey
        accountKey: $accountKey
        archetype_id: $archetype_id
      }
    )
  }
`;
