import { AnchorHTMLAttributes, FunctionComponent, useMemo } from 'react';
import cn from 'classnames';
import { SingleValue } from 'react-select';
import find from 'lodash/find';
import GridIcon from '../../../../assets/icons/GridIcon';
import ListIcon from '../../../../assets/icons/ListIcon';
import DropdownSelect from '../DropdownSelect/DropdownSelect';
import {
  sortByOptions,
  SortType,
} from '../../../../constants/creative-asset-grid';
import styles from './AdvertisingCreativeSection.module.scss';
// TODO: add download of CSV values in future cycle
// import { IconDownload } from '../../../../assets/icons';

export enum AdvertisingView {
  Grid = 'grid',
  List = 'list',
}

const ViewControl = ({
  activeView,
  onActiveViewChange,
}: {
  activeView: AdvertisingView;
  onActiveViewChange: (newActiveView: AdvertisingView) => void;
}) => {
  return (
    <>
      <div
        onClick={() => onActiveViewChange(AdvertisingView.List)}
        className={cn(
          styles.Icon,
          activeView === AdvertisingView.List ? styles.Active : ''
        )}
      >
        <ListIcon />
      </div>
      <div
        onClick={() => onActiveViewChange(AdvertisingView.Grid)}
        className={cn(
          styles.Icon,
          activeView === AdvertisingView.Grid ? styles.Active : ''
        )}
      >
        <GridIcon />
      </div>
    </>
  );
};

export interface AdvertisingCreativeSectionProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  activeView: AdvertisingView;
  onActiveViewChange: (newActiveView: AdvertisingView) => void;

  selectedChannelFilter?: string;
  onChannelFilterChange: (
    option: SingleValue<{ value: string; label: string }>
  ) => void;

  selectedSortBy?: SortType;
  onSortByChange: (
    option: SingleValue<{ value: SortType; label: string }>
  ) => void;

  selectedMediaType?: string;
  onMediaTypeChange: (
    option: SingleValue<{ value: string; label: string }>
  ) => void;

  isGridCompareView: boolean;
  setIsGridCompareView: (update: boolean) => void;
}

const mediaTypeOptions = [
  {
    label: 'All Types',
    value: '0',
  },
  {
    label: 'Image',
    value: '1',
  },
  {
    label: 'Audio',
    value: '2',
  },
  {
    label: 'Video',
    value: '3',
  },
];

export const channelOptions = [
  {
    label: 'All Channels',
    value: '0',
  },
  {
    value: '1',
    label: 'Print',
  },
  {
    value: '2',
    label: 'Print - News',
  },
  {
    value: '3',
    label: 'Television',
  },
  {
    value: '4',
    label: 'Connected TV',
  },
  {
    value: '5',
    label: 'Radio',
  },
  {
    value: '6',
    label: 'Outdoor',
  },
  {
    value: '7',
    label: 'Internet',
  },
  {
    value: '8',
    label: 'Internet - Search',
  },
  {
    value: '9',
    label: 'Internet - Video',
  },
  {
    value: '10',
    label: 'Internet - Mobile',
  },
  {
    value: '11',
    label: 'Internet - Social',
  },
  {
    value: '12',
    label: 'Cinema',
  },
];

const AdvertisingCreativeSection: FunctionComponent<
  AdvertisingCreativeSectionProps
> = ({
  activeView,
  onActiveViewChange,

  selectedChannelFilter,
  onChannelFilterChange,

  selectedSortBy,
  onSortByChange,

  selectedMediaType,
  onMediaTypeChange,

  isGridCompareView,
  setIsGridCompareView,

  ...props
}) => {
  const selectedChannelFilterOption = useMemo(() => {
    return find(channelOptions, { value: selectedChannelFilter });
  }, [selectedChannelFilter]);

  const selectedSortByOption = useMemo(() => {
    return find(sortByOptions, { value: selectedSortBy });
  }, [selectedSortBy]);

  const selectedMediaTypeOption = useMemo(() => {
    return find(mediaTypeOptions, { value: selectedMediaType });
  }, [selectedMediaType]);

  return (
    <div className={styles.AdvertisingCreativeSection} {...props}>
      <p className={styles.Title}>Creative</p>
      <div className={styles.FilterSection}>
        <DropdownSelect
          options={mediaTypeOptions}
          onChange={onMediaTypeChange}
          value={selectedMediaTypeOption}
          className={styles.Dropdown}
        />
        <DropdownSelect
          options={channelOptions}
          onChange={onChannelFilterChange}
          value={selectedChannelFilterOption}
          className={styles.Dropdown}
        />
        <DropdownSelect
          options={sortByOptions}
          onChange={onSortByChange}
          value={selectedSortByOption}
          className={styles.Dropdown}
        />

        <ViewControl
          activeView={activeView}
          onActiveViewChange={onActiveViewChange}
        />

        {activeView === AdvertisingView.Grid && (
          <>
            <button
              className={styles.CompareButton}
              type="button"
              onClick={() => setIsGridCompareView(!isGridCompareView)}
              id="creative-advertising-compare-button"
            >
              Compare
            </button>
            {/* TODO: add download CSV values functionality in future cycle */}
            {/* <div className={styles.DownloadIcon}>
              <IconDownload />
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

export default AdvertisingCreativeSection;
