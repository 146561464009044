import * as React from 'react';

function SvgIconChartAreaStacked(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path d="M22 20H5V3H4v18h18z" fill="#D7DDE2" />
        <path
          fill="#20434A"
          d="M14.438 11.571L17.25 6 21 9.714V19H6v-5.571l3.75-5.572z"
        />
        <path
          fill="#CED9DB"
          d="M14.438 15.571L17.25 13 21 14.714V19H6v-2.571l3.75-2.572z"
        />
      </g>
    </svg>
  );
}

export default SvgIconChartAreaStacked;
