import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { FlexibleXYPlot, XAxis, LineSeries, AreaSeries } from 'react-vis';
import BNContext from '../../../contexts/BNContext';

import { getDayJsStartDate } from '../../../utils/timeframe';
import COLORS from '../../../constants/colors';
import useColdstartFiller from '../../../hooks/useColdstartFiller';

import styles from './ChartVolume.module.scss';

dayjs.extend(duration);

export default function ChartVolume({
  data,
  type = 'area',
  padLimits = false,
  xAxisLine = false,
}) {
  const { timeframe } = useContext(BNContext);
  const [xDomain, setXDomain] = useState([0, 0]);
  const [yDomain, setYDomain] = useState([0, 0]);
  const [convertedData, setConvertedData] = useState([]);

  const getAxisLimit = useCallback(() => {
    const result = {
      x: {
        min: null,
        max: null,
      },
      y: {
        min: null,
        max: null,
      },
    };

    const scoreOverTime = data.map((score) => ({
      date: score.date,
      name: score.name?.toLowerCase(),
      value: score.value,
    }));

    const convertedDataTemp = [];
    scoreOverTime.forEach((point) => {
      const pointXDateFormat = dayjs(point.date).valueOf();

      convertedDataTemp.push({
        x: dayjs(point.date).valueOf(),
        y: point.value,
      });

      if (isNil(result.x.min)) {
        result.x.min = pointXDateFormat;
      }

      if (isNil(result.x.max)) {
        result.x.max = pointXDateFormat;
      }

      result.x.min =
        pointXDateFormat < result.x.min ? pointXDateFormat : result.x.min;
      result.x.max =
        pointXDateFormat > result.x.max ? pointXDateFormat : result.x.max;

      if (isNil(result.y.min)) {
        result.y.min = point.value;
      }

      if (isNil(result.y.max)) {
        result.y.max = point.value;
      }

      result.y.min = Math.min(result.y.min, point.value);
      result.y.max = Math.max(result.y.max, point.value);
    });

    // setting "slice" size if there's just one data point
    if (convertedDataTemp.length === 1) {
      let sliceStart;
      if (
        dayjs
          .duration(
            dayjs(timeframe.end).diff(
              dayjs(timeframe.end).subtract(timeframe.quantity, timeframe.unit)
            )
          )
          .asDays() < 31
      ) {
        sliceStart = dayjs(convertedDataTemp[0].x).subtract(12, 'h').valueOf();
      } else if (
        dayjs
          .duration(
            dayjs(timeframe.end).diff(
              dayjs(timeframe.end).subtract(timeframe.quantity, timeframe.unit)
            )
          )
          .asDays() < 91
      ) {
        sliceStart = dayjs(convertedDataTemp[0].x).subtract(2, 'd').valueOf();
      } else {
        sliceStart = dayjs(convertedDataTemp[0].x).subtract(10, 'd').valueOf();
      }
      convertedDataTemp.unshift({
        x: sliceStart,
        y: convertedDataTemp[0].y,
      });
    }
    setConvertedData(convertedDataTemp);

    return result;
  }, [data, timeframe]);

  const setGraphLimit = useCallback(() => {
    const axisLimit = getAxisLimit();

    setXDomain([
      padLimits
        ? dayjs(axisLimit.x.min).subtract(1, 'd').valueOf()
        : dayjs(getDayJsStartDate(timeframe).toDate()),
      padLimits
        ? dayjs(axisLimit.x.max).add(1, 'd').valueOf()
        : axisLimit.x.max,
    ]);
    setYDomain([Math.max(axisLimit.y.min - 30, 0), axisLimit.y.max]);
  }, [getAxisLimit, padLimits, timeframe]);

  useEffect(() => {
    setGraphLimit();
  }, [setGraphLimit, type, timeframe]);

  function formatReadableDate(val) {
    return dayjs(val).format('l');
  }

  const filledInData = useColdstartFiller({
    timelineData: convertedData,
    yDomain: { max: yDomain[1], min: yDomain[0] },
    type: 'volume',
  });

  return (
    <FlexibleXYPlot
      className={styles.ChartVolume}
      xDomain={xDomain}
      yDomain={yDomain}
      margin={{
        left: 0,
        bottom: 30,
      }}
    >
      <AreaSeries
        data={filledInData}
        color={COLORS.colorGray20}
        curve="curveMonotoneX"
      />

      <LineSeries
        data={filledInData}
        color={COLORS.colorGray40}
        strokeWidth=".7px"
        curve="curveMonotoneX"
      />
      <AreaSeries data={convertedData} color="#eef6f8" />

      <LineSeries data={convertedData} stroke="#54abbb" strokeWidth="1.5px" />

      <XAxis
        tickFormat={formatReadableDate}
        tickTotal={10}
        tickSizeOuter={8}
        tickSizeInner={0}
        tickPadding={0}
        style={{
          line: {
            stroke: xAxisLine ? 'black' : 'transparent',
            strokeWidth: '0.5px',
          },
          text: {
            fill: COLORS.colorGrayBase60,
            stroke: 'none',
            fontSize: '11px',
            fontWeight: 300,
          },
          ticks: {
            line: {
              stroke: COLORS.colorGray20,
              strokeWidth: '0.5px',
            },
          },
        }}
      />
    </FlexibleXYPlot>
  );
}

ChartVolume.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      name: PropTypes.string,
      sessionKey: PropTypes.string,
      value: PropTypes.number,
      variableId: PropTypes.string,
    })
  ),
  type: PropTypes.string,
  padLimits: PropTypes.bool,
  xAxisLine: PropTypes.bool,
  timeframe: PropTypes.shape({
    quantity: PropTypes.number,
    unit: PropTypes.string,
    end: PropTypes.instanceOf(Date),
  }),
};
