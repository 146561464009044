/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import maxBy from 'lodash/maxBy';
import find from 'lodash/find';
import {
  esovMetricIds,
  getMetricCategories,
  MetricCategory,
} from '../../../../mocks/data/CustomizableDashboards/metrics';
import { MetricDetail, WidgetMetaResponse } from '../types';
import { WidgetType } from '../../../../interfaces/dashboard-api';
import {
  FlowKey,
  WidgetSettingsConfig,
} from '../../../../interfaces/widget-settings';

// currently, these 45 metrics are considered in the GraphQL layer for trends, so display as sources
const summaryTrendsMetrics = [
  '1A11',
  '1A12',
  '1A13',
  '1B11',
  '1B12',
  '1B15',
  '1B17',
  '1B18',
  '1B20',
  '1B25',
  '1C13',
  '1C14',
  '1C15',
  '1C16',
  '2A13',
  '2A14',
  '2B12',
  '2B13',
  '2B16',
  '3A11',
  '3A13',
  '3A14',
  '3B12',
  '3B14',
  '3C15',
  '4A11',
  '4A13',
  '4A14',
  '4B11',
  '4B14',
  '4B15',
  '4B17',
  '4B18',
  '4C13',
  '4C14',
  '4C18',
  '5A11',
  '5A13',
  '5A14',
  '5B11',
  '5B14',
  '5B16',
  '5B18',
  '5C13',
  '5C15',
];

// currently, these 59 metrics are considered in the GraphQL layer for visual maps, so display as sources
const visualMapsMetrics = [
  '1A',
  '1A11',
  '1A12',
  '1A13',
  '1B',
  '1B11',
  '1B12',
  '1B15',
  '1B17',
  '1B18',
  '1B20',
  '1B25',
  '1C',
  '1C13',
  '1C14',
  '1C15',
  '1C16',
  '2A',
  '2A13',
  '2A14',
  '2B',
  '2B12',
  '2B13',
  '2B16',
  '3A',
  '3A11',
  '3A13',
  '3A14',
  '3B',
  '3B12',
  '3B14',
  '3C',
  '3C15',
  '4A',
  '4A11',
  '4A13',
  '4A14',
  '4B',
  '4B11',
  '4B14',
  '4B15',
  '4B17',
  '4B18',
  '4C',
  '4C13',
  '4C14',
  '4C18',
  '5A',
  '5A11',
  '5A13',
  '5A14',
  '5B',
  '5B11',
  '5B14',
  '5B16',
  '5B18',
  '5C',
  '5C13',
  '5C15',
];

interface MetricIdBucketMap {
  [id: string]: string;
}

const metricBucketByIds = (
  category = MetricCategory.FunctionalAreas
): MetricIdBucketMap => {
  const metricCategory = find(getMetricCategories, { id: category });
  if (!metricCategory) return {};

  return (metricCategory.buckets ?? []).reduce((obj, bucket) => {
    bucket.metricIds.forEach((metricId: string) => {
      obj[metricId] = bucket.name;
    });
    return obj;
  }, {} as MetricIdBucketMap);
};

interface BucketCount {
  [bucketName: string]: number;
}

const getModeBucketName = (selectedMetrics: MetricDetail[]): string => {
  const metricBucketLookup = metricBucketByIds();
  const bucketCountLookup = selectedMetrics.reduce((obj, metric) => {
    const bucketName = metricBucketLookup[metric.id];
    if (!obj[bucketName]) {
      obj[bucketName] = 0;
    }
    obj[bucketName] += 1;
    return obj;
  }, {} as BucketCount);

  return (
    maxBy(
      Object.keys(bucketCountLookup),
      (bucket) => bucketCountLookup[bucket]
    ) ?? ''
  );
};

function getMetricIdsFromSettingsConfig({
  config,
  widgetType,
}: {
  config: WidgetSettingsConfig;
  widgetType: WidgetType;
}) {
  const defaultMetricIds = [] as string[];

  switch (widgetType) {
    case WidgetType.MetricAnalysisV1:
      return [config[FlowKey.SelectMetric]?.id];

    case WidgetType.MetricCollectionV1:
      return config[FlowKey.SelectGroup].metrics.map((metric) => metric.id);

    case WidgetType.ExcessShareOfVoiceV1:
      return esovMetricIds;

    case WidgetType.PaceAnalysisV1:
      return [config[FlowKey.SelectMetric]?.id];

    case WidgetType.MetricComparisonV1:
      return [config[FlowKey.SelectMetric]?.id];

    case WidgetType.SummaryTrendsV1:
      return summaryTrendsMetrics;

    case WidgetType.VisualMapsV1:
      return visualMapsMetrics;

    case WidgetType.StackRankingV1:
      return [config[FlowKey.SelectMetric]?.id];

    default:
      return defaultMetricIds;
  }
}

function getMetricIdsFromApiConfig({
  config,
  widgetType,
}: {
  config: WidgetMetaResponse;
  widgetType: WidgetType;
}) {
  const defaultMetricIds = [] as string[];

  switch (widgetType) {
    case WidgetType.MetricAnalysisV1:
      return [config?.options?.metric?.var_id];

    case WidgetType.MetricCollectionV1:
      return Object.values(
        config?.data_srcs?.metrics?.input?.selected_metrics
      )?.map((metric) => {
        return (
          metric as {
            id: string;
          }
        ).id;
      });

    case WidgetType.ExcessShareOfVoiceV1:
      return esovMetricIds;

    case WidgetType.PaceAnalysisV1:
      return [config?.options?.metric?.var_id];

    // initially display all considered metrics, then narrow to the ones from the GraphQL response
    case WidgetType.SummaryTrendsV1:
      return summaryTrendsMetrics;

    case WidgetType.MetricComparisonV1:
      return [config?.options?.metric?.var_id];

    case WidgetType.VisualMapsV1:
      return visualMapsMetrics;

    case WidgetType.StackRankingV1:
      return [config?.options?.metric?.var_id];

    default:
      return defaultMetricIds;
  }
}

function handleConfigInputArrayFromDashboardAPI(
  input: unknown[] | Record<string, unknown>
) {
  if (!input) {
    return [];
  }

  if (Array.isArray(input)) {
    return input;
  }

  return Object.values(input);
}

export {
  summaryTrendsMetrics,
  visualMapsMetrics,
  getModeBucketName,
  getMetricIdsFromSettingsConfig,
  getMetricIdsFromApiConfig,
  handleConfigInputArrayFromDashboardAPI,
};
