import type { Attributes, FunctionComponent } from 'react';
import {
  BrandDropdownOption,
  MetricDetail,
  WidgetSettingsModalAction,
} from '../Components/Pages/CustomizableDashboards/types';
import {
  ComparisonType,
  ScoreType,
  TimeRange,
  TrendType,
  VisualMapsViewType,
  WidgetType,
} from './dashboard-api';
import { MetricCategory } from '../mocks/data/CustomizableDashboards/metrics';

export enum FlowKey {
  SelectMetric = 'select-metric',
  SelectGroup = 'select-group',
  EditMetrics = 'edit-metrics',
  SingleMetricSettings = 'single-metric-settings',
  MetricGroupSettings = 'metric-group-settings',
  ESOVSettings = 'esov-settings',
  PaceAnalysisSettings = 'pace-analysis-settings',
  StackRankingSettings = 'stack-ranking-settings',
  MetricComparisonSettings = 'metric-comparison-settings',
  SummaryTrendsSettings = 'summary-trends-settings',
  VisualMapsSettings = 'visual-maps-settings',
}

export interface WidgetSettingsConfig {
  // Metric Selection Flows
  [FlowKey.SelectMetric]: {
    id: string;
    name: string;
  };
  [FlowKey.SelectGroup]: {
    groupName: string;
    metrics: MetricDetail[];
    metricGroup: MetricCategory;
  };

  // Metric Analysis Flows
  [FlowKey.SingleMetricSettings]: {
    name: string;
    timeRange: TimeRange;
    endDate: Date;
    brandKey: string;
    description: string;
    competitiveSetKey: string;
  };

  // Metric Collection Flows: {
  [FlowKey.MetricGroupSettings]: {
    widgetName: string;
    description: string;
    layout: { h: number; w: number; x: number; y: number };
    competitiveSetKey: string;
    brandKey: string;
    timeRange: TimeRange;
    endDate: Date;
  };

  // ESOV Flows
  [FlowKey.ESOVSettings]: {
    widgetName: string;
    description: string;
    competitiveSetKey: string;
    brandKey: string;
    timeRange: TimeRange;
    chartType: string;
  };

  // Pace Analysis Flows
  [FlowKey.PaceAnalysisSettings]: {
    name: string;
    description: string;
    timeRange: TimeRange;
    endDate: Date;
    competitiveSetKey: string;
    brandKey: string;
    comparisonType: ComparisonType;
    scoreType: string;
    rivalBrands: string[];
  };

  [FlowKey.StackRankingSettings]: {
    name: string;
    description: string;
    timeRange: TimeRange;
    endDate: Date;
    competitiveSetKey: string;
    brandKey: string;
    scoreType: string;
    competitorBrands: string[];
  };

  // Metric Comparison Flows
  [FlowKey.MetricComparisonSettings]: {
    widgetName: string;
    description: string;
    competitiveSetKey: string;
    brandKey: string;
    timeRange: TimeRange;
    brands: string[];
    scoreType: string;
    endDate: Date;
  };

  // Summary Trends Flows
  [FlowKey.SummaryTrendsSettings]: {
    name: string;
    description: string;
    timeRange: TimeRange;
    endDate: Date;
    competitiveSetKey: string;
    brandKey: string;
    trendType: TrendType;
  };

  // Visual Maps Flows
  [FlowKey.VisualMapsSettings]: {
    name: string;
    description: string;
    timeRange: TimeRange;
    endDate: Date;
    competitiveSetKey: string;
    competitorBrands: string[];
    viewType: VisualMapsViewType;
    metricGroup: MetricCategory;
    scoreType: ScoreType;
  };

  brands?: BrandDropdownOption[];
}

// layout defaults for metric collection widget
export const defaultLayoutMetricsCollection = { h: 3, w: 12, x: 0, y: 0 };

export enum SettingsView {
  Calculations = 'calculations',
  DataExplanation = 'data-explanation',
  Sources = 'sources',
  WidgetExample = 'widget-example',
  FunctionalAreas = 'functional-areas',
}

export interface StepValidation {
  [stage: string]: boolean; // TODO: fix the stage is the FLowKey
}

export interface WidgetConfigProps extends Attributes {
  onConfigChange: (newConfig: WidgetSettingsConfig) => void;
  config: WidgetSettingsConfig;
  widgetType: WidgetType;
  stageKey: FlowKey;
  settingAction: WidgetSettingsModalAction;
  onValidationChange: (updatedValidationStep: StepValidation) => void;
  onPreviousStage: () => void;
  containerClassName?: string;
}

export interface Stage {
  key: FlowKey;
  name: string;
  contentComponent: FunctionComponent<WidgetConfigProps>;
  guideComponent: FunctionComponent<WidgetConfigProps>;
}

// TODO: deprecate this enum and use ScoreType from dashboard-api types
export enum DashboardsMetricScore {
  Relative = ScoreType.INDEXED,
  Raw = ScoreType.RAW,
}
