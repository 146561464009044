/* eslint-disable */
// @ts-nocheck

import { FC, useMemo } from 'react';
import 'chartjs-adapter-dayjs-4';
import { Chart, registerables, ChartOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { CreativeChannelChartItem } from '../../../interfaces/chart';
import { abbreviateNumber } from '../../../utils/number';

import PALETTES from '../../../constants/color-palettes';
import { numberToAbbreviatedCurrency } from '../../../chartjs/tooltips/helpers';
import genericBarChartTooltip from '../../../chartjs/tooltips/genericBarChartTooltip/genericBarChartTooltip';
import styles from './CreativeChannelLineChart.module.scss';
import colors from '../../../constants/colors';

Chart.register(...registerables);
export interface CreativeChannelLineChartProps {
  data: Array<CreativeChannelChartItem>;
  options?: ChartOptions;
}

const CreativeChannelLineChart: FC<CreativeChannelLineChartProps> = ({
  data,
  options,
}) => {
  const yAxisFormatter: YAxisFormatter = (value) =>
    numberToAbbreviatedCurrency(value);

  const combinedOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: 20,
    },
    scales: {
      y: {
        grid: {
          color: colors.chartGridLine,
        },
        ticks: {
          font: {
            size: 10,
            color: colors.colorGrayBase60,
          },
          callback(value: number) {
            return `$ ${abbreviateNumber(value)}`;
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          minRotation: 0,
          maxRotation: 0,
          padding: 0,
          align: 'inner',
          maxTicksLimit: 8,
          source: 'data',
          color: colors.colorGrayBase60,
          font: {
            size: 10,
            style: 'normal',
          },
        },
        type: 'timeseries',
        time: {
          stepSize: 5,
          unit: 'day',
          displayFormats: {
            day: 'M/DD/YY',
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
        enabled: false,
        external: (context: ChartTooltipContextBar) =>
          genericBarChartTooltip(context, yAxisFormatter, 'channelLineChart'),
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    ...options,
  };

  const chartData = useMemo(() => {
    const formattedChartData = data?.map((item) => {
      return {
        data: item.data,
        fill: false,
        showLine: true,
        borderWidth: 2,
        pointHitRadius: 5,
        tension: 0.4,
        pointRadius: 3,
        pointBackgroundColor: colors.opacity80,
        borderColor:
          PALETTES?.[item?.legend?.color?.palette]?.[item?.legend?.color?.index]
            ?.DEFAULT,
        borderDash: item?.legend?.color?.dashed ? [5] : [],
      };
    });

    return {
      datasets: formattedChartData,
    };
  }, [data]);

  return (
    <div className={styles.ChartContainer}>
      <Line
        redraw
        id="line"
        data={chartData}
        options={combinedOptions as ChartOptions<'line'>}
      />
    </div>
  );
};

export default CreativeChannelLineChart;
