import dayjs from 'dayjs';
import { WidgetPointData } from '../../../Components/Pages/CustomizableDashboards/types';
import { ChartTooltipContextLine } from '../../../interfaces';
import genericWidgetTooltip from '../genericWidgetTooltip/genericWidgetTooltip';
import styles from './paceWidgetTooltip.module.scss';

const paceWidgetTooltip = (context: ChartTooltipContextLine) => {
  const { tooltip } = context;
  const tooltipParent = document.createElement('div');
  tooltipParent.classList.add(styles.PaceWidgetTooltip);
  const dateLabelEl = document.createElement('div');
  dateLabelEl.classList.add(styles.DateLabel);
  const tooltipData = tooltip?.dataPoints[0]?.raw as WidgetPointData;
  const dateLabel = document.createTextNode(
    dayjs(tooltipData.x).format('MM/DD/YYYY')
  );
  const datePrefixEl = document.createElement('span');
  datePrefixEl.classList.add(styles.DatePrefix);
  datePrefixEl.textContent = 'Date: ';
  dateLabelEl.appendChild(datePrefixEl);
  dateLabelEl.appendChild(dateLabel);
  tooltipParent.appendChild(dateLabelEl);

  tooltip.dataPoints.forEach((point) => {
    const pointData = point.raw as WidgetPointData;
    const backgroundColor = point.dataset.borderColor as string;
    const labelEl = document.createElement('div');
    labelEl.classList.add(styles.Label);

    const colorSquare = document.createElement('div');
    colorSquare.classList.add(styles.ColorSquare);
    colorSquare.style.backgroundColor = backgroundColor;
    labelEl.appendChild(colorSquare);

    const descriptionContainer = document.createElement('div');
    descriptionContainer.classList.add(styles.DescriptionContainer);
    const [name, value] = pointData.description.split(':');
    const nameEl = document.createElement('span');
    nameEl.textContent = name;

    const valueEl = document.createElement('span');
    valueEl.classList.add(styles.Value);
    valueEl.textContent = value;

    descriptionContainer.appendChild(nameEl);
    descriptionContainer.appendChild(valueEl);

    labelEl.appendChild(descriptionContainer);

    tooltipParent.appendChild(labelEl);
  });

  return genericWidgetTooltip(context, tooltipParent);
};

export default paceWidgetTooltip;
