/* eslint-disable no-console */
import { Grid } from '@mui/material';
import { AnchorHTMLAttributes, useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useDashboardAPI from '../../../../../hooks/useDashboardAPI';
import AddWidgetCard from '../../AddWidgetCard/AddWidgetCard';
import { WidgetTemplate } from '../../types';
import styles from './WidgetLibraryModal.module.scss';
import { WidgetType } from '../../../../../interfaces/dashboard-api';
import { FeatureFlag } from '../../../../../utils/featureFlags';

export interface WidgetLibraryModalProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  onAddWidget: (widgetType: string) => void;
}

const WidgetLibraryModal = ({ onAddWidget }: WidgetLibraryModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [widgets, setWidgets] = useState<WidgetTemplate[]>([]);
  const { getWidgetTemplates } = useDashboardAPI();
  const flags = useFlags();

  useEffect(() => {
    if (widgets.length) return;

    setIsLoading(true);

    getWidgetTemplates().then((resp) => {
      if (!resp) {
        setIsLoading(false);
        return;
      }
      const { templates } = resp;
      const filteredTemplates = templates.filter((widget) => {
        switch (widget.type_id) {
          case flags[FeatureFlag.ViewMetricAnalysisV1] &&
            WidgetType.MetricAnalysisV1:
            return true;
          case flags[FeatureFlag.ViewMetricCollectionV1] &&
            WidgetType.MetricCollectionV1:
            return true;
          case flags[FeatureFlag.ViewExcessShareOfVoiceV1] &&
            WidgetType.ExcessShareOfVoiceV1:
            return true;
          case flags[FeatureFlag.ViewSummaryTrendsV1] &&
            WidgetType.SummaryTrendsV1:
            return true;
          case flags[FeatureFlag.ViewPaceAnalysisV1] &&
            WidgetType.PaceAnalysisV1:
            return true;
          case flags[FeatureFlag.ViewMetricComparisonV1] &&
            WidgetType.MetricComparisonV1:
            return true;
          case flags[FeatureFlag.ViewStackRankingV1] &&
            WidgetType.StackRankingV1:
            return true;
          case flags[FeatureFlag.ViewVisualMapsV1] && WidgetType.VisualMapsV1:
            return true;

          default:
            return false;
        }
      });

      if (!filteredTemplates.length) return;

      setWidgets(filteredTemplates);
      setIsLoading(false);
    });
  }, [getWidgetTemplates, widgets.length, flags]);

  return (
    <div className={styles.WidgetLibrary}>
      {isLoading ? (
        <p>
          No widgets are currently available. Please try again and let us know
          if this message persists!
        </p>
      ) : (
        <Grid container rowSpacing={1} spacing={4} className={styles.Library}>
          {widgets.map((widget) => (
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={4}
              item
              key={widget.id}
              className={styles.Widget}
            >
              <AddWidgetCard
                title={widget.name}
                description={widget.description}
                widgetType={widget.type_id}
                onAddWidget={onAddWidget}
                className={styles.WidgetCard}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default WidgetLibraryModal;
