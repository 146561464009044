import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { BrandLogo } from '@blueoceanai/react-component-library';

import { SIZES } from '../../../constants/props';

import styles from './ArchetypeCardBack.module.scss';
import ArchetypeIcon from '../../../assets/icons/ArchetypeIcon';

export default function ArchetypeCardBack({
  className,
  archetype,
  divider,
  size = 'md',
  ...props
}) {
  return (
    <Box
      className={classNames(
        styles.ArchetypeCardBack,
        divider ? styles.Divider : null,
        size === SIZES.SM ? styles.SM : null,
        size === SIZES.MD ? styles.MD : null,
        className
      )}
      {...props}
    >
      <Box p={4} className={styles.Header}>
        <ArchetypeIcon archetype={archetype.name} className={styles.Icon} />{' '}
        <h1 className={styles.Name}>{archetype.name}</h1>
      </Box>
      <Box p={4} className={styles.Body}>
        <Box className={styles.Section}>
          <h2 className={styles.Label}>BRAND VOICE</h2>
          <p className={styles.Copy}>{archetype.voiceTags.join(', ')}</p>
        </Box>
        <Box className={styles.Section}>
          <h2 className={styles.Label}>BRAND MESSAGE</h2>
          <p className={styles.Copy}>{archetype.message}</p>
        </Box>
        <Box className={styles.Section}>
          <h2 className={styles.Label}>BRAND EXAMPLE</h2>
          <Box className={styles.BrandLogos}>
            {archetype.exampleBrands.map((brand) => (
              <BrandLogo
                className={styles.Logo}
                src={brand.logoUrl}
                key={brand.name}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ArchetypeCardBack.propTypes = {
  className: PropTypes.string,
  archetype: PropTypes.shape({
    cardImgUrl: PropTypes.string,
    message: PropTypes.string,
    exampleBrands: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        logoUrl: PropTypes.string,
      })
    ),
    name: PropTypes.string,
    voiceTags: PropTypes.arrayOf(PropTypes.string),
  }),
  divider: PropTypes.bool,
  size: PropTypes.string,
};
