import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { Button } from '@blueoceanai/react-component-library';

import { BUTTON_VARIANTS } from '../../../constants/props';

import styles from './ModalFooter.module.scss';

export default function ModalFooter({
  className,
  onClose,
  onAction,
  actionText = 'Submit',
  cancelText = 'Cancel',
  disableAction,
  loading,
  ...props
}) {
  return (
    <div data-cy="modal-footer">
      <Box
        p={4}
        className={classNames(styles.ModalFooter, className)}
        {...props}
      >
        <Box mr={2}>
          <Button variant={BUTTON_VARIANTS.SECONDARY} onClick={onClose}>
            {cancelText}
          </Button>
        </Box>
        <Box>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            type="submit"
            onClick={onAction}
            disabled={disableAction}
            loading={loading}
          >
            {actionText}
          </Button>
        </Box>
      </Box>
    </div>
  );
}

ModalFooter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  actionText: PropTypes.string,
  cancelText: PropTypes.string,
  disableAction: PropTypes.bool,
  loading: PropTypes.bool,
};
