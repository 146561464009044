import * as React from 'react';

function SvgIconEditAlt(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g
        style={{
          mixBlendMode: 'darken',
        }}
        fill="none"
        fillRule="evenodd"
      >
        <path d="M0 0h24v24H0z" />
        <path
          fill="#FFF"
          d="M21.5 20a.5.5 0 01.09.992L21.5 21h-19a.5.5 0 01-.09-.992L2.5 20h19zM15.756 2c.222 0 .437.074.61.208l.1.088 4.238 4.288a.997.997 0 01.088 1.32l-.088.1-2.839 2.779-6.917 6.927a.999.999 0 01-.578.282l-.132.008H6a1 1 0 01-.993-.883L5 17v-4.238a1.001 1.001 0 01.203-.61l.087-.1 6.937-6.927 2.82-2.83a.999.999 0 01.71-.295zM13.03 5.736l-.095.096L6 12.756V17l4.243.001 6.923-6.932.098-.097-4.235-4.235zM15.756 3l-2.021 2.028 4.243 4.243 2.021-1.978L15.756 3z"
        />
      </g>
    </svg>
  );
}

export default SvgIconEditAlt;
