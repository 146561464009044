import mergeWith from 'lodash/mergeWith';
import cloneDeep from 'lodash/cloneDeep';

import { USER_ACCOUNT_TYPES } from './user';
import { USER_ROLES_KEYS } from './roles';

/**
 * Default feature flags for use as a base.
 *
 * Any negative values in this default map will not be overridden
 * when merging with overrides from professional or enterprise.
 * Each layer can only disable the layer below, not enable it
 *
 * Note: The ROOT property indicates the flag for the entire feature
 * For example, if GLOBAL.PROFILE.ROOT is false
 * then GLOBAL.PROFILE.OVERVIEW is ignored, since its parent's ROOT is off
 *
 * For more info, contact Vinh.
 */
export const BASE_FEATURE_FLAGS = {
  GLOBAL: {
    DATE_RANGE_PICKER: {
      ROOT: true, // not applied

      CALENDAR: {
        ROOT: true, // not applied

        HIGHLIGHT_SESSION_DATES: true,
      },
    },
    GOLDEN_BRANDS: false,
    METRICS_FAVORITE: false,
    FULL_SCREEN: false,
    SHARE: {
      ROOT: true,

      COPY_LINK: true, // not applied
    },
    DOWNLOAD: {
      ROOT: true, // not applied

      SVG: false,
      CSV: false,
      PDF: true,
      PPT: false,
      PNG: true,
    },
    MARKERS: {
      /** Use Marker Events */
      ROOT: true,

      ADD: {
        /** Add Marker Events */
        ROOT: true,

        /**
         * Create private markers
         *
         * Private = true and Brand = false indicates PRIVATE_ONLY
         * Do not create a PRIVATE_ONLY flag as that will allow
         * the invalid state PRIVATE_ONLY = true and PRIVATE = false
         */
        PRIVATE: true,
        ACCOUNT: true,
        BRAND: true,
      },
      EDIT: {
        ROOT: true,
        PRIVATE: true,
        ACCOUNT: true,
        BRAND: true,
      },
    },
  },
  MARKET_INDEX: {
    ROOT: true, // not applied

    OVERVIEW: {
      ROOT: true, // not applied
    },
    SCORECARD: {
      ROOT: true, // not applied
    },
    FACTORS: {
      ROOT: true, // not applied
    },
    COMPARE: {
      ROOT: true, // not applied
    },
  },
  CONTENT_ANALYSIS: {
    ROOT: true, // not applied

    THEMES: {
      ROOT: true,
    },
    TOPICS: {
      ROOT: true,
    },
    KEYWORDS: {
      ROOT: true,
      CHARTS_OVER_TIME: true,
    },
    BRAND_PERSONALITY: {
      ROOT: true,

      GOALS: true,
    },
  },
  CREATIVE: {
    ROOT: true, // not applied

    CHANNELS: {
      ROOT: true,
    },
    CONTENT_EVALUATOR: {
      ROOT: false,
    },
  },
  METRICS: {
    ROOT: true, // not applied

    L1: {
      ROOT: true, // not applied
    },
    L2: {
      ROOT: true, // not applied
    },
    L3: {
      ROOT: true, // not applied

      BAR: true,

      TIMELINE: true,

      PIE: true,
    },
  },
  PROFILE: {
    ROOT: true,

    OVERVIEW: {
      ROOT: true,
    },
    ALERTS: {
      ROOT: false,
    },
    MARKER_EVENTS: {
      ROOT: true,
    },
  },
  ADMIN_SETTINGS: {
    ROOT: true,

    USERS: {
      ROOT: true,

      FORM: {
        BRANDS: true,
      },
    },
    GLOBAL_MARKERS: {
      ROOT: true,
    },
    BILLING: {
      ROOT: false,
    },
  },
  REPORTS: {
    ROOT: true,
  },
  RESOURCES: {
    ROOT: false,
  },
  ALERTS: {
    ROOT: false,
  },
  MESSAGE_CENTER: {
    ROOT: false, // not applied
  },
  RECOMMENDATIONS: {
    ROOT: true, // not applied
    TALK: true,
  },
};

type DeepPartial<T> = T extends Record<string, unknown>
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;
export type FeatureFlags = typeof BASE_FEATURE_FLAGS;
export type FeatureLayer = DeepPartial<FeatureFlags>;
export type FeatureMap = Record<number, FeatureLayer>;

// when merging professional/enterprise with default feature flags, if default value is false, then don't override
export const ACCOUNT_TYPE_FEATURE_FLAGS: FeatureMap = {
  0: BASE_FEATURE_FLAGS,
  [USER_ACCOUNT_TYPES.AIDENTITY_ONLY]: {},
  [USER_ACCOUNT_TYPES.INDIVIDUAL]: {},
  [USER_ACCOUNT_TYPES.PROFESSIONAL]: {
    CREATIVE: {
      CHANNELS: {
        ROOT: false,
      },
    },
    CONTENT_ANALYSIS: {
      TOPICS: {
        ROOT: false,
      },
    },
  },
  [USER_ACCOUNT_TYPES.PREMIUM]: {},
  [USER_ACCOUNT_TYPES.ENTERPRISE]: {},
};

/*
  Since feature layers are merged from base -> account -> user
  There is no implicit relationship between the user layers (admin, user)
  In order to reduce duplication of features that are disabled at the user level
  mergeLayers is used to create user layers using other user layers as a base
*/
export const USER_ROLE_FEATURE_FLAGS: FeatureMap = {};

USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.ACCOUNT_ADMIN] = {
  GLOBAL: {
    DATE_RANGE_PICKER: {
      CALENDAR: {
        HIGHLIGHT_SESSION_DATES: false,
      },
    },
  },
  ADMIN_SETTINGS: {
    USERS: {
      ROOT: true,
    },
  },
};

USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BILLING_ADMIN] = mergeLayers(
  USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.ACCOUNT_ADMIN],
  {
    GLOBAL: {
      DATE_RANGE_PICKER: {
        CALENDAR: {
          HIGHLIGHT_SESSION_DATES: false,
        },
      },
    },
    ADMIN_SETTINGS: {
      USERS: {
        ROOT: false,
      },
      GLOBAL_MARKERS: {
        ROOT: false,
      },
    },
    RECOMMENDATIONS: {
      TALK: false,
    },
  }
);

USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BRAND_ADMIN] = mergeLayers(
  USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.ACCOUNT_ADMIN],
  {
    GLOBAL: {
      DATE_RANGE_PICKER: {
        CALENDAR: {
          HIGHLIGHT_SESSION_DATES: false,
        },
      },
      MARKERS: {
        ADD: {
          ACCOUNT: false,
        },
        EDIT: {
          ACCOUNT: false,
        },
      },
    },
    ADMIN_SETTINGS: {
      USERS: {
        FORM: {
          BRANDS: false,
        },
      },
      BILLING: {
        ROOT: false,
      },
    },
  }
);

USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BRAND_USER] = mergeLayers(
  USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BRAND_ADMIN],
  {
    GLOBAL: {
      DATE_RANGE_PICKER: {
        CALENDAR: {
          HIGHLIGHT_SESSION_DATES: false,
        },
      },
    },
    RECOMMENDATIONS: {
      TALK: false,
    },
  }
);

USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BRAND_GUEST] = mergeLayers(
  USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BRAND_USER],
  {
    GLOBAL: {
      DATE_RANGE_PICKER: {
        CALENDAR: {
          HIGHLIGHT_SESSION_DATES: false,
        },
      },
      MARKERS: {
        ADD: {
          // Private = true and Brand = false indicates PRIVATE_ONLY
          PRIVATE: true,
          ACCOUNT: false,
          BRAND: false,
        },
        EDIT: {
          // Private = true and Brand = false indicates PRIVATE_ONLY
          PRIVATE: true,
          ACCOUNT: false,
          BRAND: false,
        },
      },
    },
    ADMIN_SETTINGS: {
      ROOT: false,
    },
    RECOMMENDATIONS: {
      TALK: false,
    },
  }
);

USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BRAND_VIEWER] = mergeLayers(
  USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BRAND_GUEST],
  {
    GLOBAL: {
      DATE_RANGE_PICKER: {
        CALENDAR: {
          HIGHLIGHT_SESSION_DATES: false,
        },
      },
      DOWNLOAD: {
        ROOT: false,
      },
      SHARE: {
        ROOT: false,
      },
      MARKERS: {
        ADD: {
          ROOT: false,
        },
        EDIT: {
          ROOT: false,
        },
      },
    },
    PROFILE: {
      MARKER_EVENTS: {
        ROOT: false,
      },
    },
    ADMIN_SETTINGS: {
      ROOT: false,
    },
    RECOMMENDATIONS: {
      TALK: false,
    },
  }
);

USER_ROLE_FEATURE_FLAGS[USER_ROLES_KEYS.BLUEOCEAN_ADMIN] = {
  GLOBAL: {
    DATE_RANGE_PICKER: {
      CALENDAR: {
        HIGHLIGHT_SESSION_DATES: true,
      },
    },
  },
};

/** Get the merged feature flags for the account, with an optional user role  */
export function getFeatureFlags(
  accountType: number,
  userRoleId: number
): FeatureFlags {
  const layers = [ACCOUNT_TYPE_FEATURE_FLAGS[accountType]];
  if (userRoleId) layers.push(USER_ROLE_FEATURE_FLAGS[userRoleId]);
  return merge(BASE_FEATURE_FLAGS, ...layers);
}

/** Merge feature layers with earlier layers taking precedence */
export function merge(
  base: FeatureFlags,
  ...layers: FeatureLayer[]
): FeatureFlags {
  if (!layers.length) return cloneDeep(base);

  // Ensure result is always a copy, since mergeWith mutates the first argument
  return mergeWith(cloneDeep(base), ...layers, withLayers);
}

export function mergeLayers(...layers: FeatureLayer[]): FeatureLayer {
  if (layers.length === 1) return cloneDeep(layers[0]);
  return mergeWith(cloneDeep(layers[0]), ...layers.slice(1), withLayers);
}

/** Determine feature status. Base layers take precedence */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withLayers(base: any, sub: any) {
  if (base === false) {
    return base;
  }

  if (base === true) {
    return sub;
  }

  // If neither return undefined so that mergeWith uses its default
  // this is critical for traversing nested structures
  // if typeof base === 'object' it will land here and recurse
  // if a boolean is returned instead then the top level will become booleans, losing all depth
  return undefined;
}
