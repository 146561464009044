import cn from 'classnames';
import { Button } from '@blueoceanai/react-component-library';
import styles from './SettingsButtonView.module.scss';
import { SettingsView } from '../../../../../interfaces/widget-settings';

export interface SettingsButtonViewProps {
  activeView: SettingsView;
  handleViewChange: (view: SettingsView) => void;
}

const SettingsButtonView = ({
  activeView,
  handleViewChange,
}: SettingsButtonViewProps) => {
  const isWidgetExampleActive = activeView === SettingsView.WidgetExample;

  return (
    <div className={styles.ButtonsControl}>
      <Button
        onClick={() => handleViewChange(SettingsView.WidgetExample)}
        className={cn(styles.Button)}
        variant={isWidgetExampleActive ? 'primary' : undefined}
      >
        Widget Example
      </Button>
      <Button
        onClick={() => handleViewChange(SettingsView.DataExplanation)}
        className={cn(styles.Button)}
        variant={isWidgetExampleActive ? undefined : 'primary'}
      >
        Data Explanation
      </Button>
    </div>
  );
};

export default SettingsButtonView;
