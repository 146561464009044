import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalDrawer from '../../Molecules/ModalDrawer/ModalDrawer';
import AddRecommendation from '../AddRecommendation/AddRecommendation';

import RecommendationsIcon from '../../Atoms/RecommendationsIcon/RecommendationsIcon';

import styles from './AddRecommendationModal.module.scss';

export default function AddRecommendationModal({
  className,
  open,
  onClose,
  onAdd,
  onEdit,
  editedRecommendation,
  ...props
}) {
  return (
    <ModalDrawer
      title="Recommendations"
      open={open}
      onClose={onClose}
      icon={RecommendationsIcon}
      className={classNames(styles.AddRecommendationModal, className)}
      {...props}
    >
      <AddRecommendation
        onClose={onClose}
        onAdd={onAdd}
        onEdit={onEdit}
        editedRecommendation={editedRecommendation}
      />
    </ModalDrawer>
  );
}

AddRecommendationModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  editedRecommendation: PropTypes.objectOf(PropTypes.string),
};
