import { GenericIconProps } from './types';

const CheckIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.212 8.386L10.928 12.514L9.70645 11.2933C9.3163 10.9032 8.68271 10.9032 8.29245 11.2934C7.90219 11.6837 7.90219 12.3173 8.29245 12.7076L10.2925 14.7076C10.4826 14.8958 10.7359 15 11 15L11.0802 14.997C11.3992 14.9633 11.6672 14.8025 11.8325 14.5536L16.7696 9.63055C17.1385 9.09565 17.014 8.47374 16.5539 8.1677C16.1254 7.88389 15.5612 7.97066 15.2379 8.3527L15.212 8.386Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckIcon;
