import { AnchorHTMLAttributes, useMemo } from 'react';
import { CheckInCircleIcon, CircleIcon } from '../../../../../assets/icons';
import PencilEdit from '../../../../../assets/icons/PencilEdit';
import styles from './SettingStepIcon.module.scss';

export interface SettingStepIconProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  stepIndex: number;
  activeIndex: number;
  isEditAction: boolean;
}

enum StepStatus {
  Complete,
  Current,
  Future,
}

const statusIconMap: { [key in StepStatus]: JSX.Element } = {
  [StepStatus.Complete]: <CheckInCircleIcon />,
  [StepStatus.Current]: <PencilEdit />,
  [StepStatus.Future]: <CircleIcon />,
};

const SettingStepIcon = ({
  stepIndex,
  activeIndex,
  isEditAction,
}: SettingStepIconProps) => {
  const stepStatus = useMemo(() => {
    if (stepIndex === activeIndex) {
      return StepStatus.Current;
    }

    const isComplete = isEditAction || stepIndex < activeIndex;

    return isComplete ? StepStatus.Complete : StepStatus.Future;
  }, [activeIndex, isEditAction, stepIndex]);

  return (
    <div className={styles.SettingStepIcon}>{statusIconMap[stepStatus]}</div>
  );
};

export default SettingStepIcon;
