import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import { CreativeChannelSpendAsset } from '../../../../interfaces/creative';

dayjs.extend(utc);

export enum CreativeChannelIdType {
  Desktop = 'desktopChannelId',
  Mobile = 'mobileChannelId',
  Tv = 'tvChannelId',
  Ctv = 'ctvChannelId',
  Newspaper = 'newspaperChannelId',
  Radio = 'radioChannelId',
}

export interface TimeSeriesPoint {
  x: string; // ISO date
  y?: number; // number
}

// Must be a type r/t interface to use enum as a key
export type SpendByChannelId = {
  [key in CreativeChannelIdType]: {
    data: TimeSeriesPoint[];
  };
};

export interface ChannelSpendByTimeperiod {
  labels: string[];
  spendData: (number | null)[];
}

export const getTotalSpendChartDataFromAllChannels = (
  timeSeriesPoints: TimeSeriesPoint[]
): ChannelSpendByTimeperiod => {
  const result: ChannelSpendByTimeperiod = {
    spendData: [],
    labels: [],
  };

  if (!timeSeriesPoints) return result;

  const formattedTimeseriesPoints = timeSeriesPoints.map((point) => {
    return {
      ...point,
      x: dayjs.utc(point.x).local().startOf('day').toISOString(),
    };
  });

  const spendGroupedByDate = groupBy(formattedTimeseriesPoints, 'x');

  const spendSummedByDate = Object.keys(spendGroupedByDate).map((date) => {
    return {
      x: date,
      y: sumBy(spendGroupedByDate[date], 'y'),
    };
  });

  spendSummedByDate.forEach(({ x, y }) => {
    result.spendData.push(y);
    result.labels.push(x);
  });

  return result;
};

export const getChannelSpendByTimeperiod = (
  data?: SpendByChannelId
): ChannelSpendByTimeperiod => {
  if (!data) {
    return { labels: [], spendData: [] };
  }

  const allTimeSeriesPoints = Object.keys(data).reduce((arr, key) => {
    arr.push(...data[key as CreativeChannelIdType].data);
    return arr;
  }, [] as TimeSeriesPoint[]);

  return getTotalSpendChartDataFromAllChannels(allTimeSeriesPoints);
};

export const getSpendFromChannel = (
  timeSeriesPoints: TimeSeriesPoint[]
): ChannelSpendByTimeperiod => {
  if (!timeSeriesPoints?.length) {
    return { labels: [], spendData: [] };
  }

  return getTotalSpendChartDataFromAllChannels(timeSeriesPoints);
};

export const getChannelsDataFromSpendByChannel = (
  spendByChannelId?: SpendByChannelId
) => {
  if (!spendByChannelId) {
    return [];
  }

  return Object.keys(spendByChannelId).map((channelName) => {
    const item = spendByChannelId[
      channelName as CreativeChannelIdType
    ] as CreativeChannelSpendAsset;

    const formattedPoints = item.data.map((point) => {
      return { ...point, x: dayjs.utc(point.x).local().toISOString() };
    });

    return {
      ...item,
      name: channelName,
      diff: Math.round(item.diff),
      data: formattedPoints,
    };
  }) as CreativeChannelSpendAsset[];
};

export const getCreativeAssetSpendFromTimeSeries = (
  points: TimeSeriesPoint[]
): ChannelSpendByTimeperiod => {
  const result: ChannelSpendByTimeperiod = {
    spendData: [],
    labels: [],
  };

  if (!points?.length) {
    return result;
  }

  points.forEach(({ x, y }) => {
    result.spendData.push(y ?? null);
    result.labels.push(x);
  });

  return result;
};
