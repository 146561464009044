import * as React from 'react';

function SvgIconTriangleUp(props) {
  return (
    <svg viewBox="0 0 8 4" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M4 0l4 4H0z" fill="#2B3A45" fillRule="evenodd" />
    </svg>
  );
}

export default SvgIconTriangleUp;
