import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import SelectAccordion from '../../SelectAccordion/SelectAccordion';

import {
  getMetricCategories,
  getMetrics,
  MetricCategory,
} from '../../../../../mocks/data/CustomizableDashboards/metrics';
import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';

import styles from './SelectMetricFlow.module.scss';
import FunctionalAreasHeader from '../../WidgetSettingsShared/FunctionalAreasHeader/FunctionalAreasHeader';
import { FunctionalAreasView, Group, Item, MetricDetail } from '../../types';
import GridAccordion from '../../GridAccordion/GridAccordion';
import { WidgetType } from '../../../../../interfaces/dashboard-api';

const metricsById = keyBy(getMetrics, 'id');

const SelectMetricFlow: FunctionComponent<WidgetConfigProps> = ({
  onConfigChange,
  config,
  onValidationChange,
  widgetType,
}) => {
  const [activeView, setActiveView] = useState<FunctionalAreasView>(
    FunctionalAreasView.Grid
  );
  const [metric, setMetric] = useState<MetricDetail | null>(null);
  const [selectedMetricCategoryId, setSelectedMetricCategoryId] =
    useState<MetricCategory>(MetricCategory.FunctionalAreas);

  const selectedMetric = config[FlowKey.SelectMetric];

  useEffect(() => {
    if (!!selectedMetric.id && !metric) {
      setMetric(selectedMetric);
      onValidationChange({
        [FlowKey.SelectMetric]: false,
      });
      return;
    }

    if (!selectedMetric.id && !!metric) {
      onValidationChange({
        [FlowKey.SelectMetric]: true,
      });
      setMetric(null);
      return;
    }

    if (!!selectedMetric.id && !!metric && selectedMetric.id !== metric.id) {
      setMetric(selectedMetric);
      onValidationChange({
        [FlowKey.SelectMetric]: false,
      });
    }
  }, [metric, onValidationChange, selectedMetric]);

  const handleItemSelect = useCallback(
    (item: Item) => {
      let newSelectedMetric: MetricDetail | null;

      if (item.id === selectedMetric.id) {
        newSelectedMetric = null;
      } else {
        newSelectedMetric = { id: item.id } as MetricDetail;
      }

      if (!newSelectedMetric) {
        return;
      }

      const newConfig = structuredClone(config);

      newConfig[FlowKey.SelectMetric] = {
        id: newSelectedMetric.id,
        name: metricsById[newSelectedMetric.id]?.name,
      };

      onConfigChange(newConfig);
    },
    [selectedMetric.id, config, onConfigChange]
  );

  const groups: Group[] = useMemo(() => {
    const result: Group[] = [];

    const currentMetricCategory = find(getMetricCategories, {
      id: selectedMetricCategoryId,
    });

    if (!currentMetricCategory) return result;

    currentMetricCategory.buckets.forEach((bucket) => {
      const group: Group = {
        id: bucket.id,
        name: bucket.name,
        description: bucket.description,
        items: [],
      };

      bucket.metricIds.forEach((metricId) => {
        // TODO: refine as social channels are added to additional WidgetType GraphQLs
        // initially, only MetricAnalysis supports social channels
        if (
          metricsById[metricId]?.is_social_channel &&
          widgetType !== WidgetType.MetricAnalysisV1
        ) {
          return;
        }

        group.items.push({
          id: metricId,
          name: metricsById[metricId]?.name,
        });
      });

      result.push(group);
    });

    return result;
  }, [selectedMetricCategoryId, widgetType]);

  const handleViewChange = useCallback(
    (view: FunctionalAreasView) => setActiveView(view),
    []
  );

  return (
    <div className={classNames(styles.SelectMetricFlow)}>
      <FunctionalAreasHeader
        activeView={activeView}
        onViewChange={handleViewChange}
        activeMetricCategory={selectedMetricCategoryId}
        metricCategories={getMetricCategories}
        onMetricCategoryChange={setSelectedMetricCategoryId}
      />

      {activeView === FunctionalAreasView.List ? (
        <SelectAccordion
          expandOnDefault={!!metric}
          groups={groups}
          onItemSelect={handleItemSelect}
          selectedItemIds={new Set([selectedMetric.id])}
        />
      ) : (
        <GridAccordion
          selectedMetric={selectedMetric}
          groups={groups}
          onItemSelect={handleItemSelect}
        />
      )}
    </div>
  );
};

export default SelectMetricFlow;
