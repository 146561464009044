import { ClickAwayListener } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import classnames from 'classnames';

import DynamicCaretIcon, {
  CaretDirection,
} from '../../../../assets/icons/DynamicCaret';
import { DashboardDropdownItem } from '../types';

import styles from './TimeRangeDropdown.module.scss';
import { TimeRange } from '../../../../interfaces/dashboard-api';

export interface TimeRangeDropdownProps {
  options: DashboardDropdownItem[];
  activeOption: TimeRange | undefined;
  handleItemClick: (newTimeRange: TimeRange) => void;
}

const TimeRangeDropdown: FC<TimeRangeDropdownProps> = ({
  options,
  activeOption,
  handleItemClick,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOnClick = (option: DashboardDropdownItem) => {
    handleItemClick(option.id as TimeRange);
    setIsMenuOpen(false);
  };

  const dropdownTitle = useMemo(() => {
    const numDays = activeOption
      ? activeOption.split('_').join(' ').toLowerCase()
      : TimeRange.DEFAULT.split('_').join(' ').toLocaleLowerCase();

    return `${numDays} prior`;
  }, [activeOption]);

  return (
    <div className={styles.Select}>
      <div className={styles.MenuLabel} onClick={toggleMenu}>
        <span className={styles.DropdownMenuSelected}>
          <p className={styles.SelectedLabel}>{dropdownTitle}</p>
          <DynamicCaretIcon
            className={styles.CaretIcon}
            direction={CaretDirection.down}
          />
        </span>
      </div>
      {isMenuOpen && (
        <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
          <ul className={styles.DropdownList}>
            {options.map((option) => (
              <button
                key={option.id}
                className={classnames(
                  styles.DropdownItem,
                  activeOption === option.id ? styles.ActiveDropdownItem : ''
                )}
                type="button"
                onClick={() => handleOnClick(option)}
              >
                {option.name}
              </button>
            ))}
          </ul>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default TimeRangeDropdown;
