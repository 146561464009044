import { YAxisFormatter } from '../../../interfaces';

export interface CreateBrandSingleTooltipArgs {
  styles: {
    [key: string]: string;
  };
  tooltipContainerEl: Element | null;
  yAxisFormatter?: YAxisFormatter;
  brandName: string;
  value: number;
}

export default function createBrandSingleTooltipContent({
  styles,
  tooltipContainerEl,
  brandName,
  value,
  yAxisFormatter,
}: CreateBrandSingleTooltipArgs): Element | null {
  const brandEl = document.createElement('div');
  brandEl.classList.add(styles.Brand);

  const brandNameEl = document.createElement('div');
  brandNameEl.classList.add(styles.BrandName);
  const brandNameTextEl = document.createTextNode(brandName);
  brandNameEl.appendChild(brandNameTextEl);

  const brandValueEl = document.createElement('div');
  brandValueEl.classList.add(styles.BrandValue);
  const brandValueTextEl = document.createTextNode(
    String(yAxisFormatter ? yAxisFormatter(value || 0) : value)
  );
  brandValueEl.appendChild(brandValueTextEl);

  brandEl.appendChild(brandNameEl);
  brandEl.appendChild(brandValueEl);

  // Remove old children
  while (tooltipContainerEl?.firstChild) {
    tooltipContainerEl.firstChild.remove();
  }

  // Add new children
  tooltipContainerEl?.appendChild(brandEl);

  return tooltipContainerEl;
}
