import { AnchorHTMLAttributes, useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import some from 'lodash/some';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import cn from 'classnames';
import { Item, Group } from '../types';
import MetricDetailCard from '../MetricDetailCard/MetricDetailCard';
import { getMetrics } from '../../../../mocks/data/CustomizableDashboards/metrics';

import styles from './GridAccordion.module.scss';

export interface GridAccordionProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  groups: Group[];
  selectedMetric?: { id: string; name: string };
  onItemSelect: (metric: Item) => void;
}

const metricsById = keyBy(getMetrics, 'id');

const GridAccordion = ({
  groups,
  selectedMetric,
  onItemSelect,
}: GridAccordionProps) => {
  const [expandMap, setExpandMap] = useState<Record<string, boolean>>({});

  useEffect(() => {
    if (!selectedMetric || !isEmpty(expandMap)) return;

    const expandClone = structuredClone(expandMap);
    groups?.forEach((group) => {
      const isSelected = some(group.items, { id: selectedMetric?.id });
      expandClone[group.id] = isSelected;
    });

    setExpandMap(expandClone);
  }, [expandMap, groups, selectedMetric]);

  const toggleBucketExpansion = useCallback(
    (group: Group) => {
      const expandClone = structuredClone(expandMap);
      expandClone[group.id] = !expandClone[group.id];
      setExpandMap(expandClone);
    },
    [expandMap]
  );

  return (
    <div className={styles.GridAccordion}>
      {groups?.map((group) => (
        <Accordion
          disableGutters
          key={group.id}
          classes={{
            root: cn(
              styles.AccordionGroup,
              expandMap?.[group.id] ? styles.Expanded : ''
            ),
          }}
          expanded={expandMap?.[group.id] ?? false}
        >
          <AccordionSummary classes={{ content: styles.AccordionContent }}>
            <div className={styles.Summary}>
              <button
                type="button"
                className={styles.SummaryName}
                onClick={() => toggleBucketExpansion(group)}
              >
                {group.name}
              </button>
            </div>
          </AccordionSummary>

          <AccordionDetails classes={{ root: styles.AccordionDetails }}>
            <div className={styles.MetricList}>
              {group.items.map((metric) => (
                <MetricDetailCard
                  key={metric.id}
                  metric={metricsById[metric.id]}
                  isSelected={selectedMetric?.id === metric.id}
                  onCardSelect={onItemSelect}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default GridAccordion;
