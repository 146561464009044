import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './AlertIcon.module.scss';

export default function AlertIcon({
  className,
  alertCount,
  menuOpen,
  ...props
}) {
  return (
    <div className={classNames(styles.AlertIcon, className)} {...props}>
      {menuOpen ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.Icon}
        >
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M0 0L24 0 24 24 0 24z"
                      transform="translate(-1192 -16) translate(888) translate(288) translate(16 16)"
                    />
                    <path
                      fill="#20434A"
                      d="M12.123 3c1.231 0 2.297.815 2.638 1.97l.013.05.224.098c2.344 1.077 3.896 3.387 3.997 5.98l.005.26v4.875c0 .966.784 1.75 1.75 1.75l.105.009c.527.093.527.888 0 .982l-.105.009h-5.92c-.236 1.28-1.357 2.25-2.705 2.25s-2.47-.97-2.705-2.25H3.5l-.105-.01c-.56-.098-.525-.99.105-.99l.144-.006c.899-.073 1.606-.826 1.606-1.744v-4.876c0-2.697 1.577-5.126 4-6.239l.222-.097.014-.052c.325-1.1 1.307-1.89 2.463-1.964zm1.68 15.983h-3.355c.215.723.884 1.25 1.677 1.25s1.462-.527 1.677-1.25z"
                      transform="translate(-1192 -16) translate(888) translate(288) translate(16 16)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.Icon}
        >
          <g fill="none" fillRule="evenodd">
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M0 0L24 0 24 24 0 24z"
                        transform="translate(-1192 -16) translate(56) translate(952) translate(168) translate(16 16)"
                      />
                      <path
                        fill="#7E9397"
                        d="M12.123 3c1.231 0 2.297.815 2.638 1.97l.013.05.224.098c2.344 1.077 3.896 3.387 3.997 5.98l.005.26v4.875c0 .966.784 1.75 1.75 1.75l.105.009c.527.093.527.888 0 .982l-.105.009h-5.92c-.236 1.28-1.357 2.25-2.705 2.25s-2.47-.97-2.705-2.25H3.5l-.105-.01c-.56-.098-.525-.99.105-.99l.144-.006c.899-.073 1.606-.826 1.606-1.744v-4.876c0-2.697 1.577-5.126 4-6.239l.222-.097.014-.052c.325-1.1 1.307-1.89 2.463-1.964zm1.68 15.983h-3.355c.215.723.884 1.25 1.677 1.25s1.462-.527 1.677-1.25zM12.122 4c-.857 0-1.589.622-1.727 1.469-.029.177-.15.326-.32.388-2.299.854-3.825 3.048-3.826 5.5v4.876c0 .618-.204 1.19-.55 1.649l-.08.1h13.009l-.08-.1c-.313-.418-.51-.928-.544-1.482L18 16.233v-4.875c-.003-2.453-1.53-4.647-3.83-5.5-.168-.063-.29-.212-.32-.39C13.714 4.623 12.982 4 12.125 4z"
                        transform="translate(-1192 -16) translate(56) translate(952) translate(168) translate(16 16)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )}

      {alertCount > 0 ? (
        <div className={styles.AlertCount}>{alertCount}</div>
      ) : null}
    </div>
  );
}

AlertIcon.propTypes = {
  className: PropTypes.string,
  alertCount: PropTypes.number,
  menuOpen: PropTypes.bool,
};
