const hasMissingData = (givenData: Array<{ x: string; y?: number }>) =>
  givenData.some((points) => points.y === undefined);

const getInverseData = (givenData: Array<{ x: string; y?: number }>) => {
  if (!hasMissingData(givenData)) {
    return [];
  }

  const includeYZero = (index: number): boolean =>
    givenData[index].y === undefined ||
    (index - 1 > 0 && givenData[index - 1].y === undefined) ||
    (index + 1 < givenData.length - 1 && givenData[index + 1].y === undefined);

  return givenData.map((point, index) => {
    if (includeYZero(index)) {
      return { x: point.x, y: 0 };
    }

    return { x: point.x };
  });
};

export { hasMissingData, getInverseData };
