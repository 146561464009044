import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import find from 'lodash/find';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Tooltip from '../../Atoms/Tooltip/Tooltip';
import SectionHeaderBar from '../../Atoms/SectionHeaderBar/SectionHeaderBar';
import PageTitle from '../../Molecules/PageTitle/PageTitle';

import { POSITIONS } from '../../../constants/props';

import useRouter from '../../../hooks/useRouter';

import styles from './PageSubnav.module.scss';

export default function PageSubnav({
  path,
  title,
  tabs,
  shareable,
  beta = false,
  tabRightRender,
}) {
  const location = useLocation();
  const { updateRoute } = useRouter();

  const selectedTab = useMemo(() => {
    const currentTab = find(tabs, (tab) => {
      return location.pathname.includes(tab.value);
    });

    if (!currentTab) {
      return '';
    }

    return currentTab.value;
  }, [location.pathname, tabs]);

  function tabChangeHandler(event, newPage) {
    const tab = find(tabs, { value: newPage });

    if (tab.disabled) {
      return;
    }

    if (tab.updateRouteOverride) {
      tab.updateRouteOverride();
      return;
    }

    updateRoute({ pathname: `/${path}/${newPage}` });
  }

  return (
    <SectionHeaderBar position="static">
      <div className="WorkspaceTitleContainer">
        <PageTitle
          title={title}
          targetId="page-wrapper"
          padded
          fullscreen
          shareable={shareable}
          beta={beta}
        />
      </div>

      <div className={styles.TabContainer}>
        <Tabs
          data-cy="page-subnav-tabs"
          value={selectedTab}
          onChange={tabChangeHandler}
          variant="scrollable"
          classes={{
            indicator: styles.Indicator,
          }}
        >
          {tabs.map((tab) => {
            if (!tab.tooltip) {
              return (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  disableFocusRipple
                  disableRipple
                  component="div"
                  disabled={tab.disabled}
                  classes={{
                    root: styles.Tab,
                    disabled: styles.Disabled,
                  }}
                />
              );
            }

            return (
              <Tooltip
                key={tab.value}
                title={tab.tooltip}
                arrow
                placement={POSITIONS.BOTTOM_START}
              >
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  disableFocusRipple
                  disableRipple
                  component="div"
                  disabled={tab.disabled}
                  classes={{
                    root: styles.Tab,
                    disabled: styles.Disabled,
                  }}
                />
              </Tooltip>
            );
          })}
        </Tabs>
        {tabRightRender && tabRightRender()}
      </div>
    </SectionHeaderBar>
  );
}

PageSubnav.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
      updateRouteOverride: PropTypes.func,
    })
  ),
  shareable: PropTypes.bool,
  beta: PropTypes.bool,
  tabRightRender: PropTypes.func,
};
