import { GenericIconProps } from './types';

const InfoCircleIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M0 0L24 0 24 24 0 24z"
            transform="translate(-432 -152) translate(432 152)"
          />
          <path
            fill="#7E9AF7"
            d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-2.652-1.054-5.196-2.929-7.071C17.196 3.054 14.652 2 12 2z"
            transform="translate(-432 -152) translate(432 152)"
          />
          <path
            fill="#FFF"
            d="M12 10c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1s-1-.448-1-1v-5c0-.552.448-1 1-1zm0-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
            transform="translate(-432 -152) translate(432 152)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default InfoCircleIcon;
