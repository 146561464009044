import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import find from 'lodash/find';
import { BrandLogo } from '@blueoceanai/react-component-library';

import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import Dropdown from '../../Molecules/Dropdown/Dropdown';
import ScoreTile, {
  ScoreTileSize,
} from '../../ScoreDisplays/ScoreTile/ScoreTile';
import ChartLegend from '../../Molecules/ChartLegend/ChartLegend';
import ScoreLegend from '../../Molecules/ScoreLegend/ScoreLegend';
import BluescoreTimelineChart from '../../Charts/BluescoreTimelineChart/BluescoreTimelineChart';
import SubfactorLineItem from '../../Molecules/SubfactorLineItem/SubfactorLineItem';
import SubfactorCard from '../../Molecules/SubfactorCard/SubfactorCard';

import {
  getIndexRatingFromScore,
  indexedDisplayTextByRating,
} from '../../../utils/score';
import { FACTOR_TYPES, FACTOR_TYPE_KEYS } from '../../../constants/factors';
import {
  COMPARE_TYPES,
  CHART_LEGEND_ICON_VARIANT,
} from '../../../constants/props';

import styles from './CompareBrandInfo.module.scss';

function CompareBrandInfo({
  compareType,
  customerBrand = false,
  brandInfo,
  subsetInfo,
  brandsForDDL,
  selectedBrandKey,
  onCompetitorClick,
  graphScaleMap = {},
  loadingMain,
  loadingSubset,
  onCardLabelClick,
  onCardExploreClick,
}) {
  const brandOptions = useMemo(() => {
    const result = [];

    if (!brandsForDDL) {
      return result;
    }

    brandsForDDL.forEach((brand) => {
      result.push({
        label: brand.name,
        value: brand.brandKey,
        imgUrl: brand.logoUrl,
      });
    });

    return result;
  }, [brandsForDDL]);

  const selectedBrandOption = useMemo(
    () => find(brandOptions, { value: selectedBrandKey }) || {},
    [brandOptions, selectedBrandKey]
  );

  const scoreRatingDisplayText = useMemo(() => {
    const currentIndexedRating = getIndexRatingFromScore(brandInfo?.score);

    return indexedDisplayTextByRating[currentIndexedRating];
  }, [brandInfo]);

  function handleBrandChange(option) {
    onCompetitorClick(option.value);
  }

  return (
    <Grid container className={styles.CompareBrandInfo}>
      <Grid item container xs={12}>
        <Grid item xs={4}>
          <Box mb={4} data-testid="brand-title-container">
            {customerBrand && brandInfo ? (
              <Box>
                <BrandLogo
                  className={styles.CustomerBrandLogo}
                  name={brandInfo.name}
                  src={brandInfo.logoUrl}
                  bold
                />
              </Box>
            ) : null}

            {!customerBrand &&
            brandOptions &&
            selectedBrandOption &&
            selectedBrandOption.value ? (
              <Dropdown
                options={brandOptions}
                onChange={handleBrandChange}
                showListIcons
                value={selectedBrandOption.value}
                buttonProps={{ 'data-cy': 'competitor-brand-dropdown-button' }}
                listItemProps={{ 'data-cy': 'competitor-brand-dropdown-item' }}
              />
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={8} />
      </Grid>

      <Grid item container xs={12}>
        {!loadingMain ? (
          <>
            <Grid item xs={12}>
              <Box
                mb={6}
                className={styles.ScoreTileContainer}
                data-testid="major-score-tile-container"
              >
                <ScoreTile
                  value={brandInfo.score}
                  size={ScoreTileSize.Large}
                  delta={brandInfo.delta}
                  disableAnimation={process.env.REACT_APP_ENV === 'local-mock'}
                />

                <div className={styles.ScoreRatingDisplayText}>
                  {scoreRatingDisplayText}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} data-testid="major-chart-container">
              <div className={styles.GraphWrap}>
                <BluescoreTimelineChart
                  yAxisBuffer
                  showTooltips
                  customerScoresOverTime={brandInfo.scoreOverTime}
                  yDomainOverride={
                    graphScaleMap[FACTOR_TYPE_KEYS.OVERALL]
                      ? graphScaleMap[FACTOR_TYPE_KEYS.OVERALL].yDomain
                      : null
                  }
                  data-cy={`${
                    customerBrand ? 'hero' : 'competitor'
                  }-main-timeline-graph`}
                />
              </div>

              <Box mb={6} className={styles.GraphInfo}>
                <Box mb={2}>
                  {customerBrand ? (
                    <ChartLegend
                      className={styles.GraphMarkerLegend}
                      items={[
                        {
                          variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                          label: brandInfo.name,
                        },
                        {
                          variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
                          label: 'Competitor Average',
                        },
                      ]}
                    />
                  ) : (
                    <ChartLegend
                      className={styles.GraphMarkerLegend}
                      items={[
                        {
                          variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                          label: selectedBrandOption
                            ? selectedBrandOption.label
                            : '',
                        },
                        {
                          variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
                          label: 'Competitor Average',
                        },
                      ]}
                    />
                  )}
                </Box>
                <ScoreLegend />
              </Box>
            </Grid>
          </>
        ) : (
          <ComponentLoader minHeight={445} />
        )}
      </Grid>

      {!loadingSubset ? (
        <Grid item container xs={12} data-testid="minor-charts-container">
          {compareType === COMPARE_TYPES.BLUESCORE ? (
            <Box className={styles.CompareFactorsContainer} pr={7}>
              <ul className={styles.SubfactorLineList}>
                {subsetInfo.map((factor) => {
                  if (factor.subfactor !== FACTOR_TYPES[0].NAME) {
                    return (
                      <li
                        key={`${factor.factorType}-${factor.score}`}
                        className={styles.SubfactorLineListItem}
                      >
                        <SubfactorLineItem
                          highlightLabel
                          className={styles.LineItem}
                          score={factor.score}
                          subfactor={factor.factorType}
                          scoreTimelineData={factor.scoreOverTime}
                          competitorAverageScore={factor.competitorAverageScore}
                          domainOverride={
                            graphScaleMap[factor.factorType.toLowerCase()]
                          }
                          onLabelClick={() => onCardLabelClick(factor)}
                          data-cy={`${
                            customerBrand ? 'hero' : 'competitor'
                          }-factor-line-item-${factor.factorType.toLowerCase()}`}
                          data-testid="line-item-container"
                        />
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </Box>
          ) : null}

          {compareType === COMPARE_TYPES.FACTOR ? (
            <Box className={styles.CompareCardsContainer} pr={7}>
              <Box mb={0}>
                <h5 className={styles.SubsectionTitle}>Subfactors</h5>
              </Box>
              <ul className={styles.SubfactorLineList}>
                {subsetInfo.map((factor) => (
                  <li
                    key={`${factor.factorType}-${factor.score}`}
                    className={styles.SubfactorLineListItem}
                  >
                    <SubfactorLineItem
                      highlightLabel
                      className={styles.LineItem}
                      score={factor.score}
                      subfactor={factor.factorType}
                      scoreTimelineData={factor.scoreOverTime}
                      competitorAverageScore={factor.competitorAverageScore}
                      domainOverride={
                        graphScaleMap[factor.factorType.toLowerCase()]
                      }
                      onLabelClick={() => onCardLabelClick(factor)}
                      data-cy={`${
                        customerBrand ? 'hero' : 'competitor'
                      }-subfactor-line-item-${factor.factorType.toLowerCase()}`}
                      data-testid="line-item-container"
                    />
                  </li>
                ))}
              </ul>
            </Box>
          ) : null}

          {compareType === COMPARE_TYPES.SUBFACTOR ? (
            <Box className={styles.CompareCardsContainer} pr={7}>
              <Box mb={0}>
                <h5 className={styles.SubsectionTitle}>Top Drivers</h5>
              </Box>

              {subsetInfo.map((subfactorCardItem, index) => {
                if (!subfactorCardItem) {
                  return null;
                }
                return (
                  <Grid item xs={12} key={subfactorCardItem.factorType}>
                    <Box mb={index < subsetInfo.length - 1 ? 4 : 0}>
                      <SubfactorCard
                        title={subfactorCardItem.factorType}
                        metricId={subfactorCardItem.variableId}
                        chosenBrandKey={selectedBrandKey}
                        score={subfactorCardItem.score}
                        isCurrency={subfactorCardItem.isCurrency}
                        scoreOverTime={subfactorCardItem.scoreOverTime}
                        competitorAverageScore={
                          subfactorCardItem.competitorAverageScore
                        }
                        domainOverride={
                          graphScaleMap[
                            subfactorCardItem.factorType.toLowerCase()
                          ]
                        }
                        insight={[subfactorCardItem.description]}
                        rating={
                          typeof subfactorCardItem.rating === 'number'
                            ? subfactorCardItem.rating
                            : 0
                        }
                        onExploreClick={({ scoreType }) =>
                          onCardExploreClick({
                            scoreType,
                            metric: subfactorCardItem,
                          })
                        }
                        data-testid="line-item-container"
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Box>
          ) : null}
        </Grid>
      ) : (
        <ComponentLoader minHeight={445} />
      )}
    </Grid>
  );
}

CompareBrandInfo.propTypes = {
  /**
   * Type of comparison (factor, subfactor, or driver). Changes the data in and below the graph.
   */
  compareType: PropTypes.string.isRequired,
  /**
   * Denotes whether this section of information is customer brand information or not.
   */
  customerBrand: PropTypes.bool,
  brandInfo: PropTypes.shape({
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    factorType: PropTypes.string,
    score: PropTypes.number,
    delta: PropTypes.number,
    competitorAverageScore: PropTypes.number,
    scoreOverTime: PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number,
        date: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  graphScaleMap: PropTypes.shape({
    [FACTOR_TYPE_KEYS.OVERALL]: PropTypes.shape({
      yDomain: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
    [FACTOR_TYPE_KEYS.FAMILIAR]: PropTypes.shape({
      yDomain: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
    [FACTOR_TYPE_KEYS.UNIQUE]: PropTypes.shape({
      yDomain: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
    [FACTOR_TYPE_KEYS.CONSISTENT]: PropTypes.shape({
      yDomain: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
    [FACTOR_TYPE_KEYS.RELEVANT]: PropTypes.shape({
      yDomain: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
    [FACTOR_TYPE_KEYS.REVERED]: PropTypes.shape({
      yDomain: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    }),
  }),
  subsetInfo: PropTypes.arrayOf(
    PropTypes.shape({
      factorType: PropTypes.string,
    })
  ),
  brandsForDDL: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      logoUrl: PropTypes.string,
    })
  ),
  onCompetitorClick: PropTypes.func,
  loadingMain: PropTypes.bool,
  loadingSubset: PropTypes.bool,
  selectedBrandKey: PropTypes.string,
  onCardLabelClick: PropTypes.func,
  onCardExploreClick: PropTypes.func,
};

export default CompareBrandInfo;
