import { GenericIconProps } from '../types';

const CopyIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Icon/Copy">
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.001V8.001H6.5C5.67157 8.001 5 8.67257 5 9.501V18.501C5 19.3294 5.67157 20.001 6.5 20.001H13.5C14.3284 20.001 15 19.3294 15 18.501V17.001H16V18.501C16 19.8817 14.8807 21.001 13.5 21.001H6.5C5.11929 21.001 4 19.8817 4 18.501V9.501C4 8.12029 5.11929 7.001 6.5 7.001H8ZM16 4.001H10.5C9.67157 4.001 9 4.67258 9 5.501V14.501C9 15.3294 9.67157 16.001 10.5 16.001H17.5C18.3284 16.001 19 15.3294 19 14.501V7.001H16.5C16.2239 7.001 16 6.77714 16 6.501V4.001ZM20 6.52898V14.501C20 15.8817 18.8807 17.001 17.5 17.001H10.5C9.11929 17.001 8 15.8817 8 14.501V5.501C8 4.12029 9.11929 3.001 10.5 3.001H16.472C16.6048 2.99258 16.7429 3.03684 16.8536 3.14745L19.8536 6.14745C19.9642 6.25806 20.0084 6.39623 20 6.52898ZM17 6.001H18.2929L17 4.70811V6.001ZM11.5 13.001C11.2239 13.001 11 12.7771 11 12.501C11 12.2249 11.2239 12.001 11.5 12.001H13.5C13.7761 12.001 14 12.2249 14 12.501C14 12.7771 13.7761 13.001 13.5 13.001H11.5ZM11.5 11.001C11.2239 11.001 11 10.7771 11 10.501C11 10.2249 11.2239 10.001 11.5 10.001H16.5C16.7761 10.001 17 10.2249 17 10.501C17 10.7771 16.7761 11.001 16.5 11.001H11.5ZM11.5 9.001C11.2239 9.001 11 8.77714 11 8.501C11 8.22486 11.2239 8.001 11.5 8.001H16.5C16.7761 8.001 17 8.22486 17 8.501C17 8.77714 16.7761 9.001 16.5 9.001H11.5Z"
        fill="#7E9397"
      />
    </g>
  </svg>
);

export default CopyIcon;
