import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import Box from '@mui/material/Box';

import Recommendation from '../../Molecules/Recommendation/Recommendation';

import styles from './RecommendationsList.module.scss';

export default function RecommendationsList({
  className,
  impactResultsFilters,
  tagFilters,
  lifecycleFilters,
  recommendations,
  data,
  editRecommendation,
  ...props
}) {
  const [recs, setRecommendations] = useState(null);

  const [filteredRecommendations, setFilteredRecommendations] = useState(null);

  const [showImpactResults, setShowImpactResults] = useState({});

  const [showTags, setShowTags] = useState({});

  const [showLifecycle, setShowLifecycle] = useState({});

  useEffect(() => {
    setRecommendations(
      recommendations || data.competitiveSet.session.recommendations
    );
  }, [data, recommendations]);

  useEffect(() => {
    const result = {};

    if (tagFilters && tagFilters.length) {
      tagFilters.forEach((tagId) => {
        if (!isNil(tagId)) {
          result[tagId] = true;
        }
      });
    }

    setShowTags(result);
  }, [tagFilters]);

  useEffect(() => {
    const result = {};

    if (impactResultsFilters && impactResultsFilters.length) {
      impactResultsFilters.forEach((impactResultsKey) => {
        if (!isNil(impactResultsKey)) {
          result[impactResultsKey] = true;
        }
      });
    }

    setShowImpactResults(result);
  }, [impactResultsFilters]);

  useEffect(() => {
    const result = {};

    if (lifecycleFilters && lifecycleFilters.length) {
      lifecycleFilters.forEach((lifecycleKey) => {
        if (!isNil(lifecycleKey)) {
          result[lifecycleKey] = true;
        }
      });
    }

    setShowLifecycle(result);
  }, [lifecycleFilters]);

  useEffect(() => {
    const result = [];

    if (recs !== null) {
      recs.forEach((recommendation) => {
        let addRecommendation = false;

        if (isEmpty(showTags) && isEmpty(showImpactResults)) {
          addRecommendation = true;
        }

        if (
          isEmpty(showImpactResults) &&
          !isEmpty(showTags) &&
          recommendation.practice_areas !== null
        ) {
          recommendation.practice_areas
            .split(', ')
            // eslint-disable-next-line no-return-assign
            .map((tag) => (showTags[tag] ? (addRecommendation = true) : null));
        }

        if (showImpactResults[recommendation.impact_id] && isEmpty(showTags)) {
          addRecommendation = true;
        }

        if (
          showImpactResults[recommendation.impact_id] &&
          !isEmpty(showTags) &&
          recommendation.practice_areas !== null
        ) {
          addRecommendation = false;
          recommendation.practice_areas
            .split(', ')
            // eslint-disable-next-line no-return-assign
            .map((tag) =>
              showImpactResults[recommendation.impact_id] && showTags[tag]
                ? (addRecommendation = true)
                : null
            );
        }

        if (
          !isEmpty(showLifecycle) &&
          !showLifecycle[recommendation.isComplete]
        ) {
          addRecommendation = false;
        }

        if (addRecommendation) {
          result.push(recommendation);
        }
      });
    }

    setFilteredRecommendations(result);
  }, [recs, showImpactResults, showTags, showLifecycle]);

  function onMarkComplete(id) {
    const recommendationsCopy = cloneDeep(recs);
    const changedIndex = recommendationsCopy.findIndex((rec) => rec.id === id);
    recommendationsCopy[changedIndex].isComplete = 1;

    setRecommendations(recommendationsCopy);
  }

  function onDelete(id) {
    const recommendationsCopy = cloneDeep(recs);
    const removedIndex = recommendationsCopy.findIndex((rec) => rec.id === id);
    recommendationsCopy.splice(removedIndex, 1);

    setRecommendations(recommendationsCopy);
  }

  return (
    <Box
      className={classNames(styles.RecommendationsList, className)}
      {...props}
    >
      {filteredRecommendations && filteredRecommendations.length > 0 ? (
        <Box>
          {filteredRecommendations.map((recommendation) => (
            <Box
              pl={4}
              pb={3}
              pt={5}
              className={styles.Recommendation}
              key={recommendation.id}
            >
              <Recommendation
                id={recommendation.id}
                impactId={recommendation.impact_id}
                isComplete={recommendation.isComplete}
                name={recommendation.name}
                practiceAreas={recommendation.practice_areas}
                value={recommendation.value}
                sourceTypeId={recommendation.sourceType_id}
                onMarkComplete={onMarkComplete}
                editRecommendation={editRecommendation}
                createdDate={recommendation.createdDate}
                type={recommendation.type}
                associatedTypeId={recommendation.associatedType_id}
                isPrivate={recommendation.isPrivate}
                onDelete={onDelete}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box p={5} className={styles.NoRecommendations}>
          <span>You have no recommendations</span>
        </Box>
      )}
    </Box>
  );
}

RecommendationsList.propTypes = {
  className: PropTypes.string,
  /**
   * ImpactResult key from constants/recommendations.js
   */
  impactResultsFilters: PropTypes.arrayOf(PropTypes.string),
  /**
   * Tag id from constants/recommendations.js
   */
  tagFilters: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /**
   * Lifecycle keys from constants/recommendations.js
   */
  lifecycleFilters: PropTypes.arrayOf(PropTypes.number),
  recommendations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      impact_id: PropTypes.number,
      isComplete: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.number,
      practice_areas: PropTypes.string,
      sourceType_id: PropTypes.number,
      value: PropTypes.string,
    })
  ),
  data: PropTypes.shape({
    competitiveSet: PropTypes.shape({
      session: PropTypes.shape({
        recommendations: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            impact_id: PropTypes.number,
            isComplete: PropTypes.number,
            type: PropTypes.number,
            name: PropTypes.string,
            practice_areas: PropTypes.string,
            sourceType_id: PropTypes.number,
            value: PropTypes.string,
          })
        ),
      }),
    }),
  }),
  editRecommendation: PropTypes.func,
};
