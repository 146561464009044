import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DownloadIcon from '../../../assets/icons/IconDownload.jsx';

import styles from './PastReportsArchiveListItem.module.scss';

export default function PastReportsArchiveListItem({
  className,
  reportName,
  isRead = false,
  isHovered = false,
  ...props
}) {
  return (
    <div
      className={classNames(
        styles.PastReportsArchiveListItem,
        isHovered ? styles.Hovered : null,
        className
      )}
      {...props}
    >
      <div
        className={classNames(styles.ReportDate, isRead ? styles.Read : null)}
      >
        {reportName}
      </div>

      <DownloadIcon className={styles.DownloadIcon} />
    </div>
  );
}

PastReportsArchiveListItem.propTypes = {
  className: PropTypes.string,
  reportName: PropTypes.string,
  isRead: PropTypes.bool,
  isHovered: PropTypes.bool,
};
