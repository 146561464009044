import classNames from 'classnames';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import MetricCard from '../../Molecules/MetricCard/MetricCard';

import styles from './TopMetrics.module.scss';

export default function TopMetrics({
  metrics,
  onTitleClick,
  className,
  ...props
}) {
  return (
    <Box className={classNames(styles.TopMetrics, className)} {...props}>
      <Box mb={7}>
        <h3 className={styles.Title}>Top Metrics</h3>
      </Box>
      <Grid container spacing={4}>
        {metrics.map((metric) => (
          <Grid key={metric.id} item xs={12} lg={6}>
            <MetricCard
              formatType={metric.formatType}
              unit={metric.unit}
              title={metric.name}
              brand={metric.brand}
              average={metric.average}
              onTitleClick={onTitleClick}
              dateUpdated={metric.dateUpdated}
              metricId={metric.variableId}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

TopMetrics.propTypes = {
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      brand: PropTypes.shape({
        logoUrl: PropTypes.string,
        variableId: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        score: PropTypes.number,
        magnitude: PropTypes.number,
        scoresOverTime: PropTypes.arrayOf(
          PropTypes.shape({
            score: PropTypes.number,
            date: PropTypes.string,
          })
        ),
      }),
      average: PropTypes.shape({
        score: PropTypes.number,
        magnitude: PropTypes.number,
      }),
      dateUpdated: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  onTitleClick: PropTypes.func,
};
