import { Box } from '@mui/material';
import styles from './PaceWidgetTrendingList.module.scss';
import { TimeRange } from '../../../../../../interfaces/dashboard-api';
import PaceWidgetTrendingItem from '../PaceWidgetTrendingItem/PaceWidgetTrendingItem';
import { PaceAnalysisBrand } from '../../../types';
import { DashboardsMetricScore } from '../../../../../../interfaces/widget-settings';

export interface PaceWidgetTrendingListProps {
  brandsData: PaceAnalysisBrand[];
  activeTimeRange: TimeRange;
  activeScoreType: DashboardsMetricScore;
}

const PaceWidgetTrendingList = ({
  brandsData,
  activeTimeRange,
  activeScoreType,
}: PaceWidgetTrendingListProps) => (
  <Box className={styles.CardContainer}>
    {brandsData.map((brand) => (
      <PaceWidgetTrendingItem
        key={brand.brand.key}
        activeTimeRange={activeTimeRange}
        activeScoreType={activeScoreType}
        brandData={brand}
      />
    ))}
  </Box>
);

export default PaceWidgetTrendingList;
