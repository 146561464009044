import React, { useState, useMemo } from 'react';
import html2canvas from 'html2canvas';
import { parse } from 'json2csv';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';

import DownloadMenuItem from '../../Atoms/DownloadMenuItem/DownloadMenuItem';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';

import CSVLogo from '../../../assets/logos/Csv.jsx';
import PNGLogo from '../../../assets/logos/Image.jsx';
import PowerPointLogo from '../../../assets/logos/Powerpoint.jsx';
import PDFLogo from '../../../assets/logos/Pdf.jsx';
import SVGLogo from '../../../assets/logos/Svg.jsx';

import { convertFromPNG } from '../../../api/standard/tidepool/share';

import { DOWNLOAD_TYPES } from '../../../constants/props';
import { downloadFromUrl } from '../../../utils/downloadFile';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

import styles from './DownloadDropdownMenu.module.scss';
import { CloseIcon } from '../../../assets/icons';

function DownloadDropdownMenu({
  className,
  targetId,
  fileName = 'blueocean',
  json,
  padding = 0,
  onClose,
  onSuccess,
}) {
  const FEATURE_FLAGS = useFeatureFlags();
  const [loading, setLoading] = useState(false);

  const menuItems = useMemo(() => {
    const result = [];

    if (FEATURE_FLAGS.GLOBAL.DOWNLOAD.CSV) {
      result.push({
        logo: CSVLogo,
        name: 'CSV',
        type: DOWNLOAD_TYPES.CSV,
      });
    }

    if (FEATURE_FLAGS.GLOBAL.DOWNLOAD.PDF) {
      result.push({
        logo: PDFLogo,
        name: 'PDF',
        type: DOWNLOAD_TYPES.PDF,
      });
    }

    if (FEATURE_FLAGS.GLOBAL.DOWNLOAD.PNG) {
      result.push({
        logo: PNGLogo,
        name: 'PNG',
        type: DOWNLOAD_TYPES.PNG,
      });
    }

    if (FEATURE_FLAGS.GLOBAL.DOWNLOAD.SVG) {
      result.push({
        logo: SVGLogo,
        name: 'SVG',
        type: DOWNLOAD_TYPES.SVG,
      });
    }

    if (FEATURE_FLAGS.GLOBAL.DOWNLOAD.PPT) {
      result.push({
        logo: PowerPointLogo,
        name: 'PowerPoint',
        type: DOWNLOAD_TYPES.PPT,
      });
    }

    return result;
  }, [FEATURE_FLAGS]);

  async function handleDownloadTypeClick(menuItem) {
    setLoading(true);

    let convertToFormat = null;

    switch (menuItem.type) {
      case DOWNLOAD_TYPES.CSV:
        convertToFormat = 'csv';
        break;
      case DOWNLOAD_TYPES.PDF:
        convertToFormat = 'pdf';
        break;
      case DOWNLOAD_TYPES.PNG:
        convertToFormat = 'png';
        break;
      case DOWNLOAD_TYPES.SVG:
        convertToFormat = 'svg';
        break;
      case DOWNLOAD_TYPES.PPT:
        convertToFormat = 'pptx';
        break;
      default:
        // eslint-disable-next-line
        console.error(
          'DownloadDropdownMenu: Development Error - menu item not associated to download type.'
        );
        break;
    }

    const uniqueFileName = `${fileName}_${dayjs().valueOf()}.${convertToFormat}`;

    if (convertToFormat === 'csv') {
      const csv = parse(json);
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);

      downloadFromUrl(url, uniqueFileName);
      onSuccess();
    }

    if (
      convertToFormat === 'pdf' ||
      convertToFormat === 'png' ||
      convertToFormat === 'svg' ||
      convertToFormat === 'pptx'
    ) {
      try {
        const targetNode = document.querySelector(`#${targetId}`);

        if (!targetNode) {
          return;
        }

        const originalPadding = targetNode.style.padding;
        const originalWidth = targetNode.style.width;
        const originalPosition = targetNode.style.position;

        targetNode.classList.add('screenshotting');
        targetNode.classList.add('screenshotting-export');
        targetNode.style.setProperty('padding', `${padding}px`, 'important');
        targetNode.style.width = `${targetNode.clientWidth + 2 * padding}px`;
        targetNode.style.position = 'absolute';

        const canvas = await html2canvas(targetNode, {
          allowTaint: false,
          useCORS: true,
          logging: false,
        });

        const imgDataUrl = canvas.toDataURL('image/png', 1.0);

        targetNode.classList.remove('screenshotting');
        targetNode.classList.remove('screenshotting-export');

        targetNode.style.padding = originalPadding
          ? `${originalPadding}px`
          : null;
        targetNode.style.width = originalWidth ? `${originalWidth}px` : null;
        targetNode.style.position = originalPosition;

        const resp = await convertFromPNG(imgDataUrl, convertToFormat);

        if (!resp || !resp.data || !resp.data.signed_url) {
          throw new Error('No asset url from tidepool');
        }

        const assetUrl = resp.data.signed_url;
        downloadFromUrl(assetUrl, uniqueFileName);
        onSuccess?.();
      } catch (e) {
        alert('Download failed. Please try again or call customer support.');

        // eslint-disable-next-line
        console.error(
          `Error: DownloadDropdownMenu - conversion failed from png to ${convertToFormat}`,
          e
        );
      }
    }

    setLoading(false);
  }

  return (
    <Box
      p={4}
      className={classNames(styles.DownloadDropdownMenu, className)}
      data-testid="download-dropdown-menu"
    >
      <Box className={styles.Header} mb={5}>
        <h1 className={styles.Title}>Download</h1>
        <CloseIcon className={styles.CloseIcon} onClick={() => onClose()} />
      </Box>

      {loading ? <ComponentLoader minHeight={144} /> : null}

      {!loading ? (
        <Grid container spacing={2}>
          {menuItems.map((item) => (
            <Grid item xs={6} key={item.type}>
              <DownloadMenuItem
                onClick={() => handleDownloadTypeClick(item)}
                LogoSVG={item.logo}
                name={item.name}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Box>
  );
}

export default DownloadDropdownMenu;

DownloadDropdownMenu.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  targetId: PropTypes.string,
  json: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  padding: PropTypes.number,
  fileName: PropTypes.string,
};
