import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import { isBetween } from '../../../utils/timeframe';

export function prepareScores(data) {
  if (
    !data?.competitiveSet?.session?.factors ||
    !data?.competitiveSet?.session?.bluescore
  ) {
    return;
  }

  const result = {
    bluescore: [],
    familiar: [],
    unique: [],
    consistent: [],
    relevant: [],
    revered: [],
  };

  const { bluescore } = data.competitiveSet.session;

  bluescore.forEach((timeframe) => {
    const t4Quarters = JSON.parse(timeframe.t4Quarters);

    t4Quarters.forEach((score) => {
      result.bluescore.push({
        date: score.date,
        value: Math.round(score.value),
      });
    });
  });

  const { factors } = data.competitiveSet.session;

  factors.forEach((timeframe) => {
    const t4Quarters = JSON.parse(timeframe.t4Quarters);

    t4Quarters.forEach((score) => {
      result[score.name.toLowerCase()].push({
        date: score.date,
        value: Math.round(score.value),
      });
    });
  });

  return result;
}

export function prepareCompetitorBluescores(data, timeframe) {
  if (
    !data?.competitiveSet?.session?.competitors ||
    !data?.competitiveSet?.session?.CompetitorBlueScore
  ) {
    return;
  }

  const competitorBluescores =
    data?.competitiveSet?.session?.CompetitorBlueScore.map((competitor) => {
      const scoresRaw = JSON.parse(competitor.t4Quarters);
      const scores = scoresRaw
        .map((score) => {
          return {
            value: score.value,
            date: score.date,
          };
        })
        .filter((score) => {
          return isBetween(score.date, timeframe);
        });

      return {
        brandId: competitor.brand_id,
        brandKey: competitor.brandKey,
        scores,
      };
    });

  const competitorScoresByBrandId = keyBy(competitorBluescores, 'brandId');

  return data.competitiveSet.session.competitors.map((competitor) => {
    const competitorScores = competitorScoresByBrandId?.[competitor.id];

    if (!competitorScores) {
      throw new Error(
        `Market Index Overview - Relative Performance: no scores for found competitor brand id ${competitor.id}`
      );
    }

    return {
      score: last(competitorScores.scores)
        ? Math.round(last(competitorScores.scores).value)
        : null,
      name: competitor.name,
      logoUrl: competitor.logoUrl,
    };
  });
}
