import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useRouter from '../../../../hooks/useRouter';
import useDashboardAPI from '../../../../hooks/useDashboardAPI';
import DashboardListItem from '../DashboardListItem/DashboardListItem';
import TemplateSelectorContainer from '../TemplateSelector/TemplateSelectorContainer';
import { ShareableDashboard } from '../types';
import { FeatureFlag } from '../../../../utils/featureFlags';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../../assets/icons/DynamicCaret';
import styles from './DashboardsManagement.module.scss';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import IconInfoCircleSolid from '../../../../assets/icons/IconInfoCircleSolid';

const DashboardsManagement = () => {
  const { getSortedDashboards, createDashboard, getRecievedDashboards } =
    useDashboardAPI();
  const flags = useFlags();
  const { updateRoute } = useRouter();

  const [myDashboards, setMyDashboards] = useState<ShareableDashboard[]>([]);
  const [sharedDashboards, setSharedDashboards] =
    useState<ShareableDashboard[]>();
  const [dashboardsCount, setDashboardsCount] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [triggerRequery, setTriggerRequery] = useState<boolean>(false);
  const [createDashboardView, setCreateDashboardView] =
    useState<boolean>(false);

  const [sharedDashboardsLoading, setSharedDashboardsLoading] =
    useState<boolean>(false);

  const canCreateDashboardTemplates = flags[FeatureFlag.CreateWidgetTemplates];

  useEffect(() => {
    if (sharedDashboards !== undefined && !triggerRequery) return;
    setSharedDashboardsLoading(true);

    getRecievedDashboards().then((resp) => {
      if (resp && resp.dashboards) {
        setSharedDashboards(resp.dashboards);
      }
      setSharedDashboardsLoading(false);
    });
  }, [getRecievedDashboards, sharedDashboards, triggerRequery]);

  useEffect(() => {
    if (dashboardsCount !== undefined && !triggerRequery) return;

    setIsLoading(true);
    getSortedDashboards().then((resp) => {
      if (resp) {
        setMyDashboards(resp.myDashboards);
        setDashboardsCount(resp.count);
        setIsLoading(false);
        setTriggerRequery(false);
      }
    });
  }, [dashboardsCount, triggerRequery, getSortedDashboards, myDashboards]);

  const handleNewDashboard = async () => {
    if (canCreateDashboardTemplates) {
      setCreateDashboardView(true);
      return;
    }

    try {
      const newDashboard = await createDashboard();
      if (newDashboard) {
        updateRoute({
          pathname: `/dashboards/${newDashboard.id}`,
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      // TODO: error handling
    }
  };

  const handleBreadcrumbButtonClick = () => {
    setCreateDashboardView(false);
  };

  return (
    <div className={styles.DashboardManagement}>
      <div className={styles.DashboardHeader}>
        <div className={styles.HeaderTitle}>
          <div className={styles.BreadcrumbContainer}>
            {createDashboardView && (
              <button
                type="button"
                className={styles.ReturnToDashboardList}
                onClick={handleBreadcrumbButtonClick}
              >
                <DynamicCaretIcon
                  className={styles.CaretIcon}
                  direction={CaretDirection.left}
                />

                <div className={styles.ReturnToDashboardText}>Dashboards</div>
              </button>
            )}
          </div>

          {createDashboardView ? 'New Dashboard' : 'Dashboards'}
        </div>

        {!createDashboardView && (
          <button
            type="button"
            className={styles.AddDashboard}
            onClick={handleNewDashboard}
          >
            Create New Dashboard
          </button>
        )}
      </div>

      <div className={styles.DashboardGridLayout}>
        {/* TODO: hide titles if no dashboards for that section */}
        {isLoading && <ComponentLoader />}
        {!createDashboardView ? (
          <>
            <div className={styles.DashboardGridTitle}>
              MY DASHBOARDS
              <div className={styles.DashboardsList}>
                {myDashboards.map((dashboard) => (
                  <DashboardListItem
                    dashboard={dashboard}
                    key={dashboard.id}
                    updateList={setTriggerRequery}
                  />
                ))}
              </div>
            </div>
            <div className={styles.DashboardGridTitle}>
              SHARED WITH ME
              <div className={styles.DashboardsList}>
                {/* eslint-disable-next-line no-nested-ternary */}
                {sharedDashboardsLoading ? (
                  <ComponentLoader />
                ) : sharedDashboards && sharedDashboards.length ? (
                  sharedDashboards.map((dashboard) => (
                    <DashboardListItem
                      dashboard={dashboard}
                      key={dashboard.id}
                      updateList={setTriggerRequery}
                      isSharedDashboard
                    />
                  ))
                ) : (
                  <div className={styles.NoSharedDashboards}>
                    <IconInfoCircleSolid className={styles.InfoIcon} />
                    <div className={styles.TextBox}>
                      <p className={styles.Title}>
                        No dashboards have been shared with you
                      </p>
                      <p
                        className={styles.Subtitle}
                      >{`You'll be able to view the dashboards that your team shares with you here.`}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          canCreateDashboardTemplates && <TemplateSelectorContainer />
        )}
      </div>
    </div>
  );
};

export default DashboardsManagement;
