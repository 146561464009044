import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';
import { SingleValue } from 'react-select';
import { useSearchParams, useOutletContext } from 'react-router-dom';

import CreativeAdvertisingOverviewContainer from '../Creative/CreativeAdvertisingOverview/CreativeAdvertisingOverviewContainer';
import AdvertisingCreativeSection, {
  AdvertisingView,
} from '../Creative/AdvertisingCreativeSection/AdvertisingCreativeSection';
import CreativeGrid from '../Creative/CreativeGrid/CreativeGrid';
import AssetTable from '../Creative/AssetTable/AssetTable';
import Pager from '../Creative/Pager/Pager';
import ModalContainer from '../../Modals/ModalContainer';
import CreativeAssetModalContent from '../Creative/CreativeAssetModalContent';

import useRouter from '../../../hooks/useRouter';
import BNContext from '../../../contexts/BNContext';
import {
  GET_BRAND_ADVERTISING_METRICS,
  GET_COHORT_ADVERTISING_METRICS,
  GET_CREATIVES,
} from '../../../api/queries/Pages/CreativeChannelsAdvertising';
import {
  formatSpendMetricsOverviewCard,
  formatECPMMetricsOverviewCard,
  formatShareOfSpendMetricsOverviewCard,
  formatCompetitionAverageOverviewCard,
} from '../../../api/transforms/Pages/Creative/CreativeAdvertising';
import { getDayJsStartDate } from '../../../utils/timeframe';
import QUERY_PARAMS from '../../../constants/queryParams';
import {
  CreativeAdCardTypes,
  CreativeAsset,
} from '../../../interfaces/creative';
import { SortType } from '../../../constants/creative-asset-grid';

import styles from './CreativeChannelsAdvertising.module.scss';
import useCompetitiveSet from '../../../hooks/useCompetitiveSet';
import CompareBrandAssets from '../Creative/CompareBrandAssets/CompareBrandAssets';
import { GetCreativesRequestInput } from '../Creative/types';
import DropdownSelect from '../Creative/DropdownSelect/DropdownSelect';

export interface CreativeChannelsAdvertisingProps
  extends AnchorHTMLAttributes<HTMLDivElement> {}

const CreativeChannelsAdvertising: FunctionComponent<
  CreativeChannelsAdvertisingProps
> = ({ className, ...props }) => {
  // Page Rows
  const numRowOptions = [
    { value: 20, label: '20 Rows' },
    { value: 50, label: '50 Rows' },
    { value: 100, label: '100 Rows' },
  ];

  // creative asset modal state
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedCreative, setSelectedCreative] = useState<CreativeAsset>();

  // pagination state
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<{ label: string; value: number }>(
    numRowOptions[0]
  );

  // creative grid/table filter state
  const [selectedChannelFilter, setSelectedChannelFilter] =
    useState<string>('0');
  const [selectedSortBy, setSelectedSortBy] = useState<SortType>(SortType.Date);
  const [selectedMediaType, setSelectedMediaType] = useState<string>('0');

  // creative metrics panel state
  const [activeMetricPanel, setActiveMetricPanel] =
    useState<CreativeAdCardTypes>();
  const [isGridCompareView, setIsGridCompareView] = useState<boolean>(false);

  const {
    brandKey,
    competitiveSetId,
    brandName,
  }: { brandKey: string; competitiveSetId: string; brandName: string } =
    useOutletContext();

  const { nonSessionTimeframe } = useContext(BNContext);

  const [searchParams] = useSearchParams();
  const { updateRoute } = useRouter();
  const { brands } = useCompetitiveSet();

  const activeView =
    (searchParams.get(
      QUERY_PARAMS.CREATIVE_CHANNELS_ADVERTISING_ACTIVE_VIEW
    ) as AdvertisingView) || AdvertisingView.Grid;

  const brandTimeseriesInput = useMemo(() => {
    return {
      input: {
        brandKey,
        start: getDayJsStartDate(nonSessionTimeframe).toISOString(),
        end: dayjs(nonSessionTimeframe?.end).toISOString(),
      },
    };
  }, [brandKey, nonSessionTimeframe]);

  const cohortTimeseriesInput = useMemo(() => {
    return {
      input: {
        ...brandTimeseriesInput.input,
        competitiveSetId,
      },
    };
  }, [brandTimeseriesInput, competitiveSetId]);

  const getCreativesInput = useMemo(() => {
    const result: GetCreativesRequestInput = {
      input: {
        ...brandTimeseriesInput.input,
        view: activeView,
        pageSize: pageSize.value,
        page: pageIndex,
        sort: selectedSortBy,
      },
    };

    if (
      ((selectedChannelFilter && selectedChannelFilter !== '0') ||
        (selectedMediaType && selectedMediaType !== '0')) &&
      !result.input.filters
    ) {
      result.input.filters = [];
    }

    if (selectedChannelFilter && selectedChannelFilter !== '0') {
      result?.input?.filters?.push({
        filterOn: 'channel',
        filterBy: [Number(selectedChannelFilter)],
      });
    }

    if (selectedMediaType && selectedMediaType !== '0') {
      result?.input?.filters?.push({
        filterOn: 'fileType',
        filterBy: [Number(selectedMediaType)],
      });
    }

    return result;
  }, [
    brandTimeseriesInput.input,
    activeView,
    pageSize.value,
    pageIndex,
    selectedSortBy,
    selectedChannelFilter,
    selectedMediaType,
  ]);

  const { data: brandAvertisingMetricsResp, loading: brandsLoading } = useQuery(
    GET_BRAND_ADVERTISING_METRICS,
    {
      variables: brandTimeseriesInput,
    }
  );

  const { data: cohortAvertisingMetricsResp, loading: cohortsLoading } =
    useQuery(GET_COHORT_ADVERTISING_METRICS, {
      variables: cohortTimeseriesInput,
    });

  const { data: getCreativesResp, loading: creativesLoading } = useQuery(
    GET_CREATIVES,
    {
      variables: getCreativesInput,
    }
  );

  const formattedSpendMetrics = useMemo(() => {
    return formatSpendMetricsOverviewCard(brandAvertisingMetricsResp);
  }, [brandAvertisingMetricsResp]);

  const formattedECPMMetrics = useMemo(() => {
    return formatECPMMetricsOverviewCard(brandAvertisingMetricsResp);
  }, [brandAvertisingMetricsResp]);

  const formattedShareOfSpendMetrics = useMemo(() => {
    return formatShareOfSpendMetricsOverviewCard(cohortAvertisingMetricsResp);
  }, [cohortAvertisingMetricsResp]);

  const formattedCompetetitionAverageMetrics = useMemo(() => {
    return formatCompetitionAverageOverviewCard(cohortAvertisingMetricsResp);
  }, [cohortAvertisingMetricsResp]);

  const formattedCreatives = useMemo(() => {
    if (!getCreativesResp?.getCreatives?.creatives?.length) {
      return [];
    }

    return getCreativesResp.getCreatives.creatives;
  }, [getCreativesResp]);

  const formattedCreativeAssetTotal = useMemo(() => {
    return getCreativesResp?.getCreatives?.count ?? 0;
  }, [getCreativesResp?.getCreatives]);

  const carouselData = useMemo(() => {
    return [
      formattedSpendMetrics,
      formattedShareOfSpendMetrics,
      formattedCompetetitionAverageMetrics,
      formattedECPMMetrics,
    ];
  }, [
    formattedSpendMetrics,
    formattedShareOfSpendMetrics,
    formattedECPMMetrics,
    formattedCompetetitionAverageMetrics,
  ]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setSelectedCreative(undefined);
  };

  const handleOpenCreativeModal = useCallback((creative: CreativeAsset) => {
    setModalIsOpen(true);
    setSelectedCreative(creative);
  }, []);

  const handleActiveViewChange = useCallback(
    (newActiveView: AdvertisingView) => {
      updateRoute({
        params: {
          activeView: newActiveView,
        },
      });
    },
    [updateRoute]
  );

  const handleChannelFilterChange = useCallback(
    (option: SingleValue<{ value: string; label: string }>) => {
      setSelectedChannelFilter(option?.value ?? '0');
      setPageIndex(0);
    },
    []
  );

  const handleSortByChange = useCallback(
    (option: SingleValue<{ value: SortType; label: string }>) => {
      if (option !== null) {
        setSelectedSortBy(option?.value);
        setPageIndex(0);
      }
    },
    []
  );

  const handleMediaTypeChange = useCallback(
    (option: SingleValue<{ value: string; label: string }>) => {
      setSelectedMediaType(option?.value ?? '0');
      setPageIndex(0);
    },
    []
  );

  const handlePageChange = (page: number) => {
    setPageIndex(page);
  };

  const handlePageSizeChange = useCallback(
    (option: SingleValue<{ value: number; label: string }>) => {
      if (option !== null) {
        setPageSize(option);
        setPageIndex(0);
      }
    },
    []
  );

  return (
    <div
      className={classNames(styles.CreativeChannelsAdvertising, className)}
      {...props}
    >
      <section>
        <CreativeAdvertisingOverviewContainer
          brandName={brandName}
          carouselLoading={cohortsLoading || brandsLoading}
          carouselData={carouselData}
          activeMetricPanel={activeMetricPanel}
          onCarouselCardClick={setActiveMetricPanel}
          onMetricPanelClose={() => setActiveMetricPanel(undefined)}
          brandKey={brandKey}
          competitiveSetId={competitiveSetId}
        />
      </section>

      <section>
        {!isNil(activeView) && (
          <AdvertisingCreativeSection
            activeView={activeView}
            onActiveViewChange={handleActiveViewChange}
            selectedChannelFilter={selectedChannelFilter}
            onChannelFilterChange={handleChannelFilterChange}
            selectedSortBy={selectedSortBy}
            onSortByChange={handleSortByChange}
            selectedMediaType={selectedMediaType}
            onMediaTypeChange={handleMediaTypeChange}
            isGridCompareView={isGridCompareView}
            setIsGridCompareView={setIsGridCompareView}
          />
        )}

        {Boolean(activeView === AdvertisingView.Grid) && (
          <>
            <CreativeGrid
              creatives={formattedCreatives}
              loading={creativesLoading}
              onCreativeClick={handleOpenCreativeModal}
              selectedSortBy={selectedSortBy}
            />

            {isGridCompareView && (
              <div className={styles.CompareOverlay}>
                <CompareBrandAssets
                  brands={brands}
                  className={styles.CompareContainer}
                  handleClose={() => setIsGridCompareView(false)}
                />
              </div>
            )}
          </>
        )}

        {Boolean(activeView === AdvertisingView.List) && (
          <AssetTable
            data={formattedCreatives}
            loading={creativesLoading}
            onLabelClick={handleOpenCreativeModal}
          />
        )}

        <div className={styles.CreativeFooter}>
          <Pager
            pageIndex={pageIndex}
            pageSize={pageSize.value}
            totalCount={formattedCreativeAssetTotal}
            onPageChange={handlePageChange}
          />
          {Boolean(activeView === AdvertisingView.List) &&
            formattedCreatives.length > pageSize.value && (
              <DropdownSelect
                options={numRowOptions}
                value={pageSize}
                onChange={handlePageSizeChange}
              />
            )}
        </div>
      </section>

      {selectedCreative && (
        <ModalContainer isOpen={modalIsOpen} closeModal={handleCloseModal}>
          <CreativeAssetModalContent creative={selectedCreative} />
        </ModalContainer>
      )}
    </div>
  );
};

export default CreativeChannelsAdvertising;
