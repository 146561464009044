/* eslint-disable no-param-reassign */
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';

import styles from './SelectGroupFlow.module.scss';
import SelectAccordion from '../../SelectAccordion/SelectAccordion';
import FunctionalAreasHeader from '../../WidgetSettingsShared/FunctionalAreasHeader/FunctionalAreasHeader';
import { FunctionalAreasView, Group, Item, MetricDetail } from '../../types';
import GroupCard from '../../WidgetSettingsShared/GroupCard/GroupCard';
import {
  getMetricCategories,
  getMetrics,
  MetricCategory,
  NotYetImplementedBucket,
} from '../../../../../mocks/data/CustomizableDashboards/metrics';

const metricsById = keyBy(getMetrics, 'id');

const SelectGroupFlow: FunctionComponent<WidgetConfigProps> = ({
  config,
  onConfigChange,
  onValidationChange,
}) => {
  const [activeView, setActiveView] = useState<FunctionalAreasView>(
    config[FlowKey.SelectGroup].metricGroup === MetricCategory.AllMetrics
      ? FunctionalAreasView.List
      : FunctionalAreasView.Grid
  );

  const [selectedMetricCategoryId, setSelectedMetricCategoryId] =
    useState<MetricCategory>(config[FlowKey.SelectGroup].metricGroup);

  const selectedMetrics: Set<string | number> = useMemo(() => {
    const { metrics } = config[FlowKey.SelectGroup];

    return new Set(metrics.map((metric) => metric.id));
  }, [config]);

  const formattedSelectedGroup: Group[] = useMemo(() => {
    const result: Group[] = [];

    const currentMetricCategory = find(getMetricCategories, {
      id: selectedMetricCategoryId,
    });

    if (!currentMetricCategory) return result;

    currentMetricCategory.buckets.forEach((bucket) => {
      // TODO: remove once Blue Score group is supported by MetricCollection GraphQL (except it's not currently planned)
      if (bucket.id === NotYetImplementedBucket.id) return;

      const group: Group = {
        id: bucket.id,
        name: bucket.name,
        description: bucket.description,
        items: [],
      };

      bucket.metricIds.forEach((metricId) => {
        group.items.push({
          id: metricId,
          name: metricsById[metricId]?.name,
        });
      });

      result.push(group);
    });

    return result;
  }, [selectedMetricCategoryId]);

  const handleItemSelect = useCallback(
    (item: Item) => {
      if (selectedMetrics.has(item.id)) {
        selectedMetrics.delete(item.id);
      } else {
        selectedMetrics.add(item.id);
      }

      const clonedConfig = structuredClone(config);
      clonedConfig[FlowKey.SelectGroup].metrics =
        getConfigOutputFormattedSelectedMetricsSet(selectedMetrics);

      // if single metrics are chosen, change category to 'All Metrics'
      clonedConfig[FlowKey.SelectGroup].metricGroup = MetricCategory.AllMetrics;

      onConfigChange(clonedConfig);
    },
    [config, onConfigChange, selectedMetrics]
  );

  const handleCardSelect = useCallback(
    (group: Group) => {
      const newSelectedMetrics = new Set<number | string>();
      group.items.forEach((item) => {
        newSelectedMetrics.add(item.id);
      });

      const clonedConfig = structuredClone(config);
      clonedConfig[FlowKey.SelectGroup].metrics =
        getConfigOutputFormattedSelectedMetricsSet(newSelectedMetrics);
      clonedConfig[FlowKey.SelectGroup].groupName = group.name;
      clonedConfig[FlowKey.MetricGroupSettings].widgetName = group.name;

      // set group id to the group that contains this bucket
      clonedConfig[FlowKey.SelectGroup].metricGroup = selectedMetricCategoryId;

      onConfigChange(clonedConfig);
    },
    [config, selectedMetricCategoryId, onConfigChange]
  );

  useEffect(() => {
    const { metrics } = config[FlowKey.SelectGroup];

    onValidationChange({ [FlowKey.SelectGroup]: !metrics.length });
  }, [config, onValidationChange]);

  return (
    <div className={classNames(styles.SelectGroupFlow)}>
      <FunctionalAreasHeader
        activeView={activeView}
        onViewChange={setActiveView}
        onMetricCategoryChange={setSelectedMetricCategoryId}
        metricCategories={getMetricCategories}
        activeMetricCategory={selectedMetricCategoryId}
      />

      {activeView === FunctionalAreasView.List ? (
        <SelectAccordion
          groups={formattedSelectedGroup}
          expandOnDefault
          onItemSelect={handleItemSelect}
          onGroupSelect={handleCardSelect}
          selectedItemIds={selectedMetrics}
        />
      ) : (
        <div className={styles.GridView}>
          {formattedSelectedGroup.map((bucket) => {
            return (
              <GroupCard
                key={bucket.id}
                group={bucket}
                itemsTitle="Metrics"
                isSelected={isGroupSelected(bucket, selectedMetrics)}
                onGroupClick={handleCardSelect}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SelectGroupFlow;

function getConfigOutputFormattedSelectedMetricsSet(
  selectedMetrics: Set<string | number>
) {
  return Array.from(selectedMetrics).map((id) => {
    return {
      id,
      name: metricsById[id].name,
    } as MetricDetail;
  });
}

function isGroupSelected(group: Group, selectedMetrics: Set<string | number>) {
  return group.items.every((item) => selectedMetrics.has(item.id));
}
