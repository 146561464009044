import { useMemo, FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';

import ChartLegendIcon from '../../Atoms/ChartLegendIcon/ChartLegendIcon';
import ChartLegendItem from '../../Atoms/ChartLegendItem/ChartLegendItem';
import Dropdown from '../Dropdown/Dropdown';

import { ORIENTATIONS } from '../../../constants/props';
import { ChartColor } from '../../../interfaces';

import styles from './ChartLegend.module.scss';

export interface ChartLegendProps extends AnchorHTMLAttributes<HTMLDivElement> {
  orientation?: string;
  items: {
    label: string;
    variant: string;
    color?: ChartColor;
  }[];
  filterableItems?: {
    label: string;
    variant: string;
    id: string | number;
    color: ChartColor;
  }[];
  onFilterChange?: () => void;
  value?: string | number;
  fontSize?: number;
}

const ChartLegend: FunctionComponent<ChartLegendProps> = ({
  orientation = 'horizontal',
  items,
  filterableItems,
  onFilterChange = () => {},
  value,
  fontSize,
  className,
}) => {
  const dropdownItems = useMemo(() => {
    const result: {
      label: string;
      value: string | number;
      icon: FunctionComponent;
    }[] = [];

    if (filterableItems) {
      filterableItems.forEach((item) => {
        result.push({
          label: item.label,
          value: item.id,
          icon: (
            <ChartLegendIcon variant={item.variant} chartColor={item.color} />
          ) as unknown as FunctionComponent,
        });
      });
    }

    return result;
  }, [filterableItems]);

  return (
    <div
      className={classNames(
        styles.ChartLegend,
        orientation === ORIENTATIONS.HORIZONTAL ? styles.Horizontal : null,
        orientation === ORIENTATIONS.VERTICAL ? styles.Vertical : null,
        className
      )}
    >
      {items.map((item, i) => (
        <ChartLegendItem
          className={styles.Item}
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.label}--${i}`}
          fontSize={fontSize}
          label={item.label}
          variant={item.variant}
          color={item.color}
        />
      ))}

      {dropdownItems.length > 0 ? (
        <Dropdown
          variant={null}
          className={styles.DropdownMenu}
          showArrow
          options={dropdownItems}
          onChange={onFilterChange}
          value={value}
        />
      ) : null}
    </div>
  );
};

export default ChartLegend;
