import { GenericIconProps } from './types';

const ReportPageIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <path
                d="M0 0L24 0 24 24 0 24z"
                transform="translate(-860 -460) translate(805 448) translate(51 8) translate(4 4)"
              />
              <path
                fill="currentColor"
                d="M15.5 2c.133 0 .26.053.354.146l4 4c.093.094.146.221.146.354v13c0 1.379-1.122 2.5-2.5 2.5h-11C5.122 22 4 20.879 4 19.5v-15C4 3.121 5.122 2 6.5 2zm-.207 1H6.5C5.673 3 5 3.673 5 4.5v15c0 .827.673 1.5 1.5 1.5h11c.827 0 1.5-.673 1.5-1.5V6.707L15.293 3zM8.5 12c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5zM12 9c.276 0 .5.224.5.5v5c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-5c0-.276.224-.5.5-.5zm3.5-1c.276 0 .5.224.5.5v6c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-6c0-.276.224-.5.5-.5z"
                transform="translate(-860 -460) translate(805 448) translate(51 8) translate(4 4)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ReportPageIcon;
