import { FunctionComponent } from 'react';
import classNames from 'classnames';
import MuiCheckbox, {
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core/Checkbox';

import styles from './Checkbox.module.scss';

export type { MuiCheckboxProps as CheckboxProps };

const Checkbox: FunctionComponent<MuiCheckboxProps> = ({
  className,
  ...props
}) => {
  return (
    <MuiCheckbox
      disableRipple
      className={classNames(styles.Checkbox, className)}
      {...props}
    />
  );
};

export default Checkbox;
