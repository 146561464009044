/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import { AnchorHTMLAttributes, useCallback } from 'react';
import styles from './Pager.module.scss';
import { usePagination } from '../../../../hooks/usePagination';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../../assets/icons/DynamicCaret';

export interface PagerProps extends AnchorHTMLAttributes<HTMLDivElement> {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  onPageChange: (page: number) => void;
}

const Pager = ({
  pageIndex,
  pageSize,
  totalCount,
  onPageChange,
  className,
  ...props
}: PagerProps) => {
  const { paginationRange, handleDotClick } = usePagination({
    totalCount,
    pageSize,
    pageIndex,
  });

  const canPageBack = () => pageIndex > 0;
  const canPageFoward = () => pageIndex + 1 < Math.ceil(totalCount / pageSize);
  const handlePageBack = useCallback(() => {
    const newPage = pageIndex - 1;
    onPageChange(newPage);
  }, [pageIndex, onPageChange]);
  const handlePageForward = useCallback(() => {
    const newPage = pageIndex + 1;
    onPageChange(newPage);
  }, [pageIndex, onPageChange]);
  const handleGoToPage = useCallback(
    (page: string | number, index: number) => {
      let newPage = page;
      if (Number.isNaN(Number(page))) {
        newPage = handleDotClick(index);
      }
      onPageChange(Number(newPage) - 1);
    },
    [onPageChange, handleDotClick]
  );

  const fromRange = () => {
    const rangeStart = pageIndex * pageSize + 1;
    return rangeStart.toLocaleString();
  };
  const toRange = () => {
    let rangeEnd = pageSize * (pageIndex + 1);
    if (rangeEnd > totalCount) {
      rangeEnd = totalCount;
    }
    return rangeEnd.toLocaleString();
  };

  if (pageSize >= totalCount) {
    return null;
  }

  return (
    <div className={cn(styles.PagerContainer, className)} {...props}>
      <ul>
        <li className={cn(styles.Page, !canPageBack() ? styles.Disabled : '')}>
          <button
            type="button"
            disabled={!canPageBack()}
            onClick={handlePageBack}
          >
            <DynamicCaretIcon
              direction={CaretDirection.left}
              className={styles.Icon}
            />
          </button>
        </li>
        {paginationRange.map((page, index) => (
          <li
            key={`${index}_${page}`}
            className={cn(
              styles.Page,
              page === pageIndex + 1 ? styles.ActivePage : ''
            )}
          >
            <button type="button" onClick={() => handleGoToPage(page, index)}>
              {page}
            </button>
          </li>
        ))}
        <li
          className={cn(styles.Page, !canPageFoward() ? styles.Disabled : '')}
        >
          <button
            type="button"
            disabled={!canPageFoward()}
            onClick={handlePageForward}
          >
            <DynamicCaretIcon
              direction={CaretDirection.right}
              className={styles.Icon}
            />
          </button>
        </li>
      </ul>
      <p className={styles.ShowLabel}>
        Showing {fromRange()} - {toRange()} of {totalCount.toLocaleString()}
      </p>
    </div>
  );
};

export default Pager;
