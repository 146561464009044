import { useMemo, useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';

import Button from '@material-ui/core/Button';

import TopNavDropdownMenu from '../TopNavDropdownMenu/TopNavDropdownMenu';

import styles from './TopNavDropdown.module.scss';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../assets/icons/DynamicCaret';

export default function TopNavDropdown({
  className,
  options,
  value,
  onClick,
  buttonRender,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const anchorEl = useRef();

  const optionsByValue = useMemo(() => {
    if (isEmpty(options)) {
      return {};
    }

    return keyBy(options, 'value');
  }, [options]);

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  function handleItemClick(newVal) {
    onClick(newVal);

    handleClose();
  }

  return (
    <div className={classNames(styles.TopNavDropdown, className)} {...props}>
      <Button
        data-jest="top-nav-dropdown-btn"
        data-cy={`top-nav-dropdown-btn-${options[0].value}`}
        aria-controls="top-navigation-menu"
        aria-haspopup="true"
        onClick={handleButtonClick}
        className={styles.DropdownBtn}
        disableRipple
        disableElevation
        ref={anchorEl}
      >
        {buttonRender ? (
          buttonRender()
        ) : (
          <>
            {optionsByValue[value] && optionsByValue[value].icon ? (
              <div className={styles.StartIcon}>
                {optionsByValue[value].icon}
              </div>
            ) : null}
            <span className={styles.Label}>
              {optionsByValue[value] ? optionsByValue[value].label : ''}
            </span>
          </>
        )}

        <div className={styles.Icon}>
          <DynamicCaretIcon direction={CaretDirection.down} />
        </div>
      </Button>

      <TopNavDropdownMenu
        anchorEl={anchorEl.current}
        options={options}
        onClick={handleItemClick}
        value={value}
        onBackdropClick={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </div>
  );
}

TopNavDropdown.propTypes = {
  className: PropTypes.string,
  formatLabels: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonRender: PropTypes.func,
};
