import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Grid from '@material-ui/core/Grid';

import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import Switch from '../../Atoms/Switch/Switch';
import SelectInput from '../../Molecules/SelectInput/SelectInput';
import TextInput from '../../Molecules/TextInput/TextInput';
import ModalFooter from '../../Molecules/ModalFooter/ModalFooter';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

import styles from './SettingsMarkerEventForm.module.scss';

export default function SettingsMarkerEventForm({
  className,
  defaultValues,
  onClose,
  onSubmit,
  loading,
  ...props
}) {
  dayjs.extend(quarterOfYear);
  const FEATURE_FLAGS = useFeatureFlags();

  const [disableSubmit, setDisableSubmit] = useState(true);
  const formSchema = yup.object().shape({
    eventTitle: yup.string().min(1).required(),
    showEvent: yup.string().required(),
    eventDate: yup
      .date()
      .min(dayjs().subtract(4, 'quarter').toDate())
      .max(dayjs().add(4, 'quarter').toDate())
      .required(),
    private: yup.bool(),
    hdnId: yup.string(),
  });

  const defaults = {
    showEvent: 'brand',
    private: !FEATURE_FLAGS.GLOBAL.MARKERS.ADD.BRAND,
  };

  const { handleSubmit, control, watch, register, setValue } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: defaults,
  });

  const watchedFields = watch(
    ['eventTitle', 'showEvent', 'eventDate', 'private'],
    {
      defaultValues: defaults,
    }
  );

  useEffect(() => {
    formSchema.isValid(watchedFields).then((valid) => {
      setDisableSubmit(!valid);
    });
  }, [watchedFields, formSchema]);

  // Because this feature flag stops the <Controller /> from mounting
  // register() must be called manually
  useEffect(() => {
    if (!FEATURE_FLAGS.GLOBAL.MARKERS.ADD.ACCOUNT) {
      register('showEvent');
    }
  }, [register, setValue, FEATURE_FLAGS.GLOBAL.MARKERS.ADD.ACCOUNT]);

  if (!FEATURE_FLAGS.GLOBAL.MARKERS.ADD.ROOT) return null;

  return (
    <div
      className={classNames(styles.SettingsMarkerEventForm, className)}
      {...props}
    >
      {loading ? <ComponentLoader minHeight={600} /> : null}

      {!loading ? (
        <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Controller
                name="hdnId"
                control={control}
                defaultValue={
                  defaultValues && defaultValues.id ? defaultValues.id : ''
                }
                render={({ value }) => (
                  <input
                    type="hidden"
                    id="hdnId"
                    value={value}
                    data-cy="hdn-id-field"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="eventTitle"
                control={control}
                defaultValue={
                  defaultValues && defaultValues.eventTitle
                    ? defaultValues.eventTitle
                    : ''
                }
                render={({ onChange, value }) => (
                  <TextInput
                    type="text"
                    inputId="eventTitle"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required
                    helperText="This is what will display on the event flag on the timeline"
                    label="EVENT TITLE"
                  />
                )}
              />
            </Grid>

            {FEATURE_FLAGS.GLOBAL.MARKERS.ADD.ACCOUNT ? (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="showEvent"
                  control={control}
                  defaultValue={
                    defaultValues && defaultValues.showEvent
                      ? defaultValues.showEvent
                      : 'account'
                  }
                  render={({ onChange, value }) => (
                    <SelectInput
                      value={value}
                      inputId="showEvent"
                      onChange={(e) => onChange(e.target.value)}
                      required
                      label="SHOW EVENT"
                      menuItems={[
                        {
                          label: 'Account',
                          value: 'account',
                        },
                        {
                          label: 'Brand',
                          value: 'brand',
                        },
                      ]}
                    />
                  )}
                />
              </Grid>
            ) : null}

            <Grid item xs={12} sm={6}>
              <Controller
                name="eventDate"
                control={control}
                defaultValue={
                  defaultValues && defaultValues.eventDate
                    ? dayjs(defaultValues.eventDate).format('YYYY-MM-DD')
                    : ''
                }
                render={({ onChange, value }) => (
                  <TextInput
                    type="date"
                    inputId="eventDate"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required
                    placeholder="mm/dd/yyyy"
                    label="EVENT DATE"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <h3 className={styles.PrivateHeader}>
                When will this Event show?
              </h3>
              {FEATURE_FLAGS.GLOBAL.MARKERS.ADD.PRIVATE ? (
                <div className={styles.PrivateSwitch}>
                  {!FEATURE_FLAGS.GLOBAL.MARKERS.ADD.BRAND ? (
                    <Controller
                      name="private"
                      control={control}
                      defaultValue
                      render={() => <Switch checked />}
                    />
                  ) : (
                    <Controller
                      name="private"
                      control={control}
                      defaultValue={
                        defaultValues ? defaultValues.private : false
                      }
                      render={({ onChange, value }) => (
                        <Switch
                          checked={value}
                          onChange={(e) => {
                            onChange(e.target.checked);
                          }}
                        />
                      )}
                    />
                  )}
                  <span className={styles.SwitchHelper}>
                    Keep private (only you can see this marker)
                  </span>
                </div>
              ) : null}
            </Grid>
          </Grid>

          <ModalFooter
            onClose={onClose}
            actionText={defaultValues ? 'Edit Event' : 'Add Event'}
            disableAction={disableSubmit}
          />
        </form>
      ) : null}
    </div>
  );
}

SettingsMarkerEventForm.propTypes = {
  className: PropTypes.string,
  defaultValues: PropTypes.shape({
    id: PropTypes.number,
    eventTitle: PropTypes.string,
    showEvent: PropTypes.string,
    eventDate: PropTypes.string,
    private: PropTypes.bool,
  }),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};
