export const tableColumns = [
  {
    label: 'Blue Score',
    key: 'overall',
  },
  {
    label: 'familiar',
    key: 'familiar',
  },
  {
    label: 'unique',
    key: 'unique',
  },
  {
    label: 'consistent',
    key: 'consistent',
  },
  {
    label: 'relevant',
    key: 'relevant',
  },
  {
    label: 'revered',
    key: 'revered',
  },
];
