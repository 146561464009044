import React, { useState } from 'react';
import PropType from 'prop-types';
import RadioSelectorItem from '../../Atoms/RadioSelectorItem/RadioSelectorItem';

import styles from './RadioSelector.module.scss';

export default function RadioSelector({
  items,
  defaultSelectedIndex = 0,
  onChange,
}) {
  const [activeItemIndex, setActiveItemIndex] = useState(defaultSelectedIndex);

  function handleItemClick(index) {
    const oldActiveItemIndex = activeItemIndex;
    const newActiveItemIndex = index;

    setActiveItemIndex(newActiveItemIndex);
    onChange(items[newActiveItemIndex], items[oldActiveItemIndex]);
  }

  return (
    <ul className={styles.RadioSelector}>
      {items.map((item, index) => (
        <RadioSelectorItem
          onClick={() => handleItemClick(index)}
          className={styles.Item}
          active={index === activeItemIndex}
          name={item.name}
          key={item.name}
        />
      ))}
    </ul>
  );
}

RadioSelector.propTypes = {
  items: PropType.arrayOf(
    PropType.shape({
      name: PropType.string,
    })
  ),
  defaultSelectedIndex: PropType.number,
  onChange: PropType.func,
};
