import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import Checkbox from '../../Atoms/Checkbox/Checkbox';

import styles from './CheckboxList.module.scss';

export default function CheckboxList({
  className,
  options,
  onChange = () => {},
  title,
  ...props
}) {
  const [selected, setSelected] = useState({});

  function handleCheckboxChange(e, key) {
    const result = { ...selected };

    if (e && e.target) {
      result[key] = !result[key];
    }

    setSelected(result);

    const onChangeResult = [];

    Object.keys(result).forEach((resultKey) => {
      if (result[resultKey]) {
        onChangeResult.push(resultKey);
      }
    });

    onChange(onChangeResult);
  }

  return (
    <Box className={classNames(styles.CheckboxList, className)} {...props}>
      <Box className={styles.Header} mb={2}>
        <h6 className={styles.Title}>{title}</h6>
      </Box>
      <Box className={styles.Options}>
        {options.map((option, index) => (
          <Box
            className={styles.OptionItem}
            key={option.value}
            mb={index === options.length - 1 ? 0 : 1}
          >
            <Box mr={1}>
              <Checkbox
                checked={selected[option.value] || false}
                value={selected[option.value] || false}
                onChange={(e) => handleCheckboxChange(e, option.value)}
              />
            </Box>
            <span className={styles.Label}>{option.label}</span>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

CheckboxList.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  title: PropTypes.string,
};
