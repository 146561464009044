import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Grid } from '@mui/material';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import styles from './ContentAnalysisKeywordsBrandContent.module.scss';
import {
  ContentAnalysisKeywordsBrandContentProps,
  DateOption,
  FormattedBrandContentCompetitor,
  FormattedKeyword,
  KeywordsOvertime,
  KeywordTrendHeader,
} from './index';
import ThemesWordTag from '../../Molecules/ThemesWordTag/ThemesWordTag';
import KeywordHeader from './KeywordHeader/KeywordHeader';
import BNContext from '../../../contexts/BNContext';
import {
  formatKeywords,
  getCompetitorMaxKeywordCount,
} from '../ContentAnalysisKeywords/helpers';

const ContentAnalysisKeywordsBrandContent = ({
  data,
  loading,
  selectedKeyword,
  keywordClick,
  keywordsMax,
  trendIsVisible,
  setTrendIsVisible,
}: ContentAnalysisKeywordsBrandContentProps): JSX.Element | null => {
  // TODO: Set type of BNContext
  const { timeframe, setTimeframe } = useContext(BNContext);

  const [competitors, setCompetitors] = useState<
    FormattedBrandContentCompetitor[] | null
  >(null);
  const [brandContentKeyWords, setBrandContentKeyWords] = useState<
    FormattedKeyword[] | null
  >(null);
  const [keywordsOverTime, setKeywordsOverTime] = useState<KeywordsOvertime[]>(
    []
  );
  const [brandName, setBrandName] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [priorDateStart, setPriorDateStart] = useState<string>(
    dayjs().subtract(30, 'days').format()
  );
  const [selectedDateOption, setSelectedDateOption] = useState<number>(-1);

  const dateOptions: DateOption[] = useMemo(
    () => [
      { name: '30 Days Prior', numDays: 30 },
      { name: '90 Days Prior', numDays: 90 },
      { name: '120 Days Prior', numDays: 120 },
    ],
    []
  );

  const handleDateFilterChange = ({ numDays }: DateOption): void => {
    if (trendIsVisible && numDays !== timeframe?.quantity) {
      // TODO: remove this when BNContext has a type
      if (setTimeframe) {
        setTimeframe({
          unit: 'days',
          quantity: numDays,
          end: dayjs().endOf('day').toDate(),
        });
      }
      setTrendIsVisible(true);
    }
  };

  const toggleBrandTrend = (): void => {
    setTrendIsVisible(!trendIsVisible);
  };

  const handleKeywordClick = (keyword: string, brand: string): void => {
    keywordClick(keyword, brand);
  };

  const getFormattedKeywords = useCallback(formatKeywords, []);

  // *---- Handle Time Changes ----*
  useEffect(() => {
    const selectedIndex = dateOptions.findIndex(
      ({ numDays }) => numDays === timeframe?.quantity
    );
    const defaultQuantity = 30;
    setSelectedDateOption(selectedIndex);
    // TODO: when we set a BNContext type the or check isn't needed
    setPriorDateStart(
      dayjs()
        .subtract(timeframe?.quantity || defaultQuantity, 'days')
        .format()
    );
  }, [dateOptions, timeframe, setSelectedDateOption]);

  // *---- Handle Data Changes ----*
  useEffect(() => {
    if (!data) return;

    if (data.competitiveSet?.session) {
      const { session, brand } = data.competitiveSet;
      const limitedContentWords = getFormattedKeywords(
        session.brandContentKeyWords.slice(0, keywordsMax),
        session.brandContentKeyWords?.[0]?.count
      );
      const formattedCompetitors = session.competitors.map((competitor) => {
        const maxKeywordCount = getCompetitorMaxKeywordCount(competitor);
        const brandContentKeywords = getFormattedKeywords(
          competitor?.brandContentKeyWords?.slice(0, keywordsMax),
          maxKeywordCount
        );
        return { ...competitor, brandContentKeyWords: brandContentKeywords };
      });
      const formattedKeywordsOvertime = brand.keywordsOverTime?.map(
        ({ sessionDate, keywords }) => ({
          sessionDate,
          keywords: getFormattedKeywords(
            keywords.slice(0, keywordsMax),
            keywords[0]?.count
          ),
        })
      );

      setBrandContentKeyWords(limitedContentWords);
      setCompetitors(formattedCompetitors);
      setKeywordsOverTime(formattedKeywordsOvertime);
      setBrandName(brand.name);
      setLogoUrl(brand.logoUrl);
    }
  }, [data, keywordsMax, getFormattedKeywords]);

  if (loading) {
    return <ComponentLoader />;
  }

  if (!loading && data && !competitors && !brandContentKeyWords) {
    return null;
  }

  return (
    <div className={styles.BrandTopicContainer}>
      <div
        className={cn(styles.KeywordColumn, styles.TopicColumnSelectedBrand)}
      >
        <KeywordHeader
          logoUrl={logoUrl}
          brandName={brandName}
          headerClass={styles.Header}
          handleIconClick={toggleBrandTrend}
          buttonClass={styles.BrandTrendButton}
        />

        {/* Brand Content Keywords */}
        {brandContentKeyWords?.map((keyword) => (
          <ThemesWordTag
            key={keyword.name}
            value={keyword.comparativeCount}
            colorIndex={keyword.colorIndex}
            term={keyword.name}
            brand={brandName}
            keywordOnClick={handleKeywordClick}
            selectedKeyword={selectedKeyword}
          />
        ))}
        {!brandContentKeyWords && <p>No keywords available</p>}
      </div>

      {/* Competitors */}
      <div className={styles.BrandTopicContainerInner}>
        {competitors?.map((competitor) => (
          <div key={competitor.name} className={styles.KeywordColumn}>
            <KeywordHeader
              logoUrl={competitor.logoUrl}
              brandName={competitor.name}
              headerClass={styles.Header}
            />
            {competitor.brandContentKeyWords.map((keyword) => (
              <ThemesWordTag
                key={keyword.name}
                value={keyword.comparativeCount}
                colorIndex={keyword.colorIndex}
                term={keyword.name}
                brand={competitor.name}
                keywordOnClick={handleKeywordClick}
                selectedKeyword={selectedKeyword}
              />
            ))}
            {competitor.brandContentKeyWords.length === 0 && (
              <p>No keywords available</p>
            )}
          </div>
        ))}
      </div>
      {/* Keyword Trends overtime */}
      {trendIsVisible && (
        <KeywordTrendHeader
          dateOptions={dateOptions}
          handleDateFilterChange={handleDateFilterChange}
          onClose={toggleBrandTrend}
          logoUrl={logoUrl}
          name={brandName}
          selectedDateOption={selectedDateOption}
        >
          <Grid
            container
            spacing={0}
            className={styles.TrendCardContentInnerWrap}
          >
            <Grid
              container
              spacing={0}
              className={styles.TrendCardContentInner}
            >
              <Grid item xs={12} md={3} className={styles.TrendCardColumn}>
                <p className={styles.ColumnTitle}>Current {selectedKeyword}</p>
                {keywordsOverTime[0].keywords?.map((keyword) => (
                  <ThemesWordTag
                    key={keyword.name}
                    value={keyword.comparativeCount}
                    colorIndex={keyword.colorIndex}
                    brand={brandName}
                    term={keyword.name}
                    keywordOnClick={handleKeywordClick}
                    selectedKeyword={selectedKeyword}
                  />
                ))}
              </Grid>
              {keywordsOverTime
                .slice(1)
                .filter((session) =>
                  Boolean(
                    dayjs(session.sessionDate).isBetween(
                      priorDateStart,
                      dayjs()
                    )
                  )
                )
                .map((session) => (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    className={styles.TrendCardColumn}
                    key={`${session.sessionDate}`}
                  >
                    <p className={styles.ColumnTitle}>
                      {dayjs(session.sessionDate).format('MMM D, YY')}
                    </p>
                    {session.keywords.length > 0 ? (
                      <>
                        {session.keywords.map((keyword) => (
                          <ThemesWordTag
                            key={keyword.name}
                            value={keyword.comparativeCount}
                            colorIndex={keyword.colorIndex}
                            brand={brandName}
                            term={keyword.name}
                            keywordOnClick={handleKeywordClick}
                            selectedKeyword={selectedKeyword}
                          />
                        ))}
                      </>
                    ) : (
                      <p>No data available</p>
                    )}
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </KeywordTrendHeader>
      )}
    </div>
  );
};

export default ContentAnalysisKeywordsBrandContent;
