import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Tag.module.scss';

export interface TagProps extends AnchorHTMLAttributes<HTMLDivElement> {
  name: string;
}

const Tag: FunctionComponent<TagProps> = ({ className, name }) => {
  return <div className={classNames(styles.Tag, className)}>{name}</div>;
};

export default Tag;
