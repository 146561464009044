import {
  FC,
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { Grid } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import styles from './CreativeAdvertisingOverviewContainer.module.scss';
import CreativeAdvertisingChartContainer from '../CreativeAdvertisingChartContainer/CreativeAdvertisingChartContainer';
import CreativeAdvertisingCarousel from '../CreativeAdvertisingCarousel/CreativeAdvertisingCarousel';
import CreativeChannelLineChart from '../../../Charts/CreativeChannelLineChart/CreativeChannelLineChart';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import BNContext from '../../../../contexts/BNContext';
import {
  CreativeAdCardTypes,
  CreativeAdChartTitles,
  CreativeChannelSpendAsset,
  CreativeChannelTableAsset,
} from '../../../../interfaces/creative';
import { getDayJsStartDate } from '../../../../utils/timeframe';
import {
  GET_SPEND_DETAILS,
  GET_SHARE_OF_SPEND_DETAILS,
  GET_COMPETITION_AVERAGE_DETAILS,
  GET_ECPM_DETAILS,
} from '../../../../api/queries/Pages/CreativeChannelsAdvertising';
import {
  prepareAdvertisingMetricsPanelSpendData,
  prepareAdvertisingMetricsPanelShareOfSpendData,
  prepareAdvertisingMetricsPanelCompetitionAverageData,
  prepareAdvertisingMetricsPanelECPMData,
} from '../../../../api/transforms/Pages/Creative/CreativeAdvertising';

import AdvertisingTable from '../AdvertisingTable/AdvertisingTable';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../../assets/icons/DynamicCaret';
import { CloseIcon } from '../../../../assets/icons';

export interface CreativeAdvertisingOverviewContainerProps {
  carouselData: CreativeChannelSpendAsset[];
  onCarouselCardClick: (cardType: CreativeAdCardTypes) => void;
  onMetricPanelClose: () => void;
  activeMetricPanel?: CreativeAdCardTypes;
  brandKey: string;
  competitiveSetId: string;
  brandName: string;
  carouselLoading: boolean;
}

interface ChartHeaderProps {
  title: string;
  chartTitle: string;
  total: number;
  diff: number;
}

interface TableId {
  [char: number]: boolean;
}

const CreativeAdvertisingOverviewContainer: FC<
  CreativeAdvertisingOverviewContainerProps
> = ({
  carouselData,
  onCarouselCardClick,
  onMetricPanelClose,
  activeMetricPanel,
  brandKey,
  competitiveSetId,
  brandName,
  carouselLoading,
}) => {
  const { nonSessionTimeframe } = useContext(BNContext);
  const [pinnedTableId, setPinnedTableId] = useState(0);
  const [chartHeaderData, setChartHeaderData] =
    useState<ChartHeaderProps | null>(null);
  const [checkedTableRowIds, setCheckedTableRowIds] = useState({} as TableId);

  const brandTimeseriesInput = useMemo(() => {
    return {
      input: {
        brandKey,
        start: getDayJsStartDate(nonSessionTimeframe).toISOString(),
        end: dayjs(nonSessionTimeframe?.end).toISOString(),
      },
    };
  }, [brandKey, nonSessionTimeframe]);

  const cohortTimeseriesInput = useMemo(() => {
    return {
      input: {
        ...brandTimeseriesInput.input,
        competitiveSetId,
      },
    };
  }, [brandTimeseriesInput, competitiveSetId]);

  const [
    getSpendDetails,
    { data: spendDetailsResp, loading: spendDetailsLoading },
  ] = useLazyQuery(GET_SPEND_DETAILS, {
    variables: brandTimeseriesInput,
  });

  const [
    getShareOfSpendDetails,
    { data: shareOfSpendDetailsResp, loading: shareOfSpendDetailsLoading },
  ] = useLazyQuery(GET_SHARE_OF_SPEND_DETAILS, {
    variables: cohortTimeseriesInput,
  });

  const [
    getCompetitionAverageDetails,
    {
      data: competitionAverageDetailsResp,
      loading: competitionAverageDetailsLoading,
    },
  ] = useLazyQuery(GET_COMPETITION_AVERAGE_DETAILS, {
    variables: cohortTimeseriesInput,
  });

  const [
    getECPMDetails,
    { data: eCPMDetailsResp, loading: eCPMDetailsLoading },
  ] = useLazyQuery(GET_ECPM_DETAILS, {
    variables: brandTimeseriesInput,
  });

  useEffect(() => {
    if (activeMetricPanel === CreativeAdCardTypes.spendId) {
      getSpendDetails();
      setPinnedTableId(1);
    }

    if (activeMetricPanel === CreativeAdCardTypes.shareOfSpend) {
      getShareOfSpendDetails();
      setPinnedTableId(0);
    }

    if (activeMetricPanel === CreativeAdCardTypes.competitionAverage) {
      getCompetitionAverageDetails();
      setPinnedTableId(0);
    }

    if (activeMetricPanel === CreativeAdCardTypes.eCPM) {
      getECPMDetails();
      setPinnedTableId(0);
    }
  }, [
    activeMetricPanel,
    getSpendDetails,
    getShareOfSpendDetails,
    getCompetitionAverageDetails,
    getECPMDetails,
  ]);

  const tableData = useMemo(() => {
    switch (activeMetricPanel) {
      case CreativeAdCardTypes.spendId: {
        if (!spendDetailsResp?.getSpendGraphData) {
          return [];
        }

        setChartHeaderData({
          title: CreativeAdCardTypes.spendId,
          chartTitle: CreativeAdChartTitles.spendId,
          total: spendDetailsResp?.getSpendGraphData?.totalDigital,
          diff: spendDetailsResp?.getSpendGraphData?.digitalDiff,
        });

        return prepareAdvertisingMetricsPanelSpendData(spendDetailsResp);
      }

      case CreativeAdCardTypes.shareOfSpend: {
        if (!shareOfSpendDetailsResp?.getShareOfSpendGraphData) {
          return [];
        }

        setChartHeaderData({
          title: CreativeAdCardTypes.shareOfSpend,
          chartTitle: CreativeAdChartTitles.shareOfSpend,
          total: shareOfSpendDetailsResp?.getShareOfSpendGraphData?.total,
          diff: shareOfSpendDetailsResp?.getShareOfSpendGraphData?.diff,
        });

        return prepareAdvertisingMetricsPanelShareOfSpendData(
          shareOfSpendDetailsResp
        );
      }

      case CreativeAdCardTypes.competitionAverage: {
        if (!competitionAverageDetailsResp?.getCompetitionAverageGraphData) {
          return [];
        }

        setChartHeaderData({
          title: CreativeAdCardTypes.competitionAverage,
          chartTitle: CreativeAdChartTitles.competitionAverage,
          total:
            competitionAverageDetailsResp?.getCompetitionAverageGraphData
              ?.total,
          diff: competitionAverageDetailsResp?.getCompetitionAverageGraphData
            ?.diff,
        });

        return prepareAdvertisingMetricsPanelCompetitionAverageData(
          competitionAverageDetailsResp
        );
      }

      case CreativeAdCardTypes.eCPM: {
        if (!eCPMDetailsResp?.geteCPMGraphData) {
          return [];
        }

        setChartHeaderData({
          title: CreativeAdCardTypes.eCPM,
          chartTitle: CreativeAdChartTitles.eCPM,
          total: eCPMDetailsResp.geteCPMGraphData?.total,
          diff: eCPMDetailsResp.geteCPMGraphData?.diff,
        });

        return prepareAdvertisingMetricsPanelECPMData(eCPMDetailsResp);
      }

      default:
        return [];
    }
  }, [
    activeMetricPanel,
    spendDetailsResp,
    shareOfSpendDetailsResp,
    competitionAverageDetailsResp,
    eCPMDetailsResp,
  ]);

  const pinnedTableData = useMemo(() => {
    return find(tableData, { id: pinnedTableId });
  }, [tableData, pinnedTableId]);

  const pinnedTableChartData = useMemo(() => {
    const result: CreativeChannelTableAsset[] = [];

    pinnedTableData?.advertisingTableProps?.data?.forEach(
      (row: CreativeChannelTableAsset) => {
        return result.push({
          data: row.data,
          legend: row.legend,
          rowId: row.rowId,
          label: '',
          diff: 0,
          previous: null,
          share: 0,
          total: 0,
        });
      }
    );

    return result;
  }, [pinnedTableData]);

  /**
   * Initialized checked state for each row in pinned table
   */
  useEffect(() => {
    const checkedRowIds = {} as TableId;

    pinnedTableChartData.forEach((row) => {
      checkedRowIds[row.rowId] = true;
    });

    setCheckedTableRowIds(checkedRowIds);
  }, [pinnedTableChartData, setCheckedTableRowIds]);

  const handleRowCheckToggle = useCallback(
    (rowId: number) => {
      setCheckedTableRowIds({
        ...checkedTableRowIds,
        [rowId]: !checkedTableRowIds[rowId],
      });
    },
    [setCheckedTableRowIds, checkedTableRowIds]
  );

  const filteredPinnedTableData = useMemo(() => {
    const pinnedTableDataClone = cloneDeep(pinnedTableData);

    pinnedTableDataClone?.advertisingTableProps?.data?.forEach(
      (row: CreativeChannelTableAsset) => {
        if (row.legend?.color?.checked) {
          // eslint-disable-next-line no-param-reassign
          row.legend.color.checked = checkedTableRowIds[row.rowId];
        }
      }
    );

    return pinnedTableDataClone;
  }, [pinnedTableData, checkedTableRowIds]);

  const filteredPinnedTableChartData = useMemo(() => {
    return pinnedTableChartData.filter((row) => checkedTableRowIds[row.rowId]);
  }, [pinnedTableChartData, checkedTableRowIds]);

  const handleClose = useCallback(() => {
    onMetricPanelClose();
    setChartHeaderData(null);
  }, [onMetricPanelClose]);

  const isLoading =
    spendDetailsLoading ||
    shareOfSpendDetailsLoading ||
    competitionAverageDetailsLoading ||
    eCPMDetailsLoading;

  return activeMetricPanel ? (
    <div className={styles.CreativeAdvertisingOverviewContainer}>
      {isLoading ? (
        <ComponentLoader minHeight={500} />
      ) : (
        <>
          <div className={styles.Heading}>
            <span className={styles.Title}>
              <div className={styles.Breadcrumb} onClick={handleClose}>
                Advertising
              </div>
              <div className={styles.CaretIcon}>
                <DynamicCaretIcon direction={CaretDirection.right} />
              </div>
              <div className={styles.Link}>{chartHeaderData?.title}</div>
            </span>

            <CloseIcon
              data-cy="creative-advertising-overview-close-icon"
              className={styles.CloseIcon}
              onClick={handleClose}
            />
          </div>

          <Grid container spacing={6} className={styles.Main}>
            <Grid item xs={8}>
              <div
                className={styles.ChartCard}
                data-cy="creative-advertising-chart-container"
              >
                <CreativeAdvertisingChartContainer
                  displayPercent={
                    chartHeaderData?.title === CreativeAdCardTypes.shareOfSpend
                  }
                  title={
                    filteredPinnedTableData?.advertisingTableProps?.headerTitle
                  }
                  percent={
                    filteredPinnedTableData?.advertisingTableProps?.headerDiff
                  }
                  total={
                    filteredPinnedTableData?.advertisingTableProps?.headerTotal
                  }
                >
                  <CreativeChannelLineChart
                    data-cy="creative-advertising-line-chart"
                    data={filteredPinnedTableChartData}
                  />
                </CreativeAdvertisingChartContainer>
              </div>
            </Grid>

            {filteredPinnedTableData?.advertisingTableProps && (
              <Grid item xs={4}>
                <div
                  className={styles.TableCard}
                  data-cy="advertising-table-pinned"
                >
                  <AdvertisingTable
                    {...filteredPinnedTableData?.advertisingTableProps}
                    tableId={filteredPinnedTableData.id}
                    onPinClick={setPinnedTableId}
                    onLegendClick={handleRowCheckToggle}
                    pinnedTableId={pinnedTableId}
                    showHeader={false}
                  />
                </div>
                <div className={styles.TableCardFooter}>
                  Percentages are rounded to the nearest whole number
                </div>
              </Grid>
            )}

            {tableData.map(
              (table) =>
                table.id !== pinnedTableId && (
                  <Grid item xs={4} key={table.id}>
                    <div
                      className={styles.TableCard}
                      data-cy="advertising-table-cards"
                    >
                      <AdvertisingTable
                        {...table.advertisingTableProps}
                        tableId={table.id}
                        onPinClick={setPinnedTableId}
                        onLegendClick={handleRowCheckToggle}
                        pinnedTableId={pinnedTableId}
                      />
                    </div>
                  </Grid>
                )
            )}
          </Grid>
        </>
      )}
    </div>
  ) : (
    <CreativeAdvertisingCarousel
      data={carouselData}
      handleCardClick={onCarouselCardClick}
      loading={carouselLoading}
      brandName={brandName}
    />
  );
};

export default CreativeAdvertisingOverviewContainer;
