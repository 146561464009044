import dayjs from 'dayjs';
import { Slider } from '@mui/material';
import { PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';
import { ComponentProps } from 'react';
import styles from './TimelineSlider.module.scss';

export type TimelineSliderProps = {
  startDate: Date;
  endDate: Date;
  stepsNumber: number;
  currentStep: number;
  isPlaying?: boolean;
  handleChange?: ComponentProps<typeof Slider>['onChange'];
  onButtonClick: () => void;
};

const TimelineSlider = ({
  startDate,
  endDate,
  stepsNumber,
  currentStep,
  isPlaying = false,
  handleChange,
  onButtonClick,
}: TimelineSliderProps) => {
  const formatDate = (date: Date) => dayjs(date).format('MMM D, YYYY');

  const TogglePlayingIcon = isPlaying ? PauseCircleOutline : PlayCircleOutline;

  return (
    <div className={styles.Wrapper}>
      <button type="button" onClick={onButtonClick} className={styles.Button}>
        <TogglePlayingIcon className={styles.PlayIcon} />
      </button>
      <span className={styles.DateLabel}>{formatDate(startDate)}</span>
      <div className={styles.Slider}>
        <Slider
          value={currentStep}
          onChange={handleChange}
          step={1}
          marks
          min={1}
          max={stepsNumber}
        />
      </div>
      <span className={styles.DateLabel}>{formatDate(endDate)}</span>
    </div>
  );
};

export default TimelineSlider;
