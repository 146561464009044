export default {
  TIMEFRAME_LABEL: 'timeframe_label',
  TIMEFRAME_UNIT: 'timeframe_unit',
  TIMEFRAME_END: 'timeframe_end',
  TIMEFRAME_QUANTITY: 'timeframe_quantity',
  TIMEFRAME_UPDATED_AT: 'timeframe_updated_at',

  COMPETITIVE_SET_KEY: 'competitiveSetKey',
  COMPETITOR_KEY: 'competitorKey',

  BRAND_ID: 'brandId',

  FACTOR_NAME: 'factorName',
  FACTOR_ID: 'factorId',
  SUBFACTOR_ID: 'subfactorId',

  METRIC_VARIABLE_ID: 'variableId',
  METRIC_GROUP_ID: 'groupId',
  METRIC_CATEGORY_ID: 'categoryId',
  METRIC_CHANNEL_ID: 'channelId',
  METRIC_SCORE_TYPE: 'scoreType',

  CREATIVE_CHANNELS_ID: 'channelId',
  CREATIVE_CHANNELS_ADVERTISING_ACTIVE_VIEW: 'activeView',
  CREATIVE_CHANNELS_SELECTED_BRAND_KEY: 'cc_brand_key',

  NONSESSION_TIMEFRAME_LABEL: 'ns_timeframe_label',
  NONSESSION_TIMEFRAME_UNIT: 'ns_timeframe_unit',
  NONSESSION_TIMEFRAME_END: 'ns_timeframe_end',
  NONSESSION_TIMEFRAME_QUANTITY: 'ns_timeframe_quantity',
  NONSESSION_TIMEFRAME_UPDATED_AT: 'ns_timeframe_updated_at',
  FILTER_KEY: 'filterKey',
  REDIRECT_TO: 'redirectTo',
};
