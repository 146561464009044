import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import styles from './Tooltip.module.scss';

export default function BNTooltip({ ...props }) {
  return (
    <Tooltip
      classes={{ tooltip: styles.Tooltip, arrow: styles.Arrow }}
      {...props}
    />
  );
}
