import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './DatePickerPresetItem.module.scss';

export default function DatePickerPresetItem({
  className,
  onClick,
  label,
  active,
  disabled,
  ...props
}) {
  return (
    <div
      className={classNames(
        styles.DatePickerPresetItem,
        active && styles.Active,
        disabled && styles.Disabled,
        className
      )}
      onClick={onClick}
      {...props}
    >
      {label}
    </div>
  );
}

DatePickerPresetItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};
