import { AnchorHTMLAttributes } from 'react';

const GridIcon = (props: AnchorHTMLAttributes<HTMLOrSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 2H6.5C6.776 2 7 2.224 7 2.5V6.5C7 6.776 6.776 7 6.5 7H2.5C2.224 7 2 6.776 2 6.5V2.5C2 2.224 2.224 2 2.5 2ZM3 6H6V3H3V6ZM10 2H14C14.276 2 14.5 2.224 14.5 2.5V6.5C14.5 6.776 14.276 7 14 7H10C9.724 7 9.5 6.776 9.5 6.5V2.5C9.5 2.224 9.724 2 10 2ZM10.5 6H13.5V3H10.5V6ZM21.5 2H17.5C17.224 2 17 2.224 17 2.5V6.5C17 6.776 17.224 7 17.5 7H21.5C21.776 7 22 6.776 22 6.5V2.5C22 2.224 21.776 2 21.5 2ZM21 6H18V3H21V6ZM2.5 9.5H6.5C6.776 9.5 7 9.724 7 10V14C7 14.276 6.776 14.5 6.5 14.5H2.5C2.224 14.5 2 14.276 2 14V10C2 9.724 2.224 9.5 2.5 9.5ZM3 13.5H6V10.5H3V13.5ZM14 9.5H10C9.724 9.5 9.5 9.724 9.5 10V14C9.5 14.276 9.724 14.5 10 14.5H14C14.276 14.5 14.5 14.276 14.5 14V10C14.5 9.724 14.276 9.5 14 9.5ZM13.5 13.5H10.5V10.5H13.5V13.5ZM17.5 9.5H21.5C21.776 9.5 22 9.724 22 10V14C22 14.276 21.776 14.5 21.5 14.5H17.5C17.224 14.5 17 14.276 17 14V10C17 9.724 17.224 9.5 17.5 9.5ZM18 13.5H21V10.5H18V13.5ZM6.5 17H2.5C2.224 17 2 17.224 2 17.5V21.5C2 21.776 2.224 22 2.5 22H6.5C6.776 22 7 21.776 7 21.5V17.5C7 17.224 6.776 17 6.5 17ZM6 21H3V18H6V21ZM10 17H14C14.276 17 14.5 17.224 14.5 17.5V21.5C14.5 21.776 14.276 22 14 22H10C9.724 22 9.5 21.776 9.5 21.5V17.5C9.5 17.224 9.724 17 10 17ZM10.5 21H13.5V18H10.5V21ZM21.5 17H17.5C17.224 17 17 17.224 17 17.5V21.5C17 21.776 17.224 22 17.5 22H21.5C21.776 22 22 21.776 22 21.5V17.5C22 17.224 21.776 17 21.5 17ZM21 21H18V18H21V21Z"
      fill="currentColor"
    />
  </svg>
);

export default GridIcon;
