import { SocialChannelKey } from '../interfaces/metric';
import LogoLinkedInCircle from '../assets/logos/LogoLinkedInCircle';
import LogoFacebookCircle from '../assets/logos/LogoFacebookCircle';
import LogoTwitterCircle from '../assets/logos/LogoTwitterCircle';
import LogoInstagramCircle from '../assets/logos/LogoInstagramCircle';
import LogoYoutubeCircle from '../assets/logos/LogoYoutubeCircle';

/* eslint-disable import/prefer-default-export */
export const SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST = [
  '1A13',
  '1B15',
  '1B18',
  '1B20',
  '1C13',
];

export const SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST = [
  '1A13',
  '1B18',
  '1B20',
  '1B25',
  '1C13',
];

export const SOCIAL_METRIC_RATE_METRIC_WHITELIST = ['1B15'];

export const SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS = {
  BASE: '1B25',
  ZZ: '1B2511ZZ',
};

export const SOCIAL_CHANNEL_BY_ID = {
  [SocialChannelKey.Aggregate]: {
    id: SocialChannelKey.Aggregate,
    name: 'Aggregate',
    logoComponent: <>aggregate</>,
  },
  [SocialChannelKey.Facebook]: {
    id: SocialChannelKey.Facebook,
    name: 'Facebook',
    logoComponent: <LogoFacebookCircle />,
  },
  [SocialChannelKey.Twitter]: {
    id: SocialChannelKey.Twitter,
    name: 'X', // Display the new brand name and keep the back-end references
    logoComponent: <LogoTwitterCircle />,
  },
  [SocialChannelKey.Youtube]: {
    id: SocialChannelKey.Youtube,
    name: 'YouTube',
    logoComponent: <LogoYoutubeCircle />,
  },
  [SocialChannelKey.LinkedIn]: {
    id: SocialChannelKey.LinkedIn,
    name: 'LinkedIn',
    logoComponent: <LogoLinkedInCircle />,
  },
  [SocialChannelKey.Instagram]: {
    id: SocialChannelKey.Instagram,
    name: 'Instagram',
    logoComponent: <LogoInstagramCircle />,
  },
};
