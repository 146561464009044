/* eslint-disable react/destructuring-assignment */
import Select, {
  CSSObjectWithLabel,
  StylesConfig,
  GroupBase,
  Props,
} from 'react-select';
import cn from 'classnames';
import styles from './DropdownSelect.module.scss';
import colors from '../../../../constants/colors';

export interface DropdownOption {
  label: string;
  value: string | number;
}

export default function DropdownSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const getBackgroundColor = (
    isSelected: boolean,
    isFocused: boolean
  ): string | undefined => {
    if (isSelected) return colors.colorPrimary20;
    if (isFocused) return colors.colorPrimary10;
    return undefined;
  };

  const getColor = (isSelected: boolean, isDisabled: boolean): string => {
    if (!isSelected && isDisabled) return colors.colorGray70;
    return colors.colorGray90;
  };

  const DropdownStyle: StylesConfig<Option, IsMulti, Group> = {
    indicatorSeparator: () => ({ display: 'none' }),
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      width: '100%',
      cursor: 'pointer',
      borderColor: colors.colorGray20,
      boxShadow: `none`,
      '&:hover': {
        boxShadow: `none`,
        borderColor: colors.colorGray40,
        color: colors.colorGray40,
      },
    }),
    dropdownIndicator: (base: CSSObjectWithLabel) => ({
      ...base,
      color: colors.colorGray20,
      '&:hover,&:active': {
        color: colors.colorGray40,
      },
    }),
    option: (
      base: CSSObjectWithLabel,
      {
        isDisabled,
        isSelected,
        isFocused,
      }: { isDisabled: boolean; isSelected: boolean; isFocused: boolean }
    ) => ({
      ...base,
      color: getColor(isSelected, isDisabled),
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: getBackgroundColor(isSelected, isFocused),
      padding: '12px 8px',
      '&:active': { backgroundColor: colors.colorPrimary20 },
    }),
  };

  return (
    <div className={styles.DropdownSelect}>
      <Select
        className={cn(styles.DropdownMenu, props.className)}
        styles={DropdownStyle}
        {...props}
      />
    </div>
  );
}
