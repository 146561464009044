import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';

import CreativeOverviewAdCard from '../Creative/CreativeOverviewAdCard/CreativeOverviewAdCard';

import styles from './CreativeChannelsOverview.module.scss';
import useCompetitiveSet from '../../../hooks/useCompetitiveSet';

export interface CreativeChannelsOverviewProps
  extends AnchorHTMLAttributes<HTMLDivElement> {}

const CreativeChannelsOverview: FunctionComponent<
  CreativeChannelsOverviewProps
> = ({ className }) => {
  const { brands } = useCompetitiveSet();

  return (
    <div className={classNames(styles.CreativeChannelsOverview, className)}>
      {brands?.map((brand) => (
        <CreativeOverviewAdCard
          key={brand.brandKey}
          brandName={brand.name}
          logoUrl={brand.logoUrl}
          brandKey={brand.brandKey}
        />
      ))}
    </div>
  );
};

export default CreativeChannelsOverview;
