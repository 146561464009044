import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

import SubfactorLineItem from '../../Molecules/SubfactorLineItem/SubfactorLineItem';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import FavoriteStar from '../../Atoms/FavoriteStar/FavoriteStar';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';

import { FACTOR_TYPES, FACTOR_TYPE_KEYS } from '../../../constants/factors';
import { HARDCODED_METRICS_ID_MAP } from '../../../constants/metrics';
import QUERY_PARAMS from '../../../constants/queryParams';

import BNContext from '../../../contexts/BNContext';
import useRouter from '../../../hooks/useRouter';

import styles from './MarketIndexBluescore.module.scss';

const FACTOR_TYPES_BY_KEY = keyBy(FACTOR_TYPES, 'KEY');
function MarketIndexBluescore({
  allUpScores,
  sectionDescription,
  loading,
  graphScale,
  ...props
}) {
  const { FEATURE_FLAGS, metricsFavoriteMap } = useContext(BNContext);
  const { updateRoute } = useRouter();

  function handleSubfactorClick(subfactorLabel) {
    if (!subfactorLabel) {
      return;
    }

    let subfactorKey = subfactorLabel.toLowerCase();

    if (subfactorKey === 'bluescore') {
      subfactorKey = FACTOR_TYPE_KEYS.OVERALL;
    }

    updateRoute({
      params: {
        [QUERY_PARAMS.FACTOR_ID]: FACTOR_TYPES_BY_KEY[subfactorKey].ID,
      },
      pathname: '/market-index/subfactors',
    });
  }

  return (
    <Box
      className={styles.MarketIndexBluescore}
      id="market-index-bluescore"
      {...props}
    >
      <Grid container>
        <Grid item xs={12} container alignItems="center">
          <div className={styles.TitleContainer}>
            <div className={styles.PageTitle}>
              {FEATURE_FLAGS.GLOBAL.METRICS_FAVORITE ? (
                <FavoriteStar
                  metricId={HARDCODED_METRICS_ID_MAP.BLUESCORE}
                  favorited={
                    metricsFavoriteMap[HARDCODED_METRICS_ID_MAP.BLUESCORE]
                  }
                />
              ) : null}

              <h2>Overview</h2>
            </div>

            <DownloadDropdown
              className={styles.DownloadDropdown}
              targetId="market-index-bluescore"
              json={allUpScores}
              fileName="market-index-bluescore"
              padding={100}
            />
          </div>
        </Grid>
      </Grid>

      <Box className={styles.SectionDescription}>
        {sectionDescription.map((paragraph) => (
          <p key={paragraph.substring(0, 4)} className={styles.Paragraph}>
            {paragraph}
          </p>
        ))}
      </Box>

      {loading ? <ComponentLoader minHeight={428} /> : null}

      {!loading && allUpScores ? (
        <ul
          className={styles.SubfactorLineList}
          data-testid="subfactor-line-list"
        >
          {allUpScores.map((factor) => (
            <li key={factor.subfactor} className={styles.SubfactorLineListItem}>
              <SubfactorLineItem
                data-testid="subfactor-line-item"
                score={factor.score}
                subfactor={
                  factor.subfactor !== 'bluescore'
                    ? factor.subfactor
                    : 'Blue Score'
                }
                scoreTimelineData={factor.scoresOverTime || []}
                competitorAverageScore={factor.competitorAverageScore}
                className={styles.LineItem}
                highlightLabel={factor.subfactor !== 'bluescore'}
                hideGraph={factor.subfactor === 'bluescore'}
                tooltip={
                  FACTOR_TYPES_BY_KEY[factor.subfactor.toLowerCase()]?.QUESTION
                }
                domainOverride={graphScale}
                onLabelClick={() => handleSubfactorClick(factor.subfactor)}
              />
            </li>
          ))}
        </ul>
      ) : null}

      {!loading && (!allUpScores || !allUpScores.length) ? (
        <NoResultsAlert />
      ) : null}
    </Box>
  );
}

MarketIndexBluescore.propTypes = {
  allUpScores: PropTypes.arrayOf(
    PropTypes.shape({
      subfactor: PropTypes.string,
      score: PropTypes.number,
      competitorAverageScore: PropTypes.number,
      scoresOverTime: PropTypes.arrayOf(
        PropTypes.shape({
          score: PropTypes.number,
          date: PropTypes.string,
          name: PropTypes.string,
        })
      ).isRequired,
    })
  ),
  sectionDescription: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  graphScale: PropTypes.shape({
    yDomain: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
};

export default memo(MarketIndexBluescore);
