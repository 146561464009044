import { AnchorHTMLAttributes } from 'react';

const ListIcon = (props: AnchorHTMLAttributes<HTMLOrSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 2H21.5C21.776 2 22 2.224 22 2.5V6.5C22 6.776 21.776 7 21.5 7H2.5C2.224 7 2 6.776 2 6.5V2.5C2 2.224 2.224 2 2.5 2ZM3 6H21V3H3V6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 9.5H21.5C21.776 9.5 22 9.724 22 10V14C22 14.276 21.776 14.5 21.5 14.5H2.5C2.224 14.5 2 14.276 2 14V10C2 9.724 2.224 9.5 2.5 9.5ZM3 13.5H21V10.5H3V13.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 17H21.5C21.776 17 22 17.224 22 17.5V21.5C22 21.776 21.776 22 21.5 22H2.5C2.224 22 2 21.776 2 21.5V17.5C2 17.224 2.224 17 2.5 17ZM3 21H21V18H3V21Z"
      fill="currentColor"
    />
  </svg>
);

export default ListIcon;
