import colors from '../../constants/colors';

export enum DynamicMetricDirection {
  Up = 'up',
  Down = 'down',
  Neutral = 'neutral',
}

const DynamicMetricIcon = ({
  direction,
}: {
  direction: DynamicMetricDirection;
}) => {
  let topFill = colors.iconMedium;
  let bottomFill = colors.iconMedium;

  if (direction === DynamicMetricDirection.Up) {
    topFill = colors.colorSecondary90;
    bottomFill = colors.iconMedium;
  }

  if (direction === DynamicMetricDirection.Down) {
    bottomFill = colors.colorSecondary90;
    topFill = colors.iconMedium;
  }
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0341 13.5C8.69775 13.5 8.0285 15.1157 8.97345 16.0607L11.3521 18.4393C11.9379 19.0251 12.8877 19.0251 13.4734 18.4393L15.8521 16.0607C16.7971 15.1157 16.1278 13.5 14.7915 13.5H10.0341Z"
        fill={bottomFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0341 10.5C8.69775 10.5 8.0285 8.88429 8.97345 7.93934L11.3521 5.56066C11.9379 4.97487 12.8877 4.97487 13.4734 5.56066L15.8521 7.93934C16.7971 8.88429 16.1278 10.5 14.7915 10.5H10.0341Z"
        fill={topFill}
      />
    </svg>
  );
};

export default DynamicMetricIcon;
