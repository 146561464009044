import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import AlertIcon from '../../Atoms/AlertIcon/AlertIcon';
import AlertList from '../../Molecules/AlertList/AlertList';

import { ALERT_ITEM_VARIANTS } from '../../../constants/props';

import styles from './TopNavAlertDropdown.module.scss';

export default function TopNavAlertDropdown({ className, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const alerts = useMemo(
    () => [
      {
        id: 0,
        variant: ALERT_ITEM_VARIANTS.METRIC_UP,
        read: false,
        message: 'This is a test user alert 1',
      },
      {
        id: 1,
        variant: ALERT_ITEM_VARIANTS.METRIC_DOWN,
        read: true,
        message: 'This is a test user alert 2',
      },
      {
        id: 2,
        variant: ALERT_ITEM_VARIANTS.INFO,
        read: false,
        message: 'This is a test user alert 3',
      },
    ],
    []
  );

  function handleButtonClick(event) {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div
      className={classNames(styles.TopNavAlertDropdown, className)}
      {...props}
    >
      <div className={styles.NavItemContainer} onClick={handleButtonClick}>
        <AlertIcon menuOpen={Boolean(anchorEl)} alertCount={1} />
      </div>

      <Popover
        id="top-navigation-alert-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        classes={{ paper: styles.Menu, root: styles.Overlay }}
        onBackdropClick={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...props}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <AlertList alerts={alerts} />
        </ClickAwayListener>
      </Popover>
    </div>
  );
}

TopNavAlertDropdown.propTypes = {
  className: PropTypes.string,
};
