/* eslint-disable import/prefer-default-export */

export enum SortType {
  Date = 'date',
  Spend = 'spend',
}

export const sortByOptions = [
  { label: 'Most Recent', value: SortType.Date },
  { label: 'Most Spend', value: SortType.Spend },
];
