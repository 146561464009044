import { Chart, ChartType, DefaultDataPoint } from 'chart.js';
import orderBy from 'lodash/orderBy';

const LINE_WIDTH = 1;
const LINE_COLOR = '#7F8B95';

const xHoverAnnotationLine = {
  id: 'x-hover-annotation-line',
  afterDatasetDraw: (
    chart: Chart<ChartType, DefaultDataPoint<ChartType>, unknown>
  ): void => {
    const activeElements = chart.getActiveElements();

    if (activeElements?.length) {
      const sortedActiveElements = orderBy(activeElements, 'element.y', 'desc');

      const pointX = sortedActiveElements[0].element.x;
      const pointRadius = sortedActiveElements[0].element.options
        .hoverRadius as number;

      const { ctx, chartArea } = chart;
      ctx.save();
      ctx.beginPath();

      ctx.lineWidth = LINE_WIDTH;
      ctx.strokeStyle = LINE_COLOR;

      for (let elIdx = 0; elIdx < sortedActiveElements.length; elIdx += 1) {
        const currentElement = sortedActiveElements[elIdx];
        const nextElement = sortedActiveElements[elIdx + 1];

        // on lowest value of all active points
        if (elIdx === 0) {
          ctx.moveTo(pointX, chartArea.bottom);

          ctx.lineTo(pointX, currentElement.element.y + pointRadius);
        }

        // on highest value of all active points
        if (elIdx === sortedActiveElements.length - 1) {
          ctx.moveTo(pointX, currentElement.element.y - pointRadius);

          ctx.lineTo(pointX, 0);
        }

        // between highest and lowest values of all active points
        // if next element exists and current element (lower) does not overlap next element (higher)
        if (
          nextElement &&
          currentElement.element.y - pointRadius >
            nextElement.element.y + pointRadius
        ) {
          ctx.moveTo(pointX, currentElement.element.y - pointRadius);

          ctx.lineTo(pointX, nextElement.element.y + pointRadius);
        }
      }

      ctx.stroke();
      ctx.restore();
    }
  },
};

export default xHoverAnnotationLine;
