import { useState, useEffect, useContext, useMemo } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { useLazyQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

import { Button } from '@blueoceanai/react-component-library';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import ArchetypeCard from '../../Molecules/ArchetypeCard/ArchetypeCard';
import ArchetypeChart from '../../Molecules/ArchetypeChart/ArchetypeChart';
import ArchetypeTimelineChart from '../../Molecules/ArchetypeTimelineChart/ArchetypeTimelineChart';
import ArchetypeCarousel from '../../Molecules/ArchetypeCarousel/ArchetypeCarousel';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import RadioSelector from '../../Molecules/RadioSelector/RadioSelector';
import RadioDateSelector from '../../Molecules/RadioDateSelector/RadioDateSelector';
import Insight from '../../Molecules/Insight/Insight';
import ModalDrawer from '../../Molecules/ModalDrawer/ModalDrawer';
import CreativeSetGoal from '../../Organisms/CreativeSetGoal/CreativeSetGoal';

import IconChartBar from '../../../assets/icons/IconChartBar';
import IconChartLineMultiple from '../../../assets/icons/IconChartLineMultiple';

import { handleGenericError } from '../../../utils/error';

import { BUTTON_VARIANTS, ORIENTATIONS } from '../../../constants/props';

import BNContext from '../../../contexts/BNContext';

import {
  GET_BRAND_PERSONALITY,
  GET_ARCHETYPE_TIMELINE,
} from '../../../api/queries/Pages/BrandPersonality';

import {
  prepareCurrentArchetypeData,
  prepareCompetitiveSetData,
  prepareArchetypesData,
  prepareArchetypeGoalData,
  prepareArchetypeTimelineData,
  prepareGoalArchetypeTimelineData,
} from '../../../api/transforms/Pages/BrandPersonality';

import styles from './BrandPersonality.module.scss';
import { BookIcon } from '../../../assets/icons';

const CHART_VIEWS = {
  CURRENT: 'current',
  EVOLUTION: 'evolution',
};

const DATA_CATEGORY_KEYS = {
  YOUR_BRAND: 'your-brand',
  COMPETITIVE_SET: 'competitive-set',
  YOUR_BENCHMARKS: 'your-benchmarks',
  YOUR_GOAL: 'your-goal',
};

export default function BrandPersonality({ className, ...props }) {
  const {
    competitiveSetID,
    currentSession,
    timeframe,
    accountKey,
    FEATURE_FLAGS,
  } = useContext(BNContext);

  const [
    getBrandPersonality,
    {
      error: getBrandPersonalityError,
      data: brandPersonalityData,
      loading: loadingBrandPersonalityData,
    },
  ] = useLazyQuery(GET_BRAND_PERSONALITY);

  const [
    getTopArchetypeTimeline,
    { error: topArchetypeTimelineError, data: topArchetypeTimelineResp },
  ] = useLazyQuery(GET_ARCHETYPE_TIMELINE);

  const [
    getCompareArchetypeTimeline,
    {
      error: compareArchetypeTimelineError,
      data: compareArchetypeTimelineResp,
    },
  ] = useLazyQuery(GET_ARCHETYPE_TIMELINE);

  const [dataCategory, setDataCategory] = useState(
    DATA_CATEGORY_KEYS.YOUR_BRAND
  );

  const [chartView, setChartView] = useState(CHART_VIEWS.CURRENT);

  useEffect(() => {
    getBrandPersonality({
      variables: {
        id: competitiveSetID,
        sessionKey: currentSession,
        accountKey,
      },
      fetchPolicy: 'no-cache',
    });

    setDataCategory(DATA_CATEGORY_KEYS.YOUR_BRAND);
    setChartView(CHART_VIEWS.CURRENT);
  }, [competitiveSetID, currentSession, accountKey, getBrandPersonality]);

  const currentArchetypeData = useMemo(
    () => prepareCurrentArchetypeData(brandPersonalityData),
    [brandPersonalityData]
  );

  const competitiveSetData = useMemo(
    () => prepareCompetitiveSetData(brandPersonalityData),
    [brandPersonalityData]
  );

  const archetypes = useMemo(
    () => prepareArchetypesData(brandPersonalityData),
    [brandPersonalityData]
  );

  const archetypesByName = useMemo(
    () => keyBy(archetypes, 'name'),
    [archetypes]
  );

  const [selectedCompareArchetype, setSelectedCompareArchetype] =
    useState(null);

  const compareArchetypeTimelineData = useMemo(
    () =>
      handleGenericError(
        () =>
          prepareArchetypeTimelineData(compareArchetypeTimelineResp, timeframe),
        'BrandPersonality prepareArchetypeTimelineData failed transform'
      ),
    [compareArchetypeTimelineResp, timeframe]
  );
  const goalArchetype = useMemo(() => {
    if (!archetypesByName || !brandPersonalityData) {
      return;
    }
    const archetype = prepareArchetypeGoalData(brandPersonalityData);

    return archetypesByName[archetype.name];
  }, [brandPersonalityData, archetypesByName]);

  const DATA_CATEGORY_OPTIONS = useMemo(() => {
    const result = [
      {
        name: 'Your Brand',
        key: DATA_CATEGORY_KEYS.YOUR_BRAND,
      },
      {
        name: 'Competitive Set',
        key: DATA_CATEGORY_KEYS.COMPETITIVE_SET,
      },
    ];

    if (
      FEATURE_FLAGS.CONTENT_ANALYSIS.BRAND_PERSONALITY.GOALS &&
      goalArchetype
    ) {
      result.push({
        name: 'Your Goal',
        key: DATA_CATEGORY_KEYS.YOUR_GOAL,
      });
    }

    return result;
  }, [FEATURE_FLAGS.CONTENT_ANALYSIS.BRAND_PERSONALITY.GOALS, goalArchetype]);

  const topArchetype = useMemo(() => {
    if (
      !currentArchetypeData ||
      !currentArchetypeData[0] ||
      !currentArchetypeData[0].points ||
      !archetypesByName
    ) {
      return;
    }

    return archetypesByName[
      currentArchetypeData[0].points[currentArchetypeData[0].points.length - 1]
        .name
    ];
  }, [currentArchetypeData, archetypesByName]);

  const topArchetypeTimelineData = useMemo(
    () =>
      handleGenericError(
        () => prepareArchetypeTimelineData(topArchetypeTimelineResp, timeframe),
        'BrandPersonality prepareArchetypeTimelineData failed transform'
      ),
    [topArchetypeTimelineResp, timeframe]
  );

  const compareableArchetypes = useMemo(
    () => archetypes.filter((archetype) => archetype.id !== topArchetype.id),
    [archetypes, topArchetype]
  );

  const goalArchetypeTimelineData = useMemo(
    () =>
      handleGenericError(
        () => prepareGoalArchetypeTimelineData(brandPersonalityData, timeframe),
        'BrandPersonality prepareArchetypeTimelineData failed transform'
      ),
    [brandPersonalityData, timeframe]
  );
  useEffect(() => {
    if (!compareableArchetypes) {
      return;
    }

    if (!selectedCompareArchetype) {
      setSelectedCompareArchetype(compareableArchetypes[0]);
    }
  }, [compareableArchetypes, selectedCompareArchetype]);

  useDeepCompareEffectNoCheck(() => {
    if (!selectedCompareArchetype || !competitiveSetID || !currentSession) {
      return;
    }

    getCompareArchetypeTimeline({
      variables: {
        id: competitiveSetID,
        sessionKey: currentSession,
        associatedTypeId: selectedCompareArchetype.associatedTypeId,
      },
    });
  }, [selectedCompareArchetype]);

  useDeepCompareEffectNoCheck(() => {
    if (!topArchetype || !competitiveSetID || !currentSession) {
      return;
    }

    getTopArchetypeTimeline({
      variables: {
        id: competitiveSetID,
        sessionKey: currentSession,
        associatedTypeId: topArchetype.associatedTypeId,
      },
    });
  }, [topArchetype]);

  useDeepCompareEffectNoCheck(() => {
    if (getBrandPersonalityError) {
      // eslint-disable-next-line
      console.error('getBrandPersonality', getBrandPersonalityError);
    }
  }, [getBrandPersonalityError]);

  useDeepCompareEffectNoCheck(() => {
    if (compareArchetypeTimelineError) {
      // eslint-disable-next-line no-console
      console.error(compareArchetypeTimelineError);
    }

    if (topArchetypeTimelineError) {
      // eslint-disable-next-line no-console
      console.error(topArchetypeTimelineError);
    }
  }, [topArchetypeTimelineError, compareArchetypeTimelineError]);

  const [goalModalOpen, setGoalModalOpen] = useState(false);

  function handleSetAGoalClick() {
    setGoalModalOpen(true);
  }

  function handleSetAGoalClose() {
    setGoalModalOpen(false);
  }

  function handleDataCategoryChange(newCat) {
    setDataCategory(newCat.key);
  }

  function handleChartViewChange() {
    if (chartView === CHART_VIEWS.EVOLUTION) {
      setChartView(CHART_VIEWS.CURRENT);
    } else {
      setChartView(CHART_VIEWS.EVOLUTION);
    }
  }

  function handleGoalArchetypeChange() {
    getBrandPersonality({
      variables: {
        id: competitiveSetID,
        sessionKey: currentSession,
        accountKey,
      },
      fetchPolicy: 'no-cache',
    });

    setDataCategory(DATA_CATEGORY_KEYS.YOUR_GOAL);

    handleSetAGoalClose();
  }

  function handleComparingArchetypeChange(archetype) {
    setSelectedCompareArchetype(find(archetypes, { id: archetype.value }));
  }

  const isLoading = useMemo(
    () =>
      loadingBrandPersonalityData ||
      !archetypes ||
      !archetypesByName ||
      !currentArchetypeData ||
      !competitiveSetData ||
      !selectedCompareArchetype ||
      !compareArchetypeTimelineData ||
      !topArchetypeTimelineData ||
      !topArchetype,
    [
      loadingBrandPersonalityData,
      archetypes,
      archetypesByName,
      currentArchetypeData,
      competitiveSetData,
      topArchetype,
      selectedCompareArchetype,
      compareArchetypeTimelineData,
      topArchetypeTimelineData,
    ]
  );

  return (
    <div
      className={classNames(
        styles.BrandPersonality,
        chartView === CHART_VIEWS.CURRENT ? styles.Current : null,
        chartView === CHART_VIEWS.EVOLUTION ? styles.Evolution : null,
        'PageContainer',
        className
      )}
      {...props}
    >
      <Box className={classNames(styles.Header, 'PageTitleContainer')}>
        <h1>What is your brand personality?</h1>

        <Box className={styles.CtaContainer}>
          {FEATURE_FLAGS.CONTENT_ANALYSIS.BRAND_PERSONALITY.GOALS ? (
            <Box mr={2} className="hide-from-share">
              <Button
                variant={BUTTON_VARIANTS.TERTIARY}
                onClick={handleSetAGoalClick}
              >
                {goalArchetype ? 'Edit Goal' : 'Set a Goal'}
              </Button>
            </Box>
          ) : null}

          <DownloadDropdown
            targetId="creative-brand-personality-screenshot-container"
            padding={100}
          />
        </Box>
      </Box>

      {isLoading ? <ComponentLoader /> : null}

      {!isLoading ? (
        <Box className={styles.Body}>
          <Grid container spacing={10}>
            <Grid
              container
              item
              xs={12}
              lg={9}
              spacing={10}
              id="creative-brand-personality-screenshot-container"
            >
              <Grid item xs={12} className="add-to-export">
                <div className={styles.Header}>
                  <h1>What is your brand personality?</h1>
                </div>
              </Grid>
              <Grid item xs={12} lg={4}>
                <ArchetypeCard
                  className={styles.Card}
                  flippable={false}
                  archetype={topArchetype}
                />
                <Box className={styles.Section}>
                  <h2 className={styles.Label}>BRAND VOICE</h2>
                  <p className={styles.Copy}>
                    {topArchetype.voiceTags.join(', ')}
                  </p>
                </Box>
                <Box className={styles.Section}>
                  <h2 className={styles.Label}>BRAND MESSAGE</h2>
                  <p className={styles.Copy}>{topArchetype.message}</p>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={
                  dataCategory === DATA_CATEGORY_KEYS.YOUR_BRAND ||
                  dataCategory === DATA_CATEGORY_KEYS.COMPETITIVE_SET
                    ? 8
                    : 12
                }
              >
                <Box className={styles.GraphControlLvl0}>
                  <RadioSelector
                    items={DATA_CATEGORY_OPTIONS}
                    defaultSelectedIndex={findIndex(DATA_CATEGORY_OPTIONS, {
                      key: dataCategory,
                    })}
                    onChange={handleDataCategoryChange}
                  />

                  {chartView === CHART_VIEWS.EVOLUTION &&
                  dataCategory === DATA_CATEGORY_KEYS.YOUR_BRAND ? (
                    <Box className={styles.ChartChanger}>
                      <span className={styles.Label}>View current:</span>
                      <Box
                        className={styles.IconWrap}
                        ml={3}
                        onClick={handleChartViewChange}
                      >
                        <IconChartBar className={styles.Icon} />
                      </Box>
                    </Box>
                  ) : null}

                  {chartView === CHART_VIEWS.CURRENT &&
                  dataCategory === DATA_CATEGORY_KEYS.YOUR_BRAND ? (
                    <Box className={styles.ChartChanger}>
                      <span className={styles.Label}>View evolution:</span>
                      <Box
                        className={styles.IconWrap}
                        ml={3}
                        onClick={handleChartViewChange}
                      >
                        <IconChartLineMultiple className={styles.Icon} />
                      </Box>
                    </Box>
                  ) : null}
                </Box>

                {(dataCategory === DATA_CATEGORY_KEYS.YOUR_BRAND &&
                  chartView === CHART_VIEWS.EVOLUTION) ||
                dataCategory === DATA_CATEGORY_KEYS.YOUR_GOAL ? (
                  <Box className={styles.GraphControlLvl1}>
                    <RadioDateSelector />
                  </Box>
                ) : null}

                {dataCategory === DATA_CATEGORY_KEYS.YOUR_BRAND ? (
                  <Box className={styles.Chart}>
                    {chartView === CHART_VIEWS.CURRENT ? (
                      <ArchetypeChart
                        data={currentArchetypeData}
                        orientation={ORIENTATIONS.VERTICAL}
                      />
                    ) : null}

                    {chartView === CHART_VIEWS.EVOLUTION ? (
                      <ArchetypeTimelineChart
                        topArchetype={topArchetype}
                        topArchetypeTrend={topArchetypeTimelineData}
                        onComparingArchetypeChange={
                          handleComparingArchetypeChange
                        }
                        comparingArchetype={selectedCompareArchetype}
                        comparingArchetypeTrend={compareArchetypeTimelineData}
                        archetypes={compareableArchetypes}
                      />
                    ) : null}
                  </Box>
                ) : null}

                {dataCategory === DATA_CATEGORY_KEYS.COMPETITIVE_SET ? (
                  <Box className={styles.Chart}>
                    <ArchetypeChart
                      data={competitiveSetData}
                      orientation={ORIENTATIONS.VERTICAL}
                    />
                  </Box>
                ) : null}

                {goalArchetype &&
                dataCategory === DATA_CATEGORY_KEYS.YOUR_GOAL ? (
                  <Box className={styles.Chart}>
                    <ArchetypeTimelineChart
                      topArchetype={topArchetype}
                      topArchetypeTrend={topArchetypeTimelineData}
                      comparingArchetype={goalArchetype}
                      comparingArchetypeTrend={goalArchetypeTimelineData}
                      staticLegend
                    />
                  </Box>
                ) : null}
              </Grid>
            </Grid>

            {dataCategory === DATA_CATEGORY_KEYS.YOUR_BRAND ||
            dataCategory === DATA_CATEGORY_KEYS.COMPETITIVE_SET ? (
              <Grid item xs={12} lg={3}>
                <Insight
                  icon={BookIcon}
                  title="Good to Know!"
                  copy={[
                    'Welcome to our Myers-Briggs for Brand.',
                    "Archetypes help you understand you brand's personality and drive your brands look, tone and feel.",
                    'Start charting a course to the archetype you want to be or just make sure you consistently remain the archetype you are today.',
                    'Or, create new category benchmarks and see how your archetype compares with your competitors or brands you may want to emulate that are outside your category.',
                  ]}
                  ctaButtonText=""
                  ctaText=""
                />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <h2>Explore all the brand archetypes</h2>
              <Box mt={8}>
                <ArchetypeCarousel archetypes={archetypes} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null}

      <ModalDrawer
        title="Set a Goal"
        open={goalModalOpen}
        onClose={handleSetAGoalClose}
      >
        <CreativeSetGoal
          currentGoalArchetype={goalArchetype}
          archetypes={archetypes}
          loading={isLoading}
          onCancel={handleSetAGoalClose}
          onSuccess={handleGoalArchetypeChange}
        />
      </ModalDrawer>
    </div>
  );
}

BrandPersonality.propTypes = {
  className: PropTypes.string,
};
