import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ALERT_ITEM_VARIANTS } from '../../../constants/props';

import { MetricsDown, MetricsUp, Recommendations } from '../../../assets/icons';

import styles from './AlertListItem.module.scss';

function AlertListItem({ className, variant, read, message, ...props }) {
  return (
    <li
      className={classNames(
        styles.AlertListItem,
        variant === ALERT_ITEM_VARIANTS.METRIC_UP ? styles.MetricUp : null,
        variant === ALERT_ITEM_VARIANTS.METRIC_DOWN ? styles.MetricDown : null,
        variant === ALERT_ITEM_VARIANTS.INFO ? styles.Info : null,
        className
      )}
      {...props}
    >
      <div className={styles.ContentLeft}>
        <div className={styles.IconContainer}>
          {variant === ALERT_ITEM_VARIANTS.METRIC_UP ? (
            <MetricsUp className={styles.Icon} />
          ) : null}
          {variant === ALERT_ITEM_VARIANTS.METRIC_DOWN ? (
            <MetricsDown className={styles.Icon} />
          ) : null}
          {variant === ALERT_ITEM_VARIANTS.INFO ? (
            <Recommendations className={styles.Icon} />
          ) : null}
        </div>

        <span className={styles.Message}>{message}</span>
      </div>

      <div className={styles.ContentRight}>
        {read ? <div className={styles.ReadIndicator} /> : null}
      </div>
    </li>
  );
}

AlertListItem.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  read: PropTypes.bool,
  message: PropTypes.string,
};

export default memo(AlertListItem);
