export interface Color {
  DEFAULT: string;
}

export enum PaletteType {
  Ocean = 'OCEAN',
  Sunset = 'SUNSET',
}

export const OCEAN: Color[] = [
  {
    DEFAULT: '#1097CF',
  },
  {
    DEFAULT: '#1D566D',
  },
  {
    DEFAULT: '#88CED8',
  },
  {
    DEFAULT: '#16988C',
  },
  {
    DEFAULT: '#A5D4B4',
  },
  {
    DEFAULT: '#5A5A83',
  },
  {
    DEFAULT: '#A7B9D8',
  },
  {
    DEFAULT: '#606D66',
  },
  {
    DEFAULT: '#A4B9AE',
  },
  {
    DEFAULT: '#836A98',
  },
  {
    DEFAULT: '#093B61',
  },
  {
    DEFAULT: '#257696',
  },
  {
    DEFAULT: '#3AB4CD',
  },
  {
    DEFAULT: '#84D1D2',
  },
  {
    DEFAULT: '#B4EBDC',
  },
];

export const SUNSET: Color[] = [
  {
    DEFAULT: '#1D627F',
  },
  {
    DEFAULT: '#FFCF82',
  },
  {
    DEFAULT: '#F4955E',
  },
  {
    DEFAULT: '#9A4E89',
  },
  {
    DEFAULT: '#E38885',
  },
  {
    DEFAULT: '#CD4374',
  },
  {
    DEFAULT: '#D990C8',
  },
  {
    DEFAULT: '#5A478B',
  },
  {
    DEFAULT: '#C1B3E6',
  },
  {
    DEFAULT: '#54ABBB',
  },
];

export interface Palette {
  [PaletteType.Ocean]: Color[];
  [PaletteType.Sunset]: Color[];
}

const PALETTES: Palette = { OCEAN, SUNSET };

export default PALETTES;
