import { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import { OCEAN } from '../constants/palettes';
import { ChartColor } from '../interfaces';

export interface Brand {
  key: string;
  name: string;
}

export default function useChartBrandColorsMap({
  heroBrandKey,
  brands,
}: {
  heroBrandKey: Brand['key'];
  brands: Brand[];
}): { [key: string]: ChartColor } {
  return useMemo(() => {
    const result = {
      [heroBrandKey]: OCEAN[0],
    };

    const sortedBrands = orderBy(brands, 'name', 'asc');

    for (let i = 0; i < sortedBrands.length && i + 1 < OCEAN.length; i += 1) {
      const brand = sortedBrands[i];

      if (brand && brand.key !== heroBrandKey) {
        result[brand.key] = OCEAN[i + 1];
      }
    }

    return result;
  }, [heroBrandKey, brands]);
}
