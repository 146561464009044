import { ChartData, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import genericBarChartTooltip from '../../../chartjs/tooltips/genericBarChartTooltip/genericBarChartTooltip';
import {
  ChartTooltipContextBar,
  TickFormatter,
  YAxisFormatter,
} from '../../../interfaces';
import colors from '../../../constants/colors';

export interface GenericBarChartProps {
  data: ChartData;
  options?: ChartOptions;
  tooltipFormatter: YAxisFormatter;
}

export const defaultTickFormatter: TickFormatter = (
  index: number | string,
  axisLabels: string[]
): string => {
  const isFirstOrLast = index === 0 || index === axisLabels.length - 1;
  if (isFirstOrLast) {
    return axisLabels[index];
  }

  return '';
};

const GenericBarChart = ({
  data,
  options,
  tooltipFormatter,
}: GenericBarChartProps) => {
  const combinedOptions = {
    scales: {
      x: {
        grid: { display: false },
        ticks: {
          callback: (index: number | string) =>
            defaultTickFormatter(index, data?.labels as string[]),
        },
      },
    },
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        enabled: false,
        external: (context: ChartTooltipContextBar) =>
          genericBarChartTooltip(context, tooltipFormatter),
      },
    },
    backgroundColor: colors.colorPrimary70,
    borderRadius: 2,
    ...options,
  };

  return (
    <Bar
      options={combinedOptions as ChartOptions<'bar'>}
      data={data as ChartData<'bar'>}
    />
  );
};

export default GenericBarChart;
