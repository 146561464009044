import { useState, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import concat from 'lodash/concat';
import keyBy from 'lodash/keyBy';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@mui/material/Box';

import TableLabel from '../../Molecules/TableLabel/TableLabel';
import BrandDatasetFilterItem from '../../ChartUtils/BrandDatasetFilterItem';
import ScoreTile from '../../ScoreDisplays/ScoreTile';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';
import Tooltip from '../../Tooltips/Tooltip';

import { SORT_DIRECTIONS } from '../../../constants/props';
import { FACTOR_TYPES } from '../../../constants/factors';
import { tableColumns } from '../../../constants/scorecardGridTable';
import QUERY_PARAMS from '../../../constants/queryParams';

import useRouter from '../../../hooks/useRouter';

import styles from './ScorecardGrid.module.scss';

const FACTOR_TYPES_BY_KEY = keyBy(FACTOR_TYPES, 'KEY');

function ScorecardGrid({
  rows,
  customerBrandKey,
  className,
  loading,
  ...props
}) {
  const { updateRoute } = useRouter();

  const [sortState, setSortState] = useState({
    selectedColumnIdx: 0,
    direction: SORT_DIRECTIONS.DESC,
  });
  const [hiddenBrandRows, setHiddenBrandRows] = useState({});

  const sortedTableData = useMemo(() => {
    if (!rows) {
      return null;
    }

    let tableDataClone = cloneDeep(rows);

    const customerBrand = remove(
      tableDataClone,
      (row) => row.brand.brandKey === customerBrandKey
    );

    const hiddenBrands = remove(
      tableDataClone,
      (row) => hiddenBrandRows[row.brand.brandKey]
    );

    if (sortState.selectedColumnIdx !== null) {
      if (sortState.direction === SORT_DIRECTIONS.ASC) {
        // eslint-disable-next-line
        tableDataClone.sort((row1, row2) => {
          const columnKey = tableColumns[sortState.selectedColumnIdx].key;
          const colVal1 = row1[columnKey];
          const colVal2 = row2[columnKey];

          if (colVal1.score < colVal2.score) return -1;
          if (colVal1.score > colVal2.score) return 1;
          if (colVal1.score === colVal2.score) return 0;
        });
      } else {
        // eslint-disable-next-line
        tableDataClone.sort((row1, row2) => {
          const columnKey = tableColumns[sortState.selectedColumnIdx].key;
          const colVal1 = row1[columnKey];
          const colVal2 = row2[columnKey];

          if (colVal1.score > colVal2.score) return -1;
          if (colVal1.score < colVal2.score) return 1;
          if (colVal1.score === colVal2.score) return 0;
        });
      }
    }

    tableDataClone = concat(customerBrand, tableDataClone, hiddenBrands);

    return tableDataClone;
  }, [customerBrandKey, hiddenBrandRows, sortState, rows]);

  function handleLabelClick(colIndex) {
    const result = { ...sortState };

    if (sortState.selectedColumnIdx === colIndex) {
      result.direction =
        sortState.direction === SORT_DIRECTIONS.ASC
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASC;
    } else {
      result.selectedColumnIdx = colIndex;
      result.direction = SORT_DIRECTIONS.ASC;
    }

    setSortState(result);
  }

  function handleCheckboxChange(checked, brandId) {
    const hiddenBrandRowsClone = cloneDeep(hiddenBrandRows);

    hiddenBrandRowsClone[brandId] = checked;

    setHiddenBrandRows(hiddenBrandRowsClone);
  }

  function handleBrandDatasetFilterItemClick(factorName) {
    if (!FACTOR_TYPES_BY_KEY?.[factorName]) {
      // eslint-disable-next-line no-console
      console.error(
        `Factor ${factorName} was not found in factor types map upon brand tile click.`
      );
      return;
    }

    updateRoute({
      pathname: '/market-index/subfactors',
      params: {
        [QUERY_PARAMS.FACTOR_ID]: FACTOR_TYPES_BY_KEY[factorName].ID,
        [QUERY_PARAMS.FACTOR_NAME]: null,
        [QUERY_PARAMS.SUBFACTOR_ID]: null,
      },
    });
  }

  function handleCompetitorTileClick(factorName, competitorKey) {
    updateRoute({
      pathname: '/market-index/compare',
      params: {
        [QUERY_PARAMS.FACTOR_NAME]: factorName,
        [QUERY_PARAMS.FACTOR_ID]: null,
        [QUERY_PARAMS.SUBFACTOR_ID]: null,
        [QUERY_PARAMS.COMPETITOR_KEY]: competitorKey,
      },
    });
  }

  const brandCell = classNames(styles.Cell, styles.Brand);

  return (
    <TableContainer
      className={styles.ScorecardGrid}
      elevation={0}
      component={Box}
    >
      {loading || !customerBrandKey || !sortedTableData ? (
        <ComponentLoader minHeight={400} />
      ) : null}

      {!loading &&
      (!customerBrandKey ||
        !sortedTableData ||
        !sortedTableData.length ||
        !sortedTableData[0].brand ||
        !sortedTableData[0].overall ||
        !sortedTableData[0].familiar ||
        !sortedTableData[0].unique ||
        !sortedTableData[0].consistent ||
        !sortedTableData[0].relevant ||
        !sortedTableData[0].revered) ? (
        <NoResultsAlert />
      ) : null}

      {!loading &&
      customerBrandKey &&
      sortedTableData &&
      sortedTableData.length &&
      sortedTableData[0].brand &&
      sortedTableData[0].overall &&
      sortedTableData[0].familiar &&
      sortedTableData[0].unique &&
      sortedTableData[0].consistent &&
      sortedTableData[0].relevant &&
      sortedTableData[0].revered ? (
        <Table
          className={classNames(styles.Table, className)}
          aria-label="Scorecard Grid"
          {...props}
        >
          <TableHead className={styles.Head}>
            <TableRow>
              <TableCell className={styles.Cell} />
              {tableColumns.map((tableCol, index) => (
                <TableCell
                  key={tableCol.label}
                  className={classNames(styles.Cell, styles.ScoreTileCell)}
                  align="center"
                >
                  <Box className={styles.LabelContainer}>
                    <TableLabel
                      className={styles.Label}
                      onClick={() => handleLabelClick(index)}
                      direction={
                        index === sortState.selectedColumnIdx
                          ? sortState.direction
                          : SORT_DIRECTIONS.NONE
                      }
                      name={tableCol.label.toUpperCase()}
                    />
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={styles.Body}>
            {sortedTableData.map((row, index) => (
              <TableRow className={styles.Row} key={row.brand.brandKey}>
                <TableCell className={brandCell} component="th" scope="row">
                  <BrandDatasetFilterItem
                    name={row.brand.name}
                    brandId={row.brand.brandKey}
                    logoUrl={row.brand.logoUrl}
                    hideable={!(customerBrandKey === row.brand.brandKey)}
                    onFilterChange={handleCheckboxChange}
                    checked={hiddenBrandRows[row.brand.brandKey]}
                  />
                </TableCell>
                {tableColumns.map((tableCol) => (
                  <TableCell
                    className={classNames(
                      styles.Cell,
                      hiddenBrandRows[row.brand.brandKey]
                        ? styles.Hidden
                        : null,
                      styles.ScoreTileCell
                    )}
                    align="center"
                    key={`${row.brand.brandKey}-${tableCol.key}`}
                  >
                    {customerBrandKey === row.brand.brandKey &&
                    FACTOR_TYPES_BY_KEY[tableCol.label.toLowerCase()]
                      ?.QUESTION ? (
                      <Tooltip
                        title={
                          customerBrandKey === row.brand.brandKey
                            ? FACTOR_TYPES_BY_KEY[tableCol.label.toLowerCase()]
                                ?.QUESTION
                            : null
                        }
                        placement="right"
                        arrow
                      >
                        <div>
                          <ScoreTile
                            className={classNames(styles.Tile)}
                            value={row[tableCol.key].score}
                            delta={Math.round(row[tableCol.key].delta)}
                            fullWidth
                            onClick={
                              index > 0
                                ? () =>
                                    handleCompetitorTileClick(
                                      tableCol.label.toLowerCase(),
                                      row.brand.brandKey
                                    )
                                : () =>
                                    handleBrandDatasetFilterItemClick(
                                      tableCol.label
                                    )
                            }
                            wide
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <ScoreTile
                        className={classNames(styles.Tile)}
                        value={row[tableCol.key].score}
                        delta={Math.round(row[tableCol.key].delta)}
                        fullWidth
                        onClick={
                          index > 0
                            ? () =>
                                handleCompetitorTileClick(
                                  tableCol.label.toLowerCase(),
                                  row.brand.brandKey
                                )
                            : () =>
                                handleBrandDatasetFilterItemClick(
                                  tableCol.label
                                )
                        }
                        wide
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}
    </TableContainer>
  );
}

ScorecardGrid.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.shape({
        name: PropTypes.string,
      }),
      overall: PropTypes.shape({
        score: PropTypes.number,
        delta: PropTypes.number,
      }),
      familiar: PropTypes.shape({
        score: PropTypes.number,
        delta: PropTypes.number,
      }),
      unique: PropTypes.shape({
        score: PropTypes.number,
        delta: PropTypes.number,
      }),
      consistent: PropTypes.shape({
        score: PropTypes.number,
        delta: PropTypes.number,
      }),
      relevant: PropTypes.shape({
        score: PropTypes.number,
        delta: PropTypes.number,
      }),
      revered: PropTypes.shape({
        score: PropTypes.number,
        delta: PropTypes.number,
      }),
    })
  ),
  customerBrandKey: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default memo(ScorecardGrid);
