import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import { CHART_LEGEND_ICON_VARIANT } from '../../../constants/props';
import { ChartColor } from '../../../interfaces';

import styles from './ChartLegendIcon.module.scss';

export interface ChartLegendIconProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  variant: string;
  chartColor: ChartColor;
}

const ChartLegendIcon: FunctionComponent<ChartLegendIconProps> = ({
  className,
  variant = 'square',
  chartColor = {
    DEFAULT: 'black',
    FILL: 'gray',
  },
  ...props
}) => {
  const style = {
    backgroundColor: chartColor.DEFAULT,
  };

  return (
    <div className={classNames(styles.ChartLegendIcon, className)} {...props}>
      {variant === CHART_LEGEND_ICON_VARIANT.SQUARE ? (
        <div className={styles.Square} style={style} />
      ) : null}

      {variant === CHART_LEGEND_ICON_VARIANT.DOT_LINE ? (
        <div className={styles.DotLine}>
          <div className={styles.Line} style={style} />
          <div
            className={styles.Oval}
            style={{
              borderColor: chartColor.DEFAULT,
              backgroundColor: chartColor.FILL,
            }}
          />
        </div>
      ) : null}

      {variant === CHART_LEGEND_ICON_VARIANT.DASHED_LINE ? (
        <div className={styles.DashedLine}>
          <div className={styles.Line} style={style} />
          <div className={styles.Line} style={style} />
          <div className={styles.Line} style={style} />
        </div>
      ) : null}

      {variant === CHART_LEGEND_ICON_VARIANT.AREA ? (
        <div
          className={styles.Area}
          style={{
            borderColor: chartColor.DEFAULT,
            backgroundColor: chartColor.FILL,
          }}
        />
      ) : null}

      {variant === CHART_LEGEND_ICON_VARIANT.BUBBLE ? (
        <div
          className={styles.Bubble}
          style={{
            backgroundColor: chartColor.DEFAULT,
          }}
        />
      ) : null}
    </div>
  );
};

export default ChartLegendIcon;
