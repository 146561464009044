import { MetricUnit } from '../../interfaces/metric';
import { metricUnitFormattersByType } from '../../utils/metric';
import { abbreviateNumber } from '../../utils/number';

const buffer = 10;

export const positionTooltipArrow = (clientHeight: number): string => {
  return `${clientHeight / 2 - buffer}px`;
};

export const positionTop = (
  positionY: number,
  caretY: number,
  clientHeight: number
): string => {
  return `${positionY + caretY - clientHeight / 2}px`;
};

export const positionLeft = (
  positionX: number,
  caretX: number,
  clientHeight: number,
  isCenterOrLeft: boolean,
  tooltipWidth: number
): string => {
  const leftBuffer = isCenterOrLeft ? buffer : -buffer;
  let leftValue = positionX + caretX + clientHeight / 2 + leftBuffer;
  // if tooltip will be cut off
  if (leftValue + tooltipWidth > window.innerWidth) {
    leftValue = Math.ceil(window.innerWidth - tooltipWidth) + leftBuffer;
  }
  return `${leftValue}px`;
};

export const numberToAbbreviatedCurrency = (num: number | string): string => {
  const currency = abbreviateNumber(num, 2);
  return metricUnitFormattersByType[MetricUnit.USD](currency);
};
