import { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@material-ui/core';
import PastReportsArchiveList from '../../Molecules/PastReportsArchiveList/PastReportsArchiveList';

import styles from './PastReportsDropdown.module.scss';
import { ReportPageIcon } from '../../../assets/icons';

export default function PastReportsDropdown({
  className,
  onOpenChange,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    onOpenChange(isOpen);
  }, [isOpen, onOpenChange]);

  return (
    <div
      className={classNames(styles.PastReportsDropdown, className)}
      {...props}
    >
      <div className={styles.IconContainer} onClick={() => setIsOpen(!isOpen)}>
        <ReportPageIcon className={styles.Icon} title="Reports" />
      </div>

      {isOpen && (
        <ClickAwayListener onClickAway={() => setIsOpen(false)}>
          <div className={styles.Dropdown}>
            <div className={styles.Header}>
              <p>Past Report Archive</p>
            </div>

            <div className={styles.Body}>
              <PastReportsArchiveList className={styles.PastReportsList} />
            </div>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}

PastReportsDropdown.propTypes = {
  className: PropTypes.string,
  onOpenChange: PropTypes.func,
};
