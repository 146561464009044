import last from 'lodash/last';
import head from 'lodash/head';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import forEach from 'lodash/forEach';
import first from 'lodash/first';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

import { isBetween } from '../../../utils/timeframe';

dayjs.extend(isSameOrAfter);

export function prepareFactors(data) {
  if (!data?.competitiveSet?.session) {
    return;
  }
  return data.competitiveSet.session.factors;
}

export function prepareSubfactorScores(subfactorsData, timeframe) {
  if (!subfactorsData?.competitiveSet?.session) {
    return null;
  }

  const subfactorJSON = JSON.parse(
    subfactorsData.competitiveSet.session.subfactorsCalc[0].t4Quarters
  );

  const dateFilteredSubfactors = subfactorJSON
    .map((subfactor) => ({ ...subfactor, date: new Date(subfactor.date) }))
    .filter((subfactor) => isBetween(subfactor.date, timeframe));

  if (!dateFilteredSubfactors) {
    return null;
  }

  const dateSortedSubfactors = orderBy(dateFilteredSubfactors, 'date', 'desc');
  const subfactorsGroupedByDate = groupBy(dateFilteredSubfactors, 'date');

  if (!dateSortedSubfactors || !subfactorsGroupedByDate) {
    return null;
  }

  const mostRecentSubfactorDate = dateSortedSubfactors?.[0].date;

  if (!mostRecentSubfactorDate) {
    return null;
  }

  const mostRecentSubfactors = subfactorsGroupedByDate[mostRecentSubfactorDate];

  if (!mostRecentSubfactors) {
    return null;
  }

  const result = {};

  mostRecentSubfactors.forEach((subfactor) => {
    result[subfactor.name.toLowerCase()] = {
      value: Math.round(subfactor.value),
    };
  });

  return result;
}

export function prepareTooltipDataBySubfactor(subfactorsData, timeframe) {
  if (!subfactorsData?.competitiveSet?.session) {
    return null;
  }

  const subfactorJSON = JSON.parse(
    subfactorsData.competitiveSet.session.subfactorsCalc[0].t4Quarters
  );

  const dateFilteredSubfactors = subfactorJSON
    .filter((subfactor) => isBetween(subfactor.date, timeframe))
    .map((subfactor) => {
      return {
        name: subfactor.name.toLowerCase(),
        value: Math.round(subfactor.value),
        date: new Date(subfactor.date),
      };
    });

  if (!dateFilteredSubfactors) {
    return null;
  }

  const subfactorsGroupedByName = groupBy(dateFilteredSubfactors, 'name');

  Object.keys(subfactorsGroupedByName).forEach((subfactorKey) => {
    subfactorsGroupedByName[subfactorKey] = subfactorsGroupedByName[
      subfactorKey
    ].sort((subfactorA, subfactorB) => subfactorB.date - subfactorA.date);
  });

  const result = {};

  forEach(subfactorsGroupedByName, (subfactorScores, subfactorKey) => {
    let scoresToShow = 0;

    if (timeframe.quantity <= 30) {
      // show up to 2 scores
      scoresToShow = 2;
    } else if (timeframe.quantity <= 90) {
      // show up to 3 scores
      scoresToShow = 3;
    } else {
      // show up to 4 scores
      scoresToShow = 4;
    }

    const sessionScores = [];
    const indexIncrement = Math.floor(
      (subfactorScores.length - 1) / (scoresToShow - 1)
    );

    if (subfactorScores.length > 1) {
      subfactorScores.forEach((score, index) => {
        if (index === 0 || index === subfactorScores.length - 1) {
          sessionScores.push({ value: score.value, date: score.date });
        } else {
          // eslint-disable-next-line no-lonely-if
          if (scoresToShow > 2 && index % indexIncrement === 0) {
            sessionScores.push({ value: score.value, date: score.date });
          }
        }
      });
    } else {
      sessionScores.push({
        value: sessionScores[0].value,
        date: sessionScores[0].date,
      });
    }

    const newestScore = first(sessionScores);
    const oldestScore = last(sessionScores);

    result[subfactorKey] = {
      delta: newestScore.value - oldestScore.value,
      sessionScores,
    };
  });

  return result;
}

export function perpareFactorTimelineData(data, timeframe) {
  if (
    !data ||
    !data?.competitiveSet?.brand ||
    !data?.competitiveSet?.session?.factors ||
    !data?.competitiveSet?.session?.factorsCalc?.[0]?.t4Quarters
  ) {
    return;
  }

  const factorRaw = data.competitiveSet.session.factors.map((factor) => ({
    id: factor.id,
    name: factor.name,
  }));

  const result = factorRaw.map((factor) => {
    const scoreOverTime = JSON.parse(
      data.competitiveSet.session.factorsCalc[0].t4Quarters
    )
      .filter((f) => factor.name === f.name)
      .map((f) => ({
        date: f.date,
        name: f.name.toLowerCase(),
        value: factor.score <= 0 ? 0 : Math.round(f.value),
      }))
      .filter((f) => isBetween(f.date, timeframe));

    const latestScore = last(scoreOverTime)?.value;
    const oldestScore = head(scoreOverTime)?.value;

    return {
      id: factor.id,
      factorType: factor.name.toLowerCase(),
      score: latestScore,
      competitorAverageScore: 100,
      delta: latestScore - oldestScore,
      scoreOverTime,
    };
  });

  return result;
}
