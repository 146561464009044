import { GenericIconProps } from './types';

const InfoCircleIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2Z"
      fill="#20434A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7126 9.30868L10.5188 13.2591L9.2057 11.9724C8.9301 11.7023 8.48239 11.7023 8.20674 11.9725C7.93109 12.2426 7.93109 12.6813 8.20674 12.9514L10.0907 14.7976C10.2249 14.9278 10.4039 15 10.5907 15L10.6515 14.9977C10.8724 14.9747 11.062 14.8633 11.1787 14.691L15.8589 10.1111C16.1048 9.75843 16.0168 9.32787 15.6919 9.11604C15.3658 8.90436 14.9287 8.99027 14.7126 9.30868Z"
      fill="white"
    />
  </svg>
);

export default InfoCircleIcon;
