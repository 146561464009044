import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useMemo,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import Tooltip from '../../Tooltips/Tooltip';
import { IndexedRating } from '../../../interfaces/score';
import {
  MetricUnit,
  MetricFormatType,
  ScoreType,
} from '../../../interfaces/metric';
import usePreviousValue from '../../../hooks/usePreviousValue';
import {
  metricValueFormattersByType,
  metricUnitFormattersByType,
} from '../../../utils/metric';
import {
  indexedColorsByRating,
  animateNumberChange,
  getIndexRatingFromScore,
} from '../../../utils/score';

import styles from './ScoreTile.module.scss';

const isChromatic =
  process.env.CI === 'true' && process.env.NODE_ENV !== 'test';

export enum ScoreTileSize {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export interface ScoreTileProps extends AnchorHTMLAttributes<HTMLDivElement> {
  /**
   * Displayed in the center of the tile.
   */
  value: number | null;

  /**
   * Displayed in the top right of the tile.
   */
  delta?: number | null;

  /**
   * Indexed (0 - 200) | Raw (-MAXINT - MAXINT)
   */
  variant?: ScoreType;

  /**
   * Small (40px) | Medium (56px) | Large (72px)
   */
  size?: ScoreTileSize;

  /**
   * Duration in milliseconds (ms) to animate number incrementing / decrementing.
   */
  valueAnimationDuration?: number;

  /**
   * Allow score tile to disregard 1:1 apspect ratio and be full width of parent container.
   */
  fullWidth?: boolean;

  /**
   * Only works for ScoreType.Raw values. Format number based on a format type.
   */
  formatType?: MetricFormatType;

  /**
   * Only works for ScoreType.Raw values. Shows a specified symbol to describe the value.
   */
  unit?: MetricUnit;

  /** Disable all animations */
  disableAnimation?: boolean;

  /**
   * Tooltip text
   */
  tooltip?: string;
  /**
   * Custom class name for delta
   */
  deltaClassName?: string;
}

/**
 * Displays score, delta, and relative background and font colors based on IndexRating.
 */
const ScoreTile: FunctionComponent<ScoreTileProps> = ({
  className,
  deltaClassName,
  value,
  delta,
  size = ScoreTileSize.Medium,
  valueAnimationDuration = 2000,
  fullWidth = false,
  variant = ScoreType.Indexed,
  formatType = MetricFormatType.None,
  unit = MetricUnit.None,
  disableAnimation = false,
  tooltip,
  ...props
}) => {
  const previousValue = usePreviousValue(value);
  const [animatedValue, setAnimatedValue] = useState(0);

  useEffect(() => {
    if (isNil(value) || disableAnimation || isChromatic) {
      return;
    }

    if (variant === ScoreType.Indexed) {
      animateNumberChange({
        previousValue,
        nextValue: value,
        animationDuration: valueAnimationDuration,
        onChange: setAnimatedValue,
      });
    }
  }, [value, valueAnimationDuration, disableAnimation, variant, previousValue]);

  const indexedRatingFromValue = useMemo(() => {
    return getIndexRatingFromScore(
      disableAnimation || isChromatic ? value : animatedValue
    );
  }, [animatedValue, value, disableAnimation]);

  const tileColor = useMemo(() => {
    if (isNil(value)) return indexedColorsByRating[IndexedRating.Null];

    if (variant === ScoreType.Indexed)
      return indexedColorsByRating[indexedRatingFromValue];

    return indexedColorsByRating[IndexedRating.Null];
  }, [indexedRatingFromValue, value, variant]);

  const formattedValue = useMemo(() => {
    if (isNil(value)) {
      return 'n/a';
    }

    if (variant === ScoreType.Raw) {
      const metricValue = metricValueFormattersByType[formatType](
        Number(value)
      );

      return metricUnitFormattersByType[unit](String(metricValue));
    }

    if (variant === ScoreType.Indexed && (disableAnimation || isChromatic)) {
      return value;
    }

    return animatedValue;
  }, [animatedValue, value, formatType, unit, variant, disableAnimation]);

  const formattedDelta = useMemo(() => {
    if (isNil(delta)) {
      return 'n/a';
    }

    if (variant === ScoreType.Raw) {
      const metricValue = metricValueFormattersByType[formatType](
        Number(delta)
      );

      return metricUnitFormattersByType[unit](String(metricValue));
    }

    return delta;
  }, [delta, formatType, unit, variant]);

  return (
    <Tooltip
      placement="right"
      arrow
      title={tooltip}
      disableHoverListener={!tooltip}
    >
      <div
        className={classNames(
          styles.ScoreTile,
          fullWidth ? styles.FullWidth : null,
          disableAnimation || isChromatic ? styles.DisableAnimation : null,
          styles[size],
          String(formattedValue).length >= 6 ? styles.MaxLength : null,
          className
        )}
        style={{
          backgroundColor: tileColor.fill,
          color: tileColor.font,
          borderColor: tileColor.border,
        }}
        {...props}
      >
        <div className={styles.Value}>{formattedValue}</div>

        {!isNil(delta) ? (
          <div
            className={classNames(styles.Delta, deltaClassName)}
            style={{ color: tileColor.font }}
          >
            {formattedDelta}
          </div>
        ) : null}
      </div>
    </Tooltip>
  );
};

export default ScoreTile;
