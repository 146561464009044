import { gql } from '@apollo/client';

export const GET_USER_SETTINGS = gql`
  query getUserSettings {
    user {
      name
      accounts {
        id
        accountKey
        users {
          id
          email
          roles {
            id
            burId
            brandId
            account_id
          }
          lastLogin
          brands {
            id
            brandKey
            name
          }
        }
        competitiveSets {
          competitiveSetKey
          brand {
            id
            brandKey
            name
          }
        }
        seats_used
        seats_remaining
      }
    }
  }
`;

export const CREATE_ADMIN_USER = gql`
  mutation adminAddUser(
    $accountKey: ID
    $brandKey: ID
    $email: String
    $firstName: String
    $lastName: String
    $roleId: Int
    $burId: Int
  ) {
    adminAddUser(
      user: {
        accountKey: $accountKey
        brandKey: $brandKey
        email: $email
        firstName: $firstName
        lastName: $lastName
        roleId: $roleId
        burId: $burId
      }
    )
  }
`;

export const UPDATE_ADMIN_USER = gql`
  mutation adminEditUser(
    $accountKey: ID
    $competitiveSetKey: ID
    $email: String
    $role_id: Int
  ) {
    adminEditUser(
      user: {
        accountKey: $accountKey
        competitiveSetKey: $competitiveSetKey
        email: $email
        roleId: $role_id
      }
    )
  }
`;

export const DELETE_ADMIN_USER = gql`
  mutation adminDeleteUser($accountKey: ID, $brandKey: ID, $email: String) {
    adminDeleteUser(
      user: { accountKey: $accountKey, brandKey: $brandKey, email: $email }
    )
  }
`;

export const CREATE_BRAND_ADMIN = gql`
  mutation adminAssignBrandAdmin(
    $accountKey: ID
    $competitiveSetKey: ID
    $email: String
  ) {
    adminAssignBrandAdmin(
      user: {
        accountKey: $accountKey
        competitiveSetKey: $competitiveSetKey
        email: $email
      }
    )
  }
`;

export const UPDATE_BRAND_ADMIN = gql`
  mutation adminEditBrandAdmin(
    $accountKey: ID
    $competitiveSetKey: ID
    $email: String
    $old_email: String
  ) {
    adminEditBrandAdmin(
      user: {
        accountKey: $accountKey
        competitiveSetKey: $competitiveSetKey
        email: $email
        oldEmail: $old_email
      }
    )
  }
`;

export const DELETE_BRAND_ADMIN = gql`
  mutation adminRemoveBrandAdmin(
    $accountKey: ID
    $competitiveSetKey: ID
    $email: String
    $isActive: Int
  ) {
    adminRemoveBrandAdmin(
      user: {
        accountKey: $accountKey
        competitiveSetKey: $competitiveSetKey
        email: $email
        isActive: $isActive
      }
    )
  }
`;

export const GET_BILLING_INVOICES = gql`
  query getStripeCustomerBilling {
    user {
      id
      account {
        accountKey
        stripeBilling
      }
    }
  }
`;
