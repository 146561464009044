import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { CloseIcon } from '../../../../../assets/icons';
import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';
import { MetricDetail } from '../../types';
import { getModeBucketName } from '../../WidgetSettingsFlows/helper';
import styles from './EditGroupMetricsGuide.module.scss';

const EditGroupMetricsGuide = ({
  config,
  onConfigChange,
  onValidationChange,
}: WidgetConfigProps) => {
  const { metrics } = config[FlowKey.SelectGroup];
  const mostCommonBucketName = getModeBucketName(metrics);
  const [name, setName] = useState<string>(mostCommonBucketName);

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setName(target.value);
  };

  const onInputBlur = useCallback(() => {
    const newConfig = { ...config };
    newConfig[FlowKey.SelectGroup].groupName = name;
    onConfigChange(newConfig);
  }, [config, name, onConfigChange]);

  const handlePillClick = (metric: MetricDetail) => {
    const newConfig = { ...config };
    const updatedMetrics = newConfig[FlowKey.SelectGroup].metrics.filter(
      (selectedMetric) => selectedMetric.id !== metric.id
    );
    newConfig[FlowKey.SelectGroup] = {
      ...newConfig[FlowKey.SelectGroup],
      metrics: updatedMetrics,
    };
    onConfigChange(newConfig);
  };

  /**
   * When config is updated, update the local state for group name.
   */
  useEffect(() => {
    const { groupName } = config[FlowKey.SelectGroup];
    if (groupName) setName(groupName);
  }, [config]);

  useEffect(() => {
    const stepConfig = config[FlowKey.SelectGroup];
    const disableNext = !stepConfig.metrics.length || !stepConfig.groupName;
    onValidationChange({ [FlowKey.EditMetrics]: disableNext });
  }, [config, onValidationChange]);

  return (
    <div className={styles.EditGroupMetricsGuide}>
      <p className={styles.Title}>
        Confirm the individual metrics in your group
      </p>

      <div className={classNames(styles.GroupNameSection, styles.Field)}>
        <p className={styles.SubTitle}>
          GROUP NAME
          <span className={styles.Required}>*</span>
        </p>
        <input
          required
          type="text"
          value={name}
          onChange={handleInputChange}
          onBlur={() => onInputBlur()}
          className={styles.GroupName}
        />
      </div>

      <div className={classNames(styles.MetricsSection, styles.Field)}>
        <p className={styles.SubTitle}>INCLUDED METRICS</p>
        <div className={styles.MetricsList}>
          {metrics.map((metric) => (
            <div
              key={metric.id}
              onClick={() => handlePillClick(metric)}
              className={styles.Pill}
            >
              <p>{metric.name}</p>
              <CloseIcon className={styles.Icon} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EditGroupMetricsGuide;
