import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { AnchorHTMLAttributes, useMemo } from 'react';
import { BrandDropdownOption } from '../../../types';
import colors from '../../../../../../constants/colors';
import styles from './RivalBrandsForm.module.scss';

export interface RivalBrandsFormProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  brands: BrandDropdownOption[];
  selectedBrand?: string;
  selectedRivals: Set<string>;
  handleBrandSelect: (brand: BrandDropdownOption) => void;
  maxSelected?: number;
  label?: string;
}

const RivalBrandsForm = ({
  brands,
  selectedBrand,
  selectedRivals,
  handleBrandSelect,
  maxSelected = 2,
  label = 'SELECT TWO BRANDS',
}: RivalBrandsFormProps) => {
  const rivalBrands = useMemo(() => {
    const rivalsOnly: BrandDropdownOption[] = brands.filter(
      ({ value }) => value !== selectedBrand
    );

    return rivalsOnly.map((brand) => ({
      ...brand,
      isSelected: selectedRivals.has(brand.value),
    }));
  }, [brands, selectedBrand, selectedRivals]);

  return (
    <div className={styles.RivalBrandsForm}>
      <p className={styles.Label}>{label}</p>

      <FormGroup>
        {rivalBrands.map((brand) => (
          <FormControlLabel
            key={brand.value}
            className={styles.FormControl}
            control={
              <Checkbox
                sx={{
                  '&.Mui-checked': { color: colors.colorSecondary70 },
                }}
                checked={brand.isSelected}
                onChange={() => handleBrandSelect(brand)}
                name={brand.label}
                disableRipple
              />
            }
            disabled={selectedRivals.size === maxSelected && !brand.isSelected}
            label={brand.label}
          />
        ))}
      </FormGroup>
    </div>
  );
};

export default RivalBrandsForm;
