import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { BrandLogo } from '@blueoceanai/react-component-library';

import ThemesSemiPieChart from '../../Molecules/ThemesSemiPieChart/ThemesSemiPieChart';
import ChartVolume from '../../Molecules/ChartVolume/ChartVolume';
import ChartSentiment from '../../Molecules/ChartSentiment/ChartSentiment';
import { SIZES } from '../../../constants/props';

import styles from './ThemesAudienceChart.module.scss';

export default function ThemesAudienceChart({
  cardTitle,
  audienceVolumeTimelineData,
  audienceVolumeGaugeData,
  audienceSentimentTimelineData,
  audienceSentimentGaugeData,
  brandLogo,
}) {
  return (
    <Grid
      className={`${styles.ThemesAudienceChart}`}
      container
      alignItems="center"
      wrap="nowrap"
    >
      <Grid
        item
        container
        xs={2}
        md={3}
        lg={4}
        className={`${styles.ThemesCardHeaderItem} ${styles.ThemesCardItem}`}
        justifyContent="flex-start"
        alignItems="center"
      >
        <BrandLogo size={SIZES.MD} name={cardTitle} src={brandLogo} />
      </Grid>

      <Grid
        item
        container
        xs={5}
        md
        lg={4}
        className={`${styles.ThemesCardItem}`}
        alignItems="center"
        justifyContent="center"
      >
        <ChartVolume type="area" data={audienceVolumeTimelineData} />

        <ThemesSemiPieChart
          chartType={audienceVolumeGaugeData.type}
          value={audienceVolumeGaugeData.value}
          competitorAverage={audienceVolumeGaugeData.competitorAverage}
        />
      </Grid>
      <Grid
        item
        container
        xs={5}
        md
        lg={4}
        className={`${styles.ThemesCardItem} ${styles.ThemesCardItemLast}`}
        alignItems="center"
        justifyContent="center"
      >
        <ChartSentiment type="area" data={audienceSentimentTimelineData} />

        <ThemesSemiPieChart
          chartType="sentiment"
          value={audienceSentimentGaugeData.value}
          competitorAverage={audienceSentimentGaugeData.competitorAverage}
        />
      </Grid>
    </Grid>
  );
}

ThemesAudienceChart.propTypes = {
  cardTitle: PropTypes.string,
  brandLogo: PropTypes.string,
  audienceVolumeTimelineData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      date: PropTypes.string,
    })
  ),
  audienceVolumeGaugeData: PropTypes.shape({
    value: PropTypes.number,
    type: PropTypes.string,
    competitorAverage: PropTypes.number,
  }),
  audienceSentimentTimelineData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      date: PropTypes.string,
    })
  ),
  audienceSentimentGaugeData: PropTypes.shape({
    value: PropTypes.number,
    type: PropTypes.string,
    competitorAverage: PropTypes.number,
  }),
};
