import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalDrawer from '../../Molecules/ModalDrawer/ModalDrawer';
import RecommendationsSection from '../RecommendationsSection/RecommendationsSection';

import RecommendationsIcon from '../../Atoms/RecommendationsIcon/RecommendationsIcon';

import styles from './RecommendationsModal.module.scss';

export default function RecommendationsModal({
  className,
  open,
  onClose,
  recommendations,
  ...props
}) {
  return (
    <ModalDrawer
      title="Recommendations"
      open={open}
      onClose={onClose}
      icon={RecommendationsIcon}
      className={classNames(styles.RecommendationsModal, className)}
      {...props}
    >
      <RecommendationsSection recommendations={recommendations} />
    </ModalDrawer>
  );
}

RecommendationsModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  recommendations: PropTypes.arrayOf(PropTypes.string),
};
