import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import { Button } from '@blueoceanai/react-component-library';

import Tag from '../../Atoms/Tag/Tag';
import RecommendationImpactResult from '../../Atoms/RecommendationImpactResult/RecommendationImpactResult';
import ModalAlert from '../ModalAlert/ModalAlert';
import {
  MODIFY_RECOMMENDATION_COMPLETION,
  DELETE_RECOMMENDATION,
} from '../../../api/queries/Pages/Recommendations';

import { BUTTON_VARIANTS } from '../../../constants/props';
import { RECOMMENDATION_LIFECYCLE } from '../../../constants/recommendations';
import BNContext from '../../../contexts/BNContext';

import styles from './Recommendation.module.scss';
import {
  BlueOceanLogoWithDotIcon,
  EllipsisIcon,
  IconLockLocked,
} from '../../../assets/icons';

export default function Recommendation({
  id,
  className,
  name,
  value,
  practiceAreas,
  impactId,
  createdDate,
  isComplete,
  isPrivate,
  onMarkComplete,
  editRecommendation = () => {},
  type,
  associatedTypeId,
  sourceTypeId,
  onDelete,
  ...props
}) {
  const { currentSession: sessionKey, accountKey } =
    React.useContext(BNContext);
  const [recComplete, setRecComplete] = useState(isComplete);
  const [editRecommendationMutation] = useMutation(
    MODIFY_RECOMMENDATION_COMPLETION
  );
  const [deleteRecommendation] = useMutation(DELETE_RECOMMENDATION);
  const [openModalAlert, setOpenModalAlert] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  function handleButtonClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleEditRecommendation() {
    handleClose();
    const recommendationObj = {
      id,
      name,
      value,
      practiceAreas,
      impactId,
      createdDate,
      isComplete,
      isPrivate,
      type,
      associatedTypeId,
    };
    editRecommendation(recommendationObj);
  }

  async function handleDeleteRecommendation() {
    try {
      await deleteRecommendation({
        variables: {
          sessionKey,
          id,
          name,
          value,
          practice_areas: practiceAreas,
          impact_id: impactId,
          isComplete,
          isPrivate,
          type,
          associatedType_id: associatedTypeId,
        },
      });
      onDelete(id);
    } catch (errors) {
      // eslint-disable-next-line no-console
      console.error(errors);
    }
  }

  const open = Boolean(anchorEl);
  const popOverId = open ? 'share-dropdown-popover' : null;

  async function onComplete() {
    try {
      setRecComplete(1);
      onMarkComplete(id);
      await editRecommendationMutation({
        variables: {
          sessionKey,
          accountKey,
          id,
          name,
          value,
          impact_id: impactId,
          practice_areas: practiceAreas,
          isComplete: 1,
          type,
          associatedType_id: associatedTypeId,
          isPrivate,
        },
      });
    } catch (errors) {
      // eslint-disable-next-line no-console
      console.error(errors);

      setRecComplete(0);
    }
  }

  return (
    <>
      <Box
        className={classNames(styles.Recommendation, className)}
        pb={3}
        {...props}
      >
        <Grid container spacing={0}>
          <Grid container alignItems="center">
            <Grid item xs={12} sm={6}>
              <h5 className={styles.Title}>{name}</h5>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box className={styles.HeaderActions}>
                <Box mr={6}>
                  {isPrivate === 1 ? (
                    <IconLockLocked className={styles.Icon} />
                  ) : null}
                </Box>

                {recComplete === RECOMMENDATION_LIFECYCLE.IN_PROGRESS &&
                sourceTypeId !== 3 ? (
                  <Button
                    variant={BUTTON_VARIANTS.SECONDARY}
                    onClick={onComplete}
                  >
                    Mark as Complete
                  </Button>
                ) : null}

                {recComplete === RECOMMENDATION_LIFECYCLE.COMPLETE ? (
                  <Button
                    className={styles.CompletedBtn}
                    variant={BUTTON_VARIANTS.TERTIARY}
                  >
                    Completed
                  </Button>
                ) : null}
                {sourceTypeId !== 3 ? (
                  <Box ml={1} className={styles.EditDeleteDropdown}>
                    <Button
                      className={styles.MoreActionButton}
                      variant={BUTTON_VARIANTS.TERTIARY}
                      onClick={handleButtonClick}
                    >
                      <EllipsisIcon />
                    </Button>
                    <Popover
                      id={popOverId}
                      className={styles.EditDeleteDropdownPopover}
                      open={open}
                      anchorEl={anchorEl}
                      elevation={3}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <div className={styles.EditDeleteDropdownPopoverInner}>
                        <Button
                          className={styles.DropdownAction}
                          onClick={handleEditRecommendation}
                        >
                          Edit
                        </Button>
                        <Button
                          className={styles.DropdownAction}
                          onClick={() => {
                            handleClose();
                            setOpenModalAlert(true);
                          }}
                        >
                          Delete . . .
                        </Button>
                      </div>
                    </Popover>
                  </Box>
                ) : null}
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Box mt={4} mb={6}>
                <p className={styles.Text}>{value}</p>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={10}>
            <Grid item xs={12} sm={3}>
              <Box>
                <Box mb={2}>
                  <h6 className={styles.Label}>IMPACT RESULTS</h6>
                </Box>
                <RecommendationImpactResult impactResultKey={impactId} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box>
                <Box mb={2}>
                  <h6 className={styles.Label}>PRACTICE AREAS</h6>
                </Box>
                <Box className={styles.Tags}>
                  {practiceAreas
                    ? practiceAreas.split(',').map((tag, index) => (
                        <Box
                          key={tag}
                          mr={index === practiceAreas.length - 1 ? 0 : 2}
                        >
                          <Tag label={tag} />
                        </Box>
                      ))
                    : null}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box className={styles.Date} pr={6}>
                {sourceTypeId === 3 ? (
                  <p className={styles.Label}>
                    <BlueOceanLogoWithDotIcon />
                  </p>
                ) : null}
                <span className={styles.FormattedDate}>
                  {dayjs(createdDate).format('MM/DD/YY')}
                </span>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <ModalAlert
        title="Delete Recommendation"
        open={openModalAlert}
        message="Are you sure you want to delete this recommendation?"
        helper="This action cannot be undone"
        confirmText="Delete"
        onSecondaryClick={() => setOpenModalAlert(false)}
        onPrimaryClick={handleDeleteRecommendation}
        onClose={() => setOpenModalAlert(false)}
      />
    </>
  );
}

Recommendation.propTypes = {
  id: PropTypes.number,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  practiceAreas: PropTypes.string.isRequired,
  impactId: PropTypes.number.isRequired,
  createdDate: PropTypes.string.isRequired,
  isComplete: PropTypes.number,
  isPrivate: PropTypes.number,
  onComplete: PropTypes.func,
  onMarkComplete: PropTypes.func,
  sourceTypeId: PropTypes.number,
  type: PropTypes.number,
  associatedTypeId: PropTypes.number,
  editRecommendation: PropTypes.func,
  onDelete: PropTypes.func,
};
