const DownMetricIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.827 14C7.9361 14 7.48993 15.0771 8.1199 15.7071L11.7057 19.2929C12.0962 19.6834 12.7294 19.6834 13.1199 19.2929L16.7057 15.7071C17.3356 15.0771 16.8895 14 15.9986 14H8.827Z"
      fill="#D83133"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.827 11C7.9361 11 7.48993 9.92286 8.1199 9.29289L11.7057 5.70711C12.0962 5.31658 12.7294 5.31658 13.1199 5.70711L16.7057 9.29289C17.3356 9.92286 16.8895 11 15.9986 11H8.827Z"
      fill="#CED9DB"
    />
  </svg>
);

export default DownMetricIcon;
