import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import classNames from 'classnames';
import { find } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { SingleValue } from 'react-select';
import { TimeRange } from '../../../../../../interfaces/dashboard-api';
import {
  FlowKey,
  WidgetSettingsConfig,
} from '../../../../../../interfaces/widget-settings';

import styles from './SingleMetricSettingsForm.module.scss';
import { DateRangeOptions } from '../../../WidgetSettingsShared';
import { GET_COMPETITORS } from '../../../../../../api/queries/Pages/MarketIndexCompare';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../../api/queries/DataManager';
import DropdownSelect, {
  DropdownOption,
} from '../../../../Creative/DropdownSelect/DropdownSelect';
import useCompetitiveSetOptions from '../../../../../../hooks/useCompetitiveSetOptions';
import useBrandOptions from '../../../../../../hooks/useBrandOptions';
import { FeatureFlag } from '../../../../../../utils/featureFlags';
import DateRangeSelectorDropdown from '../../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';

export interface SingleMetricSettingsFormProps {
  config: WidgetSettingsConfig;
  setMetricAsName: boolean;
  onConfigChange: (config: WidgetSettingsConfig) => void;
}

interface FormData {
  name: string;
  timeRange: TimeRange;
  brandKey: string;
  competitiveSetKey: string;
  endDate: Date;
}

const SingleMetricSettingsForm: FC<SingleMetricSettingsFormProps> = ({
  config,
  setMetricAsName,
  onConfigChange,
}) => {
  const { name, timeRange, brandKey, competitiveSetKey, endDate } =
    config[FlowKey.SingleMetricSettings];
  const metric = config[FlowKey.SelectMetric];

  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  const [formData, setFormData] = useState<FormData>({
    name: setMetricAsName ? metric.name : name,
    timeRange: timeRange ?? TimeRange.DEFAULT,
    brandKey: brandKey ?? '',
    competitiveSetKey: competitiveSetKey ?? '',
    endDate: endDate ?? new Date(),
  });

  const [getFirstLastSession, { data: sessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );

  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    { fetchPolicy: 'no-cache' }
  );

  /* *****
  Competitive Options / Key Handling
  **** */
  const competitiveSetOptions = useCompetitiveSetOptions();

  const selectedCompetitiveSet = useMemo(() => {
    if (!formData.competitiveSetKey || !competitiveSetOptions.length)
      return null;

    return find(competitiveSetOptions, { value: formData.competitiveSetKey });
  }, [competitiveSetOptions, formData.competitiveSetKey]);

  /* *****
    Selected Brand Options / Key Handling
  **** */
  const brandOptions = useBrandOptions(competitorsResp);

  const selectedBrandOption = useMemo(() => {
    if (!formData.brandKey || !brandOptions.length) return null;

    return find(brandOptions, { value: formData.brandKey });
  }, [brandOptions, formData.brandKey]);

  const dateRangeValue = useMemo(() => {
    return DateRangeOptions.find(
      (option) => option.value === formData.timeRange
    );
  }, [formData.timeRange]);

  const handleSaveConfig = useCallback(
    (formDataOverride?: FormData) => {
      const newConfig = { ...config };
      const singleMetric = newConfig[FlowKey.SingleMetricSettings];

      const updatedFormData = formDataOverride ?? formData;
      newConfig[FlowKey.SingleMetricSettings] = {
        ...singleMetric,
        ...updatedFormData,
      };
      onConfigChange(newConfig);
    },
    [config, formData, onConfigChange]
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleCompetitiveSetChange = (
    newValue: SingleValue<DropdownOption>
  ) => {
    const updatedCompetitiveSetKey = (newValue?.value as string) ?? '';
    const newFormData = {
      ...formData,
      competitiveSetKey: updatedCompetitiveSetKey,
      brandKey: '',
    };

    setFormData(newFormData);
    handleSaveConfig(newFormData);
  };

  const handleBrandKeyChange = (newValue: SingleValue<DropdownOption>) => {
    const updateBrandKey = (newValue?.value as string) ?? '';
    const updatedFormData = { ...formData, brandKey: updateBrandKey };

    setFormData(updatedFormData);
    handleSaveConfig(updatedFormData);
  };

  const handleTimeRangeChange = (newValue: SingleValue<DropdownOption>) => {
    const updatedTimeRange = newValue?.value ?? TimeRange.DEFAULT;
    const updatedFormData = {
      ...formData,
      timeRange: updatedTimeRange as TimeRange,
    };

    setFormData(updatedFormData);
    handleSaveConfig(updatedFormData);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    const updatedFormData = {
      ...formData,
      timeRange: (e.timeRange as TimeRange) ?? TimeRange['90_DAYS'],
      endDate: e.endDate,
    };

    setFormData(updatedFormData);
    handleSaveConfig(updatedFormData);
  };

  // if no competitiveSet Key, but options, set as first comp set
  // duplicated in ESOVSettingsForm & GroupMetricSettingsGuide
  useEffect(() => {
    if (formData.competitiveSetKey || !competitiveSetOptions) return;

    const newFormData = {
      ...formData,
      competitiveSetKey: competitiveSetOptions[0].value as string,
    };

    setFormData(newFormData);
    handleSaveConfig(newFormData);
  }, [competitiveSetOptions, formData, handleSaveConfig]);

  // If no selected brand default to hero
  // duplicated in ESOVSettingsForm & GroupMetricSettingsGuide
  useEffect(() => {
    if (
      brandOptions.length &&
      !find(brandOptions, { value: formData.brandKey })
    ) {
      const newFormData = {
        ...formData,
        brandKey: brandOptions[0].value as string,
      };

      setFormData(newFormData);
      handleSaveConfig(newFormData);
    }
  }, [brandOptions, formData, handleSaveConfig]);

  // get session key on competitive set changes as the set may differ from session to session
  // duplicated in ESOVSettingsForm & GroupMetricSettingsGuide
  useEffect(() => {
    if (!selectedCompetitiveSet?.value) return;

    getFirstLastSession({
      variables: { id: selectedCompetitiveSet.value },
    });
  }, [getFirstLastSession, selectedCompetitiveSet]);

  // get competitors when session and competitiveSet key changes
  // duplicated in ESOVSettingsForm & GroupMetricSettingsGuide
  useEffect(() => {
    if (
      !sessionResp?.competitiveSet?.firstAndLastSession?.[1]?.sessionKey ||
      !selectedCompetitiveSet?.value
    ) {
      return;
    }

    const sessionKey =
      sessionResp?.competitiveSet?.firstAndLastSession?.[1]?.sessionKey ?? '';
    getCompetitors({
      variables: {
        id: selectedCompetitiveSet?.value,
        sessionKey,
      },
    });
  }, [getCompetitors, selectedCompetitiveSet, sessionResp]);

  return (
    <div className={classNames(styles.SingleMetricSettingsForm)}>
      <p className={styles.Label}>
        WIDGET NAME
        <span className={styles.Required}>*</span>
      </p>
      <input
        className={styles.Field}
        required
        type="text"
        name="name"
        value={formData?.name}
        onChange={handleInputChange}
        onBlur={() => handleSaveConfig()}
      />

      <p className={styles.Label}>COMPETITIVE SET</p>
      <DropdownSelect
        className={styles.Dropdown}
        options={competitiveSetOptions}
        value={selectedCompetitiveSet}
        onChange={handleCompetitiveSetChange}
      />

      <p className={styles.Label}>BRAND</p>
      <DropdownSelect
        className={styles.Dropdown}
        options={brandOptions}
        value={selectedBrandOption}
        onChange={handleBrandKeyChange}
      />

      <p className={styles.Label}>DATE RANGE</p>
      {showCustomDateRangeSelector ? (
        <DateRangeSelectorDropdown
          onApply={handleApply}
          timeRange={timeRange}
          endDate={endDate}
          fullWidth
        />
      ) : (
        <DropdownSelect
          className={styles.Dropdown}
          value={dateRangeValue}
          options={DateRangeOptions}
          onChange={handleTimeRangeChange}
        />
      )}
    </div>
  );
};

export default SingleMetricSettingsForm;
