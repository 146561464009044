/* eslint-disable */
import PropTypes from 'prop-types';
import classNames from 'classnames';

import capitalize from 'lodash/capitalize';
import isNil from 'lodash/isNil';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Tooltip from '../../Tooltips/Tooltip';

import ScoreTile, {
  ScoreTileSize,
} from '../../ScoreDisplays/ScoreTile/ScoreTile';
import BluescoreTimelineChart from '../../Charts/BluescoreTimelineChart/BluescoreTimelineChart';

import { getFactorRange } from '../../../utils/factors';
import { ScoreType } from '../../../interfaces/metric';

import styles from './SubfactorLineItem.module.scss';

export default function SubfactorLineItem({
  score,
  subfactor,
  scoreTimelineData,
  competitorAverageScore,
  hideGraph,
  tooltip,
  className,
  highlightLabel,
  domainOverride = {},
  onLabelClick,
  ...props
}) {
  function handleLabelClick() {
    if (onLabelClick) {
      onLabelClick();
    }

    return () => {};
  }

  return (
    <Grid
      container
      spacing={4}
      className={classNames(styles.SubfactorLineItem, className)}
      {...props}
    >
      <Grid item xs={5}>
        <Box className={styles.ScoreSection}>
          <Box mr={4} className={styles.ScoreWrap}>
            {tooltip ? (
              <Tooltip title={tooltip} placement="right" arrow>
                <div>
                  <ScoreTile
                    value={!isNil(score) ? Math.round(score) : score}
                    variant={ScoreType.Indexed}
                    size={ScoreTileSize.Medium}
                    disableAnimation={
                      process.env.REACT_APP_ENV === 'local-mock'
                    }
                  />
                </div>
              </Tooltip>
            ) : (
              <ScoreTile
                value={!isNil(score) ? Math.round(score) : score}
                variant={ScoreType.Indexed}
                size={ScoreTileSize.Medium}
                disableAnimation={process.env.REACT_APP_ENV === 'local-mock'}
              />
            )}
          </Box>
          <span
            onClick={handleLabelClick}
            className={classNames(
              styles.SubfactorLabel,
              onLabelClick ? styles.Clickable : null,
              highlightLabel ? styles.Highlight : null
            )}
          >
            {capitalize(subfactor)}
          </span>
        </Box>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        item
        xs={3}
      >
        {!isNil(score) ? (
          <div>
            {getFactorRange(Math.round(score))
              ? getFactorRange(Math.round(score)).NAME
              : ''}
          </div>
        ) : (
          <div>{score}</div>
        )}
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        item
        xs={4}
      >
        {hideGraph ? null : (
          <div
            className={styles.GraphContainer}
            data-testid="subfactor-bluescore-chart"
          >
            <BluescoreTimelineChart
              className={styles.Graph}
              customerScoresOverTime={scoreTimelineData}
              xAxis={false}
              yAxis={false}
              showTooltips={false}
              yDomainOverride={domainOverride.yDomain}
            />
          </div>
        )}
      </Grid>
    </Grid>
  );
}

SubfactorLineItem.propTypes = {
  /**
   * Factor score.
   */
  score: PropTypes.number,
  /**
   * Subfactor category pertaining to the given factor score.
   */
  subfactor: PropTypes.string.isRequired,
  /**
   * Score graph data.
   */
  scoreTimelineData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      score: PropTypes.number,
    })
  ),
  /**
   * Competitor average score to be drawn on graph.
   */
  competitorAverageScore: PropTypes.number.isRequired,
  /**
   * Highlights the subfactor label.
   */
  highlightLabel: PropTypes.bool,
  /**
   * Hide graph from line item.
   */
  hideGraph: PropTypes.bool,
  /**
   * Text for the tooltip on the ScoreTile.
   */
  tooltip: PropTypes.string,
  className: PropTypes.string,
  domainOverride: PropTypes.shape({
    yDomain: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
  onLabelClick: PropTypes.func,
};
