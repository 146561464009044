import { FC } from 'react';
import styles from './DateLabel.module.scss';

export interface DateLabelProps {
  date: string;
}

const DateLabel: FC<DateLabelProps> = ({ date }) => {
  return (
    <div className={styles.DateLabel}>
      <p>Updated at {date}</p>
    </div>
  );
};

export default DateLabel;
