/* eslint-disable import/prefer-default-export */
import { Chart } from 'chart.js';

export function getOrCreateTooltip(
  chart: Chart,
  cssClassTargets: { wrapper: string; contents: string }
): HTMLDivElement {
  let tooltipEl = chart?.canvas?.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.classList.add(cssClassTargets.wrapper);

    const tooltipContents = document.createElement('div');
    tooltipContents.classList.add(cssClassTargets.contents);

    tooltipEl.appendChild(tooltipContents);

    chart?.canvas?.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
}
