import React from 'react';
import PropTypes from 'prop-types';
import MetricListItem from '../../Atoms/MetricListItem/MetricListItem';

import styles from './MetricList.module.scss';

export default function MetricList({ metrics, onMetricClick }) {
  return (
    <ul className={styles.MetricList}>
      {metrics.map((metric) => (
        <MetricListItem
          onMetricClick={onMetricClick}
          key={metric.id}
          name={metric.name}
          metricId={metric.variableId}
        />
      ))}
    </ul>
  );
}

MetricList.propTypes = {
  /**
   * Array of metrics to display in the metrics list, with state attached for favorite.
   */
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  onMetricClick: PropTypes.func,
};
