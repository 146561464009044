/**
 * Hardcoding metrics variable IDs for bluescore, factors, and subfactors because backend
 * is unable to support sending them in getMetricsFavorites query. We should replace these
 * with values from the backend given the opportunity.
 */
export const HARDCODED_METRICS_ID_MAP = {
  BLUESCORE: -1,
  FAMILIAR: 1,
  UNIQUE: 2,
  CONSISTENT: 3,
  RELEVANT: 4,
  REVERED: 5,
  VISIBLE: '1A',
  CONSIDERED: '1B',
  AMPLIFIED: '1C',
  DIFFERENT: '2A',
  MEMORABLE: '2B',
  CLEAR: '3A',
  REINFORCING: '3B',
  STABLE: '3C',
  NEEDED: '4A',
  VALUED: '4B',
  INFLUENTIAL: '4C',
  DESIRABLE: '5A',
  ESTEEMED: '5B',
  INSPIRING: '5C',
};

/**
 * Blacklisting and whitelisting metric variableId is handling by hardcoding on the frontend. Should
 * move this to the backend given the necessary backend support.
 */
export const BLACKLISTED_CATEGORIES_MAP = {
  4: true,
  10: true,
};

export const BLACKLISTED_GROUPS_MAP = {
  4: true,
};

export const ALLOWED_METRICS_MAP = {
  '1A11': true,
  '1A12': true,
  '1A1211ZZ': true,
  '1A1212ZZ': true,
  '1A1213ZZ': true,
  '1A1214ZZ': true,
  '1A1215ZZ': true,
  '1A1216ZZ': true,
  '1A1217ZZ': true,
  '1A1218ZZ': true,
  '1A1219ZZ': true,
  '1A1220ZZ': true,
  '1A1221ZZ': true,
  '1A1222ZZ': true,
  '1A1223ZZ': true,
  '1A1224ZZ': true,
  '1A1226ZZ': true,
  '1A1227ZZ': true,
  '1A1228ZZ': true,
  '1A1229ZZ': true,
  '1A1230ZZ': true,
  '1A1231ZZ': true,
  '1A1232ZZ': true,
  '1A13': true,
  '1A14': true,
  '1A16': true,
  '1A1611ZZ': true,
  '1A17': true,
  '1A1711ZZ': true,
  '1A1712ZZ': true,
  '1B11': true,
  '1B12': true,
  '1B14': true,
  '1B15': true,
  '1B16': true,
  '1B17': true,
  '1B1711ZZ': true,
  '1B1712ZZ': true,
  '1B1713ZZ': true,
  '1B18': true,
  '1B20': true,
  '1B21': true,
  '1B25': true,
  '1B2511ZZ': true,
  '1C11': true,
  '1C1111ZZ': true,
  '1C12': true,
  '1C1211ZZ': true,
  '1C1212ZZ': true,
  '1C1213ZZ': true,
  '1C1214ZZ': true,
  '1C1215ZZ': true,
  '1C13': true,
  '1C14': true,
  '1C15': true,
  '1C16': true,
  '2A12': true,
  '2A1211ZZ': true,
  '2A13': true,
  '2A14': true,
  '2B12': true,
  '2B13': true,
  '2B16': true,
  '3A11': true,
  '3A1211ZZ': true,
  '3A13': true,
  '3A1311ZZ': true,
  '3A1315ZZ': true,
  '3A1316ZZ': true,
  '3A1317ZZ': true,
  '3A1318ZZ': true,
  '3A1319ZZ': true,
  '3A14': true,
  '3B11': true,
  '3B14': true,
  '3B12': true,
  '3C12': true,
  '3C15': true,
  '4A11': true,
  '4A13': true,
  '4A14': true,
  '4B11': true,
  '4B1111ZZ': true,
  '4B1112ZZ': true,
  '4B1113ZZ': true,
  '4B1114ZZ': true,
  '4B1115ZZ': true,
  '4B1116ZZ': true,
  '4B1117ZZ': true,
  '4B1118ZZ': true,
  '4B1119ZZ': true,
  '4B1120ZZ': true,
  '4B1121ZZ': true,
  '4B1122ZZ': true,
  '4B1123ZZ': true,
  '4B1124ZZ': true,
  '4B1125ZZ': true,
  '4B12': true,
  '4B1211ZZ': true,
  '4B1212ZZ': true,
  '4B1213ZZ': true,
  '4B1214ZZ': true,
  '4B13': true,
  '4B14': true,
  '4B15': true,
  '4B16': true,
  '4B17': true,
  '4B1713ZZ': true,
  '4B18': true,
  '4B19': true,
  '4C11': true,
  '4C12': true,
  '4C13': true,
  '4C14': true,
  '4C1411ZZ': true,
  '4C15': true,
  '4C16': true,
  '4C1611ZZ': true,
  '4C17': true,
  '4C18': true,
  '4C19': true,
  '5A11': true,
  '5A1211ZZ': true,
  '5A13': true,
  '5A14': true,
  '5B11': true,
  '5B12': true,
  '5B14': true,
  '5B15': true,
  '5B16': true,
  '5B17': true,
  '5B18': true,
  '5B19': true,
  '5B20': true,
  '5B21': true,
  '5C11': true,
  '5C12': true,
  '5C13': true,
  '5C14': true,
  '5C1411ZZ': true,
  '5C15': true,
  '5C16': true,
  '5C17': true,
};
