import { GenericIconProps } from '../types';

interface CollapseIconProps extends GenericIconProps {
  direction?: 'right' | 'left';
}

const CollapseIcon = ({ direction = 'right', ...props }: CollapseIconProps) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: direction === 'left' ? 'rotate(180deg)' : '' }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4697 9.5L9.10983 7.14017C8.96339 6.99372 8.96339 6.75628 9.10983 6.60983C9.25628 6.46339 9.49372 6.46339 9.64017 6.60983L12.6402 9.60984C12.7866 9.75628 12.7866 9.99372 12.6402 10.1402L9.64017 13.1402C9.49372 13.2866 9.25628 13.2866 9.10983 13.1402C8.96339 12.9937 8.96339 12.7563 9.10983 12.6098L11.4697 10.25H3.375C3.16789 10.25 3 10.0821 3 9.875C3 9.66789 3.16789 9.5 3.375 9.5H11.4697ZM14.25 4.625C14.25 4.41789 14.4179 4.25 14.625 4.25C14.8321 4.25 15 4.41789 15 4.625V15.125C15 15.3321 14.8321 15.5 14.625 15.5C14.4179 15.5 14.25 15.3321 14.25 15.125V4.625Z"
      fill="#1A8AA0"
    />
  </svg>
);

export default CollapseIcon;
