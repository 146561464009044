import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import DatePickerPresetItem from '../../Atoms/DatePickerPresetItem/DatePickerPresetItem';

import { isActive, optionAfterMinDate } from '../../../utils/timeframe';
import { DATE_PICKER_OPTIONS } from '../../../constants/date-selector';

import styles from './DatePickerPresets.module.scss';

export default function DatePickerPresets({
  className,
  onChange,
  value,
  minDate,
  selectedDate,
  ...props
}) {
  function handleOptionClick({ label, quantity, unit, end }) {
    const endDate = end ? new Date(end) : selectedDate;

    const minDateQuantity = minDate
      ? dayjs(endDate).diff(minDate, 'days')
      : quantity;

    onChange({
      label,
      quantity: Math.min(minDateQuantity, quantity),
      unit,
      end: endDate,
    });
  }

  return (
    <div className={classNames(styles.DatePickerPresets, className)} {...props}>
      <div className={styles.Label}>FROM SELECTION</div>
      {DATE_PICKER_OPTIONS.slice(0, 3).map((option) => {
        return (
          <DatePickerPresetItem
            key={option.label}
            onClick={() => handleOptionClick(option)}
            label={option.label}
            active={isActive(option, value)}
            disabled={!optionAfterMinDate(minDate, option)}
          />
        );
      })}
      <hr />
      <div className={styles.Label}>FROM TODAY</div>
      {DATE_PICKER_OPTIONS.slice(3, DATE_PICKER_OPTIONS.length).map(
        (option) => {
          return (
            <DatePickerPresetItem
              key={option.label}
              onClick={() => handleOptionClick(option)}
              label={option.label}
              active={isActive(option, value)}
              disabled={!optionAfterMinDate(minDate, option)}
            />
          );
        }
      )}
    </div>
  );
}

DatePickerPresets.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    quantity: PropTypes.number,
    unit: PropTypes.string,
    end: PropTypes.instanceOf(Date),
  }),
  minDate: PropTypes.instanceOf(Date),
};
