/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { cloneDeep } from 'lodash';
import {
  CreativeAsset,
  CreativeAdCardTypes,
  CreativeAdChartTitles,
} from '../../../../interfaces/creative';
import { PaletteType } from '../../../../constants/color-palettes';

dayjs.extend(utc);

export interface CreativeAssetByMonth {
  [month: string]: CreativeAsset[];
}

export const groupCreativesByMonth = (
  creatives: CreativeAsset[] = []
): CreativeAssetByMonth[] => {
  const groupedByMonth = creatives.reduce((obj, asset) => {
    const month = dayjs
      .utc(asset.seen.end)
      .startOf('month')
      .format('MMMM, YYYY');
    if (obj[month] === undefined) {
      obj[month] = [];
    }
    obj[month].push(asset);
    return obj;
  }, {} as CreativeAssetByMonth);

  return Object.keys(groupedByMonth)
    .sort((a, b) => dayjs(b) - dayjs(a))
    .map((key) => ({ [key]: groupedByMonth[key] }));
};

export interface FormattedAdvertisingMetricCard {
  total: number;
  id: CreativeAdCardTypes;
  name: string;
  diff: number;
  data: {
    x: string;
    y: number;
  }[];
}
export interface BrandAdvertisingMetricsResp {
  getSpendGraphData: {
    totalDiff: number;
    total: number;
    all: {
      data: {
        x: string;
        y: number;
      }[];
    }[];
  };
  geteCPMGraphData: {
    diff: number;
    total: number;
    overall: {
      x: string;
      y: number;
    }[];
  };
}

export const formatSpendMetricsOverviewCard = (
  brandAvertisingMetricsResp: BrandAdvertisingMetricsResp
) => {
  const result: FormattedAdvertisingMetricCard = {
    id: CreativeAdCardTypes.spendId,
    name: 'Spend',
    diff: 0,
    data: [],
    total: 0,
  };

  if (!brandAvertisingMetricsResp) {
    return result;
  }

  const spendResp = brandAvertisingMetricsResp.getSpendGraphData;

  result.diff = spendResp.totalDiff;
  result.total = spendResp.total;
  result.data = spendResp.overall;

  return result;
};

export const formatECPMMetricsOverviewCard = (
  brandAvertisingMetricsResp: BrandAdvertisingMetricsResp
) => {
  const result: FormattedAdvertisingMetricCard = {
    id: CreativeAdCardTypes.eCPM,
    name: 'CPM',
    diff: 0,
    data: [],
    total: 0,
  };

  if (!brandAvertisingMetricsResp) {
    return result;
  }

  const eCPMResp = brandAvertisingMetricsResp.geteCPMGraphData;

  result.diff = eCPMResp.diff;
  result.data = eCPMResp.overall;
  result.total = eCPMResp.total;

  return result;
};

export interface CohortAdvertisingMetricsResp {
  getShareOfSpendGraphData: {
    total: number;
    diff: number;
    brands: {
      data: {
        x: string;
        y: number;
      }[];
    }[];
  };

  getCompetitionAverageGraphData: {
    total: number;
    diff: number;
    overall: {
      x: string;
      y: number;
    }[];
  };
}

export const formatShareOfSpendMetricsOverviewCard = (
  cohortAvertisingMetricsResp: CohortAdvertisingMetricsResp
) => {
  const result: FormattedAdvertisingMetricCard = {
    id: CreativeAdCardTypes.shareOfSpend,
    name: 'Share of Voice - Media Spend',
    diff: 0,
    data: [],
    total: 0,
  };

  if (!cohortAvertisingMetricsResp) {
    return result;
  }

  const shareOfSpendResp = cohortAvertisingMetricsResp.getShareOfSpendGraphData;

  result.total = shareOfSpendResp.total;
  result.diff = shareOfSpendResp.diff;
  result.data = [
    shareOfSpendResp.heroChart,
    shareOfSpendResp?.competitionChart,
  ];

  return result;
};

export const formatCompetitionAverageOverviewCard = (
  cohortAvertisingMetricsResp: CohortAdvertisingMetricsResp
) => {
  const result: FormattedAdvertisingMetricCard = {
    id: CreativeAdCardTypes.competitionAverage,
    name: 'Share of Voice - Cross  Channel Avg Spend',
    diff: 0,
    data: [],
    total: 0,
  };

  if (!cohortAvertisingMetricsResp) {
    return result;
  }

  const competitionAverage =
    cohortAvertisingMetricsResp.getCompetitionAverageGraphData;

  result.total = competitionAverage.total;
  result.diff = competitionAverage.diff;
  result.data = competitionAverage.overall;

  return result;
};

export const prepareAdvertisingMetricsPanelSpendData = (
  spendDetailsResp: BrandAdvertisingMetricsResp
) => {
  const result = [];

  if (!spendDetailsResp?.getSpendGraphData) {
    return result;
  }

  const getSpendGraphData = cloneDeep(spendDetailsResp?.getSpendGraphData);

  result.push({
    id: 0,
    advertisingTableProps: {
      data: getSpendGraphData?.digital.map((item, i) => {
        return {
          ...item,
          legend: {
            color: {
              palette: PaletteType.Sunset,
              index: i,
              dashed: false,
              checked: true,
            },
          },
          rowId: i,
        };
      }),
      headerTitle: 'Digital Channels',
      showHeader: true,
      headerTotal: getSpendGraphData?.totalDigital,
      headerDiff: getSpendGraphData?.digitalDiff,
    },
  });

  result.push({
    id: 1,
    advertisingTableProps: {
      data: getSpendGraphData?.all.map((item, i) => {
        return {
          ...item,
          legend: {
            color: {
              palette: PaletteType.Sunset,
              index: i,
              dashed: false,
              checked: true,
            },
          },
          rowId: i,
        };
      }),
      showHeader: true,
      headerTitle: 'All Channels',
      headerTotal: getSpendGraphData?.total,
      headerDiff: getSpendGraphData?.totalDiff,
    },
  });

  result.push({
    id: 2,
    advertisingTableProps: {
      data: getSpendGraphData?.product.map((item, i) => {
        return {
          ...item,
          legend: {
            color: {
              palette: PaletteType.Sunset,
              index: i,
              dashed: false,
              checked: true,
            },
          },
          rowId: i,
        };
      }),
      showHeader: true,
      headerTitle: 'Products',
      headerTotal: getSpendGraphData?.totalProduct,
      headerDiff: getSpendGraphData?.productDiff,
    },
  });

  return result;
};

export const prepareAdvertisingMetricsPanelShareOfSpendData = (
  shareOfSpendDetailsResp: CohortAdvertisingMetricsResp
) => {
  const result = [];

  if (!shareOfSpendDetailsResp?.getShareOfSpendGraphData) {
    return result;
  }

  const getShareOfSpendGraphData = cloneDeep(
    shareOfSpendDetailsResp.getShareOfSpendGraphData
  );

  result.push({
    id: 0,
    advertisingTableProps: {
      data: shareOfSpendDetailsResp.getShareOfSpendGraphData.brands.map(
        (brand, i) => {
          return {
            ...brand,
            legend: {
              color: {
                palette: PaletteType.Ocean,
                index: i,
                dashed: false,
                checked: true,
              },
            },
            rowId: i,
          };
        }
      ),
      shareOfSpend: true,
      headerTitle: CreativeAdChartTitles.shareOfSpend,
      headerTotal: getShareOfSpendGraphData.total,
      headerDiff: getShareOfSpendGraphData.diff,
      showHeader: true,
      showTitleOnly: true,
    },
  });

  const allChannelsData = [];

  for (
    let i = 0;
    i < shareOfSpendDetailsResp.getShareOfSpendGraphData.channels.length;
    i += 1
  ) {
    const channel = cloneDeep(
      shareOfSpendDetailsResp.getShareOfSpendGraphData.channels[i]
    );
    const channelAvg = cloneDeep(
      shareOfSpendDetailsResp.getShareOfSpendGraphData.channelAverages[i]
    );

    channel.legend = {};
    channelAvg.legend = {};

    channel.rowId = i;
    channelAvg.rowId = i;

    channel.legend.color = {
      palette: PaletteType.Sunset,
      index: i,
      dashed: false,
      checked: true,
    };

    channelAvg.legend.color = {
      palette: PaletteType.Sunset,
      index: i,
      dashed: true,
      checked: true,
    };

    allChannelsData.push(channel);
    allChannelsData.push(channelAvg);
  }

  result.push({
    id: 1,
    advertisingTableProps: {
      data: allChannelsData,
      showHeader: true,
      headerTitle: 'Channels',
      headerTotal: getShareOfSpendGraphData.total,
      headerDiff: getShareOfSpendGraphData.diff,
      shareOfSpend: true,
      showTitleOnly: true,
    },
  });

  return result;
};

export const prepareAdvertisingMetricsPanelCompetitionAverageData = (
  competitionAverageDetailsResp: CohortAdvertisingMetricsResp
) => {
  const result = [];

  if (!competitionAverageDetailsResp?.getCompetitionAverageGraphData) {
    return result;
  }

  const getCompetitionAverageGraphData = cloneDeep(
    competitionAverageDetailsResp.getCompetitionAverageGraphData
  );

  const advertisingTableData = [];

  for (let i = 0; i < getCompetitionAverageGraphData.heroBrand.length; i += 1) {
    const heroBrand = cloneDeep(getCompetitionAverageGraphData.heroBrand[i]);
    const compAvg = cloneDeep(
      getCompetitionAverageGraphData.competitionAverage[i]
    );

    heroBrand.legend = {};
    compAvg.legend = {};

    heroBrand.rowId = i;
    compAvg.rowId = i;

    heroBrand.legend.color = {
      palette: PaletteType.Sunset,
      index: i,
      dashed: false,
      checked: true,
    };

    compAvg.legend.color = {
      palette: PaletteType.Sunset,
      index: i,
      dashed: true,
      checked: true,
    };

    compAvg.label += ` (Competition Avg.)`;

    advertisingTableData.push(heroBrand);
    advertisingTableData.push(compAvg);
  }

  result.push({
    id: 0,
    advertisingTableProps: {
      data: advertisingTableData,
      headerTitle: CreativeAdChartTitles.competitionAverage,
      headerTotal: getCompetitionAverageGraphData.total,
      headerDiff: getCompetitionAverageGraphData.diff,
    },
  });

  return result;
};

export const prepareAdvertisingMetricsPanelECPMData = (
  eCPMDetailsResp: BrandAdvertisingMetricsResp
) => {
  const result = [];

  if (!eCPMDetailsResp?.geteCPMGraphData) {
    return result;
  }

  const geteCPMGraphData = cloneDeep(eCPMDetailsResp.geteCPMGraphData);

  result.push({
    id: 0,
    advertisingTableProps: {
      data: geteCPMGraphData?.channel.map((item, i) => {
        return {
          ...item,
          legend: {
            color: {
              palette: PaletteType.Sunset,
              index: i,
              dashed: false,
              checked: true,
            },
          },
          rowId: i,
        };
      }),
      headerTitle: CreativeAdChartTitles.eCPM,
      eCPM: true,
      headerTotal: geteCPMGraphData.total,
      headerDiff: geteCPMGraphData.diff,
    },
  });

  return result;
};
