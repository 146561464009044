import React, { useState, useContext, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button } from '@blueoceanai/react-component-library';

import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import Table from '../../Molecules/Table/Table';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import SettingsAddMarkerEventModal from '../SettingsAddMarkerEventModal/SettingsAddMarkerEventModal';
import SettingsEditMarkerEventModal from '../SettingsEditMarkerEventModal/SettingsEditMarkerEventModal';

import {
  GET_ACCOUNT_MARKERS,
  ADD_ACCOUNT_MARKER,
} from '../../../api/queries/Organisms/SettingsGlobalMarkerEvents';
import { prepareAccountMarkerEvents } from '../../../api/transforms/Organisms/SettingsGlobalMarkerEvents';

import { BUTTON_VARIANTS } from '../../../constants/props';
import BNContext from '../../../contexts/BNContext';

import styles from './SettingsGlobalMarkerEvents.module.scss';

export default function SettingsGlobalMarkerEvents({ className, ...props }) {
  const { accountKey, competitiveSetID } = useContext(BNContext);
  const [markerEvents, setMarkerEvents] = useState([]);

  const [openAddMarkerEvent, setOpenAddMarkerEvent] = useState(false);
  const [openEditMarkerEvent, setOpenEditMarkerEvent] = useState(false);

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [rowIdxToDelete, setRowIdxToDelete] = useState(null);

  const [defaultValues, setDefaultValues] = useState(null);

  const queryOptions = {
    variables: {
      accountKey,
    },
    fetchPolicy: 'no-cache',
  };

  const [
    getAccountMarkers,
    {
      data: getAccountMarkersResp,
      called: getAccountMarkersCalled,
      loading: getAccountMarkersLoading,
    },
  ] = useLazyQuery(GET_ACCOUNT_MARKERS, queryOptions);

  const [addAccountMarker, { loading: addAccountMarkersLoading }] =
    useMutation(ADD_ACCOUNT_MARKER);

  useEffect(() => {
    if (getAccountMarkersCalled) {
      return;
    }

    getAccountMarkers();
  }, [getAccountMarkersCalled, getAccountMarkers]);

  useEffect(() => {
    if (!getAccountMarkersResp) {
      return;
    }

    const preparedMarkerEvents = prepareAccountMarkerEvents(
      getAccountMarkersResp
    );

    setMarkerEvents(preparedMarkerEvents);
  }, [getAccountMarkersResp]);

  function handleMarkerRemove(rowIdx) {
    setRowIdxToDelete(rowIdx);
    setOpenModalConfirm(true);
  }

  function handleMarkerEdit(rowIdx) {
    const row = markerEvents[rowIdx];

    setDefaultValues({
      id: row.id,
      eventTitle: row.label,
      showEvent: row.displayTo.toLowerCase(),
      eventDate: row.eventDate,
      private: row.private,
    });

    setOpenEditMarkerEvent(true);
  }

  async function handleMarkerAdd(newMarker) {
    try {
      const label = newMarker.eventTitle;
      const date = newMarker.eventDate;
      const payload = {
        label,
        markerEventDate: dayjs(date).toISOString(),
        isPrivate: newMarker.private === true ? 1 : 0,
        isAccountLevel: newMarker.showEvent === 'account' ? 1 : 0,
      };

      payload.competitiveSetKey = competitiveSetID;
      payload.accountKey = accountKey;

      if (newMarker.hdnId) {
        payload.id = Number(newMarker.hdnId);
      }

      await addAccountMarker({
        variables: {
          ...payload,
        },
      });

      getAccountMarkers();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  async function handleModalConfirm() {
    try {
      const payload = {
        id: markerEvents[rowIdxToDelete].id,
      };

      await addAccountMarker({
        variables: {
          ...payload,
        },
      });

      setOpenModalConfirm(false);
      getAccountMarkers();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  return (
    <div
      className={classNames(styles.SettingsGlobalMarkerEvents, className)}
      {...props}
    >
      <div className={styles.Header}>
        <h3>Global Marker Events</h3>

        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          onClick={() => setOpenAddMarkerEvent(true)}
        >
          Add Marker Event
        </Button>
      </div>
      <div className={styles.Body}>
        <div className={styles.Table}>
          {addAccountMarkersLoading || getAccountMarkersLoading ? (
            <ComponentLoader />
          ) : (
            <Table
              items={markerEvents}
              onEdit={handleMarkerEdit}
              onRemove={handleMarkerRemove}
              fields={[
                {
                  key: 'label',
                },
                {
                  key: 'brand',
                },
                {
                  key: 'eventDate',
                  label: 'Event Date',
                  format: (time) => dayjs(time).format('l'),
                },
                {
                  key: 'displayTo',
                  label: 'Display To',
                },
                {
                  key: 'addedDate',
                  label: 'Date added',
                  format: (time) => dayjs(time).format('l'),
                },
                {
                  key: 'addedBy',
                  label: 'Added By',
                },
              ]}
            />
          )}
        </div>
      </div>

      <SettingsEditMarkerEventModal
        onClose={() => setOpenEditMarkerEvent(false)}
        defaultValues={defaultValues}
        open={openEditMarkerEvent}
        onAddMarkerSuccess={handleMarkerAdd}
      />

      <SettingsAddMarkerEventModal
        onClose={() => setOpenAddMarkerEvent(false)}
        open={openAddMarkerEvent}
        onAddMarkerSuccess={handleMarkerAdd}
      />

      <ModalConfirm
        title="Delete Marker"
        open={openModalConfirm}
        message="Are you sure you want to delete this marker?"
        helper="This action cannot be undone."
        onCancel={() => setOpenModalConfirm(false)}
        onConfirm={handleModalConfirm}
      />
    </div>
  );
}

SettingsGlobalMarkerEvents.propTypes = {
  className: PropTypes.string,
};
