import classNames from 'classnames';
import { AnchorHTMLAttributes } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../assets/icons/CloseIcon';

import styles from './ModalContainer.module.scss';

export interface ModalContainerProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  closeModal: () => void;
}

const ModalContainer = ({
  isOpen,
  closeModal,
  children,
  className,
}: ModalContainerProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={classNames(styles.ModalContainer, className)}
      overlayClassName={styles.ModalContainerOverlay}
    >
      <div className={styles.Header}>
        <CloseIcon onClick={closeModal} className={styles.CloseIcon} />
      </div>

      <div id="modal-content" className={styles.Content}>
        {children}
      </div>
    </Modal>
  );
};

export default ModalContainer;
