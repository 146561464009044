import { useId, useLayoutEffect, useRef } from 'react';
import { Box } from '@mui/material';
import styles from './PaceWidgetTrendingItem.module.scss';
import { abbreviateNumber } from '../../../../../../utils/number';
import ChangeDisplayIcon from '../../../ChangeDisplayIcon/ChangeDisplayIcon';
import WidgetTooltip from '../../../WidgetTooltip/WidgetTooltip';
import ScoreChangeDescription from '../../../ScoreChangeDescription/ScoreChangeDescription';
import { PaceAnalysisBrand } from '../../../types';
import { TimeRange } from '../../../../../../interfaces/dashboard-api';
import { DashboardsMetricScore } from '../../../../../../interfaces/widget-settings';

export interface PaceWidgetTrendingItemProps {
  brandData: PaceAnalysisBrand;
  activeTimeRange: TimeRange;
  activeScoreType: DashboardsMetricScore;
}

const performanceScoreTooltip = {
  relative: {
    title: 'Relative Performance',
    description:
      'This number indicates the change in the our indexed score comparing this metric performance against your competitors, over the selected time range.',
  },
  isolated: {
    title: 'Isolated Performance',
    description:
      'This number indicates the change in the our isolated score (your brand’s raw data) and is measured by removing indexed (relative) scoring.',
  },
};

const PaceWidgetTrendingItem = ({
  brandData,
  activeTimeRange,
  activeScoreType,
}: PaceWidgetTrendingItemProps) => {
  const id = useId();
  const scoreRef = useRef<HTMLParagraphElement>(null);
  const displayIconRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (scoreRef.current) {
      scoreRef.current.style.fontSize = '48px';
      if (scoreRef.current.scrollWidth > 124)
        scoreRef.current.style.fontSize = '36px';
    }
  }, [scoreRef, brandData]);

  useLayoutEffect(() => {
    if (displayIconRef.current) {
      displayIconRef.current.className = '';
      if (displayIconRef.current.scrollWidth > 124)
        displayIconRef.current.className = styles.Smaller;
    }
  }, [displayIconRef, brandData]);

  const indicatorMetrics = [
    {
      score: brandData?.tile?.changeRate,
      description: `vs prev. ${activeTimeRange.replace('_', ' ')}`,
      tooltip: {
        relative: {
          title: 'Relative Rate of Change Vs prev. period',
          description:
            'Performance is measured by assessing rate of change of your brand’s raw metric data over the selected time period. This number provides insight into your brand’s isolated performance, and does not account for your cohort’s performance. ',
        },
        isolated: {
          title: 'Isolated Rate of Change Vs prev. period',
          description:
            'Performance is measured by assessing rate of change of your brand’s raw metric data over the selected time period. This number provides insight into your brand’s isolated performance, and does not account for your cohort’s performance. ',
        },
      },
    },
    {
      score: brandData?.tile?.cohortComparison,
      description: 'vs cohort avg.',
      tooltip: {
        relative: {
          title: 'Relative Performance Vs Cohort Average',
          description:
            'Performance is measured by removing indexed (relative) scoring and comparing your brand’s raw data to the cohort average. Note that performance is indicative of where you stand at the current point in time.',
        },
        isolated: {
          title: 'Isolated Performance Vs Cohort Average',
          description:
            'Performance is measured by removing indexed (relative) scoring and comparing your brand’s raw data to the cohort average. Note that performance is indicative of where you stand at the current point in time.',
        },
      },
    },
  ];

  return (
    <Box display="flex" flexDirection="column">
      <Box className={styles.TitleContainer}>
        <img
          className={styles.BrandLogo}
          src={brandData?.brand?.logoUrl}
          alt={`Brand logo - ${brandData?.brand?.name}`}
        />
        <p className={styles.BrandName}>
          {brandData?.brand?.name}
          {brandData?.label && ` (${brandData.label})`}
        </p>
      </Box>
      <Box className={styles.ScoreContainer}>
        <p className={styles.Score} ref={scoreRef}>
          {abbreviateNumber(brandData?.tile?.value)}
        </p>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <WidgetTooltip
            title={
              activeScoreType === DashboardsMetricScore.Relative
                ? performanceScoreTooltip.relative.title
                : performanceScoreTooltip.isolated.title
            }
            description={
              activeScoreType === DashboardsMetricScore.Relative
                ? performanceScoreTooltip.relative.description
                : performanceScoreTooltip.isolated.description
            }
            isBaselineTitle
            placement="right"
          >
            <div ref={displayIconRef}>
              <ChangeDisplayIcon
                value={brandData?.tile?.valueDelta}
                suffix={
                  activeScoreType === DashboardsMetricScore.Relative
                    ? 'pts'
                    : ''
                }
                changeSuffixColor
                className={styles.ChangeDisplayIcon}
              />
            </div>
          </WidgetTooltip>
          {indicatorMetrics.map((el) => (
            <WidgetTooltip
              title={
                activeScoreType === DashboardsMetricScore.Relative
                  ? el.tooltip.relative.title
                  : el.tooltip.isolated.title
              }
              description={
                activeScoreType === DashboardsMetricScore.Relative
                  ? el.tooltip.relative.description
                  : el.tooltip.isolated.description
              }
              isBaselineTitle
              placement="right"
              key={`${
                activeScoreType === DashboardsMetricScore.Relative
                  ? el.tooltip.relative.title
                  : el.tooltip.isolated.title
              }_${id}`}
            >
              <div>
                <ScoreChangeDescription
                  score={el.score}
                  suffix="%"
                  description={el.description}
                  className={styles.LabelDescription}
                />
              </div>
            </WidgetTooltip>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PaceWidgetTrendingItem;
