import { BrandLogo } from '@blueoceanai/react-component-library';
import { SIZES } from '../../../../constants/props';
import DifferencePill from '../DifferencePill/DifferencePill';
import DownloadDropdown from '../../../Molecules/DownloadDropdown/DownloadDropdown';
import styles from './AdCardHeader.module.scss';

export interface AdCardHeaderProps {
  brandName: string;
  handleOnClick: () => void;
  logoUrl: string;
  percentDifference: number;
  spend: string;
  downloadTargetId?: string;
}

const AdCardHeader = ({
  brandName,
  handleOnClick,
  logoUrl,
  percentDifference,
  spend,
  downloadTargetId,
}: AdCardHeaderProps) => (
  <div onClick={handleOnClick} className={styles.Header}>
    <section className={styles.HeaderSection}>
      <BrandLogo size={SIZES.LG} src={logoUrl} data-cy="brand-logo" />
      <p className={styles.BrandName} data-cy="brand-name">
        {brandName}
      </p>
    </section>

    <section className={styles.HeaderSection}>
      <p className={styles.HeaderText} data-cy="spend-value">
        <b>${spend}</b>
        <span className={styles.LightText}> Spend</span>
      </p>

      <DifferencePill percent={percentDifference} />

      {Boolean(downloadTargetId) && (
        <DownloadDropdown
          targetId={downloadTargetId as string}
          className={styles.DownloadDropdown}
        />
      )}
    </section>
  </div>
);

export default AdCardHeader;
