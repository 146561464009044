import { GenericIconProps } from '../types';

const CloseInCircleIcon = (props: GenericIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g
      style={{
        mixBlendMode: 'darken',
      }}
      fill="none"
      fillRule="evenodd"
    >
      <path d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        d="M19.07 4.93A9.999 9.999 0 104.93 19.07 9.999 9.999 0 1019.07 4.93z"
      />
      <path
        fill="#FFF"
        d="M15.344 8.654a.526.526 0 00-.743 0l-2.602 2.603-2.602-2.603a.526.526 0 00-.743.743L11.256 12l-2.602 2.603a.526.526 0 10.744.743L12 12.744l2.602 2.602a.526.526 0 10.744-.743L12.742 12l2.602-2.603a.526.526 0 000-.743z"
      />
    </g>
  </svg>
);

export default CloseInCircleIcon;
