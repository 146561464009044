/* eslint-disable quotes */

export const FACTOR_RANGE_DISPLAY = {
  MIN: 0,
  MAX: 200,
};

export const FACTOR_RANGES = [
  {
    NAME: 'Poor',
    KEY: 'poor',
    FLOOR: 0,
    CEIL: 49,
    FONT_COLOR: 'black',
  },
  {
    NAME: 'Weak',
    KEY: 'weak',
    FLOOR: 50,
    CEIL: 69,
    FONT_COLOR: 'black',
  },
  {
    NAME: 'Fair',
    KEY: 'fair',
    FLOOR: 70,
    CEIL: 89,
    FONT_COLOR: 'black',
  },
  {
    NAME: 'Moderate',
    KEY: 'moderate',
    FLOOR: 90,
    CEIL: 109,
    FONT_COLOR: 'black',
  },
  {
    NAME: 'Good',
    KEY: 'good',
    FLOOR: 110,
    CEIL: 129,
    FONT_COLOR: 'white',
  },
  {
    NAME: 'Great',
    KEY: 'great',
    FLOOR: 130,
    CEIL: 149,
    FONT_COLOR: 'white',
  },
  {
    NAME: 'Best in Class',
    KEY: 'best',
    FLOOR: 150,
    CEIL: 200,
    FONT_COLOR: 'white',
  },
];

export const FACTOR_RANGES_HEAT_MAP = [
  {
    NAME: 'Below avg score',
    KEY: 'below',
    THRESHOLD: '< 100',
  },
  {
    NAME: 'Equal avg score',
    KEY: 'equal',
    THRESHOLD: '100',
  },
  {
    NAME: 'Above avg score',
    KEY: 'above',
    THRESHOLD: '> 100',
  },
];

export const FACTOR_TYPE_KEYS = {
  OVERALL: 'overall',
  FAMILIAR: 'familiar',
  UNIQUE: 'unique',
  CONSISTENT: 'consistent',
  RELEVANT: 'relevant',
  REVERED: 'revered',
};

export const FACTOR_TYPES = [
  {
    ID: 0,
    NAME: 'Overall',
    KEY: FACTOR_TYPE_KEYS.OVERALL,
    EXTENSION: '',
    QUESTION: '',
  },
  {
    ID: 1,
    NAME: 'Familiar',
    KEY: FACTOR_TYPE_KEYS.FAMILIAR,
    EXTENSION: 'To potential customers',
    QUESTION:
      'Does your target audience know your brand and associate it with its defining attributes?',
  },
  {
    ID: 2,
    NAME: 'Unique',
    KEY: FACTOR_TYPE_KEYS.UNIQUE,
    EXTENSION: 'In positioning',
    QUESTION:
      'Are the defining attributes of your brand key differentiators from competitors and substitutes?',
  },
  {
    ID: 3,
    NAME: 'Consistent',
    KEY: FACTOR_TYPE_KEYS.CONSISTENT,
    EXTENSION: 'In messaging',
    QUESTION:
      'Does every interaction with your brand reinforce its defining attributes?',
  },
  {
    ID: 4,
    NAME: 'Relevant',
    KEY: FACTOR_TYPE_KEYS.RELEVANT,
    EXTENSION: 'In offerings',
    QUESTION:
      'Are the defining attributes of your brand useful to your target audience?',
  },
  {
    ID: 5,
    NAME: 'Revered',
    KEY: FACTOR_TYPE_KEYS.REVERED,
    EXTENSION: 'By customers',
    QUESTION:
      'Are the defining attributes touching your target audience in an emotional way?',
  },
];

export const SUBFACTOR_TYPES = [
  {
    NAME: 'Visible',
    KEY: 'visible',
    QUESTION:
      'Is your brand content prominent in marketing channels where your target audience traffics?',
    PARENT_FACTOR: FACTOR_TYPES[1].KEY,
    ID: 1,
  },
  {
    NAME: 'Considered',
    KEY: 'considered',
    QUESTION:
      'Is your brand attracting attention from and interacting with your target audiences?',
    PARENT_FACTOR: FACTOR_TYPES[1].KEY,
    ID: 2,
  },
  {
    NAME: 'Amplified',
    KEY: 'amplified',
    QUESTION:
      'Are your partners and target audiences sharing your brand with their audiences?',
    PARENT_FACTOR: FACTOR_TYPES[1].KEY,
    ID: 3,
  },
  {
    NAME: 'Different',
    KEY: 'different',
    QUESTION:
      'Does your brand offer recognized functional / tangible benefits that are different from your competitors?',
    PARENT_FACTOR: FACTOR_TYPES[2].KEY,
    ID: 4,
  },
  {
    NAME: 'Memorable',
    KEY: 'memorable',
    QUESTION:
      'Does your brand offer recognized emotional / intangible benefits that are different from your competitors?',
    PARENT_FACTOR: FACTOR_TYPES[2].KEY,
    ID: 5,
  },
  {
    NAME: 'Clear',
    KEY: 'clear',
    QUESTION:
      'Does your brand have a clear message that your target audience understands?',
    PARENT_FACTOR: FACTOR_TYPES[3].KEY,
    ID: 6,
  },
  {
    NAME: 'Reinforcing',
    KEY: 'reinforcing',
    QUESTION:
      'Is your brand content consistent within and across channels over time?',
    PARENT_FACTOR: FACTOR_TYPES[3].KEY,
    ID: 7,
  },
  {
    NAME: 'Stable',
    KEY: 'stable',
    QUESTION:
      'Is your brand content delivery predictable relative to individual channels?',
    PARENT_FACTOR: FACTOR_TYPES[3].KEY,
    ID: 8,
  },
  {
    NAME: 'Needed',
    KEY: 'needed',
    QUESTION:
      'Do the brand’s attributes align with how the target audiences makes decisions rationally?',
    PARENT_FACTOR: FACTOR_TYPES[4].KEY,
    ID: 9,
  },
  {
    NAME: 'Valued',
    KEY: 'valued',
    QUESTION:
      'Is your brand meeting the functional / tangible needs of your consumers?',
    PARENT_FACTOR: FACTOR_TYPES[4].KEY,
    ID: 10,
  },
  {
    NAME: 'Influential',
    KEY: 'influential',
    QUESTION:
      "Is your brand influential in driving your industry's adoption, growth, and innovation?",
    PARENT_FACTOR: FACTOR_TYPES[4].KEY,
    ID: 11,
  },
  {
    NAME: 'Desirable',
    KEY: 'desirable',
    QUESTION:
      "Do the emotional / intangible attributes that you message about align with your target audience's values and personality?",
    PARENT_FACTOR: FACTOR_TYPES[5].KEY,
    ID: 12,
  },
  {
    NAME: 'Esteemed',
    KEY: 'esteemed',
    QUESTION:
      'Are your consumers and employees satisfied with their interactions with your brand?',
    PARENT_FACTOR: FACTOR_TYPES[5].KEY,
    ID: 13,
  },
  {
    NAME: 'Inspiring',
    KEY: 'inspiring',
    QUESTION:
      'Is your brand positively impacting people beyond your target audience?',
    PARENT_FACTOR: FACTOR_TYPES[5].KEY,
    ID: 14,
  },
];
