import { useCallback, useMemo } from 'react';
import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';
import TextStepGuide from '../../WidgetSettingsShared/TextStepGuide/TextStepGuide';

const SingleMetricSelect = ({ config, onConfigChange }: WidgetConfigProps) => {
  const guideText = 'Select the metric that you like to display on this widget';

  const metric = useMemo(() => {
    const metricDetail = config[FlowKey.SelectMetric];
    if (!metricDetail.id) return null;
    return { id: metricDetail.id, name: metricDetail.name };
  }, [config]);

  const onMetricClick = useCallback(() => {
    const updatedMetric = { id: '', name: '' };
    onConfigChange({ ...config, [FlowKey.SelectMetric]: updatedMetric });
  }, [config, onConfigChange]);

  return (
    <TextStepGuide
      text={guideText}
      showMetrics
      metrics={metric ? [metric] : []}
      onMetricClick={onMetricClick}
    />
  );
};

export default SingleMetricSelect;
