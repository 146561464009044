import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { DROPDOWN_VARIANTS } from '../../../constants/props';

import styles from './SelectInput.module.scss';

export default function SelectInput({
  className,
  label,
  inputId,
  onChange,
  value,
  fullWidth = true,
  menuItems = [],
  required,
  placeholder,
  variant,
  disabled,
  inputProps = {},
  ...props
}) {
  return (
    <FormControl
      className={classNames(
        styles.SelectInput,
        className,
        variant === DROPDOWN_VARIANTS.NAKED ? styles.Naked : null,
        disabled ? styles.Disabled : null
      )}
      fullWidth={variant === DROPDOWN_VARIANTS.NAKED ? false : fullWidth}
      {...props}
    >
      {label ? (
        <InputLabel
          className={styles.Label}
          shrink
          id={inputId}
          required={required}
        >
          {label}
        </InputLabel>
      ) : null}

      <Select
        labelId={inputId}
        id={inputId}
        value={value}
        onChange={onChange}
        displayEmpty
        className={styles.Input}
        required={required}
        disableUnderline
        disabled={disabled}
        {...inputProps}
      >
        {placeholder ? (
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>
        ) : null}
        {menuItems.map((item) => {
          if (Object.keys(item).length) {
            return (
              <MenuItem
                data-cy="select-list-item"
                key={item.value}
                value={item.value}
                className={classNames(
                  styles.MenuItem,
                  item.value === value ? styles.Active : null
                )}
              >
                {item.label}
              </MenuItem>
            );
          }

          return null;
        })}
        ;
      </Select>
    </FormControl>
  );
}

SelectInput.propTypes = {
  className: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({}),
};
