// eslint-disable-next-line import/no-cycle, import/no-cycle
import { ChartLabelCellProps } from '../Components/Pages/Creative/AssetTable/AssetTableCells';
import { ChartPointFormatted } from './chart';

export enum CreativeAssetType {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
}

export interface SeenDates {
  start: string | null;
  end: string | null;
}

export interface CreativeAssetTag {
  id: number;
  name: string;
}

export interface CreativeAsset {
  id?: string | number;
  name?: string;
  assetUrl?: string;
  type: CreativeAssetType;
  spend: number;
  eCPM: number;
  units?: number;
  impressions?: number;
  thumbnailUrl?: string;
  seen: SeenDates;
  networks: string[];
  tags?: CreativeAssetTag[];
  data?: ChartPointFormatted[];
}

export interface CreativeChannelSpendAsset {
  data: ChartPointFormatted[];
  total: number;
  name: string;
  diff: number;
  id: CreativeAdCardTypes;
}

export enum CreativeAdCardTypes {
  competitionAverage = 'Share of Voice - Cross  Channel Avg Spend',
  eCPM = 'CPM (Digital Channels Only)',
  spendId = 'Spend',
  shareOfSpend = 'Share of Voice - Media Spend',
}

export enum CreativeAdChartTitles {
  competitionAverage = 'Avg. Spend by Channel',
  eCPM = 'CPM by Channel',
  spendId = 'Digital Channels',
  shareOfSpend = 'Share of Voice - Media Spend',
}

export interface CreativeChannelTableAsset {
  data: ChartPointFormatted[];
  label: string;
  diff: number;
  previous: number | null;
  share: number;
  total: number;
  rowId: number;
  legend?: ChartLabelCellProps | undefined;
  checked?: boolean;
}

export type CreativeScreenshot = {
  assetUrl: string;
  configUrl: string;
  date: string;
  thumbnailUrl?: string;
};

export type CreativeBrandScreenshots = {
  key: string;
  count: number;
  pageIndex: number;
  pageSize: number;
  screenshots: CreativeScreenshot[];
};
