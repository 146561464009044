import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { BrandLogo } from '@blueoceanai/react-component-library';
import Tooltip from '@mui/material/Tooltip';
import { POSITIONS, SIZES } from '../../../constants/props';

import styles from './CompetitionGradientBarPin.module.scss';
import ChangeIconLabel from '../../Pages/CustomizableDashboards/ChangeIconLabel/ChangeIconLabel';

const LEVEL_COEFFICIENT = 6;

/**
 * A single pin to be used on the CompetitionGradientBar.
 */
export default function CompetitionGradientBarPin({
  position = 'bottom',
  brands,
  size = 'sm',
  level = 1,
  showScore = true,
  connectorLevelHeight = 15,
  withTooltip = false,
}) {
  const pinWrapClasses = classNames(
    styles.PinWrap,
    position === POSITIONS.TOP ? styles.PositionTop : null,
    size === SIZES.LG ? styles.SizeLarge : null
  );

  let height = connectorLevelHeight;

  if (level === 2) {
    height = connectorLevelHeight * LEVEL_COEFFICIENT;
  }

  const connectorHeight = {
    height: `${height}px`,
  };

  const tooltipContent = (brand) => {
    return (
      <div className={styles.TooltipContainer}>
        <span className={styles.BrandName}>{brand.name}</span>
        <ChangeIconLabel
          metric={brand.scoreVsPrevious}
          suffix="pts"
          iconSize={10}
          labelClassName={styles.IconLabel}
        />
      </div>
    );
  };

  return (
    <div className={pinWrapClasses}>
      <div className={styles.Diamond} />
      <div style={connectorHeight} className={styles.Connector} />
      <ul className={styles.Logos}>
        <div className={styles.FirstLastConnectorHide}>
          <div className={styles.Left} />
          <div className={styles.Right} />
        </div>
        {brands.map((brand) => (
          <Tooltip
            title={tooltipContent(brand)}
            placement="right"
            arrow
            classes={{ tooltip: styles.Tooltip, arrow: styles.TooltipArrow }}
            disableHoverListener={!withTooltip}
            key={brand.id}
          >
            <li className={styles.Logo}>
              <div className={styles.Connector} />
              {size === SIZES.LG ? (
                <BrandLogo
                  className={styles.BrandLogo}
                  size={SIZES.LG}
                  src={brand.logoUrl}
                />
              ) : (
                <BrandLogo className={styles.BrandLogo} src={brand.logoUrl} />
              )}
              {showScore && <div className={styles.Score}>{brand.score}</div>}
            </li>
          </Tooltip>
        ))}
      </ul>
    </div>
  );
}

CompetitionGradientBarPin.propTypes = {
  /**
   * Option to reverse the pin depending upon location.
   */
  position: PropTypes.string,
  /**
   * Size of the company logo. Large is primarily reserved for the customer pin.
   */
  size: PropTypes.string,
  /**
   * Sets the height of the connector line to aide in handling pin collisions.
   */
  level: PropTypes.number,
  /**
   * Adding multple brands into this pin will cause the pin to create forks.
   */
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      squareLogoUrl: PropTypes.string,
      score: PropTypes.number,
      scoreVsPrevious: PropTypes.number,
    })
  ).isRequired,
  showScore: PropTypes.bool,
  connectorLevelHeight: PropTypes.number,
  withTooltip: PropTypes.bool,
};
