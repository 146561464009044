import { useMemo } from 'react';
import dayjs from 'dayjs';
import { ChartDataset, ChartMultipleBrandTooltipDataMap } from '../interfaces';

export default function useBrandCompareTimeseriesChartTooltipFormatter({
  brandsDatasets,
}: {
  brandsDatasets: ChartDataset[];
}): ChartMultipleBrandTooltipDataMap {
  return useMemo(() => {
    const result: ChartMultipleBrandTooltipDataMap = {};

    brandsDatasets.forEach((brand) => {
      brand.data.forEach((point) => {
        const formattedDate = dayjs(point.x).format('M/D/YYYY');

        if (!result[formattedDate]) {
          result[formattedDate] = [];
        }

        result[formattedDate].push({
          value: point.y,
          brandKey: brand.key,
          brandName: brand.name,
        });
      });
    });

    Object.keys(result).forEach((sessionDate) => {
      const brandScores = result[sessionDate];

      brandScores.sort((a, b) => {
        if (!a?.value || !b?.value) {
          return 0;
        }

        return b.value - a.value;
      });
    });

    return result;
  }, [brandsDatasets]);
}
