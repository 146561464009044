import { gql } from '@apollo/client';

export const GET_REPORTS = gql`
  query getReports($id: ID) {
    user {
      competitiveSet(id: $id) {
        reports {
          reportVersionId
          sessionKey
          contentUrl
          read
        }
      }
    }
  }
`;

export const MARK_REPORT_DOWNLOADED = gql`
  mutation markReportDownloaded($reportVersionId: String) {
    pastReportMarkDownloaded(report: { reportVersionId: $reportVersionId })
  }
`;
