import * as React from 'react';

function SvgAddFlag(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#20434A"
          d="M16.5 4C17.878 4 19 5.121 19 6.5v7c0 1.379-1.122 2.5-2.5 2.5H6v4.5c0 .244-.177.45-.41.492L5.5 21a.501.501 0 01-.5-.5v-16a.5.5 0 01.5-.5zm0 1H6v10h10.5c.827 0 1.5-.674 1.5-1.5v-7c0-.827-.673-1.5-1.5-1.5zM12 6.5a.5.5 0 01.5.5v2.5H15a.5.5 0 010 1h-2.5V13a.5.5 0 01-1 0v-2.5H9a.5.5 0 010-1h2.5V7a.5.5 0 01.5-.5z"
        />
      </g>
    </svg>
  );
}

export default SvgAddFlag;
