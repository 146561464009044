import { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import ClipboardJS from 'clipboard';

import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import Tooltip from '@material-ui/core/Tooltip';

import { SHARE_TYPES, DOWNLOAD_TYPES } from '../../../constants/props';
import { downloadFromUrl } from '../../../utils/downloadFile';

import ShareMenuItem from '../../Atoms/ShareMenuItem/ShareMenuItem';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';

import IconCircleCheck from '../../../assets/icons/CheckCircle';

import {
  getShareUrl,
  convertFromPNG,
} from '../../../api/standard/tidepool/share';

import styles from './ShareDropdownMenu.module.scss';
import { IconLink, PNGIcon, PDFIcon, CloseIcon } from '../../../assets/icons';

const menuItems = [
  {
    logo: IconLink,
    name: 'Copy Link',
    type: SHARE_TYPES.COPY,
  },
  {
    logo: PNGIcon,
    name: 'Save as PNG',
    type: DOWNLOAD_TYPES.PNG,
  },
  {
    logo: PDFIcon,
    name: 'Save as PDF',
    type: DOWNLOAD_TYPES.PDF,
  },
];

export default function ShareDropdownMenu({
  className,
  onClose,
  onSuccess,
  targetId,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [copyUrl, setCopyUrl] = useState(null);
  const [imgDataUrl, setImgDataUrl] = useState(null);
  const [urlCopied, setUrlCopied] = useState(false);

  useEffect(() => {
    const clipboard = new ClipboardJS('#copy-share-item', {
      container: document.getElementById('share-dropdown'),
    });

    return function cleanup() {
      clipboard.destroy();
    };
  }, []);

  const getImgDataUrl = useCallback(async () => {
    const targetNode = document.querySelector(`#${targetId}`);

    if (!targetNode) {
      return;
    }

    setLoading(true);

    targetNode.classList.add('screenshotting');
    targetNode.classList.add('screenshotting-share');

    const canvas = await html2canvas(targetNode, {
      allowTaint: false,
      useCORS: true,
      logging: false,
    });

    targetNode.classList.remove('screenshotting');
    targetNode.classList.remove('screenshotting-share');

    setImgDataUrl(canvas.toDataURL('image/png', 1.0));
  }, [targetId]);

  const getCopyUrl = useCallback(async () => {
    try {
      setLoading(true);

      const resp = await getShareUrl(imgDataUrl);

      if (!resp || !resp.data || !resp.data.url) {
        throw new Error('No asset url from tidepool');
      }

      setCopyUrl(resp.data.url);
    } catch (e) {
      alert('Share failed. Please try again or call customer support.');

      // eslint-disable-next-line
      console.error(
        'Error: ShareDropdownMenu - conversion failed from png to shareable Url',
        e
      );
    } finally {
      setLoading(false);
    }
  }, [imgDataUrl]);

  useEffect(() => {
    getImgDataUrl();
  }, [getImgDataUrl]);

  useEffect(() => {
    if (!imgDataUrl) return;

    getCopyUrl();
  }, [imgDataUrl, getCopyUrl]);

  const downloadAsset = useCallback(
    async (convertToFormat) => {
      try {
        setLoading(true);

        const resp = await convertFromPNG(imgDataUrl, convertToFormat);

        const assetUrl = resp?.data?.signed_url;

        if (!assetUrl) {
          throw new Error('No asset url from tidepool');
        }

        downloadFromUrl(assetUrl, 'bo-share-download');
        onSuccess?.();
      } catch (e) {
        alert('Download failed. Please try again or call customer support.');

        // eslint-disable-next-line
        console.error(
          `Error: DownloadDropdownMenu - conversion failed from png to ${convertToFormat}`,
          e
        );
      }
    },
    [imgDataUrl, onSuccess]
  );

  const handleShareTypeClick = useCallback(
    async (menuItem) => {
      switch (menuItem.type) {
        case SHARE_TYPES.COPY:
          setUrlCopied(true);

          break;
        case DOWNLOAD_TYPES.PNG:
          await downloadAsset('png');

          break;
        case DOWNLOAD_TYPES.PDF:
          await downloadAsset('pdf');

          break;
        default:
          // eslint-disable-next-line
          console.error(
            'ShareDropdownMenu: Development Error - menu item not associated to download type.'
          );
          break;
      }
    },
    [downloadAsset]
  );

  return (
    <Box
      p={4}
      className={classNames(styles.ShareDropdownMenu, className)}
      id="share-dropdown"
      {...props}
    >
      <Box className={styles.Header} mb={5}>
        <h1 className={styles.Title}>Share</h1>
        <CloseIcon className={styles.CloseIcon} onClick={() => onClose()} />
      </Box>

      {loading ? (
        <div className={styles.LoaderContainer}>
          <ComponentLoader />
        </div>
      ) : (
        <Grid container spacing={2}>
          {menuItems.map((item) => (
            <Grid item xs={12} key={item.type}>
              {item.type !== SHARE_TYPES.COPY ? (
                <ShareMenuItem
                  onClick={() => handleShareTypeClick(item)}
                  LogoSVG={item.logo}
                  name={item.name}
                  className={styles.ShareMenuItem}
                />
              ) : (
                <div
                  data-clipboard-action="copy"
                  data-clipboard-text={copyUrl}
                  id="copy-share-item"
                >
                  {urlCopied ? (
                    <Tooltip
                      title={copyUrl}
                      placement="top-start"
                      open
                      arrow
                      interactive
                      classes={{ tooltip: styles.CopyTooltip }}
                    >
                      <div
                        className={classNames(
                          styles.CopyBtn,
                          styles.ShareMenuItem
                        )}
                      >
                        <IconCircleCheck className={styles.Icon} />
                        <span>Link copied</span>
                      </div>
                    </Tooltip>
                  ) : (
                    <ShareMenuItem
                      onClick={() => handleShareTypeClick(item)}
                      LogoSVG={item.logo}
                      name={item.name}
                      className={styles.ShareMenuItem}
                    />
                  )}
                </div>
              )}
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}

ShareDropdownMenu.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  targetId: PropTypes.string,
  onSuccess: PropTypes.func,
};
