import { useState } from 'react';
import { BrandLogo } from '@blueoceanai/react-component-library';
import classNames from 'classnames';
import WidgetTooltip from '../../../WidgetTooltip/WidgetTooltip';
import styles from './MappingWidgetBrandLogo.module.scss';

export interface MappingWidgetBrandLogoProps {
  logoUrl: string;
  brandName: string;
  selected?: boolean;
  clickable?: boolean;
  id: string;
  handleOnClick?: (id: string, isSelected: boolean) => void;
}

const MappingWidgetBrandLogo = ({
  logoUrl,
  brandName,
  selected = false,
  clickable = false,
  id,
  handleOnClick,
}: MappingWidgetBrandLogoProps) => {
  const [isSelected, setIsSelected] = useState(selected);

  return (
    <div className={styles.BrandLogoWrapper}>
      <div
        className={classNames(styles.BrandLogoContainer, {
          [styles.BrandLogoContainerBackground]: isSelected,
          [styles.BrandLogoClickable]: clickable,
        })}
        onClick={() => {
          if (handleOnClick) {
            setIsSelected(!isSelected);
            handleOnClick(id, isSelected);
          }
        }}
      >
        <BrandLogo
          src={logoUrl}
          alt="Brand logo"
          className={styles.BrandLogo}
          size="sm"
        />
        <WidgetTooltip key={`${id}_${isSelected}`} description={brandName}>
          <span className={styles.BrandName}>{brandName}</span>
        </WidgetTooltip>
      </div>
    </div>
  );
};

export default MappingWidgetBrandLogo;
