import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import { CreativeAssetType } from '../../../../interfaces/creative';

import styles from './CreativeAssetTypeTag.module.scss';
import MediaTypeIcon, {
  MediaIcon,
} from '../../../../assets/icons/MediaTypeIcon';

export interface CreativeAssetTypeTagProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  creativeType: CreativeAssetType;
}

const CreativeAssetTypeTag: FunctionComponent<CreativeAssetTypeTagProps> = ({
  className,
  creativeType,
  ...props
}) => {
  return (
    <div
      className={classNames(styles.CreativeAssetTypeTag, className)}
      data-cy="creative-asset-type-tag"
      {...props}
    >
      {Boolean(creativeType === CreativeAssetType.Image) && (
        <div className={styles.TagContent}>
          <MediaTypeIcon
            iconType={MediaIcon.Image}
            height={20}
            width={20}
            className={styles.Icon}
          />

          <span className={styles.Description}>Image</span>
        </div>
      )}

      {Boolean(creativeType === CreativeAssetType.Video) && (
        <div className={styles.TagContent}>
          <MediaTypeIcon
            iconType={MediaIcon.Video}
            height={20}
            width={20}
            className={styles.Icon}
          />

          <span className={styles.Description}>Video</span>
        </div>
      )}

      {Boolean(creativeType === CreativeAssetType.Audio) && (
        <div className={styles.TagContent}>
          <MediaTypeIcon
            iconType={MediaIcon.Audio}
            height={20}
            width={20}
            className={styles.Icon}
          />

          <span className={styles.Description}>Radio</span>
        </div>
      )}
    </div>
  );
};

export default CreativeAssetTypeTag;
