import React, { ReactNode, MouseEvent } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styles from './CustomLink.module.scss';

interface CustomLinkProps {
  to: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => Promise<void> | void;
  children: ReactNode;
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, onClick, children }) => {
  const navigate = useNavigate();

  const handleClick = async (event: MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      await onClick(event);

      // If permissions are granted, navigate to the specified route
      if (!event.defaultPrevented) {
        navigate(to);
      }
    }
  };

  // Render either a div or a RouterLink based on the presence of onClick
  if (onClick) {
    return (
      <div className={styles.CustomLink} onClick={handleClick}>
        {children}
      </div>
    );
  }
  return (
    <RouterLink className={styles.CustomLink} to={to}>
      {children}
    </RouterLink>
  );
};

export default CustomLink;
