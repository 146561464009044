import { useContext, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';

import { StarFilledIcon, StarUnfilledIcon } from '../../../assets/icons';

import { UPDATE_METRICS_FAVORITE } from '../../../api/queries/DataManager';
import BNContext from '../../../contexts/BNContext';

import styles from './FavoriteStar.module.scss';

export default function FavoriteStar({
  className,
  favorited = false,
  metricId,
  ...props
}) {
  const {
    brandID,
    currentSession,
    getMetricsFavorite,
    getMetricsFavoriteLoading,
  } = useContext(BNContext);

  const [updateMetricsFavorite] = useMutation(UPDATE_METRICS_FAVORITE);

  const [loading, setLoading] = useState(false);

  async function toggleFavorite() {
    try {
      setLoading(true);

      await updateMetricsFavorite({
        variables: {
          brandID,
          variableID: String(metricId),
          isActive: Number(!favorited),
        },
      });

      await getMetricsFavorite({
        variables: {
          id: brandID,
          sessionKey: currentSession,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error: unable to favorite metric', {
        error: e,
        brandID,
        variableID: metricId,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      className={classNames(
        styles.FavoriteStar,
        loading || getMetricsFavoriteLoading ? styles.Loading : null
      )}
      onClick={toggleFavorite}
      {...props}
    >
      {favorited && <StarFilledIcon className={styles.Icon} />}
      {!favorited && <StarUnfilledIcon className={styles.Icon} />}
    </Box>
  );
}

FavoriteStar.propTypes = {
  className: PropTypes.string,
  favorited: PropTypes.bool,
  metricId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
