import { useContext, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import BNContext from '../../../contexts/BNContext';
import useRouter from '../../../hooks/useRouter';

import TopNavDropdown from '../TopNavDropdown/TopNavDropdown';

import styles from './AuthSelector.module.scss';
import { LogoutIcon, UserIcon } from '../../../assets/icons';

export const OPTIONS_KEYS = {
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  LOGOUT: 'LOGOUT',
};

export default function AuthSelector({ className, ...props }) {
  const { updateRoute } = useRouter();
  const { logout } = useAuth0();
  const { user, FEATURE_FLAGS } = useContext(BNContext);

  const OPTIONS = useMemo(() => {
    const result = [];

    if (FEATURE_FLAGS.PROFILE.ROOT) {
      result.push({
        label: 'Account Settings',
        value: OPTIONS_KEYS.ACCOUNT_SETTINGS,
        icon: <UserIcon />,
      });
    }

    result.push({
      label: 'Logout',
      value: OPTIONS_KEYS.LOGOUT,
      icon: <LogoutIcon />,
    });

    return result;
  }, [FEATURE_FLAGS.PROFILE.ROOT]);

  function handleClick(value) {
    if (value === OPTIONS_KEYS.ACCOUNT_SETTINGS) {
      updateRoute({ pathname: '/profile/overview' });
    }

    if (value === OPTIONS_KEYS.LOGOUT) {
      logout({ returnTo: window.location.origin });
    }
  }

  return (
    <div className={classNames(styles.AuthSelector, className)} {...props}>
      <TopNavDropdown
        onClick={handleClick}
        buttonRender={() => (
          <div data-jest="auth-button" className={styles.AuthButton}>
            {user && user.given_name ? (
              <span>
                Logged in as&nbsp;
                {user.given_name}
              </span>
            ) : null}

            {user && user.picture ? (
              <img
                className={styles.ProfilePicture}
                src={user.picture}
                alt={`${user.given_name}`}
              />
            ) : null}
          </div>
        )}
        options={OPTIONS}
      />
    </div>
  );
}

AuthSelector.propTypes = {
  className: PropTypes.string,
};
