import { FunctionComponent, useMemo } from 'react';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import annotationPlugin from 'chartjs-plugin-annotation';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import upperCase from 'lodash/upperCase';

import { subfactorBarChartTooltip } from '../../../chartjs/tooltips';
import { FactorKey, SubfactorKey } from '../../../interfaces';
import { getScoreColorHex, getScoreTextColor } from '../../../utils/factors';

import styles from './SubfactorBarChart.module.scss';

Chart.register(ChartDataLabels, annotationPlugin, ...registerables);

Chart.defaults.font.size = 16;
Chart.defaults.font.family =
  '"Scansky", "Segoe UI", "Roboto", "Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue", "sans-serif"';

export interface SubfactorValue {
  value: number;
}

export interface SubfactorBarChartProps {
  className?: string;
  subfactorScores: {
    [SubfactorKey.Visible]: SubfactorValue;
    [SubfactorKey.Considered]: SubfactorValue;
    [SubfactorKey.Amplified]: SubfactorValue;

    [SubfactorKey.Different]: SubfactorValue;
    [SubfactorKey.Memorable]: SubfactorValue;

    [SubfactorKey.Clear]: SubfactorValue;
    [SubfactorKey.Reinforcing]: SubfactorValue;
    [SubfactorKey.Stable]: SubfactorValue;

    [SubfactorKey.Needed]: SubfactorValue;
    [SubfactorKey.Valued]: SubfactorValue;
    [SubfactorKey.Influential]: SubfactorValue;

    [SubfactorKey.Desirable]: SubfactorValue;
    [SubfactorKey.Esteemed]: SubfactorValue;
    [SubfactorKey.Inspiring]: SubfactorValue;
  };
  tooltipDataBySubfactor: {
    [value in SubfactorKey]: {
      delta: number;
      sessionScores: { value: number; date: Date | string }[];
    };
  };
}

const SubfactorBarChart: FunctionComponent<SubfactorBarChartProps> = ({
  className,
  subfactorScores,
  tooltipDataBySubfactor,
}) => {
  const chartFactorLabelMap = useMemo(
    () => ({
      [FactorKey.Familiar]: upperCase(FactorKey.Familiar),
      [FactorKey.Unique]: upperCase(FactorKey.Unique),
      [FactorKey.Consistent]: upperCase(FactorKey.Consistent),
      [FactorKey.Relevant]: upperCase(FactorKey.Relevant),
      [FactorKey.Revered]: upperCase(FactorKey.Revered),
    }),
    []
  );

  return (
    <div className={classNames(styles.SubfactorBarChart, className)}>
      <Bar
        data={{
          datasets: [
            {
              data: [
                {
                  factor: chartFactorLabelMap[FactorKey.Familiar],
                  subfactor: SubfactorKey.Visible,
                  subfactorValue: subfactorScores[SubfactorKey.Visible].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Unique],
                  subfactor: SubfactorKey.Different,
                  subfactorValue: subfactorScores[SubfactorKey.Different].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Consistent],
                  subfactor: SubfactorKey.Clear,
                  subfactorValue: subfactorScores[SubfactorKey.Clear].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Relevant],
                  subfactor: SubfactorKey.Needed,
                  subfactorValue: subfactorScores[SubfactorKey.Needed].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Revered],
                  subfactor: SubfactorKey.Desirable,
                  subfactorValue: subfactorScores[SubfactorKey.Desirable].value,
                },
              ],
            },
            {
              data: [
                {
                  factor: chartFactorLabelMap[FactorKey.Familiar],
                  subfactor: SubfactorKey.Considered,
                  subfactorValue:
                    subfactorScores[SubfactorKey.Considered].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Unique],
                  subfactor: SubfactorKey.Memorable,
                  subfactorValue: subfactorScores[SubfactorKey.Memorable].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Consistent],
                  subfactor: SubfactorKey.Reinforcing,
                  subfactorValue:
                    subfactorScores[SubfactorKey.Reinforcing].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Relevant],
                  subfactor: SubfactorKey.Valued,
                  subfactorValue: subfactorScores[SubfactorKey.Valued].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Revered],
                  subfactor: SubfactorKey.Esteemed,
                  subfactorValue: subfactorScores[SubfactorKey.Esteemed].value,
                },
              ],
            },
            {
              data: [
                {
                  factor: chartFactorLabelMap[FactorKey.Familiar],
                  subfactor: SubfactorKey.Amplified,
                  subfactorValue: subfactorScores[SubfactorKey.Amplified].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Unique],
                  subfactor: null,
                  subfactorValue: null,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Consistent],
                  subfactor: SubfactorKey.Stable,
                  subfactorValue: subfactorScores[SubfactorKey.Stable].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Relevant],
                  subfactor: SubfactorKey.Influential,
                  subfactorValue:
                    subfactorScores[SubfactorKey.Influential].value,
                },
                {
                  factor: chartFactorLabelMap[FactorKey.Revered],
                  subfactor: SubfactorKey.Inspiring,
                  subfactorValue: subfactorScores[SubfactorKey.Inspiring].value,
                },
              ],
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          parsing: {
            yAxisKey: 'subfactorValue',
            xAxisKey: 'factor',
          },
          datasets: {
            bar: {
              borderColor: 'rgba(0,0,0,0)',
              borderWidth: 5,
              maxBarThickness: 48,
              barThickness: 48,
              backgroundColor(context) {
                const index = context.dataIndex;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const point: any = context.dataset.data[index];

                return getScoreColorHex(point?.subfactorValue);
              },
            },
          },
          skipNull: true,
          responsive: true,
          scales: {
            y: {
              max: 200,
              beginAtZero: true,
              ticks: {
                maxTicksLimit: 5,
                font: {
                  size: 13,
                  weight: 'bold',
                },
              },
              grid: {
                drawBorder: false,
                borderDash: [2, 2],
              },
            },
            x: {
              ticks: {
                font: {
                  weight: 'bold',
                },
                color: 'black',
                padding: 12,
              },
              grid: {
                display: false,
                borderWidth: 4,
              },
            },
          },
          layout: {
            padding: {
              top: 16,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: (context) =>
                subfactorBarChartTooltip(context, tooltipDataBySubfactor),
            },
            annotation: {
              annotations: {
                competitiveAverageLine: {
                  type: 'line',
                  yMin: 100,
                  yMax: 100,
                  borderColor: 'black',
                  borderWidth: 1,
                  borderDash: [2, 2],
                  drawTime: 'beforeDatasetsDraw',
                },
              },
            },
            datalabels: {
              labels: {
                subfactor: {
                  anchor: 'start',
                  align: 'top',
                  font: {
                    weight: 'bold',
                  },
                  rotation: 270,
                  display: true,
                  formatter(value) {
                    return capitalize(value.subfactor);
                  },
                  // eslint-disable-next-line
                  color: function (context) {
                    const index = context.dataIndex;
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const point: any = context.dataset.data[index];

                    return getScoreTextColor(point?.subfactorValue);
                  },
                },
                subfactorValue: {
                  anchor: 'end',
                  align: 'top',
                  display: 'auto',
                  font: {
                    size: 13,
                    weight: 'bold',
                  },
                  offset: -2,
                  formatter(value) {
                    return value.subfactorValue;
                  },
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default SubfactorBarChart;
