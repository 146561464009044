/* eslint-disable @typescript-eslint/naming-convention */

// import { AttributeValue } from '@aws-sdk/client-dynamodb';

// Generic types/interfaces
type MarshalledProp = Record<string, unknown>;
type MarshalledPropValue = MarshalledProp[keyof MarshalledProp];

export interface Marshalled {
  [key: string]: MarshalledPropValue;
}

export interface ILubo {
  created_at: number;
  created_by: string; // UUID
  updated_at: number;
  updated_by: string; // UUID
}

// Dashboard(s)
export type DashboardsClient = {
  count: number;
  dashboards: IDashboardClient[];
};

export interface DashboardDbsResponse {
  dashboards: IDashboardDb[];
  count: number;
}

export interface IBaseDashboard {
  id: string; // UUID
  user_id: string; // UUID
  created_at: number;
  updated_at: number;
  last_viewed_at: number;
  name: string;
  description?: string;
}

export interface IDashboardClient extends IBaseDashboard {
  layout?: IDashboardLayout;
  widgets: Record<string, IWidgetClient>;
}

export interface IDashboardDb extends IBaseDashboard {
  sort_key: 'CONFIG';
  layout?: unknown; // TODO
  widgets: Record<string, unknown>; // TODO
}

export interface IDashboardLayout {
  container_max_h?: number;
  container_max_w?: number;
  // @Vinh props UI needs?
}

// Widget Template/Instance(s) Shared
export interface IWidgetConfig {
  data_srcs: IWidgetDataSources;
  options?: WidgetConfigOptions;
  layout?: string;
}

export interface IMetricConfigOptions {
  var_id: string; // VARIABLE_ID
  name: string;
  score_type?: ScoreTypeId; // NOTE: this is probably not needed as the designs
  // are changing (maybe) to show both raw and indexed scores in the widget
  // regardless of the score type selected, hence removing the selector
}

// TODO: CF - integrate into endpoints and DTOs
// Note: this is a generic interface for widget config options,
// but each widget type will have its own interface for options
export type WidgetConfigOptions = Record<string, unknown>;

// Widget Instance(s) Type-Specific Config Options
export interface IWidgetConfigOptionsMETRIC_ANALYSIS
  extends WidgetConfigOptions {
  metric: IMetricConfigOptions;
}

export interface IWidgetConfigOptionsMETRIC_COLLECTION
  extends WidgetConfigOptions {
  metrics: IMetricConfigOptions;
  name: string;
  data_srcs: { metrics: IWidgetDataSource };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IWidgetConfigOptionsMETRIC_COMPARISON
  extends WidgetConfigOptions {
  metric: IMetricConfigOptions;
  selected_brand_keys: string[];
}

export interface IWidgetConfigOptionsPACE_ANALYSIS extends WidgetConfigOptions {
  metric: IMetricConfigOptions;
  comparison_type_id: string;
  selected_brand_keys: string[];
}

export interface IWidgetConfigOptionsESOV extends WidgetConfigOptions {
  view_type_id?: ViewTypeId;
}

export interface IWidgetConfigOptionsSTACK_RANKING extends WidgetConfigOptions {
  metric: IMetricConfigOptions;
  selected_brand_keys: string[];
}

export interface IWidgetConfigOptionsVISUAL_MAPS extends WidgetConfigOptions {
  view_type_id?: VisualMapsViewTypeId;
}

export interface IWidgetConfigOptionsSUMMARY_TRENDS
  extends WidgetConfigOptions {
  trend_type_id?: TrendTypeId;
}

export enum ViewType {
  'GRID' = 'GRID',
  'LINE' = 'LINE',
  'BAR' = 'BAR',
  'WATERMAP' = 'WATERMAP',
  'HEATMAP' = 'HEATMAP',
}

export enum VisualMapsViewType {
  'HEATMAP' = 'HEATMAP',
  'WATERMAP' = 'WATERMAP',
}

export type VisualMapsViewTypeId = keyof typeof VisualMapsViewType;

export type ViewTypeId = keyof typeof ViewType;

export enum TrendType {
  'ALL' = 'ALL',
  // TODO: PRODUCT/DESIGN
}

export type TrendTypeId = keyof typeof TrendType;

export enum WidgetDataSourceType {
  'BN_GQL' = 'BN_GQL',
  'EX_SVC_A' = 'EX_SVC_A', // TODO: add data source types as needed
}

export type DataSourceTypeId = keyof typeof WidgetDataSourceType;

export enum ScoreType {
  'INDEXED' = 'INDEXED',
  'RAW' = 'RAW',
}

export enum ComparisonType {
  'LEADERS' = 'LEADERS',
  'NEAREST' = 'NEAREST',
  'RIVALS' = 'RIVALS',
}

export type ScoreTypeId = keyof typeof ScoreType;

export enum TimeRange {
  '30_DAYS' = '30_DAYS',
  '60_DAYS' = '60_DAYS',
  '90_DAYS' = '90_DAYS',
  '365_DAYS' = '365_DAYS',
  // TODO: update the API types
  'DEFAULT' = '90_DAYS',
}

export type TimeRangeId = keyof typeof TimeRange;

export interface IWidgetDataSources {
  [src_id: string]: IWidgetDataSource;
}

// TODO: these are being split WIP
// brand_id?: string; // UUID
// brand_ids?: string[]; // UUID[] e.g., for stack_ranking exclude brands
// comp_set_id?: string; // UUID
// metric_id?: string; // VARIABLE_ID
// metric_ids?: string[]; // VARIABLE_ID[]
// metric_collection_id?: string; // UUID

export interface IWidgetDataSource {
  id: string; // UUID
  type_id: DataSourceTypeId;
  time_range: TimeRangeId;
  input?: {
    brandKey?: string;
    competitiveSetKey?: string;
    sessionKey?: string;
    [key: string]: unknown;
  };
}

export interface IWidgetGQLDataSource extends IWidgetDataSource {
  input: Record<string, unknown>; // TODO
}

// TODO; CF - START HERE
export type UpdateWidgetDataSource = (src: unknown) => IWidgetDataSource;

export type UpdateWidgetOptions = (
  src: WidgetConfigOptions
) => WidgetConfigOptions;

export enum WidgetType {
  MetricAnalysisV1 = 'METRIC_ANALYSIS_V1',
  MetricCollectionV1 = 'METRIC_COLLECTION_V1',
  PaceAnalysisV1 = 'PACE_ANALYSIS_V1',
  ExcessShareOfVoiceV1 = 'ESOV_V1',
  MetricComparisonV1 = 'METRIC_COMPARISON_V1',
  StackRankingV1 = 'STACK_RANKING_V1',
  SummaryTrendsV1 = 'SUMMARY_TRENDS_V1',
  VisualMapsV1 = 'VISUAL_MAPS_V1',
}

export type WidgetTypeId = keyof typeof WidgetType;

export interface IWidgetBase {
  id: string; // UUID
  type_id: WidgetTypeId;
  name: string;
  description?: string;
}

// Widget Template(s)
export type WidgetTemplatesDbResponse = {
  templates: IWidgetTemplateDb[];
  count: number;
};

export type WidgetTemplatesClient = {
  count: number;
  templates: IWidgetTemplateClient[];
};

export interface IWidgetTemplateDb extends IWidgetBase, ILubo {
  sort_key: 'TEMPLATE';
  config: IWidgetConfig;
  access_flag: string;
  is_enabled: boolean;
}

export interface IWidgetTemplateClient
  extends IWidgetBase,
    IWidgetConfig,
    ILubo {
  is_enabled: boolean;
}

// Widget Instance(s)
export interface IWidgetDb extends IWidgetBase, IWidgetConfig {
  user_id: string; // UUID
  owner_dashboard_id: string; // UUID
}

export interface IWidgetClient extends IWidgetBase, IWidgetConfig {
  user_id: string; // UUID
  owner_dashboard_id: string; // UUID
}

// Admin Interfaces
// Note: this enum will get populated when P/D is ready
export enum DashboardConfigurationType {
  'DEFAULT' = 'DEFAULT',
  'CUSTOM' = 'CUSTOM',
  'CMO' = 'CMO',
  'SOCIAL' = 'SOCIAL',
  'CONTENT' = 'CONTENT',
  'DIAGNOSTIC' = 'DIAGNOSTIC',
  'SOV' = 'SOV',
}

export type DashboardConfigurationTypeId =
  keyof typeof DashboardConfigurationType;

export enum ResultStatus {
  'SUCCESS' = 'SUCCESS',
  'FAILURE' = 'FAILURE',
  'NO_OP' = 'NO_OP',
}

export type ResultStatusId = keyof typeof ResultStatus;

export enum WriteDashboardActionType {
  'CREATE' = 'CREATE',
  'UPDATE' = 'UPDATE',
  'DELETE' = 'DELETE',
}

export interface AdminWriteDashboardReply {
  user_id: string;
  results: {
    dashboard_id: string;
    result: ResultStatus;
    action: WriteDashboardActionType;
  }[];
}

export interface AdminWriteDashboardsResponse {
  message: string;
  replies: AdminWriteDashboardReply[];
}

export interface UpdateWidgetSettings {
  name?: string;
  description?: string;
  layout?: string;
  data_srcs?: IWidgetDataSources;
  options?: WidgetConfigOptions;
  chartType?: string;
  type_id?: string;
}

export interface ICapabilities {
  can_view: boolean;
  can_edit: boolean;
  can_add_widget: boolean;
  can_remove_widget: boolean;
  can_delete: boolean;
  can_duplicate: boolean;
  can_share: boolean;
}
