import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/Pages/CustomizableDashboards';
import { GET_COMPETITORS } from '../../../../../api/queries/Pages/MarketIndexCompare';
import useCompetitiveSetOptions from '../../../../../hooks/useCompetitiveSetOptions';
import useBrandOptions from '../../../../../hooks/useBrandOptions';
import {
  ScoreType,
  TimeRange,
  VisualMapsViewType,
} from '../../../../../interfaces/dashboard-api';
import { MetricCategory } from '../../../../../mocks/data/CustomizableDashboards/metrics';
import { BrandDropdownOption } from '../../types';
import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';
import { DateRangeOptions } from '../../WidgetSettingsShared';
import DropdownSelect, {
  DropdownOption,
} from '../../../Creative/DropdownSelect/DropdownSelect';
import RivalBrandsForm from '../PaceAnalysisSettingsGuide/RivalBrandsForm/RivalBrandsForm';
import { FeatureFlag } from '../../../../../utils/featureFlags';
import DateRangeSelectorDropdown from '../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';
import styles from './VisualMapsSettingsGuide.module.scss';

interface FormFields {
  name: string;
  description: string;
  timeRange: TimeRange;
  competitiveSetKey: string;
  viewType: VisualMapsViewType;
  metricGroup: MetricCategory;
  scoreType: ScoreType;
  competitorBrands: string[];
  endDate: Date;
}

const VisualMapsSettingsGuide = ({
  config,
  onConfigChange,
  onValidationChange,
}: WidgetConfigProps) => {
  // the following logic is adapted & duplicated from SummaryTrendsGuide & StackRankingSettingsGuide
  // TODO: refactor to avoid duplication
  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );
  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    { fetchPolicy: 'no-cache' }
  );

  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  const {
    name,
    description,
    timeRange,
    competitiveSetKey,
    viewType,
    metricGroup,
    scoreType,
    competitorBrands,
    endDate,
  } = config[FlowKey.VisualMapsSettings];

  const [inputValues, setInputValues] = useState<FormFields>({
    name: name || 'Score Map',
    description:
      description || 'Displays color-coded mapping of metrics for brands',
    timeRange,
    competitiveSetKey,
    viewType: viewType || VisualMapsViewType.HEATMAP,
    metricGroup,
    scoreType,
    competitorBrands,
    endDate,
  });

  const [checkAllBrandsFlag, setCheckAllBrandsFlag] = useState(false);
  const [priorHeroBrandKey, setPriorHeroBrandKey] = useState('');
  const competitiveSetOptions = useCompetitiveSetOptions();
  const brandOptions = useBrandOptions(competitorsResp);
  const viewTypeOptions = useMemo(() => {
    return [
      { label: 'Heatmap', value: VisualMapsViewType.HEATMAP },
      { label: 'Watermap', value: VisualMapsViewType.WATERMAP },
    ];
  }, []);
  const scoreOptions = useMemo(() => {
    return [
      { label: 'Relative Performance (Indexed)', value: ScoreType.INDEXED },
    ];
  }, []);
  const metricGroupOptions = useMemo(() => {
    return [
      { label: 'Blue Ocean Framework', value: MetricCategory.FunctionalAreas },
      {
        label: 'Market Index Scorecard',
        value: MetricCategory.BlueScoreMetrics,
      },
    ];
  }, []);

  const selectedViewType = useMemo(
    () => viewTypeOptions.find(({ value }) => value === viewType),
    [viewType, viewTypeOptions]
  );

  const selectedCompetitorOption = useMemo(() => {
    if (!competitiveSetKey && !competitiveSetOptions.length) {
      return null;
    }

    return find(competitiveSetOptions, {
      value: competitiveSetKey,
    });
  }, [competitiveSetOptions, competitiveSetKey]);

  const selectedTimeRange = useMemo(
    () => DateRangeOptions.find(({ value }) => value === timeRange),
    [timeRange]
  );

  /* Event Handlers */
  const handleConfigSave = useCallback(
    (newInputValues: FormFields) => {
      const newConfig = structuredClone(config);
      const settingsConfig = newConfig[FlowKey.VisualMapsSettings];
      newConfig[FlowKey.VisualMapsSettings] = {
        ...settingsConfig,
        ...newInputValues,
      };

      onConfigChange(newConfig);
    },
    [config, onConfigChange]
  );

  const selectedCompetitorBrands = useMemo(() => {
    if (brandOptions?.length) {
      if (
        // Select all brands when a new competitive set has been chosen & brandOptions has finished populating.
        // The exception is if the competitive set or brandOptions have not changed
        (checkAllBrandsFlag && priorHeroBrandKey !== brandOptions[0]?.value) ||
        (!checkAllBrandsFlag &&
          !competitorBrands.length &&
          isEmpty(priorHeroBrandKey))
      ) {
        const newCompetitorBrands = brandOptions.map((brand) => brand.value);
        const newInputValues = {
          ...inputValues,
          competitorBrands: newCompetitorBrands,
        };
        setInputValues(newInputValues);
        handleConfigSave(newInputValues);
        if (checkAllBrandsFlag) {
          setCheckAllBrandsFlag(false);
        }
        if (isEmpty(priorHeroBrandKey)) {
          setPriorHeroBrandKey(brandOptions[0]?.value);
        }
        return new Set(newCompetitorBrands);
      }
    }
    // When the competitive set or brandOptions have not changed and
    // competitorBrands has items, then return competitorBrands
    return new Set(competitorBrands);
  }, [
    brandOptions,
    checkAllBrandsFlag,
    competitorBrands,
    handleConfigSave,
    inputValues,
    priorHeroBrandKey,
  ]);

  const selectedMetricGroup = useMemo(() => {
    return metricGroupOptions.find(({ value }) => value === metricGroup);
  }, [metricGroup, metricGroupOptions]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    const updatedInputValues = {
      ...inputValues,
      [key]: value,
    };

    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  };

  const handleDropdownChange = (
    updatedOption: SingleValue<DropdownOption>,
    field: string
  ) => {
    if (!updatedOption || !updatedOption?.value) {
      return;
    }

    const newInputValues = {
      ...inputValues,
      [field]: updatedOption.value,
    };

    // only reset selected brands if competitiveSetKey changes
    if (
      field === 'competitiveSetKey' &&
      newInputValues.competitiveSetKey !== competitiveSetKey
    ) {
      setCheckAllBrandsFlag(true); // when competitiveSetKey changes, all brands are selected by default
      setPriorHeroBrandKey(brandOptions[0]?.value); // used in selectedCompetitorBrands to determine when brandOptions is updated
      newInputValues.competitorBrands = [];
    }

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    const newInputValues = {
      ...inputValues,
      timeRange: (e.timeRange as TimeRange) ?? TimeRange['90_DAYS'],
      endDate: e.endDate,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleCompetitorBrandsChange = (brand: BrandDropdownOption) => {
    const newCompetitorBrands = new Set(competitorBrands);
    if (newCompetitorBrands.has(brand.value)) {
      newCompetitorBrands.delete(brand.value);
    } else {
      newCompetitorBrands.add(brand.value);
    }

    const newInputValues = {
      ...inputValues,
      competitorBrands: Array.from(newCompetitorBrands),
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  /* Fetch Data */
  // if no competitiveSetKey, set first as default competitiveSetKey
  useEffect(() => {
    if (inputValues.competitiveSetKey || !competitiveSetOptions.length) {
      return;
    }

    const updatedInputValues = {
      ...inputValues,
      competitiveSetKey: competitiveSetOptions[0].value,
    };
    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  }, [competitiveSetOptions, handleConfigSave, inputValues]);

  // if no competitiveSet get required sessions
  useEffect(() => {
    if (!selectedCompetitorOption?.value) {
      return;
    }

    getFirstLastSession({ variables: { id: selectedCompetitorOption.value } });
  }, [getFirstLastSession, selectedCompetitorOption]);

  // get cometitiveSet with new SessionKey
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
        ?.sessionKey ||
      !selectedCompetitorOption?.value
    ) {
      return;
    }

    const { sessionKey } =
      firstAndLastSessionResp.competitiveSet.firstAndLastSession[1];
    getCompetitors({
      variables: { id: selectedCompetitorOption.value, sessionKey },
    });
  }, [firstAndLastSessionResp, getCompetitors, selectedCompetitorOption]);

  useEffect(() => {
    const disableSave =
      !name ||
      !description ||
      !timeRange ||
      !competitiveSetKey ||
      !viewType ||
      !metricGroup ||
      !scoreType ||
      competitorBrands.length === 0;

    onValidationChange({
      [FlowKey.VisualMapsSettings]: disableSave,
    });
  }, [
    onValidationChange,
    name,
    description,
    timeRange,
    competitiveSetKey,
    viewType,
    metricGroup,
    scoreType,
    competitorBrands,
  ]);

  return (
    <div className={styles.VisualMapsSettingsGuide}>
      <div className={styles.Group}>
        <p className={styles.Label}>
          WIDGET NAME
          <span className={styles.Required}>*</span>
        </p>
        <input
          className={styles.Field}
          required
          type="text"
          name="name"
          value={name}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.Group}>
        <p className={styles.Label}>WIDGET DESCRIPTION</p>
        <input
          className={styles.Field}
          required
          type="text"
          name="description"
          value={description}
          onChange={handleInputChange}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>
          DEFAULT MAP TYPE
          <span className={styles.Required}>*</span>
        </p>
        <DropdownSelect
          className={styles.Dropdown}
          options={viewTypeOptions}
          onChange={(e) => handleDropdownChange(e, 'viewType')}
          value={selectedViewType}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>SCORE TYPE</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={scoreOptions}
          value={scoreOptions[0]}
          isDisabled
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>METRIC GROUP</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={metricGroupOptions}
          onChange={(e) => handleDropdownChange(e, 'metricGroup')}
          value={selectedMetricGroup}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>DATE RANGE</p>
        {showCustomDateRangeSelector ? (
          <DateRangeSelectorDropdown
            onApply={handleApply}
            timeRange={timeRange}
            endDate={endDate}
            fullWidth
          />
        ) : (
          <DropdownSelect
            className={styles.Dropdown}
            options={DateRangeOptions}
            onChange={(e) => handleDropdownChange(e, 'timeRange')}
            value={selectedTimeRange}
          />
        )}
      </div>
      <div className={styles.Group}>
        <p className={styles.Label}>COMPETITIVE SET</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={competitiveSetOptions}
          onChange={(e) => handleDropdownChange(e, 'competitiveSetKey')}
          value={selectedCompetitorOption}
        />
      </div>

      <RivalBrandsForm
        brands={brandOptions}
        selectedRivals={selectedCompetitorBrands}
        handleBrandSelect={handleCompetitorBrandsChange}
        maxSelected={brandOptions.length}
        label="SELECT BRANDS"
      />
    </div>
  );
};

export default VisualMapsSettingsGuide;
