import { GenericIconProps } from '../types';

const MetricLookbackWindowIcon = (props: GenericIconProps) => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="30.1654" height="30.1654" rx="14.9073" fill="#3350B2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2493 9.56729C12.0994 9.56729 9.56705 12.0483 9.56705 15.083C9.56705 18.1177 12.0994 20.5987 15.2493 20.5987C18.204 20.5987 20.6154 18.4155 20.9028 15.6401L20.124 16.3974C19.947 16.5696 19.6638 16.5657 19.4916 16.3886C19.3194 16.2116 19.3234 15.9284 19.5004 15.7562L20.8629 14.4311C21.0366 14.2623 21.3131 14.2623 21.4866 14.4312L22.8483 15.7563C23.0253 15.9286 23.0292 16.2117 22.8569 16.3887C22.6847 16.5657 22.4015 16.5696 22.2245 16.3973L21.7662 15.9513C21.3309 19.0893 18.5694 21.4932 15.2493 21.4932C11.6288 21.4932 8.67261 18.6348 8.67261 15.083C8.67261 11.5312 11.6288 8.67285 15.2493 8.67285C17.6567 8.67285 19.7664 9.93462 20.9127 11.8224C21.0409 12.0336 20.9737 12.3086 20.7626 12.4368C20.5514 12.565 20.2764 12.4978 20.1482 12.2867C19.1615 10.6617 17.3393 9.56729 15.2493 9.56729ZM15.2493 11.323C15.4962 11.323 15.6965 11.5233 15.6965 11.7703V14.84L17.5357 16.0329C17.743 16.1673 17.802 16.4442 17.6676 16.6514C17.5332 16.8587 17.2563 16.9177 17.049 16.7833L15.0059 15.4582C14.8788 15.3758 14.802 15.2345 14.802 15.083V11.7703C14.802 11.5233 15.0023 11.323 15.2493 11.323Z"
      fill="white"
    />
  </svg>
);

export default MetricLookbackWindowIcon;
