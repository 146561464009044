import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  memo,
  useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import includes from 'lodash/includes';
import keyBy from 'lodash/keyBy';
import { Button } from '@blueoceanai/react-component-library';

import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import UserCount from '../../Molecules/UserCount/UserCount';
import Table from '../../Molecules/Table/Table';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import SettingsAddUserModal from '../SettingsAddUserModal/SettingsAddUserModal';
import SettingsEditUserModal from '../SettingsEditUserModal/SettingsEditUserModal';

import BNContext from '../../../contexts/BNContext';

import {
  GET_USER_SETTINGS,
  DELETE_ADMIN_USER,
} from '../../../api/queries/Pages/AdminSettings';
import {
  prepareUsers,
  prepareBrandMap,
  prepareAccountSeats,
} from '../../../api/transforms/Pages/AdminSettings';

import { BUTTON_VARIANTS } from '../../../constants/props';
import {
  USER_ROLES,
  canManageRole,
  getLowerRoles,
} from '../../../constants/roles';

import styles from './SettingsUsers.module.scss';

const USER_ROLES_BY_ID = keyBy(USER_ROLES, 'id');

function SettingsUsers({ className, ...props }) {
  const { competitiveSetID, accountKey, userRole } = useContext(BNContext);

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [openTooManyUsersModal, setOpenTooManyUsersModal] = useState(false);

  const queryOptions = {
    variables: {
      id: competitiveSetID,
      accountKey,
    },
    fetchPolicy: 'no-cache',
  };

  const [
    getUserSettings,
    {
      error: userSettingsError,
      data: userSettingsResp,
      called: userSettingsCalled,
      loading: loadingUserSettings,
    },
  ] = useLazyQuery(GET_USER_SETTINGS, queryOptions);

  const [
    removeAdminUser,
    {
      data: removeAdminUserResp,
      error: removeAdminUserError,
      loading: removeAdminUserLoading,
    },
  ] = useMutation(DELETE_ADMIN_USER);

  const location = useLocation();

  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [confirmData, setConfirmData] = useState(null);

  const [editData, setEditData] = useState(null);

  const users = useMemo(
    () =>
      prepareUsers(userSettingsResp, userRole, competitiveSetID, accountKey),
    [userSettingsResp, userRole, competitiveSetID, accountKey]
  );

  const brandsWithBrandAdmin = useMemo(() => {
    if (!users) return [];
    const brandArray = [];
    users.forEach((user) => {
      if (user.role === 4) brandArray.push(user.brands);
    });
    return brandArray;
  }, [users]);

  const brandMap = useMemo(
    () => prepareBrandMap(userSettingsResp, accountKey),
    [userSettingsResp, accountKey]
  );

  const burIdArray = useMemo(() => {
    if (!users) return [];
    return users.map((user) => {
      return user.burId;
    });
  }, [users]);

  const seats = useMemo(
    () => prepareAccountSeats(userSettingsResp, accountKey),
    [userSettingsResp, accountKey]
  );

  const isAccountToppedOut = useMemo(() => {
    return seats && seats.used >= seats.total;
  }, [seats]);

  const fields = useMemo(() => {
    let result = [
      {
        key: 'username',
      },
      {
        key: 'brands',
        format: (brandKey) => {
          if (brandMap[brandKey]) {
            return brandMap[brandKey].name;
          }

          // eslint-disable-next-line no-console
          console.error(
            `SettingsUsers.jsx: no brand found for brandKey ${brandKey}\n\n`,
            `brandMap: ${JSON.stringify(brandMap, null, 2)}`
          );
        },
      },
      {
        key: 'role',
        format: (roleId) => {
          if (USER_ROLES_BY_ID[roleId]) {
            return USER_ROLES_BY_ID[roleId].name;
          }

          // eslint-disable-next-line no-console
          console.error(
            `SettingsUsers.jsx: no role found for role id ${roleId}\n\n`,
            `USER_ROLES_BY_ID: ${JSON.stringify(USER_ROLES_BY_ID, null, 2)}`
          );
        },
      },
      {
        key: 'lastLogin',
        label: 'Last Login',
        format: (time) => (time ? dayjs(time).format('l') : 'never logged in'),
      },
    ];

    if (includes(location.pathname, 'brand-settings')) {
      result = [
        {
          key: 'username',
        },
        {
          key: 'role',
        },
        {
          key: 'lastLogin',
          label: 'Last Login',
          format: (time) =>
            time ? dayjs(time).format('l') : 'never logged in',
        },
      ];
    }

    return result;
  }, [brandMap, location.pathname]);

  const isLoading = useMemo(() => {
    return loadingUserSettings || removeAdminUserLoading || !users || !brandMap;
  }, [loadingUserSettings, removeAdminUserLoading, users, brandMap]);

  useEffect(() => {
    if (removeAdminUserResp) {
      getUserSettings();
    }
  }, [removeAdminUserResp, getUserSettings]);

  useEffect(() => {
    if (!userSettingsCalled && !userSettingsResp) {
      getUserSettings();
    }
  }, [getUserSettings, userSettingsCalled, userSettingsResp]);

  useDeepCompareEffectNoCheck(() => {
    if (removeAdminUserError) {
      // eslint-disable-next-line no-console
      console.error(removeAdminUserError);
    }

    if (userSettingsError) {
      // eslint-disable-next-line no-console
      console.error(userSettingsError);
    }
  }, [removeAdminUserError, userSettingsError]);

  function handleUserChange({ rowIdx, key, valIdx, newVal }) {
    const result = cloneDeep(users);

    result[rowIdx][key][valIdx] = newVal;
  }

  function handleUserRemove(row) {
    setConfirmData(row);
    setOpenModalConfirm(true);
  }

  function handleUserEdit(row) {
    setEditData({
      username: find(row.cells, { key: 'username' }).id,
      brandKey: find(row.cells, { key: 'brands' }).id,
      roleId: find(row.cells, { key: 'role' }).id,
      burId: burIdArray[find(row.cells, { key: 'role' }).rowIdx],
    });

    setOpenEditUser(true);
  }

  const handleModalConfirm = useCallback(() => {
    setOpenModalConfirm(false);

    removeAdminUser({
      variables: {
        accountKey,
        brandKey: find(confirmData.cells, { key: 'brands' }).id,
        email: find(confirmData.cells, { key: 'username' }).id,
      },
    });
  }, [setOpenModalConfirm, confirmData, accountKey, removeAdminUser]);

  const handleModalSuccess = useCallback(() => {
    getUserSettings();
  }, [getUserSettings]);

  const handleModalError = useCallback(() => {
    // eslint-disable-next-line no-console
    console.error('SettingsUser.jsx: Add/Edit user failed.');
  }, []);

  const canManage = useCallback(
    (row) => {
      const role = row.cells.find((c) => c.key === 'role');
      if (!role) return false;
      return canManageRole(userRole.id, role.id);
    },
    [userRole]
  );

  const canAddUsers = useMemo(
    () => getLowerRoles(userRole.id).length > 0,
    [userRole.id]
  );

  return (
    <div className={classNames(styles.SettingsUsers, className)} {...props}>
      {canAddUsers ? (
        <div className={styles.Header}>
          <h3>
            Users
            <p>
              If you have permissions in multiple competitive sets, use the
              brand selector at the top to switch.
            </p>
          </h3>
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={() => setOpenAddUser(true)}
          >
            Add User
          </Button>
        </div>
      ) : null}
      <div className={styles.Body}>
        {isLoading && <ComponentLoader />}

        {!isLoading && (
          <div className={styles.UserCounts}>
            {!includes(location.pathname, 'brand-settings') ? (
              <UserCount
                className={styles.UserCount}
                count={seats.used}
                total={seats.total}
                label="Account Users & Guests:"
              />
            ) : null}

            <div className={styles.ViewerCount}>
              <span className={styles.Label}>Brand Viewers:&nbsp;</span>
              <span className={styles.Count}>unlimited</span>
            </div>
          </div>
        )}

        {!isLoading && (
          <div className={styles.Table}>
            <Table
              items={users}
              onChange={handleUserChange}
              onEdit={handleUserEdit}
              canEdit={canManage}
              canRemove={canManage}
              onRemove={handleUserRemove}
              fields={fields}
              groupBy="username"
            />
          </div>
        )}

        {!isLoading && users.length === 0 && (
          <div className={styles.NoResultsContainer}>
            <NoResultsAlert />
          </div>
        )}
      </div>

      <SettingsAddUserModal
        open={openAddUser}
        onSuccess={handleModalSuccess}
        onError={handleModalError}
        onClose={() => setOpenAddUser(false)}
        brandsWithBrandAdmin={brandsWithBrandAdmin}
        isAccountToppedOut={isAccountToppedOut}
        users={users}
      />

      <SettingsEditUserModal
        open={openEditUser}
        editData={editData}
        onSuccess={handleModalSuccess}
        onError={handleModalError}
        onClose={() => setOpenEditUser(false)}
        brandsWithBrandAdmin={brandsWithBrandAdmin}
        isAccountToppedOut={isAccountToppedOut}
        users={null}
      />

      <ModalConfirm
        title="Delete User"
        open={openModalConfirm}
        message="Are you sure you want to delete this user?"
        helper="This action cannot be undone."
        onCancel={() => setOpenModalConfirm(false)}
        onConfirm={handleModalConfirm}
      />
      <ModalConfirm
        title="Account User Limit Reached"
        open={openTooManyUsersModal}
        message="This account has reached the user limit.  Please contact support for more capacity."
        onCancel={() => setOpenTooManyUsersModal(false)}
        onConfirm={() => setOpenTooManyUsersModal(false)}
      />
    </div>
  );
}

SettingsUsers.propTypes = {
  className: PropTypes.string,
};

export default memo(SettingsUsers);
