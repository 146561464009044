import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import IconEye from '../../../assets/icons/IconEye';
import IconEyeHide from '../../../assets/icons/EyeHide';

import styles from './TextInput.module.scss';

export default function TextInput({
  inputId,
  label = undefined,
  placeholder = '',
  required = false,
  value = '',
  icon = undefined,
  rows = undefined,
  onChange = () => {},
  multiline = undefined,
  className,
  type,
  error = undefined,
  helperText = undefined,
  disabled = false,
  inputProps = {},
  maxLength = undefined,
  endAdornment,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);

  function passwordVisibilityIcon() {
    if (type === 'password') {
      if (showPassword) {
        return (
          <InputAdornment position="end">
            <IconEyeHide
              className={styles.Icon}
              onClick={() => setShowPassword(false)}
            />
          </InputAdornment>
        );
      }

      return (
        <InputAdornment position="end">
          <IconEye
            className={styles.Icon}
            onClick={() => setShowPassword(true)}
          />
        </InputAdornment>
      );
    }

    if (maxLength) {
      return (
        <InputAdornment position="end">
          <span className={styles.CharCount}>
            {value.length}/{maxLength}
          </span>
        </InputAdornment>
      );
    }
  }

  function handleChange(e) {
    if (maxLength && e.target.value.length > maxLength) {
      return;
    }

    onChange(e);
  }

  return (
    <FormControl
      fullWidth
      className={classNames(
        styles.TextInput,
        error ? styles.Error : null,
        disabled ? styles.Disabled : null,
        className
      )}
      type={type}
      error={error}
      {...props}
    >
      {label ? (
        <InputLabel
          className={styles.Label}
          required={required}
          htmlFor={inputId}
          focused
          shrink
        >
          {label}
        </InputLabel>
      ) : null}

      <Input
        className={styles.Input}
        required={required}
        id={inputId}
        disableUnderline
        fullWidth
        autoComplete="false"
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
        type={showPassword ? 'text' : type}
        error={error}
        disabled={disabled}
        startAdornment={
          icon ? <InputAdornment position="start">{icon}</InputAdornment> : null
        }
        endAdornment={endAdornment || passwordVisibilityIcon()}
        {...inputProps}
      />

      {helperText ? (
        <FormHelperText className={styles.HelperText} error={error}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

TextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  icon: PropTypes.node,
  rows: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputId: PropTypes.string.isRequired,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  maxLength: PropTypes.number,
  endAdornment: PropTypes.node,
};
