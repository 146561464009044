import { GenericIconProps } from './types';

function SvgStarFilled(props: GenericIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M19.973 9.674a.795.795 0 00-.687-.525l-4.546-.651-2.037-4.056A.8.8 0 0011.984 4a.802.802 0 00-.719.442L9.228 8.49l-4.546.659a.794.794 0 00-.647.533.772.772 0 00.2.784l3.3 3.138-.8 4.455c-.058.3.067.606.32.784a.812.812 0 00.839.055l4.09-2.094 4.074 2.102c.113.062.24.094.368.094.17 0 .334-.052.471-.149a.778.778 0 00.32-.784l-.799-4.455 3.3-3.138a.777.777 0 00.255-.8z"
          fill="#F4CF3D"
        />
      </g>
    </svg>
  );
}

export default SvgStarFilled;
