import { AnchorHTMLAttributes, CSSProperties } from 'react';

export enum ArrowDirection {
  up = 'up',
  right = 'right',
  down = 'down',
}

interface DynamicArrowCircleIconProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  direction: ArrowDirection;
  size?: CSSProperties['width'];
}

const DynamicArrowCircleIcon = ({
  direction,
  className,
  size = '32',
}: DynamicArrowCircleIconProps) => {
  const arrowConfig = {
    up: {
      fillColor: '#00823F',
      bgColor: '#B0DDC7',
      pathData:
        'M9.06551 16.2919C9.17015 16.3974 9.29464 16.4811 9.43181 16.5383C9.56897 16.5954 9.7161 16.6248 9.86469 16.6248C10.0133 16.6248 10.1604 16.5954 10.2976 16.5383C10.4347 16.4811 10.5592 16.3974 10.6639 16.2919L14.3671 12.5774L14.3671 21.1207C14.3671 21.4193 14.4857 21.7056 14.6968 21.9167C14.9079 22.1277 15.1942 22.2463 15.4927 22.2463C15.7913 22.2463 16.0776 22.1277 16.2887 21.9167C16.4997 21.7056 16.6183 21.4193 16.6183 21.1207L16.6183 12.5774L20.3216 16.2919C20.5335 16.5038 20.821 16.6229 21.1208 16.6229C21.4205 16.6229 21.708 16.5038 21.9199 16.2919C22.1319 16.0799 22.251 15.7924 22.251 15.4927C22.251 15.1929 22.1319 14.9055 21.9199 14.6935L16.2919 9.06548C16.1849 8.963 16.0586 8.88267 15.9205 8.8291C15.7857 8.76955 15.64 8.73879 15.4927 8.73879C15.3454 8.73879 15.1997 8.76955 15.065 8.8291C14.9268 8.88267 14.8006 8.963 14.6935 9.06548L9.06551 14.6935C8.96001 14.7982 8.87627 14.9226 8.81912 15.0598C8.76198 15.197 8.73256 15.3441 8.73256 15.4927C8.73256 15.6413 8.76198 15.7884 8.81912 15.9256C8.87627 16.0627 8.96001 16.1872 9.06551 16.2919Z',
    },
    down: {
      fillColor: '#820000',
      bgColor: '#DDB0B0',
      pathData:
        'M22.1858 15.2308C22.0851 15.1293 21.9653 15.0487 21.8333 14.9937C21.7013 14.9387 21.5597 14.9104 21.4167 14.9104C21.2737 14.9104 21.1321 14.9387 21 14.9937C20.868 15.0487 20.7482 15.1293 20.6475 15.2308L17.0833 18.8058V10.5833C17.0833 10.296 16.9692 10.0205 16.766 9.8173C16.5629 9.61414 16.2873 9.5 16 9.5C15.7127 9.5 15.4371 9.61414 15.234 9.8173C15.0308 10.0205 14.9167 10.296 14.9167 10.5833V18.8058L11.3525 15.2308C11.1485 15.0268 10.8718 14.9122 10.5833 14.9122C10.2948 14.9122 10.0182 15.0268 9.81417 15.2308C9.61018 15.4348 9.49557 15.7115 9.49557 16C9.49557 16.2885 9.61018 16.5652 9.81417 16.7692L15.2308 22.1858C15.3339 22.2845 15.4554 22.3618 15.5883 22.4133C15.718 22.4706 15.8582 22.5003 16 22.5003C16.1418 22.5003 16.282 22.4706 16.4117 22.4133C16.5447 22.3618 16.6661 22.2845 16.7692 22.1858L22.1858 16.7692C22.2874 16.6685 22.368 16.5486 22.423 16.4166C22.478 16.2846 22.5063 16.143 22.5063 16C22.5063 15.857 22.478 15.7154 22.423 15.5834C22.368 15.4514 22.2874 15.3315 22.1858 15.2308Z',
    },
    right: {
      fillColor: '#7D7D7D',
      bgColor: '#EEEEEE',
      pathData:
        'M15.2309 9.81413C15.1293 9.91484 15.0487 10.0347 14.9937 10.1667C14.9387 10.2987 14.9104 10.4403 14.9104 10.5833C14.9104 10.7263 14.9387 10.8679 14.9937 10.9999C15.0487 11.1319 15.1293 11.2518 15.2309 11.3525L18.8059 14.9166L10.5834 14.9166C10.296 14.9166 10.0205 15.0308 9.81733 15.2339C9.61417 15.4371 9.50003 15.7126 9.50003 16C9.50003 16.2873 9.61417 16.5628 9.81733 16.766C10.0205 16.9692 10.296 17.0833 10.5834 17.0833L18.8059 17.0833L15.2309 20.6475C15.0269 20.8515 14.9123 21.1281 14.9123 21.4166C14.9123 21.7051 15.0269 21.9818 15.2309 22.1858C15.4349 22.3898 15.7115 22.5044 16 22.5044C16.2885 22.5044 16.5652 22.3898 16.7692 22.1858L22.1859 16.7691C22.2845 16.6661 22.3618 16.5446 22.4134 16.4116C22.4707 16.282 22.5003 16.1417 22.5003 16C22.5003 15.8582 22.4707 15.718 22.4134 15.5883C22.3618 15.4553 22.2845 15.3338 22.1859 15.2308L16.7692 9.81413C16.6685 9.71259 16.5487 9.63199 16.4167 9.577C16.2846 9.522 16.143 9.49368 16 9.49368C15.857 9.49368 15.7154 9.522 15.5834 9.577C15.4514 9.63199 15.3316 9.71259 15.2309 9.81413Z',
    },
  };

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="16" cy="16" r="16" fill={arrowConfig[direction].bgColor} />
      <path
        d={arrowConfig[direction].pathData}
        fill={arrowConfig[direction].fillColor}
      />
    </svg>
  );
};

export default DynamicArrowCircleIcon;
