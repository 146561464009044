import { AnchorHTMLAttributes } from 'react';
import { BrandLogo } from '@blueoceanai/react-component-library';
import classnames from 'classnames';
import { SIZES } from '../../../../constants/props';
import styles from './BrandTag.module.scss';

export interface BrandTagProps extends AnchorHTMLAttributes<HTMLDivElement> {
  name: string;
  logoUrl: string;
}

const BrandTag = ({ name, logoUrl, className = '' }: BrandTagProps) => {
  return (
    <div className={classnames(styles.BrandTag, className)}>
      <BrandLogo className={styles.Logo} size={SIZES.XS} src={logoUrl} />
      <p className={styles.Title}>{name}</p>
    </div>
  );
};

export default BrandTag;
