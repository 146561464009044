import React, { useState, isValidElement, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import MenuList from '@material-ui/core/MenuList';
import { BrandLogo } from '@blueoceanai/react-component-library';

import DropdownMenuItem from '../DropdownMenuItem/DropdownMenuItem';

import { StyledPopover } from '../GraphHintOptionsDropdown/MuiStyleOverrideHOCs';

import { DROPDOWN_VARIANTS, SIZES } from '../../../constants/props';

import styles from './Dropdown.module.scss';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../assets/icons/DynamicCaret';

export default function Dropdown({
  value,
  options,
  onChange,
  showListIcons = false,
  showArrow = true,
  className,
  variant,
  fullWidth = false,
  logoSize = 'XS',
  buttonProps = {},
  listItemProps = {},
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const optionsByValue = useMemo(() => {
    if (options && options.length) {
      const result = {};

      options.forEach((option) => {
        result[option.value] = option;

        if (option.subItems) {
          option.subItems.forEach((subitem) => {
            result[subitem.value] = subitem;
          });
        }
      });

      return result;
    }

    return {};
  }, [options]);

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose(option) {
    if (option) {
      onChange(option);
    }

    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'dropdown-popover' : null;
  const imageSizeClassName =
    logoSize === 'XS'
      ? styles.XS
      : logoSize === 'SM'
      ? styles.SM
      : logoSize === 'MD'
      ? styles.MD
      : styles.LG;

  if (!value && !optionsByValue) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.Dropdown,
        variant === DROPDOWN_VARIANTS.NAKED ? styles.Naked : null,
        fullWidth ? styles.FullWidth : null,
        className
      )}
      {...props}
    >
      <Button
        aria-controls={open ? 'menu-list-grow' : null}
        aria-haspopup="true"
        onClick={handleOpen}
        className={styles.ToggleButton}
        disableRipple
        {...buttonProps}
      >
        <div className={`${styles.OptionTitle} ${imageSizeClassName}`}>
          {showListIcons && optionsByValue[value] ? (
            <Box mr={2} className={`${styles.LogoContainer}`}>
              <BrandLogo
                src={optionsByValue[value].imgUrl}
                size={SIZES[logoSize]}
              />
            </Box>
          ) : null}

          {optionsByValue[value] &&
          optionsByValue[value].icon &&
          isValidElement(optionsByValue[value].icon) ? (
            <Box mr={2}>{optionsByValue[value].icon}</Box>
          ) : null}

          <span>
            {optionsByValue[value] && optionsByValue[value].label
              ? optionsByValue[value].label
              : 'No selected option'}
          </span>
        </div>
        {showArrow ? (
          <DynamicCaretIcon
            className={styles.CaretIcon}
            direction={open ? CaretDirection.up : CaretDirection.down}
          />
        ) : null}
      </Button>

      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: styles.PopoverPaper,
        }}
        elevation={3}
      >
        <MenuList className={styles.Menu}>
          {options.map((option) => (
            <DropdownMenuItem
              key={`${option.value}-${option.label}`}
              onClick={handleClose}
              option={option}
              showIcons={showListIcons}
              selectedOption={optionsByValue[value]}
              {...listItemProps}
            />
          ))}
        </MenuList>
      </StyledPopover>
    </div>
  );
}

Dropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Items to select from the dropdown.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      imgUrl: PropTypes.string,
    })
  ).isRequired,
  /**
   * Callback to handle change from the parent component.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Show images next to labels based on imgUrl in options prop.
   */
  showListIcons: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  showArrow: PropTypes.bool,
  fullWidth: PropTypes.bool,
  logoSize: PropTypes.string,
  buttonProps: PropTypes.shape({}),
  listItemProps: PropTypes.shape({}),
};
