import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import BNContext from '../../../contexts/BNContext';
import RadioSelector from '../RadioSelectorV2/RadioSelector';
import { DATE_PICKER_OPTIONS } from '../../../constants/date-selector';

import styles from './RadioDateSelector.module.scss';

export default function RadioDateSelector({ className }) {
  const { timeframe, setTimeframe } = React.useContext(BNContext);

  const selectorOptions = [
    {
      label: `${DATE_PICKER_OPTIONS[0].label} timeline`,
      value: DATE_PICKER_OPTIONS[0].quantity,
    },
    {
      label: `${DATE_PICKER_OPTIONS[1].label} timeline`,
      value: DATE_PICKER_OPTIONS[1].quantity,
    },
    {
      label: `${DATE_PICKER_OPTIONS[2].label} timeline`,
      value: DATE_PICKER_OPTIONS[2].quantity,
    },
  ];

  function handleDateChange(quantity) {
    setTimeframe({
      unit: 'days',
      quantity,
      end: dayjs().endOf('day').toDate(),
    });
  }

  return (
    <RadioSelector
      onChange={handleDateChange}
      className={classNames(styles.RadioDateSelector, className)}
      items={selectorOptions}
      value={timeframe.quantity}
    />
  );
}

RadioDateSelector.propTypes = {
  className: PropTypes.string,
};
