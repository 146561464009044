import { useMemo, useEffect, useState, useContext, useCallback } from 'react';
import { Grid } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import sumBy from 'lodash/sumBy';
import dayjs from 'dayjs';
import {
  ChannelSpendByTimeperiod,
  getChannelSpendByTimeperiod,
  getChannelsDataFromSpendByChannel,
} from '../../../../api/transforms/Pages/Creative/CreativeOverview';
import {
  CreativeAsset,
  CreativeChannelSpendAsset,
} from '../../../../interfaces/creative';
import { abbreviateNumber } from '../../../../utils/number';
import AdCardHeader from '../AdCardHeader/AdCardHeader';
import CreativeAssetCard from '../CreativeAssetCard';
import CreativeChannelsSection from '../CreativeChannelsSection/CreativeChannelsSection';
import SpendByPeriodBarChart from '../SpendByPeriodBarChart/SpendByPeriodBarChart';
import TopList from '../TopList/TopList';
import { GET_CREATIVE_BRAND_OVERVIEW_CARD_BY_BRAND_KEY } from '../../../../api/queries/Pages/CreativeChannels';
import BNContext from '../../../../contexts/BNContext';
import CreativeAssetModalContent from '../CreativeAssetModalContent';
import ModalContainer from '../../../Modals/ModalContainer';
import NoResultsAlert from '../../../Atoms/NoResultsAlert/NoResultsAlert';
import { getDayJsStartDate } from '../../../../utils/timeframe';

import styles from './CreativeOverviewAdCard.module.scss';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import useRouter from '../../../../hooks/useRouter';
import QUERY_PARAMS from '../../../../constants/queryParams';

export interface CreativeOverviewAdCardProps {
  brandKey: string;
  brandName: string;
  logoUrl?: string;
}

const CreativeOverviewAdCard = ({
  brandKey,
  brandName,
  logoUrl = '',
}: CreativeOverviewAdCardProps) => {
  const { nonSessionTimeframe } = useContext(BNContext);
  const { updateRoute } = useRouter();

  const queryVariableBase = {
    input: {
      brandKey,
      start: getDayJsStartDate(nonSessionTimeframe).toISOString(),
      end: dayjs(nonSessionTimeframe?.end).toISOString(),
    },
  };

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedCreative, setSelectedCreative] =
    useState<CreativeAsset | null>(null);

  const [getCreativeBrandOverviewCardByBrandKey, { data, loading, called }] =
    useLazyQuery(GET_CREATIVE_BRAND_OVERVIEW_CARD_BY_BRAND_KEY, {
      variables: queryVariableBase,
    });

  useEffect(() => {
    if (!brandKey || called) {
      return;
    }

    getCreativeBrandOverviewCardByBrandKey();
  }, [getCreativeBrandOverviewCardByBrandKey, brandKey, called]);

  const latestCreatives: CreativeAsset[] = useMemo(() => {
    return data?.getCreativeBrandOverview?.latestCreatives;
  }, [data]);

  const channelsData: CreativeChannelSpendAsset[] = useMemo(() => {
    return getChannelsDataFromSpendByChannel(
      data?.getCreativeBrandOverview?.spendByChannelId
    );
  }, [data]);

  const percentDifference: number = useMemo(() => {
    return data?.getCreativeBrandOverview?.overallDiff ?? 0;
  }, [data]);

  const spend: string = useMemo(() => {
    let result = 0;

    if (!data?.getCreativeBrandOverview?.spendByChannelId) {
      return 'n/a';
    }

    Object.values(data.getCreativeBrandOverview.spendByChannelId).forEach(
      (channel) => {
        result += sumBy((channel as CreativeChannelSpendAsset).data, 'y');
      }
    );

    return abbreviateNumber(result);
  }, [data]);

  const spendByPeriodData: ChannelSpendByTimeperiod = useMemo(() => {
    return getChannelSpendByTimeperiod(
      data?.getCreativeBrandOverview?.spendByChannelId
    );
  }, [data]);

  const topNetworks: string[] = useMemo(() => {
    return data?.getCreativeBrandOverview?.topNetworks;
  }, [data]);

  const topProducts: string[] = useMemo(() => {
    return data?.getCreativeBrandOverview?.topProducts;
  }, [data]);

  const handleOnClick = useCallback(() => {
    updateRoute({
      pathname: '/creative/channels/advertising',
      params: { [QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY]: brandKey },
      replace: true,
    });
  }, [brandKey, updateRoute]);

  const handleCardClick = (creative: CreativeAsset) => {
    setSelectedCreative({ ...creative });
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCreative(null);
    setModalIsOpen(false);
  };

  return (
    <div
      className={styles.CreativeOverviewAdCardContainer}
      data-cy="creative-overview-ad-card"
    >
      <AdCardHeader
        brandName={brandName}
        handleOnClick={handleOnClick}
        logoUrl={logoUrl}
        percentDifference={percentDifference}
        spend={spend}
      />

      {loading ? (
        <ComponentLoader />
      ) : (
        <Grid container className={styles.CardContent}>
          <Grid
            item
            xs={5}
            className={styles.CardSection}
            data-cy="section-latest-creative"
          >
            <p className={styles.SectionHeader} data-cy="section-title">
              Latest Creative
            </p>

            {latestCreatives?.length ? (
              <Grid container className={styles.CreativeContainer}>
                {latestCreatives?.map((creative: CreativeAsset) => (
                  <Grid
                    key={creative.id}
                    item
                    xs={6}
                    rowSpacing={1}
                    columnSpacing={1}
                    className={styles.CreativeCard}
                  >
                    <CreativeAssetCard
                      handleOnClick={handleCardClick}
                      creative={creative}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <NoResultsAlert text="No creatives for the date range selected." />
            )}
          </Grid>

          <Grid
            item
            xs={4}
            className={styles.CardSection}
            data-cy="section-spend"
          >
            <div className={styles.HeaderContainer}>
              <p className={styles.SectionHeader} data-cy="section-title">
                Spend
              </p>
              {/* <p className={styles.SubHeader}>{timeLabel}</p> */}
            </div>

            {spendByPeriodData?.spendData?.length ? (
              <SpendByPeriodBarChart
                labels={spendByPeriodData.labels}
                data={spendByPeriodData.spendData}
              />
            ) : (
              <NoResultsAlert text="No spend data available for the data range selected." />
            )}

            <div className={styles.TopLists}>
              <TopList topNetworks={topNetworks} topProducts={topProducts} />
            </div>
          </Grid>

          <Grid
            item
            xs={3}
            className={styles.CardSection}
            data-cy="section-channels"
          >
            <div className={styles.HeaderContainer}>
              <p className={styles.SectionHeader} data-cy="section-title">
                Channels
              </p>
              {Boolean(channelsData?.length) && (
                <p data-cy="top-channel-count" className={styles.SubHeader}>
                  TOP {channelsData?.length}
                </p>
              )}
            </div>

            {channelsData?.length ? (
              <CreativeChannelsSection channelsData={channelsData} />
            ) : (
              <NoResultsAlert text="No spend data available for the data range selected." />
            )}
          </Grid>
        </Grid>
      )}

      {selectedCreative ? (
        <ModalContainer isOpen={modalIsOpen} closeModal={handleCloseModal}>
          <CreativeAssetModalContent creative={selectedCreative} />
        </ModalContainer>
      ) : null}
    </div>
  );
};

export default CreativeOverviewAdCard;
