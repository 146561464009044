import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Line } from 'react-chartjs-2';
import { Chart, registerables, ChartOptions } from 'chart.js';

import { ChartPoint } from '../../../interfaces';
import { ScoreType } from '../../../interfaces/metric';

import 'chartjs-adapter-dayjs-4';

import styles from './SingleMetricLineChart.module.scss';

Chart.register(...registerables);

export interface SingleMetricLineChartProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  mainLineTimeseriesData: ChartPoint[];
  dashedLineTimeseriesData: ChartPoint[];
  scoreType?: ScoreType;
}

const SingleMetricLineChart: FunctionComponent<SingleMetricLineChartProps> = ({
  className,
  mainLineTimeseriesData,
  dashedLineTimeseriesData,
  scoreType = ScoreType.Indexed,
}) => {
  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        type: 'timeseries',
        time: {
          unit: 'day',
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  };

  const chartData = {
    datasets: [
      {
        data: mainLineTimeseriesData,
        fill: true,
        showLine: true,
        tension: 0.7,
        borderWidth: 3,
        pointRadius: 0,
        backgroundColor:
          scoreType === ScoreType.Indexed
            ? '#D1E2E6'
            : 'rgba(244, 209, 174, 0.13)',
        borderColor: scoreType === ScoreType.Indexed ? '#1A8AA0' : '#D1A476',
      },
      {
        data: dashedLineTimeseriesData,
        fill: false,
        showLine: true,
        tension: 0.7,
        borderWidth: 2,
        pointRadius: 0,
        borderColor: '#6D7A84',
        borderDash: [6],
      },
    ],
  };

  return (
    <div className={classNames(styles.SingleMetricLineChart, className)}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default SingleMetricLineChart;
