import { GenericIconProps } from '../types';

const PNGIcon = (props: GenericIconProps) => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="46" height="46" rx="8" fill="#294249" />
    <path
      d="M14.3583 30.1896H31.6771L26.4708 23.2479L21.7958 29.3042L18.5021 24.8062L14.3583 30.1896ZM12.375 35.75C11.8083 35.75 11.3125 35.5375 10.8875 35.1125C10.4625 34.6875 10.25 34.1917 10.25 33.625V12.375C10.25 11.8083 10.4625 11.3125 10.8875 10.8875C11.3125 10.4625 11.8083 10.25 12.375 10.25H33.625C34.1917 10.25 34.6875 10.4625 35.1125 10.8875C35.5375 11.3125 35.75 11.8083 35.75 12.375V33.625C35.75 34.1917 35.5375 34.6875 35.1125 35.1125C34.6875 35.5375 34.1917 35.75 33.625 35.75H12.375ZM12.375 33.625H33.625V12.375H12.375V33.625ZM12.375 12.375V33.625V12.375Z"
      fill="white"
    />
  </svg>
);

export default PNGIcon;
