import { FC, useMemo, useContext } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import round from 'lodash/round';
import { Chart, registerables, ScriptableContext } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4';

import { CustomerScore, Point, EventMarker, Flag } from './interfaces';
import { ScoreType, MetricFormatType } from '../../../interfaces/metric';
import BNContext from '../../../contexts/BNContext';
import useColdstartFiller from '../../../hooks/useColdstartFiller';
import { metricValueFormattersByType } from '../../../utils/metric';

import {
  bluescoreTimelineEventTooltip,
  bluescoreTimelineSessionTooltip,
} from '../../../chartjs/tooltips';

import { plugin, renderBluescoreGradient } from './utils';
import colors from '../../../constants/colors';
import styles from './BluescoreTimelineChart.module.scss';

Chart.register(plugin, ...registerables);
export interface BluescoreTimelineChartProps {
  useSuggestedScale?: boolean;
  flags?: Flag[];
  showFlags?: boolean;
  showTooltips?: boolean;
  customerScoresOverTime?: CustomerScore[];
  competitorAverageScore?: number;
  yAxis?: boolean;
  xAxis?: boolean;
  showReducedXAxis?: boolean;
  yAxisBuffer?: boolean;
  yDomainOverride?: {
    min: number;
    max: number;
  };
  yMaxTicksLimit?: number;
  scoreType?: ScoreType;
  id?: string;
  formatType?: MetricFormatType;
}

const BluescoreTimelineChart: FC<BluescoreTimelineChartProps> = ({
  competitorAverageScore = 100,
  customerScoresOverTime,
  yAxis = true,
  xAxis = true,
  yAxisBuffer,
  showReducedXAxis = false,
  showTooltips,
  yDomainOverride,
  flags,
  showFlags,
  useSuggestedScale,
  yMaxTicksLimit = 6,
  scoreType = ScoreType.Indexed,
  id,
  formatType = MetricFormatType.None,
}) => {
  const { timeframe } = useContext(BNContext);

  const timelineData = useMemo(() => {
    if (!customerScoresOverTime) {
      return [];
    }

    const result = [] as Array<Point>;

    customerScoresOverTime.forEach((data) => {
      result.push({
        x: dayjs(data.date).startOf('d').valueOf(),
        y: round(Number(data.value), 3) || 0,
      });
    });

    return result;
  }, [customerScoresOverTime]);

  const yDomain = useMemo(() => {
    if (!timelineData || !timelineData.length) {
      return {
        min: Number.MAX_SAFE_INTEGER,
        max: -Number.MAX_SAFE_INTEGER,
        buffer: 0,
      };
    }

    let yMin = Number.MAX_SAFE_INTEGER;
    let yMax = -Number.MAX_SAFE_INTEGER;

    const timelineYMin = minBy(timelineData, 'y') as Point;
    const timelineYMax = maxBy(timelineData, 'y') as Point;

    if (yDomainOverride && yDomainOverride.min) {
      yMin = yDomainOverride.min;
    } else {
      if (timelineYMin) {
        yMin = minBy(timelineData, 'y')?.y || 0;
      }

      if (competitorAverageScore && scoreType === ScoreType.Indexed) {
        yMin = Math.min(yMin, competitorAverageScore);
      }
    }

    if (yDomainOverride && yDomainOverride.max) {
      yMax = yDomainOverride.max;
    } else {
      if (timelineYMax) {
        yMax = maxBy(timelineData, 'y')?.y || 0;
      }

      if (competitorAverageScore && scoreType === ScoreType.Indexed) {
        yMax = Math.max(yMax, competitorAverageScore);
      }
    }

    let buffer = (yMax - yMin) * 0.1;

    if (yMax - yMin <= 1) {
      buffer = yMax * 0.1;
    }

    // fixes a bug where if the yMin or yMax was too close to timelineY value,
    //  the tooltip would get cutoff
    if (yAxisBuffer) {
      yMax += buffer;
      yMin -= buffer;
    }

    // fixes a bug if the yMin is so close to the yMax values, the scales rendering is too large
    if (yMax - timelineYMin.y <= 2) {
      yMax += buffer;
    }

    if (scoreType === ScoreType.Indexed) {
      yMax = Math.min(yMax, 200);
      yMin = Math.max(yMin, 0);
    }

    return {
      min: yMin,
      max: yMax,
    };
  }, [
    timelineData,
    yDomainOverride,
    competitorAverageScore,
    yAxisBuffer,
    scoreType,
  ]);

  const fillerTimelineData = useColdstartFiller({ timelineData, yDomain });

  const wholeTimelineData = useMemo(() => {
    if (!fillerTimelineData) {
      return timelineData || [];
    }
    // since sessions does not return data to the point of the last selected date
    // from the datepicker, we are cloning the last y axis point to draw a straight line
    // to the last date selected on the x axis, and showing the last date selected
    const partialTimelineData = [...fillerTimelineData.concat(timelineData)];

    return [...partialTimelineData];
  }, [timelineData, fillerTimelineData]);

  function formatDateTimeStamp(date?: Date) {
    return new Date(dayjs(date).format());
  }

  const xDomain = useMemo(() => {
    if (
      !wholeTimelineData ||
      !wholeTimelineData[0] ||
      !wholeTimelineData[wholeTimelineData.length - 1]
    ) {
      return {
        min: Number.MAX_SAFE_INTEGER,
        max: -Number.MAX_SAFE_INTEGER,
      };
    }

    return {
      min: formatDateTimeStamp(wholeTimelineData[0].x),
      max: formatDateTimeStamp(timeframe?.end),
    };
  }, [wholeTimelineData, timeframe]);

  const flagData = useMemo(() => {
    const flagBuffer = 5;
    const result = [] as Array<EventMarker>;

    if (!flags || !flags.length) {
      return result;
    }

    flags.forEach((flag: Flag) => {
      const trimmedStringMaxLength = flag.description.substring(0, 30);

      result.push({
        x: dayjs(flag?.date).valueOf(),
        description: trimmedStringMaxLength,
        y: yDomain.max - flagBuffer,
      });
    });

    const epochXDomainMin = dayjs(xDomain.min).valueOf();
    const epochXDomainMax = dayjs(xDomain.max).valueOf();

    // filter between x domain
    return result.filter(
      (flag: EventMarker) =>
        flag.x >= epochXDomainMin && flag.x <= epochXDomainMax
    );
  }, [flags, xDomain, yDomain]);

  const lastPointOnChart = [
    {
      x: wholeTimelineData[wholeTimelineData.length - 1]?.x,
      y: wholeTimelineData[wholeTimelineData.length - 1]?.y,
    },
    {
      x: timeframe?.end?.getTime(),
      y: wholeTimelineData[wholeTimelineData.length - 1]?.y,
    },
  ];

  const chartData = {
    datasets: [
      {
        id: showTooltips ? 'showLine' : '',
        data: wholeTimelineData.slice(
          Math.max(fillerTimelineData.length - 1, 0),
          wholeTimelineData.length
        ),
        fill: true,
        pointBackgroundColor: '#ffffff',
        pointBorderColor: 'rgb(39, 129, 155)',
        pointHitRadius: showTooltips ? 5 : 0,
        pointRadius: showTooltips ? 3 : 0,
        pointHoverRadius: showTooltips ? 4 : 0,
        showLine: true,
        tension: 0.5,
        borderWidth: 2,
        backgroundColor: (context: ScriptableContext<'line'>) => {
          if (scoreType === ScoreType.Indexed) {
            return renderBluescoreGradient(context, yDomain?.min, yDomain?.max);
          }

          return '#E7F5F8';
        },
        borderColor: 'rgba(39, 129, 155, 1)',
      },
      {
        tension: 0.5,
        data: wholeTimelineData.slice(0, fillerTimelineData.length),
        fill: true,
        pointRadius: 0,
        pointHitRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: colors.colorGray20,
      },
      {
        data: lastPointOnChart,
        fill: true,
        pointRadius: 0,
        pointHitRadius: 0,
        pointHoverRadius: 0,
        backgroundColor: colors.colorGray20,
      },
      {
        hidden: !showFlags,
        id: showTooltips ? 'showLine' : '',
        data: flagData,
        showLine: false,
        fill: false,
        pointRadius: 5,
        pointHitRadius: 2,
        backgroundColor: colors.chartBluescoreVerticalLine,
      },
    ],
  };

  return (
    <div className={classNames(styles.BluescoreTimelineGraph)} id={id}>
      <Line
        redraw
        id="line"
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
          },
          scales: {
            y: {
              grid: {
                display: !!yAxis,
                borderColor: yAxis ? colors.chartBluescoreBorder : undefined,
              },
              ticks: {
                display: !!yAxis,
                color: '#bdc8c9',
                font: {
                  size: 12,
                  style: 'normal',
                },
                maxTicksLimit: yMaxTicksLimit,
                callback: (value: number | string) => {
                  if (scoreType === ScoreType.Raw) {
                    let roundedVal = Math.round(Number(value));

                    if (Number(value) < 1) {
                      roundedVal =
                        Math.round((Number(value) + Number.EPSILON) * 100) /
                        100;
                    }

                    const metricValue =
                      metricValueFormattersByType[formatType](roundedVal);

                    return metricValue;
                  }

                  return Math.round(Number(value));
                },
              },
              min: !useSuggestedScale ? yDomain?.min : undefined,
              max: !useSuggestedScale ? yDomain?.max : undefined,
              suggestedMin: useSuggestedScale ? yDomain?.min : undefined,
              suggestedMax: useSuggestedScale ? yDomain?.max : undefined,
            },
            x: {
              bounds: showReducedXAxis ? 'ticks' : 'data',
              grid: {
                display: false,
                borderWidth: 1,
                borderColor: xAxis ? colors.chartBluescoreBorder : undefined,
              },
              ticks: {
                display: !!xAxis,
                minRotation: 0,
                maxRotation: 0,
                padding: 0,
                align: 'inner',
                maxTicksLimit: showReducedXAxis ? 2 : 8,
                source: 'data',
                font: {
                  size: 14,
                  style: 'normal',
                },
              },
              type: 'time',
              time: {
                stepSize: showReducedXAxis ? 4 : 1,
                unit: 'month',
                tooltipFormat: 'MMM DD YYYY',
                displayFormats: {
                  month: showReducedXAxis ? 'MMM YYYY' : 'MMM',
                },
              },
            },
          },
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
              external: (context) => {
                const hasMarkerEvents = context.tooltip.dataPoints.find(
                  (item) => item.datasetIndex === 3
                );

                if (showTooltips) {
                  if (hasMarkerEvents) {
                    return bluescoreTimelineEventTooltip(context);
                  }
                  return bluescoreTimelineSessionTooltip(context);
                }
                return null;
              },
            },
            annotation:
              scoreType === ScoreType.Indexed
                ? {
                    annotations: {
                      line: {
                        type: 'line',
                        yMin: competitorAverageScore,
                        yMax: competitorAverageScore,
                        borderWidth: 1.2,
                        borderColor: colors.chartBluescoreBorder,
                        borderDash: [5],
                      },
                    },
                  }
                : undefined,
          },
        }}
      />
    </div>
  );
};

export default BluescoreTimelineChart;
