/* eslint-disable import/prefer-default-export */

import { gql } from '@apollo/client';

export const GET_BRAND_ADVERTISING_METRICS = gql`
  query getBrandAdvertisingMetrics($input: BrandTimeSeriesInput) {
    getSpendGraphData(input: $input) {
      total
      totalDiff
      overall {
        x
        y
      }
      all {
        label
        total
        previous
        share
        diff
        data {
          x
          y
        }
      }
    }

    geteCPMGraphData(input: $input) {
      total
      diff
      overall {
        x
        y
      }
      # channel {
      #   label
      #   total
      #   previous
      #   share
      #   diff
      #   data {
      #     x
      #     y
      #   }
      # }
    }
  }
`;

export const GET_COHORT_ADVERTISING_METRICS = gql`
  query getCohortAdvertisingMetrics($input: CohortTimeSeriesInput) {
    getShareOfSpendGraphData(input: $input) {
      total
      diff
      heroChart {
        x
        y
      }
      competitionChart {
        x
        y
      }
    }

    getCompetitionAverageGraphData(input: $input) {
      total
      diff
      overall {
        x
        y
      }
    }
  }
`;

export const GET_SPEND_DETAILS = gql`
  query getSpendGraphDetails($input: BrandTimeSeriesInput) {
    getSpendGraphData(input: $input) {
      total
      totalProduct
      totalDigital
      digitalDiff
      totalDiff
      productDiff
      overall {
        x
        y
      }
      digital {
        label
        total
        previous
        diff
        data {
          x
          y
        }
      }
      all {
        label
        total
        previous
        diff
        data {
          x
          y
        }
      }
      product {
        label
        total
        previous
        diff
        data {
          x
          y
        }
      }
    }
  }
`;

export const GET_SHARE_OF_SPEND_DETAILS = gql`
  query getShareOfSpendDetails($input: CohortTimeSeriesInput) {
    getShareOfSpendGraphData(input: $input) {
      total
      diff
      channelAverages {
        label
        total
        previous
        diff
        data {
          x
          y
        }
      }
      channels {
        label
        total
        previous
        diff
        data {
          x
          y
        }
      }
      brands {
        label
        total
        previous
        diff
        data {
          y
          x
        }
      }
    }
  }
`;

export const GET_COMPETITION_AVERAGE_DETAILS = gql`
  query getCompetitionAverageDetails($input: CohortTimeSeriesInput) {
    getCompetitionAverageGraphData(input: $input) {
      total
      previous
      diff
      overall {
        x
        y
      }
      heroBrand {
        label
        total
        previous
        diff
        data {
          x
          y
        }
      }
      competitionAverage {
        label
        total
        previous
        diff
        data {
          y
          x
        }
      }
    }
  }
`;

export const GET_ECPM_DETAILS = gql`
  query getECPMDetails($input: BrandTimeSeriesInput) {
    geteCPMGraphData(input: $input) {
      total
      diff
      channel {
        label
        total
        previous
        diff
        data {
          x
          y
        }
      }
      overall {
        y
        x
      }
    }
  }
`;

export const GET_CREATIVES = gql`
  query getCreatives($input: GetCreativesInput) {
    getCreatives(input: $input) {
      creatives {
        id
        name
        spend
        impressions
        eCPM
        assetUrl
        share
        channel
        thumbnailUrl
        type
        channelId
        seen {
          start
          end
        }
      }
      count
    }
  }
`;
