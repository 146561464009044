import { useLazyQuery } from '@apollo/client';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import capitalize from 'lodash/capitalize';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/Pages/CustomizableDashboards';
import { GET_COMPETITORS } from '../../../../../api/queries/Pages/MarketIndexCompare';
import useBrandOptions from '../../../../../hooks/useBrandOptions';
import useCompetitiveSetOptions from '../../../../../hooks/useCompetitiveSetOptions';
import {
  ComparisonType,
  TimeRange,
} from '../../../../../interfaces/dashboard-api';
import {
  FlowKey,
  WidgetConfigProps,
  DashboardsMetricScore,
} from '../../../../../interfaces/widget-settings';
import DropdownSelect, {
  DropdownOption,
} from '../../../Creative/DropdownSelect/DropdownSelect';
import { BrandDropdownOption, WidgetSettingsModalAction } from '../../types';
import { DateRangeOptions, ScoreTypeOptions } from '../../WidgetSettingsShared';
import { getMetrics } from '../../../../../mocks/data/CustomizableDashboards/metrics';
import styles from './PaceAnalysisSettingsGuide.module.scss';
import RivalBrandsForm from './RivalBrandsForm/RivalBrandsForm';
import { FeatureFlag } from '../../../../../utils/featureFlags';
import DateRangeSelectorDropdown from '../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';

interface FormFields {
  name: string;
  description: string;
  timeRange: TimeRange;
  endDate: Date;
  competitiveSetKey: string;
  brandKey: string;
  comparisonType: ComparisonType;
  scoreType: string;
  rivalBrands: string[];
}

const metricById = keyBy(getMetrics, 'id');

const PaceAnalysisSettingsGuide = ({
  config,
  settingAction,
  onConfigChange,
  onValidationChange,
}: WidgetConfigProps) => {
  const setMetricName = settingAction === WidgetSettingsModalAction.Add;
  const metric = config[FlowKey.SelectMetric];

  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );
  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    { fetchPolicy: 'no-cache' }
  );

  const {
    name,
    description,
    timeRange,
    endDate,
    competitiveSetKey,
    brandKey,
    comparisonType,
    scoreType,
    rivalBrands,
  } = config[FlowKey.PaceAnalysisSettings];
  const [inputValues, setInputValues] = useState<FormFields>({
    name: setMetricName ? metric.name : name,
    description,
    timeRange,
    endDate,
    competitiveSetKey,
    brandKey,
    comparisonType,
    scoreType,
    rivalBrands,
  });

  /* Populate Dropdowns and their selected option */
  const comparisonOptions = Object.values(ComparisonType).map((comparison) => ({
    value: comparison as string,
    label: `vs ${capitalize(comparison)}`,
  }));

  const selectedComparison = useMemo(() => {
    const selected = find(comparisonOptions, {
      value: inputValues.comparisonType,
    });
    return selected ?? comparisonOptions[0];
  }, [comparisonOptions, inputValues.comparisonType]);

  let scoreTypeOptions = ScoreTypeOptions;
  const hasRawData = metricById[metric.id]?.is_raw_score_available;
  if (!hasRawData) {
    scoreTypeOptions = scoreTypeOptions.filter(
      (option) => option.value !== DashboardsMetricScore.Raw
    );
  }

  const selectedScoreType = useMemo(() => {
    const selected = find(scoreTypeOptions, { value: inputValues.scoreType });
    return selected ?? scoreTypeOptions[0];
  }, [inputValues.scoreType, scoreTypeOptions]);

  const competitiveSetOptions = useCompetitiveSetOptions();
  const brandOptions = useBrandOptions(competitorsResp);

  const selectedCompetitorOption = useMemo(() => {
    if (!inputValues.competitiveSetKey && !competitiveSetOptions.length)
      return null;

    return find(competitiveSetOptions, {
      value: inputValues.competitiveSetKey,
    });
  }, [competitiveSetOptions, inputValues.competitiveSetKey]);

  const selectedBrandOption = useMemo(() => {
    if (!inputValues.brandKey && !brandOptions.length) return null;

    return find(brandOptions, { value: inputValues.brandKey });
  }, [brandOptions, inputValues.brandKey]);

  const selectedRivalBrands = new Set(inputValues.rivalBrands);

  const selectedTimeRange = useMemo(
    () => DateRangeOptions.find(({ value }) => value === inputValues.timeRange),
    [inputValues.timeRange]
  );

  /* Event Handlers */
  const handleConfigSave = useCallback(
    (newInputValues: FormFields) => {
      const newConfig = structuredClone(config);
      const settingsConfig = newConfig[FlowKey.PaceAnalysisSettings];
      newConfig[FlowKey.PaceAnalysisSettings] = {
        ...settingsConfig,
        ...newInputValues,
      };

      onConfigChange(newConfig);
    },
    [config, onConfigChange]
  );

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    const updatedInputValues = {
      ...inputValues,
      [key]: value,
    };

    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  };

  const handleDropdownChange = (
    updatedOption: SingleValue<DropdownOption>,
    field: string
  ) => {
    if (!updatedOption || !updatedOption?.value) return;

    const newInputValues = {
      ...inputValues,
      [field]: updatedOption.value,
    };

    if (field === 'brandKey' || field === 'competitiveSetKey') {
      newInputValues.rivalBrands = [];
    }

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    const newInputValues = {
      ...inputValues,
      timeRange: (e.timeRange as TimeRange) ?? TimeRange['90_DAYS'],
      endDate: e.endDate,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleRivalBrandsChange = (brand: BrandDropdownOption) => {
    const newRivalBrands = new Set(inputValues.rivalBrands);
    if (newRivalBrands.has(brand.value)) {
      newRivalBrands.delete(brand.value);
    } else {
      newRivalBrands.add(brand.value);
    }

    const newInputValues = {
      ...inputValues,
      rivalBrands: Array.from(newRivalBrands),
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  /* Fetch Data */
  // if no competitiveSetKey, set first as default competitiveSetKey
  useEffect(() => {
    if (inputValues.competitiveSetKey || !competitiveSetOptions.length) return;

    const updatedInputValues = {
      ...inputValues,
      competitiveSetKey: competitiveSetOptions[0].value,
    };
    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  }, [competitiveSetOptions, handleConfigSave, inputValues]);

  // if no brandKey, set first as default brandKey
  useEffect(() => {
    if (
      brandOptions.length &&
      !find(brandOptions, { value: inputValues.brandKey })
    ) {
      const updatedInputValues = {
        ...inputValues,
        rivalBrands: [],
        brandKey: brandOptions[0].value,
      };
      setInputValues(updatedInputValues);
      handleConfigSave(updatedInputValues);
    }
  }, [brandOptions, handleConfigSave, inputValues]);

  // if no competitiveSet get required sessions
  useEffect(() => {
    if (!selectedCompetitorOption?.value) return;

    getFirstLastSession({ variables: { id: selectedCompetitorOption.value } });
  }, [getFirstLastSession, selectedCompetitorOption]);

  // get cometitiveSet with new SessionKey
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
        ?.sessionKey ||
      !selectedCompetitorOption?.value
    ) {
      return;
    }

    const { sessionKey } =
      firstAndLastSessionResp.competitiveSet.firstAndLastSession[1];
    getCompetitors({
      variables: { id: selectedCompetitorOption.value, sessionKey },
    });
  }, [
    firstAndLastSessionResp,
    getCompetitors,
    selectedCompetitorOption,
    comparisonType,
  ]);

  useEffect(() => {
    const maxRivals = 2;
    const disableSave =
      !name ||
      !description ||
      !timeRange ||
      !competitiveSetKey ||
      !brandKey ||
      !comparisonType ||
      !scoreType ||
      (comparisonType === ComparisonType.RIVALS &&
        rivalBrands.length !== maxRivals);

    onValidationChange({
      [FlowKey.PaceAnalysisSettings]: disableSave,
    });
  }, [
    onValidationChange,
    name,
    description,
    timeRange,
    competitiveSetKey,
    brandKey,
    comparisonType,
    scoreType,
    rivalBrands,
  ]);

  return (
    <div className={styles.PaceAnalysisSettingsGuide}>
      <div className={styles.Group}>
        <p className={styles.Label}>
          WIDGET NAME
          <span className={styles.Required}>*</span>
        </p>
        <input
          className={styles.Field}
          required
          type="text"
          name="name"
          value={inputValues.name}
          onChange={handleNameChange}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>VS COMPARISON</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={comparisonOptions}
          onChange={(e) => handleDropdownChange(e, 'comparisonType')}
          value={selectedComparison}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>COMPETITIVE SET</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={competitiveSetOptions}
          onChange={(e) => handleDropdownChange(e, 'competitiveSetKey')}
          value={selectedCompetitorOption}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>BRAND</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={brandOptions}
          onChange={(e) => handleDropdownChange(e, 'brandKey')}
          value={selectedBrandOption}
        />
      </div>

      {comparisonType === ComparisonType.RIVALS && (
        <RivalBrandsForm
          brands={brandOptions}
          selectedBrand={inputValues.brandKey}
          selectedRivals={selectedRivalBrands}
          handleBrandSelect={handleRivalBrandsChange}
        />
      )}

      <div className={styles.Group}>
        <p className={styles.Label}>SCORE TYPE</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={scoreTypeOptions}
          onChange={(e) => handleDropdownChange(e, 'scoreType')}
          value={selectedScoreType}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>DATE RANGE</p>
        {showCustomDateRangeSelector ? (
          <DateRangeSelectorDropdown
            onApply={handleApply}
            timeRange={timeRange}
            endDate={endDate}
            fullWidth
          />
        ) : (
          <DropdownSelect
            className={styles.Dropdown}
            options={DateRangeOptions}
            onChange={(e) => handleDropdownChange(e, 'timeRange')}
            value={selectedTimeRange}
          />
        )}
      </div>
    </div>
  );
};

export default PaceAnalysisSettingsGuide;
