export enum FactorKey {
  Familiar = 'familiar',
  Unique = 'unique',
  Consistent = 'consistent',
  Relevant = 'relevant',
  Revered = 'revered',
}

export enum SubfactorKey {
  Visible = 'visible',
  Considered = 'considered',
  Amplified = 'amplified',

  Different = 'different',
  Memorable = 'memorable',

  Clear = 'clear',
  Reinforcing = 'reinforcing',
  Stable = 'stable',

  Needed = 'needed',
  Valued = 'valued',
  Influential = 'influential',

  Desirable = 'desirable',
  Esteemed = 'esteemed',
  Inspiring = 'inspiring',
}
