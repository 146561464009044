import { getOrCreateTooltip } from '../../../utils/chartjs';
import { ChartTooltipContextLine, EventPoint } from '../../../interfaces/chart';

import styles from './bluescoreTimelineTooltip.module.scss';

export default function renderMarkerEventTooltip(
  context: ChartTooltipContextLine
): void {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, {
    wrapper: styles.MarkerTooltip,
    contents: styles.TooltipContents,
  });
  //   Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltip.body) {
    const tooltipContents = tooltipEl.querySelector(
      `.${styles.TooltipContents}`
    );

    const getDataPoint = (tooltip.dataPoints as unknown as EventPoint[]).find(
      (item: EventPoint) => {
        return item?.raw?.description;
      }
    );

    const tooltipParent = document.createElement('div');
    tooltipParent.classList.add(styles.MarkerEventTooltip);

    const eventTextEl = document.createElement('div');
    eventTextEl.classList.add(styles.DescriptionText);

    const descriptionEl = document.createTextNode(
      getDataPoint ? getDataPoint.raw.description : ''
    );

    eventTextEl.appendChild(descriptionEl);
    tooltipParent.appendChild(eventTextEl);

    // Remove old children
    while (tooltipContents?.firstChild) {
      tooltipContents.firstChild.remove();
    }

    // Add new children
    tooltipContents?.appendChild(tooltipParent);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.top = `${positionY + tooltip.caretY - 65}px`;
    tooltipEl.style.right = `${positionX + tooltip.caretX}px`;

    if (
      // fixes short tooltips on the right side of chart
      getDataPoint &&
      getDataPoint?.raw?.description?.length <= 10 &&
      tooltip.x >= chart.width / 1.5
    ) {
      tooltipEl.style.left = `${positionX + tooltip.caretX - 30}px`;
      // fixes tooltips that extend past right side of chart
    } else if (tooltip.x >= chart.width / 1.5) {
      tooltipEl.style.left = `${positionX + tooltip.caretX - 100}px`;
    } else {
      tooltipEl.style.left = `${positionX + tooltip.caretX - 10}px`;
    }

    tooltipEl.style.padding = `${tooltip.options.padding}px`;
    tooltipEl.style.opacity = '1';
  }
}
