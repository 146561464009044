import dayjs from 'dayjs';
import {
  ChartTooltipContextBar,
  ChartTooltipContextLine,
  ChartMultipleBrandTooltipDataMap,
  ChartColor,
  ChartPoint,
  YAxisFormatter,
} from '../../../interfaces';
import { getOrCreateTooltip } from '../../../utils/chartjs';
import createBrandListTooltipContent from './brandListTooltipContents';

import styles from './brandCompareTooltip.module.scss';

export interface TimeseriesTooltipMultipleBrands {
  context: ChartTooltipContextBar | ChartTooltipContextLine;
  tooltipDataByDate: ChartMultipleBrandTooltipDataMap;
  brandColorByBrandKey: {
    [key: string]: ChartColor;
  };
  yAxisFormatter?: YAxisFormatter;
  hideArrow?: boolean;
}

export default function createCustomTooltip({
  context,
  tooltipDataByDate,
  brandColorByBrandKey,
  yAxisFormatter,
  hideArrow,
}: TimeseriesTooltipMultipleBrands): null {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, {
    wrapper: styles.BrandCompareTooltip,
    contents: styles.TooltipContents,
  });

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return null;
  }

  // Build tooltip
  if (tooltip.body) {
    const tooltipContents = tooltipEl.querySelector(
      `.${styles.TooltipContents}`
    );

    const groupDate = dayjs((tooltip.dataPoints[0].raw as ChartPoint).x).format(
      'M/D/YYYY'
    );
    const tooltipData = tooltipDataByDate[groupDate];

    createBrandListTooltipContent({
      brands: tooltipData,
      styles,
      brandColorByBrandKey,
      tooltipContainerEl: tooltipContents,
      yAxisFormatter,
    });

    // Add arrow
    if (!hideArrow) {
      const tooltipArrow = document.createElement('div');
      tooltipArrow.classList.add(
        tooltip.xAlign === 'center' || tooltip.xAlign === 'left'
          ? styles.TooltipArrowLeft
          : styles.TooltipArrowRight
      );

      tooltipArrow.style.top = `${tooltipEl.clientHeight / 2 - 10}px`;

      tooltipContents?.appendChild(tooltipArrow);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.top = `${
      positionY + tooltip.caretY - tooltipEl.clientHeight / 2
    }px`;

    // Display, position, and set styles for font
    if (tooltip.xAlign === 'center' || tooltip.xAlign === 'left') {
      tooltipEl.style.left = `${
        positionX + tooltip.caretX + tooltipEl.clientWidth / 2 + 20
      }px`;
    } else {
      tooltipEl.style.left = `${
        positionX + tooltip.caretX - tooltipEl.clientWidth / 2 - 20
      }px`;
    }

    tooltipEl.style.opacity = '1';
  }

  return null;
}
