import { FunctionComponent, useMemo } from 'react';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import classNames from 'classnames';
import meanBy from 'lodash/meanBy';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import { ChartDataset, ChartColor, YAxisFormatter } from '../../../interfaces';
import useBrandCompareTimeseriesChartTooltipFormatter from '../../../hooks/useBrandCompareTimeseriesChartTooltipFormatter';
import { xHoverAnnotationLine } from '../../../chartjs/plugins';
import { brandCompareTimeseriesTooltipMultiple } from '../../../chartjs/tooltips';
import 'chartjs-adapter-dayjs-4';

import styles from './BrandCompareAreaChart.module.scss';
import tooltipStyles from '../../../chartjs/tooltips/brandCompareTooltip/brandCompareTooltip.module.scss';

Chart.register(...registerables);

export interface BrandCompareAreaChartProps {
  className?: string;
  brandsDatasets: ChartDataset[];
  chartColorMapByBrandKey: {
    [key: string]: ChartColor;
  };
  yAxisFormatter?: YAxisFormatter;
}

const BrandCompareAreaChart: FunctionComponent<BrandCompareAreaChartProps> = ({
  className,
  brandsDatasets,
  chartColorMapByBrandKey,
  yAxisFormatter,
}) => {
  const multipleBrandTooltipByFormattedDate =
    useBrandCompareTimeseriesChartTooltipFormatter({ brandsDatasets });

  const chartDatasets = useMemo(() => {
    const datasetsClone = cloneDeep(brandsDatasets);

    datasetsClone.sort((a, b) => {
      return meanBy(a.data, 'y') - meanBy(b.data, 'y');
    });

    return datasetsClone;
  }, [brandsDatasets]);

  const sessionTickLimit = useMemo(() => {
    const sessionCount = brandsDatasets?.[0]?.data?.length;

    if (sessionCount < 4) {
      return 3;
    }

    if (sessionCount < 12) {
      return 6;
    }

    return 10;
  }, [brandsDatasets]);

  return (
    <div className={classNames(styles.BrandCompareAreaChart, className)}>
      <Line
        data={{ datasets: chartDatasets }}
        plugins={[xHoverAnnotationLine]}
        options={{
          interaction: {
            intersect: false,
            mode: 'x',
          },
          responsive: true,
          maintainAspectRatio: false,
          datasets: {
            line: {
              pointRadius: 3,
              pointHoverRadius: 5,
              pointHitRadius: 10,
              tension: 0.4,
              fill: 'origin',
              backgroundColor(context) {
                const { dataset } = context;

                return chartColorMapByBrandKey[
                  (dataset as unknown as ChartDataset).key
                ].FILL;
              },
              borderColor(context) {
                const { dataset } = context;

                return chartColorMapByBrandKey[
                  (dataset as unknown as ChartDataset).key
                ].DEFAULT;
              },
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              xAlign(context) {
                const tooltipEl = document.querySelector(
                  `.${tooltipStyles.BrandCompareTooltip}`
                );

                const tooltipWidth = tooltipEl?.clientWidth;
                const activePointX = context.tooltip.dataPoints[0].element.x;
                const chartEnd = context.tooltip.chart.chartArea.right;

                if (
                  !isNil(tooltipWidth) &&
                  tooltipWidth + activePointX > chartEnd
                ) {
                  return 'right';
                }

                return 'left';
              },
              external(context) {
                return brandCompareTimeseriesTooltipMultiple({
                  context,
                  tooltipDataByDate: multipleBrandTooltipByFormattedDate,
                  brandColorByBrandKey: chartColorMapByBrandKey,
                  hideArrow: true,
                  yAxisFormatter,
                });
              },
            },
            datalabels: { display: false },
            filler: {
              drawTime: 'beforeDatasetsDraw',
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 6,
                font: {
                  size: 13,
                },
                color: 'grey',
                callback(value) {
                  return yAxisFormatter?.(value) || value;
                },
              },
              grid: {
                drawBorder: false,
              },
            },
            x: {
              type: 'timeseries',
              ticks: {
                maxRotation: 0,
                autoSkip: true,
                maxTicksLimit: sessionTickLimit,
                color: 'grey',
                padding: 12,
                font: {
                  size: 13,
                },
                source: 'data',
              },
              time: {
                displayFormats: { day: 'M/D/YYYY' },
                unit: 'day',
              },
              grid: {
                display: false,
                borderWidth: 1,
                borderColor: 'black',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default BrandCompareAreaChart;
