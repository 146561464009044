import { gql } from '@apollo/client';

export const GET_THEME_OPTIONS = gql`
  query getThemes($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        themes {
          id
          name
          themeType
        }
      }
    }
  }
`;

export const GET_THEMES = gql`
  query getThemes($id: ID, $sessionKey: ID, $accountKey: ID, $type: Int) {
    competitiveSet(id: $id) {
      brand {
        id
        name
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        id
        brandId
        themeOverviewInsights {
          name
          value
        }
        themes {
          id
          name
          brand_id
          themeType
          themeTrends {
            associatedTypeId
            t4Quarters
          }
        }
        competitors {
          name
          id
          logoUrl
          themes {
            name
            brand_id
            themeTrends {
              associatedTypeId
              t4Quarters
            }
          }
        }
        recommendations(accountKey: $accountKey, type: $type) {
          id
          session_id
          name
          value
          type
          impact_id
          sourceType_id
          isComplete
          practice_areas
          createdDate
          isPrivate
        }
      }
    }
  }
`;
