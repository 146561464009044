import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(quarterOfYear);
dayjs.extend(minMax);

export const CREATIVE_DATES_KEYS = {
  T30DAYS: 'T30DAYS',
  T90DAYS: 'T90DAYS',
  T4QUARTERS: 'T4QUARTERS',
};

export const CREATIVE_DATES = {
  [CREATIVE_DATES_KEYS.T30DAYS]: {
    QUANTITY: 30,
    UNIT: 'days',
  },
  [CREATIVE_DATES_KEYS.T90DAYS]: {
    QUANTITY: 90,
    UNIT: 'days',
  },
  [CREATIVE_DATES_KEYS.T4QUARTERS]: {
    QUANTITY: 4,
    UNIT: 'quarters',
  },
};

export const CREATIVE_RADIO_DATE_OPTIONS = [
  {
    label: 'Last 30 Days',
    value: CREATIVE_DATES_KEYS.T30DAYS,
  },
  {
    label: 'Last 90 Days',
    value: CREATIVE_DATES_KEYS.T90DAYS,
  },
  {
    label: 'Last 4 Quarters',
    value: CREATIVE_DATES_KEYS.T4QUARTERS,
  },
];

export const MIN_TIMEFRAME = {
  QUANTITY: 14,
  UNIT: 'days',
};

export const MAX_TIMEFRAME = {
  QUANTITY: Math.max(
    dayjs().diff(dayjs().subtract(4, 'quarter').startOf('quarter'), 'days'),
    365
  ),
  UNIT: 'days',
};

export const DATE_PICKER_OPTIONS = [
  {
    label: '30 days prior',
    end: null,
    quantity: 30,
    unit: 'days',
  },
  {
    label: '90 days prior',
    end: null,
    quantity: 90,
    unit: 'days',
  },
  {
    label: '4 quarters prior',
    end: null,
    quantity: Math.max(
      dayjs().diff(dayjs().subtract(4, 'quarter').startOf('quarter'), 'days'),
      MIN_TIMEFRAME.QUANTITY
    ),
    unit: 'days',
  },
  {
    label: 'Previous month',
    end: dayjs().subtract(1, 'month').endOf('month').toDate(),
    quantity: dayjs()
      .subtract(1, 'month')
      .endOf('month')
      .diff(dayjs().subtract(1, 'month').startOf('month'), 'days'),
    unit: 'days',
  },
  {
    label: 'This month',
    end: dayjs().endOf('day').toDate(),
    quantity: Math.max(
      dayjs().diff(dayjs().startOf('month'), 'days'),
      MIN_TIMEFRAME.QUANTITY
    ),
    unit: 'days',
  },
  {
    label: 'This quarter',
    end: dayjs().endOf('day').toDate(),
    quantity: Math.max(
      dayjs().diff(dayjs().startOf('quarter'), 'days'),
      MIN_TIMEFRAME.QUANTITY
    ),
    unit: 'days',
  },
  {
    label: 'This year',
    end: dayjs().endOf('day').toDate(),
    quantity: Math.max(
      dayjs().diff(dayjs().startOf('year'), 'days'),
      MIN_TIMEFRAME.QUANTITY
    ),
    unit: 'days',
  },
];
