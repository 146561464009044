import * as React from 'react';

function SvgIconLink(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M19 1c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4H5c-2.21 0-4-1.79-4-4V5c0-2.21 1.79-4 4-4h14zm-6.14 10.142a.5.5 0 10-.709.705 2.34 2.34 0 01-.003 3.302L9.98 17.321a2.33 2.33 0 01-3.284.014 2.311 2.311 0 01-.023-3.27l2.183-2.214a.5.5 0 10-.712-.702L5.96 13.364a3.31 3.31 0 00.034 4.683 3.328 3.328 0 004.694-.02l2.168-2.172a3.339 3.339 0 00.004-4.713zm5.118-5.157a3.506 3.506 0 00-4.91.059L10.98 8.143a3.342 3.342 0 00-.001 4.715.5.5 0 10.709-.705 2.342 2.342 0 010-3.305l2.089-2.099a2.506 2.506 0 013.601.052c.86.923.807 2.367-.116 3.226l-2.264 2.107a.5.5 0 00.681.732l2.265-2.107a3.283 3.283 0 00.034-4.774z"
          fill="#20434A"
        />
      </g>
    </svg>
  );
}

export default SvgIconLink;
