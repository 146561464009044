import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';

// https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
export function abbreviateNumber(num, fixed = 1) {
  if (isNil(num) || isNaN(num)) {
    return 'n/a';
  } // terminate early

  if (num === 0) {
    return '0';
  } // terminate early

  /* eslint-disable */
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  let b = num.toPrecision(2).split('e'), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(0 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

  /* eslint-enable */
  return e;
}

export function formatCurrency(num) {
  if (!isNumber(num)) {
    return 'n/a';
  }

  const decVal = Math.round(num * 100) / 100;
  const localFormat = decVal.toLocaleString();

  return `$${localFormat}`;
}
