import { gql } from '@apollo/client';

export const GET_COMPETITORS_BLUESCORE = gql`
  query getCompetitorsBluescore($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        competitors {
          logoUrl
          name
          id
        }
        CompetitorBlueScore: competitorsLastPeriodScores(associatedTypeId: 5) {
          brand_id
          brandKey
          t4Quarters
        }
      }
    }
  }
`;

export const GET_CALCULATED_SCORES = gql`
  query getCalculatedData($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      brand {
        id
        logoUrl
        domain
        name
      }
      session(sessionKey: $sessionKey) {
        factors: brandLastPeriodScores(associatedTypeId: 1) {
          t4Quarters
        }
        bluescore: brandLastPeriodScores(associatedTypeId: 5) {
          t4Quarters
        }
      }
    }
  }
`;

export const SCORECARD_VALUES = gql`
  query getScorecardValues($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      brand {
        id
        logoUrl
        domain
        name
      }
      session(sessionKey: $sessionKey) {
        factors {
          name
          value
          delta
        }
        blueScore {
          value
        }
        competitors {
          name
          id
          blueScore {
            value
          }
          factors {
            name
            value
            delta
          }
        }
      }
    }
  }
`;

export const FACTOR_VALUES = gql`
  query getFactorData($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      brand {
        brandKey
        name
        id
        domain
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        id
        brandId
        factorsCalc: brandLastPeriodScores(associatedTypeId: 1) {
          t4Quarters
        }
        factors {
          name
          value
          delta
        }
        competitorFactorAverage {
          name
          average
        }
      }
    }
  }
`;

export const SUBFACTOR_VALUES = gql`
  query getFactorData($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      brand {
        brandKey
        name
        id
        domain
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        id
        brandId
        subfactorsCalc: brandLastPeriodScores(associatedTypeId: 2) {
          t4Quarters
        }
        subfactors {
          name
          value
          delta
        }
        competitorFactorAverage {
          name
          average
        }
      }
    }
  }
`;
