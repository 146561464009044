import throttle from 'lodash/throttle';
import { FC, useRef, useState, RefObject } from 'react';
import CreativeAdvertisingCard from '../CreativeAdvertisingCard/CreativeAdvertisingCard';
import ChartLegend from '../../../Molecules/ChartLegend/ChartLegend';

import { CHART_LEGEND_ICON_VARIANT } from '../../../../constants/props';
import {
  CreativeChannelSpendAsset,
  CreativeAdCardTypes,
} from '../../../../interfaces/creative';

import styles from './CreativeAdvertisingCarousel.module.scss';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../../assets/icons/DynamicCaret';

export interface CreativeAdvertisingCarouselProps {
  data: CreativeChannelSpendAsset[];
  handleCardClick: (id: CreativeAdCardTypes) => void;
  loading: boolean;
  brandName: string;
}

export interface CardContainerProps {
  id: CreativeAdCardTypes;
  data: CreativeChannelSpendAsset;
  handleCardClick: (id: CreativeAdCardTypes) => void;
  loading: boolean;
}

const CardContainer: FC<CardContainerProps> = ({
  data,
  handleCardClick,
  loading,
}) => {
  return (
    <div className={styles.CardContainer}>
      <span className={styles.Header}>
        <p
          role="presentation"
          className={styles.Title}
          onClick={() => handleCardClick(data.id)}
        >
          {data.name}
        </p>
        <span>
          <DynamicCaretIcon
            direction={CaretDirection.right}
            className={styles.Caret}
          />
        </span>
      </span>
      <div onClick={() => handleCardClick(data.id)}>
        <CreativeAdvertisingCard
          data={data}
          loading={loading}
          displayPercent={data.id === CreativeAdCardTypes.shareOfSpend}
        />
      </div>
    </div>
  );
};

const CreativeAdvertisingCarousel: FC<CreativeAdvertisingCarouselProps> = ({
  data,
  handleCardClick,
  loading,
  brandName,
}) => {
  const [scrollEnd, setscrollEnd] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);

  const scrollElement = useRef<null | HTMLUListElement>(null);
  const emptyElStart = useRef<HTMLDivElement>(null);
  const emptyElEnd = useRef<HTMLDivElement>(null);

  const carouselContainerEl = document.getElementById('creative-channels-page');

  const shouldHideButton =
    carouselContainerEl &&
    scrollElement?.current &&
    carouselContainerEl?.offsetWidth >= scrollElement?.current?.scrollWidth;

  const scroll = (ref: RefObject<HTMLDivElement>) => {
    setButtonClicked(true);

    // scroll right
    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }

    // scroll left
    if (ref?.current && scrollEnd) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      });

      setButtonClicked(false);
    }
  };

  const scrollCheck = () => {
    if (
      scrollElement.current &&
      Math.floor(
        scrollElement.current.scrollWidth - scrollElement.current.scrollLeft
      ) <= scrollElement.current.offsetWidth
    ) {
      setscrollEnd(true);
    } else {
      setscrollEnd(false);
    }
  };

  return (
    <div
      className={styles.CreativeContainerMain}
      data-cy="creative-advertising-carousel"
    >
      <div className={styles.CreativeAdvertisingCarousel}>
        {!scrollEnd && !shouldHideButton && (
          <button
            data-cy="creative-advertising-carousel-button-right"
            type="button"
            onClick={() => scroll(emptyElEnd)}
            className={
              !buttonClicked ? styles.ButtonRight : styles.ButtonRightClicked
            }
          >
            <DynamicCaretIcon direction={CaretDirection.right} />
          </button>
        )}
        <ul ref={scrollElement} onScroll={throttle(scrollCheck, 50)}>
          <div ref={emptyElStart} />
          {data?.map((item: CreativeChannelSpendAsset) => {
            return (
              <CardContainer
                loading={loading}
                id={item.id}
                data={item}
                key={item.name}
                handleCardClick={handleCardClick}
              />
            );
          })}
          <div ref={emptyElEnd} />
          {scrollEnd && (
            <button
              data-cy="creative-advertising-carousel-button-left"
              type="button"
              onClick={() => scroll(emptyElStart)}
              className={
                buttonClicked ? styles.ButtonLeft : styles.ButtonLeftHidden
              }
            >
              <DynamicCaretIcon direction={CaretDirection.left} />
            </button>
          )}
        </ul>
      </div>
      <div className={styles.Legend}>
        <ChartLegend
          items={[
            {
              variant: CHART_LEGEND_ICON_VARIANT.SQUARE,
              label: brandName,
            },
            {
              variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
              label: 'Industry Average',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default CreativeAdvertisingCarousel;
