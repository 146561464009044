import {
  useState,
  AnchorHTMLAttributes,
  FunctionComponent,
  useCallback,
} from 'react';
import { BrandLogo } from '@blueoceanai/react-component-library';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import classNames from 'classnames';

import Checkbox from '../../Atoms/Checkbox/Checkbox';

import styles from './BrandDatasetFilterItem.module.scss';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../assets/icons/DynamicCaret';

export interface BrandDatasetFilterItemProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  logoUrl?: string;
  name: string;
  brandId?: string;
  hideable?: boolean;
  checked?: boolean;
  onFilterChange?: (hidden: boolean, brandKey?: string) => void;
}

/**
 * A tile to identify rows the Market Index Blue Score table.
 */
const BrandDatasetFilterItem: FunctionComponent<
  BrandDatasetFilterItemProps
> = ({
  name,
  brandId,
  hideable = true,
  checked = false,
  onFilterChange,
  logoUrl,
  className,
  ...props
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const BrandDatasetFilterItemClasses = classNames(
    className,
    styles.BrandDatasetFilterItem,
    checked ? styles.hidden : null
  );

  const toggleTooltip = useCallback(() => {
    setTooltipOpen(!tooltipOpen);
  }, [tooltipOpen]);

  const toggleHidden = useCallback(() => {
    onFilterChange?.(!checked, brandId);
    toggleTooltip();
  }, [toggleTooltip, onFilterChange, brandId, checked]);

  const handleTooltipClose = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  function renderTooltip() {
    return (
      <Box
        mt={5}
        mr={4}
        mb={5}
        ml={2}
        className={styles.TooltipContentsContainer}
      >
        <div className={styles.TooltipContents} onClick={toggleHidden}>
          <Checkbox checked={checked} />
          <div>Hide this brand</div>
        </div>
      </Box>
    );
  }

  return (
    <Paper className={BrandDatasetFilterItemClasses} elevation={0} {...props}>
      <div className={styles.BrandInfo}>
        <BrandLogo
          name={name}
          logoVisible={!checked}
          src={logoUrl}
          bold={!checked}
        />
      </div>

      {hideable ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div className={styles.IconDropdown}>
            <button
              type="button"
              onClick={toggleTooltip}
              className={styles.CaretDown}
            >
              <DynamicCaretIcon direction={CaretDirection.down} />
            </button>
            <Box
              className={classNames(
                styles.Dropdown,
                tooltipOpen ? null : styles.Closed
              )}
            >
              {renderTooltip()}
            </Box>
          </div>
        </ClickAwayListener>
      ) : null}
    </Paper>
  );
};

export default BrandDatasetFilterItem;
