import React from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { ORIENTATIONS } from '../../../constants/props';

import styles from './NavTabsVertical.module.scss';

export default function NavTabsVertical({
  value,
  onChange,
  items = [],
  ...props
}) {
  return (
    <Tabs
      orientation={ORIENTATIONS.VERTICAL}
      value={value}
      onChange={onChange}
      classes={{
        root: styles.NavTabsVertical,
        indicator: styles.Indicator,
      }}
      {...props}
    >
      {items.map((item) => (
        <div data-cy={item.datacy ? item.datacy : ''}>
          <Tab
            classes={{
              root: styles.NavTab,
              wrapper: styles.Label,
            }}
            disableRipple
            key={item.value}
            label={item.label}
            value={item.value}
            fullWidth
            onClick={() => onChange(item.value)}
          />
        </div>
      ))}
    </Tabs>
  );
}

NavTabsVertical.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
};
