import { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import DatePickerCalendar from '../DatePickerCalendar/DatePickerCalendar';
import TextInput from '../TextInput/TextInput';

import styles from './DatePickerDualCalendar.module.scss';
import { CalendarIcon } from '../../../assets/icons';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(minMax);

const CALENDAR_KEYS = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

export default function DatePickerDualCalendar({
  className,
  onChange,
  value,
  minDate,
  maxDate,
  calendarLeftProps,
  calendarRightProps,
  highlightedDates,
  ...props
}) {
  const [calendarLeft, setCalendarLeft] = useState({
    activeStartDate: dayjs().startOf('month').subtract(1, 'month').toDate(),
    value: null,
  });
  const [calendarRight, setCalendarRight] = useState({
    activeStartDate: dayjs().startOf('month').toDate(),
    value: null,
  });
  const [calendarLeftInputDate, setCalendarLeftInputDate] = useState();
  const [calendarRightInputDate, setCalendarRightInputDate] = useState();

  useDeepCompareEffect(() => {
    const startDate = dayjs(value.end)
      .subtract(value.quantity, value.unit)
      .toDate();

    const newValue = [startDate, value.end];

    setCalendarRight({
      ...calendarRight,
      ...calendarRightProps,
      activeStartDate: value.end,
      minDate,
      maxDate,
      value: newValue,
    });

    setCalendarLeft({
      ...calendarLeft,
      ...calendarLeftProps,
      minDate,
      maxDate,
      activeStartDate: startDate,
      value: newValue,
    });

    setCalendarLeftInputDate(
      dayjs(value.end).subtract(value.quantity, value.unit).format('YYYY-MM-DD')
    );

    setCalendarRightInputDate(dayjs(value.end).format('YYYY-MM-DD'));
  }, [value]);

  function handleActiveStartDateChange({ activeStartDate }, calendarKey) {
    if (calendarKey === CALENDAR_KEYS.LEFT) {
      setCalendarLeft({
        ...calendarLeft,
        activeStartDate: new Date(activeStartDate),
      });
    }

    if (calendarKey === CALENDAR_KEYS.RIGHT) {
      setCalendarRight({
        ...calendarRight,
        activeStartDate: new Date(activeStartDate),
      });
    }
  }

  function handleDayChange(date, calendarKey) {
    if (!date) {
      return;
    }

    if (calendarKey === CALENDAR_KEYS.LEFT) {
      const quantity = dayjs(value.end).diff(dayjs(date), value.unit);

      onChange({
        quantity,
        end: value.end,
        unit: value.unit,
      });
      return;
    }

    if (calendarKey === CALENDAR_KEYS.RIGHT) {
      onChange({
        quantity: value.quantity,
        unit: value.unit,
        end: dayjs(date).endOf('day').toDate(),
      });
    }
  }

  return (
    <div
      className={classNames(styles.DatePickerDualCalendar, className)}
      {...props}
    >
      <div className={styles.Section}>
        <TextInput
          className={styles.DateInput}
          label="START DATE"
          type="date"
          value={calendarLeftInputDate}
          inputId="date-input-left"
          endAdornment={<CalendarIcon />}
          onChange={(e) => {
            handleDayChange(e.target.value, CALENDAR_KEYS.LEFT);
          }}
        />

        <DatePickerCalendar
          className={styles.Calendar}
          onActiveStartDateChange={(e) =>
            handleActiveStartDateChange(e, CALENDAR_KEYS.LEFT)
          }
          onClickDay={(date) => {
            handleDayChange(date, CALENDAR_KEYS.LEFT);
          }}
          showNeighboringMonth={false}
          highlightedDates={highlightedDates}
          {...calendarLeft}
        />
      </div>

      <div className={styles.Section}>
        <TextInput
          className={styles.DateInput}
          label="END DATE"
          type="date"
          value={calendarRightInputDate}
          inputId="date-input-right"
          endAdornment={<CalendarIcon />}
          onChange={(e) => {
            handleDayChange(e.target.value, CALENDAR_KEYS.RIGHT);
          }}
        />

        <DatePickerCalendar
          className={styles.Calendar}
          onActiveStartDateChange={(e) =>
            handleActiveStartDateChange(e, CALENDAR_KEYS.RIGHT)
          }
          onClickDay={(date) => {
            handleDayChange(date, CALENDAR_KEYS.RIGHT);
          }}
          showNeighboringMonth={false}
          highlightedDates={highlightedDates}
          {...calendarRight}
        />
      </div>
    </div>
  );
}

DatePickerDualCalendar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  calendarLeftProps: PropTypes.shape({}),
  calendarRightProps: PropTypes.shape({}),
  value: PropTypes.shape({
    quantity: PropTypes.number,
    unit: PropTypes.string,
    end: PropTypes.instanceOf(Date),
  }),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  highlightedDates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date),
      variant: PropTypes.string,
    })
  ),
};
