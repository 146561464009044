import { ChartOptions } from 'chart.js';
import { AnchorHTMLAttributes } from 'react';
import { Line } from 'react-chartjs-2';
import { xHoverAnnotationLine } from '../../../../../../chartjs/plugins';
import colors from '../../../../../../constants/colors';
import { abbreviateNumber } from '../../../../../../utils/number';
import { SingleWidgetGraphData } from '../../../types';
import { ChartTooltipContextLine } from '../../../../../../interfaces';
import singleMetricTooltip from '../../../../../../chartjs/tooltips/singleMetricTooltip/singleMetricTooltip';

export interface SingleMetricGraphProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  data: SingleWidgetGraphData;
  isPercentage?: boolean;
}

const SingleMetricGraph = ({
  data,
  isPercentage = false,
}: SingleMetricGraphProps) => {
  const xLabelFormatter = (
    tick: string,
    { first, last }: { first: string; last: string }
  ): string => {
    if (tick === first || tick === last) {
      return tick;
    }
    return '';
  };

  // TODO: handle RawScoreFormat.Percentage metrics consistently across widgets
  const yLabelFormatter = (value: number | string): string =>
    isPercentage ? `${value}%` : abbreviateNumber(value);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          borderColor:
            data.yDomain.showRawData && !data.yDomain.showIndexData
              ? colors.sand
              : colors.colorPrimary70,
          borderWidth: 4,
          borderDash: [5],
        },
        ticks: {
          display: true,
          maxTicksLimit: 4,
          callback: (value: string | number) => yLabelFormatter(value),
        },
      },
      y1: {
        type: 'linear',
        display: data.yDomain.showRawData && data.yDomain.showIndexData,
        position: 'right',
        grid: {
          borderColor: colors.sand,
          borderWidth: 4,
          display: false,
        },
        ticks: {
          display: true,
          maxTicksLimit: 4,
          callback: (value: string | number) => yLabelFormatter(value),
        },
      },
      x: {
        grid: {
          display: false,
          borderColor: colors.colorGray40,
        },
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          source: 'labels',
          autoSkip: false,
          minRotation: 0,
          maxRotation: 0,
          callback: (tick: string) => xLabelFormatter(tick, data.yDomain),
        },
      },
    },
    plugins: {
      title: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      legend: { display: false },
      tooltip: {
        enabled: false,
        // TODO: handle RawScoreFormat.Percentage metrics consistently across widgets
        external: (context: ChartTooltipContextLine) =>
          singleMetricTooltip(context, data.yDomain.showRawData, isPercentage),
      },
    },
  };

  const chartData = {
    labels: data.yDomain.labels,
    datasets: [
      {
        label: 'Index',
        data: data.datasets[0],
        hidden: !data.yDomain.showIndexData,
        yAxisID: 'y',
        fill: false,
        showLine: true,
        borderWidth: 2,
        borderColor: colors.colorPrimary70,
        tension: 0.4,
        pointRadius: 3,
        pointHitRadius: 5,
        pointBackgroundColor: colors.opacity80,
      },
      {
        label: 'Raw',
        data: data.datasets[1],
        hidden: !data.yDomain.showRawData,
        yAxisID:
          data.yDomain.showRawData && !data.yDomain.showIndexData ? 'y' : 'y1',
        fill: false,
        showLine: true,
        borderWidth: 2,
        borderColor: colors.sand,
        tension: 0.4,
        pointRadius: 3,
        pointHitRadius: 5,
        pointBackgroundColor: colors.opacity80,
      },
    ],
  };
  return (
    <Line
      options={options as ChartOptions<'line'>}
      data={chartData}
      plugins={[xHoverAnnotationLine]}
    />
  );
};

export default SingleMetricGraph;
