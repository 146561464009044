import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styles from './MappingWidget.module.scss';
import { TimeRange, WidgetType } from '../../../../../interfaces/dashboard-api';
import MappingWidgetGrid from './MappingWidgetGrid/MappingWidgetGrid';
import { ScoreMapType, WidgetMetaResponse } from '../../types';
import ScoreLegend from '../../../../Molecules/ScoreLegend/ScoreLegend';
import { formatVisualMapData } from '../widgetFormatResponse';
import DropdownSelect from '../../../Creative/DropdownSelect/DropdownSelect';
import { DateRangeOptions, timeRangeById } from '../../WidgetSettingsShared';
import DateRangeSelectorDropdown from '../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';
import {
  GET_FIRST_AND_LAST_SESSION,
  GET_VISUAL_MAPPING_DATA,
} from '../../../../../api/queries/Pages/CustomizableDashboards';
import { handleConfigInputArrayFromDashboardAPI } from '../../WidgetSettingsFlows/helper';
import { FeatureFlag } from '../../../../../utils/featureFlags';
import LoadingWidget from '../LoadingWidget/LoadingWidget';

export interface MappingWidgetProps {
  config: WidgetMetaResponse;
  globalDateFilter: {
    endDate: Date;
    timeRange: TimeRange | null;
  };
}

const MapTypeOptions = [
  { value: ScoreMapType.HeatMap, label: 'Heat Map' },
  { value: ScoreMapType.WaterMap, label: 'Water Map' },
];

const MappingWidget = ({ config, globalDateFilter }: MappingWidgetProps) => {
  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  const [activeTimeRange, setActiveTimeRange] = useState<TimeRange>(
    config.data_srcs?.metric?.time_range ?? TimeRange.DEFAULT
  );
  const [activeEndDate, setActiveEndDate] = useState<Date>(
    config?.data_srcs?.metric?.input?.endDate
      ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
      : new Date()
  );

  useEffect(() => {
    if (globalDateFilter?.timeRange && globalDateFilter?.endDate) {
      setActiveTimeRange(globalDateFilter.timeRange);
      setActiveEndDate(globalDateFilter.endDate);
    } else {
      setActiveTimeRange(
        config?.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
      );
      setActiveEndDate(
        config?.data_srcs?.metric?.input?.endDate
          ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
          : new Date()
      );
    }
  }, [globalDateFilter, config]);

  const [activeMapType, setActiveMapType] = useState<ScoreMapType>(
    config.options.view_type_id
  );

  const [getVisualMapData, { loading, data }] = useLazyQuery(
    GET_VISUAL_MAPPING_DATA,
    { fetchPolicy: 'no-cache' }
  );

  const [getFirstLastSession, { data: sessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );

  const gridData = useMemo(() => {
    if (loading || !data) return null;

    return formatVisualMapData(data);
  }, [data, loading]);

  const selectedMapType = useMemo(() => {
    return MapTypeOptions.find((option) => option.value === activeMapType);
  }, [activeMapType]);

  const selectedTimeRangeOption = useMemo(() => {
    return DateRangeOptions.find((option) => option.value === activeTimeRange);
  }, [activeTimeRange]);

  useEffect(() => {
    const competitiveSetKey =
      config?.data_srcs?.metric?.input?.competitiveSetKey;

    if (!competitiveSetKey) return;

    getFirstLastSession({ variables: { id: competitiveSetKey } });
  }, [config, getFirstLastSession]);

  useEffect(() => {
    const compSetKey = config?.data_srcs?.metric?.input?.competitiveSetKey;
    const group = config?.options?.metric_group_id;
    const selectedBrands = config?.options?.selected_brand_keys;
    // TODO: get session key based on custom date range
    const latestSessionKey =
      sessionResp?.competitiveSet?.firstAndLastSession?.[1]?.sessionKey;

    if (!compSetKey || !group || !latestSessionKey) return;

    const selectedBrandKeys =
      handleConfigInputArrayFromDashboardAPI(selectedBrands);

    getVisualMapData({
      variables: {
        id: compSetKey,
        sessionKey: latestSessionKey,
        input: {
          range: timeRangeById[activeTimeRange].value,
          group,
          compSetKey,
          selectedBrandKeys: selectedBrandKeys.join(','),
          endDate: activeEndDate,
        },
      },
    });
  }, [activeTimeRange, activeEndDate, sessionResp, config, getVisualMapData]);

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    setActiveTimeRange(e.timeRange ?? TimeRange['90_DAYS']);
    setActiveEndDate(e.endDate);
  };

  return (
    <div className={styles.Container}>
      <div className={styles.OptionsRow}>
        <DropdownSelect
          options={MapTypeOptions}
          value={selectedMapType}
          onChange={(option) => setActiveMapType(option?.value as ScoreMapType)}
        />

        {showCustomDateRangeSelector ? (
          <DateRangeSelectorDropdown
            timeRange={activeTimeRange}
            endDate={activeEndDate}
            onApply={handleApply}
          />
        ) : (
          <DropdownSelect
            options={DateRangeOptions}
            value={selectedTimeRangeOption}
            onChange={(option) =>
              setActiveTimeRange(option?.value as TimeRange)
            }
          />
        )}
      </div>
      <Box className={styles.ContentContainer}>
        {loading && (
          <div className={styles.Loading}>
            <LoadingWidget widgetType={WidgetType.VisualMapsV1} />
          </div>
        )}
        {gridData && (
          <MappingWidgetGrid
            activeTimeRange={activeTimeRange}
            scoreMapType={activeMapType}
            anchorBrand={gridData.anchorBrand}
            brands={gridData.brands}
            metricLabels={gridData.metricLabels}
          />
        )}
        <hr />
        <div
          className={
            activeMapType === ScoreMapType.HeatMap
              ? styles.HeatmapLegend
              : styles.WatermapLegend
          }
        >
          <ScoreLegend type={activeMapType} />
        </div>
      </Box>
    </div>
  );
};

export default MappingWidget;
