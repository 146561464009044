import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';
import BlueOceanLogo from '../../../assets/icons/NavIcons/BlueOceanLogo';
import useRouter from '../../../hooks/useRouter';
import { FeatureFlag } from '../../../utils/featureFlags';
import AuthSelector from '../../Molecules/AuthSelector/AuthSelector';
import BrandSelector from '../../Molecules/BrandSelector/BrandSelector';
import TimeRangeSelector from '../../Molecules/TimeRangeSelector/TimeRangeSelector';
import TopNavRightSet from '../../Molecules/TopNavRightSet/TopNavRightSet';
import styles from './TopNav.module.scss';

const TopNav = () => {
  const { updateRoute } = useRouter();
  const flags = useFlags();

  const handleLogoClick = useCallback(() => {
    if (!flags[FeatureFlag.BNAccess] && flags[FeatureFlag.CreativeAccess]) {
      return updateRoute({ pathname: '/creative/channels/overview' });
    }

    return updateRoute({ pathname: '/dashboards' });
  }, [flags, updateRoute]);

  const isDashboardsPage = window.location.pathname.includes('dashboards');

  return (
    <nav className={styles.TopNav}>
      <div className={styles.Left}>
        <div className={styles.HeaderMainLogo} onClick={handleLogoClick}>
          <BlueOceanLogo className={styles.MainLogo} />
          <h5>Brand Navigator</h5>
        </div>

        <div className={styles.VerticalDivider} />
        {!isDashboardsPage && (
          <>
            <BrandSelector />
            <TimeRangeSelector />
          </>
        )}
      </div>

      <div className={styles.Right}>
        <TopNavRightSet />

        <div className={styles.VerticalDivider} />

        <AuthSelector className={styles.AuthSelector} />
      </div>
    </nav>
  );
};

export default TopNav;
