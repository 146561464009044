import { MetricFormatType, MetricUnit } from '../interfaces/metric';
import { GetMetricResponse } from '../mocks/data/CustomizableDashboards/metrics';
import { abbreviateNumber } from './number';

// adding comment to deploy after enabling workflow post-freeze
export const metricValueFormattersByType = {
  [MetricFormatType.PercentageSuffix]: (val: string | number): string => {
    return `${Math.round(Number(val))}%`;
  },
  [MetricFormatType.Percentage]: (val: string | number): string => {
    const percentageVal = Number(val) * 100;

    return `${Math.round(percentageVal)}%`;
  },
  [MetricFormatType.Abbreviated]: abbreviateNumber,
  [MetricFormatType.Currency]: (val: string | number): string => {
    return `$${val?.toLocaleString()}`;
  },
  [MetricFormatType.None]: (val: string | number): string | number => val,
};

export const metricUnitFormattersByType = {
  [MetricUnit.USD]: (val: string): string => {
    return `$${val}`;
  },
  [MetricUnit.None]: (val: string): string => val,
};

export function showRawMetricValue(metric: GetMetricResponse): boolean {
  return metric.is_raw_score_available && metric.is_raw_score_meaningful;
}
