import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useCallback,
  ChangeEvent,
} from 'react';
import classNames from 'classnames';
import Switch from '../../Atoms/Switch/Switch';
import { ScoreType } from '../../../interfaces/metric';

import styles from './ScoreTypeSwitch.module.scss';

export interface ScoreTypeSwitchProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  /**
   * The current score type.
   */
  value: ScoreType;

  /**
   * Callback fired when the score type changes.
   */
  onTypeChange: (type: ScoreType) => void;

  /**
   * If `true`, the switch will be disabled.
   */
  disabled?: boolean;
}

const ScoreTypeSwitch: FunctionComponent<ScoreTypeSwitchProps> = ({
  className,
  value = ScoreType.Indexed,
  disabled = false,
  onTypeChange,
  ...props
}) => {
  const checked = value === ScoreType.Raw;

  const handleTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onTypeChange(event.target.checked ? ScoreType.Raw : ScoreType.Indexed);
    },
    [onTypeChange]
  );

  return (
    <div
      className={classNames(
        styles.ScoreTypeSwitch,
        value === ScoreType.Raw ? 'score-switch-active' : 'score-switch',
        className
      )}
      {...props}
    >
      <div className={styles.SwitchContainer}>
        <Switch
          disabled={disabled}
          checked={checked}
          onChange={handleTypeChange}
        />
      </div>

      <p>Show count</p>
    </div>
  );
};

export default ScoreTypeSwitch;
