import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalDrawer from '../../Molecules/ModalDrawer/ModalDrawer';
import SettingsUserForm from '../SettingsUserForm/SettingsUserForm';

import styles from './SettingsEditUserModal.module.scss';

export default function SettingsEditUserModal({
  className,
  open,
  onClose,
  editData,
  onSuccess,
  onError,
  brandsWithBrandAdmin,
  isAccountToppedOut,
  users,
  ...props
}) {
  return (
    <ModalDrawer
      title="Edit user"
      open={open}
      onClose={onClose}
      className={classNames(styles.SettingsEditUserModal, className)}
      {...props}
    >
      <SettingsUserForm
        onSuccess={onSuccess}
        onError={onError}
        onClose={onClose}
        editData={editData}
        className={styles.AddUser}
        brandsWithBrandAdmin={brandsWithBrandAdmin}
        isAccountToppedOut={isAccountToppedOut}
        users={users}
      />
    </ModalDrawer>
  );
}

SettingsEditUserModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  editData: PropTypes.shape({}),
  onSuccess: PropTypes.func,
  brandsWithBrandAdmin: PropTypes.arrayOf(PropTypes.string),
  onError: PropTypes.func,
  isAccountToppedOut: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.string),
};
