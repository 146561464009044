import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalDrawer from '../../Molecules/ModalDrawer/ModalDrawer';
import SettingsUserForm from '../SettingsUserForm/SettingsUserForm';

import styles from './SettingsAddUserModal.module.scss';

export default function SettingsAddUserModal({
  className,
  open,
  onSuccess,
  onError,
  onClose,
  brandsWithBrandAdmin,
  isAccountToppedOut,
  users,
  ...props
}) {
  return (
    <ModalDrawer
      title="Add user"
      open={open}
      onClose={onClose}
      className={classNames(styles.SettingsAddUserModal, className)}
      {...props}
    >
      <SettingsUserForm
        brandsWithBrandAdmin={brandsWithBrandAdmin}
        onSuccess={onSuccess}
        onError={onError}
        onClose={onClose}
        className={styles.AddUser}
        isAccountToppedOut={isAccountToppedOut}
        users={users}
      />
    </ModalDrawer>
  );
}

SettingsAddUserModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  brandsWithBrandAdmin: PropTypes.arrayOf(PropTypes.string),
  onError: PropTypes.func,
  isAccountToppedOut: PropTypes.bool,
  users: PropTypes.arrayOf(PropTypes.string),
};
