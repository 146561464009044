import { FC } from 'react';
import classNames from 'classnames';
import styles from './DiffPillMini.module.scss';

export interface DiffPillProps {
  percentage: number | string | undefined;
}

const DiffPillMini: FC<DiffPillProps> = ({ percentage }) => {
  return (
    <div
      className={classNames(
        styles.DiffPillMiniContainer,
        percentage && percentage > 0 ? styles.Positive : styles.Negative
      )}
      data-cy="mini-diff-pill"
    >
      <p className={styles.Text}>
        {percentage && percentage < 0 ? null : `+`}
        {`${Math.round(percentage as number)}`}%
      </p>
    </div>
  );
};

export default DiffPillMini;
