import { GenericIconProps } from '../types';

const AlertTriangleIcon = (props: GenericIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill="#F4CF3D"
        d="M11.977 3c.922 0 1.776.464 2.274 1.224l.094.156 7.276 12.557c.5.836.506 1.874.017 2.716a2.717 2.717 0 01-2.193 1.343l-.183.004H4.692a2.716 2.716 0 01-2.341-1.367 2.672 2.672 0 01-.064-2.528l.092-.168L9.61 4.38A2.715 2.715 0 0111.977 3z"
      />
      <path
        fill="#20434A"
        d="M12 15a1 1 0 001-1V9a1 1 0 00-2 0v5a1 1 0 001 1zm0 3a1 1 0 100-2 1 1 0 000 2z"
      />
    </g>
  </svg>
);

export default AlertTriangleIcon;
