import range from 'lodash/range';
import isNil from 'lodash/isNil';
import { IndexedRating } from '../interfaces/score';

export const indexedRatings: IndexedRating[] = [
  IndexedRating.Poor,
  IndexedRating.Weak,
  IndexedRating.Fair,
  IndexedRating.Moderate,
  IndexedRating.Good,
  IndexedRating.Great,
  IndexedRating.Best,
];

type IndexedRangesByRatingMap = {
  [key in IndexedRating]: {
    floor: number | null;
    ceil: number | null;
  };
};

export const indexedRangesByRating: IndexedRangesByRatingMap = {
  [IndexedRating.Poor]: {
    floor: 0,
    ceil: 49,
  },
  [IndexedRating.Weak]: {
    floor: 50,
    ceil: 69,
  },
  [IndexedRating.Fair]: {
    floor: 70,
    ceil: 89,
  },
  [IndexedRating.Moderate]: {
    floor: 90,
    ceil: 109,
  },
  [IndexedRating.Good]: {
    floor: 110,
    ceil: 129,
  },
  [IndexedRating.Great]: {
    floor: 130,
    ceil: 149,
  },
  [IndexedRating.Best]: {
    floor: 150,
    ceil: 200,
  },
  [IndexedRating.Null]: {
    floor: null,
    ceil: null,
  },
};

type IndexedColorsByRatingMap = {
  [key in IndexedRating]: {
    fill: string;
    font: string;
    border?: string;
    gradientFill?: string;
  };
};

export const indexedColorsByRating: IndexedColorsByRatingMap = {
  [IndexedRating.Poor]: {
    fill: '#f6f8ed',
    font: 'black',
    gradientFill: 'rgb(246, 248, 237, 0.6)',
  },
  [IndexedRating.Weak]: {
    fill: '#dbefe2',
    font: 'black',
    gradientFill: 'rgb(219, 239, 226, 0.7)',
  },
  [IndexedRating.Fair]: {
    fill: '#bfe9dd',
    font: 'black',
    gradientFill: 'rgb(191, 233, 221, 0.7)',
  },
  [IndexedRating.Moderate]: {
    fill: '#96cfd1',
    font: 'black',
    gradientFill: 'rgb(150, 207, 209, 0.8)',
  },
  [IndexedRating.Good]: {
    fill: '#60b2ca',
    font: 'white',
    gradientFill: 'rgb(96, 178, 202, 0.8)',
  },
  [IndexedRating.Great]: {
    fill: '#3d7493',
    font: 'white',
    gradientFill: 'rgb(61, 116, 147, 0.8)',
  },
  [IndexedRating.Best]: {
    fill: '#193a5e',
    font: 'white',
    gradientFill: 'rgb(25, 58, 94, 0.8)',
  },
  [IndexedRating.Null]: {
    fill: 'white',
    font: 'black',
    border: '#84d1d2',
  },
};

type IndexedDisplayTextByRatingMap = {
  [key in IndexedRating]: string;
};

export const indexedDisplayTextByRating: IndexedDisplayTextByRatingMap = {
  [IndexedRating.Poor]: 'Poor',
  [IndexedRating.Weak]: 'Weak',
  [IndexedRating.Fair]: 'Fair',
  [IndexedRating.Moderate]: 'Moderate',
  [IndexedRating.Good]: 'Good',
  [IndexedRating.Great]: 'Great',
  [IndexedRating.Best]: 'Best in Class',
  [IndexedRating.Null]: 'N/A',
};

export function animateNumberChange({
  previousValue,
  nextValue,
  animationDuration,
  onChange,
}: {
  previousValue?: number | null;
  nextValue: number;
  animationDuration: number;
  onChange: (value: number) => void;
}) {
  // if previousValue is greater than next value, we decrement nextValue by 1.
  // if not, then increment by 1 instead.
  const rangeNextValue =
    (previousValue ?? 0) > nextValue ? nextValue - 1 : nextValue + 1;

  const values = range(previousValue ?? 0, rangeNextValue);

  const msBetweenUpdateFrames = animationDuration / values.length;

  const valuesToAnimate = values.map((value, index) => {
    return {
      value,
      elapsedAnimationTime: (index + 1) * msBetweenUpdateFrames,
    };
  });

  let start: DOMHighResTimeStamp;

  function step(timestamp: DOMHighResTimeStamp) {
    if (start === undefined) {
      start = timestamp;
    }

    const elapsed = timestamp - start;

    while (
      valuesToAnimate[0] &&
      elapsed > valuesToAnimate[0].elapsedAnimationTime
    ) {
      const nextVal = valuesToAnimate.shift();

      if (!nextVal) {
        break;
      }

      onChange(nextVal.value);
    }

    if (valuesToAnimate.length) {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
}

export function getIndexRatingFromScore(score: number | null) {
  if (isNil(score)) {
    return IndexedRating.Null;
  }

  for (let i = 0; i < indexedRatings.length; i += 1) {
    const currentRating = indexedRatings[i];
    const ratingRange = indexedRangesByRating[currentRating];

    if (ratingRange.ceil === null) {
      return IndexedRating.Poor;
    }

    if (Math.floor(score) <= ratingRange.ceil) {
      return currentRating;
    }
  }

  return IndexedRating.Null;
}

export function animatePercentageChange({
  nextValue,
  animationDuration,
  onChange,
}: {
  nextValue: number;
  animationDuration: number;
  onChange: (value: number) => void;
}): { cancel: () => void } {
  const startValue = 0;
  const endValue = nextValue;
  const increment = (endValue - startValue) / animationDuration;
  let animationRequestId: number | null = null;
  let startTime: number | null = null;

  function animate(timestamp: number) {
    if (startTime === null) {
      startTime = timestamp;
    }

    const elapsed = timestamp - startTime;
    const currentValue = startValue + increment * elapsed;

    if (currentValue >= endValue) {
      onChange(endValue);
    } else {
      onChange(currentValue);
      animationRequestId = requestAnimationFrame(animate);
    }
  }

  animationRequestId = requestAnimationFrame(animate);

  return {
    cancel: () => {
      if (animationRequestId !== null) {
        cancelAnimationFrame(animationRequestId);
        animationRequestId = null;
      }
    },
  };
}
