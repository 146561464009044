import { FunctionComponent } from 'react';
import classNames from 'classnames';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import ChangeDisplayIcon from '../../../../ChangeDisplayIcon/ChangeDisplayIcon';
import SingleMetricLineChart from '../../../../../../Charts/SingleMetricLineChart/SingleMetricLineChart';

import { ChartPoint } from '../../../../../../../interfaces';
import { ScoreType } from '../../../../../../../interfaces/metric';
import { abbreviateNumber } from '../../../../../../../utils/number';

import styles from './Full.module.scss';

export interface FullProps extends TableCellProps {
  score: number;
  diff: number;
  mainLineTimeseriesData: ChartPoint[];
  dashedLineTimeseriesData: ChartPoint[];
  scoreType: ScoreType;
  hasRaw?: boolean;
  hasIndexed?: boolean;
  isPercentage?: boolean;
}

const Full: FunctionComponent<FullProps> = ({
  className,
  score,
  diff,
  mainLineTimeseriesData,
  dashedLineTimeseriesData,
  scoreType,
  hasRaw = true,
  hasIndexed = true,
  isPercentage = false,
}) => {
  let formattedScore = abbreviateNumber(score);
  let suffix = '';

  // TODO: handle RawScoreFormat.Percentage metrics consistently across widgets
  // set suffix and calculate formattedScore for percentage and indexed scores
  if (scoreType === ScoreType.Raw && isPercentage) {
    formattedScore = `${Math.round(Number(score) * 100)}`;
    suffix = '%';
  } else if (scoreType === ScoreType.Indexed) {
    formattedScore = `${Math.round(score)}`;
    suffix = 'pts';
  }

  if (scoreType === ScoreType.Raw && !hasRaw) {
    return (
      <TableCell className={classNames(styles.Full, className)} align="center">
        <p className={styles.IndexedOnlyMsg}>Indexed Only</p>
      </TableCell>
    );
  }

  // TODO: adjust hasIndexed when social channels contain indexed data
  // TODO: update "Raw Metric Only" message based on feedback
  if (!hasIndexed) {
    return (
      <TableCell className={classNames(styles.Full, className)} align="center">
        <p className={styles.IndexedOnlyMsg}>Raw Metric Only</p>
      </TableCell>
    );
  }

  return (
    <TableCell className={classNames(styles.Full, className)} align="right">
      <div className={styles.Container}>
        <p className={styles.MetricNumber}>
          {formattedScore}
          {
            suffix.length === 1
              ? suffix
              : '' /* TODO: handle RawScoreFormat.Percentage metrics consistently across widgets */
          }
        </p>

        <div className={styles.DisplayIconContainer}>
          <ChangeDisplayIcon
            hideSymbol
            changeSuffixColor
            value={diff}
            suffix={suffix}
            classes={{
              root: styles.Diff,
              icon: styles.DiffIcon,
              value: styles.DiffValue,
              suffix: styles.DiffSuffix,
            }}
            scoreType={scoreType}
          />
        </div>

        <div className={styles.Chart}>
          <SingleMetricLineChart
            mainLineTimeseriesData={mainLineTimeseriesData}
            dashedLineTimeseriesData={dashedLineTimeseriesData}
            scoreType={scoreType}
          />
        </div>
      </div>
    </TableCell>
  );
};

export default Full;
