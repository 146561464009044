import * as React from 'react';

function SvgMetrics(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g
        style={{
          mixBlendMode: 'darken',
        }}
        fill="none"
        fillRule="evenodd"
      >
        <path d="M0 0h24v24H0z" />
        <path
          fill="#20434A"
          d="M20.5 8h.011a.491.491 0 01.456.317c.001.007.004.013.006.02.008.021.013.043.018.065v.008l.003.013.006.057v6.77a.5.5 0 01-.992.09L20 15.25V9.733l-4.93 5.06c-.365.375-.86.586-1.378.586-.46 0-.902-.167-1.251-.468l-.127-.118-3.048-3.129a.958.958 0 00-1.282-.086l-.096.087-5.056 5.189a.48.48 0 01-.69 0 .512.512 0 01-.056-.638l.057-.07 5.056-5.188c.365-.376.86-.587 1.378-.587.46 0 .902.167 1.252.468l.126.118 3.048 3.129a.958.958 0 001.282.086l.097-.087L19.336 9H13.75a.5.5 0 01-.492-.41l-.008-.09a.5.5 0 01.41-.492L13.75 8h6.75z"
        />
      </g>
    </svg>
  );
}

export default SvgMetrics;
