import * as React from 'react';

function SvgPowerpoint(props) {
  return (
    <svg viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.619.4C7.872.417 3.217 5.072 3.2 10.819L16.4 13.6 13.619.4z"
          fill="#ED6C47"
          fillRule="nonzero"
        />
        <path
          d="M13.4.4C19.247.417 23.983 5.152 24 10.998L18.7 14.6l-5.3-3.602V.4z"
          fill="#FF8F6B"
          fillRule="nonzero"
        />
        <path
          d="M13.6 21.6c5.737-.017 10.383-4.753 10.4-10.6H3.2c.017 5.847 4.663 10.583 10.4 10.6z"
          fill="#D35230"
          fillRule="nonzero"
        />
        <path
          d="M12.39 4.2H5.303a10.302 10.302 0 00.704 14.2h6.383a1.006 1.006 0 001.01-.996V5.196a1.006 1.006 0 00-1.01-.996z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M11.794 5h-6.88C1.688 9.54 2.633 15.796 7.06 19.2h4.735a1.004 1.004 0 001.005-.996V5.996A1.004 1.004 0 0011.794 5z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.2}
        />
        <path
          d="M11.794 5h-6.88a10.448 10.448 0 00.578 12.8h6.303a1.007 1.007 0 001.005-1.004V6.004A1.007 1.007 0 0011.794 5z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.2}
        />
        <path
          d="M11.022 5h-5.96c-2.678 3.899-2.447 9.164.562 12.8h5.398A.994.994 0 0012 16.796V6.004A.994.994 0 0011.022 5z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.2}
        />
        <path
          d="M1 5h10a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1V6a1 1 0 011-1z"
          fill="#C43E1C"
          fillRule="nonzero"
        />
        <path
          d="M6.205 7.207a3.172 3.172 0 012.072.595 2.11 2.11 0 01.719 1.723c.008.419-.116.829-.356 1.174a2.367 2.367 0 01-1.01.877 3.486 3.486 0 01-1.52.314H4.675v2.71H3.2V7.207h3.005zm-1.531 3.462h1.268a1.67 1.67 0 001.122-.328.814.814 0 00.379-.759c0-.817-.485-1.226-1.453-1.226H4.674v2.313z"
          fill="#F9F7F7"
          fillRule="nonzero"
        />
        <path d="M0-1h24v24H0z" />
      </g>
    </svg>
  );
}

export default SvgPowerpoint;
