import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import last from 'lodash/last';
import { isBetween } from '../../../utils/timeframe';

export function prepareCustomerBrandInfo(data, compareMeta, timeframe) {
  if (
    !data?.competitiveSet?.brand ||
    !data?.competitiveSet?.session?.[compareMeta.typeQuery] ||
    !data?.competitiveSet?.session?.[compareMeta.type] ||
    !data?.competitiveSet?.session?.[compareMeta.type]?.[0] ||
    !timeframe
  ) {
    return;
  }

  const { name, logoUrl } = data.competitiveSet.brand;
  const { session } = data.competitiveSet;
  const scoresByTimeframe = session[compareMeta.type][0];

  const scoreOverTime = JSON.parse(scoresByTimeframe.t4Quarters)
    .filter(
      (score) =>
        score.name === compareMeta.typeName && isBetween(score.date, timeframe)
    )
    .map((score) => ({
      date: score.date,
      name: score.name.toLowerCase(),
      value: Math.round(score.value),
    }));

  const oldestScore = head(scoreOverTime)?.value;
  const latestScore = last(scoreOverTime)?.value;

  const result = {
    logoUrl,
    name,
    score: latestScore,
    factorType: compareMeta.typeName,
    competitorAverageScore: 100,
    delta: latestScore - oldestScore,
    scoreOverTime,
  };

  return result;
}

export function prepareSubfactorsInfo(data, compareMeta, timeframe) {
  if (
    !data?.competitiveSet?.session?.[compareMeta.typeQuery]?.[
      compareMeta.subsetQuery
    ] ||
    !timeframe
  ) {
    return;
  }

  const { session } = data.competitiveSet;
  const factor = session[compareMeta.typeQuery];
  const factors = factor[compareMeta.subsetQuery];

  const scoresByTimeframe = session[compareMeta.subsetTimelineName][0];
  const scoreOverTime = JSON.parse(scoresByTimeframe.t4Quarters);
  const scoreOverTimeByFactorName = groupBy(scoreOverTime, 'name');

  const result = [];

  factors.forEach((f) => {
    let factorScoresOverTime = [];

    if (scoreOverTimeByFactorName[f.name]) {
      factorScoresOverTime = scoreOverTimeByFactorName[f.name]
        .filter((score) => isBetween(score.date, timeframe))
        .map((score) => ({
          value: Math.round(score.value),
          date: score.date,
        }));
    }

    const oldestScore = head(factorScoresOverTime)?.value;
    const latestScore = last(factorScoresOverTime)?.value;

    result.push({
      score: latestScore,
      factorType: f.name,
      competitorAverageScore: 100,
      delta: latestScore - oldestScore,
      scoreOverTime: factorScoresOverTime,
    });
  });

  return result;
}

function prepareDriversInfo(data, compareMeta, timeframe) {
  if (
    !data?.competitiveSet?.session?.[compareMeta?.subsetTimelineName] ||
    !timeframe
  ) {
    return;
  }

  const { session } = data.competitiveSet;
  const factor = session[compareMeta.typeQuery];
  const factors = factor[compareMeta.subsetQuery];

  const drivers = session[compareMeta.subsetTimelineName].map((driver) =>
    JSON.parse(driver.t4Quarters)
  );

  const result = [];

  if (!drivers) {
    return result;
  }

  factors.forEach((f) => {
    const driverScoreOverTime = drivers.filter(
      (driverScores) => driverScores[0].variableId === f.variableId
    )[0];

    let filteredDriverScoresOverTime = [];

    if (driverScoreOverTime) {
      filteredDriverScoresOverTime = driverScoreOverTime
        .filter((driver) => isBetween(driver.date, timeframe))
        .map((driver) => ({
          value: Math.round(driver.value),
          date: driver.date,
        }));
    }

    const oldestScore = head(filteredDriverScoresOverTime)?.value;
    const latestScore = last(filteredDriverScoresOverTime)?.value;

    const ratingMeta = JSON.parse(f.properties);
    const rating = ratingMeta.negativeImpact
      ? -ratingMeta.impactScore
      : ratingMeta.impactScore;

    result.push({
      variableId: f.variableId,
      score: latestScore,
      factorType: f.name,
      competitorAverageScore: 100,
      delta: latestScore - oldestScore,
      scoreOverTime: filteredDriverScoresOverTime,
      isCurrency: false,
      description: f.description,
      rating,
    });
  });

  return result;
}

export function prepareSubsetInfo(data, compareMeta, timeframe) {
  if (compareMeta.type !== 'SubFactors') {
    return prepareSubfactorsInfo(data, compareMeta, timeframe);
  }
  return prepareDriversInfo(data, compareMeta, timeframe);
}

export function prepareCompetitorBrandInfo(
  data,
  brandKey,
  compareMeta,
  timeframe
) {
  if (
    !data?.competitiveSet?.session ||
    !brandKey ||
    !compareMeta ||
    !compareMeta.typeName ||
    !timeframe
  ) {
    return;
  }

  const scoresByTimeframe = find(
    data.competitiveSet.session[`Competitor${compareMeta.type}`],
    { brandKey }
  );

  const scoreOverTime = JSON.parse(scoresByTimeframe.t4Quarters)
    .filter(
      (score) =>
        compareMeta.typeName === score.name && isBetween(score.date, timeframe)
    )
    .map((score) => ({
      value: Math.round(score.value),
      date: score.date,
    }));

  const oldestScore = head(scoreOverTime)?.value;
  const latestScore = last(scoreOverTime)?.value;

  const competitorBrandInfo = {
    score: latestScore,
    factorType: compareMeta.typeName,
    competitorAverageScore: 100,
    delta: latestScore - oldestScore,
    scoreOverTime,
  };
  return competitorBrandInfo;
}

function prepareCompetitorSubfactorInfo(
  data,
  brandKey,
  compareMeta,
  timeframe
) {
  if (
    !data?.competitiveSet?.session?.competitor ||
    !brandKey ||
    !compareMeta ||
    !timeframe
  ) {
    return;
  }
  const result = [];

  const { competitor } = data.competitiveSet.session;

  const factors = competitor[compareMeta.typeQuery][compareMeta.subsetQuery];
  const scoresByTimeframe = find(
    data.competitiveSet.session[`Competitor${compareMeta.subsetTimelineName}`],
    { brandKey }
  );

  const scores = JSON.parse(scoresByTimeframe.t4Quarters);
  const scoresByFactorName = groupBy(scores, 'name');

  factors.forEach((f) => {
    const scoreOverTime = scoresByFactorName[f.name]
      .filter((score) => isBetween(score.date, timeframe))
      .map((score) => ({ value: Math.round(score.value), date: score.date }));

    const oldestScore = head(scoreOverTime)?.value;
    const latestScore = last(scoreOverTime)?.value;

    result.push({
      score: latestScore,
      factorType: f.name,
      competitorAverageScore: 100,
      delta: latestScore - oldestScore,
      scoreOverTime,
    });
  });

  return result;
}

function prepareCompetitorDriverInfo(data, brandKey, compareMeta, timeframe) {
  if (
    !data?.competitiveSet?.session?.competitor ||
    !brandKey ||
    !compareMeta ||
    !timeframe
  ) {
    return;
  }
  const result = [];

  const { competitor, CompetitorScoreDrivers } = data.competitiveSet.session;

  const { scoreDrivers } = competitor.subFactor;

  const competitorDrivers = CompetitorScoreDrivers.filter(
    (driver) => brandKey === driver.brandKey
  ).map((time) => JSON.parse(time.t4Quarters));

  const customerDriverVariableIds = data.competitiveSet.session[
    compareMeta.typeQuery
  ].scoreDrivers.map((driver) => driver.variableId);

  scoreDrivers.forEach((driver) => {
    if (
      !customerDriverVariableIds.includes(driver.variableId) ||
      !competitorDrivers ||
      !competitorDrivers.length
    ) {
      return;
    }

    const competitorDriversOverTime = competitorDrivers.filter(
      (competitorDriver) => competitorDriver[0].variableId === driver.variableId
    )[0];

    let scoreOverTime = [];

    if (competitorDriversOverTime) {
      scoreOverTime = competitorDriversOverTime
        .filter((competitorDriver) =>
          isBetween(competitorDriver.date, timeframe)
        )
        .map((competitorDriver) => ({
          value: Math.round(competitorDriver.value),
          date: competitorDriver.date,
        }));
    }

    const oldestScore = head(scoreOverTime)?.value;
    const latestScore = last(scoreOverTime)?.value;

    result.push({
      variableId: driver.variableId,
      isCurrency: false,
      score: latestScore,
      factorType: driver.name,
      competitorAverageScore: 100,
      delta: latestScore - oldestScore,
      description: driver.description,
      scoreOverTime,
    });
  });
  const sorted = [];
  data.competitiveSet.session.subFactor.scoreDrivers.forEach((driver) => {
    result.forEach((res) => {
      if (res.variableId === driver.variableId) sorted.push(res);
    });
  });

  return sorted;
}

export function prepareCompetitorSubsetInfo(
  data,
  brandKey,
  compareMeta,
  timeframe
) {
  if (compareMeta.type !== 'SubFactors') {
    return prepareCompetitorSubfactorInfo(
      data,
      brandKey,
      compareMeta,
      timeframe
    );
  }
  return prepareCompetitorDriverInfo(data, brandKey, compareMeta, timeframe);
}

export function prepareCompetitorDropdownOptions(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.competitors
  ) {
    return;
  }

  return data.competitiveSet.session.competitors
    .map((competitor) => ({
      name: competitor.name,
      brandKey: competitor.brandKey,
      logoUrl: competitor.logoUrl ?? '',
      bluescore: competitor.blueScore.value,
    }))
    .sort((competitorA, competitorB) => {
      return competitorB.bluescore - competitorA.bluescore;
    });
}
