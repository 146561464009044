import { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import { BrandLogo } from '@blueoceanai/react-component-library';
import { SIZES } from '../../../../constants/props';
import { FormattedESOVdata } from '../types';

import ScoreTilePercent from '../ScoreTilePercent/ScoreTilePercent';

import styles from './ExcessShareOfVoiceGrid.module.scss';

export interface ExcessShareOfVoiceGridProps {
  data: FormattedESOVdata[];
}

const ExcessShareOfVoiceGrid: FC<ExcessShareOfVoiceGridProps> = ({ data }) => {
  const formattedTableData = [
    {
      label: 'Share of Voice',
      subtitle: 'share of ad and paid search spend in the cohort',
      data: data?.map((item) => item?.shareOfVoice),
    },
    {
      label: 'Share of Market',
      subtitle: 'share of revenue in the cohort',
      data: data?.map((item) => item?.shareOfMarket),
    },
    {
      label: 'Excess Share of Voice',
      subtitle: 'SOV% - SOM%',
      data: data?.map((item) => item?.excessShareOfVoice),
      showDiff: true,
    },
  ];

  const tableColumns = data.map((item) => ({
    name: item?.brand,
    logoUrl: item?.logoUrl?.trim(),
    brandKey: item?.brandKey,
  }));

  return (
    <TableContainer className={styles.ExcessShareOfVoiceGrid} component={Box}>
      <Table className={styles.Table} aria-label="Excess Share Of Voice Grid">
        <TableHead className={styles.Head}>
          <TableRow>
            <TableCell className={styles.Cell} />
            {tableColumns.map((tableCol) => {
              return (
                <TableCell
                  key={tableCol.name}
                  className={styles.HeaderCell}
                  align="center"
                >
                  <BrandLogo size={SIZES.MD} src={tableCol.logoUrl} />
                  <p>{tableCol?.name}</p>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody className={styles.Body}>
          {formattedTableData.map((row, index) => (
            <TableRow className={styles.Row} key={row.label}>
              <TableCell
                className={styles.LabelCell}
                component="th"
                scope="row"
              >
                <p className={styles.Label}>{row.label}</p>
                <p className={styles.Subtitle}>{`(${row.subtitle})`}</p>
              </TableCell>
              {formattedTableData[index]?.data?.map((chartData) => (
                <TableCell
                  className={styles.ScoreTileCell}
                  key={chartData}
                  align="center"
                >
                  <ScoreTilePercent
                    value={chartData ?? 0}
                    showDiff={row.showDiff}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ExcessShareOfVoiceGrid;
