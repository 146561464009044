import { AnchorHTMLAttributes, CSSProperties, useMemo } from 'react';
import cn from 'classnames';
import DynamicArrowCircleIcon, {
  ArrowDirection,
} from '../../../../assets/icons/DynamicArrowCircleIcon';
import styles from './ChangeIconLabel.module.scss';
import { abbreviateNumber } from '../../../../utils/number';

export interface ChangeIconLabelProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  metric: number;
  suffix?: string;
  iconSize?: CSSProperties['width'];
  labelClassName?: string;
}

const ChangeIconLabel = ({
  metric,
  suffix,
  iconSize,
  labelClassName,
  className = '',
}: ChangeIconLabelProps) => {
  const arrowProps = useMemo(() => {
    let direction = ArrowDirection.right;

    if (metric > 0) {
      direction = ArrowDirection.up;
    }

    if (metric < 0) {
      direction = ArrowDirection.down;
    }

    return { direction };
  }, [metric]);

  const labelClass = useMemo(() => {
    if (metric > 0) return styles.Up;
    if (metric < 0) return styles.Down;
    return '';
  }, [metric]);

  const metricLabel = useMemo(() => {
    return abbreviateNumber(Math.abs(metric));
  }, [metric]);

  return (
    <div className={styles.ChangeIconLabel}>
      <DynamicArrowCircleIcon
        direction={arrowProps.direction}
        size={iconSize}
        className={cn(styles.Icon, className)}
      />
      <p className={cn(styles.Label, labelClass, labelClassName)}>
        {metricLabel} {suffix}
      </p>
    </div>
  );
};

export default ChangeIconLabel;
