import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';

import IconChartAreaStacked from '../../../assets/icons/IconChartAreaStacked.jsx';
import IconChartColumnGrouped from '../../../assets/icons/IconChartColumnGrouped.jsx';

import useFeatureFlags from '../../../hooks/useFeatureFlags';
import { ChartType } from '../../../interfaces/chart';

import styles from './ChartTypeSelector.module.scss';
import ChartPieIcon from '../../../assets/icons/Charts/ChartPieIcon';

export interface ChartTypeSelectorProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  defaultChartType?: ChartType;
  onTypeChange: (newVal: ChartType, oldVal: ChartType) => void;
  hideAreaChart?: boolean;
  hidePieChart?: boolean;
  customIcons?: { icon: JSX.Element; type: ChartType }[];
}

const ChartTypeSelector: FunctionComponent<ChartTypeSelectorProps> = ({
  defaultChartType,
  onTypeChange,
  className,
  hideAreaChart,
  hidePieChart,
  customIcons,
  ...props
}) => {
  const [activeChartType, setActiveChartType] = useState<ChartType>(
    defaultChartType ?? ChartType.Bar
  );
  const FEATURE_FLAGS = useFeatureFlags();

  function handleIconClick(newType: ChartType) {
    const oldType = activeChartType;

    setActiveChartType(newType);
    onTypeChange(newType, oldType);
  }

  const formatIcons = () => {
    if (customIcons) return customIcons;

    const result = [];

    if (FEATURE_FLAGS.METRICS.L3.BAR) {
      result.push({
        icon: <IconChartColumnGrouped />,
        type: ChartType.Bar,
      });
    }

    if (FEATURE_FLAGS.METRICS.L3.TIMELINE && !hideAreaChart) {
      result.push({
        icon: <IconChartAreaStacked />,
        type: ChartType.Area,
      });
    }

    if (FEATURE_FLAGS.METRICS.L3.PIE && !hidePieChart) {
      result.push({
        icon: <ChartPieIcon />,
        type: ChartType.Pie,
      });
    }

    return result;
  };

  const items = formatIcons();

  // if a default chart type is provided, set it as the active chart type
  useEffect(() => {
    if (defaultChartType) {
      setActiveChartType(defaultChartType);
    }
  }, [defaultChartType]);

  return (
    <Box className={classNames(styles.ChartTypeSelector, className)} {...props}>
      <span className={styles.Label}>Chart:</span>
      {items.map((item) => (
        <Box
          onClick={() => handleIconClick(item.type)}
          key={item.type}
          className={classNames(
            styles.Icon,
            item.type === activeChartType ? styles.Active : null
          )}
        >
          {item.icon}
        </Box>
      ))}
    </Box>
  );
};

export default ChartTypeSelector;
