import {
  useState,
  useMemo,
  useCallback,
  FunctionComponent,
  AnchorHTMLAttributes,
} from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import orderBy from 'lodash/orderBy';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ChartTypeSelector from '../../ChartUtils/ChartTypeSelector';
import BrandDatasetFilter from '../../ChartUtils/BrandDatasetFilter';
import RadioDateSelector from '../../Molecules/RadioDateSelector/RadioDateSelector';
import PageTitle from '../../Molecules/PageTitle/PageTitle';
import BrandCompareBarChart from '../../Charts/BrandCompareBarChart';
import BrandCompareAreaChart from '../../Charts/BrandCompareAreaChart';
import BrandComparePieChart from '../../Charts/BrandComparePieChart';
import ChartLegend, {
  ChartLegendProps,
} from '../../Molecules/ChartLegend/ChartLegend';
import ScoreTypeSwitch from '../../SocialMetrics/ScoreTypeSwitch';

import { ScoreType, SocialChannelKey } from '../../../interfaces/metric';

import useChartBrandColorsMap from '../../../hooks/useChartBrandColorsMap';

import {
  ORIENTATIONS,
  CHART_LEGEND_ICON_VARIANT,
} from '../../../constants/props';
import {
  SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST,
  SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS,
} from '../../../constants/social-metrics';

import { ChartType, ChartDataset } from '../../../interfaces/chart';
import { Metric } from '../../../interfaces';
import {
  metricValueFormattersByType,
  metricUnitFormattersByType,
} from '../../../utils/metric';

import styles from './MetricDefaultChartView.module.scss';
import { FeatureFlag } from '../../../utils/featureFlags';

export interface MetricDefaultChartViewProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  metric: Metric;
  brandMetricsDatasets: ChartDataset[];
  scoreType: ScoreType;
  onScoreTypeChange: (type: ScoreType) => void;
  socialChannelId: SocialChannelKey;
}

const MetricDefaultChartView: FunctionComponent<
  MetricDefaultChartViewProps
> = ({
  className,
  metric,
  brandMetricsDatasets,
  scoreType = ScoreType.Indexed,
  socialChannelId,
  onScoreTypeChange,
  ...props
}) => {
  const [activeChartType, setActiveChartType] = useState<ChartType>(
    ChartType.Bar
  );
  const [hiddenBrandKeys, setHiddenBrandKeys] = useState<string[]>([]);
  const flags = useFlags();

  const chartColorMapByBrandKey = useChartBrandColorsMap({
    heroBrandKey: brandMetricsDatasets?.[0]?.key,
    brands: brandMetricsDatasets,
  });

  const chartLegendItems = useMemo(() => {
    const heroBrand: ChartLegendProps['items'] = [];
    const competitors: ChartLegendProps['items'] = [];

    brandMetricsDatasets.forEach((brand, index) => {
      if (index === 0) {
        heroBrand.push({
          variant: CHART_LEGEND_ICON_VARIANT.SQUARE,
          label: brand.name,
          color: chartColorMapByBrandKey[brand.key],
        });
      } else {
        competitors.push({
          variant: CHART_LEGEND_ICON_VARIANT.SQUARE,
          label: brand.name,
          color: chartColorMapByBrandKey[brand.key],
        });
      }
    });

    const sortedCompetitors = orderBy(competitors, 'label', 'asc');

    return heroBrand.concat(sortedCompetitors);
  }, [brandMetricsDatasets, chartColorMapByBrandKey]);

  const filteredBrandDatasets = useMemo(() => {
    return brandMetricsDatasets.filter((brand) => {
      return !hiddenBrandKeys.includes(brand.key);
    });
  }, [hiddenBrandKeys, brandMetricsDatasets]);

  const yAxisFormatter = useCallback(
    (value: string | number) => {
      const formattedVal =
        metricValueFormattersByType[metric.formatType](value);

      return metricUnitFormattersByType[metric.unit](String(formattedVal));
    },
    [metric]
  );

  const showRawScoreToggle =
    flags[FeatureFlag.ViewMetricsSocialMetrics] &&
    (SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(metric.variableId) ||
      metric.variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.ZZ);

  return (
    <Box
      className={classNames(styles.MetricDefaultChartView, className)}
      id="metrics-detail-chart-export-container"
      {...props}
    >
      <Grid container>
        <Grid
          xs={12}
          item
          className={classNames(styles.PageTitle, 'PageTitleContainer')}
        >
          <PageTitle
            className={styles.PageTitle}
            title={metric?.nameWithChannel || metric?.name}
            downloadable
            favoritable
            metricId={metric?.variableId}
            targetId="metrics-detail-chart-export-container"
          />
        </Grid>

        {metric?.description && (
          <Grid xs={12} md={6} item className={styles.MetricDescription}>
            <p>{metric.descriptionOfChannel || metric.description}</p>
          </Grid>
        )}
      </Grid>

      <Grid container>
        <Grid item xs={12} md={3}>
          <Box className={styles.BrandFilterContainer}>
            <BrandDatasetFilter
              heroBrandKey={brandMetricsDatasets?.[0]?.key}
              brandsDatasets={brandMetricsDatasets}
              onFilterChange={setHiddenBrandKeys}
              metricId={metric?.variableId}
              scoreType={scoreType}
            />

            {showRawScoreToggle && (
              <ScoreTypeSwitch
                value={scoreType}
                className={classNames(
                  'hide-from-export',
                  'hide-from-share',
                  styles.ScoreTypeSwitch
                )}
                onTypeChange={onScoreTypeChange}
                disabled={socialChannelId !== SocialChannelKey.Aggregate}
              />
            )}
          </Box>
        </Grid>

        <Grid container item xs={12} md={9}>
          <Box className={styles.ChartOptions} mb={6}>
            <ChartTypeSelector
              onTypeChange={setActiveChartType}
              className={styles.ChartTypeSelector}
            />
            <RadioDateSelector className={styles.RadioDateSelector} />
          </Box>

          <Box className={styles.MetricComparisonChartContainer}>
            {activeChartType === ChartType.Bar ? (
              <BrandCompareBarChart
                brandsDatasets={filteredBrandDatasets}
                chartColorMapByBrandKey={chartColorMapByBrandKey}
                yAxisFormatter={yAxisFormatter}
              />
            ) : null}

            {activeChartType === ChartType.Area ? (
              <BrandCompareAreaChart
                brandsDatasets={filteredBrandDatasets}
                chartColorMapByBrandKey={chartColorMapByBrandKey}
                yAxisFormatter={yAxisFormatter}
              />
            ) : null}

            {activeChartType === ChartType.Pie ? (
              <div className={styles.PieChartContainer}>
                <BrandComparePieChart
                  brandsDatasets={filteredBrandDatasets}
                  chartColorMapByBrandKey={chartColorMapByBrandKey}
                  yAxisFormatter={yAxisFormatter}
                />
              </div>
            ) : null}
          </Box>

          <Box className={styles.ChartLegendContainer}>
            <ChartLegend
              items={chartLegendItems}
              orientation={ORIENTATIONS.HORIZONTAL}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MetricDefaultChartView;
