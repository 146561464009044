import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import find from 'lodash/find';
import { SingleValue } from 'react-select';

import useFeatureFlags from '../../../hooks/useFeatureFlags';
import QUERY_PARAMS from '../../../constants/queryParams';
import useRouter from '../../../hooks/useRouter';
import DropdownSelect from './DropdownSelect/DropdownSelect';
import styles from './Creative.module.scss';
import { GET_COMPETITORS } from '../../../api/queries/Pages/MarketIndexCompare';
import { handleGenericError } from '../../../utils/error';
import { prepareCompetitorDropdownOptions } from '../../../api/transforms/Pages/MarketIndexCompare';
import BNContext from '../../../contexts/BNContext';
import useQuery from '../../../hooks/useQuery';

import Workspace from '../../Templates/Workspace/Workspace';
import SubNav from '../../Organisms/PageSubnav/PageSubnav';

const beta = true;
const ALL_BRANDS_FILTER_OPT_IN = ['/website'];
const BRAND_FILTER_OPT_OUT = ['/overview'];

const showAllBrandsOption = (path: string) => {
  return ALL_BRANDS_FILTER_OPT_IN.some((optInRoot) => path.includes(optInRoot));
};

const showBrandFilter = (path: string) => {
  return !BRAND_FILTER_OPT_OUT.some((optInRoot) => path.includes(optInRoot));
};

export default function Creative() {
  const FEATURE_FLAGS = useFeatureFlags();

  const location = useLocation();

  const { updateRoute } = useRouter();
  const path = 'creative';
  const pageTitle = 'Creative Analysis';

  const { competitiveSetID, currentSession } = useContext(BNContext);
  const queryParams = useQuery();

  const queryVariableBase = {
    id: competitiveSetID,
    sessionKey: currentSession,
  };

  const [
    getCompetitors,
    {
      loading: loadingCompetitors,
      // error: errorCompetitors,
      data: competitorsResp,
    },
  ] = useLazyQuery(GET_COMPETITORS, {
    variables: {
      ...queryVariableBase,
    },
  });

  useEffect(() => {
    if (!competitorsResp || loadingCompetitors) {
      getCompetitors();
    }
  }, [getCompetitors, competitorsResp, loadingCompetitors]);

  const competitorBrands = useMemo(
    () =>
      handleGenericError(
        () => prepareCompetitorDropdownOptions(competitorsResp),
        'MarketIndexCompare prepareCompetitorDropdownOptions failed transform'
      ),
    [competitorsResp]
  );

  const heroBrand = useMemo(() => {
    return competitorsResp?.competitiveSet?.brand;
  }, [competitorsResp]);

  const compSetBrandOptions = useMemo(() => {
    if (!heroBrand || !competitorBrands?.length) {
      return [];
    }
    const head = showAllBrandsOption(location.pathname)
      ? [
          {
            label: 'All Brands',
            value: 'ALL_BRANDS',
          },
        ]
      : [];
    // potentially add competitorBrands.sort(nameSort); here
    return head.concat(
      [heroBrand].concat(...competitorBrands).map((brand) => ({
        label: brand.name,
        value: brand.brandKey,
      }))
    );
  }, [heroBrand, competitorBrands, location]);

  const selectedBrand = useMemo(() => {
    return find(compSetBrandOptions, {
      value: queryParams.get(QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY),
    }) as { label: string; value: string };
  }, [compSetBrandOptions, queryParams]);

  /**
   * If no selected brand query parameter is available, default to the first brand in the comp set.
   */
  useEffect(() => {
    if (!compSetBrandOptions?.[0]) {
      return;
    }

    const matchedBrand = find(compSetBrandOptions, {
      value: queryParams.get(QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY),
    });

    if (
      queryParams.get(QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY) &&
      matchedBrand
    ) {
      return;
    }

    updateRoute({
      params: {
        [QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY]:
          compSetBrandOptions[0].value,
      },
      replace: true,
    });
  }, [queryParams, compSetBrandOptions, updateRoute]);

  const CUSTOM_FILTER_HOOKS: Record<string, (value: string) => void> = useMemo(
    () => ({
      '/website': (v) => {
        const pathInHook =
          v === 'ALL_BRANDS'
            ? '/creative/channels/website/all-brands'
            : '/creative/channels/website/brand';
        updateRoute({
          pathname: pathInHook,
          params: {
            [QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY]: v,
          },
          replace: true,
        });
      },
    }),
    [updateRoute]
  );

  const getCustomFilterChangeHook = useCallback(
    (pathCallback: string) => {
      const [hookKey] = Object.keys(CUSTOM_FILTER_HOOKS).filter((key) =>
        pathCallback.includes(key)
      );
      return CUSTOM_FILTER_HOOKS[hookKey] ?? (() => {});
    },
    [CUSTOM_FILTER_HOOKS]
  );

  const handleSelectedBrandChange = useCallback(
    (option: SingleValue<{ value: string; label: string }>) => {
      if (!option?.value) {
        return;
      }

      updateRoute({
        params: {
          [QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY]: option.value,
        },
      });
      (async () => {
        getCustomFilterChangeHook(location.pathname)(option.value);
      })();
    },
    [updateRoute, location, getCustomFilterChangeHook]
  );

  const tabRightRender = useCallback(() => {
    return (Boolean(showBrandFilter(location.pathname)) && (
        <DropdownSelect
          className={styles.BrandSelector}
          options={compSetBrandOptions}
          onChange={handleSelectedBrandChange}
          value={selectedBrand}
        />
      )
    );
  }, [
    compSetBrandOptions,
    handleSelectedBrandChange,
    selectedBrand,
    location,
  ]);

  function tabPages() {
    const result = [];

    if (FEATURE_FLAGS.CREATIVE.CHANNELS.ROOT) {
      result.push(
        {
          label: 'Overview',
          value: 'channels/overview',
        },
        {
          label: 'Advertising',
          value: 'channels/advertising',
        },
        {
          label: 'Website',
          value: 'channels/website',
          updateRouteOverride: () => {
            const params: { [index: string]: string } = {};

            // Per requirements, tab click on Creative Website section
            // should reset the Brand selector since that section
            // uses 'ALL_BRANDS' as the default selected brand key

            params[QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY] =
              'ALL_BRANDS';
            updateRoute({
              pathname: '/creative/channels/website/all-brands',
              params,
            });
          },
        }
      );
    }
    return result;
  }

  return (
    <div id="page-wrapper" className="WorkspaceContainer">
      <div className="TabNavContainer">
        <SubNav
          path={path}
          title={pageTitle}
          tabs={tabPages()}
          beta={beta}
          tabRightRender={tabRightRender}
        />
      </div>

      <Workspace>
        <Outlet />
      </Workspace>
    </div>
  );
}
