import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { BrandLogo } from '@blueoceanai/react-component-library';

import { SIZES } from '../../../constants/props';
import ThemesSemiPieChart from '../../Molecules/ThemesSemiPieChart/ThemesSemiPieChart';
import IconRational from '../../../assets/icons/IconRational.jsx';
import IconEmotinal from '../../../assets/icons/IconEmotional.jsx';

import useRouter from '../../../hooks/useRouter';

import styles from './ThemesCard.module.scss';

export default function ThemesCard({
  variant,
  cardTheme,
  cardTitle,
  data,
  brandLogo,
  ...props
}) {
  const { updateRoute } = useRouter();

  return (
    <Grid
      className={`${styles.ThemesCard}`}
      container
      alignItems="center"
      {...props}
    >
      <Grid
        item
        container
        xs={12}
        md={3}
        className={`${styles.ThemesCardHeaderItem} ${styles.ThemesCardItem}`}
        justifyContent="flex-start"
        alignItems="center"
      >
        {variant === 'themes' ? (
          <h5 className={styles.ThemesCardHeader}>
            {cardTheme === 'emotional' ? (
              <IconEmotinal className={styles.Icon} />
            ) : (
              <IconRational className={styles.Icon} />
            )}
            <div
              className={styles.CardTitle}
              onClick={() =>
                updateRoute({
                  pathname: '/content-analysis/themes/overview',
                  state: {
                    selectedTheme: {
                      label: cardTitle,
                      value: cardTitle.trim().replace(' ', '-').toLowerCase(),
                    },
                  },
                })
              }
            >
              {cardTitle}
            </div>
          </h5>
        ) : null}
        {variant === 'themes-overview' ? (
          <BrandLogo size={SIZES.MD} name={cardTitle} src={brandLogo} />
        ) : null}
      </Grid>
      {variant === 'themes' || variant === 'themes-overview'
        ? data.map((item, index) => (
            <Grid
              key={item.key}
              item
              container
              xs={4}
              md={3}
              className={`${styles.ThemesCardItem}`}
              alignItems="center"
              justifyContent="center"
            >
              {index === 0 ? (
                <p className={styles.PieChartTitle}>Brand Volume</p>
              ) : null}
              {index === 1 ? (
                <p className={styles.PieChartTitle}>Audience Volume</p>
              ) : null}
              {index === 2 ? (
                <p className={styles.PieChartTitle}>Audience Sentiment</p>
              ) : null}
              <ThemesSemiPieChart
                chartType={item.type}
                value={item.value}
                competitorAverage={item.competitorAverage}
              />
            </Grid>
          ))
        : null}
    </Grid>
  );
}

ThemesCard.propTypes = {
  variant: PropTypes.string,
  cardTheme: PropTypes.string,
  cardTitle: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  brandLogo: PropTypes.string,
};
