import { FunctionComponent, useState } from 'react';
import classNames from 'classnames';
import Markdown from 'react-markdown';
import {
  FlowKey,
  SettingsView,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';
import { InfoCircleIcon } from '../../../../../assets/icons';
import SettingsButtonView from '../../WidgetSettingsShared/SettingsButtonView/SettingsButtonView';
import CalculationsAndSources from '../../CalculationsAndSources/CalculationsAndSources';
import { getMetricIdsFromSettingsConfig } from '../helper';
import widgetExamplesByWidgetType from './widgetExampleMap';

import styles from './GenericSettingsFlow.module.scss';
import {
  VisualMapsViewType,
  WidgetType,
} from '../../../../../interfaces/dashboard-api';

const GenericSettingsFlow: FunctionComponent<WidgetConfigProps> = ({
  config,
  widgetType,
  containerClassName,
}) => {
  const [activeView, setActiveView] = useState<SettingsView>(
    SettingsView.WidgetExample
  );

  const handleViewChange = (view: SettingsView) => setActiveView(view);

  const metricIds = getMetricIdsFromSettingsConfig({ config, widgetType });
  const widgetExample = widgetExamplesByWidgetType[widgetType];

  // VisualMapsV1 displays a different example image based on which of the two viewTypes is selected
  const visualMapViewType =
    widgetType === WidgetType.VisualMapsV1
      ? config?.[FlowKey.VisualMapsSettings]?.viewType
      : '';

  return (
    <div className={classNames(styles.GenericSettingsFlow)}>
      <SettingsButtonView
        activeView={activeView}
        handleViewChange={handleViewChange}
      />

      {activeView === SettingsView.WidgetExample ? (
        <>
          {widgetType !== WidgetType.VisualMapsV1 ||
          visualMapViewType === VisualMapsViewType.HEATMAP ? (
            <div className={styles.ExampleContainer}>
              <img src={widgetExample.exampleImg} alt="Widget Example" />
              <p className={styles.HelperText}>
                Widget Example for illustrative purposes only; does not
                represent actual data
              </p>
            </div>
          ) : (
            <div className={styles.ExampleContainer}>
              <img src={widgetExample.secondExampleImg} alt="Widget Example" />
              <p className={styles.HelperText}>
                Widget Example for illustrative purposes only; does not
                represent actual data
              </p>
            </div>
          )}

          <div className={styles.HelperContainer}>
            <div className={styles.Header}>
              <InfoCircleIcon className={styles.Icon} />

              <p className={styles.HeaderText}>How this widget can help you:</p>
            </div>

            <Markdown className={styles.InfoContainer}>
              {widgetExample.infoMd}
            </Markdown>
          </div>
        </>
      ) : (
        Boolean(metricIds.length && widgetType) && (
          <CalculationsAndSources
            metricIds={metricIds}
            widgetType={widgetType}
            className={styles.CalculationsAndSourcesSettings}
            containerClassName={containerClassName}
          />
        )
      )}
    </div>
  );
};

export default GenericSettingsFlow;
