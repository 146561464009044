import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './RadioSelectorItem.module.scss';

export default function RadioSelectorItem({
  name,
  active,
  className,
  ...props
}) {
  return (
    <li
      className={classNames(
        styles.RadioSelectorItem,
        active ? styles.Active : null,
        className
      )}
      {...props}
    >
      <span>{name}</span>
    </li>
  );
}

RadioSelectorItem.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool,
  className: PropTypes.string,
};
