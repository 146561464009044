import { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button } from '@blueoceanai/react-component-library';

import blankDashboard from '../../../../assets/images/dashboards/blank-dashboard.jpg';
import cmoDashboard from '../../../../assets/images/dashboards/cmo-dashboard.jpg';
import diagnosticDashboard from '../../../../assets/images/dashboards/diagnostic-dashboard.jpg';
import shareOfVoiceDashboard from '../../../../assets/images/dashboards/shareOfVoice-dashboard.jpg';
import socialDashboard from '../../../../assets/images/dashboards/social-dashboard.jpg';
import vitalSignsDashboard from '../../../../assets/images/dashboards/vitalSigns-dashboard.jpg';

import { DashboardTemplateType } from '../types';
import styles from './TemplateSelectorCard.module.scss';
import { DashboardConfigurationType } from '../../../../interfaces/dashboard-api';

interface CardProps {
  title: string;
  description: string;
  onCardClick: (type: string) => void;
  type: string;
}

const TemplateImageLookup: Record<string, string> = {
  [DashboardConfigurationType.CUSTOM]: blankDashboard,
  [DashboardConfigurationType.CMO]: cmoDashboard,
  [DashboardConfigurationType.DEFAULT]: vitalSignsDashboard,
  [DashboardConfigurationType.DIAGNOSTIC]: diagnosticDashboard,
  [DashboardConfigurationType.SOCIAL]: socialDashboard,
  [DashboardConfigurationType.SOV]: shareOfVoiceDashboard,
};

const TemplateSelectorCard: FC<CardProps> = ({
  title,
  description,
  onCardClick,
  type,
}) => {
  return (
    <Card className={styles.Card}>
      <div className={styles.CardImage}>
        <img
          src={TemplateImageLookup[type] ?? blankDashboard}
          alt={`${title} Card`}
        />
      </div>
      <CardContent className={styles.CardContent}>
        <div className={styles.Text}>
          <p className={styles.Title}>{title}</p>
          <p className={styles.Description}>{description}</p>
        </div>

        <Button
          variant="contained"
          className={styles.CardButton}
          onClick={() => onCardClick(type)}
        >
          {type === DashboardTemplateType.Custom ? 'Start' : 'Customize'}
        </Button>
      </CardContent>
    </Card>
  );
};

export default TemplateSelectorCard;
