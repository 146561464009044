import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './BetaTag.module.scss';

export default function BetaTag({ className, ...props }) {
  return (
    <div
      className={classNames('beta-feature-tag', styles.BetaTag, className)}
      {...props}
    >
      <span>Beta Feature</span>
    </div>
  );
}

BetaTag.propTypes = {
  className: PropTypes.string,
};
