import * as React from 'react';

function SvgEyeHide(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#7E9397"
          d="M22.386 12.183c-.123-.149-2.052-2.453-4.78-4.081l3.748-3.748a.5.5 0 00-.707-.707l-3.956 3.955C15.401 6.956 13.978 6.5 12.5 6.5c-5.147 0-9.695 5.45-9.886 5.683a.5.5 0 000 .635c.123.149 2.052 2.453 4.78 4.081l-3.748 3.748a.5.5 0 00.708.707l3.956-3.955c1.29.646 2.713 1.102 4.191 1.102 5.147 0 9.695-5.45 9.886-5.683a.5.5 0 00-.001-.635zm-18.72.317c1.041-1.147 4.837-5 8.834-5 1.188 0 2.355.345 3.44.853l-1.708 1.707A2.972 2.972 0 0012.5 9.5c-1.654 0-3 1.346-3 3 0 .646.21 1.242.56 1.733l-1.931 1.931c-2.135-1.226-3.822-2.957-4.463-3.664zm10.834 0c0 1.103-.897 2-2 2-.37 0-.712-.107-1.01-.283l2.727-2.727c.175.298.283.64.283 1.01zm-4 0c0-1.103.897-2 2-2 .37 0 .712.107 1.01.283l-2.727 2.727a1.986 1.986 0 01-.283-1.01zm2 5c-1.188 0-2.355-.345-3.44-.853l1.708-1.707c.49.349 1.086.56 1.733.56 1.654 0 3-1.346 3-3 0-.646-.21-1.242-.56-1.733l1.931-1.931c2.135 1.227 3.822 2.957 4.463 3.664-1.042 1.147-4.837 5-8.835 5z"
        />
      </g>
    </svg>
  );
}

export default SvgEyeHide;
