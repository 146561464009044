import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import {
  FC,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { abbreviateNumber } from '../../../../../utils/number';
import { handleGenericError } from '../../../../../utils/error';

import ExcessShareOfVoiceBarChart from '../../../../Charts/ExcessShareOfVoiceBarChart/ExcessShareOfVoiceBarChart';
import ExcessShareOfVoiceGrid from '../../ExcessShareOfVoiceGrid/ExcessShareOfVoiceGrid';
import ExcessShareOfVoiceLineChart from '../../../../Charts/ExcessShareOfVoiceLineChart/ExcessShareOfVoiceLineChart';
import ChangeDisplayIcon from '../../ChangeDisplayIcon/ChangeDisplayIcon';
import WidgetSubheader from '../../WidgetSubheader/WidgetSubheader';
import IconWithPopup from '../../IconWithPopup/IconWithPopup';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import GridChartIcon from '../../../../../assets/icons/Charts/GridChartIcon';
import LineChartIconSingle from '../../../../../assets/icons/Charts/LineChartSingleIcon';
import BarChartIconAscending from '../../../../../assets/icons/Charts/BarChartAscendingIcon';
import IconInfoCircleSolid from '../../../../../assets/icons/IconInfoCircleSolid';

import useESOV from '../../../../../hooks/useESOV';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/DataManager';
import { GET_COMPETITORS } from '../../../../../api/queries/Pages/MarketIndexCompare';
import { Brand } from '../../../../../hooks/useCompetitiveSet';
import { prepareCompetitorDropdownOptions } from '../../../../../api/transforms/Pages/MarketIndexCompare';

import BNContext from '../../../../../contexts/BNContext';

import { WidgetType } from '../../../../../interfaces/dashboard-api';
import { ESOVPosition } from '../../../../../interfaces/metric';
import { ChartType } from '../../../../../interfaces/chart';
import {
  WidgetMetaResponse,
  ExcessShareOfVoiceData,
  FormattedESOVdata,
} from '../../types';

import styles from './ExcessShareOfVoice.module.scss';

const CHART_ICONS = [
  {
    icon: <GridChartIcon />,
    type: ChartType.Grid,
  },
  {
    icon: <BarChartIconAscending />,
    type: ChartType.Bar,
  },
  {
    icon: <LineChartIconSingle />,
    type: ChartType.Line,
  },
];
export interface ExcessShareOfVoiceProps {
  config: WidgetMetaResponse;
  handleError?: (err: string) => void;
}

interface HeroBrand {
  metrics: ExcessShareOfVoiceData['data'][string];
  position: ESOVPosition;
  esovSetRanking: ESOVPosition;
  friendlyName: string;
}

const ExcessShareOfVoice: FC<ExcessShareOfVoiceProps> = ({
  config,
  handleError,
}) => {
  let heroBrand;
  const { competitiveSetID: competitiveSetIdFallback } = useContext(BNContext);
  const activeChart = config?.options?.view_type_id?.toLowerCase();
  const heroBrandKeyConfig = config?.data_srcs?.esov?.input?.brandKey;

  const [error, setError] = useState<boolean | null>(null);
  const [activeChartType, setActiveChartType] =
    useState<ChartType>(activeChart);

  const [esovData, setEsovData] = useState<ExcessShareOfVoiceData>({
    loading: false,
    error: null,
    data: {},
  });

  const isBarChart = activeChartType === ChartType.Bar;
  const competitiveSetId =
    config?.data_srcs?.esov?.input?.competitiveSetKey ||
    competitiveSetIdFallback;

  // fetch sessionKey for useESOV hook & to fetch competitor brands data
  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (config?.data_srcs?.esov?.input) {
      const { competitiveSetKey } = config.data_srcs.esov.input;

      getFirstLastSession({
        variables: {
          id: competitiveSetKey,
        },
      });
    }
  }, [config, getFirstLastSession]);

  const latestSessionKey =
    firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
      ?.sessionKey;

  // fetch ESOV calculation data
  useESOV(competitiveSetId as string, heroBrandKeyConfig, latestSessionKey)
    .then((data) => {
      const didESOVdataUpdate = isEqual(data.data, esovData.data);
      // if brandKey was changed or data was updated, re-render
      if (isEmpty(esovData.data) || !didESOVdataUpdate) {
        setEsovData(data as ExcessShareOfVoiceData);
      }
    })
    .catch((err) => {
      setError(err);
      if (handleError) handleError(config.id);
      // eslint-disable-next-line no-console
      console.error(err);
    });

  /* Competitor brands data fetching * handling start */
  const queryVariableBase = useMemo(() => {
    return {
      id: competitiveSetId,
      sessionKey: latestSessionKey,
    };
  }, [competitiveSetId, latestSessionKey]);

  // duplicated code with useCompetitiveSet
  const [
    getCompetitors,
    { loading: loadingCompetitors, data: competitorsResp },
  ] = useLazyQuery(GET_COMPETITORS, {
    variables: {
      ...queryVariableBase,
    },
  });

  useEffect(() => {
    if (!competitorsResp || loadingCompetitors) {
      getCompetitors();
    }
  }, [getCompetitors, competitorsResp, loadingCompetitors]);

  useEffect(() => {
    if (
      queryVariableBase?.id?.length &&
      competitorsResp?.competitiveSet?.brand
    ) {
      if (
        queryVariableBase.id !==
          competitorsResp.competitiveSet.brand.brandKey &&
        !loadingCompetitors
      ) {
        getCompetitors({
          variables: {
            ...queryVariableBase,
          },
        });
      }
    }
  }, [queryVariableBase, competitorsResp, loadingCompetitors, getCompetitors]);

  const competitorBrands = useMemo(
    () =>
      handleGenericError(
        () => prepareCompetitorDropdownOptions(competitorsResp),
        ' prepareCompetitorDropdownOptions failed transform'
      ),
    [competitorsResp]
  );

  const mainHeroBrand = useMemo(() => {
    return competitorsResp?.competitiveSet?.brand;
  }, [competitorsResp]);

  // main dataset to map brandData to ESOV data
  const brands = useMemo(() => {
    if (!mainHeroBrand || !competitorBrands?.length) {
      return [];
    }

    return [mainHeroBrand].concat(competitorBrands);
  }, [mainHeroBrand, competitorBrands]);
  /* Competitor brands data fetching * handling end */

  // map hero brand data
  if (esovData?.data && heroBrandKeyConfig) {
    const foundBrand = brands?.find(
      (item) => item?.brandKey === heroBrandKeyConfig
    );

    heroBrand = {
      logoUrl: foundBrand?.logoUrl,
      friendlyName: foundBrand?.name,
      metrics: esovData?.data?.[heroBrandKeyConfig],
      esovSetRanking:
        esovData?.data?.[heroBrandKeyConfig]?.excessShareOfVoiceInsight
          ?.esovSetRanking,
      position:
        esovData?.data?.[heroBrandKeyConfig]?.excessShareOfVoiceInsight
          ?.position,
    };
  }

  const formatESOVdata = useCallback(
    (data: ExcessShareOfVoiceData, brandsList: Brand[]) => {
      const brandKeys = Object.keys(esovData?.data ?? {});

      // filter out non brandkey properties
      // and map brand metadata
      const mappedBrands = brandKeys
        .filter((item) => item !== 'last_session_date')
        .map((key) => {
          const brandMetadata = brandsList.find(
            (item) => item?.brandKey === key
          );
          const brandData = data?.data?.[key];

          if (brandMetadata && brandData) {
            return {
              brand: brandMetadata?.name ?? '',
              brandKey: brandMetadata?.brandKey ?? '',
              logoUrl: brandMetadata?.logoUrl ?? '',
              shareOfVoice: brandData.shareOfVoice ?? 0,
              shareOfMarket: brandData.shareOfMarket ?? 0,
              excessShareOfVoice: brandData.excessShareOfVoice ?? 0,
            };
          }

          return {};
        });

      return mappedBrands;
    },
    [esovData]
  );

  const transformedData = useMemo(() => {
    return formatESOVdata(esovData, brands);
  }, [esovData, brands, formatESOVdata]);

  const sortData = useCallback((data: FormattedESOVdata[]) => {
    const clonedData = [...data];

    clonedData.sort(
      (a, b) => (a.excessShareOfVoice ?? 0) - (b.excessShareOfVoice ?? 0)
    );
    return clonedData;
  }, []);

  const sortedData = useMemo(() => {
    return sortData(transformedData as FormattedESOVdata[]);
  }, [transformedData, sortData]);

  const renderInsightsBlurb = (brandData: HeroBrand) => {
    let text;
    const { position, metrics, friendlyName, esovSetRanking } = brandData;

    const { shareOfMarket, spendMultiple, direction } =
      // eslint-disable-next-line no-unsafe-optional-chaining
      metrics?.excessShareOfVoiceInsight;

    const spendComparison = direction === 'negative' ? 'less' : 'more';

    let esovPositionText = 'a strategy poised to further accelerate your';
    if (esovSetRanking === ESOVPosition.MIDDLE) {
      esovPositionText = 'positioning it for steady';
    }
    if (esovSetRanking === ESOVPosition.LAGGING) {
      esovPositionText = 'potentially hindering its';
    }

    if (position === ESOVPosition.LEADER) {
      text = `${friendlyName} leads the pack in paid media.
      ${friendlyName} secures a remarkable ${abbreviateNumber(
        shareOfMarket
      )}% of revenue share in the cohort, spending
      ${abbreviateNumber(spendMultiple)}x ${spendComparison}
      than the cohort average, ${esovPositionText} revenue share growth.`;
    }

    if (position === ESOVPosition.MIDDLE) {
      text = `${friendlyName} holds a competitive position in paid media compared to the rest of the cohort.
      Holding ${abbreviateNumber(
        shareOfMarket
      )}% of revenue share in the cohort, ${friendlyName} employs a spending strategy close to the
       cohort average, ${esovPositionText} revenue share growth.`;
    }

    if (position === ESOVPosition.LAGGING) {
      text = `${friendlyName} trails behind in paid media within the competitive set.
      Despite a ${abbreviateNumber(
        shareOfMarket
      )}% revenue share in the cohort, ${friendlyName} spends ${abbreviateNumber(
        spendMultiple
      )}x ${spendComparison}
      the cohort average, ${esovPositionText} revenue share growth.`;
    }

    return text;
  };

  // upon saving a new chart type from settings, update the active chart type
  useEffect(() => {
    setActiveChartType(activeChart);
  }, [activeChart]);

  const didError = error || esovData?.error;
  const extractedDate = esovData?.data?.last_session_date?.toString();
  const marketShareImpactHoverText = `ESOV of ${abbreviateNumber(
    heroBrand?.metrics?.excessShareOfVoice
  )}% leads to an estimated market share growth of ${abbreviateNumber(
    heroBrand?.metrics?.excessShareOfVoiceImpact
  )}% per year.`;

  // for comp sets with over 8 brands, use a smaller insights section
  const shouldUseSmallInsightsSection = transformedData?.length > 8;

  return (
    <>
      {!didError && (
        <WidgetSubheader
          brandName={heroBrand?.friendlyName}
          brandLogoUrl={heroBrand?.logoUrl}
          customIcons={CHART_ICONS}
          defaultChartType={activeChart}
          chartHandler={setActiveChartType}
          lastUpdatedDate={dayjs(extractedDate).format('MM/DD/YYYY')}
        />
      )}

      {didError ? (
        // TODO: create a generic error component
        <div>Something went wrong.</div>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {!esovData?.loading ? (
            <div className={styles.Container}>
              <div
                className={
                  shouldUseSmallInsightsSection
                    ? styles.ChartSectionLarge
                    : styles.ChartSection
                }
              >
                {isBarChart && (
                  <div className={styles.ChartLegend}>
                    <span className={styles.Row}>
                      <div className={styles.SquareMarket} />
                      Share of Market
                    </span>

                    <span className={styles.Row}>
                      <div className={styles.SquareVoice} />
                      Share of Voice
                    </span>
                  </div>
                )}

                <div className={styles.Chart}>
                  {isBarChart && (
                    <ExcessShareOfVoiceBarChart data={transformedData} />
                  )}

                  {activeChartType === ChartType.Grid && (
                    <ExcessShareOfVoiceGrid data={sortedData} />
                  )}

                  {activeChartType === ChartType.Line && (
                    <div className={styles.LineChart}>
                      <div className={styles.SOVrow}>
                        <div className={styles.TitleContainer}>
                          <div className={styles.SOVTitle}>Share of Voice</div>
                          <div className={styles.SOVSubtitle}>
                            Source calc. for Ad Spend: United States
                          </div>
                        </div>
                      </div>
                      <ExcessShareOfVoiceLineChart data={transformedData} />
                      <div className={styles.SOMTitle}>Share of Market</div>
                      <div className={styles.SOMSubtitle}>
                        Source calc. for Global: Revenue
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={
                  shouldUseSmallInsightsSection
                    ? styles.InsightsSectionSmall
                    : styles.InsightsSection
                }
              >
                <div className={styles.MarketShare}>
                  <span className={styles.MarketShareImpactTitle}>
                    <p className={styles.Text}>Estimated Market Share Impact</p>
                    <IconWithPopup
                      text={marketShareImpactHoverText}
                      icon={<IconInfoCircleSolid className={styles.InfoIcon} />}
                    />
                  </span>

                  <ChangeDisplayIcon
                    value={heroBrand?.metrics?.excessShareOfVoiceImpact || 0}
                    suffix="%"
                  />
                  <div className={styles.DifferenceArrow} />

                  {heroBrand?.position === ESOVPosition.LEADER && (
                    <span className={styles.InsightInfoTitle}>
                      {`${heroBrand?.friendlyName} spending dominates paid
                    media`}
                    </span>
                  )}
                  <p className={styles.BlurbText}>
                    {heroBrand?.metrics
                      ? renderInsightsBlurb(heroBrand as HeroBrand)
                      : null}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <LoadingWidget widgetType={WidgetType.ExcessShareOfVoiceV1} />
          )}
        </>
      )}
    </>
  );
};

export default ExcessShareOfVoice;
