import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconCircle from '../../../assets/icons/IconCircle.jsx';
import { CheckInCircleIcon } from '../../../assets/icons/index.jsx';

import styles from './CheckCircle.module.scss';

export default function CheckCircle({ className, selected, ...props }) {
  return (
    <div className={classNames(styles.CheckCircle, className)} {...props}>
      {selected ? (
        <CheckInCircleIcon className={classNames(styles.Icon, styles.Check)} />
      ) : (
        <IconCircle className={classNames(styles.Icon, styles.Circle)} />
      )}
    </div>
  );
}

CheckCircle.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
};
