import forEach from 'lodash/forEach';
import cloneDeep from 'lodash/cloneDeep';
import keyBy from 'lodash/keyBy';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';

import { isBetween } from '../../../utils/timeframe';
import { ARCHETYPES_RESP } from '../../../utils/__stubs__/archetypes';

const archetypesByName = keyBy(ARCHETYPES_RESP, 'name');

const LOWEST_POINT_CAP = -1;

export function prepareArchetypeTimelineData(data, timeframe) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.brandPersonality ||
    !data.competitiveSet.session.brandPersonality.archetypeTrend ||
    !data.competitiveSet.session.brandPersonality.archetypeTrend[0]
  ) {
    return { points: [] };
  }

  const archetypeTrendByTimeframe =
    data.competitiveSet.session.brandPersonality.archetypeTrend[0];

  const archetypeScoresOverTime = JSON.parse(
    archetypeTrendByTimeframe.t4Quarters
  );

  return {
    id: archetypeScoresOverTime[0].variableId,
    name: archetypeScoresOverTime[0].name,
    points: archetypeScoresOverTime
      .filter((score) => isBetween(score.date, timeframe))
      .map((score) => ({
        y: score.original_value,
        x: dayjs(score.date).startOf('day').valueOf(),
      })),
  };
}

export function prepareCurrentArchetypeData(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.brandPersonality ||
    !data.competitiveSet.session.brandPersonality.personality
  ) {
    return [];
  }

  try {
    const personalityData = JSON.parse(
      data.competitiveSet.session.brandPersonality.personality
    );

    if (
      !personalityData ||
      !personalityData.heroBrand ||
      !personalityData.heroBrand.both
    ) {
      return [];
    }

    const customerBrand = getChartPoints(
      data.competitiveSet.brand.id,
      personalityData.heroBrand.both
    );

    customerBrand.points = scaleChart(customerBrand.points);

    return [customerBrand];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export function prepareGoalArchetypeTimelineData(data, timeframe) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.brandPersonality ||
    !data.competitiveSet.session.brandPersonality.goal ||
    !data.competitiveSet.session.brandPersonality.goal.goalTrend ||
    !data.competitiveSet.session.brandPersonality.goal.goalTrend.t4Quarters
  ) {
    return;
  }

  const { goal } = data.competitiveSet.session.brandPersonality;

  const goalTrend = JSON.parse(goal.goalTrend.t4Quarters);

  return {
    id: goal.variableId,
    name: goal.name,
    points: goalTrend
      .filter((score) => isBetween(score.date, timeframe))
      .map((score) => ({
        y: score.original_value,
        x: dayjs(score.date).startOf('day').valueOf(),
      })),
  };
}

export function prepareArchetypeGoalData(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.brandPersonality ||
    !data.competitiveSet.session.brandPersonality.goal
  ) {
    return {};
  }

  const { goal } = data.competitiveSet.session.brandPersonality;

  return {
    id: goal.id,
    name: goal.name,
  };
}

export function prepareCompetitiveSetData(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.brandPersonality ||
    !data.competitiveSet.session.brandPersonality.personality
  ) {
    return [];
  }

  try {
    const personalityData = JSON.parse(
      data.competitiveSet.session.brandPersonality.personality
    );

    if (
      !personalityData ||
      !personalityData.heroBrand ||
      !personalityData.heroBrand.both ||
      !personalityData.averages ||
      !personalityData.averages.both
    ) {
      return [];
    }

    const customerBrand = getChartPoints(
      data.competitiveSet.brand.id,
      personalityData.heroBrand.both
    );

    const competitorBrand = getChartPoints(
      data.competitiveSet.brand.id + 1,
      personalityData.averages.both
    );

    customerBrand.points = scaleChart(customerBrand.points);
    competitorBrand.points = scaleChart(competitorBrand.points);

    return [customerBrand, competitorBrand];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export function prepareArchetypesData(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.brandPersonality ||
    !data.competitiveSet.session.brandPersonality.archetypes
  ) {
    return [];
  }

  try {
    const result = [];

    const { archetypes } = data.competitiveSet.session.brandPersonality;

    archetypes.forEach((archetype) => {
      result.push({
        id: archetype.id,
        cardImgUrl: archetype.cardImgUrl,
        message: archetype.description,
        exampleBrands: JSON.parse(archetype.exampleBrands),
        name: archetype.name,
        voiceTags: JSON.parse(archetype.voice),
        associatedTypeId: archetype.variableId,
      });
    });

    return result;
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
}

function getChartPoints(id, chartData) {
  const result = {
    id,
    points: [],
  };

  forEach(chartData, (value, key) => {
    result.points.push({
      name: archetypesByName[capitalize(key)].name,
      archetypeId: archetypesByName[capitalize(key)].id,
      score: value,
    });
  });

  result.points = sortBy(result.points, ['score']);

  forEach(result.points, (point, index) => {
    result.points[index].order = index;
  });

  return result;
}

function scaleChart(points) {
  const newPoints = sortBy(cloneDeep(points), ['score']);

  if (newPoints[0].score < LOWEST_POINT_CAP) {
    newPoints[0].score = LOWEST_POINT_CAP;
  }

  const lowestPoint = newPoints[0].score;
  const highestPoint = newPoints[newPoints.length - 1].score;

  if (Math.abs(lowestPoint) > highestPoint) {
    const multiplier = Math.abs(highestPoint / lowestPoint);

    forEach(newPoints, (point, index) => {
      if (point.score < 0) {
        newPoints[index].score = point.score * multiplier;
      }
    });
  }

  return newPoints;
}
