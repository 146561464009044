import { GenericIconProps } from '../types';

const CopyIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 19.65H6C5.08873 19.65 4.35 18.9113 4.35 18V6C4.35 5.08873 5.08873 4.35 6 4.35H15.0522C15.5471 4.35 16.0159 4.57213 16.3293 4.95516L17.7291 6.66608L19.277 8.55797C19.5182 8.85275 19.65 9.22203 19.65 9.60295V17.9999C19.65 18.9112 18.9112 19.65 18 19.65Z"
      stroke="#7E9397"
      strokeWidth="0.7"
    />
    <path
      d="M7.90554 4.35H15.2055V8.22222C15.2055 9.13349 14.4668 9.87222 13.5555 9.87222H9.55554C8.64427 9.87222 7.90554 9.13349 7.90554 8.22222V4.35Z"
      stroke="#7E9397"
      strokeWidth="0.7"
    />
    <path
      d="M7.01663 19.472H16.9833V14.7109C16.9833 13.7996 16.2446 13.0609 15.3333 13.0609H8.66663C7.75536 13.0609 7.01663 13.7996 7.01663 14.7109V19.472Z"
      stroke="#7E9397"
      strokeWidth="0.7"
    />
    <rect
      x="12.35"
      y="6.12783"
      width="1.07778"
      height="1.96667"
      stroke="#7E9397"
      strokeWidth="0.7"
    />
    <rect
      x="9.5556"
      y="14.889"
      width="4.88889"
      height="0.444444"
      rx="0.222222"
      stroke="#7E9397"
      strokeWidth="0.444444"
    />
    <rect
      x="9.5556"
      y="16.6668"
      width="4.88889"
      height="0.444444"
      rx="0.222222"
      stroke="#7E9397"
      strokeWidth="0.444444"
    />
  </svg>
);

export default CopyIcon;
