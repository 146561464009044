import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { SEVERITY } from '../../../constants/props';

import styles from './Alert.module.scss';
import CloseIcon from '../../../assets/icons/CloseIcon';

export default function Alert({
  className,
  severity,
  children,
  onClose,
  ...props
}) {
  return (
    <div
      className={classNames(
        styles.Alert,
        severity === SEVERITY.WARNING && styles.Warning,
        severity === SEVERITY.ERROR && styles.Error, // TODO
        severity === SEVERITY.INFO && styles.Info, // TODO
        severity === SEVERITY.SUCCESS && styles.Success, // TODO
        className
      )}
      {...props}
    >
      <div className={styles.Left}>{children}</div>
      <div className={styles.Right}>
        {onClose && (
          <CloseIcon onClick={onClose} className={styles.CloseIcon} />
        )}
      </div>
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.string,
  severity: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};
