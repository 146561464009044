import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalDrawer from '../../Molecules/ModalDrawer/ModalDrawer';
import SettingsMarkerEventForm from '../SettingsMarkerEventForm/SettingsMarkerEventForm';

import useFeatureFlags from '../../../hooks/useFeatureFlags';

import styles from './SettingsAddMarkerEventModal.module.scss';

export default function SettingsAddMarkerEventModal({
  className,
  open,
  onClose,
  onAddMarkerSuccess,
  defaultValues,
  ...props
}) {
  const FEATURE_FLAGS = useFeatureFlags();

  const [loading, setLoading] = useState(false);
  async function handleSubmit(data) {
    try {
      setLoading(true);
      onAddMarkerSuccess(data);
      onClose();
    } catch (errors) {
      if (errors && errors.length) {
        errors.forEach((error) => {
          // eslint-disable-next-line no-console
          console.error(error.message);
        });
      }

      if (errors) {
        // eslint-disable-next-line no-console
        console.error(errors);
      }
    } finally {
      setLoading(false);
    }
  }

  if (!FEATURE_FLAGS.GLOBAL.MARKERS.ROOT) return null;

  return (
    <ModalDrawer
      title="Add Marker Event"
      open={open}
      onClose={onClose}
      className={classNames(styles.SettingsAddMarkerEventModal, className)}
      {...props}
    >
      <SettingsMarkerEventForm
        onClose={onClose}
        defaultValues={defaultValues}
        className={styles.AddMarker}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </ModalDrawer>
  );
}

SettingsAddMarkerEventModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  onAddMarkerSuccess: PropTypes.func,
};
