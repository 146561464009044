import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalBase from '../ModalBase/ModalBase';

import { AlertTriangleIcon } from '../../../assets/icons';

import styles from './ModalAlert.module.scss';

export default function ModalAlert({
  className,
  title,
  message,
  helper,
  onClose,
  onPrimaryClick,
  Icon = AlertTriangleIcon,
  confirmText = 'Ok',
  ...props
}) {
  return (
    <ModalBase
      title={title}
      className={classNames(styles.ModalAlert, className)}
      onClose={onClose}
      primaryText={confirmText}
      closeX={false}
      // eslint-disable-next-line
      onPrimaryClick={onPrimaryClick ? onPrimaryClick : onClose}
      data-cy="modal-alert"
      {...props}
    >
      <div className={styles.Content}>
        <div className={styles.ColLeft}>
          <Icon className={styles.Icon} />
        </div>

        <div className={styles.ColRight}>
          {message ? <h5>{message}</h5> : null}
          {helper ? <p>{helper}</p> : null}
        </div>
      </div>
    </ModalBase>
  );
}

ModalAlert.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  helper: PropTypes.string,
  onClose: PropTypes.func,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  onPrimaryClick: PropTypes.func,
  Icon: PropTypes.shape({}),
};
