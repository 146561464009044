import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import Tag from '../../Atoms/Tag/Tag';

import { TAGS } from '../../../constants/recommendations';

import styles from './DeselectInput.module.scss';

export default function DeselectInput({
  className,
  onChange,
  value,
  ...props
}) {
  const [deleted, setDeleted] = useState({});

  useEffect(() => {
    if (value !== null) {
      const activeTags = value.split(', ');
      const inactiveTags = [];

      const result = { ...deleted };
      TAGS.forEach((tag) => {
        if (!activeTags.includes(tag.label)) {
          inactiveTags.push(tag);

          result[tag.label] = true;
        }
      });

      setDeleted(result);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    const activeTags = [];

    TAGS.forEach((tag) => {
      if (!deleted[tag.label]) {
        activeTags.push(tag.label);
      }
    });

    onChange(activeTags);

    // eslint-disable-next-line
  }, [deleted]);

  function handleTagClick(tagId) {
    const result = { ...deleted };
    result[tagId] = true;
    setDeleted(result);
  }

  return (
    <Box
      p={1}
      pb={0}
      className={classNames(styles.DeselectInput, className)}
      {...props}
    >
      {TAGS.map((tag, index) => {
        if (!deleted[tag.label]) {
          return (
            <Box
              key={tag.label}
              mr={index !== TAGS.length - 1 ? 1 : 0}
              mb={1}
              onClick={() => handleTagClick(tag.label)}
            >
              <Tag label={tag.label} deletable />
            </Box>
          );
        }

        return null;
      })}
    </Box>
  );
}

DeselectInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};
