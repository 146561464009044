import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ModalDrawer from '../../Molecules/ModalDrawer/ModalDrawer';
import SettingsMarkerEventForm from '../SettingsMarkerEventForm/SettingsMarkerEventForm';

import styles from './SettingsEditMarkerEventModal.module.scss';

export default function SettingsEditMarkerEventModal({
  className,
  open,
  onClose,
  defaultValues,
  onAddMarkerSuccess,
  ...props
}) {
  const [loading, setLoading] = useState(false);

  async function handleSubmit(data) {
    try {
      setLoading(true);

      onAddMarkerSuccess(data);
      onClose();
    } catch (errors) {
      if (errors && errors.length) {
        errors.forEach((error) => {
          // eslint-disable-next-line no-console
          console.error(error.message);
        });
      }

      if (errors) {
        // eslint-disable-next-line no-console
        console.error(errors);
      }
    } finally {
      setLoading(false);
    }
  }
  return (
    <ModalDrawer
      title="Edit Marker Event"
      open={open}
      onClose={onClose}
      className={classNames(styles.SettingsEditMarkerEventModal, className)}
      {...props}
    >
      <SettingsMarkerEventForm
        onClose={onClose}
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </ModalDrawer>
  );
}

SettingsEditMarkerEventModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultValues: PropTypes.shape({}),
  onAddMarkerSuccess: PropTypes.func,
};
