import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Button } from '@blueoceanai/react-component-library';

import DatePickerDualCalendar from '../DatePickerDualCalendar/DatePickerDualCalendar';
import DatePickerPresets from '../DatePickerPresets/DatePickerPresets';
import Alert from '../../Atoms/Alert/Alert';

import { MAX_TIMEFRAME, MIN_TIMEFRAME } from '../../../constants/date-selector';
import { BUTTON_VARIANTS, SEVERITY } from '../../../constants/props';

import styles from './DatePickerDualCalendarWithPresets.module.scss';
import useTimelimits from '../../../hooks/useTimelimits';

const DATE_PICKER_VALIDATION_ERROR_MSG = {
  MIN: 'Data range falls outside of minimum date boundary. We suggest this range instead.',
  MAX: 'Data range falls outside of maximum date boundary. We suggest this range instead.',
  SHORT: 'Data range too short. We suggest this range instead.',
};

export default function DatePickerDualCalendarWithPresets({
  className = '',
  onApply,
  value,
  onChange,
  calendarLeftProps = {},
  calendarRightProps = {},
  minDate,
  maxDate,
  highlightedDates,
  ...props
}) {
  const { disableApply } = useTimelimits();

  const [alert, setAlert] = useState({
    open: false,
    message: null,
    severity: null,
  });

  function validateChange(timeframe) {
    // eslint-disable-next-line prefer-destructuring
    let quantity = timeframe.quantity;

    // new timeframe is less than 14 days from min start date
    const minEndDate = dayjs(minDate)
      .endOf('day')
      .add(MIN_TIMEFRAME.QUANTITY, MIN_TIMEFRAME.UNIT);

    if (dayjs(timeframe.end).isBefore(minEndDate, 'day')) {
      setAlert({
        open: true,
        message: DATE_PICKER_VALIDATION_ERROR_MSG.MIN,
        severity: SEVERITY.WARNING,
      });

      onChange({
        end: minEndDate.toDate(),
        unit: MIN_TIMEFRAME.UNIT,
        quantity: MIN_TIMEFRAME.QUANTITY,
      });
      return;
    }

    // new timeframe quantity is less than min timeframe quantity
    if (quantity < MIN_TIMEFRAME.QUANTITY) {
      quantity = MIN_TIMEFRAME.QUANTITY;

      setAlert({
        open: true,
        message: DATE_PICKER_VALIDATION_ERROR_MSG.SHORT,
        severity: SEVERITY.WARNING,
      });

      onChange({ ...timeframe, quantity });
      return;
    }

    // new timeframe start date is before min (4q before today)
    const minDateQuantity = minDate
      ? dayjs(timeframe.end).diff(minDate, 'days')
      : value.quantity;

    if (minDateQuantity < timeframe.quantity) {
      quantity = minDateQuantity;

      setAlert({
        open: true,
        message: DATE_PICKER_VALIDATION_ERROR_MSG.MIN,
        severity: SEVERITY.WARNING,
      });

      onChange({ ...timeframe, quantity });
      return;
    }

    // new timeframe end date is after max (today)
    if (dayjs(timeframe.end).isAfter(maxDate, 'day')) {
      setAlert({
        open: true,
        message: DATE_PICKER_VALIDATION_ERROR_MSG.MAX,
        severity: SEVERITY.WARNING,
      });

      onChange({ ...timeframe, end: maxDate, quantity });
      return;
    }

    // if new timeframe quantity is greater than a year/rolling 4 quarters
    if (timeframe.quantity > MAX_TIMEFRAME.QUANTITY) {
      setAlert({
        open: true,
        message: DATE_PICKER_VALIDATION_ERROR_MSG.MAX,
        severity: SEVERITY.WARNING,
      });

      onChange({
        ...timeframe,
        end: maxDate,
        quantity: MAX_TIMEFRAME.QUANTITY,
      });
      return;
    }

    // new timeframe passes all validation without issue
    onChange({ ...timeframe, quantity });
  }

  function handleAlertClose() {
    setAlert({
      open: false,
      message: null,
      severity: null,
    });
  }

  return (
    <div
      className={classNames(
        styles.DatePickerDualCalendarWithPresets,
        className
      )}
      {...props}
    >
      <div className={styles.ColLeft}>
        <DatePickerPresets
          className={styles.Presets}
          value={value}
          onChange={validateChange}
          minDate={minDate}
          selectedDate={value.end}
        />
      </div>

      <div className={styles.ColRight}>
        {alert.open && (
          <Alert
            className={styles.Alert}
            severity={alert.severity}
            onClose={handleAlertClose}
          >
            {alert.message}
          </Alert>
        )}

        <DatePickerDualCalendar
          className={styles.Calendars}
          value={value}
          onChange={validateChange}
          calendarLeftProps={calendarLeftProps}
          calendarRightProps={calendarRightProps}
          highlightedDates={highlightedDates}
          minDate={minDate}
          maxDate={maxDate}
        />

        <div className={styles.ActionContainer}>
          <Button
            onClick={() => onApply(value)}
            variant={BUTTON_VARIANTS.PRIMARY}
            disabled={disableApply(value)}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

DatePickerDualCalendarWithPresets.propTypes = {
  className: PropTypes.string,
  onApply: PropTypes.func,
  onChange: PropTypes.func,
  calendarLeftProps: PropTypes.shape({}),
  calendarRightProps: PropTypes.shape({}),
  value: PropTypes.shape({
    quantity: PropTypes.number,
    unit: PropTypes.string,
    end: PropTypes.instanceOf(Date),
  }),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  highlightedDates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date),
      variant: PropTypes.string,
    })
  ),
};
