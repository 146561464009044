import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrandLogo } from '@blueoceanai/react-component-library';

import VerbatimTag from '../../Atoms/VerbatimTag/VerbatimTag';

import { SIZES } from '../../../constants/props';

import styles from './VerbatimWide.module.scss';

let escapeRegExp;
function escapeRegExpChars() {
  // From: https://codereview.stackexchange.com/questions/121342/regex-to-match-strings-safely
  // Referencing this table: https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/regexp
  // the following characters should be escaped
  // \ ^ $ * + ? . ( ) | { } [ ]
  // These characters only have special meaning inside of brackets
  // they do not need to be escaped, but they MAY be escaped
  // without any adverse effects:
  // : ! , =

  const specials = [
    // order matters for these
    '-',
    '[',
    ']',
    // order doesn't matter for any of these
    '/',
    '{',
    '}',
    '(',
    ')',
    '*',
    '+',
    '?',
    '.',
    '\\',
    '^',
    '$',
    '|',
  ];

  // Escape every character with '\' even though only some strictly require it when inside []
  // eslint-disable-next-line
  const regex = RegExp('[' + specials.join('\\') + ']', 'g');

  escapeRegExp = (str) => str.replace(regex, '\\$&');
}
export default function VerbatimWide({ text, source, selectedWords, logoUrl }) {
  const [verbatimText, setVerbatimText] = useState('');

  // todo: add logic to parse verbatim data

  useEffect(() => {
    if (!text) {
      return;
    }
    setVerbatimText(text);
    if (selectedWords.length) {
      let newText = '';

      escapeRegExpChars();

      selectedWords.forEach((word) => {
        const escapedWord = escapeRegExp(word);

        // TODO: improved logic or use a library to handle plurals, past tense, etc. 1 possibility is
        // https://stackoverflow.com/questions/15794411/is-there-a-way-to-include-word-variants-past-tense-gerund-when-checking-for-a
        const re = new RegExp(`\\b${escapedWord}(?:s|ing|ed|es|ly)?\\b`, 'gi'); // search for all instances

        newText = text.replace(re, '<mark>$&</mark>');
      });

      setVerbatimText(newText);
    }
  }, [text, selectedWords]);

  if (verbatimText) {
    return (
      <div className={styles.VerbatimWide}>
        <div className={styles.Image}>
          <BrandLogo size={SIZES.SM} src={logoUrl} />
        </div>
        <div className={styles.Content}>
          {/* eslint-disable-next-line */}
          <p dangerouslySetInnerHTML={{ __html: verbatimText }} />
        </div>

        {source && <VerbatimTag className={styles.Tag} source={source} />}
      </div>
    );
  }
}

VerbatimWide.propTypes = {
  text: PropTypes.string,
  logoUrl: PropTypes.string,
  selectedWords: PropTypes.arrayOf(PropTypes.string),
  source: PropTypes.string,
};
