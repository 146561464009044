import { GenericIconProps } from './types';

const InfoCircleIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12.5C2 6.97715 6.47715 2.5 12 2.5C14.6522 2.5 17.1957 3.55357 19.0711 5.42893C20.9464 7.3043 22 9.84784 22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5ZM3 12.5C3 17.4706 7.02944 21.5 12 21.5C14.3869 21.5 16.6761 20.5518 18.364 18.864C20.0518 17.1761 21 14.8869 21 12.5C21 7.52944 16.9706 3.5 12 3.5C7.02944 3.5 3 7.52944 3 12.5ZM12 7.5C12.2455 7.5 12.4496 7.67688 12.4919 7.91012L12.5 8V12H16.5C16.7761 12 17 12.2239 17 12.5C17 12.7455 16.8231 12.9496 16.5899 12.9919L16.5 13H12.5V17C12.5 17.2761 12.2761 17.5 12 17.5C11.7545 17.5 11.5504 17.3231 11.5081 17.0899L11.5 17V13H7.5C7.22386 13 7 12.7761 7 12.5C7 12.2545 7.17688 12.0504 7.41012 12.0081L7.5 12H11.5V8C11.5 7.72386 11.7239 7.5 12 7.5Z"
      fill="#7E9397"
    />
  </svg>
);

export default InfoCircleIcon;
