import React, { useContext, useMemo } from 'react';
import sortBy from 'lodash/sortBy';

import { BrandLogo } from '@blueoceanai/react-component-library';

import TopNavDropdown from '../TopNavDropdown/TopNavDropdown';

import { SIZES } from '../../../constants/props';
import BNContext from '../../../contexts/BNContext';

import styles from './BrandSelector.module.scss';

function BrandSelector() {
  const {
    accountList,
    accountKey,
    competitiveSetID,
    setCompetitiveSetID,
    setAccountKey,
    setAccountType,
  } = useContext(BNContext);

  const options = useMemo(() => {
    if (!accountList || !accountList.length) {
      return [{ value: '', label: 'No brand options' }];
    }
    const result = [];

    accountList.forEach((account) => {
      account.competitiveSets.forEach((competitiveSet) => {
        result.push({
          accountKey: account.accountKey,
          value: `${competitiveSet.competitiveSetKey}|${account.accountKey}`,
          compSetKey: competitiveSet.competitiveSetKey,
          label: competitiveSet.friendlyName,
          icon: (
            <BrandLogo src={competitiveSet.brand.logoUrl} size={SIZES.XS} />
          ),
        });
      });
    });
    return sortBy(result, 'label');
  }, [accountList]);

  function handleBrandChange(keyHash) {
    // keyHash = <compSetKey>|<accountKey>
    const khSplit = keyHash.split('|');
    setCompetitiveSetID(khSplit[0]);
    setAccountKey(khSplit[1]);
    setAccountType(
      accountList.filter((a) => a.accountKey === khSplit[1])[0].product_id
    );
  }

  return (
    <div className={styles.BrandSelector}>
      <TopNavDropdown
        value={`${competitiveSetID}|${accountKey}`}
        options={options}
        onClick={handleBrandChange}
      />
    </div>
  );
}

export default BrandSelector;
