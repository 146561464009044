/* eslint-disable quotes */
export const ARCHETYPES_RESP = [
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/caregiver@2x.jpg',
    message:
      'When we treat each other with honesty and friendliness we can live together in harmony.',
    exampleBrands: [
      {
        name: 'Unicef',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/unicef.png',
      },
      {
        name: 'WWF',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/wwf.png',
      },
      {
        name: 'Toms',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/toms.png',
      },
    ],
    name: 'Caregiver',
    voiceTags: ['Optimistic', 'Honest', 'Humble'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/hero@2x.jpg',
    message:
      'Heroes want to leave a positive mark on the world and do so through courageous, bold and inspirational acts.',
    exampleBrands: [
      {
        name: 'Adidas',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/adidas.png',
      },
      {
        name: 'Nike',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/nike.png',
      },
      {
        name: 'FedEx',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/fedex.png',
      },
    ],
    name: 'Hero',
    voiceTags: ['Honest', 'Candid', 'Brave'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/Innocent@2x.jpg',
    message:
      "Innocents have pure intentions and find happiness in life's simplest things. They aim to slow things down and appreciate life has to offer.",
    exampleBrands: [
      {
        name: 'Aveeno',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/aveeno.png',
      },
      {
        name: 'WaterWipes',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/waterwipes.jpg',
      },
      {
        name: 'Dove',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/dove.png',
      },
    ],
    name: 'Innocent',
    voiceTags: ['Optimistic', 'Honest', 'Humble'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/ruler@2x.jpg',
    message:
      "Rulers are the leaders, setting the path for others to follow. They've earned their #1 market position and use it to their advantage.",
    exampleBrands: [
      {
        name: 'Louis Vutton',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/louisvuitton.png',
      },
      {
        name: 'Mercedes Benz',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/mercedesbenz.png',
      },
      {
        name: 'Rolex',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/rolex.png',
      },
    ],
    name: 'Ruler',
    voiceTags: ['Commanding', 'Refined', 'Articulate'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/lover@2x.jpg',
    message:
      'Lovers make lasting, visceral connections that are built on intimate moments.',
    exampleBrands: [
      {
        name: 'Victoria Secret',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/victoriasecret.png',
      },
      {
        name: 'Alfa Romeo',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/alfa-romeo.png',
      },
      {
        name: 'Chanel',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/chanel.png',
      },
    ],
    name: 'Lover',
    voiceTags: ['Sensual', 'Empathetic', 'Soothing'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/sage@2x.jpg',
    message:
      "Sages prioritize knowledge and truth. They see what others can't past, present, and future and offer learnings from their deep wisdom.",
    exampleBrands: [
      {
        name: 'Google',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/google.png',
      },
      {
        name: 'BBC',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/bbc.png',
      },
      {
        name: 'TED',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/ted.png',
      },
    ],
    name: 'Sage',
    voiceTags: ['Knowledgable', 'Assured', 'Guiding'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/everyperson@2x.jpg',
    message:
      "Everypersons believe we're stronger together and, true to their supportive and dependable nature, strive for fairness and equality for all.",
    exampleBrands: [
      {
        name: 'IKEA',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/ikea.png',
      },
      {
        name: 'Target',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/target.png',
      },
      {
        name: 'Lynx',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/lynx.png',
      },
    ],
    name: 'Everyperson',
    voiceTags: ['Friendly', 'Humble', 'Authentic'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/creator@2x.jpg',
    message:
      'Creators fuse art and science to create visionary, meaningful and beautiful products and experiences.',
    exampleBrands: [
      {
        name: 'Lego',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/lego.png',
      },
      {
        name: 'Apple',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/apple.png',
      },
      {
        name: 'Adobe',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/adobe.png',
      },
    ],
    name: 'Creator',
    voiceTags: ['Inspirational', 'Daring', 'Provocative'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/explorer@2x.jpg',
    message:
      'Explorers are driven by adventure and thrive on new experiences. These independent souls want to break free from the ordinary.',
    exampleBrands: [
      {
        name: 'The North Face',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/thenorthface.png',
      },
      {
        name: 'Jeep',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/jeep.png',
      },
      {
        name: 'Patagonia',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/patagonia.png',
      },
    ],
    name: 'Explorer',
    voiceTags: ['Exciting', 'Fearless', 'Daring'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/magician@2x.jpg',
    message:
      'Magicians are the visionaries that inspire change and bring dreams to life.',
    exampleBrands: [
      {
        name: 'Coca Cola',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/coca-cola.png',
      },
      {
        name: 'Dyson',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/dyson.png',
      },
      {
        name: 'Disney',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/disney.png',
      },
    ],
    name: 'Magician',
    voiceTags: ['Mystical', 'Informed', 'Reassuring'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/rebel@2x.jpg',
    message:
      'Rebels are agents of change, breaking conventions and fighting authority. They make an effort to standout, offering an alternative to the mainstream.',
    exampleBrands: [
      {
        name: 'Diesel',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/diesel.png',
      },
      {
        name: 'Virgin',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/virgin.png',
      },
      {
        name: 'Harley Davidson',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/harleydavidson.png',
      },
    ],
    name: 'Rebel',
    voiceTags: ['Disruptive', 'Revellious', 'Combative'],
  },
  {
    cardImgUrl:
      'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/jester@2x.jpg',
    message:
      'Jesters live in the moment, bringing joy to the world with their humor and light-hearted attitude.',
    exampleBrands: [
      {
        name: 'Old Spice',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/oldspice.png',
      },
      {
        name: 'M&Ms',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/m-ms.png',
      },
      {
        name: 'Geico',
        logoUrl:
          'https://dev-proto-logo.s3-us-west-2.amazonaws.com/archetypes/geico.png',
      },
    ],
    name: 'Jester',
    voiceTags: ['Fun Loving', 'Playful', 'Optimistic'],
  },
];
