import { GenericIconProps } from './types';

const CircleIcon = (props: GenericIconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48632 2 2 6.48632 2 12C2 17.5137 6.48632 22 12 22C17.5137 22 22 17.5137 22 12C22 6.48632 17.5137 2 12 2ZM12 21C7.03729 21 3 16.9627 3 12C3 7.03729 7.03729 3 12 3C16.9627 3 21 7.03729 21 12C21 16.9627 16.9627 21 12 21Z"
      fill="currentColor"
    />
  </svg>
);

export default CircleIcon;
