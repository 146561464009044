import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@mui/material/Box';
import { BrandLogo } from '@blueoceanai/react-component-library';

import { SIZES } from '../../../constants/props';

import styles from './DropdownMenuItem.module.scss';

export default function DropdownMenuItem({
  className,
  option,
  showIcons,
  selectedOption,
  onClick,
  ...props
}) {
  return (
    <>
      <MenuItem
        className={classNames(
          styles.MenuItem,
          className,
          selectedOption && option.value === selectedOption.value
            ? styles.Active
            : null,
          option.subItems && option.subItems.length
            ? styles.HasSubOptions
            : null
        )}
        onClick={() => onClick(option)}
        {...props}
      >
        <div className={styles.OptionTitle}>
          {showIcons ? (
            <Box mr={2} className={styles.LogoContainer}>
              <BrandLogo src={option.imgUrl} size={SIZES.XS} />
            </Box>
          ) : null}

          {option.icon && isValidElement(option.icon) ? (
            <Box mr={2}>{option.icon}</Box>
          ) : null}
          <span>{option.label}</span>
        </div>
      </MenuItem>
      {option.subItems && option.subItems.length ? (
        <MenuList className={styles.SubMenuList}>
          {option.subItems.map((subItem) => (
            <DropdownMenuItem
              option={subItem}
              showIcons={showIcons}
              onClick={onClick}
              selectedOption={selectedOption}
              key={subItem.value}
              {...props}
            />
          ))}
        </MenuList>
      ) : null}
    </>
  );
}

DropdownMenuItem.propTypes = {
  className: PropTypes.string,
  option: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        imgUrl: PropTypes.string,
        label: PropTypes.string,
      })
    ),
    imgUrl: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.node,
  }),
  showIcons: PropTypes.bool,
  selectedOption: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imgUrl: PropTypes.string,
    label: PropTypes.string,
  }),
  onClick: PropTypes.func,
};
