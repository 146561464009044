import React from 'react';
import PropTypes from 'prop-types';
import { XYPlot, ArcSeries, LabelSeries } from 'react-vis';
import styles from './ThemesSemiPieChart.module.scss';

export default function ThemesSemiPieChart({
  chartType,
  value,
  competitorAverage,
}) {
  const brandValue =
    chartType === 'sentiment' ? Math.round(value - 100) : Math.round(value);
  const competitorValue =
    chartType === 'sentiment'
      ? Math.round(competitorAverage - 100)
      : Math.round(competitorAverage);
  const displayValue =
    chartType === 'sentiment' ? Math.round(value - 100) : Math.round(value);

  const COLORS = {
    green: '#20c660',
    red: '#d83133',
    blue: '#7fd1e0',
    gray: '#f4f7fa',
  };

  function numberTransform(val) {
    const convertedValue =
      chartType === 'sentiment'
        ? Math.PI * (val / 200)
        : Math.PI * (val / 100) - Math.PI / 2;

    return convertedValue;
  }

  const chartColor =
    chartType === 'sentiment'
      ? brandValue >= 0
        ? COLORS.green
        : COLORS.red
      : COLORS.blue;
  const chartColor2 =
    chartType === 'sentiment'
      ? brandValue >= 0
        ? COLORS.green
        : COLORS.red
      : 'transparent';
  /*
        range: [-1.57, 1.57], [-Math.PI/2, Math.PI/2]
    */
  const data = [
    // background gray
    {
      angle0: (-2 * Math.PI) / 4,
      angle: Math.PI / 2,
      opacity: 1,
      radius: 2,
      radius0: 1.4,
      color: COLORS.gray,
    },
    // foreground color 2 (invisible for volume)
    {
      angle0: brandValue >= 0 ? -2 * 0 : (-2 * Math.PI) / 4,
      angle: brandValue > 0 ? Math.PI / 2 : 0,
      opacity: 0.2,
      radius: 2,
      radius0: 1.4,
      color: chartColor2,
    },
    // foreground color
    {
      angle0: chartType === 'sentiment' ? 0 : (-2 * Math.PI) / 4,
      angle: numberTransform(brandValue),
      opacity: 1,
      radius: 2,
      radius0: 1.4,
      color: chartColor,
    },
    // competitor score pin needle
    {
      angle0: numberTransform(competitorValue),
      angle: numberTransform(competitorValue),
      // angle: -1.0,
      opacity: 1,
      radius: 2.15,
      radius0: 1.4,
      color: '#49545C',
    },
    // competitor score pin dot
    {
      angle0: numberTransform(competitorValue) - 0.035,
      angle: numberTransform(competitorValue) + 0.035,
      // angle: -1.0,
      opacity: 1,
      radius: 2.3,
      radius0: 2.15,
      color: '#49545C',
    },
  ];

  return (
    <XYPlot
      className={styles.ThemesSemiPieChart}
      xDomain={[-10, 10]}
      margin={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 10,
      }}
      yDomain={[0, 0.5]}
      width={150}
      height={60}
    >
      <ArcSeries
        animation
        radiusDomain={[0, 1.2]}
        data={data}
        colorType="literal"
      />
      <LabelSeries
        labelAnchorX="middle"
        labelAnchorY="end"
        data={[
          {
            x: 0,
            y: 0,
            label: `${
              chartType === 'sentiment' && brandValue > 0 ? '+' : ''
            }${displayValue}%`,
          },
        ]}
      />
    </XYPlot>
  );
}

ThemesSemiPieChart.propTypes = {
  chartType: PropTypes.string,
  value: PropTypes.number,
  competitorAverage: PropTypes.number,
};
