/* eslint-disable */
// @ts-nocheck

import { Chart, ChartType, DefaultDataPoint } from 'chart.js';
import colors from '../../../constants/colors';

export const renderPercentages = (
  chart: Chart<ChartType, DefaultDataPoint<ChartType>, unknown>
) => {
  const { ctx } = chart;
  const yAxis = chart.scales.y;
  const xAxis = chart.scales.x;
  const { datasets } = chart.data;

  const highestValues = datasets.slice(0, 2).reduce((highest, dataset) => {
    return dataset.data.map((value, index) =>
      Math.max(value as number, highest[index])
    );
  }, Array(datasets[0].data.length).fill(0));

  ctx.save();
  ctx.font = '400 20px Arial';
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';

  datasets[2].data.forEach((value, index) => {
    const x = xAxis.getPixelForValue(index);
    const yPosition = yAxis.getPixelForValue(highestValues[index]) - 10;

    if (value && value > 0) {
      ctx.fillStyle = colors.colorSemanticSuccess70;
    } else if (value && value < 0) {
      ctx.fillStyle = colors.colorSemanticError70;
    } else {
      ctx.fillStyle = 'black';
    }

    ctx.fillText(`${(value as number)?.toFixed(0)}%`, x, yPosition);

    if (value === highestValues[index]) {
      ctx.beginPath();
      ctx.moveTo(x, yPosition - 5);
      ctx.lineTo(x, yPosition - 15);
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.closePath();
    }
  });

  ctx.restore();
};

// the brand logos were refetching on re-render, using a cache to prevent refetch
const imageCache = {};

export const renderIconsAsLabels = (
  chart: Chart<ChartType, DefaultDataPoint<ChartType>, unknown>
) => {
  const chartData = chart.config.options.labelData.data;

  const labels = chartData.map((item) => item.brand);
  const images = chartData.map((item) => item.logoUrl);

  const { ctx } = chart;
  const xAxis = chart.scales.x;
  const yAxis = chart.scales.y;

  const renderLogoLabels = (
    image: HTMLImageElement,
    x: number,
    index: number
  ) => {
    const desiredSize = 38;
    const extraBottomMargin = 4;
    const labelUnderIconMargin = 8;
    const containerWidth = 80;
    const label = labels[index];
    const maxLabelsLength = 6;

    const shouldTruncateLabels = labels.length > maxLabelsLength;

    ctx.drawImage(
      image,
      x - desiredSize / 2,
      yAxis.bottom + extraBottomMargin,
      desiredSize,
      desiredSize - labelUnderIconMargin
    );

    ctx.save();
    ctx.font = '12px Scansky';
    ctx.fillStyle = colors.colorGray40;
    ctx.textBaseline = 'top';
    ctx.textAlign = 'center';

    if (shouldTruncateLabels || ctx.measureText(label).width > containerWidth) {
      // Truncate the label if it exceeds the container width or if there are more than 6 labels
      let truncatedLabel = '';
      let width = 0;
      const truncateMargin = 16;

      for (const char of label) {
        const charWidth = ctx.measureText(char).width;
        if (width + charWidth <= containerWidth - truncateMargin) {
          // Append the character if it fits within the container width
          truncatedLabel += char;
          width += charWidth;
        } else {
          // Break the loop if adding the character would exceed the width
          truncatedLabel += '...';
          break;
        }
      }

      ctx.fillText(truncatedLabel, x, yAxis.bottom + desiredSize);
    } else {
      // If the text fits within the container, draw it as is
      ctx.fillText(label, x, yAxis.bottom + desiredSize);
    }

    ctx.restore();
  };

  xAxis.ticks.forEach((_, index) => {
    const x = xAxis.getPixelForTick(index);
    const imageUrl = images[index];

    if (imageCache[imageUrl]) {
      renderLogoLabels(imageCache[imageUrl], x, index);
    } else {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
        imageCache[imageUrl] = image;
        renderLogoLabels(image, x, index);
      };
    }
  });
};
