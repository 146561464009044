import { Box, TooltipProps } from '@mui/material';
import Tooltip from '../../../Tooltips/Tooltip';
import styles from './WidgetTooltip.module.scss';

export type WidgetTooltipProps = {
  title?: string;
  description?: string;
  isBaselineTitle?: boolean;
} & Pick<TooltipProps, 'children' | 'placement'>;

const WidgetTooltip = ({
  title,
  description,
  children,
  placement = 'bottom-start',
  isBaselineTitle = false,
}: WidgetTooltipProps) => {
  return (
    <Tooltip
      classes={{
        tooltip: styles.Tooltip,
        arrow: styles.TooltipArrow,
      }}
      arrow
      placement={placement}
      title={
        <Box>
          <p className={styles.TooltipDescription}>
            <span
              className={
                isBaselineTitle
                  ? styles.TooltipTitleBaseline
                  : styles.TooltipTitle
              }
            >
              {title && title}
              {isBaselineTitle && ': '}
            </span>
            {description && description}
          </p>
        </Box>
      }
    >
      {children}
    </Tooltip>
  );
};

export default WidgetTooltip;
