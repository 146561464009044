import { AnchorHTMLAttributes } from 'react';
import { WidgetType } from '../../../../../interfaces/dashboard-api';
import styles from './LoadingWidget.module.scss';

export interface LoadingWidgetProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  widgetType: WidgetType;
}

const SingleMetricLoader = (
  <div className={styles.SingleMetricWidget}>
    <div className={styles.Header}>
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
    </div>
    <div className={styles.ChartContainer}>
      <div className={styles.Chart} />
    </div>
  </div>
);

const GroupMetricLoader = (
  <div className={styles.GroupMetricWidget}>
    <div className={styles.Header}>
      <div className={styles.MetricSquare} />
    </div>
    <div className={styles.ChartContainer}>
      <div className={styles.Row} />
      <div className={styles.Row} />
      <div className={styles.Row} />
    </div>
  </div>
);

const ESOVLoader = (
  <div className={styles.ESOV}>
    <div className={styles.Header}>
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
    </div>
    <div className={styles.ChartContainer}>
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
    </div>
  </div>
);

const SummaryTrendsLoader = (
  <div className={styles.SummaryTrendsWidget}>
    <div className={styles.Header}>
      <div className={styles.MetricSquare} />
      <div className={styles.MetricSquare} />
    </div>
    <div className={styles.Row} />
    <div className={styles.Row} />
    <div className={styles.Row} />
    <div className={styles.Row} />
    <div className={styles.Row} />
    <div className={styles.Row} />
  </div>
);

const VisualMapLoader = (
  <div className={styles.VisualMapWidget}>
    <div className={styles.WidgetBody}>
      <div className={styles.Column}>
        <div className={styles.Row} />
        <div className={styles.Row} />
        <div className={styles.Row} />
        <div className={styles.Row} />
      </div>
      <div className={styles.Column}>
        <div className={styles.Row} />
        <div className={styles.Row} />
        <div className={styles.Row} />
        <div className={styles.Row} />
      </div>
    </div>
  </div>
);

const GenericLoader = <div className={styles.Chart} />;

const widgetLoadingMap: Record<WidgetType, JSX.Element> = {
  [WidgetType.MetricAnalysisV1]: SingleMetricLoader,
  [WidgetType.MetricCollectionV1]: GroupMetricLoader,
  [WidgetType.ExcessShareOfVoiceV1]: ESOVLoader,
  [WidgetType.PaceAnalysisV1]: GenericLoader,
  [WidgetType.MetricComparisonV1]: GenericLoader,
  [WidgetType.SummaryTrendsV1]: SummaryTrendsLoader,
  [WidgetType.StackRankingV1]: GenericLoader,
  [WidgetType.VisualMapsV1]: VisualMapLoader,
};

const LoadingWidget = ({ widgetType }: LoadingWidgetProps) => {
  const activeWidget = widgetLoadingMap[widgetType] ?? GenericLoader;
  return <div className={styles.LoadingWidget}>{activeWidget}</div>;
};

export default LoadingWidget;
