import { ChartTooltipContextLine } from '../../../interfaces';
import { getOrCreateTooltip } from '../../../utils/chartjs';
import { positionLeft, positionTop } from '../helpers';
import styles from './genericWidgetTooltip.module.scss';

const genericWidgetTooltip = (
  context: ChartTooltipContextLine,
  content: HTMLDivElement
) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, {
    wrapper: styles.WidgetTooltipContainer,
    contents: styles.TooltipContents,
  });

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return null;
  }

  if (tooltip.body.length) {
    const tooltipContents = tooltipEl.querySelector(
      `.${styles.TooltipContents}`
    );

    while (tooltipContents?.firstChild) {
      tooltipContents.firstChild.remove();
    }

    tooltipContents?.appendChild(content);

    // Display, position, and set styles for font
    const buffer = 0.35;
    const tooltipIsClipped = tooltip.x >= chart.width * buffer;

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    tooltipEl.style.top = positionTop(
      positionY,
      tooltip.caretY,
      tooltipEl.clientHeight
    );
    tooltipEl.style.left = positionLeft(
      positionX,
      tooltip.caretX,
      tooltipEl.clientHeight,
      false,
      tooltipEl.clientWidth
    );

    if (tooltipIsClipped) {
      tooltipEl.style.left = `${
        positionX + tooltip.caretX - tooltipEl.offsetWidth
      }px`;
    }

    tooltipEl.style.opacity = '1';

    return null;
  }

  return null;
};

export default genericWidgetTooltip;
