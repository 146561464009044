import React from 'react';
import PropTypes from 'prop-types';
import { StyledAppBar } from './MuiStyleOverrideHOCs';

export default function SectionHeaderBar({ children, ...props }) {
  return <StyledAppBar {...props}>{children}</StyledAppBar>;
}

SectionHeaderBar.propTypes = {
  children: PropTypes.node,
};
