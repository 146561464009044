// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC } from 'react';
import { Chart, registerables, ChartOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ChartPointFormatted } from '../../../interfaces/chart';

import styles from './CreativeMiniLineChart.module.scss';
import colors from '../../../constants/colors';

Chart.register(...registerables);
export interface CreativeMiniLineChartProps {
  data: Array<ChartPointFormatted> | Array<Array<ChartPointFormatted>>;
  showXAxis?: boolean;
  options?: ChartOptions;
  fill?: boolean;
}

const CreativeMiniLineChart: FC<CreativeMiniLineChartProps> = ({
  data,
  showXAxis,
  options,
  fill,
}) => {
  const combinedOptions = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: { radius: 0, hoverRadius: 0 },
    },
    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          minRotation: 0,
          maxRotation: 0,
          padding: 0,
          align: 'inner',
          maxTicksLimit: 2,
          display: !!showXAxis,
          source: 'data',
          font: {
            size: 12,
            style: 'normal',
          },
        },
        type: 'time',
        time: {
          stepSize: 60,
          unit: 'day',
          displayFormats: {
            day: 'MM/DD/YY',
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    ...options,
  };

  const twoLineData = {
    datasets: [
      {
        data: data[0],
        fill,
        showLine: true,
        tension: showXAxis ? 0.5 : 0,
        borderWidth: 2,
        pointRadius: 0,
        backgroundColor: colors.chartCreativeMiniLine,
        borderColor: 'rgba(39, 129, 155, 1)',
      },
      {
        data: data[1],
        fill: false,
        showLine: true,
        tension: showXAxis ? 0.5 : 0,
        borderWidth: 1,
        pointRadius: 0,
        backgroundColor: colors.chartCreativeMiniLine,
        borderColor: '#B3BDC4',
      },
    ],
  };

  const singleData = {
    datasets: [
      {
        data,
        fill,
        showLine: true,
        tension: showXAxis ? 0.5 : 0,
        borderWidth: 2,
        pointRadius: 0,
        backgroundColor: colors.chartCreativeMiniLine,
        borderColor: 'rgba(39, 129, 155, 1)',
      },
    ],
  };

  const chartData = data.length === 2 ? twoLineData : singleData;

  return (
    <div className={styles.ChartContainer} data-cy="mini-line-chart">
      <Line
        redraw
        id="line"
        data={chartData}
        options={combinedOptions as ChartOptions<'line'>}
      />
    </div>
  );
};

export default CreativeMiniLineChart;
