import { gql } from '@apollo/client';

export const GET_TOPICS = gql`
  query getTopics($id: ID, $sessionKey: ID, $accountKey: ID, $type: Int) {
    competitiveSet(id: $id) {
      competitiveSetKey
      brand {
        id
        name
        brandKey
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        id
        brandId
        topics {
          ...getTopics
        }
        competitors {
          brandKey
          name
          id
          logoUrl
          topics {
            ...getTopics
          }
        }
        recommendations(accountKey: $accountKey, type: $type) {
          id
          name
          value
          type
          impact_id
          sourceType_id
          isComplete
          practice_areas
          createdDate
        }
      }
    }
  }
  fragment getTopics on Topic {
    id
    text
  }
`;

export const GET_ALL_SESSION_TOPICS = gql`
  query getTopicsBetweenSessions($competitiveSetKey: ID, $accountKey: ID) {
    user {
      account(accountKey: $accountKey) {
        id
        competitiveSet(competitiveSetKey: $competitiveSetKey) {
          brand {
            id
            name
            brandKey
            logoUrl
          }
          friendlyName
          competitiveSetKey
          sessions {
            sessionKey
            sessionDate
            topics {
              text
              id
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_COMPETITOR_TOPICS = gql`
  query getSessionsInRange($id: ID, $sessionKey: ID, $lookbackDays: Int) {
    competitiveSet(id: $id) {
      id
      friendlyName
      sessionsInRange(sessionKey: $sessionKey, lookbackDays: $lookbackDays) {
        sessionKey
        sessionDate
        topics {
          id
          text
        }
        competitors {
          logoUrl
          name
          brandKey
          topics {
            id
            text
          }
        }
      }
    }
  }
`;
