import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './VerbatimTag.module.scss';

export default function VerbatimTag({ className, source, ...props }) {
  return (
    <div className={classNames(styles.VerbatimTag, className)} {...props}>
      {source}
    </div>
  );
}

VerbatimTag.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string,
};
