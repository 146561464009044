import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import styles from './ComponentLoader.module.scss';

export default function ComponentLoader({
  className = '',
  minHeight = 100,
  loaderSize = 24,
  ...props
}) {
  const [loaderVisibile, setLoaderVisibile] = useState(false);

  useEffect(() => {
    const displayLoader = setTimeout(() => {
      setLoaderVisibile(true);
    }, 0);

    return function cleanup() {
      clearTimeout(displayLoader);
    };
  }, []);

  return (
    <div
      style={{ minHeight, visibility: loaderVisibile ? 'visible' : 'hidden' }}
      className={classNames(styles.ComponentLoader, className)}
      {...props}
    >
      <CircularProgress style={{ height: loaderSize, width: loaderSize }} />
    </div>
  );
}

ComponentLoader.propTypes = {
  className: PropTypes.string,
  minHeight: PropTypes.number,
  loaderSize: PropTypes.number,
};
