import {
  ChangeEvent,
  AnchorHTMLAttributes,
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import find from 'lodash/find';
import classNames from 'classnames';
import { SingleValue } from 'react-select';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../../api/queries/DataManager';
import { GET_COMPETITORS } from '../../../../../../api/queries/Pages/MarketIndexCompare';
import DropdownSelect, {
  DropdownOption,
} from '../../../../Creative/DropdownSelect/DropdownSelect';
import RivalBrandsForm from '../../../WidgetSettingsGuides/PaceAnalysisSettingsGuide/RivalBrandsForm/RivalBrandsForm';

import useBrandOptions from '../../../../../../hooks/useBrandOptions';
import useCompetitiveSetOptions from '../../../../../../hooks/useCompetitiveSetOptions';
import {
  ScoreType,
  TimeRange,
} from '../../../../../../interfaces/dashboard-api';

import {
  DateRangeOptions,
  ScoreTypeOptions,
} from '../../../WidgetSettingsShared';
import { BrandDropdownOption } from '../../../types';
import {
  FlowKey,
  WidgetSettingsConfig,
} from '../../../../../../interfaces/widget-settings';
import { FeatureFlag } from '../../../../../../utils/featureFlags';
import DateRangeSelectorDropdown from '../../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';
import styles from './MetricComparisonSettingsForm.module.scss';

export interface MetricComparisonSettingsFormProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  config: WidgetSettingsConfig;
  setMetricAsName: boolean;
  onConfigChange: (config: WidgetSettingsConfig) => void;
}

interface FormFields {
  widgetName: string;
  description: string;
  timeRange: TimeRange;
  endDate: Date;
  brandKey: string;
  competitiveSetKey: string;
  scoreType: string;
  brands: string[];
}

const MetricComparisonSettingsForm: FC<MetricComparisonSettingsFormProps> = ({
  config,
  onConfigChange,
  setMetricAsName,
}) => {
  // we are filtering out the score type options to only show the indexed option
  const scoreTypeOptions = ScoreTypeOptions.filter(
    (item) => item.value === ScoreType.INDEXED
  );

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );
  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    { fetchPolicy: 'no-cache' }
  );

  const {
    widgetName,
    timeRange,
    endDate,
    brandKey,
    description,
    competitiveSetKey,
    scoreType,
    brands,
  } = config[FlowKey.MetricComparisonSettings];

  const metric = config[FlowKey.SelectMetric];

  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  const [inputValues, setInputValues] = useState<FormFields>({
    widgetName: setMetricAsName ? metric.name : widgetName,
    description,
    timeRange,
    endDate,
    competitiveSetKey,
    brandKey,
    scoreType,
    brands,
  });

  const competitiveSetOptions = useCompetitiveSetOptions();
  const brandOptions = useBrandOptions(competitorsResp);

  const selectedCompetitorOption = useMemo(() => {
    if (!inputValues.competitiveSetKey && !competitiveSetOptions.length)
      return null;

    return find(competitiveSetOptions, {
      value: inputValues.competitiveSetKey,
    });
  }, [competitiveSetOptions, inputValues.competitiveSetKey]);

  const selectedTimeRange = useMemo(
    () => DateRangeOptions.find(({ value }) => value === inputValues.timeRange),
    [inputValues.timeRange]
  );

  const selectedScoreType = useMemo(() => {
    const selected = find(scoreTypeOptions, { value: inputValues.scoreType });
    return selected ?? scoreTypeOptions[0];
  }, [inputValues.scoreType, scoreTypeOptions]);

  const handleConfigSave = useCallback(
    (newInputValues: FormFields) => {
      const newConfig = structuredClone(config);
      const metricComparisonConfig =
        newConfig[FlowKey.MetricComparisonSettings];
      newConfig[FlowKey.MetricComparisonSettings] = {
        ...metricComparisonConfig,
        ...newInputValues,
      };

      onConfigChange(newConfig);
    },
    [config, onConfigChange]
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newInputValues = {
      ...inputValues,
      [name]: value,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleDropdownChange = (
    updatedOption: SingleValue<DropdownOption>,
    field: string
  ) => {
    if (!updatedOption || !updatedOption?.value) return;

    const newInputValues = {
      ...inputValues,
      [field]: updatedOption.value,
    };

    if (field === 'competitiveSetKey') {
      newInputValues.brands = [];
    }

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    const newInputValues = {
      ...inputValues,
      timeRange: (e.timeRange as TimeRange) ?? TimeRange['90_DAYS'],
      endDate: e.endDate,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  /* Fetch Data */
  // if no competitiveSetKey, set first as default competitiveSetKey
  useEffect(() => {
    if (inputValues.competitiveSetKey || !competitiveSetOptions.length) return;

    const updatedInputValues = {
      ...inputValues,
      competitiveSetKey: competitiveSetOptions[0].value,
    };
    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  }, [competitiveSetOptions, handleConfigSave, inputValues]);

  // if no brandKey, set first as default brandKey
  useEffect(() => {
    if (
      brandOptions.length &&
      !find(brandOptions, { value: inputValues.brandKey })
    ) {
      const updatedInputValues = {
        ...inputValues,
        brandKey: brandOptions[0].value,
      };
      setInputValues(updatedInputValues);
      handleConfigSave(updatedInputValues);
    }
  }, [brandOptions, handleConfigSave, inputValues]);

  // if no competitiveSet get required sessions
  useEffect(() => {
    if (!selectedCompetitorOption?.value) return;

    getFirstLastSession({ variables: { id: selectedCompetitorOption.value } });
  }, [getFirstLastSession, selectedCompetitorOption]);

  // get cometitiveSet with new SessionKey
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
        ?.sessionKey ||
      !selectedCompetitorOption?.value
    ) {
      return;
    }

    const { sessionKey } =
      firstAndLastSessionResp.competitiveSet.firstAndLastSession[1];
    getCompetitors({
      variables: { id: selectedCompetitorOption.value, sessionKey },
    });
  }, [firstAndLastSessionResp, getCompetitors, selectedCompetitorOption]);

  const handleBrandSelection = (brand: BrandDropdownOption) => {
    const selectedBrands = new Set(Object.values(inputValues.brands));

    if (selectedBrands.has(brand.value)) {
      selectedBrands.delete(brand.value);
    } else {
      selectedBrands.add(brand.value);
    }

    const newInputValues = {
      ...inputValues,
      brands: Array.from(selectedBrands),
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const checkedBrands = new Set(Object.values(inputValues?.brands));

  return (
    <div className={classNames(styles.MetricComparisonSettingsForm)}>
      <p className={styles.Label}>
        WIDGET NAME
        <span className={styles.Required}>*</span>
      </p>

      <input
        className={styles.Field}
        required
        type="text"
        name="widgetName"
        value={inputValues.widgetName}
        onChange={handleInputChange}
      />

      <p className={styles.Label}>SCORE TYPE</p>
      <DropdownSelect
        className={styles.Dropdown}
        value={selectedScoreType}
        options={scoreTypeOptions}
        onChange={(e) => handleDropdownChange(e, 'scoreType')}
      />

      <div className={styles.Group}>
        <p className={styles.Label}>COMPETITIVE SET</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={competitiveSetOptions}
          onChange={(e) => handleDropdownChange(e, 'competitiveSetKey')}
          value={selectedCompetitorOption}
        />
      </div>

      <RivalBrandsForm
        label="SELECT AT LEAST ONE BRAND"
        brands={brandOptions}
        selectedRivals={checkedBrands}
        handleBrandSelect={handleBrandSelection}
        maxSelected={brandOptions.length + 1}
      />

      <p>DATE RANGE</p>
      {showCustomDateRangeSelector ? (
        <DateRangeSelectorDropdown
          onApply={handleApply}
          timeRange={timeRange}
          endDate={endDate}
          fullWidth
        />
      ) : (
        <DropdownSelect
          className={styles.Dropdown}
          options={DateRangeOptions}
          onChange={(e) => handleDropdownChange(e, 'timeRange')}
          value={selectedTimeRange}
        />
      )}
    </div>
  );
};

export default MetricComparisonSettingsForm;
