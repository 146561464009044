import { AnchorHTMLAttributes, useCallback } from 'react';
import cn from 'classnames';
import ChangeIconLabel from '../../../ChangeIconLabel/ChangeIconLabel';
import { SingleMetricWidgetData } from '../../../types';
import styles from './SingleMetricSummary.module.scss';
import { abbreviateNumber } from '../../../../../../utils/number';
import { ScoreType } from '../../../../../../interfaces/metric';

export interface SingleMetricSummaryProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  rawMetric: string;
  data: SingleMetricWidgetData;
  showRaw: boolean;
  showIndexed?: boolean;
  isPercentage?: boolean;
}

const SingleMetricSummary = ({
  rawMetric,
  data,
  showRaw,
  showIndexed = true,
  isPercentage = false,
}: SingleMetricSummaryProps) => {
  const formatMetric = useCallback(
    (num: number, scoreType: ScoreType): string => {
      if (scoreType === ScoreType.Indexed) {
        return `${Math.round(num)}`;
      }

      // TODO: handle RawScoreFormat.Percentage metrics consistently across widgets
      if (scoreType === ScoreType.Raw && isPercentage) {
        return `${Math.round(num * 100)}%`;
      }

      return abbreviateNumber(num);
    },
    [isPercentage]
  );

  return (
    <div className={styles.SingleMetricSummary}>
      {/* Relative / Index Data */}
      {showIndexed && (
        <div
          className={cn(styles.MetricContainer, showRaw ? '' : styles.FlexRow)}
        >
          <div className={styles.MetricSection}>
            <p className={styles.Label}>
              {formatMetric(data.relativeScoreTile, ScoreType.Indexed)}
            </p>
            <ChangeIconLabel
              className={styles.Icon}
              metric={Number(
                formatMetric(data.relativeScoreDelta, ScoreType.Indexed)
              )}
              suffix="pts"
            />
          </div>
          <div
            className={cn(
              styles.MetricSection,
              showRaw ? styles.BlueBorder : ''
            )}
          >
            <p className={styles.SubTitle}>Relative Performance</p>
            <p
              className={cn(
                styles.SubTitle,
                showRaw ? '' : styles.MinimizePadding
              )}
            >
              vs Prev. Period
            </p>
          </div>
        </div>
      )}

      {/* Isolated / Raw Data */}
      {showRaw && (
        <>
          <div className={styles.MetricContainer}>
            <div className={styles.MetricSection}>
              <p className={styles.Label}>
                {formatMetric(data.isolatedScoreTile, ScoreType.Raw)}
              </p>
              <ChangeIconLabel
                className={styles.Icon}
                metric={
                  isPercentage
                    ? Math.round(data.isolatedScoreDelta * 100)
                    : data.isolatedScoreDelta /* TODO:
                    handle RawScoreFormat.Percentage metrics consistently across widgets */
                }
                suffix={
                  isPercentage ? '%' : '' /* TODO:
                handle RawScoreFormat.Percentage metrics consistently across widgets */
                }
              />
            </div>
            <div className={cn(styles.MetricSection, styles.YellowBorder)}>
              <p className={styles.SubTitle}>{rawMetric}</p>
              <p className={styles.SubTitle}>vs Prev. Period</p>
            </div>
          </div>

          <div className={cn(styles.MetricContainer, styles.CohortContainer)}>
            <div className={styles.MetricSection}>
              <ChangeIconLabel
                className={styles.Icon}
                metric={data.isolatedCohortComparison}
                suffix="%"
              />
              <div className={styles.SubtitleSection}>
                <p className={styles.SubTitle}>Raw Performance</p>
                <p className={styles.SubTitle}>vs Cohort Average</p>
              </div>
            </div>

            <div className={styles.MetricSection}>
              <ChangeIconLabel
                className={styles.Icon}
                metric={data.isolatedChangeRate}
                suffix="%"
              />
              <div className={styles.SubtitleSection}>
                <p className={styles.SubTitle}>Raw Performance</p>
                <p className={styles.SubTitle}>vs Prev. Period</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleMetricSummary;
