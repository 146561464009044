import { GenericIconProps } from './types';

const UserIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L24 0 24 24 0 24z"
              transform="translate(-1248 -68) translate(1240 56) translate(8 12)"
            />
            <path
              fill="#7E9397"
              d="M14.328 11.332C15.624 10.541 16.5 9.127 16.5 7.5 16.5 5.019 14.481 3 12 3S7.5 5.019 7.5 7.5c0 1.627.876 3.041 2.172 3.832C6.115 12.348 3.5 15.621 3.5 19.5v.5h17v-.5c0-3.879-2.615-7.152-6.172-8.168zM8.5 7.5C8.5 5.57 10.07 4 12 4s3.5 1.57 3.5 3.5S13.93 11 12 11 8.5 9.43 8.5 7.5zM4.517 19c.258-3.903 3.516-7 7.483-7 3.967 0 7.225 3.097 7.483 7H4.517z"
              transform="translate(-1248 -68) translate(1240 56) translate(8 12)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default UserIcon;
