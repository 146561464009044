import { GenericIconProps } from '../types';

const ShareIcon = (props: GenericIconProps) => (
  <svg
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4068 0C11.6262 0 10.1828 1.44347 10.1828 3.22409C10.1828 3.73785 10.3029 4.22354 10.5167 4.6546L5.98296 6.89614C5.41782 5.96409 4.39368 5.34129 3.22408 5.34129C1.44347 5.34129 0 6.78477 0 8.56538C0 10.346 1.44347 11.7895 3.22408 11.7895C4.40562 11.7895 5.43871 11.1539 6.00012 10.206L10.4804 12.4211C10.2893 12.833 10.1827 13.292 10.1827 13.7759C10.1827 15.5565 11.6262 17 13.4068 17C15.1874 17 16.6309 15.5565 16.6309 13.7759C16.6309 11.9953 15.1874 10.5518 13.4068 10.5518C12.5381 10.5518 11.7496 10.8954 11.1698 11.4542L6.40437 9.09805C6.43318 8.92477 6.44816 8.74683 6.44816 8.56538C6.44816 8.37197 6.43113 8.18254 6.39849 7.99851L11.2352 5.60715C11.8082 6.12959 12.5703 6.44817 13.4068 6.44817C15.1874 6.44817 16.6309 5.0047 16.6309 3.22409C16.6309 1.44347 15.1874 0 13.4068 0ZM13.4069 1.17259C14.54 1.17259 15.4586 2.09116 15.4586 3.22428C15.4586 4.3574 14.54 5.27597 13.4069 5.27597C12.2738 5.27597 11.3552 4.3574 11.3552 3.22428C11.3552 2.09116 12.2738 1.17259 13.4069 1.17259ZM13.4069 11.7242C14.54 11.7242 15.4585 12.6427 15.4585 13.7759C15.4585 14.909 14.54 15.8275 13.4069 15.8275C12.2737 15.8275 11.3552 14.909 11.3552 13.7759C11.3552 12.6427 12.2737 11.7242 13.4069 11.7242ZM5.27571 8.56557C5.27571 7.43246 4.35714 6.51388 3.22402 6.51388C2.09091 6.51388 1.17233 7.43246 1.17233 8.56557C1.17233 9.69869 2.09091 10.6173 3.22402 10.6173C4.35714 10.6173 5.27571 9.69869 5.27571 8.56557Z"
      fill="currentColor"
    />
  </svg>
);

export default ShareIcon;
