import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
} from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import { useLazyQuery } from '@apollo/client';
import sumBy from 'lodash/sumBy';
import isNil from 'lodash/isNil';
import Tag from '../../../Labels/Tag';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import NoResultsAlert from '../../../Atoms/NoResultsAlert/NoResultsAlert';
import {
  CreativeAsset,
  CreativeAssetType,
  CreativeAssetTag,
} from '../../../../interfaces/creative';
import BNContext from '../../../../contexts/BNContext';

import styles from './CreativeAssetModalContent.module.scss';
import { GET_CREATIVE_ASSET_BY_ID } from '../../../../api/queries/Pages/CreativeChannels';
import SpendByPeriodBarChart from '../SpendByPeriodBarChart/SpendByPeriodBarChart';
import { getCreativeAssetSpendFromTimeSeries } from '../../../../api/transforms/Pages/Creative/CreativeOverview';
import { getDayJsStartDate } from '../../../../utils/timeframe';
import { formatCurrency } from '../../../../utils/number';

const NOT_AVAILABLE = 'n/a';

export interface CreativeAssetModalContentProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  creative: CreativeAsset;
}

const CreativeAssetModalContent: FunctionComponent<
  CreativeAssetModalContentProps
> = ({ className, creative }) => {
  const { nonSessionTimeframe } = useContext(BNContext);
  const [assetLoadError, setAssetLoadError] = useState(false);
  const defaultDayLength = 1;

  const queryVariableBase = {
    input: {
      creativeId: creative.id,
      start: getDayJsStartDate(nonSessionTimeframe).toISOString(),
      end: dayjs(nonSessionTimeframe?.end).toISOString(),
    },
  };

  const [getCreativeAssetModalData, { data: creativeResp, loading }] =
    useLazyQuery(GET_CREATIVE_ASSET_BY_ID, {
      variables: queryVariableBase,
    });

  useEffect(() => {
    getCreativeAssetModalData();
  }, [getCreativeAssetModalData]);

  const creativeAsset = useMemo(() => {
    return creativeResp?.getCreativeDetails;
  }, [creativeResp]);

  const lengthOfTimeSeen = useMemo(() => {
    if (!creativeAsset?.seen) {
      return NOT_AVAILABLE;
    }

    if (creativeAsset?.seen?.end) {
      const seenLength = dayjs(creativeAsset.seen.end).diff(
        creativeAsset?.seen?.start,
        'days'
      );
      return seenLength < defaultDayLength ? defaultDayLength : seenLength;
    }

    return dayjs().diff(creativeAsset?.seen?.start, 'days');
  }, [creativeAsset]);

  const dayPluralize = useMemo(() => {
    return lengthOfTimeSeen === 1 ? 'day' : 'days';
  }, [lengthOfTimeSeen]);

  const spendData = useMemo(
    () => getCreativeAssetSpendFromTimeSeries(creativeAsset?.spendByWeek),
    [creativeAsset]
  );

  const totalSpend = useMemo(() => {
    return sumBy(creativeAsset?.spendByWeek, 'y');
  }, [creativeAsset]);

  function formatReadableDate(date: string | null) {
    return dayjs(date).format('M/D/YYYY');
  }

  const handleAssetLoadError = useCallback(() => {
    setAssetLoadError(true);
  }, []);

  if (!creative) {
    return (
      <div className={classNames(styles.CreativeAssetModalContent, className)}>
        <NoResultsAlert text="No creative asset details available." />
      </div>
    );
  }

  return (
    <div className={classNames(styles.CreativeAssetModalContent, className)}>
      <Grid container className={styles.GridContainer}>
        <Grid
          item
          xs={12}
          md={7}
          className={styles.Col}
          style={{ paddingRight: 40 }}
        >
          <div className={styles.LeftColContent}>
            {isNil(creative?.type) ? (
              <NoResultsAlert text="No creative type or asset url available." />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {assetLoadError ? (
                  <NoResultsAlert text="Creative asset failed to load." />
                ) : (
                  <>
                    {creative?.type === CreativeAssetType.Image && (
                      <img
                        className={styles.AssetCardImg}
                        src={creative?.assetUrl}
                        alt="creative-asset"
                        onError={handleAssetLoadError}
                      />
                    )}

                    {creative?.type === CreativeAssetType.Video && (
                      <video controls controlsList="nodownload">
                        <source
                          src={creative?.assetUrl}
                          onError={handleAssetLoadError}
                        />
                        Your browser does not support HTML5 video player.
                      </video>
                    )}

                    {creative?.type === CreativeAssetType.Audio && (
                      <audio controls controlsList="nodownload">
                        <source
                          src={creative?.assetUrl}
                          onError={handleAssetLoadError}
                        />
                        Your browser does not support HTML5 audio player.
                      </audio>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </Grid>

        <Grid item xs={12} md={5} className={styles.Col}>
          <div className={styles.CreativeName}>{creative?.name}</div>

          <div className={styles.DetailsList}>
            <div className={styles.DetailsListItem}>
              <div className={styles.Label}>
                Seen {lengthOfTimeSeen} {dayPluralize}
              </div>
              <div className={styles.Value}>
                {creativeAsset?.seen?.end ? (
                  <>
                    {formatReadableDate(creativeAsset?.seen?.start)} -{' '}
                    {formatReadableDate(creativeAsset?.seen?.end)}
                  </>
                ) : (
                  <>
                    {formatReadableDate(creativeAsset?.seen?.start)} -{' '}
                    {formatReadableDate(new Date().toISOString())}
                  </>
                )}
              </div>
            </div>

            <div className={styles.DetailsListItem}>
              <div className={styles.Label}>Impressions</div>
              <div className={styles.Value}>
                {isNil(creative?.impressions)
                  ? NOT_AVAILABLE
                  : creative?.impressions.toLocaleString()}
              </div>
            </div>

            <div className={styles.DetailsListItem}>
              <div className={styles.Label}>Spend</div>
              <div className={styles.Value}>
                {isNil(totalSpend)
                  ? NOT_AVAILABLE
                  : `$${totalSpend.toLocaleString()}`}
              </div>
            </div>

            <div className={styles.DetailsListItem}>
              <div className={styles.Label}>CPM</div>
              <div className={styles.Value}>
                {isNil(creative?.eCPM)
                  ? NOT_AVAILABLE
                  : formatCurrency(creative.eCPM)}
              </div>
            </div>

            <div className={styles.DetailsListItem}>
              <div className={styles.Label}>Type</div>
              <div className={styles.Value}>
                {isNil(creative?.type) ? NOT_AVAILABLE : creative.type}
              </div>
            </div>

            {creative?.tags ? (
              <div className={styles.DetailsListItem}>
                <div className={styles.Label}>Tags</div>
                <div className={styles.Value}>
                  <div className={styles.TagList}>
                    {creative?.tags?.map((tag: CreativeAssetTag) => {
                      return <Tag key={tag.id} name={tag.name} />;
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          {loading ? (
            <ComponentLoader />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {spendData?.spendData?.length ? (
                <div className={styles.ChartContainer}>
                  <SpendByPeriodBarChart
                    className={styles.NoBoxShadow}
                    data={spendData.spendData}
                    labels={spendData.labels}
                  />
                  <p className={styles.ChartLabel}>
                    <span className={styles.ColorSquare} />
                    Spend
                  </p>
                </div>
              ) : (
                <NoResultsAlert text="No spend data available for this creativeAsset." />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CreativeAssetModalContent;
