import { HARDCODED_METRICS_ID_MAP } from '../../constants/metrics';

export function prepareMetricFavoriteMap(data) {
  if (!data?.user?.brand?.session) {
    return {};
  }

  const result = {};

  const bluescore = data.user.brand.session.blueScore;
  result[HARDCODED_METRICS_ID_MAP.BLUESCORE] = isFavorite(bluescore.favorite);

  const { factors } = data.user.brand.session;
  factors.forEach((factor) => {
    if (factor.name === 'Familiar') {
      result[HARDCODED_METRICS_ID_MAP.FAMILIAR] = isFavorite(factor.favorite);
    }

    if (factor.name === 'Unique') {
      result[HARDCODED_METRICS_ID_MAP.UNIQUE] = isFavorite(factor.favorite);
    }

    if (factor.name === 'Consistent') {
      result[HARDCODED_METRICS_ID_MAP.CONSISTENT] = isFavorite(factor.favorite);
    }

    if (factor.name === 'Relevant') {
      result[HARDCODED_METRICS_ID_MAP.RELEVANT] = isFavorite(factor.favorite);
    }

    if (factor.name === 'Revered') {
      result[HARDCODED_METRICS_ID_MAP.REVERED] = isFavorite(factor.favorite);
    }
  });

  const subfactors = data.user.brand.session.subFactors;
  subfactors.forEach((subfactor) => {
    if (subfactor.name === 'Visible') {
      result[HARDCODED_METRICS_ID_MAP.VISIBLE] = isFavorite(subfactor.favorite);
    }

    if (subfactor.name === 'Considered') {
      result[HARDCODED_METRICS_ID_MAP.CONSIDERED] = isFavorite(
        subfactor.favorite
      );
    }

    if (subfactor.name === 'Amplified') {
      result[HARDCODED_METRICS_ID_MAP.AMPLIFIED] = isFavorite(
        subfactor.favorite
      );
    }

    if (subfactor.name === 'Different') {
      result[HARDCODED_METRICS_ID_MAP.DIFFERENT] = isFavorite(
        subfactor.favorite
      );
    }

    if (subfactor.name === 'Memorable') {
      result[HARDCODED_METRICS_ID_MAP.MEMORABLE] = isFavorite(
        subfactor.favorite
      );
    }

    if (subfactor.name === 'Clear') {
      result[HARDCODED_METRICS_ID_MAP.CLEAR] = isFavorite(subfactor.favorite);
    }

    if (subfactor.name === 'Reinforcing') {
      result[HARDCODED_METRICS_ID_MAP.REINFORCING] = isFavorite(
        subfactor.favorite
      );
    }

    if (subfactor.name === 'Stable') {
      result[HARDCODED_METRICS_ID_MAP.STABLE] = isFavorite(subfactor.favorite);
    }

    if (subfactor.name === 'Needed') {
      result[HARDCODED_METRICS_ID_MAP.NEEDED] = isFavorite(subfactor.favorite);
    }

    if (subfactor.name === 'Valued') {
      result[HARDCODED_METRICS_ID_MAP.VALUED] = isFavorite(subfactor.favorite);
    }

    if (subfactor.name === 'Influential') {
      result[HARDCODED_METRICS_ID_MAP.INFLUENTIAL] = isFavorite(
        subfactor.favorite
      );
    }

    if (subfactor.name === 'Desirable') {
      result[HARDCODED_METRICS_ID_MAP.DESIRABLE] = isFavorite(
        subfactor.favorite
      );
    }

    if (subfactor.name === 'Esteemed') {
      result[HARDCODED_METRICS_ID_MAP.ESTEEMED] = isFavorite(
        subfactor.favorite
      );
    }

    if (subfactor.name === 'Inspiring') {
      result[HARDCODED_METRICS_ID_MAP.INSPIRING] = isFavorite(
        subfactor.favorite
      );
    }
  });

  const { metrics } = data.user.brand.session;
  metrics.forEach((metric) => {
    result[metric.variableId] = isFavorite(metric.favorite);
  });

  return result;
}

function isFavorite(favorite) {
  if (!favorite) {
    return false;
  }

  return Boolean(favorite.brand_id && favorite.user_id);
}
