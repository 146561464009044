import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import ChartLegendIcon from '../ChartLegendIcon/ChartLegendIcon';

import styles from './ChartLegendItem.module.scss';

export default function ChartLegendItem({
  color = {
    DEFAULT: 'black',
    FILL: 'gray',
  },
  label,
  variant,
  fontSize = 13,
  className,
  ...props
}) {
  return (
    <div className={classNames(styles.ChartLegendItem, className)} {...props}>
      <Box mr={1}>
        <ChartLegendIcon chartColor={color} variant={variant} />
      </Box>

      <span className={styles.Label} style={{ fontSize }}>
        {label}
      </span>
    </div>
  );
}

ChartLegendItem.propTypes = {
  color: PropTypes.shape({
    DEFAULT: PropTypes.string,
    FILL: PropTypes.string,
  }),
  fontSize: PropTypes.number,
  label: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};
