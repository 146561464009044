import classNames from 'classnames';
import { FunctionComponent } from 'react';
import MuiSwitch, { SwitchProps as MuiSwitchProps } from '@mui/material/Switch';

import styles from './Switch.module.scss';

export interface SwitchProps extends MuiSwitchProps {
  className?: string;
}

const Switch: FunctionComponent<SwitchProps> = ({ className, ...props }) => {
  return (
    <MuiSwitch
      disableRipple
      className={classNames(styles.Switch, className)}
      classes={{
        root: styles.SwitchRoot,
        track: styles.Track,
        thumb: styles.Thumb,
        input: styles.Input,
        switchBase: styles.SwitchBase,
        checked: styles.Checked,
        colorPrimary: styles.ColorPrimary,
        colorSecondary: styles.ColorSecondary,
      }}
      {...props}
    />
  );
};

export default Switch;
