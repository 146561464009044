import { GenericIconProps } from './types';

const TagIcon = (props: GenericIconProps) => (
  <svg
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.32004 1.62892L2.69352 1.62842C2.10555 1.62842 1.62891 2.10506 1.62891 2.69304L1.63387 7.30179C1.66325 7.85099 1.87239 8.35553 2.22718 8.75073L9.40306 15.9273C9.60261 16.1271 9.87358 16.2394 10.1561 16.2394C10.4387 16.2394 10.7097 16.1271 10.9094 15.9272L16.4296 10.4077C16.5682 10.2691 16.5682 10.0445 16.4297 9.90589L8.76447 2.23425C8.35533 1.86611 7.85051 1.65714 7.32004 1.62892ZM7.28969 2.33777L7.41961 2.35086C7.73743 2.39516 8.03602 2.5333 8.27631 2.7491L15.6765 10.1568L10.4074 15.4255C10.3406 15.4922 10.2503 15.5297 10.1561 15.5297C10.062 15.5297 9.97163 15.4922 9.90507 15.4256L2.74218 8.26272C2.50859 8.00177 2.36316 7.65091 2.3431 7.28214L2.33865 2.69265C2.33865 2.49704 2.49753 2.33816 2.69352 2.33816L7.28969 2.33777ZM5.53251 3.75765C4.55255 3.75765 3.75814 4.55206 3.75814 5.53202C3.75814 6.51197 4.55255 7.30638 5.53251 7.30638C6.51246 7.30638 7.30687 6.51197 7.30687 5.53202C7.30687 4.55206 6.51246 3.75765 5.53251 3.75765ZM5.53251 4.4674C6.12048 4.4674 6.59712 4.94404 6.59712 5.53202C6.59712 6.11999 6.12048 6.59663 5.53251 6.59663C4.94453 6.59663 4.46789 6.11999 4.46789 5.53202C4.46789 4.94404 4.94453 4.4674 5.53251 4.4674Z"
      fill="currentColor"
    />
  </svg>
);

export default TagIcon;
