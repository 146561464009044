import { GenericIconProps } from './types';

export enum MediaIcon {
  Image,
  Audio,
  Video,
}

export interface MediaTypeIconProps extends GenericIconProps {
  iconType: MediaIcon;
}

const MediaTypeIcon = ({ iconType, ...props }: MediaTypeIconProps) => {
  return (
    <>
      {iconType === MediaIcon.Image && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.33333 4H18.6667C20.5076 4 22 5.52223 22 7.4V17.6C22 19.4778 20.5076 21 18.6667 21H5.33333C3.49238 21 2 19.4778 2 17.6V7.4C2 5.52223 3.49238 4 5.33333 4ZM4 18H14L9 11L4 18ZM16.5 14C18.433 14 20 12.433 20 10.5C20 8.567 18.433 7 16.5 7C14.567 7 13 8.567 13 10.5C13 12.433 14.567 14 16.5 14Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconType === MediaIcon.Audio && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M12.0417 4.66101C15.0255 4.66101 17.7152 6.45886 18.8562 9.21584C19.9972 11.9728 19.3645 15.1457 17.2534 17.2542C17.058 17.4493 16.7414 17.4491 16.5463 17.2537C16.3511 17.0583 16.3513 16.7418 16.5467 16.5466C18.3716 14.724 18.9185 11.9814 17.9322 9.59824C16.9459 7.21509 14.6209 5.66101 12.0417 5.66101C9.46255 5.66101 7.13749 7.21509 6.15123 9.59824C5.16497 11.9814 5.71184 14.724 7.53672 16.5466C7.7321 16.7418 7.7323 17.0583 7.53716 17.2537C7.34202 17.4491 7.02544 17.4493 6.83005 17.2542C4.71892 15.1457 4.08626 11.9728 5.22723 9.21584C6.3682 6.45886 9.05798 4.66101 12.0417 4.66101Z"
            fill="currentColor"
          />
          <path
            d="M4.23412 4.23394C8.54614 -0.0779808 15.5372 -0.0779808 19.8492 4.23394C24.1612 8.54587 24.1614 15.5369 19.8496 19.849C19.6543 20.0443 19.3377 20.0443 19.1425 19.8491C18.9472 19.6538 18.9472 19.3372 19.1424 19.142C23.0638 15.2204 23.0636 8.86247 19.1421 4.94106C15.2206 1.01965 8.86271 1.01965 4.94122 4.94106C1.01972 8.86247 1.01958 15.2204 4.9409 19.142C5.13615 19.3372 5.13614 19.6538 4.94087 19.8491C4.74561 20.0443 4.42902 20.0443 4.23377 19.849C-0.0780585 15.5369 -0.077903 8.54587 4.23412 4.23394Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 21V16H12.5V21H11.5Z"
            fill="currentColor"
          />
          <path
            d="M11.8125 20.1083C11.9285 20.0413 12.0715 20.0413 12.1875 20.1083L13.3861 20.8002C13.7183 20.9921 13.5822 21.5 13.1986 21.5H10.8014C10.4178 21.5 10.2817 20.9921 10.6139 20.8002L11.8125 20.1083Z"
            fill="currentColor"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 14V11C14 9.89543 13.1046 9 12 9C10.8954 9 10 9.89543 10 11V14C10 15.1046 10.8954 16 12 16C13.1046 16 14 15.1046 14 14ZM12 8C10.3431 8 9 9.34315 9 11V14C9 15.6569 10.3431 17 12 17C13.6569 17 15 15.6569 15 14V11C15 9.34315 13.6569 8 12 8Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconType === MediaIcon.Video && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.575 9.125V14.875C9.575 15.175 9.70417 15.4 9.9625 15.55C10.2208 15.7 10.475 15.6917 10.725 15.525L15.25 12.625C15.4833 12.475 15.6 12.2667 15.6 12C15.6 11.7333 15.4833 11.525 15.25 11.375L10.725 8.475C10.475 8.30833 10.2208 8.3 9.9625 8.45C9.70417 8.6 9.575 8.825 9.575 9.125ZM12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3667 2 12C2 10.6333 2.2625 9.34167 2.7875 8.125C3.3125 6.90833 4.02917 5.84583 4.9375 4.9375C5.84583 4.02917 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6333 2 12 2C13.3667 2 14.6583 2.2625 15.875 2.7875C17.0917 3.3125 18.1542 4.02917 19.0625 4.9375C19.9708 5.84583 20.6875 6.90833 21.2125 8.125C21.7375 9.34167 22 10.6333 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.9708 18.1542 19.0625 19.0625C18.1542 19.9708 17.0917 20.6875 15.875 21.2125C14.6583 21.7375 13.3667 22 12 22ZM12 20.5C14.3333 20.5 16.3333 19.6667 18 18C19.6667 16.3333 20.5 14.3333 20.5 12C20.5 9.66667 19.6667 7.66667 18 6C16.3333 4.33333 14.3333 3.5 12 3.5C9.66667 3.5 7.66667 4.33333 6 6C4.33333 7.66667 3.5 9.66667 3.5 12C3.5 14.3333 4.33333 16.3333 6 18C7.66667 19.6667 9.66667 20.5 12 20.5Z"
            fill="currentColor"
          />
        </svg>
      )}
    </>
  );
};

export default MediaTypeIcon;
