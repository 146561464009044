import * as React from 'react';

function SvgIconChartBar(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path d="M21 20H4V3H3v18h18z" fill="#D7DDE2" />
        <path
          d="M6.382 17a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H5v-2h1.382zm1.883-3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H5v-2h3.265zm2.823-3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H5v-2h6.088zm5.647-3a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H5V8h11.735zM20.5 5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H5V5h15.5z"
          fill="#20434A"
        />
      </g>
    </svg>
  );
}

export default SvgIconChartBar;
