import { Chart, ChartType, DefaultDataPoint } from 'chart.js';
import isNil from 'lodash/isNil';
import {
  ChartTooltipContext,
  EventPoint,
  ChartPoint,
} from '../../../interfaces/chart';
import { IndexedRating } from '../../../interfaces/score';
import colors from '../../../constants/colors';
import {
  indexedRatings,
  indexedRangesByRating,
  indexedColorsByRating,
  getIndexRatingFromScore,
} from '../../../utils/score';

export const plugin = {
  id: 'customPlugins',
  afterDraw: (
    chart: Chart<ChartType, DefaultDataPoint<ChartType>, unknown>
  ) => {
    // plugin to draw vertical line on hover
    const { tooltip, ctx } = chart;

    const shouldShowLine = (
      tooltip?.dataPoints as unknown as EventPoint[]
    )?.find((item: EventPoint) => item?.dataset?.id === 'showLine');

    const isMarkerEvent = (
      tooltip?.dataPoints as unknown as EventPoint[]
    )?.find((item: EventPoint) =>
      // eslint-disable-next-line no-prototype-builtins
      item?.raw?.hasOwnProperty('description')
    );

    const activeElements = chart.getActiveElements();

    if (shouldShowLine && activeElements.length) {
      const activePoint = activeElements[0];
      const { x, y } = activePoint.element;
      const bottomY = chart?.scales?.y?.bottom;
      const topY = isMarkerEvent
        ? y
        : (chart.tooltip as unknown as ChartPoint).y;

      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, bottomY);
      ctx.lineTo(x, topY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = colors.chartBluescoreVerticalLine;
      ctx.stroke();
      ctx.restore();
    }
  },
};

export const renderBluescoreGradient = (
  context: ChartTooltipContext,
  yMin: number,
  yMax: number
) => {
  const indexedRatingsClone = [...indexedRatings];

  const { ctx } = context.chart;
  const gradient = ctx.createLinearGradient(0, 0, 0, context.chart.height);

  const showDynamicGradient = yMax <= 200;
  const totalRange = showDynamicGradient ? yMax - yMin : 200;

  let indexedRatingsInRange = indexedRatingsClone;

  if (showDynamicGradient) {
    const min = getIndexRatingFromScore(yMin);
    const max = getIndexRatingFromScore(yMax);

    const minIndex = indexedRatingsClone.indexOf(min);
    const maxIndex = indexedRatingsClone.indexOf(max);

    indexedRatingsInRange = indexedRatingsClone.slice(minIndex, maxIndex + 1);
  }

  const percentFillByIndexedRating: { [key in IndexedRating]?: number } = {};

  indexedRatingsInRange.forEach((indexedRating, index) => {
    const { floor, ceil } = indexedRangesByRating[indexedRating];

    if (isNil(floor) || isNil(ceil)) {
      return;
    }

    let adjustedFloor = floor;
    let adjustedCeil = ceil;

    // if the lowest rating in range, adjust based on y min
    if (showDynamicGradient && index === 0) {
      adjustedFloor = yMin;
    }

    // if the highest rating in range, adjust based on ymax
    if (showDynamicGradient && index === indexedRatingsInRange.length - 1) {
      adjustedCeil = yMax;
    }

    // If their subtraction will be negative number, do not add to PercentFill
    if (adjustedFloor > adjustedCeil) {
      return;
    }

    percentFillByIndexedRating[indexedRating] =
      (adjustedCeil - adjustedFloor) / totalRange;
  });

  let totalPercentFilled = 0;

  indexedRatingsInRange.reverse().forEach((indexedRating) => {
    totalPercentFilled += percentFillByIndexedRating[indexedRating] || 0;

    gradient.addColorStop(
      totalPercentFilled,
      indexedColorsByRating[indexedRating].gradientFill || ''
    );
  });

  return gradient;
};
