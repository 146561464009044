import * as React from 'react';

function SvgCsv(props) {
  return (
    <svg viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M15 .46H7a1 1 0 00-1 1v4.25l9 5.25 4.5 1.898 4.499-1.898V5.71l-9-5.25z"
          fill="#21A366"
        />
        <path fill="#107C41" d="M6 5.71h9v5.25H6z" />
        <path d="M23 .46h-8v5.25h8.999V1.46a1 1 0 00-1-1z" fill="#33C481" />
        <path
          d="M15 10.96H6v9.5a1 1 0 001 1h16a1 1 0 00.999-1v-4.25l-9-5.25z"
          fill="#185C37"
        />
        <path fill="#107C41" d="M15 10.96h8.999v5.25h-9z" />
        <path
          d="M12.5 4.21H6v14.25h6.5a1.003 1.003 0 001-1V5.21a1.003 1.003 0 00-1-1z"
          fill="#000"
          opacity={0.1}
        />
        <path
          d="M11.75 4.96H6v14.25h5.75a1.003 1.003 0 001-1V5.96a1.003 1.003 0 00-1-1z"
          fill="#000"
          opacity={0.2}
        />
        <path
          d="M11.75 4.96H6v12.75h5.75a1.003 1.003 0 001-1V5.96a1.003 1.003 0 00-1-1z"
          fill="#000"
          opacity={0.2}
        />
        <path
          d="M11 4.96H6v12.75h5a1.003 1.003 0 001-1V5.96a1.003 1.003 0 00-1-1z"
          fill="#000"
          opacity={0.2}
        />
        <path
          d="M1 4.96h10a1 1 0 011 1v10a1 1 0 01-1 1H1a1 1 0 01-1-1v-10a1 1 0 011-1z"
          fill="#107C41"
        />
        <path
          d="M2.65 14.71l2.427-3.761-2.223-3.74h1.788L5.856 9.6c.111.227.188.396.23.508h.016c.08-.181.164-.357.251-.528l1.297-2.37H9.29l-2.28 3.719 2.338 3.781H7.602L6.2 12.084a2.191 2.191 0 01-.167-.35h-.021c-.041.119-.095.233-.162.34l-1.442 2.635H2.65z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default SvgCsv;
