import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import ButtonDropdownMenu from '../ButtonDropdownMenu/ButtonDropdownMenu';
import AlertListItem from '../../Atoms/AlertListItem/AlertListItem';

import styles from './AlertList.module.scss';

export default function AlertList({ className, alerts, ...props }) {
  function handleMarkAllRead() {
    alert('TODO: Handle marking all unread alerts as read.');
  }

  return (
    <div className={classNames(styles.AlertList, className)} {...props}>
      <div className={styles.Header}>
        <h5>Alerts</h5>

        <ButtonDropdownMenu
          onClick={handleMarkAllRead}
          items={[{ label: 'Mark as read', value: 'Mark as read' }]}
        />
      </div>

      <div className={styles.Body}>
        <ul className={styles.Alerts}>
          {alerts.map((alert) => (
            <AlertListItem
              className={styles.AlertItem}
              key={alert.id}
              message={alert.message}
              read={alert.read}
              variant={alert.variant}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

AlertList.propTypes = {
  className: PropTypes.string,
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.string,
      read: PropTypes.bool,
      message: PropTypes.string,
    })
  ),
};
