import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SORT_DIRECTIONS } from '../../../constants/props';

import styles from './TableLabel.module.scss';
import DynamicMetricIcon, {
  DynamicMetricDirection,
} from '../../../assets/icons/DynamicMetricIcon';

/**
 * Label for use with tables in conjuction with sorting.
 */
export default function TableLabel({
  name,
  disableSort = false,
  direction = null,
  className,
  ...props
}) {
  return (
    <div className={classNames(styles.Label, className)} {...props}>
      <span className={styles.Title}>{name}</span>
      {!disableSort ? (
        <div className={styles.SortIcon}>
          {direction === SORT_DIRECTIONS.NONE ? (
            <DynamicMetricIcon
              direction={DynamicMetricDirection.Neutral}
              className={styles.Icon}
            />
          ) : null}
          {direction === SORT_DIRECTIONS.ASC ? (
            <DynamicMetricIcon
              direction={DynamicMetricDirection.Up}
              className={styles.Icon}
            />
          ) : null}
          {direction === SORT_DIRECTIONS.DESC ? (
            <DynamicMetricIcon
              direction={DynamicMetricDirection.Down}
              className={styles.Icon}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

TableLabel.propTypes = {
  /**
   * Table column name.
   */
  name: PropTypes.string.isRequired,
  /**
   * Table column sort direction.
   */
  direction: PropTypes.string,
  className: PropTypes.string,
  disableSort: PropTypes.bool,
};
