import { WidgetType } from '../../../../../interfaces/dashboard-api';
import metricAnalysisExampleImg from '../../../../../assets/images/widget-settings/SingleMetricWidgetExample.jpg';
import metricCollectionExampleImg from '../../../../../assets/images/widget-settings/GroupMetricWidgetExample.jpg';
import esovExampleImg from '../../../../../assets/images/widget-settings/ESOVWidgetExample.jpg';
import paceAnalysisExampleImg from '../../../../../assets/images/widget-settings/pace-analysis/example-img-pace-widget.png';
import metricComparisonExampleImg from '../../../../../assets/images/widget-settings/MetricComparisonWidgetExample.png';
import stackExampleImg from '../../../../../assets/images/widget-settings/stack-ranking/StackRanking@2x.png';
import summaryTrendsExampleImg from '../../../../../assets/images/widget-settings/SummaryTrendsExample.png';
import VisualMapsHeatmapExample from '../../../../../assets/images/widget-settings/visual-maps/VisualMapsHeatmapExample.png';
import VisualMapsWatermapExample from '../../../../../assets/images/widget-settings/visual-maps/VisualMapsWatermapExample.png';

const widgetExamplesByWidgetType: Record<
  WidgetType,
  { exampleImg: string; secondExampleImg?: string; infoMd: string }
> = {
  [WidgetType.MetricAnalysisV1]: {
    exampleImg: metricAnalysisExampleImg,
    infoMd:
      'The single metric widget provides a concise visual representation of a specific metric, offering a quick and focused insight into a particular aspect of your data.',
  },
  [WidgetType.MetricCollectionV1]: {
    exampleImg: metricCollectionExampleImg,
    infoMd: `Powered by a group of metrics this widget provides valuable insights to aid your decision making:
   - **Evaluate effectiveness:** Assess the impact and effectiveness of your actions and identify areas for improvement.
   - **Benchmark against industry:** Compare your performance with industry benchmarks to gauge your standing.
   - **Track trends:** Monitor changes and trends over time to stay informed and make data-driven decisions.`,
  },
  [WidgetType.ExcessShareOfVoiceV1]: {
    exampleImg: esovExampleImg,
    infoMd: `The Excess Share of Voice (ESoV) widget predicts a brand's future market growth by calculating the difference between its Share of Voice (SoV) and Share of Market (SoM). By analyzing the ESoV, you can identify whether a brand's SoM is expected to expand (SoV excess) or contract (SoV deficit).`,
  },
  [WidgetType.PaceAnalysisV1]: {
    exampleImg: paceAnalysisExampleImg,
    infoMd: `The pace analysis widget offers a focused evaluation of a single metric's performance over time, highlighting the relative pace and progress compared to competitors within the cohort, providing valuable insights into the dynamic nature of your brand’s trajectory.`,
  },
  [WidgetType.MetricComparisonV1]: {
    exampleImg: metricComparisonExampleImg,
    infoMd: `The comparison widget enables a comprehensive assessment of a single metric by presenting a side-by-side comparison across all brands in the cohort, allowing for a holistic understanding of the brand's performance in relation to competitors.`,
  },
  [WidgetType.StackRankingV1]: {
    exampleImg: stackExampleImg,
    infoMd: `The stack ranking widget provides a concise metric performance at a glance between selected brands and how they stack against each other over time. `,
  },
  [WidgetType.SummaryTrendsV1]: {
    exampleImg: summaryTrendsExampleImg,
    infoMd:
      'The trending widget showcases three metrics on the rise and three declining, along with a summary highlighting overall trend patterns.',
  },
  [WidgetType.VisualMapsV1]: {
    exampleImg: VisualMapsHeatmapExample,
    secondExampleImg: VisualMapsWatermapExample,
    infoMd:
      'The Heatmap/Watermap widget displays a color-coded heat or water mapping of metrics for all brands, providing a concise visual overview of relative performance levels and patterns.',
  },
};

export default widgetExamplesByWidgetType;
