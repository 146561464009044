import React, { ReactElement, useState } from 'react';
import styles from './IconWithPopup.module.scss';

interface IconWithPopupProps {
  icon: ReactElement;
  text: string;
  title?: string;
}

const IconWithPopup: React.FC<IconWithPopupProps> = ({ icon, text, title }) => {
  const [showPopup, setShowPopup] = useState(false);
  // TODO: Implement logic to dynamically render the tooltip
  // to have the arrow point to source of hover point
  // as well as option to choose position of tooltip

  return (
    <div
      className={styles.Tooltip}
      onMouseEnter={() => setShowPopup(true)}
      onMouseLeave={() => setShowPopup(false)}
    >
      {icon}
      {showPopup && (
        <div className={styles.Popup}>
          <div className={styles.Triangle} />
          <p className={styles.Title}> {title}</p>
          <p className={styles.Text}>{text}</p>
        </div>
      )}
    </div>
  );
};

export default IconWithPopup;
