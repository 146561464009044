import { FACTOR_RANGES } from '../constants/factors';

export function getFactorRangeScaleIdx(score) {
  let result = null;

  FACTOR_RANGES.forEach((RANGE, index) => {
    if (score <= RANGE.CEIL && score >= RANGE.FLOOR) {
      result = index;
    }
  });

  return result;
}

export function getFactorRange(score) {
  return FACTOR_RANGES[getFactorRangeScaleIdx(score)];
}

export const getScoreTextColor = (score) => {
  const lightBgTextColor = 'black';
  const darkBgTextColor = 'white';

  return score < 110 ? lightBgTextColor : darkBgTextColor;
};

export const getScoreColorHex = (score) => {
  const scoreColor =
    score < 50
      ? 'rgba(246, 248, 237, 1)'
      : score >= 50 && score < 70
      ? 'rgba(219, 239, 226, 1)'
      : score >= 70 && score < 90
      ? 'rgba(191, 233, 221, 1)'
      : score >= 90 && score < 110
      ? 'rgba(150, 207, 209, 1)'
      : score >= 110 && score < 130
      ? 'rgba(96, 178, 202, 1)'
      : score >= 130 && score < 150
      ? 'rgba(61, 116, 147, 1)'
      : 'rgba(25, 58, 94, 1)';
  return scoreColor;
};

export const getScoreColorHexOpacity = (score) => {
  const scoreColor =
    score < 50
      ? 'rgba(246, 248, 237, 0.15)'
      : score >= 50 && score < 70
      ? 'rgba(219, 239, 226, 0.15)'
      : score >= 70 && score < 90
      ? 'rgba(191, 233, 221, 0.15)'
      : score >= 90 && score < 110
      ? 'rgba(150, 207, 209, 0.15)'
      : score >= 110 && score < 130
      ? 'rgba(96, 178, 202, 0.15)'
      : score >= 130 && score < 150
      ? 'rgba(61, 116, 147, 0.15)'
      : 'rgba(25, 58, 94, 0.15)';
  return scoreColor;
};
