const GoogleTagManagerID = 'GTM-K64DBP4';

export const GoogleTagManagerInit = {
  gtmId: GoogleTagManagerID,
};

export const GoogleTagManagerArgs = {
  gtmId: GoogleTagManagerID,
  events: {
    screen_view: 'userInfo',
  },
};

export const GoogleAnalyticsCode = 'UA-192885812-1';
