import { gql } from '@apollo/client';

export const GET_FACTORS = gql`
  query getFactors($id: ID, $sessionKey: ID, $accountKey: ID) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        blueScore {
          recommendations(accountKey: $accountKey) {
            id
            name
            value
            type
            impact_id
            sourceType_id
            isComplete
            practice_areas
          }
        }
        factors {
          id
          name
          value
          subFactors {
            associatedTypeId
            name
            value
          }
        }
      }
    }
  }
`;
