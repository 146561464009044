import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';

import styles from './ImpactRating.module.scss';

/**
 * Display a rating from positive to negative using colored dots.
 */
export default function ImpactRating({ low = -3, high = 3, rating = 0 }) {
  const showAllCircles = 6;
  let rates = rating;

  function generateLeftCircles() {
    const result = [];

    // handle a special case for the Market Index -> Subfactor score drivers page where
    // we show 3 red + 3 green circles
    if (rating === showAllCircles) {
      rates = low;
    }

    for (let x = low; x < 0; x++) {
      result.push(
        <div
          className={classNames(
            styles.Circle,
            styles.Left,
            x <= 0 && x >= rates ? styles.Filled : null
          )}
          key={x}
        />
      );
    }

    return result.map((circle) => circle);
  }

  function generateRightCircles() {
    const result = [];

    // handle a special case for the Market Index -> Subfactor score drivers page where
    // we show 3 red + 3 green circles
    if (rating === showAllCircles) {
      rates = high;
    }

    for (let x = 0; x < high; x++) {
      result.push(
        <div
          className={classNames(
            styles.Circle,
            styles.Right,
            x >= 0 && x < rates ? styles.Filled : null
          )}
          key={x}
        />
      );
    }

    return result.map((circle) => circle);
  }

  return (
    <div
      className={classNames(
        styles.ImpactRating,
        rating === 0 ? styles.NotVisible : null
      )}
    >
      <Box className={styles.Impact} mr={2}>
        IMPACT
      </Box>
      <Box className={styles.Rating}>
        {generateLeftCircles()}
        <div className={styles.Divider} />
        {generateRightCircles()}
      </Box>
    </div>
  );
}

ImpactRating.propTypes = {
  /**
   * Lowest rating possible.
   */
  low: PropTypes.number,
  /**
   * Highest rating possible.
   */
  high: PropTypes.number,
  /**
   * Actual rating.
   */
  rating: PropTypes.number,
};
