import { GenericIconProps } from '../icons/types';

const LogoLinkedInCircle = (props: GenericIconProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="11" cy="11" r="11" fill="#0A66C2" />
    <path d="M8.74999 8.7666H6.1532V16.5776H8.74999V8.7666Z" fill="white" />
    <path
      d="M7.46893 5C6.58049 5 6 5.5841 6 6.34966C6 7.09915 6.56284 7.69935 7.43457 7.69935H7.45134C8.3568 7.69935 8.92039 7.09915 8.92039 6.34966C8.90359 5.5841 8.35686 5 7.46893 5Z"
      fill="white"
    />
    <path
      d="M15.123 8.58276C13.7456 8.58276 13.1286 9.34027 12.7831 9.87235V8.76644H10.187C10.2214 9.49912 10.187 16.5774 10.187 16.5774H12.7831V12.2152C12.7831 11.9817 12.7999 11.7483 12.8687 11.5814C13.0561 11.1151 13.4835 10.6321 14.2008 10.6321C15.1398 10.6321 15.516 11.3486 15.516 12.3982V16.5774H18.1123V12.0982C18.1123 9.69888 16.8313 8.58276 15.123 8.58276Z"
      fill="white"
    />
  </svg>
);

export default LogoLinkedInCircle;
