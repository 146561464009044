import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useMemo,
  useState,
  useCallback,
} from 'react';
import classNames from 'classnames';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChartDataset } from '../../../interfaces';
import BrandDatasetFilterItem from '../BrandDatasetFilterItem';
import ScoreTile from '../../ScoreDisplays/ScoreTile/ScoreTile';
import {
  SOCIAL_METRIC_RATE_METRIC_WHITELIST,
  SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST,
} from '../../../constants/social-metrics';

import { ScoreType, MetricFormatType } from '../../../interfaces/metric';

import styles from './BrandDatasetFilter.module.scss';
import { FeatureFlag } from '../../../utils/featureFlags';

export interface BrandDatasetFilterProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  brandsDatasets: ChartDataset[];
  heroBrandKey: string;
  onFilterChange: (hiddenBrandKeys: string[]) => void;
  metricId: string;
  scoreType: ScoreType;
}

function brandSortComparator(a: ChartDataset, b: ChartDataset) {
  if (b.name < a.name) {
    return 1;
  }

  if (b.name > a.name) {
    return -1;
  }

  return 0;
}

const BrandDatasetFilter: FunctionComponent<BrandDatasetFilterProps> = ({
  className,
  brandsDatasets,
  heroBrandKey,
  onFilterChange,
  metricId,
  scoreType,
  ...props
}) => {
  const [brandHideByBrandKey, setBrandHideByBrandKey] = useState<{
    [key: string]: boolean;
  }>({});
  const flags = useFlags();

  useDeepCompareEffect(() => {
    const result: string[] = [];

    forEach(brandHideByBrandKey, (value, key) => {
      if (value) {
        result.push(key);
      }
    });

    onFilterChange(result);
  }, [brandHideByBrandKey]);

  const heroBrand = useMemo(() => {
    return find(brandsDatasets, { key: heroBrandKey });
  }, [brandsDatasets, heroBrandKey]);

  const competitorBrands = useMemo(() => {
    const competitors = brandsDatasets.filter((brand) => {
      return brand.key !== heroBrandKey;
    });

    const hiddenCompetitors = competitors
      .filter((brand) => {
        return brandHideByBrandKey[brand.key];
      })
      .sort(brandSortComparator);

    const shownCompetitors = competitors
      .filter((brand) => {
        return !brandHideByBrandKey[brand.key];
      })
      .sort(brandSortComparator);

    return shownCompetitors.concat(hiddenCompetitors);
  }, [brandsDatasets, heroBrandKey, brandHideByBrandKey]);

  const handleFilterChange = useCallback(
    (hidden: boolean, brandKey?: string) => {
      if (brandKey) {
        setBrandHideByBrandKey({ ...brandHideByBrandKey, [brandKey]: hidden });
      }
    },
    [brandHideByBrandKey]
  );

  const isSocialMetric =
    flags[FeatureFlag.ViewMetricsSocialMetrics] &&
    (SOCIAL_METRIC_RATE_METRIC_WHITELIST.includes(metricId) ||
      SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(metricId));

  const scoreTileFormatType =
    flags[FeatureFlag.ViewMetricsSocialMetrics] &&
    SOCIAL_METRIC_RATE_METRIC_WHITELIST.includes(metricId)
      ? MetricFormatType.PercentageSuffix
      : MetricFormatType.Abbreviated;

  return (
    <div
      className={classNames(styles.BrandDatasetFilter, className)}
      {...props}
    >
      <div className={styles.BrandItem}>
        {flags[FeatureFlag.ViewMetricsSocialMetrics] && (
          <ScoreTile
            variant={isSocialMetric ? scoreType : ScoreType.Raw}
            value={Number(heroBrand?.tile?.score)}
            delta={heroBrand?.tile?.delta}
            tooltip={heroBrand?.tile?.tooltip}
            className={styles.ScoreTile}
            formatType={scoreTileFormatType}
            disableAnimation
          />
        )}

        <BrandDatasetFilterItem
          name={heroBrand?.name || ''}
          brandId={heroBrand?.key || ''}
          logoUrl={heroBrand?.logoUrl}
          hideable={false}
        />
      </div>

      <hr />

      <div className={styles.CompetitorBrands}>
        {competitorBrands.map((brand) => (
          <div className={styles.BrandItem} key={brand.key}>
            {flags[FeatureFlag.ViewMetricsSocialMetrics] && (
              <ScoreTile
                variant={isSocialMetric ? scoreType : ScoreType.Raw}
                value={brand?.tile?.score}
                delta={brand?.tile?.delta}
                tooltip={brand?.tile?.tooltip}
                className={styles.ScoreTile}
                formatType={scoreTileFormatType}
                disableAnimation
              />
            )}
            <BrandDatasetFilterItem
              name={brand?.name || ''}
              brandId={brand?.key || ''}
              logoUrl={brand?.logoUrl}
              onFilterChange={handleFilterChange}
              checked={brandHideByBrandKey[brand?.key]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandDatasetFilter;
