import cn from 'classnames';
import { CircleXIcon } from '../../../../../../assets/icons';
import { TimeRange } from '../../../../../../interfaces/dashboard-api';
import WidgetSubheader from '../../../WidgetSubheader/WidgetSubheader';
import { timeRangeToNumDays } from '../../widgetFormatResponse';
import styles from './SingleMetricNoData.module.scss';

export interface SingleMetricNoDataProps {
  brandName?: string;
  brandLogoUrl?: string;
  activeTimeRange: TimeRange;
  activeEndDate: Date;
  handleTimeRangeChange: (option: TimeRange) => void;
  onCustomTimeRangeChange?: ({
    endDate,
    timeRange,
  }: {
    endDate: Date;
    timeRange: TimeRange | null;
  }) => void;
}

const NotAvailable = () => (
  <div className={styles.IconContainer}>
    <CircleXIcon className={styles.Icon} />
    <p>N/A</p>
  </div>
);

const SingleMetricNoData = ({
  brandName,
  brandLogoUrl,
  activeTimeRange,
  activeEndDate,
  handleTimeRangeChange,
  onCustomTimeRangeChange,
}: SingleMetricNoDataProps) => {
  const vsTimeframeText = `vs ${timeRangeToNumDays(activeTimeRange)} days ago`;
  return (
    <div className={styles.SingleMetricNoData}>
      <WidgetSubheader
        brandName={brandName}
        brandLogoUrl={brandLogoUrl}
        activeEndDate={activeEndDate}
        activeTimeRange={activeTimeRange}
        handleTimeRangeChange={handleTimeRangeChange}
        onCustomTimeRangeChange={onCustomTimeRangeChange}
      />
      <div className={styles.SummarySection}>
        <div className={styles.MetricContainer}>
          <div className={styles.MetricSection}>
            <p className={styles.Label}>N/A</p>
            <NotAvailable />
          </div>
          <div className={cn(styles.SubTitleContainer, styles.BlueBorder)}>
            <p className={styles.SubTitle}>Relative Performance</p>
            <p className={styles.SubTitle}>{vsTimeframeText}</p>
          </div>
        </div>

        <div className={styles.MetricContainer}>
          <div className={styles.MetricSection}>
            <p className={styles.Label}>N/A</p>
            <NotAvailable />
          </div>
          <div className={cn(styles.SubTitleContainer, styles.YellowBorder)}>
            <p className={styles.SubTitle}>Isolated Performance</p>
            <p className={styles.SubTitle}>{vsTimeframeText}</p>
          </div>
        </div>

        <div className={styles.MetricContainer}>
          <div className={styles.MetricSection}>
            <NotAvailable />
            <p className={styles.SubTitle}>
              Isolated Performance Vs Cohort Average{' '}
            </p>
          </div>

          <div className={styles.MetricSection}>
            <NotAvailable />
            <p className={styles.SubTitle}>
              Isolated Rate of Change Vs prev. 30 days
            </p>
          </div>
        </div>
      </div>

      <div className={styles.GraphSection}>
        <p className={styles.SubTitle}>Temporary Not Available</p>
      </div>
    </div>
  );
};

export default SingleMetricNoData;
