import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './TablePin.module.scss';
import { PushPinIcon } from '../../../../assets/icons';

export interface TablePinProps extends AnchorHTMLAttributes<HTMLDivElement> {
  active?: boolean;
}

const TablePin: FunctionComponent<TablePinProps> = ({
  className,
  active,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles.TablePin,
        active ? styles.Active : null,
        className
      )}
      {...props}
    >
      <PushPinIcon />
    </div>
  );
};

export default TablePin;
