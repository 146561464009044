import { FunctionComponent, useMemo } from 'react';
import classNames from 'classnames';
import { Chart, registerables } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import orderBy from 'lodash/orderBy';
import last from 'lodash/last';
import { ChartDataset, ChartColor, YAxisFormatter } from '../../../interfaces';
import { brandComparePieTooltipSingle } from '../../../chartjs/tooltips';

import styles from './BrandComparePieChart.module.scss';

Chart.register(...registerables);

export interface BrandComparePieChartProps {
  className?: string;
  brandsDatasets: ChartDataset[];
  chartColorMapByBrandKey: {
    [key: string]: ChartColor;
  };
  yAxisFormatter?: YAxisFormatter;
}

const BrandComparePieChart: FunctionComponent<BrandComparePieChartProps> = ({
  className,
  brandsDatasets,
  chartColorMapByBrandKey,
  yAxisFormatter,
}) => {
  const flattenedChartValues = useMemo(() => {
    const result: {
      brandKey: string;
      brandName: string;
      value?: number;
    }[] = [];

    brandsDatasets.forEach((brand) => {
      result.push({
        brandKey: brand.key,
        brandName: brand.name,
        value: last(brand.data)?.y,
      });
    });

    return orderBy(result, 'value', 'desc');
  }, [brandsDatasets]);

  const chartDatasets = useMemo(() => {
    return [
      {
        data: flattenedChartValues.map((brand) => {
          return {
            value: brand.value,
            name: brand.brandName,
          };
        }),
        backgroundColor: flattenedChartValues.map(
          (brand) => chartColorMapByBrandKey[brand.brandKey].DEFAULT
        ),
      },
    ];
  }, [flattenedChartValues, chartColorMapByBrandKey]);

  return (
    <div className={classNames(styles.BrandComparePieChart, className)}>
      <Pie
        data={{ datasets: chartDatasets }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          datasets: {
            pie: {
              borderWidth: 0,
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              external: (context) => {
                return brandComparePieTooltipSingle({
                  context,
                  yAxisFormatter,
                });
              },
            },
            datalabels: { display: false },
          },
        }}
      />
    </div>
  );
};

export default BrandComparePieChart;
