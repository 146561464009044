import capitalize from 'lodash/capitalize';
import find from 'lodash/find';

import {
  ChangeEvent,
  AnchorHTMLAttributes,
  FC,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { SingleValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';

import DropdownSelect from '../../../../Creative/DropdownSelect/DropdownSelect';
import { BrandDropdownOption } from '../../../types';

import { GET_FIRST_AND_LAST_SESSION } from '../../../../../../api/queries/DataManager';
import { GET_COMPETITORS } from '../../../../../../api/queries/Pages/MarketIndexCompare';

import {
  TimeRange,
  ViewType,
} from '../../../../../../interfaces/dashboard-api';

import {
  FlowKey,
  WidgetSettingsConfig,
} from '../../../../../../interfaces/widget-settings';

import styles from './ESOVSettingsForm.module.scss';
import useCompetitiveSetOptions from '../../../../../../hooks/useCompetitiveSetOptions';
import useBrandOptions from '../../../../../../hooks/useBrandOptions';

export interface ESOVSettingsFormProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  config: WidgetSettingsConfig;
  onConfigChange: (config: WidgetSettingsConfig) => void;
}

interface FormFields {
  widgetName: string;
  description: string;
  timeRange: TimeRange;
  brandKey: string;
  chartType: string;
  competitiveSetKey: string;
}

interface Option {
  label: string;
  value: string;
}

const ESOVSettingsForm: FC<ESOVSettingsFormProps> = ({
  config,
  onConfigChange,
}) => {
  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const {
    widgetName,
    timeRange,
    brandKey,
    description,
    chartType,
    competitiveSetKey,
  } = config[FlowKey.ESOVSettings];

  const [formData, setFormData] = useState<FormFields>({
    widgetName,
    timeRange,
    brandKey: (brandKey || (config.brands && config.brands[0].value)) ?? '',
    description,
    competitiveSetKey: competitiveSetKey ?? '',
    chartType: chartType || ViewType.GRID,
  });

  const handleConfigSave = useCallback(
    (newInputValues: FormFields) => {
      const newConfig = structuredClone(config);
      const esovConfig = newConfig[FlowKey.ESOVSettings];
      newConfig[FlowKey.ESOVSettings] = { ...esovConfig, ...newInputValues };

      onConfigChange(newConfig);
    },
    [config, onConfigChange]
  );

  const chartTypeOptions: BrandDropdownOption[] = Object.entries(
    ViewType
  ).reduce((options: BrandDropdownOption[], [label, value]) => {
    if (['GRID', 'LINE', 'BAR'].includes(value)) {
      options.push({
        label: capitalize(label),
        value,
      });
    }
    return options;
  }, []);

  const selectedChartOption = useMemo(() => {
    if (!formData.chartType) return null;

    return find(chartTypeOptions, { value: formData.chartType });
  }, [chartTypeOptions, formData.chartType]);

  /* Comp Set Selector Logic [start] */
  const competitiveSetOptions = useCompetitiveSetOptions();

  const selectedCompetitiveSetOption = useMemo(() => {
    if (!formData.competitiveSetKey && !competitiveSetOptions?.length)
      return null;

    return find(competitiveSetOptions, {
      value: formData.competitiveSetKey,
    });
  }, [competitiveSetOptions, formData]);

  // duplicated in SingleMetricsSettingsForm & GroupMetricSettingsGuide
  useEffect(() => {
    if (formData.competitiveSetKey || !competitiveSetOptions.length) return;

    const newInputValues = {
      ...formData,
      competitiveSetKey: competitiveSetOptions[0].value,
    };

    setFormData(newInputValues);
    handleConfigSave(newInputValues);
  }, [competitiveSetOptions, formData, handleConfigSave]);

  /* Comp Set Selector Logic [end] */

  /* Brand Selector Logic [start] */
  const brandOptions = useBrandOptions(competitorsResp);

  const selectedBrandOption = useMemo(() => {
    if (!formData.brandKey || !brandOptions.length) return null;

    return find(brandOptions, { value: formData.brandKey });
  }, [brandOptions, formData.brandKey]);

  /**
   * If no selected brand, default to the hero brand. This is to handle the case
   * where the user changes competitive set and the list of competitors changes.
   * duplicated in SingleMetricsSettingsForm & GroupMetricSettingsGuide
   */
  useEffect(() => {
    if (
      brandOptions?.length &&
      !find(brandOptions, {
        value: formData.brandKey,
      })
    ) {
      const newInputValues = {
        ...formData,
        brandKey: brandOptions[0].value,
      };

      setFormData(newInputValues);
      handleConfigSave(newInputValues);
    }
  }, [formData, brandOptions, handleConfigSave]);

  /**
   * Get the last session key when the selected competitive set changes.
   * duplicated in SingleMetricsSettingsForm & GroupMetricSettingsGuide
   */
  useEffect(() => {
    if (!selectedCompetitiveSetOption?.value) return;

    getFirstLastSession({
      variables: {
        id: selectedCompetitiveSetOption?.value,
      },
    });
  }, [selectedCompetitiveSetOption, getFirstLastSession]);

  /**
   * Get the competitors when the last session key and competitive set key changes.
   * duplicated in SingleMetricsSettingsForm & GroupMetricSettingsGuide
   */
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
        ?.sessionKey ||
      !selectedCompetitiveSetOption?.value
    )
      return;

    getCompetitors({
      variables: {
        id: selectedCompetitiveSetOption?.value,
        sessionKey:
          firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
            ?.sessionKey,
      },
    });
  }, [firstAndLastSessionResp, selectedCompetitiveSetOption, getCompetitors]);

  /**
   * If no selected brand, default to the hero brand
   */
  useEffect(() => {
    if (!formData.brandKey && brandOptions?.length) {
      const newInputValues = {
        ...formData,
        brandKey: brandOptions[0].value,
      };

      setFormData(newInputValues);
      handleConfigSave(newInputValues);
    }
  }, [brandOptions, formData, handleConfigSave]);

  /* Brand Selector Logic [end] */

  const handleCompetitiveSetChange = (newValue: SingleValue<Option>) => {
    const updatedCompetitiveSetKey = newValue?.value ?? '';
    const newFormData = {
      ...formData,
      competitiveSetKey: updatedCompetitiveSetKey,
      brandKey: '',
    };

    setFormData(newFormData);
    handleConfigSave(newFormData);
  };

  const handleBrandKeyChange = (selectedOption: SingleValue<Option>) => {
    const updateBrandKey = selectedOption?.value ?? '';
    const updatedFormData = { ...formData, brandKey: updateBrandKey };
    setFormData(updatedFormData);

    handleConfigSave(updatedFormData);
  };

  const handleChartType = (selectedOption: SingleValue<Option>) => {
    const updatedChartType = selectedOption?.value ?? '';
    const updatedFormData = { ...formData, chartType: updatedChartType };
    setFormData(updatedFormData);

    handleConfigSave(updatedFormData);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const newInputValues = {
      ...formData,
      [name]: value,
    };

    setFormData(newInputValues);
    handleConfigSave(newInputValues);
  };

  return (
    <div className={classNames(styles.ESOVSettingsForm)}>
      <p className={styles.Label}>
        WIDGET NAME
        <span className={styles.Required}>*</span>
      </p>

      <input
        className={styles.Field}
        required
        type="text"
        name="widgetName"
        value={formData.widgetName}
        onChange={handleInputChange}
      />

      <p className={styles.Label}>WIDGET DESCRIPTION</p>
      <input
        className={styles.Field}
        required
        type="text"
        name="description"
        value={
          formData.description ||
          'Widget predicts market growth based on visibility'
        }
        onChange={handleInputChange}
      />

      <p className={styles.Label}>DEFAULT CHART TYPE</p>
      <DropdownSelect
        className={styles.Dropdown}
        value={selectedChartOption}
        options={chartTypeOptions}
        onChange={handleChartType}
      />

      <p className={styles.Label}>COMPETITIVE SET</p>
      <DropdownSelect
        className={styles.Dropdown}
        value={selectedCompetitiveSetOption}
        options={competitiveSetOptions}
        onChange={handleCompetitiveSetChange}
      />

      <p className={styles.Label}>BRAND</p>
      <DropdownSelect
        className={styles.Dropdown}
        value={selectedBrandOption}
        options={brandOptions}
        onChange={handleBrandKeyChange}
      />
    </div>
  );
};

export default ESOVSettingsForm;
