import { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';

import {
  WidgetConfigProps,
  FlowKey,
} from '../../../../../interfaces/widget-settings';
import { WidgetType } from '../../../../../interfaces/dashboard-api';

import ESOVSettingsForm from '../../Widgets/ExcessShareOfVoice/SettingsForm/ESOVSettingsForm';

import styles from './SettingsGuide.module.scss';

const SettingsGuide: FunctionComponent<WidgetConfigProps> = ({
  config,
  onConfigChange,
  widgetType,
  onValidationChange,
}) => {
  const esovConfig = config[FlowKey.ESOVSettings];

  const renderSettingsForm = (id: unknown) => {
    switch (id) {
      case WidgetType.ExcessShareOfVoiceV1:
        return (
          <ESOVSettingsForm config={config} onConfigChange={onConfigChange} />
        );
      case WidgetType.SummaryTrendsV1:
          return <p>Summary Trends</p>;
      default:
        return <p>To Be Rendered</p>;
    }
  };

  useEffect(() => {
    const disableSave = !esovConfig.brandKey || !esovConfig.competitiveSetKey;

    onValidationChange({ [FlowKey.ESOVSettings]: disableSave });
  }, [esovConfig, onValidationChange]);

  return (
    <div className={classNames(styles.SettingsGuide)}>
      <div className={styles.SettingsForm}>
        {renderSettingsForm(widgetType)}
      </div>
    </div>
  );
};

export default SettingsGuide;
