import * as React from 'react';

function SvgSvg(props) {
  return (
    <svg viewBox="0 0 17 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-4-2h24v24H-4z" />
        <path
          d="M12.535 0H1.342C.597 0 0 .617 0 1.37v18.26C0 20.383.597 21 1.342 21h14.316c.745 0 1.342-.617 1.342-1.37V4.593c0-.36-.139-.706-.388-.963L13.491.405A1.33 1.33 0 0012.535 0z"
          fill="#CED9DB"
        />
        <path
          d="M1.342 1h11.194a.33.33 0 01.237.102l3.121 3.224c.067.07.106.166.106.267V19.63c0 .208-.157.37-.342.37H1.342C1.157 20 1 19.838 1 19.63V1.37c0-.208.157-.37.342-.37z"
          fill="#F4F7FA"
        />
        <path
          d="M12.5 9c.073 0 .143.016.205.044.093-.028.192-.044.295-.044h1a1 1 0 011 1v1a1 1 0 01-1 1h-1a1 1 0 01-1-1v-1h-1.5a2.5 2.5 0 00-2.495 2.336L8 12.5V14h1a1 1 0 011 1v1a1 1 0 01-1 1H8a1 1 0 01-1-1v-1a1 1 0 01.044-.296.449.449 0 01-.036-.114L7 14.5v-2a3.5 3.5 0 013.308-3.495L10.5 9h2zM9 15H8v1h1v-1zm3-11a2 2 0 11-1.57 3.24l-5.19 5.19a2 2 0 11-.935-.407l5.718-5.717A2 2 0 0112 4zm-7.393 9.205a1 1 0 10.1.088zM14 10h-1v1h1v-1zm-2-5a1 1 0 100 2 1 1 0 000-2z"
          fill="#73387D"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgSvg;
