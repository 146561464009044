import { GenericIconProps } from '../types';

const DragIcon = (props: GenericIconProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 6C14.6044 6 14.2178 6.1173 13.8889 6.33706C13.56 6.55682 13.3036 6.86918 13.1522 7.23463C13.0009 7.60009 12.9613 8.00222 13.0384 8.39018C13.1156 8.77814 13.3061 9.13451 13.5858 9.41421C13.8655 9.69392 14.2219 9.8844 14.6098 9.96157C14.9978 10.0387 15.3999 9.99913 15.7654 9.84776C16.1308 9.69638 16.4432 9.44004 16.6629 9.11114C16.8827 8.78224 17 8.39556 17 8C17 7.46957 16.7893 6.96086 16.4142 6.58579C16.0391 6.21071 15.5304 6 15 6ZM9 6C8.60444 6 8.21776 6.1173 7.88886 6.33706C7.55996 6.55682 7.30362 6.86918 7.15224 7.23463C7.00087 7.60009 6.96126 8.00222 7.03843 8.39018C7.1156 8.77814 7.30608 9.13451 7.58579 9.41421C7.86549 9.69392 8.22186 9.8844 8.60982 9.96157C8.99778 10.0387 9.39992 9.99913 9.76537 9.84776C10.1308 9.69638 10.4432 9.44004 10.6629 9.11114C10.8827 8.78224 11 8.39556 11 8C11 7.46957 10.7893 6.96086 10.4142 6.58579C10.0391 6.21071 9.53044 6 9 6ZM21 6C20.6044 6 20.2178 6.1173 19.8889 6.33706C19.56 6.55682 19.3036 6.86918 19.1522 7.23463C19.0009 7.60009 18.9613 8.00222 19.0384 8.39018C19.1156 8.77814 19.3061 9.13451 19.5858 9.41421C19.8655 9.69392 20.2219 9.8844 20.6098 9.96157C20.9978 10.0387 21.3999 9.99913 21.7654 9.84776C22.1308 9.69638 22.4432 9.44004 22.6629 9.11114C22.8827 8.78224 23 8.39556 23 8C23 7.46957 22.7893 6.96086 22.4142 6.58579C22.0391 6.21071 21.5304 6 21 6Z"
      fill="currentColor"
    />
    <path
      d="M1.88886 6.33706C2.21776 6.1173 2.60444 6 3 6C3.53043 6 4.03914 6.21071 4.41422 6.58579C4.78929 6.96086 5 7.46957 5 8C5 8.39556 4.8827 8.78224 4.66294 9.11114C4.44318 9.44004 4.13082 9.69638 3.76537 9.84776C3.39992 9.99913 2.99778 10.0387 2.60982 9.96157C2.22186 9.8844 1.86549 9.69392 1.58579 9.41421C1.30608 9.13451 1.1156 8.77814 1.03843 8.39018C0.96126 8.00222 1.00087 7.60009 1.15224 7.23463C1.30362 6.86918 1.55996 6.55682 1.88886 6.33706Z"
      fill="currentColor"
    />
    <path
      d="M1.88886 13.3371C2.21776 13.1173 2.60444 13 3 13C3.53043 13 4.03914 13.2107 4.41422 13.5858C4.78929 13.9609 5 14.4696 5 15C5 15.3956 4.8827 15.7822 4.66294 16.1111C4.44318 16.44 4.13082 16.6964 3.76537 16.8478C3.39992 16.9991 2.99778 17.0387 2.60982 16.9616C2.22186 16.8844 1.86549 16.6939 1.58579 16.4142C1.30608 16.1345 1.1156 15.7781 1.03843 15.3902C0.96126 15.0022 1.00087 14.6001 1.15224 14.2346C1.30362 13.8692 1.55996 13.5568 1.88886 13.3371Z"
      fill="currentColor"
    />
    <path
      d="M15 13C14.6044 13 14.2178 13.1173 13.8889 13.3371C13.56 13.5568 13.3036 13.8692 13.1522 14.2346C13.0009 14.6001 12.9613 15.0022 13.0384 15.3902C13.1156 15.7781 13.3061 16.1345 13.5858 16.4142C13.8655 16.6939 14.2219 16.8844 14.6098 16.9616C14.9978 17.0387 15.3999 16.9991 15.7654 16.8478C16.1308 16.6964 16.4432 16.44 16.6629 16.1111C16.8827 15.7822 17 15.3956 17 15C17 14.4696 16.7893 13.9609 16.4142 13.5858C16.0391 13.2107 15.5304 13 15 13ZM9 13C8.60444 13 8.21776 13.1173 7.88886 13.3371C7.55996 13.5568 7.30362 13.8692 7.15224 14.2346C7.00087 14.6001 6.96126 15.0022 7.03843 15.3902C7.1156 15.7781 7.30608 16.1345 7.58579 16.4142C7.86549 16.6939 8.22186 16.8844 8.60982 16.9616C8.99778 17.0387 9.39992 16.9991 9.76537 16.8478C10.1308 16.6964 10.4432 16.44 10.6629 16.1111C10.8827 15.7822 11 15.3956 11 15C11 14.4696 10.7893 13.9609 10.4142 13.5858C10.0391 13.2107 9.53044 13 9 13ZM21 13C20.6044 13 20.2178 13.1173 19.8889 13.3371C19.56 13.5568 19.3036 13.8692 19.1522 14.2346C19.0009 14.6001 18.9613 15.0022 19.0384 15.3902C19.1156 15.7781 19.3061 16.1345 19.5858 16.4142C19.8655 16.6939 20.2219 16.8844 20.6098 16.9616C20.9978 17.0387 21.3999 16.9991 21.7654 16.8478C22.1308 16.6964 22.4432 16.44 22.6629 16.1111C22.8827 15.7822 23 15.3956 23 15C23 14.4696 22.7893 13.9609 22.4142 13.5858C22.0391 13.2107 21.5304 13 21 13Z"
      fill="currentColor"
    />
  </svg>
);

export default DragIcon;
