import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import { SIZES } from '../../../constants/props';

import styles from './ScoreLegendItem.module.scss';
import { ScoreMapType } from '../../Pages/CustomizableDashboards/types';

function determineRangeDisplay(low, high) {
  return `${low}-${high}`;
}
export default function ScoreLegendItem({
  label,
  identifier,
  rangeLow,
  rangeHigh,
  threshold,
  size,
  type = ScoreMapType.WaterMap,
}) {
  const scoreLegendItemClasses = classNames(
    styles.ScoreLegendItem,
    styles[size],
    size === SIZES.SM ? styles.SM : null,
    size === SIZES.MD ? styles.MD : null
  );
  const rangeClasses = classNames(styles.range, styles[identifier]);

  return (
    <div
      data-jest="score-legend-item-container"
      className={scoreLegendItemClasses}
    >
      <Box mb={2} className={styles.LabelWrap}>
        <span data-jest="label" className={styles.label}>
          {label.toLowerCase()}
        </span>
      </Box>
      <div className={rangeClasses}>
        <span data-jest="range">
          {type === ScoreMapType.WaterMap
            ? determineRangeDisplay(rangeLow, rangeHigh)
            : threshold}
        </span>
      </div>
    </div>
  );
}

ScoreLegendItem.propTypes = {
  label: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  rangeLow: PropTypes.number,
  rangeHigh: PropTypes.number,
  threshold: PropTypes.string,
  size: PropTypes.string.isRequired,
  type: ScoreMapType,
};
