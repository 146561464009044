import { FC } from 'react';
import sumBy from 'lodash/sumBy';
import DiffPillMini from '../DifferencePill/DiffPillMini';
import { abbreviateNumber } from '../../../../utils/number';

import styles from './CreativeChannelsRowItem.module.scss';

import CreativeMiniLineChart from '../../../Charts/CreativeMiniLineChart/CreativeMiniLineChart';
import { CreativeChannelSpendAsset } from '../../../../interfaces/creative';
import { getInverseData } from '../../../Charts/CreativeMiniLineChart/helpers';

export interface CreativeChannelRowItemProps {
  data: CreativeChannelSpendAsset;
}

const CreativeChannelsRowItem: FC<CreativeChannelRowItemProps> = ({ data }) => {
  const sumTotals = abbreviateNumber(sumBy(data.data, 'y'));
  const inverseChartData = data.total === 0 ? [] : getInverseData(data.data);
  const chartData = [data.data, inverseChartData];

  return (
    <div
      className={styles.CreativeChannelRowItemContainer}
      data-cy="channel-row-item"
    >
      <p className={styles.Title} data-cy="channel-name">
        {data.name}
      </p>
      <div className={styles.CreativeChannelRowItem}>
        <div className={styles.Numbers}>
          <p
            className={styles.Total}
            data-cy="channel-title"
          >{`$${sumTotals}`}</p>

          <DiffPillMini percentage={data.diff} />
        </div>

        <div className={styles.ChartContainer}>
          <CreativeMiniLineChart data={chartData} fill />
        </div>
      </div>
    </div>
  );
};

export default CreativeChannelsRowItem;
