import * as React from 'react';

function SvgImage(props) {
  return (
    <svg viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-2-4h24v24H-2z" />
        <path d="M-2-4h24v24H-2z" />
        <path
          d="M3.333 0h13.334C18.507 0 20 1.522 20 3.4v10.2c0 1.878-1.492 3.4-3.333 3.4H3.333C1.493 17 0 15.478 0 13.6V3.4C0 1.522 1.492 0 3.333 0zM2 14h10L7 7l-5 7zm12.5-4a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
          fill="#20434A"
        />
      </g>
    </svg>
  );
}

export default SvgImage;
