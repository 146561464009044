import React from 'react';
import PropType from 'prop-types';
import RadioSelectorItem from '../../Atoms/RadioSelectorItem/RadioSelectorItem';

import styles from './RadioSelector.module.scss';

export default function RadioSelector({ items, value, onChange }) {
  function handleItemClick(item) {
    onChange(item.value);
  }

  return (
    <ul className={styles.RadioSelector} data-cy="radio-selector">
      {items.map((item) => (
        <RadioSelectorItem
          onClick={() => handleItemClick(item)}
          className={styles.Item}
          active={value === item.value}
          name={item.label}
          key={item.value}
          data-cy="radio-selector-item"
        />
      ))}
    </ul>
  );
}

RadioSelector.propTypes = {
  items: PropType.arrayOf(
    PropType.shape({
      label: PropType.string,
      value: PropType.oneOfType([PropType.string, PropType.number]),
    })
  ),
  value: PropType.oneOfType([PropType.string, PropType.number]),
  onChange: PropType.func,
};
