/* eslint-disable import/prefer-default-export */
import {
  TimeRange,
  ComparisonType,
} from '../../../../interfaces/dashboard-api';
import { DashboardDropdownItem } from '../types';
import { DropdownOption } from '../../Creative/DropdownSelect/DropdownSelect';
import { DashboardsMetricScore } from '../../../../interfaces/widget-settings';

export const TimeRangeOptions: DashboardDropdownItem[] = [
  { id: TimeRange['30_DAYS'], name: '30 days prior' },
  { id: TimeRange['60_DAYS'], name: '60 days prior' },
  { id: TimeRange['90_DAYS'], name: '90 days prior' },
];

export const DateRangeOptions: DropdownOption[] = [
  { label: '30 days prior', value: TimeRange['30_DAYS'] },
  { label: '60 days prior', value: TimeRange['60_DAYS'] },
  { label: '90 days prior', value: TimeRange['90_DAYS'] },
];

export const ScoreTypeOptions: DropdownOption[] = [
  { label: 'Relative Performance', value: DashboardsMetricScore.Relative },
  { label: 'Raw Performance', value: DashboardsMetricScore.Raw },
];

export const ComparisonTypeOptions: DropdownOption[] = [
  { label: 'vs. Leaders', value: ComparisonType.LEADERS },
  { label: 'vs. Nearest', value: ComparisonType.NEAREST },
  { label: 'vs. Rivals', value: ComparisonType.RIVALS },
];

export const timeRangeById = {
  [TimeRange['30_DAYS']]: {
    value: 30,
  },
  [TimeRange['60_DAYS']]: {
    value: 60,
  },
  [TimeRange['90_DAYS']]: {
    value: 90,
  },
  [TimeRange['365_DAYS']]: {
    value: 365,
  },
};
