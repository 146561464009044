import type { ChangeEvent, FunctionComponent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import { useLazyQuery } from '@apollo/client';
import type { SingleValue } from 'react-select';
import DropdownSelect, {
  DropdownOption,
} from '../../../Creative/DropdownSelect/DropdownSelect';
import {
  DashboardsMetricScore,
  FlowKey,
  WidgetConfigProps,
  WidgetSettingsConfig,
} from '../../../../../interfaces/widget-settings';
import { TimeRange } from '../../../../../interfaces/dashboard-api';
import { DateRangeOptions, ScoreTypeOptions } from '../../WidgetSettingsShared';
import { BrandDropdownOption, WidgetSettingsModalAction } from '../../types';
import RivalBrandsForm from '../PaceAnalysisSettingsGuide/RivalBrandsForm/RivalBrandsForm';
import useCompetitiveSetOptions from '../../../../../hooks/useCompetitiveSetOptions';
import useBrandOptions from '../../../../../hooks/useBrandOptions';
import { getMetrics } from '../../../../../mocks/data/CustomizableDashboards/metrics';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/Pages/CustomizableDashboards';
import { GET_COMPETITORS } from '../../../../../api/queries/Pages/MarketIndexCompare';
import { FeatureFlag } from '../../../../../utils/featureFlags';
import DateRangeSelectorDropdown from '../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';

import styles from './StackRankingSettingsGuide.module.scss';

const metricById = keyBy(getMetrics, 'id');

const StackRankingSettingsGuide: FunctionComponent<WidgetConfigProps> = ({
  config,
  settingAction,
  onConfigChange,
  onValidationChange,
}) => {
  const {
    name,
    description,
    timeRange,
    competitiveSetKey,
    brandKey,
    scoreType,
    competitorBrands,
    endDate,
  } = config[FlowKey.StackRankingSettings];
  const metric = config[FlowKey.SelectMetric];

  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );
  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    { fetchPolicy: 'no-cache' }
  );

  const [inputValues, setInputValues] = useState<
    WidgetSettingsConfig[FlowKey.StackRankingSettings]
  >({
    name:
      settingAction === WidgetSettingsModalAction.Add
        ? `${metric.name} Ranking`
        : name,
    description,
    timeRange,
    endDate,
    competitiveSetKey,
    brandKey,
    scoreType,
    competitorBrands,
  });

  const competitiveSetOptions = useCompetitiveSetOptions();
  const brandOptions = useBrandOptions(competitorsResp);

  const selectedCompetitorOption = useMemo(() => {
    if (!inputValues.competitiveSetKey && !competitiveSetOptions.length)
      return null;

    return find(competitiveSetOptions, {
      value: inputValues.competitiveSetKey,
    });
  }, [competitiveSetOptions, inputValues.competitiveSetKey]);

  const selectedTimeRange = useMemo(
    () => DateRangeOptions.find(({ value }) => value === inputValues.timeRange),
    [inputValues.timeRange]
  );

  let scoreTypeOptions = ScoreTypeOptions;
  const hasRawData = metricById[metric.id]?.is_raw_score_available;
  if (!hasRawData) {
    scoreTypeOptions = scoreTypeOptions.filter(
      (option) => option.value !== DashboardsMetricScore.Raw
    );
  }

  const selectedScoreType = useMemo(() => {
    const selected = find(scoreTypeOptions, { value: inputValues.scoreType });
    return selected ?? scoreTypeOptions[0];
  }, [inputValues.scoreType, scoreTypeOptions]);

  const selectedRivalBrands = new Set(inputValues.competitorBrands);

  /* Event Handlers */
  const handleConfigSave = useCallback(
    (newInputValues: WidgetSettingsConfig[FlowKey.StackRankingSettings]) => {
      const newConfig = structuredClone(config);
      const settingsConfig = newConfig[FlowKey.StackRankingSettings];
      newConfig[FlowKey.StackRankingSettings] = {
        ...settingsConfig,
        ...newInputValues,
      };

      onConfigChange(newConfig);
    },
    [config, onConfigChange]
  );

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    const updatedInputValues = {
      ...inputValues,
      [key]: value,
    };

    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  };

  const handleDropdownChange = (
    updatedOption: SingleValue<DropdownOption>,
    field: string
  ) => {
    if (!updatedOption || !updatedOption?.value) return;

    const newInputValues = {
      ...inputValues,
      [field]: updatedOption.value,
    };

    if (field === 'brandKey' || field === 'competitiveSetKey') {
      newInputValues.competitorBrands = [];
    }

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    const newInputValues = {
      ...inputValues,
      timeRange: (e.timeRange as TimeRange) ?? TimeRange['90_DAYS'],
      endDate: e.endDate,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleRivalBrandsChange = (brand: BrandDropdownOption) => {
    const newRivalBrands = new Set(inputValues.competitorBrands);
    if (newRivalBrands.has(brand.value)) {
      newRivalBrands.delete(brand.value);
    } else {
      newRivalBrands.add(brand.value);
    }

    const newInputValues = {
      ...inputValues,
      competitorBrands: Array.from(newRivalBrands),
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  /* Effects */
  // if no competitiveSetKey, set first as default competitiveSetKey
  useEffect(() => {
    if (inputValues.competitiveSetKey || !competitiveSetOptions.length) return;

    const updatedInputValues = {
      ...inputValues,
      competitiveSetKey: competitiveSetOptions[0].value,
    };
    setInputValues(updatedInputValues);
    handleConfigSave(updatedInputValues);
  }, [competitiveSetOptions, handleConfigSave, inputValues]);

  // if no brandKey, set first as default brandKey
  useEffect(() => {
    if (
      brandOptions.length &&
      !find(brandOptions, { value: inputValues.brandKey })
    ) {
      const updatedInputValues = {
        ...inputValues,
        rivalBrands: [],
        brandKey: brandOptions[0].value,
      };
      setInputValues(updatedInputValues);
      handleConfigSave(updatedInputValues);
    }
  }, [brandOptions, handleConfigSave, inputValues]);

  // if no competitiveSet get required sessions
  useEffect(() => {
    if (!selectedCompetitorOption?.value) return;

    getFirstLastSession({ variables: { id: selectedCompetitorOption.value } });
  }, [getFirstLastSession, selectedCompetitorOption]);

  // get cometitiveSet with new SessionKey
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
        ?.sessionKey ||
      !selectedCompetitorOption?.value
    ) {
      return;
    }

    const { sessionKey } =
      firstAndLastSessionResp.competitiveSet.firstAndLastSession[1];
    getCompetitors({
      variables: { id: selectedCompetitorOption.value, sessionKey },
    });
  }, [firstAndLastSessionResp, getCompetitors, selectedCompetitorOption]);

  // validate required fields
  useEffect(() => {
    const disableSave =
      !name ||
      !description ||
      !timeRange ||
      !competitiveSetKey ||
      !brandKey ||
      !scoreType ||
      competitorBrands.length === 0;

    onValidationChange({
      [FlowKey.StackRankingSettings]: disableSave,
    });
  }, [
    onValidationChange,
    name,
    description,
    timeRange,
    competitiveSetKey,
    brandKey,
    scoreType,
    competitorBrands,
  ]);

  return (
    <div className={styles.StackRankingSettingsGuide}>
      <div className={styles.Group}>
        <p className={styles.Label}>
          WIDGET NAME
          <span className={styles.Required}>*</span>
        </p>
        <input
          className={styles.Field}
          required
          type="text"
          name="name"
          value={inputValues.name}
          onChange={handleNameChange}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>SCORE TYPE</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={scoreTypeOptions}
          onChange={(e) => handleDropdownChange(e, 'scoreType')}
          value={selectedScoreType}
        />
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>DATE RANGE</p>
        {showCustomDateRangeSelector ? (
          <DateRangeSelectorDropdown
            onApply={handleApply}
            timeRange={timeRange}
            endDate={endDate}
            fullWidth
          />
        ) : (
          <DropdownSelect
            className={styles.Dropdown}
            options={DateRangeOptions}
            onChange={(e) => handleDropdownChange(e, 'timeRange')}
            value={selectedTimeRange}
          />
        )}
      </div>

      <div className={styles.Group}>
        <p className={styles.Label}>COMPETITIVE SET</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={competitiveSetOptions}
          onChange={(e) => handleDropdownChange(e, 'competitiveSetKey')}
          value={selectedCompetitorOption}
        />
      </div>

      <RivalBrandsForm
        brands={brandOptions}
        selectedBrand={inputValues.brandKey}
        selectedRivals={selectedRivalBrands}
        handleBrandSelect={handleRivalBrandsChange}
        maxSelected={brandOptions.length}
        label="SELECT COMPARISON BRANDS"
      />
    </div>
  );
};

export default StackRankingSettingsGuide;
