import { GenericIconProps } from '../types';

const ChartPieIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Icon/Chart/Pie">
      <g id="Triangle">
        <circle id="Mask" cx="12" cy="12" r="10" fill="#20434A" />
        <mask
          id="mask0_223_275"
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <circle id="Mask_2" cx="12" cy="12" r="10" fill="white" />
        </mask>
        <g mask="url(#mask0_223_275)">
          <path
            id="Triangle Copy"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 1L3 3L1 12H12V1Z"
            fill="#CED9DB"
          />
          <path
            id="Triangle_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 -1L22 3L23 16.5L20 20L12 12V-1Z"
            fill="#7E9397"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ChartPieIcon;
