import { GenericIconProps } from './types';

function CopyLink(props: GenericIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V18C0 20.2091 1.79086 22 4 22H18C20.2091 22 22 20.2091 22 18V4C22 1.79086 20.2091 0 18 0H4ZM11.1528 10.14C10.957 10.3348 10.9562 10.6514 11.1509 10.8472C12.0595 11.7607 12.0583 13.237 11.1482 14.149L8.98044 16.3215C8.07567 17.2282 6.60857 17.2343 5.69601 16.335C4.78683 15.4387 4.77636 13.9751 5.67262 13.0659L7.85607 10.851C8.04993 10.6544 8.04767 10.3378 7.85101 10.1439C7.65436 9.95007 7.33779 9.95233 7.14393 10.149L4.96048 12.3639C3.67649 13.6664 3.69149 15.7632 4.99431 17.0475C6.29828 18.3325 8.39515 18.3238 9.68831 17.0278L11.8561 14.8553C13.1552 13.5534 13.1569 11.446 11.8599 10.142C11.6652 9.94618 11.3486 9.94532 11.1528 10.14ZM10.6859 11.8601C10.8817 11.6653 10.8826 11.3488 10.6879 11.153C9.77867 10.2388 9.77891 8.76187 10.6884 7.84801L12.777 5.74933C13.7399 4.78183 15.3 4.763 16.2859 5.70698C16.3176 5.73733 16.3485 5.76859 16.3784 5.80072C17.2373 6.72367 17.1853 8.16813 16.2624 9.02702L13.9982 11.1341C13.796 11.3222 13.7846 11.6386 13.9728 11.8407C14.1609 12.0429 14.4772 12.0543 14.6794 11.8661L16.9436 9.75908C18.2709 8.52395 18.3456 6.44673 17.1104 5.11948C17.0674 5.07327 17.0231 5.02832 16.9775 4.98466C15.5981 3.66396 13.4154 3.69031 12.0682 5.04392L9.97959 7.1426C8.68193 8.4465 8.68159 10.5538 9.97884 11.8581C10.1736 12.0539 10.4902 12.0548 10.6859 11.8601Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CopyLink;
