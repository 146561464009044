import React from 'react';
import PropTypes from 'prop-types';
import VerbatimTag from '../../Atoms/VerbatimTag/VerbatimTag';
// import ThumbsUpIcon from '../../../assets/icons/IconThumbUp.jsx';
// import ThumbsDownIcon from '../../../assets/icons/IconThumbDown.jsx';
import styles from './VerbatimCustomer.module.scss';

export default function VerbatimCustomer({ content = '', source }) {
  // const positiveOrNegative = 'positive';
  // todo: add logic to parse verbatim data
  return (
    <div className={styles.VerbatimCustomer}>
      <div className={styles.Image}>
        {/* {
          positiveOrNegative === 'positive'
            ? (
              <div className={styles.Positive}>
                <ThumbsUpIcon />
              </div>
            )
            : (
              <div className={styles.Negative}>
                <ThumbsDownIcon />
              </div>
            )} */}
      </div>
      <div className={styles.Content}>
        <p>{content}</p>
      </div>
      <div className={styles.Footer}>
        {/* <p className={styles.Date}>
          5/5/2020
        </p> */}
        {source && <VerbatimTag className={styles.Tag} source={source} />}
      </div>
    </div>
  );
}

VerbatimCustomer.propTypes = {
  content: PropTypes.string,
  source: PropTypes.string,
};
