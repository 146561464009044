import { useState, useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';

import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import isEmpty from 'lodash/isEmpty';

import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import InsightsCTA from '../../Molecules/InsightsCTA/InsightsCTA';
import ScoreLegend from '../../Molecules/ScoreLegend/ScoreLegend';
import RadioDateSelector from '../../Molecules/RadioDateSelector/RadioDateSelector';
import FactorCard from '../../Molecules/FactorCard/FactorCard';
import SubfactorBarChart from '../../Charts/SubfactorBarChart/SubfactorBarChart';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import ChartLegend from '../../Molecules/ChartLegend/ChartLegend';

import {
  GET_FACTORS,
  GET_SUBFACTORS,
} from '../../../api/queries/Pages/MarketIndexFactorsOverview';
import QUERY_PARAMS from '../../../constants/queryParams';
import { prepareInsight } from '../../../api/transforms/Molecules/InsightCTA';
import {
  prepareFactors,
  prepareSubfactorScores,
  prepareTooltipDataBySubfactor,
  perpareFactorTimelineData,
} from '../../../api/transforms/Pages/MarketIndexFactorsOverview';

import BNContext from '../../../contexts/BNContext';
import useRouter from '../../../hooks/useRouter';
import { CHART_LEGEND_ICON_VARIANT } from '../../../constants/props';

import styles from './MarketIndexFactorsOverview.module.scss';

import { handleGenericError } from '../../../utils/error';

export default function MarketIndexFactorsOverview({
  disableCache = false,
  loading,
}) {
  const { updateRoute } = useRouter();
  const [factorsDisplayId] = useState('factors-display');

  const {
    timeframe,
    competitiveSetID: _competitiveSetId,
    currentSession: sessionKey,
    accountKey,
  } = useContext(BNContext);

  const [getFactors, { loading: gettingFactors, error, data }] =
    useLazyQuery(GET_FACTORS);

  const [
    getSubFactorData,
    {
      loading: gettingSubFactors,
      error: subFactorsError,
      data: subFactorsData,
    },
  ] = useLazyQuery(GET_SUBFACTORS);

  useEffect(() => {
    if (!_competitiveSetId || !sessionKey) {
      return;
    }

    const queryOptions = {
      variables: {
        id: _competitiveSetId,
        sessionKey,
        accountKey,
      },
    };

    const subFactorsQueryOptions = {
      variables: {
        id: _competitiveSetId,
        sessionKey,
      },
    };

    if (disableCache) {
      queryOptions.fetchPolicy = 'no-cache';
    }

    getFactors(queryOptions);
    getSubFactorData(subFactorsQueryOptions);
  }, [
    getFactors,
    getSubFactorData,
    _competitiveSetId,
    sessionKey,
    accountKey,
    disableCache,
  ]);

  const insight = useMemo(
    () =>
      handleGenericError(
        () => prepareInsight(data),
        'MarketIndexFactorsOverview prepareInsight failed transform'
      ),
    [data]
  );

  const factors = useMemo(
    () =>
      handleGenericError(
        () => prepareFactors(data),
        'MarketIndexFactorsOverview prepareFactors failed transform'
      ),
    [data]
  );

  const subfactorScores = useMemo(
    () =>
      handleGenericError(
        () => prepareSubfactorScores(subFactorsData, timeframe),
        'MarketIndexFactorsOverview prepareSubfactorScores failed transform'
      ),
    [subFactorsData, timeframe]
  );

  const tooltipDataBySubfactor = useMemo(
    () =>
      handleGenericError(
        () => prepareTooltipDataBySubfactor(subFactorsData, timeframe),
        'MarketIndexFactorsOverview prepareTooltipDataBySubfactor failed transform'
      ),
    [subFactorsData, timeframe]
  );

  const factorMap = useMemo(
    () =>
      handleGenericError(
        () => perpareFactorTimelineData(data, timeframe),
        'MarketIndexFactorsOverview perpareFactorTimelineData failed transform'
      ),
    [data, timeframe]
  );

  const graphScale = useMemo(() => {
    const result = {
      yDomain: { min: Number.MAX_SAFE_INTEGER, max: -Number.MAX_SAFE_INTEGER },
    };

    if (!factorMap || !Object.keys(factorMap).length) {
      return result;
    }

    result.yDomain.min = null;
    result.yDomain.max = null;

    Object.values(factorMap).forEach((factor) => {
      if (!factor || !factor.scoreOverTime || !factor.scoreOverTime.length) {
        return;
      }

      const factorMin = minBy(factor.scoreOverTime, 'value').value;
      const factorMax = maxBy(factor.scoreOverTime, 'value').value;

      if (result.yDomain.min === null) {
        result.yDomain.min = factorMin;
      } else {
        result.yDomain.min = Math.min(factorMin, result.yDomain.min);
      }

      if (result.yDomain.max === null) {
        result.yDomain.max = factorMax;
      } else {
        result.yDomain.max = Math.max(factorMax, result.yDomain.max);
      }
    });

    return result;
  }, [factorMap]);

  const isLoading = useMemo(
    () =>
      loading ||
      gettingFactors ||
      gettingSubFactors ||
      !insight ||
      !factors ||
      !subfactorScores ||
      !factorMap ||
      !graphScale,
    [
      loading,
      gettingFactors,
      gettingSubFactors,
      insight,
      factors,
      subfactorScores,
      factorMap,
      graphScale,
    ]
  );

  const isSubfactorScoresLoading = useMemo(() => {
    return isEmpty(subfactorScores) || gettingSubFactors;
  }, [subfactorScores, gettingSubFactors]);

  function handleFactorCardClick(factorId) {
    updateRoute({ params: { [QUERY_PARAMS.FACTOR_ID]: factorId } });
  }

  if (error || subFactorsError) {
    return <GenericErrorCopy />;
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      spacing={10}
      className={styles.MarketIndexFactorsOverview}
    >
      <Grid item xs={12} md={9}>
        <div id={factorsDisplayId}>
          <Box
            data-testid="market-index-subfactors-title-container"
            className={classNames(styles.TitleContainer, 'remove-from-export')}
          >
            <h1
              className={styles.Title}
              id="market-index-title-factor-overview"
            >
              How does your brand perform across key factors and subfactors?
            </h1>

            <DownloadDropdown
              targetId={factorsDisplayId}
              fileName="factors"
              padding={100}
            />
          </Box>

          <Box
            className={classNames(
              styles.SectionDescription,
              'remove-from-export'
            )}
          >
            <p className={styles.Paragraph}>
              The Factor and Subfactor analysis provides practitioners with
              direct and actionable metrics to create GTM plans for every team
              in your marketing organization. It’s equally suited for near-term
              optimization and mid-term strategic planning.
            </p>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              {isSubfactorScoresLoading ? (
                <ComponentLoader minHeight={400} />
              ) : (
                <div
                  className={styles.SubfactorChartContainer}
                  data-testid="subfactor-bar-chart"
                >
                  <SubfactorBarChart
                    subfactorScores={subfactorScores}
                    tooltipDataBySubfactor={tooltipDataBySubfactor}
                  />
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <div
                className={styles.ChartLegendContainer}
                data-testid="chart-legend-container"
              >
                <ChartLegend
                  fontSize={14}
                  items={[
                    {
                      variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
                      label: 'Competitor Average',
                      color: { DEFAULT: 'black', FILL: 'black' },
                    },
                  ]}
                />
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            className={classNames(styles.Top, 'SectionContainer')}
            justifyContent="space-between"
          >
            <Grid
              container
              item
              xs={12}
              lg={6}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box
                mb={6}
                className={styles.ScoreLegendContainer}
                data-testid="score-legend-container"
              >
                <ScoreLegend />
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={6}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box mb={6} data-testid="radio-date-selector">
                <RadioDateSelector />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={4}
            className="SectionContainer"
            data-testid="factor-card-container"
          >
            {isLoading ? (
              <Grid item xs={12}>
                <ComponentLoader minHeight={400} />
              </Grid>
            ) : null}

            {!isLoading && factorMap
              ? factorMap.map((factor) => (
                  <Grid key={factor.factorType} item xs={12} md={6}>
                    <FactorCard
                      data-cy={`factor-card-${factor.factorType}`}
                      factorType={factor.factorType}
                      score={factor.score}
                      competitorAverageScore={factor.competitorAverageScore}
                      delta={factor.delta}
                      scoreOverTime={factor.scoreOverTime}
                      graphScale={graphScale}
                      onNameClick={() => handleFactorCardClick(factor.id)}
                    />
                  </Grid>
                ))
              : null}

            {!isLoading && !factorMap ? (
              <Grid item xs={12}>
                <p>No Data</p>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            container
            alignItems="center"
            className={classNames('SubtitleContainer', 'remove-from-export')}
          >
            <Grid item xs={11}>
              <h2 className={styles.Subtitle}>
                What the Factor and Subfactor Analysis Answers:
              </h2>
            </Grid>
          </Grid>

          <Box
            mb={1}
            className={classNames(
              styles.SectionDescription,
              'remove-from-export'
            )}
          >
            <p className={styles.Paragraph}>
              Where and how big is the gap between your brand and competitors?
            </p>
            <p className={styles.Paragraph}>
              How effective is your current brand strategy?
            </p>
            <p className={styles.Paragraph}>
              What marketing channels present quick win opportunities?
            </p>
            <p className={styles.Paragraph}>
              Where do you need to invest more mid-long term?
            </p>
          </Box>
        </div>
      </Grid>

      <Grid item xs={12} md={3}>
        <InsightsCTA
          data-testid="insights-cta"
          insight={insight}
          loading={isLoading}
          data={data?.competitiveSet?.session?.blueScore?.recommendations}
        />
      </Grid>
    </Grid>
  );
}

MarketIndexFactorsOverview.propTypes = {
  disableCache: PropTypes.bool,
  loading: PropTypes.bool,
};
