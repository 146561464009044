/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import BNContext, { GlobalTimeframe } from '../contexts/BNContext';

dayjs.extend(quarterOfYear);

export interface TimeRangePreset {
  isNonSessionPath: boolean;
  minDate: Date;
}

const NonSessionTimeLocations: { [pathname: string]: boolean } = {
  '/creative/channels/overview': true,
  '/creative/channels/advertising': true,
  '/creative/channels/website/brand': true,
  '/creative/channels/website/all-brands': true,
  '/creative/channels/website/compare': true,
};

const useTimelimits = () => {
  const { pathname } = useLocation();
  const {
    timeframe,
    setTimeframe,
    oldestSession,
    nonSessionTimeframe,
    setNonSessionTimeframe,
  } = useContext(BNContext);
  const isNonSessionPath = Boolean(NonSessionTimeLocations[pathname]);
  const [selectedTimeframe, setSelectedTimeframe] = useState<
    GlobalTimeframe | undefined
  >(timeframe);

  const fourQuartersAgo = dayjs()
    .subtract(4, 'quarters')
    .startOf('quarter')
    .toDate();

  const minDate = useMemo(() => {
    if (!isNonSessionPath && oldestSession) {
      return oldestSession.date;
    }

    return fourQuartersAgo;
  }, [fourQuartersAgo, isNonSessionPath, oldestSession]);

  // set selectedTimeframe
  useEffect(() => {
    if (isNonSessionPath) {
      setSelectedTimeframe(nonSessionTimeframe);
    }

    if (!isNonSessionPath) {
      setSelectedTimeframe(timeframe);
    }
  }, [isNonSessionPath, nonSessionTimeframe, selectedTimeframe, timeframe]);

  const handleTimeframeApply = (newTimeframe: GlobalTimeframe) => {
    if (!newTimeframe.label) newTimeframe.label = 'Custom date range';
    if (setTimeframe) setTimeframe({ ...newTimeframe });
  };

  const handleNonSessionTimeframeApply = (newTimeframe: GlobalTimeframe) => {
    if (!newTimeframe.label) newTimeframe.label = 'Custom date range';
    if (setNonSessionTimeframe) setNonSessionTimeframe({ ...newTimeframe });
  };

  const disableApply = (pendingTimeframe: GlobalTimeframe): boolean => {
    if (isNonSessionPath) {
      return (
        pendingTimeframe.quantity === nonSessionTimeframe?.quantity &&
        pendingTimeframe.unit === nonSessionTimeframe?.unit &&
        dayjs(pendingTimeframe.end).isSame(nonSessionTimeframe?.end)
      );
    }
    return (
      pendingTimeframe.quantity === timeframe?.quantity &&
      pendingTimeframe.unit === timeframe?.unit &&
      dayjs(pendingTimeframe.end).isSame(timeframe?.end)
    );
  };

  return {
    isNonSessionPath,
    selectedTimeframe,
    minDate,
    disableApply,
    handleTimeframeApply,
    handleNonSessionTimeframeApply,
  };
};

export default useTimelimits;
