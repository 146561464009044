import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import debounce from 'lodash/debounce';
import {
  FACTOR_RANGE_DISPLAY,
  FACTOR_RANGES,
} from '../../../constants/factors';
import { POSITIONS, SIZES } from '../../../constants/props';
import { getFactorRangeScaleIdx } from '../../../utils/factors';
import CompetitionGradientBarPin from '../../Atoms/CompetitionGradientBarPin/CompetitionGradientBarPin';
import useElementResize from '../../../hooks/useElementResize';

import styles from './CompetitionGradientBar.module.scss';

const POINTER_WIDTH = 20;
const LOGO_WIDTH = 40;
const DIVIDER_WIDTH = 1;

/**
 * A responsive bar that shows brand performance relative to other brands based on score.
 */
export default function CompetitionGradientBar({
  topPins,
  bottomPins,
  withTooltip = false,
}) {
  const dividersRef = useRef(null);
  const [bottomPinData, setBottomPinData] = useState();
  const [topPinData, setTopPinData] = useState();

  const groupPins = useCallback((pinData) => {
    const pinDataClone = cloneDeep(pinData);
    const result = [];

    let i = 0;
    let pin = 0;

    while (i < pinDataClone.length) {
      result.push(pinDataClone[i]);

      let xAverage = pinDataClone[i].x;

      while (
        detectCollision(pinDataClone[i], pinDataClone[i + 1], 'pointerWidth')
      ) {
        result[pin].brands = result[pin].brands.concat(
          pinDataClone[i + 1].brands
        );
        xAverage += pinDataClone[i + 1].x;
        i++;
      }

      result[pin].logoWidth = result[pin].brands.length * LOGO_WIDTH;
      result[pin].pointerWidth = POINTER_WIDTH;
      result[pin].x = Math.round(xAverage / result[pin].brands.length);

      i++;
      pin++;
    }

    return result;
  }, []);

  const adjustPinElevation = useCallback((pinData) => {
    if (!pinData) {
      return;
    }

    const pinDataClone = cloneDeep(pinData);

    let i = 0;
    while (i + 1 < pinDataClone.length) {
      const pin1 = pinDataClone[i];
      const pin2 = pinDataClone[i + 1];

      if (pin1.level === 1 && detectCollision(pin1, pin2, 'logoWidth')) {
        pin2.level = 2;
      }
      i++;
    }

    return pinDataClone;
  }, []);

  const getPinData = useCallback((dimensionData, pinData) => {
    if (!dividersRef.current) {
      return;
    }
    const result = [];

    pinData.forEach((pin) => {
      const x = calculatePinPosition(pin.score, dimensionData);

      result.push({
        x: Math.round(x),
        level: 1,
        brands: [pin],
        logoWidth: LOGO_WIDTH,
        pointerWidth: POINTER_WIDTH,
      });
    });

    return sortBy(result, ['x']);
  }, []);

  const setPins = useCallback(() => {
    const dimensionData = getDimensionData();

    [topPins, bottomPins].forEach((pins) => {
      const pinData = getPinData(dimensionData, pins);
      const groupedPins = groupPins(pinData);
      const elevatedPins = adjustPinElevation(groupedPins);

      if (pins === topPins) {
        setTopPinData(elevatedPins);
      }

      if (pins === bottomPins) {
        setBottomPinData(elevatedPins);
      }
    });
  }, [topPins, bottomPins, adjustPinElevation, getPinData, groupPins]);

  const debouncedSetPins = useMemo(() => debounce(setPins, 100), [setPins]);

  useElementResize(
    window,
    () => {
      debouncedSetPins();
    },
    [setPins]
  );

  useEffect(() => {
    setPins();
  }, [setPins]);

  useEffect(() => {
    return () => {
      debouncedSetPins.cancel();
    };
  }, [debouncedSetPins]);

  function getDimensionData() {
    if (!dividersRef || !dividersRef.current || !dividersRef.current.children) {
      return;
    }

    const result = {
      bar: {
        h: dividersRef.current.clientHeight,
        w: dividersRef.current.clientWidth,
      },
      dividers: [],
    };

    let pxFromLeft = 0;
    forEach(dividersRef.current.children, (child) => {
      result.dividers.push({
        h: child.clientHeight,
        w: child.clientWidth,
        x: pxFromLeft,
      });

      pxFromLeft += child.clientWidth + DIVIDER_WIDTH;
    });

    return result;
  }

  function detectCollision(pin1, pin2, widthType) {
    if (!pin1 || !pin2) {
      return false;
    }

    if (Math.round(pin1.x) === Math.round(pin2.x)) {
      return true;
    }

    const pin1AdjustedX = Math.round(pin1.x - pin1[widthType] / 2);
    const pin2AdjustedX = Math.round(pin2.x - pin2[widthType] / 2);

    if (
      pin1AdjustedX < pin2AdjustedX + pin2[widthType] &&
      pin1AdjustedX + pin1[widthType] > pin2AdjustedX
    ) {
      return true;
    }

    return false;
  }

  function calculatePinPosition(score, dimensionData) {
    const rangeIdx = getFactorRangeScaleIdx(score);
    const RANGE = FACTOR_RANGES[rangeIdx];
    const dimensions = dimensionData.dividers[rangeIdx];
    const x =
      ((score - RANGE.FLOOR) / (RANGE.CEIL - RANGE.FLOOR)) * dimensions.w +
      dimensions.x;

    return x;
  }

  function placePins(pinData, { size, position, className }) {
    if (!pinData) {
      return null;
    }

    return pinData.map((pin, key) => {
      const pinClasses = classNames(styles.Pin, className);

      return (
        // false positive for react/no-array-index-key
        // eslint-disable-next-line
        <div key={key} className={pinClasses} style={{ left: `${pin.x}px` }}>
          <CompetitionGradientBarPin
            position={position}
            size={size}
            brands={pin.brands}
            level={pin.level}
            withTooltip={withTooltip}
          />
        </div>
      );
    });
  }

  return (
    <div className={styles.Wrap}>
      <div className={styles.GradientBar}>
        <ul className={styles.Dividers} ref={dividersRef}>
          {FACTOR_RANGES.map((RANGE) => (
            <li key={RANGE.KEY} className={styles.Divider} />
          ))}
        </ul>
        {placePins(bottomPinData, {
          size: SIZES.SM,
          position: POSITIONS.BOTTOM,
          className: styles.Bottom,
        })}
        {placePins(topPinData, {
          size: SIZES.SM,
          position: POSITIONS.TOP,
          className: styles.Top,
        })}
      </div>
      <div className={styles.LimitsWrap}>
        <div className={styles.LimitsLeft}>
          <div className={styles.Amount}>{FACTOR_RANGE_DISPLAY.MIN}</div>
        </div>
        <div className={styles.LimitsRight}>
          <div className={styles.Amount}>{FACTOR_RANGE_DISPLAY.MAX}</div>
        </div>
      </div>
    </div>
  );
}

CompetitionGradientBar.propTypes = {
  /**
   * An Array of all brand information for the top pins.
   */
  topPins: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      logoUrl: PropTypes.string,
      position: PropTypes.string,
      size: PropTypes.string,
    })
  ).isRequired,
  /**
   * An Array of all brand information for the bottom pins.
   */
  bottomPins: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      logoUrl: PropTypes.string,
      position: PropTypes.string,
      size: PropTypes.string,
    })
  ).isRequired,
  withTooltip: PropTypes.bool,
};
