import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './UserCount.module.scss';

const MAX_DOTS = 15;

export default function UserCount({
  className,
  label,
  count = 0,
  total = 0,
  color,
  maxDots = MAX_DOTS,
  ...props
}) {
  const [dots, setDots] = useState([]);
  const [showBar, setShowBar] = useState(false);

  const updateDots = useCallback(() => {
    const result = [];

    for (let x = 0; x < maxDots; x++) {
      result.push({
        key: x,
        filled: Boolean(x <= count - 1),
      });
    }

    setDots(result);
  }, [count, maxDots]);

  const updateShowBar = useCallback(() => {
    setShowBar(count >= MAX_DOTS);
  }, [count]);

  useEffect(() => {
    updateDots();
    updateShowBar();
  }, [updateDots, updateShowBar]);

  return (
    <div className={classNames(styles.UserCount, className)} {...props}>
      <div className={styles.LabelContainer}>
        <span className={styles.Label}>{label}</span>&nbsp;
        <span className={styles.Count}>
          {count} of {total}&nbsp;seats used
        </span>
      </div>

      {!showBar ? (
        <div className={styles.DotCounter}>
          {dots.map((dot) => (
            <div
              key={dot.key}
              style={
                dot.filled
                  ? {
                      backgroundColor: color,
                    }
                  : null
              }
              className={classNames(
                styles.Dot,
                dot.filled ? styles.Filled : null
              )}
            />
          ))}
        </div>
      ) : (
        <div className={styles.BarCounter}>
          <div
            className={styles.Fill}
            style={{
              width: `${(count / total) * 100}%`,
            }}
          />
        </div>
      )}
    </div>
  );
}

UserCount.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  label: PropTypes.string,
  color: PropTypes.string,
  maxDots: PropTypes.number,
  maxBar: PropTypes.number,
  total: PropTypes.number,
};
