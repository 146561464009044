import { Outlet } from 'react-router-dom';

import SubNav from '../../Organisms/PageSubnav/PageSubnav';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

const pathSlug = 'content-analysis';
const pageTitle = 'Content Analysis';

export default function ContentAnalysis() {
  const FEATURE_FLAGS = useFeatureFlags();

  function tabPages() {
    const result = [];

    if (FEATURE_FLAGS.CONTENT_ANALYSIS.THEMES.ROOT) {
      result.push({
        label: 'Themes',
        value: 'themes',
      });
    }

    if (FEATURE_FLAGS.CONTENT_ANALYSIS.TOPICS.ROOT) {
      result.push({
        label: 'Hero Messages',
        value: 'topics',
      });
    }

    if (FEATURE_FLAGS.CONTENT_ANALYSIS.KEYWORDS.ROOT) {
      result.push({
        label: 'Keywords',
        value: 'keywords',
      });
    }

    if (FEATURE_FLAGS.CONTENT_ANALYSIS.BRAND_PERSONALITY.ROOT) {
      result.push({
        label: 'Brand Personality',
        value: 'brand-personality',
      });
    }

    return result;
  }

  return (
    <div id="page-wrapper" className="WorkspaceContainer">
      <div className="TabNavContainer">
        <SubNav path={pathSlug} title={pageTitle} tabs={tabPages()} shareable />
      </div>

      <Outlet />
    </div>
  );
}
