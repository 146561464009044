function SvgIconCircle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#7E9397"
          d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 1a9 9 0 10.001 18.001A9 9 0 0012 3z"
        />
      </g>
    </svg>
  );
}

export default SvgIconCircle;
