import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import { useEffect, useMemo, useState } from 'react';
import { Select, SelectChangeEvent } from '@mui/material';
import { MenuItem } from '@material-ui/core';
import { useLazyQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { CloseIcon, ShareIcon } from '../../../../../assets/icons';
import { ShareableDashboard, UserInfo } from '../../types';
import { GET_SHAREABLE_USERS_BY_SETS } from '../../../../../api/queries/Pages/CustomizableDashboards';
import RevokeAccessModal from './RevokeAccessModal';
import InviteSentModal from './InviteSentModal';
import styles from './ShareDashboardModal.module.scss';

import { WidgetType } from '../../../../../interfaces/dashboard-api';
import useDashboardAPI from '../../../../../hooks/useDashboardAPI';
import nameSort from '../../../../../utils/namesort';

export interface ShareDashboardModalProps extends DialogProps {
  dashboard: ShareableDashboard;
  handleOnClose: () => void;
  handleShareSubmit: (dashboardId: string, invitees: UserInfo[]) => void;
  updateList?: (update: boolean) => void;
}

const ShareDashboardModal = ({
  dashboard,
  handleOnClose,
  handleShareSubmit,
  updateList,
  ...props
}: ShareDashboardModalProps) => {
  const [invitedUsers, setInvitedUsers] = useState<UserInfo[]>([]);
  const [selectedUserToRemove, setSelectedUserToRemove] = useState<string>('');
  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);

  const [isRevokeAccessModalOpen, setIsRevokeAccessModalOpen] =
    useState<boolean>(false);

  const { revokeSharingAccess } = useDashboardAPI();

  const [getShareableUsersBySets, { data: shareData }] = useLazyQuery(
    GET_SHAREABLE_USERS_BY_SETS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const widgetCompetitiveSets = useMemo(() => {
    if (!dashboard?.widgets || !props?.open) {
      return [];
    }

    const competitiveSets: string[] = [];
    Object.values(dashboard.widgets).forEach((widget) => {
      let competitiveSetKey: string;

      // As of 1/25/2024, there are 8 widget types. Two types have slightly
      //   different paths to the competitiveSetKey vs the other six widget types
      switch (widget.type_id) {
        case WidgetType.MetricCollectionV1:
          competitiveSetKey =
            widget.data_srcs?.metrics?.input?.competitiveSetKey;
          break;
        case WidgetType.ExcessShareOfVoiceV1:
          competitiveSetKey = widget.data_srcs?.esov?.input?.competitiveSetKey;
          break;
        default:
          competitiveSetKey =
            widget.data_srcs?.metric?.input?.competitiveSetKey;
      }
      competitiveSets.push(competitiveSetKey ?? '');
    });
    return uniq(competitiveSets.flat());
  }, [dashboard.widgets, props.open]);

  useEffect(() => {
    if (!isEmpty(widgetCompetitiveSets)) {
      getShareableUsersBySets({
        variables: {
          input: {
            uuids: widgetCompetitiveSets,
          },
        },
      });
    }
  }, [widgetCompetitiveSets, getShareableUsersBySets]);

  const cohortUsers = useMemo(() => {
    if (isEmpty(shareData?.user?.getShareableUsersBySets)) {
      return [];
    }

    return shareData?.user?.getShareableUsersBySets
      .filter((user: { name: string | null }) => user.name !== null)
      .sort(nameSort);
  }, [shareData]);

  const emailAddresses = cohortUsers.filter((user: UserInfo) =>
    invitedUsers.some((invitedUser) => invitedUser.userKey === user.userKey)
  );

  const handleChange = ({ target }: SelectChangeEvent<string[]>) => {
    const selectedUserKeys = target.value as string[];

    const selectedUsers = cohortUsers.filter((user: UserInfo) =>
      selectedUserKeys.includes(user.userKey)
    );

    setInvitedUsers(selectedUsers);
  };

  const handleInviteSubmit = async (dashId: string, users: UserInfo[]) => {
    setIsInviteModalOpen(true);
    await handleShareSubmit(dashId, users);

    setInvitedUsers(invitedUsers);
  };

  const handleRemoveUser = async (user: string) => {
    const response = await revokeSharingAccess(dashboard.id, user);

    if (response === 200) {
      if (updateList) updateList(true);
      setIsRevokeAccessModalOpen(false);
    }
  };

  const handleRemoveAccessModal = (user: string) => {
    setIsRevokeAccessModalOpen(true);
    setSelectedUserToRemove(user);
  };

  const handleCloseInviteSentModal = () => {
    if (invitedUsers) {
      handleOnClose();
    }
    setIsInviteModalOpen(false);
  };

  const handleModalClose = () => {
    setInvitedUsers([]);
    handleOnClose();
  };

  const peopleWithAccess = dashboard?.sharedWithUsers ?? [];

  const removedUsername = cohortUsers.find(
    (item: UserInfo) => item.userKey === selectedUserToRemove
  )?.name;

  return (
    <>
      {isRevokeAccessModalOpen && (
        <RevokeAccessModal
          username={removedUsername}
          open={isRevokeAccessModalOpen}
          handleOnClose={() => setIsRevokeAccessModalOpen(false)}
          handleRemoveUser={() => handleRemoveUser(selectedUserToRemove)}
          showError={false}
          dashboardOwner={dashboard.ownerName}
        />
      )}
      {isInviteModalOpen && (
        <InviteSentModal
          dashboardId={dashboard.id}
          open={isInviteModalOpen}
          names={emailAddresses.map((item: UserInfo) => item.name)}
          handleOnClose={handleCloseInviteSentModal}
        />
      )}

      {!isRevokeAccessModalOpen ? (
        <Dialog
          className={styles.ModalContainer}
          onClose={handleModalClose}
          {...props}
        >
          <div className={styles.ShareDashboardModal}>
            <div className={styles.Header}>
              <div className={styles.Left}>
                <ShareIcon className={styles.Icon} />
                <p>{dashboard.name}</p>
              </div>

              <CloseIcon className={styles.Icon} onClick={handleModalClose} />
            </div>

            {/* Share with people */}
            <div className={styles.Section}>
              <p className={styles.SectionHeader}>Share With People</p>
              <Select
                multiple
                value={invitedUsers.map((user) => user.userKey)}
                onChange={handleChange}
                className={styles.Dropdown}
                placeholder="Select people"
                sx={{ borderColor: 'transparent' }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '330px',
                    },
                  },
                }}
              >
                <MenuItem disabled value="">
                  Select People
                </MenuItem>
                {cohortUsers.map((user: UserInfo) => (
                  <MenuItem
                    key={user.userKey}
                    value={user.userKey}
                    className={styles.DropdownItem}
                  >
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {/* People shared with */}
            <div className={styles.Section}>
              <p className={styles.SectionHeader}>People with access</p>
              <ul className={styles.List}>
                {peopleWithAccess.length > 0
                  ? peopleWithAccess.map((user) => {
                      const isDashboardOwner =
                        dashboard.user_id === user?.userKey;

                      return (
                        <li key={user.name} className={styles.AccessItem}>
                          <p>
                            {user.name}
                            {isDashboardOwner && <span> (you)</span>}
                          </p>
                          <span className={styles.AccessRow}>
                            <div className={styles.RoleBadge}>
                              {isDashboardOwner ? 'Owner' : 'View only'}
                            </div>
                            {!isDashboardOwner && (
                              <button
                                type="button"
                                disabled={peopleWithAccess.length === 0}
                                className={styles.RemoveButton}
                                onClick={() =>
                                  handleRemoveAccessModal(user.userKey ?? '')
                                }
                              >
                                Remove
                              </button>
                            )}
                          </span>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>

            {/* Footer */}
            <div className={styles.Footer}>
              <button
                type="button"
                title="Invite Share Access"
                disabled={invitedUsers.length === 0}
                onClick={() => handleInviteSubmit(dashboard.id, invitedUsers)}
                className={styles.InviteButton}
              >
                Invite
              </button>
            </div>
          </div>
        </Dialog>
      ) : null}
    </>
  );
};

export default ShareDashboardModal;
