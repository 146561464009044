import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { BrandLogo } from '@blueoceanai/react-component-library';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import { formatKeywords } from '../ContentAnalysisKeywords/helpers';
import {
  FormattedKeyword,
  KeywordHeader,
} from '../ContentAnalysisKeywordsBrandContent';
import { ContentAnalysisKeywordsBrandAudienceComparisonProps } from './types';
import styles from './ContentAnalysisKeywordsBrandAudienceComparison.module.scss';
import { SIZES } from '../../../constants/props';
import ThemesWordTag from '../../Molecules/ThemesWordTag/ThemesWordTag';
import AudienceSVG from '../ContentAnalysisKeywords/AudienceIcon.svg';

const ContentAnalysisKeywordsBrandAudienceComparison = ({
  data,
  loading,
  selectedKeyword,
  keywordsMax,
  keywordClick,
}: ContentAnalysisKeywordsBrandAudienceComparisonProps) => {
  const [brandKeywords, setBrandKeywords] = useState<FormattedKeyword[]>([]);
  const [audienceKeywords, setAudienceKeywords] = useState<FormattedKeyword[]>(
    []
  );
  const [brandName, setBrandName] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');

  const handleKeywordClick = (keyword: string, brand: string): void => {
    keywordClick(keyword, brand);
  };

  const getFormattedKeywords = useCallback(formatKeywords, []);

  // *=== set data variables ===*
  useEffect(() => {
    if (
      !data ||
      !data?.competitiveSet?.session?.brandContentKeyWords?.length ||
      !data?.competitiveSet?.session?.audienceContentKeyWords?.length
    ) {
      return;
    }

    const formattedBrandWords = getFormattedKeywords(
      data.competitiveSet.session.brandContentKeyWords.slice(0, keywordsMax),
      data.competitiveSet.session.brandContentKeyWords[0].count
    );
    setBrandKeywords(formattedBrandWords);

    const formattedAudienceWords = getFormattedKeywords(
      data.competitiveSet.session.audienceContentKeyWords.slice(0, keywordsMax),
      data.competitiveSet.session.audienceContentKeyWords[0].count
    );

    setAudienceKeywords(formattedAudienceWords);

    const { name, logoUrl: brandLogo } = data.competitiveSet.brand;
    setBrandName(name);
    setLogoUrl(brandLogo);
  }, [data, getFormattedKeywords, keywordsMax]);

  if (loading) {
    return <ComponentLoader />;
  }

  if (!loading && data && !brandKeywords?.length && !audienceKeywords?.length) {
    return null;
  }

  return (
    <div className={styles.BrandTopicContainer}>
      <div
        className={cn(styles.KeywordColumn, styles.TopicColumnSelectedBrand)}
      >
        <KeywordHeader
          brandName={brandName}
          logoUrl={logoUrl}
          headerClass={styles.Header}
        />
        {brandKeywords?.map((keyword) => (
          <ThemesWordTag
            key={keyword.name}
            value={keyword.comparativeCount}
            colorIndex={keyword.colorIndex}
            term={keyword.name}
            brand={brandName}
            keywordOnClick={handleKeywordClick}
            selectedKeyword={selectedKeyword}
          />
        ))}
        {brandKeywords?.length === 0 && <p>No keywords available</p>}
      </div>
      <div className={styles.KeywordColumn}>
        <div className={styles.Header}>
          <BrandLogo size={SIZES.LG} src={AudienceSVG} />
          <p>Audience</p>
        </div>
        {audienceKeywords?.map((keyword) => (
          <ThemesWordTag
            key={keyword.name}
            value={keyword.comparativeCount}
            colorIndex={keyword.colorIndex}
            term={keyword.name}
            brand=""
            keywordOnClick={handleKeywordClick}
            selectedKeyword={selectedKeyword}
          />
        ))}
        {audienceKeywords?.length === 0 && <p>No keywords available</p>}
      </div>
    </div>
  );
};

export default ContentAnalysisKeywordsBrandAudienceComparison;
