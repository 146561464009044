import colors from './colors';

export const OCEAN = [
  {
    DEFAULT: colors.chartBarOcean01,
    FILL: 'rgb(231,244,250)',
  },
  {
    DEFAULT: colors.chartBarOcean02,
    FILL: 'rgb(232,238,240)',
  },
  {
    DEFAULT: colors.chartBarOcean03,
    FILL: 'rgb(243,250,251)',
  },
  {
    DEFAULT: colors.chartBarOcean04,
    FILL: 'rgb(232,244,243)',
  },
  {
    DEFAULT: colors.chartBarOcean05,
    FILL: 'rgb(246,250,247)',
  },
  {
    DEFAULT: colors.chartBarOcean06,
    FILL: 'rgb(238,238,242)',
  },
  {
    DEFAULT: colors.chartBarOcean07,
    FILL: 'rgb(246,248,251)',
  },
  {
    DEFAULT: colors.chartBarOcean08,
    FILL: 'rgb(239,240,240)',
  },
  {
    DEFAULT: colors.chartBarOcean09,
    FILL: 'rgb(246,248,247)',
  },
  {
    DEFAULT: colors.chartBarOcean10,
    FILL: 'rgb(242,240,244)',
  },
  {
    // 5 New Colors Added on 4/28/21. Handles case where demo brand
    // has more than 9 competitors.
    DEFAULT: '#1F561A',
    FILL: 'rgb(240,250,239)',
  },
  {
    DEFAULT: '#3E7053',
    FILL: 'rgb(242,248,244)',
  },
  {
    DEFAULT: '#5769D1',
    FILL: 'rgb(239,241,251)',
  },
  {
    DEFAULT: '#272791',
    FILL: 'rgb(239,239,251)',
  },
  {
    DEFAULT: '#60364A',
    FILL: 'rgb(248,242,245)',
  },
];

export const SUNSET = [
  {
    DEFAULT: colors.chartBarSunset01,
    FILL: '#e8eff2',
  },
  {
    DEFAULT: colors.chartBarSunset02,
    FILL: '#fffaf2',
  },
  {
    DEFAULT: colors.chartBarSunset03,
    FILL: '#fdf4ef',
  },
  {
    DEFAULT: colors.chartBarSunset04,
    FILL: '#f5edf3',
  },
  {
    DEFAULT: colors.chartBarSunset05,
    FILL: '#fcf3f3',
  },
  {
    DEFAULT: colors.chartBarSunset06,
    FILL: '#faecf1',
  },
  {
    DEFAULT: colors.chartBarSunset07,
    FILL: '#fbf4f9',
  },
  {
    DEFAULT: colors.chartBarSunset08,
    FILL: '#eeecf3',
  },
  {
    DEFAULT: colors.chartBarSunset09,
    FILL: '#f8f7fc',
  },
  {
    DEFAULT: colors.chartBarSunset10,
    FILL: '#eef6f8',
  },
];

export default {
  OCEAN,
  SUNSET,
};
