import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';

import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';

import { useMutation } from '@apollo/client';

import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import ModalFooter from '../../Molecules/ModalFooter/ModalFooter';
import SelectInput from '../../Molecules/SelectInput/SelectInput';
import ArchetypeCardBack from '../../Molecules/ArchetypeCardBack/ArchetypeCardBack';

import { SIZES } from '../../../constants/props';
import BNContext from '../../../contexts/BNContext';

import ArchetypesDiagram from '../../../assets/images/ArchetypesDiagramBig';

import { UPDATE_GOAL } from '../../../api/queries/Organisms/CreativeSetGoal';

import styles from './CreativeSetGoal.module.scss';

export default function CreativeSetGoal({
  className,
  archetypes,
  loading,
  currentGoalArchetype,
  onCancel,
  onSuccess,
  ...props
}) {
  const { competitiveSetID, accountKey } = useContext(BNContext);

  const [
    updateGoal,
    {
      data: updateGoalResp,
      loading: updateGoalLoading,
      error: updateGoalError,
    },
  ] = useMutation(UPDATE_GOAL);

  const [selectedArchetypeName, setSelectedArchetypeName] = useState(
    currentGoalArchetype ? currentGoalArchetype.name : null
  );

  const archetypeOptions = useMemo(() => {
    const result = [];

    archetypes.forEach((archetype) => {
      result.push({ label: archetype.name, value: archetype.name });
    });

    return result;
  }, [archetypes]);

  const archetypesByName = useMemo(
    () => keyBy(archetypes, 'name'),
    [archetypes]
  );

  const selectedArchetype = useMemo(
    () => archetypesByName[selectedArchetypeName],
    [archetypesByName, selectedArchetypeName]
  );

  const isLoading = useMemo(
    () =>
      loading &&
      (!archetypes ||
        !archetypeOptions ||
        !archetypesByName ||
        !selectedArchetype),
    [loading, archetypes, archetypeOptions, archetypesByName, selectedArchetype]
  );

  const noData = useMemo(
    () =>
      !loading &&
      (!archetypes ||
        !archetypeOptions ||
        !archetypesByName ||
        !selectedArchetype),
    [loading, archetypes, archetypeOptions, archetypesByName, selectedArchetype]
  );

  useEffect(() => {
    if (!selectedArchetypeName && archetypeOptions && archetypeOptions[0]) {
      setSelectedArchetypeName(archetypeOptions[0].value);
    }
  }, [selectedArchetypeName, archetypeOptions]);

  useDeepCompareEffectNoCheck(() => {
    if (updateGoalResp && updateGoalResp.addGoal) {
      onSuccess(selectedArchetype);
    }

    if (updateGoalResp && !updateGoalResp.addGoal) {
      alert('Something went wrong. Please try again or call customer support');
    }
  }, [updateGoalResp]);

  useDeepCompareEffectNoCheck(() => {
    if (updateGoalError) {
      // eslint-disable-next-line
      console.error(updateGoalError);
    }
  }, [updateGoalError]);

  function handleUpdateGoal() {
    updateGoal({
      variables: {
        competitiveSetKey: competitiveSetID,
        accountKey,
        archetype_id: selectedArchetype.id,
      },
    });
  }

  return (
    <Box className={classNames(styles.CreativeSetGoal, className)} {...props}>
      {isLoading && !noData ? <ComponentLoader /> : null}

      {!isLoading && !noData ? (
        <div className={styles.Body}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box className={styles.Select} mb={10}>
                <SelectInput
                  inputId="archetype-select"
                  onChange={(e) => setSelectedArchetypeName(e.target.value)}
                  label="SELECT YOUR NEW ARCHETYPE GOAL"
                  menuItems={archetypeOptions}
                  value={selectedArchetypeName}
                  required
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box className={styles.Wheel} mt={6}>
                <ArchetypesDiagram className={styles.Diagram} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={styles.Info}>
                <ArchetypeCardBack
                  size={SIZES.SM}
                  archetype={selectedArchetype}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
      ) : null}

      {!isLoading && noData ? <NoResultsAlert /> : null}

      <ModalFooter
        onAction={handleUpdateGoal}
        onCancel={onCancel}
        actionText="Set Goal"
        loading={updateGoalLoading}
      />
    </Box>
  );
}

CreativeSetGoal.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  currentGoalArchetype: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSuccess: PropTypes.func,
  archetypes: PropTypes.arrayOf(
    PropTypes.shape({
      cardImgUrl: PropTypes.string,
      message: PropTypes.string,
      exampleBrands: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          name: PropTypes.string,
          logoUrl: PropTypes.string,
        })
      ),
      name: PropTypes.string,
      voiceTags: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};
