import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Recommendations } from '../../../assets/icons';

import styles from './RecommendationsIcon.module.scss';

export default function RecommendationsIcon({ className, ...props }) {
  return (
    <div
      className={classNames(styles.RecommendationsIcon, className)}
      {...props}
    >
      <Recommendations className={styles.Icon} />
    </div>
  );
}

RecommendationsIcon.propTypes = {
  className: PropTypes.string,
};
