import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import SettingsAddMarkerEventModal from '../../Organisms/SettingsAddMarkerEventModal/SettingsAddMarkerEventModal';

import { StyledPopover } from './MuiStyleOverrideHOCs';
import { AddFlagIcon } from '../../../assets/icons';
import EyeHide from '../../../assets/icons/EyeHide.jsx';
import EyeShow from '../../../assets/icons/IconEye.jsx';
import EditIcon from '../../../assets/icons/IconEditAlt.jsx';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import useRouter from '../../../hooks/useRouter';

import style from './GraphHintOptionsDropdown.module.scss';

/**
 * Options dropdown to be used with the BluescoreTimelineGraph component.
 */
export default function GraphHintOptionsDropdown({
  onHideMarkerEvent,
  onAddMarkerSuccess,
  onShowMarkerEvent,
  showMarkerEvent,
  className,
  ...props
}) {
  const FEATURE_FLAGS = useFeatureFlags();
  const { updateRoute } = useRouter();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddMarker, setOpenAddMarker] = useState(false);

  function handleButtonClick(event) {
    setAnchorEl(event?.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAddMarkerEvent() {
    setOpenAddMarker(true);
    handleClose();
  }

  function handleHideMarkerEvent() {
    onHideMarkerEvent();
    handleClose();
  }

  function handleShowMarkerEvent() {
    onShowMarkerEvent();
    handleClose();
  }

  function handleEditMarkerEvent() {
    updateRoute({ pathname: '/admin-settings/global-marker-events' });
    handleClose();
  }

  const open = Boolean(anchorEl);
  const id = open ? 'graph-flag-option-popover' : null;
  const buttonClassNames = classNames(style.Button, open ? style.Open : null);

  if (!FEATURE_FLAGS.GLOBAL.MARKERS.ADD.ROOT) return null;

  return (
    <div
      className={classNames(
        style.HintOptionsDropdown,
        'hide-from-export',
        'hide-from-share',
        className
      )}
      {...props}
    >
      <Button className={buttonClassNames} onClick={handleButtonClick}>
        <AddFlagIcon className={style.Icon} />
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <StyledPopover
        id={id || ''}
        className={style.Popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={3}
      >
        <ul className={style.OptionsList}>
          {FEATURE_FLAGS.GLOBAL.MARKERS.ADD.ROOT && (
            <li>
              <button onClick={handleAddMarkerEvent}>
                <Box
                  pt={2}
                  pr={6}
                  pb={2}
                  pl={2}
                  mt={3}
                  className={style.ListItem}
                >
                  <AddFlagIcon className={style.Icon} />
                  <span>Add Marker Event</span>
                </Box>
              </button>
            </li>
          )}

          {showMarkerEvent ? (
            <li>
              <button onClick={handleHideMarkerEvent}>
                <Box pt={2} pr={6} pb={2} pl={2} className={style.ListItem}>
                  <EyeHide className={style.Icon} />
                  <span>Hide Marker Events</span>
                </Box>
              </button>
            </li>
          ) : (
            <li>
              <button onClick={handleShowMarkerEvent}>
                <Box pt={2} pr={6} pl={2} className={style.ListItem}>
                  <EyeShow className={style.Icon} />
                  <span>Show Marker Events</span>
                </Box>
              </button>
            </li>
          )}
          {FEATURE_FLAGS.GLOBAL.MARKERS.ADD.ROOT &&
            FEATURE_FLAGS.ADMIN_SETTINGS.ROOT && (
              <li>
                <button onClick={handleEditMarkerEvent}>
                  <Box pt={2} pr={6} pb={2} pl={2} className={style.ListItem}>
                    <EditIcon className={style.Icon} />
                    <span>Edit Marker Events</span>
                  </Box>
                </button>
              </li>
            )}
        </ul>
      </StyledPopover>

      <SettingsAddMarkerEventModal
        open={openAddMarker}
        onClose={() => setOpenAddMarker(false)}
        onAddMarkerSuccess={onAddMarkerSuccess}
      />
    </div>
  );
}

GraphHintOptionsDropdown.propTypes = {
  onAddMarkerSuccess: PropTypes.func,
  /**
   * Callback to handle hide market event click.
   */
  onHideMarkerEvent: PropTypes.func.isRequired,
  onShowMarkerEvent: PropTypes.func.isRequired,
  className: PropTypes.string,
  showMarkerEvent: PropTypes.bool,
};
