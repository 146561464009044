/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import min from 'lodash/min';
import max from 'lodash/max';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import {
  ActiveBluescoreBrand,
  BlueScoreData,
  BlueScoreResponse,
  FormattedMetricComparisonData,
  GetPaceAnalysisWidgetDataResp,
  LevelType,
  MappingMetric,
  MetricComparisonResponse,
  MetricData,
  MetricVisualMap,
  PaceAnalysisBrand,
  PaceAnalysisData,
  SingleMetricWidgetData,
  SingleWidgetGraphData,
  StackRankingWidgetResp,
  VisualMappingWidgetData,
  VisualMapResponse,
  WidgetPointData,
} from '../types';
import {
  ComparisonType,
  ScoreType,
  TimeRange,
} from '../../../../interfaces/dashboard-api';
import { getMetrics } from '../../../../mocks/data/CustomizableDashboards/metrics';

dayjs.extend(isBetween);

const jsonToPointData = (data: string, brand: string): WidgetPointData[] => {
  return JSON.parse(data).map(
    ({ date, value }: { date: string; value: number }) => ({
      x: dayjs(date).startOf('day').toDate(),
      y: Math.round(value),
      description: `${brand}: ${Math.round(value)}`,
      id: brand,
    })
  );
};

const metricToGraphPoint = (
  metrics: MetricData[],
  description: string,
  isPercentage = false
): WidgetPointData[] => {
  return (metrics ?? []).map(({ date, value }) => ({
    x: dayjs(date).startOf('day').toDate(),
    // TODO: handle RawScoreFormat.Percentage metrics consistently across widgets
    y: isPercentage ? Math.round(Number(value) * 100) : Math.round(value),
    description,
    id: date,
  }));
};

const MARKER_BUFFER = 5;
export const MARKER_ID = 'marker-event';

export const timeRangeToNumDays = (timeRange: TimeRange): number =>
  Number(timeRange?.split('_')[0]);

// will need this when we support blue score in the future
export const formatBlueScoreWidgetData = (
  data: BlueScoreResponse,
  selectedBrands: ActiveBluescoreBrand,
  timeframe: TimeRange = TimeRange['30_DAYS']
): BlueScoreData => {
  const { brand, session, markers } = data;
  const labels: Date[] = [];
  const timeframeStart = timeRangeToNumDays(timeframe);
  const startDate = dayjs().subtract(timeframeStart, 'day');
  for (let i = 0; i < timeframeStart; i++) {
    labels.push(startDate.add(i, 'day').startOf('day').toDate());
  }
  let maxPoint = 0;
  let minPoint = Number.MAX_SAFE_INTEGER;

  const [rawBrandBluescore] = session.bluescore;
  const competitorLookup = [brand, ...session.competitors].reduce(
    (obj, competitor) => {
      obj[competitor.id] = competitor;
      return obj;
    },
    {} as { [id: number]: { id: number; logoUrl: string; name: string } }
  );

  const allBrands = [
    { id: brand.id, t90Days: rawBrandBluescore.t90Days },
    ...session.competitorsLastPeriodScores,
  ];

  const brands = allBrands
    .filter(({ id }) => selectedBrands[id])
    .map((company) => {
      const { name } = competitorLookup[company.id];
      const allGraphPoints = jsonToPointData(company.t90Days, name);
      const graphPoints = allGraphPoints.filter(({ x }) => {
        return dayjs(x).isBetween(startDate, dayjs(), 'day', '[]');
      });
      const maxVal = maxBy(graphPoints, 'y');
      const minVal = minBy(graphPoints, 'y');
      if (maxVal && maxVal.y > maxPoint) {
        maxPoint = maxVal?.y;
      }

      if (minVal && minVal.y < minPoint) {
        minPoint = minVal.y;
      }
      return {
        data: graphPoints,
        ...competitorLookup[company.id],
      };
    });

  const brandsSummary = allBrands.map((company) => {
    const { name } = competitorLookup[company.id];
    const graphPoints = jsonToPointData(company.t90Days, name);
    const latestScore = Math.round(graphPoints[graphPoints.length - 1]?.y);
    let difference = 0;

    if (graphPoints.length > 1) {
      const penultimate = graphPoints[graphPoints.length - 2].y;
      difference = latestScore - penultimate;
    }

    return {
      ...competitorLookup[company.id],
      latestScore,
      difference,
      isActive: Boolean(selectedBrands[company.id]),
    };
  });

  const filteredMarkers = markers
    .filter(({ markerEventDate }) =>
      dayjs(markerEventDate).isBetween(startDate, dayjs(), 'day', '[]')
    )
    .map((marker) => ({
      x: dayjs(marker.markerEventDate).startOf('day').toDate(),
      y: Math.round(maxPoint + MARKER_BUFFER),
      description: marker.label,
      id: MARKER_ID,
    }));

  const first = dayjs(labels[0]).format('MMM DD, YYYY');
  const last = dayjs(labels[labels.length - 1]).format('MMM DD, YYYY');

  const formatted: BlueScoreData = {
    brands,
    brandsSummary: sortBy(brandsSummary, 'latestScore').reverse(),
    markers: filteredMarkers,
    yDomain: { min: minPoint, max: maxPoint, labels, first, last },
  };

  return formatted;
};

export const formatMetricComparisonData = (
  data: MetricComparisonResponse,
  selectedBrands: ActiveBluescoreBrand,
  timeframe: TimeRange = TimeRange['30_DAYS']
): FormattedMetricComparisonData => {
  const { brands, markers, selectedMetricName } = data;
  let labels: string[] = [];

  const timeframeStart = timeRangeToNumDays(timeframe);
  const startDate = dayjs().subtract(timeframeStart, 'day');

  let maxPoint = 0;
  let minPoint = Number.MAX_SAFE_INTEGER;

  const brandsSummary = brands.map((company) => {
    const latestScore = Math.round(company.relativeScoreTile);
    const difference = Math.round(company.relativeScoreDelta);

    return {
      id: company.brandKey,
      name: company.name,
      logoUrl: company.logoUrl,
      latestScore,
      difference,
      isActive: Boolean(selectedBrands[company.brandKey]),
    };
  });

  const brandsChartData = brands
    .filter((item) => selectedBrands[item.brandKey])
    .map((company) => {
      return {
        id: company.brandKey.toString(),
        name: company.name,
        data: company.relativeTimeSeries.map((dateItem) => {
          return {
            id: company.brandKey.toString(),
            x: dateItem.date,
            y: dateItem.value,
            description: `${company.name} - ${Math.round(dateItem.value)}`,
          };
        }),
      };
    });

  labels = brandsChartData[0]?.data.map(({ x }) => x) ?? [];

  const filteredMarkers = markers
    .filter(({ date }) =>
      dayjs(date).isBetween(startDate, dayjs(), 'day', '[]')
    )
    .map((marker) => ({
      x: marker.date,
      y: Math.round(maxPoint + MARKER_BUFFER),
      description: marker.label,
      id: MARKER_ID,
    }));

  const first = dayjs(labels[0]).format('MMM DD, YYYY');
  const last = dayjs(labels[labels.length - 1]).format('MMM DD, YYYY');

  const getMinMaxYValues = brandsChartData.flatMap((item) =>
    item.data.map((pointData) => pointData.y)
  );

  minPoint = max(getMinMaxYValues) ?? 0;
  maxPoint = min(getMinMaxYValues) ?? 0;

  const formatted: FormattedMetricComparisonData = {
    brands: brandsChartData,
    brandsSummary: sortBy(brandsSummary, 'latestScore').reverse(),
    markers: filteredMarkers,
    yDomain: { min: minPoint, max: maxPoint, labels, first, last },
    selectedMetricName,
  };

  return formatted;
};

export const formatSingleMetricGraph = (
  data: SingleMetricWidgetData,
  timeframe: TimeRange,
  hasMeaningfulRawValue: boolean,
  hasMeaningfulIndexedValue: boolean,
  isPercentage: boolean
): SingleWidgetGraphData => {
  const labels = [];
  const datasets: WidgetPointData[][] = [];
  const numDays = timeRangeToNumDays(timeframe);
  const startDate = dayjs().subtract(numDays, 'day');

  for (let i = 0; i < numDays; i++) {
    labels.push(startDate.add(i, 'day').startOf('day').toISOString());
  }

  const indexPoints = metricToGraphPoint(data.relativeTimeSeries, 'Relative');

  // TODO: handle RawScoreFormat.Percentage metrics consistently across widgets
  const rawPoints = metricToGraphPoint(
    data.isolatedTimeSeries,
    'Raw',
    isPercentage
  );
  datasets.push(indexPoints, rawPoints);

  const first = dayjs(labels[0]).format('MMM D');
  const last = dayjs(labels[labels.length - 1]).format('MMM D');

  return {
    yDomain: {
      labels,
      first,
      last,
      showRawData: hasMeaningfulRawValue && Boolean(rawPoints.length),
      showIndexData: hasMeaningfulIndexedValue && Boolean(indexPoints.length),
    },
    datasets,
  };
};

function formatPaceAnalysisBrandsData({
  brands,
  scoreType,
}: {
  brands: PaceAnalysisData[];
  scoreType: ScoreType;
}): PaceAnalysisBrand[] {
  const responseKeysByScoreType = {
    [ScoreType.INDEXED]: {
      timeSeries: 'relativeTimeSeries',
      value: 'relativeScoreTile',
      valueDelta: 'relativeScoreDelta',
      changeRate: 'relativeChangeRate',
      cohortComparison: 'relativeCohortComparison',
      insight: 'relativeScoreInsights',
    },
    [ScoreType.RAW]: {
      timeSeries: 'isolatedTimeSeries',
      value: 'isolatedScoreTile',
      valueDelta: 'isolatedScoreDelta',
      changeRate: 'isolatedChangeRate',
      cohortComparison: 'isolatedCohortComparison',
      insight: 'isolatedScoreInsights',
    },
  };

  return brands.map((brand) => {
    const responseKeys = responseKeysByScoreType[scoreType];

    const result = {
      brand: {
        key: brand.brandKey,
        name: brand.name,
        logoUrl: brand.logoUrl,
      },
      tile: {
        value: brand[responseKeys.value as keyof PaceAnalysisData] as number,
        valueDelta: brand[
          responseKeys.valueDelta as keyof PaceAnalysisData
        ] as number,
        changeRate: brand[
          responseKeys.changeRate as keyof PaceAnalysisData
        ] as number,
        cohortComparison: brand[
          responseKeys.cohortComparison as keyof PaceAnalysisData
        ] as number,
      },
      graph: {
        data: brand[
          responseKeys.timeSeries as keyof PaceAnalysisData
        ] as MetricData[],
      },
      insight: brand[responseKeys.insight as keyof PaceAnalysisData] as string,
    };

    return result;
  });
}

export const formatPaceAnalysisWidgetData = (
  response: GetPaceAnalysisWidgetDataResp
): Record<
  ComparisonType,
  Record<ScoreType, { brands: PaceAnalysisBrand[] }>
> => {
  const defaultResult = {
    [ComparisonType.LEADERS]: {
      [ScoreType.INDEXED]: {
        brands: [],
      },
      [ScoreType.RAW]: {
        brands: [],
      },
    },
    [ComparisonType.NEAREST]: {
      [ScoreType.INDEXED]: {
        brands: [],
      },
      [ScoreType.RAW]: {
        brands: [],
      },
    },
    [ComparisonType.RIVALS]: {
      [ScoreType.INDEXED]: {
        brands: [],
      },
      [ScoreType.RAW]: {
        brands: [],
      },
    },
  };

  if (!response?.competitiveSet?.session?.paceAnalysisWidget) {
    return defaultResult;
  }

  const rivalRelativeBrands =
    response?.competitiveSet?.session?.paceAnalysisWidget?.rivalRelativeBrands;
  const rivalIsolatedBrands =
    response?.competitiveSet?.session?.paceAnalysisWidget?.rivalIsolatedBrands;
  const leadingRelativeBrands =
    response?.competitiveSet?.session?.paceAnalysisWidget
      ?.leadingRelativeBrands;
  const leadingIsolatedBrands =
    response?.competitiveSet?.session?.paceAnalysisWidget
      ?.leadingIsolatedBrands;
  const closeRelativeCompetitors =
    response?.competitiveSet?.session?.paceAnalysisWidget
      ?.closeRelativeCompetitors;
  const closeIsolatedCompetitors =
    response?.competitiveSet?.session?.paceAnalysisWidget
      ?.closeIsolatedCompetitors;

  const result = {
    [ComparisonType.LEADERS]: {
      [ScoreType.INDEXED]: {
        brands: leadingRelativeBrands
          ? formatPaceAnalysisBrandsData({
              brands: leadingRelativeBrands,
              scoreType: ScoreType.INDEXED,
            })
          : [],
      },
      [ScoreType.RAW]: {
        brands: leadingIsolatedBrands
          ? formatPaceAnalysisBrandsData({
              brands: leadingIsolatedBrands,
              scoreType: ScoreType.RAW,
            })
          : [],
      },
    },
    [ComparisonType.NEAREST]: {
      [ScoreType.INDEXED]: {
        brands: closeRelativeCompetitors
          ? formatPaceAnalysisBrandsData({
              brands: closeRelativeCompetitors,
              scoreType: ScoreType.INDEXED,
            })
          : [],
      },
      [ScoreType.RAW]: {
        brands: closeIsolatedCompetitors
          ? formatPaceAnalysisBrandsData({
              brands: closeIsolatedCompetitors,
              scoreType: ScoreType.RAW,
            })
          : [],
      },
    },
    [ComparisonType.RIVALS]: {
      [ScoreType.INDEXED]: {
        brands: rivalRelativeBrands
          ? formatPaceAnalysisBrandsData({
              brands: rivalRelativeBrands,
              scoreType: ScoreType.INDEXED,
            })
          : [],
      },
      [ScoreType.RAW]: {
        brands: rivalIsolatedBrands
          ? formatPaceAnalysisBrandsData({
              brands: rivalIsolatedBrands,
              scoreType: ScoreType.RAW,
            })
          : [],
      },
    },
  };

  return result;
};

interface StackRankingPin {
  id: string;
  name: string;
  logoUrl: string;
  score: number;
  scoreVsPrevious: number;
}

export interface FormattedStackRankingData {
  topPins: StackRankingPin[];
  bottomPins: StackRankingPin[];
}

export const formatStackRankingData = ({
  respData,
  allowedBrandKeys,
  heroBrandKey,
}: {
  respData: StackRankingWidgetResp;
  allowedBrandKeys: string[];
  heroBrandKey: string;
}): FormattedStackRankingData => {
  const result: FormattedStackRankingData = {
    topPins: [],
    bottomPins: [],
  };

  const stackRankingWidget =
    respData?.competitiveSet?.session?.stackRankingWidget;

  if (!stackRankingWidget) {
    return result;
  }

  const brands = stackRankingWidget?.brands ?? [];

  const heroBrand = brands.find(({ brandKey }) => brandKey === heroBrandKey);

  result.topPins.push({
    id: heroBrand?.brandKey ?? '',
    name: heroBrand?.name ?? '',
    logoUrl: heroBrand?.logoUrl ?? '',
    score: Math.round(Number(heroBrand?.relativeScoreTile)) ?? 0,
    scoreVsPrevious: heroBrand?.relativeScoreDelta ?? 0,
  } as StackRankingPin);

  // Filter out the hero brand to prevent duplicates
  const filteredBrands = brands.filter(
    ({ brandKey }) =>
      allowedBrandKeys.includes(brandKey) && brandKey !== heroBrandKey
  );

  result.bottomPins = filteredBrands.map((brand) => ({
    id: brand?.brandKey,
    name: brand?.name,
    logoUrl: brand?.logoUrl,
    score: Math.round(Number(brand?.relativeScoreTile)),
    scoreVsPrevious: brand?.relativeScoreDelta,
  }));

  return result;
};

interface VisualMapFormattedData {
  brands: VisualMappingWidgetData[];
  metricLabels: MappingMetric[];
  anchorBrand: VisualMappingWidgetData;
}

const determineMetricLevel = (variableId: string): LevelType => {
  const factorLength = 1;
  const subFactorLength = 2;
  if (variableId.length === factorLength && !Number.isNaN(Number(variableId))) {
    return LevelType.Factor;
  }

  if (
    variableId.length === subFactorLength &&
    Number.isNaN(Number(variableId))
  ) {
    return LevelType.SubFactor;
  }

  return LevelType.ScoreDrivers;
};

const metricById = keyBy(getMetrics, 'id');

// TODO: Remove with ENG-1478 Fix
const BlueScoreBrand = {
  brandKey: 'blueScore',
  name: 'Blue Score',
  logoUrl: '',
  description: `The overall Blue Score is a composite score of 5 factors indicative of brand health relative to competitors. It’s derived by an always-on process of ingestion, weighting, and calculation of 3,000+ data points.`,
};

export const formatVisualMapData = (
  response: VisualMapResponse
): VisualMapFormattedData | null => {
  if (
    !response?.competitiveSet?.session?.visualMapWidget?.visualMapMetrics
      ?.length
  ) {
    return null;
  }

  const { visualMapMetrics } = response.competitiveSet.session.visualMapWidget;

  const allBrands = visualMapMetrics[0].visualMapBrands.reduce((obj, brand) => {
    const { brandKey, name, logoUrl } = brand;
    obj[brand.brandKey] = { brandKey, name, logoUrl, metrics: [] };
    return obj;
  }, {} as { [id: string]: VisualMappingWidgetData });

  const sortedMetrics = sortBy(visualMapMetrics, 'variableId');

  sortedMetrics.forEach((metric) => {
    const level = determineMetricLevel(metric.variableId);
    const showRawScore = metricById[metric.variableId]?.is_raw_score_available;
    const metricDetail = {
      name: metric.name,
      description: metric.description,
      variableId: metric.variableId,
      level,
      showRawScore,
    };

    // TODO: Remove with ENG-1478 Fix
    if (metric.variableId === 'blue_score') {
      metricDetail.name = BlueScoreBrand.name;
      metricDetail.description = BlueScoreBrand.description;
      metricDetail.level = LevelType.Factor;
    }

    metric.visualMapBrands.forEach((metricData) => {
      const { brandKey, name, logoUrl, ...rest } = metricData;
      const data: MetricVisualMap = { ...metricDetail, ...rest };
      allBrands[brandKey].metrics.push(data);
    });
  });

  const data: VisualMappingWidgetData[] = Object.values(allBrands);
  const [anchorBrand, ...brands] = data;
  const metricLabels = anchorBrand.metrics.map((metric) => ({
    name: metric.name,
    description: metric.description,
    level: metric.level,
    variableId: metric.variableId,
  }));

  return {
    brands,
    anchorBrand,
    metricLabels,
  };
};
