import { useState, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import capitalize from 'lodash/capitalize';
import findIndex from 'lodash/findIndex';

import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableCell from '@material-ui/core/TableCell';
import TableLabel from '../TableLabel/TableLabel';

import IconEditAlt from '../../../assets/icons/IconEditAlt';

import { SORT_DIRECTIONS } from '../../../constants/props';

import styles from './Table.module.scss';
import { CloseInCircleIcon } from '../../../assets/icons';

export default function Table({
  className,
  items = [],
  fields = [],
  onEdit,
  canEdit = () => true,
  onRemove,
  canRemove = () => true,
  groupBy,
  ...props
}) {
  const [sortState, setSortState] = useState({
    index: null,
    selectedColumnId: null,
    direction: null,
  });

  const headers = useMemo(() => {
    const headerMap = {};

    items.forEach((item) => {
      Object.keys(item).forEach((key) => {
        headerMap[key] = key;
      });
    });

    const newHeaders = [];

    if (fields && fields.length > 0) {
      fields.forEach((field, index) => {
        newHeaders.push({
          label: !isNil(field.label) ? field.label : capitalize(field.key),
          id: index,
          align: field.align,
          sortable: !isNil(field.sortable) ? field.sortable : true,
        });
      });
    } else {
      Object.keys(headerMap).forEach((key, index) => {
        newHeaders.push({
          label: key,
          id: index,
        });
      });
    }

    return newHeaders;
  }, [items, fields]);

  const rows = useMemo(() => {
    const result = [];

    items.forEach((item, rowIdx) => {
      const row = {
        id: rowIdx,
        cells: [],
      };

      if (fields && fields.length > 0) {
        fields.forEach((field) => {
          const value = item[field.key];
          const formattedValue = field.format ? field.format(value) : value;

          row.cells.push({
            key: field.key,
            id: value,
            value: formattedValue,
            component: field.component,
            align: field.align,
            rowIdx,
          });
        });
      } else {
        Object.keys(item).forEach((key) => {
          const value = item[key];

          row.cells.push({
            key,
            id: value,
            value,
          });
        });
      }

      result.push(row);
    });

    if (sortState.direction === SORT_DIRECTIONS.ASC) {
      result.sort((rowA, rowB) => {
        if (
          rowA.cells[sortState.index].value < rowB.cells[sortState.index].value
        ) {
          return -1;
        }

        if (
          rowA.cells[sortState.index].value > rowB.cells[sortState.index].value
        ) {
          return 1;
        }

        return 0;
      });
    }

    if (sortState.direction === SORT_DIRECTIONS.DESC) {
      result.sort((rowA, rowB) => {
        if (
          rowA.cells[sortState.index].value > rowB.cells[sortState.index].value
        ) {
          return -1;
        }

        if (
          rowA.cells[sortState.index].value < rowB.cells[sortState.index].value
        ) {
          return 1;
        }

        return 0;
      });
    }

    return result;
  }, [items, sortState, fields]);

  const groupedByRows = useMemo(() => {
    if (!groupBy) {
      return;
    }

    const map = {};

    rows.forEach((row) => {
      const colIdx = findIndex(row.cells, { key: groupBy });
      const cell = row.cells[colIdx];

      if (!map[cell.value]) {
        map[cell.value] = [];
      }

      map[cell.value].push(row);
    });

    return map;
  }, [rows, groupBy]);

  const groups = useMemo(() => {
    if (!groupBy) {
      return;
    }

    return Object.keys(groupedByRows);
  }, [groupedByRows, groupBy]);

  function handleHeaderClick(headerId, index) {
    let sortDirection = sortState.direction;

    if (sortDirection === null) {
      sortDirection = SORT_DIRECTIONS.ASC;
    } else if (sortDirection === SORT_DIRECTIONS.ASC) {
      sortDirection = SORT_DIRECTIONS.DESC;
    } else {
      sortDirection = null;
    }

    setSortState({
      selectedColumnId: headerId,
      direction: sortDirection,
      index,
    });
  }

  const hasActions = useMemo(() => onEdit || onRemove, [onEdit, onRemove]);

  return (
    <MuiTableContainer
      className={classNames(styles.Table, className)}
      {...props}
    >
      <MuiTable>
        <MuiTableHead>
          <MuiTableRow className={styles.Header}>
            {headers.map((header, index) => (
              <MuiTableCell className={styles.HeaderCell} key={header.id}>
                <TableLabel
                  onClick={() => handleHeaderClick(header.id, index)}
                  name={header.label}
                  disableSort={!header.sortable}
                  direction={
                    header.id === sortState.selectedColumnId
                      ? sortState.direction
                      : null
                  }
                  style={{
                    justifyContent:
                      header.align === 'right' ? 'flex-end' : 'flex-start',
                  }}
                />
              </MuiTableCell>
            ))}

            {hasActions ? (
              <MuiTableCell className={styles.HeaderCell}>
                <TableLabel name="Actions" />
              </MuiTableCell>
            ) : null}
          </MuiTableRow>
        </MuiTableHead>

        {groupBy && (
          <MuiTableBody>
            {groups.map((groupKey) => {
              return groupedByRows[groupKey].map((row, rowIndex) => {
                return (
                  <MuiTableRow className={styles.TableRow} key={row.id}>
                    {row.cells.map((col) => {
                      return (
                        <MuiTableCell
                          className={classNames(
                            styles.RowCell,
                            rowIndex === 0 ? styles.RowCellGroupStart : null,
                            rowIndex === groupedByRows[groupKey].length - 1
                              ? styles.RowCellGroupEnd
                              : null
                          )}
                          key={col.id}
                        >
                          {col.key === groupBy && rowIndex > 0 && ''}

                          {((col.key === groupBy && rowIndex === 0) ||
                            col.key !== groupBy) && (
                            <div
                              style={{
                                textAlign: col.align ? col.align : 'left',
                              }}
                            >
                              {col.component
                                ? col.component(col.value, col.rowIdx)
                                : col.value}
                            </div>
                          )}
                        </MuiTableCell>
                      );
                    })}

                    {hasActions ? (
                      <MuiTableCell
                        className={classNames(
                          styles.RowCell,
                          rowIndex === 0 ? styles.RowCellGroupStart : null,
                          rowIndex === groupedByRows[groupKey].length - 1
                            ? styles.RowCellGroupEnd
                            : null
                        )}
                      >
                        <div className={styles.Actions}>
                          {onEdit && canEdit(row) ? (
                            <div
                              className={styles.Action}
                              onClick={() => onEdit(row)}
                            >
                              <IconEditAlt className={styles.Icon} />
                              <span>Edit</span>
                            </div>
                          ) : null}

                          {onRemove && canRemove(row) ? (
                            <div
                              className={styles.Action}
                              onClick={() => onRemove(row)}
                            >
                              <CloseInCircleIcon className={styles.Icon} />
                              <span>Remove</span>
                            </div>
                          ) : null}
                        </div>
                      </MuiTableCell>
                    ) : null}
                  </MuiTableRow>
                );
              });
            })}
          </MuiTableBody>
        )}

        {!groupBy && (
          <MuiTableBody>
            {rows.map((row, rowIdx) => (
              <MuiTableRow className={styles.TableRow} key={row.id}>
                {row.cells.map((col) => {
                  return (
                    <MuiTableCell
                      className={classNames(
                        styles.RowCell,
                        styles.RowCellGroupEnd
                      )}
                      key={col.id}
                    >
                      <div
                        style={{
                          textAlign: col.align ? col.align : 'left',
                        }}
                      >
                        {col.component
                          ? col.component(col.value, col.rowIdx)
                          : col.value}
                      </div>
                    </MuiTableCell>
                  );
                })}

                {hasActions ? (
                  <MuiTableCell
                    className={(styles.RowCell, styles.RowCellGroupEnd)}
                  >
                    <div className={styles.Actions}>
                      {onEdit && canEdit(row) ? (
                        <div
                          className={styles.Action}
                          onClick={() => onEdit(rowIdx)}
                        >
                          <IconEditAlt className={styles.Icon} />
                          <span>Edit</span>
                        </div>
                      ) : null}

                      {onRemove && canRemove(row) ? (
                        <div
                          className={styles.Action}
                          onClick={() => onRemove(rowIdx)}
                        >
                          <CloseInCircleIcon className={styles.Icon} />
                          <span>Remove</span>
                        </div>
                      ) : null}
                    </div>
                  </MuiTableCell>
                ) : null}
              </MuiTableRow>
            ))}
          </MuiTableBody>
        )}
      </MuiTable>
    </MuiTableContainer>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onEdit: PropTypes.func,
  canEdit: PropTypes.func,
  onRemove: PropTypes.func,
  canRemove: PropTypes.func,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      sortable: PropTypes.bool,
      label: PropTypes.string,
    })
  ),
  groupBy: PropTypes.string,
};
