import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import {
  FormattedKeyword,
  KeywordHeader,
} from '../ContentAnalysisKeywordsBrandContent';
import {
  ContentAnalysisKeywordsAudienceContentProps,
  FormattedAudienceContentCompetitor,
} from './types';
import styles from './ContentAnalysisKeywordsAudienceContent.module.scss';
import {
  formatKeywords,
  getCompetitorMaxKeywordCount,
} from '../ContentAnalysisKeywords/helpers';
import ThemesWordTag from '../../Molecules/ThemesWordTag/ThemesWordTag';

const ContentAnalysisKeywordsAudienceContent = ({
  data,
  loading,
  keywordsMax,
  selectedKeyword,
  keywordClick,
}: ContentAnalysisKeywordsAudienceContentProps) => {
  const [competitors, setCompetitors] = useState<
    FormattedAudienceContentCompetitor[]
  >([]);
  const [audienceContentKeywords, setAudienceContentKeywords] = useState<
    FormattedKeyword[]
  >([]);
  const [brandName, setBrandName] = useState<string>('');
  const [logoUrl, setLogoUrl] = useState<string>('');
  const noKeywords = 'No keywords available';

  const getFormattedKeywords = useCallback(formatKeywords, []);
  const handleKeywordClick = (keyword: string, brand: string): void => {
    keywordClick(keyword, brand);
  };

  // *=== set data variables ===*
  useEffect(() => {
    if (
      !data?.competitiveSet?.session?.audienceContentKeyWords?.length ||
      !data?.competitiveSet?.session?.competitors
    ) {
      return;
    }

    if (data.competitiveSet.session) {
      const { competitors: unformattedCompetitors, audienceContentKeyWords } =
        data.competitiveSet.session;
      const { name, logoUrl: brandUrl } = data.competitiveSet.brand;
      const formattedCompetitors = unformattedCompetitors.map((competitor) => {
        const maxKeywordCount = getCompetitorMaxKeywordCount(competitor);
        const formattedKeywords = getFormattedKeywords(
          competitor.audienceContentKeyWords.slice(0, keywordsMax),
          maxKeywordCount
        );
        return { ...competitor, audienceContentKeyWords: formattedKeywords };
      });
      const audienceKeywords = getFormattedKeywords(
        audienceContentKeyWords.slice(0, keywordsMax),
        audienceContentKeyWords[0].count
      );
      setCompetitors(formattedCompetitors);
      setAudienceContentKeywords(audienceKeywords);

      setBrandName(name);
      setLogoUrl(brandUrl);
    }
  }, [data, getFormattedKeywords, keywordsMax]);

  if (loading) {
    return <ComponentLoader />;
  }

  if (!loading && data && !competitors && !audienceContentKeywords) {
    return null;
  }

  return (
    <div className={styles.BrandTopicContainer}>
      <div
        className={cn(styles.KeywordColumn, styles.TopicColumnSelectedBrand)}
      >
        <KeywordHeader
          logoUrl={logoUrl}
          brandName={brandName}
          headerClass={styles.Header}
        />

        {audienceContentKeywords.map((keyword) => (
          <ThemesWordTag
            key={keyword.name}
            value={keyword.comparativeCount}
            colorIndex={keyword.colorIndex}
            term={keyword.name}
            brand={brandName}
            keywordOnClick={handleKeywordClick}
            selectedKeyword={selectedKeyword}
          />
        ))}
        {audienceContentKeywords.length === 0 && <p>{noKeywords}</p>}
      </div>
      <div className={styles.BrandTopicContainerInner}>
        {competitors.map((competitor) => (
          <div className={styles.KeywordColumn} key={competitor.name}>
            <KeywordHeader
              logoUrl={competitor.logoUrl}
              brandName={competitor.name}
              headerClass={styles.Header}
            />
            {competitor.audienceContentKeyWords.map((keyword) => (
              <ThemesWordTag
                key={keyword.name}
                value={keyword.comparativeCount}
                colorIndex={keyword.colorIndex}
                term={keyword.name}
                brand={competitor.name}
                keywordOnClick={handleKeywordClick}
                selectedKeyword={selectedKeyword}
              />
            ))}
            {competitor.audienceContentKeyWords.length === 0 && (
              <p>{noKeywords}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentAnalysisKeywordsAudienceContent;
