import * as React from 'react';

function SvgMetricsDown(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.583 0h24v24h-24z" />
        <path
          fill="#6A0102"
          d="M21.083 17h.011a.491.491 0 00.456-.317c.001-.007.004-.013.006-.02.008-.021.013-.043.018-.065v-.008l.003-.013.006-.057V9.75a.5.5 0 00-.992-.09l-.008.09v5.517l-4.93-5.06a1.922 1.922 0 00-1.378-.586c-.46 0-.902.167-1.251.468l-.127.118-3.048 3.129a.958.958 0 01-1.282.086l-.096-.087-5.056-5.189a.48.48 0 00-.69 0 .512.512 0 00-.056.638l.057.07 5.056 5.188c.365.376.86.587 1.378.587.46 0 .902-.167 1.252-.468l.126-.118 3.048-3.129a.958.958 0 011.282-.086l.097.087L19.919 16h-5.586a.5.5 0 00-.492.41l-.008.09a.5.5 0 00.41.492l.09.008h6.75z"
        />
      </g>
    </svg>
  );
}

export default SvgMetricsDown;
