import { createContext } from 'react';
import { BrandMetadata } from '../interfaces/brand';
import { UserInfo } from '../Components/Pages/CustomizableDashboards/types';

export interface GlobalTimeframe {
  end?: Date;
  unit?: string;
  quantity?: number;
  label?: string;
}

export interface Session {
  date: Date;
  isClientVisible: boolean;
  id: string;
}

export interface AccountList {
  accountKey: string;
  product_id: number;
  competitiveSets: BrandMetadata[];
}

interface GlobalContext {
  timeframe?: GlobalTimeframe;
  setTimeframe?: (timeframe: GlobalTimeframe) => void;
  nonSessionTimeframe?: GlobalTimeframe;
  setNonSessionTimeframe?: (timeframe: GlobalTimeframe) => void;
  competitiveSetID?: string;
  heroBrandKey?: string;
  currentSession?: string;
  oldestSession?: Session;
  allSessions?: Session[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FEATURE_FLAGS?: any;
  accountList?: AccountList[];
  accountKey?: string;
  userSettingsData?: UserInfo[];
}

const BNContext = createContext<GlobalContext>({});

export default BNContext;
export const BNContextProvider = BNContext.Provider;
