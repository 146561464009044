import { useFlags } from 'launchdarkly-react-client-sdk';
import { LeftNavIconType } from '../../../assets/icons/NavIcons/LeftNavIcon';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import QUERY_PARAMS from '../../../constants/queryParams';
import { FeatureFlag } from '../../../utils/featureFlags';

interface NavItemParam {
  [x: string]: string | number | null | undefined;
}

export interface NavigationItem {
  name: string;
  pathname: string;
  iconType: LeftNavIconType;
  rootPath: string;
  params?: NavItemParam;
  customText?: string;
  allowAccess: boolean;
  subNav: {
    name: string;
    pathname: string;
    customText?: string;
    params?: NavItemParam;
    children?: {
      name: string;
      customText: string;
      pathname: string;
      params?: NavItemParam;
    }[];
  }[];
}

const useLeftNavItems = (): NavigationItem[] => {
  const FEATURE_FLAGS = useFeatureFlags();
  const flags = useFlags();
  const result: NavigationItem[] = [];

  /* DASHBOARD */
  if (flags[FeatureFlag.ViewDashboards]) {
    result.push({
      name: 'Dashboards',
      pathname: '/dashboards',
      rootPath: 'dashboards',
      iconType: LeftNavIconType.Dashboard,
      allowAccess: flags[FeatureFlag.ViewDashboards],
      subNav: [],
    });
  }

  /* MARKET INDEX */
  result.push({
    name: 'Market Index',
    pathname: '/market-index/overview',
    iconType: LeftNavIconType.List,
    rootPath: 'market-index',
    allowAccess: flags[FeatureFlag.BNAccess],
    subNav: [
      {
        name: 'Overview',
        pathname: '/market-index/overview',
        customText: 'Overview',
      },
      {
        name: 'Scorecard',
        pathname: '/market-index/scorecard',
        customText: 'Scorecard',
      },
      {
        name: 'Subfactors',
        pathname: '/market-index/subfactors',
        customText: 'Subfactors',
      },
      {
        name: 'Compare',
        pathname: '/market-index/compare',
        customText: 'Compare',
      },
    ],
  });

  /* CONTENT ANALYSIS */
  const contentAnalysis = {
    name: 'Content',
    pathname: '/content-analysis/themes',
    iconType: LeftNavIconType.Content,
    rootPath: 'content-analysis',
    allowAccess: flags[FeatureFlag.BNAccess],
    subNav: [
      {
        name: 'Themes',
        pathname: '/content-analysis/themes',
        customText: 'Themes',
      },
    ],
  };

  if (!FEATURE_FLAGS.CONTENT_ANALYSIS.THEMES.ROOT) {
    contentAnalysis.subNav.pop();
  }

  if (FEATURE_FLAGS.CONTENT_ANALYSIS.TOPICS.ROOT) {
    contentAnalysis.subNav.push({
      name: 'Hero Messages',
      pathname: '/content-analysis/topics',
      customText: 'Hero Messages',
    });
  }

  if (FEATURE_FLAGS.CONTENT_ANALYSIS.KEYWORDS.ROOT) {
    contentAnalysis.subNav.push({
      name: 'Keywords',
      pathname: '/content-analysis/keywords',
      customText: 'Keywords',
    });
  }

  if (FEATURE_FLAGS.CONTENT_ANALYSIS.BRAND_PERSONALITY.ROOT) {
    contentAnalysis.subNav.push({
      name: 'Brand Personality',
      pathname: '/content-analysis/brand-personality',
      customText: 'Brand Personality',
    });
  }

  result.push(contentAnalysis);

  const creativeRoutes: NavigationItem = {
    name: 'Creative',
    pathname: '/creative/channels',
    iconType: LeftNavIconType.Creative,
    rootPath: 'creative',
    params: {
      [QUERY_PARAMS.CREATIVE_CHANNELS_ID]: null,
    },
    allowAccess: flags[FeatureFlag.CreativeAccess],
    subNav: [
      {
        name: 'Brand Creative',
        pathname: '/creative/channels',
        customText: 'Overview',
      },
    ],
  };

  if (FEATURE_FLAGS.CREATIVE.CHANNELS.ROOT) {
    creativeRoutes.subNav.pop();
    creativeRoutes.pathname = '/creative/channels/overview';
    creativeRoutes.subNav.push(
      {
        name: 'Overview',
        pathname: '/creative/channels/overview',
        customText: 'Creative Overview',
      },
      {
        name: 'Advertising',
        pathname: '/creative/channels/advertising',
        customText: 'Creative Advertising',
      },
      {
        name: 'Website',
        pathname: '/creative/channels/website/all-brands',
        customText: 'Creative Website',
        params: {
          [QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY]: 'ALL_BRANDS',
        },
      }
    );
  }
  result.push(creativeRoutes);

  /* METRICS */
  result.push({
    name: 'Metrics',
    iconType: LeftNavIconType.Metrics,
    rootPath: 'metrics',
    pathname: '/metrics',
    params: {
      [QUERY_PARAMS.METRIC_GROUP_ID]: 2,
      [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
      [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
      [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
      [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
    },
    allowAccess: flags[FeatureFlag.BNAccess],
    subNav: [
      {
        name: 'Business',
        pathname: '/metrics',
        params: {
          [QUERY_PARAMS.METRIC_GROUP_ID]: 2,
          [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
          [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
          [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
          [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
        },
        children: [
          {
            name: 'Employee Support',
            customText: 'Employee Support',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 6,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
          {
            name: 'Media Spend',
            customText: 'Media Spend',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 7,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
          {
            name: 'Business Performance',
            customText: 'Business Performance',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 8,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
        ],
      },
      {
        name: 'Digital',
        pathname: '/metrics',
        params: {
          [QUERY_PARAMS.METRIC_GROUP_ID]: 1,
          [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
          [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
          [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
          [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
        },
        children: [
          {
            name: 'News',
            customText: 'News',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 1,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
          {
            name: 'Reviews',
            customText: 'Reviews',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 2,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
          {
            name: 'Social Media',
            customText: 'Social Media',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 3,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
          {
            name: 'Website',
            customText: 'Website',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 5,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
        ],
      },
      {
        name: 'Content',
        pathname: '/metrics',
        params: {
          [QUERY_PARAMS.METRIC_GROUP_ID]: 3,
          [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
          [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
          [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
          [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
        },
        children: [
          {
            name: 'Text Content Metrics',
            customText: 'Text Content Metrics',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 9,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
          {
            name: 'Brand Personality',
            customText: 'Brand Personality',
            pathname: '/metrics',
            params: {
              [QUERY_PARAMS.METRIC_GROUP_ID]: null,
              [QUERY_PARAMS.METRIC_CATEGORY_ID]: 11,
              [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
              [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
              [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
            },
          },
        ],
      },
    ],
  });

  /* RECOMMENDATIONS */
  result.push({
    name: 'Ideas',
    pathname: '/recommendations',
    iconType: LeftNavIconType.Recommendations,
    rootPath: 'recommendations',
    customText: 'Recommendations',
    allowAccess: flags[FeatureFlag.BNAccess],
    subNav: [],
  });

  return result;
};

export default useLeftNavItems;
