/* eslint-disable no-param-reassign */
import { useLazyQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { TimeRange } from '../interfaces/dashboard-api';
import {
  ESOVPosition,
  ESOVResponse,
  ESOVResponseData,
  MetricTrendResponse,
  MetricTrendSession,
} from '../interfaces/metric';
import {
  UI_TO_GQL_TIME_RANGE,
  getESOVQuery,
} from '../api/queries/Pages/CustomizableDashboards';

// Internal setup
// TODO: update the 3 enum members to match P/D decision
enum CutoffPadding {
  // '30_DAYS' = 7,
  // '60_DAYS' = 14,
  // '90_DAYS' = 21,
  '30_DAYS' = 1200,
  '60_DAYS' = 1200,
  '90_DAYS' = 1200,
  '365_DAYS' = 1200,
}

enum TimeRangeLength {
  '30_DAYS' = 30,
  '60_DAYS' = 60,
  '90_DAYS' = 90,
  '365_DAYS' = 365,
}

enum ESOVMetricId {
  'REVENUE' = '4B17',
  'AD_SPEND_A' = '1A1212ZZ',
  'AD_SPEND_B' = '1A1217ZZ',
}

// Put all the cutoff/smoothing/lookback/carryforward logic here
const filterMetricTrendSessions = (trend: string, timeRange: TimeRange) => {
  const trends: MetricTrendSession[] = JSON.parse(trend) ?? [];
  // ! TODO: PENDING ANSWER from P/D for requirements to
  // ! cutoff/smooth lookback/carryforward periods
  // ! Current leading option:
  // ! - if are trends (within or without TR), grab the last session:
  // !   - check the date of the last session
  // !   - if it's older than start of TR + CUTOFF_PADDING, return empty array/no data message
  // !   - if it's within the TR + CUTOFF_PADDING window, return the last session with
  // !     message about smoothing the data and return the actual session date used.
  return trends.filter((sesh: MetricTrendSession) => {
    const { date } = sesh;
    const sessionDate = new Date(date).getTime();
    const cutoff = new Date().setDate(
      new Date().getDate() -
        (TimeRangeLength[timeRange] + CutoffPadding[timeRange])
    );
    if (sessionDate < cutoff) {
      return false;
    }
    return true;
  });
};

// Filter and aggregate the sessions data into a single record containing the correct session data
// for each brand/competitor.
const filterSessions = (
  data: MetricTrendResponse['data'],
  timeRange: TimeRange,
  heroKey: string
) => {
  const trKey = `${UI_TO_GQL_TIME_RANGE[timeRange]}`; // e.g., t90Days (t60Days DOES NOT EXIST in the API)
  const { competitiveSet } = data;
  const { session } = competitiveSet;
  const { metricTrend } = session;
  const { [trKey]: heroTrend } = metricTrend;
  const allBrandSessions = session.competitors.reduce(
    (
      agg: Record<string, MetricTrendSession[]>,
      { brandKey, metricTrend: cmt }
    ) => {
      const { [trKey]: cxTrend } = cmt;
      agg[brandKey] = filterMetricTrendSessions(cxTrend, timeRange);
      return agg;
    },
    {
      [heroKey]: filterMetricTrendSessions(heroTrend, timeRange),
    }
  );
  return allBrandSessions;
};

// Normalizes the brandKey lookup for hero and competitors
const lookupBrand = (
  brandKey: string,
  compSet: MetricTrendResponse['data']['competitiveSet']
) => {
  const { session, brand } = compSet;
  if (brand.brandKey === brandKey) {
    return brand;
  }
  return session.competitors.find((comp) => comp.brandKey === brandKey);
};

// Calculates the excess share of voice insight
// Requirements: https://github.com/blueocean-ai/brand-navigator/issues/1168#issue-1843781877
const calculateExcessShareOfVoiceInsight = (
  data: Record<string, ESOVResponseData>,
  brandKey: string,
  adSpendSum: number
) => {
  const spendAvg = adSpendSum / Object.keys(data).length;
  const {
    excessShareOfVoice,
    shareOfMarket,
    adSpend: { value },
  } = data[brandKey];
  const setSpend = Object.keys(data).map((key) => ({
    k: key,
    v: data[key].adSpend.value,
  }));
  const esovSpendRanking = Object.keys(data).map((key) => ({
    k: key,
    v: data[key].excessShareOfVoice,
  }));
  const sortedSpend = setSpend.sort((a, b) => b.v - a.v);
  const heroPosition = sortedSpend.findIndex((s) => s.k === brandKey) + 1;
  const sortedEsovSpend = esovSpendRanking.sort((a, b) => b.v - a.v);
  const esovHeroPosition =
    sortedEsovSpend.findIndex((s) => s.k === brandKey) + 1;
  const setSize = sortedSpend.length;
  const excessShareOfVoiceInsight = {
    direction: excessShareOfVoice > 0 ? 'positive' : 'negative',
    shareOfMarket,
    spendMultiple: value / spendAvg,
    position: ESOVPosition.MIDDLE,
    esovSetRanking: ESOVPosition.MIDDLE,
  };

  // Requirements for position:
  // - if hero is in 1st position, they are the leader
  if (esovHeroPosition === 1) {
    excessShareOfVoiceInsight.esovSetRanking = ESOVPosition.LEADER;
  } else if (setSize === 3) {
    excessShareOfVoiceInsight.esovSetRanking =
      esovHeroPosition === 2 ? ESOVPosition.MIDDLE : ESOVPosition.LAGGING;
  } else if (setSize === 4) {
    if (esovHeroPosition === 2) {
      excessShareOfVoiceInsight.esovSetRanking = ESOVPosition.MIDDLE;
    }
    if (esovHeroPosition >= 3) {
      excessShareOfVoiceInsight.esovSetRanking = ESOVPosition.LAGGING;
    }
  } else {
    if (esovHeroPosition >= setSize - 1) {
      excessShareOfVoiceInsight.esovSetRanking = ESOVPosition.LAGGING;
    }
    if (esovHeroPosition < setSize - 2 && esovHeroPosition > 1) {
      excessShareOfVoiceInsight.esovSetRanking = ESOVPosition.MIDDLE;
    }
  }

  // Requirements for position:
  // - if hero is in 1st position, they are the leader
  if (heroPosition === 1) {
    excessShareOfVoiceInsight.position = ESOVPosition.LEADER;
    return excessShareOfVoiceInsight;
  }
  // - if odd size small set, hero is in middle position or lagging
  // - if hero is in 2nd position, they are in the middle
  // - if hero is in 3rd position, they are lagging
  if (setSize === 3) {
    excessShareOfVoiceInsight.position =
      heroPosition === 2 ? ESOVPosition.MIDDLE : ESOVPosition.LAGGING;
    return excessShareOfVoiceInsight;
  }
  // - if even size small set, hero is in middle position or lagging
  // - if hero is in 2nd position, they are in the middle
  // - if hero is in 3rd or 4th position, they are lagging
  if (setSize === 4) {
    if (heroPosition === 2) {
      excessShareOfVoiceInsight.position = ESOVPosition.MIDDLE;
    }
    if (heroPosition >= 3) {
      excessShareOfVoiceInsight.position = ESOVPosition.LAGGING;
    }
    return excessShareOfVoiceInsight;
  }
  // - if set is larger than 4, hero is in middle position or lagging
  // - if hero is within 2 of the end, they are lagging
  // - if hero is within 1 of the start and 3 or less than the end, they are middle
  if (heroPosition >= setSize - 1) {
    excessShareOfVoiceInsight.position = ESOVPosition.LAGGING;
  }
  if (heroPosition < setSize - 2 && heroPosition > 1) {
    excessShareOfVoiceInsight.position = ESOVPosition.MIDDLE;
  }
  return excessShareOfVoiceInsight;
};

// Hook / Public Interface
export default async function useESOV(
  competitiveSetId: string,
  brandKey: string,
  sessionKey: string
) {
  const timeRange = TimeRange.DEFAULT;

  // Set up the lazy queries for parallel execution via getRevenue() and getAdSpend()
  // called by the useMemo() hook below, which returns the ESOVResponse of this top-level hook.
  const [revenueQuery, revenueMeta] = useLazyQuery(getESOVQuery(timeRange), {
    variables: {
      id: competitiveSetId,
      sessionKey,
      metricId: ESOVMetricId.REVENUE,
    },
  });
  const [adSpendQuery, adSpendMeta] = useLazyQuery(getESOVQuery(timeRange), {
    variables: {
      id: competitiveSetId,
      sessionKey,
      metricId: ESOVMetricId.AD_SPEND_A,
    },
  });
  const [adSpendQueryB, adSpendMetaB] = useLazyQuery(getESOVQuery(timeRange), {
    variables: {
      id: competitiveSetId,
      sessionKey,
      metricId: ESOVMetricId.AD_SPEND_B,
    },
  });

  // Set up the memoized functions for parallel execution: revenue
  // Only query if we haven't already + no error + not loading
  const getRevenue = useCallback(async () => {
    if (!revenueMeta.loading && !revenueMeta.error && !revenueMeta.called) {
      await revenueQuery();
    }
    if (revenueMeta.called && revenueMeta.loading) {
      return {
        loading: true,
        error: null,
        data: null,
      } as ESOVResponse;
    }
    if (revenueMeta.error) {
      return {
        loading: false,
        error: revenueMeta.error,
        data: null,
      } as ESOVResponse;
    }
    if (revenueMeta.data) {
      return {
        loading: false,
        error: null,
        data: revenueMeta.data as MetricTrendResponse,
      };
    }
    return revenueMeta;
  }, [revenueQuery, revenueMeta]);

  // Set up the memoized functions for parallel execution: adSpend x2
  // We batch these together since they both need to be filtered/merged before
  // calculating the ESOV components
  const getAdSpend = useCallback(async () => {
    if (!adSpendMeta.loading && !adSpendMeta.error && !adSpendMeta.called) {
      await adSpendQuery();
    }
    if (!adSpendMetaB.loading && !adSpendMetaB.error && !adSpendMetaB.called) {
      await adSpendQueryB();
    }
    if (
      (adSpendMeta.called && adSpendMeta.loading) ||
      (adSpendMetaB.called && adSpendMetaB.loading)
    ) {
      return {
        loading: true,
        error: null,
        data: null,
      } as ESOVResponse;
    }
    if (adSpendMeta.error || adSpendMetaB.error) {
      return {
        loading: false,
        error: adSpendMeta.error ?? adSpendMetaB.error,
        data: null,
      } as ESOVResponse;
    }
    if (adSpendMeta.data && adSpendMetaB.data) {
      return {
        loading: false,
        error: null,
        data: {
          [ESOVMetricId.AD_SPEND_A]: adSpendMeta.data as unknown,
          [ESOVMetricId.AD_SPEND_B]: adSpendMetaB.data as unknown,
        },
      };
    }
    return {
      loading: false,
      error: null,
      data: null,
    } as ESOVResponse;
  }, [adSpendQuery, adSpendMeta, adSpendQueryB, adSpendMetaB]);

  // This is the driver for the ESOV calculation. It will run the 3 queries in parallel
  // and then filter/merge the results into the ESOV components, then calculate the necessary outputs.
  return useMemo(async () => {
    // If we don't have a sessionKey, we cannot proceed.
    if (!sessionKey?.length) {
      return {
        loading: true,
        error: null,
        data: null,
      } as ESOVResponse;
    }
    const [revenue, adSpend] = await Promise.all([getRevenue(), getAdSpend()]);
    // Since both queries are required to calculate the ESOV components, we cannot proceed
    // if either query has an error
    if (revenue.error || adSpend.error) {
      return {
        loading: false,
        error: revenue.error ?? adSpend.error,
        data: null,
      } as ESOVResponse;
    }
    // If either query is still loading, we cannot proceed.
    if (!revenue.data || !adSpend.data) {
      return {
        loading: true,
        error: null,
        data: null,
      } as ESOVResponse;
    }

    // At this point, we have revenue data, adSpend data, and no errors.
    // We can proceed with the ESOV calculation.
    // Filter the sessions to only include those within the time range + lookback requirements
    const revenueFiltered = filterSessions(revenue.data, timeRange, brandKey);

    // Since the ESOC calc is a point-in-time calc, we only need the latest session in the filtered range.
    const revenueLatestSession = Object.keys(revenueFiltered).reduce(
      (acc: Record<string, MetricTrendSession>, key: string) => {
        const sesh = revenueFiltered[key];
        if (!sesh.length) {
          return acc;
        }
        acc[key] = sesh[sesh.length - 1];
        return acc;
      },
      {}
    );

    // Repeat the filtering for both adSpend sessions data.
    const adSpendFiltered = filterSessions(
      adSpend.data[ESOVMetricId.AD_SPEND_A] as MetricTrendResponse['data'],
      timeRange,
      brandKey
    );

    const adSpendFilteredB = filterSessions(
      adSpend.data[ESOVMetricId.AD_SPEND_B] as MetricTrendResponse['data'],
      timeRange,
      brandKey
    );

    // Merge the adSpend sessions data into a single object.
    const adSpendLatestSession = Object.keys(adSpendFiltered).reduce(
      (acc: Record<string, MetricTrendSession>, key: string) => {
        const seshA = adSpendFiltered[key];
        const seshB = adSpendFilteredB[key];
        if (!seshA.length && !seshB.length) {
          return acc;
        }
        if (!seshA.length) {
          acc[key] = seshB[seshB.length - 1];
          return acc;
        }
        if (!seshB.length) {
          acc[key] = seshA[seshA.length - 1];
          return acc;
        }
        acc[key] = {
          ...seshA[seshA.length - 1],
          original_value:
            seshA[seshA.length - 1].original_value +
            seshB[seshB.length - 1].original_value,
        };
        return acc;
      },
      {}
    );

    // If there are no sessions for either revenue or adSpendCombined, we cannot proceed.
    if (
      !Object.keys(revenueLatestSession).length ||
      !Object.keys(adSpendLatestSession).length
    ) {
      // ! TODO: PENDING ANSWER from P/D for handling no sessions (e.g., new brand)
      return {
        loading: false,
        error: null,
        data: null,
      };
    }

    // At this point, we have revenue data, adSpend data, no errors, and at least 1 session for each.
    // We can proceed with the aggregating the pre-requisite data used in ESOV calculation.
    // For each brand, in the selected session:
    // - lookup the brand via brandKey (can be hero or competitor)
    // - get the brand's international ratio
    // - calculate the adjusted revenue (revenue * international ratio)
    const components = Object.keys(revenueLatestSession).reduce(
      (agg, key: string) => {
        const brand = lookupBrand(key, revenue.data.competitiveSet);
        const revenueValue = revenueLatestSession[key].original_value;
        const adSpendValue = adSpendLatestSession[key].original_value;
        const revenueRatio = brand?.international_ratio ?? 100;
        const revenueAdjusted = revenueValue * (revenueRatio * 0.01);
        agg[key] = {
          revenue: {
            value: revenueValue,
            adjusted: revenueAdjusted,
            ratio: revenueRatio,
          },
          adSpend: {
            value: adSpendValue,
          },
          shareOfVoice: 0,
          shareOfMarket: 0,
          excessShareOfVoice: 0,
          excessShareOfVoiceImpact: 0,
          excessShareOfVoiceInsight: {},
        };
        return agg;
      },
      {} as Record<string, ESOVResponseData>
    );

    // Sum the adjusted revenue and adSpend values across all brands.
    const revenueSum = Object.keys(components).reduce(
      (acc: number, key: string) => {
        const { revenue: rev } = components[key];
        acc += rev.adjusted ?? 0;
        return acc;
      },
      0
    );
    const adSpendSum = Object.keys(components).reduce(
      (acc: number, key: string) => {
        const { adSpend: ad } = components[key];
        acc += ad.value ?? 0;
        return acc;
      },
      0
    );

    // For each brand:
    // - calculate the share of voice (adSpend / adSpendSum)
    // - calculate the share of market (revenueAdjusted / revenueSum)
    // - calculate the excess share of voice (shareOfVoice - shareOfMarket)
    // - calculate the excess share of voice impact (0.01 * 0.05 * excessShareOfVoice)
    const resp = Object.keys(components).reduce((agg, key: string) => {
      const { revenue: rev, adSpend: ad } = components[key];
      agg[key] = {
        ...components[key],
      };
      if (!ad?.value || !adSpendSum) {
        agg[key].shareOfVoice = 0;
      } else {
        agg[key].shareOfVoice = (ad.value / adSpendSum) * 100;
      }
      if (!rev?.adjusted || !revenueSum) {
        agg[key].shareOfMarket = 0;
      } else {
        agg[key].shareOfMarket = (rev.adjusted / revenueSum) * 100;
      }
      agg[key].excessShareOfVoice =
        agg[key].shareOfVoice - agg[key].shareOfMarket;
      // For every 10% of ESOV, there is a 0.5% impact on SOM growth (annualized)
      // e.g., 10% ESOV = 0.5% SOM growth, 20% ESOV = 1% SOM growth, etc.
      agg[key].excessShareOfVoiceImpact =
        0.01 * 0.05 * agg[key].excessShareOfVoice * 100;
      return agg;
    }, {} as Record<string, ESOVResponseData>);

    // For the selected brand only:
    resp[brandKey].excessShareOfVoiceInsight =
      calculateExcessShareOfVoiceInsight(resp, brandKey, adSpendSum);
    // Add the last session date to the response for display in UI
    // Note: use the adSpendLatestSession since it gets updated more frequently than revenue
    (resp as unknown as Record<string, string>).last_session_date =
      adSpendLatestSession[brandKey].date as string;

    return {
      loading: false,
      error: null,
      data: resp,
    } as ESOVResponse;
  }, [getRevenue, getAdSpend, timeRange, brandKey, sessionKey]);
}
