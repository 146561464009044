import {
  ChartTooltipContextBar,
  ChartTooltipContextLine,
  ChartFlattendBrandDatasetPoint,
  YAxisFormatter,
} from '../../../interfaces';
import { getOrCreateTooltip } from '../../../utils/chartjs';

import styles from './brandCompareTooltip.module.scss';

export interface TimeseriesTooltipSingleBrands {
  context: ChartTooltipContextBar | ChartTooltipContextLine;
  yAxisFormatter?: YAxisFormatter;
}

export default function createCustomTooltip({
  context,
  yAxisFormatter,
}: TimeseriesTooltipSingleBrands): null {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, {
    wrapper: styles.BrandCompareTooltip,
    contents: styles.TooltipContents,
  });

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return null;
  }

  // Build tooltip
  if (tooltip.body) {
    const tooltipContents = tooltipEl.querySelector(
      `.${styles.TooltipContents}`
    );

    const datasetPoint = tooltip.dataPoints[0]
      .raw as ChartFlattendBrandDatasetPoint;

    const pointValue = yAxisFormatter
      ? yAxisFormatter(datasetPoint.y)
      : datasetPoint.y;

    const pointBrandName = datasetPoint.brandName;

    const brandEl = document.createElement('div');
    brandEl.classList.add(styles.Brand);

    const brandNameEl = document.createElement('div');
    brandNameEl.classList.add(styles.BrandName);
    const brandNameTextEl = document.createTextNode(pointBrandName);
    brandNameEl.appendChild(brandNameTextEl);

    const brandValueEl = document.createElement('div');
    brandValueEl.classList.add(styles.BrandValue);
    const brandValueTextEl = document.createTextNode(String(pointValue));
    brandValueEl.appendChild(brandValueTextEl);

    brandEl.appendChild(brandNameEl);
    brandEl.appendChild(brandValueEl);

    // Remove old children
    while (tooltipContents?.firstChild) {
      tooltipContents.firstChild.remove();
    }

    // Add new children
    tooltipContents?.appendChild(brandEl);

    // Add arrow
    const tooltipArrow = document.createElement('div');
    tooltipArrow.classList.add(
      tooltip.xAlign === 'center' || tooltip.xAlign === 'left'
        ? styles.TooltipArrowLeft
        : styles.TooltipArrowRight
    );
    tooltipContents?.appendChild(tooltipArrow);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipArrow.style.top = `${tooltipEl.clientHeight / 2 - 10}px`;
    tooltipEl.style.top = `${
      positionY + tooltip.caretY - tooltipEl.clientHeight / 2
    }px`;

    // Display, position, and set styles for font
    if (tooltip.xAlign === 'center' || tooltip.xAlign === 'left') {
      tooltipEl.style.left = `${
        positionX + tooltip.caretX + tooltipEl.clientWidth / 2 + 20
      }px`;
    } else {
      tooltipEl.style.left = `${
        positionX + tooltip.caretX - tooltipEl.clientWidth / 2 - 20
      }px`;
    }

    tooltipEl.style.opacity = '1';
  }

  return null;
}
