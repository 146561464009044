import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import CloseIcon from '../../../assets/icons/CloseIcon';

import styles from './Tag.module.scss';

export default function Tag({ className, label, deletable, ...props }) {
  return (
    <Box
      px={3}
      py={1}
      className={classNames(
        styles.Tag,
        deletable ? styles.Deletable : null,
        className
      )}
      {...props}
    >
      <span>{label}</span>
      {deletable ? (
        <Box ml={2} className={styles.IconContainer}>
          <CloseIcon className={styles.Icon} />
        </Box>
      ) : null}
    </Box>
  );
}

Tag.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  deletable: PropTypes.bool,
};
