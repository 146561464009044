import { FC } from 'react';
import { Button } from '@blueoceanai/react-component-library';
import styles from './CreativeIntelligenceModalContent.module.scss';
import { BUTTON_VARIANTS } from '../../../../constants/props';

export interface CreativeIntelligenceModalContentProps {}

const CreativeIntelligenceModalContent: FC<
  CreativeIntelligenceModalContentProps
> = () => {
  return (
    <div
      style={{ backgroundImage: 'url(/modal-bg.png)' }}
      className={styles.CreativeIntelligenceModal}
    >
      <div className={styles.Heading}>
        <p>
          To upgrade your access to Brand Navigator please{' '}
          <a
            target="_blank"
            href="https://www.blueocean.ai/contact-us/"
            rel="noreferrer"
          >
            contact your account representative.
          </a>
        </p>
      </div>

      <div className={styles.MainBody}>
        <div className={styles.Text}>
          <p className={styles.Heading}>
            The predictive brand intelligence platform that unlocks growth
          </p>
          <ul>
            <p className={styles.Body}>
              <li>Strengthen your GTM with competitive intelligence</li>
              <li>Increase the impact of your messaging and content </li>
              <li>Optimize your campaign and channel strategy</li>
              <li>
                Grow marketing impact with more confident decisions and
                cross-org alignment
              </li>
            </p>
          </ul>

          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            onClick={() => window.open('https://www.blueocean.ai/contact-us/')}
          >
            Contact Us
          </Button>
        </div>
        <div className={styles.VideoContainer}>
          <iframe
            className={styles.Video}
            src="https://21635132.fs1.hubspotusercontent-na1.net/hubfs/21635132/BlueOcean_Demo_4min.mp4"
            frameBorder="0"
            allow="encrypted-media"
            allowFullScreen
            sandbox=""
            title="video"
          />
        </div>
      </div>
    </div>
  );
};

export default CreativeIntelligenceModalContent;
