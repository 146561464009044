import classNames from 'classnames';
import { InfoOutlined } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import styles from './MappingWidgetGrid.module.scss';
import WidgetTooltip from '../../../WidgetTooltip/WidgetTooltip';
import { TimeRange } from '../../../../../../interfaces/dashboard-api';
import {
  LevelType,
  ScoreMapType,
  VisualMappingWidgetData,
  MappingMetric,
} from '../../../types';
import MappingWidgetGridCell from '../MappingWidgetGridCell/MappingWidgetGridCell';
import MappingWidgetBrandLogo from '../MappingWidgetBrandLogo/MappingWidgetBrandLogo';

export interface MappingWidgetGridProps {
  activeTimeRange: TimeRange;
  scoreMapType: ScoreMapType;
  anchorBrand: VisualMappingWidgetData;
  brands: VisualMappingWidgetData[];
  metricLabels: MappingMetric[];
}

const MappingWidgetGrid = ({
  activeTimeRange,
  scoreMapType,
  anchorBrand,
  brands,
  metricLabels,
}: MappingWidgetGridProps) => {
  const scoreCompetitorsContainerRef = useRef<HTMLDivElement>(null);
  const headerCompetitorsContainerRef = useRef<HTMLDivElement>(null);
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const brandLogoRef = useRef<HTMLDivElement>(null);
  const [selectedBrands, setSelectedBrands] = useState<Set<string>>(new Set());
  const [orderedBrands, setOrderedBrands] =
    useState<VisualMappingWidgetData[]>(brands);

  const handleChangeOrder = (brandKey: string, isSelected: boolean) => {
    const brandIndex = orderedBrands.findIndex((b) => b.brandKey === brandKey);
    const changedOrder = [...orderedBrands];
    const [brandToMove] = changedOrder.splice(brandIndex, 1);
    const newSelectedBrands = new Set(selectedBrands);

    if (!isSelected) {
      newSelectedBrands.add(brandKey);
      changedOrder.unshift(brandToMove);
    } else {
      newSelectedBrands.delete(brandKey);
      changedOrder.push(brandToMove);
    }

    setSelectedBrands(newSelectedBrands);
    setOrderedBrands(changedOrder);
  };

  useEffect(() => {
    if (
      headerCompetitorsContainerRef.current &&
      brandLogoRef.current &&
      headerContainerRef.current &&
      headerCompetitorsContainerRef.current.offsetWidth <
        headerCompetitorsContainerRef.current.scrollWidth
    ) {
      headerContainerRef.current.className = classNames(
        styles.HeaderContainer,
        styles.HeaderWithScroll
      );
      brandLogoRef.current.style.height = `${headerCompetitorsContainerRef.current.clientHeight}px`;
    }

    headerCompetitorsContainerRef.current?.addEventListener('scroll', () => {
      if (
        scoreCompetitorsContainerRef.current &&
        headerCompetitorsContainerRef.current
      )
        scoreCompetitorsContainerRef.current.scrollLeft =
          headerCompetitorsContainerRef.current.scrollLeft;
    });
    scoreCompetitorsContainerRef.current?.addEventListener('scroll', () => {
      if (
        scoreCompetitorsContainerRef.current &&
        headerCompetitorsContainerRef.current
      )
        headerCompetitorsContainerRef.current.scrollLeft =
          scoreCompetitorsContainerRef.current.scrollLeft;
    });
  }, [scoreCompetitorsContainerRef, headerCompetitorsContainerRef]);

  return (
    <div className={styles.Container}>
      <div ref={headerContainerRef} className={styles.HeaderContainer}>
        <div ref={brandLogoRef}>
          <MappingWidgetBrandLogo
            logoUrl={anchorBrand?.logoUrl}
            brandName={anchorBrand?.name}
            selected
            id={anchorBrand?.brandKey}
          />
        </div>
        <div
          ref={headerCompetitorsContainerRef}
          className={styles.CompetitorsContainer}
        >
          {orderedBrands.map((brand) => (
            <MappingWidgetBrandLogo
              key={`${brand.name}_${brand.brandKey}`}
              logoUrl={brand.logoUrl}
              brandName={brand.name}
              clickable
              id={brand.brandKey}
              handleOnClick={handleChangeOrder}
            />
          ))}
        </div>
      </div>
      <div className={styles.BodyContainer}>
        <div className={classNames(styles.FirstColumn, styles.ColumnContainer)}>
          {metricLabels.map((metric) => (
            <div key={metric.variableId} className={styles.TitleContainer}>
              <p className={styles[metric.level]}>{metric.name}</p>
              {metric.level !== LevelType.ScoreDrivers && (
                <WidgetTooltip
                  title={metric.name}
                  description={metric.description}
                  placement="right"
                >
                  <InfoOutlined className={styles.InfoIcon} />
                </WidgetTooltip>
              )}
            </div>
          ))}
        </div>
        <div className={classNames(styles.FirstColumn, styles.ColumnContainer)}>
          <div className={styles.ColumnContainer}>
            {anchorBrand.metrics.map((metric) => (
              <MappingWidgetGridCell
                key={metric.variableId}
                scoreMapType={scoreMapType}
                activeTimeRange={activeTimeRange}
                metric={metric}
              />
            ))}
          </div>
        </div>
        <div
          ref={scoreCompetitorsContainerRef}
          className={styles.CompetitorsContainer}
        >
          {orderedBrands.map((brand) => (
            <div key={brand.brandKey} className={styles.ColumnContainer}>
              {brand.metrics.map((metric) => (
                <MappingWidgetGridCell
                  key={metric.variableId}
                  scoreMapType={scoreMapType}
                  activeTimeRange={activeTimeRange}
                  metric={metric}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MappingWidgetGrid;
