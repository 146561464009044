import { GenericIconProps } from '../icons/types';

const LogoTwitterCircle = (props: GenericIconProps) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
       {...props}
  >
    <g id="Logo / Brand / X">
      <circle id="Ellipse 3" cx="11" cy="11" r="11" fill="black"/>
      <path id="Vector" d="M12.1416 10.1955L16.6089 5H15.5503L11.6714 9.51119L8.57328 5H5L9.68492 11.8217L5 17.27H6.05866L10.1549 12.506L13.4267 17.27H17L12.1414 10.1955H12.1416ZM10.6916 11.8818L10.217 11.2025L6.44011 5.79735H8.06615L11.1141 10.1595L11.5888 10.8388L15.5508 16.5089H13.9248L10.6916 11.8821V11.8818Z" fill="white"/>
    </g>
  </svg>
);

export default LogoTwitterCircle;
