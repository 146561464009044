/* eslint-disable react/jsx-no-useless-fragment */
import { Tooltip } from '@mui/material';
import { AnchorHTMLAttributes } from 'react';
import { TagIcon } from '../../../../../assets/icons';
import IconCircleCheckSolid from '../../../../../assets/icons/IconCircleCheckSolid';
import IconCirclePlus from '../../../../../assets/icons/IconCirclePlus';
import { Group } from '../../types';
import styles from './GroupCard.module.scss';

export interface GroupCardProps extends AnchorHTMLAttributes<HTMLDivElement> {
  group: Group;
  itemsTitle: string;
  isSelected: boolean;
  onGroupClick: (group: Group) => void;
}

const GroupCard = ({
  group,
  itemsTitle,
  isSelected,
  onGroupClick,
}: GroupCardProps) => {
  const itemsList = group.items.map(({ name }) => name).join(', ');
  return (
    <div className={styles.GroupCard} onClick={() => onGroupClick(group)}>
      <div className={styles.Header}>
        <p className={styles.Title}>{group.name}</p>
        {isSelected ? <IconCircleCheckSolid /> : <IconCirclePlus />}
      </div>
      <div className={styles.ItemsContainer}>
        <div className={styles.IconCircle}>
          <TagIcon className={styles.Icon} />
        </div>
        <div>
          <p className={styles.ItemsTitle}>{itemsTitle}</p>
          <Tooltip
            title={
              <ul style={{ fontSize: '13px' }}>
                {group.items.map(({ name }) => (
                  <li key={name}>{name}</li>
                ))}
              </ul>
            }
            arrow
          >
            <p className={styles.ItemsList}>{itemsList}</p>
          </Tooltip>
        </div>
      </div>
      <p className={styles.Description}>{group.description}</p>
    </div>
  );
};

export default GroupCard;
