import dayjs from 'dayjs';
import { ChartTooltipContextBar, YAxisFormatter } from '../../../interfaces';
import { getOrCreateTooltip } from '../../../utils/chartjs';
import { positionLeft, positionTooltipArrow, positionTop } from '../helpers';

import styles from './genericBarChartTooltip.module.scss';

interface BarChartDatasetPoint {
  label: string;
  raw: number;
  formattedValue: string;
}

interface CustomBarChartDatasetPoint {
  label: string;
  raw: { x: string; y: number };
  formattedValue: string;
}

const genericBarChartTooltip = (
  context: ChartTooltipContextBar,
  yAxisFormatter: YAxisFormatter,
  customChart?: string
) => {
  // eslint-disable-next-line one-var
  let value, label, barColor;
  const { chart, tooltip } = context;

  const tooltipEl = getOrCreateTooltip(chart, {
    wrapper: styles.BarTooltip,
    contents: styles.TooltipContents,
  });

  // Hide tooltip if not visible
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return null;
  }

  // Build the body
  if (tooltip.body.length) {
    const tooltipContents = tooltipEl.querySelector(
      `.${styles.TooltipContents}`
    );

    // modify tooltip values based on the custom chart
    if (customChart === 'channelLineChart') {
      const barDataPoint = tooltip.dataPoints[0] as CustomBarChartDatasetPoint;
      value = yAxisFormatter(barDataPoint?.raw?.y);
      label = dayjs(barDataPoint?.raw?.x).format('MM/DD/YY');
      barColor = tooltip.labelColors[0].borderColor as string;
    } else {
      const barDataPoint = tooltip.dataPoints[0] as BarChartDatasetPoint;
      value = yAxisFormatter(barDataPoint?.raw);
      label = barDataPoint.label;
      barColor = tooltip.labelColors[0].backgroundColor as string;
    }

    const labelEl = document.createElement('div');
    labelEl.classList.add(styles.Label);

    const colorSquare = document.createElement('div');
    colorSquare.classList.add(styles.ColorSquare);
    colorSquare.style.backgroundColor = barColor;
    labelEl.appendChild(colorSquare);

    const labelTextEl = document.createTextNode(String(label));
    labelEl.appendChild(labelTextEl);

    const valueEl = document.createElement('div');
    valueEl.classList.add(styles.Value);
    const valueText = document.createTextNode(String(value));
    valueEl.appendChild(valueText);

    // Remove old children;
    while (tooltipContents?.firstChild) {
      tooltipContents.firstChild.remove();
    }

    // add new contents
    tooltipContents?.appendChild(labelEl);
    tooltipContents?.appendChild(valueEl);
    tooltipEl.style.opacity = '1';

    // Add arrow
    const isCenterOrLeft =
      tooltip.xAlign === 'center' || tooltip.xAlign === 'left';
    const tooltipArrow = document.createElement('div');
    tooltipArrow.classList.add(styles.TooltipArrowLeft);
    tooltipContents?.appendChild(tooltipArrow);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipArrow.style.top = positionTooltipArrow(tooltipEl.clientHeight);
    tooltipEl.style.top = positionTop(
      positionY,
      tooltip.caretY,
      tooltipEl.clientHeight
    );
    tooltipEl.style.left = positionLeft(
      positionX,
      tooltip.caretX,
      tooltipEl.clientHeight,
      isCenterOrLeft,
      tooltipEl.clientWidth
    );

    tooltipEl.style.opacity = '1';
  }

  return null;
};

export default genericBarChartTooltip;
