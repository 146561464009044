import { gql } from '@apollo/client';

export const GET_PROFILE_MARKERS = gql`
  query getProfileMarkers($id: ID) {
    user {
      markers(competitiveSetKey: $id) {
        id
        label
        markerEventDate
        competitiveSet_id
        account_id
        user_id
        isPrivate
        isActive
        isAccountLevel
        createdDate
      }
    }
  }
`;
export const UPSERT_MARKER = gql`
  mutation upsertMarker(
    $id: Int
    $competitiveSetKey: ID
    $accountKey: ID
    $label: String
    $markerEventDate: DateType
    $isPrivate: Int
    $isAccountLevel: Int
  ) {
    addMarker(
      marker: {
        id: $id
        competitiveSetKey: $competitiveSetKey
        accountKey: $accountKey
        label: $label
        markerEventDate: $markerEventDate
        isPrivate: $isPrivate
        isAccountLevel: $isAccountLevel
      }
    )
  }
`;
