import { Line } from 'react-chartjs-2';
import classNames from 'classnames';
import { useEffect, useMemo, useState, AnchorHTMLAttributes } from 'react';
import { ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import colors from '../../../../../../constants/colors';
import PALETTES, {
  PaletteType,
} from '../../../../../../constants/color-palettes';
import { xHoverAnnotationLine } from '../../../../../../chartjs/plugins';
import { WidgetPointData, PaceAnalysisBrand } from '../../../types';
import paceWidgetTooltip from '../../../../../../chartjs/tooltips/paceWidgetTooltip/paceWidgetTooltip';
import TimelineSlider from '../TimelineSlider/TimelineSlider';
import { CHART_LEGEND_ICON_VARIANT } from '../../../../../../constants/props';
import ChartLegend from '../../../../../Molecules/ChartLegend/ChartLegend';
import styles from './PaceWidgetGraph.module.scss';
import { TimeRange } from '../../../../../../interfaces/dashboard-api';

export interface PaceWidgetGraphProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  brands: PaceAnalysisBrand[];
  yDomain?: Date[];
  timeRange: TimeRange;
  animated?: boolean;
}

type ChartDataProps = {
  label: string;
  data: WidgetPointData[];
  fill: boolean;
  showLine: boolean;
  borderWidth: number;
  pointHitRadius: number;
  tension: number;
  pointRadius: number;
  pointBackgroundColor: string;
  borderColor: string;
};

const INTERVAL_OF_DAYS_TO_HIDE_X_LABELS = {
  [TimeRange['30_DAYS']]: 3,
  [TimeRange['60_DAYS']]: 4,
  [TimeRange['90_DAYS']]: 7,
  [TimeRange['365_DAYS']]: 30,
};
const ANIMATION_INTERVAL = 1000;

const extractChartData = (data: ChartDataProps[], currentLabels: Date[]) => {
  const convertedDates = currentLabels.map((el) => +el);
  return data.map((el) => {
    return {
      ...el,
      data: el.data.filter((e) => convertedDates.includes(+e.x)),
    };
  });
};

const findMinMaxYAxisValue = (brands: PaceAnalysisBrand[]) => {
  const values = brands.reduce((acc: number[], curr) => {
    return [...acc, ...curr.graph.data.map((el) => el.value)];
  }, []);

  return {
    min: Math.min(...values),
    max: Math.max(...values),
  };
};

const getGraphOptions = ({
  suggestedMin,
  suggestedMax,
}: {
  suggestedMin: number;
  suggestedMax: number;
}): ChartOptions<'line'> => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'x',
    },
    scales: {
      y: {
        suggestedMax,
        suggestedMin,
        grid: {
          display: true,
          drawBorder: false,
          borderDash: [5],
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,

          font: {
            size: 12,
            style: 'normal',
            weight: '300',
          },
        },
      },
      x: {
        grid: {
          display: false,
          borderColor: '#e5e5e5',
        },
        type: 'timeseries',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'M/DD',
          },
        },
        ticks: {
          source: 'data',
          font: {
            size: 12,
            style: 'normal',
            weight: '300',
          },
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: paceWidgetTooltip,
      },
    },
  };
};

const PaceWidgetGraph = ({
  brands,
  yDomain = [],
  timeRange,
  animated = false,
  className,
}: PaceWidgetGraphProps) => {
  const [index, setIndex] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);

  const datasets = useMemo<ChartDataProps[]>(() => {
    const chartData =
      brands?.map((brand, idx) => ({
        label: brand.brand.name,
        data: brand.graph.data.map((points) => {
          return {
            x: new Date(points.date),
            y: points.value,
            id: brand.brand.key,
            description: `${brand.brand.name}: ${points.value}`,
          };
        }),
        fill: false,
        showLine: true,
        borderWidth: 2,
        pointHitRadius: 0.5,
        tension: 0.4,
        pointRadius: 3,
        pointBackgroundColor: colors.opacity80,
        borderColor: PALETTES[PaletteType.Sunset][idx].DEFAULT,
      })) ?? [];

    return animated
      ? extractChartData(chartData, yDomain.slice(0, index))
      : chartData;
  }, [animated, brands, index, yDomain]);

  const filteredYDomain = useMemo(() => {
    return yDomain.reduce((acc: Date[], curr) => {
      if (!acc.length) return [curr];
      if (
        dayjs(curr).diff(dayjs(acc[acc.length - 1]), 'day') >=
        INTERVAL_OF_DAYS_TO_HIDE_X_LABELS[timeRange]
      ) {
        return [...acc, curr];
      }
      return acc;
    }, []);
  }, [timeRange, yDomain]);

  const labels = useMemo(
    () =>
      animated
        ? yDomain.slice(0, index).filter((el) => filteredYDomain.includes(el))
        : filteredYDomain,
    [animated, filteredYDomain, index, yDomain]
  );

  const legendItems = useMemo(() => {
    if (!brands.length) return [];

    return brands.map((brand, idx) => {
      const color = PALETTES[PaletteType.Sunset][idx].DEFAULT;
      return {
        variant: CHART_LEGEND_ICON_VARIANT.SQUARE,
        label: brand.brand.name,
        color: {
          DEFAULT: color,
          FILL: color,
        },
      };
    });
  }, [brands]);

  useEffect(() => {
    if (isPlaying) {
      const interval = setInterval(() => {
        if (index < yDomain.length) {
          setIndex((p) => p + 1);
        } else {
          setIsPlaying(false);
        }
      }, ANIMATION_INTERVAL);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [index, isPlaying, yDomain]);

  const handleChange = (_event: Event, value: number | number[]) => {
    setIndex(Array.isArray(value) ? value[0] : value);
  };
  const { min, max } = findMinMaxYAxisValue(brands);
  const options = getGraphOptions({ suggestedMax: max, suggestedMin: min });

  return (
    <div className={classNames(styles.Wrapper, className)}>
      <div className={styles.GraphContainer}>
        <Line
          plugins={[xHoverAnnotationLine]}
          data={{ datasets, labels }}
          options={options}
        />
      </div>

      <ChartLegend items={legendItems} className={styles.ChartLegend} />

      {animated && (
        <TimelineSlider
          isPlaying={isPlaying}
          handleChange={handleChange}
          startDate={yDomain[0]}
          endDate={yDomain.at(-1) as Date}
          stepsNumber={yDomain.length}
          currentStep={index}
          onButtonClick={() => setIsPlaying((p) => !p)}
        />
      )}
    </div>
  );
};

export default PaceWidgetGraph;
