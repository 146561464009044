import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  FlexibleXYPlot,
  VerticalBarSeries,
  HorizontalBarSeries,
  HorizontalGridLines,
  VerticalGridLines,
  ChartLabel,
  XAxis,
  YAxis,
} from 'react-vis';

import PALETTES from '../../../constants/palettes';
import { ORIENTATIONS } from '../../../constants/props';

import styles from './ArchetypeChart.module.scss';

const BAR_BORDER_RADIUS = 3;

export default function ArchetypeChart({
  className,
  data,
  theme = 'OCEAN',
  orientation = 'horizontal',
  ...props
}) {
  const formattedData = useMemo(() => {
    const result = [];

    data.forEach((set) => {
      const newSet = {
        id: set.id,
        points: [],
      };

      set.points.forEach((point) => {
        if (orientation === ORIENTATIONS.HORIZONTAL) {
          newSet.points.push({
            x: point.order,
            y: point.score,
          });
        }

        if (orientation === ORIENTATIONS.VERTICAL) {
          newSet.points.push({
            x: point.score,
            y: point.order,
          });
        }
      });

      result.push(newSet);
    });

    return result;
  }, [data, orientation]);

  const themeColorMap = useMemo(() => {
    const result = {};

    formattedData.forEach((item, index) => {
      result[item.id] = PALETTES[theme][index];
    });

    return result;
  }, [formattedData, theme]);

  useEffect(() => {
    styleBars();
  }, []);

  function styleBars() {
    const seriesBars = document.querySelectorAll(
      '.rv-xy-plot__series--bar rect'
    );

    if (!seriesBars) {
      return;
    }

    seriesBars.forEach((bar) => {
      bar.setAttribute('rx', BAR_BORDER_RADIUS);
    });
  }

  return (
    <div className={classNames(styles.ArchetypeChart, className)} {...props}>
      <FlexibleXYPlot
        margin={{
          top: orientation === ORIENTATIONS.HORIZONTAL ? 60 : 20,
          left: orientation === ORIENTATIONS.VERTICAL ? 80 : 50,
        }}
      >
        {orientation === ORIENTATIONS.HORIZONTAL ? (
          <HorizontalGridLines />
        ) : null}

        {orientation === ORIENTATIONS.HORIZONTAL ? (
          <XAxis
            top={0}
            tickTotal={12}
            tickFormat={(order) => data[0].points[order].name}
            tickSize={0}
            hideLine
          />
        ) : null}

        {orientation === ORIENTATIONS.HORIZONTAL
          ? formattedData.map((bar) => (
              <VerticalBarSeries
                key={`${bar.id}-bar`}
                data={bar.points}
                barWidth={0.4}
                color={
                  themeColorMap[bar.id]
                    ? themeColorMap[bar.id].DEFAULT
                    : 'black'
                }
                style={{
                  strokeWidth: 1,
                  stroke: 'white',
                }}
              />
            ))
          : null}

        {orientation === ORIENTATIONS.HORIZONTAL ? (
          <ChartLabel
            text="More"
            xPercent={0}
            yPercent={0}
            includeMargin={false}
            style={{
              textAnchor: 'middle',
              transform: 'translate(-30 70)',
            }}
          />
        ) : null}

        {orientation === ORIENTATIONS.HORIZONTAL ? (
          <ChartLabel
            text="Less"
            xPercent={0}
            yPercent={1}
            includeMargin={false}
            style={{
              textAnchor: 'middle',
              transform: 'translate(-30 60)',
            }}
          />
        ) : null}

        {orientation === ORIENTATIONS.VERTICAL ? <VerticalGridLines /> : null}

        {orientation === ORIENTATIONS.VERTICAL ? (
          <YAxis
            tickTotal={12}
            tickFormat={(order) => data[0].points[order].name}
            tickSize={0}
            hideLine
          />
        ) : null}

        {orientation === ORIENTATIONS.VERTICAL
          ? formattedData.map((bar) => (
              <HorizontalBarSeries
                key={`${bar.id}-bar`}
                data={bar.points}
                barWidth={0.4}
                color={
                  themeColorMap[bar.id]
                    ? themeColorMap[bar.id].DEFAULT
                    : 'black'
                }
                style={{
                  strokeWidth: 1,
                  stroke: 'white',
                }}
              />
            ))
          : null}

        {orientation === ORIENTATIONS.VERTICAL ? (
          <ChartLabel
            text="Less"
            xPercent={0}
            yPercent={0}
            includeMargin={false}
            style={{
              textAnchor: 'middle',
              transform: 'translate(30 15)',
            }}
          />
        ) : null}

        {orientation === ORIENTATIONS.VERTICAL ? (
          <ChartLabel
            text="More"
            xPercent={1}
            yPercent={0}
            includeMargin={false}
            style={{
              textAnchor: 'middle',
              transform: 'translate(-20 15)',
            }}
          />
        ) : null}
      </FlexibleXYPlot>
    </div>
  );
}

ArchetypeChart.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  orientation: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      points: PropTypes.arrayOf(
        PropTypes.shape({
          archetypeId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
          name: PropTypes.string,
          score: PropTypes.number,
        })
      ),
    })
  ),
};
