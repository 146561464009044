import { ChartTooltipContextLine } from '../../../interfaces';
import { getOrCreateTooltip } from '../../../utils/chartjs';
import { positionLeft, positionTooltipArrow, positionTop } from '../helpers';

import styles from './esovTooltip.module.scss';

interface CustomBarChartDatasetPoint {
  label: string;
  raw: { x: string; y: number; text: string; esov: number };
}

const esovTooltip = (context: ChartTooltipContextLine) => {
  const { chart, tooltip } = context;
  const { opacity, dataPoints, xAlign, caretX, caretY } = tooltip;

  const tooltipEl = getOrCreateTooltip(chart, {
    wrapper: styles.ESOVtooltip,
    contents: styles.TooltipContents,
  });

  if (opacity === 0) {
    tooltipEl.style.opacity = '0';
    return null;
  }

  if (dataPoints.length) {
    const barDataPoint = dataPoints[0] as CustomBarChartDatasetPoint;
    const { x, y, esov, text } = barDataPoint.raw;

    const toolTipData = [
      {
        label: 'Share of Voice',
        value: `${y}%`,
        shouldHighlight: false,
      },
      {
        label: 'Share of Market',
        value: `${x}%`,
        shouldHighlight: false,
      },
      {
        label: 'ESoV',
        value: `${esov.toFixed(0)}%`,
        shouldHighlight: true,
      },
    ];

    const tooltipContents = tooltipEl.querySelector(
      `.${styles.TooltipContents}`
    );

    // Remove old children
    while (tooltipContents?.firstChild) {
      tooltipContents.firstChild.remove();
    }

    // Create list elements for tooltip content
    const brandList = document.createElement('div');
    brandList.classList.add(styles.BrandList);

    const brandLabel = document.createElement('div');
    brandLabel.textContent = `${text.toUpperCase()}`;
    brandLabel.classList.add(styles.BrandLabel);
    brandList.appendChild(brandLabel);

    toolTipData.forEach((item) => {
      const labelContainer = document.createElement('div');
      labelContainer.classList.add(styles.LabelContainer);

      if (item.shouldHighlight && esov > 0) {
        labelContainer.classList.add(styles.Highlight);
      } else if (item.shouldHighlight && esov < 0) {
        labelContainer.classList.add(styles.HighlightNegative);
      }

      const value = document.createElement('div');
      value.textContent = `${item.value}`;
      value.classList.add(styles.Value);

      const label = document.createElement('div');
      label.textContent = `${item.label}`;
      label.classList.add(styles.Label);

      labelContainer.appendChild(label);
      labelContainer.appendChild(value);

      brandList.appendChild(labelContainer);
    });

    // Append the list to the tooltip contents
    tooltipContents?.appendChild(brandList);

    // Positioning and styling
    const isCenterOrLeft = xAlign === 'center' || xAlign === 'left';
    const tooltipArrow = document.createElement('div');
    tooltipArrow.classList.add(styles.TooltipArrowLeft);
    tooltipContents?.appendChild(tooltipArrow);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipArrow.style.top = positionTooltipArrow(tooltipEl.clientHeight);
    tooltipEl.style.top = positionTop(
      positionY,
      caretY,
      tooltipEl.clientHeight
    );
    tooltipEl.style.left = positionLeft(
      positionX,
      caretX,
      tooltipEl.clientHeight,
      isCenterOrLeft,
      tooltipEl.clientWidth
    );

    tooltipEl.style.opacity = '1';
  }

  return null;
};

export default esovTooltip;
