import { AnchorHTMLAttributes, FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ChartType } from '../../../../interfaces/chart';
import { TimeRange } from '../../../../interfaces/dashboard-api';
import ChartTypeSelector from '../../../ChartUtils/ChartTypeSelector';
import TimeRangeDropdown from '../TimeRangeDropdown/TimeRangeDropdown';
import { TimeRangeOptions } from '../WidgetSettingsShared';
import DateRangeSelectorDropdown from '../DateRangeSelectorDropdown/DateRangeSelectorDropdown';
import { FeatureFlag } from '../../../../utils/featureFlags';

import DateLabel from '../DateLabel/DateLabel';

import styles from './WidgetSubheader.module.scss';
import BrandTag from '../BrandTag/BrandTag';

export interface WidgetSubheaderProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  activeTimeRange?: TimeRange;
  handleTimeRangeChange?: (option: TimeRange) => void;
  customIcons?: { icon: JSX.Element; type: ChartType }[];
  defaultChartType?: ChartType;
  chartHandler?: (type: ChartType) => void;
  lastUpdatedDate?: string;
  // TODO: Consolidate clearcode solution and our solution for additional
  // widget header components
  // additionalComponents?: JSX.Element | JSX.Element[];
  brandName?: string;
  brandLogoUrl?: string;
  activeEndDate?: Date;
  onCustomTimeRangeChange?: ({
    endDate,
    timeRange,
  }: {
    endDate: Date;
    timeRange: TimeRange | null;
  }) => void;
}

const WidgetSubheader: FC<WidgetSubheaderProps> = ({
  activeTimeRange,
  handleTimeRangeChange,
  customIcons,
  defaultChartType,
  chartHandler = () => {},
  lastUpdatedDate,
  // additionalComponents,
  brandName,
  brandLogoUrl = '',
  activeEndDate,
  onCustomTimeRangeChange,
}) => {
  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  return (
    <section className={styles.WidgetSubheader}>
      <div className={styles.ChartSelector}>
        {customIcons && customIcons.length ? (
          <ChartTypeSelector
            onTypeChange={chartHandler}
            defaultChartType={defaultChartType}
            customIcons={customIcons}
          />
        ) : null}
      </div>

      <div className={styles.BrandTagDateHeader}>
        {brandName && <BrandTag name={brandName} logoUrl={brandLogoUrl} />}

        {lastUpdatedDate ? (
          <DateLabel date={lastUpdatedDate} />
        ) : (
          <div className={styles.TimeRangeDropdown}>
            {showCustomDateRangeSelector &&
            activeEndDate &&
            onCustomTimeRangeChange ? (
              <DateRangeSelectorDropdown
                timeRange={activeTimeRange ?? null}
                endDate={activeEndDate}
                onApply={onCustomTimeRangeChange}
              />
            ) : (
              <TimeRangeDropdown
                options={TimeRangeOptions}
                activeOption={activeTimeRange}
                handleItemClick={handleTimeRangeChange || (() => {})}
              />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default WidgetSubheader;
