import * as React from 'react';

function SvgQuote(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M.583 0h24v24h-24z" />
        <path
          fill="#0A5F70"
          d="M5.083 3a2.503 2.503 0 00-2.5 2.5v10c0 1.379 1.122 2.5 2.5 2.5h6.373l2.32 1.265.444.242 2.624 1.431a.492.492 0 00.494-.008.499.499 0 00.245-.43V18h2.5c1.378 0 2.5-1.121 2.5-2.5v-10c0-1.379-1.122-2.5-2.5-2.5zm0 1h15c.827 0 1.5.673 1.5 1.5v10c0 .827-.673 1.5-1.5 1.5h-3a.5.5 0 00-.5.5v2.157c-4.404-2.402-4.832-2.632-4.964-2.655l-.015-.001L5.083 17c-.827 0-1.5-.673-1.5-1.5v-10c0-.827.673-1.5 1.5-1.5zm2 9a.5.5 0 000 1h11a.5.5 0 000-1zm0-3a.5.5 0 000 1h11a.5.5 0 000-1zm0-3a.5.5 0 000 1h11a.5.5 0 000-1z"
        />
      </g>
    </svg>
  );
}

export default SvgQuote;
