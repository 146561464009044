import {
  useState,
  AnchorHTMLAttributes,
  useCallback,
  SyntheticEvent,
} from 'react';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Popover from '@material-ui/core/Popover';
import { Button } from '@blueoceanai/react-component-library';

import DownloadIcon from '../../../assets/icons/IconDownload.jsx';
import DownloadDropdownMenu from '../DownloadDropdownMenu/DownloadDropdownMenu';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

import styles from './DownloadDropdown.module.scss';

export interface DownloadDropdownProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  targetId: string;
  fileName?: string;
  json?: object;
  padding?: number;
  buttonClassName?: string;
}

function DownloadDropdown({
  className,
  targetId,
  fileName,
  json,
  padding,
  buttonClassName,
  ...props
}: DownloadDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement>();
  const FEATURE_FLAGS = useFeatureFlags();

  const handleButtonClick = useCallback((event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget as HTMLInputElement);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(undefined);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'download-dropdown-popover' : undefined;

  if (!FEATURE_FLAGS.GLOBAL.DOWNLOAD.ROOT) return null;

  return (
    <Box
      className={classNames(
        styles.DownloadDropdown,
        className,
        'hide-from-share',
        'hide-from-export'
      )}
      {...props}
    >
      <Button className={buttonClassName} onClick={handleButtonClick}>
        <DownloadIcon className={styles.Icon} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        elevation={3}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ zIndex: 10000000 }}
      >
        <DownloadDropdownMenu
          json={json}
          fileName={fileName}
          targetId={targetId}
          padding={padding}
          onClose={handleClose}
          onSuccess={handleClose}
        />
      </Popover>
    </Box>
  );
}

export default DownloadDropdown;
