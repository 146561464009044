import {
  createElement,
  forwardRef,
  FunctionComponent,
  JSXElementConstructor,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionActions from '@mui/material/AccordionActions';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Button } from '@blueoceanai/react-component-library';
import isEqual from 'lodash/isEqual';

import {
  GenericSettingsFlow,
  SelectGroupFlow,
  SelectMetricFlow,
  SettingsGuide,
} from '../../WidgetSettingsFlows';
import {
  GroupMetricSettingsGuide,
  SelectGroupMetricsGuide,
  SelectMetricGuide,
} from '../../WidgetSettingsGuides';
import CloseIcon from '../../../../../assets/icons/CloseIcon';
import {
  FlowKey,
  Stage,
  StepValidation,
  WidgetConfigProps,
  WidgetSettingsConfig,
} from '../../../../../interfaces/widget-settings';
import {
  defaultConfig,
  widgetSettingsInputAdapter,
  widgetSettingsOutputAdapter,
} from '../../../../../utils/widget-settings-adapters';

import styles from './WidgetSettingsModal.module.scss';
import useDashboardAPI from '../../../../../hooks/useDashboardAPI';

import EditGroupMetricsGuide from '../../WidgetSettingsGuides/EditGroupMetricsGuide/EditGroupMetricsGuide';

import { WidgetMetaResponse, WidgetSettingsModalAction } from '../../types';
import {
  UpdateWidgetSettings,
  WidgetType,
} from '../../../../../interfaces/dashboard-api';
import SingleMetricSelect from '../../WidgetSettingsGuides/SingleMetricSelect/SingleMetricSelect';
import SettingStepIcon from '../../WidgetSettingsGuides/SettingStepIcon/SettingStepIcon';
import PaceAnalysisSettingsGuide from '../../WidgetSettingsGuides/PaceAnalysisSettingsGuide/PaceAnalysisSettingsGuide';
import SummaryTrendsSettingsGuide from '../../WidgetSettingsGuides/SummaryTrendsGuide/SummaryTrendsGuide';
import StackRankingSettingsGuide from '../../WidgetSettingsGuides/StackRankingSettingsGuide/StackRankingSettingsGuide';
import VisualMapsSettingsGuide from '../../WidgetSettingsGuides/VisualMapsSettingsGuide/VisualMapsSettingsGuide';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
    },
    ref: Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

export const flowMapByKey: Record<FlowKey, Stage> = {
  // Metric Selection Flows
  [FlowKey.SelectMetric]: {
    name: 'Select Metric',
    key: FlowKey.SelectMetric,
    contentComponent: SelectMetricFlow,
    guideComponent: SingleMetricSelect,
  },
  [FlowKey.SelectGroup]: {
    name: 'Select Group',
    key: FlowKey.SelectGroup,
    contentComponent: SelectGroupFlow,
    guideComponent: SelectGroupMetricsGuide,
  },

  // Metric Analysis Flows
  [FlowKey.SingleMetricSettings]: {
    name: 'Settings',
    key: FlowKey.SingleMetricSettings,
    contentComponent: GenericSettingsFlow,
    guideComponent: SelectMetricGuide,
  },

  // Metric Collection Flows
  [FlowKey.EditMetrics]: {
    name: 'Edit Metrics',
    key: FlowKey.EditMetrics,
    contentComponent: SelectGroupFlow,
    guideComponent: EditGroupMetricsGuide,
  },
  [FlowKey.MetricGroupSettings]: {
    name: 'Settings',
    key: FlowKey.MetricGroupSettings,
    contentComponent: GenericSettingsFlow,
    guideComponent: GroupMetricSettingsGuide,
  },

  // ESOV Flows
  [FlowKey.ESOVSettings]: {
    name: 'Settings',
    key: FlowKey.ESOVSettings,
    contentComponent: GenericSettingsFlow,
    guideComponent: SettingsGuide,
  },

  // Pace Analysis Flows
  [FlowKey.PaceAnalysisSettings]: {
    name: 'Settings',
    key: FlowKey.PaceAnalysisSettings,
    contentComponent: GenericSettingsFlow,
    guideComponent: PaceAnalysisSettingsGuide,
  },

  // Stack Ranking Flows
  [FlowKey.StackRankingSettings]: {
    name: 'Settings',
    key: FlowKey.StackRankingSettings,
    contentComponent: GenericSettingsFlow,
    guideComponent: StackRankingSettingsGuide,
  },

  // Metric Comparison Flows
  [FlowKey.MetricComparisonSettings]: {
    name: 'Settings',
    key: FlowKey.MetricComparisonSettings,
    contentComponent: GenericSettingsFlow,
    guideComponent: SelectMetricGuide,
  },

  // Summary Trends Flows
  [FlowKey.SummaryTrendsSettings]: {
    name: 'Settings',
    key: FlowKey.SummaryTrendsSettings,
    contentComponent: GenericSettingsFlow,
    guideComponent: SummaryTrendsSettingsGuide,
  },

  // Visual Maps Flows
  [FlowKey.VisualMapsSettings]: {
    name: 'Settings',
    key: FlowKey.VisualMapsSettings,
    contentComponent: GenericSettingsFlow,
    guideComponent: VisualMapsSettingsGuide,
  },
};

export const settingsStagesByWidgetType: Record<WidgetType, FlowKey[]> = {
  [WidgetType.MetricAnalysisV1]: [
    FlowKey.SelectMetric,
    FlowKey.SingleMetricSettings,
  ],
  [WidgetType.MetricCollectionV1]: [
    FlowKey.SelectGroup,
    FlowKey.EditMetrics,
    FlowKey.MetricGroupSettings,
  ],
  [WidgetType.ExcessShareOfVoiceV1]: [FlowKey.ESOVSettings],
  [WidgetType.PaceAnalysisV1]: [
    FlowKey.SelectMetric,
    FlowKey.PaceAnalysisSettings,
  ],
  [WidgetType.MetricComparisonV1]: [
    FlowKey.SelectMetric,
    FlowKey.MetricComparisonSettings,
  ],
  [WidgetType.StackRankingV1]: [
    FlowKey.SelectMetric,
    FlowKey.StackRankingSettings,
  ],
  [WidgetType.SummaryTrendsV1]: [FlowKey.SummaryTrendsSettings],
  [WidgetType.VisualMapsV1]: [FlowKey.VisualMapsSettings],
};

const disableNextByWidgetStage: Record<WidgetType, StepValidation> = {
  [WidgetType.MetricAnalysisV1]: {
    [FlowKey.SelectMetric]: true,
    [FlowKey.SingleMetricSettings]: true,
  },
  [WidgetType.MetricCollectionV1]: {
    [FlowKey.SelectGroup]: true,
    [FlowKey.EditMetrics]: true,
    [FlowKey.MetricGroupSettings]: true,
  },
  [WidgetType.ExcessShareOfVoiceV1]: {
    [FlowKey.ESOVSettings]: false,
  },
  [WidgetType.PaceAnalysisV1]: {
    [FlowKey.SelectMetric]: true,
    [FlowKey.PaceAnalysisSettings]: false,
  },
  [WidgetType.MetricComparisonV1]: {
    [FlowKey.SelectMetric]: true,
    [FlowKey.MetricComparisonSettings]: true,
  },
  [WidgetType.StackRankingV1]: {
    [FlowKey.SelectMetric]: true,
    [FlowKey.StackRankingSettings]: false,
  },
  [WidgetType.SummaryTrendsV1]: {
    [FlowKey.SummaryTrendsSettings]: false,
  },
  [WidgetType.VisualMapsV1]: {
    [FlowKey.VisualMapsSettings]: false,
  },
};

export interface WidgetSettingsModalProps extends DialogProps {
  dashboardId: string;
  widgetConfig: WidgetMetaResponse;
  action?: WidgetSettingsModalAction;
  onClose: (widgetId: string) => void;
  onCancel: (widgetId: string, action: WidgetSettingsModalAction) => void;
  onSaveSuccess: (widgetId: string) => void;
}

const WidgetSettingsModal: FunctionComponent<WidgetSettingsModalProps> = ({
  className,
  onClose,
  onCancel,
  onSaveSuccess,
  action = WidgetSettingsModalAction.Edit,
  dashboardId,
  widgetConfig,
  ...props
}) => {
  const widgetType = widgetConfig.type_id as WidgetType;
  const widgetId = widgetConfig.id;

  const [stageFlowKeys, setStageFlowKeys] = useState<FlowKey[]>(
    settingsStagesByWidgetType[widgetType]
  );
  const [newSettingsConfig, setNewSettingsConfig] =
    useState<WidgetSettingsConfig>(defaultConfig);
  const [expandedStageKey, setExpandedStageKey] = useState<FlowKey>();
  const [stepValidation, setStepValidation] = useState<
    Record<WidgetType, StepValidation>
  >(disableNextByWidgetStage);

  const { updateWidgetInstance } = useDashboardAPI();

  useEffect(() => {
    if (!widgetType) return;

    const flowKeys = settingsStagesByWidgetType[widgetType];
    setStageFlowKeys(flowKeys);
  }, [widgetType]);

  useEffect(() => {
    if (!stageFlowKeys?.[0]) return;

    setExpandedStageKey(stageFlowKeys[0]);
  }, [stageFlowKeys]);

  useEffect(() => {
    if (!widgetType) return;

    const config = widgetSettingsInputAdapter({
      apiConfig: widgetConfig as UpdateWidgetSettings,
      widgetType,
    });

    setNewSettingsConfig(config);
  }, [widgetConfig, widgetType]);

  const handleOnClose = useCallback(() => {
    onClose(widgetId);
  }, [widgetId, onClose]);

  const handleOnStageBack = useCallback(() => {
    stageFlowKeys.forEach((stageFlowKey, index) => {
      if (stageFlowKey === expandedStageKey) {
        const previousStageKey = stageFlowKeys[index - 1];

        if (previousStageKey) {
          setExpandedStageKey(previousStageKey);
        } else {
          handleOnClose();
        }
      }
    });
  }, [expandedStageKey, handleOnClose, stageFlowKeys]);

  const handleOnStageForward = useCallback(() => {
    stageFlowKeys.forEach((stageFlowKey, index) => {
      if (stageFlowKey === expandedStageKey) {
        const nextStageKey = stageFlowKeys[index + 1];

        if (nextStageKey) {
          setExpandedStageKey(nextStageKey);
        }
      }
    });
  }, [expandedStageKey, stageFlowKeys]);

  const handleOnStageSave = useCallback(async () => {
    const dashboardApiPayload = widgetSettingsOutputAdapter({
      uiConfig: newSettingsConfig,
      widgetType,
    });

    const result = await updateWidgetInstance({
      dashboardId,
      widgetId,
      payload: dashboardApiPayload,
    });

    if (result?.id) {
      onSaveSuccess(widgetId);
      handleOnClose();
    } else {
      // eslint-disable-next-line no-alert
      alert('error saving widget settings');
    }
  }, [
    newSettingsConfig,
    widgetType,
    updateWidgetInstance,
    dashboardId,
    widgetId,
    handleOnClose,
    onSaveSuccess,
  ]);

  const handleConfigChange = useCallback(
    (updatedConfig: WidgetSettingsConfig) => {
      setNewSettingsConfig(updatedConfig);
    },
    []
  );

  const handleValidationChange = useCallback(
    (updatedValidationStep: StepValidation) => {
      const typeValidation = stepValidation[widgetType];
      const updatedValidation = {
        ...stepValidation,
        [widgetType]: { ...typeValidation, ...updatedValidationStep },
      };

      // additional check that there are changes to avoid infinite loop
      const validationIsEqual = isEqual(updatedValidation, stepValidation);
      if (!validationIsEqual) {
        setStepValidation(updatedValidation);
      }
    },
    [stepValidation, widgetType]
  );

  const handleWidgetSettingsCancel = useCallback(() => {
    onCancel(widgetId, action);
  }, [widgetId, action, onCancel]);

  return (
    <Dialog
      fullScreen
      className={classNames(styles.WidgetSettingsModal, className)}
      onClose={handleOnClose}
      TransitionComponent={Transition}
      {...props}
    >
      <header className={styles.Header}>
        <div className={styles.Title}>Widget Settings</div>

        <CloseIcon
          className={styles.CloseIcon}
          onClick={handleWidgetSettingsCancel}
        />
      </header>

      <main className={styles.Body}>
        {expandedStageKey ? (
          <>
            <section className={styles.FlowStagesContainer}>
              {stageFlowKeys?.map((stageKey, stageIndex) => {
                const expanded = stageKey === expandedStageKey;
                const stage = flowMapByKey[stageKey];
                const activeIndex = stageFlowKeys.indexOf(expandedStageKey);

                return (
                  <Accordion
                    expanded={expanded}
                    disableGutters
                    style={{ flex: expanded ? 1 : 0 }}
                    classes={{ root: styles.FlowStageContent }}
                    key={stageKey}
                  >
                    <AccordionSummary
                      classes={{
                        root: styles.FlowAccordionSummary,
                        content: styles.HeaderContent,
                      }}
                    >
                      {stage.name}
                      <SettingStepIcon
                        activeIndex={activeIndex}
                        stepIndex={stageIndex}
                        isEditAction={action === WidgetSettingsModalAction.Edit}
                      />
                    </AccordionSummary>

                    {/* TODO: fix: Apply calculated height to div[role="region"] */}
                    <AccordionDetails
                      classes={{
                        root: styles.FlowAccordionDetails,
                      }}
                    >
                      {expandedStageKey &&
                        flowMapByKey[expandedStageKey].guideComponent &&
                        createElement(
                          flowMapByKey[expandedStageKey].guideComponent,
                          {
                            onConfigChange: handleConfigChange,
                            config: newSettingsConfig,
                            widgetType,
                            stageKey,
                            settingAction: action,
                            onValidationChange: handleValidationChange,
                            onPreviousStage: handleOnStageBack,
                          } as WidgetConfigProps
                        )}
                    </AccordionDetails>

                    <AccordionActions
                      classes={{
                        root: styles.FlowStagesFooter,
                      }}
                    >
                      <div className={styles.ActionButtonContainer}>
                        {Boolean(stageIndex > 0) && (
                          <Button
                            variant="secondary"
                            className={styles.ActionButton}
                            onClick={handleOnStageBack}
                          >
                            Back
                          </Button>
                        )}

                        {stageIndex < stageFlowKeys.length - 1 ? (
                          <Button
                            variant="primary"
                            className={styles.ActionButton}
                            onClick={handleOnStageForward}
                            disabled={
                              stepValidation[widgetType][expandedStageKey]
                            }
                          >
                            Next
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            className={styles.ActionButton}
                            disabled={
                              stepValidation[widgetType][expandedStageKey]
                            }
                            onClick={handleOnStageSave}
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </AccordionActions>
                  </Accordion>
                );
              })}
            </section>

            <section className={styles.FlowContentContainer}>
              {expandedStageKey &&
                flowMapByKey[expandedStageKey].contentComponent &&
                createElement(flowMapByKey[expandedStageKey].contentComponent, {
                  onConfigChange: handleConfigChange,
                  config: newSettingsConfig,
                  settingAction: action,
                  widgetType: widgetConfig.type_id,
                  onValidationChange: handleValidationChange,
                  onPreviousStage: handleOnStageBack,
                  containerClassName: styles.FlowContentContainer,
                  stageKey: expandedStageKey,
                } as WidgetConfigProps)}
            </section>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </main>
    </Dialog>
  );
};

export default WidgetSettingsModal;
