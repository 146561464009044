import { useContext, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { useLazyQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BrandLogo } from '@blueoceanai/react-component-library';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';

import FavoriteStar from '../../Atoms/FavoriteStar/FavoriteStar';
import ScoreTile from '../../ScoreDisplays/ScoreTile/ScoreTile';
import BluescoreTimelineChart from '../../Charts/BluescoreTimelineChart/BluescoreTimelineChart';
import {
  BreakdownType,
  MetricFormatType,
  ScoreType,
} from '../../../interfaces/metric';

import ChartLegend from '../ChartLegend/ChartLegend';

import BNContext from '../../../contexts/BNContext';

import { SIZES, CHART_LEGEND_ICON_VARIANT } from '../../../constants/props';

import styles from './MetricCard.module.scss';
import {
  GET_SCORE_DRIVER_RAW_SCORES,
  GET_SOCIAL_METRICS_BY_CHANNEL,
} from '../../../api/queries/Organisms/SubfactorCard';
import {
  SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST,
  SOCIAL_METRIC_RATE_METRIC_WHITELIST,
  SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST,
  SOCIAL_CHANNEL_BY_ID,
} from '../../../constants/social-metrics';
import BreakdownTypeToggle from '../../SocialMetrics/BreakdownTypeToggle';
import { getDayJsStartDate } from '../../../utils/timeframe';
import ScoreTypeSwitch from '../../SocialMetrics/ScoreTypeSwitch';
import { FeatureFlag } from '../../../utils/featureFlags';

export default function MetricCard({
  className,
  title,
  brand,
  average,
  dateUpdated,
  onTitleClick,
  metricId,
  formatType,
  unit,
  socialMetrics = [],
  defaultScoreType = ScoreType.Indexed,
  defaultBreakdownType = BreakdownType.Aggregate,
  ...props
}) {
  const {
    FEATURE_FLAGS,
    metricsFavoriteMap,
    timeframe,
    heroBrandKey,
    competitiveSetID,
  } = useContext(BNContext);
  const flags = useFlags();

  const [breakdownType, setBreakdownType] = useState(defaultBreakdownType);
  const [scoreType, setScoreType] = useState(defaultScoreType);
  const [queryBrandKey, setQueryBrandKey] = useState(brand.id);

  useEffect(() => {
    if (brand?.id && heroBrandKey) {
      setQueryBrandKey(heroBrandKey);
    }
  }, [brand, heroBrandKey]);

  const queryOptions = {
    variables: {
      input: {
        competitiveSetId: competitiveSetID,
        brandKey: queryBrandKey,
        metricId,
        start: getDayJsStartDate(timeframe).toISOString(),
        end: dayjs(timeframe?.end).toISOString(),
      },
    },
    fetchPolicy: 'no-cache',
  };

  /* * Raw Score Driver Toggle * */
  const [
    getScoreDriverRawScores,
    { data: getScoreDriverRawScoresData, loading: loadingRawScores },
  ] = useLazyQuery(GET_SCORE_DRIVER_RAW_SCORES, queryOptions);

  const currentScoreCard = useMemo(() => {
    const rawScoresData = getScoreDriverRawScoresData?.getScoreDriverRawScores;

    const rawScoresCard = {
      chartData: rawScoresData?.chart
        ? rawScoresData.chart
        : brand.scoresOverTime,
      tileScore: rawScoresData?.tile?.score
        ? round(rawScoresData?.tile?.score, 3)
        : null,
      tileDelta: getTileDelta(rawScoresData?.chart),
      chartYDomainOverride: undefined,
      description: rawScoresData?.description,
    };

    const indexedScoreCard = {
      chartData: brand.scoresOverTime,
      tileScore: brand.score,
      description: null,
    };

    return scoreType === ScoreType.Raw ? rawScoresCard : indexedScoreCard;
  }, [
    getScoreDriverRawScoresData,
    brand.score,
    brand.scoresOverTime,
    scoreType,
  ]);

  const showRawScoreToggle =
    flags[FeatureFlag.ViewMetricsSocialMetrics] &&
    SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(metricId?.toUpperCase());

  /* * Social Metrics By Channel Toggle * */
  const [
    getSocialMetricsByChannel,
    {
      loading: loadingSocialMetricsByChannel,
      data: getSocialMetricsByChannelData,
    },
  ] = useLazyQuery(GET_SOCIAL_METRICS_BY_CHANNEL, queryOptions);

  const socialMetricsByChannelData =
    getSocialMetricsByChannelData?.getSocialMetricsByChannel || socialMetrics;

  const scoreTileFormatType = SOCIAL_METRIC_RATE_METRIC_WHITELIST.includes(
    metricId
  )
    ? MetricFormatType.PercentageSuffix
    : MetricFormatType.Abbreviated;

  useEffect(() => {
    if (breakdownType === BreakdownType.Channel) {
      getSocialMetricsByChannel();
    }
  }, [breakdownType, getSocialMetricsByChannel]);

  const formattedSocialMetricsByChannelData = socialMetricsByChannelData?.map(
    (channel) => ({
      ...channel,
      tile: {
        ...channel.tile,
        delta: getTileDelta(channel.timeseries),
      },
    })
  );

  const showBreakdownToggle =
    flags[FeatureFlag.ViewMetricsSocialMetrics] &&
    SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(metricId?.toUpperCase());

  const handleScoreTypeSwitch = async (type) => {
    if (type === ScoreType.Indexed) {
      setScoreType(type);

      return;
    }

    try {
      await getScoreDriverRawScores();

      setScoreType(type);
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(
        'Unable to fetch count. Please try again later or contact customer support.'
      );
    }
  };

  return (
    <Box
      p={4}
      pt={5}
      className={classNames(styles.MetricCard, className)}
      {...props}
    >
      {(loadingRawScores || loadingSocialMetricsByChannel) && (
        <CircularProgress
          className={styles.CardLoader}
          size={16}
          disableShrink
        />
      )}

      <div className={styles.Content}>
        <Box mb={4} className={styles.Header}>
          {FEATURE_FLAGS.GLOBAL.METRICS_FAVORITE ? (
            <Box mr={1} className={styles.IconContainer}>
              <FavoriteStar
                favorited={metricsFavoriteMap[metricId]}
                metricId={metricId}
              />
            </Box>
          ) : null}

          <h4
            className={styles.Title}
            style={{ cursor: onTitleClick ? 'pointer' : 'default' }}
            onClick={() => (onTitleClick ? onTitleClick(metricId) : () => {})}
          >
            {title}
          </h4>

          {showBreakdownToggle && (
            <BreakdownTypeToggle
              value={breakdownType}
              onTypeChange={setBreakdownType}
            />
          )}
        </Box>
        {breakdownType === BreakdownType.Aggregate && (
          <Grid container spacing={4}>
            <Grid xs={12} md={6} item className={styles.ScoreBoxesContainer}>
              <Box className={styles.ScoreBoxes}>
                <Box p={2} className={styles.ScoreBox}>
                  <Box mr={2}>
                    <ScoreTile
                      className={styles.MetricScoreTile}
                      value={currentScoreCard.tileScore}
                      delta={currentScoreCard.tileDelta}
                      formatType={scoreTileFormatType}
                      disableAnimation={
                        showRawScoreToggle || showBreakdownToggle
                      }
                      variant={ScoreType.Raw}
                    />
                  </Box>
                  <BrandLogo
                    size={SIZES.MD}
                    name={brand.name}
                    src={brand.logoUrl}
                  />
                </Box>
                <Box p={2} className={styles.ScoreBox}>
                  <Box mr={2}>
                    <ScoreTile
                      className={styles.MetricScoreTile}
                      value={average.score}
                      formatType={formatType}
                      unit={unit}
                      variant={ScoreType.Raw}
                    />
                  </Box>
                  <span className={styles.CatAvgText}>Category Average</span>
                </Box>
                {showRawScoreToggle && (
                  <ScoreTypeSwitch
                    value={scoreType}
                    className={classNames(
                      'hide-from-export',
                      'hide-from-share'
                    )}
                    onTypeChange={handleScoreTypeSwitch}
                  />
                )}
              </Box>
            </Grid>

            <Grid xs={12} md={6} item>
              <div className={styles.GraphWrap}>
                <BluescoreTimelineChart
                  useSuggestedScale
                  customerScoresOverTime={currentScoreCard.chartData}
                  competitorAverageScore={average.score}
                  xAxis
                  showReducedXAxis
                  yAxis={false}
                  showTooltips={false}
                />
                <ChartLegend
                  className={styles.TimelineChartLegend}
                  items={[
                    {
                      variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                      label: 'Your Score',
                    },
                    {
                      variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
                      label: 'Industry Average',
                    },
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        )}

        {breakdownType === BreakdownType.Channel && (
          <div className={styles.ChannelContainer}>
            {formattedSocialMetricsByChannelData?.map((socialMetric) => {
              return (
                <div className={styles.SocialMetricChannel}>
                  <Box mr={4} className={styles.ScoreBox}>
                    <div className={styles.ChannelName}>
                      <ScoreTile
                        className={styles.MetricScoreTile}
                        value={socialMetric.tile.score}
                        delta={socialMetric.tile.delta}
                        formatType={scoreTileFormatType}
                        variant={ScoreType.Raw}
                      />
                    </div>
                    <div className={styles.ColRight}>
                      {
                        SOCIAL_CHANNEL_BY_ID[socialMetric.channel]
                          ?.logoComponent
                      }

                      <p className={styles.ChannelName}>
                        <span>
                          {SOCIAL_CHANNEL_BY_ID[socialMetric.channel]?.name ??
                            socialMetric.channel}
                        </span>
                      </p>
                    </div>
                  </Box>

                  <div className={styles.ChannelGraphWrap}>
                    <BluescoreTimelineChart
                      customerScoresOverTime={socialMetric.timeseries}
                      scoreType={ScoreType.Raw}
                      formatType={scoreTileFormatType}
                      showReducedXAxis
                      yMaxTicksLimit={3}
                      yAxisBuffer
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <Box pt={3} mt={8} className={styles.Footer}>
        <span className={styles.Date}>
          {timeframe?.label} ending{' '}
          {dayjs(timeframe?.end).format('MMMM D, YYYY')}
        </span>
      </Box>
    </Box>
  );
}

MetricCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  average: PropTypes.shape({
    score: PropTypes.number,
    magnitude: PropTypes.number,
  }),
  brand: PropTypes.shape({
    id: PropTypes.string,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    score: PropTypes.number,
    magnitude: PropTypes.number,
    scoresOverTime: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        score: PropTypes.number,
      })
    ),
  }),
  onTitleClick: PropTypes.func,
  favorited: PropTypes.bool,
  dateUpdated: PropTypes.string,
  metricId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formatType: PropTypes.string,
  unit: PropTypes.string,
  socialMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      channel: PropTypes.string,
      timeseries: PropTypes.arrayOf(
        PropTypes.shape({
          date: PropTypes.string,
          value: PropTypes.number,
        })
      ),
      tile: PropTypes.shape({
        score: PropTypes.number,
        tooltip: PropTypes.string,
      }),
      description: PropTypes.string,
    })
  ),
  defaultScoreType: PropTypes.oneOf(['indexed', 'raw']),
  defaultBreakdownType: PropTypes.oneOf(['aggregate', 'channel']),
};

function getTileDelta(chartData) {
  if (!chartData) {
    return null;
  }

  const formattedChartData = chartData.map((data) => ({
    ...data,
    date: new Date(data.date),
  }));

  const sortedChartData = sortBy(formattedChartData, 'date');

  const oldestScore = sortedChartData[sortedChartData.length - 1]?.value;
  const latestScore = sortedChartData[0]?.value;

  if (!oldestScore || !latestScore) {
    return null;
  }

  return oldestScore - latestScore;
}
