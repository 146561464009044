import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ThemesWordTag.module.scss';

export default function ThemesWordTag({
  term = 'Term',
  value = 0.65,
  colorIndex = 1,
  keywordOnClick,
  brand,
  selectedKeyword,
  tagIsSelected = false,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const chartPercentage = value; // do math
  const chartColors = ['Index3', 'Index2', 'Index1', 'Index0'];

  function clickEvent() {
    if (isSelected) {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
    keywordOnClick(term, brand);
  }

  useEffect(() => {
    if (selectedKeyword === term) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedKeyword, term]);

  useEffect(() => {
    if (tagIsSelected) {
      clickEvent();
    }
    /* eslint-disable-next-line */
  }, []);

  return (
    /* will add htmlfor once data comes in and can be unique */
    /* eslint-disable-next-line */
    <label className={`${styles.ThemesWordTagLabel}`}>
      <input type="checkbox" onChange={clickEvent} checked={isSelected} />
      <div
        className={`${styles.ThemesWordTag} ${styles.BarContainer}  ${
          styles[chartColors[colorIndex]]
        }`}
      >
        <p className={styles.Term}>{term}</p>
        <span
          className={`${styles.GraphColor}`}
          style={{ width: `calc(1% * ${chartPercentage})` }}
        />
      </div>
    </label>
  );
}

ThemesWordTag.propTypes = {
  term: PropTypes.string,
  value: PropTypes.number,
  colorIndex: PropTypes.number,
  selectedKeyword: PropTypes.string,
  brand: PropTypes.string,
  keywordOnClick: PropTypes.func,
  tagIsSelected: PropTypes.bool,
};
