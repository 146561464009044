import { AnchorHTMLAttributes } from 'react';

export enum CaretDirection {
  up = 'rotate(90deg)',
  right = 'rotate(180deg)',
  left = 'rotate(g0deg)',
  down = 'rotate(270deg)',
}

export interface DynamicCaretIconProp
  extends AnchorHTMLAttributes<SVGAElement> {
  direction: CaretDirection;
}

const DynamicCaretIcon = ({ direction, className }: DynamicCaretIconProp) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      style={{
        transform: direction,
        transition: 'transform 0.2s ease-in-out',
      }}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85355 0.146447C6.02712 0.320013 6.0464 0.589437 5.91141 0.784306L5.85355 0.853553L2.06061 4.6465C1.8871 4.82006 1.86783 5.08941 2.00273 5.28422L2.06055 5.35345L5.85355 9.14645C6.04882 9.34171 6.04882 9.65829 5.85355 9.85355C5.67999 10.0271 5.41056 10.0464 5.21569 9.91141L5.14645 9.85355L1.35339 6.0605C0.804419 5.51136 0.770108 4.64252 1.2505 4.05337L1.35345 3.93945L5.14645 0.146447C5.34171 -0.0488155 5.65829 -0.0488155 5.85355 0.146447Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DynamicCaretIcon;
