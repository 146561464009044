import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

import { EllipsisIcon } from '../../../assets/icons';

import styles from './ButtonDropdownMenu.module.scss';

export default function ButtonDropdownMenu({
  className,
  items,
  onClick = () => {},
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleButtonClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(item) {
    onClick(item);
    handleClose();
  }

  const open = Boolean(anchorEl);
  const id = open ? 'graph-flag-option-popover' : null;

  return (
    <div
      className={classNames(
        styles.ButtonDropdownMenu,
        id ? styles.Open : null,
        className
      )}
      {...props}
    >
      <Button
        disableRipple
        disableFocusRipple
        className={styles.Button}
        onClick={handleButtonClick}
      >
        <EllipsisIcon className={styles.Icon} />
      </Button>
      <Popover
        id={id}
        className={styles.Popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={3}
      >
        <ul className={styles.OptionsList}>
          {items.map((item) => (
            <li
              onClick={() => handleClick(item)}
              className={styles.ListItem}
              key={item.value}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </Popover>
    </div>
  );
}

ButtonDropdownMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  onClick: PropTypes.func,
};
