import { FunctionComponent } from 'react';
import { Chart, registerables, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import classNames from 'classnames';
import { ChartDataset, ChartPoint } from '../../../interfaces';
import { renderIconsAsLabels, renderPercentages } from './helpers';
import { FormattedESOVdata } from '../../Pages/CustomizableDashboards/types';

import colors from '../../../constants/colors';
import styles from './ExcessShareOfVoiceBarChart.module.scss';

export interface ExcessShareOfVoiceBarChartProps {
  className?: string;
  data: FormattedESOVdata[];
}

interface CustomBarChartOptions extends ChartOptions<'bar'> {
  labelData?: {
    display: boolean;
    data: FormattedESOVdata[];
  };
}

export interface FlattendBrandDatasetPoint {
  brandKey: ChartDataset['key'];
  brandName: ChartDataset['name'];
  x: string;
  y: ChartPoint['y'];
}

Chart.register(...registerables);

const ExcessShareOfVoiceBarChart: FunctionComponent<
  ExcessShareOfVoiceBarChartProps
> = ({ data, className }) => {
  const chartData = {
    datasets: [
      {
        label: 'Share of Voice',
        data: data.map((item) => item.shareOfVoice),
        backgroundColor: colors.chartBarOcean02,
        barThickness: 35,
        borderRadius: 8,
        datalabels: {
          display: false,
        },
      },
      {
        label: 'Share of Market',
        data: data.map((item) => item.shareOfMarket),
        backgroundColor: colors.colorSecondary30,
        barThickness: 75,
        borderRadius: 8,
        barPercentage: 0.5,
        datalabels: {
          display: false,
        },
      },
      {
        label: 'Percentages',
        data: data.map((item) => item.excessShareOfVoice),
        backgroundColor: 'transparent',
        borderWidth: 1,
        datalabels: {
          display: false,
        },
      },
    ],
    labels: Array.from({ length: data.length }, () => ''),
  };

  const chartOptions: CustomBarChartOptions = {
    labelData: {
      display: true,
      data,
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        display: true,
        position: 'right',
        suggestedMax: 100,
        min: 0,
        beginAtZero: true,
        ticks: {
          font: {
            size: 12,
          },
          color: 'grey',
          stepSize: 25,
          callback: (value) => {
            if (value === 0 || value === 50 || value === 100) {
              return value;
            }
            return '';
          },
        },
        grid: {
          display: true,
          drawTicks: false,
        },
      },
      x: {
        stacked: true,
        ticks: {
          color: 'grey',
          padding: 12,
          font: {
            size: 13,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };

  return (
    <div className={classNames(styles.ExcessShareOfVoiceBarChart, className)}>
      <Bar
        data={{ datasets: chartData.datasets, labels: chartData.labels }}
        options={chartOptions}
        plugins={[
          {
            id: 'decimalPoints',
            afterDraw: renderPercentages,
          },
          {
            id: 'iconsAsLabels',
            afterDraw: renderIconsAsLabels,
          },
        ]}
      />
    </div>
  );
};

export default ExcessShareOfVoiceBarChart;
