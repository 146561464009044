import {
  BubbleDataPoint,
  Chart,
  ChartTypeRegistry,
  ScatterDataPoint,
  TooltipModel,
} from 'chart.js';

export interface ChartTooltipContext {
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  >;
}

export interface ChartTooltipContextLine extends ChartTooltipContext {
  tooltip: TooltipModel<'line'>;
}

export interface ChartTooltipContextBar extends ChartTooltipContext {
  tooltip: TooltipModel<'bar'>;
}

export interface ChartTooltipContextPie extends ChartTooltipContext {
  tooltip: TooltipModel<'pie'>;
}

export interface ChartPoint {
  x: Date;
  y: number;
}
export interface ChartPointFormatted {
  x: string;
  y?: number;
}

export interface ChartDataset {
  key: string;
  name: string;
  logoUrl?: string;
  data: ChartPoint[];
  tile: {
    score: number | null;
    delta: number | null;
    tooltip: string;
  };
}

export interface ChartFlattendBrandDatasetPoint {
  brandKey: ChartDataset['key'];
  brandName: ChartDataset['name'];
  x: string;
  y: ChartPoint['y'];
}

export interface ChartMultipleBrandTooltipData {
  brandKey: string;
  brandName: string;
  value?: number;
}

export interface ChartMultipleBrandTooltipDataMap {
  [key: string]: ChartMultipleBrandTooltipData[];
}

export enum ChartType {
  Line = 'line',
  Area = 'area',
  Bar = 'bar',
  Pie = 'pie',
  Grid = 'grid',
}

export interface ChartColor {
  DEFAULT: string;
  FILL: string;
}

export interface YAxisFormatter {
  (value: number | string): string | string[] | number | number[] | null;
}

export interface TickFormatter {
  (index: number | string, labels: string[]): string;
}

export interface EventPoint {
  dataset: {
    id: string;
  };
  raw: {
    x: number;
    y: number;
    description: string;
  };
}

export interface CreativeChannelChartItem {
  data: Array<ChartPointFormatted>;
  diff: number;
  label: string;
  previous?: number | null;
  share?: number | null;
  total: number;
}
