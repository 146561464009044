import { GenericIconProps } from './types';

function PencilEdit(props: GenericIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.756 4a1 1 0 01.61.208l.1.088 4.238 4.288a1 1 0 01.088 1.32l-.088.1-2.839 2.779-6.917 6.927a1 1 0 01-.578.282L9.238 20H5a1 1 0 01-.993-.883L4 19v-4.238a1 1 0 01.203-.61l.087-.1 6.937-6.927 2.82-2.83a1 1 0 01.71-.295zM12.03 7.737l-.095.095L5 14.756V19L9.243 19l6.923-6.932.098-.096-.35-.35-3.535-3.536-.35-.35zm.706-.709l.35.35 3.536 3.536.358.358 2.02-1.979L14.756 5l-2.02 2.028z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default PencilEdit;
