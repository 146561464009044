import { FC } from 'react';
import classNames from 'classnames';
import DifferencePill from '../DifferencePill/DifferencePill';
import { CreativeChannelSpendAsset } from '../../../../interfaces/creative';
import { abbreviateNumber } from '../../../../utils/number';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';

import styles from './CreativeAdvertisingCard.module.scss';
import CreativeMiniLineChart from '../../../Charts/CreativeMiniLineChart/CreativeMiniLineChart';

export interface CreativeAdvertisingCardProps {
  data: CreativeChannelSpendAsset;
  loading: boolean;
  displayPercent?: boolean;
}

const CreativeAdvertisingCard: FC<CreativeAdvertisingCardProps> = ({
  data,
  loading,
  displayPercent,
}) => {
  return (
    <div
      className={classNames(styles.CreativeAdvertisingCard)}
      data-cy="creative-advertising-card"
    >
      <p className={styles.Total}>
        {displayPercent
          ? `${data?.total}%`
          : `$${abbreviateNumber(data?.total, 2)}`}
      </p>
      <div className={styles.Subheading}>
        <DifferencePill iconOnly percent={data.diff} />
      </div>
      <div className={styles.ChartContainer}>
        {!loading ? (
          <CreativeMiniLineChart data={data.data} showXAxis />
        ) : (
          <ComponentLoader minHeight={50} />
        )}
      </div>
    </div>
  );
};

export default CreativeAdvertisingCard;
