import { AnchorHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import keyBy from 'lodash/keyBy';
import { WidgetMetaResponse } from '../../types';
import { getMetrics } from '../../../../../mocks/data/CustomizableDashboards/metrics';
import { TimeRange, WidgetType } from '../../../../../interfaces/dashboard-api';
import WidgetSubheader from '../../WidgetSubheader/WidgetSubheader';
import InsightsCard from '../../InsightsCard/InsightsCard';
import SummaryTrendsWidgetGrid, {
  WidgetGridType,
} from './SummaryTrendsWidgetGrid/SummaryTrendsWidgetGrid';
import {
  GET_FIRST_AND_LAST_SESSION,
  GET_SUMMARY_TRENDS_DATA,
} from '../../../../../api/queries/Pages/CustomizableDashboards';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import { timeRangeToNumDays } from '../widgetFormatResponse';
import styles from './SummaryTrendsWidget.module.scss';

export interface SummaryTrendsWidgetProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  config: WidgetMetaResponse;
  globalDateFilter: {
    endDate: Date;
    timeRange: TimeRange | null;
  };
}

const SummaryTrendsWidget = ({
  config,
  globalDateFilter,
}: SummaryTrendsWidgetProps) => {
  const [activeTimeRange, setActiveTimeRange] = useState<TimeRange>(
    config?.data_srcs?.metrics?.time_range ?? TimeRange['90_DAYS']
  );
  const [activeEndDate, setActiveEndDate] = useState<Date>(
    config?.data_srcs?.metric?.input?.endDate
      ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
      : new Date()
  );

  useEffect(() => {
    if (globalDateFilter?.timeRange && globalDateFilter?.endDate) {
      setActiveTimeRange(globalDateFilter.timeRange);
      setActiveEndDate(globalDateFilter.endDate);
    } else {
      setActiveTimeRange(
        config?.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
      );
      setActiveEndDate(
        config?.data_srcs?.metric?.input?.endDate
          ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
          : new Date()
      );
    }
  }, [globalDateFilter, config]);

  const metricById = keyBy(getMetrics, 'id');

  // duplicative code with WidgetContextualHelpDrawer
  // TODO: refactor to avoid duplication
  const [getSummaryTrendsData, { loading, data }] = useLazyQuery(
    GET_SUMMARY_TRENDS_DATA,
    {
      variables: {},
      fetchPolicy: 'no-cache',
    }
  );

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );

  const validData = useMemo(() => {
    if (!data?.competitiveSet?.session?.trendSummaryWidget) {
      return false;
    }

    const { trendSummaryWidget } = data.competitiveSet.session;
    return Boolean(
      trendSummaryWidget.metricsTrendingUp ||
        trendSummaryWidget.metricsTrendingDown
    );
  }, [data]);

  useEffect(() => {
    if (config?.data_srcs?.metric?.input) {
      const { competitiveSetKey } = config.data_srcs.metric.input;

      getFirstLastSession({ variables: { id: competitiveSetKey } });
    }
  }, [config, getFirstLastSession]);

  // TODO: get session key based on custom date range
  const latestSessionKey =
    firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
      ?.sessionKey;

  useEffect(() => {
    if (!config?.data_srcs?.metric?.input) {
      return;
    }

    const { competitiveSetKey, brandKey } = config.data_srcs.metric.input;
    const timeRange = timeRangeToNumDays(activeTimeRange);

    getSummaryTrendsData({
      variables: {
        id: competitiveSetKey,
        sessionKey: latestSessionKey,
        input: {
          range: timeRange,
          endDate: activeEndDate,
          selectedBrandKey: brandKey,
          compSetKey: competitiveSetKey,
        },
      },
    });
  }, [
    activeTimeRange,
    activeEndDate,
    config,
    getSummaryTrendsData,
    latestSessionKey,
  ]);

  const summaryTrendsInsights = useMemo(() => {
    if (!data?.competitiveSet?.session?.trendSummaryWidget) {
      return null;
    }

    const brandName = data.competitiveSet.session.trendSummaryWidget.name;
    let insightChangeStatement = `${brandName}'s`;
    const trendingUp =
      data.competitiveSet.session.trendSummaryWidget.metricsTrendingUp;

    trendingUp.forEach(
      (row: {
        isolatedChangeRate: number;
        isolatedCohortRate: number;
        variableId: string;
      }) => {
        const roundedPercentageChange = Math.round(
          ((Number(row.isolatedChangeRate) + Number.EPSILON) * 100) / 100
        );
        const roundedPercentageCohort = Math.round(
          ((Number(row.isolatedCohortRate) + Number.EPSILON) * 100) / 100
        );

        if (roundedPercentageChange < 0) {
          insightChangeStatement += ` **${
            metricById[row.variableId].name
          } decreased ${roundedPercentageChange}%**`;
        } else {
          insightChangeStatement += ` **${
            metricById[row.variableId].name
          } increased ${roundedPercentageChange}%**`;
        }

        insightChangeStatement += ` in the last ${timeRangeToNumDays(
          activeTimeRange
        )} days`;

        if (roundedPercentageCohort < 0) {
          insightChangeStatement += ` and is below the cohort average by ${roundedPercentageCohort}%.\n\n`;
        } else {
          insightChangeStatement += ` and is above the cohort average by ${roundedPercentageCohort}%.\n\n`;
        }
      }
    );

    return insightChangeStatement as string;
  }, [
    data?.competitiveSet?.session?.trendSummaryWidget,
    metricById,
    activeTimeRange,
  ]);

  const handleTimeRangeChange = (option: TimeRange) => {
    setActiveTimeRange(option);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    setActiveTimeRange(e.timeRange ?? TimeRange['90_DAYS']);
    setActiveEndDate(e.endDate);
  };

  // TODO: restore "Explore Opportunities" button once there is a destination
  return (
    /* eslint-disable react/jsx-no-useless-fragment */ // needed for LoadingWidget to style properly
    <>
      {validData && !loading ? (
        <div className={styles.Container}>
          <WidgetSubheader
            brandName={data?.competitiveSet?.session?.trendSummaryWidget?.name}
            brandLogoUrl={
              data?.competitiveSet?.session?.trendSummaryWidget?.logoUrl
            }
            activeTimeRange={activeTimeRange}
            handleTimeRangeChange={handleTimeRangeChange}
            activeEndDate={activeEndDate}
            onCustomTimeRangeChange={handleApply}
          />

          <Box className={styles.ContentContainer}>
            <Box display="flex" flexDirection="column" gap="16px">
              <SummaryTrendsWidgetGrid
                title={WidgetGridType.TrendingUp}
                data={
                  data?.competitiveSet?.session?.trendSummaryWidget
                    ?.metricsTrendingUp
                }
              />
              <SummaryTrendsWidgetGrid
                title={WidgetGridType.TrendingDown}
                data={
                  data?.competitiveSet?.session?.trendSummaryWidget
                    ?.metricsTrendingDown
                }
              />
            </Box>
            <InsightsCard
              insights={summaryTrendsInsights || ''}
              className={styles.InsightCard}
            />
          </Box>
        </div>
      ) : (
        <LoadingWidget widgetType={WidgetType.SummaryTrendsV1} />
      )}
    </>
  );
};

export default SummaryTrendsWidget;
