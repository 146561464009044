import { AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import Markdown from 'react-markdown';
import MetricSourcesIcon from '../../../../assets/icons/MetricIcons/MetricSourcesIcon';
import MetricRefreshScheduleIcon from '../../../../assets/icons/MetricIcons/MetricRefreshScheduleIcon';
import MetricLookbackWindowIcon from '../../../../assets/icons/MetricIcons/MetricLookbackWindowIcon';
import IconCircleCheckSolid from '../../../../assets/icons/IconCircleCheckSolid';
import IconCirclePlus from '../../../../assets/icons/IconCirclePlus';
import CollapseIcon from '../../../../assets/icons/NavIcons/CollapseIcon';
import type { GetMetricResponse } from '../../../../mocks/data/CustomizableDashboards/metrics';
import styles from './MetricDetailCard.module.scss';

export interface MetricDetailCardProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  metric: GetMetricResponse;
  isSelected?: boolean;
  onCardSelect?: (metric: GetMetricResponse) => void;
  onReadMoreClick?: (metricId: string) => void;
}

const MetricDetailCard = ({
  className,
  metric,
  isSelected = false,
  onCardSelect,
  onReadMoreClick,
}: MetricDetailCardProps) => {
  return (
    <div
      className={classNames(styles.MetricDetailCard, className)}
      onClick={() => onCardSelect?.(metric)}
      style={{ cursor: onCardSelect ? 'pointer' : 'default' }}
    >
      <div className={styles.Header}>
        <p className={styles.Title}>{metric?.name}</p>

        {onCardSelect && (
          <div className={styles.SelectedStatus}>
            {isSelected ? <IconCircleCheckSolid /> : <IconCirclePlus />}
          </div>
        )}

        {onReadMoreClick && (
          <div
            className={styles.ReadMoreButton}
            onClick={() => onReadMoreClick(metric?.id)}
          >
            <p className={styles.ReadMoreText}>Read More</p>

            <CollapseIcon />
          </div>
        )}
      </div>

      <div className={styles.Details}>
        <div className={styles.DetailsCol}>
          <div className={styles.DetailSection}>
            <MetricLookbackWindowIcon className={styles.Icon} />

            <div className={styles.TextContainer}>
              <p className={styles.SectionHeader}>Lookback Window</p>

              <Markdown className={styles.SectionTextContainer}>
                {metric?.lookback_window_md || 'N/A'}
              </Markdown>
            </div>
          </div>

          <div className={styles.DetailSection}>
            <MetricRefreshScheduleIcon className={styles.Icon} />

            <div className={styles.TextContainer}>
              <p className={styles.SectionHeader}>Refresh Schedule</p>

              <Markdown className={styles.SectionTextContainer}>
                {metric?.refresh_schedule_md || 'N/A'}
              </Markdown>
            </div>
          </div>
        </div>

        <div className={styles.DetailsCol}>
          <div className={styles.DetailSection}>
            <MetricSourcesIcon className={styles.Icon} />

            <div className={styles.TextContainer}>
              <p className={styles.SectionHeader}>Sources</p>

              <Markdown className={styles.SectionTextContainer}>
                {metric?.data_source_md || 'N/A'}
              </Markdown>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.TextContainer, styles.Description)}>
        <p className={styles.SectionHeader}>What is this Metric?</p>

        <Markdown className={styles.SectionTextContainer}>
          {metric?.description_md || 'N/A'}
        </Markdown>
      </div>
    </div>
  );
};

export default MetricDetailCard;
