import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import RecommendationsList from '../RecommendationsList/RecommendationsList';
import Dropdown from '../../Molecules/Dropdown/Dropdown';

import useRouter from '../../../hooks/useRouter';

import IconRecommendations from '../../../assets/icons/Recommendations';

import { TAGS, IMPACT_RESULTS } from '../../../constants/recommendations';

import styles from './RecommendationsSection.module.scss';

const PRACTICE_AREA_OPTIONS = TAGS.map((tag) => ({
  label: tag.label,
  value: tag.label,
}));
PRACTICE_AREA_OPTIONS.unshift({
  label: 'All',
  value: null,
});

const IMPACT_RESULT_OPTIONS = IMPACT_RESULTS.map((impactResults) => ({
  label: impactResults.label,
  value: impactResults.key,
}));
IMPACT_RESULT_OPTIONS.unshift({
  label: 'All',
  value: null,
});

export default function RecommendationsSection({
  className,
  recommendations,
  ...props
}) {
  const { updateRoute } = useRouter();

  const [practiceAreaId, setPracticeAreaId] = useState(null);
  const [impactResultsKey, setImpactResultKey] = useState(null);

  return (
    <Box
      p={4}
      className={classNames(styles.RecommendationsSection, className)}
      {...props}
    >
      <Box className={styles.Header} py={5}>
        <h1 className={styles.Title}>This Section</h1>
        <Box className={styles.LinkWrap}>
          <Box mr={2}>
            <IconRecommendations className={styles.Icon} />
          </Box>

          <div
            onClick={() => updateRoute({ pathname: '/recommendations' })}
            className={styles.Link}
          >
            View all recommendations »
          </div>
        </Box>
      </Box>

      <Box className={styles.Filters} pt={3} pb={4}>
        <Box className={styles.Filter}>
          <Box mb={2}>
            <span className={styles.Label}>PRACTICE AREA</span>
          </Box>

          <Dropdown
            options={PRACTICE_AREA_OPTIONS}
            value={practiceAreaId}
            onChange={({ value }) => setPracticeAreaId(value)}
            fullWidth
          />
        </Box>

        <Box className={styles.Filter}>
          <Box mb={2}>
            <span className={styles.Label}>IMPACT RESULTS</span>
          </Box>

          <Dropdown
            options={IMPACT_RESULT_OPTIONS}
            value={impactResultsKey}
            onChange={({ value }) => setImpactResultKey(value)}
            fullWidth
          />
        </Box>
      </Box>

      <Box>
        <RecommendationsList
          impactResultsFilters={[impactResultsKey]}
          tagFilters={[practiceAreaId]}
          lifecycleFilters={[0]}
          recommendations={recommendations}
        />
      </Box>
    </Box>
  );
}

RecommendationsSection.propTypes = {
  className: PropTypes.string,
  recommendations: PropTypes.arrayOf(PropTypes.object),
};
