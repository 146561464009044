import React from 'react';
import PropTypes from 'prop-types';
import { BrandLogo } from '@blueoceanai/react-component-library';

import VerbatimTag from '../../Atoms/VerbatimTag/VerbatimTag';
import { SIZES } from '../../../constants/props';

import styles from './VerbatimBrand.module.scss';

export default function VerbatimBrand({ content = '', source, logoUrl }) {
  // todo: add logic to parse verbatim data
  return (
    <div className={styles.VerbatimBrand}>
      <div className={styles.Image}>
        <BrandLogo size={SIZES.SM} src={logoUrl} />
      </div>
      <div className={styles.Content}>
        <p>{content}</p>
      </div>

      {source && <VerbatimTag className={styles.Tag} source={source} />}
    </div>
  );
}

VerbatimBrand.propTypes = {
  content: PropTypes.string,
  logoUrl: PropTypes.string,
  source: PropTypes.string,
};
