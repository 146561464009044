import { useEffect } from 'react';

export default function useElementResize(element, cb, dependencies = []) {
  useEffect(() => {
    function handleResize() {
      cb();
    }

    element.addEventListener('resize', handleResize);
    handleResize();

    return function cleanup() {
      return element.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [...dependencies, element]);
}
