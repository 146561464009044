import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import cn from 'classnames';
import { CreativeChannelTableAsset } from '../../../../interfaces/creative';
import styles from './AdvertisingTable.module.scss';
import {
  ChartLabelCell,
  NumberTransformCell,
  DiffPillCell,
  AssetNumberFormat,
} from '../AssetTable/AssetTableCells';
import DiffPillMini from '../DifferencePill/DiffPillMini';
import TablePin from '../TablePin/TablePin';
import { abbreviateNumber } from '../../../../utils/number';

export interface AdvertisingTableProps {
  data: CreativeChannelTableAsset[];
  showHeader?: boolean;
  headerTitle?: string;
  headerTotal?: number;
  headerDiff?: number;
  eCPM?: boolean;
  tableId: number;
  onPinClick?: (tableId: number) => void;
  onLegendClick?: (rowLegendId: number) => void;
  shareOfSpend: boolean;
  pinnedTableId?: number;
  showTitleOnly: boolean;
}

type FieldValue = string | number;

enum AdvertisingTableColumns {
  Label = 'label',
  Previous = 'previous',
  Current = 'total',
  Change = 'diff',
}

const AdvertisingTable = ({
  data,
  showHeader,
  headerTitle,
  headerTotal,
  headerDiff,
  eCPM,
  tableId,
  pinnedTableId,
  onPinClick,
  onLegendClick,
  shareOfSpend,
  showTitleOnly,
}: AdvertisingTableProps) => {
  const isPinnedTable = tableId === pinnedTableId;

  const columns: ColumnDef<CreativeChannelTableAsset, FieldValue>[] = [
    {
      accessorKey: AdvertisingTableColumns.Label,
      header: '',
      cell: (cell) =>
        ChartLabelCell({
          label: cell.getValue(),
          className: styles.Label,
          color: isPinnedTable ? cell?.row?.original?.legend?.color : undefined,
          onLegendClick: () => onLegendClick?.(cell.row.original?.rowId),
        }),
    },
    {
      accessorKey: AdvertisingTableColumns.Previous,
      header: 'Previous',
      cell: (cell) =>
        NumberTransformCell(
          cell.getValue(),
          shareOfSpend
            ? AssetNumberFormat.Percentage
            : AssetNumberFormat.CurrencyAbbreviated
        ),
    },
    {
      accessorKey: AdvertisingTableColumns.Current,
      header: 'Current',
      cell: (cell) =>
        NumberTransformCell(
          cell.getValue(),
          shareOfSpend
            ? AssetNumberFormat.Percentage
            : AssetNumberFormat.CurrencyAbbreviated
        ),
    },
    {
      accessorKey: AdvertisingTableColumns.Change,
      header: 'Change',
      cell: (cell) => DiffPillCell(cell.getValue()),
    },
  ];

  const table = useReactTable({
    data,
    columns,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={styles.TableMain}>
      {showHeader && (
        <div className={styles.TableHeader}>
          <span className={styles.Heading}>
            <p>{headerTitle}</p>

            {onPinClick && (
              <TablePin
                className={styles.PinIcon}
                active={isPinnedTable}
                onClick={() => onPinClick(tableId)}
              />
            )}
          </span>
          {!showTitleOnly && (
            <span className={styles.PercentIcons}>
              <p>{`$${abbreviateNumber(headerTotal, 2)}`}</p>
              <DiffPillMini percentage={headerDiff} />
            </span>
          )}
        </div>
      )}
      <table className={styles.AdvertisingTableContainer}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className={styles.HeaderRow}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()} // map to function to be called
                  >
                    <div className={cn(styles.HeaderCell)}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}

                      {header.column.columnDef.header === '' &&
                        !showHeader &&
                        !eCPM &&
                        onPinClick && (
                          <TablePin
                            className={styles.PinIcon}
                            active={isPinnedTable}
                            onClick={() => onPinClick(tableId)}
                          />
                        )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={styles.Row}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={styles.Cell}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdvertisingTable;
