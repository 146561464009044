import random from 'lodash/random';
import last from 'lodash/last';
import head from 'lodash/head';

import { isBetween } from '../../../utils/timeframe';

export function prepareFactor(data, timeframe) {
  if (
    !data?.competitiveSet?.session?.factor?.name ||
    !data?.competitiveSet?.session?.factor?.value
  ) {
    return;
  }

  const { name } = data.competitiveSet.session.factor;

  const scoreOverTime = JSON.parse(
    data.competitiveSet.session.factorsCalc[0].t4Quarters
  )
    .filter((f) => f.name === name)
    .map((f) => ({
      date: f.date,
      name: f.name.toLowerCase(),
      value: Math.round(f.value),
    }))
    .filter((f) => isBetween(f.date, timeframe));

  const latestScore = last(scoreOverTime)?.value;
  const oldestScore = head(scoreOverTime)?.value;

  return {
    score: latestScore,
    factorType: name,
    competitorAverageScore: 100,
    delta: latestScore - oldestScore,
    scoreOverTime,
  };
}

export function prepareSubfactors(data, timeframe) {
  if (
    !data?.competitiveSet?.session?.factor?.subFactors?.length ||
    !data?.competitiveSet?.session?.subfactorsCalc?.[0]?.t4Quarters
  ) {
    return;
  }

  return data.competitiveSet.session.factor.subFactors.map((subfactor) => {
    const scoreOverTime = JSON.parse(
      data.competitiveSet.session.subfactorsCalc[0].t4Quarters
    )
      .filter((f) => f.name === subfactor.name)
      .map((f) => ({
        date: f.date,
        name: f.name.toLowerCase(),
        value: Math.round(f.value),
      }))
      .filter((f) => isBetween(f.date, timeframe));

    const latestScore = last(scoreOverTime)?.value;
    const oldestScore = head(scoreOverTime)?.value;

    return {
      id: subfactor.associatedTypeId,
      factorType: subfactor.name.toLowerCase(),
      score: latestScore,
      competitorAverageScore: 100,
      delta: latestScore - oldestScore,
      scoreOverTime,
    };
  });
}

export function prepareInsight(data) {
  if (!data?.competitiveSet?.session?.factor?.insight?.value) {
    return ['No Insight Available'];
  }

  try {
    let insight = data.competitiveSet.session.factor.insight.value;

    if (insight[0] === '[') {
      insight = JSON.parse(insight);
      insight = insight.filter((blankInsight) => /\S/.test(blankInsight));
      return [insight[random(insight.length - 1)]];
    }

    return [insight];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
