import classNames from 'classnames';
import dayjs from 'dayjs';
import { FACTOR_RANGES } from '../../../constants/factors';
import { ChartPoint, ChartTooltipContextLine } from '../../../interfaces';

import { getOrCreateTooltip } from '../../../utils/chartjs';

import styles from './bluescoreTimelineTooltip.module.scss';

export default function renderSessionMarkerTooltip(
  context: ChartTooltipContextLine
): void {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, {
    wrapper: styles.MarkerTooltip,
    contents: styles.TooltipContents,
  });

  //   Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  const getKey = (key: number) => {
    return FACTOR_RANGES.find((item) => item.FLOOR <= key && key <= item.CEIL);
  };

  const isTooltipFillerData = tooltip.dataPoints.find(
    (item) => item.datasetIndex === 1 || item.datasetIndex === 2
  );

  // fixes edge case where the last point has both an invalid tooltip, and a valid tooltip
  if (isTooltipFillerData) {
    const isValidData = tooltip.dataPoints.find(
      (item) => item.datasetIndex === 0
    );

    if (!isValidData) {
      tooltipEl.style.opacity = '0';
      return;
    }
  }

  // Set Text
  if (tooltip.body) {
    const tooltipContents = tooltipEl.querySelector(
      `.${styles.TooltipContents}`
    );

    const dataPoint = tooltip.dataPoints[0].raw as ChartPoint;

    const identifier = getKey(dataPoint?.y);

    const rangeClasses = classNames(styles.Score, styles[identifier?.KEY || 0]);

    const splitClassNameStr = rangeClasses.split(/ (.*)/);
    // the classList add method doesnt allow white space chars, need to hack the original
    // string into two

    const tooltipParent = document.createElement('div');
    tooltipParent.classList.add(splitClassNameStr[0], splitClassNameStr[1]);

    const scoreValueEl = document.createElement('div');
    scoreValueEl.classList.add(styles.ScoreValue);
    const scoreTextEl = document.createTextNode(dataPoint?.y.toString());
    scoreValueEl.appendChild(scoreTextEl);

    const scoreDateEl = document.createElement('div');
    scoreDateEl.classList.add(styles.ScoreDate);
    const dateTextEl = document.createTextNode(
      dayjs(dataPoint?.x).format('MMM DD')
    );
    scoreDateEl.appendChild(dateTextEl);

    tooltipParent.appendChild(scoreValueEl);
    tooltipParent.appendChild(scoreDateEl);

    // Remove old children
    while (tooltipContents?.firstChild) {
      tooltipContents.firstChild.remove();
    }

    // Add new children
    tooltipContents?.appendChild(tooltipParent);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.top = `${positionY + tooltip.caretY - 70}px`;
    tooltipEl.style.left = `${positionX + tooltip.caretX - 40}px`;
    tooltipEl.style.right = `${positionY + tooltip.caretY}px`;
    tooltipEl.style.padding = `${tooltip.options.padding}px`;
    tooltipEl.style.opacity = '1';
  }
}
