import keyBy from 'lodash/keyBy';

export const IMPACT_RESULT_KEYS = {
  // TACTICAL: 'tactical',
  TACTICAL: 1,
  // STRATEGIC: 'strategic',
  STRATEGIC: 2,
  // EVOLVE: 'evolve',
  EVOLVE: 3,
};

export const IMPACT_RESULTS = [
  {
    label: 'Tactical',
    key: IMPACT_RESULT_KEYS.TACTICAL,
  },
  {
    label: 'Strategic',
    key: IMPACT_RESULT_KEYS.STRATEGIC,
  },
  {
    label: 'Evolve',
    key: IMPACT_RESULT_KEYS.EVOLVE,
  },
];

export const TAGS = [
  {
    id: 0,
    label: 'Creative',
  },
  {
    id: 1,
    label: 'Customer Support',
  },
  {
    id: 2,
    label: 'HR',
  },
  {
    id: 3,
    label: 'Marketing',
  },
  {
    id: 4,
    label: 'Media',
  },
  {
    id: 5,
    label: 'Operations',
  },
  {
    id: 6,
    label: 'Product',
  },
  {
    id: 7,
    label: 'Product Marketing',
  },
  {
    id: 8,
    label: 'Social',
  },
];

export const RECOMMENDATION_LIFECYCLE = {
  IN_PROGRESS: 0,
  COMPLETE: 1,
};

export const GROUP_BY_OPTIONS_KEYS = {
  IMPACT_RESULT: 'impact-result',
  PRACTICE_AREA: 'practice-area',
};

export const GROUP_BY_OPTIONS = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Impact Results',
    value: GROUP_BY_OPTIONS_KEYS.IMPACT_RESULT,
  },
  {
    label: 'Practice Area',
    value: GROUP_BY_OPTIONS_KEYS.PRACTICE_AREA,
  },
];

export const GROUP_BY_OPTIONS_BY_VALUE = keyBy(GROUP_BY_OPTIONS, 'value');
