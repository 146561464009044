import React, { SetStateAction, useCallback, useState } from 'react';
import { LeftNavIcon } from '../../../assets/icons';
import ModalContainer from '../../Modals/ModalContainer';
import CreativeIntelligenceModalContent from '../../Pages/Creative/CreativeIntelligenceModalContent/CreativeIngelligenceModalContent';
import useRouter from '../../../hooks/useRouter';
import { NavigationItem } from '../../Organisms/LeftNav/useLeftNavItems';
import LeftNavItemSubNav from '../LeftNavItemSubNav/LeftNavItemSubNav';
import styles from './LeftNavItem.module.scss';

export interface LeftNavItemProp extends NavigationItem {
  openSubNav: string | null;
  setOpenSubNav: React.Dispatch<SetStateAction<string | null>>;
  isActive: boolean;
}

const LeftNavItem = ({
  openSubNav,
  setOpenSubNav,
  name,
  isActive,
  allowAccess,
  subNav,
  pathname,
  params,
  iconType,
}: LeftNavItemProp) => {
  const { updateRoute } = useRouter();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const hasSubRoutes = Boolean(subNav?.length);
  const handleModalClose = () => setIsOpenModal(false);

  const handleMouseEnter = useCallback(() => {
    const openNavContent = allowAccess && subNav.length ? name : null;
    setOpenSubNav(openNavContent);
  }, [allowAccess, name, setOpenSubNav, subNav.length]);

  const handleNavClick = useCallback(() => {
    if (!allowAccess) {
      setIsOpenModal(true);
    }

    if (!hasSubRoutes) {
      updateRoute({ pathname, params });
    }
  }, [allowAccess, hasSubRoutes, params, pathname, updateRoute]);

  return (
    <>
      <ModalContainer
        isOpen={isOpenModal}
        closeModal={handleModalClose}
        className={styles.Modal}
      >
        <CreativeIntelligenceModalContent />
      </ModalContainer>
      <li
        key={name}
        className={isActive ? styles.LeftNavItemActive : styles.LeftNavItem}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setOpenSubNav(null)}
      >
        <div className={styles.NavItem} onClick={handleNavClick}>
          <LeftNavIcon type={iconType} />
          <span className={styles.Subtitle}>{name}</span>
          {hasSubRoutes && (
            <LeftNavItemSubNav
              name={name}
              pathname={pathname}
              params={params}
              headerName={name}
              navItems={subNav}
              openSubNav={openSubNav}
            />
          )}
        </div>
      </li>
    </>
  );
};

export default LeftNavItem;
