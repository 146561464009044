import { GenericIconProps } from './types';

const LogoutIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L24 0 24 24 0 24z"
              transform="translate(-1248 -100) translate(1240 56) translate(8 44)"
            />
            <path
              fill="#7E9397"
              d="M18.5 2C19.878 2 21 3.121 21 4.5v15c0 1.379-1.122 2.5-2.5 2.5h-8C9.122 22 8 20.879 8 19.5V14h1v5.5c0 .778.596 1.42 1.356 1.493L10.5 21h8c.827 0 1.5-.673 1.5-1.5v-15c0-.827-.673-1.5-1.5-1.5h-8C9.673 3 9 3.673 9 4.5V9H8V4.5c0-1.324 1.034-2.41 2.336-2.495L10.5 2zm-4 9c.276 0 .5.224.5.5s-.224.5-.5.5H4.707l2.147 2.147c.195.195.195.512 0 .707-.098.097-.226.146-.354.146-.128 0-.256-.049-.354-.146l-3-2.999c-.046-.047-.083-.102-.108-.163-.05-.123-.05-.26 0-.383.025-.06.062-.116.108-.163l3-2.999c.195-.195.512-.195.707 0 .195.195.195.512 0 .707L4.707 11z"
              transform="translate(-1248 -100) translate(1240 56) translate(8 44) matrix(-1 0 0 1 24 0)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoutIcon;
