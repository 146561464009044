import { gql } from '@apollo/client';

export const GET_SUBFACTORS = gql`
  query getSubFactorData(
    $id: ID
    $sessionKey: ID
    $subFactorId: Int
    $accountKey: ID
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        subFactor(subFactorId: $subFactorId) {
          name
          value
          delta
          scoreDrivers {
            isCurrency
            brand_id
            session_id
            metricName
            value
            description
            metricGroup
            metricCategory
            metricVisualType
            properties
            variableId
            metricLastPeriodScores {
              type
              t4Quarters
            }
          }
          recommendations(accountKey: $accountKey) {
            id
            name
            value
            type
            impact_id
            sourceType_id
            isComplete
            practice_areas
          }
          insight {
            name
            value
          }
        }
        subfactorsCalc: brandLastPeriodScores(associatedTypeId: 2) {
          type
          t4Quarters
        }
        competitorSubFactorAverage {
          factor_id
          name
          average
        }
      }
    }
  }
`;
