import { AnchorHTMLAttributes } from 'react';

const InfoStars = (props: AnchorHTMLAttributes<HTMLOrSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 13L12 11.5L9.5 10L8 7L6.5 10L4 11.5L6.5 13L8 16L9.5 13Z"
      stroke="#7E9AF7"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M17.125 8L19 7L17.125 6L16 4L14.875 6L13 7L14.875 8L16 10L17.125 8Z"
      stroke="#7E9AF7"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M15.125 19L17 18L15.125 17L14 15L12.875 17L11 18L12.875 19L14 21L15.125 19Z"
      stroke="#7E9AF7"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default InfoStars;
