import * as React from 'react';

function IconFullScreen(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          d="M4 7.5a.5.5 0 01-1 0v-2A2.5 2.5 0 015.5 3h2a.5.5 0 010 1h-2A1.5 1.5 0 004 5.5v2zM16.5 4a.5.5 0 010-1h2A2.5 2.5 0 0121 5.5v2a.5.5 0 01-1 0v-2A1.5 1.5 0 0018.5 4h-2zM20 16.5a.5.5 0 011 0v2a2.5 2.5 0 01-2.5 2.5h-2a.5.5 0 010-1h2a1.5 1.5 0 001.5-1.5v-2zM7.5 20a.5.5 0 010 1h-2A2.5 2.5 0 013 18.5v-2a.5.5 0 011 0v2A1.5 1.5 0 005.5 20h2z"
          fill="#20434A"
        />
      </g>
    </svg>
  );
}

export default IconFullScreen;
