import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import { BreakdownType } from '../../../interfaces/metric';

import styles from './BreakdownTypeToggle.module.scss';

export interface BreakdownTypeToggleProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  /**
   * Callback for when the breakdown type is changed.
   */
  onTypeChange: (value: BreakdownType) => void;

  /**
   * The currently selected breakdown type.
   *
   * @default BreakdownType.Aggregate
   */
  value: BreakdownType;
}

export interface BreakdownToggleOption {
  label: string;
  value: BreakdownType;
}

const BreakdownTypeToggle: FunctionComponent<BreakdownTypeToggleProps> = ({
  className,
  onTypeChange,
  value = BreakdownType.Aggregate,
  ...props
}) => {
  const breakdownToggleOptions: BreakdownToggleOption[] = [
    {
      label: 'Aggregate',
      value: BreakdownType.Aggregate,
    },
    {
      label: 'By Channel',
      value: BreakdownType.Channel,
    },
  ];

  return (
    <div
      className={classNames(styles.BreakdownTypeToggle, className)}
      {...props}
    >
      {breakdownToggleOptions.map((option) => (
        <div
          key={option.value}
          className={classNames(
            styles.Option,
            value === option.value && styles.Selected
          )}
          onClick={() => onTypeChange(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default BreakdownTypeToggle;
