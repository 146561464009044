import * as React from 'react';

function SvgIconSend(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g
        style={{
          mixBlendMode: 'darken',
        }}
        fill="none"
        fillRule="evenodd"
      >
        <path d="M0 0h24v24H0z" />
        <path
          fill="#CED9DB"
          d="M20.616 4a.382.382 0 01.368.272l.008.031.002.014a.368.368 0 01-.01.174l-2.882 13.68a1.05 1.05 0 01-1.607.655l-3.604-2.403-1.72 2.95a.765.765 0 01-1.415-.272l-.008-.114-.001-4.575-6.094-1.693a.9.9 0 01-.637-.699l-.014-.112a.901.901 0 01.55-.885L20.48 4.031h.002a.277.277 0 01.074-.024zM10.497 14.828v4.159c0 .006.005.012.011.013h.01l.006-.006 1.742-2.987-1.769-1.179zm9.515-9.372l-9.286 8.622 6.184 4.123a.305.305 0 00.136.048l.072-.002.07-.019a.298.298 0 00.18-.213l2.644-12.559zm-1.168.061L3.842 11.715a.15.15 0 00.014.282l6.165 1.712 8.823-8.192z"
        />
      </g>
    </svg>
  );
}

export default SvgIconSend;
