import { gql } from '@apollo/client';

export const GET_METRICS = gql`
  query getMetrics($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      competitiveSetKey
      session(sessionKey: $sessionKey) {
        metrics {
          id
          name
          variableId
          isCurrency
          group
          category
          value: original_value
          description
        }
        metricGroups {
          name
          id
        }
        metricCategories {
          id
          name
          metricGroupId
        }
        # metricVisualTypes {
        #   id
        #   name
        # }
      }
    }
  }
`;

export const GET_METRICS_BY_GROUP = gql`
  query getMetricsByGroup($id: ID, $sessionKey: ID, $groupId: Int) {
    competitiveSet(id: $id) {
      competitiveSetKey
      brand {
        brandKey
        name
        id
        domain
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        metrics: metricsByGroup(groupId: $groupId) {
          id
          name
          variableId
          isCurrency
          group
          category
          value: original_value
          delta
        }
        competitors {
          name
          metrics: metricsByGroup(groupId: $groupId) {
            id
            name
            variableId
            isCurrency
            group
            category
            value: original_value
            delta
          }
        }
        brandLastPeriodScores(associatedTypeId: 3) {
          t4Quarters
        }
      }
    }
  }
`;

export const GET_METRICS_BY_GROUP_AND_CATEGORY = gql`
  query getMetricsByGroupAndCategory(
    $id: ID
    $sessionKey: ID
    $groupId: Int
    $categoryId: Int
  ) {
    competitiveSet(id: $id) {
      competitiveSetKey
      brand {
        brandKey
        name
        id
        domain
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        metrics: metricsByGroupAndCategory(
          groupId: $groupId
          categoryId: $categoryId
        ) {
          brand_id
          id
          name
          variableId
          isCurrency
          group
          category
          value: original_value
          delta
        }
        competitors {
          name
          metrics: metricsByGroupAndCategory(
            groupId: $groupId
            categoryId: $categoryId
          ) {
            brand_id
            id
            name
            variableId
            isCurrency
            group
            category
            value: original_value
            delta
          }
        }
        brandLastPeriodScores(associatedTypeId: 3) {
          t4Quarters
        }
      }
    }
  }
`;

export const GET_METRICS_TRENDLINE = gql`
  query getMetricTrendline($id: ID, $sessionKey: ID, $metricId: String) {
    competitiveSet(id: $id) {
      competitiveSetKey
      brand {
        name
        id
        brandKey
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        id
        sessionDate
        metricTrend(associatedTypeId: $metricId) {
          t4Quarters
        }
        competitors {
          id
          brandKey
          name
          logoUrl
          metricTrend(associatedTypeId: $metricId) {
            t4Quarters
          }
        }
      }
    }
  }
`;

export const GET_SOCIAL_METRICS_SET_DETAILS_BY_CHANNEL = gql`
  query SocialMetricsDetails(
    $input: SocialMetricsCompetitiveSetTimeSeriesInput
  ) {
    getSocialMetricsSetDetailsByChannel(input: $input) {
      brand_key_string
      results {
        channel
        timeseries {
          date
          value
        }
        description
        tile {
          score
          tooltip
        }
      }
    }
  }
`;

export const GET_SOCIAL_METRICS_CHANNEL = gql`
  query getSocialMetricsByChannel($input: SocialMetricsTimeSeriesInput) {
    getSocialMetricsByChannel(input: $input) {
      channel
    }
  }
`;
