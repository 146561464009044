/**
 * This file handles the initialization of the site.
 *
 * See:
 * https://miro.com/app/board/uXjVPb7hKJg=/
 * https://app.clickup.com/8664410/v/dc/88dau-25900/88dau-80660
 */
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import urlcat from 'urlcat';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import find from 'lodash/find';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { DATE_PICKER_OPTIONS } from '../../../constants/date-selector';
import QUERY_PARAMS from '../../../constants/queryParams';
import { Environment } from '../../../interfaces/env';

import useQuery from '../../../hooks/useQuery';
import useRouter from '../../../hooks/useRouter';

import {
  BASE_FEATURE_FLAGS,
  getFeatureFlags,
} from '../../../constants/feature-flags';
import { BNContextProvider } from '../../../contexts/BNContext';

import {
  GET_ALL_BRANDS_BY_USERID,
  GET_FIRST_AND_LAST_SESSION,
  USER_AND_ACCOUNT_KEYS,
  GET_METRICS_FAVORITE,
} from '../../../api/queries/DataManager';

import { GET_SHAREABLE_USERS } from '../../../api/queries/Pages/CustomizableDashboards';
import { userMock } from '../../../mocks/data/auth0';

import { prepareMetricFavoriteMap } from '../../../api/transforms/DataManager';

export default function DataManager({ children }) {
  const navigate = useNavigate();
  const queryParams = useQuery();
  const location = useLocation();
  const ldClient = useLDClient();

  const { updateRoute } = useRouter();

  const priceId = useMemo(() => queryParams.get('priceId'), [queryParams]);
  const productId = useMemo(() => queryParams.get('productId'), [queryParams]);

  const localStoragePriceId = localStorage.getItem('priceId');
  const localStorageProductId = localStorage.getItem('productId');

  const { user } = useAuth0();

  const [accountList, setAccountList] = useState([]);
  const [accountType, setAccountType] = useState(0);
  const [accountKey, setAccountKey] = useState(
    localStorage.getItem('accountId')
  );
  const [competitiveSetId, setCompetitiveSetId] = useState(
    queryParams.get(QUERY_PARAMS.COMPETITIVE_SET_KEY)
  );
  const [heroBrandKey, setHeroBrandKey] = useState();
  const [currentSession, setCurrentSession] = useState();
  const [userRole, setUserRole] = useState({
    id: 0,
    name: '',
  });
  const [FEATURE_FLAGS, SET_FEATURE_FLAGS] = useState(BASE_FEATURE_FLAGS);
  const [newestSession, setNewestSession] = useState();
  const [oldestSession, setOldestSession] = useState();
  const [allSessions, setAllSessions] = useState();
  const [initialCompetitiveSetLoaded, setInitialCompetitiveSetLoaded] =
    useState(false);
  const [timeframeAdjustmentsCalculated, setTimeframeAdjustmentsCalculated] =
    useState(false);

  const [timeframe, setTimeframe] = useState({
    label:
      queryParams.get(QUERY_PARAMS.TIMEFRAME_LABEL) ||
      DATE_PICKER_OPTIONS[1].label,
    unit:
      queryParams.get(QUERY_PARAMS.TIMEFRAME_UNIT) ||
      DATE_PICKER_OPTIONS[1].unit,
    quantity: queryParams.get(QUERY_PARAMS.TIMEFRAME_QUANTITY)
      ? Number(queryParams.get(QUERY_PARAMS.TIMEFRAME_QUANTITY))
      : DATE_PICKER_OPTIONS[1].quantity,
    end: queryParams.get(QUERY_PARAMS.TIMEFRAME_END)
      ? new Date(queryParams.get(QUERY_PARAMS.TIMEFRAME_END))
      : dayjs().endOf('day').toDate(),
  });

  const [nonSessionTimeframe, setNonSessionTimeframe] = useState({
    label:
      queryParams.get(QUERY_PARAMS.NONSESSION_TIMEFRAME_LABEL) ||
      DATE_PICKER_OPTIONS[1].label,
    unit:
      queryParams.get(QUERY_PARAMS.NONSESSION_TIMEFRAME_UNIT) ||
      DATE_PICKER_OPTIONS[1].unit,
    quantity: queryParams.get(QUERY_PARAMS.NONSESSION_TIMEFRAME_QUANTITY)
      ? Number(queryParams.get(QUERY_PARAMS.NONSESSION_TIMEFRAME_QUANTITY))
      : DATE_PICKER_OPTIONS[1].quantity,
    end: queryParams.get(QUERY_PARAMS.TIMEFRAME_END)
      ? new Date(queryParams.get(QUERY_PARAMS.TIMEFRAME_END))
      : dayjs().endOf('day').toDate(),
  });

  const [metricsFavoriteMap, setMetricsFavoriteMap] = useState({});

  const [areSessionsLoaded, setAreSessionsLoaded] = useState(false);

  const [userSettingsData, setUserSettingsData] = useState([]);

  // MANAGE CONTEXT STATES
  const _setTimeframe = useCallback(
    (newTimeframe) => {
      const timeframeEnd = dayjs(newTimeframe.end).endOf('day');

      updateRoute({
        params: {
          [QUERY_PARAMS.TIMEFRAME_UNIT]: newTimeframe.unit,
          [QUERY_PARAMS.TIMEFRAME_QUANTITY]: Number(newTimeframe.quantity),
          [QUERY_PARAMS.TIMEFRAME_END]: timeframeEnd.toISOString(),
          [QUERY_PARAMS.TIMEFRAME_LABEL]: newTimeframe.label,
          [QUERY_PARAMS.TIMEFRAME_UPDATED_AT]: new Date().toISOString(),
        },
      });

      setTimeframe({ ...newTimeframe, end: timeframeEnd.toDate() });
    },
    [updateRoute]
  );

  const _setNonSessionTimeframe = useCallback(
    (newTimeframe) => {
      const timeframeEnd = dayjs(newTimeframe.end).endOf('day');

      updateRoute({
        params: {
          [QUERY_PARAMS.NONSESSION_TIMEFRAME_UNIT]: newTimeframe.unit,
          [QUERY_PARAMS.NONSESSION_TIMEFRAME_QUANTITY]: Number(
            newTimeframe.quantity
          ),
          [QUERY_PARAMS.NONSESSION_TIMEFRAME_END]: timeframeEnd.toISOString(),
          [QUERY_PARAMS.NONSESSION_TIMEFRAME_UPDATED_AT]:
            new Date().toISOString(),
        },
      });

      setNonSessionTimeframe({ ...newTimeframe, end: timeframeEnd.toDate() });
    },
    [updateRoute]
  );

  const _setCompetitiveSetId = useCallback(
    (compSetId) => {
      updateRoute({
        params: {
          [QUERY_PARAMS.COMPETITIVE_SET_KEY]: compSetId,
        },
      });

      setCompetitiveSetId(compSetId);
      setTimeframeAdjustmentsCalculated(false);
    },
    [updateRoute]
  );

  useEffect(() => {
    if (process.env.REACT_APP_ENV === Environment.LocalMock) {
      ldClient.identify({
        name: userMock.name,
        key: userMock.email,
        email: userMock.email,
        avatar: userMock.picture,
        firstName: userMock.given_name,
        lastName: userMock.family_name,
        custom: {
          competitiveSetKey: competitiveSetId,
        },
      });

      return;
    }

    if (!user) {
      return;
    }

    ldClient.identify({
      name: user.name,
      key: user.email,
      email: user.email,
      avatar: user.picture,
      firstName: user.given_name,
      lastName: user.family_name,
      custom: {
        competitiveSetKey: competitiveSetId,
      },
    });
  }, [user, ldClient, competitiveSetId]);

  /**
   * Reset timeframe start to default (90 days) after REACT_APP_TIMEFRAME_TTL_MS amount of time.
   */
  useEffect(() => {
    const timeframeUpdatedAt = queryParams.get(
      QUERY_PARAMS.TIMEFRAME_UPDATED_AT
    );

    if (!timeframeUpdatedAt) {
      return;
    }

    if (
      dayjs().subtract(process.env.REACT_APP_TIMEFRAME_TTL_MS, 'milliseconds') >
      dayjs(timeframeUpdatedAt)
    ) {
      _setTimeframe({
        quantity: DATE_PICKER_OPTIONS[1].quantity,
        unit: DATE_PICKER_OPTIONS[1].unit,
        end: dayjs().endOf('day').toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nsTimeframeUpdatedAt = queryParams.get(
      QUERY_PARAMS.NONSESSION_TIMEFRAME_UPDATED_AT
    );

    if (!nsTimeframeUpdatedAt) {
      return;
    }

    if (
      dayjs().subtract(process.env.REACT_APP_TIMEFRAME_TTL_MS, 'milliseconds') >
      dayjs(nsTimeframeUpdatedAt)
    ) {
      _setNonSessionTimeframe({
        quantity: DATE_PICKER_OPTIONS[1].quantity,
        unit: DATE_PICKER_OPTIONS[1].unit,
        end: dayjs().endOf('day').toISOString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const paramCompSetId = queryParams.get(QUERY_PARAMS.COMPETITIVE_SET_KEY);

    if (accountList?.length && !paramCompSetId) {
      _setCompetitiveSetId(accountList[0].competitiveSets[0].competitiveSetKey);
    }
  }, [accountList, queryParams, _setCompetitiveSetId]);

  useEffect(() => {
    if (accountList?.length) {
      setAccountKey(accountList[0].accountKey);
      setAccountType(accountList[0].product_id);
    }
  }, [accountList]);

  useEffect(() => {
    if (!accountList?.length) {
      return;
    }

    accountList.forEach((account) => {
      account.competitiveSets.forEach((competitiveSet) => {
        if (competitiveSet.competitiveSetKey === competitiveSetId) {
          setHeroBrandKey(competitiveSet.brand.brandKey);
        }
      });
    });
  }, [competitiveSetId, accountList]);

  const [
    getKeys,
    {
      called: getKeysCalled,
      data: getKeysResp,
      error: getKeysError,
      loading: loadingKeys,
    },
  ] = useLazyQuery(USER_AND_ACCOUNT_KEYS, {
    fetchPolicy: 'no-cache',
  });

  const [
    getAllBrandsByUserId,
    {
      called: getAllBrandsCalled,
      loading: loadingGetAllBrands,
      data: allBrandsByUserID,
      error: getBrandsError,
    },
  ] = useLazyQuery(GET_ALL_BRANDS_BY_USERID, {
    fetchPolicy: 'no-cache',
  });

  const [
    getFirstLastSession,
    { data: firstAndLastSessionResp, error: getFirstAndLastSessionError },
  ] = useLazyQuery(GET_FIRST_AND_LAST_SESSION, {
    fetchPolicy: 'no-cache',
  });

  const [
    getMetricsFavorites,
    {
      called: getMetricsFavoritesCalled,
      loading: getMetricsFavoritesLoading,
      data: metricsFavoriteResp,
      error: getMetricsFavoritesError,
    },
  ] = useLazyQuery(GET_METRICS_FAVORITE, {
    fetchPolicy: 'no-cache',
  });

  useDeepCompareEffectNoCheck(() => {
    if (getKeysError) {
      // eslint-disable-next-line no-console
      console.error(`Site entry: [getKeys] ${getKeysError}`);
    }

    if (getBrandsError) {
      // eslint-disable-next-line no-console
      console.error(`Site entry: [getAllBrands] ${getBrandsError}`);
    }
    if (getFirstAndLastSessionError) {
      // eslint-disable-next-line no-console
      console.error(
        `Site entry: [getFirstAndLastSession] ${getFirstAndLastSessionError}`
      );
    }
    if (getMetricsFavoritesError) {
      // eslint-disable-next-line no-console
      console.error(
        `Site entry: [getMetricsFavorites] ${getMetricsFavoritesError}`
      );
    }
  }, [
    getKeysError,
    getBrandsError,
    getFirstAndLastSessionError,
    getMetricsFavoritesError,
  ]);

  /**
   * Initial getKeys request.
   */
  useEffect(() => {
    if (getKeysCalled || loadingKeys || getKeysResp) {
      return;
    }

    getKeys();
  }, [getKeysCalled, loadingKeys, getKeysResp, getKeys]);

  /**
   * Determine user flow based on accountKeys from getKeys request.
   *
   * Presence of accountKey indicates that the user has onboarded.
   *
   * Presence of productId and priceId either from query params or local storage
   * indicates that the user has came from REACT_APP_EXTERNAL_ONBOARDING_URL
   */
  useEffect(() => {
    if (!getKeysResp) {
      return;
    }

    const accountKeyResp = getKeysResp.user?.accounts[0]?.accountKey;

    if (accountKeyResp) {
      if (includes(location.pathname, 'onboarding')) {
        navigate(urlcat('', '/market-index/overview'), { replace: true });
      }

      if (!loadingGetAllBrands && !allBrandsByUserID) {
        getAllBrandsByUserId();
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        (productId && priceId) ||
        (localStorageProductId && localStoragePriceId)
      ) {
        navigate(
          urlcat('', '/onboarding/brand-profile/add-brand', {
            priceId: localStoragePriceId || priceId,
            productId: localStorageProductId || productId,
          }),
          { replace: true }
        );

        localStorage.removeItem('priceId');
        localStorage.removeItem('productId');
      } else {
        // if no account key is present, redirect user to this page
        // to logout and log back in
        navigate(urlcat('', '/logout-login'), { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [
    getKeysResp,
    getAllBrandsCalled,
    loadingGetAllBrands,
    allBrandsByUserID,
    productId,
    priceId,
    localStoragePriceId,
    localStorageProductId,
    navigate,
    getAllBrandsByUserId,
  ]);

  useEffect(() => {
    if (!competitiveSetId) {
      return;
    }

    getFirstLastSession({
      variables: {
        id: competitiveSetId,
      },
    });
  }, [competitiveSetId, getFirstLastSession]);

  useEffect(() => {
    if (!competitiveSetId || !currentSession) {
      return;
    }

    if (getMetricsFavoritesCalled || getMetricsFavoritesLoading) {
      return;
    }

    getMetricsFavorites({
      variables: {
        id: competitiveSetId,
        sessionKey: currentSession,
      },
    });
  }, [
    competitiveSetId,
    currentSession,
    getMetricsFavoritesCalled,
    getMetricsFavoritesLoading,
    getMetricsFavorites,
  ]);

  /**
   * Set brand list after fetching (primarily used for top nav).
   */
  useEffect(() => {
    if (!allBrandsByUserID) {
      return;
    }

    setAccountList(allBrandsByUserID?.user?.accounts);
  }, [allBrandsByUserID]);

  /**
   * Set session key after fetching.
   */
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.length ||
      !firstAndLastSessionResp.competitiveSet.firstAndLastSession[1]?.sessionKey
    ) {
      return;
    }

    setCurrentSession(
      firstAndLastSessionResp.competitiveSet.firstAndLastSession[1].sessionKey
    );
  }, [firstAndLastSessionResp]);

  /**
   * Set sessions list after fetching.
   */
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.sessions?.length ||
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession
    ) {
      return;
    }

    const sessions = firstAndLastSessionResp.competitiveSet.sessions.map(
      ({ sessionKey, sessionDate, isClientVisible }) => {
        return {
          key: sessionKey,
          date: dayjs(sessionDate).startOf('day').toDate(),
          isClientVisible: Boolean(isClientVisible),
        };
      }
    );

    setAllSessions(sessions);

    const firstAndLastSessionKeys =
      firstAndLastSessionResp.competitiveSet.firstAndLastSession;

    const updatedOldestSession = find(sessions, {
      key: firstAndLastSessionKeys?.[0]?.sessionKey,
    });
    const updatedNewestSession = find(sessions, {
      key: firstAndLastSessionKeys?.[1]?.sessionKey,
    });

    setOldestSession({ ...updatedOldestSession });
    setNewestSession({
      ...updatedNewestSession,
      date: dayjs(updatedNewestSession.date).endOf('day').toDate(),
    });
    setInitialCompetitiveSetLoaded(true);
  }, [firstAndLastSessionResp]);

  useEffect(() => {
    if (
      !initialCompetitiveSetLoaded ||
      !oldestSession ||
      !newestSession ||
      timeframeAdjustmentsCalculated
    ) {
      return;
    }

    const t30DaysTimeframe = {
      quantity: 30,
      unit: 'days',
    };

    const sessionRangeInDays = dayjs(newestSession.date).diff(
      oldestSession.date,
      'days'
    );

    if (sessionRangeInDays < t30DaysTimeframe.quantity) {
      _setTimeframe({
        ...t30DaysTimeframe,
        end: newestSession.date,
      });

      setTimeframeAdjustmentsCalculated(true);
    }
  }, [
    timeframeAdjustmentsCalculated,
    initialCompetitiveSetLoaded,
    oldestSession,
    newestSession,
    _setTimeframe,
  ]);

  useEffect(() => {
    if (!metricsFavoriteResp) {
      return;
    }

    const preparedMetricFavoriteMap =
      prepareMetricFavoriteMap(metricsFavoriteResp);

    setMetricsFavoriteMap(preparedMetricFavoriteMap);
  }, [metricsFavoriteResp]);

  /**
   * Set userRole after fetching.
   */
  useEffect(() => {
    if (!firstAndLastSessionResp?.competitiveSet?.userRole) {
      return;
    }

    setUserRole(firstAndLastSessionResp.competitiveSet?.userRole);
  }, [firstAndLastSessionResp]);

  useEffect(() => {
    if (isNil(accountType)) {
      return;
    }

    if (isNil(userRole) || !userRole.id) {
      return;
    }

    SET_FEATURE_FLAGS(getFeatureFlags(accountType, userRole.id));
  }, [accountType, userRole]);

  useEffect(() => {
    if (!getKeysResp || process.env.REACT_APP_ENV === 'local-mock') {
      return;
    }

    const visitor = {
      id:
        getKeysResp.user && getKeysResp.user.userKey
          ? getKeysResp.user.userKey
          : 'No Data',
      email:
        getKeysResp.user && getKeysResp.user.email
          ? getKeysResp.user.email
          : 'No Data',
      full_name:
        getKeysResp.user && getKeysResp.user.name
          ? getKeysResp.user.name
          : 'No Data',
    };

    const account = {
      id:
        getKeysResp.user &&
        getKeysResp.user.accounts[0] &&
        getKeysResp.user.accounts[0].accountKey
          ? getKeysResp.user.accounts[0].accountKey
          : 'No Data',
    };

    const { pendo } = window;

    pendo.initialize({
      visitor,
      account,
    });
  }, [getKeysResp]);

  const [getShareableUsers, { data: userData, loading: settingsLoading }] =
    useLazyQuery(GET_SHAREABLE_USERS, {
      variables: {},
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (!userData) {
      getShareableUsers();
    }

    if (userData) {
      const cohortUsers = userData ? userData.user.getShareableUsers : [];

      setUserSettingsData(cohortUsers);
    }
  }, [getShareableUsers, userData, settingsLoading]);

  if (!getKeysCalled && !accountKey) {
    return 'Loading... 40%';
  }

  if (
    getKeysCalled &&
    accountKey &&
    (!competitiveSetId || !currentSession || !timeframe || !userRole)
  ) {
    return 'Loading... 60%';
  }

  if (
    getKeysCalled &&
    !accountKey &&
    !includes(location.pathname, 'onboarding')
  ) {
    return 'Loading... 80%';
  }

  return (
    <BNContextProvider
      value={{
        accountList,
        accountType,
        accountKey,
        userRole,
        nonSessionTimeframe,
        setNonSessionTimeframe: _setNonSessionTimeframe,
        timeframe,
        setTimeframe: _setTimeframe,
        competitiveSetID: competitiveSetId,
        setCompetitiveSetID: _setCompetitiveSetId,
        heroBrandKey,
        setAccountKey,
        setAccountType,
        currentSession,
        user,
        getKeys,
        FEATURE_FLAGS,
        metricsFavoriteMap,
        getMetricsFavorites,
        getMetricsFavoritesLoading,
        areSessionsLoaded,
        setAreSessionsLoaded,
        newestSession,
        oldestSession,
        allSessions,
        userSettingsData,
      }}
    >
      {children}
    </BNContextProvider>
  );
}

DataManager.propTypes = {
  children: PropTypes.node,
};
