import * as React from 'react';

function SvgIconGauge(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 0L20 0 20 20 0 20z"
          transform="translate(-455.000000, -397.000000) translate(455.000000, 397.017964)"
        />
        <g fillRule="nonzero">
          <path
            fill="#C3C3C3"
            d="M8.729 2.46L10.195 2.47 9.18 17.099 7.714 17.089z"
            transform="translate(-455.000000, -397.000000) translate(455.000000, 397.017964) translate(2.500000, 2.500000) translate(8.954603, 9.779693) rotate(-45.000000) translate(-8.954603, -9.779693)"
          />
          <path
            fill="#49545C"
            d="M.903 0h3.973c.499 0 .903.404.903.903v4.156c0 .498-.404.902-.903.902H.903C.404 5.961 0 5.557 0 5.06V.903C0 .404.404 0 .903 0z"
            transform="translate(-455.000000, -397.000000) translate(455.000000, 397.017964) translate(2.500000, 2.500000)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconGauge;
