import { Grid } from '@mui/material';
import { AnchorHTMLAttributes, useMemo } from 'react';
import { groupCreativesByMonth } from '../../../../api/transforms/Pages/Creative/CreativeAdvertising';
import { CreativeAsset } from '../../../../interfaces/creative';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import CreativeAssetCard from '../CreativeAssetCard';
import { SortType } from '../../../../constants/creative-asset-grid';
import styles from './CreativeGrid.module.scss';
import NoResultsAlert from '../../../Atoms/NoResultsAlert/NoResultsAlert';

export interface CreativeGridProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  creatives: CreativeAsset[];
  loading: boolean;
  onCreativeClick: (creative: CreativeAsset) => void;
  selectedSortBy: SortType;
  cardColumns?: number;
}

const CreativeGrid = ({
  creatives,
  loading,
  onCreativeClick,
  selectedSortBy,
  cardColumns = 3,
}: CreativeGridProps) => {
  const groupedCreatives = useMemo(
    () => groupCreativesByMonth(creatives),
    [creatives]
  );

  if (!loading && !creatives.length) {
    return (
      <NoResultsAlert text="No creatives available, adjust date range / filters" />
    );
  }

  return (
    <Grid container className={styles.CreativeGrid}>
      {loading ? (
        <ComponentLoader />
      ) : (
        <>
          {selectedSortBy === SortType.Date
            ? groupedCreatives.map((monthCreatives) => {
                const month = Object.keys(monthCreatives)[0];
                return (
                  <Grid key={month} item xs={12} className={styles.Container}>
                    <p className={styles.Label}>{month}</p>
                    <Grid container>
                      {monthCreatives[month].map((creative) => (
                        <Grid
                          key={creative.id}
                          item
                          xs={cardColumns}
                          rowSpacing={1}
                          columnSpacing={1}
                          className={styles.Card}
                        >
                          <CreativeAssetCard
                            creative={creative}
                            handleOnClick={() => onCreativeClick(creative)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                );
              })
            : null}

          {selectedSortBy === SortType.Spend
            ? creatives.map((creative) => {
                return (
                  <Grid
                    key={creative.id}
                    item
                    xs={cardColumns}
                    rowSpacing={1}
                    columnSpacing={1}
                    className={styles.Card}
                  >
                    <CreativeAssetCard
                      creative={creative}
                      handleOnClick={() => onCreativeClick(creative)}
                    />
                  </Grid>
                );
              })
            : null}
        </>
      )}
    </Grid>
  );
};

export default CreativeGrid;
