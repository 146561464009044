import maxBy from 'lodash/maxBy';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart,
  registerables,
  ChartType,
  DefaultDataPoint,
  Color,
} from 'chart.js';

import { ChartTooltipContextLine } from '../../../interfaces';

import esovTooltip from '../../../chartjs/tooltips/esovTooltip/esovTooltip';
import { FormattedESOVdata } from '../../Pages/CustomizableDashboards/types';
import colors from '../../../constants/colors';
import styles from './ExcessShareOfVoiceLineChart.module.scss';

export interface ExcessShareOfVoiceLineChartProps {
  data: FormattedESOVdata[];
}

Chart.register(...registerables);

const renderEquillibriumLine = (
  chart: Chart<ChartType, DefaultDataPoint<ChartType>, unknown>
) => {
  const {
    ctx,
    scales: { x: xAxis, y: yAxis },
  } = chart;

  const highestYValues = chart.data.datasets[0].data.map(
    (point) => (point as { x: number; y: number }).y
  );

  const ROUND_VALUE = 10;

  // the y axis chart rounds the nearest highest value to the nearest 10,
  // thus the equilibrium line needs rounding so it is on the same scale
  const highestPoint =
    Math.ceil(Math.max(...highestYValues) / ROUND_VALUE) * ROUND_VALUE;

  ctx.save();
  ctx.beginPath();
  ctx.moveTo(xAxis.left, yAxis.getPixelForValue(0));
  ctx.lineTo(xAxis.right, yAxis.getPixelForValue(highestPoint));
  ctx.strokeStyle = colors.colorPrimaryBase60;
  ctx.lineWidth = 1;
  ctx.stroke();
  ctx.restore();
};

const ExcessShareOfVoiceLineChart: FC<ExcessShareOfVoiceLineChartProps> = ({
  data,
}) => {
  const transformData = (chartData: FormattedESOVdata[]) => {
    return chartData.map((item: FormattedESOVdata) => ({
      x: Math.round(item.shareOfMarket ?? 0),
      y: Math.round(item.shareOfVoice ?? 0),
      esov: item.excessShareOfVoice,
      icon: item.logoUrl ?? '',
      text: item.brand ?? '',
    }));
  };

  const transformedData = transformData(data);

  const highestY = maxBy(transformedData, 'y')?.y;
  const highestX = maxBy(transformedData, 'x')?.x || 0;
  const xAxisBuffer = 10;

  const chartData = {
    datasets: [
      {
        label: 'Icons',
        data: transformedData,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pointStyle: (context: any) => {
          // renders icons on the chart
          const imageUrl = context?.dataset?.data[context.dataIndex]?.icon;
          const imagePixelSize = 22;

          if (imageUrl) {
            const image = new Image();
            image.src = imageUrl;
            image.width = imagePixelSize;
            image.height = imagePixelSize;

            return image;
          }
          return undefined;
        },
        pointRadius: 10,
        showLine: false,
        datalabels: {
          display: true,
          align: 'right' as const,
          anchor: 'center' as const,
          offset: 10,
          font: {
            size: 12,
          },
          backgroundColor: 'white',
          borderRadius: 6,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formatter: (_: unknown, context: any) =>
            context.dataset.data[context.dataIndex].text,
          color: colors.colorGray40 as Color,
        },
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
        external: (context: ChartTooltipContextLine) => {
          return esovTooltip(context);
        },
      },
    },
    scales: {
      x: {
        type: 'linear' as const,
        display: true,
        suggestedMax: highestX + xAxisBuffer,
        suggestedMin: 0,
        beginAtZero: true,
        ticks: {
          color: colors.colorGrayBase60,
          font: {
            size: 12,
            weight: 400,
          },
          stepSize: 10,
          callback: (value: number) => {
            return `${value}%`;
          },
        },
      },
      y: {
        display: true,
        suggestedMax: highestY,
        suggestedMin: 0,
        beginAtZero: true,
        ticks: {
          font: {
            size: 12,
            weight: 400,
          },
          color: colors.colorGrayBase60,
          stepSize: 10,
          callback: (value: number) => {
            return `${value}%`;
          },
        },
      },
    },
  };

  return (
    <div className={styles.ExcessShareOfVoiceLineChart}>
      <Line
        data={chartData}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options={options}
        plugins={[
          {
            id: 'renderEquillibriumLine',
            afterDraw: renderEquillibriumLine,
          },
        ]}
      />
    </div>
  );
};

export default ExcessShareOfVoiceLineChart;
