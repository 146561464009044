import { gql } from '@apollo/client';

export const EMAIL_STRATEGIST = gql`
  mutation emailStrategist(
    $competitiveSetKey: ID
    $accountKey: ID
    $email: String
    $message: String
  ) {
    emailStrategist(
      email: {
        competitiveSetKey: $competitiveSetKey
        accountKey: $accountKey
        email: $email
        message: $message
      }
    )
  }
`;
