import { BrandLogo, Button } from '@blueoceanai/react-component-library';
import { BUTTON_VARIANTS, SIZES } from '../../../../constants/props';
import { KeywordHeaderProps } from '../types';
import { CounterClockwiseClockIcon } from '../../../../assets/icons';

const KeywordHeader = ({
  brandName,
  logoUrl,
  handleIconClick,
  headerClass,
  buttonClass = '',
}: KeywordHeaderProps): JSX.Element => {
  return (
    <div data-testid="keyword-header-container" className={headerClass}>
      <BrandLogo size={SIZES.LG} src={logoUrl} />
      <p data-testid="keyword-brand-name">{brandName}</p>
      {!!handleIconClick && (
        <Button
          data-testid="keyword-header-icon-button"
          className={buttonClass}
          onClick={handleIconClick}
          variant={BUTTON_VARIANTS.TERTIARY}
        >
          <CounterClockwiseClockIcon />
        </Button>
      )}
    </div>
  );
};

export default KeywordHeader;
