import { useMemo, useContext, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { handleGenericError } from '../utils/error';
import { prepareCompetitorDropdownOptions } from '../api/transforms/Pages/MarketIndexCompare';
import { GET_COMPETITORS } from '../api/queries/Pages/MarketIndexCompare';
import BNContext from '../contexts/BNContext';

export type Brand = {
  name: string;
  brandKey: string;
  logoUrl?: string;
};

export type CompetitiveSet = {
  setId: string;
  brands: Brand[];
};

// Returns competitive set as a list of brands
// Note: the hero brand will always be first
export default function useCompetitiveSet() {
  const { competitiveSetID, currentSession } = useContext(BNContext);

  const queryVariableBase = useMemo(() => {
    return {
      id: competitiveSetID,
      sessionKey: currentSession,
    };
  }, [competitiveSetID, currentSession]);

  const [
    getCompetitors,
    { loading: loadingCompetitors, data: competitorsResp },
  ] = useLazyQuery(GET_COMPETITORS, {
    variables: {
      ...queryVariableBase,
    },
  });

  useEffect(() => {
    if (!competitorsResp || loadingCompetitors) {
      getCompetitors();
    }
  }, [getCompetitors, competitorsResp, loadingCompetitors]);

  useEffect(() => {
    if (
      queryVariableBase?.id?.length &&
      competitorsResp?.competitiveSet?.brand
    ) {
      if (
        queryVariableBase.id !==
          competitorsResp.competitiveSet.brand.brandKey &&
        !loadingCompetitors
      ) {
        getCompetitors({
          variables: {
            ...queryVariableBase,
          },
        });
      }
    }
  }, [queryVariableBase, competitorsResp, loadingCompetitors, getCompetitors]);

  const competitorBrands = useMemo(
    () =>
      handleGenericError(
        () => prepareCompetitorDropdownOptions(competitorsResp),
        'MarketIndexCompare prepareCompetitorDropdownOptions failed transform'
      ),
    [competitorsResp]
  );

  const heroBrand = useMemo(() => {
    return competitorsResp?.competitiveSet?.brand;
  }, [competitorsResp]);

  return useMemo(() => {
    const competitiveSet: CompetitiveSet = {
      setId: competitiveSetID ?? '',
      brands: [],
    };
    if (!heroBrand || !competitorBrands?.length) {
      return competitiveSet;
    }
    // potentially add competitorBrands.sort(nameSort); here
    competitiveSet.brands = [heroBrand].concat(competitorBrands);
    return competitiveSet;
  }, [heroBrand, competitorBrands, competitiveSetID]);
}
