import Button from '@material-ui/core/Button';
import DynamicCaretIcon, { CaretDirection } from '../../../assets/icons/DynamicCaret';
import styles from './AccordionControl.module.scss';

export interface AccordionControlProps {
  isOpen: boolean;
  handleClick: () => void;
}

const AccordionControl = ({ isOpen, handleClick }:  AccordionControlProps) => {

  return (
    <div className={styles.AccordionControl}>
      <Button
        onClick={handleClick}
        className={styles.AccordionButton}
        disableRipple
      >
        <DynamicCaretIcon
          className={styles.CaretIcon}
          direction={isOpen ? CaretDirection.up : CaretDirection.down}
        />
      </Button>

  </div>
  );
}

export default AccordionControl;