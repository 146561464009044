/* eslint-disable */
import { Button, ClickAwayListener } from '@mui/material';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { isEqual } from 'lodash';
import find from 'lodash/find';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DATE_PICKER_OPTIONS } from '../../../constants/date-selector';
import { CALENDAR_PICKER_HIGHLIGHT_VARIANTS } from '../../../constants/props';
import BNContext, { GlobalTimeframe } from '../../../contexts/BNContext';


dayjs.extend(quarterOfYear);

import useTimelimits from '../../../hooks/useTimelimits';
import { isActive } from '../../../utils/timeframe';

import styles from './TimeRangeSelector.module.scss';
import DatePickerDualCalendarWithPresets from '../DatePickerDualCalendarWithPresets/DatePickerDualCalendarWithPresets';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../assets/icons/DynamicCaret';


export enum TimeRangeText {
  Default = 'Choose Timeframe',
  Custom = 'Custom date range',
}

const TimeRangeSelector = () => {
  const { allSessions, FEATURE_FLAGS } = useContext(BNContext);
  const {
    isNonSessionPath,
    selectedTimeframe,
    minDate,
    handleTimeframeApply,
    handleNonSessionTimeframeApply,
  } = useTimelimits();

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>();
  const [displayText, setDisplayText] = useState<string>(TimeRangeText.Default);
  const [highlightedDates, setHighlightedDates] = useState<
    { date: Date; variant: string }[]
  >([]);
  const [maxDate] = useState<Date>(dayjs().endOf('day').toDate());
  const [pendingDate, setPendingDate] = useState<GlobalTimeframe | undefined>(
    selectedTimeframe
  );

  // Set Display Text
  useEffect(() => {
    let text: string = TimeRangeText.Default;
    const selectedOption = find(
      DATE_PICKER_OPTIONS,
      (option: GlobalTimeframe) => {
        return isActive(option, selectedTimeframe);
      }
    ) as GlobalTimeframe | undefined;

    if (selectedOption && selectedOption.label) {
      text = selectedOption.label;
    }

    if (!selectedOption && selectedTimeframe?.quantity) {
      const unit = selectedTimeframe.unit ?? 'day';
      const start = dayjs(selectedTimeframe.end)
        .subtract(selectedTimeframe.quantity, unit as dayjs.ManipulateType)
        .format('M/D/YY');
      const end = dayjs(selectedTimeframe.end).format('M/D/YY');
      text = `${start} - ${end}`;
    }

    setDisplayText(text);
  }, [selectedTimeframe]);

  // Set Highlighted Dates for applicable users
  useEffect(() => {
    if (
      !FEATURE_FLAGS.GLOBAL.DATE_RANGE_PICKER.CALENDAR
        .HIGHLIGHT_SESSION_DATES ||
      !allSessions?.length
    ) {
      setHighlightedDates([]);
      return;
    }

    setHighlightedDates(
      allSessions.map((session) => {
        return {
          date: session.date,
          variant: session.isClientVisible
            ? CALENDAR_PICKER_HIGHLIGHT_VARIANTS.SUCCESS
            : CALENDAR_PICKER_HIGHLIGHT_VARIANTS.WARNING,
        };
      })
    );
  }, [allSessions, FEATURE_FLAGS]);

  const applyTimeChange = useCallback(() => {
    if (!pendingDate) return;

    if (isNonSessionPath) {
      handleNonSessionTimeframeApply(pendingDate);
    } else {
      handleTimeframeApply(pendingDate);
    }
  }, [
    handleNonSessionTimeframeApply,
    handleTimeframeApply,
    isNonSessionPath,
    pendingDate,
  ]);

  const handleApply = useCallback(
    (value: GlobalTimeframe) => {
      setPendingDate(value);
      applyTimeChange();
      setMenuIsOpen(false);
    },
    [applyTimeChange]
  );

  const applyPendingDate = useCallback(
    (value: GlobalTimeframe | undefined) => setPendingDate(value),
    []
  );

  const handleClickAway = useCallback(() => {
    setMenuIsOpen(false);
    if (!isEqual(selectedTimeframe, pendingDate)) {
      setPendingDate(selectedTimeframe);
    }
  }, [pendingDate, selectedTimeframe]);

  const toggleMenu = useCallback(
    () => setMenuIsOpen(!menuIsOpen),
    [menuIsOpen]
  );

  return (
    <div className={styles.TimeRangeSelector}>
      <Button
        data-jest="time-range-selector-dropdown-btn"
        onClick={toggleMenu}
        className={styles.DropdownBtn}
        disableRipple
        disableElevation
      >
        <span>{displayText}</span>

        <DynamicCaretIcon
          direction={CaretDirection.down}
          className={styles.Icon}
        />
      </Button>

      {menuIsOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div
            data-testid="time-range-menu-dropdown"
            className={styles.DatePickerMenu}
          >
            <DatePickerDualCalendarWithPresets
              value={pendingDate}
              onChange={applyPendingDate}
              onApply={handleApply}
              minDate={minDate}
              maxDate={maxDate}
              highlightedDates={highlightedDates}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default TimeRangeSelector;
