import { FunctionComponent, AnchorHTMLAttributes, useCallback } from 'react';
import classNames from 'classnames';
import { Button } from '@blueoceanai/react-component-library';

import styles from './AddWidgetCard.module.scss';
import { widgetExampleImages } from '../types';

export interface AddWidgetCardProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  widgetType: string;
  onAddWidget: (widgetType: string) => void;
}

const AddWidgetCard: FunctionComponent<AddWidgetCardProps> = ({
  className,
  title,
  description,
  widgetType,
  onAddWidget,
}) => {
  const handleAddWidget = useCallback(() => {
    onAddWidget(widgetType);
  }, [onAddWidget, widgetType]);

  return (
    <div className={classNames(styles.AddWidgetCard, className)}>
      <div className={styles.ContentContainer}>
        <div className={styles.Preview}>
          <img src={widgetExampleImages[widgetType]} alt={`${title} Example`} />
        </div>

        <div className={styles.TitleContainer}>
          <h2>{title}</h2>

          {/* TODO add info icon */}
        </div>

        <p className={styles.Description}>{description}</p>
      </div>

      <Button
        variant="primary"
        className={styles.AddBtn}
        onClick={handleAddWidget}
      >
        Add
      </Button>
    </div>
  );
};

export default AddWidgetCard;
