import { gql } from '@apollo/client';

export const GET_SCORECARD_VALUES = gql`
  query getScorecardValues(
    $id: ID
    $sessionKey: ID
    $accountKey: ID
    $type: Int
  ) {
    competitiveSet(id: $id) {
      brand {
        brandKey
        logoUrl
        name
      }
      session(sessionKey: $sessionKey) {
        id
        competitors {
          name
          id
          brandKey
          logoUrl
        }
        blueScore {
          insight {
            name
            value
          }
        }
        recommendations(accountKey: $accountKey, type: $type) {
          id
          name
          value
          type
          impact_id
          sourceType_id
          isComplete
          practice_areas
          createdDate
        }
        BlueScore: brandLastPeriodScores(associatedTypeId: 5) {
          t4Quarters
        }
        Factors: brandLastPeriodScores(associatedTypeId: 1) {
          t4Quarters
        }
        CompetitorBlueScore: competitorsLastPeriodScores(associatedTypeId: 5) {
          brand_id
          brandKey
          t4Quarters
        }
        CompetitorFactors: competitorsLastPeriodScores(associatedTypeId: 1) {
          brand_id
          brandKey
          t4Quarters
        }
      }
    }
  }
`;
