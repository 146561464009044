import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Avatar.module.scss';

export default function Avatar({ className, initials, ...props }) {
  return (
    <div className={classNames(styles.Avatar, className)} {...props}>
      <div className={styles.Label}>
        <span>AVATAR</span>
      </div>

      <div className={styles.AvatarThumbnail}>
        {initials ? <span>{initials}</span> : null}
      </div>
    </div>
  );
}

Avatar.propTypes = {
  className: PropTypes.string,
  initials: PropTypes.string,
};
