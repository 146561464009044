import { gql } from '@apollo/client';

export const GET_ACCOUNT_MARKERS = gql`
  query getAccountMarkers($accountKey: ID) {
    user {
      name
      account(accountKey: $accountKey) {
        markers {
          id
          label
          markerEventDate
          account_id
          userEmail
          brandName
          createdDate
          isPrivate
          isAccountLevel
        }
      }
    }
  }
`;

export const ADD_ACCOUNT_MARKER = gql`
  mutation addAccountMarker(
    $id: Int
    $competitiveSetKey: ID
    $label: String
    $markerEventDate: DateType
    $accountKey: ID
    $isPrivate: Int
    $isAccountLevel: Int
  ) {
    addMarker(
      marker: {
        id: $id
        competitiveSetKey: $competitiveSetKey
        accountKey: $accountKey
        label: $label
        markerEventDate: $markerEventDate
        isPrivate: $isPrivate
        isAccountLevel: $isAccountLevel
      }
    )
  }
`;
