import { useCallback } from 'react';
import urlcat from 'urlcat';
import { useNavigate, useLocation } from 'react-router-dom';

import useQuery from './useQuery';

import QUERY_PARAMS from '../constants/queryParams';

export default function useRouter() {
  const queryParams = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const updateRoute = useCallback(
    ({
      params = {},
      pathname = location.pathname,
      state: newState = {},
      replace = false,
    }) => {
      const resultParams = {};

      Object.values(QUERY_PARAMS).forEach((key) => {
        const val = params[key] || queryParams.get(key);

        // if params[key] is null, do not add the key to resultParams
        if (params[key] !== null) {
          resultParams[key] = val;
        }
      });

      const newPath = urlcat('', pathname, resultParams);

      navigate(newPath, {
        replace,
        state: { ...newState },
      });
    },
    [queryParams, navigate, location]
  );

  return { updateRoute };
}
