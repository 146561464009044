import find from 'lodash/find';

export function prepareFactorDropdownOptions(data) {
  const result = [];

  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.factors ||
    !data.competitiveSet.session.factors.length
  ) {
    return result;
  }

  data.competitiveSet.session.factors.forEach((factor) => {
    result.push({
      value: factor.id,
      label: factor.name,
    });
  });

  return result;
}

export function prepareSubfactorDropdownOptions(data, factorId) {
  const result = [];

  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.factors ||
    !data.competitiveSet.session.factors.length
  ) {
    return result;
  }

  const factor = find(data.competitiveSet.session.factors, {
    id: Number(factorId),
  });

  if (!factor || !factor.subFactors || !factor.subFactors.length) {
    return result;
  }

  factor.subFactors.forEach((subfactor) => {
    result.push({
      value: subfactor.associatedTypeId,
      label: subfactor.name,
    });
  });

  return result;
}
