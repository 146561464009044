import * as React from 'react';

function SvgPdf(props) {
  return (
    <svg viewBox="0 0 17 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-4-2h24v24H-4z" />
        <path
          d="M12.535 0H1.342C.597 0 0 .617 0 1.37v18.26C0 20.383.597 21 1.342 21h14.316c.745 0 1.342-.617 1.342-1.37V4.593c0-.36-.139-.706-.388-.963L13.491.405A1.33 1.33 0 0012.535 0z"
          fill="#CED9DB"
        />
        <path
          d="M1.342 1h11.194a.33.33 0 01.237.102l3.121 3.224c.067.07.106.166.106.267V19.63c0 .208-.157.37-.342.37H1.342C1.157 20 1 19.838 1 19.63V1.37c0-.208.157-.37.342-.37z"
          fill="#F4F7FA"
        />
        <path
          d="M3.673 3.93c.712-.493 1.609-.277 1.936.624.404 1.118.752 2.636.906 4.188l-.01-.069c.76.722 1.6 1.347 2.5 1.859l.299.162a14.082 14.082 0 013.677-.586l.484-.003c.93.035 1.534.729 1.535 1.565 0 .85-.631 1.514-1.543 1.4a13.62 13.62 0 01-3.755-1.066l-.445-.209-.046-.023c-.851.283-1.672.652-2.447 1.1l-.41.246-.013.075c-.26 1.292-.714 2.467-1.385 3.497l-.207.304c-.523.733-1.43.872-2.11.429-.71-.464-.87-1.366-.26-2.133a14.462 14.462 0 013.15-2.83l-.083.06c.146-.928.186-1.908.132-2.912l-.033-.476-.1-.1a14.08 14.08 0 01-1.976-2.556l-.232-.4c-.47-.79-.235-1.683.436-2.147zm1.43 10.078l-.082.066a13.452 13.452 0 00-1.866 1.846c-.232.293-.192.52.031.666.242.158.547.11.749-.172a8.175 8.175 0 001.013-1.938l.122-.355.033-.113zm8.333-2.904l-.43.004a13.08 13.08 0 00-1.73.16l-.426.075-.085.017.188.072c.567.206 1.148.372 1.739.494l.445.085.448.068c.266.033.415-.124.415-.409 0-.287-.165-.504-.468-.556l-.096-.01zm-6.841-1.022l.005.425-.01.491-.026.49-.033.369.162-.09c.288-.152.58-.296.878-.429l.489-.208-.271-.17a13.84 13.84 0 01-.73-.512l-.353-.273-.111-.093zM4.668 4.895c-.09-.249-.226-.282-.426-.143-.253.175-.337.492-.14.825.284.505.598.99.941 1.45l.27.348-.019-.108a16.933 16.933 0 00-.426-1.768l-.098-.311-.102-.293z"
          fill="#D83133"
        />
      </g>
    </svg>
  );
}

export default SvgPdf;
