import { AnchorHTMLAttributes } from 'react';
import { BrandLogo } from '@blueoceanai/react-component-library';
import cn from 'classnames';
import { BlueScoreBrandsSummary } from '../../../types';
import { SIZES } from '../../../../../../constants/props';
import ScoreTile from '../../../../../ScoreDisplays/ScoreTile';
import styles from './BlueScoreWidgetControl.module.scss';
import { ScoreTileSize } from '../../../../../ScoreDisplays/ScoreTile/ScoreTile';
import WidgetTooltip from '../../../WidgetTooltip/WidgetTooltip';

export interface BlueScoreWidgetControlProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  brandsSummary: BlueScoreBrandsSummary[];
  handleBrandClick: (brand: BlueScoreBrandsSummary) => void;
}

const BlueScoreWidgetControl = ({
  brandsSummary,
  handleBrandClick,
}: BlueScoreWidgetControlProps) => {
  return (
    <div className={styles.BlueScoreWidgetControl}>
      {brandsSummary.map((brand) => (
        <button
          type="button"
          key={brand.name}
          onClick={() => handleBrandClick(brand)}
          className={cn(styles.Brand, brand.isActive ? styles.ActiveBrand : '')}
        >
          <BrandLogo src={brand.logoUrl} size={SIZES.SM} />
          <WidgetTooltip placement="bottom" description={brand.name}>
            <p className={styles.Label}>{brand.name}</p>
          </WidgetTooltip>
          <ScoreTile
            value={brand.latestScore}
            delta={brand.difference}
            size={ScoreTileSize.Small}
            className={styles.ScoreTile}
            deltaClassName={styles.Delta}
            fullWidth={!brand.isActive}
          />
        </button>
      ))}
    </div>
  );
};

export default BlueScoreWidgetControl;
