/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import { BrandLogo } from '@blueoceanai/react-component-library';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

import BetaTag from '../../Atoms/BetaTag/BetaTag';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import ResourcesCTA from '../../Molecules/ResourcesCTA/ResourcesCTA';
import Dropdown from '../../Molecules/Dropdown/Dropdown';
import RadioSelector from '../../Molecules/RadioSelector/RadioSelector';
import VerbatimWide from '../../Molecules/VerbatimWide/VerbatimWide';

import ContentAnalysisKeywordsBrandContent from '../ContentAnalysisKeywordsBrandContent/ContentAnalysisKeywordsBrandContent';
import ContentAnalysisKeywordsAudienceContent from '../ContentAnalysisKeywordsAudienceContent/ContentAnalysisKeywordsAudienceContent';
import ContentAnalysisKeywordsBrandAudienceComparison from '../ContentAnalysisKeywordsBrandAudienceComparison/ContentAnalysisKeywordsBrandAudienceComparison';

import { SIZES } from '../../../constants/props';
import { GET_KEYWORDS } from '../../../api/queries/Pages/ContentAnalysisKeywords';
import BNContext from '../../../contexts/BNContext';

import styles from './ContentAnalysisKeywords.module.scss';
import AudienceSVG from './AudienceIcon.svg';

export default function ContentAnalysisKeywords() {
  const {
    competitiveSetID: _competitiveSetId,
    currentSession: sessionKey,
    accountKey,
    allSessions,
    timeframe,
  } = React.useContext(BNContext);

  const radioItems = [
    { name: 'Brand Content' },
    { name: 'Audience Content' },
    { name: 'Brand / Audience Comparison' },
  ];

  const brandInsight = [
    'We’ve tracked and clustered the top keywords your competitors use across their various channels so you can see how aligned you are to the category or see what new uncharted messaging territory could help differentiate you from the pack.',
    'There is no way you can read thousands of web pages that your competitors update daily, weekly, and monthly.',
    'But we can, and we are here to help!',
  ];
  const audienceInsight = [
    'Have you ever wondered what your competitor’s customers say about them?',
    'Use this section to see how similar or different your customers’ conversations about your category are or watch shifts in conversation over time.',
  ];
  const comparisonInsight = [
    'Do you and your customers have a conversation and can you see it in the way you speak versus how they speak?',
    'Brands that find harmony with what their customers talk about will create long, meaningful relationships.',
  ];
  const keywordsMax = 12;

  const [currentAppBrand, setCurrentAppBrand] = useState('');
  const [filterItem, setFilterItem] = useState(radioItems[0]);
  const [selectedDropdown, setSelectedDropdown] = useState({});
  const [selectedTerm, setSelectedTerm] = useState('');
  const [brandKeywordTerm, setBrandKeywordTerm] = useState({});
  const [audienceKeywordTerm, setAudienceKeywordTerm] = useState({});
  const [competitorKeywordTerm, setCompetitorKeywordTerm] = useState({});
  const [competitorAudienceKeywordTerm, setCompetitorAudienceKeywordTerm] =
    useState({});
  const [selectedCompetitor, setSelectedCompetitor] = useState(0);
  const [brandDropdown, setbrandDropdown] = useState([]);
  const [brandName, setbrandName] = useState('');
  const [brandLogoURL, setBrandLogoURL] = useState('');
  const [showTrend, setShowTrend] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [getKeywords, { loading, error, data, refetch }] = useLazyQuery(
    GET_KEYWORDS,
    {
      variables: {
        id: _competitiveSetId,
        sessionKey,
        accountKey,
        type: 16,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const refetchKeywords = useCallback(
    (sessionId) => {
      refetch({
        id: _competitiveSetId,
        accountKey,
        sessionKey: sessionId,
        type: 16,
      });
    },
    [_competitiveSetId, accountKey, refetch]
  );

  const handleTimeframeChange = useCallback(() => {
    let sessionId;
    const useLatest = timeframe.label !== 'Custom date range';

    if (useLatest) {
      sessionId = sessionKey;
    } else {
      const end = dayjs(timeframe.end);
      const start = dayjs(timeframe.end).subtract(timeframe.quantity, 'day');
      const [selectedSession] = allSessions
        .filter((session) => {
          return (
            Boolean(dayjs(session.date).isBetween(start, end)) &&
            session.isClientVisible
          );
        })
        .sort((a, b) => new Date(a.date) - new Date(b.date));
      sessionId = selectedSession?.key ?? '';
    }

    if (!sessionId) {
      return;
    }

    setIsLoading(true);
    refetchKeywords(sessionId);
  }, [timeframe, sessionKey, allSessions, refetchKeywords]);

  const assignBrandVerbatims = useCallback(() => {
    const brandContent = data.competitiveSet.session.brandContentKeyWords;
    const [selectedBrandContent] = brandContent.filter(
      (keyword) => keyword.name === selectedTerm
    );
    setBrandKeywordTerm(selectedBrandContent);
  }, [selectedTerm, data, setBrandKeywordTerm]);

  useEffect(() => {
    if (!_competitiveSetId || !sessionKey) {
      return;
    }
    if (currentAppBrand !== '' && currentAppBrand !== _competitiveSetId) {
      // eslint-disable-next-line
      console.log('change brand');
    }
    setCurrentAppBrand(_competitiveSetId);
    resetPage();
    getKeywords();
  }, [getKeywords, _competitiveSetId, sessionKey, currentAppBrand]);

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      setTimeout(() => setIsLoading(false), 250);
    }
  }, [loading, isLoading]);

  useEffect(() => handleTimeframeChange(), [handleTimeframeChange]);

  useEffect(() => {
    if (data?.competitiveSet?.session?.competitors) {
      setbrandDropdown(
        data.competitiveSet.session.competitors.map((c) => ({
          label: c.name,
          value: c.name.trim().replace(' ', '-').toLowerCase(),
          imgUrl: c.logoUrl,
        }))
      );
      setbrandName(data.competitiveSet.brand.name);
      setBrandLogoURL(data.competitiveSet.brand.logoUrl);

      if (!selectedTerm) {
        const [term] = data.competitiveSet.session.brandContentKeyWords;
        setSelectedTerm(term.name);
      }

      setCompetitorKeywordTerm(
        data?.competitiveSet?.session?.competitors?.[
          selectedCompetitor
        ]?.brandContentKeyWords?.filter((kw) => kw?.name === selectedTerm)?.[0]
      );
      setCompetitorAudienceKeywordTerm(
        data?.competitiveSet?.session?.competitors?.[
          selectedCompetitor
        ]?.audienceContentKeyWords?.filter(
          (kw) => kw.name === selectedTerm
        )?.[0]
      );
      assignBrandVerbatims();
    }
  }, [
    data,
    setbrandDropdown,
    selectedCompetitor,
    selectedTerm,
    filterItem,
    assignBrandVerbatims,
  ]);

  useEffect(() => {
    if (
      !selectedDropdown ||
      !Object.prototype.hasOwnProperty.call(selectedDropdown, 'value')
    ) {
      setSelectedDropdown(brandDropdown[0]);
    }
    if (
      selectedDropdown &&
      !brandDropdown.some((brand) => brand.value === selectedDropdown.value)
    ) {
      setSelectedDropdown(brandDropdown[0]);
    }
  }, [selectedDropdown, setSelectedDropdown, brandDropdown]);

  useEffect(() => {
    if (error) {
      console.error(error); // eslint-disable-line no-console
    }
  }, [error]);

  function handleFilterChange(item) {
    setFilterItem(item);
    if (item.name === 'Audience Content') {
      const [term] = data.competitiveSet.session.audienceContentKeyWords;
      setSelectedTerm(term?.name);
    } else {
      const [term] = data.competitiveSet.session.brandContentKeyWords;
      setSelectedTerm(term?.name);
    }
  }

  const handleCompetitorSelect = (comp) => {
    setSelectedDropdown(comp);
    setSelectedCompetitor(
      data?.competitiveSet?.session?.competitors.findIndex(
        (el) => el.name.trim().replace(' ', '-').toLowerCase() === comp.value
      )
    );
  };

  const handleKeywordClick = (k, brand) => {
    const key = k;
    let competitorSelected = selectedCompetitor;

    if (brand !== brandName && brand !== '' && brandDropdown.length > 0) {
      handleCompetitorSelect({
        label: brand,
        value: brand.trim().replace(' ', '-').toLowerCase(),
        imgUrl: brandDropdown.filter((x) => x.label === brand)[0].imgUrl,
      });
      competitorSelected = data?.competitiveSet?.session?.competitors.findIndex(
        (el) => el.name === brand
      );
    }

    setBrandKeywordTerm(
      data?.competitiveSet?.session?.brandContentKeyWords?.filter(
        (kw) => kw?.name === key
      )[0]
    );

    setCompetitorKeywordTerm(
      sortVerbatims(
        data?.competitiveSet?.session?.competitors?.[
          competitorSelected
        ]?.brandContentKeyWords?.filter((kw) => kw?.name === key)?.[0]
      )
    );

    setAudienceKeywordTerm(
      sortVerbatims(
        data?.competitiveSet?.session?.audienceContentKeyWords?.filter(
          (kw) => kw?.name === key
        )?.[0]
      )
    );

    setCompetitorAudienceKeywordTerm(
      sortVerbatims(
        data?.competitiveSet?.session?.competitors?.[
          competitorSelected
        ]?.audienceContentKeyWords?.filter((kw) => kw?.name === key)?.[0]
      )
    );

    setSelectedTerm(key);
  };

  function sortVerbatims(verbatims) {
    const verbatimsClone = cloneDeep(verbatims);

    if (!verbatimsClone?.verbatims) {
      return;
    }

    verbatimsClone.verbatims.sort((firstEl, secondEl) => {
      if (firstEl.source === 'social') {
        return -1;
      }

      if (secondEl.source === 'web') {
        return 1;
      }

      return 0;
    });

    return verbatimsClone;
  }

  const toggleTrendVisibility = (isShowTrend) => {
    setShowTrend(isShowTrend);
  };

  function resetPage() {
    setCompetitorKeywordTerm({});
    setAudienceKeywordTerm({});
    setBrandKeywordTerm({});
    setCompetitorAudienceKeywordTerm({});
    setbrandDropdown([]);
  }

  return (
    <Grid
      container
      className={classNames('PageContainer', styles.ContentAnalysisKeywords)}
      spacing={10}
    >
      <Grid className={styles.BrandContentContainer} item xs={12} md={9}>
        <div id="content-analysis-keywords-screenshot-container">
          <Grid
            className={classNames(styles.TitleContainer, 'PageTitleContainer')}
            container
          >
            <Grid item xs={11} container alignItems="center">
              <h1 className={styles.Title}>What keywords are being used?</h1>
              <BetaTag />
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              item
              xs={1}
            >
              <DownloadDropdown
                targetId="content-analysis-keywords-screenshot-container"
                padding={100}
              />
            </Grid>
            <Box mb={1} className={styles.SectionDescription}>
              <p className={styles.Paragraph}>
                Select a keyword to see how this is being used in your content
                and your competitors content.
              </p>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            className={`${styles.RadioContainer} SectionContainer`}
          >
            <RadioSelector
              items={radioItems}
              defaultSelectedIndex={0}
              onChange={handleFilterChange}
            />
          </Grid>
          {isLoading ? (
            <ComponentLoader />
          ) : (
            <Grid item className={styles.BrandContentContainer} xs={12}>
              {filterItem.name === 'Brand Content' ? (
                <ContentAnalysisKeywordsBrandContent
                  data={data}
                  loading={isLoading}
                  selectedKeyword={selectedTerm}
                  keywordsMax={keywordsMax}
                  keywordClick={handleKeywordClick}
                  trendIsVisible={showTrend}
                  setTrendIsVisible={toggleTrendVisibility}
                />
              ) : null}
              {filterItem.name === 'Audience Content' ? (
                <ContentAnalysisKeywordsAudienceContent
                  data={data}
                  loading={isLoading}
                  keywordsMax={keywordsMax}
                  selectedKeyword={selectedTerm}
                  keywordClick={handleKeywordClick}
                />
              ) : null}
              {filterItem.name === 'Brand / Audience Comparison' ? (
                <ContentAnalysisKeywordsBrandAudienceComparison
                  data={data}
                  loading={isLoading}
                  keywordsMax={keywordsMax}
                  selectedKeyword={selectedTerm}
                  keywordClick={handleKeywordClick}
                />
              ) : null}
            </Grid>
          )}
        </div>
      </Grid>

      <Grid item xs={12} md={3}>
        {filterItem.name === 'Brand Content' ? (
          <ResourcesCTA
            loading={isLoading}
            insight={brandInsight}
            data={data?.competitiveSet?.session?.recommendations.filter(
              (r) => r.associatedType_id === 1
            )}
            modalType="recommendations"
          />
        ) : null}
        {filterItem.name === 'Audience Content' ? (
          <ResourcesCTA
            loading={isLoading}
            insight={audienceInsight}
            data={data?.competitiveSet?.session?.recommendations.filter(
              (r) => r.associatedType_id === 2
            )}
            modalType="recommendations"
          />
        ) : null}
        {filterItem.name === 'Brand / Audience Comparison' ? (
          <ResourcesCTA
            loading={isLoading}
            insight={comparisonInsight}
            data={data?.competitiveSet?.session?.recommendations.filter(
              (r) => r.associatedType_id === 3
            )}
            modalType="recommendations"
          />
        ) : null}
      </Grid>

      {!showTrend ? (
        <Grid item xs={12} className={styles.VerbatimsContainer}>
          <Box
            className={styles.BrandContentCompare}
            id="content-analysis-keywords-verbatims-screenshot-container"
          >
            <Grid container>
              <Grid item xs={12}>
                <div className={styles.HeaderContainer}>
                  <div className={styles.TitleContainer}>
                    <h2 className={styles.Title}>
                      {filterItem.name === 'Brand Content'
                        ? 'Brand Verbatims'
                        : null}
                      {filterItem.name === 'Audience Content'
                        ? 'Audience Verbatims'
                        : null}
                      {filterItem.name === 'Brand / Audience Comparison'
                        ? 'Verbatims'
                        : null}
                    </h2>
                    <BetaTag />
                  </div>

                  <DownloadDropdown
                    targetId="content-analysis-keywords-verbatims-screenshot-container"
                    padding={100}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid spacing={10} container item xs={12}>
              <Grid item md={6}>
                {/* Hero Brand Verbatims */}
                <div className={styles.ColumnHeader}>
                  <BrandLogo
                    size={SIZES.SM}
                    name={brandName || ''}
                    src={brandLogoURL}
                  />
                </div>
                {filterItem.name === 'Audience Content' ? (
                  selectedTerm !== '' ? (
                    audienceKeywordTerm &&
                    audienceKeywordTerm.verbatims?.length ? (
                      audienceKeywordTerm.verbatims.map((verbatim, index) => (
                        <VerbatimWide
                          key={`${verbatim.content}_${index}`}
                          text={verbatim.content}
                          source={verbatim.source}
                          selectedWords={[selectedTerm]}
                          logoUrl={AudienceSVG}
                        />
                      ))
                    ) : (
                      <p>No verbatims contain that keyword</p>
                    )
                  ) : (
                    <p>No keyword selected</p>
                  )
                ) : selectedTerm !== '' ? (
                  brandKeywordTerm && brandKeywordTerm.verbatims ? (
                    brandKeywordTerm.verbatims.map((verbatim, index) => (
                      <VerbatimWide
                        key={`${verbatim.content}_${index}`}
                        text={verbatim.content}
                        source={verbatim.source}
                        selectedWords={[selectedTerm]}
                        logoUrl={brandLogoURL}
                      />
                    ))
                  ) : (
                    <p>No verbatims contain that keyword</p>
                  )
                ) : (
                  <p>No keyword selected</p>
                )}
              </Grid>
              <Grid item md={6}>
                <div
                  className={`${styles.ColumnHeader} ${styles.ColumnHeaderWithDropdown}`}
                >
                  {filterItem.name === 'Brand Content' &&
                  brandDropdown.length > 0 ? (
                    <Dropdown
                      showArrow
                      showListIcons
                      value={
                        selectedDropdown && selectedDropdown.value
                          ? selectedDropdown.value
                          : ''
                      }
                      options={brandDropdown}
                      onChange={handleCompetitorSelect}
                      logoSize="SM"
                    />
                  ) : null}
                  {filterItem.name === 'Audience Content' &&
                  brandDropdown.length > 0 ? (
                    <Dropdown
                      showArrow
                      showListIcons
                      value={
                        selectedDropdown && selectedDropdown.value
                          ? selectedDropdown.value
                          : ''
                      }
                      options={brandDropdown}
                      onChange={handleCompetitorSelect}
                      logoSize="SM"
                    />
                  ) : null}
                  {filterItem.name === 'Brand / Audience Comparison' ? (
                    <BrandLogo
                      size={SIZES.SM}
                      name="Audience"
                      src={AudienceSVG}
                    />
                  ) : null}
                </div>
                {filterItem.name !== 'Brand / Audience Comparison' ? (
                  filterItem.name === 'Brand Content' ? (
                    selectedTerm !== '' ? (
                      competitorKeywordTerm &&
                      competitorKeywordTerm.verbatims?.length ? (
                        competitorKeywordTerm.verbatims.map(
                          (verbatim, index) => (
                            <VerbatimWide
                              key={`${verbatim.content}_${index}`}
                              text={verbatim.content}
                              source={verbatim.source}
                              selectedWords={[selectedTerm]}
                              logoUrl={selectedDropdown?.imgUrl || ''}
                            />
                          )
                        )
                      ) : (
                        <p>No verbatims contain that keyword</p>
                      )
                    ) : (
                      <p>No keyword selected</p>
                    )
                  ) : selectedTerm !== '' ? (
                    competitorAudienceKeywordTerm &&
                    competitorAudienceKeywordTerm.verbatims?.length ? (
                      competitorAudienceKeywordTerm.verbatims.map(
                        (verbatim, index) => (
                          <VerbatimWide
                            key={`${verbatim.content}_${index}`}
                            text={verbatim.content}
                            source={verbatim.source}
                            selectedWords={[selectedTerm]}
                            logoUrl="www.google.com"
                          />
                        )
                      )
                    ) : (
                      <p>No verbatims contain that keyword</p>
                    )
                  ) : (
                    <p>No keyword selected</p>
                  )
                ) : selectedTerm !== '' ? (
                  audienceKeywordTerm &&
                  audienceKeywordTerm.verbatims?.length ? (
                    audienceKeywordTerm.verbatims.map((verbatim, index) => (
                      <VerbatimWide
                        key={`${verbatim.content}_${index}`}
                        text={verbatim.content}
                        source={verbatim.source}
                        selectedWords={[selectedTerm]}
                        logoUrl={AudienceSVG}
                      />
                    ))
                  ) : (
                    <p>No verbatims contain that keyword</p>
                  )
                ) : (
                  <p>No keyword selected</p>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ) : null}

      <Grid item xs={12}>
        <p>
          This section is updated upon collection of a statistically significant
          volume of data; we typically see a cadence of 2 updates per quarter.
        </p>
      </Grid>
    </Grid>
  );
}
