import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import head from 'lodash/head';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';

import { isBetween } from '../../../utils/timeframe';

const scorecardRowTemplate = {
  brand: {
    name: null,
    brandKey: null,
    logoUrl: null,
  },
  overall: {
    scores: null,
    score: null,
    delta: null,
  },
  familiar: {
    scores: null,
    score: null,
    delta: null,
  },
  unique: {
    scores: null,
    score: null,
    delta: null,
  },
  consistent: {
    scores: null,
    score: null,
    delta: null,
  },
  relevant: {
    scores: null,
    score: null,
    delta: null,
  },
  revered: {
    scores: null,
    score: null,
    delta: null,
  },
};

export function prepareScorecardRows(data, timeframe) {
  if (
    !timeframe ||
    !data?.competitiveSet?.brand ||
    !data?.competitiveSet?.session?.BlueScore?.[0]?.t4Quarters ||
    !data?.competitiveSet?.session?.Factors?.[0]?.t4Quarters
  ) {
    return;
  }

  const result = [];

  const heroBrand = getHeroBrandScorecardRow(data, timeframe);

  result.push(heroBrand);

  const competitorBrands = getCompetitorBrandScorecardRows(data, timeframe);

  return result.concat(competitorBrands);
}

function getCompetitorBrandScorecardRows(data, timeframe) {
  const result = [];

  const competitors = cloneDeep(data?.competitiveSet?.session?.competitors);

  const competitorBluescoresByBrandKey = keyBy(
    data?.competitiveSet?.session?.CompetitorBlueScore,
    'brandKey'
  );

  const competitorFactorsByBrandKey = keyBy(
    data?.competitiveSet?.session?.CompetitorFactors,
    'brandKey'
  );

  competitors.forEach((competitor) => {
    if (!competitorBluescoresByBrandKey?.[competitor.brandKey]?.t4Quarters) {
      return;
    }

    const competitorBrand = cloneDeep(scorecardRowTemplate);

    competitorBrand.brand.name = competitor.name;
    competitorBrand.brand.brandKey = competitor.brandKey;
    competitorBrand.brand.logoUrl = competitor.logoUrl;

    competitorBrand.overall = {
      ...getDisplayScoreAndDelta(
        JSON.parse(
          competitorBluescoresByBrandKey[competitor.brandKey].t4Quarters
        ),
        timeframe
      ),
    };

    const factorScores = getFactorsDisplayScoreAndDelta(
      JSON.parse(competitorFactorsByBrandKey[competitor.brandKey].t4Quarters),
      timeframe
    );

    merge(competitorBrand, factorScores);

    Object.keys(competitorBrand).forEach((factorName) => {
      if (competitorBrand[factorName].scores === null) {
        delete competitorBrand[factorName].scores;
      }
    });

    result.push(competitorBrand);
  });

  return result;
}

function getHeroBrandScorecardRow(data, timeframe) {
  const heroBrand = cloneDeep(scorecardRowTemplate);

  heroBrand.brand.name = data.competitiveSet.brand.name;
  heroBrand.brand.brandKey = data.competitiveSet.brand.brandKey;
  heroBrand.brand.logoUrl = data.competitiveSet.brand.logoUrl;

  heroBrand.overall = {
    ...getDisplayScoreAndDelta(
      JSON.parse(data?.competitiveSet?.session?.BlueScore?.[0]?.t4Quarters),
      timeframe
    ),
  };

  const factorScores = getFactorsDisplayScoreAndDelta(
    JSON.parse(data?.competitiveSet?.session?.Factors?.[0]?.t4Quarters),
    timeframe
  );

  merge(heroBrand, factorScores);

  Object.keys(heroBrand).forEach((factorName) => {
    if (heroBrand[factorName].scores === null) {
      delete heroBrand[factorName].scores;
    }
  });

  return heroBrand;
}

function getScores(rawScores, timeframe) {
  return rawScores
    .map((session) => {
      return { date: session.date, score: session.value };
    })
    .filter((session) => isBetween(session.date, timeframe));
}

function getDisplayScoreAndDelta(rawScores, timeframe) {
  const scores = getScores(rawScores, timeframe);

  const firstScore = head(scores)?.score;
  const lastScore = last(scores)?.score;

  return {
    score: Math.round(lastScore),
    delta: lastScore - firstScore,
  };
}

function getFactorsDisplayScoreAndDelta(rawScores, timeframe) {
  const result = {};

  const factorScores = groupBy(rawScores, 'name');

  Object.keys(factorScores).forEach((factorName) => {
    const templateFactorNameKey = factorName.toLowerCase();

    result[templateFactorNameKey] = {
      ...getDisplayScoreAndDelta(factorScores[factorName], timeframe),
    };
  });

  return result;
}
