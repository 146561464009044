import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import styles from './ShareMenuItem.module.scss';

export default function ShareMenuItem({ className, LogoSVG, name, ...props }) {
  return (
    <Box
      p={2}
      className={classNames(styles.ShareMenuItem, className)}
      {...props}
    >
      <Box className={styles.LogoContainer} mr={2}>
        <LogoSVG className={styles.Logo} />
      </Box>
      <span className={styles.Label}>{name}</span>
    </Box>
  );
}

ShareMenuItem.propTypes = {
  className: PropTypes.string,
  LogoSVG: PropTypes.func.isRequired,
  name: PropTypes.string,
};
