import React, { useState, useMemo, useContext } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import RadioSelector from '../../Molecules/RadioSelector/RadioSelector';
import MetricList from '../../Molecules/MetricList/MetricList';

import BNContext from '../../../contexts/BNContext';

import styles from './FilterableMetricsList.module.scss';

const GROUP_FILTER_TYPES = {
  ALL: 'All',
  FAVORITE: 'Favorite',
};
export default function FilterableMetricsList({
  metricGroup,
  onMetricClick,
  metrics = [],
}) {
  const { FEATURE_FLAGS, metricsFavoriteMap } = useContext(BNContext);

  const [selectedGroupType, setSelectedGroupType] = useState(
    GROUP_FILTER_TYPES.ALL
  );

  const filteredMetrics = useMemo(() => {
    let result = [];

    if (selectedGroupType === GROUP_FILTER_TYPES.FAVORITE) {
      metrics.forEach((metric) => {
        if (metricsFavoriteMap[metric.variableId]) {
          result.push(metric);
        }
      });
    } else {
      result = metrics;
    }

    return result;
  }, [metrics, metricsFavoriteMap, selectedGroupType]);

  function handleFilterChange(item) {
    setSelectedGroupType(item.name);
  }

  const radioItems = [
    {
      name: GROUP_FILTER_TYPES.ALL,
    },
    {
      name: GROUP_FILTER_TYPES.FAVORITE,
    },
  ];

  return (
    <div className={styles.FilterableMetricsList}>
      <Box mb={6}>
        <header className={styles.Header}>{metricGroup.name} Metrics</header>
      </Box>
      {FEATURE_FLAGS.GLOBAL.METRICS_FAVORITE ? (
        <Box mb={3}>
          <RadioSelector
            items={radioItems}
            defaultSelectedIndex={0}
            onChange={handleFilterChange}
          />
        </Box>
      ) : null}

      <Box>
        <MetricList metrics={filteredMetrics} onMetricClick={onMetricClick} />
      </Box>
    </div>
  );
}

FilterableMetricsList.propTypes = {
  metricGroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  onMetricClick: PropTypes.func,
};
