import { useMemo } from 'react';

export interface UsePaginationProps {
  totalCount: number;
  pageSize: number;
  pageIndex: number;
  siblingCount?: number;
}

const range = (start: number, end: number): number[] => {
  const length = end - start + 1;
  return Array.from({ length }, (_, index) => index + start);
};

const dots = '...';

export const usePagination = ({
  totalCount,
  pageSize,
  pageIndex,
  siblingCount = 2,
}: UsePaginationProps): {
  paginationRange: (string | number)[];
  handleDotClick: (focusIndex: number) => number;
} => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    // first + last + currentPage + ellipsis
    const totalPageNumbers = siblingCount + 4;
    const positionBuffer = 2;
    const firstIndex = 1;

    // If no need for hiding page numbers return count
    if (totalPageNumbers >= totalPageCount) {
      return range(firstIndex, totalPageCount);
    }

    const leftSiblingIndex = Math.max(pageIndex - siblingCount, 0);
    const rightSiblingIndex = Math.min(
      pageIndex + siblingCount - 1,
      totalPageCount
    );
    const showLeftDots = leftSiblingIndex > positionBuffer;
    const showRightDots = rightSiblingIndex < totalPageCount - positionBuffer;

    // Just show dots on right
    if (!showLeftDots && showRightDots) {
      const leftItemCount = 2 + positionBuffer * siblingCount;
      const leftRange = range(firstIndex, leftItemCount);

      return [...leftRange, dots, totalPageCount];
    }

    // Just show dots on left
    if (showLeftDots && !showRightDots) {
      const rightItemCount = 3 + positionBuffer * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount, totalPageCount);
      return [firstIndex, dots, ...rightRange];
    }

    if (showRightDots && showLeftDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstIndex, dots, ...middleRange, dots, totalPageCount];
    }

    const defaultRange = range(firstIndex, totalPageCount);
    return [...defaultRange];
  }, [totalCount, pageSize, siblingCount, pageIndex]);

  const handleDotClick = (focusIndex: number) => {
    const halfway = Math.ceil(paginationRange.length / 2);
    const navigateBack = focusIndex < halfway;
    const closestIndex = navigateBack ? 1 : -1;
    const siblingBuffer = navigateBack ? -siblingCount : siblingCount;
    const page =
      Number(paginationRange[focusIndex + closestIndex]) + siblingBuffer;
    return page;
  };

  return { paginationRange, handleDotClick };
};
