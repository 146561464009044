import { FC } from 'react';
import classNames from 'classnames';
import sumBy from 'lodash/sumBy';

import CreativeChannelsRowItem from './CreativeChannelsRowItem';

import { CreativeChannelSpendAsset } from '../../../../interfaces/creative';
import styles from './CreativeChannelsSection.module.scss';

export interface CreativeChannelsSectionProps {
  channelsData: Array<CreativeChannelSpendAsset>;
}

const CreativeChannelsSection: FC<CreativeChannelsSectionProps> = ({
  channelsData,
}) => {
  const sortedChannelsData = channelsData.sort(
    (a, b) => sumBy(b.data, 'y') - sumBy(a.data, 'y')
  );

  return (
    <div className={classNames(styles.CreativeChannelsSection)}>
      {sortedChannelsData.map((item) => (
        <CreativeChannelsRowItem key={item.name} data={item} />
      ))}
    </div>
  );
};

export default CreativeChannelsSection;
