import { gql } from '@apollo/client';

export const FACTOR_DETAILS = gql`
  query getFactorData(
    $id: ID
    $sessionKey: ID
    $factorId: Int
    $accountKey: ID
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        factor(factorId: $factorId) {
          id
          name
          value
          delta
          insight {
            name
            value
          }
          recommendations(accountKey: $accountKey) {
            id
            name
            value
            type
            impact_id
            sourceType_id
            isComplete
            practice_areas
          }
          subFactors {
            associatedTypeId
            name
            value
            delta
          }
        }
        factorsCalc: brandLastPeriodScores(associatedTypeId: 1) {
          type
          t4Quarters
        }
        subfactorsCalc: brandLastPeriodScores(associatedTypeId: 2) {
          type
          t4Quarters
        }
        competitorFactorAverage {
          name
          average
        }
        competitorSubFactorAverage {
          factor_id
          name
          average
        }
      }
    }
  }
`;
