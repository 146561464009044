import { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import concat from 'lodash/concat';
import isNil from 'lodash/isNil';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

import CompetitionGradientBar from '../../Molecules/CompetitionGradientBar/CompetitionGradientBar';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';

import styles from './MarketIndexRelativePerformance.module.scss';

function MarketIndexRelativePerformance({
  sectionDescription,
  brandBluescore,
  competitorBluescores,
  loading,
  ...props
}) {
  return (
    <Box
      className={styles.MarketIndexRelativePerformance}
      id="market-index-relative-performance"
      {...props}
    >
      <Grid
        container
        alignItems="center"
        className={classNames('SubtitleContainer', 'remove-from-export')}
      >
        <Grid item xs={11}>
          <h2 className={styles.Title}>
            Questions that the Blue Score answers:
          </h2>
        </Grid>
        <Grid
          item
          container
          xs={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          <div className="DownloadButtonContainer">
            <DownloadDropdown
              targetId="market-index-relative-performance"
              json={concat(brandBluescore, competitorBluescores)}
              fileName="relative-performance"
              padding={100}
            />
          </div>
        </Grid>
      </Grid>

      <div>
        <Box
          className={classNames(
            styles.SectionDescription,
            'remove-from-export'
          )}
        >
          {sectionDescription.map((paragraph, index) => (
            <Box
              key={paragraph.substring(0, 4)}
              mb={index < sectionDescription.length - 1 ? 4 : 0}
            >
              <p className={styles.Paragraph}>{paragraph}</p>
            </Box>
          ))}
        </Box>

        {loading || (!competitorBluescores && !brandBluescore) ? (
          <ComponentLoader minHeight={283} />
        ) : (
          <div>
            {!competitorBluescores ||
            !brandBluescore ||
            isNil(competitorBluescores[0].score) ? (
              <NoResultsAlert text="No relative performance chart data available." />
            ) : null}
          </div>
        )}

        {competitorBluescores &&
        brandBluescore &&
        !isNil(competitorBluescores[0].score) ? (
          <div className={styles.GradientBarSection}>
            <CompetitionGradientBar
              topPins={[brandBluescore]}
              bottomPins={competitorBluescores}
            />
          </div>
        ) : null}
      </div>
    </Box>
  );
}

MarketIndexRelativePerformance.propTypes = {
  sectionDescription: PropTypes.arrayOf(PropTypes.string),
  brandBluescore: PropTypes.shape({
    name: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    logoUrl: PropTypes.string,
    position: PropTypes.string,
    size: PropTypes.string,
  }),
  loading: PropTypes.bool,
  competitorBluescores: PropTypes.arrayOf(
    PropTypes.shape({
      score: PropTypes.number,
      name: PropTypes.string,
      logoUrl: PropTypes.string,
    })
  ),
};

export default memo(MarketIndexRelativePerformance);
