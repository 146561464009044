import * as React from 'react';

function SvgIconYoutube(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g
        style={{
          mixBlendMode: 'darken',
        }}
        fill="none"
        fillRule="evenodd"
      >
        <path d="M0 0h24v24H0z" />
        <path
          fill="#7E9397"
          d="M12.126 5c2.259 0 4.518.185 6.776.556C20.688 5.849 22 7.418 22 9.258v6.49c0 1.84-1.312 3.409-3.096 3.702-2.228.367-4.493.55-6.794.55-2.335 0-4.67-.188-7.003-.565C3.317 19.145 2 17.575 2 15.73V9.278C2 7.432 3.32 5.86 5.11 5.574A44.047 44.047 0 0112.126 5zm0 1.071c-2.24 0-4.523.187-6.852.56-1.279.206-2.221 1.329-2.221 2.647v6.453c0 1.317.94 2.44 2.219 2.646 2.28.368 4.559.552 6.838.552 2.245 0 4.454-.18 6.626-.536 1.274-.21 2.211-1.33 2.211-2.645v-6.49c0-1.315-.937-2.435-2.212-2.644a40.707 40.707 0 00-6.61-.543zM10 9.51c0-.387.45-.632.817-.445l4.899 2.501a.491.491 0 01-.002.892l-4.899 2.48c-.367.185-.815-.06-.815-.446zm1.1.862v3.26l3.206-1.622-3.205-1.638z"
        />
      </g>
    </svg>
  );
}

export default SvgIconYoutube;
