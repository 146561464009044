import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Box from '@mui/material/Box';
import Popover from '@material-ui/core/Popover';

import ShareButton from '../../Atoms/ShareButton/ShareButton';
import ShareDropdownMenu from '../ShareDropdownMenu/ShareDropdownMenu';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

import styles from './ShareDropdown.module.scss';

function ShareDropdown({ className, targetId, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const FEATURE_FLAGS = useFeatureFlags();

  function handleButtonClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'share-dropdown-popover' : null;

  if (!FEATURE_FLAGS.GLOBAL.SHARE.ROOT) return null;

  return (
    <Box
      className={classNames(styles.ShareDropdown, 'hide-from-share', className)}
      {...props}
    >
      <ShareButton onClick={handleButtonClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        elevation={3}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ShareDropdownMenu
          targetId={targetId}
          onClose={handleClose}
          onSuccess={handleClose}
        />
      </Popover>
    </Box>
  );
}

export default ShareDropdown;

ShareDropdown.propTypes = {
  className: PropTypes.string,
  targetId: PropTypes.string,
};
