import React, { useEffect } from 'react';
// cross-fetch only exists to supply @apollo/client a fetch library during testing.
import fetch from 'cross-fetch';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
import MockEnvironmentVariables from '../../../mocks/env-vars';

import DataManager from '../DataManager/DataManager';
import { PoseidonSocketProvider } from '../../../contexts/PoseidonSocketContext';

export default function ApplicationAuthBoundary({ children }) {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  const [token, setToken] = React.useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (process.env.REACT_APP_ENV === 'local-mock') {
      setToken(MockEnvironmentVariables.AuthenticationToken);
      return;
    }

    if (isAuthenticated && !token) {
      getAccessTokenSilently({
        scope: 'openid profile email',
        audience: process.env.REACT_APP_AUDIENCE,
      })
        .then((newToken) => {
          setToken(newToken);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }

    if (!isAuthenticated && !token && !isLoading) {
      const urlParams = new URLSearchParams(window.location.search);

      const productId = urlParams.get('productId');
      const priceId = urlParams.get('priceId');
      const screenHint = urlParams.get('screen_hint');
      if (productId && priceId) {
        localStorage.setItem('productId', productId);
        localStorage.setItem('priceId', priceId);
      }

      if (screenHint && screenHint === 'signup')
        loginWithRedirect({ action: 'signup' });
      else loginWithRedirect({ prompt: 'login' });
    }
  }, [
    isAuthenticated,
    token,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  ]);

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  const httpLink = createHttpLink({
    uri: ({ operationName }) => {
      return `${apiUrl}?op=${operationName}`;
    },
    fetch,
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      {isLoading || !token ? (
        <p>Loading... 20%</p>
      ) : (
        <DataManager>
          <PoseidonSocketProvider>{children}</PoseidonSocketProvider>
        </DataManager>
      )}
    </ApolloProvider>
  );
}

ApplicationAuthBoundary.propTypes = {
  children: PropTypes.node,
};
