import { GenericIconProps } from '../types';

const BarChartAscendingIcon = (props: GenericIconProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 23.8535H8V6.85352H7L7 24.8535H25V23.8535Z"
      fill="#D7DDE2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 8.85352C21.2239 8.85352 21 9.07737 21 9.35352V22.8535H24V9.35352C24 9.07737 23.7761 8.85352 23.5 8.85352H21.5ZM17 12.3535C17 12.0774 17.2239 11.8535 17.5 11.8535H19.5C19.7761 11.8535 20 12.0774 20 12.3535V22.8535H17V12.3535ZM13.5 14.8535C13.2239 14.8535 13 15.0774 13 15.3535V22.8535H16V15.3535C16 15.0774 15.7761 14.8535 15.5 14.8535H13.5ZM9.5 16.8535C9.22386 16.8535 9 17.0774 9 17.3535V22.8535H12V17.3535C12 17.0774 11.7761 16.8535 11.5 16.8535H9.5Z"
      fill="#20434A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 17.8535H21V22.8535H24V17.8535ZM17 18.8535H20V22.8535H17V18.8535ZM16 19.8535H13V22.8535H16V19.8535ZM12 20.8535H9V22.8535H12V20.8535Z"
      fill="#CED9DB"
    />
  </svg>
);

export default BarChartAscendingIcon;
