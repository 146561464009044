import { GenericIconProps } from '../icons/types';

const LogoYoutubeCircle = (props: GenericIconProps) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="11" cy="11" r="11" fill="#ED1F24" />
    <path
      d="M17.9641 7.44237C17.7969 6.81693 17.3045 6.32445 16.679 6.15732C15.5454 5.85352 11 5.85352 11 5.85352C11 5.85352 6.45459 5.85352 5.32089 6.15732C4.69559 6.32445 4.203 6.81693 4.03584 7.44237C3.73218 8.57592 3.73218 10.941 3.73218 10.941C3.73218 10.941 3.73218 13.3062 4.03584 14.4396C4.203 15.065 4.69559 15.5576 5.32089 15.7248C6.45459 16.0285 11 16.0285 11 16.0285C11 16.0285 15.5454 16.0285 16.679 15.7248C17.3045 15.5576 17.7969 15.065 17.9641 14.4396C18.2679 13.3062 18.2679 10.941 18.2679 10.941C18.2679 10.941 18.2679 8.57592 17.9641 7.44237Z"
      fill="white"
    />
    <path
      d="M9.54651 13.1215L13.3229 10.9411L9.54651 8.76074V13.1215Z"
      fill="#ED1F24"
    />
  </svg>
);

export default LogoYoutubeCircle;
