import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import Tag from '../../../Labels/Tag';
import {
  CreativeAssetType,
  CreativeAsset,
} from '../../../../interfaces/creative';
import CreativeAssetTypeTag from '../CreativeAssetTypeTag/CreativeAssetTypeTag';
import { formatCurrency } from '../../../../utils/number';

import styles from './CreativeAssetCard.module.scss';
import MediaTypeIcon, {
  MediaIcon,
} from '../../../../assets/icons/MediaTypeIcon';

export interface CreativeAssetCardProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  forceHover?: boolean;
  creative: CreativeAsset;
  handleOnClick: (creative: CreativeAsset) => void;
}

const CreativeAssetCard: FunctionComponent<CreativeAssetCardProps> = ({
  className,
  forceHover = false,
  creative,
  handleOnClick,
}) => {
  const [imgLoadError, setImgLoadError] = useState(false);

  const handleImageError = useCallback(() => {
    setImgLoadError(true);
  }, []);

  const handleImageSuccess = useCallback(() => {
    setImgLoadError(false);
  }, []);

  return (
    <div
      onClick={() => handleOnClick(creative)}
      className={classNames(
        styles.CreativeAssetCard,
        forceHover ? styles.Hover : null,
        className
      )}
      data-cy="creative-asset-card"
    >
      <div className={styles.AssetContent}>
        {(imgLoadError || !creative.thumbnailUrl) &&
        creative.type !== CreativeAssetType.Audio ? (
          <div className={styles.NoThumbnail}>
            <CreativeAssetTypeTag
              className={styles.FloatingIcon}
              creativeType={creative.type}
            />

            <MediaTypeIcon
              iconType={MediaIcon.Image}
              className={styles.MajorIcon}
              height={40}
              width={40}
            />
            <div data-cy="no-thumbnail-message">No thumbnail available</div>
          </div>
        ) : (
          <>
            {creative.type === null && creative.thumbnailUrl && (
              <div className={styles.NoThumbnail}>
                <MediaTypeIcon
                  iconType={MediaIcon.Image}
                  className={styles.MajorIcon}
                  height={40}
                  width={40}
                />

                <div data-cy="no-thumbnail-message">Unknown creative type</div>
              </div>
            )}

            {creative.type === CreativeAssetType.Image &&
              creative.thumbnailUrl && (
                <>
                  <CreativeAssetTypeTag
                    className={styles.FloatingIcon}
                    creativeType={creative.type}
                  />
                  <img
                    className={styles.AssetCardImg}
                    src={creative.thumbnailUrl}
                    alt="creative-asset-thumbnail"
                    onError={handleImageError}
                    onLoad={handleImageSuccess}
                    data-cy="creative-asset-thumbnail"
                  />
                </>
              )}

            {creative.type === CreativeAssetType.Video &&
              creative.thumbnailUrl && (
                <>
                  <CreativeAssetTypeTag
                    className={styles.FloatingIcon}
                    creativeType={creative.type}
                  />

                  <img
                    className={styles.AssetCardImg}
                    src={creative.thumbnailUrl}
                    alt="creative-asset-thumbnail"
                    onError={handleImageError}
                    onLoad={handleImageSuccess}
                    data-cy="creative-asset-thumbnail"
                  />
                </>
              )}

            {creative.type === CreativeAssetType.Audio && (
              <div className={styles.NoThumbnail}>
                <CreativeAssetTypeTag
                  className={styles.FloatingIcon}
                  creativeType={creative.type}
                />

                <MediaTypeIcon
                  iconType={MediaIcon.Audio}
                  className={styles.MajorIcon}
                  height={40}
                  width={40}
                />

                <div data-cy="no-thumbnail-message">Radio advertisement</div>
              </div>
            )}
          </>
        )}
      </div>

      <div className={styles.AssetHoverContent}>
        <div className={styles.CreativeName}>{creative.name}</div>

        <div className={styles.PreviewList}>
          {!isNil(creative.spend) && (
            <div className={styles.PreviewListItem}>
              <div className={styles.Label}>Spend</div>
              <div className={styles.Value}>
                ${creative.spend.toLocaleString()}
              </div>
            </div>
          )}

          {!isNil(creative.units) && (
            <div className={styles.PreviewListItem}>
              <div className={styles.Label}>Units</div>
              <div className={styles.Value}>
                {creative.units.toLocaleString()}
              </div>
            </div>
          )}

          {!isNil(creative.impressions) && (
            <div className={styles.PreviewListItem}>
              <div className={styles.Label}>Impressions</div>
              <div className={styles.Value}>
                {creative.impressions.toLocaleString()}
              </div>
            </div>
          )}

          {!isNil(creative.eCPM) && (
            <div className={styles.PreviewListItem}>
              <div className={styles.Label}>CPM</div>
              <div className={styles.Value}>
                {formatCurrency(creative.eCPM)}
              </div>
            </div>
          )}
        </div>

        {creative.tags ? (
          <div className={styles.TagList}>
            {creative.tags.map((tag) => {
              return <Tag key={tag.id} name={tag.name} />;
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CreativeAssetCard;
