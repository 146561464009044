import styles from './TopList.module.scss';

export interface TopListProps {
  topNetworks: string[];
  topProducts: string[];
}

const TopList = ({ topNetworks, topProducts }: TopListProps) => {
  const networks = topNetworks?.length ? topNetworks.join(', ') : 'n/a';
  const products = topProducts?.length ? topProducts.join(', ') : 'n/a';

  return (
    <div className={styles.TopList}>
      <p className={styles.ListItem} data-cy="top-networks">
        <span className={styles.Header}>Top networks:</span>
        <span>{networks}</span>
      </p>
      <p className={styles.ListItem} data-cy="top-products">
        <span className={styles.Header}>Top products:</span>
        <span>{products}</span>
      </p>
    </div>
  );
};

export default TopList;
