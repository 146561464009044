import styles from './AccordionList.module.scss';

export interface AccordionListProps {
  metricId: string;
  onMetricClick: (metricVariableId: string, id: number) => void;
  listItems: Array<{id: number, name: string}>;
}
const AccordionList = ({ metricId, onMetricClick, listItems } : AccordionListProps) => {

  return(
    <div className={styles.AccordionList}>
      <ul>
        {listItems.map((item) => (
          <li key={item.name}>
            <span
              onClick={() => onMetricClick(metricId, item.id)}
            >{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AccordionList;