import dayjs from 'dayjs';
import { ChartTooltipContextBar, SubfactorKey } from '../../../interfaces';
import { getOrCreateTooltip } from '../../../utils/chartjs';

import styles from './subfactorBarChartTooltip.module.scss';

export default function createCustomTooltip(
  context: ChartTooltipContextBar,
  tooltipDataBySubfactor: {
    [value in SubfactorKey]: {
      delta: number;
      sessionScores: { value: number; date: Date | string }[];
    };
  }
): null {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart, {
    wrapper: styles.SubfactorBarChartTooltip,
    contents: styles.TooltipContents,
  });

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return null;
  }

  const subfactorKey = (
    tooltip.dataPoints[0].raw as { subfactor: SubfactorKey }
  ).subfactor;

  // Build tooltip
  if (tooltip.body) {
    // Get tooltip data
    const tooltipData = tooltipDataBySubfactor[subfactorKey];

    // Build major delta
    const subfactorScoreDelta = document.createElement('div');
    const subfactorScoreDeltaText = document.createTextNode(
      tooltipData.delta < 0
        ? String(tooltipData.delta)
        : `+${String(tooltipData.delta)}`
    );
    subfactorScoreDelta.classList.add(styles.Delta);
    subfactorScoreDelta.appendChild(subfactorScoreDeltaText);

    // Build score history list container
    const subfactorScoreHistory = document.createElement('div');
    subfactorScoreHistory.classList.add(styles.ScoreHistoryList);

    tooltipData.sessionScores.forEach((session) => {
      // Build score history list item
      const subfactorScoreHistoryItem = document.createElement('div');
      subfactorScoreHistoryItem.classList.add(styles.ScoreHistoryItem);

      // Build score history item date
      const historyItemDate = document.createElement('div');
      const historyItemDateText = document.createTextNode(
        `${dayjs(session.date).format('M/D/YYYY')}:`
      );
      historyItemDate.classList.add(styles.HistoryItemDate);
      historyItemDate.appendChild(historyItemDateText);

      // Build score history item score
      const historyItemScore = document.createElement('div');
      const historyItemScoreText = document.createTextNode(
        String(session.value)
      );
      historyItemScore.classList.add(styles.HistoryItemScore);
      historyItemScore.appendChild(historyItemScoreText);

      // Add date and score to history item
      subfactorScoreHistoryItem.appendChild(historyItemDate);
      subfactorScoreHistoryItem.appendChild(historyItemScore);

      // Add history item to history list
      subfactorScoreHistory.appendChild(subfactorScoreHistoryItem);
    });

    const tooltipContents = tooltipEl.querySelector(
      `.${styles.TooltipContents}`
    );

    // Remove old children
    while (tooltipContents?.firstChild) {
      tooltipContents.firstChild.remove();
    }

    // Add new children
    tooltipContents?.appendChild(subfactorScoreDelta);
    tooltipContents?.appendChild(subfactorScoreHistory);

    // Add arrow
    const tooltipArrow = document.createElement('div');
    tooltipArrow.classList.add(styles.TooltipArrowLeft);
    tooltipContents?.appendChild(tooltipArrow);

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipArrow.style.top = `${tooltipEl.clientHeight / 2 - 10}px`;
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = `${positionX + tooltip.caretX + 94}px`;
    tooltipEl.style.top = `${
      positionY + tooltip.caretY - tooltipEl.clientHeight / 2 - 10
    }px`;
  }

  return null;
}
