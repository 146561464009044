import { Grid } from '@mui/material';
import { BrandLogo, Button } from '@blueoceanai/react-component-library';
import cn from 'classnames';
import styles from '../ContentAnalysisKeywordsBrandContent.module.scss';
import RadioSelector from '../../../Molecules/RadioSelector/RadioSelector';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import { SIZES } from '../../../../constants/props';
import { KeywordTrendHeaderProps } from '../types';

const KeywordTrendHeader = ({
  dateOptions,
  handleDateFilterChange,
  onClose,
  logoUrl,
  name,
  selectedDateOption,
  children,
}: KeywordTrendHeaderProps): JSX.Element => {
  return (
    <div
      data-testid="keyword-trend-header"
      className={cn(styles.TrendCard, styles.KeywordTrendHeaderContainer)}
    >
      <Grid
        container
        className={styles.TrendCardControls}
        alignItems="center"
        justifyContent="flex-end"
      >
        <RadioSelector
          items={dateOptions}
          defaultSelectedIndex={selectedDateOption}
          onChange={handleDateFilterChange}
        />
        <Button className={styles.Close} onClick={onClose}>
          <CloseIcon />
        </Button>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid
            container
            item
            xs={4}
            className={styles.TrendCardLogoContainer}
            direction="column"
            alignItems="center"
          >
            <BrandLogo size={SIZES.LG} src={logoUrl} />
            <p>{name}</p>
          </Grid>
        </Grid>
        {children}
      </Grid>
    </div>
  );
};

export default KeywordTrendHeader;
