/* eslint-disable import/prefer-default-export */
import filter from 'lodash/filter';
import { Brand } from '../../../../hooks/useCompetitiveSet';

const openDefaultCompare = (brands: Brand[]): Set<string> => {
  if (!brands.length || brands.length < 2) {
    return new Set([]);
  }

  const [hero, compare] = brands;
  return new Set([hero.brandKey, compare.brandKey]);
};

const brandKeyIsActive = (
  brandKey: string,
  activeBrands: Set<string> = new Set()
): boolean => {
  return Array.from(activeBrands).indexOf(brandKey) >= 0;
};

const findActiveBrands = (
  activeBrands: Set<string>,
  brands: Brand[]
): Brand[] | null => {
  if (!activeBrands.size || !brands.length) return null;
  return Array.from(activeBrands).reduce((cards, brandKey) => {
    const [brand] = filter(brands, ['brandKey', brandKey]);
    if (brand) {
      cards.push(brand);
    }

    return cards;
  }, [] as Brand[]);
};

const replaceBrand = (
  newBrandKey: string,
  oldBrandKey: string,
  activeBrands: Set<string>
): Set<string> => {
  const brandKeysOrder = Array.from(activeBrands);
  const oldIndex = brandKeysOrder.indexOf(oldBrandKey);
  if (oldIndex < 0) return new Set();

  if (brandKeyIsActive(newBrandKey)) return new Set();

  brandKeysOrder.splice(oldIndex, 1, newBrandKey);
  return new Set(brandKeysOrder);
};

const addBrand = (activeBrands: Set<string>, brands: Brand[]): Set<string> => {
  const brandsOrder = Array.from(activeBrands);
  const [inactive] = filter(
    brands,
    ({ brandKey }) => brandsOrder.indexOf(brandKey) < 0
  );
  if (!inactive) return new Set();

  brandsOrder.push(inactive.brandKey);
  return new Set(brandsOrder);
};

const removeBrand = (
  brandKey: string,
  activeBrands: Set<string>
): Set<string> => {
  const brandsOrder = Array.from(activeBrands);
  const removeIndex = brandsOrder.indexOf(brandKey);
  brandsOrder.splice(removeIndex, 1);
  return new Set(brandsOrder);
};

export {
  openDefaultCompare,
  brandKeyIsActive,
  findActiveBrands,
  replaceBrand,
  addBrand,
  removeBrand,
};
