export function prepareMarkers(markersResp) {
  if (
    !markersResp ||
    !markersResp.competitiveSet ||
    !markersResp.competitiveSet.markers
  ) {
    return;
  }

  const result = [];

  markersResp.competitiveSet.markers.forEach((marker) => {
    result.push({
      date: marker.markerEventDate,
      description: marker.label,
    });
  });

  return result;
}
