import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import { StyledTabs, StyledTab } from './MuiStyleOverrideHOCs';

import SectionHeaderBar from '../../Atoms/SectionHeaderBar/SectionHeaderBar';
import PageTitle from '../../Molecules/PageTitle/PageTitle';

import useRouter from '../../../hooks/useRouter';

import styles from './MarketIndexNavigation.module.scss';

const tabs = [
  {
    label: 'Overview',
    value: 'overview',
  },
  {
    label: 'Scorecard',
    value: 'scorecard',
  },
  {
    label: 'Subfactors',
    value: 'subfactors',
  },
  {
    label: 'Compare',
    value: 'compare',
  },
];

export default function MarketIndexNavigation({ className }) {
  const location = useLocation();
  const { updateRoute } = useRouter();

  const marketIndexPage = useMemo(() => {
    const currentTab = find(tabs, (tab) => {
      return location.pathname.includes(tab.value);
    });

    if (!currentTab) {
      return '';
    }

    return currentTab.value;
  }, [location.pathname]);

  function tabChangeHandler(event, newMarketIndexPage) {
    updateRoute({ pathname: `/market-index/${newMarketIndexPage}` });
  }

  return (
    <SectionHeaderBar
      position="static"
      className={classNames(styles.MarketIndexNavigation, className)}
      style={{ boxShadow: 'none' }}
    >
      <div className={classNames(styles.Header, 'WorkspaceTitleContainer')}>
        <PageTitle
          targetId="market-index-page"
          shareable
          fullscreen
          padded
          title="Market Index"
          className="Title"
        />
      </div>

      <StyledTabs
        value={marketIndexPage}
        onChange={tabChangeHandler}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <StyledTab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            disableFocusRipple
            disableRipple
            component="div"
            data-cy={`subnav-btn-${tab.value}`}
          />
        ))}
      </StyledTabs>
    </SectionHeaderBar>
  );
}

MarketIndexNavigation.propTypes = {
  className: PropTypes.string,
};
