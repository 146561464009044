import dayjs from 'dayjs';
import dayjsIsBetween from 'dayjs/plugin/isBetween';

dayjs.extend(dayjsIsBetween);

export function isBetween(checkedTime, timeframe) {
  const timeframeStart = dayjs(timeframe.end).subtract(
    timeframe.quantity,
    timeframe.unit
  );
  const timeframeEnd = dayjs(timeframe.end);

  return dayjs(checkedTime).isBetween(
    timeframeStart,
    timeframeEnd,
    'day',
    '[]'
  );
}

export function isActive(option, timeframe) {
  if (
    option.end &&
    dayjs(option.end).isSame(timeframe.end, 'day') &&
    option.unit === timeframe.unit &&
    option.quantity === timeframe.quantity &&
    option.label === timeframe.label
  ) {
    return true;
  }

  if (
    !option.end &&
    option.unit === timeframe.unit &&
    option.quantity === timeframe.quantity
  ) {
    return true;
  }

  return false;
}

export function getDayJsStartDate(timeframe) {
  return dayjs(timeframe.end).subtract(timeframe.quantity, timeframe.unit);
}

export function optionAfterMinDate(minDate, option) {
  const optionStart = dayjs().subtract(option.quantity, option.unit);
  return dayjs(minDate).isSameOrBefore(optionStart, 'day');
}
