/* eslint-disable no-alert */

import { useLazyQuery } from '@apollo/client';
import {
  AnchorHTMLAttributes,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import countBy from 'lodash/countBy';
import { GET_METRIC_COMPARISON_DATA } from '../../../../../api/queries/Pages/CustomizableDashboards';
import { formatMetricComparisonData } from '../widgetFormatResponse';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/DataManager';
import {
  ActiveBluescoreBrand,
  BlueScoreBrandsSummary,
  FormattedMetricComparisonData,
  WidgetMetaResponse,
} from '../../types';
import BlueScoreWidgetControl from './BlueScoreWidgetControl/BlueScoreWidgetControl';
import BlueScoreWidgetGraph from './BlueScoreWidgetGraph/BlueScoreWidgetGraph';
import ChartLegend from '../../../../Molecules/ChartLegend/ChartLegend';
import { CHART_LEGEND_ICON_VARIANT } from '../../../../../constants/props';
import PALETTES, { PaletteType } from '../../../../../constants/color-palettes';
import WidgetSubheader from '../../WidgetSubheader/WidgetSubheader';
import { TimeRange, WidgetType } from '../../../../../interfaces/dashboard-api';
import ScoreLegend from '../../../../Molecules/ScoreLegend/ScoreLegend';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import { timeRangeById } from '../../WidgetSettingsShared';
import { handleConfigInputArrayFromDashboardAPI } from '../../WidgetSettingsFlows/helper';

import BNContext from '../../../../../contexts/BNContext';

import styles from './BlueScoreWidget.module.scss';

export interface BlueScoreWidgetProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  timeframe?: TimeRange;
  config: WidgetMetaResponse;
  globalDateFilter: {
    endDate: Date;
    timeRange: TimeRange | null;
  };
}

const BlueScoreWidget = ({
  config,
  timeframe = TimeRange['90_DAYS'],
  globalDateFilter,
}: BlueScoreWidgetProps) => {
  const { accountKey } = useContext(BNContext);

  const brandKeysFromConfig = handleConfigInputArrayFromDashboardAPI(
    config?.options?.selected_brand_keys
  ) as string[];

  const selectedBrandsMap: { [key: string]: boolean } =
    brandKeysFromConfig.reduce(
      (acc: { [key: string]: boolean }, brandKey: string) => {
        acc[brandKey] = true;
        return acc;
      },
      {}
    );

  const [selectedBrands, setSelectedBrands] =
    useState<ActiveBluescoreBrand>(selectedBrandsMap);
  const [data, setData] = useState<FormattedMetricComparisonData | null>(null);

  const [activeEndDate, setActiveEndDate] = useState<Date>(
    config?.data_srcs?.metric?.input?.endDate
      ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
      : new Date()
  );
  const [activeTimeRange, setActiveTimeRange] = useState<TimeRange>(
    config.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
  );

  useEffect(() => {
    if (globalDateFilter?.timeRange && globalDateFilter?.endDate) {
      setActiveTimeRange(globalDateFilter.timeRange);
      setActiveEndDate(globalDateFilter.endDate);
    } else {
      setActiveTimeRange(
        config?.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
      );
      setActiveEndDate(
        config?.data_srcs?.metric?.input?.endDate
          ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
          : new Date()
      );
    }
  }, [globalDateFilter, config]);

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    {
      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    if (config?.data_srcs?.metric?.input) {
      const { competitiveSetKey } = config.data_srcs.metric.input;

      getFirstLastSession({
        variables: {
          id: competitiveSetKey,
        },
      });
    }
  }, [config, getFirstLastSession]);

  // TODO: get session key based on custom date range
  const latestSessionKey =
    firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
      ?.sessionKey;

  const [getMetricComparisonData, { loading, data: rawMetricScoreResponse }] =
    useLazyQuery(GET_METRIC_COMPARISON_DATA, {
      variables: {},
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (!config.data_srcs?.metric?.input || !latestSessionKey) return;

    const { competitiveSetKey } = config.data_srcs.metric.input;
    const { metric: selectedMetric } = config.options;

    getMetricComparisonData({
      variables: {
        id: competitiveSetKey,
        sessionKey: latestSessionKey,
        input: {
          selectedAccountKey: accountKey,
          associatedTypeId: selectedMetric?.var_id,
          range: timeRangeById[activeTimeRange].value,
          endDate: activeEndDate,
          compSetKey: competitiveSetKey,
        },
      },
    });
  }, [
    accountKey,
    activeTimeRange,
    activeEndDate,
    config,
    getMetricComparisonData,
    latestSessionKey,
    timeframe,
  ]);

  const handleTimeRangeChange = (option: TimeRange) =>
    setActiveTimeRange(option);

  const legendItems = useMemo(() => {
    if (!data || !data.brands.length) return [];

    return [
      {
        variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
        label: 'Industry Average',
      },
      ...data.brands.map((brand, index) => {
        let colorIndex = index;
        if (PALETTES[PaletteType.Ocean].length <= index) {
          colorIndex = PALETTES[PaletteType.Ocean].length - index;
        }
        const color = PALETTES[PaletteType.Ocean][colorIndex].DEFAULT;
        return {
          variant: CHART_LEGEND_ICON_VARIANT.SQUARE,
          label: brand.name,
          color: {
            DEFAULT: color,
            FILL: color,
          },
        };
      }),
    ];
  }, [data]);

  useEffect(() => {
    if (loading || !rawMetricScoreResponse?.competitiveSet) return;

    setData(
      formatMetricComparisonData(
        rawMetricScoreResponse.competitiveSet.session.metricComparisonWidget,
        selectedBrands,
        timeframe
      )
    );
  }, [rawMetricScoreResponse, loading, selectedBrands, timeframe]);

  const handleBrandClick = (brand: BlueScoreBrandsSummary) => {
    const isSelected = Boolean(selectedBrands[brand.id]);
    const updatedSelected = { ...selectedBrands, [brand.id]: !isSelected };
    const countSelected = countBy(updatedSelected);
    if (!countSelected.true) {
      alert('At least 1 brand must be selected');
      return;
    }
    setSelectedBrands(updatedSelected);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    setActiveTimeRange(e.timeRange ?? TimeRange['90_DAYS']);
    setActiveEndDate(e.endDate);
  };

  return (
    <div className={styles.BlueScoreWidget}>
      {loading && <LoadingWidget widgetType={WidgetType.MetricComparisonV1} />}

      {!loading && data?.brandsSummary ? (
        <>
          <WidgetSubheader
            activeTimeRange={activeTimeRange}
            handleTimeRangeChange={handleTimeRangeChange}
            activeEndDate={activeEndDate}
            onCustomTimeRangeChange={handleApply}
          />

          <BlueScoreWidgetControl
            brandsSummary={data.brandsSummary}
            handleBrandClick={handleBrandClick}
          />

          <div className={styles.GraphWrapper}>
            <BlueScoreWidgetGraph
              brands={data.brands}
              markers={data.markers}
              yDomain={data.yDomain}
            />
            <ChartLegend items={legendItems} />
          </div>

          <ScoreLegend />
        </>
      ) : (
        <p>No Data</p>
      )}
    </div>
  );
};

export default BlueScoreWidget;
