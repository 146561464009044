import { useEffect, useContext, useMemo } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import { useLazyQuery } from '@apollo/client';

import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import cloneDeep from 'lodash/cloneDeep';

import Bluescore from '../../Organisms/MarketIndexBluescore/MarketIndexBluescore';
import Trend from '../../Organisms/MarketIndexTrend/MarketIndexTrend';
import RelativePerformance from '../../Organisms/MarketIndexRelativePerformance/MarketIndexRelativePerformance';
import ScoreLegend from '../../Molecules/ScoreLegend/ScoreLegend';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';

import {
  GET_CALCULATED_SCORES,
  GET_COMPETITORS_BLUESCORE,
} from '../../../api/queries/Pages/MarketIndex';
import {
  prepareScores,
  prepareCompetitorBluescores,
} from '../../../api/transforms/Pages/MarketIndexOverview';

import BNContext from '../../../contexts/BNContext';

import { handleGenericError } from '../../../utils/error';
import { isBetween } from '../../../utils/timeframe';

import { SIZES, POSITIONS } from '../../../constants/props';

import styles from './MarketIndexOverview.module.scss';

const bluescoreDescription = [
  'The overall Blue Score is a composite score of 5 factors indicative of brand health relative to competitors. It’s derived by an always-on process of ingestion, weighting, and calculation of 3,000+ data points.',
];

const trendDescription = [
  'The Blue Score is a benchmark metric designed to provide an all-up view of brand performance that is trackable over time. Use our market event system to add "moment in time" trackers to help annotate your brand’s trendline, such as when a campaign has gone live.',
];

const relativePerformanceDescription = [
  'What is the overall health of your brand?',
  'How is your brand trending against competitors?',
];

export default function MarketIndexOverview() {
  const {
    timeframe,
    competitiveSetID: _competitiveSetId,
    currentSession: sessionKey,
  } = useContext(BNContext);

  const [
    getScores,
    { loading: scoresLoading, error: scoresError, data: scoresResp },
  ] = useLazyQuery(GET_CALCULATED_SCORES, {
    variables: {
      id: _competitiveSetId,
      sessionKey,
    },
  });

  const [
    getCompetitorBluescores,
    {
      loading: competitorBluescoresLoading,
      error: competitorBluescoresError,
      data: fetchedCompetitorBluescores,
    },
  ] = useLazyQuery(GET_COMPETITORS_BLUESCORE, {
    variables: {
      id: _competitiveSetId,
      sessionKey,
    },
  });

  const scores = useMemo(
    () =>
      handleGenericError(
        () => prepareScores(scoresResp),
        'MarketIndexOverview prepareScores failed transform'
      ),
    [scoresResp]
  );

  const filteredScoresByTimeframe = useMemo(() => {
    if (!scores || !timeframe) {
      return;
    }

    const result = cloneDeep(scores);

    Object.keys(result).forEach((factorName) => {
      result[factorName] = result[factorName].filter((point) => {
        if (!point || !point.date) {
          return false;
        }

        return isBetween(point.date, timeframe);
      });
    });
    return result;
  }, [scores, timeframe]);

  const allUpScores = useMemo(() => {
    if (!filteredScoresByTimeframe) {
      return;
    }

    const result = [];

    Object.keys(filteredScoresByTimeframe).forEach((factorName) => {
      const factorScoresOverTime = filteredScoresByTimeframe[factorName];

      if (!factorScoresOverTime) {
        return;
      }

      result.push({
        subfactor: factorName,
        score: factorScoresOverTime[factorScoresOverTime.length - 1]
          ? factorScoresOverTime[factorScoresOverTime.length - 1].value
          : null,
        competitorAverageScore: 100,
        scoresOverTime: factorScoresOverTime,
      });
    });
    return result;
  }, [filteredScoresByTimeframe]);

  const relativePerformanceBluescore = useMemo(() => {
    if (
      !filteredScoresByTimeframe ||
      !filteredScoresByTimeframe.bluescore ||
      !filteredScoresByTimeframe.bluescore.length ||
      !scoresResp ||
      !scoresResp.competitiveSet ||
      !scoresResp.competitiveSet.brand
    ) {
      return;
    }

    return {
      name: scoresResp.competitiveSet.brand.name,
      score:
        filteredScoresByTimeframe.bluescore[
          filteredScoresByTimeframe.bluescore.length - 1
        ].value,
      logoUrl: scoresResp.competitiveSet.brand.logoUrl,
      position: POSITIONS.TOP,
      size: SIZES.LG,
    };
  }, [filteredScoresByTimeframe, scoresResp]);

  const competitorBluescores = useMemo(
    () =>
      handleGenericError(
        () =>
          prepareCompetitorBluescores(fetchedCompetitorBluescores, timeframe),
        'MarketIndexOverview prepareCompetitorBluescores failed transform'
      ),
    [fetchedCompetitorBluescores, timeframe]
  );

  const graphScale = useMemo(() => {
    if (!allUpScores || !allUpScores.length) {
      return;
    }

    const result = {
      yDomain: { min: Number.MAX_SAFE_INTEGER, max: -Number.MAX_SAFE_INTEGER },
    };

    allUpScores.forEach((factor) => {
      const factorMin = minBy(factor.scoresOverTime, 'value')?.value;
      const factorMax = maxBy(factor.scoresOverTime, 'value')?.value;

      result.yDomain.min = Math.min(factorMin, result.yDomain.min);
      result.yDomain.max = Math.max(factorMax, result.yDomain.max);
    });

    return result;
  }, [allUpScores]);

  useEffect(() => {
    if (!_competitiveSetId || !sessionKey) {
      return;
    }

    getScores();
    getCompetitorBluescores();
  }, [_competitiveSetId, sessionKey, getScores, getCompetitorBluescores]);

  useDeepCompareEffectNoCheck(() => {
    if (scoresError) {
      console.error(scoresError); // eslint-disable-line no-console
    }
  }, [scoresError]);

  useDeepCompareEffectNoCheck(() => {
    if (competitorBluescoresError) {
      console.error(competitorBluescoresError); // eslint-disable-line no-console
    }
  }, [competitorBluescoresError]);

  if (scoresError || competitorBluescoresError) {
    return <GenericErrorCopy />;
  }

  return (
    <Grid
      container
      className={classNames(styles.MarketIndexOverview, 'PageContainer')}
    >
      <Grid
        item
        xs={12}
        className={classNames(
          'PageTitleContainer',
          'ContainerWithMobileBorder'
        )}
      >
        <h1 className={styles.Title} id="market-index-title-overview">
          What is your brand’s overall performance against the market?
        </h1>
      </Grid>

      <Grid container spacing={10} className="SectionContainer">
        <Grid item xs={12} md={6} className="ContainerWithMobileBorder">
          <div data-cy="market-index-bluescore" className="ContainerInner">
            <Bluescore
              allUpScores={allUpScores}
              sectionDescription={bluescoreDescription}
              loading={scoresLoading || !allUpScores || !graphScale}
              graphScale={graphScale}
            />
            <div className={`${styles.BlueScoreLegend}`}>
              <ScoreLegend data-cy="score-legend" />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className="ContainerWithMobileBorder">
          <div className={`${styles.MarketIndexTrendContainer} ContainerInner`}>
            <Trend
              sectionDescription={trendDescription}
              customerScoresOverTime={
                allUpScores && allUpScores[0]
                  ? allUpScores[0].scoresOverTime
                  : null
              }
              loading={scoresLoading || !allUpScores || !graphScale}
              graphScale={graphScale}
              data-cy="market-index-trend"
            />
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12} className="ContainerWithMobileBorder">
        <div
          className="ContainerInner"
          data-testid="market-index-relative-performance"
        >
          <RelativePerformance
            sectionDescription={relativePerformanceDescription}
            brandBluescore={relativePerformanceBluescore}
            loading={competitorBluescoresLoading}
            competitorBluescores={competitorBluescores}
          />
        </div>
      </Grid>
    </Grid>
  );
}
