import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useState,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import { useLazyQuery } from '@apollo/client';
import type { WatchQueryFetchPolicy } from '@apollo/client';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ScoreTypeSwitch from '../../SocialMetrics/ScoreTypeSwitch';
import BreakdownTypeToggle from '../../SocialMetrics/BreakdownTypeToggle';
import ScoreTile from '../../ScoreDisplays/ScoreTile/ScoreTile';
import BluescoreTimelineChart, {
  CustomerScore,
} from '../../Charts/BluescoreTimelineChart';
import ImpactRating from '../../Atoms/ImpactRating/ImpactRating';
import MetricUpIcon from '../../../assets/icons/Metrics';

import { getDayJsStartDate } from '../../../utils/timeframe';
import {
  ScoreType,
  BreakdownType,
  SocialMetric,
  MetricFormatType,
  SocialChannelKey,
} from '../../../interfaces/metric';
import {
  SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST,
  SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST,
  SOCIAL_METRIC_RATE_METRIC_WHITELIST,
  SOCIAL_CHANNEL_BY_ID,
} from '../../../constants/social-metrics';

import {
  GET_SCORE_DRIVER_RAW_SCORES,
  GET_SOCIAL_METRICS_BY_CHANNEL,
} from '../../../api/queries/Organisms/SubfactorCard';

import BNContext from '../../../contexts/BNContext';

import styles from './SubfactorCard.module.scss';
import { FeatureFlag } from '../../../utils/featureFlags';

export interface SocialChannel {
  name: string;
  logoComponent: FunctionComponent;
}
export interface SubfactorCardProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  title: string;
  metricId: string;
  chosenBrandKey?: string;
  score: number;
  delta?: number;
  scoreOverTime?: CustomerScore[];
  rating?: number;
  insight: string[];
  onExploreClick?: ({ scoreType }: { scoreType: ScoreType }) => void;
  domainOverride?: {
    yDomain: {
      min: number;
      max: number;
    };
  };

  socialMetrics?: SocialMetric[];

  /**
   * Selected score type for the subfactor card upon initial render.
   */
  defaultScoreType?: ScoreType;

  /**
   * Selected breakdown type for the subfactor card upon initial render.
   */
  defaultBreakdownType?: BreakdownType;
}

const SubfactorCard: FunctionComponent<SubfactorCardProps> = ({
  className,
  title,
  metricId,
  chosenBrandKey = '',
  score,
  scoreOverTime,
  delta,
  insight,
  onExploreClick,
  rating,
  domainOverride,
  defaultScoreType = ScoreType.Indexed,
  defaultBreakdownType = BreakdownType.Aggregate,
  socialMetrics,
  ...props
}) => {
  const { competitiveSetID, timeframe, heroBrandKey } = useContext(BNContext);
  const flags = useFlags();

  const [scoreType, setScoreType] = useState<ScoreType>(defaultScoreType);
  const [breakdownType, setBreakdownType] =
    useState<BreakdownType>(defaultBreakdownType);
  const [queryBrandKey, setQueryBrandKey] = useState<string>(chosenBrandKey);

  useEffect(() => {
    if (!chosenBrandKey?.length && heroBrandKey) {
      setQueryBrandKey(heroBrandKey);
    }
  }, [chosenBrandKey, heroBrandKey]);

  const queryOptions = {
    variables: {
      input: {
        competitiveSetId: competitiveSetID,
        brandKey: queryBrandKey,
        metricId,
        start: getDayJsStartDate(timeframe).toISOString(),
        end: dayjs(timeframe?.end).toISOString(),
      },
    },
    fetchPolicy: 'no-cache' as WatchQueryFetchPolicy,
  };

  /* * Raw Score Driver Toggle * */
  const [
    getScoreDriverRawScores,
    { data: getScoreDriverRawScoresData, loading: loadingRawScores },
  ] = useLazyQuery(GET_SCORE_DRIVER_RAW_SCORES, queryOptions);

  const currentScoreCard = useMemo(() => {
    const rawScoresData = getScoreDriverRawScoresData?.getScoreDriverRawScores;

    const rawScoresCard = {
      chartData: rawScoresData?.chart ? rawScoresData.chart : scoreOverTime,
      tileScore: rawScoresData?.tile?.score
        ? round(rawScoresData?.tile?.score, 3)
        : null,
      tileDelta: getTileDelta(rawScoresData?.chart),
      chartYDomainOverride: undefined,
      description: rawScoresData?.description,
    };

    const indexedScoreCard = {
      chartData: scoreOverTime,
      tileScore: score,
      tileDelta: delta,
      chartYDomainOverride: domainOverride?.yDomain,
      description: null,
    };

    return scoreType === ScoreType.Raw ? rawScoresCard : indexedScoreCard;
  }, [
    delta,
    domainOverride,
    getScoreDriverRawScoresData,
    score,
    scoreOverTime,
    scoreType,
  ]);

  const showRawScoreToggle = SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(
    metricId?.toUpperCase()
  );

  /* * Social Metrics By Channel Toggle * */
  const [
    getSocialMetricsByChannel,
    {
      loading: loadingSocialMetricsByChannel,
      data: getSocialMetricsByChannelData,
    },
  ] = useLazyQuery(GET_SOCIAL_METRICS_BY_CHANNEL, queryOptions);

  useEffect(() => {
    if (breakdownType === BreakdownType.Channel) {
      getSocialMetricsByChannel();
    }
  }, [breakdownType, getSocialMetricsByChannel]);

  const socialMetricsByChannelData: SocialMetric[] =
    getSocialMetricsByChannelData?.getSocialMetricsByChannel || socialMetrics;

  const formattedSocialMetricsByChannelData = socialMetricsByChannelData?.map(
    (channel) => ({
      ...channel,
      tile: {
        ...channel.tile,
        delta: getTileDelta(channel.timeseries),
      },
    })
  );

  const showBreakdownToggle = SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(
    metricId?.toUpperCase()
  );

  const scoreTileFormatType = SOCIAL_METRIC_RATE_METRIC_WHITELIST.includes(
    metricId
  )
    ? MetricFormatType.PercentageSuffix
    : MetricFormatType.Abbreviated;

  const handleScoreTypeSwitch = async (type: ScoreType) => {
    if (type === ScoreType.Indexed) {
      setScoreType(type);

      return;
    }

    try {
      await getScoreDriverRawScores();

      setScoreType(type);
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert(
        'Unable to fetch count. Please try again later or contact customer support.'
      );
    }
  };

  return (
    <Box
      p={4}
      className={classNames(styles.SubfactorCard, className)}
      {...props}
    >
      {(loadingRawScores || loadingSocialMetricsByChannel) && (
        <CircularProgress
          className={styles.CardLoader}
          size={16}
          disableShrink
        />
      )}

      <div className={styles.Header}>
        <h5 className={styles.Title}>{title}</h5>

        {showBreakdownToggle && (
          <BreakdownTypeToggle
            value={breakdownType}
            onTypeChange={setBreakdownType}
          />
        )}
      </div>

      <div className={styles.ContentContainer}>
        {breakdownType === BreakdownType.Aggregate && (
          <div className={styles.AggregateContainer}>
            <Box mr={4}>
              <ScoreTile
                value={currentScoreCard.tileScore}
                delta={currentScoreCard.tileDelta}
                variant={scoreType}
                disableAnimation={showRawScoreToggle || showBreakdownToggle}
                formatType={scoreTileFormatType}
              />
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} md={5}>
                <div className={styles.GraphWrap}>
                  <BluescoreTimelineChart
                    customerScoresOverTime={currentScoreCard.chartData}
                    showReducedXAxis
                    yMaxTicksLimit={3}
                    yDomainOverride={currentScoreCard.chartYDomainOverride}
                    scoreType={scoreType}
                    yAxisBuffer
                    formatType={scoreTileFormatType}
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={7}>
                {scoreType === ScoreType.Indexed ? (
                  insight.map((paragraph) => {
                    if (!paragraph) {
                      return null;
                    }

                    return (
                      <p
                        className={styles.Insight}
                        key={paragraph.substring(0, 4)}
                      >
                        {paragraph}
                      </p>
                    );
                  })
                ) : (
                  <p className={styles.Insight}>
                    {currentScoreCard?.description}
                  </p>
                )}
              </Grid>

              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <div className={styles.LeftJustfiedContainer}>
                  {showRawScoreToggle && (
                    <ScoreTypeSwitch
                      value={scoreType}
                      className={classNames(
                        'hide-from-export',
                        'hide-from-share'
                      )}
                      onTypeChange={handleScoreTypeSwitch}
                    />
                  )}

                  <div className={styles.MetricLinkContainer}>
                    <MetricUpIcon className={styles.Icon} />
                    <span
                      onClick={() => onExploreClick?.({ scoreType })}
                      className={styles.ExploreMetricLink}
                    >
                      Explore this metric »
                    </span>
                  </div>
                </div>

                {!isNil(rating) ? <ImpactRating rating={rating} /> : null}
              </Grid>
            </Grid>
          </div>
        )}

        {breakdownType === BreakdownType.Channel && (
          <div className={styles.ChannelContainer}>
            {formattedSocialMetricsByChannelData?.map((socialMetric) => {
              return (
                <div className={styles.SocialMetricChannel}>
                  <p className={styles.ChannelName}>
                    <span>
                      {SOCIAL_CHANNEL_BY_ID[
                        socialMetric.channel as SocialChannelKey
                      ]?.name ?? socialMetric.channel}
                    </span>
                  </p>

                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={5}>
                      <div className={styles.ColLeft}>
                        <ScoreTile
                          variant={ScoreType.Raw}
                          value={socialMetric.tile.score}
                          delta={socialMetric.tile.delta}
                          tooltip={socialMetric.tile.tooltip}
                          className={styles.ScoreTile}
                          formatType={scoreTileFormatType}
                        />

                        <div className={styles.ChannelGraphWrap}>
                          <BluescoreTimelineChart
                            customerScoresOverTime={socialMetric.timeseries}
                            showReducedXAxis
                            yMaxTicksLimit={3}
                            scoreType={ScoreType.Raw}
                            formatType={scoreTileFormatType}
                            yAxisBuffer
                          />
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <div className={styles.ColRight}>
                        {
                          SOCIAL_CHANNEL_BY_ID[
                            socialMetric.channel as SocialChannelKey
                          ]?.logoComponent
                        }

                        <p className={styles.ChannelDescription}>
                          {socialMetric.description ??
                            'No metric description available.'}
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            })}

            {flags[FeatureFlag.ViewMetricsSocialMetrics] ? (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="space-between"
              >
                <div className={styles.LeftJustfiedContainer}>
                  {scoreType === ScoreType.Indexed && (
                    <div className={styles.MetricLinkContainer}>
                      <MetricUpIcon className={styles.Icon} />
                      <span
                        onClick={() =>
                          onExploreClick?.({ scoreType: ScoreType.Raw })
                        }
                        className={styles.ExploreMetricLink}
                      >
                        Explore this metric »
                      </span>
                    </div>
                  )}
                </div>

                {!isNil(rating) ? <ImpactRating rating={rating} /> : null}
              </Grid>
            ) : null}

            {!flags[FeatureFlag.ViewMetricsSocialMetrics] && !isNil(rating) ? (
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="flex-end"
              >
                <ImpactRating rating={rating} />
              </Grid>
            ) : null}
          </div>
        )}
      </div>
    </Box>
  );
};

export default SubfactorCard;

function getTileDelta(chartData?: { value: number; date: string }[]) {
  if (!chartData) {
    return null;
  }

  const formattedChartData = chartData.map((data) => ({
    ...data,
    date: new Date(data.date),
  }));

  const sortedChartData = sortBy(formattedChartData, 'date');

  const oldestScore = sortedChartData[sortedChartData.length - 1]?.value;
  const latestScore = sortedChartData[0]?.value;

  if (!oldestScore || !latestScore) {
    return null;
  }

  return oldestScore - latestScore;
}
