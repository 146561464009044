import {
  AnchorHTMLAttributes,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import cn from 'classnames';
import { SingleValue } from 'react-select';
import { find } from 'lodash';
import DynamicPlusIcon from '../../../../assets/icons/DynamicPlusIcon';
import { Brand } from '../../../../hooks/useCompetitiveSet';
import CompareCard from '../CompareCard/CompareCard';
import { DropdownOption } from '../DropdownSelect/DropdownSelect';
import styles from './CompareBrandAssets.module.scss';
import {
  openDefaultCompare,
  findActiveBrands,
  replaceBrand,
  addBrand,
  removeBrand,
  brandKeyIsActive,
} from './helpers';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import { channelOptions } from '../AdvertisingCreativeSection/AdvertisingCreativeSection';
import GreenDropdownSelect from '../GreenDropdownSelect/GreenDropdownSelect';
import {
  sortByOptions,
  SortType,
} from '../../../../constants/creative-asset-grid';

export interface CompareBrandAssetsProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  brands: Brand[];
  handleClose: () => void;
}

const CompareBrandAssets = ({
  brands,
  handleClose,
  ...props
}: CompareBrandAssetsProps) => {
  const minNumCards = 2;
  const [activeBrands, setActiveBrands] = useState<Set<string>>(new Set());
  const [compareCards, setCompareCards] = useState<Brand[]>([]);
  const [channelFilter, setChannelFilter] = useState<string>('0');
  const [selectedSortBy, setSelectedSortBy] = useState<SortType>(SortType.Date);
  const [resetPageIndex, setResetPageIndex] = useState<boolean>(false);

  useEffect(() => {
    setActiveBrands(openDefaultCompare(brands));
  }, [brands]);

  useEffect(() => {
    const cards = findActiveBrands(activeBrands, brands);
    if (!cards) return;
    setCompareCards(cards);
  }, [activeBrands, brands]);

  const onBrandChange = (
    update: DropdownOption | null,
    fromBrandKey: string
  ) => {
    if (!update?.value || update.value === fromBrandKey) return;

    const updatedBrands = replaceBrand(
      update.value as string,
      fromBrandKey,
      activeBrands
    );
    if (!updatedBrands.size) return;
    setActiveBrands(updatedBrands);
  };

  const addCard = useCallback(() => {
    const updatedBrands = addBrand(activeBrands, brands);
    if (!updatedBrands.size) return;
    setActiveBrands(updatedBrands);
  }, [activeBrands, brands]);

  const removeCard = useCallback(
    (cardBrandKey: string) => {
      const updatedBrands = removeBrand(cardBrandKey, activeBrands);
      if (!updatedBrands.size) return;
      setActiveBrands(updatedBrands);
    },
    [activeBrands]
  );

  const handleChannelChange = useCallback(
    (option: SingleValue<{ value: string; label: string }>) => {
      if (option?.value) {
        setResetPageIndex(true);
        setChannelFilter(option.value);
        setTimeout(() => setResetPageIndex(false), 750);
      }
    },
    []
  );

  const handleSortByChange = useCallback(
    (option: SingleValue<{ value: SortType; label: string }>) => {
      if (option !== null) {
        setResetPageIndex(true);
        setSelectedSortBy(option?.value);
        setTimeout(() => setResetPageIndex(false), 750);
      }
    },
    []
  );

  const options = useMemo(() => {
    return brands.map((brand) => {
      return {
        label: brand.name,
        value: brand.brandKey,
        isDisabled: brandKeyIsActive(brand.brandKey, activeBrands),
      };
    });
  }, [activeBrands, brands]);

  const showAddButton = useMemo(() => {
    return activeBrands.size < brands.length;
  }, [activeBrands.size, brands.length]);

  const allowCardClose = useMemo(() => {
    return activeBrands.size > minNumCards;
  }, [activeBrands.size]);

  const selectedChannel = useMemo(() => {
    return find(channelOptions, { value: channelFilter });
  }, [channelFilter]);

  const selectedSortByOption = useMemo(() => {
    return find(sortByOptions, { value: selectedSortBy });
  }, [selectedSortBy]);

  return (
    <div className={cn(styles.CompareBrandsContainer, props?.className)}>
      <div className={styles.HelperBar}>
        <h3 className={styles.BarTitle}>Creatives Compare</h3>
        <section className={styles.CompareFilters}>
          <GreenDropdownSelect
            options={channelOptions}
            onChange={handleChannelChange}
            value={selectedChannel}
            className={styles.Dropdown}
          />

          <GreenDropdownSelect
            options={sortByOptions}
            onChange={handleSortByChange}
            value={selectedSortByOption}
            className={styles.Dropdown}
          />
          <button
            type="button"
            onClick={handleClose}
            className={styles.CloseButton}
          >
            <CloseIcon />
          </button>
        </section>
      </div>
      <div className={styles.CardCarosel}>
        {compareCards.map((brand) => (
          <CompareCard
            key={brand.brandKey}
            brandKey={brand.brandKey}
            brandName={brand.name}
            logoUrl={brand.logoUrl}
            options={options}
            showCloseIcon={allowCardClose}
            channelFilter={channelFilter}
            sortBy={selectedSortBy}
            resetPage={resetPageIndex}
            handleClose={removeCard}
            onDropdownChange={onBrandChange}
            className={styles.Card}
          />
        ))}

        {showAddButton && (
          <button type="button" onClick={addCard} className={styles.Add}>
            <DynamicPlusIcon className={styles.Icon} />
          </button>
        )}
      </div>
    </div>
  );
};

export default CompareBrandAssets;
