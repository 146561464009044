import random from 'lodash/random';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import head from 'lodash/head';

import { isBetween } from '../../../utils/timeframe';

export function prepareSubfactor(data, timeframe) {
  if (
    !data?.competitiveSet?.session?.subfactorsCalc?.[0]?.t4Quarters ||
    !data?.competitiveSet?.session?.subFactor?.name ||
    isNil(data?.competitiveSet?.session?.subFactor?.value)
  ) {
    return;
  }

  const { name } = data.competitiveSet.session.subFactor;

  const scoreOverTime = JSON.parse(
    data.competitiveSet.session.subfactorsCalc[0].t4Quarters
  )
    .filter((factor) => factor.name === name)
    .map((factor) => ({
      date: factor.date,
      name: factor.name.toLowerCase(),
      value: Math.round(factor.value),
    }))
    .filter((f) => isBetween(f.date, timeframe));

  const latestScore = last(scoreOverTime)?.value;
  const oldestScore = head(scoreOverTime)?.value;

  return {
    score: latestScore,
    factorType: name,
    competitorAverageScore: 100,
    delta: latestScore - oldestScore,
    scoreOverTime,
  };
}

export function prepareScoreDrivers(data, timeframe) {
  if (!data?.competitiveSet?.session?.subFactor?.scoreDrivers) {
    return;
  }

  const { scoreDrivers } = data.competitiveSet.session.subFactor;

  return scoreDrivers.map((sd) => {
    const { negativeImpact, impactScore } = JSON.parse(sd.properties);
    const scoreOverTime = JSON.parse(sd.metricLastPeriodScores[0].t4Quarters)
      .map((s) => ({ date: s.date, value: Math.round(s.value) }))
      .filter((s) => isBetween(s.date, timeframe));

    const latestScore = last(scoreOverTime)?.value;
    const oldestScore = head(scoreOverTime)?.value;

    return {
      variableId: sd.variableId,
      score: latestScore,
      title: sd.metricName,
      competitorAverageScore: 100,
      delta: latestScore - oldestScore,
      scoreOverTime,
      insight: [sd.description],
      rating: negativeImpact ? -Math.abs(impactScore) : impactScore,
      actionType: 'quote',
      isCurrency: false,
      onActionIconClick: () => {},
    };
  });
}

export function prepareInsight(data) {
  if (!data?.competitiveSet?.session?.subFactor?.insight?.value) {
    return ['No Insight Available'];
  }

  try {
    let insight = data.competitiveSet.session.subFactor.insight.value;

    if (insight[0] === '[') {
      insight = JSON.parse(insight);
      insight = insight.filter((blankInsight) => /\S/.test(blankInsight));
      return [insight[random(insight.length - 1)]];
    }

    return [insight];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
