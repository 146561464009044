import { useState, useMemo, useEffect, FC } from 'react';
import Modal from '@mui/material/Modal';
import { Button } from '@blueoceanai/react-component-library';

import { SingleValue } from 'react-select';
import find from 'lodash/find';

import { useLazyQuery } from '@apollo/client';

import useBrandOptions from '../../../../hooks/useBrandOptions';
import useCompetitiveSetOptions from '../../../../hooks/useCompetitiveSetOptions';

import { GET_FIRST_AND_LAST_SESSION } from '../../../../api/queries/Pages/CustomizableDashboards';
import { GET_COMPETITORS } from '../../../../api/queries/Pages/MarketIndexCompare';
import DropdownSelect, {
  DropdownOption,
} from '../../Creative/DropdownSelect/DropdownSelect';

import styles from './CompetitiveSetSelector.module.scss';
import { TemplateSelectionData } from '../types';

interface CompetitiveSetSelectorProps {
  handleClose: (isOpen: boolean) => void;
  handleDataChange: (data: TemplateSelectionData) => void;
  handleCreateDefaultDashboard: () => void;
}

const CompetitiveSetSelector: FC<CompetitiveSetSelectorProps> = ({
  handleClose,
  handleDataChange,
  handleCreateDefaultDashboard,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [stepValidation, setStepValidation] = useState({
    selectCompSet: true,
    selectBrand: false,
    createDashboard: false,
  });

  const [inputValues, setInputValues] = useState<TemplateSelectionData>({
    competitiveSetKey: '',
    brandKey: '',
    brandName: '',
    selectedBrandKeys: [],
  });

  const closeModal = () => {
    setInputValues({
      competitiveSetKey: '',
      brandKey: '',
      brandName: '',
      selectedBrandKeys: [],
    });

    setIsOpen(false);
    handleClose(false);
  };

  const handleBack = () => {
    if (inputValues.brandKey) {
      setStepValidation((prev) => ({
        ...prev,
        selectBrand: false,
        createDashboard: false,
        selectCompSet: true,
      }));

      setInputValues((prev) => ({
        ...prev,
        brandKey: '',
        brandName: '',
      }));
    }

    if (!stepValidation.selectBrand) {
      closeModal();
    }
  };

  const handleNext = () => {
    if (inputValues.competitiveSetKey) {
      setStepValidation((prev) => ({
        ...prev,
        selectBrand: true,
        selectCompSet: false,
      }));
    }

    if (inputValues.brandKey && inputValues.competitiveSetKey) {
      setStepValidation((prev) => ({
        ...prev,
        createDashboard: true,
      }));
    }

    if (stepValidation.createDashboard) {
      handleCreateDefaultDashboard();
    }
  };

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );
  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    { fetchPolicy: 'no-cache' }
  );

  const competitiveSetOptions = useCompetitiveSetOptions();
  const brandOptions = useBrandOptions(competitorsResp);

  const selectedCompetitorOption = useMemo(() => {
    if (!inputValues.competitiveSetKey && !competitiveSetOptions.length)
      return null;

    return find(competitiveSetOptions, {
      value: inputValues.competitiveSetKey,
    });
  }, [competitiveSetOptions, inputValues.competitiveSetKey]);

  const selectedBrandOption = useMemo(() => {
    if (!inputValues.brandKey && !brandOptions.length) return null;

    return find(brandOptions, { value: inputValues.brandKey });
  }, [brandOptions, inputValues.brandKey]);

  const handleDropdownChange = (
    updatedOption: SingleValue<DropdownOption>,
    field: string
  ) => {
    if (!updatedOption || !updatedOption?.value) return;
    const nonSelectedBrands = new Set(
      Array.from(brandOptions.map((brand) => brand.value))
    );

    const newInputValues = {
      ...inputValues,
      selectedBrandKeys: Array.from(nonSelectedBrands),
      [field]: updatedOption.value,
    };
    setInputValues(newInputValues);
    handleDataChange(newInputValues);
  };

  useEffect(() => {
    if (
      brandOptions.length &&
      !find(brandOptions, { value: inputValues.brandKey })
    ) {
      const selectedBrands = new Set(
        Array.from(brandOptions.map((brand) => brand.value as string))
      );
      const updatedInputValues = {
        ...inputValues,
        brandKey: brandOptions[0].value,
        brandName: brandOptions[0].label,
        selectedBrandKeys: Array.from(selectedBrands),
      };
      setInputValues(updatedInputValues);
      handleDataChange(updatedInputValues);
    }
  }, [brandOptions, handleDataChange, inputValues]);

  // if no competitiveSet get required sessions
  useEffect(() => {
    if (!selectedCompetitorOption?.value) return;

    getFirstLastSession({ variables: { id: selectedCompetitorOption.value } });
  }, [getFirstLastSession, selectedCompetitorOption]);

  // get competitiveSet with new SessionKey
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
        ?.sessionKey ||
      !selectedCompetitorOption?.value
    ) {
      return;
    }

    const { sessionKey } =
      firstAndLastSessionResp.competitiveSet.firstAndLastSession[1];
    getCompetitors({
      variables: { id: selectedCompetitorOption.value, sessionKey },
    });
  }, [firstAndLastSessionResp, getCompetitors, selectedCompetitorOption]);

  const disableButton =
    (stepValidation.selectCompSet && !inputValues.competitiveSetKey) ||
    (stepValidation.selectBrand && !inputValues.brandKey);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div className={styles.Modal}>
        <div className={styles.ModalHeader}>
          <p>
            {!stepValidation.selectBrand
              ? 'Select Competitive Set'
              : 'Which brand do you want to highlight?'}
          </p>
          <button
            className={styles.closeButton}
            onClick={closeModal}
            type="button"
          >
            X
          </button>
        </div>
        <div className={styles.Content}>
          {!stepValidation.selectBrand ? (
            <div className={styles.Group}>
              <p className={styles.Label}>COMPETITIVE SET</p>
              <DropdownSelect
                className={styles.Dropdown}
                options={competitiveSetOptions}
                onChange={(e) => handleDropdownChange(e, 'competitiveSetKey')}
                value={selectedCompetitorOption}
              />
            </div>
          ) : (
            <div className={styles.Group}>
              <p className={styles.Label}>BRAND</p>
              <DropdownSelect
                className={styles.Dropdown}
                options={brandOptions}
                onChange={(e) => handleDropdownChange(e, 'brandKey')}
                value={selectedBrandOption}
              />
            </div>
          )}
        </div>
        <div className={styles.ModalFooter}>
          <Button
            className={styles.BackButton}
            variant="contained"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            disabled={disableButton}
            className={
              !disableButton ? styles.NextButton : styles.NextButtonDisabled
            }
            variant="contained"
            type="button"
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CompetitiveSetSelector;
