import { GenericIconProps } from './types';

const BookIcon = (props: GenericIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L24 0 24 24 0 24z"
              transform="translate(-1088 -296) translate(1072 280) translate(16 16)"
            />
            <g fill="currentColor">
              <path
                d="M13 .5c1.378 0 2.5 1.121 2.5 2.5v13c0 .276-.224.5-.5.5H3c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5h13c.276 0 .5.224.5.5s-.224.5-.5.5H3C1.622 20.5.5 19.379.5 18V3C.5 1.621 1.622.5 3 .5zm0 1H3c-.827 0-1.5.673-1.5 1.5v13.001c.418-.314.938-.501 1.5-.501h11.5V3c0-.827-.673-1.5-1.5-1.5zm-2 6c.276 0 .5.224.5.5s-.224.5-.5.5H5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1-3c.276 0 .5.224.5.5s-.224.5-.5.5H4c-.276 0-.5-.224-.5-.5s.224-.5.5-.5z"
                transform="translate(-1088 -296) translate(1072 280) translate(16 16) translate(4 2)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BookIcon;
