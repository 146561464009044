import React, { useEffect, useContext, useMemo } from 'react';
import { useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';

import FavoriteStar from '../../Atoms/FavoriteStar/FavoriteStar';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import Dropdown from '../../Molecules/Dropdown/Dropdown';
import MarketIndexFactorsOverview from '../MarketIndexFactorsOverview/MarketIndexFactorsOverview';
import MarketIndexFactorDetail from '../MarketIndexFactorDetail/MarketIndexFactorDetail';
import MarketIndexSubFactor from '../MarketIndexSubFactor/MarketIndexSubFactor';

import { DROPDOWN_VARIANTS } from '../../../constants/props';
import { HARDCODED_METRICS_ID_MAP } from '../../../constants/metrics';
import QUERY_PARAMS from '../../../constants/queryParams';

import BNContext from '../../../contexts/BNContext';

import { GET_FACTORS } from '../../../api/queries/Pages/MarketIndexFactors';
import {
  prepareFactorDropdownOptions,
  prepareSubfactorDropdownOptions,
} from '../../../api/transforms/Pages/MarketIndexFactors';

import useQuery from '../../../hooks/useQuery';
import useRouter from '../../../hooks/useRouter';

import styles from './MarketIndexSubfactors.module.scss';

export default function MarketIndexSubfactors({ className, ...props }) {
  const { updateRoute } = useRouter();

  const {
    competitiveSetID: _competitiveSetId,
    accountKey,
    currentSession: sessionKey,
    metricsFavoriteMap,
    FEATURE_FLAGS,
  } = useContext(BNContext);

  const queryParams = useQuery();

  const factorId = useMemo(
    () => Number(queryParams.get('factorId')),
    [queryParams]
  );
  const subfactorId = useMemo(
    () => Number(queryParams.get('subfactorId')),
    [queryParams]
  );

  const [getFactors, { loading: gettingFactors, error, data }] =
    useLazyQuery(GET_FACTORS);

  useEffect(() => {
    if (!_competitiveSetId || !sessionKey) {
      return;
    }

    const queryOptions = {
      variables: {
        id: _competitiveSetId,
        accountKey,
        sessionKey,
      },
    };
    getFactors(queryOptions);
  }, [getFactors, _competitiveSetId, accountKey, sessionKey]);

  const factorDropdownOptions = useMemo(
    () => prepareFactorDropdownOptions(data),
    [data]
  );

  const subfactorDropdownOptions = useMemo(
    () => prepareSubfactorDropdownOptions(data, factorId),
    [data, factorId]
  );

  const factorById = useMemo(
    () => keyBy(factorDropdownOptions, 'value'),
    [factorDropdownOptions]
  );

  const subfactorById = useMemo(
    () => keyBy(subfactorDropdownOptions, 'value'),
    [subfactorDropdownOptions]
  );

  const metricVariableID = useMemo(() => {
    if (factorId && !subfactorId && factorById) {
      const factor = factorById[factorId];

      if (
        !factor?.label ||
        !HARDCODED_METRICS_ID_MAP[factor.label.toUpperCase()]
      ) {
        return null;
      }

      return HARDCODED_METRICS_ID_MAP[factor.label.toUpperCase()];
    }

    if (factorId && subfactorId && subfactorById) {
      const subfactor = subfactorById[subfactorId];

      if (
        !subfactor?.label ||
        !HARDCODED_METRICS_ID_MAP[subfactor.label.toUpperCase()]
      ) {
        return null;
      }

      return HARDCODED_METRICS_ID_MAP[subfactor.label.toUpperCase()];
    }

    return null;
  }, [factorId, subfactorId, factorById, subfactorById]);

  const loading = useMemo(
    () =>
      gettingFactors ||
      !factorDropdownOptions ||
      !subfactorDropdownOptions ||
      !factorById,
    [
      gettingFactors,
      factorDropdownOptions,
      subfactorDropdownOptions,
      factorById,
    ]
  );

  function goBackFactorRoot() {
    updateRoute({
      pathname: '/market-index/subfactors',
      params: {
        [QUERY_PARAMS.SUBFACTOR_ID]: null,
        [QUERY_PARAMS.FACTOR_ID]: null,
      },
    });
  }

  function goBackFactorDetail() {
    updateRoute({ params: { [QUERY_PARAMS.SUBFACTOR_ID]: null } });
  }

  function handleFactorChange(factor) {
    updateRoute({ params: { [QUERY_PARAMS.FACTOR_ID]: factor.value } });
  }

  function handleSubfactorChange(subfactor) {
    updateRoute({ params: { [QUERY_PARAMS.SUBFACTOR_ID]: subfactor.value } });
  }

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <div
      className={classNames(
        styles.MarketIndexFactors,
        'PageContainer',
        className
      )}
      {...props}
    >
      <div className={styles.Header}>
        {FEATURE_FLAGS.GLOBAL.METRICS_FAVORITE && (
          <div className={styles.BreadCrumbItem}>
            <FavoriteStar
              metricId={metricVariableID}
              favorited={metricsFavoriteMap[metricVariableID]}
            />
          </div>
        )}

        <div className={styles.BreadCrumbItem}>
          <h5
            className={factorId || subfactorId ? styles.Link : null}
            onClick={factorId || subfactorId ? goBackFactorRoot : null}
          >
            Factors{factorId ? <span>:</span> : null}
          </h5>
        </div>

        <div className={styles.BreadCrumbItem} data-testid="factors-dropdown">
          {factorId &&
          factorDropdownOptions &&
          factorDropdownOptions.length &&
          !subfactorId ? (
            <Dropdown
              options={factorDropdownOptions}
              variant={DROPDOWN_VARIANTS.NAKED}
              onChange={handleFactorChange}
              value={factorId}
              data-cy="dropdown-factors"
            />
          ) : null}

          {factorId && subfactorId && factorById[factorId] ? (
            <h5 className={styles.Link} onClick={goBackFactorDetail}>
              {factorById[factorId].label}:
            </h5>
          ) : null}
        </div>

        <div className={styles.BreadCrumbItem}>
          {factorId &&
          subfactorId &&
          subfactorDropdownOptions &&
          subfactorDropdownOptions.length ? (
            <Dropdown
              options={subfactorDropdownOptions}
              variant={DROPDOWN_VARIANTS.NAKED}
              onChange={handleSubfactorChange}
              value={subfactorId}
              data-cy="dropdown-subfactors"
            />
          ) : null}
        </div>
      </div>

      {!factorId && !subfactorId ? (
        <MarketIndexFactorsOverview loading={loading} />
      ) : null}

      {factorId && !subfactorId ? (
        <MarketIndexFactorDetail factorId={factorId} loading={loading} />
      ) : null}

      {subfactorId ? (
        <MarketIndexSubFactor
          factorId={factorId}
          subfactorId={subfactorId}
          loading={loading}
        />
      ) : null}
    </div>
  );
}

MarketIndexSubfactors.propTypes = {
  className: PropTypes.string,
};
