/* eslint-disable camelcase */

import {
  DashboardsMetricScore,
  defaultLayoutMetricsCollection,
  FlowKey,
  WidgetSettingsConfig,
} from '../interfaces/widget-settings';
import {
  ComparisonType,
  IWidgetConfigOptionsESOV,
  IWidgetConfigOptionsMETRIC_ANALYSIS,
  IWidgetConfigOptionsMETRIC_COMPARISON,
  IWidgetConfigOptionsPACE_ANALYSIS,
  IWidgetConfigOptionsSTACK_RANKING,
  IWidgetConfigOptionsSUMMARY_TRENDS,
  ScoreType,
  TimeRange,
  TrendType,
  UpdateWidgetSettings,
  VisualMapsViewType,
  WidgetDataSourceType,
  WidgetType,
} from '../interfaces/dashboard-api';
import { MetricCategory } from '../mocks/data/CustomizableDashboards/metrics';
import { handleConfigInputArrayFromDashboardAPI } from '../Components/Pages/CustomizableDashboards/WidgetSettingsFlows/helper';

interface AdapterInputMethod {
  (args: { apiConfig: UpdateWidgetSettings }): WidgetSettingsConfig;
}

interface AdapterOutputMethod {
  (args: { uiConfig: WidgetSettingsConfig }): UpdateWidgetSettings;
}

export const defaultConfig: WidgetSettingsConfig = {
  // Metric Selection Flows
  [FlowKey.SelectMetric]: {
    id: '',
    name: '',
  },
  [FlowKey.SelectGroup]: {
    metrics: [],
    groupName: '',
    metricGroup: MetricCategory.FunctionalAreas,
  },

  // Metric Analysis Flows
  [FlowKey.SingleMetricSettings]: {
    name: '',
    description: '',
    timeRange: TimeRange.DEFAULT,
    endDate: new Date(),
    brandKey: '',
    competitiveSetKey: '',
  },

  // Metric Collection Flows
  [FlowKey.MetricGroupSettings]: {
    widgetName: '',
    description: '',
    layout: defaultLayoutMetricsCollection,
    competitiveSetKey: '',
    brandKey: '',
    timeRange: TimeRange.DEFAULT,
    endDate: new Date(),
  },

  // ESOV Flows
  [FlowKey.ESOVSettings]: {
    widgetName: '',
    description: '',
    competitiveSetKey: '',
    brandKey: '',
    timeRange: TimeRange.DEFAULT,
    chartType: '',
  },

  // Pace Analysis Flows
  [FlowKey.PaceAnalysisSettings]: {
    name: '',
    description: '',
    timeRange: TimeRange.DEFAULT,
    endDate: new Date(),
    competitiveSetKey: '',
    brandKey: '',
    comparisonType: ComparisonType.LEADERS,
    scoreType: `${DashboardsMetricScore.Relative}`,
    rivalBrands: [],
  },

  // Stack Ranking Flows
  [FlowKey.StackRankingSettings]: {
    name: '',
    description: '',
    timeRange: TimeRange.DEFAULT,
    endDate: new Date(),
    competitiveSetKey: '',
    brandKey: '',
    scoreType: `${DashboardsMetricScore.Relative}`,
    competitorBrands: [],
  },

  // Metric Comparison Flows
  [FlowKey.MetricComparisonSettings]: {
    widgetName: '',
    description: '',
    competitiveSetKey: '',
    brandKey: '',
    timeRange: TimeRange.DEFAULT,
    endDate: new Date(),
    brands: [],
    scoreType: `${DashboardsMetricScore.Relative}`,
  },

  // Stack Ranking Flows
  [FlowKey.SummaryTrendsSettings]: {
    name: '',
    description: '',
    timeRange: TimeRange.DEFAULT,
    endDate: new Date(),
    competitiveSetKey: '',
    brandKey: '',
    trendType: TrendType.ALL,
  },

  // Visual Maps Flows
  [FlowKey.VisualMapsSettings]: {
    name: '',
    description: '',
    timeRange: TimeRange.DEFAULT,
    endDate: new Date(),
    competitiveSetKey: '',
    competitorBrands: [],
    viewType: VisualMapsViewType.HEATMAP, // default to HEATMAP per design
    metricGroup: MetricCategory.FunctionalAreas, // only 1 option per design
    scoreType: ScoreType.INDEXED, // only 1 option per design
  },
};

export const adaptersByWidgetType: Record<
  WidgetType,
  { input: AdapterInputMethod; output: AdapterOutputMethod }
> = {
  [WidgetType.MetricAnalysisV1]: {
    input: ({ apiConfig }) => {
      const uiConfig: WidgetSettingsConfig = {
        ...defaultConfig,
        [FlowKey.SelectMetric]: {
          id: (apiConfig?.options as IWidgetConfigOptionsMETRIC_ANALYSIS)
            ?.metric?.var_id,
          name: (apiConfig?.options as IWidgetConfigOptionsMETRIC_ANALYSIS)
            ?.metric?.name,
        },
        [FlowKey.SingleMetricSettings]: {
          name: apiConfig?.name ?? '',
          description: apiConfig?.description ?? '',
          timeRange:
            (apiConfig?.data_srcs?.metric?.time_range as TimeRange) ??
            TimeRange.DEFAULT,
          endDate: apiConfig?.data_srcs?.metric?.input?.endDate
            ? new Date(apiConfig?.data_srcs?.metric?.input?.endDate as Date)
            : new Date(),
          brandKey: apiConfig?.data_srcs?.metric?.input?.brandKey ?? '',
          competitiveSetKey:
            apiConfig?.data_srcs?.metric?.input?.competitiveSetKey ?? '',
        },
      };

      return uiConfig;
    },
    output: ({ uiConfig }) => {
      const apiConfig: UpdateWidgetSettings = {
        name: uiConfig[FlowKey.SingleMetricSettings].name,
        description: uiConfig[FlowKey.SingleMetricSettings].description,
        data_srcs: {
          metric: {
            id: 'metric',
            type_id: WidgetDataSourceType.BN_GQL,
            time_range: uiConfig[FlowKey.SingleMetricSettings]?.timeRange,
            input: {
              competitiveSetKey:
                uiConfig[FlowKey.SingleMetricSettings].competitiveSetKey,
              brandKey: uiConfig[FlowKey.SingleMetricSettings].brandKey,
              endDate: uiConfig[FlowKey.SingleMetricSettings].endDate,
            },
          },
        },
        options: {
          metric: {
            var_id: uiConfig[FlowKey.SelectMetric]?.id,
            name: uiConfig[FlowKey.SelectMetric]?.name,
          },
        },
      };

      return apiConfig;
    },
  },
  [WidgetType.MetricCollectionV1]: {
    input: ({ apiConfig }) => {
      const metrics = apiConfig?.data_srcs?.metrics?.input?.selected_metrics;

      const uiConfig: WidgetSettingsConfig = {
        ...defaultConfig,
        [FlowKey.SelectGroup]: {
          metrics: handleConfigInputArrayFromDashboardAPI(
            metrics as unknown[]
          ) as { id: string; name: string }[],
          groupName: apiConfig?.name ?? '',
          metricGroup:
            (apiConfig?.options?.metric_group_id as MetricCategory) ??
            MetricCategory.FunctionalAreas,
        },
        [FlowKey.MetricGroupSettings]: {
          widgetName: apiConfig?.name ?? '',
          description: apiConfig?.description ?? '',
          layout: apiConfig?.layout
            ? JSON.parse(apiConfig.layout)
            : defaultLayoutMetricsCollection,
          timeRange:
            (apiConfig?.data_srcs?.metrics?.time_range as TimeRange) ??
            TimeRange.DEFAULT,
          endDate: apiConfig?.data_srcs?.metrics?.input?.endDate
            ? new Date(apiConfig?.data_srcs?.metrics?.input?.endDate as Date)
            : new Date(),
          brandKey: apiConfig?.data_srcs?.metrics?.input?.brandKey ?? '',
          competitiveSetKey:
            apiConfig?.data_srcs?.metrics?.input?.competitiveSetKey ?? '',
        },
      };
      return uiConfig;
    },
    output: ({ uiConfig }) => {
      const half = 2;
      const buffer = 1.75;

      const apiConfig: UpdateWidgetSettings = {
        name: uiConfig[FlowKey.MetricGroupSettings].widgetName,
        description: uiConfig[FlowKey.MetricGroupSettings].description,
        layout: JSON.stringify({
          // Assumes an additional grid unit is needed to fit every two rows.
          // Examples: 2 or 3 rows = 3 grid units; 4 or 5 rows = 4; 6 or 7 rows = 5;
          // ...32 or 33 rows = 18 grid units. At this time, row height is 85.44px.
          h: Math.round(
            uiConfig[FlowKey.SelectGroup].metrics.length / half + buffer
          ),
          w: uiConfig[FlowKey.MetricGroupSettings].layout.w,
          x: uiConfig[FlowKey.MetricGroupSettings].layout.x,
          y: uiConfig[FlowKey.MetricGroupSettings].layout.y,
        }),
        data_srcs: {
          metrics: {
            id: 'metrics',
            type_id: WidgetDataSourceType.BN_GQL,
            time_range: uiConfig[FlowKey.MetricGroupSettings].timeRange,
            input: {
              brandKey: uiConfig[FlowKey.MetricGroupSettings].brandKey,
              competitiveSetKey:
                uiConfig[FlowKey.MetricGroupSettings].competitiveSetKey,
              selected_metrics: uiConfig[FlowKey.SelectGroup].metrics.map(
                ({ id, name }) => ({
                  id,
                  name,
                })
              ),
              endDate: uiConfig[FlowKey.MetricGroupSettings].endDate,
            },
          },
        },
        options: {
          metric_group_id: uiConfig[FlowKey.SelectGroup].metricGroup,
        },
      };
      return apiConfig;
    },
  },
  [WidgetType.ExcessShareOfVoiceV1]: {
    input: ({ apiConfig }) => {
      const uiConfig: WidgetSettingsConfig = {
        ...defaultConfig,
        [FlowKey.ESOVSettings]: {
          widgetName: apiConfig.name ?? '',
          description: apiConfig.description ?? '',
          timeRange:
            (apiConfig?.data_srcs?.esov?.time_range as TimeRange | undefined) ??
            TimeRange.DEFAULT,
          brandKey:
            (apiConfig.data_srcs?.esov?.input?.brandKey as string) ?? '', // TODO: update tbd with actual src when implemented
          competitiveSetKey:
            (apiConfig.data_srcs?.esov?.input?.competitiveSetKey as
              | string
              | undefined) ?? '',
          chartType:
            (apiConfig?.options as IWidgetConfigOptionsESOV)?.view_type_id ??
            '',
        },
      };

      return uiConfig;
    },
    output: ({ uiConfig }) => {
      const apiConfig: UpdateWidgetSettings = {
        name: uiConfig[FlowKey.ESOVSettings].widgetName,
        description: uiConfig[FlowKey.ESOVSettings].description,
        data_srcs: {
          esov: {
            id: 'esov',
            type_id: WidgetDataSourceType.BN_GQL,
            time_range: uiConfig[FlowKey.ESOVSettings].timeRange,
            input: {
              brandKey: uiConfig[FlowKey.ESOVSettings].brandKey,
              competitiveSetKey:
                uiConfig[FlowKey.ESOVSettings].competitiveSetKey,
            },
          },
        },
        options: {
          view_type_id: uiConfig[FlowKey.ESOVSettings].chartType,
        },
      };
      return apiConfig;
    },
  },
  [WidgetType.PaceAnalysisV1]: {
    input: ({ apiConfig }) => {
      const selectedRivalBrands = (
        apiConfig?.options as IWidgetConfigOptionsPACE_ANALYSIS
      )?.selected_brand_keys;

      const uiConfig: WidgetSettingsConfig = {
        ...defaultConfig,
        [FlowKey.SelectMetric]: {
          id:
            (apiConfig?.options as IWidgetConfigOptionsPACE_ANALYSIS)?.metric
              ?.var_id ?? '',
          name:
            (apiConfig?.options as IWidgetConfigOptionsPACE_ANALYSIS)?.metric
              ?.name ?? '',
        },
        [FlowKey.PaceAnalysisSettings]: {
          name: apiConfig.name ?? '',
          description: apiConfig.description ?? '',
          timeRange:
            (apiConfig?.data_srcs?.metric?.time_range as TimeRange) ??
            TimeRange.DEFAULT,
          endDate: apiConfig?.data_srcs?.metrics?.input?.endDate
            ? new Date(apiConfig?.data_srcs?.metrics?.input?.endDate as Date)
            : new Date(),
          brandKey: apiConfig.data_srcs?.metric?.input?.brandKey ?? '',
          competitiveSetKey:
            apiConfig.data_srcs?.metric?.input?.competitiveSetKey ?? '',
          comparisonType:
            ((apiConfig.options as IWidgetConfigOptionsPACE_ANALYSIS)
              ?.comparison_type_id as ComparisonType) ?? ComparisonType.LEADERS,
          scoreType:
            (apiConfig.options as IWidgetConfigOptionsPACE_ANALYSIS)?.metric
              ?.score_type ?? `${DashboardsMetricScore.Relative}`,
          rivalBrands: handleConfigInputArrayFromDashboardAPI(
            selectedRivalBrands as unknown[]
          ) as string[],
        },
      };
      return uiConfig;
    },
    output: ({ uiConfig }) => {
      const apiConfig: UpdateWidgetSettings = {
        name: uiConfig[FlowKey.PaceAnalysisSettings].name,
        description: uiConfig[FlowKey.PaceAnalysisSettings].description,
        data_srcs: {
          metric: {
            id: 'metric',
            type_id: WidgetDataSourceType.BN_GQL,
            time_range: uiConfig[FlowKey.PaceAnalysisSettings].timeRange,
            input: {
              competitiveSetKey:
                uiConfig[FlowKey.PaceAnalysisSettings].competitiveSetKey,
              brandKey: uiConfig[FlowKey.PaceAnalysisSettings].brandKey,
              endDate: uiConfig[FlowKey.PaceAnalysisSettings].endDate,
            },
          },
        },
        options: {
          metric: {
            var_id: uiConfig[FlowKey.SelectMetric].id,
            name: uiConfig[FlowKey.SelectMetric].name,
            score_type: uiConfig[FlowKey.PaceAnalysisSettings].scoreType,
          },
          comparison_type_id:
            uiConfig[FlowKey.PaceAnalysisSettings].comparisonType,
          selected_brand_keys:
            uiConfig[FlowKey.PaceAnalysisSettings].rivalBrands,
        },
      };
      return apiConfig;
    },
  },
  [WidgetType.SummaryTrendsV1]: {
    input: ({ apiConfig }) => {
      const uiConfig: WidgetSettingsConfig = {
        ...defaultConfig,
        [FlowKey.SummaryTrendsSettings]: {
          name: apiConfig.name ?? '',
          description: apiConfig.description ?? '',
          timeRange:
            (apiConfig?.data_srcs?.metric?.time_range as TimeRange) ??
            TimeRange.DEFAULT,
          endDate: apiConfig?.data_srcs?.metric?.input?.endDate
            ? new Date(apiConfig?.data_srcs?.metric?.input?.endDate as Date)
            : new Date(),
          brandKey: apiConfig.data_srcs?.metric?.input?.brandKey ?? '',
          competitiveSetKey:
            apiConfig.data_srcs?.metric?.input?.competitiveSetKey ?? '',
          trendType:
            ((apiConfig.options as IWidgetConfigOptionsSUMMARY_TRENDS)
              ?.trendType as TrendType) ?? TrendType.ALL,
        },
      };
      return uiConfig;
    },
    output: ({ uiConfig }) => {
      const apiConfig: UpdateWidgetSettings = {
        name: uiConfig[FlowKey.SummaryTrendsSettings].name,
        description: uiConfig[FlowKey.SummaryTrendsSettings].description,
        data_srcs: {
          metric: {
            id: 'metric',
            type_id: WidgetDataSourceType.BN_GQL,
            time_range: uiConfig[FlowKey.SummaryTrendsSettings].timeRange,
            input: {
              competitiveSetKey:
                uiConfig[FlowKey.SummaryTrendsSettings].competitiveSetKey,
              brandKey: uiConfig[FlowKey.SummaryTrendsSettings].brandKey,
              endDate: uiConfig[FlowKey.SummaryTrendsSettings].endDate,
            },
          },
        },
        options: {
          trend_type_id: uiConfig[FlowKey.SummaryTrendsSettings].trendType,
        },
      };
      return apiConfig;
    },
  },
  [WidgetType.MetricComparisonV1]: {
    input: ({ apiConfig }) => {
      const selectedBrandKeys = (
        apiConfig?.options as IWidgetConfigOptionsMETRIC_COMPARISON
      ).selected_brand_keys;

      const uiConfig: WidgetSettingsConfig = {
        ...defaultConfig,
        [FlowKey.SelectMetric]: {
          id: (apiConfig?.options as IWidgetConfigOptionsMETRIC_COMPARISON)
            ?.metric?.var_id,
          name: (apiConfig?.options as IWidgetConfigOptionsMETRIC_COMPARISON)
            ?.metric?.name,
        },
        [FlowKey.MetricComparisonSettings]: {
          widgetName: apiConfig?.name ?? '',
          description: apiConfig?.description ?? '',
          timeRange:
            (apiConfig?.data_srcs?.metric?.time_range as TimeRange) ??
            TimeRange.DEFAULT,
          endDate: apiConfig?.data_srcs?.metric?.input?.endDate
            ? new Date(apiConfig?.data_srcs?.metric?.input?.endDate as Date)
            : new Date(),
          brandKey: apiConfig?.data_srcs?.metric?.input?.brandKey ?? '',
          competitiveSetKey:
            apiConfig?.data_srcs?.metric?.input?.competitiveSetKey ?? '',
          brands: handleConfigInputArrayFromDashboardAPI(
            selectedBrandKeys as unknown[]
          ) as string[],
          scoreType:
            (apiConfig.options as IWidgetConfigOptionsMETRIC_COMPARISON)?.metric
              ?.score_type ?? `${DashboardsMetricScore.Relative}`,
        },
      };

      return uiConfig;
    },
    output: ({ uiConfig }) => {
      const apiConfig: UpdateWidgetSettings = {
        name: uiConfig[FlowKey.MetricComparisonSettings].widgetName,
        description: uiConfig[FlowKey.MetricComparisonSettings].description,
        data_srcs: {
          metric: {
            id: 'metric',
            type_id: WidgetDataSourceType.BN_GQL,
            time_range: uiConfig[FlowKey.MetricComparisonSettings]?.timeRange,
            input: {
              competitiveSetKey:
                uiConfig[FlowKey.MetricComparisonSettings].competitiveSetKey,
              brandKey: uiConfig[FlowKey.MetricComparisonSettings].brandKey,
              endDate: uiConfig[FlowKey.MetricComparisonSettings].endDate,
            },
          },
        },
        options: {
          selected_brand_keys:
            uiConfig[FlowKey.MetricComparisonSettings].brands,
          metric: {
            var_id: uiConfig[FlowKey.SelectMetric].id,
            name: uiConfig[FlowKey.SelectMetric].name,
            score_type: uiConfig[FlowKey.MetricComparisonSettings]?.scoreType,
          },
        },
      };

      return apiConfig;
    },
  },
  [WidgetType.StackRankingV1]: {
    input: ({ apiConfig }) => {
      const selectedBrandKeys = (
        apiConfig?.options as IWidgetConfigOptionsSTACK_RANKING
      )?.selected_brand_keys;

      const uiConfig: WidgetSettingsConfig = {
        ...defaultConfig,
        [FlowKey.SelectMetric]: {
          id:
            (apiConfig?.options as IWidgetConfigOptionsSTACK_RANKING)?.metric
              ?.var_id ?? '',
          name:
            (apiConfig?.options as IWidgetConfigOptionsSTACK_RANKING)?.metric
              ?.name ?? '',
        },
        [FlowKey.StackRankingSettings]: {
          name: apiConfig.name ?? '',
          description: apiConfig.description ?? '',
          timeRange:
            (apiConfig?.data_srcs?.metric?.time_range as TimeRange) ??
            TimeRange.DEFAULT,
          endDate: apiConfig?.data_srcs?.metric?.input?.endDate
            ? new Date(apiConfig?.data_srcs?.metric?.input?.endDate as Date)
            : new Date(),
          brandKey: apiConfig.data_srcs?.metric?.input?.brandKey ?? '',
          competitiveSetKey:
            apiConfig.data_srcs?.metric?.input?.competitiveSetKey ?? '',
          scoreType:
            (apiConfig.options as IWidgetConfigOptionsSTACK_RANKING)?.metric
              ?.score_type ?? `${DashboardsMetricScore.Relative}`,
          competitorBrands: handleConfigInputArrayFromDashboardAPI(
            selectedBrandKeys as unknown[]
          ) as string[],
        },
      };
      return uiConfig;
    },
    output: ({ uiConfig }) => {
      const apiConfig: UpdateWidgetSettings = {
        name: uiConfig[FlowKey.StackRankingSettings].name,
        description: uiConfig[FlowKey.StackRankingSettings].description,
        data_srcs: {
          metric: {
            id: 'metric',
            type_id: WidgetDataSourceType.BN_GQL,
            time_range: uiConfig[FlowKey.StackRankingSettings].timeRange,
            input: {
              competitiveSetKey:
                uiConfig[FlowKey.StackRankingSettings].competitiveSetKey,
              brandKey: uiConfig[FlowKey.StackRankingSettings].brandKey,
              endDate: uiConfig[FlowKey.StackRankingSettings].endDate,
            },
          },
        },
        options: {
          metric: {
            var_id: uiConfig[FlowKey.SelectMetric].id,
            name: uiConfig[FlowKey.SelectMetric].name,
            score_type: uiConfig[FlowKey.StackRankingSettings].scoreType,
          },
          selected_brand_keys:
            uiConfig[FlowKey.StackRankingSettings].competitorBrands,
        },
      };
      return apiConfig;
    },
  },
  [WidgetType.VisualMapsV1]: {
    input: ({ apiConfig }) => {
      const selectedBrandKeys = apiConfig?.options?.selected_brand_keys;

      const uiConfig: WidgetSettingsConfig = {
        ...defaultConfig,
        [FlowKey.VisualMapsSettings]: {
          name: apiConfig.name ?? '',
          description: apiConfig.description ?? '',
          timeRange:
            (apiConfig?.data_srcs?.metric?.time_range as TimeRange) ??
            TimeRange.DEFAULT,
          endDate: apiConfig?.data_srcs?.metric?.input?.endDate
            ? new Date(apiConfig?.data_srcs?.metric?.input?.endDate as Date)
            : new Date(),
          competitiveSetKey:
            apiConfig.data_srcs?.metric?.input?.competitiveSetKey ?? '',
          viewType:
            (apiConfig.options?.view_type_id as VisualMapsViewType) ??
            VisualMapsViewType.HEATMAP, // initially set to default
          metricGroup:
            (apiConfig.options?.metric_group_id as MetricCategory) ??
            MetricCategory.FunctionalAreas, // initially set to default
          scoreType:
            (apiConfig.options?.metric_score_type as ScoreType) ??
            ScoreType.INDEXED, // initially set to default
          competitorBrands: handleConfigInputArrayFromDashboardAPI(
            selectedBrandKeys as unknown[]
          ) as string[],
        },
      };
      return uiConfig;
    },
    output: ({ uiConfig }) => {
      const apiConfig: UpdateWidgetSettings = {
        name: uiConfig[FlowKey.VisualMapsSettings].name,
        description: uiConfig[FlowKey.VisualMapsSettings].description,
        data_srcs: {
          metric: {
            id: 'metric',
            type_id: WidgetDataSourceType.BN_GQL,
            time_range: uiConfig[FlowKey.VisualMapsSettings].timeRange,
            input: {
              competitiveSetKey:
                uiConfig[FlowKey.VisualMapsSettings].competitiveSetKey,
              endDate: uiConfig[FlowKey.VisualMapsSettings].endDate,
            },
          },
        },
        options: {
          view_type_id: uiConfig[FlowKey.VisualMapsSettings].viewType,
          metric_group_id: uiConfig[FlowKey.VisualMapsSettings].metricGroup,
          metric_score_type: uiConfig[FlowKey.VisualMapsSettings].scoreType,
          selected_brand_keys:
            uiConfig[FlowKey.VisualMapsSettings].competitorBrands,
        },
      };
      return apiConfig;
    },
  },
};

export function widgetSettingsInputAdapter({
  apiConfig,
  widgetType,
}: {
  apiConfig: UpdateWidgetSettings;
  widgetType: WidgetType;
}): WidgetSettingsConfig {
  return adaptersByWidgetType[widgetType].input({ apiConfig });
}

export function widgetSettingsOutputAdapter({
  uiConfig,
  widgetType,
}: {
  uiConfig: WidgetSettingsConfig;
  widgetType: WidgetType;
}): UpdateWidgetSettings {
  return adaptersByWidgetType[widgetType].output({ uiConfig });
}
