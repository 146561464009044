import { gql } from '@apollo/client';

export const GET_BLUESCORE_HERO_MAIN = gql`
  query getBluescoreHeroMain($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      brand {
        logoUrl
        name
      }
      session(sessionKey: $sessionKey) {
        blueScore {
          value
          delta
        }
        BlueScore: brandLastPeriodScores(associatedTypeId: 5) {
          t4Quarters
        }
      }
    }
  }
`;

export const GET_BLUESCORE_HERO_SUBSET = gql`
  query getBluescoreHeroSubset($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        blueScore {
          factors {
            name
            value
            delta
          }
        }
        Factors: brandLastPeriodScores(associatedTypeId: 1) {
          t4Quarters
        }
      }
    }
  }
`;

export const GET_BLUESCORE_COMPETITOR_MAIN = gql`
  query getBluescoreCompetitorMain(
    $id: ID
    $sessionKey: ID
    $competitorKey: ID
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        competitor(competitorKey: $competitorKey) {
          name
          id
          brandKey
          logoUrl
          blueScore {
            value
            delta
            factors {
              name
              value
              delta
            }
          }
        }
        CompetitorBlueScore: competitorsLastPeriodScores(
          associatedTypeId: 5
          competitorKey: $competitorKey
        ) {
          brand_id
          brandKey
          t4Quarters
        }
      }
    }
  }
`;

export const GET_BLUESCORE_COMPETITOR_SUBSET = gql`
  query getBluescoreCompetitorSubset(
    $id: ID
    $sessionKey: ID
    $competitorKey: ID
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        competitor(competitorKey: $competitorKey) {
          name
          id
          brandKey
          logoUrl
          blueScore {
            value
            delta
            factors {
              name
              value
              delta
            }
          }
        }
        CompetitorFactors: competitorsLastPeriodScores(
          associatedTypeId: 1
          competitorKey: $competitorKey
        ) {
          brand_id
          brandKey
          t4Quarters
        }
      }
    }
  }
`;

export const GET_FACTOR_HERO_MAIN = gql`
  query getFactorHeroMain($id: ID, $sessionKey: ID, $factorId: Int) {
    competitiveSet(id: $id) {
      brand {
        logoUrl
        name
      }
      session(sessionKey: $sessionKey) {
        factor(factorId: $factorId) {
          name
          value
          delta
        }
        Factors: brandLastPeriodScores(associatedTypeId: 1) {
          t4Quarters
        }
      }
    }
  }
`;

export const GET_FACTOR_HERO_SUBSET = gql`
  query getFactorHeroSubset($id: ID, $sessionKey: ID, $factorId: Int) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        id
        factor(factorId: $factorId) {
          subFactors {
            factor_id
            name
            value
            delta
          }
        }
        SubFactors: brandLastPeriodScores(associatedTypeId: 2) {
          t4Quarters
        }
      }
    }
  }
`;

export const GET_FACTOR_COMPETITOR_MAIN = gql`
  query getFactorCompetitorMain(
    $id: ID
    $competitorKey: ID
    $sessionKey: ID
    $factorId: Int
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        competitor(competitorKey: $competitorKey) {
          name
          brandKey
          logoUrl
          factor(factorId: $factorId) {
            name
            value
            delta
          }
        }
        CompetitorFactors: competitorsLastPeriodScores(
          associatedTypeId: 1
          competitorKey: $competitorKey
        ) {
          brand_id
          brandKey
          t4Quarters
        }
      }
    }
  }
`;

export const GET_FACTOR_COMPETITOR_SUBSET = gql`
  query getFactorCompetitorSubset(
    $id: ID
    $competitorKey: ID
    $sessionKey: ID
    $factorId: Int
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        competitor(competitorKey: $competitorKey) {
          factor(factorId: $factorId) {
            subFactors {
              factor_id
              name
              value
              delta
            }
          }
        }
        CompetitorSubFactors: competitorsLastPeriodScores(
          associatedTypeId: 2
          competitorKey: $competitorKey
        ) {
          brand_id
          brandKey
          t4Quarters
        }
      }
    }
  }
`;

export const GET_SUBFACTOR_HERO_MAIN = gql`
  query getSubfactorHeroMain($id: ID, $sessionKey: ID, $subFactorId: Int) {
    competitiveSet(id: $id) {
      brand {
        name
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        subFactor(subFactorId: $subFactorId) {
          name
          value
          delta
        }
        SubFactors: brandLastPeriodScores(associatedTypeId: 2) {
          t4Quarters
        }
      }
    }
  }
`;

export const GET_SUBFACTOR_HERO_SUBSET = gql`
  query getSubfactorHeroSubset($id: ID, $sessionKey: ID, $subFactorId: Int) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        subFactor(subFactorId: $subFactorId) {
          scoreDrivers {
            name: metricName
            value
            metricGroup
            metricCategory
            metricVisualType
            properties
            variableId
            description
            isCurrency
          }
        }
        ScoreDrivers: brandLastPeriodScores(associatedTypeId: 3) {
          t4Quarters
        }
      }
    }
  }
`;

export const GET_SUBFACTOR_COMPETITOR_MAIN = gql`
  query getSubfactorCompetitorMain(
    $id: ID
    $sessionKey: ID
    $subFactorId: Int
    $competitorKey: ID
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        competitor(competitorKey: $competitorKey) {
          name
          id
          brandKey
          logoUrl
          subFactor(subFactorId: $subFactorId) {
            name
            value
            delta
          }
        }
        CompetitorSubFactors: competitorsLastPeriodScores(
          competitorKey: $competitorKey
          associatedTypeId: 2
        ) {
          brand_id
          brandKey
          t4Quarters
        }
      }
    }
  }
`;

export const GET_SUBFACTOR_COMPETITOR_SUBSET = gql`
  query getSubfactorCompetitorSubset(
    $id: ID
    $sessionKey: ID
    $subFactorId: Int
    $competitorKey: ID
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        subFactor(subFactorId: $subFactorId) {
          scoreDrivers {
            name: metricName
            value
            metricGroup
            metricCategory
            metricVisualType
            properties
            variableId
            description
            isCurrency
          }
        }
        competitor(competitorKey: $competitorKey) {
          subFactor(subFactorId: $subFactorId) {
            scoreDrivers: metrics {
              name
              value
              variableId
              isCurrency
              description
            }
          }
        }
        CompetitorScoreDrivers: competitorsLastPeriodScores(
          competitorKey: $competitorKey
          associatedTypeId: 3
        ) {
          brand_id
          brandKey
          t4Quarters
        }
      }
    }
  }
`;

export const GET_COMPETITORS = gql`
  query getCompetitors($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      brand {
        name
        logoUrl
        brandKey
      }
      session(sessionKey: $sessionKey) {
        competitors {
          name
          id
          brandKey
          logoUrl
          blueScore {
            value
          }
        }
      }
    }
  }
`;
