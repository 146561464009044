/* eslint-disable import/prefer-default-export */

export const userMock = {
  name: 'Captain Ahab',
  email: 'captain.ahab@gmail.com',
  picture:
    'https://i.guim.co.uk/img/static/sys-images/Guardian/Pix/pictures/2014/10/30/1414670814763/1956-MOBY-DICK-012.jpg?width=620&quality=85&dpr=1&s=none',
  given_name: 'Captain',
  family_name: 'Ahab',
};
