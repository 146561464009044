import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import PaceWidgetTrendingList from './PaceWidgetTrendingList/PaceWidgetTrendingList';
import PaceWidgetGraph from './PaceWidgetGraph/PaceWidgetGraph';
import DropdownSelect from '../../../Creative/DropdownSelect/DropdownSelect';
import BrandTag from '../../BrandTag/BrandTag';
import DateRangeSelectorDropdown from '../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';

import { formatPaceAnalysisWidgetData } from '../widgetFormatResponse';
import {
  ComparisonType,
  ScoreType,
  TimeRange,
  WidgetType,
} from '../../../../../interfaces/dashboard-api';
import { PaceAnalysisBrand, WidgetMetaResponse } from '../../types';
import { DashboardsMetricScore } from '../../../../../interfaces/widget-settings';
import {
  DateRangeOptions,
  ScoreTypeOptions,
  timeRangeById,
} from '../../WidgetSettingsShared';
import { FeatureFlag } from '../../../../../utils/featureFlags';
import { getMetrics } from '../../../../../mocks/data/CustomizableDashboards/metrics';
import InsightsCard from '../../InsightsCard/InsightsCard';
import { handleConfigInputArrayFromDashboardAPI } from '../../WidgetSettingsFlows/helper';

import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/DataManager';
import { GET_PACE_ANALYSIS_WIDGET_DATA } from '../../../../../api/queries/Pages/CustomizableDashboards';

import styles from './PaceWidget.module.scss';
import LoadingWidget from '../LoadingWidget/LoadingWidget';

const metricById = keyBy(getMetrics, 'id');

export type PaceWidgetProps = {
  config: WidgetMetaResponse;
  globalDateFilter: {
    endDate: Date;
    timeRange: TimeRange | null;
  };
};

const PaceWidget = ({ config, globalDateFilter }: PaceWidgetProps) => {
  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  const activeComparisonType: ComparisonType =
    config?.options?.comparison_type_id ?? ComparisonType.LEADERS;

  const [activeTimeRange, setActiveTimeRange] = useState<TimeRange>(
    config.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
  );
  const [activeEndDate, setActiveEndDate] = useState<Date>(
    config?.data_srcs?.metric?.input?.endDate
      ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
      : new Date()
  );
  const [activeScoreType, setActiveScoreType] = useState<DashboardsMetricScore>(
    config.options?.metric?.score_type ?? DashboardsMetricScore.Relative
  );

  useEffect(() => {
    if (globalDateFilter?.timeRange && globalDateFilter?.endDate) {
      setActiveTimeRange(globalDateFilter.timeRange);
      setActiveEndDate(globalDateFilter.endDate);
    } else {
      setActiveTimeRange(
        config?.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
      );
      setActiveEndDate(
        config?.data_srcs?.metric?.input?.endDate
          ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
          : new Date()
      );
    }
  }, [globalDateFilter, config]);

  const [getPaceAnalysisData, { loading, data }] = useLazyQuery(
    GET_PACE_ANALYSIS_WIDGET_DATA,
    { fetchPolicy: 'no-cache' }
  );

  const [getFirstLastSession, { data: sessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );

  let scoreTypeOptions = ScoreTypeOptions;

  // TODO: remove logic in next 2 lines when social channels are added for PaceWidget in back-end AND front-end
  //   these are the only related social channels metrics that can be selected in PaceAnalysisSettings
  const disabledSocialChannels = ['1A13', '1B18', '1B20', '1C13'];
  const hasRawData =
    metricById[config?.options?.metric?.var_id]?.is_raw_score_available &&
    !disabledSocialChannels.includes(config?.options?.metric?.var_id);
  if (!hasRawData) {
    scoreTypeOptions = scoreTypeOptions.filter(
      (option) => option.value !== DashboardsMetricScore.Raw
    );
  }

  const selectedTimeRangeOption = useMemo(() => {
    return DateRangeOptions.find((option) => option.value === activeTimeRange);
  }, [activeTimeRange]);

  const selectedScoreTypeOption = useMemo(() => {
    return ScoreTypeOptions.find((option) => option.value === activeScoreType);
  }, [activeScoreType]);

  const analysisData = useMemo(() => {
    if (loading || !data) return null;

    return formatPaceAnalysisWidgetData(data);
  }, [data, loading]);

  useEffect(() => {
    const competitiveSetKey =
      config?.data_srcs?.metric?.input?.competitiveSetKey;

    if (!competitiveSetKey) return;

    getFirstLastSession({ variables: { id: competitiveSetKey } });
  }, [config, getFirstLastSession]);

  useEffect(() => {
    const competitiveSetKey =
      config?.data_srcs?.metric?.input?.competitiveSetKey;
    const brandKey = config?.data_srcs?.metric?.input?.brandKey;
    const metric = config?.options?.metric;

    // TODO: get session key based on custom date range
    const latestSessionKey =
      sessionResp?.competitiveSet?.firstAndLastSession?.[1]?.sessionKey;

    if (!competitiveSetKey || !brandKey || !metric || !latestSessionKey) return;

    const selectedBrandKeysRaw = handleConfigInputArrayFromDashboardAPI(
      config?.options?.selected_brand_keys
    ) as string[];

    getPaceAnalysisData({
      variables: {
        id: competitiveSetKey,
        sessionKey: latestSessionKey,
        input: {
          associatedTypeId: metric.var_id,
          associatedMetricName: metricById[metric.var_id].name,
          range: timeRangeById[activeTimeRange].value,
          endDate: activeEndDate,
          selectedBrandKey: brandKey,
          rivalBrandKeys: Object.values(selectedBrandKeysRaw).join(','),
          compSetKey: competitiveSetKey,
        },
      },
    });
  }, [
    activeTimeRange,
    activeEndDate,
    sessionResp,
    config,
    getPaceAnalysisData,
  ]);

  const activePaceData =
    analysisData?.[activeComparisonType]?.[
      activeScoreType as unknown as ScoreType
    ];

  const mainPaceBrand = find(activePaceData?.brands, (brandItem) => {
    return brandItem.brand.key === config?.data_srcs?.metric?.input?.brandKey;
  });

  // Ensure the tile values are sorted from the largest to smallest number
  const sortedActivePaceData = useMemo(() => {
    return activePaceData?.brands?.sort((a, b) => {
      if (a?.tile?.value > b?.tile?.value) return -1;
      if (a?.tile?.value < b?.tile?.value) return 1;
      if (a?.tile?.value === b?.tile?.value) return 0;
      return 0;
    });
  }, [activePaceData]);

  // After sorting, determine the position of the main brand
  const mainBrandPosition = findIndex(sortedActivePaceData, (brandItem) => {
    return brandItem.brand.key === config?.data_srcs?.metric?.input?.brandKey;
  });

  // Add label wording as appropriate based on the logic specified in the design
  // https://www.figma.com/file/CkzUMjtcxrGwXdDRJ4V0w3/Intelligent-Canvas?type=design&node-id=3536-334099&mode=dev
  // No label ever on the main brand & no labels on any brands for RIVALS
  // For NEAREST: 'Ahead' = brand name ahead of main & 'Behind' = brand name trailing main
  // For LEADERS: 'Leader' = 1st in cohort; 'Ahead' = brand name directly ahead of main
  //   If main brand is 1st or 2nd, then use: '2nd place' or '3rd place', accordingly on the other brand names

  const processedPaceData = useMemo(() => {
    const first = 0;
    const second = 1;
    const third = 2;

    if (!sortedActivePaceData) {
      return {} as PaceAnalysisBrand[];
    }

    return sortedActivePaceData.map((brandItem) => {
      let label = '';
      if (!(mainPaceBrand?.brand?.key === brandItem?.brand?.key)) {
        if (activeComparisonType === ComparisonType.NEAREST) {
          label = 'Behind';
          if (
            sortedActivePaceData[first]?.brand?.key === brandItem?.brand?.key ||
            mainBrandPosition === third
          ) {
            label = 'Ahead';
          }
        } else if (activeComparisonType === ComparisonType.LEADERS) {
          label = '2nd place';
          if (
            sortedActivePaceData[first]?.brand?.key === brandItem?.brand?.key
          ) {
            label = 'Leader';
          } else if (mainBrandPosition === third) {
            label = 'Ahead';
          } else if (
            mainBrandPosition === second ||
            sortedActivePaceData[third]?.brand?.key === brandItem?.brand?.key
          ) {
            label = '3rd place';
          }
        }
      }

      return {
        ...brandItem,
        label,
      } as PaceAnalysisBrand;
    });
  }, [
    sortedActivePaceData,
    activeComparisonType,
    mainPaceBrand,
    mainBrandPosition,
  ]);

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    setActiveTimeRange(e.timeRange ?? TimeRange['90_DAYS']);
    setActiveEndDate(e.endDate);
  };

  return (
    <Box className={styles.PaceAnalysisWidget}>
      <div className={styles.OptionsRow}>
        <BrandTag
          name={mainPaceBrand?.brand.name || ''}
          logoUrl={mainPaceBrand?.brand.logoUrl || ''}
        />

        <DropdownSelect
          options={scoreTypeOptions}
          value={selectedScoreTypeOption}
          isDisabled={scoreTypeOptions.length === 1}
          onChange={(option) =>
            setActiveScoreType(option?.value as DashboardsMetricScore)
          }
        />

        {showCustomDateRangeSelector ? (
          <DateRangeSelectorDropdown
            timeRange={activeTimeRange}
            endDate={activeEndDate}
            onApply={handleApply}
          />
        ) : (
          <DropdownSelect
            options={DateRangeOptions}
            value={selectedTimeRangeOption}
            onChange={(option) =>
              setActiveTimeRange(option?.value as TimeRange)
            }
          />
        )}
      </div>

      <div className={styles.ContentContainer}>
        {loading && (
          <div className={styles.Loading}>
            <LoadingWidget widgetType={WidgetType.PaceAnalysisV1} />
          </div>
        )}
        {processedPaceData && sortedActivePaceData ? (
          <div className={styles.PaceContent}>
            <PaceWidgetTrendingList
              activeTimeRange={activeTimeRange}
              brandsData={processedPaceData}
              activeScoreType={activeScoreType}
            />

            <PaceWidgetGraph
              className={styles.PaceGraph}
              brands={sortedActivePaceData}
              timeRange={activeTimeRange}
            />

            <div className={styles.InsightsContainer}>
              <InsightsCard
                insights={(mainPaceBrand as PaceAnalysisBrand)?.insight}
              />
            </div>
          </div>
        ) : (
          <p>No Data</p>
        )}
      </div>
    </Box>
  );
};

export default PaceWidget;
