import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';

export const StyledAppBar = withStyles({
  root: {
    backgroundColor: 'white',
    boxShadow: 'none',
    color: 'black',
    flexDirection: 'row',
    minHeight: '56px',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderBottom: '1px solid rgb(233 237 240);',
  },
})(AppBar);
