import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './NoResultsAlert.module.scss';

export default function NoResultsAlert({
  className = '',
  text = 'No results',
  ...props
}) {
  return (
    <div className={classNames(styles.NoResultsAlert, className)} {...props}>
      {text}
    </div>
  );
}

NoResultsAlert.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};
