import { gql } from '@apollo/client';

export const GET_CREATIVE_ASSET_BY_ID = gql`
  query getCreativeAdModalByAdId($input: GetCreativeDetailsInput) {
    getCreativeDetails(input: $input) {
      seen {
        start
        end
      }
      spendByWeek {
        x
        y
      }
    }
  }
`;

export const GET_CREATIVE_BRAND_OVERVIEW_CARD_BY_BRAND_KEY = gql`
  query getCreativeBrandOverviewCardByBrandKey($input: BrandTimeSeriesInput) {
    getCreativeBrandOverview(input: $input) {
      latestCreatives {
        id
        name
        spend
        impressions
        eCPM
        thumbnailUrl
        type
        assetUrl
      }
      spendByChannelId: spendByChannel
      topNetworks
      topProducts
      overallDiff
    }
  }
`;

export const GET_CREATIVE_BRAND_SCREENSHOTS_BY_BRAND_KEY = gql`
  query getScreenshots($input: GetScreenshotsInput) {
    getScreenshots(input: $input) {
      screenshots {
        assetUrl
        configUrl
        date
        thumbnailUrl
      }
      count
    }
  }
`;

export const GET_CREATIVE_CHANNEL = gql`
  query getCreativeChannel($id: ID, $sessionKey: ID, $endDate: DateType) {
    competitiveSet(id: $id) {
      brand {
        brandKey
        name
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        digitalAdvertisement: creativeChannel(
          endDate: $endDate
          contentType: "advertisement"
        ) {
          content_type
          origin
          meta
          url
          content_date
          video_url
          adSizeId
        }
        web: creativeChannel(endDate: $endDate, contentType: "screenshot") {
          content_type
          origin
          meta
          url
          content_date
          video_url
          adSizeId
        }
        competitors {
          logoUrl
          brandKey
          name
          digitalAdvertisement: creativeChannel(
            endDate: $endDate
            contentType: "advertisement"
          ) {
            content_type
            origin
            meta
            url
            content_date
            video_url
            adSizeId
          }
          web: creativeChannel(endDate: $endDate, contentType: "screenshot") {
            content_type
            origin
            meta
            url
            content_date
            video_url
            adSizeId
          }
        }
      }
    }
  }
`;
