import { useState, useEffect, useMemo } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import findIndex from 'lodash/findIndex';

import NavTabsVertical from '../../Molecules/NavTabsVertical/NavTabsVertical';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import useRouter from '../../../hooks/useRouter';

import styles from './Profile.module.scss';

const PROFILE_PATHS = {
  OVERVIEW: 'overview',
  ALERTS: 'alerts',
  MARKER_EVENTS: 'marker-events',
};

export default function Profile({ className, ...props }) {
  const FEATURE_FLAGS = useFeatureFlags();
  const { updateRoute } = useRouter();

  const TAB_ITEMS = useMemo(() => {
    const result = [];

    if (FEATURE_FLAGS.PROFILE.OVERVIEW.ROOT) {
      result.push({
        value: PROFILE_PATHS.OVERVIEW,
        label: 'Profile',
        datacy: 'profile-menu-item-profile',
      });
    }

    if (FEATURE_FLAGS.PROFILE.ALERTS.ROOT) {
      result.push({
        value: PROFILE_PATHS.ALERTS,
        label: 'Alerts',
        datacy: 'profile-menu-item-alerts',
      });
    }

    if (FEATURE_FLAGS.PROFILE.MARKER_EVENTS.ROOT) {
      result.push({
        value: PROFILE_PATHS.MARKER_EVENTS,
        label: 'My Marker Events',
        datacy: 'profile-menu-item-my-marker-events',
      });
    }

    return result;
  }, [FEATURE_FLAGS]);

  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(
      findIndex(TAB_ITEMS, (tab) => {
        return location.pathname.includes(tab.value);
      })
    );
  }, [location, TAB_ITEMS]);

  function handleTabChange(newPath) {
    if (typeof newPath === 'string') {
      updateRoute({ pathname: `/profile/${newPath}` });
    }
  }

  return (
    <div
      className={classNames(styles.Profile, 'WorkspaceContainer', className)}
      {...props}
    >
      <div className={classNames(styles.Header, 'WorkspaceTitleContainer')}>
        <h1>My Profile</h1>
      </div>

      <div className={classNames(styles.Body, 'PageContainer')}>
        <Grid container spacing={4} className={styles.BodyContainer}>
          <Grid item xs={12} sm={3} md={2}>
            <NavTabsVertical
              className={styles.NavTabs}
              value={currentPage}
              onChange={handleTabChange}
              items={TAB_ITEMS}
            />
          </Grid>

          <Grid item xs={12} sm={9} md={10}>
            <div className={styles.Content}>
              <Outlet />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

Profile.propTypes = {
  className: PropTypes.string,
};
