import {
  ChartColor,
  ChartMultipleBrandTooltipData,
  YAxisFormatter,
} from '../../../interfaces';

export interface CreateBrandListTooltipArgs {
  brands: ChartMultipleBrandTooltipData[];
  styles: {
    [key: string]: string;
  };
  brandColorByBrandKey: {
    [key: string]: ChartColor;
  };
  tooltipContainerEl: Element | null;
  yAxisFormatter?: YAxisFormatter;
}

export default function createBrandListTooltipContent({
  brands,
  styles,
  brandColorByBrandKey,
  tooltipContainerEl,
  yAxisFormatter,
}: CreateBrandListTooltipArgs): Element | null {
  const brandList = document.createElement('div');
  brandList.classList.add(styles.BrandList);

  brands.forEach((brand) => {
    const brandEl = document.createElement('div');
    brandEl.classList.add(styles.Brand);

    const brandLeftEl = document.createElement('div');
    brandLeftEl.classList.add(styles.BrandLeft);

    const brandColorEl = document.createElement('div');
    brandColorEl.classList.add(styles.BrandColor);
    brandColorEl.style.backgroundColor =
      brandColorByBrandKey[brand.brandKey].DEFAULT;

    const brandNameEl = document.createElement('div');
    brandNameEl.classList.add(styles.BrandName);
    const brandNameTextEl = document.createTextNode(brand.brandName);
    brandNameEl.appendChild(brandNameTextEl);

    const brandValueEl = document.createElement('div');
    brandValueEl.classList.add(styles.BrandValue);
    const brandValueTextEl = document.createTextNode(
      String(yAxisFormatter ? yAxisFormatter(brand.value || 0) : brand.value)
    );
    brandValueEl.appendChild(brandValueTextEl);

    brandLeftEl.appendChild(brandColorEl);
    brandLeftEl.appendChild(brandNameEl);

    brandEl.appendChild(brandLeftEl);
    brandEl.appendChild(brandValueEl);

    brandList.appendChild(brandEl);
  });

  // Remove old children
  while (tooltipContainerEl?.firstChild) {
    tooltipContainerEl.firstChild.remove();
  }

  // Add new children
  tooltipContainerEl?.appendChild(brandList);

  return tooltipContainerEl;
}
