import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import find from 'lodash/find';
import { useLazyQuery } from '@apollo/client';
import PageTitle from '../../Molecules/PageTitle/PageTitle';

import BNContext from '../../../contexts/BNContext';
import { GET_COMPETITORS } from '../../../api/queries/Pages/MarketIndexCompare';
import { prepareCompetitorDropdownOptions } from '../../../api/transforms/Pages/MarketIndexCompare';
import { handleGenericError } from '../../../utils/error';
import QUERY_PARAMS from '../../../constants/queryParams';
import useQuery from '../../../hooks/useQuery';

import styles from './CreativeChannels.module.scss';

export interface CreativeChannelsProps
  extends AnchorHTMLAttributes<HTMLDivElement> {}

const ALL_BRANDS_FILTER_OPT_IN = ['/website'];

const showAllBrandsOption = (path: string) => {
  return ALL_BRANDS_FILTER_OPT_IN.some((optInRoot) => path.includes(optInRoot));
};

const CreativeChannels: FunctionComponent<CreativeChannelsProps> = ({
  className,
}) => {
  const location = useLocation();
  const { competitiveSetID, currentSession } = useContext(BNContext);
  const queryParams = useQuery();

  const queryVariableBase = {
    id: competitiveSetID,
    sessionKey: currentSession,
  };

  const [
    getCompetitors,
    {
      loading: loadingCompetitors,
      // error: errorCompetitors,
      data: competitorsResp,
    },
  ] = useLazyQuery(GET_COMPETITORS, {
    variables: {
      ...queryVariableBase,
    },
  });

  useEffect(() => {
    if (!competitorsResp || loadingCompetitors) {
      getCompetitors();
    }
  }, [getCompetitors, competitorsResp, loadingCompetitors]);

  const competitorBrands = useMemo(
    () =>
      handleGenericError(
        () => prepareCompetitorDropdownOptions(competitorsResp),
        'MarketIndexCompare prepareCompetitorDropdownOptions failed transform'
      ),
    [competitorsResp]
  );

  const heroBrand = useMemo(() => {
    return competitorsResp?.competitiveSet?.brand;
  }, [competitorsResp]);

  const compSetBrandOptions = useMemo(() => {
    if (!heroBrand || !competitorBrands?.length) {
      return [];
    }
    const head = showAllBrandsOption(location.pathname)
      ? [
          {
            label: 'All Brands',
            value: 'ALL_BRANDS',
          },
        ]
      : [];
    return head.concat(
      [heroBrand].concat(...competitorBrands).map((brand) => ({
        label: brand.name,
        value: brand.brandKey,
      }))
    );
  }, [heroBrand, competitorBrands, location]);

  const selectedBrand = useMemo(() => {
    return find(compSetBrandOptions, {
      value: queryParams.get(QUERY_PARAMS.CREATIVE_CHANNELS_SELECTED_BRAND_KEY),
    }) as { label: string; value: string };
  }, [compSetBrandOptions, queryParams]);

  return (
    <div
      id="creative-channels-page"
      className={classNames(styles.CreativeChannels, className)}
    >
      <div className={styles.Header}>
        <PageTitle targetId="creative-channels-page" />
      </div>

      <div className={styles.Content}>
        <Outlet
          context={{
            brandKey: selectedBrand?.value,
            competitiveSetId: competitiveSetID,
            brandName: selectedBrand?.label,
          }}
        />
      </div>
    </div>
  );
};

export default CreativeChannels;
