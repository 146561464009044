import { GenericIconProps } from './types';

const CounterClockwiseClockIcon = (props: GenericIconProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9 7.5V12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00002 14.25L12.9 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.7312 9.34686H2.9812V5.59686"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.1687 17.8313C7.32222 18.9857 8.79221 19.7721 10.3927 20.091C11.9933 20.4099 13.6524 20.2469 15.1603 19.6227C16.6682 18.9984 17.957 17.941 18.8639 16.5842C19.7707 15.2273 20.2547 13.632 20.2547 12C20.2547 10.368 19.7707 8.7727 18.8639 7.41585C17.957 6.059 16.6682 5.00158 15.1603 4.37735C13.6524 3.75313 11.9933 3.59014 10.3927 3.90902C8.79221 4.22789 7.32222 5.0143 6.1687 6.16875L2.9812 9.34688"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CounterClockwiseClockIcon;
