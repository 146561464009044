export const SIZES = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const DATE_SELECTOR_OFFSET_TYPES = {
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months',
  QUARTERS: 'quarters',
  YEARS: 'years',
};

export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: null,
};

export const ORIENTATIONS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const TYPEAHEAD_INPUT_VARIANTS = {
  PRIMARY: 'primary',
  NAKED: 'naked',
  DROPDOWN: 'dropdown',
};

export const POSITIONS = {
  TOP_START: 'top-start',
  TOP: 'top',
  TOP_END: 'top-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  LEFT_END: 'left-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  RIGHT_END: 'right-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  BOTTOM_END: 'bottom-end',
};

export const CHART_LEGEND_ICON_VARIANT = {
  SQUARE: 'square',
  DOT_LINE: 'dot-line',
  DASHED_LINE: 'dashed-line',
  AREA: 'area',
  BUBBLE: 'bubble',
};

export const COMPARE_TYPES = {
  BLUESCORE: 'bluescore',
  FACTOR: 'factors',
  SUBFACTOR: 'subfactors',
};

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const DOWNLOAD_TYPES = {
  CSV: 'csv',
  PDF: 'pdf',
  PNG: 'png',
  SVG: 'svg',
  PPT: 'ppt',
};

export const SHARE_TYPES = {
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  EMAIL: 'email',
  SMS: 'sms',
  COPY: 'copy',
};

export const THEMES = {
  OCEAN: 'OCEAN',
  SUNSET: 'SUNSET',
};

export const CHART_TYPES = {
  LINE: 'line',
  AREA: 'area',
  BAR: 'bar',
  PIE: 'pie',
};

export const SPECIAL_CHART_VARIANTS = {
  APPROVAL: 'approval',
  THUMBS: 'thumbs',
  SENTIMENT: 'sentiment',
  UNIQUENESS: 'uniqueness',
};

export const METRIC_GROUPS = {
  DIGITAL: {
    NAME: 'Digital',
    KEY: 'digital',
  },
  BUSINESS: {
    NAME: 'Business',
    KEY: 'business',
  },
  CONTENT: {
    NAME: 'Content',
    KEY: 'content',
  },
};

export const DROPDOWN_VARIANTS = {
  NORMAL: 'normal',
  NAKED: 'naked',
};

export const GENERAL_VARIANTS = {
  ALERT: 'alert',
  SUCCESS: 'success',
  INFO: 'info',
};

export const SHADE_VARIANTS = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const MANAGE_BRAND_CARD_STATES = {
  DATA_PENDING: 'data-pending',
  SETUP_PENDING: 'setup-pending',
  ADD_BRAND: 'add-brand',
  COMPLETE: 'complete',
};

export const ALERT_ITEM_VARIANTS = {
  METRIC_UP: 'METRIC_UP',
  METRIC_DOWN: 'METRIC_DOWN',
  INFO: 'INFO',
};

export const SEVERITY = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const CALENDAR_PICKER_HIGHLIGHT_VARIANTS = {
  SUCCESS: 'success',
  WARNING: 'warning',
};
