import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import SubfactorCard from '../../Molecules/SubfactorCard/SubfactorCard';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';

import useRouter from '../../../hooks/useRouter';

import styles from './SubfactorCalculationBreakdown.module.scss';
import ImpactRating from '../../Atoms/ImpactRating/ImpactRating';

export default function SubfactorCalculationBreakdown({
  title,
  subfactorCardData,
  className,
  loading,
  onCardExploreClick,
  titleId,
  subfactorId,
  subfactorName,
  yDomain,
  ...props
}) {
  const { updateRoute } = useRouter();

  return (
    <Grid
      container
      className={classNames(styles.SubfactorCalculationBreakdown, className)}
      {...props}
      id="subfactor-calculation-breakdown"
    >
      <Grid
        container
        item
        xs={11}
        alignItems="center"
        className="remove-from-export"
      >
        <h2 className={styles.Title} id={titleId}>
          What’s driving your {subfactorName} score?
        </h2>
      </Grid>

      <Grid
        container
        item
        xs={1}
        alignItems="center"
        justifyContent="flex-end"
        className="remove-from-export"
      >
        <DownloadDropdown
          data-testid="subfactor-breakdown-dropdown"
          targetId="subfactor-calculation-breakdown"
          padding={100}
        />
      </Grid>

      <Grid item xs={12} className="remove-from-export">
        <Box mb={7}>
          <p className={styles.Paragraph}>
            The key metrics listed below are the drivers of your current score
            in {subfactorName}. For certain drivers, you&apos;ll see an Index
            score (relative to your competitive set) and count (your
            brand&apos;s isolated values), along with channel-specific
            breakdowns.
          </p>
          <p className={styles.Paragraph}>
            To view other metrics contributing to your BlueScore, explore the{' '}
            <b>
              <span
                className={styles.Link}
                onClick={() => updateRoute({ pathname: '/metrics' })}
              >
                metrics section
              </span>
            </b>
            .
          </p>
        </Box>
      </Grid>

      <Grid item xs={12} className="remove-from-export">
        <Box mb={7} className={styles.ImpactExplanation}>
          <ImpactRating rating={6} />
          <p className={styles.Indented}>
            IMPACT depicts the type (positive or negative) and degree of impact(
            low, medium and high) each metric currently has on the overall{' '}
            {subfactorName} score on a six-dot scale. Learn more about impact in
            our{' '}
            <b>
              <a
                href="https://www.blueocean.ai/faq/what-do-the-impact-dots-mean/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ.
              </a>
            </b>
          </p>
        </Box>
      </Grid>

      {loading ? <ComponentLoader /> : null}

      {!loading ? (
        <Grid container spacing={2}>
          {subfactorCardData.map((subfactorCardItem) => (
            <Grid item xs={12} key={subfactorCardItem.variableId}>
              <SubfactorCard
                data-testid="subfactor-calculator-breakdown-card"
                title={subfactorCardItem.title}
                metricId={subfactorCardItem.variableId}
                score={subfactorCardItem.score}
                scoreOverTime={subfactorCardItem.scoreOverTime}
                competitorAverageScore={
                  subfactorCardItem.competitorAverageScore
                }
                delta={subfactorCardItem.delta}
                insight={subfactorCardItem.insight}
                actionIconType={subfactorCardItem.actionIconType}
                onActionIconClick={subfactorCardItem.onActionIconClick}
                rating={subfactorCardItem.rating}
                isCurrency={subfactorCardItem.isCurrency}
                onExploreClick={({ scoreType }) =>
                  onCardExploreClick({ scoreType, metric: subfactorCardItem })
                }
                domainOverride={{ yDomain }}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Grid>
  );
}

SubfactorCalculationBreakdown.propTypes = {
  /**
   * Section title.
   */
  title: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
  /**
   * Data to fill the subfactor cards.
   */
  subfactorCardData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      metricId: PropTypes.string,
      score: PropTypes.number,
      scoreOverTime: PropTypes.arrayOf(
        PropTypes.shape({ score: PropTypes.number, date: PropTypes.string })
      ),
      competitorAverageScore: PropTypes.number,
      delta: PropTypes.number,
      insight: PropTypes.arrayOf(PropTypes.string),
      actionType: PropTypes.string,
      onActionIconClick: PropTypes.func,
      rating: PropTypes.number,
    })
  ),
  className: PropTypes.string,
  loading: PropTypes.bool,
  onCardExploreClick: PropTypes.func,
  subfactorId: PropTypes.number,
  subfactorName: PropTypes.string,
  yDomain: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
};
