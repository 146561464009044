/* eslint-disable consistent-return */
import dayjs from 'dayjs';
import { WidgetPointData } from '../../../Components/Pages/CustomizableDashboards/types';
import { MARKER_ID } from '../../../Components/Pages/CustomizableDashboards/Widgets/widgetFormatResponse';
import { ChartTooltipContextLine } from '../../../interfaces';
import genericWidgetTooltip from '../genericWidgetTooltip/genericWidgetTooltip';
import styles from './bluescoreWidgetTooltip.module.scss';

const bluescoreWidgetTooltip = (context: ChartTooltipContextLine) => {
  const { tooltip } = context;
  const tooltipParent = document.createElement('div');
  const dateLabelEl = document.createElement('div');
  dateLabelEl.classList.add(styles.DateLabel);
  const tooltipData = tooltip?.dataPoints?.[0]?.raw as WidgetPointData;
  if (!tooltipData) {
    return;
  }
  const dateLabel = document.createTextNode(
    dayjs(tooltipData.x).format('MMM D')
  );
  dateLabelEl.appendChild(dateLabel);
  tooltipParent.appendChild(dateLabelEl);

  tooltip.dataPoints.forEach((point) => {
    const pointData = point.raw as WidgetPointData;
    const backgroundColor = point.dataset.borderColor as string;
    const labelEl = document.createElement('div');
    labelEl.classList.add(styles.Label);

    if (pointData.id === MARKER_ID) {
      const label = document.createTextNode(pointData.description);
      labelEl.appendChild(label);
    } else {
      const colorSquare = document.createElement('div');
      colorSquare.classList.add(styles.ColorSquare);
      colorSquare.style.backgroundColor = backgroundColor;
      labelEl.appendChild(colorSquare);

      const labelText = document.createTextNode(pointData.description);
      labelEl.appendChild(labelText);
    }

    tooltipParent.appendChild(labelEl);
  });

  return genericWidgetTooltip(context, tooltipParent);
};

export default bluescoreWidgetTooltip;
