import React, { createElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import Box from '@mui/material/Box';

import styles from './ModalDrawer.module.scss';
import { CloseIcon } from '../../../assets/icons';

export default function ModalDrawer({
  className,
  children,
  title,
  icon,
  onClose,
  ...props
}) {
  return (
    <Drawer
      anchor="right"
      className={classNames(styles.ModalDrawer, className)}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: '60%',
          width: '100%',
        },
      }}
      {...props}
    >
      <Box className={styles.Header} p={4}>
        <Box className={styles.Left}>
          {icon ? <Box mr={2}>{createElement(icon)}</Box> : null}
          <h1>{title}</h1>
        </Box>

        <CloseIcon className={styles.Icon} onClick={onClose} />
      </Box>
      <Box className={styles.Body}>{children}</Box>
    </Drawer>
  );
}

ModalDrawer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  icon: PropTypes.func,
  onClose: PropTypes.func,
};
