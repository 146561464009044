import { Outlet } from 'react-router-dom';

import Workspace from '../../Templates/Workspace/Workspace';
import MarketIndexNavigation from '../../Organisms/MarketIndexNavigation/MarketIndexNavigation';

export default function MarketIndexPage() {
  return (
    <div id="market-index-page" className="WorkspaceContainer">
      <div className="TabNavContainer">
        <MarketIndexNavigation shareable />
      </div>

      <Workspace>
        <Outlet />
      </Workspace>
    </div>
  );
}
