import { gql } from '@apollo/client';

export const GET_FACTORS = gql`
  query getFactorsOverview($id: ID, $sessionKey: ID, $accountKey: ID) {
    competitiveSet(id: $id) {
      brand {
        brandKey
        name
        id
        domain
        logoUrl
      }
      session(sessionKey: $sessionKey) {
        id
        brandId
        factorsCalc: brandLastPeriodScores(associatedTypeId: 1) {
          t4Quarters
        }
        blueScore {
          insight {
            value
          }
          recommendations(accountKey: $accountKey) {
            id
            name
            value
            type
            impact_id
            sourceType_id
            isComplete
            practice_areas
          }
        }
        factors {
          id
          name
          value
          delta
          subFactors {
            name
            value
          }
        }
        competitorFactorAverage {
          name
          average
        }
      }
    }
  }
`;

export const GET_SUBFACTORS = gql`
  query getSubFactorData($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        subfactorsCalc: brandLastPeriodScores(associatedTypeId: 2) {
          type
          t4Quarters
        }
        competitorSubFactorAverage {
          factor_id
          name
          average
        }
      }
    }
  }
`;
