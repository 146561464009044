/* eslint-disable react/jsx-no-useless-fragment */
import { useLazyQuery } from '@apollo/client';
import {
  AnchorHTMLAttributes,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import keyBy from 'lodash/keyBy';
import {
  GET_FIRST_AND_LAST_SESSION,
  GET_METRIC_ANALYSIS_DATA,
} from '../../../../../api/queries/Pages/CustomizableDashboards';
import { TimeRange, WidgetType } from '../../../../../interfaces/dashboard-api';
import { WidgetMetaResponse } from '../../types';
import WidgetSubheader from '../../WidgetSubheader/WidgetSubheader';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import {
  formatSingleMetricGraph,
  timeRangeToNumDays,
} from '../widgetFormatResponse';
import { showRawMetricValue } from '../../../../../utils/metric';
import {
  getMetrics,
  RawScoreFormat,
} from '../../../../../mocks/data/CustomizableDashboards/metrics';
import SingleMetricGraph from './SingleMetricGraph/SingleMetricGraph';
import SingleMetricNoData from './SingleMetricNoData/SingleMetricNoData';
import SingleMetricSummary from './SingleMetricSummary/SingleMetricSummary';
import styles from './SingleMetricWidget.module.scss';

export interface SingleMetricWidgetProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  config: WidgetMetaResponse;
  globalDateFilter: {
    endDate: Date;
    timeRange: TimeRange | null;
  };
}

const metricById = keyBy(getMetrics, 'id');

const SingleMetricWidget = ({
  config,
  globalDateFilter,
}: SingleMetricWidgetProps) => {
  const metric = metricById[config?.options?.metric?.var_id];

  const [activeTimeRange, setActiveTimeRange] = useState<TimeRange>(
    config?.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
  );
  const [activeEndDate, setActiveEndDate] = useState<Date>(
    config?.data_srcs?.metric?.input?.endDate
      ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
      : new Date()
  );

  useEffect(() => {
    if (globalDateFilter?.timeRange && globalDateFilter?.endDate) {
      setActiveTimeRange(globalDateFilter.timeRange);
      setActiveEndDate(globalDateFilter.endDate);
    } else {
      setActiveTimeRange(
        config?.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
      );
      setActiveEndDate(
        config?.data_srcs?.metric?.input?.endDate
          ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
          : new Date()
      );
    }
  }, [globalDateFilter, config]);

  const [getMetricAnalysisData, { loading, data }] = useLazyQuery(
    GET_METRIC_ANALYSIS_DATA,
    {
      variables: {},
      fetchPolicy: 'no-cache',
    }
  );

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );

  const graphData = useMemo(() => {
    if (loading || !data) return null;
    if (!data?.competitiveSet?.session) return null;
    const isPercentage = metric?.raw_score_format === RawScoreFormat.Percentage;
    return formatSingleMetricGraph(
      data.competitiveSet.session.metricAnalysisWidget,
      activeTimeRange,
      showRawMetricValue(metric),
      !metric.is_social_channel, // TODO: adjust when social channels contain indexed data
      isPercentage
    );
  }, [activeTimeRange, data, loading, metric]);

  const hasData = useMemo(() => {
    if (!data?.competitiveSet?.session?.metricAnalysisWidget) return false;

    const { metricAnalysisWidget } = data.competitiveSet.session;
    return Boolean(
      metricAnalysisWidget.isolatedScoreTile ||
        metricAnalysisWidget.relativeScoreTile
    );
  }, [data]);

  const brand = useMemo(() => {
    const { selectedBrandName: name, brandLogoUrl: logoUrl } =
      data?.competitiveSet?.session?.metricAnalysisWidget ?? {};
    return { name, logoUrl };
  }, [data]);

  useEffect(() => {
    if (config?.data_srcs?.metric?.input) {
      const { competitiveSetKey } = config.data_srcs.metric.input;

      getFirstLastSession({ variables: { id: competitiveSetKey } });
    }
  }, [config, getFirstLastSession]);

  // TODO: get session key based on custom date range
  const latestSessionKey =
    firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
      ?.sessionKey;

  const fetchData = useCallback(() => {
    if (!config?.data_srcs?.metric?.input) return;

    const { competitiveSetKey, brandKey } = config.data_srcs.metric.input;
    const { metric: selectedMetric } = config.options;
    const range = timeRangeToNumDays(activeTimeRange);

    getMetricAnalysisData({
      variables: {
        id: competitiveSetKey,
        sessionKey: latestSessionKey,
        metricId: selectedMetric?.var_id,
        range,
        selectedBrandKey: brandKey,
        endDate: activeEndDate,
      },
    });
  }, [
    activeTimeRange,
    activeEndDate,
    config,
    getMetricAnalysisData,
    latestSessionKey,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTimeRangeChange = (option: TimeRange) => {
    setActiveTimeRange(option);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    setActiveTimeRange(e.timeRange ?? TimeRange['90_DAYS']);
    setActiveEndDate(e.endDate);
  };

  return (
    <div className={styles.SingleMetricWidget}>
      {hasData ? (
        <>
          <WidgetSubheader
            brandName={brand.name}
            brandLogoUrl={brand.logoUrl}
            activeTimeRange={activeTimeRange}
            handleTimeRangeChange={handleTimeRangeChange}
            activeEndDate={activeEndDate}
            onCustomTimeRangeChange={handleApply}
          />

          <SingleMetricSummary
            rawMetric={config?.options?.metric?.name}
            data={data?.competitiveSet?.session?.metricAnalysisWidget}
            showRaw={
              data?.competitiveSet?.session?.metricAnalysisWidget
                ?.isolatedTimeSeries?.length && showRawMetricValue(metric)
            }
            showIndexed={
              !metric.is_social_channel /* TODO:
               adjust when social channels contain indexed data */
            }
            isPercentage={
              metric?.raw_score_format === RawScoreFormat.Percentage /* TODO:
               handle RawScoreFormat.Percentage metrics consistently across widgets */
            }
          />
          <div className={styles.GraphContainer}>
            {graphData && (
              <SingleMetricGraph
                data={graphData}
                isPercentage={
                  metric?.raw_score_format ===
                  RawScoreFormat.Percentage /* TODO:
                   handle RawScoreFormat.Percentage metrics consistently across widgets */
                }
              />
            )}
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <LoadingWidget widgetType={WidgetType.MetricAnalysisV1} />
          ) : (
            <SingleMetricNoData
              brandName={brand.name}
              brandLogoUrl={brand.logoUrl}
              activeEndDate={activeEndDate}
              activeTimeRange={activeTimeRange}
              handleTimeRangeChange={handleTimeRangeChange}
              onCustomTimeRangeChange={handleApply}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SingleMetricWidget;
