import { FunctionComponent, AnchorHTMLAttributes, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import Grid from '@mui/material/Grid'
import { useScreenshotsContext } from '../CreativeChannelsScreenshots/CreativeChannelsScreenshots';
import Pager from '../Creative/Pager/Pager';
import { CreativeBrandScreenshots } from '../../../interfaces/creative';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import { GET_CREATIVE_BRAND_SCREENSHOTS_BY_BRAND_KEY } from '../../../api/queries/Pages/CreativeChannels';
import CreativeScreenshotCardWrapper from '../CreativeScreenshotCardWrapper/CreativeScreenshotCardWrapper';

import styles from './CreativeChannelsScreenshotsSingleBrand.module.scss';

export interface CreativeChannelsScreenshotsSingleBrandProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
    heroBrandKey?: string;
    isEmbedded?: boolean;
    isCompareView?: boolean;
  }

const CreativeChannelsScreenshotsSingleBrand: FunctionComponent<CreativeChannelsScreenshotsSingleBrandProps> = ({
  heroBrandKey,
  isEmbedded,
  isCompareView,
  className,
  ...props
}) => {
  // ! TODO: time range change should reset pagination (single brand and compare)
  // ! TODO: section-level brand-selector should reset pagination (single brand and compare)
  const pageSize = 6;
  const { brands, getQuery, brandFilterKey } = useScreenshotsContext();
  const [loading, setLoading] = useState<boolean | null>(true);
  const [count, setCount] = useState<number>(0);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [screenshots, setScreenshots] = useState<CreativeBrandScreenshots | null>(null);

  const selectedBrandKey = useMemo(() => {
    if (!isEmbedded && (!brands?.length || !brandFilterKey?.length)) {
      return null;
    }
    if (isEmbedded && (!brands?.length || !heroBrandKey?.length)) {
      return null;
    }
    return isEmbedded ? heroBrandKey : brandFilterKey;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmbedded, brands, brandFilterKey, getQuery]);

  const selectedBrand = useMemo(() => {
    if (!brands?.length || !selectedBrandKey?.length) return null;
    return brands.find(b => b.brandKey === selectedBrandKey) ?? null;
  }, [selectedBrandKey, brands]);

  const [getScreenshots, { data, loading: fetching, called }] =
    useLazyQuery(GET_CREATIVE_BRAND_SCREENSHOTS_BY_BRAND_KEY, {
      variables: getQuery(selectedBrandKey ?? '', pageIndex, pageSize),
    });

  useEffect(() => {
    if (called || !brands?.length || !selectedBrandKey?.length || !getScreenshots || fetching) return;
    getScreenshots();
  }, [getScreenshots, brands, selectedBrandKey, fetching, called]);

  useEffect(() => {
    if (fetching || !data) return;
    setCount(data.getScreenshots?.count ?? 0);
    setLoading(false);
  }, [data, fetching, setLoading, setCount]);

  useEffect(() => {
    if (!data?.getScreenshots?.screenshots) return;
    setScreenshots(data.getScreenshots ?? {}); 
  }, [data, setScreenshots]);

  return (
    <div
      className={classNames(styles.CreativeChannelsScreenshotsSingleBrand, className)}
      {...props}
    >
      <Grid container spacing={2} className={classNames(styles.Container, isEmbedded ? styles.EmbeddedContainer : '')}>
          {Boolean(!count) && loading &&
            <Grid item xs={isEmbedded ? 12 : 6} px={2}>
              <ComponentLoader className={styles.BrandCard} />
            </Grid>
          }
          {!!screenshots?.screenshots && !fetching && !!selectedBrand ? (
            screenshots.screenshots.map(screenshot => {
              return (
                <Grid item xs={isEmbedded ? 12 : 6} px={2}
                  key={`${selectedBrand.brandKey}${screenshot.assetUrl}${screenshot.date}`}
                  className={classNames(styles.BrandCard, isCompareView && !loading && !fetching ? styles.CardBackground : '')}
                >
                  <CreativeScreenshotCardWrapper
                    brand={selectedBrand}
                    screenshot={screenshot}
                    isEmbedded={isEmbedded}
                    isCompareView={isCompareView}
                  />
                </Grid>
              )
            })) : null
          }
          {!count && !loading && !fetching && !! selectedBrand && (
            <Grid item xs={isEmbedded ? 12 : 6} px={2}
              key={`${selectedBrand.brandKey}_EMPTY_STATE`}
              className={styles.BrandCard}
            >
              <CreativeScreenshotCardWrapper
                brand={selectedBrand}
                isEmbedded={isEmbedded} 
                isCompareView={isCompareView}
              />
            </Grid>
          )}
      </Grid>
      {!!count && !!selectedBrand && (<Pager
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={count}
        onPageChange={setPageIndex}
        className={styles.Pager}
      />)}
    </div>
  );
};

export default CreativeChannelsScreenshotsSingleBrand;
