import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import PALETTES, { PaletteType } from '../../../../constants/color-palettes';

import styles from './ChartLegendColorLabel.module.scss';
import { CheckIcon } from '../../../../assets/icons';

export interface ChartLegendColorLabelProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  legendColor: {
    palette: PaletteType;
    index: number;
    checked?: boolean;
    dashed?: boolean;
  };
}

const ChartLegendColorLabel: FunctionComponent<ChartLegendColorLabelProps> = ({
  className,
  legendColor,
  ...props
}) => {
  let borderColor =
    PALETTES?.[legendColor.palette]?.[legendColor.index]?.DEFAULT;

  let backgroundColor = legendColor.checked
    ? PALETTES?.[legendColor.palette]?.[legendColor.index]?.DEFAULT
    : 'white';

  if (legendColor.dashed) {
    borderColor = '#dddddd';
    backgroundColor = '#dddddd';

    if (!legendColor.checked) {
      borderColor =
        PALETTES?.[legendColor.palette]?.[legendColor.index]?.DEFAULT;
      backgroundColor = 'white';
    }
  }

  return (
    <div
      style={{
        borderColor,
        backgroundColor,
      }}
      className={classNames(
        styles.ChartLegendColorLabel,
        legendColor.checked ? styles.Checked : styles.Unchecked,
        legendColor.dashed ? styles.Dashed : null,
        className
      )}
      {...props}
    >
      {legendColor.dashed && legendColor.checked ? (
        <div
          className={styles.DashedLine}
          style={{
            borderTopColor:
              PALETTES?.[legendColor.palette]?.[legendColor.index]?.DEFAULT,
          }}
        />
      ) : (
        <CheckIcon className={styles.CheckIcon} />
      )}
    </div>
  );
};

export default ChartLegendColorLabel;
