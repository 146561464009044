import { DataExplanationCalculation } from '../types';
import { WidgetType } from '../../../../interfaces/dashboard-api';

import ESOVWidgetSOV from '../../../../assets/images/widget-settings/sov-calc.png';
import ESOVWidgetSOM from '../../../../assets/images/widget-settings/som-calc.png';
import ESOVWidgetESOV from '../../../../assets/images/widget-settings/esov-calc.png';

import SingleMetricWidgetRelativePerf from '../../../../assets/images/widget-settings/calc-img-Relative_Performance@2x.png';
import SingleMetricWebsiteVisitors from '../../../../assets/images/widget-settings/calc-img-Raw_Performance@2x.png';
import SingleMetricWidgetISO from '../../../../assets/images/widget-settings/calc-img-Delta-Raw_vs_Cohort_Avg@2x.png';
import SingleMetricISORateChange from '../../../../assets/images/widget-settings/calc-img-Delta-Raw_vs_Prev_Period@2x.png';

import PaceAnalysisRelativePerf from '../../../../assets/images/widget-settings/pace-analysis/calc-img-pace-relative-score.png';
import PaceAnalysisRawPerf from '../../../../assets/images/widget-settings/pace-analysis/calc-img-pace-raw-score.png';
import PaceAnalysisRawPerfCohortAvg from '../../../../assets/images/widget-settings/pace-analysis/calc-img-pace-vs-avg.png';
import PaceAnalysisRawPerfPrevPeriod from '../../../../assets/images/widget-settings/pace-analysis/calc-img-pace-vs-period.png';

import StackRankingRelativePerf from '../../../../assets/images/widget-settings/stack-ranking/calc-img-stack-relative.png';

import MetricComparisonCalcImg from '../../../../assets/images/widget-settings/metric-comparison-calc-img.png';

import VisualMapsScoreExample from '../../../../assets/images/widget-settings/visual-maps/VisualMapsScoreExample.png';
import BlankImage from '../../../../assets/images/widget-settings/visual-maps/BlankImage.png';

enum CalculationType {
  RelativePerformance = 'relative-performance',
  RawPerformancePrevPeriodRaw = 'raw-performance-prev-period-raw',
  RawPerformanceCohortAvg = 'raw-performance-cohort-avg',
  RawPerformancePrevPeriodPerformance = 'raw-performance-prev-period-performance',
}

const metricCalculations: DataExplanationCalculation[] = [
  {
    id: CalculationType.RelativePerformance,
    imgSrc: SingleMetricWidgetRelativePerf,
    text: '**Relative Performance vs Prev. Period** - The indexed score for this metric relative to the rest of the competitive set. The score shift indicates the change over the period selected in the date range: green and up for positive, red and down for negative, grey and right for neutral.',
  },
  {
    id: CalculationType.RawPerformancePrevPeriodRaw,
    imgSrc: SingleMetricWebsiteVisitors,
    text: '**Raw Performance vs Prev. Period** - The raw score for this metric versus the previous date selected. Green and up for positive, red and down for negative, gray and right for neutral.',
  },
  {
    id: CalculationType.RawPerformanceCohortAvg,
    imgSrc: SingleMetricWidgetISO,
    text: '**Raw Performance vs Cohort Avg.** - The brand’s change in raw score for this metric compared to the competitive set’s raw score changes. Green and up for positive, red and down for negative, gray and right for neutral.',
  },
  {
    id: CalculationType.RawPerformancePrevPeriodPerformance,
    imgSrc: SingleMetricISORateChange,
    text: '**Raw Performance vs Prev. Period** - Performance is measured by assessing rate of change of your brand’s raw metric data over a given time period. This number provides insight into your brand’s isolated performance, and does not account for your cohort’s performance. Green and up for positive, red and down for negative, gray and right for neutral.',
  },
];

const paceAnalysisCalcImgMap: Record<CalculationType, string> = {
  [CalculationType.RelativePerformance]: PaceAnalysisRelativePerf,
  [CalculationType.RawPerformancePrevPeriodRaw]: PaceAnalysisRawPerf,
  [CalculationType.RawPerformanceCohortAvg]: PaceAnalysisRawPerfCohortAvg,
  [CalculationType.RawPerformancePrevPeriodPerformance]:
    PaceAnalysisRawPerfPrevPeriod,
};

const calculationsByWidgetType: Record<
  WidgetType,
  DataExplanationCalculation[]
> = {
  [WidgetType.MetricAnalysisV1]: metricCalculations,
  [WidgetType.MetricCollectionV1]: metricCalculations,
  [WidgetType.ExcessShareOfVoiceV1]: [
    {
      id: 'share-of-voice',
      imgSrc: ESOVWidgetSOV,
      text: `**Share of Voice (SoV)** - The brands share of ad and paid search spend. Share of Voice is a crucial metric that quantifies a brand's visibility and presence within a specific market or industry. It represents the proportion of advertising or marketing efforts dedicated to a brand compared to its competitors, providing insights into its relative exposure and impact in the target audience's perception.`,
    },
    {
      id: 'share-of-market',
      imgSrc: ESOVWidgetSOM,
      text: `**Share of Market (SoM)** - The brand’s share of revenue. Share of Market is a fundamental metric that measures a brand's portion of total sales within a specific market or industry. It represents the brand's market share compared to its competitors, providing insights into its relative performance and penetration in capturing customer demand and generating revenue.`,
    },
    {
      id: 'excess-share-of-voice',
      imgSrc: ESOVWidgetESOV,
      text: `**Excess Share of Voice (ESoV)** - Excess Share of Voice (ESoV) is a valuable metric derived from the comparison between a brand's Share of Voice (SoV) and its Share of Market (SoM). It quantifies the difference between the brand's advertising presence and its actual market share, providing insights into whether the brand is overperforming (ESoV) or underperforming (SoM) in terms of visibility and potential market growth.`,
    },
  ],
  [WidgetType.PaceAnalysisV1]: metricCalculations.map((calculation) => {
    const calcClone = structuredClone(calculation);

    calcClone.imgSrc =
      paceAnalysisCalcImgMap[calculation.id as CalculationType];

    return calcClone;
  }),
  [WidgetType.MetricComparisonV1]: [
    {
      id: CalculationType.RelativePerformance,
      imgSrc: MetricComparisonCalcImg,
      text: '**Relative Performance vs Prev. Period** - The indexed score for this metric relative to the rest of the competitive set. The smaller number in the top right corner indicates the change over the period selected in the date range.',
    },
  ],
  [WidgetType.StackRankingV1]: [
    {
      id: CalculationType.RelativePerformance,
      imgSrc: StackRankingRelativePerf,
      text: '**Relative Performance vs Prev. Period** - The indexed score for this metric relative to the rest of the competitive set. The score shift indicates the change over the period selected in the date range: green and up for positive, red and down for negative, grey and right for neutral.',
    },
  ],
  [WidgetType.SummaryTrendsV1]: metricCalculations,
  [WidgetType.VisualMapsV1]: [
    {
      id: CalculationType.RelativePerformance,
      imgSrc: VisualMapsScoreExample,
      text: '**Relative Performance vs Prev. Period** - The indexed score for this metric relative to the rest of the competitive set. The score shift indicates the change over the period selected in the date range: green and up for positive, red and down for negative, grey and right for neutral.',
    },
    {
      id: CalculationType.RawPerformanceCohortAvg,
      imgSrc: BlankImage,
      text: '**Raw Performance vs Cohort Avg.** - The brand’s change in raw score for this metric compared to the competitive set’s raw score changes. Green and up for positive, red and down for negative, gray and right for neutral.',
    },
    {
      id: CalculationType.RawPerformancePrevPeriodRaw,
      imgSrc: BlankImage,
      text: '**Raw Performance vs Prev. Period** - The raw score for this metric versus the previous date selected. Green and up for positive, red and down for negative, gray and right for neutral.',
    },
  ],
};

export default calculationsByWidgetType;
