import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useId } from 'react';
import keyBy from 'lodash/keyBy';
import ChangeIconLabel from '../../../ChangeIconLabel/ChangeIconLabel';
import { abbreviateNumber } from '../../../../../../utils/number';
import WidgetTooltip from '../../../WidgetTooltip/WidgetTooltip';
import { SummaryMetric } from '../../../types';
import { showRawMetricValue } from '../../../../../../utils/metric';
import { getMetrics } from '../../../../../../mocks/data/CustomizableDashboards/metrics';
import styles from './SummaryTrendsWidgetGrid.module.scss';

type HeaderData = {
  title: string;
  tooltipTitle: string;
  tooltipDescription: string;
};

export enum WidgetGridType {
  TrendingDown = 'Trending Down',
  TrendingUp = 'Trending Up',
}

export interface SummaryTrendsWidgetGridProps {
  data: SummaryMetric[];
  title: WidgetGridType;
}

const metricById = keyBy(getMetrics, 'id');

const SummaryTrendsWidgetGrid = ({
  data,
  title,
}: SummaryTrendsWidgetGridProps) => {
  const id = useId();

  const allRowDescriptions =
    title === WidgetGridType.TrendingUp
      ? 'Gaining position as cohort gains'
      : 'Dropping position as cohort gains';

  const headerData: HeaderData[] = [
    {
      title: 'Relative Performance\nvs Prev. Period',
      tooltipTitle: 'Relative Performance',
      tooltipDescription:
        'The indexed score for this metric relative to the rest of the competitive set. The score shift indicates the' +
        ' change over the period selected in the date range: green and up for positive, red and down for negative,' +
        ' grey and right for neutral.',
    },
    {
      title: `Raw Metric Perf.\nvs Prev. Period`,
      tooltipTitle: 'Raw Metric Performance vs Previous Period',
      tooltipDescription:
        'The raw metric score versus the previous date selected. Green and up for positive, red and down for negative' +
        ', gray and right for neutral.',
    },
    {
      title: 'Raw Metric Rate\nvs Cohort Avg.',
      tooltipTitle: 'Raw Metric Rate vs Cohort Average',
      tooltipDescription:
        'The brand’s change in raw score for this metrics compared to the competitive set’s raw score changes. Green' +
        ' and up for positive, red and down for negative, gray and right for neutral.',
    },
    {
      title: 'Raw Metric Rate\nvs Prev. Period',
      tooltipTitle: 'Raw Metric Rate vs Prev. Period',
      tooltipDescription:
        'Performance is measured by assessing rate of change of your brand’s raw metric data over a given time' +
        ' period. This number provides insight into your brand’s isolated performance, and does not account for your' +
        ' cohort’s performance. Green and up for positive, red and down for negative, gray and right for neutral.',
    },
  ];

  return (
    <TableContainer>
      <Table>
        <colgroup>
          {[...Array(5)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <col key={`${index}_${id}_col`} /> // removing index results in a non-unique key console error
          ))}
        </colgroup>
        <TableHead className={styles.TableHeader}>
          <TableRow>
            <TableCell className={styles.TableCell}>
              <Box
                display="flex"
                justifyContent="start"
                alignItems="center"
                gap="4px"
              >
                {title}
              </Box>
            </TableCell>
            {headerData.map((headerCell) => (
              <TableCell
                key={`${headerCell.title}_${id}`}
                className={styles.TableCell}
                align="center"
              >
                <WidgetTooltip
                  title={headerCell.tooltipTitle}
                  description={headerCell.tooltipDescription}
                  isBaselineTitle
                >
                  <p>{headerCell.title}</p>
                </WidgetTooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={styles.TableBody}>
          {data.map((row) => (
            <TableRow
              className={styles.TableRow}
              key={`${row.variableId}_${id}`}
            >
              <TableCell
                className={styles.TableCell}
                component="th"
                scope="row"
              >
                <WidgetTooltip
                  title={metricById[row.variableId]?.name}
                  description={metricById[row.variableId]?.description_md}
                  placement="right"
                >
                  <div>
                    <p className={styles.Name}>
                      {metricById[row.variableId]?.name}
                    </p>
                    <p className={styles.Description}>{allRowDescriptions}</p>
                  </div>
                </WidgetTooltip>
              </TableCell>
              <TableCell className={styles.TableCell}>
                <Box display="flex" justifyContent="center" gap="5.8px">
                  <p className={styles.Label}>
                    {abbreviateNumber(row.relativeScoreTile, 0)}
                  </p>
                  <ChangeIconLabel
                    metric={row.relativeScoreDelta}
                    suffix="pts"
                    iconSize={14}
                    labelClassName={styles.LabelIcon}
                  />
                </Box>
              </TableCell>
              <TableCell className={styles.TableCell}>
                {showRawMetricValue(metricById[row.variableId]) ? (
                  <Box display="flex" justifyContent="center" gap="5.8px">
                    <p className={styles.Label}>
                      {abbreviateNumber(row.isolatedScoreTile)}
                    </p>
                    <ChangeIconLabel
                      metric={row.isolatedScoreDelta}
                      iconSize={14}
                      labelClassName={styles.LabelIcon}
                    />
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="center" gap="5.8px">
                    <p className={styles.IndexedOnlyLabel}>Indexed Only</p>
                  </Box>
                )}
              </TableCell>
              <TableCell className={styles.TableCell}>
                {showRawMetricValue(metricById[row.variableId]) ? (
                  <Box display="flex" justifyContent="center">
                    <ChangeIconLabel
                      metric={row.isolatedCohortRate}
                      suffix="%"
                      iconSize={20}
                      labelClassName={styles.LabelIcon}
                    />
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <p className={styles.IndexedOnlyLabel}>Indexed Only</p>
                  </Box>
                )}
              </TableCell>
              <TableCell className={styles.TableCell}>
                {showRawMetricValue(metricById[row.variableId]) ? (
                  <Box display="flex" justifyContent="center">
                    <ChangeIconLabel
                      metric={row.isolatedChangeRate}
                      suffix="%"
                      iconSize={20}
                      labelClassName={styles.LabelIcon}
                    />
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <p className={styles.IndexedOnlyLabel}>Indexed Only</p>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SummaryTrendsWidgetGrid;
