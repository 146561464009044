import { gql } from '@apollo/client';

export const GET_MARKERS = gql`
  query getMarkers($id: ID, $accountKey: ID) {
    competitiveSet(id: $id) {
      markers(accountKey: $accountKey) {
        label
        markerEventDate
      }
    }
  }
`;

export const ADD_MARKER = gql`
  mutation addMarker(
    $competitiveSetKey: ID
    $accountKey: ID
    $label: String
    $markerEventDate: DateType
    $isPrivate: Int
    $isAccountLevel: Int
  ) {
    addMarker(
      marker: {
        competitiveSetKey: $competitiveSetKey
        accountKey: $accountKey
        label: $label
        markerEventDate: $markerEventDate
        isPrivate: $isPrivate
        isAccountLevel: $isAccountLevel
      }
    )
  }
`;
