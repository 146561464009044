import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import Paper from '@material-ui/core/Paper';
import ScoreTile from '../../ScoreDisplays/ScoreTile';
import BluescoreTimelineChart from '../../Charts/BluescoreTimelineChart/BluescoreTimelineChart';
import { SUBFACTOR_TYPES } from '../../../constants/factors';

import styles from './FactorsSubfactorCard.module.scss';

export default function FactorsSubfactorCard({
  factorType,
  score,
  delta,
  scoreOverTime,
  onNameClick,
  graphScale = {},
  ...props
}) {
  const FACTOR =
    SUBFACTOR_TYPES[findIndex(SUBFACTOR_TYPES, { KEY: factorType })] || {};

  return (
    <Paper className={styles.FactorCard} {...props}>
      <div className={styles.Header}>
        <h4 data-cy="subfactor-name" onClick={onNameClick}>
          {FACTOR.NAME}
        </h4>
      </div>

      <div className={styles.Body}>
        <div className={styles.Left}>
          <ScoreTile value={score} delta={delta} />
        </div>

        <div className={styles.Right}>
          <div
            className={styles.GraphWrap}
            data-testid="factor-subfactor-card-chart"
          >
            <BluescoreTimelineChart
              customerScoresOverTime={scoreOverTime}
              showReducedXAxis
              yAxis={false}
              yDomainOverride={graphScale.yDomain}
              showTooltips={false}
            />
          </div>
        </div>
      </div>

      <div className={styles.Text}>
        <p>{FACTOR.QUESTION}</p>
      </div>
    </Paper>
  );
}

FactorsSubfactorCard.propTypes = {
  /**
   * Factor key from factors.js constants file.
   */
  factorType: PropTypes.string.isRequired,
  /**
   * Score on ScoreTile.
   */
  score: PropTypes.number.isRequired,
  /**
   * delta on Scoretile.
   */
  delta: PropTypes.number,
  /**
   * Scores over time to be displayed on graph.
   */
  scoreOverTime: PropTypes.arrayOf(
    PropTypes.shape({
      score: PropTypes.number,
      date: PropTypes.string,
    })
  ),
  onNameClick: PropTypes.func,
  graphScale: PropTypes.shape({
    yDomain: PropTypes.shape({}),
  }),
};
