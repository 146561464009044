import { FunctionComponent, AnchorHTMLAttributes, useMemo, useContext, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { ApolloClient, InMemoryCache, useApolloClient } from '@apollo/client';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';

import useQuery from '../../../hooks/useQuery';
import BNContext from '../../../contexts/BNContext';
import useRouter from '../../../hooks/useRouter';
import useCompetitiveSet from '../../../hooks/useCompetitiveSet';
import { getDayJsStartDate } from '../../../utils/timeframe';

import styles from './CreativeChannelsScreenshots.module.scss';
// TODO: to be used for section download if global download
// TODO: comp doesn't work
// import { IconDownload } from '../../../assets/icons';


type ScreenshotQuery = {
  input: {
    brandKey: string;
    start: string;
    end: string;
    pageIndex: number;
    pageSize: number;
  };
};

type GetQuery = (brandKey: string, pageIndex?: number, pageSize?: number) => ScreenshotQuery;

type ContextType = {
  brands: {
    name: string;
    brandKey: string;
    logoUrl?: string;
  }[];
  timeRange: {
    start: string;
    end: string;
  };
  query: ApolloClient<InMemoryCache>['query'];
  getQuery: GetQuery;
  brandFilterKey?: string;
};

export interface CreativeChannelsScreenshotsProps
  extends AnchorHTMLAttributes<HTMLDivElement> {}

const CreativeChannelsScreenshots: FunctionComponent<CreativeChannelsScreenshotsProps> = (
  { className, ...props },
) => {
  const { updateRoute } = useRouter();
  const location = useLocation();
  const { nonSessionTimeframe } = useContext(BNContext);
  const queryParams = useQuery();
  const { brands } = useCompetitiveSet();
  const brandFilterKey = useMemo(() => queryParams.get('cc_brand_key'), [queryParams]);
  const ac = useApolloClient();
  const query = useMemo(() => ac.query.bind(ac), [ac]);

  useEffect(() => {
    // Instead of top-level redirect via index in app,
    // we use an outlet-level redirect to handle index
    // so that we can avoid rerenders, flickers of loading state
    // and refetching data
    if (location.pathname === '/creative/channels/website') {
      updateRoute({
        pathname: '/creative/channels/website/all-brands',
        replace: true,
      });
      return;
    }
    // If comp set has changed while on the single-brand route, we automatically
    // set the brandFilter to ALL_BRANDS and redirect to the all-brands route
    // per product requirements
    if (brandFilterKey === 'ALL_BRANDS' && location.pathname.includes('/brand')) {
      updateRoute({
        pathname: '/creative/channels/website/all-brands',
        replace: true,
      });
    }
  }, [queryParams, brands, updateRoute, location, brandFilterKey]);

  const timeRange = useMemo(() => {
    return {
      start: getDayJsStartDate(nonSessionTimeframe).toISOString(),
      end: dayjs(nonSessionTimeframe?.end).toISOString(),
    };
  }, [nonSessionTimeframe]);

  const getQuery: GetQuery = useCallback((brandKey: string, pageIndex = 0, pageSize = 10) => {
   return {
      input: {
        brandKey,
        ...timeRange,
        pageIndex,
        pageSize,
      },
    };
  }, [timeRange]);

  const isCompareBtnVisible = useCallback(() => !location.pathname.includes('/compare'), [location]);

  const handleCompareBtnClick = useCallback(
    () => {
      updateRoute({
        pathname: `/creative/channels/website/compare`,
        params: {
          redirectTo: location.pathname,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateRoute, location, brandFilterKey, getQuery]
  );

  return (
    <div
      className={classNames(styles.CreativeChannelsScreenshots, className)}
      {...props}
    >
      <section className={styles.TitleRow}>
        <p className={styles.Title}>Website</p>
        {isCompareBtnVisible() ?
          <div className={styles.ActionRow}>
            <button
              className={styles.CompareButton}
              type="button"
              onClick={handleCompareBtnClick}>
              Compare
            </button>
            {/* TODO: Replace with global download comp if possible */}
            {/* <div className={styles.DownloadIcon}>
              <IconDownload onClick={() => 'TODO' } />
            </div> */}
          </div>
          : null
        }
      </section>
      <section className={
        classNames(styles.ScreenshotsOutlet, !isCompareBtnVisible() ? styles.CompareOverlay : '')
      }>
        <Outlet
          context={{
            query,
            brands,
            timeRange,
            getQuery,
            brandFilterKey,
          }} {...props}
        />
      </section>
    </div>
  );
};

export default CreativeChannelsScreenshots;

export function useScreenshotsContext() {
  return useOutletContext<ContextType>();
}