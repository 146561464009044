import {
  ColorMap,
  Competitor,
  ContentKeyword,
} from '../ContentAnalysisKeywordsBrandContent';

const createColorIndex = (count: number, maxCount: number): number => {
  const purple: ColorMap = { value: 3, percentMin: 75 };
  const blue: ColorMap = { value: 2, percentMin: 50 };
  const green: ColorMap = { value: 1, percentMin: 25 };
  const index = (count / maxCount) * 100;

  if (index >= purple.percentMin) return purple.value;
  if (index < purple.percentMin && index >= blue.percentMin) return blue.value;
  if (index < blue.percentMin && index > green.percentMin) return green.value;
  return 0;
};

const getComparativeCount = (count: number, maxCount: number): number => {
  return Math.floor((count * 100) / maxCount);
};

export const getCompetitorMaxKeywordCount = (
  competitor: Competitor
): number => {
  return competitor?.brandContentKeyWords?.[0]?.count ?? 0;
};

export const formatKeywords = (keywords: ContentKeyword[], maxCount = 0) => {
  return keywords.map((keyword) => ({
    ...keyword,
    colorIndex: createColorIndex(keyword.count, maxCount),
    comparativeCount: getComparativeCount(keyword.count, maxCount),
  }));
};
