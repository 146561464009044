import { useDebounce } from '@react-hook/debounce';
import { useLocation } from 'react-router-dom';
import useLeftNavItems from './useLeftNavItems';

import styles from './LeftNav.module.scss';
import LeftNavItem from '../../Molecules/LeftNavItem/LeftNavItem';

const LeftNav = () => {
  const location = useLocation();

  // use Debounce for the slide in/out of the side menu
  const [subNavIsOpen, setSubNavIsOpen] = useDebounce<string | null>(
    null,
    5,
    false
  );
  const navItems = useLeftNavItems();

  return (
    <nav className={styles.LeftNav}>
      <ul>
        {navItems.map((item) => (
          <LeftNavItem
            openSubNav={subNavIsOpen}
            setOpenSubNav={setSubNavIsOpen}
            key={item.name}
            isActive={location.pathname.includes(item.rootPath)}
            {...item}
          />
        ))}
      </ul>
    </nav>
  );
};

export default LeftNav;
