import classNames from 'classnames';
import PropTypes from 'prop-types';

import Flickity from 'react-flickity-component';
import ArchetypeCard from '../ArchetypeCard/ArchetypeCard';

import styles from './ArchetypeCarousel.module.scss';

export default function ArchetypeCarousel({ className, archetypes, ...props }) {
  const flickityOptions = {
    wrapAround: true,
    pageDots: false,
    initialIndex: 1,
    prevNextButtons: false,
    reloadOnUpdate: false,
    cellAlign: 'left',
  };

  return (
    <div className={classNames(styles.ArchetypeCarousel, className)} {...props}>
      {archetypes && archetypes.length ? (
        <Flickity options={flickityOptions}>
          {archetypes.map((archetype) => (
            <ArchetypeCard
              key={archetype.name}
              archetype={archetype}
              className={styles.Card}
            />
          ))}
        </Flickity>
      ) : null}
    </div>
  );
}

ArchetypeCarousel.propTypes = {
  className: PropTypes.string,
  archetypes: PropTypes.arrayOf(
    PropTypes.shape({
      cardImgUrl: PropTypes.string,
      message: PropTypes.string,
      exampleBrands: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          name: PropTypes.string,
          logoUrl: PropTypes.string,
        })
      ),
      name: PropTypes.string,
      voiceTags: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};
