import { AnchorHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import styles from './InsightsCard.module.scss';
import InfoStars from '../../../../assets/icons/InfoStars';

export type InsightsCardProps = AnchorHTMLAttributes<HTMLDivElement> & {
  title?: string;
  recomendations?: string;
  observations?: string;
  insights?: string;
  children?: ReactNode;
};

const InsightsCard = ({
  className,
  recomendations,
  observations,
  insights,
  title,
  children,
  ...props
}: InsightsCardProps) => {
  return (
    <div
      className={classNames(styles.InsightsCardWrapper, className)}
      {...props}
    >
      <InfoStars />
      <div className={styles.InsightsCardContainer}>
        {title && <span className={styles.InsightsCardTitle}>{title}</span>}
        {recomendations && <ReactMarkdown>{recomendations}</ReactMarkdown>}
        {observations && <ReactMarkdown>{observations}</ReactMarkdown>}
        {insights && <ReactMarkdown>{insights}</ReactMarkdown>}
        {children}
      </div>
    </div>
  );
};

export default InsightsCard;
