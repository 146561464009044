import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import TopNav from '../../Organisms/TopNav/TopNav';
import LeftNav from '../../Organisms/LeftNav/LeftNav';
import Footer from '../../Organisms/Footer/Footer';

import styles from './SiteNavigationContainer.module.scss';

export interface SiteNavigationContainerProps
  extends AnchorHTMLAttributes<HTMLDivElement> {}

const SiteNavigationContainer: FunctionComponent<
  SiteNavigationContainerProps
> = ({ className }) => {
  return (
    <div className={classNames(styles.SiteNavigationContainer, className)}>
      <TopNav />
      <LeftNav />

      <div className={styles.Content}>
        <Outlet />
      </div>

      <div className={styles.Footer}>
        <Footer id="footer" />
      </div>
    </div>
  );
};

export default SiteNavigationContainer;
