import classNames from 'classnames';
import DownMetricIcon from '../../../../assets/icons/DownMetricIcon';
import NeutralMetricIcon from '../../../../assets/icons/NeutralMetricIcon';
import UpMetricIcon from '../../../../assets/icons/UpMetricIcon';
import styles from './DifferencePill.module.scss';

export enum PercentDirection {
  Up,
  Down,
  Neutral,
}

export interface DifferencePillProps {
  percent: number;
  iconOnly?: boolean;
  xl?: boolean;
}

const DifferencePill = ({ percent, iconOnly, xl }: DifferencePillProps) => {
  const backgroundClass = {
    [PercentDirection.Neutral]: '',
    [PercentDirection.Up]: styles.UpTrend,
    [PercentDirection.Down]: styles.DownTrend,
  };

  const calculateDirection = (direction: number) => (
    <>
      {direction === PercentDirection.Up && <UpMetricIcon />}
      {direction === PercentDirection.Down && <DownMetricIcon />}
      {direction === PercentDirection.Neutral && <NeutralMetricIcon />}
    </>
  );

  let direction = percent > 0 ? PercentDirection.Up : PercentDirection.Down;
  if (percent === 0) {
    direction = PercentDirection.Neutral;
  }

  const formattedPercent = Math.round(Math.abs(percent)).toLocaleString();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {iconOnly ? (
        <div
          data-cy="difference-pill"
          className={classNames(
            xl ? styles.PillContainerXL : styles.PillContainerSmall,
            backgroundClass[direction]
          )}
        >
          <span
            className={classNames(
              styles.PercentSection,
              backgroundClass[direction]
            )}
          >
            <span className={styles.Icon}>{calculateDirection(direction)}</span>
            <p className={styles.Percent}>{formattedPercent}%</p>
          </span>
          <p className={xl ? styles.PillTextXL : styles.PillTextSmall}>
            vs previous period
          </p>
        </div>
      ) : (
        <div
          className={classNames(
            styles.PillContainer,
            backgroundClass[direction]
          )}
          data-cy="previous-period-diff"
        >
          {calculateDirection(direction)}
          <p className={styles.PillText}>
            {formattedPercent}% vs previous period
          </p>
        </div>
      )}
    </>
  );
};

export default DifferencePill;
