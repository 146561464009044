import { sortBy } from 'lodash';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum USER_ROLES_KEYS {
  ACCOUNT_ADMIN = 1,
  BILLING_ADMIN = 2,
  BRAND_ADMIN = 4,
  BRAND_USER = 5,
  BRAND_GUEST = 6,
  BRAND_VIEWER = 7,
  BLUEOCEAN_ADMIN = 8,
  BLUEOCEAN_BRAND_VIEWER = 9,
}

export interface UserRole {
  id: number;
  name: string;
}

export const USER_ROLES: UserRole[] = [
  {
    id: USER_ROLES_KEYS.ACCOUNT_ADMIN,
    name: 'Account Admin',
  },
  {
    id: USER_ROLES_KEYS.BILLING_ADMIN,
    name: 'Billing/Payment Admin',
  },
  {
    id: USER_ROLES_KEYS.BRAND_ADMIN,
    name: 'Brand Admin',
  },
  {
    id: USER_ROLES_KEYS.BRAND_USER,
    name: 'Brand User',
  },
  {
    id: USER_ROLES_KEYS.BRAND_GUEST,
    name: 'Brand Guest',
  },
  {
    id: USER_ROLES_KEYS.BRAND_VIEWER,
    name: 'Brand Viewer',
  },
  {
    id: USER_ROLES_KEYS.BLUEOCEAN_ADMIN,
    name: 'BlueOcean Admin',
  },
  {
    id: USER_ROLES_KEYS.BLUEOCEAN_BRAND_VIEWER,
    name: 'BlueOcean Brand Viewer',
  },
];

export function getLowerRoles(userRoleId: USER_ROLES_KEYS): UserRole[] {
  return sortBy(
    USER_ROLES.filter((role) => canManageRole(userRoleId, role.id)),
    ['id']
  );
}

export function canManageRole(
  userRoleId: USER_ROLES_KEYS,
  roleToCheck: USER_ROLES_KEYS
): boolean {
  if (!userRoleId) return false;

  // BlueOcean Admins can manage all roles
  if (userRoleId === USER_ROLES_KEYS.BLUEOCEAN_ADMIN) return true;

  // Only Blue Ocean Admins can manage this, and they returned above
  if (roleToCheck === USER_ROLES_KEYS.BLUEOCEAN_BRAND_VIEWER) return false;

  // These roles cannot manage other roles
  if (
    userRoleId === USER_ROLES_KEYS.BRAND_GUEST ||
    userRoleId === USER_ROLES_KEYS.BRAND_VIEWER
  )
    return false;

  // All other roles can manage below
  return userRoleId < roleToCheck;
}
