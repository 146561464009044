import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { FlexibleXYPlot, XAxis, LineSeries, AreaSeries } from 'react-vis';
import BNContext from '../../../contexts/BNContext';

import { getDayJsStartDate } from '../../../utils/timeframe';
import COLORS from '../../../constants/colors';
import useColdstartFiller from '../../../hooks/useColdstartFiller';

import styles from './ChartSentiment.module.scss';

dayjs.extend(duration);

export default function ChartSentiment({
  data,
  type = 'area',
  padLimits = false,
  xAxisLine = false,
}) {
  const { timeframe } = useContext(BNContext);
  const [xDomain, setXDomain] = useState([0, 0]);
  const [yDomain, setYDomain] = useState([0, 0]);
  const [convertedData, setConvertedData] = useState([]);
  const [convertedDataPos, setConvertedDataPos] = useState([]);
  const [convertedDataNeg, setConvertedDataNeg] = useState([]);

  const getAxisLimit = useCallback(() => {
    const result = {
      x: {
        min: null,
        max: null,
      },
      y: {
        min: null,
        max: null,
      },
    };

    const convertedDataTemp = [];
    const convertedPos = [];
    const convertedNeg = [];
    const scoreOverTime = data.map((score) => ({
      date: score.date,
      name: score.name?.toLowerCase(),
      value: score.value,
    }));

    scoreOverTime.forEach((point, index) => {
      const pointXDateFormat = dayjs(point.date).valueOf();

      convertedDataTemp.push({
        x: dayjs(point.date).valueOf(),
        y: point.value - 100,
      });
      if (point.value - 100 > 0) {
        if (index > 0 && scoreOverTime[index - 1].value - 100 <= 0) {
          convertedPos.push({
            x:
              (dayjs(point.date).valueOf() +
                dayjs(scoreOverTime[index - 1].date).valueOf()) /
              2,
            y: 0,
          });
        }
        convertedPos.push({
          x: dayjs(point.date).valueOf(),
          y: point.value - 100,
        });
        convertedNeg.push({ x: dayjs(point.date).valueOf(), y: 0 });
        if (
          index + 1 < scoreOverTime.length &&
          scoreOverTime[index + 1].value - 100 <= 0
        ) {
          convertedPos.push({
            x:
              (dayjs(point.date).valueOf() +
                dayjs(scoreOverTime[index + 1].date).valueOf()) /
              2,
            y: 0,
          });
        }
      } else {
        if (index > 0 && scoreOverTime[index - 1].value - 100 > 0) {
          convertedNeg.push({
            x:
              (dayjs(point.date).valueOf() +
                dayjs(scoreOverTime[index - 1].date).valueOf()) /
              2,
            y: 0,
          });
        }
        convertedPos.push({ x: dayjs(point.date).valueOf(), y: 0 });
        convertedNeg.push({
          x: dayjs(point.date).valueOf(),
          y: point.value - 100,
        });
        if (
          index + 1 < scoreOverTime.length &&
          scoreOverTime[index + 1].value - 100 > 0
        ) {
          convertedNeg.push({
            x:
              (dayjs(point.date).valueOf() +
                dayjs(scoreOverTime[index + 1].date).valueOf()) /
              2,
            y: 0,
          });
        }
      }

      if (isNil(result.x.min)) {
        result.x.min = pointXDateFormat;
      }

      if (isNil(result.x.max)) {
        result.x.max = pointXDateFormat;
      }

      result.x.min =
        pointXDateFormat < result.x.min ? pointXDateFormat : result.x.min;
      result.x.max =
        pointXDateFormat > result.x.max ? pointXDateFormat : result.x.max;

      if (isNil(result.y.min)) {
        result.y.min = point.value;
      }

      if (isNil(result.y.max)) {
        result.y.max = point.value;
      }

      result.y.min = Math.min(result.y.min, point.value);
      result.y.max = Math.max(result.y.max, point.value);
    });

    // setting "slice" size if there's just one data point
    if (convertedDataTemp.length === 1) {
      let sliceStart;
      if (
        dayjs
          .duration(
            dayjs(timeframe.end).diff(
              dayjs(timeframe.end).subtract(timeframe.quantity, timeframe.unit)
            )
          )
          .asDays() < 31
      ) {
        sliceStart = dayjs(convertedDataTemp[0].x).subtract(12, 'h').valueOf();
      } else if (
        dayjs
          .duration(
            dayjs(timeframe.end).diff(
              dayjs(timeframe.end).subtract(timeframe.quantity, timeframe.unit)
            )
          )
          .asDays() < 91
      ) {
        sliceStart = dayjs(convertedDataTemp[0].x).subtract(2, 'd').valueOf();
      } else {
        sliceStart = dayjs(convertedDataTemp[0].x).subtract(10, 'd').valueOf();
      }
      convertedDataTemp.unshift({
        x: sliceStart,
        y: convertedDataTemp[0].y,
      });

      convertedPos.unshift({
        x: sliceStart,
        y: convertedPos[0].y,
      });

      convertedNeg.unshift({
        x: sliceStart,
        y: convertedNeg[0].y,
      });
    }

    setConvertedData(convertedDataTemp);
    setConvertedDataPos(convertedPos);
    setConvertedDataNeg(convertedNeg);

    return result;
  }, [data, timeframe]);

  const setGraphLimit = useCallback(() => {
    const axisLimit = getAxisLimit();

    setXDomain([
      padLimits
        ? dayjs(axisLimit.x.min).subtract(1, 'd').valueOf()
        : dayjs(getDayJsStartDate(timeframe).toDate()),
      padLimits
        ? dayjs(axisLimit.x.max).add(1, 'd').valueOf()
        : axisLimit.x.max,
    ]);

    let range = 0;
    if (Math.abs(axisLimit.y.max - 100) > Math.abs(axisLimit.y.min - 100)) {
      range = Math.abs(axisLimit.y.max - 100);
    } else {
      range = Math.abs(axisLimit.y.min - 100);
    }
    setYDomain([0, range]);
  }, [getAxisLimit, padLimits, timeframe]);

  useEffect(() => {
    setGraphLimit();
  }, [setGraphLimit, type, timeframe]);

  function formatReadableDate(val) {
    return dayjs(val).format('l');
  }

  const filledInData = useColdstartFiller({
    timelineData: convertedData,
    yDomain: { max: yDomain[1], min: -1 * yDomain[0] },
    type: 'volume',
  });

  return (
    <FlexibleXYPlot
      className={styles.ChartSentiment}
      xDomain={xDomain}
      yDomain={yDomain}
      margin={{
        top: 10,
        left: 0,
        bottom: 51,
      }}
    >
      <AreaSeries
        data={filledInData}
        color={COLORS.colorGray20}
        curve="curveMonotoneX"
      />

      <LineSeries
        data={filledInData}
        color={COLORS.colorGray40}
        strokeWidth=".7px"
        curve="curveMonotoneX"
      />

      <AreaSeries data={convertedDataPos} color="#D2F4DF" />
      <AreaSeries data={convertedDataNeg} color="#F7D6D6" />

      <LineSeries data={convertedDataPos} stroke="#20c660" strokeWidth="1px" />
      <LineSeries data={convertedDataNeg} stroke="#d83133" strokeWidth="1px" />

      <XAxis
        tickFormat={formatReadableDate}
        tickTotal={10}
        tickSizeOuter={8}
        tickSizeInner={0}
        tickPadding={0}
        on0
        style={{
          line: {
            stroke: xAxisLine ? 'black' : 'black',
            strokeWidth: '.5px',
          },
          text: {
            fill: COLORS.colorGrayBase60,
            stroke: 'none',
            fontSize: '11px',
            fontWeight: 300,
          },
          ticks: {
            line: {
              stroke: COLORS.colorGray20,
              strokeWidth: '0.5px',
            },
          },
        }}
      />
    </FlexibleXYPlot>
  );
}

ChartSentiment.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      name: PropTypes.string,
      sessionKey: PropTypes.string,
      value: PropTypes.number,
      variableId: PropTypes.string,
    })
  ),
  type: PropTypes.string,
  padLimits: PropTypes.bool,
  xAxisLine: PropTypes.bool,
  timeframe: PropTypes.shape({
    quantity: PropTypes.number,
    unit: PropTypes.string,
    end: PropTypes.instanceOf(Date),
  }),
};
