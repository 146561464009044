import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import {
  CloseIcon,
  ShareIcon,
  CopyLink,
  CheckInCircleIcon,
} from '../../../../../assets/icons';

import styles from './InviteSentModal.module.scss';

interface InviteSentModalProps extends DialogProps {
  handleOnClose: () => void;
  names: string[];
  dashboardId: string;
}

const InviteSentModal: React.FC<InviteSentModalProps> = ({
  handleOnClose,
  names,
  dashboardId,
  ...props
}) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const displayNames = names
    .map((name, index) => (index === names.length - 1 ? name : `${name}, `))
    .join('');

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://deep.blueocean.ai/dashboards/${dashboardId}`
      );
      setIsLinkCopied(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to copy link to clipboard', error);
    }
  };

  const renderCopiedButton = () => {
    return (
      <span className={styles.CopiedButton} onClick={handleCopyLink}>
        <CheckInCircleIcon className={styles.CheckInCircleIcon} />
        <p>Linked Copied!</p>
      </span>
    );
  };

  return (
    <Dialog
      className={styles.ModalContainer}
      onClose={handleOnClose}
      {...props}
    >
      <div className={styles.InviteSentModal}>
        <div className={styles.Header}>
          <div className={styles.Left}>
            <ShareIcon className={styles.ShareIcon} />
            <p>Invite sent successfully!</p>
          </div>
          <CloseIcon className={styles.Icon} onClick={handleOnClose} />
        </div>

        <div className={styles.TextSection}>
          {`Email invitations to ${displayNames} to view the dashboard have been successfully sent`}
        </div>

        <div className={styles.Footer}>
          <div className={styles.FooterText}>
            <p>Only people with a BlueOcean account can open this link</p>
            <span className={styles.LinkButton}>
              {!isLinkCopied ? (
                <span className={styles.ButtonGroup} onClick={handleCopyLink}>
                  <CopyLink className={styles.CopyLink} />{' '}
                  <p className={styles.CopyLinkText}>Copy link</p>
                </span>
              ) : (
                renderCopiedButton()
              )}
            </span>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default InviteSentModal;
