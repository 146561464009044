import { ChartOptions } from 'chart.js';
import { AnchorHTMLAttributes, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import PALETTES, {
  PaletteType,
} from '../../../../../../constants/color-palettes';
import {
  MetricComparisonGraphSet,
  MetricComparisonPointData,
  MetricComparisonYDomain,
} from '../../../types';
import colors from '../../../../../../constants/colors';
import styles from './BlueScoreWidgetGraph.module.scss';
import { ChartTooltipContextLine } from '../../../../../../interfaces';
import { xHoverAnnotationLine } from '../../../../../../chartjs/plugins';
import bluescoreWidgetTooltip from '../../../../../../chartjs/tooltips/bluescoreWidgetTooltip/bluescoreWidgetTooltip';

export interface BlueScoreWidgetGraphProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  brands: MetricComparisonGraphSet[];
  markers: MetricComparisonPointData[];
  yDomain: MetricComparisonYDomain;
}

const BlueScoreWidgetGraph = ({
  brands,
  markers,
  yDomain,
}: BlueScoreWidgetGraphProps) => {
  const competitorAverageScore = 100;

  const yLabelFormatter = (
    tick: string,
    { first, last }: { first: string; last: string }
  ): string | null => {
    if (tick === first || tick === last) {
      return tick.toUpperCase();
    }
    return null;
  };

  const chartData = useMemo(() => {
    if (!brands) return { datasets: [] };

    const brandDatasets = brands.map((brand, index) => {
      return {
        data: brand.data,
        fill: false,
        showLine: true,
        borderWidth: 2,
        pointHitRadius: 5,
        tension: 0.4,
        pointRadius: 3,
        pointBackgroundColor: colors.opacity80,
        borderColor: PALETTES[PaletteType.Ocean][index].DEFAULT,
      };
    });

    const markersDataset = {
      data: markers,
      fill: false,
      showLine: false,
      pointRadius: 5,
      pointHitRadius: 2,
      backgroundColor: colors.chartBluescoreVerticalLine,
    };

    return {
      labels: yDomain.labels,
      datasets: [...brandDatasets, markersDataset],
    };
  }, [brands, markers, yDomain.labels]);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    interaction: {
      intersect: false,
      mode: 'x',
    },
    scales: {
      y: {
        grid: {
          display: true,
          drawOnChartArea: true,
          borderDash: [5],
          borderColor: colors.colorGray40,
          color: (context: { tick: { value: number } }) => {
            if (context.tick.value === competitorAverageScore) {
              return colors.chartBluescoreBorder;
            }
            return '#E6E6E9';
          },
        },
        ticks: {
          display: true,
          maxTicksLimit: 5,
          font: {
            size: 13,
          },
        },
      },
      x: {
        grid: {
          display: false,
          borderColor: colors.colorGray40,
        },
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MMM DD, YYYY',
          },
        },
        ticks: {
          source: 'labels',
          font: {
            size: 13,
          },
          callback: (tick: string) => yLabelFormatter(tick, yDomain),
        },
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          line: {
            type: 'line',
            yMin: competitorAverageScore,
            yMax: competitorAverageScore,
            borderWidth: 1,
            borderColor: colors.chartBluescoreBorder,
            borderDash: [5],
          },
        },
      },
      tooltip: {
        enabled: false,
        external: (context: ChartTooltipContextLine) =>
          bluescoreWidgetTooltip(context),
      },
    },
  };

  return (
    <div>
      <Line
        options={options as ChartOptions<'line'>}
        data={chartData}
        plugins={[xHoverAnnotationLine]}
        className={styles.BlueScoreWidgetChart}
      />
    </div>
  );
};

export default BlueScoreWidgetGraph;
