import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import FullScreenButton from '../../Atoms/FullScreenButton/FullScreenButton';
import FavoriteStar from '../../Atoms/FavoriteStar/FavoriteStar';
import DownloadDropdown from '../DownloadDropdown/DownloadDropdown';
import ShareDropdown from '../ShareDropdown/ShareDropdown';

import BNContext from '../../../contexts/BNContext';

import styles from './PageTitle.module.scss';
import BetaTag from '../../Atoms/BetaTag/BetaTag';

export default function PageTitle({
  shareable,
  downloadable,
  fullscreen,
  favoritable,
  title,
  className,
  targetId,
  metricId,
  padded,
  beta = false,
}) {
  const { FEATURE_FLAGS, metricsFavoriteMap } = useContext(BNContext);

  return (
    <div
      className={classnames(
        'page-title',
        styles.PageTitle,
        padded ? styles.Padded : null,
        className
      )}
    >
      <div className={styles.Title}>
        {FEATURE_FLAGS.GLOBAL.METRICS_FAVORITE && favoritable && (
          <FavoriteStar
            favorited={metricsFavoriteMap[metricId]}
            className={styles.Star}
          />
        )}

        <h1>{title}</h1>
        {beta ? <BetaTag /> : null}
      </div>

      <div className={styles.Actions}>
        {FEATURE_FLAGS.GLOBAL.SHARE.ROOT && shareable ? (
          <ShareDropdown targetId={targetId} />
        ) : null}

        {FEATURE_FLAGS.GLOBAL.FULL_SCREEN && fullscreen ? (
          <FullScreenButton />
        ) : null}

        {FEATURE_FLAGS.GLOBAL.DOWNLOAD.ROOT && downloadable ? (
          <DownloadDropdown targetId={targetId} padding={100} />
        ) : null}
      </div>
    </div>
  );
}

PageTitle.propTypes = {
  padded: PropTypes.bool,
  downloadable: PropTypes.bool,
  favoritable: PropTypes.bool,
  shareable: PropTypes.bool,
  fullscreen: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  targetId: PropTypes.string,
  metricId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  beta: PropTypes.bool,
};
