/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { BrandLogo } from '@blueoceanai/react-component-library';
import {
  AnchorHTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SingleValue } from 'react-select';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import CloseIcon from '../../../../assets/icons/CloseIcon';
import { SIZES } from '../../../../constants/props';
import DropdownSelect, {
  DropdownOption,
} from '../DropdownSelect/DropdownSelect';
import styles from './CompareCard.module.scss';
import Pager from '../Pager/Pager';
import { CreativeAsset } from '../../../../interfaces/creative';
import ModalContainer from '../../../Modals/ModalContainer';
import CreativeAssetModalContent from '../CreativeAssetModalContent';
import { GetCreativesRequestInput } from '../types';
import { GET_CREATIVES } from '../../../../api/queries/Pages/CreativeChannelsAdvertising';
import BNContext from '../../../../contexts/BNContext';
import { getDayJsStartDate } from '../../../../utils/timeframe';
import { AdvertisingView } from '../AdvertisingCreativeSection/AdvertisingCreativeSection';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import CreativeGrid from '../CreativeGrid/CreativeGrid';
import { SortType } from '../../../../constants/creative-asset-grid';

export interface CompareCardProps extends AnchorHTMLAttributes<HTMLDivElement> {
  brandKey: string;
  brandName: string;
  logoUrl?: string;
  options: { label: string; value: string }[];
  showCloseIcon: boolean;
  channelFilter: string;
  sortBy: SortType;
  resetPage: boolean;
  onDropdownChange: (
    newValue: DropdownOption | null,
    fromBrandKey: string
  ) => void;
  handleClose: (brandKey: string) => void;
}
export interface BrandCompetitor {
  name: string;
  brandKey: string;
  id: string;
  logoUrl: string;
}

const CompareCard = ({
  brandKey,
  brandName,
  logoUrl,
  options,
  showCloseIcon,
  channelFilter,
  sortBy,
  onDropdownChange,
  handleClose,
  resetPage,
  className = '',
}: CompareCardProps) => {
  const pageSize = 14;
  const selectedBrand = useMemo(() => {
    return { label: brandName, value: brandKey };
  }, [brandKey, brandName]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedCreative, setSelectedCreative] =
    useState<CreativeAsset | null>(null);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const { nonSessionTimeframe } = useContext(BNContext);

  useEffect(() => {
    if (resetPage) {
      setPageIndex(0);
    }
  }, [resetPage]);

  const getCreativesInput = useMemo(() => {
    const result: GetCreativesRequestInput = {
      input: {
        brandKey,
        start: getDayJsStartDate(nonSessionTimeframe).toISOString(),
        end: dayjs(nonSessionTimeframe?.end).toISOString(),
        view: AdvertisingView.Grid,
        pageSize,
        page: pageIndex,
        sort: sortBy,
      },
    };

    if (channelFilter && channelFilter !== '0' && !result.input.filters) {
      result.input.filters = [
        { filterOn: 'channel', filterBy: [Number(channelFilter)] },
      ];
    }

    return result;
  }, [brandKey, channelFilter, nonSessionTimeframe, pageIndex, sortBy]);

  const { data: getCreativesResp, loading } = useQuery(GET_CREATIVES, {
    variables: getCreativesInput,
    fetchPolicy: 'no-cache',
  });

  const creativesCount = useMemo(() => {
    return getCreativesResp?.getCreatives?.count ?? 0;
  }, [getCreativesResp]);

  const handleCardClick = useCallback((creative: CreativeAsset) => {
    setSelectedCreative(creative);
    setModalIsOpen(true);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setPageIndex(page);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalIsOpen(false);
    setSelectedCreative(null);
  }, []);

  const handleDropdownChange = useCallback(
    (newValue: SingleValue<{ label: string; value: string } | null>) => {
      onDropdownChange(newValue, selectedBrand.value);
    },
    [onDropdownChange, selectedBrand.value]
  );

  return (
    <div className={cn(styles.Card, className)}>
      <div className={styles.Header}>
        <BrandLogo size={SIZES.MD} src={logoUrl} className={styles.Logo} />
        <DropdownSelect
          value={selectedBrand}
          options={options}
          onChange={handleDropdownChange}
        />
        {showCloseIcon && (
          <CloseIcon
            className={styles.Icon}
            onClick={() => handleClose(brandKey)}
          />
        )}
      </div>

      <CreativeGrid
        className={styles.AssetGrid}
        creatives={getCreativesResp?.getCreatives?.creatives ?? []}
        loading={loading}
        onCreativeClick={handleCardClick}
        selectedSortBy={sortBy}
        cardColumns={6}
      />

      {loading ? (
        <ComponentLoader />
      ) : (
        <Pager
          pageIndex={pageIndex}
          pageSize={pageSize}
          totalCount={creativesCount}
          onPageChange={handlePageChange}
          className={styles.Pager}
        />
      )}
      {selectedCreative && (
        <ModalContainer isOpen={modalIsOpen} closeModal={handleCloseModal}>
          <CreativeAssetModalContent creative={selectedCreative} />
        </ModalContainer>
      )}
    </div>
  );
};

export default CompareCard;
