import { gql } from '@apollo/client';

export const GET_BRAND_PERSONALITY = gql`
  query getBrandPersonality($id: ID, $sessionKey: ID, $accountKey: ID) {
    competitiveSet(id: $id) {
      brand {
        id
      }
      session(sessionKey: $sessionKey) {
        id
        sessionKey
        sessionDate
        brandPersonality {
          competitiveSet_id
          personality
          archetypes {
            id
            variableId
            name
            voice
            description
            cardImgUrl
            exampleBrands
          }
          goal(accountKey: $accountKey) {
            id
            name
            variableId
            goalTrend {
              t4Quarters
            }
          }
        }
      }
    }
  }
`;

export const GET_ARCHETYPE_TIMELINE = gql`
  query getArchetypeTimeline(
    $id: ID
    $sessionKey: ID
    $associatedTypeId: String
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        brandPersonality {
          archetypeTrend(associatedTypeId: $associatedTypeId) {
            t4Quarters
          }
        }
      }
    }
  }
`;
