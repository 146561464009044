import { FC } from 'react';
import classNames from 'classnames';
import { abbreviateNumber } from '../../../../utils/number';

import DynamicArrowCircleIcon, {
  ArrowDirection,
} from '../../../../assets/icons/DynamicArrowCircleIcon';
import { ScoreType } from '../../../../interfaces/metric';

import styles from './ChangeDisplayIcon.module.scss';

export interface ChangeDisplayIconProps {
  value: number;
  suffix: string;
  className?: string;
  changeSuffixColor?: boolean;
  hideSymbol?: boolean;
  classes?: {
    root: string;
    icon: string;
    value: string;
    suffix: string;
  };
  scoreType?: ScoreType;
}

const ChangeDisplayIcon: FC<ChangeDisplayIconProps> = ({
  value,
  suffix,
  className,
  changeSuffixColor,
  hideSymbol,
  classes,
  scoreType,
}) => {
  let icon;
  let suffixColor;
  if (value > 0) {
    icon = <DynamicArrowCircleIcon direction={ArrowDirection.up} size={25} />;
  } else if (value < 0) {
    icon = <DynamicArrowCircleIcon direction={ArrowDirection.down} size={25} />;
  } else {
    icon = (
      <DynamicArrowCircleIcon direction={ArrowDirection.right} size={25} />
    );
  }

  const formatNumber = (val: number) => {
    if (val === null || val === undefined) {
      return <p className={styles.LabelSmall}>No Change</p>;
    }

    if (scoreType === ScoreType.Indexed) {
      return Math.round(val);
    }

    if (hideSymbol) {
      return abbreviateNumber(Math.abs(val));
    }

    return abbreviateNumber(val);
  };

  const formattedNumber = formatNumber(value);

  return (
    <div className={classNames(styles.Container, className, classes?.root)}>
      <div className={classNames(styles.Icon, classes?.icon)}>{icon}</div>

      <div
        className={classNames(styles.PercentNumber, classes?.value)}
        style={{ color: changeSuffixColor ? suffixColor : undefined }}
      >
        {formattedNumber}
      </div>

      <span
        className={classes?.suffix}
        style={{ color: changeSuffixColor ? suffixColor : undefined }}
      >
        {suffix || null}
      </span>
    </div>
  );
};

export default ChangeDisplayIcon;
