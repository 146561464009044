/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import socketIOClient, { Socket } from 'socket.io-client';
import Cookies from 'js-cookie';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlag } from '../utils/featureFlags';

export interface PoseidonSocket {
  socket: Socket | null;
  messageResponse: string;
  emitMessage: (message: string) => void;
}

export const PoseidonContext = createContext<PoseidonSocket>({
  socket: null,
  messageResponse: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  emitMessage: (message: string) => {},
});

export const usePoseidonSocketContext = () =>
  useContext<PoseidonSocket>(PoseidonContext);

const poseidonSocketUrl = process.env.REACT_APP_POSEIDON_URL ?? '';
const twentySeconds = 20000;

const createSocketToken = (): string => {
  const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  return guid;
};

const getSessionToken = (): string => {
  let sessionToken = Cookies.get('posedionSessionToken');
  if (!sessionToken) {
    sessionToken = createSocketToken();
    Cookies.set('posedionSessionToken', sessionToken, { expires: 7 });
  }

  return sessionToken;
};

const initalizeSocket = (
  sessionToken: string,
  setResponse: Dispatch<SetStateAction<string>>
) => {
  const socket = socketIOClient(poseidonSocketUrl, {
    query: { token: sessionToken },
    withCredentials: true,
  });

  socket.on('connect', () => {
    console.log(`Connected to socket ${socket.id} on room ${sessionToken}`);
  });

  socket.on('message', (data: string) => {
    setResponse(data);
  });

  socket.on('connect_error', (err) => {
    console.log('issue connecting: ', err);
  });

  return socket;
};

const initializeHeartbeat = (socket: Socket) => {
  return setInterval(() => {
    socket.emit('heartbeat', {});
  }, twentySeconds);
};

export const PoseidonSocketProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [messageResponse, setMessageResponse] = useState<string>('');
  const flags = useFlags();

  const emitMessage = useCallback(
    (payload: string) => {
      if (!socket) return;
      socket.emit('message', payload);
    },
    [socket]
  );

  useEffect(() => {
    if (!flags[FeatureFlag.PoseidonDashboardAccess]) {
      return;
    }

    const sessionToken = getSessionToken();
    const newSocket = initalizeSocket(sessionToken, setMessageResponse);
    setSocket(newSocket);

    const heartbeatInterval = initializeHeartbeat(newSocket);

    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(heartbeatInterval);
      newSocket.disconnect();
    };
  }, [flags]);

  const providerValue = useMemo(
    () => ({ socket, messageResponse, emitMessage }),
    [socket, messageResponse, emitMessage]
  );

  return (
    <PoseidonContext.Provider value={providerValue}>
      {children}
    </PoseidonContext.Provider>
  );
};
