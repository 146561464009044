import { useState } from 'react';
import classNames from 'classnames';

import TopNavAlertDropdown from '../../Organisms/TopNavAlertDropdown/TopNavAlertDropdown';
import PastReportsDropdown from '../../Organisms/PastReportsDropdown/PastReportsDropdown';

import useFeatureFlags from '../../../hooks/useFeatureFlags';
import useRouter from '../../../hooks/useRouter';

import styles from './TopNavRightSet.module.scss';
import { BookIcon, GearIcon } from '../../../assets/icons';

export default function TopNavRightSet() {
  const { updateRoute } = useRouter();
  const FEATURE_FLAGS = useFeatureFlags();

  const [isReportsDropdownOpen, setIsReportsDropdownOpen] = useState(false);

  return (
    <div className={styles.TopNavRightSet}>
      <ul className={styles.TopNavRightSetList}>
        {FEATURE_FLAGS.RESOURCES.ROOT ? (
          <li className={styles.TopNavRightSetListItem}>
            <div className={styles.ItemContent}>
              <div
                onClick={() => updateRoute({ pathname: '/resources' })}
                className={styles.item}
              >
                <span alt="Resources">
                  <BookIcon className={styles.Icon} title="Resources" />
                </span>
              </div>
            </div>
          </li>
        ) : null}

        {FEATURE_FLAGS.REPORTS.ROOT ? (
          <li
            className={classNames(
              styles.TopNavRightSetListItem,
              isReportsDropdownOpen ? styles.Active : null
            )}
          >
            <div className={styles.ItemContent}>
              <PastReportsDropdown onOpenChange={setIsReportsDropdownOpen} />
            </div>
          </li>
        ) : null}

        {FEATURE_FLAGS.ADMIN_SETTINGS.ROOT ? (
          <li className={styles.TopNavRightSetListItem}>
            <div className={styles.ItemContent}>
              <div
                onClick={() =>
                  updateRoute({ pathname: '/admin-settings/users' })
                }
                className={styles.item}
              >
                <GearIcon className={styles.Icon} title="Settings" />
              </div>
            </div>
          </li>
        ) : null}

        {FEATURE_FLAGS.ALERTS.ROOT ? (
          <li className={styles.TopNavRightSetListItem}>
            <div className={styles.ItemContent}>
              <TopNavAlertDropdown />
            </div>
          </li>
        ) : null}
      </ul>
    </div>
  );
}
