/* eslint-disable import/prefer-default-export */

import { gql } from '@apollo/client';

export interface GetScoreDriversRawScoresResp {
  getScoreDriverRawScores: {
    chart: {
      date: string;
      value: number;
    }[];
    tile: {
      score: number;
      tooltip: string;
    };
    description: string;
  };
}

export const GET_SCORE_DRIVER_RAW_SCORES = gql`
  query getScoreDriverRawScores($input: SocialMetricsTimeSeriesInput) {
    getScoreDriverRawScores(input: $input) {
      chart {
        date
        value
      }
      tile {
        score
        tooltip
      }
      description
    }
  }
`;

export const GET_SOCIAL_METRICS_BY_CHANNEL = gql`
  query getSocialMetricsByChannel($input: SocialMetricsTimeSeriesInput) {
    getSocialMetricsByChannel(input: $input) {
      tile {
        score
        tooltip
      }
      timeseries {
        date
        value
      }
      channel
      description
    }
  }
`;
