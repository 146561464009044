import { gql } from '@apollo/client';

export const GET_PROFILE_OVERVIEW = gql`
  query getUserProfile {
    user {
      email
      avatarUrl
      lastLogin
      name
      subscriptions {
        name
        isActive
      }
      roles {
        role: name
        brand: brandName
      }
    }
  }
`;
