import * as React from 'react';

function SvgIconEmotional(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#7E9397"
          d="M10.345 1.73a9.521 9.521 0 017.284 1.593c2.1 1.5 3.465 3.786 3.766 6.31l.023.303c.18 2.928-1.125 5.748-3.484 7.547l-.064.046-.001 4.468-.007.078a.436.436 0 01-.863-.078V17.31l.008-.083a.436.436 0 01.175-.272c2.348-1.667 3.619-4.414 3.348-7.23-.27-2.27-1.504-4.338-3.406-5.695a8.646 8.646 0 00-6.622-1.445c-3.947.704-5.457 3.032-7.589 9.68l-.475 1.471 2.514.003.079.007a.435.435 0 01.356.427v2.609l.006.155c.082 1.126 1.048 2.019 2.234 2.019h.892l.078.007a.434.434 0 01.356.427V22l-.007.078a.434.434 0 01-.427.357l-.079-.007A.436.436 0 018.084 22v-2.174h-.457l-.182-.005c-1.63-.092-2.927-1.415-2.927-3.038v-2.174H2.444l-.12-.008a.885.885 0 01-.587-.346.863.863 0 01-.128-.783l.6-1.852c2.165-6.657 3.835-9.122 8.136-9.89zm2.011 5.89a2.155 2.155 0 011.861-.592c.643.104 1.2.488 1.508 1.046l.049.095c.41.815.252 1.787-.396 2.44l-3.209 3.32a.238.238 0 01-.34 0l-3.206-3.32a2.106 2.106 0 01-.444-2.343l.043-.092a2.086 2.086 0 011.56-1.146c.683-.11 1.38.111 1.86.591l.358.354z"
        />
      </g>
    </svg>
  );
}

export default SvgIconEmotional;
