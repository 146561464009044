import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider as MuiV4ThemeProvider,
} from '@material-ui/core/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { BlueoceanTheme } from './MuiThemes';

import ApplicationAuthBoundary from './Components/Templates/ApplicationAuthBoundary/ApplicationAuthBoundary';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { pendoInit } from './utils/pendo/init';

import './index.scss';

async function renderApp() {
  if (process.env.REACT_APP_ENV === 'local-mock') {
    // eslint-disable-next-line global-require
    const { worker } = require('./mocks/browser');
    worker.start();
  } else {
    pendoInit();
  }

  const generateClassName = createGenerateClassName({
    productionPrefix: 'brand-navigator',
    disableGlobal: true,
  });

  const container = document.getElementById('root');
  const root = createRoot(container);

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    options: {
      streaming: true,
    },
  });

  root.render(
    <StrictMode>
      <LDProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTHDOMAIN}
          clientId={process.env.REACT_APP_AUTHCLIENTID}
          redirectUri={window.location.origin}
          scope="openid profile email"
          audience={process.env.REACT_APP_AUDIENCE}
        >
          <Router>
            <ApplicationAuthBoundary>
              <StyledEngineProvider injectFirst>
                <StylesProvider
                  generateClassName={generateClassName}
                  injectFirst
                >
                  <ThemeProvider theme={BlueoceanTheme}>
                    <MuiV4ThemeProvider theme={BlueoceanTheme}>
                      <App />
                    </MuiV4ThemeProvider>
                  </ThemeProvider>
                </StylesProvider>
              </StyledEngineProvider>
            </ApplicationAuthBoundary>
          </Router>
        </Auth0Provider>
      </LDProvider>
    </StrictMode>
  );
}

renderApp();

serviceWorker.unregister();
