import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Button } from '@blueoceanai/react-component-library';
import Avatar from '../../Atoms/Avatar/Avatar';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';

import TextInput from '../../Molecules/TextInput/TextInput';
import Table from '../../Molecules/Table/Table';
import ModalAlert from '../../Molecules/ModalAlert/ModalAlert';

import BNContext from '../../../contexts/BNContext';
import { GET_PROFILE_OVERVIEW } from '../../../api/queries/Pages/ProfileOverview';
import {
  prepareProfileBrands,
  prepareProfileAvatarInitials,
} from '../../../api/transforms/Organisms/ProfileOverview';

import { BUTTON_VARIANTS } from '../../../constants/props';

import styles from './ProfileOverview.module.scss';
import { InfoCircleIcon } from '../../../assets/icons';

export default function ProfileOverview({ className, ...props }) {
  const [brands, setBrands] = useState([]);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({
    title: null,
    message: null,
    helper: null,
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const [getProfileOverview, { error, loading, data }] =
    useLazyQuery(GET_PROFILE_OVERVIEW);
  const { user } = React.useContext(BNContext);

  useEffect(() => {
    if (!data) getProfileOverview();
  }, [getProfileOverview, data]);

  useEffect(() => {
    if (data) {
      setBrands(prepareProfileBrands(data.user.roles, data.user.lastLogin));
    }
  }, [data]);

  async function handleChangePassword() {
    if (user && user.sub) {
      const provider = user.sub.split('|');

      switch (provider[0]) {
        case 'google-oauth2': {
          window.location.replace(
            'https://myaccount.google.com/intro/signinoptions/password?hl=en-US'
          );
          break;
        }
        case 'facebook': {
          window.location.replace(
            'https://www.facebook.com/login/identify/?ctx=recover&ars=royal_blue_bar'
          );
          break;
        }
        case 'linkedin': {
          window.location.replace(
            'https://www.linkedin.com/uas/request-password-reset?trk=guest_homepage-basic_forgot_password'
          );
          break;
        }
        default: {
          const pwData = {
            client_id: process.env.REACT_APP_AUTHCLIENTID,
            email: user.email,
            connection: 'Username-Password-Authentication',
          };

          setBtnLoading(true);

          fetch(
            `https://${process.env.REACT_APP_AUTHDOMAIN}/dbconnections/change_password`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(pwData),
            }
          )
            .then((response) => response.text())
            .then(() => {
              setAlertProps({
                title: 'Password',
                message: 'Change Password',
                helper: "We've just sent you an email to reset your password",
                Icon: InfoCircleIcon,
              });

              setOpenModalAlert(true);

              setBtnLoading(false);
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log('error', err);

              setAlertProps({
                title: 'Password',
                message: 'Change Password',
                helper: err.error_description,
              });

              setOpenModalAlert(true);

              setBtnLoading(false);
            });
          break;
        }
      }
    } else {
      // eslint-disable-next-line no-console
      console.log('user from context', user);
      setAlertProps({
        title: 'Password',
        message: 'Change Password',
        helper:
          'No user information could be found.  Please contact BlueOcean.',
      });
      setOpenModalAlert(true);
      setBtnLoading(false);
    }
  }

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <div className={classNames(styles.ProfileOverview, className)} {...props}>
      <div className={styles.Header}>
        <h3>Profile</h3>
      </div>

      {loading && <ComponentLoader minHeight={280} />}

      {!loading && (
        <div className={styles.Body}>
          <div className={classNames(styles.Section, styles.ProfileSection)}>
            <div className={styles.SectionItem}>
              <Avatar
                className={styles.Avatar}
                initials={prepareProfileAvatarInitials(data?.user?.name)}
              />
            </div>

            <div className={styles.SectionItem} data-cy="txt-profile-email">
              <TextInput
                className={styles.Input}
                label="EMAIL ADDRESS"
                inputId="email"
                value={data?.user?.email}
                onChange={() => {}}
                helperText="To change the address for your account, please contact your BlueOcean admin."
                disabled
              />
            </div>

            <div className={styles.SectionItem} data-cy="bnm-change-password">
              <Button
                className={styles.Button}
                variant={BUTTON_VARIANTS.SECONDARY}
                onClick={handleChangePassword}
                loading={btnLoading}
              >
                Change Password
              </Button>
            </div>
          </div>

          <div className={classNames(styles.Section, styles.BrandsSection)}>
            <h1 className={styles.SectionHeader}>My Brands</h1>
            <Table
              items={brands || null}
              fields={[
                {
                  key: 'brand',
                },
                {
                  key: 'role',
                },
                {
                  key: 'lastLogin',
                  label: 'Last Accessed',
                  format: (time) => dayjs(time).format('l'),
                },
              ]}
            />
          </div>
        </div>
      )}

      <ModalAlert
        open={openModalAlert}
        onClose={() => setOpenModalAlert(false)}
        {...alertProps}
      />
    </div>
  );
}

ProfileOverview.propTypes = {
  className: PropTypes.string,
};
