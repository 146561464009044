import { AnchorHTMLAttributes, SyntheticEvent, useState } from 'react';
import cn from 'classnames';
import styles from './TitleEditor.module.scss';

export interface TitleEditorProps extends AnchorHTMLAttributes<HTMLDivElement> {
  title?: string;
  maxCharacters?: number;
  className?: string;
  handleTitleChange: (title: string) => void;
  disabled?: boolean;
}

const EscapeKey = 'Escape';

const TitleEditor = ({
  title = '',
  maxCharacters = 100,
  className = '',
  handleTitleChange,
  disabled,
}: TitleEditorProps) => {
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const [editedTitle, setEditedTitle] = useState<string>(title);

  const usedCharacters = `${editedTitle.length}/${maxCharacters}`;

  const handleTitleEdit = (updatedTitle: string) => {
    setEditedTitle(updatedTitle);
  };

  const handleCloseEditing = () => {
    setIsEditView(false);
    setEditedTitle(title);
  };

  const handleEditSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!editedTitle || editedTitle === title) return;

    handleTitleChange(editedTitle);
    setTimeout(() => handleCloseEditing(), 250);
  };

  return (
    <div className={cn(styles.TitleEditor, className)}>
      {isEditView && !disabled ? (
        <form className={styles.EditingContainer} onSubmit={handleEditSubmit}>
          <input
            className={styles.Input}
            value={editedTitle}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            maxLength={maxCharacters}
            onChange={(e) => handleTitleEdit(e.target.value)}
            onBlur={handleCloseEditing}
            onKeyUp={(e) => {
              if (e.key === EscapeKey) {
                handleCloseEditing();
              }
            }}
          />
          <span className={styles.CharacterCount}>{usedCharacters}</span>
        </form>
      ) : (
        <button
          className={styles.EditorTitle}
          type="button"
          title="Click to edit dashboard name"
          onClick={() => setIsEditView(true)}
        >
          {title}
        </button>
      )}
    </div>
  );
};

export default TitleEditor;
