import { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useLazyQuery, useQuery } from '@apollo/client';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';

import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import Dropdown from '../../Molecules/Dropdown/Dropdown';
import CompareBrandInfo from '../../Organisms/CompareBrandInfo/CompareBrandInfo';

import useQueryParams from '../../../hooks/useQuery';
import useRouter from '../../../hooks/useRouter';

import {
  GET_BLUESCORE_HERO_MAIN,
  GET_BLUESCORE_HERO_SUBSET,
  GET_BLUESCORE_COMPETITOR_MAIN,
  GET_BLUESCORE_COMPETITOR_SUBSET,
  GET_FACTOR_HERO_MAIN,
  GET_FACTOR_HERO_SUBSET,
  GET_FACTOR_COMPETITOR_MAIN,
  GET_FACTOR_COMPETITOR_SUBSET,
  GET_SUBFACTOR_HERO_MAIN,
  GET_SUBFACTOR_HERO_SUBSET,
  GET_SUBFACTOR_COMPETITOR_MAIN,
  GET_SUBFACTOR_COMPETITOR_SUBSET,
  GET_COMPETITORS,
} from '../../../api/queries/Pages/MarketIndexCompare';
import {
  prepareCustomerBrandInfo,
  prepareSubsetInfo,
  prepareCompetitorBrandInfo,
  prepareCompetitorDropdownOptions,
  prepareCompetitorSubsetInfo,
} from '../../../api/transforms/Pages/MarketIndexCompare';

import {
  FACTOR_TYPES,
  SUBFACTOR_TYPES,
  FACTOR_TYPE_KEYS,
} from '../../../constants/factors';
import { COMPARE_TYPES } from '../../../constants/props';
import QUERY_PARAMS from '../../../constants/queryParams';
import {
  SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST,
  SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST,
  SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS,
} from '../../../constants/social-metrics';
import { SocialChannelKey, ScoreType } from '../../../interfaces/metric';

import { handleGenericError } from '../../../utils/error';

import BNContext from '../../../contexts/BNContext';

import styles from './MarketIndexCompare.module.scss';

const associatedTypeById = {
  familiar: 1,
  unique: 2,
  consistent: 3,
  relevant: 4,
  revered: 5,
  visible: 1,
  considered: 2,
  amplified: 3,
  different: 4,
  memorable: 5,
  clear: 6,
  reinforcing: 7,
  stable: 8,
  needed: 9,
  valued: 10,
  influential: 11,
  desirable: 12,
  esteemed: 13,
  inspiring: 14,
};

const YDOMAIN_MIN_DEFAULT = 0;
const YDOMAIN_MAX_DEFAULT = 200;

function getDropdownOptions() {
  const result = [];
  const SUBFACTOR_TYPES_BY_PARENT_FACTOR = groupBy(
    SUBFACTOR_TYPES,
    'PARENT_FACTOR'
  );

  FACTOR_TYPES.forEach((TYPE) => {
    if (!SUBFACTOR_TYPES_BY_PARENT_FACTOR[TYPE.KEY]) {
      const item = {
        value: TYPE.KEY,
        label: 'Blue Score',
      };
      result.push(item);
    } else {
      const item = {
        value: TYPE.KEY,
        label: TYPE.NAME,
        subItems: [],
      };

      SUBFACTOR_TYPES_BY_PARENT_FACTOR[TYPE.KEY].forEach((SUBFACTOR_TYPE) => {
        item.subItems.push({
          value: SUBFACTOR_TYPE.KEY,
          label: SUBFACTOR_TYPE.NAME,
        });
      });

      result.push(item);
    }
  });

  return result;
}

const dropdownOptions = getDropdownOptions();

function MarketIndexCompare() {
  const {
    competitiveSetID: _competitiveSetId,
    currentSession: sessionKey,
    timeframe,
  } = useContext(BNContext);

  const queryParams = useQueryParams();
  const { updateRoute } = useRouter();

  const factorName = useMemo(
    () => queryParams.get('factorName'),
    [queryParams]
  );

  const competitorKey = useMemo(
    () => queryParams.get('competitorKey'),
    [queryParams]
  );

  const selectedFactor = useMemo(() => {
    if (!factorName) {
      updateRoute({
        params: { [QUERY_PARAMS.FACTOR_NAME]: 'overall' },
      });
    }

    let result = dropdownOptions[0];

    dropdownOptions.forEach((item) => {
      if (item.value === factorName) {
        result = item;
      }

      if (item.subItems && item.subItems.length) {
        item.subItems.forEach((subitem) => {
          if (subitem.value === factorName) {
            result = subitem;
          }
        });
      }
    });

    return result;
  }, [factorName, updateRoute]);

  const compareType = useMemo(() => {
    if (selectedFactor.value === 'overall') {
      return COMPARE_TYPES.BLUESCORE;
    }

    if (find(FACTOR_TYPES, ['KEY', selectedFactor.value])) {
      return COMPARE_TYPES.FACTOR;
    }

    if (find(SUBFACTOR_TYPES, ['KEY', selectedFactor.value])) {
      return COMPARE_TYPES.SUBFACTOR;
    }
  }, [selectedFactor.value]);

  const compareMetaByMetricType = {
    [COMPARE_TYPES.BLUESCORE]: {
      type: 'BlueScore',
      typeName: 'BlueScore',
      typeQuery: 'blueScore',
      subsetQuery: 'factors',
      subsetTimelineName: 'Factors',
      api: {
        heroMain: {
          query: GET_BLUESCORE_HERO_MAIN,
        },
        heroSubset: {
          query: GET_BLUESCORE_HERO_SUBSET,
        },
        competitorMain: {
          query: GET_BLUESCORE_COMPETITOR_MAIN,
          queryVariables: {
            competitorKey,
          },
        },
        competitorSubset: {
          query: GET_BLUESCORE_COMPETITOR_SUBSET,
          queryVariables: {
            competitorKey,
          },
        },
      },
    },
    [COMPARE_TYPES.FACTOR]: {
      type: 'Factors',
      typeName: selectedFactor.label,
      typeQuery: 'factor',
      subsetQuery: 'subFactors',
      subsetTimelineName: 'SubFactors',
      api: {
        heroMain: {
          query: GET_FACTOR_HERO_MAIN,
          queryVariables: {
            factorId: associatedTypeById[selectedFactor.value],
          },
        },
        heroSubset: {
          query: GET_FACTOR_HERO_SUBSET,
          queryVariables: {
            factorId: associatedTypeById[selectedFactor.value],
          },
        },
        competitorMain: {
          query: GET_FACTOR_COMPETITOR_MAIN,
          queryVariables: {
            factorId: associatedTypeById[selectedFactor.value],
            competitorKey,
          },
        },
        competitorSubset: {
          query: GET_FACTOR_COMPETITOR_SUBSET,
          queryVariables: {
            factorId: associatedTypeById[selectedFactor.value],
            competitorKey,
          },
        },
      },
    },
    [COMPARE_TYPES.SUBFACTOR]: {
      type: 'SubFactors',
      typeName: selectedFactor.label,
      typeQuery: 'subFactor',
      subsetQuery: 'scoreDrivers',
      subsetTimelineName: 'ScoreDrivers',
      api: {
        heroMain: {
          query: GET_SUBFACTOR_HERO_MAIN,
          queryVariables: {
            subFactorId: associatedTypeById[selectedFactor.value],
          },
        },
        heroSubset: {
          query: GET_SUBFACTOR_HERO_SUBSET,
          queryVariables: {
            subFactorId: associatedTypeById[selectedFactor.value],
          },
        },
        competitorMain: {
          query: GET_SUBFACTOR_COMPETITOR_MAIN,
          queryVariables: {
            subFactorId: associatedTypeById[selectedFactor.value],
            competitorKey,
          },
        },
        competitorSubset: {
          query: GET_SUBFACTOR_COMPETITOR_SUBSET,
          queryVariables: {
            subFactorId: associatedTypeById[selectedFactor.value],
            competitorKey,
          },
        },
      },
    },
  };

  const compareMeta = compareMetaByMetricType[compareType];

  const queryVariableBase = {
    id: _competitiveSetId,
    sessionKey,
  };

  const queryOptions = {};
  queryOptions.fetchPolicy = 'no-cache';

  /* Hero Main Chart and Info | API + Transform */
  const {
    data: heroMainData,
    loading: loadingHeroMain,
    error: errorHeroMain,
  } = useQuery(compareMeta.api.heroMain.query, {
    variables: {
      ...queryVariableBase,
      ...(compareMeta.api.heroMain.queryVariables || {}),
    },
    ...queryOptions,
  });

  const heroBrandMainData = useMemo(
    () =>
      handleGenericError(
        () => prepareCustomerBrandInfo(heroMainData, compareMeta, timeframe),
        'MarketIndexCompare prepareCustomerBrandInfo failed transform'
      ),
    [heroMainData, compareMeta, timeframe]
  );
  /* ---------------------------------------- */

  /* Hero Subset Chart and Info | API + Transform */
  const {
    data: heroSubsetData,
    loading: loadingHeroSubset,
    error: errorHeroSubset,
  } = useQuery(compareMeta.api.heroSubset.query, {
    variables: {
      ...queryVariableBase,
      ...(compareMeta.api.heroSubset.queryVariables || {}),
    },
    ...queryOptions,
  });

  const heroBrandSubsetData = useMemo(
    () =>
      handleGenericError(
        () => prepareSubsetInfo(heroSubsetData, compareMeta, timeframe),
        'MarketIndexCompare prepareSubsetInfo failed transform'
      ),
    [heroSubsetData, compareMeta, timeframe]
  );
  /* ---------------------------------------- */

  /* Competitor Main Chart and Info | API + Transform */
  const {
    data: competitorMainData,
    loading: loadingCompetitorMain,
    error: errorCompetitorMain,
  } = useQuery(compareMeta.api.competitorMain.query, {
    variables: {
      ...queryVariableBase,
      ...(compareMeta.api.competitorMain.queryVariables || {}),
    },
    ...queryOptions,
  });

  const competitorBrandMainData = useMemo(
    () =>
      handleGenericError(
        () =>
          prepareCompetitorBrandInfo(
            competitorMainData,
            competitorKey,
            compareMeta,
            timeframe
          ),
        'MarketIndexCompare prepareCompetitorBrandInfo failed transform'
      ),
    [competitorMainData, competitorKey, compareMeta, timeframe]
  );
  /* ---------------------------------------- */

  /* Competitor Subset Chart and Info | API + Transform */
  const {
    data: competitorSubsetData,
    loading: loadingCompetitorSubset,
    error: errorCompetitorSubset,
  } = useQuery(compareMeta.api.competitorSubset.query, {
    variables: {
      ...queryVariableBase,
      ...(compareMeta.api.competitorSubset.queryVariables || {}),
    },
    ...queryOptions,
  });

  const competitorBrandSubsetData = useMemo(
    () =>
      handleGenericError(
        () =>
          prepareCompetitorSubsetInfo(
            competitorSubsetData,
            competitorKey,
            compareMeta,
            timeframe
          ),
        'MarketIndexCompare prepareCompetitorSubsetInfo failed transform'
      ),
    [competitorSubsetData, competitorKey, compareMeta, timeframe]
  );
  /* ---------------------------------------- */

  const [
    getCompetitors,
    {
      loading: loadingCompetitors,
      error: errorCompetitors,
      data: competitorsResp,
    },
  ] = useLazyQuery(GET_COMPETITORS, {
    variables: {
      ...queryVariableBase,
    },
  });

  useEffect(() => {
    getCompetitors();
  }, [getCompetitors]);

  const competitorBrandsForDDL = useMemo(
    () =>
      handleGenericError(
        () => prepareCompetitorDropdownOptions(competitorsResp),
        'MarketIndexCompare prepareCompetitorDropdownOptions failed transform'
      ),
    [competitorsResp]
  );

  useEffect(() => {
    if (
      competitorBrandsForDDL &&
      competitorBrandsForDDL.length &&
      (!competitorKey ||
        !find(competitorBrandsForDDL, { brandKey: competitorKey }))
    ) {
      updateRoute({
        params: {
          [QUERY_PARAMS.COMPETITOR_KEY]: competitorBrandsForDDL[0].brandKey,
        },
      });
    }
  }, [competitorBrandsForDDL, competitorKey, updateRoute]);

  const graphScaleMap = useMemo(() => {
    const result = {
      [FACTOR_TYPE_KEYS.OVERALL]: {
        yDomain: {
          min: YDOMAIN_MIN_DEFAULT,
          max: YDOMAIN_MAX_DEFAULT,
        },
      },
      [FACTOR_TYPE_KEYS.FAMILIAR]: {
        yDomain: {
          min: YDOMAIN_MIN_DEFAULT,
          max: YDOMAIN_MAX_DEFAULT,
        },
      },
      [FACTOR_TYPE_KEYS.UNIQUE]: {
        yDomain: {
          min: YDOMAIN_MIN_DEFAULT,
          max: YDOMAIN_MAX_DEFAULT,
        },
      },
      [FACTOR_TYPE_KEYS.CONSISTENT]: {
        yDomain: {
          min: YDOMAIN_MIN_DEFAULT,
          max: YDOMAIN_MAX_DEFAULT,
        },
      },
      [FACTOR_TYPE_KEYS.RELEVANT]: {
        yDomain: {
          min: YDOMAIN_MIN_DEFAULT,
          max: YDOMAIN_MAX_DEFAULT,
        },
      },
      [FACTOR_TYPE_KEYS.REVERED]: {
        yDomain: {
          min: YDOMAIN_MIN_DEFAULT,
          max: YDOMAIN_MAX_DEFAULT,
        },
      },
    };

    if (
      !heroBrandMainData?.scoreOverTime?.length ||
      !heroBrandSubsetData?.length ||
      !competitorBrandMainData?.scoreOverTime?.length ||
      !competitorBrandSubsetData?.length
    ) {
      return result;
    }

    result[FACTOR_TYPE_KEYS.OVERALL].yDomain = {
      min: Math.min(
        minBy(heroBrandMainData.scoreOverTime, 'value').value,
        minBy(competitorBrandMainData.scoreOverTime, 'value').value,
        100
      ),
      max: Math.max(
        maxBy(heroBrandMainData.scoreOverTime, 'value').value,
        maxBy(competitorBrandMainData.scoreOverTime, 'value').value,
        100
      ),
    };

    heroBrandSubsetData.forEach((factor) => {
      if (!factor || !factor.scoreOverTime || !factor.scoreOverTime.length) {
        return;
      }

      if (!result[factor.factorType.toLowerCase()]) {
        result[factor.factorType.toLowerCase()] = {
          yDomain: {
            min: YDOMAIN_MIN_DEFAULT,
            max: YDOMAIN_MAX_DEFAULT,
          },
        };
      }

      const factorRange = {
        min: Math.min(minBy(factor.scoreOverTime, 'value').value, 100),
        max: Math.max(maxBy(factor.scoreOverTime, 'value').value, 100),
      };

      result[factor.factorType.toLowerCase()].yDomain = factorRange;
    });

    competitorBrandSubsetData.forEach((factor) => {
      if (
        !factor ||
        !factor.scoreOverTime ||
        !factor.scoreOverTime.length ||
        !result[factor.factorType.toLowerCase()]
      ) {
        return;
      }

      if (!result[factor.factorType.toLowerCase()]) {
        result[factor.factorType.toLowerCase()] = {
          yDomain: {
            min: YDOMAIN_MIN_DEFAULT,
            max: YDOMAIN_MAX_DEFAULT,
          },
        };
      }

      const factorRange = {
        min: Math.min(
          result[factor.factorType.toLowerCase()].yDomain.min,
          minBy(factor.scoreOverTime, 'value').value
        ),
        max: Math.max(
          result[factor.factorType.toLowerCase()].yDomain.max,
          maxBy(factor.scoreOverTime, 'value').value,
          100
        ),
      };

      result[factor.factorType.toLowerCase()].yDomain = factorRange;
    });

    return result;
  }, [
    heroBrandMainData,
    heroBrandSubsetData,
    competitorBrandMainData,
    competitorBrandSubsetData,
  ]);

  function handleOverallChange(option) {
    updateRoute({
      params: {
        [QUERY_PARAMS.FACTOR_NAME]: option.value,
      },
    });
  }

  function handleCompetitorChange(id) {
    updateRoute({
      params: {
        [QUERY_PARAMS.COMPETITOR_KEY]: id,
      },
    });
  }

  function handleCardLabelClick(factor) {
    if (!factor?.factorType) {
      return;
    }

    updateRoute({
      params: {
        [QUERY_PARAMS.FACTOR_NAME]: factor.factorType.toLowerCase(),
      },
    });
  }

  function handleCardExploreClick({ metric, scoreType }) {
    if (!metric?.variableId) {
      return;
    }

    const newParams = {
      [QUERY_PARAMS.METRIC_GROUP_ID]: null,
      [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
      [QUERY_PARAMS.METRIC_VARIABLE_ID]: metric.variableId,
    };

    if (
      SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(metric.variableId) ||
      SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(metric.variableId)
    ) {
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = SocialChannelKey.Aggregate;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = scoreType;
    } else {
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = null;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = null;
    }

    if (
      metric.variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE &&
      scoreType === ScoreType.Indexed
    ) {
      newParams[QUERY_PARAMS.METRIC_VARIABLE_ID] =
        SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE;
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = null;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = ScoreType.Indexed;
    }

    if (
      metric.variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE &&
      scoreType === ScoreType.Raw
    ) {
      newParams[QUERY_PARAMS.METRIC_VARIABLE_ID] =
        SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.ZZ;
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = null;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = ScoreType.Raw;
    }

    updateRoute({
      pathname: '/metrics',
      params: newParams,
    });
  }

  const isLoadingHeroMain = loadingHeroMain || !heroBrandMainData;
  const isLoadingHeroSubset = loadingHeroSubset || !heroBrandSubsetData;
  const isLoadingCompetitorMain =
    loadingCompetitors || loadingCompetitorMain || !competitorBrandMainData;
  const isLoadingCompetitorSubset =
    loadingCompetitors || loadingCompetitorSubset || !competitorBrandSubsetData;

  if (
    errorCompetitors ||
    errorHeroMain ||
    errorHeroSubset ||
    errorCompetitorMain ||
    errorCompetitorSubset
  ) {
    return <GenericErrorCopy />;
  }

  return (
    <Grid
      container
      className={classNames(styles.MarketIndexCompare, 'PageContainer')}
      data-testid="MarketIndexCompare"
    >
      <Grid item xs={12} className={styles.CompareOptions}>
        <Box className={styles.CompareHeader}>
          <span className={styles.ComparisonLabel}>Comparison</span>
          <Box ml={2} className={styles.DropdownContainer}>
            <Dropdown
              className={styles.Dropdown}
              options={dropdownOptions}
              onChange={handleOverallChange}
              value={selectedFactor.value}
              buttonProps={{ 'data-cy': 'factor-dropdown-button' }}
              listItemProps={{ 'data-cy': 'factor-dropdown-item' }}
            />
          </Box>
        </Box>
      </Grid>
      <Grid container item spacing={4} xs={12}>
        <Grid item xs={12} md={6}>
          <CompareBrandInfo
            compareType={compareType}
            customerBrand
            factorKey={selectedFactor.value}
            brandInfo={heroBrandMainData}
            subsetInfo={heroBrandSubsetData}
            brandsForDDL={[]}
            loadingMain={isLoadingHeroMain}
            loadingSubset={isLoadingHeroSubset}
            graphScaleMap={graphScaleMap}
            onCardLabelClick={handleCardLabelClick}
            onCardExploreClick={handleCardExploreClick}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CompareBrandInfo
            factorKey={selectedFactor.value}
            compareType={compareType}
            brandInfo={competitorBrandMainData}
            subsetInfo={competitorBrandSubsetData}
            brandsForDDL={competitorBrandsForDDL}
            selectedBrandKey={competitorKey}
            onCompetitorClick={handleCompetitorChange}
            loadingMain={isLoadingCompetitorMain}
            loadingSubset={isLoadingCompetitorSubset}
            graphScaleMap={graphScaleMap}
            onCardLabelClick={handleCardLabelClick}
            onCardExploreClick={handleCardExploreClick}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

MarketIndexCompare.propTypes = {};

export default MarketIndexCompare;
