import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import { USER_ROLES_KEYS } from '../../../constants/roles';

export function prepareBillingInvoices(data) {
  if (!data) {
    return null;
  }

  if (!data?.user?.account?.stripeBilling) {
    return [];
  }

  try {
    const stripeBillingResp = JSON.parse(data?.user?.account?.stripeBilling);

    if (!stripeBillingResp?.data || !stripeBillingResp?.data?.length) {
      return [];
    }

    const invoices = stripeBillingResp?.data;

    return invoices.map((invoice) => {
      return {
        invoicePdfUrl: invoice.invoice_pdf,
        id: invoice.number,
        date: invoice.due_date,
      };
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to parse billing invoices', e);
  }
}

export function prepareAccountSeats(data, accountKey) {
  if (!data) {
    return null;
  }

  if (!data?.user?.accounts) {
    return [];
  }

  try {
    const { accounts } = data.user;
    const seats = {
      used: 0,
      total: 1,
    };
    accounts.forEach((account) => {
      if (account.accountKey === accountKey) {
        seats.total += account.competitiveSets.length * 6;
        seats.used += account.seats_used;
      }
    });
    // eslint-disable-next-line
    return seats;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to parse seats', e);
  }
}

export function prepareUsers(data, userRole, currentCompSetId, accountKey) {
  if (!data?.user || !userRole || !currentCompSetId || !accountKey) {
    return null;
  }
  if (!data?.user?.accounts[0]?.users.length) {
    return [];
  }
  const result = [];
  if (
    userRole.id < USER_ROLES_KEYS.BRAND_ADMIN ||
    userRole.id === USER_ROLES_KEYS.BLUEOCEAN_ADMIN
  ) {
    const { accounts } = data.user;
    accounts.forEach((account) => {
      if (account.accountKey === accountKey) {
        const accountId = account.id;
        const { users } = account;
        users.forEach((user) => {
          const { brands } = user;
          brands.forEach((brand) => {
            const roleKeys = user.roles
              .filter((role) => {
                return role.brandId === brand.id;
              })
              .map((role) => {
                return {
                  id: role.id,
                  account_id: role.account_id,
                  burId: role.burId,
                };
              })[0];
            const item = {
              id: user.email,
              username: user.email,
              role: roleKeys?.id || undefined,
              burId: roleKeys?.burId || undefined,
              lastLogin: user.lastLogin,
              brands: brand.brandKey,
            };
            if (item.role !== undefined && roleKeys.account_id === accountId)
              result.push(item);
          });
        });
      }
    });
  } else {
    const { accounts } = data.user;

    accounts.forEach((account) => {
      if (account.accountKey === accountKey) {
        const accountId = account.id;
        const compSet = account.competitiveSets.filter((competitiveSet) => {
          return competitiveSet.competitiveSetKey === currentCompSetId;
        });
        if (compSet && compSet.length > 0) {
          const currentBrand = compSet[0].brand;
          const { users } = account;
          users.forEach((user) => {
            const roleKeys = user.roles
              .filter((role) => {
                return role.brandId === currentBrand.id;
              })
              .map((role) => {
                return {
                  id: role.id,
                  account_id: role.account_id,
                  burId: role.burId,
                };
              })[0];
            const item = {
              id: user.email,
              username: user.email,
              role: roleKeys?.id || undefined,
              burId: roleKeys?.burId || undefined,
              lastLogin: user.lastLogin,
              brands: currentBrand.brandKey,
            };
            if (item.role !== undefined && roleKeys.account_id === accountId)
              result.push(item);
          });
        }
      }
    });
  }
  return sortBy(result, 'id');
}

export function prepareBrandMap(data, accountKey) {
  if (!data?.user?.accounts[0]?.competitiveSets || !accountKey) {
    return null;
  }

  if (!data?.user?.accounts[0]?.competitiveSets.length) {
    return {};
  }
  const { accounts } = data.user;

  const competitiveSets = accounts
    .filter((account) => {
      return account.accountKey === accountKey;
    })
    .map((account) => {
      return account.competitiveSets;
    })[0];

  const brands = [];

  competitiveSets.forEach((compSet) => {
    brands.push({
      brandKey: compSet.brand.brandKey,
      name: compSet.brand.name,
    });
  });
  return keyBy(brands, 'brandKey');
}
