import React, { useContext, useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import { useLazyQuery, WatchQueryFetchPolicy } from '@apollo/client';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';

import BNContext from '../../../contexts/BNContext';
import FavoriteStar from '../FavoriteStar/FavoriteStar';

import styles from './MetricListItem.module.scss';
import { SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST } from '../../../constants/social-metrics';
import AccordionControl from '../AccordionControl/AccordionControl';
import AccordionList from '../AccordionList/AccordionList';
import { getDayJsStartDate } from '../../../utils/timeframe';
import { FeatureFlag } from '../../../utils/featureFlags';
import { GET_SOCIAL_METRICS_CHANNEL } from '../../../api/queries/Pages/Metrics';
import { prepareMetricListChannels } from '../../../api/transforms/Pages/Metrics';

export interface MetricListItemProps {
  name: string;
  metricId: string;
  onMetricClick: (metricVariableId: string) => void;
}

const MetricListItem = ({ name, metricId, onMetricClick }: MetricListItemProps) => {
  const { FEATURE_FLAGS } = useContext(BNContext);
  const flags = useFlags();
  const { competitiveSetID, timeframe, heroBrandKey } = useContext(BNContext);
  const showChannels = SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(metricId) && flags[FeatureFlag.ViewMetricsSocialMetrics];
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };
  /* * Social Metrics By Channel Toggle * */
  const queryOptions = {
    variables: {
      input: {
        competitiveSetId: competitiveSetID,
        brandKey: heroBrandKey,
        metricId,
        start: getDayJsStartDate(timeframe).toISOString(),
        end: dayjs(timeframe?.end).toISOString(),
      },
    },
    fetchPolicy: 'no-cache' as WatchQueryFetchPolicy,
    // Keep caching consistent with similar queries for now & will revisit once metrics summary/details are integrated
  };

  const [
    getSocialMetricsByChannel,
    {
      loading: loadingSocialMetricsByChannel,
      data: getSocialMetricsByChannelData,
    },
  ] = useLazyQuery(GET_SOCIAL_METRICS_CHANNEL, queryOptions);

  useEffect(() => {
    if (showChannels)
      getSocialMetricsByChannel();
  }, [getSocialMetricsByChannel, competitiveSetID, heroBrandKey, metricId, timeframe, showChannels]);

  const socialMetricsByChannelData: { id: number; name: string }[] = useMemo(() => {
    return prepareMetricListChannels(getSocialMetricsByChannelData);
  }, [getSocialMetricsByChannelData]);

  return (
    <li className={styles.MetricListItem}>
      {FEATURE_FLAGS.GLOBAL.METRICS_FAVORITE ? (
        <Box mr={2}>
          <FavoriteStar
            metricId={metricId}
            className={styles.IconContainer}
          />
        </Box>
      ) : null}

      <div
        className={styles.NameContainer}
      >
        <span
          onClick={() => onMetricClick(metricId)}
        >{name}</span>

        {showChannels && !loadingSocialMetricsByChannel ? (
          <AccordionControl
            isOpen={isOpen}
            handleClick={ handleClick }
          />
        ) : null}
      </div>

      {showChannels && isOpen && !loadingSocialMetricsByChannel && (
        <AccordionList
          metricId={metricId}
          listItems={socialMetricsByChannelData}
          onMetricClick={onMetricClick}
        />
        )}
    </li>
  );
}

export default MetricListItem;
