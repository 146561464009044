/* eslint-disable no-param-reassign */
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useLazyQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { BrandLogo } from '@blueoceanai/react-component-library';
import RadioDateSelector from '../../Molecules/RadioDateSelector/RadioDateSelector';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import ThemesTopic from '../../Molecules/ThemesTopic/ThemesTopic';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import ResourcesCTA from '../../Molecules/ResourcesCTA/ResourcesCTA';

import {
  INSIGHT,
  MAIN_BRAND,
  HISTORY_TOPICS,
  COMPETITOR_TOPICS,
  TOPICS_THEME,
} from '../../../constants/topics';

import {
  GET_ALL_COMPETITOR_TOPICS,
  GET_ALL_SESSION_TOPICS,
  GET_TOPICS,
} from '../../../api/queries/Pages/ContentAnalysisTopics';
import { SIZES } from '../../../constants/props';

import { isBetween } from '../../../utils/timeframe';
import BNContext from '../../../contexts/BNContext';
import styles from './ContentAnalysisTopics.module.scss';
import { CloseIcon, CounterClockwiseClockIcon } from '../../../assets/icons';

const MAX_TOPIC_LIMIT = 20;

export default function ContentAnalysisTopics() {
  const {
    competitiveSetID: _competitiveSetID,
    currentSession: sessionKey,
    accountKey: accountKey, //eslint-disable-line
    accountList,
    timeframe,
  } = useContext(BNContext);

  // main brand is selected brand from nav
  const getMainBrand = useMemo(
    () =>
      accountList[0]?.competitiveSets?.find(
        (item) => item?.competitiveSetKey === _competitiveSetID
      ),
    [_competitiveSetID, accountList]
  );

  const [mainBrandHistoryTopics, setMainBrandHistoryTopics] = useState(null);
  const [competitorTopics, setCompetitorTopics] = useState(null);
  const [showHistoryView, setShowHistoryView] = useState(null);
  const [mainBrandData, setMainBrandData] = useState(null);
  const [heroBrandSelected, setHeroBrandSelected] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState(
    getMainBrand?.brand?.brandKey || null
  );

  const resetSideScroll = () => {
    const innerBrandContainer = document.getElementById(
      'brand-inner-container'
    );
    // reset side scroll on every history view click
    innerBrandContainer.scroll(0, 0);
  };

  const handleClose = (e) => {
    e.preventDefault();
    setShowHistoryView(false);
    setSelectedBrand(getMainBrand?.brand?.brandKey);
    setHeroBrandSelected(true);

    resetSideScroll();
  };

  const handleChartIconClick = (e, key) => {
    e.preventDefault();
    setShowHistoryView(true);
    setSelectedBrand(key);
    if (key !== getMainBrand?.brand?.brandKey) {
      setHeroBrandSelected(false);
    }

    resetSideScroll();
  };

  const [getTopics, { data }] = useLazyQuery(GET_TOPICS, {
    variables: {
      id: _competitiveSetID,
      sessionKey,
      accountKey,
      type: 15,
    },
  });

  // this query returns all competitor topics and sessions
  const [
    getCompetitorTopics,
    {
      loading: competitorTopicsLoading,
      error: competitorError,
      data: fetchedCompetitorTopics,
    },
  ] = useLazyQuery(GET_ALL_COMPETITOR_TOPICS, {
    variables: {
      id: _competitiveSetID,
      sessionKey,
      lookbackDays: timeframe.quantity,
    },
  });

  // this query returns all session topics for the selected brand from the navbar. It contains ALL
  // the sessions for that competitive set, and needs to be filtered based on the date
  const [
    getAllSessionTopics,
    { loading: sessionTopicsLoading, data: sessionTopics },
  ] = useLazyQuery(GET_ALL_SESSION_TOPICS, {
    variables: {
      competitiveSetKey: _competitiveSetID,
      accountKey,
    },
  });

  const filterSessionsByDate = useCallback(
    (arr) => {
      const noDataResult = arr.map((item) => {
        const isWithinDate = isBetween(dayjs(item.sessionDate), timeframe);

        if (!isWithinDate && item.competitors) {
          // if there are no sessions in the date range & a competitor prop,
          // we know there are no topics to show as the competitor topics has a competitor prop
          item.noTopics = true;
        }

        return item;
      });

      const filteredResult = arr
        ?.filter((o) => {
          return isBetween(dayjs(o.sessionDate), timeframe);
        })
        .slice(0, 10);

      return filteredResult.length ? filteredResult : noDataResult;
      // the date ranges are limiting 10 sessions per year
    },
    [timeframe]
  );

  const extractHistoryTopics = useCallback(
    (topicsArr) => {
      return topicsArr
        .map((item) => {
          return item.competitors.filter((brand) => {
            return brand.brandKey === selectedBrand;
          });
        })
        .flat();
    },
    [selectedBrand]
  );

  const checkForAvailableTopics = useCallback(
    (topicsArr) => {
      const latestDate = topicsArr ? topicsArr[0] : null;

      const checkDate = isBetween(dayjs(latestDate?.sessionDate), timeframe);

      return !checkDate;
    },
    [timeframe]
  );

  // helper function to compare two arrays of topics and highlight differences
  const compareTopics = (currentArr, previousArr) => {
    const currentCopy = cloneDeep(currentArr);
    const previousCopy = cloneDeep(previousArr);

    const previousVals = {};
    const currentVals = {};

    const oldVals = [];
    const newVals = [];
    const sameVals = [];

    currentCopy?.forEach((item) => {
      if (!currentVals[item.text.toLowerCase()])
        currentVals[item.text.toLowerCase()] = item.text;
    });

    previousCopy?.forEach((item) => {
      if (!previousVals[item.text.toLowerCase()])
        previousVals[item.text.toLowerCase()] = item.text;
    });

    currentCopy?.forEach((item) => {
      // grab new values
      if (!previousVals[item.text.toLowerCase()]) {
        item.theme = TOPICS_THEME.new;
        newVals.push(item);
      }

      // grab same values
      if (previousVals[item.text.toLowerCase()]) {
        item.theme = TOPICS_THEME.same;
        sameVals.push(item);
      }
    });

    // grab previous values
    previousCopy?.forEach((prevItem) => {
      if (!currentVals[prevItem.text.toLowerCase()]) {
        prevItem.theme = TOPICS_THEME.old;

        oldVals.push(prevItem);
      }
    });

    return newVals.concat(sameVals).concat(oldVals);
  };

  // highlights changes between datasets
  const highlightChanges = useCallback((dataArr, flag) => {
    let result;
    const topicsMap = {};

    const clonedArr = cloneDeep(dataArr);

    const latestDate = clonedArr[0];
    const earliestDate = clonedArr[clonedArr.length - 1];

    switch (flag) {
      case MAIN_BRAND:
        clonedArr?.map((item) => {
          item.topics = compareTopics(item.topics, earliestDate.topics);

          return item;
        });
        break;

      case COMPETITOR_TOPICS:
        earliestDate?.competitors?.forEach((item) => {
          if (!topicsMap[item.name]) topicsMap[item.name] = item.topics;
        });

        latestDate?.competitors?.map((item) => {
          item.topics = compareTopics(item.topics, topicsMap[item.name]);

          return item;
        });
        break;

      case HISTORY_TOPICS:
        result = clonedArr?.map((item, index) => {
          const nextElm = clonedArr[index + 1];

          item.topics = compareTopics(item?.topics, nextElm?.topics);

          return item;
        });
        break;
      default:
        return;
    }

    if (result?.length && flag === HISTORY_TOPICS) {
      latestDate.historyTopics = result;
    }

    return latestDate;
  }, []);

  // TRANSFORMED DATASETS
  const filteredCompetitorTopics = useMemo(
    () => (competitorTopics ? filterSessionsByDate(competitorTopics) : null),
    [competitorTopics, filterSessionsByDate]
  );

  const filteredMainBrandHistoryTopics = useMemo(
    () =>
      mainBrandHistoryTopics
        ? filterSessionsByDate(mainBrandHistoryTopics)
        : null,
    [mainBrandHistoryTopics, filterSessionsByDate]
  );

  const filteredCompetitorHistoryTopics = useMemo(
    () =>
      filteredCompetitorTopics
        ? extractHistoryTopics(filteredCompetitorTopics)
        : null,
    [filteredCompetitorTopics, extractHistoryTopics]
  );

  const highlightedCompetitorHistoryTopics = useMemo(
    () =>
      filteredCompetitorHistoryTopics
        ? highlightChanges(filteredCompetitorHistoryTopics, HISTORY_TOPICS)
        : null,
    [filteredCompetitorHistoryTopics, highlightChanges]
  );

  const allBrandsTopics = useMemo(
    () =>
      filteredCompetitorTopics
        ? highlightChanges(filteredCompetitorTopics, COMPETITOR_TOPICS)
        : null,
    [filteredCompetitorTopics, highlightChanges]
  );

  const highlightedMainBrandTopics = useMemo(
    () =>
      filteredMainBrandHistoryTopics
        ? highlightChanges(filteredMainBrandHistoryTopics, MAIN_BRAND)
        : null,
    [filteredMainBrandHistoryTopics, highlightChanges]
  );

  const highlightedMainBrandHistoryTopics = useMemo(
    () =>
      filteredMainBrandHistoryTopics
        ? highlightChanges(filteredMainBrandHistoryTopics, HISTORY_TOPICS)
        : null,
    [highlightChanges, filteredMainBrandHistoryTopics]
  );

  // fetch topics, but we only need recommendations. should refactor this query
  useEffect(() => {
    if (!_competitiveSetID || !sessionKey) {
      return;
    }
    getTopics();
  }, [getTopics, _competitiveSetID, sessionKey, accountKey]);

  // fetch and sort competitor topics
  useEffect(() => {
    getCompetitorTopics();
  }, [getCompetitorTopics, _competitiveSetID, sessionKey, timeframe]);

  useEffect(() => {
    const clonedCompetitorTopics = cloneDeep(
      fetchedCompetitorTopics?.competitiveSet?.sessionsInRange
    );

    const addDateToCompetitorTopics = clonedCompetitorTopics?.map((item) => {
      const result = item.competitors.map((comp) => ({
        ...comp,
        sessionDate: item.sessionDate,
      }));
      item.competitors = result;
      return item;
    });

    // we are sorting both datasets with latest date in ascending order
    const sortedCompTopics = addDateToCompetitorTopics
      ? addDateToCompetitorTopics?.sort(
          (a, b) => new Date(b?.sessionDate) - new Date(a?.sessionDate)
        )
      : null;

    setCompetitorTopics(sortedCompTopics);
  }, [
    fetchedCompetitorTopics,
    selectedBrand,
    _competitiveSetID,
    sessionKey,
    timeframe,
  ]);

  // fetch and sort all session topics
  useEffect(() => {
    getAllSessionTopics();
  }, [getAllSessionTopics, _competitiveSetID, accountKey]);

  useEffect(() => {
    const clonedSessionTopics = cloneDeep(
      sessionTopics?.user?.account?.competitiveSet?.sessions
    );

    const addNameToSessionTopics = clonedSessionTopics?.map((sess) => {
      sess.name = sessionTopics?.user?.account?.competitiveSet?.friendlyName;
      return sess;
    });

    const sortedSessionTopics = addNameToSessionTopics
      ? addNameToSessionTopics?.sort(
          (a, b) => new Date(b?.sessionDate) - new Date(a?.sessionDate)
        )
      : null;

    if (sessionTopics?.user?.account)
      setMainBrandData(sessionTopics.user.account.competitiveSet.brand);

    setMainBrandHistoryTopics(sortedSessionTopics);
  }, [selectedBrand, _competitiveSetID, sessionTopics, timeframe]);

  useEffect(() => {
    setSelectedBrand(getMainBrand?.brand?.brandKey);
  }, [getMainBrand]);

  // brand topics map is a hash table of all available brands and their topics
  // to dynamically switch views
  const brandTopicsMap = useMemo(() => {
    const result = {};

    if ((!selectedBrand || !mainBrandData) && !isEmpty(result)) {
      return result;
    }

    const key = selectedBrand?.length ? selectedBrand : mainBrandData?.brandKey;

    allBrandsTopics?.competitors?.forEach((item) => {
      if (!result[item?.brandKey]) {
        result[item?.brandKey] = {
          data: {
            url: item.logoUrl,
            topics: item.topics,
            name: item.name,
          },
        };
      }
    });

    if (!result[key]) {
      result[key] = {
        data: {
          url: mainBrandData?.logoUrl,
          topics: highlightedMainBrandTopics?.topics,
          name: mainBrandData?.name,
        },
      };
    }

    return result;
  }, [
    allBrandsTopics,
    selectedBrand,
    mainBrandData,
    highlightedMainBrandTopics,
  ]);

  useDeepCompareEffectNoCheck(() => {
    if (competitorError) {
      console.error(competitorError); // eslint-disable-line no-console
    }
  }, [competitorError]);

  const isLoading = sessionTopicsLoading || competitorTopicsLoading;

  const noMainBrandTopicsAvailable = useMemo(
    () => checkForAvailableTopics(filteredMainBrandHistoryTopics),
    [filteredMainBrandHistoryTopics, checkForAvailableTopics]
  );

  const noCompetitorHistoryTopics = useMemo(
    () => checkForAvailableTopics(filteredCompetitorTopics),
    [filteredCompetitorTopics, checkForAvailableTopics]
  );

  const renderSelectedBrandColumn = (brand) => {
    return (
      <div
        className={`${styles.TopicColumn} ${styles.TopicColumnSelectedBrand}`}
      >
        {isLoading ? <ComponentLoader minHeight={400} /> : null}
        {!isLoading ? (
          <div className={styles.Header}>
            <div className={styles.LogoContainer}>
              {brandTopicsMap[brand]?.data?.url !== '' ? (
                <BrandLogo
                  size={SIZES.LG}
                  src={brandTopicsMap[brand]?.data?.url}
                />
              ) : (
                <BrandLogo size={SIZES.LG} src="" />
              )}

              <p>{brandTopicsMap[brand]?.data?.name}</p>
            </div>

            {!showHistoryView && (
              <div className={styles.HistoryIconContainer}>
                <CounterClockwiseClockIcon
                  className={styles.HistoryIcon}
                  alt="History Icon"
                  onClick={(e) => handleChartIconClick(e, brand)}
                />
              </div>
            )}
          </div>
        ) : null}

        {!isLoading && !noMainBrandTopicsAvailable
          ? brandTopicsMap[brand]?.data?.topics
              ?.slice(0, MAX_TOPIC_LIMIT)
              .map((topic) => (
                <ThemesTopic
                  theme={topic.theme}
                  key={topic.id}
                  topicTitle={topic.text}
                />
              ))
          : null}

        {!isLoading && noMainBrandTopicsAvailable && (
          <p>No Messages Available</p>
        )}
        <div className={styles.LegendContainer}>
          <div className={styles.Legend}>
            <div className={styles.LegendIcons}>
              <div className={styles.NewTopicsIcon} />
              <p>New Messages</p>
            </div>

            <div className={styles.LegendIcons}>
              <div className={styles.RemovedTopicsIcon} />
              <p>Removed Messages</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHistoryView = (topicsArr) => {
    return topicsArr &&
      !noCompetitorHistoryTopics &&
      !noMainBrandTopicsAvailable ? (
      topicsArr.map((topic, index) => (
        <div
          key={`${topic.id} -- ${index + 3}}`}
          className={`${styles.TopicColumn} `}
        >
          <h6>{dayjs(topic.sessionDate).format('M/D/YYYY')}</h6>
          {topic.topics.slice(0, MAX_TOPIC_LIMIT).map((item, i) => (
            <ThemesTopic
              key={`${topic.id} -- ${i + 3}}`}
              topicTitle={item.text}
              theme={item.theme}
            />
          ))}
        </div>
      ))
    ) : (
      <div className={styles.HistoryViewNoTopics}>
        <p>No Messages available</p>
      </div>
    );
  };

  return (
    <Grid
      container
      spacing={10}
      className={classNames('PageContainer', styles.ContentAnalysisTopics)}
    >
      <Grid item xs={12} md={9}>
        <Grid
          className={classNames(styles.TitleContainer, 'PageTitleContainer')}
          container
        >
          <Grid container item xs={11} alignItems="center">
            <h1 className={styles.Title}>
              What Hero Messages are Brands changing over time?
            </h1>
            <Grid container item xs={11} alignItems="center">
              <p>
                This section helps you understand which messages your
                competitors are prioritizing across the funnel - high-level
                vision statements down to direct feature/benefits CTAs - and how
                those messages are changing over time.
              </p>
              <b>Source:</b>
              <p> website homepage</p> <b> Update Frequency: </b>
              <p> every two weeks</p>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={1}
          >
            <DownloadDropdown targetId="page-wrapper" />
          </Grid>
        </Grid>

        <div className={styles.DateHeader}>
          <RadioDateSelector value={timeframe.quantity} />

          {showHistoryView && (
            <CloseIcon
              className={styles.CloseIcon}
              onClick={(e) => handleClose(e)}
            />
          )}
        </div>

        <div className={styles.BrandTopicContainer}>
          {renderSelectedBrandColumn(selectedBrand || mainBrandData?.brandKey)}

          <div
            className={styles.BrandTopicContainerInner}
            id="brand-inner-container"
          >
            {isLoading ? (
              <>
                <div className={`${styles.TopicColumn} `}>
                  <ComponentLoader minHeight={400} />
                </div>
                <div className={`${styles.TopicColumn} `}>
                  <ComponentLoader minHeight={400} />
                </div>

                <div className={`${styles.TopicColumn} `}>
                  <ComponentLoader minHeight={400} />
                </div>
              </>
            ) : null}

            {competitorError && <p>Error fetching competitor hero messages</p>}

            {!showHistoryView && !isLoading
              ? allBrandsTopics?.competitors
                  ?.filter((item) => item.brandKey !== selectedBrand)
                  .map((competitor) => (
                    <div
                      key={competitor.brandKey}
                      className={styles.TopicColumn}
                    >
                      <div className={styles.Header}>
                        <div className={styles.LogoContainer}>
                          <BrandLogo size={SIZES.LG} src={competitor.logoUrl} />
                          <p>{competitor.name}</p>
                        </div>

                        {!showHistoryView && (
                          <div className={styles.HistoryIconContainer}>
                            <button
                              onClick={(e) =>
                                handleChartIconClick(e, competitor.brandKey)
                              }
                            >
                              <CounterClockwiseClockIcon
                                className={styles.HistoryIcon}
                                alt="History Icon"
                              />
                            </button>
                          </div>
                        )}
                      </div>
                      {!allBrandsTopics.noTopics ? (
                        competitor.topics
                          .slice(0, MAX_TOPIC_LIMIT)
                          .map((topic, i) => (
                            <ThemesTopic
                              theme={topic.theme}
                              key={`${topic.id} -- ${i + 3}}`}
                              topicTitle={topic.text}
                            />
                          ))
                      ) : (
                        <p> No hero messages available</p>
                      )}
                    </div>
                  ))
              : null}

            {showHistoryView
              ? renderHistoryView(
                  heroBrandSelected
                    ? highlightedMainBrandHistoryTopics?.historyTopics
                    : highlightedCompetitorHistoryTopics?.historyTopics
                )
              : null}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <ResourcesCTA
          loading={isLoading}
          insight={INSIGHT}
          // TODO:  refactor one of the graphql queries to include recommendations
          data={data?.competitiveSet?.session?.recommendations}
          modalType="recommendations"
        />
      </Grid>
    </Grid>
  );
}
