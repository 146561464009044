import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import TalkToStrategist from '../../Molecules/TalkToStrategist/TalkToStrategist';
import ModalDrawer from '../../Molecules/ModalDrawer/ModalDrawer';
import TalkToStrategistIcon from '../../Atoms/TalkToStrategistIcon/TalkToStrategistIcon';

import styles from './TalkToStrategistModal.module.scss';

export default function TalkToStrategistModal({
  className,
  open,
  onClose,
  ...props
}) {
  return (
    <ModalDrawer
      title="Talk to a Strategist"
      open={open}
      onClose={onClose}
      icon={TalkToStrategistIcon}
      className={classNames(styles.TalkToStrategistModal, className)}
      {...props}
    >
      <TalkToStrategist onCancel={onClose} />
    </ModalDrawer>
  );
}

TalkToStrategistModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
