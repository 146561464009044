import React from 'react';
import PropTypes from 'prop-types';

import useRouter from '../../../hooks/useRouter';

import styles from './LeftNavItemSubNav.module.scss';

export default function LeftNavItemSubNav(props) {
  const { updateRoute } = useRouter();
  const { openSubNav } = props;
  const isOpen = openSubNav === props.name;
  const isTransitional = !isOpen && openSubNav !== null;

  return (
    <div
      className={`${!isOpen ? styles.LeftNavItemSubNav : ''} ${
        isOpen ? styles.LeftNavItemSubNavOpen : ''
      }
      ${isTransitional ? styles.LeftNavItemSubNavTransitional : ''}`}
    >
      <div
        className={`${styles.inner} ${
          isOpen ? styles.innerOpen : styles.innerClosed
        }`}
      >
        <div
          onClick={() =>
            updateRoute({ pathname: props.pathname, params: props.params })
          }
        >
          <h5>{props.headerName}</h5>
        </div>
        <ul className={`${isOpen ? styles.subNavListOpen : styles.subNavList}`}>
          {props.navItems.map((subNavItem) => {
            if (subNavItem.children) {
              return (
                <div key={subNavItem.name}>
                  <div
                    onClick={() =>
                      updateRoute({
                        pathname: subNavItem.pathname,
                        params: subNavItem.params,
                      })
                    }
                    className={styles.groupHeaderLink}
                  >
                    <span
                      key={`${subNavItem.name}-head`}
                      className={styles.groupHeader}
                    >
                      {subNavItem.name}
                    </span>
                  </div>
                  <ul className={styles.subNavNestedList}>
                    {subNavItem.children.map((child) => (
                      <li
                        key={`${props.headerName}-${subNavItem.name}-${child.name}`}
                      >
                        <div
                          className={styles.subNavLink}
                          onClick={() =>
                            updateRoute({
                              pathname: child.pathname,
                              params: child.params,
                            })
                          }
                        >
                          {child.customText}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            }
            return (
              <li key={subNavItem.name}>
                <div
                  className={styles.item}
                  onClick={() =>
                    updateRoute({
                      pathname: subNavItem.pathname,
                      params: subNavItem.params,
                    })
                  }
                >
                  {subNavItem.name}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

LeftNavItemSubNav.propTypes = {
  pathname: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  params: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  openSubNav: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),

  name: PropTypes.string,
  /**
   *  nav section name.
   */
  headerName: PropTypes.string,
  /**
   * array of items used to populate the nav. A configurable list of nav items.
   */
  navItems: PropTypes.arrayOf(PropTypes.shape({})),
};
