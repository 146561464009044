export const prepareProfileBrands = (brands, lastLogin) => {
  const brandMap = brands.map((brand) => ({
    brand: brand.brand,
    role: brand.role,
    lastLogin,
  }));
  return brandMap;
};

export const prepareProfileSubscriptions = (subscriptions) => {
  const subMap = subscriptions.map((subscription) => ({
    label: subscription.name,
    value: subscription.isActive === 1,
    key: subscription.name.toLowerCase().replaceAll(' ', '-'),
  }));
  return subMap;
};

export const prepareProfileAvatarInitials = (name) => {
  if (!name) return;
  if (name.trim().indexOf(' ') > -1) {
    const s = name.split(' ');
    return s[0][0] + s[1][0];
  }
  return name[0];
};
