import React, { useState, useEffect, useMemo } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import dayjs from 'dayjs';

import { Button } from '@blueoceanai/react-component-library';
import Table from '../../Molecules/Table/Table';
import SettingsAddMarkerEventModal from '../SettingsAddMarkerEventModal/SettingsAddMarkerEventModal';
import SettingsEditMarkerEventModal from '../SettingsEditMarkerEventModal/SettingsEditMarkerEventModal';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';

import { BUTTON_VARIANTS } from '../../../constants/props';
import {
  GET_PROFILE_MARKERS,
  UPSERT_MARKER,
} from '../../../api/queries/Pages/ProfileMarkerEvents';
import { prepareProfileMarkerEvents } from '../../../api/transforms/Organisms/ProfileMarkerEvents';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import BNContext from '../../../contexts/BNContext';

import styles from './ProfileMarkerEvents.module.scss';

export default function ProfileMarkerEvents({ className, ...props }) {
  const { competitiveSetID, accountKey, FEATURE_FLAGS } =
    React.useContext(BNContext);
  const [rowIdxToDelete, setRowIdxToDelete] = useState(null);

  const [getProfileMarkers, { error, loading, data: markersData, refetch }] =
    useLazyQuery(GET_PROFILE_MARKERS, {
      variables: {
        id: competitiveSetID,
      },
    });

  const [markerEvents, setMarkerEvents] = useState([]);
  const [deleteMarker] = useMutation(UPSERT_MARKER);
  const [addMarker] = useMutation(UPSERT_MARKER);
  const [updateMarker] = useMutation(UPSERT_MARKER);

  useEffect(() => {
    if (!markersData) getProfileMarkers();
  }, [getProfileMarkers, markersData]);

  useEffect(() => {
    if (markersData?.user?.markers) {
      setMarkerEvents(prepareProfileMarkerEvents(markersData.user.markers));
    }
  }, [setMarkerEvents, markersData]);

  const [openAddMarkerEvent, setOpenAddMarkerEvent] = useState(false);
  const [openEditMarkerEvent, setOpenEditMarkerEvent] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const [defaultValues, setDefaultValues] = useState(null);

  const displayToOptions = useMemo(() => {
    const result = [];
    if (FEATURE_FLAGS.GLOBAL.MARKERS.EDIT.ACCOUNT) {
      result.push({
        label: 'Account',
        value: 'account',
      });
    }

    if (FEATURE_FLAGS.GLOBAL.MARKERS.EDIT.BRAND) {
      result.push({
        label: 'Brand',
        value: 'brand',
      });
    }
    return result;
  }, [FEATURE_FLAGS]);

  function handleMarkerChange({ rowIdx, key, newVal }) {
    const results = cloneDeep(markerEvents);

    results[rowIdx][key] = newVal;

    setMarkerEvents(results);
    updateMarker({
      variables: {
        id: Number(markerEvents[rowIdx].id),
        label: markerEvents[rowIdx].label,
        markerEventDate: markerEvents[rowIdx].eventDate,
        accountKey,
        competitiveSetKey: competitiveSetID,
        isPrivate: markerEvents[rowIdx].private,
        isAccountLevel: newVal === 'brand' ? 0 : 1,
      },
    });
  }

  function handleMarkerRemove(rowIdx) {
    setRowIdxToDelete(rowIdx);
    setOpenModalConfirm(true);
  }

  // function handleMarkerRemove(rowIdx) {
  //   const confirm = window.confirm(
  //     'Are you sure you want to delete this marker event?',
  //   );

  //   if (confirm) {
  //     const result = cloneDeep(markerEvents);

  //     remove(result, (user, idx) => idx === rowIdx);

  //     deleteMarker({
  //       variables: {
  //         id: Number(markerEvents[rowIdx].id),
  //       },
  //     });

  //     setMarkerEvents(result);
  //   }
  // }

  async function handleModalConfirm() {
    try {
      setOpenModalConfirm(false);
      const result = cloneDeep(markerEvents);

      remove(result, (user, idx) => idx === rowIdxToDelete);

      deleteMarker({
        variables: {
          id: Number(markerEvents[rowIdxToDelete].id),
        },
      });
      setMarkerEvents(result);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
  function handleMarkerEdit(rowIdx) {
    const row = markerEvents[rowIdx];

    setDefaultValues({
      id: row.id,
      eventTitle: row.label,
      showEvent: row.displayTo,
      eventDate: row.eventDate,
      private: row.private,
    });

    setOpenEditMarkerEvent(true);
  }
  async function handleMarkerEditSuccess(data) {
    const label = data.eventTitle;
    const date = data.eventDate;
    await updateMarker({
      variables: {
        id: Number(data.hdnId),
        competitiveSetKey: competitiveSetID,
        accountKey,
        label,
        markerEventDate: dayjs(date).toISOString(),
        isPrivate: data.private === true ? 1 : 0,
        isAccountLevel: data.showEvent === 'brand' ? 0 : 1,
      },
    });
    refetch();
  }
  async function handleMarkerAddSuccess(data) {
    const label = data.eventTitle;
    const date = data.eventDate;
    await addMarker({
      variables: {
        competitiveSetKey: competitiveSetID,
        accountKey,
        label,
        markerEventDate: dayjs(date).toISOString(),
        isPrivate: data.private === true ? 1 : 0,
        isAccountLevel: data.showEvent === 'brand' ? 0 : 1,
      },
    });
    refetch();
  }

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <div
      className={classNames(styles.ProfileMarkerEvents, className)}
      {...props}
    >
      <div className={styles.Header} data-cy="btn-add-marker-event">
        <h3>My Marker Events</h3>
        <Button
          variant={BUTTON_VARIANTS.PRIMARY}
          onClick={() => setOpenAddMarkerEvent(true)}
        >
          Add Marker Event
        </Button>
      </div>
      <div className={styles.Body}>
        {loading ? <ComponentLoader minHeight={400} /> : null}

        <div className={styles.Table} data-cy="tbl-my-marker-events">
          <Table
            items={markerEvents}
            onChange={handleMarkerChange}
            onEdit={handleMarkerEdit}
            onRemove={handleMarkerRemove}
            fields={[
              {
                key: 'label',
              },
              {
                key: 'eventDate',
                label: 'Event Date',
                format: (time) => dayjs(time).format('l'),
              },
              {
                key: 'displayTo',
                label: 'Display To',
              },
              {
                key: 'addedDate',
                label: 'Date added',
                format: (time) => dayjs(time).format('l'),
              },
            ]}
            options={{ displayTo: displayToOptions }}
          />
        </div>
      </div>

      <SettingsEditMarkerEventModal
        onClose={() => setOpenEditMarkerEvent(false)}
        defaultValues={defaultValues}
        open={openEditMarkerEvent}
        onAddMarkerSuccess={handleMarkerEditSuccess}
      />

      <SettingsAddMarkerEventModal
        onClose={() => setOpenAddMarkerEvent(false)}
        open={openAddMarkerEvent}
        onAddMarkerSuccess={handleMarkerAddSuccess}
      />

      <ModalConfirm
        title="Delete Marker"
        open={openModalConfirm}
        message="Are you sure you want to delete this marker?"
        helper="This action cannot be undone."
        onCancel={() => setOpenModalConfirm(false)}
        onConfirm={handleModalConfirm}
      />
    </div>
  );
}

ProfileMarkerEvents.propTypes = {
  className: PropTypes.string,
};
