import { useState, useMemo, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import WidgetSubheader from '../../WidgetSubheader/WidgetSubheader';
import CompetitionGradientBar from '../../../../Molecules/CompetitionGradientBar/CompetitionGradientBar';
import { timeRangeById } from '../../WidgetSettingsShared';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/DataManager';
import { GET_STACK_RANKING_WIDGET_DATA } from '../../../../../api/queries/Pages/CustomizableDashboards';
import { handleConfigInputArrayFromDashboardAPI } from '../../WidgetSettingsFlows/helper';

import { WidgetMetaResponse, StackRankingWidgetResp } from '../../types';
import { formatStackRankingData } from '../widgetFormatResponse';
import { TimeRange, WidgetType } from '../../../../../interfaces/dashboard-api';

import styles from './StackRankingWidget.module.scss';
import LoadingWidget from '../LoadingWidget/LoadingWidget';

export type StackRankingWidgetProps = {
  config: WidgetMetaResponse;
  globalDateFilter: {
    endDate: Date;
    timeRange: TimeRange | null;
  };
};

const StackRankingWidget = ({
  config,
  globalDateFilter,
}: StackRankingWidgetProps) => {
  const [activeEndDate, setActiveEndDate] = useState<Date>(
    config?.data_srcs?.metric?.input?.endDate
      ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
      : new Date()
  );
  const [activeTimeRange, setActiveTimeRange] = useState<TimeRange>(
    config.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
  );

  const [getStackRankingData, { loading, data }] = useLazyQuery(
    GET_STACK_RANKING_WIDGET_DATA,
    { fetchPolicy: 'no-cache' }
  );
  const [getFirstLastSession, { data: sessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );

  useEffect(() => {
    if (globalDateFilter?.timeRange && globalDateFilter?.endDate) {
      setActiveTimeRange(globalDateFilter.timeRange);
      setActiveEndDate(globalDateFilter.endDate);
    } else {
      setActiveTimeRange(
        config?.data_srcs?.metric?.time_range ?? TimeRange['90_DAYS']
      );
      setActiveEndDate(
        config?.data_srcs?.metric?.input?.endDate
          ? new Date(config?.data_srcs?.metric?.input?.endDate as Date)
          : new Date()
      );
    }
  }, [globalDateFilter, config]);

  useEffect(() => {
    const competitiveSetKey =
      config?.data_srcs?.metric?.input?.competitiveSetKey;

    if (!competitiveSetKey) return;

    getFirstLastSession({ variables: { id: competitiveSetKey } });
  }, [config, getFirstLastSession]);

  useEffect(() => {
    const competitiveSetKey =
      config?.data_srcs?.metric?.input?.competitiveSetKey;
    const metric = config?.options?.metric;
    // TODO: get session key based on custom date range
    const latestSessionKey =
      sessionResp?.competitiveSet?.firstAndLastSession?.[1]?.sessionKey;

    if (!competitiveSetKey || !metric || !latestSessionKey) return;

    getStackRankingData({
      variables: {
        id: competitiveSetKey,
        sessionKey: latestSessionKey,
        input: {
          associatedTypeId: metric.var_id,
          range: timeRangeById[activeTimeRange].value,
          endDate: activeEndDate,
          compSetKey: competitiveSetKey,
        },
      },
    });
  }, [
    activeTimeRange,
    activeEndDate,
    sessionResp,
    config,
    getStackRankingData,
  ]);

  const preparedStackRankingData = useMemo(() => {
    if (loading || !data) return null;

    const selectedBrandKeys = handleConfigInputArrayFromDashboardAPI(
      config?.options?.selected_brand_keys
    ) as string[];

    return formatStackRankingData({
      respData: data as StackRankingWidgetResp,
      allowedBrandKeys: selectedBrandKeys,
      heroBrandKey: config?.data_srcs?.metric?.input?.brandKey,
    });
  }, [data, config, loading]);

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    setActiveTimeRange(e.timeRange ?? TimeRange['90_DAYS']);
    setActiveEndDate(e.endDate);
  };

  return (
    <div className={styles.StackRankingWidget}>
      <WidgetSubheader
        activeTimeRange={activeTimeRange}
        handleTimeRangeChange={setActiveTimeRange}
        activeEndDate={activeEndDate}
        onCustomTimeRangeChange={handleApply}
      />

      {loading ? (
        <LoadingWidget widgetType={WidgetType.StackRankingV1} />
      ) : (
        <CompetitionGradientBar
          topPins={preparedStackRankingData?.topPins ?? []}
          bottomPins={preparedStackRankingData?.bottomPins ?? []}
          withTooltip
        />
      )}
    </div>
  );
};

export default StackRankingWidget;
