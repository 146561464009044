import { useContext, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import BNContext from '../contexts/BNContext';
import { BrandDropdownOption } from '../interfaces/brand-options';

export default function useCompetitiveSetOptions() {
  const { accountList } = useContext(BNContext);

  return useMemo(() => {
    const result: BrandDropdownOption<string>[] = [];

    if (!accountList?.length) {
      return result;
    }

    accountList?.forEach((account) => {
      account.competitiveSets.forEach((competitiveSet) => {
        result.push({
          label: competitiveSet.friendlyName,
          value: competitiveSet.competitiveSetKey,
        });
      });
    });

    return sortBy(result, 'label');
  }, [accountList]);
}
