import { useState, useEffect, useContext, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

import dayjs from 'dayjs';
import RadioDateSelector from '../../Molecules/RadioDateSelector/RadioDateSelector';
import BluescoreTimelineChart from '../../Charts/BluescoreTimelineChart/BluescoreTimelineChart';
import ScoreLegend from '../../Molecules/ScoreLegend/ScoreLegend';
import GraphHintOptionsDropdown from '../../Molecules/GraphHintOptionsDropdown/GraphHintOptionsDropdown';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import ChartLegend from '../../Molecules/ChartLegend/ChartLegend';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';

import { CHART_LEGEND_ICON_VARIANT } from '../../../constants/props';
import BNContext from '../../../contexts/BNContext';

import { GET_MARKERS } from '../../../api/queries/Molecules/MarkersWrapper';
import { UPSERT_MARKER } from '../../../api/queries/Pages/ProfileMarkerEvents';
import { prepareMarkers } from '../../../api/transforms/Molecules/MarkersWrapper';

import { handleGenericError } from '../../../utils/error';

import style from './MarketIndexTrend.module.scss';

function MarketIndexTrend({
  sectionDescription,
  customerScoresOverTime,
  loading,
  graphScale,
  ...props
}) {
  const { competitiveSetID, accountKey } = useContext(BNContext);

  const [showFlags, setShowFlags] = useState(true);

  const queryOptions = {
    variables: {
      id: competitiveSetID,
      accountKey,
    },
    fetchPolicy: 'no-cache',
  };

  const [
    getMarkers,
    { error, loading: fetchingMarkers, data: markersResp, refetch },
  ] = useLazyQuery(GET_MARKERS, queryOptions);

  const [addMarker] = useMutation(UPSERT_MARKER);

  useEffect(() => {
    if (!competitiveSetID) {
      return;
    }

    getMarkers();
  }, [competitiveSetID, getMarkers]);

  const flagData = useMemo(() => {
    if (!markersResp) {
      return [];
    }

    return handleGenericError(
      () => prepareMarkers(markersResp),
      'MarketIndexTrend prepareMarkers failed transform'
    );
  }, [markersResp]);

  const isLoading = useMemo(
    () => loading || fetchingMarkers || !flagData,
    [loading, fetchingMarkers, flagData]
  );

  async function handleAddMarkerSuccess(data) {
    const label = data.eventTitle;
    const date = data.eventDate;
    await addMarker({
      variables: {
        competitiveSetKey: competitiveSetID,
        accountKey,
        label,
        markerEventDate: dayjs(date).toISOString(),
        isPrivate: data.private === true ? 1 : 0,
        isAccountLevel: data.showEvent === 'brand' ? 0 : 1,
      },
    });
    refetch();
  }

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <Box className={style.MarketIndexTrend} id="market-index-trend" {...props}>
      <Grid container alignItems="center" className="SubtitleContainer">
        <Grid item xs={11}>
          <h2 className={style.Title}>Blue Score Trend</h2>
        </Grid>
        <Grid
          container
          item
          xs={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <div className="DownloadButtonContainer">
            <DownloadDropdown
              targetId="market-index-trend"
              json={customerScoresOverTime}
              fileName="market-index-trend"
              padding={100}
            />
          </div>
        </Grid>
      </Grid>

      <Box className={style.SectionDescription}>
        {sectionDescription.map((paragraph, index) => (
          <Box
            key={paragraph.substring(0, 4)}
            mb={index < sectionDescription.length - 1 ? 4 : 0}
          >
            <p className={style.Paragraph}>{paragraph}</p>
          </Box>
        ))}
      </Box>

      {isLoading ? (
        <ComponentLoader minHeight={304} />
      ) : (
        <Box className={style.DateSelector} mb={10}>
          <RadioDateSelector />
        </Box>
      )}

      {!isLoading ? (
        <>
          <div
            data-testid="market-index-bluescore-container"
            className={style.GraphContainer}
          >
            {!isLoading && !customerScoresOverTime ? (
              <NoResultsAlert />
            ) : (
              <>
                <div
                  className="hidden-pendo-anchor"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                />
                <BluescoreTimelineChart
                  id="market-index-overview-bluescore-trend-chart"
                  showTooltips
                  customerScoresOverTime={customerScoresOverTime}
                  flags={flagData}
                  showFlags={showFlags}
                  yDomainOverride={graphScale?.yDomain}
                />
              </>
            )}
          </div>
          <div
            data-testid="market-index-bluescore-legend"
            className={style.GraphSupport}
          >
            <Box mb={3} className={style.GraphLegend}>
              <ChartLegend
                items={[
                  {
                    variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                    label: 'Your Score',
                  },
                  {
                    variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
                    label: 'Industry Average',
                  },
                ]}
              />
              <GraphHintOptionsDropdown
                onAddMarkerSuccess={handleAddMarkerSuccess}
                showMarkerEvent={showFlags}
                onShowMarkerEvent={() => setShowFlags(true)}
                onHideMarkerEvent={() => setShowFlags(false)}
              />
            </Box>
            <ScoreLegend />
          </div>
        </>
      ) : null}
    </Box>
  );
}

MarketIndexTrend.propTypes = {
  sectionDescription: PropTypes.arrayOf(PropTypes.string),
  customerScoresOverTime: PropTypes.arrayOf(
    PropTypes.shape({
      score: PropTypes.number,
      date: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  graphScale: PropTypes.shape({
    yDomain: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
};

export default memo(MarketIndexTrend);
