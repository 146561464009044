import { FC, AnchorHTMLAttributes } from 'react';
import 'chartjs-adapter-dayjs-4';
import DifferencePill from '../DifferencePill/DifferencePill';
import styles from './CreativeAdvertisingChartContainer.module.scss';
import { abbreviateNumber } from '../../../../utils/number';

export interface CreativeAdvertisingChartContainerProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  title: string;
  total: number;
  percent: number;
  displayPercent?: boolean;
}

const CreativeAdvertisingChartContainer: FC<
  CreativeAdvertisingChartContainerProps
> = ({ title, total, percent, children, displayPercent }) => {
  return (
    <div className={styles.ChartContainer}>
      <div className={styles.Inner}>
        <span className={styles.Heading}>
          <p className={styles.Title}>{title}</p>
          <span className={styles.Totals}>
            <p className={styles.Total}>
              {displayPercent ? `${total}%` : `$${abbreviateNumber(total, 2)}`}
            </p>
            <DifferencePill iconOnly xl percent={percent} />
          </span>
        </span>
        {children}
      </div>
    </div>
  );
};

export default CreativeAdvertisingChartContainer;
