import {
  ComparisonType,
  ICapabilities,
  WidgetType,
} from '../../../interfaces/dashboard-api';
import { ScoreType } from '../../../interfaces/metric';
import SingleMetricWidgetExample from '../../../assets/images/widget-settings/SingleMetricWidgetExample.jpg';
import GroupMetricWidgetExample from '../../../assets/images/widget-settings/GroupMetricWidgetExample.jpg';
import ESOVWidgetExample from '../../../assets/images/widget-settings/ESOVWidgetExample.jpg';
import PaceAnalysisExample from '../../../assets/images/widget-settings/PaceAnalysisExample.png';
import SummaryTrendsExample from '../../../assets/images/widget-settings/SummaryTrendsExample.png';
import StackRankingExample from '../../../assets/images/widget-settings/stack-ranking/StackRanking@2x.png';
import VisualMapsHeatmapExample from '../../../assets/images/widget-settings/visual-maps/VisualMapsHeatmapExample.png';
import MetricComparisonWidgetExample from '../../../assets/images/widget-settings/MetricComparisonWidgetExample.png';

// TODO: update types to use this below
export interface WidgetBrand {
  brandKey: string;
  logoUrl: string;
  name: string;
}

export interface ActiveBluescoreBrand {
  [id: string]: boolean;
}

export interface BlueScoreData {
  brands: BlueScoreGraphSet[];
  markers: WidgetPointData[];
  brandsSummary: BlueScoreBrandsSummary[];
  yDomain: BlueScoreGraphYDomain;
}

export interface BlueScoreGraphSet {
  id: number;
  name: string;
  data: WidgetPointData[];
}

export interface BlueScoreGraphYDomain {
  min: number;
  max: number;
  first: string;
  last: string;
  labels: Date[];
}

export interface BlueScoreResponse {
  brand: {
    id: number;
    logoUrl: string;
    name: string;
  };
  session: {
    competitors: {
      id: number;
      logoUrl: string;
      name: string;
    }[];
    competitorsLastPeriodScores: {
      id: number;
      t90Days: string;
    }[];
    bluescore: {
      t90Days: string;
    }[];
  };
  markers: {
    label: string;
    markerEventDate: string;
  }[];
}

export interface FormattedMetricComparisonData {
  brands: MetricComparisonGraphSet[];
  markers: MetricComparisonPointData[];
  brandsSummary: BlueScoreBrandsSummary[];
  yDomain: MetricComparisonYDomain;
  selectedMetricName: string;
}

export interface MetricComparisonYDomain {
  min: number;
  max: number;
  first: string;
  last: string;
  labels: string[];
}

export interface MetricComparisonGraphSet {
  id: string;
  name: string;
  data: MetricComparisonPointData[];
}

export interface MetricComparisonChartPoint {
  date: string;
  value: number;
}

export interface MetricComparisonResponse {
  brands: MetricComparisonBrand[];
  selectedMetricName: string;
  selectedMetricDescription: string;
  markers: {
    label: string;
    date: string;
  }[];
}

export interface MetricComparisonPointData {
  id: string;
  x: string;
  y: number;
  description: string;
}

export interface WidgetPointData {
  x: Date;
  y: number;
  id: string;
  description: string;
}

export interface MetricComparisonBrand {
  brandKey: number;
  logoUrl: string;
  name: string;
  relativeScoreDelta: number;
  relativeScoreTile: number;
  relativeTimeSeries: MetricComparisonChartPoint[];
}

export interface BlueScoreBrandsSummary {
  id: number;
  name: string;
  logoUrl: string;
  latestScore: number;
  difference: number;
  isActive: boolean;
}

export enum DashboardActionType {
  Delete = 'delete-dashboard',
  Duplicate = 'duplicate-dashboard',
  Share = 'share-dashboard',
}

export interface DashboardActionHandler {
  (id: string): void;
}

export interface DashboardDropdownItem {
  name: string;
  id: string;
}

export interface DataExplanationCalculation {
  id: string;
  imgSrc: string;
  text: string;
}

export interface DataExplanationContent {
  calculations: DataExplanationCalculation[];
}

export const DraggableHandleClass = 'widget-drag';

export interface Item {
  id: string | number;
  name: string;
}

export interface Group {
  id: string | number;
  name: string;
  description: string;
  items: Item[];
}

export enum FunctionalAreasView {
  List = 'Simple List',
  Grid = 'Detailed Grid',
}

export interface MetricData {
  date: string;
  value: number;
}

// TODO: deprecate in favor of MetricResponseData
export interface MetricDetail {
  id: string;
  name: string;
}

export interface SingleMetricWidgetData {
  relativeScoreTile: number;
  relativeScoreDelta: number;
  isolatedScoreTile: number;
  isolatedScoreDelta: number;
  isolatedCohortComparison: number;
  isolatedChangeRate: number;
  relativeTimeSeries: MetricData[];
  isolatedTimeSeries: MetricData[];
}

export interface SingleWidgetGraphData {
  yDomain: {
    labels: string[];
    first: string;
    last: string;
    showRawData: boolean;
    showIndexData: boolean;
  };
  datasets: WidgetPointData[][];
}

export interface ExcessShareOfVoiceData {
  loading: boolean;
  error: unknown;
  data: {
    [key: string]: {
      revenue: {
        value: number;
        adjusted: number;
        ratio: number;
      };
      adSpend: {
        value: number;
      };
      shareOfVoice: number;
      shareOfMarket: number;
      excessShareOfVoice: number;
      excessShareOfVoiceImpact: number;
      excessShareOfVoiceInsight: {
        direction: string;
        shareOfMarket: number;
        spendMultiple: number;
        position: string;
        esovSetRanking: string;
      };
    };
  };
}

export interface FormattedESOVdata {
  brand?: string;
  brandKey?: string;
  logoUrl?: string;
  shareOfVoice?: number;
  shareOfMarket?: number;
  excessShareOfVoice?: number;
}

export interface SummaryMetric {
  variableId: string;
  name: string;
  description: string;
  relativeScoreTile: number;
  relativeScoreDelta: number;
  isolatedScoreTile: number;
  isolatedScoreDelta: number;
  isolatedCohortRate: number;
  isolatedChangeRate: number;
}

export interface TrendingSummaryResponse {
  data: {
    competitiveSet: {
      session: {
        trendSummaryWidget: {
          insights: string;
          name: string;
          logoUrl: string;
          metricsTrendingUp: SummaryMetric[];
          metricsTrendingDown: SummaryMetric[];
        };
      };
    };
  };
}

export interface WidgetLayout {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface WidgetConfig {
  layout: {
    [key: string]: unknown;
  };
  data_sources: {
    [key: string]: unknown;
  };
}

export interface ExampleImages {
  [key: string]: string;
}

export const widgetExampleImages: ExampleImages = {
  [WidgetType.ExcessShareOfVoiceV1]: ESOVWidgetExample,
  [WidgetType.MetricComparisonV1]: MetricComparisonWidgetExample,
  [WidgetType.MetricCollectionV1]: GroupMetricWidgetExample,
  [WidgetType.MetricAnalysisV1]: SingleMetricWidgetExample,
  [WidgetType.PaceAnalysisV1]: PaceAnalysisExample,
  [WidgetType.SummaryTrendsV1]: SummaryTrendsExample,
  [WidgetType.StackRankingV1]: StackRankingExample,
  [WidgetType.VisualMapsV1]: VisualMapsHeatmapExample,
};

// TODO: add a type so we don't use any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ConfigOption = any;

export interface WidgetMetaResponse {
  // TODO: add and set type when confirm works for first 3 widgets
  // data_srcs: IWidgetDataSources;
  data_srcs: { [key: string]: ConfigOption };
  description: string;
  id: string;
  layout: string;
  name: string;
  owner_dashboard_id: string;
  type_id: string;
  widget_id: string;
  options: { [key: string]: ConfigOption };
}

export enum DashboardManagementView {
  All,
  MyDashboards,
  SharedWithMe,
}

export interface DashboardUser {
  userKey?: string;
  id?: string;
  name: string;
  email: string;
}

export interface DashboardResponse {
  created_at: number;
  description: string;
  id: string;
  last_viewed_at: number;
  layout: Record<string, string>;
  name: string;
  updated_at: number;
  user_id: string;
  widgets: Record<string, WidgetMetaResponse>;
  capabilities: ICapabilities;
  shared_with?: string;
}

export interface SortedDashboardResponse {
  myDashboards: ShareableDashboard[];
  sharedDashboards: ShareableDashboard[];
  all: ShareableDashboard[];
  count: number;
}

export interface ShareableDashboard extends DashboardResponse {
  ownerName: string;
  isCurrentUserOwner: boolean;
  sharedWithUsers: DashboardUser[];
  isDashboardShared: boolean;
}

export interface DashboardListResponse {
  dashboards: ShareableDashboard[];
  count: number;
}

export enum WidgetSettingsModalAction {
  Add = 'add',
  Edit = 'edit',
}

export interface WidgetTemplate {
  id: string;
  name: string;
  description: string;
  config: WidgetConfig;
  type_id: WidgetType;
  created_at: number;
  created_by: string;
  updated_at: number;
  updated_by: string;
}

export interface WidgetTemplateResponse {
  templates: WidgetTemplate[];
  count: number;
}

export interface BrandDropdownOption {
  label: string;
  value: string;
  logoUrl?: string;
}

export interface ScoreTypeDropdownOption {
  label: string;
  value: ScoreType;
}

export interface ComparisonTypeDropdownOption {
  label: string;
  value: ComparisonType;
}

export interface PaceGraphSet {
  id: string;
  name: string;
  data: WidgetPointData[];
}

export interface PaceAnalysisData {
  brandKey: string;
  name: string;
  logoUrl: string;
  relativeTimeSeries: { date: string; value: number }[];
  isolatedTimeSeries: { date: string; value: number }[];
  relativeScoreTile: number;
  relativeScoreDelta: number;
  relativeCohortComparison: number;
  relativeChangeRate: number;
  relativeScoreInsights: string;
  isolatedScoreTile: number;
  isolatedScoreDelta: number;
  isolatedCohortComparison: number;
  isolatedChangeRate: number;
  isolatedScoreInsights: string;
}

export interface PaceAnalysisBrand {
  brand: {
    key: string;
    name: string;
    logoUrl: string;
  };
  tile: {
    value: number;
    valueDelta: number;
    changeRate: number;
    cohortComparison: number;
  };
  graph: {
    data: MetricData[];
  };
  insight: string;
  label?: string;
}

// TODO: deprecate PaceAnalysisBrandData in favor of PaceAnalysisBrand
export interface PaceAnalysisBrandData {
  name: string;
  brandKey: string;
  logoUrl: string;
  scoreTile: number;
  scoreDelta: number;
  cohortComparison: number;
  changeRate: number;
  insights: string;
}

export interface GetPaceAnalysisWidgetDataResp {
  competitiveSet: {
    session: {
      paceAnalysisWidget: {
        start: string;
        end: string;
        rivalRelativeBrands: PaceAnalysisData[];
        rivalIsolatedBrands: PaceAnalysisData[];
        leadingRelativeBrands: PaceAnalysisData[];
        leadingIsolatedBrands: PaceAnalysisData[];
        closeRelativeCompetitors: PaceAnalysisData[];
        closeIsolatedCompetitors: PaceAnalysisData[];
      };
    };
  };
}

export interface StackRankingWidgetInput {
  associatedTypeId: string;
  range: number;
  competitiveSetKey: string;
}

export interface StackRankingWidgetResp {
  competitiveSet: {
    session: {
      stackRankingWidget: {
        selectedMetricName: string;
        selectedMetricDescription: string;
        brands: {
          brandKey: string;
          name: string;
          logoUrl: string;
          relativeScoreTile: number;
          relativeScoreDelta: number;
        }[];
      };
    };
  };
}

export enum ScoreMapType {
  WaterMap = 'WATERMAP',
  HeatMap = 'HEATMAP',
}

export enum LevelType {
  Factor = 'Factor',
  SubFactor = 'SubFactor',
  ScoreDrivers = 'ScoreDrivers',
}

export interface MetricVisualMap {
  name: string;
  description: string;
  variableId: string;
  level: LevelType;
  showRawScore: boolean;
  relativeScoreTile: number;
  relativeScoreDelta: number;
  relativeChangeRate: number;
  relativeCohortComparison: number;
  isolatedScoreTile: number;
  isolatedScoreDelta: number;
  isolatedChangeRate: number;
  isolatedCohortComparison: number;
}

export interface VisualMappingWidgetData {
  brandKey: string;
  name: string;
  logoUrl: string;
  metrics: MetricVisualMap[];
}

export interface MappingMetric {
  name: string;
  description: string;
  level: LevelType;
  variableId: string;
}

export interface VisualMapData {
  brandKey: string;
  name: string;
  logoUrl: string;
  relativeScoreTile: number;
  relativeScoreDelta: number;
  relativeChangeRate: number;
  relativeCohortComparison: number;
  isolatedScoreTile: number;
  isolatedScoreDelta: number;
  isolatedChangeRate: number;
  isolatedCohortComparison: number;
}

export interface VisualMapResponse {
  competitiveSet: {
    session: {
      visualMapWidget: {
        visualMapMetrics: {
          name: string;
          description: string;
          variableId: string;
          visualMapBrands: VisualMapData[];
        }[];
      };
    };
  };
}

export interface DashboardTemplate {
  type: string;
  version: string;
  title: string;
  summary: string;
  screenshot: string;
  is_enabled: boolean;
}

export interface DashboardTemplateResponse {
  templates: DashboardTemplate[];
  count: number;
}

export enum DashboardTemplateType {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
}

export interface TemplateSelectionData {
  competitiveSetKey: string;
  brandKey: string;
  brandName: string;
  selectedBrandKeys: string[];
}

export interface UserInfo {
  email: string;
  name: string;
  userKey: string;
}

export interface UserSettingsData {
  user: {
    accounts: {
      users: UserInfo[];
    }[];
  };
}
