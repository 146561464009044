import { useState, useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';

import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';

import ScoreTile, {
  ScoreTileSize,
} from '../../ScoreDisplays/ScoreTile/ScoreTile';
import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import ChartLegend from '../ChartLegend/ChartLegend';
import RadioDateSelector from '../RadioDateSelector/RadioDateSelector';
import BluescoreTimelineChart from '../../Charts/BluescoreTimelineChart/BluescoreTimelineChart';
import GraphHintOptionsDropdown from '../GraphHintOptionsDropdown/GraphHintOptionsDropdown';

import { handleGenericError } from '../../../utils/error';
import {
  getIndexRatingFromScore,
  indexedDisplayTextByRating,
} from '../../../utils/score';
import { CHART_LEGEND_ICON_VARIANT } from '../../../constants/props';
import BNContext from '../../../contexts/BNContext';

import { GET_MARKERS } from '../../../api/queries/Molecules/MarkersWrapper';
import { UPSERT_MARKER } from '../../../api/queries/Pages/ProfileMarkerEvents';
import { prepareMarkers } from '../../../api/transforms/Molecules/MarkersWrapper';

import style from './MarketIndexSubfactorTrend.module.scss';

function MarketIndexSubfactorTrend({ factorData, loading }) {
  const { competitiveSetID, accountKey } = useContext(BNContext);

  const [showFlags, setShowFlags] = useState(true);
  const [flagData, setFlagData] = useState([]);

  const queryOptions = {
    variables: {
      id: competitiveSetID,
      accountKey,
    },
    fetchPolicy: 'no-cache',
  };

  const [getMarkers, { error, data: markersResp, refetch }] = useLazyQuery(
    GET_MARKERS,
    queryOptions
  );

  const [addMarker] = useMutation(UPSERT_MARKER);

  useEffect(() => {
    getMarkers();
  }, [getMarkers]);

  useEffect(() => {
    if (!markersResp) {
      return;
    }

    handleGenericError(
      () => setFlagData(prepareMarkers(markersResp)),
      'MarketIndexSubfactorTrend prepareMarkers failed transform'
    );
  }, [markersResp]);

  const scoreRatingDisplayText = useMemo(() => {
    const currentIndexedRating = getIndexRatingFromScore(factorData?.score);

    return indexedDisplayTextByRating[currentIndexedRating];
  }, [factorData]);

  async function handleAddMarkerSuccess(data) {
    try {
      const label = data.eventTitle;
      const date = data.eventDate;
      await addMarker({
        variables: {
          brandKey: competitiveSetID,
          accountKey: data.showEvent === 'brand' ? null : accountKey,
          label,
          markerEventDate: dayjs(date).toISOString(),
          isPrivate: data.private === true ? 1 : 0,
        },
      });
      refetch();
    } catch (e) {
      if (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <div className={style.MarketIndexSubfactorTrend}>
      {loading ? <ComponentLoader minHeight={250} /> : null}

      {!loading ? (
        <>
          <Grid
            container
            alignItems="center"
            className={style.TileAndDateContainer}
          >
            <Grid item xs={12} md={2}>
              <div className={style.ScoreTileContainer}>
                <ScoreTile
                  value={factorData.score}
                  size={ScoreTileSize.Medium}
                  delta={factorData.magnitude}
                />

                <div className={style.ScoreRatingDisplayText}>
                  {scoreRatingDisplayText}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={style.DateSelector}>
                <RadioDateSelector />
              </div>
            </Grid>
          </Grid>

          <div
            className={style.GraphContainer}
            data-testid="factor-detail-bluescore-chart-container"
          >
            {!factorData ||
            !factorData.scoreOverTime ||
            !factorData.scoreOverTime.length ||
            !factorData.competitorAverageScore ? (
              <NoResultsAlert data-testid="factor-detail-bluescore-trend-no-data" />
            ) : (
              <BluescoreTimelineChart
                yAxisBuffer
                customerScoresOverTime={factorData.scoreOverTime}
                competitorAverageScore={factorData.competitorAverageScore}
                showTooltips
                showFlags={showFlags}
                flags={flagData}
              />
            )}
          </div>
          <div className={style.GraphSupport}>
            <div
              className={style.GraphLegend}
              data-testid="factor-detail-chart-legend-container"
            >
              <ChartLegend
                items={[
                  {
                    variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                    label: 'Your Score',
                  },
                  {
                    variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
                    label: 'Competitor Average',
                  },
                ]}
              />
              <GraphHintOptionsDropdown
                onAddMarkerSuccess={handleAddMarkerSuccess}
                showMarkerEvent={showFlags}
                onShowMarkerEvent={() => setShowFlags(true)}
                onHideMarkerEvent={() => setShowFlags(false)}
              />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

MarketIndexSubfactorTrend.propTypes = {
  factorData: PropTypes.shape({
    scoreOverTime: PropTypes.arrayOf(
      PropTypes.shape({
        score: PropTypes.number,
        date: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    score: PropTypes.number.isRequired,
    factorType: PropTypes.string,
    competitorAverageScore: PropTypes.number,
    magnitude: PropTypes.number.isRequired,
  }),
  loading: PropTypes.bool,
};

export default MarketIndexSubfactorTrend;
