import { FunctionComponent, AnchorHTMLAttributes, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Grid from '@mui/material/Grid'

import styles from './CreativeChannelsScreenshotsMultiBrand.module.scss';
import { useScreenshotsContext } from '../CreativeChannelsScreenshots/CreativeChannelsScreenshots';
import { GET_CREATIVE_BRAND_SCREENSHOTS_BY_BRAND_KEY } from '../../../api/queries/Pages/CreativeChannels';
import { CreativeScreenshot } from '../../../interfaces/creative';
import CreativeScreenshotCardWrapper from '../CreativeScreenshotCardWrapper/CreativeScreenshotCardWrapper';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';

export interface CreativeChannelsScreenshotsMultiBrandProps
  extends AnchorHTMLAttributes<HTMLDivElement> {}

const CreativeChannelsScreenshotsMultiBrand: FunctionComponent<CreativeChannelsScreenshotsMultiBrandProps> = (
  { className, ...props },
) => {
  const { brands, query, getQuery } = useScreenshotsContext();
  const [loading, setLoading] = useState<boolean | null>(null);
  const [screenshots, setScreenshots] = useState<Map<string, CreativeScreenshot>>(new Map());

  const getAllQuery = useCallback(() => {
    return brands.map(brand => {
      return query({
        query: GET_CREATIVE_BRAND_SCREENSHOTS_BY_BRAND_KEY,
        variables: getQuery(brand.brandKey),
      });
    });
  }, [query, getQuery, brands]); 

  useEffect(() => {
    if (loading && brands?.length) {
      (async () => {
        const brandsSS = await Promise.all(getAllQuery());
        const ss = brandsSS.map((bssData) => {
          return bssData.data?.getScreenshots ?? {};
        });
        const updatedScreenshots = brands.reduce((agg, brand, idx) => {
          const [recentSS] = ss[idx].screenshots ?? [];
          agg.set(brand.brandKey, recentSS ?? null);
          return agg;
        }, new Map());
        setScreenshots(updatedScreenshots);
        setLoading(false);
      })();
    }
  }, [loading, setLoading, brands, getAllQuery, setScreenshots]);

  useEffect(() => {
    if (!query || !getQuery || !brands?.length) return;
    if (loading !== null) return;
    setLoading(true);
  }, [loading, query, getQuery, brands, setLoading]);

  useEffect(() => {
    if (brands?.length) {
      setLoading(true);
    }
  }, [brands, getQuery, setLoading]);

  return (
    <div
      className={classNames(styles.CreativeChannelsScreenshotsMultiBrand, className)}
      {...props}
    >
      <Grid container spacing={2} className={styles.Container}>
        {loading || !brands?.length ? 
          <Grid item xs={6}><ComponentLoader className={styles.BrandCard} /></Grid> :
          brands.map(brand => (
            <Grid item xs={6} px={2}
              key={brand.brandKey}
              className={styles.BrandCard}
            >
              <CreativeScreenshotCardWrapper
                brand={brand}
                screenshot={screenshots.get(brand.brandKey)}
              />
            </Grid>))
        }
      </Grid>
    </div>
  );
};

export default CreativeChannelsScreenshotsMultiBrand;
