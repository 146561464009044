import { FunctionComponent } from 'react';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import classNames from 'classnames';
import ChangeDisplayIcon from '../../../../ChangeDisplayIcon/ChangeDisplayIcon';

import styles from './PercentageDiff.module.scss';

export interface PercentageDiffProps extends TableCellProps {
  diffPercentage: number;
}

const PercentageDiff: FunctionComponent<PercentageDiffProps> = ({
  className,
  diffPercentage,
}) => {
  return (
    <TableCell
      className={classNames(styles.PercentageDiff, className)}
      align="right"
    >
      <div className={styles.Container}>
        <ChangeDisplayIcon
          hideSymbol
          changeSuffixColor
          value={diffPercentage}
          className={styles.MetricGroupCell}
          suffix="%"
          classes={{
            root: styles.Diff,
            icon: styles.DiffIcon,
            value: styles.DiffValue,
            suffix: styles.DiffSuffix,
          }}
        />
      </div>
    </TableCell>
  );
};

export default PercentageDiff;
