import { GenericIconProps } from './types';

function SvgIconStarUnfilled(props: GenericIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#CED9DB"
          d="M11.984 4c.268 0 .515.132.662.346l.057.096 2.037 4.056 4.546.65a.798.798 0 01.687.526.773.773 0 01-.174.722l-3.381 3.216.799 4.455a.778.778 0 01-.32.784.808.808 0 01-.751.096l-.088-.041-4.074-2.102-4.09 2.094a.812.812 0 01-.84-.055.782.782 0 01-.332-.672l.013-.112.8-4.455-3.3-3.138a.773.773 0 01-.2-.784.793.793 0 01.536-.51l.111-.023 4.546-.66 2.037-4.047A.802.802 0 0111.984 4zm2.097 5.413l-2.097-4.175-2.097 4.167-4.615.668 3.341 3.177-.821 4.575 4.194-2.146 4.172 2.154-.82-4.575 3.352-3.185-4.609-.66z"
        />
      </g>
    </svg>
  );
}

export default SvgIconStarUnfilled;
