import { ChangeEvent, useEffect, useState, useMemo, useCallback } from 'react';
import { SingleValue } from 'react-select';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLazyQuery } from '@apollo/client';
import find from 'lodash/find';
import { TimeRange } from '../../../../../interfaces/dashboard-api';
import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';
import DropdownSelect, {
  DropdownOption,
} from '../../../Creative/DropdownSelect/DropdownSelect';
import { GET_FIRST_AND_LAST_SESSION } from '../../../../../api/queries/Pages/CustomizableDashboards';
import { GET_COMPETITORS } from '../../../../../api/queries/Pages/MarketIndexCompare';
import styles from './GroupMetricSettingsGuide.module.scss';
import { getModeBucketName } from '../../WidgetSettingsFlows/helper';
import { WidgetSettingsModalAction } from '../../types';
import { DateRangeOptions } from '../../WidgetSettingsShared';
import useCompetitiveSetOptions from '../../../../../hooks/useCompetitiveSetOptions';
import useBrandOptions from '../../../../../hooks/useBrandOptions';
import { FeatureFlag } from '../../../../../utils/featureFlags';
import DateRangeSelectorDropdown from '../../DateRangeSelectorDropdown/DateRangeSelectorDropdown';

interface FormFields {
  widgetName: string;
  description: string;
  timeRange: TimeRange;
  competitiveSetKey: string;
  brandKey: string;
  endDate: Date;
}

const GroupMetricSettingsGuide = ({
  config,
  settingAction,
  onConfigChange,
  onValidationChange,
}: WidgetConfigProps) => {
  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [getCompetitors, { data: competitorsResp }] = useLazyQuery(
    GET_COMPETITORS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const flags = useFlags();
  const showCustomDateRangeSelector =
    flags[FeatureFlag.ViewWidgetCustomDateSelector];

  const { groupName, metrics } = config[FlowKey.SelectGroup];
  const {
    widgetName,
    description,
    timeRange,
    competitiveSetKey,
    brandKey,
    endDate,
  } = config[FlowKey.MetricGroupSettings];

  const mostCommonMetricGroup = getModeBucketName(metrics);
  const setMetricName = settingAction === WidgetSettingsModalAction.Add;

  const [inputValues, setInputValues] = useState<FormFields>({
    widgetName: setMetricName ? mostCommonMetricGroup : widgetName,
    description: description ?? '',
    timeRange: timeRange ?? TimeRange.DEFAULT,
    competitiveSetKey: competitiveSetKey ?? '',
    brandKey: brandKey ?? '',
    endDate: endDate ?? new Date(),
  });

  const handleConfigSave = useCallback(
    (newInputValues: FormFields) => {
      const newConfig = structuredClone(config);

      newConfig[FlowKey.MetricGroupSettings] = {
        ...newConfig[FlowKey.MetricGroupSettings],
        widgetName: newInputValues.widgetName,
        description: newInputValues.description,
        timeRange: newInputValues.timeRange,
        competitiveSetKey: newInputValues.competitiveSetKey,
        brandKey: newInputValues.brandKey,
        endDate: newInputValues.endDate,
      };

      onConfigChange(newConfig);
    },
    [config, onConfigChange]
  );

  /* Comp Set Selector Logic [start] */
  const competitiveSetOptions = useCompetitiveSetOptions();

  const selectedCompetitiveSetOption = useMemo(() => {
    if (!inputValues.competitiveSetKey && !competitiveSetOptions?.length)
      return null;

    return find(competitiveSetOptions, {
      value: inputValues.competitiveSetKey,
    });
  }, [competitiveSetOptions, inputValues]);

  // duplicated in SingleMetricsSettingsForm & ESOVSettingsForm
  useEffect(() => {
    if (inputValues.competitiveSetKey || !competitiveSetOptions.length) return;

    const newInputValues = {
      ...inputValues,
      competitiveSetKey: competitiveSetOptions[0].value,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  }, [competitiveSetOptions, inputValues, handleConfigSave]);

  const handleCompetitiveSetChange = (
    newValue: SingleValue<{ label: string; value: string } | null>
  ) => {
    const newInputValues = {
      ...inputValues,
      competitiveSetKey: newValue?.value ?? '',
      // clear brand key when competitive set changes
      brandKey: '',
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };
  /* Comp Set Selector Logic [end] */

  /* Brand Selector Logic [start] */
  const brandOptions = useBrandOptions(competitorsResp);

  /**
   * If no selected brand, default to the hero brand. This is to handle the case
   * where the user changes competitive set and the list of competitors changes.
   * duplicated in SingleMetricsSettingsForm & ESOVSettingsForm
   */
  useEffect(() => {
    if (
      brandOptions?.length &&
      !find(brandOptions, {
        value: inputValues.brandKey,
      })
    ) {
      const newInputValues = {
        ...inputValues,
        brandKey: brandOptions[0].value,
      };

      setInputValues(newInputValues);
      handleConfigSave(newInputValues);
    }
  }, [inputValues, brandOptions, handleConfigSave]);

  const selectedBrandOption = useMemo(() => {
    if (!inputValues.brandKey && !brandOptions?.length) return null;

    return find(brandOptions, {
      value: inputValues.brandKey,
    });
  }, [brandOptions, inputValues]);

  const dateRangeValue = useMemo(() => {
    return DateRangeOptions.find(
      (option) => option.value === inputValues.timeRange
    );
  }, [inputValues.timeRange]);

  /**
   * Get the last session key when the selected competitive set changes.
   * duplicated in SingleMetricsSettingsForm & ESOVSettingsForm
   */
  useEffect(() => {
    if (!selectedCompetitiveSetOption?.value) return;

    getFirstLastSession({
      variables: {
        id: selectedCompetitiveSetOption?.value,
      },
    });
  }, [selectedCompetitiveSetOption, getFirstLastSession]);

  /**
   * Get the competitors when the last session key and competitive set key changes.
   * duplicated in SingleMetricsSettingsForm & ESOVSettingsForm
   */
  useEffect(() => {
    if (
      !firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
        ?.sessionKey ||
      !selectedCompetitiveSetOption?.value
    )
      return;

    getCompetitors({
      variables: {
        id: selectedCompetitiveSetOption?.value,
        sessionKey:
          firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
            ?.sessionKey,
      },
    });
  }, [firstAndLastSessionResp, selectedCompetitiveSetOption, getCompetitors]);

  /**
   * If no selected brand, default to the hero brand
   */
  useEffect(() => {
    if (!inputValues.brandKey && brandOptions?.length) {
      const newInputValues = {
        ...inputValues,
        brandKey: brandOptions[0].value,
      };

      setInputValues(newInputValues);
      handleConfigSave(newInputValues);
    }
  }, [brandOptions, inputValues, handleConfigSave]);

  const handleBrandChange = (
    newValue: SingleValue<{ label: string; value: string } | null>
  ) => {
    const newInputValues = {
      ...inputValues,
      brandKey: newValue?.value ?? '',
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };
  /* Brand Selector Logic [end] */

  const handleInputChange = (
    { target }: ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const newInputValues = {
      ...inputValues,
      [field]: target.value,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleTimeRangeChange = (updatedTime: SingleValue<DropdownOption>) => {
    const updatedTimeRange = updatedTime?.value ?? TimeRange.DEFAULT;
    const newInputValues = {
      ...inputValues,
      timeRange: updatedTimeRange as TimeRange,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    const newInputValues = {
      ...inputValues,
      timeRange: (e.timeRange as TimeRange) ?? TimeRange['90_DAYS'],
      endDate: e.endDate,
    };

    setInputValues(newInputValues);
    handleConfigSave(newInputValues);
  };

  useEffect(() => {
    const disableSave =
      !widgetName ||
      !selectedCompetitiveSetOption?.value ||
      !selectedBrandOption?.value ||
      !inputValues.timeRange;
    onValidationChange({ [FlowKey.MetricGroupSettings]: disableSave });
  }, [
    onValidationChange,
    widgetName,
    selectedCompetitiveSetOption,
    selectedBrandOption,
    inputValues,
  ]);

  return (
    <div className={styles.GroupMetricSettingsGuide}>
      <div className={styles.FieldGroup}>
        <p className={styles.SubTitle}>
          METRIC GROUP<span className={styles.Required}>*</span>
        </p>
        {/* TODO: Change this to be editable field */}
        <p className={styles.Text}>{groupName}</p>
      </div>

      <div className={styles.FieldGroup}>
        <p className={styles.SubTitle}>
          WIDGET NAME<span className={styles.Required}>*</span>
        </p>
        <input
          required
          type="text"
          value={inputValues.widgetName}
          onChange={(e) => handleInputChange(e, 'widgetName')}
          className={styles.Field}
        />
      </div>

      <div className={styles.FieldGroup}>
        <p className={styles.SubTitle}>DESCRIPTION</p>
        <input
          required
          type="text"
          value={inputValues.description}
          onChange={(e) => handleInputChange(e, 'description')}
          className={styles.Field}
        />
      </div>

      <div className={styles.FieldGroup}>
        <p className={styles.SubTitle}>COMPETITIVE SET</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={competitiveSetOptions}
          onChange={handleCompetitiveSetChange}
          value={selectedCompetitiveSetOption}
        />
      </div>

      <div className={styles.FieldGroup}>
        <p className={styles.SubTitle}>BRAND</p>
        <DropdownSelect
          className={styles.Dropdown}
          options={brandOptions}
          onChange={handleBrandChange}
          value={selectedBrandOption}
        />
      </div>

      <div className={styles.FieldGroup}>
        <p className={styles.SubTitle}>DATE RANGE</p>
        {showCustomDateRangeSelector ? (
          <DateRangeSelectorDropdown
            onApply={handleApply}
            timeRange={timeRange}
            endDate={endDate}
            fullWidth
          />
        ) : (
          <DropdownSelect
            className={styles.Dropdown}
            value={dateRangeValue}
            options={DateRangeOptions}
            onChange={handleTimeRangeChange}
          />
        )}
      </div>
    </div>
  );
};

export default GroupMetricSettingsGuide;
