import { NO_DATA } from '../constants/error';

export function handleGenericError(cb, message) {
  try {
    return cb();
  } catch (e) {
    switch (e) {
      case NO_DATA:
        console.error(message); // eslint-disable-line no-console
        break;

      default:
        // eslint-disable-next-line no-console
        console.error(e);
        break;
    }
  }
}
