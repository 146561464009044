import { Outlet } from 'react-router-dom';
import styles from './ContentAnalysisThemes.module.scss';

export default function ContentAnalysisThemes() {
  return (
    <div className={styles.ContentAnalysisThemes}>
      <Outlet />
    </div>
  );
}
