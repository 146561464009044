import * as React from 'react';

function SvgIconEye(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#7E9397"
          d="M12 6c5.147 0 9.695 5.45 9.886 5.683a.498.498 0 010 .634C21.695 12.55 17.147 18 12 18c-5.147 0-9.695-5.45-9.886-5.682a.5.5 0 010-.635C2.305 11.45 6.853 6 12 6zm0 1c-3.997 0-7.793 3.853-8.834 5 1.041 1.147 4.836 5 8.834 5 3.998 0 7.793-3.853 8.834-5-1.041-1.147-4.837-5-8.834-5zm0 2c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm0 1c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"
        />
      </g>
    </svg>
  );
}

export default SvgIconEye;
