import { gql } from '@apollo/client';

export const GET_RECOMMENDATIONS = gql`
  query getRecommendations($id: ID, $sessionKey: ID, $accountKey: ID) {
    competitiveSet(id: $id) {
      id
      session(sessionKey: $sessionKey) {
        recommendations: allBrandRecommendations(accountKey: $accountKey) {
          id
          session_id
          name
          value
          impact_id
          sourceType_id
          isComplete
          practice_areas
          createdDate
          isPrivate
          type
          associatedType_id
        }
      }
    }
  }
`;

export const ADD_RECOMMENDATIONS = gql`
  mutation addRecommendation(
    $sessionKey: ID
    $accountKey: ID
    $name: String
    $value: String
    $impact_id: Int
    $practice_areas: String
    $isPrivate: Int
    $type: Int
    $associatedType_id: Int
  ) {
    addRecommendation(
      recommendation: {
        sessionKey: $sessionKey
        accountKey: $accountKey
        type: $type
        name: $name
        value: $value
        impact_id: $impact_id
        sourceType_id: 2
        practice_areas: $practice_areas
        associatedType_id: $associatedType_id
        isPrivate: $isPrivate
        isComplete: 0
        isActive: 1
      }
    )
  }
`;

export const MODIFY_RECOMMENDATION_COMPLETION = gql`
  mutation editRecommendationMutation(
    $sessionKey: ID
    $id: Int
    $name: String
    $value: String
    $impact_id: Int
    $practice_areas: String
    $type: Int
    $associatedType_id: Int
    $isComplete: Int
    $isPrivate: Int
  ) {
    addRecommendation(
      recommendation: {
        sessionKey: $sessionKey
        id: $id
        type: $type
        name: $name
        value: $value
        impact_id: $impact_id
        sourceType_id: 2
        practice_areas: $practice_areas
        associatedType_id: $associatedType_id
        isComplete: $isComplete
        isPrivate: $isPrivate
        isActive: 1
      }
    )
  }
`;

export const DELETE_RECOMMENDATION = gql`
  mutation deleteRecommendation(
    $sessionKey: ID
    $id: Int
    $name: String
    $value: String
    $impact_id: Int
    $practice_areas: String
    $type: Int
    $associatedType_id: Int
    $isComplete: Int
    $isPrivate: Int
  ) {
    addRecommendation(
      recommendation: {
        sessionKey: $sessionKey
        id: $id
        type: $type
        name: $name
        value: $value
        impact_id: $impact_id
        sourceType_id: 2
        practice_areas: $practice_areas
        associatedType_id: $associatedType_id
        isComplete: $isComplete
        isPrivate: $isPrivate
        isActive: 0
      }
    )
  }
`;
