import { useMemo } from 'react';
import { ChartData, ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import cn from 'classnames';
import genericBarChartTooltip from '../../../../chartjs/tooltips/genericBarChartTooltip/genericBarChartTooltip';
import { numberToAbbreviatedCurrency } from '../../../../chartjs/tooltips/helpers';
import { ChartTooltipContextBar, YAxisFormatter } from '../../../../interfaces';
import GenericBarChart, {
  defaultTickFormatter,
} from '../../../Charts/GenericBarChart/GenericBarChart';
import styles from './SpendByPeriodBarChart.module.scss';

export interface SpendByPeriodBarChartProps {
  labels?: string[];
  data: (number | null)[];
  className?: string;
}

const SpendByPeriodBarChart = ({
  labels,
  data,
  className = '',
}: SpendByPeriodBarChartProps) => {
  const formattedLabels: string[] | undefined = useMemo(() => {
    if (!labels) {
      return undefined;
    }

    return labels?.map((label) => {
      return dayjs(label).format('M/D/YY');
    });
  }, [labels]);

  const yAxisFormatter: YAxisFormatter = (value) =>
    numberToAbbreviatedCurrency(value);

  const options: ChartOptions = {
    scales: {
      y: {
        grid: { display: true, drawBorder: false },
        ticks: {
          display: true,
          maxTicksLimit: 4,
          callback(value) {
            return yAxisFormatter(value);
          },
        },
      },
      x: {
        grid: { display: false },
        ticks: {
          maxRotation: 0, // prevent rotating of labels
          minRotation: 0,
          autoSkip: false, // when true the last label is hidden
          callback: labels
            ? (index) =>
                defaultTickFormatter(index, formattedLabels as string[])
            : undefined,
        },
      },
    },
    plugins: {
      legend: { display: false },
      datalabels: { display: false },
      tooltip: {
        enabled: false,
        external: (context: ChartTooltipContextBar) =>
          genericBarChartTooltip(context, yAxisFormatter),
      },
    },
  };

  const chartData: ChartData = {
    labels: labels ? formattedLabels : undefined,
    datasets: [{ label: 'Spend', data }],
  };

  return (
    <div
      className={cn(styles.SpendByPeriodBarChart, className)}
      data-cy="spend-period-bar-chart"
    >
      <GenericBarChart
        options={options}
        data={chartData}
        tooltipFormatter={yAxisFormatter}
      />
    </div>
  );
};

export default SpendByPeriodBarChart;
