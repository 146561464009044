import classNames from 'classnames';
import { useId } from 'react';
import { Tooltip } from '@mui/material';
import { TimeRange } from '../../../../../../interfaces/dashboard-api';
import { MetricVisualMap, ScoreMapType } from '../../../types';
import styles from './MappingWidgetGridCell.module.scss';
import ScoreChangeDescription from '../../../ScoreChangeDescription/ScoreChangeDescription';

export interface MappingWidgetGridCellProps {
  scoreMapType: ScoreMapType;
  activeTimeRange: TimeRange;
  metric: MetricVisualMap;
}

const waterMapLevelMaxScore = {
  poor: 49,
  weak: 69,
  fair: 89,
  moderate: 109,
  good: 129,
  great: 149,
  bestInClass: 200,
};

const heatMapAvgScore = 100;

const getClassName = (score: number, scoreMapType: ScoreMapType): string => {
  if (scoreMapType === ScoreMapType.HeatMap) {
    if (score < heatMapAvgScore) return 'below';
    if (score > heatMapAvgScore) return 'above';
    return 'equal';
  }
  switch (true) {
    case score <= waterMapLevelMaxScore.poor:
      return 'poor';
    case score <= waterMapLevelMaxScore.weak:
      return 'weak';
    case score <= waterMapLevelMaxScore.fair:
      return 'fair';
    case score <= waterMapLevelMaxScore.moderate:
      return 'moderate';
    case score <= waterMapLevelMaxScore.good:
      return 'good';
    case score <= waterMapLevelMaxScore.great:
      return 'great';
    case score <= waterMapLevelMaxScore.bestInClass:
      return 'best';
    default:
      return '';
  }
};

const MappingWidgetGridCell = ({
  scoreMapType,
  activeTimeRange,
  metric,
}: MappingWidgetGridCellProps) => {
  const id = useId();
  const score = Math.round(metric.relativeScoreTile);
  const scoreDelta = metric.showRawScore
    ? metric.isolatedScoreDelta
    : metric.relativeScoreDelta;
  const scoreChangeRate = metric.showRawScore
    ? metric.isolatedChangeRate
    : metric.relativeChangeRate;
  const scoreCohortComparison = metric.showRawScore
    ? metric.isolatedCohortComparison
    : metric.relativeCohortComparison;

  const tooltipData = [
    {
      score: scoreDelta,
      suffix: 'pts',
      description: `vs prev. ${activeTimeRange.replace('_', ' ')}`,
    },
    {
      score: scoreChangeRate,
      suffix: '%',
      description: `vs prev. ${activeTimeRange.replace('_', ' ')}`,
    },
    {
      score: scoreCohortComparison,
      suffix: '%',
      description: 'vs cohort avg.',
    },
  ];

  const tooltipContent = (
    <div className={styles.TooltipContainer}>
      {tooltipData.map((el) => (
        <ScoreChangeDescription
          key={`${id}_${el.suffix}_${el.description}`}
          score={el.score}
          suffix={el.suffix}
          description={el.description}
          className={styles.LabelDescription}
        />
      ))}
    </div>
  );

  return (
    <Tooltip
      classes={{
        tooltip: styles.Tooltip,
        arrow: styles.TooltipArrow,
      }}
      placement="top"
      arrow
      title={tooltipContent}
    >
      <div
        className={classNames(
          styles.Cell,
          styles[getClassName(score, scoreMapType)]
        )}
      >
        <p>{score}</p>
      </div>
    </Tooltip>
  );
};

export default MappingWidgetGridCell;
