import { useState, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import classNames from 'classnames';
import keyBy from 'lodash/keyBy';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';

import { Button } from '@blueoceanai/react-component-library';
import ScoreTile, {
  ScoreTileSize,
} from '../../ScoreDisplays/ScoreTile/ScoreTile';
import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import InsightsCTA from '../../Molecules/InsightsCTA/InsightsCTA';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import BluescoreTimelineChart from '../../Charts/BluescoreTimelineChart/BluescoreTimelineChart';
import RadioDateSelector from '../../Molecules/RadioDateSelector/RadioDateSelector';
import GraphHintOptionsDropdown from '../../Molecules/GraphHintOptionsDropdown/GraphHintOptionsDropdown';
import ScoreLegend from '../../Molecules/ScoreLegend/ScoreLegend';
import ChartLegend from '../../Molecules/ChartLegend/ChartLegend';
import SubfactorCalculationBreakdown from '../../Organisms/SubfactorCalculationBreakdown/SubfactorCalculationBreakdown';

import { GET_SUBFACTORS } from '../../../api/queries/Pages/MarketIndexSubFactor';
import {
  prepareSubfactor,
  prepareScoreDrivers,
  prepareInsight,
} from '../../../api/transforms/Pages/MarketIndexSubFactor';

import { handleGenericError } from '../../../utils/error';
import {
  getIndexRatingFromScore,
  indexedDisplayTextByRating,
} from '../../../utils/score';
import {
  CHART_LEGEND_ICON_VARIANT,
  BUTTON_VARIANTS,
} from '../../../constants/props';
import { SUBFACTOR_TYPES, FACTOR_TYPES } from '../../../constants/factors';
import QUERY_PARAMS from '../../../constants/queryParams';
import {
  SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST,
  SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST,
  SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS,
} from '../../../constants/social-metrics';
import { SocialChannelKey, ScoreType } from '../../../interfaces/metric';

import BNContext from '../../../contexts/BNContext';
import useRouter from '../../../hooks/useRouter';

import { GET_MARKERS } from '../../../api/queries/Molecules/MarkersWrapper';
import { UPSERT_MARKER } from '../../../api/queries/Pages/ProfileMarkerEvents';
import { prepareMarkers } from '../../../api/transforms/Molecules/MarkersWrapper';

import styles from './MarketIndexSubFactor.module.scss';

const SUBFACTOR_TYPES_BY_ID = keyBy(SUBFACTOR_TYPES, 'ID');
const FACTOR_TYPES_BY_ID = keyBy(FACTOR_TYPES, 'ID');

function MarketIndexSubFactor({
  disableCache = false,
  factorId,
  subfactorId,
  loading,
}) {
  const { updateRoute } = useRouter();
  const [showFlags, setShowFlags] = useState(true);

  const {
    timeframe,
    competitiveSetID,
    accountKey,
    currentSession: sessionKey, //eslint-disable-line
  } = useContext(BNContext);

  const queryOptions = {
    variables: {
      id: competitiveSetID,
      accountKey,
    },
    fetchPolicy: 'no-cache',
  };

  const [getMarkers, { loading: fetchingMarkers, data: markersResp, refetch }] =
    useLazyQuery(GET_MARKERS, queryOptions);

  const [
    getSubfactors,
    { error, loading: gettingSubfactors, data: subFactorsResp },
  ] = useLazyQuery(GET_SUBFACTORS);

  const [addMarker] = useMutation(UPSERT_MARKER);

  const flagData = useMemo(() => {
    if (!markersResp) {
      return [];
    }

    return handleGenericError(
      () => prepareMarkers(markersResp),
      'MarketIndexSubFactor prepareMarkers failed transform'
    );
  }, [markersResp]);

  useEffect(() => {
    if (!competitiveSetID) {
      return;
    }

    getMarkers();
  }, [competitiveSetID, getMarkers]);

  useEffect(() => {
    const options = {
      variables: {
        id: competitiveSetID,
        sessionKey,
        accountKey,
        subFactorId: subfactorId,
      },
    };

    if (disableCache) {
      options.fetchPolicy = 'no-cache';
    }

    getSubfactors(options);
  }, [
    subfactorId,
    competitiveSetID,
    accountKey,
    sessionKey,
    disableCache,
    getSubfactors,
  ]);

  const insight = useMemo(
    () => prepareInsight(subFactorsResp),
    [subFactorsResp]
  );

  const factor = useMemo(
    () =>
      handleGenericError(
        () => prepareSubfactor(subFactorsResp, timeframe),
        'MarketIndexSubFactor prepareSubfactor failed transform'
      ),
    [subFactorsResp, timeframe]
  );

  // need to do: actionIconClick
  const scoreDrivers = useMemo(
    () =>
      handleGenericError(
        () => prepareScoreDrivers(subFactorsResp, timeframe),
        'MarketIndexSubFactor prepareScoreDrivers failed transform'
      ),
    [subFactorsResp, timeframe]
  );

  const scoreRatingDisplayText = useMemo(() => {
    const currentIndexedRating = getIndexRatingFromScore(factor?.score);

    return indexedDisplayTextByRating[currentIndexedRating];
  }, [factor]);

  const pageLevelYDomain = useMemo(() => {
    if (!factor || !scoreDrivers) {
      return {
        min: 0,
        max: 200,
      };
    }

    let min = minBy(factor.scoreOverTime, 'value')?.value;
    let max = maxBy(factor.scoreOverTime, 'value')?.value;

    scoreDrivers.forEach((driver) => {
      min = Math.min(min, minBy(driver.scoreOverTime, 'value')?.value);
      max = Math.max(max, maxBy(driver.scoreOverTime, 'value')?.value);
    });

    return {
      min,
      max,
    };
  }, [factor, scoreDrivers]);

  const isLoading = useMemo(
    () =>
      loading ||
      gettingSubfactors ||
      fetchingMarkers ||
      !insight ||
      !factor ||
      !scoreDrivers,
    [loading, gettingSubfactors, fetchingMarkers, insight, factor, scoreDrivers]
  );

  async function handleAddMarkerSuccess(data) {
    const label = data.eventTitle;
    const date = data.eventDate;
    await addMarker({
      variables: {
        competitiveSetKey: competitiveSetID,
        accountKey,
        label,
        markerEventDate: dayjs(date).toISOString(),
        isPrivate: data.private === true ? 1 : 0,
        isAccountLevel: data.showEvent === 'brand' ? 0 : 1,
      },
    });
    refetch();
  }

  function handleCompareBtnClick() {
    updateRoute({
      pathname: '/market-index/compare',
      params: {
        [QUERY_PARAMS.FACTOR_NAME]: SUBFACTOR_TYPES_BY_ID[subfactorId].KEY,
      },
    });
  }

  function handleCardExploreClick({ metric, scoreType }) {
    if (!metric?.variableId) {
      return;
    }

    const newParams = {
      [QUERY_PARAMS.METRIC_GROUP_ID]: null,
      [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
      [QUERY_PARAMS.METRIC_VARIABLE_ID]: metric.variableId,
    };

    if (
      SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(metric.variableId) ||
      SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(metric.variableId)
    ) {
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = SocialChannelKey.Aggregate;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = scoreType;
    } else {
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = null;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = null;
    }

    if (
      metric.variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE &&
      scoreType === ScoreType.Indexed
    ) {
      newParams[QUERY_PARAMS.METRIC_VARIABLE_ID] =
        SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE;
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = null;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = ScoreType.Indexed;
    }

    if (
      metric.variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE &&
      scoreType === ScoreType.Raw
    ) {
      newParams[QUERY_PARAMS.METRIC_VARIABLE_ID] =
        SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.ZZ;
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = null;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = ScoreType.Raw;
    }

    updateRoute({
      pathname: '/metrics',
      params: newParams,
    });
  }

  if (error) {
    return <GenericErrorCopy />;
  }

  const driverTitleId = `${SUBFACTOR_TYPES_BY_ID[subfactorId].KEY}-driver-title`;
  const subfactorName = SUBFACTOR_TYPES_BY_ID[subfactorId]?.NAME;

  return (
    <Grid container spacing={10} className={styles.MarketIndexSubFactor}>
      <Grid item xs={12} md={9}>
        <div id="market-index-subfactor-trend">
          <div
            data-testid="subfactor-dropdown"
            className={classNames(styles.ScreenshotFactorName, 'add-to-export')}
          >
            Factors: {FACTOR_TYPES_BY_ID[factorId]?.NAME}: {subfactorName}
          </div>

          <Grid container>
            <Grid
              item
              xs={9}
              className={classNames(styles.Title, 'PageTitleContainer')}
            >
              <h1
                id={`market-index-title-subfactor-${subfactorName?.toLowerCase()}`}
              >
                {SUBFACTOR_TYPES_BY_ID[subfactorId]?.QUESTION}
              </h1>
            </Grid>

            <Grid
              item
              container
              xs={3}
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Button
                className={classNames(
                  styles.CompareBtn,
                  'hide-from-share',
                  'hide-from-export'
                )}
                variant={BUTTON_VARIANTS.SECONDARY}
                onClick={handleCompareBtnClick}
              >
                Compare
              </Button>
              <DownloadDropdown
                targetId="market-index-subfactor-trend"
                className={styles.DownloadDropdown}
                padding={100}
              />
            </Grid>
          </Grid>

          {isLoading ? <ComponentLoader minHeight={400} /> : null}

          {!isLoading ? (
            <>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box
                    mb={8}
                    className={styles.ScoreTileContainer}
                    data-testid="subfactor-score-tile"
                  >
                    <ScoreTile
                      value={factor.score}
                      size={ScoreTileSize.Large}
                      delta={factor.delta}
                    />

                    <div className={styles.ScoreRatingDisplayText}>
                      {scoreRatingDisplayText}
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={8}
                  container
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Box mb={8}>
                    <RadioDateSelector />
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <div
                    className={styles.GraphContainer}
                    data-testid="subfactor-graph-container"
                  >
                    {!factor ||
                    !factor.scoreOverTime ||
                    !factor.scoreOverTime.length ||
                    !factor.competitorAverageScore ? (
                      <NoResultsAlert data-cy="subfactor-detail-bluescore-trend-no-data" />
                    ) : (
                      <BluescoreTimelineChart
                        yAxisBuffer
                        customerScoresOverTime={factor.scoreOverTime}
                        competitorAverageScore={factor.competitorAverageScore}
                        showFlags={showFlags}
                        flags={flagData}
                        showTooltips
                        yDomainOverride={pageLevelYDomain}
                        data-cy="subfactor-trend-graph"
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container className={styles.GraphTools}>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  data-testid="subfactors-chart-legend"
                >
                  <ChartLegend
                    items={[
                      {
                        variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                        label: 'Your Score',
                      },
                      {
                        variant: CHART_LEGEND_ICON_VARIANT.DASHED_LINE,
                        label: 'Industry Average',
                      },
                    ]}
                  />
                </Grid>
                <Grid
                  data-testid="subfactors-marker-dropdown"
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <GraphHintOptionsDropdown
                    onAddMarkerSuccess={handleAddMarkerSuccess}
                    showMarkerEvent={showFlags}
                    onShowMarkerEvent={() => setShowFlags(true)}
                    onHideMarkerEvent={() => setShowFlags(false)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                className={styles.LegendContainer}
                data-testid="subfactors-score-legend"
              >
                <Grid item xs={12} md={8}>
                  <ScoreLegend />
                </Grid>
                <Grid item xs={false} md={4} />
              </Grid>
            </>
          ) : null}
        </div>

        <Grid container className={styles.SubfactorBreakdownContainer}>
          <Grid item xs={12}>
            <SubfactorCalculationBreakdown
              data-testid="subfactor-breakdown-container"
              subfactorId={subfactorId}
              subfactorName={subfactorName}
              titleId={driverTitleId}
              subfactorCardData={scoreDrivers}
              loading={isLoading}
              onCardExploreClick={handleCardExploreClick}
              yDomain={pageLevelYDomain}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3} data-testid="subfactor-insights-cta">
        <InsightsCTA
          loading={isLoading}
          insight={insight}
          data={
            subFactorsResp?.competitiveSet?.session?.subFactor?.recommendations
          }
          modalType="recommendations"
        />
      </Grid>
    </Grid>
  );
}

MarketIndexSubFactor.propTypes = {
  disableCache: PropTypes.bool,
  factorId: PropTypes.number,
  subfactorId: PropTypes.number,
  loading: PropTypes.bool,
};

export default MarketIndexSubFactor;
