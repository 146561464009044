import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';
import TopMetrics from '../../Organisms/TopMetrics/TopMetrics';
import FilterableMetricsList from '../../Organisms/FilterableMetricsList/FilterableMetricsList';

import styles from './MetricsOverview.module.scss';

export default function MetricsOverview({
  className,
  metrics,
  topMetrics,
  onMetricClick,
  onTitleClick,
  metricGroup,
  ...props
}) {
  return (
    <div className={classNames(styles.MetricsOverview, className)} {...props}>
      <Box className={styles.TopMetricsSection} pb={4}>
        {topMetrics && !topMetrics.length ? <NoResultsAlert /> : null}

        {topMetrics && topMetrics.length > 0 ? (
          <TopMetrics metrics={topMetrics} onTitleClick={onTitleClick} />
        ) : null}
      </Box>
      <Box className={styles.MetricsListSection} pt={4}>
        <FilterableMetricsList
          metricGroup={metricGroup}
          metrics={metrics}
          onMetricClick={onMetricClick}
        />

        {metrics && !metrics.length && <NoResultsAlert />}
      </Box>
    </div>
  );
}

MetricsOverview.propTypes = {
  className: PropTypes.string,
  metricGroup: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  topMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  onMetricClick: PropTypes.func,
  onTitleClick: PropTypes.func,
};
