import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@blueoceanai/react-component-library';
import Box from '@mui/material/Box';

import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import RecommendationsModal from '../../Organisms/RecommendationsModal/RecommendationsModal';
import TalkToStrategistModal from '../../Organisms/TalkToStrategistModal/TalkToStrategistModal';

import { BUTTON_VARIANTS } from '../../../constants/props';

import RecommendationIcon from '../../../assets/icons/Recommendations.jsx';
import InsightsIcon from '../../../assets/icons/Insights.jsx';
import BNContext from '../../../contexts/BNContext';

import styles from './InsightsCTA.module.scss';

function InsightsCTA({ data, insight, loading, ...props }) {
  const { accountType } = React.useContext(BNContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [strategistModalOpen, setStrategistModalOpen] = useState(false);
  const [numberRecommendations, setNumberRecommendations] = useState(0);

  function closeModal() {
    setModalOpen(false);
  }
  function openStrategistModal() {
    setStrategistModalOpen(true);
  }
  function closeStrategistModal() {
    setStrategistModalOpen(false);
  }
  useEffect(() => {
    let numRec = 0;
    if (data?.length) {
      data.map((recommendation) =>
        recommendation.isComplete !== 1 ? numRec++ : null
      );
    }
    setNumberRecommendations(numRec);
  }, [data]);

  return (
    <>
      <div className={styles.InsightsCTA} {...props}>
        <Box p={4} className={styles.Header}>
          <InsightsIcon className={styles.Icon} />
          <Box ml={2}>
            <h5>Insights</h5>
          </Box>
        </Box>
        <Box p={4} pb={6} className={styles.Body}>
          {loading ? <ComponentLoader /> : null}

          {!loading && insight ? (
            <>
              <Box pb={3}>
                {insight.map((paragraph, index) => (
                  <p
                    className={styles.InsightText}
                    // eslint-disable-next-line
                    key={index}
                  >
                    {paragraph}
                  </p>
                ))}
              </Box>
              <Box className={styles.ButtonWrap}>
                {accountType === 5 ? (
                  numberRecommendations > 0 ? (
                    <Button
                      variant={BUTTON_VARIANTS.PRIMARY}
                      onClick={() => setModalOpen(true)}
                    >
                      <RecommendationIcon className={styles.Icon} />
                      Show Recommendations ({numberRecommendations})
                    </Button>
                  ) : null
                ) : (
                  <Button
                    onClick={openStrategistModal}
                    variant={BUTTON_VARIANTS.PRIMARY}
                  >
                    Upgrade to Premium
                  </Button>
                )}
              </Box>
            </>
          ) : null}

          {!loading && !insight ? <p>No Insights Available</p> : null}
        </Box>
      </div>

      {!loading && data ? (
        <RecommendationsModal
          open={modalOpen}
          onClose={closeModal}
          recommendations={data}
        />
      ) : null}
      <TalkToStrategistModal
        open={strategistModalOpen}
        onClose={closeStrategistModal}
      />
    </>
  );
}

InsightsCTA.propTypes = {
  // TODO: replace data with more specific datastructure and name
  // eslint-disable-next-line
  data: PropTypes.any,
  /**
   * Body text of the insight. Each array element is a paragraph.
   */
  insight: PropTypes.arrayOf(PropTypes.string),

  loading: PropTypes.bool,
};

export default memo(InsightsCTA);
