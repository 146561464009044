import { memo } from 'react';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import ScoreTile from '../../ScoreDisplays/ScoreTile';
import BluescoreTimelineChart from '../../Charts/BluescoreTimelineChart/BluescoreTimelineChart';
import { FACTOR_TYPES } from '../../../constants/factors';

import styles from './FactorCard.module.scss';

function FactorCard({
  factorType,
  score,
  delta,
  scoreOverTime,
  graphScale = {},
  onNameClick = () => {},
  ...props
}) {
  const FACTOR = FACTOR_TYPES[findIndex(FACTOR_TYPES, { KEY: factorType })];

  return (
    <Paper className={styles.FactorCard} {...props}>
      <Box m={4}>
        <Box mb={4} className={styles.Header}>
          <Box mb={2}>
            <h4 data-cy="factor-name" onClick={onNameClick}>
              {FACTOR.NAME}
            </h4>
          </Box>
          <p>{FACTOR.EXTENSION}</p>
        </Box>
        <div className={styles.Body}>
          <Box mr={4}>
            <ScoreTile value={score} delta={delta} />
          </Box>

          <div className={styles.Right}>
            <div
              className={styles.GraphWrap}
              data-testid="factor-card-bluescore-chart"
            >
              <BluescoreTimelineChart
                customerScoresOverTime={scoreOverTime}
                showReducedXAxis
                yDomainOverride={graphScale.yDomain}
                showTooltips={false}
                yAxis={false}
              />
            </div>
            <p>{FACTOR.QUESTION}</p>
          </div>
        </div>
      </Box>
    </Paper>
  );
}

FactorCard.propTypes = {
  /**
   * Factor key from factors.js constants file.
   */
  factorType: PropTypes.string.isRequired,
  /**
   * Score on ScoreTile.
   */
  score: PropTypes.number.isRequired,
  /**
   * Delta on Scoretile.
   */
  delta: PropTypes.number,
  /**
   * Scores over time to be displayed on graph.
   */
  scoreOverTime: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
    })
  ),
  graphScale: PropTypes.shape({
    yDomain: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }),
  }),
  onNameClick: PropTypes.func,
};

export default memo(FactorCard);
