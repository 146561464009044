import { AnchorHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@blueoceanai/react-component-library';
import { ClickAwayListener } from '@mui/material';
import { EllipsisIcon } from '../../../../assets/icons';
import styles from './ActionsDropdown.module.scss';

export interface ActionDropdownItem {
  label: string;
  value: string | number;
}

export interface ActionsDropdownProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  actions: ActionDropdownItem[];
  handleOnClick: (item: ActionDropdownItem) => void;
  buttonClassName?: string;
}

const ActionsDropdown = ({
  className,
  actions,
  buttonClassName,
  handleOnClick,
}: ActionsDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const handleClickAway = () => setShowDropdown(false);
  const handleActionClick = (item: ActionDropdownItem) => {
    handleOnClick(item);
    setShowDropdown(false);
  };
  return (
    <div className={classNames(styles.ActionsDropdown, className)}>
      <Button
        onClick={toggleDropdown}
        className={classNames(styles.Button, buttonClassName)}
      >
        <EllipsisIcon />
      </Button>
      {showDropdown && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <ul className={styles.DropdownMenu}>
            {actions.map((item) => (
              <li key={item.value} className={styles.ItemContainer}>
                <button
                  type="button"
                  onClick={() => handleActionClick(item)}
                  className={styles.ActionItem}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default ActionsDropdown;
