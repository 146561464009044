// eslint-disable-next-line import/prefer-default-export
export enum IndexedRating {
  Poor = 'poor',
  Weak = 'weak',
  Fair = 'fair',
  Moderate = 'moderate',
  Good = 'good',
  Great = 'great',
  Best = 'best',
  Null = 'null',
}
