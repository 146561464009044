import { FunctionComponent, useMemo } from 'react';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import uniq from 'lodash/uniq';
import groupBy from 'lodash/groupBy';
import classNames from 'classnames';
import {
  brandCompareTimeseriesTooltipMultiple,
  brandCompareTooltipSingle,
} from '../../../chartjs/tooltips';
import useBrandCompareTimeseriesChartTooltipFormatter from '../../../hooks/useBrandCompareTimeseriesChartTooltipFormatter';
import {
  ChartDataset,
  ChartPoint,
  ChartColor,
  YAxisFormatter,
} from '../../../interfaces';

import styles from './BrandCompareBarChart.module.scss';

Chart.register(...registerables);

export interface BrandCompareBarChartProps {
  className?: string;
  brandsDatasets: ChartDataset[];
  chartColorMapByBrandKey: {
    [key: string]: ChartColor;
  };
  yAxisFormatter?: YAxisFormatter;
}

export interface FlattendBrandDatasetPoint {
  brandKey: ChartDataset['key'];
  brandName: ChartDataset['name'];
  x: string;
  y: ChartPoint['y'];
}

const BrandCompareBarChart: FunctionComponent<BrandCompareBarChartProps> = ({
  className,
  brandsDatasets,
  chartColorMapByBrandKey,
  yAxisFormatter,
}) => {
  const multipleBrandTooltipByFormattedDate =
    useBrandCompareTimeseriesChartTooltipFormatter({ brandsDatasets });

  function formatPointX(date: Date) {
    return dayjs(date).format('M/D/YYYY');
  }

  const chartLabels = useMemo(() => {
    const dates: Date[] = [];

    brandsDatasets.forEach((brandDataset) => {
      brandDataset.data.forEach((point) => {
        dates.push(point.x);
      });
    });

    dates.sort((a, b) => a.getTime() - b.getTime());

    const labels = uniq(dates.map(formatPointX));

    return labels;
  }, [brandsDatasets]);

  const chartDatasets = useMemo(() => {
    // group scores by date
    const flattendedBrandsDatasets: FlattendBrandDatasetPoint[] = [];

    brandsDatasets.forEach((brandDataset) => {
      brandDataset.data.forEach((point) => {
        flattendedBrandsDatasets.push({
          ...point,
          x: formatPointX(point.x),
          brandName: brandDataset.name,
          brandKey: brandDataset.key,
        });
      });
    });

    const pointsGroupedByDate = groupBy(flattendedBrandsDatasets, 'x');

    // sort scores in each date group in desc order
    Object.keys(pointsGroupedByDate).forEach((dateKey) => {
      pointsGroupedByDate[dateKey] = pointsGroupedByDate[dateKey].sort(
        (a, b) => b.y - a.y
      );
    });

    // rebuild chart dataset to be sorted per date group
    const sortedDatasets: { data: FlattendBrandDatasetPoint[] }[] = [];

    for (let i = 0; i < brandsDatasets.length; i += 1) {
      sortedDatasets.push({ data: [] });
    }

    Object.keys(pointsGroupedByDate).forEach((dateKey) => {
      for (let i = 0; i < pointsGroupedByDate[dateKey].length; i += 1) {
        sortedDatasets[i].data.push(pointsGroupedByDate[dateKey][i]);
      }
    });

    return sortedDatasets;
  }, [brandsDatasets]);

  const sessionTickLimit = useMemo(() => {
    const sessionCount = chartDatasets?.[0]?.data?.length;

    if (sessionCount < 4) {
      return 3;
    }

    if (sessionCount < 12) {
      return 6;
    }

    return 10;
  }, [chartDatasets]);

  return (
    <div className={classNames(styles.BrandCompareBarChart, className)}>
      <Bar
        data={{ datasets: chartDatasets, labels: chartLabels }}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          datasets: {
            bar: {
              borderColor: 'rgba(0,0,0,0)',
              minBarLength: 6,
              borderRadius: 4,
              maxBarThickness: 36,
              backgroundColor(context) {
                const index = context.dataIndex;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const point: any = context.dataset.data[index];

                if (!point) {
                  return 'black';
                }

                return chartColorMapByBrandKey[point.brandKey].DEFAULT;
              },
            },
          },
          plugins: {
            legend: { display: false },
            tooltip: {
              enabled: false,
              external: (context) => {
                const sessionCount = chartDatasets?.[0]?.data?.length;

                if (sessionCount <= 14) {
                  return brandCompareTooltipSingle({ context, yAxisFormatter });
                }

                return brandCompareTimeseriesTooltipMultiple({
                  context,
                  tooltipDataByDate: multipleBrandTooltipByFormattedDate,
                  brandColorByBrandKey: chartColorMapByBrandKey,
                  yAxisFormatter,
                });
              },
            },
            datalabels: { display: false },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 6,
                font: {
                  size: 13,
                },
                color: 'grey',
                callback(value) {
                  return yAxisFormatter?.(value) || value;
                },
              },
              grid: {
                drawBorder: false,
              },
            },
            x: {
              ticks: {
                maxRotation: 0,
                autoSkip: true,
                maxTicksLimit: sessionTickLimit,
                color: 'grey',
                padding: 12,
                font: {
                  size: 13,
                },
              },
              grid: {
                display: false,
                borderWidth: 1,
                borderColor: 'black',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default BrandCompareBarChart;
