import { useState, useEffect, useMemo, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import classNames from 'classnames';
import dayjs from 'dayjs';

import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useQuery from '../../../hooks/useQuery';

import MetricsOverview from '../MetricsOverview/MetricsOverview';
import MetricDefaultChartView from '../../Organisms/MetricDefaultChartView/MetricDefaultChartView';
import Dropdown from '../../Molecules/Dropdown/Dropdown';
import TypeaheadInput from '../../Molecules/TypeaheadInput/TypeaheadInput';
import PageTitle from '../../Molecules/PageTitle/PageTitle';
import FullScreenButton from '../../Atoms/FullScreenButton/FullScreenButton.jsx';
import ShareDropdown from '../../Molecules/ShareDropdown/ShareDropdown';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import NoResultsAlert from '../../Atoms/NoResultsAlert/NoResultsAlert';

import { handleGenericError } from '../../../utils/error';

import BNContext from '../../../contexts/BNContext';
import useRouter from '../../../hooks/useRouter';
import { getDayJsStartDate } from '../../../utils/timeframe';

import {
  DROPDOWN_VARIANTS,
  TYPEAHEAD_INPUT_VARIANTS,
} from '../../../constants/props';
import QUERY_PARAMS from '../../../constants/queryParams';
import {
  SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST,
  SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST,
  SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS,
} from '../../../constants/social-metrics';

import {
  GET_METRICS,
  GET_METRICS_BY_GROUP,
  GET_METRICS_BY_GROUP_AND_CATEGORY,
  GET_METRICS_TRENDLINE,
  GET_SOCIAL_METRICS_SET_DETAILS_BY_CHANNEL,
} from '../../../api/queries/Pages/Metrics';

import {
  prepareMetricGroupsList,
  prepareMetricCategoriesList,
  prepareMetricChannelsList,
  prepareMetricList,
  prepareTopMetrics,
  prepareBrandMetricsDatasets,
  prepareBrandSocialMetricsDatasets,
} from '../../../api/transforms/Pages/Metrics';

import { ScoreType, SocialChannelKey } from '../../../interfaces/metric';

import styles from './Metrics.module.scss';
import { FeatureFlag } from '../../../utils/featureFlags';

export default function Metrics() {
  const { updateRoute } = useRouter();
  const flags = useFlags();

  const {
    competitiveSetID: _competitiveSetId,
    currentSession: sessionKey,
    timeframe,
    FEATURE_FLAGS,
  } = useContext(BNContext);

  const queryParams = useQuery();
  const groupId = useMemo(
    () => queryParams.get(QUERY_PARAMS.METRIC_GROUP_ID),
    [queryParams]
  );
  const categoryId = useMemo(
    () => queryParams.get(QUERY_PARAMS.METRIC_CATEGORY_ID),
    [queryParams]
  );
  const channelId = useMemo(
    () =>
      queryParams.get(QUERY_PARAMS.METRIC_CHANNEL_ID) ??
      SocialChannelKey.Aggregate,
    [queryParams]
  );
  const variableId = useMemo(
    () => queryParams.get(QUERY_PARAMS.METRIC_VARIABLE_ID),
    [queryParams]
  );
  const scoreType = useMemo(
    () => queryParams.get(QUERY_PARAMS.METRIC_SCORE_TYPE) ?? ScoreType.Indexed,
    [queryParams]
  );

  const [typeaheadText, setTypeaheadText] = useState('');

  const [getMetrics, { data: metricsResp, loading: loadingMetrics }] =
    useLazyQuery(GET_METRICS);

  const [
    getMetricsByGroup,
    { data: metricsByGroupResp, loading: loadingMetricsByGroup },
  ] = useLazyQuery(GET_METRICS_BY_GROUP);

  const [
    getMetricsByGroupAndCategory,
    {
      data: metricsByGroupAndCategoryResp,
      loading: loadingMetricsByGroupAndCategory,
    },
  ] = useLazyQuery(GET_METRICS_BY_GROUP_AND_CATEGORY);

  const [
    getBrandMetricsDatasets,
    {
      data: metricsTrendlineResp,
      loading: loadingMetricsTrendline,
      called: getBrandMetricsDatasetsCalled,
    },
  ] = useLazyQuery(GET_METRICS_TRENDLINE);

  const queryOptions = {
    variables: {
      input: {
        competitiveSetId: _competitiveSetId,
        metricId: variableId,
        start: getDayJsStartDate(timeframe).toISOString(),
        end: dayjs(timeframe?.end).toISOString(),
      },
    },
    fetchPolicy: 'no-cache',
  };

  const [
    getSocialMetricsSetDetailsByChannel,
    {
      loading: loadingSocialMetricsSetDetailsByChannel,
      data: getSocialMetricsDetailsByChannelData,
    },
  ] = useLazyQuery(GET_SOCIAL_METRICS_SET_DETAILS_BY_CHANNEL, queryOptions);

  useEffect(() => {
    if (!_competitiveSetId || !sessionKey) {
      return;
    }

    getMetrics({
      variables: {
        id: _competitiveSetId,
        sessionKey,
      },
    });
  }, [_competitiveSetId, sessionKey, getMetrics]);

  const data = useMemo(() => {
    if (
      metricsByGroupResp &&
      metricsByGroupResp.competitiveSet?.competitiveSetKey === _competitiveSetId
    ) {
      return metricsByGroupResp;
    }

    if (
      metricsByGroupAndCategoryResp &&
      metricsByGroupAndCategoryResp.competitiveSet?.competitiveSetKey ===
        _competitiveSetId
    ) {
      return metricsByGroupAndCategoryResp;
    }
  }, [metricsByGroupResp, metricsByGroupAndCategoryResp, _competitiveSetId]);

  /* ----------- LISTS ----------- */
  const metricGroupList = useMemo(
    () => prepareMetricGroupsList(metricsResp),
    [metricsResp]
  );

  const metricCategoryList = useMemo(
    () => prepareMetricCategoriesList(metricsResp),
    [metricsResp]
  );

  const metricChannelList = useMemo(
    () =>
      prepareMetricChannelsList(
        metricsTrendlineResp,
        getSocialMetricsDetailsByChannelData
      ),
    [metricsTrendlineResp, getSocialMetricsDetailsByChannelData]
  );

  const metricList = useMemo(
    () => prepareMetricList(metricsResp),
    [metricsResp]
  );
  /* ----------- END LISTS ----------- */

  /* ----------- MAPS ----------- */
  const metricGroupById = useMemo(
    () => keyBy(metricGroupList, 'id'),
    [metricGroupList]
  );

  const metricGroupByName = useMemo(
    () => keyBy(metricGroupList, 'name'),
    [metricGroupList]
  );

  const metricCategoryById = useMemo(
    () => keyBy(metricCategoryList, 'id'),
    [metricCategoryList]
  );

  const metricCategoryByName = useMemo(
    () => keyBy(metricCategoryList, 'name'),
    [metricCategoryList]
  );

  const metricChannelById = useMemo(
    () => keyBy(metricChannelList, 'id'),
    [metricChannelList]
  );

  const metricById = useMemo(
    () => keyBy(metricList, 'variableId'),
    [metricList]
  );
  /* ----------- END MAPS ----------- */

  /* ----------- SELECTED ----------- */
  const selectedMetricChannel = useMemo(() => {
    if (!isEmpty(metricChannelById) && channelId) {
      return metricChannelById[channelId];
    }

    return {
      id: null,
    };
  }, [metricChannelById, channelId]);

  const selectedMetric = useMemo(() => {
    const socialMetricsResults =
      getSocialMetricsDetailsByChannelData?.getSocialMetricsSetDetailsByChannel;

    if (!isEmpty(metricById) && variableId) {
      const currentlySelectedMetric = metricById[variableId];
      const nameWithoutChannel = metricById[variableId].name;

      if (selectedMetricChannel.name) {
        currentlySelectedMetric.nameWithChannel = nameWithoutChannel
          .concat(' - ')
          .concat(selectedMetricChannel.name);
      }

      if (socialMetricsResults) {
        const socialResultsByChannelId = keyBy(
          socialMetricsResults[0].results,
          'channel'
        );

        currentlySelectedMetric.descriptionOfChannel =
          socialResultsByChannelId[channelId]?.description;
      }

      return currentlySelectedMetric;
    }
  }, [
    metricById,
    variableId,
    selectedMetricChannel,
    getSocialMetricsDetailsByChannelData,
    channelId,
  ]);

  const selectedMetricGroup = useMemo(() => {
    if (
      !isEmpty(metricGroupById) &&
      !isEmpty(metricGroupByName) &&
      !isEmpty(metricCategoryById) &&
      !isEmpty(metricById)
    ) {
      if (groupId) {
        return metricGroupById[groupId];
      }

      if (categoryId) {
        const groupIdByCategory = metricCategoryById[categoryId].metricGroupId;

        return metricGroupById[groupIdByCategory];
      }

      if (selectedMetric && selectedMetric.variableId) {
        return metricGroupByName[selectedMetric.group];
      }
    }
  }, [
    metricGroupById,
    groupId,
    metricById,
    metricGroupByName,
    selectedMetric,
    metricCategoryById,
    categoryId,
  ]);

  const selectedMetricCategory = useMemo(() => {
    if (!isEmpty(metricCategoryById) && categoryId) {
      return metricCategoryById[categoryId];
    }

    if (selectedMetric && selectedMetric.variableId) {
      return metricCategoryByName[selectedMetric.category];
    }

    return {
      id: null,
    };
  }, [metricCategoryById, metricCategoryByName, selectedMetric, categoryId]);

  /* ----------- END SELECTED ----------- */

  /* ----------- TOP NAV ----------- */
  const typeaheadOptions = useMemo(() => {
    if (!metricList) {
      return;
    }

    const result = [];

    metricList.forEach((metric) => {
      result.push({
        value: metric.name,
        id: metric.variableId,
      });
    });

    return result;
  }, [metricList]);
  /* ----------- END TOP NAV ----------- */

  /* ----------- DROPDOWNS ----------- */
  const metricGroupDropdownOptions = useMemo(() => {
    if (!metricGroupList) {
      return;
    }

    const groupList = cloneDeep(metricGroupList);

    const content = groupList.pop();
    const business = groupList.pop();
    const digital = groupList.pop();

    groupList.push(business);
    groupList.push(digital);
    groupList.push(content);

    if (groupList[0]) {
      return formatDropdownList(groupList);
    }
  }, [metricGroupList]);

  const metricCategoryDropdownOptions = useMemo(() => {
    if (!selectedMetricGroup) {
      return;
    }

    const metricCategories = filter(metricCategoryList, {
      metricGroupId: selectedMetricGroup.id,
    });

    metricCategories.unshift({
      name: 'Overview',
      id: null,
    });

    return formatDropdownList(metricCategories);
  }, [metricCategoryList, selectedMetricGroup]);

  const metricChannelDropdownOptions = useMemo(() => {
    if (!selectedMetricGroup) {
      return;
    }

    if (!metricChannelList) {
      return;
    }

    return formatDropdownList(metricChannelList);
  }, [metricChannelList, selectedMetricGroup]);
  /* ----------- END DROPDOWNS ----------- */

  useEffect(() => {
    if (
      !groupId &&
      !categoryId &&
      !variableId &&
      metricGroupDropdownOptions &&
      metricGroupDropdownOptions[0]
    ) {
      updateRoute({
        params: {
          [QUERY_PARAMS.METRIC_GROUP_ID]: metricGroupDropdownOptions[0].value,
        },
      });
    }
  }, [
    groupId,
    categoryId,
    variableId,
    metricGroupDropdownOptions,
    updateRoute,
  ]);

  useEffect(() => {
    if (!_competitiveSetId || !sessionKey) {
      return;
    }

    const variablesBase = {
      id: _competitiveSetId,
      sessionKey,
    };

    if (groupId && selectedMetricGroup) {
      getMetricsByGroup({
        variables: {
          ...variablesBase,
          groupId: selectedMetricGroup.id,
        },
      });
    }

    if (categoryId && selectedMetricGroup && selectedMetricCategory) {
      getMetricsByGroupAndCategory({
        variables: {
          ...variablesBase,
          groupId: selectedMetricGroup.id,
          categoryId: selectedMetricCategory.id,
        },
      });
    }

    if (variableId) {
      getBrandMetricsDatasets({
        variables: {
          ...variablesBase,
          metricId: variableId,
        },
      });

      if (
        flags[FeatureFlag.ViewMetricsSocialMetrics] &&
        (SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(variableId) ||
          SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(variableId))
      ) {
        getSocialMetricsSetDetailsByChannel();
      }
    }
  }, [
    _competitiveSetId,
    variableId,
    groupId,
    selectedMetricGroup,
    categoryId,
    selectedMetricCategory,
    sessionKey,
    getMetricsByGroup,
    getMetricsByGroupAndCategory,
    getBrandMetricsDatasets,
    getSocialMetricsSetDetailsByChannel,
    flags,
  ]);

  /* ----------- OVERVIEW ----------- */
  const topMetrics = useMemo(() => {
    if (!selectedMetricGroup || !selectedMetricCategory) {
      return null;
    }

    return prepareTopMetrics({
      data,
      timeframe,
      metricGroupId: selectedMetricGroup.id,
      metricCategoryId: selectedMetricCategory.id,
    });
  }, [data, selectedMetricCategory, selectedMetricGroup, timeframe]);

  const filteredMetrics = useMemo(() => {
    if (!(selectedMetricCategory && selectedMetricGroup) || variableId) {
      return;
    }

    if (selectedMetricCategory.id === null) {
      return metricList.filter((metric) => {
        return metric.group === selectedMetricGroup.name;
      });
    }

    return metricList.filter((metric) => {
      return metric.category === selectedMetricCategory.name;
    });
  }, [metricList, selectedMetricGroup, selectedMetricCategory, variableId]);
  /* ----------- END OVERVIEW ----------- */

  /* ----------- DETAILS ----------- */
  const brandMetricsIndexedDataSet = useMemo(
    () =>
      handleGenericError(
        () => prepareBrandMetricsDatasets(metricsTrendlineResp, timeframe),

        'Metrics prepareBrandMetricsDatasets failed transform'
      ),
    [metricsTrendlineResp, timeframe]
  );

  const brandMetricsDatasetsForSocial = useMemo(
    () =>
      handleGenericError(
        () =>
          prepareBrandSocialMetricsDatasets(
            metricsTrendlineResp,
            getSocialMetricsDetailsByChannelData,
            variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE
              ? SocialChannelKey.Aggregate
              : channelId
          ),

        'Metrics prepareBrandSocialMetricsDatasets failed transform'
      ),
    [
      metricsTrendlineResp,
      getSocialMetricsDetailsByChannelData,
      channelId,
      variableId,
    ]
  );

  /* ----------- END DETAILS ----------- */

  /* ----------- LOADING ----------- */
  // const loadingStaticData = useMemo(
  //   () => !metricGroupList || !metricCategoryList,
  //   [metricGroupList, metricCategoryList]
  // );

  const loadingStaticData = useMemo(
    () => !metricGroupList || !metricCategoryList || !metricChannelList,
    [metricGroupList, metricCategoryList, metricChannelList]
  );

  const loadingRequests = useMemo(
    () =>
      loadingMetrics ||
      loadingMetricsByGroup ||
      loadingMetricsByGroupAndCategory ||
      loadingMetricsTrendline ||
      loadingSocialMetricsSetDetailsByChannel,
    [
      loadingMetrics,
      loadingMetricsByGroup,
      loadingMetricsByGroupAndCategory,
      loadingMetricsTrendline,
      loadingSocialMetricsSetDetailsByChannel,
    ]
  );

  const loadingOverview = useMemo(
    () =>
      loadingRequests || loadingStaticData || !topMetrics || !filteredMetrics,
    [loadingRequests, loadingStaticData, topMetrics, filteredMetrics]
  );

  const loadingDetails = useMemo(
    () =>
      !getBrandMetricsDatasetsCalled ||
      isNil(brandMetricsIndexedDataSet) ||
      !selectedMetric,
    [getBrandMetricsDatasetsCalled, brandMetricsIndexedDataSet, selectedMetric]
  );
  /* ----------- END LOADING ----------- */

  function formatDropdownList(list) {
    const result = [];

    list.forEach((item) => {
      result.push({
        value: item.id,
        label: item.name,
      });
    });

    return result;
  }

  function handleMetricGroupChange(option) {
    setTypeaheadText('');

    updateRoute({
      params: {
        [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
        [QUERY_PARAMS.METRIC_GROUP_ID]: option.value,
        [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
        [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
        [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
      },
    });
  }

  function handleMetricCategoryChange(option) {
    setTypeaheadText('');

    if (isNil(option.value)) {
      updateRoute({
        params: {
          [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
          [QUERY_PARAMS.METRIC_GROUP_ID]: selectedMetricGroup.id,
          [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
          [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
          [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
        },
      });
    } else {
      updateRoute({
        params: {
          [QUERY_PARAMS.METRIC_GROUP_ID]: null,
          [QUERY_PARAMS.METRIC_CATEGORY_ID]: option.value,
          [QUERY_PARAMS.METRIC_VARIABLE_ID]: null,
          [QUERY_PARAMS.METRIC_SCORE_TYPE]: null,
          [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
        },
      });
    }
  }

  function handleMetricChannelChange(option) {
    setTypeaheadText('');

    const newParams = {
      [QUERY_PARAMS.METRIC_CHANNEL_ID]: option.value,
    };

    if (option.value === SocialChannelKey.Aggregate) {
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = null;
    } else {
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = ScoreType.Raw;
    }

    updateRoute({
      params: newParams,
    });
  }

  function handleMetricClick(metricVariableId, id = null) {
    if (metricById[metricVariableId] && metricById[metricVariableId].name) {
      setTypeaheadText(metricById[metricVariableId].name);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `Metric.jsx handleMetricClick: no metric in map for metricVariableId ${metricVariableId}`,
        {
          competitiveSetID: _competitiveSetId,
          sessionKey,
          groupId,
          categoryId,
          variableId,
        }
      );
    }

    const newParams = {
      [QUERY_PARAMS.METRIC_VARIABLE_ID]: metricVariableId,
      [QUERY_PARAMS.METRIC_CATEGORY_ID]: null,
      [QUERY_PARAMS.METRIC_GROUP_ID]: null,
    };

    if (
      SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(metricVariableId) ||
      SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(metricVariableId)
    ) {
      if (id) {
        newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = id;
        newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = ScoreType.Raw;
      } else {
        newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = SocialChannelKey.Aggregate;
        newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = ScoreType.Indexed;
      }
      // handle youtube video views raw
    } else if (
      metricVariableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.ZZ &&
      id === null
    ) {
      newParams[QUERY_PARAMS.METRIC_CHANNEL_ID] = id;
      newParams[QUERY_PARAMS.METRIC_SCORE_TYPE] = ScoreType.Raw;
    }

    updateRoute({
      params: newParams,
    });
  }

  function handleTypeaheadChange(option) {
    setTypeaheadText(option.value);

    if (option.id) {
      handleMetricClick(option.id);
    }
  }

  const handleScoreTypeChange = (newScoreType) => {
    // handle youtube video views indexed
    if (
      variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE &&
      newScoreType === ScoreType.Raw
    ) {
      updateRoute({
        params: {
          [QUERY_PARAMS.METRIC_SCORE_TYPE]: newScoreType,
          [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
          [QUERY_PARAMS.METRIC_VARIABLE_ID]:
            SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.ZZ,
        },
      });

      return;
    }

    // handle youtube video views raw
    if (
      variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.ZZ &&
      newScoreType === ScoreType.Indexed
    ) {
      updateRoute({
        params: {
          [QUERY_PARAMS.METRIC_SCORE_TYPE]: newScoreType,
          [QUERY_PARAMS.METRIC_CHANNEL_ID]: null,
          [QUERY_PARAMS.METRIC_VARIABLE_ID]:
            SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE,
        },
      });

      return;
    }

    updateRoute({
      params: {
        [QUERY_PARAMS.METRIC_SCORE_TYPE]: newScoreType,
      },
    });
  };

  let brandMetricsDatasets =
    scoreType === ScoreType.Indexed
      ? brandMetricsIndexedDataSet
      : brandMetricsDatasetsForSocial;

  /**
   * Special case for youtube video views
   */
  if (
    variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.ZZ ||
    variableId === SOCIAL_METRIC_YOUTUBE_VIDEO_VIEWS.BASE
  ) {
    brandMetricsDatasets = brandMetricsIndexedDataSet;
  }

  const metricsDetailLoaded = variableId && !loadingDetails;

  const shouldRenderMetricsOverview =
    (groupId || categoryId) &&
    (selectedMetricGroup || selectedMetricCategory) &&
    !loadingOverview;

  const showSocialChannelDropdown =
    flags[FeatureFlag.ViewMetricsSocialMetrics] &&
    SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(variableId);

  return (
    <Box
      className={classNames(styles.Metrics, 'WorkspaceContainer')}
      id="page-wrapper"
    >
      <Grid
        container
        className={classNames(
          styles.TitleRowContainer,
          'WorkspaceTitleContainer'
        )}
      >
        <Grid item xs={12} md={6}>
          <PageTitle padded title="Metrics" />
        </Grid>

        <Grid item xs={12} md={6} className={styles.HeaderRight}>
          <TypeaheadInput
            className={classNames(styles.TypeAhead, 'hide-from-share')}
            variant={TYPEAHEAD_INPUT_VARIANTS.DROPDOWN}
            potentialResults={typeaheadOptions}
            placeholder="All metrics"
            inputId="metrics-search"
            value={typeaheadText}
            onChange={handleTypeaheadChange}
            forceSelect
            filterResults
          />
          {FEATURE_FLAGS.GLOBAL.SHARE.ROOT && (
            <ShareDropdown targetId="page-wrapper" />
          )}
          {FEATURE_FLAGS.GLOBAL.FULL_SCREEN && <FullScreenButton />}
        </Grid>
      </Grid>
      <Box className="PageContainer">
        <Grid item xs={12}>
          {!loadingStaticData &&
            selectedMetricGroup &&
            selectedMetricCategory && (
              <div className={styles.DropdownFilters}>
                <Dropdown
                  variant={DROPDOWN_VARIANTS.NAKED}
                  options={metricGroupDropdownOptions}
                  onChange={handleMetricGroupChange}
                  value={selectedMetricGroup.id}
                />

                <Dropdown
                  variant={DROPDOWN_VARIANTS.NAKED}
                  options={metricCategoryDropdownOptions}
                  onChange={handleMetricCategoryChange}
                  value={selectedMetricCategory.id}
                />

                {showSocialChannelDropdown && (
                  <Dropdown
                    variant={DROPDOWN_VARIANTS.NAKED}
                    options={metricChannelDropdownOptions}
                    onChange={handleMetricChannelChange}
                    value={selectedMetricChannel.id}
                  />
                )}
              </div>
            )}
        </Grid>

        {(groupId || categoryId) && loadingOverview ? (
          <ComponentLoader minHeight={600} />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              {shouldRenderMetricsOverview && (
                <Box className={styles.Content}>
                  <MetricsOverview
                    metricGroup={
                      selectedMetricCategory.id === null
                        ? selectedMetricGroup
                        : selectedMetricCategory
                    }
                    topMetrics={topMetrics}
                    metrics={filteredMetrics}
                    onMetricClick={handleMetricClick}
                    onTitleClick={handleMetricClick}
                  />
                </Box>
              )}

              {metricsDetailLoaded && (
                <Box className={styles.Content}>
                  {brandMetricsDatasets?.length ? (
                    <MetricDefaultChartView
                      metric={selectedMetric}
                      brandMetricsDatasets={brandMetricsDatasets}
                      scoreType={scoreType}
                      onScoreTypeChange={handleScoreTypeChange}
                      socialChannelId={channelId}
                    />
                  ) : (
                    <NoResultsAlert />
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
