import {
  AnchorHTMLAttributes,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import keyBy from 'lodash/keyBy';
import first from 'lodash/first';
import last from 'lodash/last';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import CellFull, {
  FullProps as CellFullProps,
} from './MetricGroupWidgetCells/Full/Full';
import CellLabel, {
  LabelProps as CellLabelProps,
} from './MetricGroupWidgetCells/Label/Label';
import CellPercentageDiff, {
  PercentageDiffProps as CellPercentageDiffProps,
} from './MetricGroupWidgetCells/PercentageDiff/PercentageDiff';
import WidgetSubheader from '../../WidgetSubheader/WidgetSubheader';

import { WidgetMetaResponse } from '../../types';
import { TimeRange, WidgetType } from '../../../../../interfaces/dashboard-api';
import { ScoreType } from '../../../../../interfaces/metric';
import {
  getMetrics,
  RawScoreFormat,
} from '../../../../../mocks/data/CustomizableDashboards/metrics';
import { showRawMetricValue } from '../../../../../utils/metric';
import { timeRangeById } from '../../WidgetSettingsShared';
import { handleConfigInputArrayFromDashboardAPI } from '../../WidgetSettingsFlows/helper';

import {
  GET_FIRST_AND_LAST_SESSION,
  GET_METRIC_GROUP_WIDGET_DATA,
  GetMetricGroupMetricResp,
} from '../../../../../api/queries/Pages/CustomizableDashboards';

import styles from './MetricGroupWidget.module.scss';
import LoadingWidget from '../LoadingWidget/LoadingWidget';

enum CellType {
  Label = 'label',
  Full = 'full',
  PercentageDiff = 'percentage-diff',
}

enum ColumnKey {
  RowLabel = 'row-label',
  RelativePerfVs30Days = 'relative-perf-vs-30-days',
  IsolatedPerfVs30Days = 'isolated-perf-vs-30-days',
  IsolatedPerfVsCohortAvg = 'isolated-perf-vs-cohort-avg',
  IsolatedRateVs30Days = 'isolated-rate-vs-30-days',
}

interface Column {
  name: string;
  key: ColumnKey;
  cellType: CellType;
}

const columnMap: Record<ColumnKey, Column> = {
  [ColumnKey.RowLabel]: {
    name: '',
    key: ColumnKey.RowLabel,
    cellType: CellType.Label,
  },
  [ColumnKey.RelativePerfVs30Days]: {
    name: 'Relative Performance vs Prev. Period',
    key: ColumnKey.RelativePerfVs30Days,
    cellType: CellType.Full,
  },
  [ColumnKey.IsolatedPerfVs30Days]: {
    name: 'Raw Metric Performance over Period Selected',
    key: ColumnKey.IsolatedPerfVs30Days,
    cellType: CellType.Full,
  },
  [ColumnKey.IsolatedPerfVsCohortAvg]: {
    name: 'Raw Performance vs Cohort Avg.',
    key: ColumnKey.IsolatedPerfVsCohortAvg,
    cellType: CellType.PercentageDiff,
  },
  [ColumnKey.IsolatedRateVs30Days]: {
    name: 'Raw Performance vs Prev. Period',
    key: ColumnKey.IsolatedRateVs30Days,
    cellType: CellType.PercentageDiff,
  },
};

const metricRowColumnOrder: ColumnKey[] = [
  ColumnKey.RowLabel,
  ColumnKey.RelativePerfVs30Days,
  ColumnKey.IsolatedPerfVs30Days,
  ColumnKey.IsolatedPerfVsCohortAvg,
  ColumnKey.IsolatedRateVs30Days,
];

interface Row {
  [ColumnKey.RowLabel]: CellLabelProps;
  [ColumnKey.RelativePerfVs30Days]: CellFullProps;
  [ColumnKey.IsolatedPerfVs30Days]: CellFullProps;
  [ColumnKey.IsolatedPerfVsCohortAvg]: CellPercentageDiffProps;
  [ColumnKey.IsolatedRateVs30Days]: CellPercentageDiffProps;
}

const metricById = keyBy(getMetrics, 'id');

export interface MetricGroupWidgetProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  config?: WidgetMetaResponse;
  globalDateFilter: {
    endDate: Date;
    timeRange: TimeRange | null;
  };
}

const MetricGroupWidget: FunctionComponent<MetricGroupWidgetProps> = ({
  config,
  globalDateFilter,
}) => {
  const [activeEndDate, setActiveEndDate] = useState<Date>(
    config?.data_srcs?.metrics?.input?.endDate
      ? new Date(config?.data_srcs?.metrics?.input?.endDate as Date)
      : new Date()
  );

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [
    getMetricGroupWidgetData,
    { loading, data: metricGroupWidgetDataResp },
  ] = useLazyQuery(GET_METRIC_GROUP_WIDGET_DATA, {
    fetchPolicy: 'no-cache',
  });

  const [activeTimeRange, setActiveTimeRange] = useState<TimeRange>(
    config?.data_srcs?.metrics?.time_range ?? TimeRange['90_DAYS']
  );

  useEffect(() => {
    if (globalDateFilter?.timeRange && globalDateFilter?.endDate) {
      setActiveTimeRange(globalDateFilter.timeRange);
      setActiveEndDate(globalDateFilter.endDate);
    } else {
      setActiveTimeRange(
        config?.data_srcs?.metrics?.time_range ?? TimeRange['90_DAYS']
      );
      setActiveEndDate(
        config?.data_srcs?.metrics?.input?.endDate
          ? new Date(config?.data_srcs?.metrics?.input?.endDate as Date)
          : new Date()
      );
    }
  }, [globalDateFilter, config]);

  useEffect(() => {
    if (config?.data_srcs?.metrics?.input) {
      const { competitiveSetKey } = config.data_srcs.metrics.input;

      getFirstLastSession({
        variables: {
          id: competitiveSetKey,
        },
      });
    }
  }, [config, getFirstLastSession]);

  // TODO: get session key based on custom date range
  const latestSessionKey =
    firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
      ?.sessionKey;

  useEffect(() => {
    if (!config?.data_srcs?.metrics?.input || !latestSessionKey) return;

    const {
      competitiveSetKey,
      brandKey,
      selected_metrics: selectedMetrics,
    } = config.data_srcs.metrics.input;

    const associatedTypeIds = handleConfigInputArrayFromDashboardAPI(
      selectedMetrics
    ) as { id: string; name: string }[];

    getMetricGroupWidgetData({
      variables: {
        id: competitiveSetKey,
        sessionKey: latestSessionKey,
        input: {
          associatedTypeIds: associatedTypeIds.map((item) => item.id),
          range: timeRangeById[activeTimeRange].value,
          endDate: activeEndDate,
          selectedBrandKey: brandKey,
          compSetKey: competitiveSetKey,
        },
      },
    });
  }, [
    config,
    activeEndDate,
    activeTimeRange,
    latestSessionKey,
    getMetricGroupWidgetData,
  ]);

  const tableData: Row[] = useMemo(() => {
    const result: Row[] = [];

    if (
      !metricGroupWidgetDataResp?.competitiveSet?.session?.groupMetricWidget ||
      !config?.data_srcs?.metrics?.input
    )
      return result;

    const { selected_metrics: selectedMetrics } =
      config.data_srcs.metrics.input;
    const associatedMetricIds = handleConfigInputArrayFromDashboardAPI(
      selectedMetrics
    ) as { id: string; name: string }[];

    // TODO: make the order more deterministic
    //  initially using the order from settings stored in Dashboard API
    //  which is set based on the groupings in metrics.ts
    const sortedMetricIds = associatedMetricIds.map((item) => item.id);

    const { groupMetricWidget } =
      metricGroupWidgetDataResp.competitiveSet.session;

    groupMetricWidget.metrics
      .sort(
        (a: GetMetricGroupMetricResp, b: GetMetricGroupMetricResp) =>
          sortedMetricIds.indexOf(a.metric) - sortedMetricIds.indexOf(b.metric)
      )
      .forEach((metric: GetMetricGroupMetricResp) => {
        const newItem = {
          [ColumnKey.RowLabel]: {
            label: metricById[metric.metric]?.name ?? 'Unknown Metric',
          },
          [ColumnKey.RelativePerfVs30Days]: {
            score: metric.relativeScoreTile,
            diff: metric.relativeScoreDelta,
            mainLineTimeseriesData: metric.relativeTimeSeries.map((item) => ({
              x: new Date(item.date),
              y: item.value,
            })),
            dashedLineTimeseriesData: [
              {
                x: new Date(first(metric.relativeTimeSeries)?.date ?? ''),
                y: 100,
              },
              {
                x: new Date(last(metric.relativeTimeSeries)?.date ?? ''),
                y: 100,
              },
            ],
            // TODO: adjust scoreType and hasIndexed when social channels contain indexed data
            scoreType: metricById[metric.metric]?.is_social_channel
              ? ScoreType.Raw
              : ScoreType.Indexed,
            hasIndexed: !metricById[metric.metric]?.is_social_channel,
          },
          [ColumnKey.IsolatedPerfVs30Days]: {
            score: metric.isolatedScoreTile,
            diff:
              // TODO: handle RawScoreFormat.Percentage metric deltas consistently across widgets
              metricById[metric.metric]?.raw_score_format ===
              RawScoreFormat.Percentage
                ? Math.round(Number(metric.isolatedScoreDelta) * 100)
                : metric.isolatedScoreDelta,
            mainLineTimeseriesData: metric.isolatedTimeSeries.map((item) => ({
              x: new Date(item.date),
              y: item.value,
            })),
            dashedLineTimeseriesData: metric.cohortTimeSeries.map((item) => ({
              x: new Date(item.date),
              y: item.value,
            })),
            scoreType: ScoreType.Raw,
            hasRaw: showRawMetricValue(metricById[metric.metric]),
            // TODO: handle RawScoreFormat.Percentage metrics consistently across widgets
            isPercentage:
              metricById[metric.metric]?.is_social_channel &&
              metricById[metric.metric]?.raw_score_format ===
                RawScoreFormat.Percentage,
          },
          [ColumnKey.IsolatedPerfVsCohortAvg]: {
            diffPercentage: metric.isolatedCohortComparison,
          },
          [ColumnKey.IsolatedRateVs30Days]: {
            diffPercentage: metric.isolatedChangeRate,
          },
        };

        result.push(newItem);
      });

    return result;
  }, [metricGroupWidgetDataResp, config]);

  const brand = useMemo(() => {
    const { selectedBrandName: name, brandLogoUrl: logoUrl } =
      metricGroupWidgetDataResp?.competitiveSet?.session?.groupMetricWidget ??
      {};
    return { name, logoUrl };
  }, [metricGroupWidgetDataResp]);

  const handleTimeRangeChange = (option: TimeRange) =>
    setActiveTimeRange(option);

  const handleApply = (e: { endDate: Date; timeRange: TimeRange | null }) => {
    setActiveTimeRange(e.timeRange ?? TimeRange['90_DAYS']);
    setActiveEndDate(e.endDate);
  };

  return (
    <div className={styles.MetricGroupWidget}>
      {loading ? (
        <LoadingWidget widgetType={WidgetType.MetricCollectionV1} />
      ) : (
        <>
          <div className={styles.WidgetSubheaderContainer}>
            <WidgetSubheader
              brandName={brand.name}
              brandLogoUrl={brand.logoUrl}
              activeTimeRange={activeTimeRange}
              handleTimeRangeChange={handleTimeRangeChange}
              activeEndDate={activeEndDate}
              onCustomTimeRangeChange={handleApply}
            />
          </div>

          <TableContainer component={Box}>
            <Table className={styles.Table} aria-label="Metric Group Table">
              <TableHead>
                <TableRow>
                  {metricRowColumnOrder.map((colKey) => {
                    const tableCol = columnMap[colKey];

                    return (
                      <TableCell
                        key={colKey}
                        className={styles.HeaderCell}
                        align="center"
                      >
                        <p className={styles.HeaderLabel}>{tableCol.name}</p>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData?.map((row) => {
                  return (
                    <TableRow
                      className={styles.Row}
                      key={row[ColumnKey.RowLabel].label}
                    >
                      {metricRowColumnOrder.map((colKey) => {
                        const tableCol = columnMap[colKey];
                        const cellData = row[colKey];

                        switch (tableCol.cellType) {
                          case CellType.Label:
                            return (
                              <CellLabel
                                key={colKey}
                                label={(cellData as CellLabelProps).label}
                              />
                            );

                          case CellType.Full:
                            return (
                              <CellFull
                                key={colKey}
                                score={(cellData as CellFullProps).score}
                                diff={(cellData as CellFullProps).diff}
                                mainLineTimeseriesData={
                                  (cellData as CellFullProps)
                                    .mainLineTimeseriesData
                                }
                                dashedLineTimeseriesData={
                                  (cellData as CellFullProps)
                                    .dashedLineTimeseriesData
                                }
                                scoreType={
                                  (cellData as CellFullProps).scoreType
                                }
                                hasRaw={(cellData as CellFullProps).hasRaw}
                                hasIndexed={
                                  (cellData as CellFullProps).hasIndexed
                                }
                                isPercentage={
                                  (cellData as CellFullProps).isPercentage
                                }
                              />
                            );

                          case CellType.PercentageDiff:
                            return (
                              <CellPercentageDiff
                                key={colKey}
                                diffPercentage={
                                  (cellData as CellPercentageDiffProps)
                                    .diffPercentage
                                }
                              />
                            );

                          default:
                            return null;
                        }
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default MetricGroupWidget;
