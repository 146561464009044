import { GenericIconProps } from './types';

const CalendarIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0L24 0 24 24 0 24z"
                  transform="translate(-996 -180) translate(768 144) translate(16 8) translate(0 24) translate(212 4)"
                />
                <path
                  fill="currentColor"
                  d="M16.5 2c.276 0 .5.224.5.5V5h2.5C20.878 5 22 6.121 22 7.5v12c0 1.379-1.122 2.5-2.5 2.5h-15C3.122 22 2 20.879 2 19.5v-12C2 6.121 3.122 5 4.5 5H7V2.5c0-.276.224-.5.5-.5s.5.224.5.5V5h8V2.5c0-.276.224-.5.5-.5zm4.5 8H3v9.5c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5V10zM6.5 18c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm6 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm-3 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm6 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm3 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm-12-3c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm6 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm-3 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm6 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm3 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm-12-3c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm6 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm-3 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm6 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm3 0c.276 0 .5.224.5.5s-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1-6h-15C3.673 6 3 6.673 3 7.5V9h18V7.5c0-.827-.673-1.5-1.5-1.5z"
                  transform="translate(-996 -180) translate(768 144) translate(16 8) translate(0 24) translate(212 4)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
