import { FunctionComponent, useEffect } from 'react';
import classNames from 'classnames';

import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';
import { WidgetType } from '../../../../../interfaces/dashboard-api';
import SingleMetricSettingsForm from '../../Widgets/SingleMetricWidget/SettingsForm/SingleMetricSettingsForm';
import MetricComparisonSettingsForm from '../../Widgets/BlueScoreWidget/SettingsForm/MetricComparisonSettingsForm';

import styles from './SelectMetricGuide.module.scss';
import PencilEdit from '../../../../../assets/icons/PencilEdit';
import { WidgetSettingsModalAction } from '../../types';

const SelectMetricGuide: FunctionComponent<WidgetConfigProps> = ({
  config,
  onConfigChange,
  widgetType,
  settingAction,
  onValidationChange,
  onPreviousStage,
}) => {
  const selectedMetric = config[FlowKey.SelectMetric];

  const handleSelectedMetricDelete = () => {
    onPreviousStage();
  };

  const renderSettingsForm = (id: string) => {
    switch (id) {
      case WidgetType.MetricAnalysisV1:
        return (
          <SingleMetricSettingsForm
            config={config}
            setMetricAsName={settingAction === WidgetSettingsModalAction.Add}
            onConfigChange={onConfigChange}
          />
        );
      case WidgetType.MetricComparisonV1:
        return (
          <MetricComparisonSettingsForm
            setMetricAsName={settingAction === WidgetSettingsModalAction.Add}
            config={config}
            onConfigChange={onConfigChange}
          />
        );
      default:
        return <p>To Be Rendered</p>;
    }
  };

  useEffect(() => {
    const getFlowKey = (type: WidgetType) => {
      switch (type) {
        case WidgetType.MetricAnalysisV1:
          return FlowKey.SingleMetricSettings;
        case WidgetType.MetricComparisonV1:
          return FlowKey.MetricComparisonSettings;
        default:
          return undefined;
      }
    };

    const singleMetricChangingConfig = config[FlowKey.SingleMetricSettings];
    const metricComparisonChangingConfig =
      config[FlowKey.MetricComparisonSettings];

    const widgetValidationMap = {
      [FlowKey.SingleMetricSettings]:
        !singleMetricChangingConfig.brandKey ||
        !singleMetricChangingConfig.competitiveSetKey,
      [FlowKey.MetricComparisonSettings]:
        !metricComparisonChangingConfig.brands?.length ||
        !metricComparisonChangingConfig.competitiveSetKey,
    };

    const flowKey = getFlowKey(widgetType);

    if (!flowKey) return;

    onValidationChange({ [flowKey]: widgetValidationMap[flowKey] });
  }, [config, onValidationChange, widgetType]);

  return (
    <div className={classNames(styles.SelectMetricGuide)}>
      <div className={styles.MetricSelection}>
        <p className={styles.MetricTitle}>
          METRIC
          <span className={styles.Required}>*</span>
        </p>

        <button
          type="button"
          onClick={() => handleSelectedMetricDelete()}
          className={styles.MetricTag}
        >
          {selectedMetric.name}
          <PencilEdit />
        </button>
      </div>

      <div className={styles.SettingsForm}>
        {renderSettingsForm(widgetType)}
      </div>
    </div>
  );
};

export default SelectMetricGuide;
