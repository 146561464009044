import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useState,
  useMemo,
} from 'react';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Button } from '@blueoceanai/react-component-library';
import ActionsDropdown, {
  ActionDropdownItem,
} from '../ActionsDropdown/ActionsDropdown';
import DownloadDropdown from '../../../Molecules/DownloadDropdown/DownloadDropdown';
import MetricContextualHelpIcon from '../../../../assets/icons/MetricIcons/MetricContextualHelpIcon';
import { FeatureFlag } from '../../../../utils/featureFlags';

import styles from './WidgetContainer.module.scss';
import { DraggableHandleClass, WidgetTemplate } from '../types';
import { DragIcon } from '../../../../assets/icons';
import { ICapabilities } from '../../../../interfaces/dashboard-api';

export enum WidgetActionType {
  Edit = 'edit',
  Delete = 'delete',
}

export interface WidgetActionClickHandler {
  (widgetId: string): void;
}

export interface WidgetContainerProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  title: string;
  widgetId: string;
  onWidgetEditClick: WidgetActionClickHandler;
  onWidgetDeleteClick: WidgetActionClickHandler;
  onWidgetContextualHelpClick: WidgetActionClickHandler;
  disableActions?: boolean | undefined;
  capabilities: ICapabilities;
}

const WidgetContainer: FunctionComponent<WidgetContainerProps> = ({
  className,
  title,
  widgetId,
  onWidgetEditClick,
  onWidgetDeleteClick,
  onWidgetContextualHelpClick,
  children,
  disableActions,
  capabilities,
}) => {
  const flags = useFlags();
  const [widgetData] = useState<WidgetTemplate>();
  const ActionHandlerStrategy: Record<
    WidgetActionType,
    WidgetActionClickHandler
  > = {
    [WidgetActionType.Edit]: onWidgetEditClick,
    [WidgetActionType.Delete]: onWidgetDeleteClick,
  };

  const handleActionClick = (item: ActionDropdownItem) => {
    ActionHandlerStrategy[item.value as WidgetActionType]?.(widgetId);
  };

  const widgetElId = `widget-${widgetId}`;

  // in case of widget error, we can still delete the empty widget card,
  // but opening widget modal is disabled
  const modifiedWidgetActions = useMemo(() => {
    const actions: ActionDropdownItem[] = [];
    if (!disableActions && capabilities?.can_edit) {
      actions.push({ label: 'Edit', value: WidgetActionType.Edit });
    }

    if (capabilities?.can_remove_widget) {
      actions.push({ label: 'Delete', value: WidgetActionType.Delete });
    }

    return actions;
  }, [capabilities, disableActions]);

  return (
    <div
      className={classNames(styles.WidgetContainer, className)}
      id={widgetElId}
    >
      <div className={styles.WidgetHeader}>
        <div className={styles.Title}>{widgetData?.name || title}</div>

        <div
          className={classNames(
            styles.ActionsContainer,
            'hide-from-export',
            'hide-from-share'
          )}
        >
          {capabilities?.can_edit && (
            <DragIcon
              className={classNames(DraggableHandleClass, styles.DragIcon)}
            />
          )}

          <DownloadDropdown
            className={styles.DownloadDropdown}
            buttonClassName={styles.DownloadDropdownButton}
            targetId={widgetElId}
            padding={24}
          />

          {flags[FeatureFlag.ViewDashboardWidgetContextualHelp] ?? (
            <Button
              className={styles.ContextualHelpButton}
              onClick={() => onWidgetContextualHelpClick(widgetId)}
            >
              <MetricContextualHelpIcon />
            </Button>
          )}

          {!!modifiedWidgetActions.length && (
            <ActionsDropdown
              className={styles.ActionsDropdown}
              buttonClassName={styles.ActionsDropdownButton}
              actions={modifiedWidgetActions}
              handleOnClick={handleActionClick}
            />
          )}
        </div>
      </div>

      <div className={styles.WidgetContent}>{children}</div>
    </div>
  );
};

export default WidgetContainer;
