import { gql } from '@apollo/client';

export const GET_INSIGHTS = gql`
  query getThemes($id: ID, $sessionKey: ID, $themeName: String) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        themes {
          id
          name
          themeType
        }
        theme(themeName: $themeName) {
          insight {
            name
            value
          }
        }
      }
    }
  }
`;

export const GET_THEME_VERBATIMS = gql`
  query getBrandThemeVerbatims($id: ID, $sessionKey: ID) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        themes {
          name
          id
          verbatims {
            source
            content
            verbatim_type
          }
        }
        competitors {
          name
          id
          logoUrl
          themes {
            name
            verbatims {
              source
              content
              verbatim_type
            }
          }
        }
      }
    }
  }
`;
