import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@mui/material/Box';

import styles from './DownloadMenuItem.module.scss';

export default function DownloadMenuItem({
  LogoSVG,
  name,
  className,
  ...props
}) {
  return (
    <Box
      pl={2}
      className={classNames(styles.DownloadMenuItem, className)}
      {...props}
    >
      <Box mr={2}>
        <LogoSVG className={styles.Logo} />
      </Box>
      <span className={styles.Name}>{name}</span>
    </Box>
  );
}

DownloadMenuItem.propTypes = {
  /**
   * Logo to display in item.
   */
  // eslint-disable-next-line
  LogoSVG: PropTypes.any,
  /**
   * Item type.
   */
  name: PropTypes.string,
  /**
   * Classes to append to the component.
   */
  className: PropTypes.string,
};
