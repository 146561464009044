import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import IconSend from '../../../assets/icons/IconSend.jsx';

import styles from './TalkToStrategistIcon.module.scss';

export default function TalkToStrategistIcon({ className, ...props }) {
  return (
    <div
      className={classNames(styles.TalkToStrategistIcon, className)}
      {...props}
    >
      <IconSend className={styles.Icon} />
    </div>
  );
}

TalkToStrategistIcon.propTypes = {
  className: PropTypes.string,
};
