import * as React from 'react';

function SvgIconDownload(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#20434A"
          d="M12.19 14.963a.503.503 0 01-.544-.11l-3-2.998a.5.5 0 01.708-.707l2.146 2.146V5.001a.5.5 0 011 0v8.293l2.146-2.146a.5.5 0 01.708.707l-3 2.998a.48.48 0 01-.163.11zM6 19.5l-.164-.005A2.501 2.501 0 013.5 17v-6l.008-.09A.5.5 0 014.5 11v6l.007.144A1.5 1.5 0 006 18.5h12l.144-.007A1.5 1.5 0 0019.5 17v-6l.008-.09a.5.5 0 01.992.09v6l-.005.164A2.501 2.501 0 0118 19.5H6z"
        />
      </g>
    </svg>
  );
}

export default SvgIconDownload;
