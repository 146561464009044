import * as React from 'react';

function SvgIconChartLineMultiple(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path d="M21 20H4V3H3v18h18z" fill="#D7DDE2" />
        <path
          fill="#20434A"
          d="M11.137 10.792l4.716.943c.246.05.5-.027.677-.205l5-5a.749.749 0 10-1.06-1.06l-4.717 4.716-4.606-.921a.748.748 0 00-.533.092l-5 3a.75.75 0 00.772 1.286l4.751-2.85z"
        />
        <path
          fill="#7E9397"
          d="M15.614 18.643c.269.161.61.138.855-.057l5-4a.752.752 0 00.117-1.055.752.752 0 00-1.055-.117L15.94 17.09l-4.553-2.732a.748.748 0 00-.772 0l-5 3a.75.75 0 00.772 1.286L11 15.875l4.614 2.768z"
        />
      </g>
    </svg>
  );
}

export default SvgIconChartLineMultiple;
