import * as React from 'react';

function SvgRecommendations(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#fff"
          d="M13.367 21.773a.455.455 0 01.082.902l-.082.008H10.64a.455.455 0 01-.082-.902l.082-.008h2.727zM6.875 11.648a5.229 5.229 0 117.054 5.873l-.107.04v1.94a.455.455 0 01-.373.447l-.082.007H10.64a.455.455 0 01-.455-.454v-1.943l-.108-.039a5.226 5.226 0 01-3.241-5.645zm5.559-3.285a4.319 4.319 0 00-1.664 8.435.453.453 0 01.325.435l-.001 1.812h1.819v-1.812c0-.172.097-.327.247-.404l.078-.031a4.306 4.306 0 003.084-4.118 4.319 4.319 0 00-3.888-4.317zm3.645-6.202a2.117 2.117 0 011.306 1.897l-.002.167-.06 1.19a1.206 1.206 0 001.121 1.266l.146.002 1.188-.06a2.116 2.116 0 011.649 3.567l-.119.116-.883.797a1.208 1.208 0 00-.102 1.69l.101.104.884.797a2.117 2.117 0 01-1.36 3.687l-.167-.002-1.191-.06a.456.456 0 01-.036-.905l.082-.004 1.192.061a1.207 1.207 0 00.968-2.003l-.097-.099-.884-.797a2.121 2.121 0 01-.128-3.018l.128-.125.884-.797a1.21 1.21 0 00-.747-2.101h-.127l-1.189.06a2.12 2.12 0 01-2.224-2.045l.002-.179.061-1.189a1.208 1.208 0 00-2.004-.967l-.098.097-.798.884a2.12 2.12 0 01-3.017.128l-.125-.128-.799-.884a1.207 1.207 0 00-2.102.743v.127l.061 1.189A2.12 2.12 0 015.55 7.593l-.18-.002-1.189-.062a1.207 1.207 0 00-.97 2.004l.097.098.884.798a2.12 2.12 0 01.128 3.017l-.128.126-.884.797a1.208 1.208 0 00.748 2.101h.127l1.189-.06a.456.456 0 01.127.897l-.082.011-1.188.06a2.116 2.116 0 01-1.649-3.567l.12-.117.882-.797a1.21 1.21 0 00.103-1.69l-.102-.103-.884-.798a2.115 2.115 0 011.363-3.687l.167.002 1.189.062A1.206 1.206 0 006.684 5.56l.001-.146-.06-1.19a2.115 2.115 0 013.568-1.645l.116.12.798.883a1.21 1.21 0 001.689.102l.104-.102.798-.883c.6-.665 1.554-.88 2.381-.538z"
        />
      </g>
    </svg>
  );
}

export default SvgRecommendations;
