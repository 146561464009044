import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import TextInput from '../TextInput/TextInput';
import ModalFooter from '../ModalFooter/ModalFooter';
import { EMAIL_STRATEGIST } from '../../../api/queries/Molecules/TalkToAStrategist';
import BNContext from '../../../contexts/BNContext';

import styles from './TalkToStrategist.module.scss';

export default function TalkToStrategist({ className, onCancel, ...props }) {
  const {
    user,
    competitiveSetID,
    accountKey, //eslint-disable-line
  } = React.useContext(BNContext);

  const [formData, setFormData] = useState('');

  const [emailStrategist] = useMutation(EMAIL_STRATEGIST);

  function onSubmit() {
    handleEmailStrategist(formData);
    onCancel();
  }

  async function handleEmailStrategist(data) {
    try {
      const { email } = user;
      await emailStrategist({
        variables: {
          competitiveSetKey: competitiveSetID,
          accountKey,
          email,
          message: data,
        },
      });
    } catch (e) {
      if (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  return (
    <Box
      px={4}
      py={6}
      className={classNames(styles.TalkToStrategist, className)}
      {...props}
    >
      <Box mb={6}>
        <p className={styles.Intro}>
          Send a message to a BlueOcean strategist.
        </p>
      </Box>

      <Box>
        <TextInput
          inputId="talk-to-strategist"
          label="MESSAGE"
          value={formData}
          rows={24}
          onChange={(e) => setFormData(e.target.value)}
          multiline
        />
      </Box>

      <ModalFooter onAction={onSubmit} onCancel={onCancel} actionText="Send" />
    </Box>
  );
}

TalkToStrategist.propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
};
