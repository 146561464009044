import { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { Button } from '@blueoceanai/react-component-library';

import keyBy from 'lodash/keyBy';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

import InsightsCTA from '../../Molecules/InsightsCTA/InsightsCTA';
import MarketIndexSubfactorTrend from '../../Molecules/MarketIndexSubfactorTrend/MarketIndexSubfactorTrend';
import ScoreLegend from '../../Molecules/ScoreLegend/ScoreLegend';
import FactorsSubfactorCard from '../../Molecules/FactorsSubfactorCard/FactorsSubfactorCard';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';

import { handleGenericError } from '../../../utils/error';

import styles from './MarketIndexFactorDetail.module.scss';

import { FACTOR_DETAILS } from '../../../api/queries/Pages/MarketIndexFactorDetail';
import {
  prepareFactor,
  prepareSubfactors,
  prepareInsight,
} from '../../../api/transforms/Pages/MarketIndexFactorDetail';

import { FACTOR_TYPES } from '../../../constants/factors';
import { BUTTON_VARIANTS } from '../../../constants/props';
import QUERY_PARAMS from '../../../constants/queryParams';

import useRouter from '../../../hooks/useRouter';

import BNContext from '../../../contexts/BNContext';

const FACTOR_TYPES_BY_ID = keyBy(FACTOR_TYPES, 'ID');

export default function MarketIndexFactorDetail({
  disableCache = false,
  loading,
  factorId,
}) {
  const { updateRoute } = useRouter();

  const {
    timeframe,
    competitiveSetID: _competitiveSetId,
    accountKey,
    currentSession: sessionKey,
  } = useContext(BNContext);

  const [getFactorDetails, { error, loading: gettingFactorDetails, data }] =
    useLazyQuery(FACTOR_DETAILS);

  useEffect(() => {
    const queryOptions = {
      variables: {
        id: _competitiveSetId,
        sessionKey,
        accountKey,
        factorId,
      },
    };

    if (disableCache) {
      queryOptions.fetchPolicy = 'no-cache';
    }

    getFactorDetails(queryOptions);
  }, [
    _competitiveSetId,
    sessionKey,
    accountKey,
    factorId,
    disableCache,
    getFactorDetails,
  ]);

  const factor = useMemo(
    () =>
      handleGenericError(
        () => prepareFactor(data, timeframe),
        'MarketIndexFactorDetail prepareFactor failed transform'
      ),
    [data, timeframe]
  );

  const subfactors = useMemo(
    () =>
      handleGenericError(
        () => prepareSubfactors(data, timeframe),
        'MarketIndexFactorDetail prepareSubfactors failed transform'
      ),
    [data, timeframe]
  );

  const graphScale = useMemo(() => {
    const result = {
      yDomain: { min: Number.MAX_SAFE_INTEGER, max: -Number.MAX_SAFE_INTEGER },
    };

    if (!subfactors || !subfactors.length) {
      return result;
    }

    result.yDomain.min = null;
    result.yDomain.max = null;

    subfactors.forEach((subfactor) => {
      const factorMin = minBy(subfactor.scoreOverTime, 'value');
      const factorMax = maxBy(subfactor.scoreOverTime, 'value');

      if (!factorMin || !factorMax) {
        return;
      }

      if (result.yDomain.min === null) {
        result.yDomain.min = factorMin.value;
      } else {
        result.yDomain.min = Math.min(factorMin.value, result.yDomain.min);
      }

      if (result.yDomain.max === null) {
        result.yDomain.max = factorMax.value;
      } else {
        result.yDomain.max = Math.max(factorMax.value, result.yDomain.max);
      }
    });

    return result;
  }, [subfactors]);

  const insight = handleGenericError(
    () => prepareInsight(data),
    'MarketIndexFactorDetail prepareInsight failed transform'
  );

  const isLoading = useMemo(
    () => gettingFactorDetails || loading || !factor || !subfactors || !insight,
    [gettingFactorDetails, loading, factor, subfactors, insight]
  );

  function handleSubfactorNameClick(subfactorId) {
    updateRoute({ params: { [QUERY_PARAMS.SUBFACTOR_ID]: subfactorId } });
  }

  function handleCompareBtnClick() {
    updateRoute({
      pathname: '/market-index/compare',
      params: { [QUERY_PARAMS.FACTOR_NAME]: factor.factorType.toLowerCase() },
    });
  }

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <Grid container spacing={10} className={styles.MarketIndexFactorDetail}>
      <Grid item xs={12} md={9}>
        <div id="market-index-factor-detail">
          <div
            className={classNames(styles.ScreenshotFactorName, 'add-to-export')}
          >
            Factors: {FACTOR_TYPES_BY_ID[factorId]?.NAME}
          </div>

          <Grid
            container
            className={styles.TitleContainer}
            alignItems="flex-start"
          >
            <Grid item xs={12} md={8}>
              <h1
                className={styles.Title}
                id={`market-index-title-factor-${FACTOR_TYPES_BY_ID[
                  factorId
                ]?.NAME?.toLowerCase()}`}
              >
                {FACTOR_TYPES_BY_ID[factorId]?.QUESTION}
              </h1>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={styles.ButtonContainer}>
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  onClick={handleCompareBtnClick}
                  className={classNames('hide-from-share', 'hide-from-export')}
                >
                  Compare
                </Button>

                <DownloadDropdown
                  targetId="market-index-factor-detail"
                  json={factor}
                  fileName="market-index-factor-detail"
                  padding={100}
                />
              </div>
            </Grid>
          </Grid>

          <MarketIndexSubfactorTrend loading={isLoading} factorData={factor} />

          <Grid
            container
            spacing={4}
            className="SectionContainer"
            data-testid="factor-detail-subfactors-container"
          >
            <Grid item xs={12}>
              <h3 className={styles.SubfactorsContainerTitle}>Subfactors</h3>
            </Grid>

            {isLoading ? <ComponentLoader minHeight={250} /> : null}

            {!isLoading &&
              subfactors.map((subfactor) => (
                <Grid key={subfactor.factorType} item xs={12} md={4}>
                  <FactorsSubfactorCard
                    factorType={subfactor.factorType}
                    score={subfactor.score}
                    delta={subfactor.delta}
                    scoreOverTime={subfactor.scoreOverTime}
                    graphScale={graphScale}
                    onNameClick={() => handleSubfactorNameClick(subfactor.id)}
                    data-testid={`subfactor-card-${subfactor.factorType.toLowerCase()}`}
                  />
                </Grid>
              ))}

            <Grid item xs={7} data-testid="factor-detail-score-legend">
              <ScoreLegend />
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12} md={3} data-testid="factor-detail-insights-cta">
        <InsightsCTA
          loading={isLoading}
          insight={insight}
          data={data?.competitiveSet?.session?.factor?.recommendations}
        />
      </Grid>
    </Grid>
  );
}

MarketIndexFactorDetail.propTypes = {
  disableCache: PropTypes.bool,
  factorId: PropTypes.number,
  loading: PropTypes.bool,
};
