import * as React from 'react';

function SvgIconChartColumnGrouped(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path d="M21 20H4V3H3v18h18z" fill="#D7DDE2" />
        <path
          fill="#CED9DB"
          d="M6 15a.5.5 0 01.5.5V19H5v-3.5a.5.5 0 01.5-.5H6zm5-1a.5.5 0 01.5.5V19H10v-4.5a.5.5 0 01.5-.5h.5zm5-2a.5.5 0 01.5.5V19H15v-6.5a.5.5 0 01.5-.5h.5z"
        />
        <path
          fill="#20434A"
          d="M8 12a.5.5 0 01.5.5V19H7v-6.5a.5.5 0 01.5-.5H8zm5-2a.5.5 0 01.5.5V19H12v-8.5a.5.5 0 01.5-.5h.5zm5-3a.5.5 0 01.5.5V19H17V7.5a.5.5 0 01.5-.5h.5z"
        />
      </g>
    </svg>
  );
}

export default SvgIconChartColumnGrouped;
