import * as React from 'react';

function SvgIconLockLocked(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#20434A"
          d="M12 2c3.033 0 5.5 2.468 5.5 5.5V10h2a.5.5 0 01.5.5v11a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h2V7.5C6.5 4.468 8.967 2 12 2zm7 9H5v10h14V11zm-7 2c1.378 0 2.5 1.121 2.5 2.5 0 1.208-.86 2.217-2 2.449V19.5a.5.5 0 01-1 0v-1.551a2.502 2.502 0 01-2-2.449c0-1.379 1.122-2.5 2.5-2.5zm0 1c-.827 0-1.5.673-1.5 1.5S11.173 17 12 17s1.5-.673 1.5-1.5S12.827 14 12 14zm0-11a4.505 4.505 0 00-4.5 4.5V10h9V7.5C16.5 5.019 14.481 3 12 3z"
        />
      </g>
    </svg>
  );
}

export default SvgIconLockLocked;
