import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '@blueoceanai/react-component-library';
import ShareIcon from '../../../assets/icons/IconShare.jsx';

import styles from './ShareButton.module.scss';

function ShareButton({ className, ...props }) {
  return (
    <Button
      data-html2canvas-ignore="true"
      className={classNames(className, styles.ShareButton)}
      {...props}
    >
      <ShareIcon className={styles.Icon} />
    </Button>
  );
}

export default ShareButton;

ShareButton.propTypes = {
  className: PropTypes.string,
};
