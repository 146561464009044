import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';

import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import InsightsCTA from '../../Molecules/InsightsCTA/InsightsCTA';
import ScoreLegend from '../../Molecules/ScoreLegend/ScoreLegend';
import ScorecardGrid from '../../Organisms/ScorecardGrid/ScorecardGrid';

import { GET_SCORECARD_VALUES } from '../../../api/queries/Pages/MarketIndexScorecard';
import { prepareScorecardRows } from '../../../api/transforms/Pages/MarketIndexScorecard';
import { prepareInsight } from '../../../api/transforms/Molecules/InsightCTA';

import BNContext from '../../../contexts/BNContext';

import styles from './MarketIndexScorecard.module.scss';

export default function MarketIndexScorecard({ disableCache = false }) {
  const { competitiveSetID, currentSession, accountKey, timeframe } =
    React.useContext(BNContext);

  const [scorecardId] = useState('scorecard');
  const [scorecardGridId] = useState('scorecard-grid');

  const queryOptions = {
    variables: {
      id: competitiveSetID,
      sessionKey: currentSession,
      accountKey,
      type: 6,
    },
  };

  if (disableCache) {
    queryOptions.fetchPolicy = 'no-cache';
  }

  const [getScorecardValues, { loading, error, data }] = useLazyQuery(
    GET_SCORECARD_VALUES,
    queryOptions
  );

  const insight = useMemo(() => prepareInsight(data), [data]);

  const scorecardRows = useMemo(
    () => prepareScorecardRows(data, timeframe),
    [data, timeframe]
  );

  const isLoading = useMemo(
    () => loading || !insight || !scorecardRows,
    [loading, insight, scorecardRows]
  );

  useEffect(() => {
    if (!competitiveSetID || !currentSession) {
      return;
    }

    getScorecardValues();
  }, [competitiveSetID, currentSession, getScorecardValues]);

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      className={classNames(styles.MarketIndexScorecard, 'PageContainer')}
      spacing={10}
    >
      <Grid id={scorecardGridId} item xs={12} md={9}>
        <Grid container>
          <Grid item xs={12} className={classNames('remove-from-export')}>
            <Box
              className={classNames(
                styles.TitleContainer,
                'PageTitleContainer'
              )}
            >
              <h1
                className={styles.Title}
                id="market-index-title-scorecard-title"
              >
                How does your brand stack up against the competition?
              </h1>

              <DownloadDropdown
                targetId={scorecardGridId}
                fileName="scorecard"
                json={scorecardRows}
                padding={100}
              />
            </Box>
          </Grid>

          <Grid
            container
            alignItems="flex-start"
            item
            xs={12}
            className={classNames('remove-from-export')}
          >
            <Box className={styles.SectionDescription}>
              <p className={styles.Paragraph}>
                A snapshot assessment of your brand and competitor’s performance
                across 5 Factors. Use the Market Index to understand your
                brand’s strengths and weaknesses relative to the category.
              </p>
            </Box>
          </Grid>

          <ScorecardGrid
            id={scorecardId}
            rows={scorecardRows}
            customerBrandKey={
              scorecardRows && scorecardRows[0] && scorecardRows[0].brand
                ? scorecardRows[0].brand.brandKey
                : null
            }
            loading={isLoading}
            data-cy="market-index-scorecard"
          />

          <Grid
            className={classNames(styles.ScoreLegendGrid, 'SectionContainer')}
            container
            spacing={4}
          >
            <Grid item xs={false} md={3} />
            <Grid item xs={12} md={9}>
              <Box mt={3}>
                <ScoreLegend />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            className={classNames('SubtitleContainer', 'remove-from-export')}
          >
            <Grid item xs={11}>
              <h2 className={styles.Subtitle}>
                What the Market Index Answers:
              </h2>
            </Grid>
          </Grid>

          <Box
            mb={1}
            className={classNames(
              styles.SectionDescription,
              'remove-from-export'
            )}
          >
            <p className={styles.Paragraph}>
              How is your brand performing relative to the competitive set?
            </p>
            <p className={styles.Paragraph}>Who is leading the category?</p>
            <p className={styles.Paragraph}>Where is your brand at risk?</p>
            <p className={styles.Paragraph}>What is your brand doing well?</p>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3}>
        <InsightsCTA
          loading={isLoading}
          insight={insight}
          data={data?.competitiveSet?.session?.recommendations}
          modalType="recommendations"
          data-cy="market-index-scorecard-insight"
        />
      </Grid>
    </Grid>
  );
}

MarketIndexScorecard.propTypes = {
  disableCache: PropTypes.bool,
};
