import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FunctionComponent, AnchorHTMLAttributes, useState } from 'react';
import keyBy from 'lodash/keyBy';
import classNames from 'classnames';
import Markdown from 'react-markdown';
import { WidgetType } from '../../../../interfaces/dashboard-api';
import calculationsByWidgetType from './calculationsMap';
import MetricDetailCard from '../MetricDetailCard/MetricDetailCard';
import { getMetrics } from '../../../../mocks/data/CustomizableDashboards/metrics';
import CollapseIcon from '../../../../assets/icons/NavIcons/CollapseIcon';
import MetricLookbackWindowIcon from '../../../../assets/icons/MetricIcons/MetricLookbackWindowIcon';
import MetricRefreshScheduleIcon from '../../../../assets/icons/MetricIcons/MetricRefreshScheduleIcon';

import styles from './CalculationsAndSources.module.scss';

export enum TabType {
  Calculations = 'calculations',
  Sources = 'sources',
}

export interface CalculationsAndSourcesProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  metricIds: string[];
  widgetType: WidgetType;
  containerClassName?: string;
}

const metricsById = keyBy(getMetrics, 'id');

const CalculationsAndSources: FunctionComponent<
  CalculationsAndSourcesProps
> = ({ className, metricIds, widgetType, containerClassName }) => {
  const [activeTab, setActiveTab] = useState<TabType>(TabType.Sources);
  const [activeReadMoreMetricId, setActiveReadMoreMetricId] =
    useState<string>();

  const calculations = calculationsByWidgetType[widgetType];

  const readMoreMetric = metricsById[activeReadMoreMetricId || ''];

  const handleTabChange = (event: unknown, newTab: TabType) =>
    setActiveTab(newTab);

  const handleReadMoreClick = (metricId: string) => {
    if (containerClassName) {
      const $container = document.querySelector(`.${containerClassName}`);

      if ($container) $container.scrollTop = 0;
    }

    setActiveReadMoreMetricId(metricId);
  };

  return (
    <div className={classNames(styles.CalculationsAndSources, className)}>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        classes={{ root: styles.Tabs, indicator: styles.TabIndicator }}
      >
        <Tab
          label="Sources"
          disableRipple
          value={TabType.Sources}
          classes={{ root: styles.Tab, selected: styles.SelectedTab }}
        />
        <Tab
          label="Calculations"
          disableRipple
          value={TabType.Calculations}
          classes={{ root: styles.Tab, selected: styles.SelectedTab }}
        />
      </Tabs>

      <div className={styles.TabContent}>
        {activeTab === TabType.Calculations && (
          <div className={styles.CalculationsTab}>
            <h3 className={styles.Header}>Calculations in this Widget</h3>

            <p className={styles.HelperText}>
              Images for illustrative purposes only; do not represent actual
              data.
            </p>

            <div className={styles.Calculations}>
              {calculations?.map(({ imgSrc, id, text }) => (
                <div key={id} className={styles.CalculationRow}>
                  <img
                    className={styles.CalculationScreenshot}
                    src={imgSrc}
                    alt={id}
                  />

                  <Markdown className={styles.CalculationDescription}>
                    {text}
                  </Markdown>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === TabType.Sources && (
          <div className={styles.SourcesTab}>
            {!activeReadMoreMetricId ? (
              <div className={styles.MainContent}>
                <h3 className={styles.Header}>Sources in this Widget</h3>

                <div className={styles.MetricCards}>
                  {metricIds.map((metricId) => {
                    return (
                      <MetricDetailCard
                        className={styles.MetricCard}
                        key={metricId}
                        metric={metricsById[metricId]}
                        onReadMoreClick={handleReadMoreClick}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className={styles.ReadMoreContent}>
                <div
                  className={styles.BackButton}
                  onClick={() => setActiveReadMoreMetricId(undefined)}
                >
                  <CollapseIcon direction="left" />

                  <p className={styles.BackText}>Back</p>
                </div>

                <h3 className={styles.Header}>{readMoreMetric.name}</h3>

                <section className={styles.MetricDescriptionSection}>
                  <div>
                    <h4 className={styles.SectionHeader}>
                      What is this metric?
                    </h4>

                    <Markdown className={styles.SectionTextContainer}>
                      {readMoreMetric.description_md || 'N/A'}
                    </Markdown>
                  </div>

                  <div className={styles.DetailsCol}>
                    <div className={styles.DetailSection}>
                      <MetricLookbackWindowIcon className={styles.Icon} />

                      <div className={styles.TextContainer}>
                        <h4 className={styles.SectionHeader}>
                          Lookback Window
                        </h4>

                        <Markdown className={styles.SectionTextContainer}>
                          {readMoreMetric.lookback_window_md || 'N/A'}
                        </Markdown>
                      </div>
                    </div>

                    <div className={styles.DetailSection}>
                      <MetricRefreshScheduleIcon className={styles.Icon} />

                      <div className={styles.TextContainer}>
                        <h4 className={styles.SectionHeader}>
                          Refresh Schedule
                        </h4>

                        <Markdown className={styles.SectionTextContainer}>
                          {readMoreMetric.refresh_schedule_md || 'N/A'}
                        </Markdown>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <h4 className={styles.SectionHeader}>
                    How is this metric calculated?
                  </h4>

                  <Markdown className={styles.SectionTextContainer}>
                    {readMoreMetric.calculation_method_md || 'N/A'}
                  </Markdown>
                </section>

                <section>
                  <h4 className={styles.SectionHeader}>Data Source</h4>

                  <Markdown className={styles.SectionTextContainer}>
                    {readMoreMetric.data_source_md || 'N/A'}
                  </Markdown>
                </section>

                <section>
                  <h4 className={styles.SectionHeader}>
                    How is this information valuable to my brand?
                  </h4>

                  <Markdown className={styles.SectionTextContainer}>
                    {readMoreMetric.information_value_md || 'N/A'}
                  </Markdown>
                </section>

                <section>
                  <h4 className={styles.SectionHeader}>
                    Additional considerations where this metric can play a role
                  </h4>

                  <Markdown className={styles.SectionTextContainer}>
                    {readMoreMetric.additional_considerations_md || 'N/A'}
                  </Markdown>
                </section>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculationsAndSources;
