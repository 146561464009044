const DynamicPlusIcon = ({ className = '' }: { className?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4919 7.41012C12.4496 7.17688 12.2455 7 12 7C11.7239 7 11.5 7.22386 11.5 7.5V11.5H7.5L7.41012 11.5081C7.17688 11.5504 7 11.7545 7 12C7 12.2761 7.22386 12.5 7.5 12.5H11.5V16.5L11.5081 16.5899C11.5504 16.8231 11.7545 17 12 17C12.2761 17 12.5 16.7761 12.5 16.5V12.5H16.5L16.5899 12.4919C16.8231 12.4496 17 12.2455 17 12C17 11.7239 16.7761 11.5 16.5 11.5H12.5V7.5L12.4919 7.41012Z"
      fill="currentColor"
    />
  </svg>
);

export default DynamicPlusIcon;
