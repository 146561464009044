import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useState,
  useCallback,
  useMemo,
} from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Button } from '@blueoceanai/react-component-library';
import { TimeRange } from '../../../../interfaces/dashboard-api';
import TextInput from '../../../Molecules/TextInput/TextInput';
import DatePickerCalendar from '../../../Molecules/DatePickerCalendar/DatePickerCalendar';
import { CalendarIcon } from '../../../../assets/icons';

import styles from './DateRangeSelector.module.scss';

export const TIME_RANGE_OPTIONS: { label: string; value: TimeRange | null }[] =
  [
    {
      label: 'Default',
      value: null,
    },
    {
      label: '30 days prior',
      value: TimeRange['30_DAYS'],
    },
    {
      label: '90 days prior',
      value: TimeRange['90_DAYS'],
    },
    {
      label: '4 quarters prior',
      value: TimeRange['365_DAYS'],
    },
  ];

export const timeRangeValueMap = {
  [TimeRange['30_DAYS']]: 30,
  [TimeRange['60_DAYS']]: 60,
  [TimeRange['90_DAYS']]: 90,
  [TimeRange['365_DAYS']]: 365,
};

export interface DateRangeSelectorProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  timeRange: TimeRange | null;
  endDate: Date;
  onApply: ({
    endDate,
    timeRange,
  }: {
    endDate: Date;
    timeRange: TimeRange | null;
  }) => void;
}

const DateRangeSelector: FunctionComponent<DateRangeSelectorProps> = ({
  className,
  timeRange = null,
  endDate = new Date(),
  onApply,
}) => {
  const [activeCalendarPanel, setActiveCalendarPanel] = useState<Date>(endDate);
  const [activeTimeRange, setActiveTimeRange] = useState<TimeRange | null>(
    timeRange
  );
  const [activeEndDate, setActiveEndDate] = useState<Date>(endDate);

  const handleApply = useCallback(() => {
    onApply({ endDate: activeEndDate, timeRange: activeTimeRange });
  }, [onApply, activeEndDate, activeTimeRange]);

  const handleActiveCalendarPaneChange = useCallback(
    ({ activeStartDate }: { activeStartDate: Date }) => {
      setActiveCalendarPanel(activeStartDate);
    },
    []
  );

  const calendarValue = useMemo(() => {
    return [
      dayjs(activeEndDate)
        .subtract(
          timeRangeValueMap[activeTimeRange ?? TimeRange['90_DAYS']],
          'days'
        )
        .toDate(),
      activeEndDate,
    ];
  }, [activeEndDate, activeTimeRange]);

  return (
    <div className={classNames(styles.DateRangeSelector, className)}>
      <div className={styles.ColLeft}>
        <div className={styles.Header}>RANGE</div>

        <div className={styles.PresetOptions}>
          {TIME_RANGE_OPTIONS.map((option) => (
            <div
              key={option.value}
              className={classNames(
                styles.PresetOption,
                activeTimeRange === option.value ? styles.Active : null
              )}
              onClick={() => setActiveTimeRange(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.ColRight}>
        <div className={styles.Header}>END DATE</div>

        <TextInput
          className={styles.DateInput}
          type="date"
          value={dayjs(activeEndDate).format('YYYY-MM-DD')}
          inputId="date-input-right"
          endAdornment={<CalendarIcon />}
          onChange={
            ((e: { target: { value: Date } }) => {
              setActiveEndDate(e.target.value);
              // using any here since typing is missing from TextInput
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }) as any
          }
        />

        <DatePickerCalendar
          className={styles.DatePickerCalendar}
          highlightedDates={[]}
          onClickDay={setActiveEndDate}
          onActiveStartDateChange={handleActiveCalendarPaneChange}
          activeStartDate={activeCalendarPanel}
          value={calendarValue}
          maxDate={new Date()}
        />

        <div className={styles.Footer}>
          <Button onClick={handleApply} variant="primary">
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DateRangeSelector;
