/* eslint-disable react/destructuring-assignment */
import Select, {
  CSSObjectWithLabel,
  GroupBase,
  Props,
  StylesConfig,
} from 'react-select';
import cn from 'classnames';
import colors from '../../../../constants/colors';
import styles from './GreenDropdownSelect.module.scss';

export default function GreenDropdownSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const getBackgroundColor = (
    isSelected: boolean,
    isFocused: boolean
  ): string | undefined => {
    if (isSelected) return colors.colorSecondary20;
    if (isFocused) return colors.colorSecondary10;
    return undefined;
  };

  const DropdownStyle: StylesConfig<Option, IsMulti, Group> = {
    indicatorSeparator: () => ({ display: 'none' }),
    container: (base: CSSObjectWithLabel) => ({ ...base, width: '200px' }),
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      width: '100%',
      cursor: 'pointer',
      borderColor: 'white',
      borderRadius: '8px',
      color: 'white',
      backgroundColor: colors.colorSecondary80,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    }),
    singleValue: (base: CSSObjectWithLabel) => ({
      ...base,
      color: 'white',
      fontWeight: '300',
    }),
    dropdownIndicator: (base: CSSObjectWithLabel) => ({
      ...base,
      color: 'white',
      '&:hover,&:active': {
        color: 'white',
      },
    }),
    option: (
      base: CSSObjectWithLabel,
      {
        isDisabled,
        isSelected,
        isFocused,
      }: { isDisabled: boolean; isSelected: boolean; isFocused: boolean }
    ) => ({
      ...base,
      color: isDisabled ? colors.colorGray70 : colors.colorGray90,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: getBackgroundColor(isSelected, isFocused),
      padding: '12px 8px',
      '&:active': { backgroundColor: colors.colorSecondary20 },
    }),
  };

  return (
    <div className={styles.DropdownSelect}>
      <Select
        className={cn(styles.DropdownMenu, props.className)}
        styles={DropdownStyle}
        {...props}
      />
    </div>
  );
}
