import sortBy from 'lodash/sortBy';
import keyBy from 'lodash/keyBy';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import { isBetween } from '../../../utils/timeframe';
import {
  ALLOWED_METRICS_MAP,
  BLACKLISTED_CATEGORIES_MAP,
  BLACKLISTED_GROUPS_MAP,
} from '../../../constants/metrics';
import {
  SOCIAL_CHANNEL_BY_ID,
  SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST,
  SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST,
} from '../../../constants/social-metrics';

import { METRIC_CARD_DEFS } from '../../../utils/__stubs__/metrics';
import {
  MetricFormatType,
  MetricUnit,
  SocialChannelKey,
} from '../../../interfaces/metric';

export function prepareMetricGroupsList(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.metricGroups ||
    !data.competitiveSet.session.metricGroups.length
  ) {
    return [];
  }

  const filteredGroups = data.competitiveSet.session.metricGroups.filter(
    (group) => {
      return !BLACKLISTED_GROUPS_MAP[group.id];
    }
  );

  return filteredGroups;
}

export function prepareMetricCategoriesList(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.metricCategories ||
    !data.competitiveSet.session.metricCategories.length
  ) {
    return [];
  }
  const filteredCategories =
    data.competitiveSet.session.metricCategories.filter((category) => {
      return !BLACKLISTED_CATEGORIES_MAP[category.id];
    });

  return filteredCategories;
}

export function prepareMetricChannelsList(data, socialMetricsData) {
  const socialMetricsResults =
    socialMetricsData?.getSocialMetricsSetDetailsByChannel;
  if (
    !data?.competitiveSet?.brand ||
    !data?.competitiveSet?.session?.competitors ||
    !data?.competitiveSet?.session?.metricTrend ||
    !socialMetricsResults
  ) {
    return [];
  }

  const heroBrand = data.competitiveSet.brand;

  const filteredChannelList = [];

  socialMetricsResults.forEach((s) => {
    if (heroBrand.brandKey === s.brand_key_string.toLowerCase()) {
      s.results.forEach((r) => {
        filteredChannelList.push(SOCIAL_CHANNEL_BY_ID[r.channel]);
      });
    }
  });

  return filteredChannelList;
}

export function prepareMetricList(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.metrics ||
    !data.competitiveSet.session.metrics.length
  ) {
    return [];
  }

  const filteredMetrics = data.competitiveSet.session.metrics
    .filter((metric) => ALLOWED_METRICS_MAP[metric.variableId])
    .map((metric) => {
      const result = {
        ...metric,
        formatType: MetricFormatType.Abbreviated,
        unit: MetricUnit.None,
      };

      if (metric.isCurrency === 1) {
        result.unit = MetricUnit.USD;
      }

      return result;
    });

  return sortBy(filteredMetrics, ['name']);
}

export function prepareTopMetrics({
  data,
  timeframe,
  metricGroupId,
  metricCategoryId,
}) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.competitors ||
    !data.competitiveSet.session.metrics
  ) {
    return null;
  }

  const { competitiveSet } = data;

  const groupCards = METRIC_CARD_DEFS.filter(
    (c) => c.id === Number(metricGroupId)
  );
  const { metrics } = data.competitiveSet.session;
  const completeMetrics = metrics.map((metric) => {
    const result = {
      ...metric,
      formatType: MetricFormatType.Abbreviated,
      unit: MetricUnit.None,
    };

    if (metric.isCurrency === 1) {
      result.unit = MetricUnit.USD;
    }

    return result;
  });

  const metricsById = keyBy(completeMetrics, 'variableId');
  const { competitors } = competitiveSet.session;

  if (!groupCards) {
    return;
  }

  let variableIds = [];

  const card = groupCards[0];

  if (metricCategoryId) {
    // eslint-disable-next-line prefer-destructuring
    variableIds = card.categories
      .filter((c) => c.id === Number(metricCategoryId))
      .map((c) => c.variableIds)[0];
  } else {
    variableIds = card.variableIds;
  }

  if (!variableIds) {
    return [];
  }

  const avgMap = {};

  variableIds.forEach((variableId) => {
    const mapResult = {
      score: 0,
      magnitude: 0,
    };

    completeMetrics.forEach((brandMetric) => {
      if (brandMetric.variableId === variableId) {
        mapResult.score += brandMetric.value;
        mapResult.magnitude += brandMetric.delta || 0;
      }
    });

    competitors.forEach((competitor) => {
      competitor.metrics.forEach((competitorMetric) => {
        if (competitorMetric.variableId === variableId) {
          mapResult.score += competitorMetric.value;
          mapResult.magnitude += competitorMetric.delta || 0;
        }
      });
    });

    mapResult.score /= 1 + competitors.length;
    mapResult.magnitude /= 1 + competitors.length;

    avgMap[variableId] = mapResult;
  });

  const result = [];

  const brandAllScores = competitiveSet.session.brandLastPeriodScores
    .map((timeframes) => JSON.parse(timeframes.t4Quarters))
    .filter((scores) => variableIds.includes(scores[0].variableId));

  variableIds.forEach((variableId) => {
    const metric = metricsById[variableId];

    if (isNil(metric)) {
      return;
    }

    const scoresOverTime = find(
      brandAllScores,
      (scores) => scores?.[0]?.variableId === variableId
    );

    if (isNil(scoresOverTime)) {
      return;
    }

    const transformScores = scoresOverTime.map((score) => {
      return {
        date: score.date,
        name: score.name,
        sessionKey: score.sessionKey,
        variableId: score.variableId,
        value: score.original_value,
      };
    });

    const filteredScoresOverTime = transformScores.filter((score) =>
      isBetween(score.date, timeframe)
    );

    // When an end date on the date picker is selected, due to sessions calculations populating data
    // at different intervals the data returned is not always an exact match with the end date selected
    // therefore we are populating the Metrics value with the last date closest to selected end date
    const lastDateOfSessionData =
      filteredScoresOverTime[filteredScoresOverTime.length - 1];

    result.push({
      id: metric.id,
      variableId: metric.variableId,
      name: metric.name,
      brand: {
        logoUrl: competitiveSet.brand.logoUrl,
        id: competitiveSet.brand.brandKey,
        name: competitiveSet.brand.name,
        score: lastDateOfSessionData?.value,
        magnitude: metric.delta,
        scoresOverTime: filteredScoresOverTime,
      },
      average: {
        score: Math.round(avgMap[variableId].score),
        magnitude: Math.round(avgMap[variableId].magnitude),
      },
      dateUpdate: competitiveSet.session,
      formatType: metric.formatType,
      unit: metric.unit,
    });
  });

  return result;
}

export function prepareBrandMetricsDatasets(data, timeframe) {
  if (
    !data?.competitiveSet?.brand ||
    !data?.competitiveSet?.session?.competitors ||
    !data?.competitiveSet?.session?.metricTrend
  ) {
    return null;
  }

  const heroBrand = data.competitiveSet.brand;
  const { session } = data.competitiveSet;
  const { competitors } = data.competitiveSet.session;

  const result = [];

  result.push({
    key: heroBrand.brandKey,
    name: data.competitiveSet.brand.name,
    logoUrl: data.competitiveSet.brand.logoUrl,
    data: [],
    tile: {
      score: null,
      tooltip: '',
      delta: null,
    },
  });

  const heroBrandMetricsTrend = JSON.parse(session.metricTrend.t4Quarters);

  if (!heroBrandMetricsTrend) {
    return [];
  }

  const whichValueToUse = (metric) => {
    // For the following variableIds the DS team does not want to do display
    // negative values so the value property has already been indexed and has a range
    // of 0 - 200
    if (
      metric.variableId === '5B16' ||
      metric.variableId === '4B15' ||
      metric.variableId === '5B18' ||
      metric.variableId === '3B14' ||
      SOCIAL_METRIC_BREAKDOWN_TOGGLE_WHITELIST.includes(metric.variableId) ||
      SOCIAL_METRIC_RAW_SCORE_TOGGLE_WHITELIST.includes(metric.variableId)
    ) {
      return metric.value;
    }
    return metric.original_value;
  };

  heroBrandMetricsTrend.forEach((metric) => {
    result[0].data.push({
      x: new Date(metric.date),
      y: Math.round(whichValueToUse(metric)),
    });
  });

  result[0].data = result[0].data.filter((ss) => isBetween(ss.x, timeframe));

  competitors.forEach((competitor) => {
    const dataset = {
      key: competitor.brandKey,
      name: competitor.name,
      logoUrl: competitor.logoUrl,
      data: [],
      tile: {
        score: null,
        tooltip: '',
        delta: null,
      },
    };

    if (competitor.metricTrend) {
      const competitorMetricTrend = JSON.parse(
        competitor.metricTrend.t4Quarters
      );

      competitorMetricTrend.forEach((metric) => {
        dataset.data.push({
          x: new Date(metric.date),
          y: Math.round(whichValueToUse(metric)),
        });
      });
    }

    dataset.data = dataset.data.filter((ss) => isBetween(ss.x, timeframe));

    result.push(dataset);
  });

  result.forEach((dataset) => {
    // sort the data x by date before assigning the tile score and delta
    dataset.data.sort((a, b) => a.x.getTime() - b.x.getTime());

    // eslint-disable-next-line no-param-reassign
    dataset.tile.score = dataset.data[dataset.data.length - 1]?.y;
    // eslint-disable-next-line no-param-reassign
    dataset.tile.delta =
      dataset.data[dataset.data.length - 1]?.y - dataset.data[0]?.y;
  });

  return result;
}

export function prepareBrandSocialMetricsDatasets(
  data,
  socialMetricsData,
  channelId
) {
  const socialMetricsResults =
    socialMetricsData?.getSocialMetricsSetDetailsByChannel;
  if (
    !data?.competitiveSet?.brand ||
    !data?.competitiveSet?.session?.competitors ||
    !data?.competitiveSet?.session?.metricTrend ||
    !socialMetricsResults ||
    !channelId
  ) {
    return null;
  }

  const heroBrand = data.competitiveSet.brand;
  const { session } = data.competitiveSet;
  const { competitors } = data.competitiveSet.session;

  const result = [];

  const getTileDelta = (chartData) => {
    if (!chartData) {
      return null;
    }

    const formattedChartData = chartData.map((currentChartData) => ({
      ...currentChartData,
      date: new Date(currentChartData.date),
    }));

    const sortedChartData = sortBy(formattedChartData, 'date');

    const oldestScore = sortedChartData[sortedChartData.length - 1]?.value;
    const latestScore = sortedChartData[0]?.value;

    if (!oldestScore || !latestScore) {
      return null;
    }

    return oldestScore - latestScore;
  };

  result.push({
    key: heroBrand.brandKey,
    name: data.competitiveSet.brand.name,
    logoUrl: data.competitiveSet.brand.logoUrl,
    data: [],
    tile: {
      score: null,
      tooltip: '',
      delta: null,
    },
  });

  const heroBrandMetricsTrend = JSON.parse(session.metricTrend.t4Quarters);

  if (!heroBrandMetricsTrend) {
    return [];
  }

  competitors.forEach((competitor) => {
    const dataset = {
      key: competitor.brandKey,
      name: competitor.name,
      logoUrl: competitor.logoUrl,
      data: [],
      tile: {
        score: null,
        tooltip: '',
        delta: null,
      },
    };

    result.push(dataset);
  });

  result.forEach((r) => {
    socialMetricsResults.forEach((s) => {
      const socialResultsByChannelId = keyBy(s.results, 'channel');

      if (r.key === s.brand_key_string.toLowerCase()) {
        // eslint-disable-next-line no-param-reassign
        r.tile.score = socialResultsByChannelId[channelId].tile.score;
        // eslint-disable-next-line no-param-reassign
        r.tile.tooltip = socialResultsByChannelId[channelId].tile.tooltip;
        // eslint-disable-next-line no-param-reassign
        r.tile.delta = getTileDelta(
          socialResultsByChannelId[channelId].timeseries
        );
        socialResultsByChannelId[channelId].timeseries.forEach((t) => {
          r.data.push({
            x: new Date(t.date),
            y: t.value,
          });
        });
      }
    });
  });
  return result;
}

/**
 * Social metric channel list without aggregate.
 */
export function prepareMetricListChannels(socialMetricsChannelData) {
  const socialMetricsResults =
    socialMetricsChannelData?.getSocialMetricsByChannel;

  if (!socialMetricsResults || !socialMetricsResults.length) {
    return [];
  }
  const filteredChannelList = [];

  socialMetricsResults.forEach((s) => {
    if (s.channel === SocialChannelKey.Aggregate) {
      return;
    }

    filteredChannelList.push(SOCIAL_CHANNEL_BY_ID[s.channel]);
  });

  return filteredChannelList;
}
