import {
  FlowKey,
  WidgetConfigProps,
} from '../../../../../interfaces/widget-settings';
import TextStepGuide from '../../WidgetSettingsShared/TextStepGuide/TextStepGuide';

const SelectGroupMetricsGuide = ({ config }: WidgetConfigProps) => {
  const { metrics } = config[FlowKey.SelectGroup];

  const guideText = 'Select a metric group on the right to get started.';
  return (
    <TextStepGuide text={guideText} showMetrics={false} metrics={metrics} />
  );
};

export default SelectGroupMetricsGuide;
