import React from 'react';
import IconRational from '../../../assets/icons/IconRational.jsx';
import IconEmotinal from '../../../assets/icons/IconEmotional.jsx';
import IconGauge from '../../../assets/icons/IconGauge.jsx';
import styles from './ThemesLegend.module.scss';

export default function ThemesLegend() {
  return (
    <div className={`${styles.ThemesLegend}`}>
      <div className={`${styles.ThemesLegendItem}`}>
        <IconRational />
        <span>Rational Themes</span>
      </div>
      <div className={`${styles.ThemesLegendItem}`}>
        <IconEmotinal />
        <span>Emotional Themes</span>
      </div>
      <div className={`${styles.ThemesLegendItem}`}>
        <IconGauge />
        <span>Average Score of Cohort</span>
      </div>
    </div>
  );
}
