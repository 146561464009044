import { FunctionComponent } from 'react';
import MuiTooltip, {
  TooltipProps as MuiTooltipProps,
} from '@mui/material/Tooltip';

import styles from './Tooltip.module.scss';

const Tooltip: FunctionComponent<MuiTooltipProps> = ({ ...props }) => {
  return (
    <MuiTooltip
      classes={{ tooltip: styles.Tooltip, arrow: styles.Arrow }}
      {...props}
    />
  );
};

export default Tooltip;
