import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';

import RecommendationImpactResult from '../../Atoms/RecommendationImpactResult/RecommendationImpactResult';
import CheckCircle from '../../Atoms/CheckCircle/CheckCircle';

import styles from './ImpactResultRadioSelector.module.scss';

import { IMPACT_RESULTS } from '../../../constants/recommendations';

export default function ImpactResultRadioSelector({
  className,
  onChange,
  value,
  ...props
}) {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (value !== null) {
      setSelected(value);
    }
  }, [value]);

  function handleImpactResultClick(impactResultKey) {
    setSelected(impactResultKey);

    onChange(impactResultKey);
  }

  return (
    <Box
      className={classNames(styles.ImpactResultRadioSelector, className)}
      {...props}
    >
      <Grid container spacing={5}>
        {IMPACT_RESULTS.map((impactResult) => (
          <Grid item xs={12} sm={4} key={impactResult.key}>
            <Box
              p={2}
              className={styles.RadioItem}
              onClick={() => handleImpactResultClick(impactResult.key)}
            >
              <Box className={styles.ImpactResultContainer} mr={8}>
                <RecommendationImpactResult
                  impactResultKey={impactResult.key}
                />
              </Box>
              <Box className={styles.CheckContainer}>
                <CheckCircle selected={selected === impactResult.key} />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

ImpactResultRadioSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
};
