import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import type { DrawerProps } from '@mui/material/Drawer';
import Drawer from '@mui/material/Drawer';
import { useLazyQuery } from '@apollo/client';
import {
  GET_FIRST_AND_LAST_SESSION,
  GET_SUMMARY_TRENDS_VARIABLE_IDS,
} from '../../../../../api/queries/Pages/CustomizableDashboards';
import MetricContextualHelpIcon from '../../../../../assets/icons/MetricIcons/MetricContextualHelpIcon';
import { CloseIcon } from '../../../../../assets/icons';
import { WidgetMetaResponse } from '../../types';
import { timeRangeToNumDays } from '../../Widgets/widgetFormatResponse';
import CalculationsAndSources from '../../CalculationsAndSources/CalculationsAndSources';
import { TimeRange, WidgetType } from '../../../../../interfaces/dashboard-api';
import { getMetricIdsFromApiConfig } from '../../WidgetSettingsFlows/helper';

import styles from './WidgetContextualHelpDrawer.module.scss';

export interface WidgetContextualHelpDrawerProps extends DrawerProps {
  widgetConfig: WidgetMetaResponse;
}

const WidgetContextualHelpDrawer: FunctionComponent<
  WidgetContextualHelpDrawerProps
> = ({ widgetConfig, onClose, ...props }) => {
  const [activeTimeRange] = useState<TimeRange>(
    widgetConfig?.data_srcs?.metrics?.time_range ?? TimeRange['90_DAYS']
  );

  // duplicative code from SummaryTrendsWidget but with a more specific query
  // TODO: refactor to avoid duplication
  const [getSummaryTrendsVariableIds, { data: summaryTrendsData }] =
    useLazyQuery(GET_SUMMARY_TRENDS_VARIABLE_IDS, {
      variables: {},
      fetchPolicy: 'no-cache',
    });

  const [getFirstLastSession, { data: firstAndLastSessionResp }] = useLazyQuery(
    GET_FIRST_AND_LAST_SESSION,
    { fetchPolicy: 'no-cache' }
  );

  const latestSessionKey =
    firstAndLastSessionResp?.competitiveSet?.firstAndLastSession?.[1]
      ?.sessionKey;

  useEffect(() => {
    if (widgetConfig?.data_srcs?.metric?.input) {
      const { competitiveSetKey } = widgetConfig.data_srcs.metric.input;

      getFirstLastSession({ variables: { id: competitiveSetKey } });
    }
  }, [widgetConfig, getFirstLastSession]);

  useEffect(() => {
    if (!widgetConfig?.data_srcs?.metric?.input) {
      return;
    }

    const { competitiveSetKey, brandKey } = widgetConfig.data_srcs.metric.input;
    const timeRange = timeRangeToNumDays(activeTimeRange);

    getSummaryTrendsVariableIds({
      variables: {
        id: competitiveSetKey,
        sessionKey: latestSessionKey,
        input: {
          range: timeRange,
          selectedBrandKey: brandKey,
        },
      },
    });
  }, [
    activeTimeRange,
    widgetConfig,
    getSummaryTrendsVariableIds,
    latestSessionKey,
  ]);

  const validSummaryTrendsVariableIds = useMemo(() => {
    if (!summaryTrendsData?.competitiveSet?.session?.trendSummaryWidget) {
      return false;
    }

    const { trendSummaryWidget } = summaryTrendsData.competitiveSet.session;
    return Boolean(
      trendSummaryWidget.metricsTrendingUp ||
        trendSummaryWidget.metricsTrendingDown
    );
  }, [summaryTrendsData]);

  const sourcesMetricIds: string[] = useMemo(() => {
    const widgetType = widgetConfig?.type_id as WidgetType;

    // Handle SummaryTrendsV1 widget case where the variable IDs are dynamic
    // from the GraphQL response based on competitive set, brand, and session
    if (
      validSummaryTrendsVariableIds &&
      widgetType === WidgetType.SummaryTrendsV1
    ) {
      const { trendSummaryWidget } = summaryTrendsData.competitiveSet.session;
      const { metricsTrendingUp, metricsTrendingDown } = trendSummaryWidget;
      const metricsUpAndDown: { variableId: string }[] = [];
      if (metricsTrendingUp) {
        metricsUpAndDown.push(...metricsTrendingUp);
      }
      if (metricsTrendingDown) {
        metricsUpAndDown.push(...metricsTrendingDown);
      }

      return metricsUpAndDown.map(
        ({ variableId }: { variableId: string }) => variableId
      );
    }

    return getMetricIdsFromApiConfig({ config: widgetConfig, widgetType });
  }, [
    widgetConfig,
    validSummaryTrendsVariableIds,
    summaryTrendsData?.competitiveSet?.session,
  ]);

  return (
    <Drawer
      classes={{
        root: styles.WidgetContextualHelpDrawer,
        paper: styles.Content,
      }}
      anchor="right"
      onClose={onClose}
      {...props}
    >
      <div className={styles.Header}>
        <div className={styles.HeaderRight}>
          <div className={styles.HeaderIconContainer}>
            <MetricContextualHelpIcon />
          </div>

          <h1 className={styles.HeaderText}>Understand this data</h1>
        </div>
        <div className={styles.HeaderLeft}>
          <CloseIcon
            className={styles.CloseIcon}
            onClick={(e) => onClose?.(e, 'escapeKeyDown')}
          />
        </div>
      </div>

      <div className={styles.Body}>
        <CalculationsAndSources
          metricIds={sourcesMetricIds}
          widgetType={widgetConfig?.type_id as WidgetType}
          containerClassName={styles.Content}
        />
      </div>
    </Drawer>
  );
};

export default WidgetContextualHelpDrawer;
