import {
  ArrowDownwardOutlined,
  ArrowForward,
  ArrowUpwardOutlined,
} from '@mui/icons-material';
import classNames from 'classnames';
import { Box } from '@mui/material';
import styles from './ScoreChangeDescription.module.scss';
import { abbreviateNumber } from '../../../../utils/number';

export interface ScoreChangeDescriptionProps {
  score: number;
  suffix?: string;
  description: string;
  className?: string;
}

const ScoreChangeDescription = ({
  score,
  suffix,
  description,
  className,
}: ScoreChangeDescriptionProps) => {
  let arrowItem;
  let scoreColorClass;

  if (score < 0) {
    scoreColorClass = styles.Down;
    arrowItem = (
      <ArrowDownwardOutlined
        className={classNames(scoreColorClass, styles.Icon)}
      />
    );
  } else if (score > 0) {
    scoreColorClass = styles.Up;
    arrowItem = (
      <ArrowUpwardOutlined
        className={classNames(scoreColorClass, styles.Icon)}
      />
    );
  } else {
    scoreColorClass = styles.Right;
    arrowItem = (
      <ArrowForward className={classNames(scoreColorClass, styles.Icon)} />
    );
  }

  return (
    <Box className={classNames(styles.Container, className)}>
      {arrowItem}
      <p className={styles.LabelDescription}>
        <span className={scoreColorClass}>
          {abbreviateNumber(score)}
          {suffix && `${suffix} `}
        </span>
        {description}
      </p>
    </Box>
  );
};

export default ScoreChangeDescription;
