import { ApolloError } from '@apollo/client';
import { CustomerScore } from '../Components/Charts/BluescoreTimelineChart/interfaces';

export enum BreakdownType {
  Aggregate = 'aggregate',
  Channel = 'channel',
}

export enum ScoreType {
  Raw = 'raw',
  Indexed = 'indexed',
}

export enum MetricUnit {
  USD = 'usd',
  None = 'none',
}

export enum MetricFormatType {
  PercentageSuffix = 'percentage-suffix',
  Percentage = 'percentage-calculated',
  Abbreviated = 'abbreviated',
  Currency = 'currency',
  None = 'none',
}

export interface Metric {
  category: string;
  description: string;
  descriptionOfChannel: string;
  formatType: MetricFormatType;
  group: string;
  id: number;
  isCurrency: 0 | 1; // TODO: remove this in favor of formatType and unit
  name: string;
  nameWithChannel: string;
  unit: MetricUnit;
  value: number;
  variableId: string;
}

export interface SocialMetric {
  channel: string;
  timeseries: CustomerScore[];
  tile: {
    score: number;
    tooltip: string;
  };
  description: string;
}

export enum SocialChannelKey {
  Aggregate = 'aggregate',
  Facebook = 'facebook',
  Twitter = 'twitter',
  Youtube = 'youtube',
  LinkedIn = 'linkedin',
  Instagram = 'instagram',
}

export interface MetricTrendSession {
  name: string;
  value: number;
  date: string | number | Date;
  original_value: number;
  sessionKey: string;
  variableId: string;
}

export interface ESOVResponseData {
  revenue: {
    value: number;
    adjusted: number;
    ratio: number;
  };
  adSpend: {
    value: number;
  };
  shareOfVoice: number;
  shareOfMarket: number;
  excessShareOfVoice: number;
  excessShareOfVoiceImpact: number;
  excessShareOfVoiceInsight?: Record<string, unknown>;
}

export enum ESOVPosition {
  LEADER = 'leader',
  MIDDLE = 'middle',
  LAGGING = 'lagging',
}

export interface ESOVResponse {
  loading: boolean;
  error: ApolloError | null;
  data: Record<string, ESOVResponseData | string> | null;
}


export interface MetricTrendResponse {
  data: {
    competitiveSet: {
      brand: {
        brandKey: string;
        logoUrl: string;
        name: string;
        international_ratio: number;
        __typename: string;
      };
      session: {
        id: number;
        sessionDate: string;
        metricTrend: {
          [x: string]: string;
          __typename: string;
        };
        competitors: {
          id: number;
          brandKey: string;
          name: string;
          logoUrl: string;
          international_ratio?: number;
          metricTrend: {
            [x: string]: string;
            __typename: string;
          };
          __typename: string;
        }[];
        __typename: string;
      };
      __typename: string;
    };
  }
}
