/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { useQuery } from '@apollo/client';
import type { Layout } from 'react-grid-layout';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  WidgetLayout,
  WidgetTemplateResponse,
  DashboardResponse,
  DashboardListResponse,
  DashboardTemplateResponse,
  ShareableDashboard,
  DashboardUser,
  SortedDashboardResponse,
  UserInfo,
} from '../Components/Pages/CustomizableDashboards/types';
import { USER_AND_ACCOUNT_KEYS } from '../api/queries/DataManager';
import { UpdateWidgetSettings } from '../interfaces/dashboard-api';

import BNContext from '../contexts/BNContext';

const dashboardApi = axios.create({
  baseURL: process.env.REACT_APP_DASHBOARD_API_URL,
  timeout: 30000,
});

const addSharedData = (
  dashboard: DashboardResponse,
  userId: string,
  userMap: Record<string, UserInfo>
): ShareableDashboard => {
  const isCurrentUserOwner = dashboard.user_id === userId;
  const sharedWithUsers: DashboardUser[] = (
    dashboard.shared_with?.split(',').filter(Boolean) ?? []
  ).map((id: string) => {
    return { ...userMap[id.toLowerCase()] };
  });

  return {
    ...dashboard,
    ownerName: userMap[dashboard.user_id]?.name,
    isCurrentUserOwner,
    sharedWithUsers,
    isDashboardShared: sharedWithUsers.length > 0,
  };
};

const useDashboardAPI = () => {
  const { userSettingsData } = useContext(BNContext);
  const [token, setToken] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>('');
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const userLookup = useMemo(() => {
    return (userSettingsData ?? []).reduce(
      (lookup: Record<string, UserInfo>, user: UserInfo) => {
        lookup[user.userKey] = user;
        return lookup;
      },
      {} as Record<string, UserInfo>
    );
  }, [userSettingsData]);

  useQuery(USER_AND_ACCOUNT_KEYS, {
    skip: !!userId,
    onCompleted: (data) => setUserId(data.user.userKey),
  });

  // Populate auth token
  useEffect(() => {
    if (isLoading) return;

    if (isAuthenticated && !token) {
      getAccessTokenSilently({
        scope: 'openid profile email',
        audience: process.env.REACT_APP_AUDIENCE,
      })
        .then((newToken) => setToken(newToken))
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, [isLoading, isAuthenticated, token, getAccessTokenSilently]);

  useEffect(() => {
    dashboardApi.interceptors.request.use((config) => {
      if (token) {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    });
  }, [token]);

  /* Dashboards Methods */
  const getDashboards = async (): Promise<DashboardListResponse | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.get(`/dashboards/user/${userId}`);
    const sharedDashboards = data.dashboards.map(
      (dashboard: DashboardResponse) =>
        addSharedData(dashboard, userId, userLookup)
    );

    return {
      ...data,
      dashboards: sharedDashboards,
    };
  };

  const getSortedDashboards =
    async (): Promise<SortedDashboardResponse | null> => {
      if (!token || !userId) {
        return Promise.resolve(null);
      }

      const { data } = await dashboardApi.get(`/dashboards/user/${userId}`);
      const sharedDashboards = data.dashboards.map(
        (dashboard: DashboardResponse) =>
          addSharedData(dashboard, userId, userLookup)
      );

      return {
        ...data,
        all: sharedDashboards,
        myDashboards: sharedDashboards.filter(
          (dashboard: ShareableDashboard) => dashboard.isCurrentUserOwner
        ),
        sharedDashboards: sharedDashboards.filter(
          (dashboard: ShareableDashboard) => !dashboard.isCurrentUserOwner
        ),
      };
    };

  /* Dashboard Methods */
  const getDashboard = async (
    id: string
  ): Promise<ShareableDashboard | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }
    const { data } = await dashboardApi.get(`/dashboard/${id}/user/${userId}`);
    const sharedDashboard = addSharedData(data, userId, userLookup);

    return sharedDashboard;
  };

  const createDashboard = async (): Promise<DashboardResponse | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }
    const { data } = await dashboardApi.post(`/dashboard`, { user_id: userId });

    return data;
  };

  const deleteDashboard = async (id: string): Promise<boolean | null> => {
    if (!token) {
      return Promise.resolve(null);
    }

    const { status } = await dashboardApi.delete(`/dashboard/${id}`);
    return status === 204;
  };

  const duplicateDashboard = async (
    id: string
  ): Promise<DashboardResponse | null> => {
    if (!token) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.post(`/dashboard/${id}/duplicate`, {});
    return data;
  };

  const updateDashboard = async (
    id: string,
    layoutData: {
      layout: Layout[];
      widgets: WidgetLayout[];
    }
  ): Promise<DashboardResponse | null> => {
    if (!token) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.put(
      `/dashboard/dashboard${id}`,
      layoutData
    );
    return data;
  };

  const updateDashboardLastViewed = async (
    id: string
  ): Promise<number | null> => {
    if (!token) {
      return Promise.resolve(null);
    }

    const { status } = await dashboardApi.patch(`/dashboard/${id}`, {});

    return status;
  };

  const updateDashboardTitle = async (
    id: string,
    title: string
  ): Promise<boolean | null> => {
    if (!token) {
      return Promise.resolve(null);
    }

    const { status } = await dashboardApi.patch(`/dashboard/${id}/metadata`, {
      title,
    });
    const isSuccess = status === 204;

    return Promise.resolve(isSuccess);
  };

  /* Dashboard Sharing */
  const shareDashboard = async (
    dashboardId: string,
    senderName: string | undefined,
    sharedUserIds: UserInfo[]
  ): Promise<unknown> => {
    if (!token || !userId) {
      return Promise.resolve();
    }

    const body = {
      sender_user_name: senderName,
      link: `https://deep.blueocean.ai/dashboards/${dashboardId}`,
      receivers: sharedUserIds.map((user) => {
        return {
          uid: user.userKey,
          name: user.name,
          email: user.email,
        };
      }),
    };

    const data = await dashboardApi.post(
      `/dashboard/${dashboardId}/share`,
      body
    );

    return Promise.resolve(data);
  };

  const revokeSharingAccess = async (
    dashboardId: string,
    revokedUserId?: string
  ): Promise<number | null> => {
    if (!token) {
      return Promise.resolve(null);
    }

    const { status } = await dashboardApi.delete(
      `/dashboard/${dashboardId}/unshare/user/${revokedUserId ?? userId}`
    );

    return status;
  };

  const getRecievedDashboards =
    async (): Promise<DashboardListResponse | null> => {
      if (!token || !userId) {
        return Promise.resolve(null);
      }

      const { data } = await dashboardApi.get(
        `/dashboards/received/user/${userId}`
      );

      const sharedDashboards = data.dashboards.map(
        (dashboard: DashboardResponse) =>
          addSharedData(dashboard, userId, userLookup)
      );

      return {
        ...data,
        dashboards: sharedDashboards,
      };
    };

  const checkDashboardPermissions = async (
    id: string
  ): Promise<number | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }
    const { status } = await dashboardApi.get(
      `/dashboard/${id}/user/${userId}`
    );

    return status;
  };

  const removeReceivedDashboard = async (
    dashboardId: string
  ): Promise<string[] | null> => {
    if (!token) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.post(
      `/dashboard/${dashboardId}/remove`
    );
    return data;
  };

  const duplicateSharedDashboard = async (
    dashboardId: string
  ): Promise<DashboardResponse | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.post(
      `/dashboard/${dashboardId}/replicate/user/${userId}`
    );

    return data;
  };

  const updateReceivedDashboardLastViewed = async (
    id: string
  ): Promise<number | null> => {
    if (!token) {
      return Promise.resolve(null);
    }

    const { status } = await dashboardApi.patch(
      `/dashboard/${id}/user/${userId}`,
      {}
    );

    return status;
  };

  /* Widget Methods */
  const getWidgetTemplates =
    async (): Promise<WidgetTemplateResponse | null> => {
      if (!token || !userId) {
        return Promise.resolve(null);
      }
      const { data } = await dashboardApi.get(`/widget-templates`);

      return data;
    };

  const addWidgetToDashboard = async ({
    dashboardId,
    widgetType,
  }: {
    dashboardId: string;
    widgetType: string;
  }): Promise<ShareableDashboard | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.post(
      `/dashboard/${dashboardId}/widget/${widgetType}`,
      {}
    );
    const sharedDashboard = addSharedData(data, userId, userLookup);

    return sharedDashboard;
  };

  const updateWidgetLayout = async ({
    dashboardId,
    newWidgetLayout,
  }: {
    dashboardId: string;
    newWidgetLayout: Record<string, string>;
  }): Promise<ShareableDashboard | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.patch(
      `/dashboard/${dashboardId}/widget-layouts/user/${userId}`,
      newWidgetLayout
    );
    const sharedDashboard = addSharedData(data, userId, userLookup);

    return sharedDashboard;
  };

  const deleteWidgetFromDashboard = async ({
    dashboardId,
    widgetId,
  }: {
    dashboardId: string;
    widgetId: string;
  }): Promise<ShareableDashboard | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.delete(
      `/dashboard/${dashboardId}/widget/${widgetId}`
    );
    const sharedDashboard = addSharedData(data, userId, userLookup);

    return sharedDashboard;
  };

  const updateWidgetInstance = async ({
    dashboardId,
    widgetId,
    payload,
  }: {
    dashboardId: string;
    widgetId: string;
    payload: UpdateWidgetSettings;
  }): Promise<DashboardResponse | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.patch(
      `/dashboard/${dashboardId}/widget/${widgetId}`,
      payload
    );

    return data;
  };

  const updateWidgetInstances = async ({
    dashboardId,
    widgets,
  }: {
    dashboardId: string;
    widgets: Record<string, UpdateWidgetSettings>;
  }) => {
    if (!token || !userId || !dashboardId) {
      return Promise.resolve(null);
    }

    const { data } = await dashboardApi.put(
      `/dashboard/${dashboardId}/user/${userId}`,
      widgets
    );
    return data;
  };

  const getDashboardTemplates =
    async (): Promise<DashboardTemplateResponse | null> => {
      if (!token || !userId) {
        return Promise.resolve(null);
      }

      const { data } = await dashboardApi.get(`/dashboards/templates`);

      return data;
    };

  const createDashboardTemplate = async (params: {
    type_id: string;
    brandKey?: string;
    competitiveSetKey?: string;
    timeRange?: string;
    brandName?: string;
    selectedBrandKeys?: string[];
    title?: string;
    description?: string;
  }): Promise<DashboardResponse | null> => {
    if (!token || !userId) {
      return Promise.resolve(null);
    }

    const dashboardValues = {
      user_id: userId,
      type_id: params.type_id,
      params: {
        brandKey: params?.brandKey,
        competitiveSetKey: params?.competitiveSetKey,
        timeRange: params?.timeRange,
        brandName: params?.brandName,
        selectedBrandKeys: params?.selectedBrandKeys,
      },
      title: params?.title,
      description: params?.description,
    };

    const { data } = await dashboardApi.post(`/dashboard`, {
      ...dashboardValues,
    });

    return data;
  };

  return {
    updateDashboard,
    updateDashboardLastViewed,
    getDashboard,
    getDashboards,
    getSortedDashboards,
    createDashboard,
    deleteDashboard,
    duplicateDashboard,
    updateDashboardTitle,
    shareDashboard,
    getWidgetTemplates,
    addWidgetToDashboard,
    updateWidgetLayout,
    deleteWidgetFromDashboard,
    updateWidgetInstance,
    getDashboardTemplates,
    createDashboardTemplate,
    updateWidgetInstances,
    revokeSharingAccess,
    getRecievedDashboards,
    removeReceivedDashboard,
    checkDashboardPermissions,
    duplicateSharedDashboard,
    updateReceivedDashboardLastViewed,
  };
};

export default useDashboardAPI;
