import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';

import Switch from '../../Atoms/Switch/Switch';
import {
  ADD_RECOMMENDATIONS,
  MODIFY_RECOMMENDATION_COMPLETION,
} from '../../../api/queries/Pages/Recommendations';
import TextInput from '../../Molecules/TextInput/TextInput';
import SelectInput from '../../Molecules/SelectInput/SelectInput';
import ImpactResultRadioSelector from '../../Molecules/ImpactResultRadioSelector/ImpactResultRadioSelector';
import DeselectInput from '../../Molecules/DeselectInput/DeselectInput';
import ModalFooter from '../../Molecules/ModalFooter/ModalFooter';
import BNContext from '../../../contexts/BNContext';

import styles from './AddRecommendation.module.scss';

import { TAGS } from '../../../constants/recommendations';

export default function AddRecommendation({
  className,
  onClose,
  onAdd,
  onEdit,
  editedRecommendation,
  ...props
}) {
  const {
    currentSession: sessionKey, //eslint-disable-line
    accountKey,
  } = React.useContext(BNContext);

  const [disableAction, setDisableAction] = useState(true);

  const PRACTICE_AREA_OPTIONS = TAGS.map((tag) => tag.label);

  const [formData, setFormData] = useState({
    title: {
      key: 'title',
      required: true,
      value: '',
    },
    description: {
      key: 'description',
      required: true,
      value: '',
    },
    recommendationsAssociationType: {
      key: 'recommendationsAssociationType',
      required: false,
      value: '',
    },
    impactResults: {
      key: 'impactResults',
      required: false,
      value: 1,
    },
    practiceAreas: {
      key: 'practiceAreas',
      required: false,
      value: PRACTICE_AREA_OPTIONS,
    },
    private: {
      key: 'private',
      required: false,
      value: false,
    },
  });

  const recommendationsAssociationType = [
    { label: 'Select Page', value: '' },
    { label: 'Market Index Scorecard', value: '6, -1' },
    { label: 'Factors > Overview', value: '5, -1' },
    { label: 'Factors > Familiar', value: '1, 1' },
    { label: 'Factors > Unique', value: '1, 2' },
    { label: 'Factors > Consistent', value: '1, 3' },
    { label: 'Factors > Relevant', value: '1, 4' },
    { label: 'Factors > Revered', value: '1, 5' },
    { label: 'Factors > Familiar > Visible', value: '2, 1' },
    { label: 'Factors > Familiar > Considered', value: '2, 2' },
    { label: 'Factors > Familiar > Amplified', value: '2, 3' },
    { label: 'Factors > Unique > Different', value: '2, 4' },
    { label: 'Factors > Unique > Memorable', value: '2, 5' },
    { label: 'Factors > Consistent > Clear', value: '2, 6' },
    { label: 'Factors > Consistent > Reinforcing', value: '2, 7' },
    { label: 'Factors > Consistent > Stable', value: '2, 8' },
    { label: 'Factors > Relevant > Needed', value: '2, 9' },
    { label: 'Factors > Relevant > Valued', value: '2, 10' },
    { label: 'Factors > Relevant > Influential', value: '2, 11' },
    { label: 'Factors > Revered > Desirable', value: '2, 12' },
    { label: 'Factors > Revered > Esteemed', value: '2, 13' },
    { label: 'Factors > Revered > Inspiring', value: '2, 14' },
    { label: 'Content Analysis Themes > Overview', value: '4, -1' },
    { label: 'Content Analysis Topics > Overview', value: '15, -1' },
    { label: 'Content Analysis Keywords > Brand Content', value: '16, 1' },
    { label: 'Content Analysis Keywords > Audience Content', value: '16, 2' },
    {
      label: 'Content Analysis Keywords > Brand/Audience Comparison',
      value: '16, 3',
    },
    { label: 'Recommendations > All Recommendations', value: '-1, -1' },
  ];

  useEffect(() => {
    if (
      formData.title.value !== '' &&
      formData.description.value !== '' &&
      formData.recommendationsAssociationType.value !== '' &&
      formData.impactResults.value !== ''
    ) {
      setDisableAction(false);
    } else {
      setDisableAction(true);
    }
  }, [formData]);

  function handleFormChange(e, key) {
    const result = cloneDeep(formData);

    let value = '';

    if (e && e.target) {
      value = e.target.value;
    } else {
      value = e;
    }

    if (key === 'private') {
      value = !result.private.value;
    }

    result[key].value = value;

    setFormData(result);
  }

  const [addRecommendation] = useMutation(ADD_RECOMMENDATIONS);
  const [editRecommendationMutation] = useMutation(
    MODIFY_RECOMMENDATION_COMPLETION
  );

  useEffect(() => {
    if (editedRecommendation !== null) {
      const result = cloneDeep(formData);

      result.title.value = editedRecommendation.name;
      result.description.value = editedRecommendation.value;
      result.impactResults.value = editedRecommendation.impactId;

      result.practiceAreas.value =
        editedRecommendation.practiceAreas.split(',');
      result.recommendationsAssociationType.value = `${editedRecommendation.type}, ${editedRecommendation.associatedTypeId}`;

      setFormData(result);
    }
    // eslint-disable-next-line
  }, [editedRecommendation]);

  async function handleSubmit() {
    if (editedRecommendation === null) {
      try {
        await addRecommendation({
          variables: {
            sessionKey,
            accountKey,
            name: formData.title.value,
            value: formData.description.value,
            impact_id: formData.impactResults.value,
            practice_areas: formData.practiceAreas.value.join(', '),
            isPrivate: formData.private.value ? 1 : 0,
            // eslint-disable-next-line
            type: parseInt(
              formData.recommendationsAssociationType.value.split(',')[0]
            ),
            // eslint-disable-next-line
            associatedType_id: parseInt(
              formData.recommendationsAssociationType.value.split(',')[1]
            ),
          },
        });

        onClose();
      } catch (errors) {
        // eslint-disable-next-line no-console
        console.error(errors);
      } finally {
        onAdd();
      }
    } else {
      try {
        await editRecommendationMutation({
          variables: {
            sessionKey,
            id: editedRecommendation.id,
            name: formData.title.value,
            value: formData.description.value,
            impact_id: formData.impactResults.value,
            practice_areas: formData.practiceAreas.value.join(', '),
            isPrivate: formData.private.value ? 1 : 0,
            isComplete: editedRecommendation.id,
            // eslint-disable-next-line
            type: parseInt(
              formData.recommendationsAssociationType.value.split(',')[0]
            ),
            // eslint-disable-next-line
            associatedType_id: parseInt(
              formData.recommendationsAssociationType.value.split(',')[1]
            ),
          },
        });

        onClose();
      } catch (errors) {
        // eslint-disable-next-line no-console
        console.error(errors);
      } finally {
        const editedRec = {
          sessionKey,
          id: editedRecommendation.id,
          name: formData.title.value,
          value: formData.description.value,
          impact_id: formData.impactResults.value,
          practice_areas: formData.practiceAreas.value.join(', '),
          isPrivate: formData.private.value ? 1 : 0,
          isComplete: editedRecommendation.isComplete,
          // eslint-disable-next-line
          type: parseInt(
            formData.recommendationsAssociationType.value.split(',')[0]
          ),
          // eslint-disable-next-line
          associatedType_id: parseInt(
            formData.recommendationsAssociationType.value.split(',')[1]
          ),
        };
        onEdit(editedRec);
      }
    }
  }

  return (
    <Box
      py={6}
      px={4}
      className={classNames(styles.AddRecommendation, className)}
      {...props}
    >
      <Box mb={5}>
        <TextInput
          inputId="title"
          label="TITLE"
          required
          onChange={(e) => {
            handleFormChange(e, 'title');
          }}
          value={formData.title.value}
          maxLength={200}
        />
      </Box>

      <Box mb={5}>
        <TextInput
          inputId="description"
          label="DESCRIPTION"
          required
          multiline
          rows={6}
          onChange={(e) => {
            handleFormChange(e, 'description');
          }}
          value={formData.description.value}
          maxLength={1000}
        />
      </Box>

      <Box mb={4}>
        <Box mb={2}>
          <SelectInput
            inputId="recommendationsAssociationType"
            label="ASSIGN TO PAGE"
            menuItems={recommendationsAssociationType}
            onChange={(e) => {
              handleFormChange(e, 'recommendationsAssociationType');
            }}
            required
            value={formData.recommendationsAssociationType.value}
          />
        </Box>

        <span className={styles.Helper}>
          Which reporting period does this recommendation reference?
        </span>
      </Box>

      <Box mb={4}>
        <h5 className={styles.Label}>IMPACT RESULTS</h5>

        <Box mb={2}>
          <ImpactResultRadioSelector
            onChange={(e) => {
              handleFormChange(e, 'impactResults');
            }}
            value={
              editedRecommendation?.impactId ? editedRecommendation.impactId : 1
            }
          />
        </Box>

        <span className={styles.Helper}>When should this be done?</span>
      </Box>

      <Box mb={10} pr={2}>
        <h5 className={styles.Label}>PRACTICE AREAS</h5>

        <Box mb={2}>
          <DeselectInput
            onChange={(e) => {
              handleFormChange(e, 'practiceAreas');
            }}
            value={
              editedRecommendation?.practiceAreas
                ? editedRecommendation.practiceAreas
                : null
            }
          />
        </Box>

        <span className={styles.Helper}>
          What practice areas does this impact? Deselect the areas that
          don&apos;t apply.
        </span>
      </Box>

      <Box mt={4}>
        <Switch
          className={styles.KeepPrivateSwitch}
          onChange={(e) => {
            handleFormChange(e, 'private');
          }}
          checked={formData.private.value}
        />
        <span className={styles.SwitchHelper}>
          Keep private (only you can see this recommendation)
        </span>
      </Box>

      <ModalFooter
        actionText={editedRecommendation === null ? 'Add' : 'Save'}
        onCancel={onClose}
        disableAction={disableAction}
        onAction={handleSubmit}
        // loading={loading}
      />
    </Box>
  );
}

AddRecommendation.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  editedRecommendation: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
};
