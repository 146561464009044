import random from 'lodash/random';

export function prepareInsight(data) {
  if (
    !data ||
    !data.competitiveSet ||
    !data.competitiveSet.session ||
    !data.competitiveSet.session.blueScore ||
    !data.competitiveSet.session.blueScore.insight ||
    !data.competitiveSet.session.blueScore.insight.value
  ) {
    return ['No Insight Available'];
  }

  try {
    let insight = data.competitiveSet.session.blueScore.insight.value;

    if (insight[0] === '[') {
      insight = JSON.parse(insight);
      insight = insight.filter((blankInsight) => /\S/.test(blankInsight));
      return [insight[random(insight.length - 1)]];
    }

    return [insight];
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
