import React from 'react';
import PropTypes from 'prop-types';
import {
  FACTOR_RANGES,
  FACTOR_RANGES_HEAT_MAP,
} from '../../../constants/factors';
import ScoreLegendItem from '../ScoreLegendItem/ScoreLegendItem';

import styles from './ScoreLegend.module.scss';
import { ScoreMapType } from '../../Pages/CustomizableDashboards/types';

/**
 * Blue Score color legend.
 */
export default function ScoreLegend({
  size = 'md',
  type = ScoreMapType.WaterMap,
}) {
  const factorRanges =
    type === ScoreMapType.WaterMap ? FACTOR_RANGES : FACTOR_RANGES_HEAT_MAP;

  return (
    <div className={styles.ScoreLegend}>
      {factorRanges.map((range) => (
        <ScoreLegendItem
          key={range.KEY}
          label={range.NAME}
          identifier={range.KEY}
          rangeLow={range.FLOOR}
          rangeHigh={range.CEIL}
          threshold={range.THRESHOLD}
          size={size}
          type={type}
        />
      ))}
    </div>
  );
}

ScoreLegend.propTypes = {
  /**
   * Control the size of the score legend. (primary for use in switching from mobile to desktop)
   */
  size: PropTypes.string,
  type: ScoreMapType,
};
