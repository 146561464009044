import React, { createElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { Button } from '@blueoceanai/react-component-library';

import { BUTTON_VARIANTS } from '../../../constants/props';

import styles from './Insight.module.scss';

export default function Insight({
  className,
  icon,
  title,
  copy,
  ctaOnClick,
  ctaText,
  ctaButtonText,
  ...props
}) {
  return (
    <Box className={classNames(styles.Insight, className)} {...props}>
      <Box className={styles.Header} p={4}>
        <Box className={styles.IconContainer} mr={3}>
          {createElement(icon)}
        </Box>
        <h5>{title}</h5>
      </Box>
      <Box className={styles.Body} p={4}>
        <Box className={styles.Copy}>
          {copy.map((paragraph, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={index}>{paragraph}</p>
          ))}
        </Box>
        <Box className={styles.CTA} px={4}>
          <Box>
            {ctaText ? <span className={styles.Text}>{ctaText}</span> : null}
          </Box>

          {ctaButtonText.length > 0 ? (
            <Button
              className={styles.CtaButton}
              variant={BUTTON_VARIANTS.PRIMARY}
              onClick={ctaOnClick}
            >
              {ctaButtonText}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

Insight.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.func,
  title: PropTypes.string,
  copy: PropTypes.arrayOf(PropTypes.string),
  ctaOnClick: PropTypes.func,
  ctaText: PropTypes.string,
  ctaButtonText: PropTypes.string,
};
