import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TOPICS_THEME } from '../../../constants/topics';
import Tooltip from '../../Atoms/Tooltip/Tooltip';

import styles from './ThemesTopic.module.scss';

export default function ThemesTopic({
  // eslint-disable-next-line
  topicTitle = '',
  theme,
}) {
  const stringLength = 300;
  const topicContent =
    topicTitle.length > stringLength
      ? `${topicTitle.substring(0, stringLength)}...`
      : topicTitle;
  const topicTooltip = topicTitle;

  return (
    <div>
      {topicContent.length > stringLength ? (
        <Tooltip title={topicTooltip} enterDelay={300} arrow>
          <div
            className={classNames(
              theme === TOPICS_THEME.new
                ? styles.ThemesTopicNew
                : styles.ThemesTopic,
              theme === TOPICS_THEME.old
                ? styles.ThemesTopicRemoved
                : styles.ThemesTopic
            )}
          >
            <p>{topicContent}</p>
          </div>
        </Tooltip>
      ) : (
        <div
          className={classNames(
            theme === TOPICS_THEME.new
              ? styles.ThemesTopicNew
              : styles.ThemesTopic,
            theme === TOPICS_THEME.old
              ? styles.ThemesTopicRemoved
              : styles.ThemesTopic
          )}
        >
          <p>{topicContent}</p>
        </div>
      )}
    </div>
  );
}

ThemesTopic.propTypes = {
  topicTitle: PropTypes.string,
  theme: PropTypes.string,
};
