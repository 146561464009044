import dayjs from 'dayjs';
import { WidgetPointData } from '../../../Components/Pages/CustomizableDashboards/types';
import { ChartTooltipContextLine } from '../../../interfaces';
import { abbreviateNumber } from '../../../utils/number';
import genericWidgetTooltip from '../genericWidgetTooltip/genericWidgetTooltip';
import styles from './singleMetricTooltip.module.scss';

const singleMetricTooltip = (
  context: ChartTooltipContextLine,
  showRawData: boolean,
  isPercentage: boolean
) => {
  const { tooltip } = context;
  const tooltipParent = document.createElement('div');
  const dateLabelEl = document.createElement('div');
  dateLabelEl.classList.add(styles.DateLabel);

  if (tooltip?.dataPoints?.length) {
    const tooltipData = tooltip.dataPoints[0].raw as WidgetPointData;
    const dateLabel = document.createTextNode(
      dayjs(tooltipData.x).format('MMM D')
    );
    dateLabelEl.appendChild(dateLabel);
    tooltipParent.appendChild(dateLabelEl);
    if (!showRawData && tooltip.dataPoints.length === 2) {
      tooltip.dataPoints.pop();
    }

    tooltip.dataPoints.forEach((point) => {
      const pointData = point.raw as WidgetPointData;
      const backgroundColor = point.dataset.borderColor as string;
      const labelEl = document.createElement('div');
      labelEl.classList.add(styles.Label);

      const colorSquare = document.createElement('div');
      colorSquare.classList.add(styles.ColorSquare);
      colorSquare.style.backgroundColor = backgroundColor;
      labelEl.appendChild(colorSquare);

      const labelText = document.createTextNode(pointData.description);
      labelEl.appendChild(labelText);

      const valueEl = document.createElement('div');
      valueEl.classList.add(styles.MetricValue);
      // TODO: handle RawScoreFormat.Percentage metrics consistently across widgets
      if (showRawData && isPercentage) {
        valueEl.appendChild(document.createTextNode(`${pointData.y}%`));
      } else {
        valueEl.appendChild(
          document.createTextNode(abbreviateNumber(pointData.y, 3))
        );
      }
      labelEl.appendChild(valueEl);

      tooltipParent.appendChild(labelEl);
    });

    return genericWidgetTooltip(context, tooltipParent);
  }

  return null;
};

export default singleMetricTooltip;
