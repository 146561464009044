import { GenericIconProps } from './types';

function CloseIcon(props: GenericIconProps) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="currentColor"
          d="M17.657 6.343a.5.5 0 01.058.638l-.058.07L12.707 12l4.95 4.95a.5.5 0 01-.638.765l-.07-.058L12 12.707l-4.95 4.95a.5.5 0 01-.765-.638l.058-.07L11.293 12l-4.95-4.95a.5.5 0 01.638-.765l.07.058L12 11.293l4.95-4.95a.5.5 0 01.707 0z"
        />
      </g>
    </svg>
  );
}

export default CloseIcon;
