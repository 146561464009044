export const INSIGHT = [
  'So often we are focused on what our own brand is saying, rarely do we see what others are talking about in granular detail. Use this section to track alignment to the category, but also see shifts in competitor messaging prioritization over time.',
];

export const MAIN_BRAND = 'mainBrand';

export const COMPETITOR_TOPICS = 'competitorTopics';

export const HISTORY_TOPICS = 'historyTopics';

export const TOPICS_THEME = {
  old: 'old',
  new: 'new',
  same: 'same',
};
