import * as React from 'react';

function SvgCheckCircle(props) {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10A10 10 0 0010 0z"
          fill="currentColor"
        />
        <path
          d="M12.713 7.309l-4.194 3.95-1.313-1.287a.717.717 0 00-1 0 .683.683 0 000 .98l1.885 1.846c.134.13.313.202.5.202l.06-.002a.718.718 0 00.528-.307l4.68-4.58c.246-.353.158-.783-.167-.995a.715.715 0 00-.98.193z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default SvgCheckCircle;
