import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import { Button } from '@blueoceanai/react-component-library';
import RecommendationsModal from '../../Organisms/RecommendationsModal/RecommendationsModal';
import TalkToStrategistModal from '../../Organisms/TalkToStrategistModal/TalkToStrategistModal';
import { BUTTON_VARIANTS } from '../../../constants/props';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import BNContext from '../../../contexts/BNContext';

import styles from './ResourcesCTA.module.scss';
import { BookIcon } from '../../../assets/icons';

export default function ResourcesCTA({ data, loading, insight }) {
  const { accountType } = React.useContext(BNContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [strategistModalOpen, setStrategistModalOpen] = useState(false);
  const [numberRecommendations, setNumberRecommendations] = useState(0);

  function closeModal() {
    setModalOpen(false);
  }
  function openStrategistModal() {
    setStrategistModalOpen(true);
  }
  function closeStrategistModal() {
    setStrategistModalOpen(false);
  }
  useEffect(() => {
    if (!data) {
      return;
    }
    let numRec = 0;
    if (data?.length) {
      data.map((recommendation) =>
        recommendation.isComplete !== 1 ? numRec++ : null
      );
    }
    setNumberRecommendations(numRec);
  }, [data]);

  return (
    <>
      <Paper elevation={0} className={styles.ResourcesCTA}>
        <Box p={4} className={styles.Header}>
          <BookIcon className={styles.Icon} />
          <Box ml={2}>
            <h5>Good to Know!</h5>
          </Box>
        </Box>
        <Box p={4} pb={6} className={styles.Body}>
          {loading ? <ComponentLoader /> : null}

          {!loading && insight ? (
            <>
              <Box pb={3}>
                {insight.map((paragraph) => (
                  <p className={styles.Text} key={paragraph.substring(0, 10)}>
                    {paragraph}
                  </p>
                ))}
              </Box>

              <Box className={styles.ButtonWrap}>
                {accountType === 5 ? (
                  numberRecommendations > 0 ? (
                    <Button
                      variant={BUTTON_VARIANTS.PRIMARY}
                      onClick={() => setModalOpen(true)}
                    >
                      Show Recommendations ({numberRecommendations})
                    </Button>
                  ) : null
                ) : (
                  <Button
                    onClick={openStrategistModal}
                    variant={BUTTON_VARIANTS.PRIMARY}
                  >
                    Upgrade to Premium
                  </Button>
                )}
              </Box>
            </>
          ) : null}
        </Box>
      </Paper>
      {/* // eslint-disable-next-line */}
      {!loading && data !== null ? (
        <RecommendationsModal
          open={modalOpen}
          onClose={closeModal}
          recommendations={data}
        />
      ) : null}
      <TalkToStrategistModal
        open={strategistModalOpen}
        onClose={closeStrategistModal}
      />
    </>
  );
}

ResourcesCTA.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  /**
   * Body text of the insight. Each array element is a paragraph.
   */
  insight: PropTypes.arrayOf(PropTypes.string).isRequired,

  loading: PropTypes.bool,
};
