import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Footer.module.scss';

import Logo from '../../../assets/images/BlueoceanLogo.jsx';
import FooterImage from '../../../assets/images/FooterImage.jsx';

export default function Footer({ ...props }) {
  const d = new Date();
  const currentYear = d.getFullYear();

  return (
    <footer className={classNames(styles.Footer)} {...props}>
      <FooterImage className={styles.FooterImage} />
      <div className={styles.nav}>
        <div className={styles.navLeft}>
          <a href="/">
            <Logo className={styles.logo} />
          </a>
          <ul>
            <li>
              <a
                href="https://www.blueocean.ai/about-us/"
                target="_blank"
                rel="noopener noreferrer"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="https://www.blueocean.ai/careers/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
            </li>
            <li>
              <a
                href="https://www.blueocean.ai/faq/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </li>
            <li>
              <a href="mailto:support@blueocean.ai?subject=Brand Navigator Support">
                Contact Support
              </a>
            </li>
            <li>
              <a
                href="https://www.blueocean.ai/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms
              </a>
            </li>
            <li>
              <a
                href="https://www.blueocean.ai/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <span>{`Copyright ${currentYear}, BlueOcean AI, Inc. All rights reserved.`}</span>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};
