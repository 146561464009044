import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useState,
  MouseEvent,
  useCallback,
} from 'react';
import classNames from 'classnames';
import { Popover } from '@mui/material';
import dayjs from 'dayjs';
import { TimeRange } from '../../../../interfaces/dashboard-api';
import DateRangeSelector, {
  timeRangeValueMap,
} from '../DateRangeSelector/DateRangeSelector';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../../assets/icons/DynamicCaret';

import styles from './DateRangeSelectorDropdown.module.scss';

export interface DateRangeSelectorDropdownProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  timeRange: TimeRange | null;
  endDate: Date;
  onApply: ({
    endDate,
    timeRange,
  }: {
    endDate: Date;
    timeRange: TimeRange | null;
  }) => void;
  fullWidth?: boolean;
  classes?: {
    dropdownButton: string;
  };
}

const DateRangeSelectorDropdown: FunctionComponent<
  DateRangeSelectorDropdownProps
> = ({
  className,
  timeRange,
  endDate,
  fullWidth = false,
  classes,
  onApply,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = useCallback(
    ({
      endDate: newEndDate,
      timeRange: newTimeRange,
    }: {
      endDate: Date;
      timeRange: TimeRange | null;
    }) => {
      onApply({ endDate: newEndDate, timeRange: newTimeRange });
      handleClose();
    },
    [onApply]
  );

  const open = Boolean(anchorEl);

  const formattedDateRange =
    timeRange && endDate
      ? `${dayjs(endDate)
          .subtract(timeRangeValueMap[timeRange], 'days')
          .format('MMM D, YYYY')} - ${dayjs(endDate).format('MMM D, YYYY')}`
      : 'Filter by Date Range';

  return (
    <div className={classNames(styles.DateRangeSelectorDropdown, className)}>
      <div
        className={classNames(
          styles.DropdownBtn,
          open ? styles.Open : null,
          fullWidth ? styles.FullWidth : null,
          classes?.dropdownButton ?? null
        )}
        onClick={anchorEl ? handleClose : handleOpen}
      >
        <div className={styles.Label}>{formattedDateRange}</div>

        <DynamicCaretIcon direction={CaretDirection.down} />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{
          paper: styles.Popover,
        }}
      >
        <DateRangeSelector
          timeRange={timeRange}
          endDate={endDate}
          onApply={handleApply}
        />
      </Popover>
    </div>
  );
};

export default DateRangeSelectorDropdown;
