import { useMemo } from 'react';
import nameSort from '../utils/namesort';
import { BrandDropdownOption, BrandOptions } from '../interfaces/brand-options';

    export default function useBrandOptions(competitorsResp: BrandOptions) {
    return useMemo(() => {
        const result: BrandDropdownOption<string>[] = [];

        if (
            !competitorsResp?.competitiveSet?.brand ||
            !competitorsResp?.competitiveSet?.session?.competitors?.length
        )
            {
                return result;
            }

        // push hero brand onto brand options as first element
        result.push({
            label: competitorsResp?.competitiveSet?.brand?.name ?? '',
            value: competitorsResp?.competitiveSet?.brand?.brandKey ?? '',
        });

        // competitors
        const competitors =
            competitorsResp?.competitiveSet?.session?.competitors ?? [];

        competitors.sort(nameSort);

        // push competitors onto brand options
        competitors.forEach(
            (competitor: {
                name: string;
                brandKey: string
            }) => {
                result.push({
                    label: competitor.name,
                    value: competitor.brandKey,
                });
            }
        );

        return result;
    }, [competitorsResp]);
}
