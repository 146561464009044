import { GenericIconProps } from '../icons/types';

const LogoFacebookCircle = (props: GenericIconProps) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="11" cy="11" r="11" fill="#1877F2" />
    <path
      d="M15.4636 13.9363L15.9727 10.6533H12.7885V8.52272C12.7885 7.62454 13.2333 6.74906 14.6595 6.74906H16.1072V3.95401C16.1072 3.95401 14.7933 3.73218 13.5373 3.73218C10.9148 3.73218 9.2008 5.30451 9.2008 8.15103V10.6533H6.28577V13.9363H9.2008V21.873C9.78529 21.9637 10.3844 22.0109 10.9947 22.0109C11.6049 22.0109 12.204 21.9637 12.7885 21.873V13.9363H15.4636Z"
      fill="white"
    />
  </svg>
);

export default LogoFacebookCircle;
