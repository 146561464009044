import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';

import IconTriangleUp from '../../../assets/icons/IconTriangleUp';

import {
  IMPACT_RESULT_KEYS,
  IMPACT_RESULTS,
} from '../../../constants/recommendations';

import styles from './RecommendationImpactResult.module.scss';

const IMPACT_RESULTS_BY_KEY = keyBy(IMPACT_RESULTS, 'key');

export default function RecommendationImpactResult({
  className,
  impactResultKey,
  ...props
}) {
  return (
    <div
      className={classNames(
        styles.RecommendationImpactResult,
        impactResultKey === IMPACT_RESULT_KEYS.TACTICAL ? styles.ActNow : null,
        impactResultKey === IMPACT_RESULT_KEYS.STRATEGIC
          ? styles.ActSoon
          : null,
        impactResultKey === IMPACT_RESULT_KEYS.EVOLVE ? styles.ActLater : null,
        className
      )}
      {...props}
    >
      <div className={styles.NegativeFill} />
      <div className={styles.Marker}>
        <IconTriangleUp className={styles.Icon} />
        <span>{IMPACT_RESULTS_BY_KEY[impactResultKey].label}</span>
      </div>
    </div>
  );
}

RecommendationImpactResult.propTypes = {
  className: PropTypes.string,
  impactResultKey: PropTypes.number,
};
