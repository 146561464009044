/* eslint-disable react/jsx-no-useless-fragment */
import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import cn from 'classnames';
import { CreativeAsset } from '../../../../interfaces/creative';
import styles from './AssetTable.module.scss';
import {
  SeenFrom,
  LabelCell,
  NumberTransformCell,
  AssetNumberFormat,
  AssetFieldValue,
} from './AssetTableCells';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import NoResultsAlert from '../../../Atoms/NoResultsAlert/NoResultsAlert';

export interface AssetTableProps {
  data: CreativeAsset[];
  loading: boolean;
  onLabelClick: (creative: CreativeAsset) => void;
}

enum AssetTableColumns {
  CreativeName = 'name',
  SeenRange = 'seen_start',
  Channel = 'channel',
  Type = 'type',
  SharePercent = 'share',
  eCPM = 'eCPM',
  Impressions = 'impressions',
  Spend = 'spend',
}

const AssetTable = ({ data, loading, onLabelClick }: AssetTableProps) => {
  const isRightAligned: { [key: string]: boolean } = {
    seen_start: true,
    eCPM: true,
    impressions: true,
    spend: true,
  };

  const columns: ColumnDef<CreativeAsset, AssetFieldValue>[] = [
    {
      accessorKey: AssetTableColumns.CreativeName,
      header: 'Creative',
      cell: (cell) =>
        LabelCell({
          label: cell.getValue() ?? 'N/A',
          className: styles.CreativeName,
          onLabelClick: () => onLabelClick(cell.row.original as CreativeAsset),
        }),
    },
    {
      accessorKey: AssetTableColumns.SeenRange,
      header: 'Seen From',
      cell: (cell) => SeenFrom(cell),
    },
    {
      accessorKey: AssetTableColumns.Channel,
      header: 'Channel',
      cell: (cell) =>
        LabelCell({
          label: cell.getValue(),
          className: styles.ChannelType,
        }),
    },
    {
      accessorKey: AssetTableColumns.Type,
      header: 'Type',
      cell: (cell) =>
        LabelCell({
          label: cell.getValue(),
          className: styles.CellCapitalize,
        }),
    },
    {
      accessorKey: AssetTableColumns.SharePercent,
      header: '% Share',
      cell: (cell) =>
        NumberTransformCell(cell.getValue(), AssetNumberFormat.Percentage),
    },
    {
      accessorKey: AssetTableColumns.eCPM,
      header: 'CPM',
      cell: (cell) =>
        NumberTransformCell(
          cell.getValue(),
          AssetNumberFormat.Currency,
          styles.RightAlign
        ),
    },
    {
      accessorKey: AssetTableColumns.Impressions,
      header: 'Impressions',
      cell: (cell) =>
        NumberTransformCell(
          cell.getValue(),
          AssetNumberFormat.Default,
          styles.RightAlign
        ),
    },
    {
      accessorKey: AssetTableColumns.Spend,
      header: 'Spend',
      cell: (cell) =>
        NumberTransformCell(
          cell.getValue(),
          AssetNumberFormat.Currency,
          styles.RightAlign
        ),
    },
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {loading ? (
        <ComponentLoader />
      ) : (
        <>
          {!data.length ? (
            <NoResultsAlert text="No creatives available, adjust date range / filters" />
          ) : (
            <table className={styles.AssetTableContainer}>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className={styles.HeaderRow}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        <div
                          className={cn(
                            styles.HeaderCell,
                            isRightAligned[header.column.id]
                              ? styles.RightAlign
                              : ''
                          )}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className={styles.Row}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
};

export default AssetTable;
