import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './GenericErrorCopy.module.scss';

export default function GenericErrorCopy({ className, ...props }) {
  return (
    <p className={classNames(styles.GenericErrorCopy, className)} {...props}>
      An error has occured. Please try again or contact customer support.
    </p>
  );
}

GenericErrorCopy.propTypes = {
  className: PropTypes.string,
};
