import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  FlexibleXYPlot,
  AreaSeries,
  LineSeries,
  HorizontalGridLines,
  XAxis,
  ChartLabel,
} from 'react-vis';
import dayjs from 'dayjs';
import find from 'lodash/find';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

import Box from '@mui/material/Box';

import ChartLegend from '../ChartLegend/ChartLegend';

import useColdstartFiller from '../../../hooks/useColdstartFiller';

import PALETTES from '../../../constants/palettes';
import { CHART_LEGEND_ICON_VARIANT } from '../../../constants/props';
import colors from '../../../constants/colors';

import styles from './ArchetypeTimelineChart.module.scss';

const BUFFER_MULTIPLIER = 0.25;

export default function ArchetypeTimelineChart({
  className,
  theme = 'OCEAN',
  staticLegend = false,
  archetypes,
  topArchetype,
  topArchetypeTrend,
  comparingArchetype,
  comparingArchetypeTrend,
  onComparingArchetypeChange,
  ...props
}) {
  const archetypeOptions = useMemo(() => {
    if (!archetypes || staticLegend) {
      return;
    }

    const result = [];

    archetypes.forEach((item) => {
      result.push({
        label: item.name,
        id: item.id,
        variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
        color: PALETTES[theme][1],
      });
    });

    return result;
  }, [archetypes, staticLegend, theme]);

  const compareArchetypeColor = useMemo(() => {
    if (staticLegend) {
      return PALETTES[theme][1];
    }
    const currentOption = find(archetypeOptions, {
      id: comparingArchetype.id,
    });

    return currentOption?.color;
  }, [archetypeOptions, comparingArchetype, staticLegend, theme]);

  const yDomain = useMemo(() => {
    if (
      !topArchetypeTrend ||
      !topArchetypeTrend.points ||
      !topArchetypeTrend.points.length ||
      !comparingArchetypeTrend ||
      !comparingArchetypeTrend.points ||
      !comparingArchetypeTrend.points.length
    ) {
      return {
        min: Number.MAX_SAFE_INTEGER,
        max: -Number.MAX_SAFE_INTEGER,
        buffer: 0,
      };
    }
    const topArchetypeMin = minBy(topArchetypeTrend.points, 'y').y;
    const topArchetypeMax = maxBy(topArchetypeTrend.points, 'y').y;

    const comparingArchetypeMin = minBy(comparingArchetypeTrend.points, 'y').y;
    const comparingArchetypeMax = maxBy(comparingArchetypeTrend.points, 'y').y;

    const min = Math.min(topArchetypeMin, comparingArchetypeMin);
    const max = Math.max(topArchetypeMax, comparingArchetypeMax);

    const buffer = (max - min) * BUFFER_MULTIPLIER;

    return {
      min,
      max,
      buffer,
    };
  }, [topArchetypeTrend, comparingArchetypeTrend]);

  /* TOP ARCHETYPE COLD START */
  const topArchetypeFiller = useColdstartFiller({
    timelineData: topArchetypeTrend.points,
    yDomain,
  });

  const wholeTopArchetypeTimelineData = useMemo(() => {
    if (!topArchetypeFiller) {
      return topArchetypeTrend.points || [];
    }

    return topArchetypeFiller.concat(topArchetypeTrend.points);
  }, [topArchetypeTrend.points, topArchetypeFiller]);
  /* TOP ARCHETYPE COLD START END */

  /* COMPARING ARCHETYPE COLD START */
  const comparingArchetypeFiller = useColdstartFiller({
    timelineData: comparingArchetypeTrend.points,
    yDomain,
  });

  const wholeComparingArchetypeTimelineData = useMemo(() => {
    if (!comparingArchetypeFiller) {
      return comparingArchetypeTrend.points || [];
    }

    return comparingArchetypeFiller.concat(comparingArchetypeTrend.points);
  }, [comparingArchetypeTrend.points, comparingArchetypeFiller]);
  /* COMPARING ARCHETYPE COLD START END */

  return (
    <div
      className={classNames(styles.ArchetypeTimelineChart, className)}
      {...props}
    >
      <FlexibleXYPlot
        margin={{
          left: 50,
        }}
        yDomain={[yDomain.min - yDomain.buffer, yDomain.max + yDomain.buffer]}
      >
        <HorizontalGridLines />

        <AreaSeries
          data={wholeTopArchetypeTimelineData}
          color={PALETTES[theme][0].FILL}
          curve="curveMonotoneX"
        />

        <LineSeries
          data={wholeTopArchetypeTimelineData}
          color={PALETTES[theme][0].DEFAULT}
          curve="curveMonotoneX"
        />

        <AreaSeries
          data={wholeTopArchetypeTimelineData.slice(
            0,
            topArchetypeFiller.length
          )}
          color={colors.colorGray20}
          curve="curveMonotoneX"
        />

        <LineSeries
          data={wholeTopArchetypeTimelineData.slice(
            0,
            topArchetypeFiller.length
          )}
          color={colors.colorGray40}
          curve="curveMonotoneX"
        />

        <XAxis
          tickFormat={(time) => dayjs(time).format('l')}
          tickSizeInner={0}
          tickSizeOuter={6}
          tickTotal={Math.min(wholeTopArchetypeTimelineData.length, 5)}
        />

        <LineSeries
          data={wholeComparingArchetypeTimelineData}
          curve="curveMonotoneX"
          color={compareArchetypeColor.DEFAULT}
        />

        <LineSeries
          data={wholeComparingArchetypeTimelineData.slice(
            0,
            comparingArchetypeFiller.length
          )}
          curve="curveMonotoneX"
          color={colors.colorGray50}
        />

        <ChartLabel
          text="More"
          xPercent={0}
          yPercent={0}
          includeMargin={false}
          style={{
            textAnchor: 'middle',
            transform: 'translate(-30 30)',
          }}
        />

        <ChartLabel
          text="Less"
          xPercent={0}
          yPercent={1}
          includeMargin={false}
          style={{
            textAnchor: 'middle',
            transform: 'translate(-30 14)',
          }}
        />
      </FlexibleXYPlot>

      <Box pl={8}>
        {staticLegend ? (
          <ChartLegend
            items={[
              {
                variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                label: `${topArchetype.name} - Current`,
              },
              {
                variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                label: `${comparingArchetype.name} - Goal`,
              },
            ]}
          />
        ) : (
          <ChartLegend
            items={[
              {
                variant: CHART_LEGEND_ICON_VARIANT.DOT_LINE,
                label: `${topArchetype.name} (main archetype)`,
              },
            ]}
            filterableItems={archetypeOptions}
            value={comparingArchetype.id}
            onFilterChange={onComparingArchetypeChange}
          />
        )}
      </Box>
    </div>
  );
}

ArchetypeTimelineChart.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  archetypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  topArchetype: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  topArchetypeTrend: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        y: PropTypes.number,
      })
    ),
  }),
  comparingArchetype: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  comparingArchetypeTrend: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    points: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        y: PropTypes.number,
      })
    ),
  }),
  staticLegend: PropTypes.bool,
  archetypeGoal: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  onComparingArchetypeChange: PropTypes.func,
};
