import { useEffect, useState, useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useRouter from '../../../../hooks/useRouter';

import TemplateSelectorCard from './TemplateSelectorCard';
import CompetitiveSetSelector from './CompetitiveSetSelector';
import useDashboardAPI from '../../../../hooks/useDashboardAPI';

import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import {
  DashboardTemplateType,
  DashboardTemplate,
  DashboardTemplateResponse,
  TemplateSelectionData,
} from '../types';
import {
  DashboardConfigurationType,
  TimeRange,
} from '../../../../interfaces/dashboard-api';

import styles from './TemplateSelectorContainer.module.scss';
import { FeatureFlag } from '../../../../utils/featureFlags';

const TemplateSelectorContainer = () => {
  const { updateRoute } = useRouter();
  const { getDashboardTemplates, createDashboardTemplate } = useDashboardAPI();
  const flags = useFlags();

  const [showCompSetModal, setShowCompSetModal] = useState(false);
  const [templateData, setTemplateData] = useState<DashboardTemplateResponse>({
    templates: [],
    count: 0,
  });
  const [selectionData, setSelectionData] = useState<TemplateSelectionData>({
    competitiveSetKey: '',
    brandKey: '',
    brandName: '',
    selectedBrandKeys: [],
  });
  const [templateType, setTemplateType] = useState<string>('');

  const createCustomDashboard = useCallback(async () => {
    const params = {
      type_id: DashboardTemplateType.Custom,
    };

    const response = await createDashboardTemplate(params);
    return response;
  }, [createDashboardTemplate]);

  const fetchDashboardTemplates = useCallback(async () => {
    const TemplateTypeAccessLookup: Record<string, boolean> = {
      [DashboardConfigurationType.CMO]: flags[FeatureFlag.CMOPulseTemplate],
      [DashboardConfigurationType.SOCIAL]:
        flags[FeatureFlag.SocialPerformanceTemplate],
      [DashboardConfigurationType.CMO]: flags[FeatureFlag.CMOPulseTemplate],
      [DashboardConfigurationType.DIAGNOSTIC]:
        flags[FeatureFlag.DiagnosticReportTemplate],
      [DashboardConfigurationType.SOV]: flags[FeatureFlag.ShareOfVoiceTemplate],
      [DashboardConfigurationType.DEFAULT]: true,
      [DashboardConfigurationType.CUSTOM]: true,
    };

    const response = await getDashboardTemplates();
    const templates = (response?.templates ?? []).filter(
      (template) => TemplateTypeAccessLookup[template.type]
    );

    if (response) {
      setTemplateData({ templates, count: templates.length });
    }
  }, [flags, getDashboardTemplates]);

  const handleCreateDefaultDashboard = useCallback(async () => {
    const params = {
      timeRange: TimeRange['90_DAYS'],
      type_id: templateType,
      ...selectionData,
    };

    const response = await createDashboardTemplate(params);

    if (response?.id) {
      updateRoute({ pathname: `/dashboards/${response.id}` });
    }
  }, [createDashboardTemplate, selectionData, templateType, updateRoute]);

  const handleCardClick = useCallback(
    async (type: string) => {
      if (type === DashboardTemplateType.Custom) {
        const response = await createCustomDashboard();

        if (response?.id) {
          updateRoute({ pathname: `/dashboards/${response.id}` });
        }
      }

      setTemplateType(type);
      setShowCompSetModal(true);
    },
    [createCustomDashboard, updateRoute]
  );

  useEffect(() => {
    if (templateData?.templates?.length) return;

    fetchDashboardTemplates();
  }, [fetchDashboardTemplates, templateData]);

  return (
    <div className={styles.TemplateSelectorContainer}>
      {showCompSetModal && (
        <CompetitiveSetSelector
          handleClose={setShowCompSetModal}
          handleDataChange={setSelectionData}
          handleCreateDefaultDashboard={handleCreateDefaultDashboard}
        />
      )}

      {!showCompSetModal && templateData ? (
        templateData?.templates?.map((template: DashboardTemplate) => (
          <TemplateSelectorCard
            type={template.type}
            onCardClick={handleCardClick}
            key={template.type}
            title={template.title}
            description={template.summary}
          />
        ))
      ) : (
        <ComponentLoader />
      )}
    </div>
  );
};

export default TemplateSelectorContainer;
