import dayjs from 'dayjs';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';

import styles from './DatePickerCalendar.module.scss';
import '../../../styles/react-calendar.scss';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../assets/icons/DynamicCaret';

export default function DatePickerCalendar({
  className,
  disabled = false,
  onActiveStartDateChange,
  highlightedDates,
  ...props
}) {
  const highlightDateMap = (highlightedDates ?? []).reduce((obj, session) => {
    const startDay = dayjs(session.date).format('MM/DD/YYYY');
    // eslint-disable-next-line no-param-reassign
    obj[startDay] = session.variant;
    return obj;
  }, {});
  function generateTileClassName({ date }) {
    if (!highlightedDates) {
      return null;
    }

    const startDay = dayjs(date).format('MM/DD/YYYY');
    const highlighted = highlightDateMap[startDay];

    if (highlighted) {
      return `highlighted-${highlighted}`;
    }

    return null;
  }

  return (
    <Calendar
      className={classNames(
        className,
        styles.DatePickerCalendar,
        disabled ? styles.Disabled : null
      )}
      calendarType="US"
      prev2Label={null}
      next2Label={null}
      onActiveStartDateChange={disabled ? () => {} : onActiveStartDateChange}
      prevLabel={
        <div className={styles.NavigationCaret}>
          <DynamicCaretIcon
            direction={CaretDirection.left}
            className={classNames(
              styles.Caret,
              disabled ? styles.Disabled : null
            )}
          />
        </div>
      }
      nextLabel={
        <div className={styles.NavigationCaret}>
          <DynamicCaretIcon
            direction={CaretDirection.right}
            className={classNames(
              styles.Caret,
              disabled ? styles.Disabled : null
            )}
          />
        </div>
      }
      formatShortWeekday={(locale, date) => {
        return dayjs(date).format('dd')[0];
      }}
      minDetail="month"
      tileDisabled={() => disabled}
      tileClassName={generateTileClassName}
      {...props}
    />
  );
}

DatePickerCalendar.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onActiveStartDateChange: PropTypes.func,
  highlightedDates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.instanceOf(Date),
      variant: PropTypes.string,
    })
  ),
};
