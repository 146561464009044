import {
  AnchorHTMLAttributes,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import IconCirclePlus from '../../../../assets/icons/IconCirclePlus';
import IconCircleCheckSolid from '../../../../assets/icons/IconCircleCheckSolid';

import styles from './SelectAccordion.module.scss';
import { Group, Item } from '../types';
import { getMetrics } from '../../../../mocks/data/CustomizableDashboards/metrics';

export interface SelectAccordionProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  groups: Group[];
  selectedItemIds: Set<string | number>;
  expandOnDefault?: boolean;
  onItemSelect: (item: Item) => void;
  onGroupSelect?: (group: Group) => void;
}

const SelectAccordion: FunctionComponent<SelectAccordionProps> = ({
  className,
  groups,
  selectedItemIds,
  expandOnDefault = false,
  onItemSelect,
  onGroupSelect,
}) => {
  const [accordionExpandMap, setAccordionExpandMap] = useState<
    Record<string, boolean>
  >({});

  const metricsById = keyBy(getMetrics, 'id');

  useEffect(() => {
    if (!expandOnDefault || !isEmpty(accordionExpandMap)) return;

    const accordianExpandClone = structuredClone(accordionExpandMap);
    groups.forEach((group) => {
      const isSelected = some(group.items, (item) => {
        return selectedItemIds.has(item.id);
      });
      accordianExpandClone[group.id] = isSelected;
    });

    setAccordionExpandMap(accordianExpandClone);
  }, [accordionExpandMap, selectedItemIds, expandOnDefault, groups]);

  const toggleGroupExpansion = useCallback(
    (group: Group) => {
      const accordionExpandMapCopy = structuredClone(accordionExpandMap);

      accordionExpandMapCopy[group.id] = !accordionExpandMapCopy[group.id];

      setAccordionExpandMap(accordionExpandMapCopy);
    },
    [accordionExpandMap]
  );

  function isGroupSelected(group: Group) {
    return group.items.every((item) => selectedItemIds.has(item.id));
  }

  return (
    <div className={classNames(styles.SelectAccordion, className)}>
      {groups.map((group) => (
        <Accordion
          disableGutters
          key={group.id}
          classes={{
            root: styles.AccordionGroup,
            expanded: styles.ExpandedBorder,
          }}
          expanded={accordionExpandMap?.[group.id] ?? false}
        >
          <AccordionSummary
            classes={{ content: styles.AccordionSummaryContent }}
          >
            <div className={styles.GroupLine}>
              <div
                className={styles.GroupLeft}
                onClick={() => toggleGroupExpansion(group)}
              >
                {group.name}
              </div>

              {onGroupSelect && (
                <div className={styles.GroupRight}>
                  <div onClick={() => onGroupSelect?.(group)}>
                    {isGroupSelected(group) ? (
                      <IconCircleCheckSolid />
                    ) : (
                      <IconCirclePlus />
                    )}
                  </div>
                </div>
              )}
            </div>
          </AccordionSummary>

          <AccordionDetails classes={{ root: styles.AccordionDetails }}>
            <div className={styles.ItemsList}>
              {group.items.map((item) => (
                <div
                  className={styles.ItemLine}
                  onClick={() => onItemSelect(item)}
                  key={item.id}
                >
                  <div className={styles.ItemLeft}>
                    {metricsById[item.id]?.is_social_channel ? (
                      <p>{item.name}</p>
                    ) : (
                      item.name
                    )}
                  </div>
                  <div className={styles.ItemRight}>
                    {selectedItemIds.has(item.id) ? (
                      <IconCircleCheckSolid />
                    ) : (
                      <IconCirclePlus />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SelectAccordion;
