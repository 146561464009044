import { useCallback, useState } from 'react';
import type { Layout, Layouts } from 'react-grid-layout';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Button } from '@blueoceanai/react-component-library';

import NoWidgetImg from '../../../../assets/images/placeholder-boat.png';
import styles from './DashboardGridLayout.module.scss';
import WidgetContainer from '../WidgetContainer/WidgetContainer';
import SingleMetricWidget from '../Widgets/SingleMetricWidget/SingleMetricWidget';
import MetricGroupWidget from '../Widgets/MetricGroupWidget/MetricGroupWidget';
import ExcessShareOfVoice from '../Widgets/ExcessShareOfVoice/ExcessShareOfVoice';
import BlueScoreWidget from '../Widgets/BlueScoreWidget/BlueScoreWidget';
import SummaryTrendsWidget from '../Widgets/TrendingSummaryWidget/SummaryTrendsWidget';
import StackRankingWidget from '../Widgets/StackRankingWidget/StackRankingWidget';

import { DraggableHandleClass, WidgetMetaResponse } from '../types';
import {
  ICapabilities,
  WidgetType,
  TimeRange,
} from '../../../../interfaces/dashboard-api';

import 'react-grid-layout/css/styles.css';
import PaceWidget from '../Widgets/PaceWidget/PaceWidget';
import MappingWidget from '../Widgets/MappingWidget/MappingWidget';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export interface DashboardGridLayoutProps {
  // TODO: apply same treament as dashboard actions using map
  onDeleteWidget: (widgetId: string) => void;
  onEditWidget: (widgetId: string) => void;
  onWidgetContextualHelpClick: (widgetId: string) => void;
  onLayoutUpdate: (currentLayout: Layout[], allLayouts: Layouts) => void;
  widgetMap: Record<string, WidgetMetaResponse>;
  layout: Layout[];
  onClickAddWidget: (isOpen: boolean) => void;
  capabilities: ICapabilities;
  globalDateFilter: {
    endDate: Date;
    timeRange: TimeRange | null;
  };
}

const DashboardGridLayout = ({
  onDeleteWidget,
  onEditWidget,
  onWidgetContextualHelpClick,
  onLayoutUpdate,
  layout,
  widgetMap,
  onClickAddWidget,
  capabilities,
  globalDateFilter,
}: DashboardGridLayoutProps) => {
  const [error, setError] = useState<{ [key: string]: boolean }>({});

  const handleWidgetError = useCallback(
    (id: string) => {
      if (!error[id]) {
        setError((prevError) => ({
          ...prevError,
          [id]: true,
        }));
      }
    },
    [error]
  );

  const renderWidgets = (id: string) => {
    switch (widgetMap[id]?.type_id) {
      case WidgetType.MetricAnalysisV1:
        return (
          <SingleMetricWidget
            config={widgetMap[id]}
            globalDateFilter={globalDateFilter}
          />
        );
      case WidgetType.MetricCollectionV1:
        return (
          <MetricGroupWidget
            config={widgetMap[id]}
            globalDateFilter={globalDateFilter}
          />
        );
      case WidgetType.ExcessShareOfVoiceV1:
        return (
          <ExcessShareOfVoice
            handleError={handleWidgetError}
            config={widgetMap[id]}
          />
        );
      case WidgetType.MetricComparisonV1:
        return (
          <BlueScoreWidget
            config={widgetMap[id]}
            globalDateFilter={globalDateFilter}
          />
        );
      case WidgetType.SummaryTrendsV1:
        return (
          <SummaryTrendsWidget
            config={widgetMap[id]}
            globalDateFilter={globalDateFilter}
          />
        );
      case WidgetType.PaceAnalysisV1:
        return (
          <PaceWidget
            config={widgetMap[id]}
            globalDateFilter={globalDateFilter}
          />
        );
      case WidgetType.StackRankingV1:
        return (
          <StackRankingWidget
            config={widgetMap[id]}
            globalDateFilter={globalDateFilter}
          />
        );
      case WidgetType.VisualMapsV1:
        return (
          <MappingWidget
            config={widgetMap[id]}
            globalDateFilter={globalDateFilter}
          />
        );

      default:
        return <p>Unable to render, please refresh</p>;
    }
  };

  return (
    <div className={styles.DashboardGridLayout}>
      {layout.length ? (
        <ResponsiveReactGridLayout
          className={styles.GridLayout}
          onLayoutChange={onLayoutUpdate}
          verticalCompact
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          autoSize
          margin={{
            lg: [20, 20],
            md: [20, 20],
            sm: [20, 20],
            xs: [20, 20],
            xxs: [20, 20],
          }}
          draggableHandle={`.${DraggableHandleClass}`}
          isResizable={false}
        >
          {layout?.length &&
            layout?.map((widget) => {
              return (
                <div
                  className={styles.WidgetItem}
                  key={widget?.i}
                  data-grid={widget}
                >
                  <WidgetContainer
                    title={widgetMap[widget?.i]?.name}
                    onWidgetDeleteClick={onDeleteWidget}
                    onWidgetEditClick={onEditWidget}
                    onWidgetContextualHelpClick={onWidgetContextualHelpClick}
                    widgetId={widget?.i}
                    disableActions={error[widget?.i]}
                    capabilities={capabilities}
                  >
                    {renderWidgets(widget?.i)}
                  </WidgetContainer>
                </div>
              );
            })}
        </ResponsiveReactGridLayout>
      ) : (
        <div className={styles.Notification}>
          <div className={styles.NoWidgetMessage}>
            <span>A sea of possibilities awaits you...</span>
            <img
              className={styles.NoWidgetImg}
              src={NoWidgetImg}
              alt="To begin, please add a widget to this dashboard"
            />
            {capabilities?.can_add_widget && (
              <Button
                className={styles.AddWidget}
                onClick={() => onClickAddWidget(true)}
              >
                Add a Widget
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardGridLayout;
