import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Grid, Hidden, Box } from '@mui/material';
import { useLazyQuery } from '@apollo/client';

import InsightsCTA from '../../Molecules/InsightsCTA/InsightsCTA';
import ThemesLegend from '../../Molecules/ThemesLegend/ThemesLegend';
import ThemesCard from '../../Organisms/ThemesCard/ThemesCard';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import DownloadDropdown from '../../Molecules/DownloadDropdown/DownloadDropdown';
import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';

import {
  prepareInsight,
  prepareHeroThemes,
  prepareCompetitorThemes,
  prepareLandingThemes,
} from '../../../api/transforms/Pages/ContentAnalysisThemes';
import { GET_THEMES } from '../../../api/queries/Pages/ContentAnalysisThemes';
import BNContext from '../../../contexts/BNContext';

import styles from './ContentAnalysisThemesLanding.module.scss';

const ContentAnalysisThemesLanding = () => {
  const {
    competitiveSetID,
    currentSession: sessionKey,
    accountKey,
    timeframe,
  } = useContext(BNContext);

  const queryOptions = {
    variables: {
      id: competitiveSetID,
      sessionKey,
      accountKey,
      type: 4,
    },
  };

  const [themeData, setThemeData] = useState(null);
  const [insight, setInsight] = useState([]);
  const [heroThemes, setHeroThemes] = useState(null);
  const [competitorThemes, setCompetitorThemes] = useState(null);

  const [getThemes, { loading, error, data }] = useLazyQuery(
    GET_THEMES,
    queryOptions
  );

  useEffect(() => {
    if (!competitiveSetID || !sessionKey) {
      return;
    }
    getThemes();
    setInsight(['No Insights Available']);
  }, [getThemes, competitiveSetID, sessionKey]);

  useEffect(() => {
    setHeroThemes(prepareHeroThemes(data, timeframe));
    setCompetitorThemes(prepareCompetitorThemes(data, timeframe));
  }, [data, timeframe]);

  useEffect(() => {
    setThemeData(prepareLandingThemes(heroThemes, competitorThemes));
  }, [heroThemes, competitorThemes]);

  useEffect(() => {
    if (!data) return;

    if (!themeData) return;

    const orderedInsights = [];
    let orderedThemeNames = [];
    let flatThemeNames = [];

    flatThemeNames = `${[
      themeData
        .filter((theme) => theme[0].theme === 'rational')
        .map((b) => b[0].name)
        .sort(),
    ]},${[
      themeData
        .filter((theme) => theme[0].theme === 'emotional')
        .map((b) => b[0].name)
        .sort(),
    ]}`;

    orderedThemeNames = flatThemeNames.split(',');

    if (data?.competitiveSet?.session?.themeOverviewInsights) {
      const themeName = [];
      const rawInsight = [];
      const insightObj = {
        themeName,
        rawInsight,
      };
      let returnedInsight = ' ';

      data.competitiveSet.session.themeOverviewInsights.forEach((i) => {
        // eslint-disable-next-line
        returnedInsight = prepareInsight(i?.value)[0];
        insightObj.themeName.push(i.name);
        insightObj.rawInsight.push(returnedInsight);
      });

      // eslint-disable-next-line
      for (const j of orderedThemeNames) {
        let count = 0;
        insightObj.themeName.forEach((k) => {
          if (k === j) {
            orderedInsights.push(k);
            orderedInsights.push(insightObj.rawInsight[count]);
          }
          count++;
        });
      }

      if (orderedInsights[0]) {
        setInsight(orderedInsights);
      } else {
        setInsight(['No Insights Available']);
      }
    }
  }, [data, themeData, setInsight]);

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <div className={classNames(styles.ContentAnalysisThemesLanding)}>
      <Grid
        spacing={10}
        container
        className={classNames(styles.ContentAnalysisThemes, 'PageContainer')}
      >
        <Grid item xs={12} md={9}>
          <div id="content-analysis-themes-download-container">
            <Grid
              className={classNames(
                'PageTitleContainer',
                styles.TitleContainer
              )}
              container
            >
              <Grid item xs={12} md={11}>
                <h1 className={styles.Title}>
                  What conversation themes are happening around the brand?
                </h1>
              </Grid>
              <Hidden smDown>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  item
                  xs={1}
                >
                  <DownloadDropdown
                    targetId="content-analysis-themes-download-container"
                    padding={100}
                  />
                </Grid>
              </Hidden>
            </Grid>

            <Box className={`${styles.SectionDescription}`}>
              <p>
                Brand and Audience content analysis identifies the top narrative
                themes across your competitive set. Divided into Rational
                (functional) and Emotional (experience) value propositions, you
                will know exactly which messages to reinforce or re-imagine.
              </p>
              <p>
                Audience sentiment analysis lets us see how well your messaging
                is received by your audience.
              </p>
            </Box>
            <div
              className={classNames(styles.ChartContainer, 'SectionContainer')}
            >
              <Grid
                className={`${styles.ChartHeader} ${styles.ChartRow}`}
                container
              >
                <Grid
                  item
                  xs={3}
                  className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                >
                  <h5 className={`${styles.HeaderLabel}`}>Theme</h5>
                  <p className={`${styles.HeaderText}`}>
                    Emotional and rational themes for this category.
                  </p>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                >
                  <h5 className={`${styles.HeaderLabel}`}>Brand Volume</h5>
                  <p className={`${styles.HeaderText}`}>
                    How much is brand talking about these themes in their
                    content?
                  </p>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                >
                  <h5 className={`${styles.HeaderLabel}`}>Audience Volume</h5>
                  <p className={`${styles.HeaderText}`}>
                    How much is the audience talking about these themes in their
                    conversations?
                  </p>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={`${styles.ChartHeaderItem} ${styles.ChartRowItem}`}
                >
                  <h5 className={`${styles.HeaderLabel}`}>
                    Audience Sentiment
                  </h5>
                  <p className={`${styles.HeaderText}`}>
                    How does the audience feel about the brand in regards to the
                    specific theme?
                  </p>
                </Grid>
              </Grid>
              <div className={`${styles.ChartBody}`}>
                {loading && themeData === null ? (
                  <ComponentLoader minHeight={400} />
                ) : null}

                {!loading && themeData !== null
                  ? themeData
                      .filter((theme) => theme[0].theme === 'rational')
                      // potentially use nameSort here
                      .sort((a, b) => a[0].name.localeCompare(b[0].name))
                      .map((b) => (
                        <ThemesCard
                          key={`${b[0].name}-rational`}
                          variant="themes"
                          cardTheme={b[0].theme}
                          cardTitle={b[0].name}
                          data={b}
                        />
                      ))
                  : null}

                {!loading && themeData !== null
                  ? themeData
                      .filter((theme) => theme[0].theme === 'emotional')
                      // potentially use nameSort here
                      .sort((a, b) => a[0].name.localeCompare(b[0].name))
                      .map((b) => (
                        <ThemesCard
                          key={`${b[0].name}-emotional`}
                          variant="themes"
                          cardTheme={b[0].theme}
                          cardTitle={b[0].name}
                          data={b}
                        />
                      ))
                  : null}
              </div>
            </div>
            <Box mb={4}>
              <ThemesLegend />
            </Box>
            <Grid
              container
              alignItems="center"
              className={classNames('SubtitleContainer', 'remove-from-export')}
            >
              <Grid item xs={11}>
                <h2 className={styles.Subtitle}>
                  What Themes and Sentiment Answers:
                </h2>
              </Grid>
            </Grid>
            <Box
              mb={1}
              className={classNames(
                styles.SectionDescription,
                'remove-from-export'
              )}
            >
              <p className={styles.Paragraph}>
                What are the drivers of messaging differentiation in the
                category?
              </p>
              <p className={styles.Paragraph}>
                Who has captured the most audience mindshare and positive
                emotional sentiment?
              </p>
              <p className={styles.Paragraph}>
                Which messaging do you need to publish more of? Less of?
              </p>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <InsightsCTA
            loading={loading}
            insight={insight}
            data={data?.competitiveSet?.session?.recommendations}
            modalType="recommendations"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ContentAnalysisThemesLanding;
