import { FunctionComponent } from 'react';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import classNames from 'classnames';

import styles from './Label.module.scss';

export interface LabelProps extends TableCellProps {
  label: string;
}

const Label: FunctionComponent<LabelProps> = ({ className, label }) => {
  return (
    <TableCell className={classNames(styles.Label, className)}>
      {label}
    </TableCell>
  );
};

export default Label;
