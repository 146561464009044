import * as React from 'react';

function SvgArchetypesDiagramBig(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 755 755" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(0 1)">
        <path
          fill="#D3BC00"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M377.685 375.42V0c68.383.07 132.5 18.333 187.765 50.203L377.685 375.419z"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(419.933 50.569)"
        >
          <tspan x={2.027} y={77.1}>
            {'Rebel'}
          </tspan>
        </text>
        <g transform="translate(426.464 50.569)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#D3BC00"
            fillRule="nonzero"
            d="M22.124 9.482c6.982 0 12.642 5.66 12.642 12.642 0 6.982-5.66 12.642-12.642 12.642a12.589 12.589 0 01-7.873-2.75.74.74 0 01-.167-.135 12.62 12.62 0 01-4.602-9.757c0-6.982 5.66-12.642 12.642-12.642zM19.97 27.315l-.173.317a10.79 10.79 0 01-1.75 2.32 10.202 10.202 0 01-1.89 1.486 10.997 10.997 0 005.967 1.748 11.01 11.01 0 005.862-1.68 10.17 10.17 0 01-2.004-1.554 10.9 10.9 0 01-1.887-2.566 5.588 5.588 0 01-1.971.357c-.763 0-1.49-.153-2.154-.428zm9.572-13.397l-.214.105a8.809 8.809 0 00-2.229 1.61 9.248 9.248 0 00-1.532 2.05 5.61 5.61 0 012.176 4.44 5.61 5.61 0 01-2.26 4.505c.455.85.994 1.585 1.616 2.207a8.826 8.826 0 002.282 1.637 11.033 11.033 0 003.805-8.348c0-3.255-1.406-6.182-3.644-8.206zm-14.912.07l-.037.033a11.032 11.032 0 00-3.531 8.103c0 3.292 1.438 6.248 3.72 8.275a8.7 8.7 0 002.147-1.564c.731-.731 1.347-1.617 1.849-2.663l-.17.335a5.608 5.608 0 01-2.103-4.383c0-1.735.786-3.286 2.022-4.317a9.255 9.255 0 00-1.598-2.175 8.832 8.832 0 00-2.299-1.645zm7.494 4.097a4.038 4.038 0 102.11.595l-.037-.014a.794.794 0 01-.14-.085 3.981 3.981 0 00-1.933-.496zm0-7.023a11.01 11.01 0 00-6.145 1.863c.768.449 1.457.98 2.067 1.59a10.852 10.852 0 011.828 2.459 5.59 5.59 0 012.25-.469c.732 0 1.431.14 2.072.395.504-.904 1.1-1.7 1.786-2.385a10.27 10.27 0 012.184-1.66 11.017 11.017 0 00-6.042-1.793z"
          />
        </g>
        <ellipse
          cx={377.29}
          cy={376.5}
          stroke="#D8D8D8"
          strokeWidth={0.79}
          rx={376.895}
          ry={376.5}
        />
        <path
          fill="#009C68"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M376.21 375.814L50.947 188.024C83.965 131.1 131.479 83.61 188.446 50.599L376.21 375.814z"
        />
        <path
          fill="#C02C68"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M565.792 702.6L378.37 377.974l324.92 187.593c-33.07 56.777-80.576 104.127-137.497 137.033z"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(556.626 553.886)"
        >
          <tspan x={4.054} y={77.1}>
            {'Jester'}
          </tspan>
        </text>
        <g transform="translate(566.528 553.886)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#93438C"
            fillRule="nonzero"
            d="M34.766 20.722v.81c0 6.982-5.66 12.642-12.642 12.642-6.982 0-12.642-5.66-12.642-12.642v-.81h25.284zm-7.537 1.62H17.018v8.96a10.966 10.966 0 004.81 1.248l.296.004c1.842 0 3.58-.452 5.106-1.251l-.001-8.961zm-11.832 0H11.13l.022.255a11.008 11.008 0 004.244 7.666v-7.921zm17.72 0H28.85v7.922a11.008 11.008 0 004.241-7.632l.025-.29zM22.123 9.482a4.951 4.951 0 110 9.902 4.951 4.951 0 010-9.902zm0 1.62a3.331 3.331 0 100 6.662 3.331 3.331 0 000-6.662z"
          />
        </g>
        <path
          fill="#14958F"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M375.131 376.105H.395v.395c0-68.388 18.253-132.517 50.157-187.791l324.58 187.396z"
        />
        <path
          fill="#CD4E4C"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M378.082 376.895h376.103v-.395c0 68.632-18.383 132.975-50.499 188.383L378.082 376.895z"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.504}
          transform="translate(632.48 411.661)"
        >
          <tspan x={3.22} y={77.1}>
            {'Lover'}
          </tspan>
        </text>
        <g transform="translate(639.01 411.661)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#CD4E4C"
            fillRule="nonzero"
            d="M22.124 9.482l3.702 3.702h5.237v5.236l3.703 3.704-3.703 3.702v5.237h-5.237l-3.702 3.703-3.704-3.703h-5.235l-.001-5.237-3.702-3.702 3.702-3.703v-5.236l5.237-.001 3.703-3.702zm0 2.221l-3.052 3.052h-4.317v4.317l-3.052 3.052 3.052 3.05v4.318h4.316l3.053 3.052 3.052-3.052h4.316v-4.316l3.052-3.052-3.052-3.053v-4.316h-4.317l-3.051-3.052zm0 5.872a4.549 4.549 0 110 9.098 4.549 4.549 0 010-9.098zm0 1.57a2.978 2.978 0 100 5.957 2.978 2.978 0 000-5.956z"
          />
        </g>
        <path
          fill="#008FC1"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M376.498 376.895L50.894 564.883C18.84 509.581.466 445.38.395 376.895h376.103z"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(55.89 411.661)"
        >
          <tspan x={3.885} y={77.1}>
            {'Creator'}
          </tspan>
        </text>
        <g transform="translate(71.902 411.661)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#008FB4"
            fillRule="nonzero"
            d="M21.384 34.766v-.021c-6.638-.383-11.902-5.887-11.902-12.621 0-6.734 5.264-12.238 11.902-12.621v-.021h1.48v.02c6.637.384 11.902 5.888 11.902 12.622s-5.265 12.238-11.903 12.62v.022h-1.479zm0-6.195H13.01a11.152 11.152 0 008.373 4.691v-4.691zm9.853 0h-8.374v4.691a11.152 11.152 0 008.374-4.69zm-9.853-5.753H10.982a11.098 11.098 0 001.143 4.274h9.259v-4.274zm11.881 0H22.863v4.274h9.26a11.098 11.098 0 001.142-4.274zm-11.881-5.906H12.25a11.095 11.095 0 00-1.262 4.426h10.396v-4.426zm10.614 0h-9.135v4.426h10.396a11.095 11.095 0 00-1.261-4.426zm-10.614-5.927a11.148 11.148 0 00-8.196 4.448h8.196zm1.48 0l-.001 4.448h8.197a11.148 11.148 0 00-8.197-4.448z"
          />
        </g>
        <path
          fill="#CC7121"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M753.79 376.895v-.79H379.448l324.58-187.396c31.905 55.274 50.157 119.403 50.157 187.791"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(637.43 263.906)"
        >
          <tspan x={2.44} y={77.1}>
            {'Hero'}
          </tspan>
        </text>
        <g transform="translate(640.01 263.906)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#CC7121"
            fillRule="nonzero"
            d="M21.8 9.482l11.386 6.982v11.32L21.8 34.766 11.062 27.77V16.478l.337-.22a.756.756 0 01.011-.007L21.8 9.48zm5.034 15.85L21.97 28.25l-4.57-2.91-4.027 2.074 8.45 5.506 9.01-5.526-4-2.06zm-4.84-7.498l-3.796 2.418v3.744l3.796 2.418 4.056-2.432v-3.716l-4.056-2.432zm-9.417.352v7.875l4.105-2.114V20.3l-4.105-2.114zm19.093 0L27.565 20.3v3.647l4.105 2.114v-7.875zm-9.847-6.857l-8.45 5.506 4.028 2.073 4.57-2.91 4.864 2.916 3.999-2.06-9.01-5.525z"
          />
        </g>
        <path
          fill="#DF9E07"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M378.37 375.814L566.135 50.6c56.966 33.011 104.48 80.5 137.498 137.426L378.37 375.815z"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(541.453 129.582)"
        >
          <tspan x={2.931} y={77.1}>
            {'Magician'}
          </tspan>
        </text>
        <g transform="translate(563.367 129.582)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#DC9F00"
            fillRule="nonzero"
            d="M21.333 34.766v-.024c-6.614-.409-11.851-5.902-11.851-12.618 0-6.717 5.237-12.21 11.85-12.618v-.024h1.583v.024c6.614.409 11.851 5.902 11.851 12.618s-5.237 12.21-11.85 12.618l-.001.024h-1.582zm0-5.95h-8.016a11.045 11.045 0 008.016 4.34v-4.34zm9.597 0h-8.015v4.34a11.045 11.045 0 008.015-4.34zm-9.597-5.538h-10.21a10.98 10.98 0 001.19 3.956h9.02v-3.956zm11.791 0h-10.21v3.956h9.021a10.987 10.987 0 001.19-3.956zm-11.791-6.29h-9.007a11 11 0 00-1.254 4.708h10.26v-4.707zm10.59 0h-9.008v4.708h10.26a11 11 0 00-1.253-4.707zm-10.59-5.896a11.045 11.045 0 00-7.997 4.315h7.997zm1.582 0v4.315h7.996a11.045 11.045 0 00-7.996-4.315z"
          />
        </g>
        <path
          fill="#33519D"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M376.894 378.37V753l-6.227-.057c-65.906-1.134-127.689-19.168-181.194-49.949L376.894 378.37z"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(251.844 620.257)"
        >
          <tspan x={3.88} y={77.1}>
            {'Caregiver'}
          </tspan>
        </text>
        <g transform="translate(277.918 620.257)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#2F529D"
            fillRule="nonzero"
            d="M28.023 9.482v6.646l3.662 3.662-3.662 3.051v7.014l-3.29-1.918.001 6.561-.659.098a15.696 15.696 0 01-4.614 0l-.659-.098v-6.622l-3.29 2.013v-7.066l-3.66-3.3 3.66-3.41V9.481l6.256 4.378 6.255-4.378zm-4.835 12.947h-2.841V33.15a14.207 14.207 0 002.842 0l-.001-10.721zm3.29-9.98l-4.71 3.297-4.71-3.297v4.335l-2.92 2.72 2.92 2.632v4.997l1.744-1.068V17.32h5.931v8.827l1.745 1.018v-5.048l2.918-2.432-2.918-2.918v-4.32zm-3.29 6.416h-2.84l-.001 2.019h2.841v-2.019z"
          />
        </g>
        <path
          fill="#0080B9"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M376.21 377.974L188.788 702.6c-56.92-32.906-104.427-80.256-137.496-137.033L376.21 377.974z"
        />
        <text fill="#FFF" fontFamily="Helvetica" fontSize={20.544}>
          <tspan x={144.938} y={630.985}>
            {'Ruler'}
          </tspan>
        </text>
        <g transform="translate(147.756 553.886)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#0082C0"
            fillRule="nonzero"
            d="M22.124 14.222c4.554 0 8.653 2.374 12.268 7.048l.374.484-.374.484c-3.615 4.674-7.714 7.047-12.268 7.047s-8.654-2.373-12.268-7.047l-.374-.484.374-.484c3.614-4.674 7.714-7.048 12.268-7.048zm0 1.583c-3.866 0-7.401 1.961-10.633 5.949l.237.288c3.168 3.794 6.624 5.66 10.396 5.66 3.865 0 7.4-1.96 10.633-5.948-3.232-3.988-6.768-5.95-10.633-5.95zm0 1.561a4.388 4.388 0 110 8.775 4.388 4.388 0 010-8.775zm0 1.583a2.805 2.805 0 100 5.61 2.805 2.805 0 000-5.61z"
          />
        </g>
        <path
          fill="#94438B"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M377.685 378.37l187.422 324.624c-53.61 30.842-115.532 48.886-181.584 49.956l-5.838.047V378.37z"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(397.809 620.257)"
        >
          <tspan x={4.387} y={77.1}>
            {'Everyperson'}
          </tspan>
        </text>
        <g transform="translate(437.736 620.257)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#93438C"
            fillRule="nonzero"
            d="M22.16 10.272l.539.49c6.493 5.904 9.768 10.69 9.768 14.487 0 5.692-4.615 10.307-10.307 10.307-5.693 0-10.308-4.615-10.308-10.307 0-3.796 3.275-8.583 9.769-14.487l.539-.49zm0 12.54c-2.884 3.355-4.318 5.8-4.318 7.19 0 2.169 1.92 3.952 4.318 3.952 2.397 0 4.317-1.783 4.317-3.952 0-1.314-1.28-3.566-3.85-6.64zm0-10.37c-5.821 5.39-8.706 9.697-8.706 12.807a8.687 8.687 0 003.165 6.714 5.236 5.236 0 01-.38-1.961c0-2.016 1.78-4.94 5.32-8.94l.6-.679.857.97c3.37 3.851 5.064 6.682 5.064 8.649 0 .691-.135 1.353-.38 1.962a8.69 8.69 0 003.165-6.715c0-2.995-2.675-7.1-8.07-12.213z"
          />
        </g>
        <path
          fill="#90AC37"
          stroke="#FFF"
          strokeWidth={0.79}
          d="M376.895 375.42L189.13 50.203C242.82 19.24 304.865 1.123 371.057.05l5.838-.047V375.42z"
        />
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(54.52 263.906)"
        >
          <tspan x={0} y={77.1}>
            {'Innocent'}
          </tspan>
        </text>
        <g transform="translate(77.433 263.906)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#14958F"
            fillRule="nonzero"
            d="M21.94 34.765c-6.897-.098-12.458-5.72-12.458-12.641 0-6.982 5.66-12.642 12.642-12.642 6.982 0 12.642 5.66 12.642 12.642 0 6.982-5.66 12.642-12.642 12.642h-.16l-.01-.001zm6.733-12.08L23.327 33.12a11.065 11.065 0 008.373-5.457.175.175 0 01-.018-.025l-3.009-4.954zm-13.499-.014l-2.821 4.644a11.07 11.07 0 008.288 5.772l-5.467-10.416zm6.95-11.61c-6.11 0-11.062 4.953-11.062 11.063 0 1.185.186 2.327.531 3.397l3.682-6.06L21.99 32.26l6.565-12.812 3.95 6.502c.44-1.192.68-2.481.68-3.826 0-6.11-4.953-11.062-11.062-11.062zm0 4.96a4.06 4.06 0 110 8.119 4.06 4.06 0 010-8.12zm0 1.58a2.48 2.48 0 100 4.959 2.48 2.48 0 000-4.959z"
          />
        </g>
        <text
          fill="#FFF"
          fontFamily="Helvetica"
          fontSize={20.544}
          transform="translate(266.437 52.149)"
        >
          <tspan x={5.049} y={77.1}>
            {'Explorer'}
          </tspan>
        </text>
        <g transform="translate(287.4 52.149)">
          <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
          <path
            fill="#90AC37"
            fillRule="nonzero"
            d="M26.194 10.272c3.881 0 6.593 1.24 6.593 3.767v11.898c0 3.126-3.411 6.237-10.117 9.537a.837.837 0 01-.3.08h-.102a.79.79 0 01-.29-.076l-.01-.004-.705-.353c-6.24-3.169-9.411-6.17-9.411-9.184V14.039c0-2.527 2.711-3.767 6.592-3.767zm-4.669 1.588h-3.08c-3.106 0-5.005.868-5.005 2.18v11.897c0 2.148 2.663 4.714 8.085 7.533v-7.905h-4.133v-1.588h4.133V20.68h-2.63v-1.588h2.63V11.86zm4.67 0h-3.082v7.232h2.63v1.588h-2.63v3.297h4.134v1.588h-4.134v7.905c5.299-2.755 7.963-5.268 8.081-7.386l.005-.147V14.039c0-1.31-1.9-2.18-5.005-2.18z"
          />
        </g>
        <circle cx={378.475} cy={376.895} r={208.596} fill="#FFF" />
        <circle cx={378.475} cy={376.895} r={120.101} fill="#FFF" />
        <circle cx={378.475} cy={376.895} r={120.101} fill="#FFF" />
        <path
          fill="#DF9E07"
          d="M547.565 376.895c0-93.385-75.638-169.09-168.942-169.09l-.148 169.09h169.09z"
        />
        <path
          fill="#009C68"
          d="M209.386 376.895c0-93.385 75.638-169.09 168.942-169.09l.147 169.09H209.386z"
        />
        <path
          fill="#C02C68"
          d="M547.565 376.895c0 93.385-75.638 169.09-168.942 169.09l-.148-169.09h169.09z"
        />
        <path
          fill="#0080B9"
          d="M209.386 376.895c0 93.385 75.638 169.09 168.942 169.09l.147-169.09H209.386z"
        />
        <path
          fill="#FFF"
          d="M378.475 376.105v.79H209.386v-.79zm169.09 0v.79h-169.09v-.79zm-170.268 1.185h.776v169.09h-.776z"
        />
        <path fill="#FFF" d="M377.297 208.2h.776v169.09h-.776z" />
        <text fill="#FFF" fontFamily="Helvetica" fontSize={18.963}>
          <tspan x={402.179} y={315.301}>
            {'Leave'}
          </tspan>
          <tspan x={402.179} y={343.746}>
            {'Legacy'}
          </tspan>
        </text>
        <text fill="#FFF" fontFamily="Helvetica" fontSize={18.963}>
          <tspan x={402.179} y={419.599}>
            {'Pursue'}
          </tspan>
          <tspan x={402.179} y={448.044}>
            {'Connection'}
          </tspan>
        </text>
        <text fill="#FFF" fontFamily="Helvetica" fontSize={18.963}>
          <tspan x={289.497} y={315.301}>
            {'Explore'}
          </tspan>
          <tspan x={266.321} y={343.746}>
            {'Spirituality'}
          </tspan>
        </text>
        <text fill="#FFF" fontFamily="Helvetica" fontSize={18.963}>
          <tspan x={289.719} y={419.599}>
            {'Provide'}
          </tspan>
          <tspan x={277.08} y={448.044}>
            {'Structure'}
          </tspan>
        </text>
        <g>
          <text
            fill="#FFF"
            fontFamily="Helvetica"
            fontSize={20.544}
            transform="translate(149.126 129.582)"
          >
            <tspan x={0.715} y={77.1}>
              {'Sage'}
            </tspan>
          </text>
          <g transform="translate(151.496 129.582)">
            <circle cx={22.124} cy={22.124} r={22.124} fill="#FFF" />
            <path
              fill="#14958F"
              fillRule="nonzero"
              d="M22.014 10.272l11.742 6.262v11.18l-11.742 6.262-11.742-6.263V16.534l11.742-6.262zm-.826 13.104l-8.616 3.692 8.616 4.596v-8.288zm1.65-.05v8.337l8.668-4.622-8.667-3.714zm-10.915-4.628v6.85l7.994-3.424-7.994-3.426zm20.18.048l-7.88 3.377 7.88 3.378v-6.755zm-9.264-6.162v8.336l8.667-3.714-8.667-4.622zm-1.651-.001l-8.616 4.596 8.616 3.692v-8.288z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgArchetypesDiagramBig;
