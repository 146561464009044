/* eslint-disable no-console */
import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { CreativeScreenshot } from "../../../interfaces/creative";
import CreativeScreenshotCardContent from '../CreativeScreenshotCardContent/CreativeScreenshotCardContent';
import styles from './CreativeScreenshotCardWrapper.module.scss';
import ModalContainer from '../../Modals/ModalContainer';

export interface CreativeScreenshotCardWrapperProps {
  brand: {
    name: string;
    brandKey: string;
    logoUrl?: string;
  };
  screenshot?: CreativeScreenshot;
  isEmbedded?: boolean;
  isCompareView?: boolean;
  errorMsg?: string;
}

const formatReadableDate = (date: string | null) => {
  return dayjs(date).format('MMMM D, YYYY');
};

const CreativeScreenshotCardWrapper = ({
  brand,
  screenshot,
  isEmbedded = false,
  isCompareView = false,
  errorMsg = 'No screenshots available for this time period.',
}: CreativeScreenshotCardWrapperProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const handleModalClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  let formattedDate = '';
  if (screenshot?.date) {
    formattedDate = formatReadableDate(screenshot.date);
  }

  return (
    <div
      className={classNames(styles.CreativeScreenshotCardWrapper)}
    >
      <CreativeScreenshotCardContent
        brand={brand}
        date={formattedDate}
        assetUrl={screenshot?.assetUrl}
        configUrl={screenshot?.configUrl}
        errorMsg={errorMsg}
        handleModalOpen={handleModalOpen}
        isEmbedded={isEmbedded}
        isCompareView={isCompareView}
      />
      <ModalContainer isOpen={isOpen} closeModal={handleModalClose} className={styles.ScreenshotCardModal}>
        {/* TODO: consider adding download icon, late change req */}
        <CreativeScreenshotCardContent
          brand={brand}
          date={formattedDate}
          assetUrl={screenshot?.assetUrl}
          configUrl={screenshot?.configUrl}
          errorMsg={errorMsg}
          handleModalOpen={handleModalOpen}
          isEmbedded
          imgOnly
          isCompareView={isCompareView}
        />
      </ModalContainer>
    </div>
  );
};

export default CreativeScreenshotCardWrapper;
