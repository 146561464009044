import { AnchorHTMLAttributes } from 'react';
import { CloseIcon } from '../../../../../assets/icons';
import { MetricDetail } from '../../types';
import styles from './TextStepGuide.module.scss';

export interface TextStepGuideProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  text: string;
  showMetrics: boolean;
  metrics: MetricDetail[];
  onMetricClick?: () => void;
}

const defaultMetricClick = () => {};

const TextStepGuide = ({
  text,
  showMetrics,
  metrics,
  onMetricClick = defaultMetricClick,
}: TextStepGuideProps) => {
  return (
    <div className={styles.TextStepGuide}>
      <p className={styles.StepText}>{text}</p>
      {showMetrics && (
        <div className={styles.MetricsContainer}>
          <p className={styles.Title}>
            METRIC
            <span className={styles.Required}>*</span>
          </p>
          {metrics.length ? (
            <>
              {metrics.map((metric) => (
                <button
                  key={metric.id}
                  type="button"
                  onClick={() => onMetricClick()}
                  className={styles.Metric}
                >
                  <p>{metric.name}</p>
                  <CloseIcon className={styles.Icon} />
                </button>
              ))}
            </>
          ) : (
            <p className={styles.SubTitle}>No metrics selected</p>
          )}
        </div>
      )}
    </div>
  );
};

export default TextStepGuide;
