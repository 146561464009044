import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Layout } from 'react-grid-layout';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ActionsDropdown, {
  ActionDropdownItem,
} from '../ActionsDropdown/ActionsDropdown';
import {
  DashboardActionHandler,
  DashboardActionType,
  DashboardResponse,
  ShareableDashboard,
  UserInfo,
  WidgetMetaResponse,
  WidgetSettingsModalAction,
} from '../types';
import useDashboardAPI from '../../../../hooks/useDashboardAPI';
import useRouter from '../../../../hooks/useRouter';
import ModalConfirm from '../../../Molecules/ModalConfirm/ModalConfirm';
import DashboardGridLayout from '../DashboardGridLayout/DashboardGridLayout';
import ModalContainer from '../../../Modals/ModalContainer';
import ShareDashboardModal from '../Modals/ShareDashboardModal/ShareDashboardModal';
import RevokeAccessModal from '../Modals/ShareDashboardModal/RevokeAccessModal';
import DateRangeSelectorDropdown from '../DateRangeSelectorDropdown/DateRangeSelectorDropdown';
import {
  WidgetContextualHelpDrawer,
  WidgetLibraryModal,
  WidgetSettingsModal,
} from '../Modals';
import styles from './DashboardOverview.module.scss';
import TitleEditor from '../TitleEditor/TitleEditor';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../../assets/icons/DynamicCaret';
import {
  WidgetType,
  TimeRange,
  UpdateWidgetSettings,
} from '../../../../interfaces/dashboard-api';
import { FeatureFlag } from '../../../../utils/featureFlags';
import ComponentLoader from '../../../Atoms/ComponentLoader/ComponentLoader';
import { CloseIcon, SaveIcon } from '../../../../assets/icons';
import { timeRangeValueMap } from '../DateRangeSelector/DateRangeSelector';

enum ModalConfirmType {
  DeleteDashboard = 'deleteDashboard',
  SaveGlobalFilters = 'saveGlobalFilters',
}

const DashboardOverview = () => {
  const { id } = useParams();
  const { updateRoute } = useRouter();
  const [globalDateFilter, setGlobalDateFilter] = useState<{
    endDate: Date;
    timeRange: TimeRange | null;
  }>({
    endDate: new Date(),
    timeRange: null,
  });
  const [dashboard, setDashboard] = useState<ShareableDashboard | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isWidgetContextualHelpOpen, setIsWidgetContextualHelpOpen] =
    useState<boolean>(false);
  const [activeContextualHelpWidgetId, setActiveContextualHelpWidgetId] =
    useState<string>('');
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isRevokeAccessModalOpen, setIsRevokeAccessModalOpen] =
    useState<boolean>(false);
  const [errorOccurred, setErrorOccurred] = useState<boolean>(false);
  const [triggerRequery, setTriggerRequery] = useState<boolean>(false);

  const [modalInfo, setModalInfo] = useState<{
    title: string;
    helper: string;
    message: string;
    confirmType: ModalConfirmType;
  }>({
    title: '',
    message: '',
    helper: '',
    confirmType: ModalConfirmType.DeleteDashboard,
  });
  const [isWidgetLibraryModalOpen, setIsWidgetLibraryModalOpen] =
    useState<boolean>(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [widgetSettings, setWidgetSettings] =
    useState<WidgetMetaResponse | null>(null);
  const [widgetSettingsAction, setWidgetSettingsAction] =
    useState<WidgetSettingsModalAction>(WidgetSettingsModalAction.Edit);
  const flags = useFlags();

  const {
    getDashboard,
    deleteDashboard,
    duplicateDashboard,
    updateDashboardLastViewed,
    addWidgetToDashboard,
    updateWidgetLayout,
    deleteWidgetFromDashboard,
    updateDashboardTitle,
    updateWidgetInstances,
    shareDashboard,
    duplicateSharedDashboard,
    updateReceivedDashboardLastViewed,
    revokeSharingAccess,
  } = useDashboardAPI();

  const isNonOwnedDashboardShared =
    dashboard?.isDashboardShared && !dashboard?.isCurrentUserOwner;

  const deleteMessage = useMemo(() => {
    return `Are you sure you want to delete this dashboard: ${
      dashboard?.name || 'dashboard'
    }?`;
  }, [dashboard]);

  const openConfirmModal = () => setIsConfirmOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);

  const refreshDashboard = useCallback(() => {
    if (!id || errorOccurred) return;

    setLoading(true);

    getDashboard(id)
      .then((dashboardResp) => {
        setDashboard(dashboardResp);
        setLoading(false);
        if (dashboardResp) {
          if (
            dashboardResp.isCurrentUserOwner &&
            dashboardResp.isDashboardShared
          ) {
            // Both owner and shared dashboard, update both last viewed
            updateDashboardLastViewed(id);
            updateReceivedDashboardLastViewed(id);
          } else if (dashboardResp.shared_with?.length) {
            // Shared dashboard, update received dashboard last viewed
            updateReceivedDashboardLastViewed(id);
          } else {
            // Non-shared dashboard, update last viewed
            updateDashboardLastViewed(id);
          }
        }
      })
      .catch(() => {
        setIsRevokeAccessModalOpen(true);
        setErrorOccurred(true);
        setLoading(false);
      });
  }, [
    id,
    errorOccurred,
    getDashboard,
    updateDashboardLastViewed,
    updateReceivedDashboardLastViewed,
  ]);

  const handleCloseRevokeAccessModal = () => {
    setIsRevokeAccessModalOpen(false);
    updateRoute({ pathname: '/dashboards' });
  };

  const handleManageDashboard = useCallback(() => {
    if (!dashboard) return;
    updateRoute({ pathname: '/dashboards' });
  }, [dashboard, updateRoute]);

  const handleConfirmDeleteDashboard = useCallback(() => {
    if (!dashboard) return;

    if (isNonOwnedDashboardShared) {
      setModalInfo({
        title: 'You are about to remove this dashboard',
        message: `Are you sure you want to remove this dashboard: ${
          dashboard?.name || 'dashboard'
        }?`,
        helper:
          'You will no longer be able to view this dashboard and will need to contact the dashboard owner to re-gain access.',
        confirmType: ModalConfirmType.DeleteDashboard,
      });
      openConfirmModal();
    } else {
      setModalInfo({
        title: 'You are about to delete this dashboard',
        message: deleteMessage,
        helper: 'This action cannot be undone.',
        confirmType: ModalConfirmType.DeleteDashboard,
      });
    }

    openConfirmModal();
  }, [dashboard, deleteMessage, isNonOwnedDashboardShared]);

  const handleDeleteDashboard = useCallback(async () => {
    if (!dashboard) return;

    const isDeleted = await deleteDashboard(dashboard.id);
    if (isDeleted) {
      updateRoute({ pathname: '/dashboards' });
    }
  }, [dashboard, deleteDashboard, updateRoute]);

  const handleDeleteSharedDashboard = useCallback(async () => {
    if (!dashboard) return;

    const isDeleted = await revokeSharingAccess(dashboard.id);
    if (isDeleted) {
      updateRoute({ pathname: '/dashboards' });
    }
  }, [dashboard, revokeSharingAccess, updateRoute]);

  const handleDuplicateDashboard = useCallback(async () => {
    if (!dashboard) return;

    if (dashboard.isDashboardShared) {
      duplicateSharedDashboard(dashboard.id)
        .then((createdDashboard) => {
          const canNavigate = !isEmpty(createdDashboard);
          if (canNavigate) {
            setDashboard(null);
            updateRoute({
              pathname: `/dashboards/${createdDashboard.id}`,
            });
          }
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    } else {
      duplicateDashboard(dashboard.id)
        .then((createdDashboard) => {
          const canNavigate = !isEmpty(createdDashboard);
          if (canNavigate) {
            setDashboard(null);
            updateRoute({
              pathname: `/dashboards/${createdDashboard.id}`,
            });
          }
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    }
  }, [dashboard, duplicateDashboard, duplicateSharedDashboard, updateRoute]);

  const handleShareDashboard = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  const handleShareSubmit = async (
    dashboardId: string,
    invitees: UserInfo[]
  ) => {
    const response = await shareDashboard(
      dashboardId,
      dashboard?.ownerName,
      invitees
    );

    if (response) setTriggerRequery(true);
  };

  const closeConfirmModal = () => setIsConfirmOpen(false);
  const handleWidgetLibraryModalClose = () =>
    setIsWidgetLibraryModalOpen(false);

  const ActionHandlerStrategy: Record<
    DashboardActionType,
    DashboardActionHandler
  > = {
    [DashboardActionType.Duplicate]: handleDuplicateDashboard,
    [DashboardActionType.Delete]: handleConfirmDeleteDashboard,
    [DashboardActionType.Share]: handleShareDashboard,
  };

  const handleActionClick = (item: ActionDropdownItem) => {
    ActionHandlerStrategy[item.value as DashboardActionType]?.(item.label);
  };

  const dashboardActions = useMemo(() => {
    const actions: ActionDropdownItem[] = [];

    if (dashboard?.capabilities?.can_duplicate) {
      actions.push({
        label: 'Duplicate Dashboard',
        value: DashboardActionType.Duplicate,
      });
    }

    if (dashboard?.capabilities?.can_delete || isNonOwnedDashboardShared) {
      actions.push({
        label: isNonOwnedDashboardShared
          ? 'Remove Dashboard'
          : 'Delete Dashboard',
        value: DashboardActionType.Delete,
      });
    }

    if (dashboard?.capabilities.can_share) {
      actions.push({
        label: 'Share Dashboard',
        value: DashboardActionType.Share,
      });
    }

    return actions;
  }, [
    dashboard?.capabilities?.can_delete,
    dashboard?.capabilities?.can_duplicate,
    dashboard?.capabilities.can_share,
    isNonOwnedDashboardShared,
  ]);

  useEffect(() => {
    if (dashboard && !triggerRequery) return;

    refreshDashboard();
    setTriggerRequery(false);
  }, [dashboard, refreshDashboard, triggerRequery]);

  const handleDeleteWidget = async (widgetId: string) => {
    if (!id || !widgetId) return;

    const dashboardResp = await deleteWidgetFromDashboard({
      dashboardId: id,
      widgetId,
    });

    setDashboard(dashboardResp);
  };

  const handleWidgetSettingsClose = async () => {
    setWidgetSettings(null);
    setIsSettingsOpen(false);
  };

  const handleWidgetSettingsCancel = async (
    widgetId: string,
    action: WidgetSettingsModalAction
  ) => {
    // if widget settings was never saved, delete the widget
    if (action === WidgetSettingsModalAction.Add) {
      await handleDeleteWidget(widgetId);
    }

    handleWidgetSettingsClose();
  };

  const handleAddWidget = async (widgetId: string) => {
    if (!widgetId || !id) return;

    const dashboardResp = await addWidgetToDashboard({
      dashboardId: id,
      widgetType: widgetId,
    });

    setDashboard(dashboardResp);
    handleWidgetLibraryModalClose();

    if (dashboardResp?.widgets) {
      let newWidgetId;

      // find the new widget that was just added
      Object.keys(dashboardResp.widgets).forEach((currentWidgetId) => {
        const widget = dashboardResp.widgets[currentWidgetId];

        if (widget.type_id === widgetId && isEmpty(widget.data_srcs)) {
          newWidgetId = currentWidgetId;
        }
      });

      if (!newWidgetId) return;

      const settings = dashboardResp.widgets[newWidgetId];
      setWidgetSettings(settings);
      setWidgetSettingsAction(WidgetSettingsModalAction.Add);
      setIsSettingsOpen(true);
    }
  };

  const handleContextualHelpIconClick = (widgetId: string) => {
    setActiveContextualHelpWidgetId(widgetId);
    setIsWidgetContextualHelpOpen(true);
  };

  const handleLayoutUpdate = async (currentLayout: Layout[]) => {
    if (!id) return;

    if (!dashboard?.capabilities?.can_edit) {
      return;
    }

    const newWidgetLayoutById: Record<string, string> = {};

    currentLayout.forEach((layout) => {
      newWidgetLayoutById[layout.i] = JSON.stringify({
        h: layout.h,
        w: layout.w,
        x: layout.x,
        y: layout.y,
      });
    });

    const dashboardResp = await updateWidgetLayout({
      dashboardId: id,
      newWidgetLayout: newWidgetLayoutById,
    });

    setDashboard(dashboardResp);
  };

  const handleWidgetSettingsSaveSuccess = () => {
    setDashboard(null);
  };

  const handleDashboardNameChange = async (title: string) => {
    if (!id) return;

    const updateSuccessful = await updateDashboardTitle(id, title);
    // TODO: handle error / success state
    if (!updateSuccessful) {
      // eslint-disable-next-line no-console
      console.error('Unable to update dashboard title');
    }

    refreshDashboard();
  };

  const widgetsLayout: Layout[] = useMemo(() => {
    if (!dashboard?.widgets) return [];

    const widgetRespList = Object.values(dashboard.widgets).map(
      (widget) => widget
    );

    const defaultLayout = {
      // TODO: get heights and width from widget template / widget meta
      h: 2,
      w: 2,
      x: 0,
      y: -Infinity,
    };

    try {
      return widgetRespList.map((widget) => {
        let parsedLayout = defaultLayout;

        if (widget.layout) {
          parsedLayout = JSON.parse(widget.layout);

          // TODO: use constant if negative infinity is used elsewhere from dashboard api
          if (String(parsedLayout.y) === '@@NEGATIVE_INFINITY@@') {
            parsedLayout.y = -Infinity;
          }
        }

        return {
          i: widget.id,
          ...parsedLayout,
        };
      });
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert((e as Error).message);
    }

    return [];
  }, [dashboard?.widgets]);

  const widgetsById = useMemo(() => {
    if (!dashboard?.widgets) return {};

    const widgetsTemp = dashboard.widgets;

    if (!isEmpty(widgetsTemp)) {
      Object.keys(widgetsTemp).forEach((currentWidgetId) => {
        switch (widgetsTemp[currentWidgetId].type_id) {
          case flags[FeatureFlag.ViewMetricAnalysisV1] &&
            WidgetType.MetricAnalysisV1:
            break;
          case flags[FeatureFlag.ViewMetricCollectionV1] &&
            WidgetType.MetricCollectionV1:
            break;
          case flags[FeatureFlag.ViewExcessShareOfVoiceV1] &&
            WidgetType.ExcessShareOfVoiceV1:
            break;
          case flags[FeatureFlag.ViewSummaryTrendsV1] &&
            WidgetType.SummaryTrendsV1:
            break;
          case flags[FeatureFlag.ViewPaceAnalysisV1] &&
            WidgetType.PaceAnalysisV1:
            break;
          case flags[FeatureFlag.ViewMetricComparisonV1] &&
            WidgetType.MetricComparisonV1:
            break;
          case flags[FeatureFlag.ViewStackRankingV1] &&
            WidgetType.StackRankingV1:
            break;
          case flags[FeatureFlag.ViewVisualMapsV1] && WidgetType.VisualMapsV1:
            break;

          default:
            delete widgetsTemp[currentWidgetId];
        }
      });
    }

    return widgetsTemp;
  }, [dashboard?.widgets, flags]);

  const handleEditWidget = (widgetId: string) => {
    setWidgetSettingsAction(WidgetSettingsModalAction.Edit);
    setIsSettingsOpen(true);
    const settings = widgetsById[widgetId];
    setWidgetSettings(settings);
  };

  interface WidgetInstanceCustomDateRange {
    time_range: string;
    input: {
      endDate: string;
    };
  }

  const handleGlobalDateFilterApply = (e: {
    endDate: Date;
    timeRange: TimeRange | null;
  }) => {
    setGlobalDateFilter(e);
  };

  const saveGlobalDateFilter = useCallback(() => {
    setModalInfo({
      title: 'Save this configuration to new dashboard?',
      message:
        'When saving filters a duplicate dashboard will be created with the filter settings chosen applied at the individual widget level.',
      helper: '',
      confirmType: ModalConfirmType.SaveGlobalFilters,
    });
    openConfirmModal();
  }, []);

  const saveGlobalFiltersToNewDashboard = useCallback(
    async (newDashboard: DashboardResponse) => {
      // iterate through all widget instances except for ESOV and update their timeRange and endDate
      if (newDashboard?.widgets) {
        const newPayload: Record<string, UpdateWidgetSettings> =
          structuredClone(newDashboard?.widgets);

        Object.keys(newPayload).forEach((currentWidgetId) => {
          const widget = newPayload[currentWidgetId];

          if (widget.type_id === WidgetType.ExcessShareOfVoiceV1) {
            return;
          }

          if (widget.type_id === WidgetType.MetricCollectionV1) {
            if (!widget.data_srcs) {
              return;
            }

            (
              widget.data_srcs as unknown as {
                metrics: WidgetInstanceCustomDateRange;
              }
            ).metrics.time_range =
              globalDateFilter.timeRange ?? TimeRange['90_DAYS'];
            (
              widget.data_srcs as unknown as {
                metrics: WidgetInstanceCustomDateRange;
              }
            ).metrics.input.endDate = globalDateFilter.endDate.toISOString();
          } else {
            if (!widget.data_srcs) {
              return;
            }

            (
              widget.data_srcs as unknown as {
                metric: WidgetInstanceCustomDateRange;
              }
            ).metric.time_range =
              globalDateFilter.timeRange ?? TimeRange['90_DAYS'];
            (
              widget.data_srcs as unknown as {
                metric: WidgetInstanceCustomDateRange;
              }
            ).metric.input.endDate = globalDateFilter.endDate.toISOString();
          }
        });

        await updateWidgetInstances({
          dashboardId: newDashboard.id,
          widgets: newPayload,
        });

        if (!dashboard) {
          return;
        }

        const formattedDateRange = `${dayjs(globalDateFilter.endDate)
          .subtract(
            timeRangeValueMap[
              globalDateFilter.timeRange ?? TimeRange['90_DAYS']
            ],
            'days'
          )
          .format('MMM D, YYYY')} - ${dayjs(globalDateFilter.endDate).format(
          'MMM D, YYYY'
        )}`;

        await updateDashboardTitle(
          newDashboard.id,
          `${dashboard.name} [${formattedDateRange}]`
        );
        closeConfirmModal();
        updateRoute({
          pathname: `/dashboards/${newDashboard.id}`,
        });
        refreshDashboard();
      }
    },
    [
      globalDateFilter,
      updateRoute,
      updateWidgetInstances,
      dashboard,
      updateDashboardTitle,
      refreshDashboard,
    ]
  );

  const handleModalConfirm = useCallback(async () => {
    if (modalInfo.confirmType === ModalConfirmType.DeleteDashboard) {
      // if dashboard is shared and doesnt belong to user, call revokeAccess endpoint
      if (isNonOwnedDashboardShared) {
        handleDeleteSharedDashboard();
      } else {
        handleDeleteDashboard();
      }
    }

    if (modalInfo.confirmType === ModalConfirmType.SaveGlobalFilters) {
      if (!dashboard) {
        return;
      }

      const createdDashboard = await duplicateDashboard(dashboard.id);
      if (!createdDashboard) {
        return;
      }

      await saveGlobalFiltersToNewDashboard(createdDashboard);
    }
  }, [
    modalInfo.confirmType,
    isNonOwnedDashboardShared,
    handleDeleteSharedDashboard,
    handleDeleteDashboard,
    dashboard,
    duplicateDashboard,
    saveGlobalFiltersToNewDashboard,
  ]);

  return (
    <div className={styles.DashboardPage}>
      <div className={styles.Header}>
        <div className={styles.HeaderText}>
          <button
            type="button"
            className={styles.ReturnToDashboardList}
            onClick={handleManageDashboard}
          >
            <DynamicCaretIcon
              className={styles.CaretIcon}
              direction={CaretDirection.left}
            />
            <div className={styles.ReturnToDashboardText}>Dashboards</div>
          </button>

          <TitleEditor
            disabled={!dashboard?.capabilities?.can_edit}
            title={dashboard?.name}
            className={styles.DashboardTitle}
            handleTitleChange={handleDashboardNameChange}
            key={dashboard?.name}
          />
        </div>

        <div className={styles.Actions}>
          <p className={styles.OwnerText}>Owned By {dashboard?.ownerName}</p>

          {dashboard?.capabilities?.can_add_widget !== undefined &&
            (dashboard.capabilities.can_add_widget ? (
              <button
                type="button"
                className={styles.AddWidget}
                onClick={() => setIsWidgetLibraryModalOpen(true)}
              >
                Add Widget
              </button>
            ) : (
              <p className={styles.Badge}>View Only</p>
            ))}

          <ActionsDropdown
            actions={dashboardActions}
            handleOnClick={handleActionClick}
          />
        </div>
      </div>

      {/* Modals */}
      {dashboard?.id && (
        <ModalContainer
          isOpen={isWidgetLibraryModalOpen}
          closeModal={handleWidgetLibraryModalClose}
        >
          <WidgetLibraryModal onAddWidget={handleAddWidget} />
        </ModalContainer>
      )}

      <ModalConfirm
        open={isConfirmOpen}
        onCancel={closeConfirmModal}
        onConfirm={handleModalConfirm}
        onClose={closeConfirmModal}
        confirmText="Confirm"
        className=""
        {...modalInfo}
      />

      {flags[FeatureFlag.ViewWidgetCustomDateSelector] && (
        <div className={styles.GlobalFiltersContainer}>
          <div className={styles.GlobalFilters}>
            <div className={styles.FilterHeader}>Add Filter:</div>
            <DateRangeSelectorDropdown
              timeRange={globalDateFilter.timeRange ?? null}
              endDate={globalDateFilter.endDate}
              onApply={handleGlobalDateFilterApply}
              classes={{ dropdownButton: styles.DateRangeDropdownButton }}
            />
          </div>

          {globalDateFilter.endDate && globalDateFilter.timeRange && (
            <div className={styles.FilterActions}>
              <button
                type="button"
                className={styles.FilterActionButton}
                onClick={() =>
                  setGlobalDateFilter({ endDate: new Date(), timeRange: null })
                }
              >
                <CloseIcon className={styles.ActionIcon} />
                <span>Clear All</span>
              </button>

              <button
                type="button"
                className={styles.FilterActionButton}
                onClick={saveGlobalDateFilter}
              >
                <SaveIcon className={styles.ActionIcon} />
                <span>Save</span>
              </button>
            </div>
          )}
        </div>
      )}

      {loading && <ComponentLoader className={styles.Loader} />}
      {!loading && dashboard && (
        <DashboardGridLayout
          onDeleteWidget={handleDeleteWidget}
          onEditWidget={handleEditWidget}
          onWidgetContextualHelpClick={handleContextualHelpIconClick}
          onLayoutUpdate={handleLayoutUpdate}
          layout={widgetsLayout}
          widgetMap={widgetsById}
          onClickAddWidget={setIsWidgetLibraryModalOpen}
          capabilities={dashboard.capabilities}
          globalDateFilter={globalDateFilter}
        />
      )}

      <WidgetContextualHelpDrawer
        open={isWidgetContextualHelpOpen}
        onClose={() => setIsWidgetContextualHelpOpen(false)}
        widgetConfig={widgetsById[activeContextualHelpWidgetId]}
      />

      {dashboard?.id && widgetSettings?.id && (
        <WidgetSettingsModal
          open={isSettingsOpen}
          dashboardId={dashboard?.id}
          onClose={handleWidgetSettingsClose}
          onCancel={handleWidgetSettingsCancel}
          onSaveSuccess={handleWidgetSettingsSaveSuccess}
          widgetConfig={widgetSettings}
          action={widgetSettingsAction}
        />
      )}

      {isShareModalOpen && dashboard && (
        <ShareDashboardModal
          dashboard={dashboard}
          open={isShareModalOpen}
          handleOnClose={closeShareModal}
          handleShareSubmit={handleShareSubmit}
          updateList={setTriggerRequery}
        />
      )}

      {isRevokeAccessModalOpen && (
        <RevokeAccessModal
          open={isRevokeAccessModalOpen}
          handleOnClose={handleCloseRevokeAccessModal}
          showError
        />
      )}
    </div>
  );
};

export default DashboardOverview;
