import {
  FunctionComponent,
  AnchorHTMLAttributes,
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { BrandLogo } from '@blueoceanai/react-component-library';
import classNames from 'classnames';
import { find, findIndex } from 'lodash';
import { SingleValue } from 'react-select';
import { useScreenshotsContext } from '../CreativeChannelsScreenshots/CreativeChannelsScreenshots';
import useRouter from '../../../hooks/useRouter';
import useQuery from '../../../hooks/useQuery';
import { Brand } from '../../../hooks/useCompetitiveSet';
import DropdownSelect from '../Creative/DropdownSelect/DropdownSelect';
import CloseIcon from '../../../assets/icons/CloseIcon';
import DynamicPlusIcon from '../../../assets/icons/DynamicPlusIcon';
import CreativeChannelsScreenshotsSingleBrand from '../CreativeChannelsScreenshotsSingleBrand/CreativeChannelsScreenshotsSingleBrand';

import styles from './CreativeChannelsScreenshotsCompare.module.scss';
import { SIZES } from '../../../constants/props';

export interface CreativeChannelsScreenshotsCompareProps
  extends AnchorHTMLAttributes<HTMLDivElement> {}

type PanelDropdownOption = { label: string; value: string };

type ComparePanel = {
  id: string;
  dropdown: {
    options: PanelDropdownOption[];
    selected: PanelDropdownOption | null;
    logoUrl?: string;
  };
};

type ComparePanels = ComparePanel[];

const getBrandIdx = (brands: Brand[], targetKey: string) => {
  return findIndex(brands, ({ brandKey }) => brandKey === targetKey) ?? 0;
};

const getBrand = (brands: Brand[], targetKey: string) => {
  return find(brands, ({ brandKey }) => brandKey === targetKey) ?? null;
};

const getBrandOption = (brands: Brand[], targetId: string) => {
  const brand = getBrand(brands, targetId);
  if (!brand) return null;
  return {
    label: brand.name,
    value: brand.brandKey,
  };
};

const getPanel = (
  brands: Brand[],
  brandOption: PanelDropdownOption,
  brandIdsInUse: Set<string>
) => {
  return {
    id: brandOption.value,
    dropdown: {
      options: [
        brandOption,
        ...brands
          .filter(({ brandKey }) => !brandIdsInUse.has(brandKey))
          .map(({ name, brandKey }) => ({
            label: name,
            value: brandKey,
          })),
      ],
      selected: brandOption,
      logoUrl: getBrand(brands, brandOption.value)?.logoUrl ?? '',
    },
  };
};

const removeReplacePanel = (
  brandIdsInUse: Set<string>,
  panelId: string,
  replacement?: string
) => {
  const panelIdOrder = Array.from(brandIdsInUse);
  const oldPanelIdx = findIndex(panelIdOrder, (id) => id === panelId);
  const args: [number, number, ...string[]] = [oldPanelIdx, 1];
  if (replacement?.length) {
    args.push(replacement);
  }
  panelIdOrder.splice(...args);
  return panelIdOrder;
};

// Note: interaction requirements --
// 1. default view == hero brand + next brand in set
// 2. adding a new panel should default to next brand in set
// 3. when closing the page, redirect to All Brands as default
// 4. when closing the page, but brandFilterKey was set on entry,
// redirect to single brand view
const CreativeChannelsScreenshotsCompare: FunctionComponent<
  CreativeChannelsScreenshotsCompareProps
> = ({ className, ...props }) => {
  const queryParams = useQuery();
  const { updateRoute } = useRouter();
  const { brands, brandFilterKey } = useScreenshotsContext();
  const [brandIdsInUse, setBrandIdsInUse] = useState<Set<string>>(new Set());
  const [panels, setPanels] = useState<ComparePanels>([]);

  const heroBrandKey = useMemo(() => {
    if (!brands?.length || !brandFilterKey?.length) return null;
    return brandFilterKey === 'ALL_BRANDS'
      ? brands[0].brandKey
      : brandFilterKey;
  }, [brands, brandFilterKey]);

  useEffect(() => {
    if (!heroBrandKey?.length || !brands?.length) return;
    const heroBrandIdx = getBrandIdx(brands, heroBrandKey);
    let compBrandIdx = 1;
    if (heroBrandIdx !== 0) {
      compBrandIdx = 0;
    }
    const heroBrand = brands[heroBrandIdx];
    const compBrand = brands[compBrandIdx];
    if (!heroBrand || !compBrand) return;
    setBrandIdsInUse(new Set([heroBrand.brandKey, compBrand.brandKey]));
  }, [heroBrandKey, brands, setBrandIdsInUse]);

  useEffect(() => {
    if (!brandIdsInUse.size || !brands?.length) return;
    const ps: ComparePanels = [];
    brandIdsInUse.forEach((id) => {
      const brandOption = getBrandOption(brands, id);
      if (brandOption) {
        ps.push(getPanel(brands, brandOption, brandIdsInUse));
      }
    });
    setPanels(ps);
  }, [brands, brandIdsInUse, setPanels]);

  const showAddButton = useMemo(() => {
    return brandIdsInUse.size < brands.length;
  }, [brandIdsInUse.size, brands.length]);

  const handlePageClose = useCallback(() => {
    updateRoute({
      pathname:
        queryParams.get('redirectTo') ??
        '/creative/channels/website/all-brands',
      params: {
        redirectTo: null,
      },
      replace: true,
    });
  }, [updateRoute, queryParams]);

  const handleSelectedBrandChange = useCallback(
    (
      option: SingleValue<{ value: string; label: string }>,
      currentPanelId: string
    ) => {
      if (!option?.value || option.value === currentPanelId) {
        return;
      }
      const panelIdOrder = removeReplacePanel(
        brandIdsInUse,
        currentPanelId,
        option.value
      );
      setBrandIdsInUse(new Set(panelIdOrder));
    },
    [brandIdsInUse, setBrandIdsInUse]
  );

  const handlePanelClose = useCallback(
    (panelId: string) => {
      const panelIdOrder = removeReplacePanel(brandIdsInUse, panelId);
      setBrandIdsInUse(new Set(panelIdOrder));
    },
    [setBrandIdsInUse, brandIdsInUse]
  );

  const addNewPanel = useCallback(() => {
    const panelIdOrder = Array.from(brandIdsInUse);
    const nextBrandId = (
      find(brands, ({ brandKey }) => {
        return !brandIdsInUse.has(brandKey);
      }) ?? {}
    ).brandKey;
    if (!nextBrandId) return;
    panelIdOrder.push(nextBrandId);
    setBrandIdsInUse(new Set(panelIdOrder));
  }, [brands, setBrandIdsInUse, brandIdsInUse]);

  // ! TODO: add loading state and component loader
  return (
    <div
      className={classNames(
        styles.CreativeChannelsScreenshotsCompare,
        styles.CompareContainer,
        className
      )}
      {...props}
    >
      <div className={styles.Controls}>
        <h3 className={styles.BarTitle}>Website Compare</h3>
        <section className={styles.ActionRow}>
          <button
            type="button"
            onClick={handlePageClose}
            className={styles.CloseButton}
          >
            <CloseIcon />
          </button>
        </section>
      </div>

      <div className={styles.PanelCarousel}>
        {panels?.map((panel) => {
          return (
            <div key={panel.id} className={styles.Panel}>
              <div className={styles.Header}>
                <BrandLogo
                  size={SIZES.LG}
                  src={panel.dropdown.logoUrl}
                  className={styles.Logo}
                />
                <DropdownSelect
                  options={panel.dropdown.options}
                  onChange={(option) =>
                    handleSelectedBrandChange(option, panel.id)
                  }
                  value={panel.dropdown.selected}
                />
                {panels.length > 1 ? (
                  <CloseIcon
                    className={styles.Icon}
                    onClick={() => handlePanelClose(panel.id)}
                  />
                ) : null}
              </div>
              <CreativeChannelsScreenshotsSingleBrand
                className={styles.PanelCards}
                heroBrandKey={panel.id}
                isEmbedded
                isCompareView
              />
            </div>
          );
        })}
        {showAddButton && (
          <button
            type="button"
            onClick={addNewPanel}
            className={styles.Add}
            disabled={panels.length >= brands.length}
          >
            <DynamicPlusIcon className={styles.Icon} />
          </button>
        )}
      </div>
    </div>
  );
};

export default CreativeChannelsScreenshotsCompare;
