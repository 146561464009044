import React, { useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grow from '@material-ui/core/Grow';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import { TYPEAHEAD_INPUT_VARIANTS } from '../../../constants/props';

import styles from './TypeaheadInput.module.scss';
import DynamicCaretIcon, {
  CaretDirection,
} from '../../../assets/icons/DynamicCaret';

export default function TypeaheadInput({
  inputId,
  className,
  label,
  footerText,
  placeholder,
  potentialResults = [],
  required = false,
  value = '',
  variant = 'primary',
  icon,
  forceSelect = false,
  filterResults,
  onChange = () => {},
  inputProps = {},
  ...props
}) {
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    const element = document.querySelector(`.${styles.TypeaheadInput}`);

    function hideResults() {
      setShowResults(false);
    }

    element.addEventListener('blur', hideResults, true);

    return function cleanup() {
      element.removeEventListener('blur', hideResults, true);
    };
  }, []);

  const filteredResults = useMemo(() => {
    const results = potentialResults;

    if (!filterResults) {
      return results;
    }

    return results.filter((option) => {
      return option.value.toLowerCase().includes(value);
    });
  }, [potentialResults, filterResults, value]);

  function handleSearchTextChange(e) {
    if (!e || !e.target) {
      return;
    }

    onChange({
      value: e.target.value,
    });

    setShowResults(true);
  }

  function handleResultSelection(item) {
    setShowResults(false);
    onChange(item);
  }

  function handlePrematureSelection() {
    if (!forceSelect) {
      const item = {
        value,
        id: null,
      };

      setShowResults(false);
      onChange(item);
    }
  }

  function handleEnterPress(e) {
    if (e.key === 'Enter' && value && value.length) {
      handlePrematureSelection();
    }

    if (e.key === 'Enter' && value === '') {
      document.getElementById(inputId || `typeahead-label-${label}`).blur();
      setShowResults(false);
    }
  }

  function handleInputClick() {
    if (!showResults) {
      setShowResults(true);
      onChange({
        value: '',
        id: null,
      });
    }
  }

  return (
    <FormControl
      required={required}
      className={classNames(
        styles.TypeaheadInput,
        showResults ? styles.ResultsOpen : null,
        label ? styles.HasLabel : null,
        variant === TYPEAHEAD_INPUT_VARIANTS.DROPDOWN ? styles.Dropdown : null,
        className
      )}
      fullWidth
      {...props}
    >
      <InputLabel
        className={styles.Label}
        required={required}
        htmlFor={inputId || `typeahead-label-${label}`}
        focused
        shrink
      >
        {label}
      </InputLabel>

      <Box className={styles.InputWrap}>
        <Input
          onClick={handleInputClick}
          className={styles.Input}
          required={required}
          id={inputId || `typeahead-label-${label}`}
          disableUnderline
          fullWidth
          autoComplete="false"
          onChange={handleSearchTextChange}
          value={value}
          placeholder={placeholder}
          onKeyDown={handleEnterPress}
          startAdornment={
            icon ? (
              <InputAdornment position="start">{icon}</InputAdornment>
            ) : null
          }
          endAdornment={
            variant === TYPEAHEAD_INPUT_VARIANTS.DROPDOWN ? (
              <DynamicCaretIcon
                direction={CaretDirection.down}
                className={styles.Caret}
              />
            ) : null
          }
          {...inputProps}
        />
      </Box>

      <Grow in={showResults} style={{ transformOrigin: 'center top 0' }}>
        <Box className={styles.ResultList}>
          <Box className={styles.Body}>
            <ul className={styles.List}>
              {filteredResults.map((result, index) => (
                <li
                  className={styles.ListItem}
                  onClick={() => handleResultSelection(result)}
                  // eslint-disable-next-line
                  key={index}
                  data-cy="typeahead-list-item"
                >
                  {result.imgUrl && (
                    <img className={styles.Img} src={result.imgUrl} alt="" />
                  )}

                  <span className={styles.Value}>{result.value}</span>

                  {result.helperText && (
                    <span className={styles.HelperText}>
                      {result.helperText}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </Box>

          {footerText ? (
            <Box p={4} pt={0} className={styles.Footer}>
              <span>{footerText}</span>
            </Box>
          ) : null}
        </Box>
      </Grow>
    </FormControl>
  );
}

TypeaheadInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  footerText: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  potentialResults: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.string.isRequired,
      icon: PropTypes.node,
      imgUrl: PropTypes.string,
      helperText: PropTypes.string,
    })
  ).isRequired,
  variant: PropTypes.string,
  icon: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  inputId: PropTypes.string,
  forceSelect: PropTypes.bool,
  filterResults: PropTypes.bool,
  inputProps: PropTypes.shape({}),
};
