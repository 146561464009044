export const ALL_METRICS_RESP = [
  {
    name: 'Non-branded Search',
    id: '1A11',
    group: 'Index',
    categoryId: 5,
  },
  {
    name: 'Advertising',
    id: '1A12',
    group: 'Index',
    categoryId: 7,
  },
  {
    name: 'TV Spend',
    id: '1A1212ZZ',
    group: 'Quantity',
    categoryId: 7,
  },
  {
    name: 'Out of Home Spend',
    id: '1A1213ZZ',
    group: 'Quantity',
    categoryId: 7,
  },
  {
    name: 'Display Ads Share of Voice Desktop',
    id: '1A1214ZZ',
    group: 'Quantity',
    categoryId: 7,
  },
  {
    name: 'Display Ads Share of Voice Mobile',
    id: '1A1215ZZ',
    group: 'Quantity',
    categoryId: 7,
  },
  {
    name: 'Desktop Search Ads Spend',
    id: '1A1217ZZ',
    group: 'Quantity',
    categoryId: 7,
  },
  {
    name: 'Mobile Search Ads Spend',
    id: '1A1218ZZ',
    group: 'Quantity',
    categoryId: 7,
  },
  {
    name: 'Print Ads Spend',
    id: '1A1226ZZ',
    group: 'Quantity',
    categoryId: 7,
  },
  {
    name: 'Radio Ads Spend',
    id: '1A1227ZZ',
    group: 'Quantity',
    categoryId: 7,
  },
];

export const METRIC_CARD_DEFS = [
  {
    id: 1,
    name: 'Digital',
    categories: [
      {
        id: 1,
        name: 'News',
        variableIds: ['5C15', '4C18'],
      },
      {
        id: 2,
        name: 'Reviews',
        variableIds: ['1C16', '4B11'],
      },
      {
        id: 3,
        name: 'Social Media',
        variableIds: ['1A13', '1B18', '1B20', '1C13'],
      },
      // {
      //   id: 4,
      //   name: 'Social Media Frequency', // this needs to be completed
      //   variableIds: ['1A13', '1B18', '1B20', '1C13'],
      // },
      {
        id: 5,
        name: 'Website',
        variableIds: ['1B11', '1C15', '4C13', '1C14'],
      },
    ],
    variableIds: ['1B11', '1B18', '1B20', '1C16'],
  },
  {
    id: 2,
    name: 'Business',
    categories: [
      {
        id: 6,
        name: 'Employee Support',
        variableIds: ['5B14', '5C13'],
      },
      {
        id: 7,
        name: 'Media Spend',
        variableIds: ['1A1212ZZ', '1A1230ZZ'],
      },
      {
        id: 8,
        name: 'Business Performance',
        variableIds: ['4B17', '4B18'],
      },
    ],
    variableIds: ['4B17', '1A12', '4B18', '5B14'],
  },
  {
    id: 3,
    name: 'Content',
    categories: [
      {
        id: 9,
        name: 'Text Content Metrics',
        variableIds: ['4A11', '5A11', '5B11', '3A11'],
      },
      {
        id: 10,
        name: 'Creative Metric',
        variableIds: ['2B15', '3A15'],
      },
      {
        id: 11,
        name: 'Brand Personality',
        variableIds: ['3A14', '2B13'],
      },
    ],
    variableIds: ['5B11', '2B13', '3A11', '3A15'],
  },
];
