// eslint-disable-next-line
// @ts-nocheck

import { CellContext } from '@tanstack/react-table';
import { FunctionComponent, AnchorHTMLAttributes } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
// eslint-disable-next-line import/no-cycle
import { CreativeAsset } from '../../../../interfaces/creative';
import ChartLegendColorLabel from '../ChartLegendColorLabel/ChartLegendColorLabel';

import DiffPillMini from '../DifferencePill/DiffPillMini';

import styles from './AssetTable.module.scss';
import { abbreviateNumber } from '../../../../utils/number';

export type AssetFieldValue = string | string[] | number | null;

export enum AssetNumberFormat {
  Percentage = 'percentage',
  Currency = 'currency',
  Abbreviated = 'abbreviated',
  CurrencyAbbreviated = 'currency-abbreviated',
  Default = 'default',
}
const NumberFormatter: {
  [format: AssetNumberFormat]: (val: AssetFieldValue) => string;
} = {
  [AssetNumberFormat.Percentage]: (val) => {
    if (val === null) return '-';
    const percentVal = Math.round(Number(val));
    return `${percentVal.toLocaleString()}%`;
  },
  [AssetNumberFormat.Currency]: (val) => {
    if (val === null) {
      return '-';
    }
    const currencyVal = Number(val).toLocaleString();
    return `$${currencyVal}`;
  },
  [AssetNumberFormat.Default]: (val) => {
    if (val === null) {
      return '-';
    }
    return `${val.toLocaleString()}`;
  },
  [AssetNumberFormat.Abbreviated]: (val) => {
    if (val === null) {
      return '-';
    }
    return abbreviateNumber(Number(val), 2);
  },
  [AssetNumberFormat.CurrencyAbbreviated]: (val) => {
    if (val === null) {
      return '-';
    }
    return `$${abbreviateNumber(Number(val), 2)}`;
  },
};

export const SeenFrom = ({
  row,
}: CellContext<CreativeAsset, AssetFieldValue>) => {
  const { start, end } = row.original.seen;
  const startDate = start ? dayjs(start).format('M/D/YYYY') : '';
  const endDate = end ? dayjs(end).format('M/D/YYYY') : 'Today';
  return (
    <div className={styles.RightAlign}>
      <span>{startDate} - </span>
      <span>{endDate}</span>
    </div>
  );
};

export const NumberTransformCell = (
  num: AssetFieldValue,
  formatType: AssetNumberFormat,
  className?: string
) => {
  const formatted = NumberFormatter[formatType](num);
  return <p className={cn(styles.CellText, className)}>{formatted}</p>;
};

export interface LabelCellProps extends AnchorHTMLAttributes<HTMLDivElement> {
  label: AssetFieldValue;
  onLabelClick?: () => void;
}

export const LabelCell: FunctionComponent<LabelCellProps> = ({
  className,
  label,
  onLabelClick,
}) => {
  return (
    <div className={className} onClick={onLabelClick}>
      <p>{label}</p>
    </div>
  );
};

export interface ChartLabelCellProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  label: AssetFieldValue;
  color?: {
    palette: string;
    index: number;
    dashed?: boolean;
    checked?: boolean | undefined;
  };
  onLegendClick?: () => void;
}

export const ChartLabelCell: FunctionComponent<ChartLabelCellProps> = ({
  className,
  label,
  color,
  onLegendClick,
}) => {
  return (
    <div className={className}>
      {color && (
        <ChartLegendColorLabel legendColor={color} onClick={onLegendClick} />
      )}

      <p>{label}</p>
    </div>
  );
};

export const DiffPillCell = (num: number | string) => {
  return <DiffPillMini percentage={num} />;
};
