import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Grid from '@material-ui/core/Grid';
import { Button } from '@blueoceanai/react-component-library';
import cloneDeep from 'lodash/cloneDeep';

import GenericErrorCopy from '../../Atoms/GenericErrorCopy/GenericErrorCopy';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';
import Switch from '../../Atoms/Switch/Switch';
import SelectInput from '../../Molecules/SelectInput/SelectInput';
import CheckboxList from '../../Molecules/CheckboxList/CheckboxList';
import TalkToStrategistModal from '../../Organisms/TalkToStrategistModal/TalkToStrategistModal';
import RecommendationsList from '../../Organisms/RecommendationsList/RecommendationsList';
import RecommendationsAccordion from '../../Organisms/RecommendationsAccordion/RecommendationsAccordion';
import AddRecommendationModal from '../../Organisms/AddRecommendationModal/AddRecommendationModal';
import { GET_RECOMMENDATIONS } from '../../../api/queries/Pages/Recommendations';
import PageTitle from '../../Molecules/PageTitle/PageTitle';
import BNContext from '../../../contexts/BNContext';

import {
  IMPACT_RESULTS,
  TAGS,
  RECOMMENDATION_LIFECYCLE,
  GROUP_BY_OPTIONS,
} from '../../../constants/recommendations';
import { BUTTON_VARIANTS, DROPDOWN_VARIANTS } from '../../../constants/props';

import styles from './Recommendations.module.scss';

const PRACTICE_AREA_OPTIONS = TAGS.map((tag) => ({
  label: tag.label,
  value: tag.label,
}));

const IMPACT_RESULT_OPTIONS = IMPACT_RESULTS.map((impactResults) => ({
  label: impactResults.label,
  value: impactResults.key,
}));

export default function Recommendations({ className, disableCache = true }) {
  const {
    competitiveSetID: _competitiveSetId,
    currentSession: sessionKey,
    accountKey: accountKey, //eslint-disable-line
    FEATURE_FLAGS,
  } = React.useContext(BNContext);

  const queryOptions = {
    variables: {
      id: _competitiveSetId,
      sessionKey,
      accountKey,
    },
  };

  if (disableCache) {
    queryOptions.fetchPolicy = 'no-cache';
  }

  const [recData, setRecData] = useState(null);
  const [strategistModalOpen, setStrategistModalOpen] = useState(false);
  const [addRecommendationModalOpen, setAddRecommendationModalOpen] =
    useState(false);

  const [groupBy, setGroupBy] = useState('');

  const [hideCompleted, setHideCompleted] = useState(false);

  const [impactResultsFilters, setImpactResultsFilters] = useState([]);
  const [tagFilters, setTagFilters] = useState([]);
  const [lifecycleFilters, setLifecycleFilters] = useState([
    RECOMMENDATION_LIFECYCLE.IN_PROGRESS,
    RECOMMENDATION_LIFECYCLE.COMPLETE,
  ]);
  const [editedRecommendation, setEditedRecommendation] = useState(null);

  const [getRecommendations, { loading, error, data }] = useLazyQuery(
    GET_RECOMMENDATIONS,
    queryOptions
  );

  useEffect(() => {
    if (!_competitiveSetId || !sessionKey) {
      return;
    }
    getRecommendations();
  }, [getRecommendations, _competitiveSetId, sessionKey]);

  useEffect(() => {
    if (data) {
      setRecData(data);
    }
  }, [data]);

  useEffect(() => {
    if (hideCompleted) {
      setLifecycleFilters([RECOMMENDATION_LIFECYCLE.IN_PROGRESS]);
    } else {
      setLifecycleFilters([
        RECOMMENDATION_LIFECYCLE.IN_PROGRESS,
        RECOMMENDATION_LIFECYCLE.COMPLETE,
      ]);
    }
  }, [hideCompleted, recData]);

  function openStrategistModal() {
    setStrategistModalOpen(true);
  }

  function closeStrategistModal() {
    setStrategistModalOpen(false);
  }

  function openAddRecModal() {
    setAddRecommendationModalOpen(true);
  }

  function closeAddRecModal() {
    setAddRecommendationModalOpen(false);
    setEditedRecommendation(null);
  }

  function editRecommendation(recommendationObj) {
    setEditedRecommendation(recommendationObj);
    setAddRecommendationModalOpen(true);
  }

  function handleGroupByChange(e) {
    setGroupBy(e.target.value);
  }

  function handleHideCompletedToggle() {
    setHideCompleted(!hideCompleted);
  }

  function handleImpactResultChange(impactResults) {
    setImpactResultsFilters(impactResults);
  }

  function handlePracticeAreaChange(practiceAreas) {
    setTagFilters(practiceAreas);
  }

  function onEdit(editedRec) {
    const recDataClone = cloneDeep(recData);
    const currentRecIndex =
      recData.competitiveSet.session.recommendations.findIndex(
        (rec) => rec.id === editedRec.id
      );

    recDataClone.competitiveSet.session.recommendations[currentRecIndex].name =
      editedRec.name;
    recDataClone.competitiveSet.session.recommendations[currentRecIndex].value =
      editedRec.value;
    recDataClone.competitiveSet.session.recommendations[
      currentRecIndex
    ].impact_id = editedRec.impact_id;
    recDataClone.competitiveSet.session.recommendations[
      currentRecIndex
    ].practice_areas = editedRec.practice_areas;
    recDataClone.competitiveSet.session.recommendations[
      currentRecIndex
    ].isPrivate = editedRec.isPrivate;
    recDataClone.competitiveSet.session.recommendations[
      currentRecIndex
    ].isComplete = editedRec.isComplete;
    recDataClone.competitiveSet.session.recommendations[currentRecIndex].type =
      editedRec.type;
    recDataClone.competitiveSet.session.recommendations[
      currentRecIndex
    ].associatedType_id = editedRec.associatedType_id;

    setRecData(recDataClone);
  }

  async function onAdd() {
    try {
      getRecommendations();
    } catch (e) {
      if (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  }

  if (error) {
    return <GenericErrorCopy />;
  }

  return (
    <Box
      id="page-wrapper"
      px={4}
      className={classNames(
        styles.Recommendations,
        'WorkspaceContainer',
        className
      )}
    >
      <div
        className={classNames('WorkspaceTitleContainer', styles.WorkspaceTitle)}
      >
        <PageTitle
          title="Ideas & Recommendations"
          shareable
          fullscreen
          targetId="page-wrapper"
        />
      </div>

      <Grid className="PageContainer" container>
        <Grid item xs={false} md={2} className={styles.LeftSideBar}>
          <Box py={2}>
            <CheckboxList
              title="Impact Results"
              options={IMPACT_RESULT_OPTIONS}
              onChange={handleImpactResultChange}
            />
          </Box>
          <Box py={2} pb={6}>
            <CheckboxList
              title="Practice Area"
              options={PRACTICE_AREA_OPTIONS}
              onChange={handlePracticeAreaChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box px={4} pr={0}>
            <Box className={styles.ActionsContainer} pb={4}>
              <Box className={styles.Filters}>
                <Box className={styles.GroupByContainer} mr={10}>
                  <Box>
                    <span className={styles.GroupByLabel}>
                      Group by:&nbsp; &nbsp;{' '}
                    </span>
                  </Box>

                  <SelectInput
                    className={styles.GroupSelect}
                    menuItems={GROUP_BY_OPTIONS}
                    inputId="group-by"
                    variant={DROPDOWN_VARIANTS.NAKED}
                    onChange={handleGroupByChange}
                    value={groupBy}
                  />
                </Box>
                <Box className={styles.CompletedFilter}>
                  <Switch
                    onChange={handleHideCompletedToggle}
                    checked={hideCompleted}
                  />
                  <Box>
                    <span className={styles.CompletedLabel}>
                      Hide completed items
                    </span>
                  </Box>
                </Box>
              </Box>
              <Box className={styles.Buttons}>
                <Box mr={2}>
                  <Button
                    onClick={openAddRecModal}
                    variant={BUTTON_VARIANTS.SECONDARY}
                  >
                    Add Recommendation
                  </Button>
                </Box>
                {FEATURE_FLAGS.RECOMMENDATIONS.TALK ? (
                  <Button
                    onClick={openStrategistModal}
                    variant={BUTTON_VARIANTS.PRIMARY}
                  >
                    Talk to a Strategist
                  </Button>
                ) : null}
              </Box>
            </Box>
            <Box>
              {loading &&
              !recData &&
              !recData?.competitiveSet?.session?.recommendations ? (
                <ComponentLoader minHeight={400} />
              ) : null}
              {!loading &&
              recData?.competitiveSet?.session?.recommendations &&
              !groupBy ? (
                <RecommendationsList
                  impactResultsFilters={impactResultsFilters}
                  tagFilters={tagFilters}
                  lifecycleFilters={lifecycleFilters}
                  editRecommendation={editRecommendation}
                  // recommendations={data.competitiveSet.session.recommendations}
                  data={recData}
                />
              ) : null}
              {!loading &&
              recData?.competitiveSet?.session?.recommendations &&
              groupBy ? (
                <RecommendationsAccordion
                  impactResultsFilters={impactResultsFilters}
                  tagFilters={tagFilters}
                  lifecycleFilters={lifecycleFilters}
                  groupBy={groupBy}
                  data={recData}
                  editRecommendation={editRecommendation}
                  // recommendations={data.competitiveSet.session.recommendations}
                />
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <AddRecommendationModal
        open={addRecommendationModalOpen}
        onClose={closeAddRecModal}
        onAdd={onAdd}
        onEdit={onEdit}
        editedRecommendation={editedRecommendation}
      />

      <TalkToStrategistModal
        open={strategistModalOpen}
        onClose={closeStrategistModal}
      />
    </Box>
  );
}

Recommendations.propTypes = {
  className: PropTypes.string,
  disableCache: PropTypes.bool,
};
