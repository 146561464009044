import tidepoolInstance from './instance';

export function convertFromPNG(pngDataUrl, format) {
  return tidepoolInstance.post('/dev-image-transcoding', {
    image: pngDataUrl,
    image_format: 'png',
    format,
  });
}

export function getShareUrl(pngDataUrl) {
  return tidepoolInstance.post('/print-to-pdf', {
    image: pngDataUrl,
  });
}
