import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#4EC4DB',
    height: '3px',
  },
})(Tabs);

export const StyledTab = withStyles({
  root: {
    color: 'black',
    fontSize: '11.5px',
    lineHeight: '13px',
    height: '52px',
  },
})(Tab);
