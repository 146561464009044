export function prepareAccountMarkerEvents(data) {
  const markers = data?.user?.account?.markers;

  if (!markers) {
    return [];
  }

  return markers.map((marker) => {
    return {
      id: marker.id,
      label: marker.label,
      brand: marker.brandName ? marker.brandName : 'All',
      eventDate: marker.markerEventDate,
      displayTo: marker.isAccountLevel === 0 ? 'Brand' : 'Account',
      addedDate: marker.createdDate,
      addedBy: marker.userEmail,
      private: Boolean(marker.isPrivate),
    };
  });
}
