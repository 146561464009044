export default {
  aquaBlue: '#00d0e2',
  aquaMarine: '#3bd4ae',
  blueWithAHintOfPurple: '#5c43bd',
  bluishGreen: '#13b58c',
  buff: '#fffb9d',
  buttonTertiaryDefault: '#e3eef0',
  cerulean: '#007dcd',
  charcoalGrey: '#2e3338',
  chartAnnotationLine: '#748590',
  chartCreativeMiniLine: '#e7f4fa',
  chartBarOcean01: '#1097cf',
  chartBarOcean02: '#1d566d',
  chartBarOcean03: '#88ced8',
  chartBarOcean04: '#16988c',
  chartBarOcean05: '#a5d4b4',
  chartBarOcean06: '#5a5a83',
  chartBarOcean07: '#a7b9d8',
  chartBarOcean08: '#606d66',
  chartBarOcean09: '#a4b9ae',
  chartBarOcean10: '#836a98',
  chartBarSunset01: '#1d627f',
  chartBarSunset02: '#ffcf82',
  chartBarSunset03: '#f4955e',
  chartBarSunset04: '#9a4e89',
  chartBarSunset05: '#e38885',
  chartBarSunset06: '#cd4374',
  chartBarSunset07: '#d990c8',
  chartBarSunset08: '#5a478b',
  chartBarSunset09: '#c1b3e6',
  chartBarSunset10: '#54abbb',
  chartBarSunsetIndex: '#2f95ae',
  chartBluescoreBorder: '#434e54',
  chartBluescoreVerticalLine: '#6B6B75',
  chartGridLine: '#F0F0F0',
  chartHeatmapDivergent0: '#f6f6f6',
  chartHeatmapDivergent01: '#e0eeb9',
  chartHeatmapDivergent02: '#f5c8ae',
  chartHeatmapDivergent03: '#7cd695',
  chartHeatmapDivergent04: '#d55a81',
  chartHeatmapDivergentSunburst0: '#f8f0ba',
  chartHeatmapDivergentSunburst01: '#d6e6a7',
  chartHeatmapDivergentSunburst02: '#fadb9f',
  chartHeatmapDivergentSunburst03: '#9bd08e',
  chartHeatmapDivergentSunburst04: '#ea9173',
  chartHeatmapDivergentSunburst05: '#68c69a',
  chartHeatmapDivergentSunburst06: '#51c3a9',
  chartHeatmapDivergentSunburst07: '#36babd',
  chartHeatmapDivergentSunburst08: '#1fb1ce',
  chartHeatmapIndex01: '#f6f8ed',
  chartHeatmapIndex02: '#dbefe2',
  chartHeatmapIndex03: '#bfe9dd',
  chartHeatmapIndex04: '#96cfd1',
  chartHeatmapIndex05: '#60b2ca',
  chartHeatmapIndex06: '#3d7493',
  chartHeatmapIndex07: '#193a5e',
  chartHeatmapVolume01: '#74c4b1',
  chartHeatmapVolume02: '#6ccfe2',
  chartHeatmapVolume03: '#4d8ce6',
  chartHeatmapVolume04: '#6f50ab',
  chartPlatformBarFacebook: '#3a5a9a',
  chartPlatformBarGoogle: '#0f9d57',
  chartPlatformBarInstagram: '#f77737',
  chartPlatformBarLinkedIn: '#0078b5',
  chartPlatformBarSnap: '#f1e612',
  chartPlatformBarTikTok: '#02f7ef',
  chartPlatformBarTwitter: '#44b5fa',
  chartPlatformBarYelp: '#ae1b1c',
  chartPlatformBarYouTube: '#ff0000',
  colorBlack: '#000000',
  colorGray10: '#f4f7fa',
  colorGray20: '#e9edf0',
  colorGray40: '#b3bdc4',
  colorGray50: '#99a4ad',
  colorGray70: '#6d7a84',
  colorGray80: '#49545c',
  colorGray90: '#2b3a45',
  colorGrayBase60: '#7f8b95',
  colorPrimary10: '#eef9fb',
  colorPrimary20: '#dbf3f8',
  colorPrimary30: '#c1ecf5',
  colorPrimary40: '#9be2f0',
  colorPrimary50: '#77d5e7',
  colorPrimary70: '#2dadc6',
  colorPrimary80Link: '#1a8aa0',
  colorPrimary90: '#0a5f70',
  colorPrimaryBase60: '#4ec3db',
  colorSecondary10: '#f1f6f7',
  colorSecondary20: '#E3EEF0',
  colorSecondary30: '#d1e2e6',
  colorSecondary40: '#a6c4ca',
  colorSecondary50: '#81a5ac',
  colorSecondary60: '#5F8890',
  colorSecondary70: '#467078',
  colorSecondary80: '#335e66',
  colorSecondary90: '#20434a',
  colorSecondaryBase60: '#5f8890',
  colorSemanticAlert10: '#fff9e2',
  colorSemanticAlert30: '#fff0b3',
  colorSemanticAlert70: '#d0a600',
  colorSemanticAlert90: '#907300',
  colorSemanticAlertBase50: '#f4cf3d',
  colorSemanticError10: '#fff2f2',
  colorSemanticError30: '#f5b7b7',
  colorSemanticError70: '#a81618',
  colorSemanticError90: '#6a0102',
  colorSemanticErrorBase50: '#d83133',
  colorSemanticInfo10: '#f3f5fd',
  colorSemanticInfo30: '#d8e0f9',
  colorSemanticInfo70: '#5574db',
  colorSemanticInfo90: '#3350b2',
  colorSemanticInfoBase50: '#7e9af7',
  colorSemanticSuccess10: '#e8fdf0',
  colorSemanticSuccess30: '#aeecc6',
  colorSemanticSuccess70: '#0a923f',
  colorSemanticSuccess90: '#005421',
  colorSemanticSuccessBase50: '#20c660',
  colorSemanticWarning10: '#fff2ec',
  colorSemanticWarning30: '#ffbfa1',
  colorSemanticWarning70: '#bf5f32',
  colorSemanticWarning90: '#7f3f21',
  colorSemanticWarningBase50: '#ff7f43',
  darkGreyBlue: '#304b62',
  dodgerBlue: '#4c8df0',
  ice: '#e2f9f3',
  iceBlue: '#eff5f9',
  iceBlueTwo: '#eef3f6',
  iconDefault: '#7e9397',
  iconMedium: '#ced9db',
  inputDisabled: '#d7dde2',
  maskNoTrend: 'rgba(233, 237, 240, 0.9)',
  navActive: 'rgba(255, 255, 255, 0.15)',
  opacity60: 'rgba(255, 255, 255, 0.6)',
  opacity80: 'rgba(255, 255, 255, 0.9)',
  paleAqua: '#b9ffee',
  paleBlue: '#dee9ee',
  peach: '#ffbf7d',
  peachTwo: '#ffa67d',
  styleguideFill: '#d74fee',
  tealish: '#24b4d2',
  uiModalOverlay: 'rgba(10, 18, 45, 0.8)',
  uiTableCol: '#ffffff',
  veryLightBlue: '#dbf8ff',
  white10: 'rgba(255, 255, 255, 0.1)',
  white50: 'rgba(255, 255, 255, 0.5)',
  sand: '#f4d1ae',
};
