/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { TimeRange } from '../../../interfaces/dashboard-api';

export const GET_METRIC_COMPARISON_DATA = gql`
  query getMetricComparison(
    $id: ID
    $sessionKey: ID
    $input: MetricComparisonWidget
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        metricComparisonWidget(input: $input) {
          selectedMetricName
          selectedMetricDescription
          markers {
            date
            label
          }
          brands {
            brandKey
            name
            logoUrl
            relativeTimeSeries {
              date
              value
            }
            relativeScoreTile
            relativeScoreDelta
          }
        }
      }
    }
  }
`;

export const GET_BLUE_SCORE_DATA = gql`
  query getBlueScoreData($id: ID, $sessionKey: ID, $accountKey: ID) {
    competitiveSet(id: $id) {
      brand {
        id
        logoUrl
        name
        __typename
      }
      session(sessionKey: $sessionKey) {
        competitors: competitors {
          id
          logoUrl
          name
        }
        bluescore: brandLastPeriodScores(associatedTypeId: 5) {
          t90Days
          __typename
        }
        competitorsLastPeriodScores(associatedTypeId: 5) {
          id: brand_id
          t90Days
          __typename
        }
        __typename
      }
      markers(accountKey: $accountKey) {
        label
        markerEventDate
        __typename
      }
      __typename
    }
    __typename
  }
`;

// ESOV query and helpers start here
export const UI_TO_GQL_TIME_RANGE = {
  '30_DAYS': 't30Days',
  '60_DAYS': 't90Days',
  '90_DAYS': 't90Days',
  '365_DAYS': 't4Quaters',
};

// Note: since we're using a variable for the time range FIELD key in GQL, we can't use fragments
// or pass into gql library. So, we have to construct the query string manually with a template string.
const constructESOVQuery = (timeRange: TimeRange = TimeRange.DEFAULT) => {
  const tr = UI_TO_GQL_TIME_RANGE[timeRange] ?? UI_TO_GQL_TIME_RANGE['90_DAYS'];
  return `query getESOVCalcComponents($id: ID, $sessionKey: ID, $metricId: String) {
    competitiveSet(id: $id) {
      brand {
        brandKey
        logoUrl
        name
        international_ratio
        __typename
      }
      session(sessionKey: $sessionKey) {
        id
        sessionDate
        metricTrend(associatedTypeId: $metricId) {
          ${tr}
          __typename
        }
        competitors {
          id
          brandKey
          name
          logoUrl
          # TODO: this won't work until Lucas adds to 2 sprocs -- activate when ready
          # international_ratio
          metricTrend(associatedTypeId: $metricId) {
            ${tr}
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
  `;
};

export const getESOVQuery = (timeRange: TimeRange = TimeRange.DEFAULT) =>
  gql(constructESOVQuery(timeRange));

export const GET_METRIC_ANALYSIS_DATA = gql`
  query getMetricAnalysis(
    $id: ID
    $sessionKey: ID
    $metricId: String
    $range: Int
    $selectedBrandKey: String
    $endDate: DateType
  ) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        metricAnalysisWidget(
          associatedTypeId: $metricId
          range: $range
          selectedBrandKey: $selectedBrandKey
          compSetKey: $id
          endDate: $endDate
        ) {
          relativeTimeSeries {
            date
            value
            __typename
          }
          isolatedTimeSeries {
            date
            value
            __typename
          }
          relativeScoreTile
          relativeScoreDelta
          isolatedScoreTile
          isolatedScoreDelta
          isolatedCohortComparison
          isolatedChangeRate
          selectedBrandName
          brandLogoUrl
          __typename
        }
      }
    }
  }
`;

export const GET_FIRST_AND_LAST_SESSION = gql`
  query getFirstLastSession($id: ID) {
    competitiveSet(id: $id) {
      firstAndLastSession {
        sessionKey
      }
    }
  }
`;

export interface GetMetricGroupWidgetDataResp {
  competitiveSet: {
    session: {
      groupMetricWidget: {
        metrics: GetMetricGroupMetricResp;
        selectedBrandName: string;
        selectedBrandKey: string;
        brandLogoUrl: string;
      };
    };
  };
}

export interface GetMetricGroupMetricResp {
  metric: string;
  relativeTimeSeries: {
    date: string;
    value: number;
  }[];
  isolatedTimeSeries: {
    date: string;
    value: number;
  }[];
  cohortTimeSeries: {
    date: string;
    value: number;
  }[];
  relativeScoreTile: number;
  relativeScoreDelta: number;
  isolatedScoreTile: number;
  isolatedScoreDelta: number;
  isolatedCohortComparison: number;
  isolatedChangeRate: number;
}

export const GET_METRIC_GROUP_WIDGET_DATA = gql`
  query getGroupMetrics($id: ID, $sessionKey: ID, $input: GroupMetricWidget) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        groupMetricWidget(input: $input) {
          metrics {
            metric
            relativeTimeSeries {
              date
              value
            }
            isolatedTimeSeries {
              date
              value
            }
            cohortTimeSeries {
              date
              value
            }
            relativeScoreTile
            relativeScoreDelta
            isolatedScoreTile
            isolatedScoreDelta
            isolatedCohortComparison
            isolatedChangeRate
          }
          selectedBrandName
          selectedBrandKey
          brandLogoUrl
        }
      }
    }
  }
`;

export interface PaceAnalysisWidgetInput {
  associatedTypeId: string;
  associatedMetricName: string;
  range: number;
  selectedBrandKey: string;
  rivalBrandKeys: string;
  compSetKey: string;
}

const PACE_ANALYSIS_BRAND_DATA = `
  brandKey
  name
  logoUrl
  relativeTimeSeries {
      date
      value
  }
  isolatedTimeSeries {
      date
      value
  }
  relativeScoreTile
  relativeScoreDelta
  relativeChangeRate
  relativeCohortComparison
  relativeScoreInsights
  isolatedScoreTile
  isolatedScoreDelta
  isolatedChangeRate
  isolatedCohortComparison
  isolatedScoreInsights
`;

export const GET_PACE_ANALYSIS_WIDGET_DATA = gql`
    query getPaceAnalysis($id: ID, $sessionKey: ID, $input: PaceAnalysisWidget) {
        competitiveSet(id: $id) {
            session(sessionKey: $sessionKey) {
                paceAnalysisWidget(input: $input) {
                    start
                    end
                    rivalRelativeBrands {
                        ${PACE_ANALYSIS_BRAND_DATA}
                    }
                    rivalIsolatedBrands {
                        ${PACE_ANALYSIS_BRAND_DATA}
                    }
                    leadingRelativeBrands {
                        ${PACE_ANALYSIS_BRAND_DATA}
                    }
                    leadingIsolatedBrands {
                        ${PACE_ANALYSIS_BRAND_DATA}
                    }
                    closeRelativeCompetitors {
                        ${PACE_ANALYSIS_BRAND_DATA}
                    }
                    closeIsolatedCompetitors {
                        ${PACE_ANALYSIS_BRAND_DATA}
                    }
                }
            }
        }
    }
`;

export const GET_STACK_RANKING_WIDGET_DATA = gql`
  query getStackRanking($id: ID, $sessionKey: ID, $input: StackRankingWidget) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        stackRankingWidget(input: $input) {
          selectedMetricName
          selectedMetricDescription
          brands {
            brandKey
            name
            logoUrl
            relativeScoreTile
            relativeScoreDelta
          }
        }
      }
    }
  }
`;

export const GET_SUMMARY_TRENDS_DATA = gql`
  query getTrendSummary($id: ID, $sessionKey: ID, $input: TrendSummaryWidget) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        trendSummaryWidget(input: $input) {
          insights
          name
          logoUrl
          metricsTrendingUp {
            variableId
            relativeScoreTile
            relativeScoreDelta
            isolatedScoreTile
            isolatedScoreDelta
            isolatedCohortRate
            isolatedChangeRate
          }
          metricsTrendingDown {
            variableId
            relativeScoreTile
            relativeScoreDelta
            isolatedScoreTile
            isolatedScoreDelta
            isolatedCohortRate
            isolatedChangeRate
          }
        }
      }
    }
  }
`;

export const GET_SUMMARY_TRENDS_VARIABLE_IDS = gql`
  query getTrendSummary($id: ID, $sessionKey: ID, $input: TrendSummaryWidget) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        trendSummaryWidget(input: $input) {
          metricsTrendingUp {
            variableId
          }
          metricsTrendingDown {
            variableId
          }
        }
      }
    }
  }
`;

export interface VisualMapWidgetInput {
  range: number;
  group: string;
  compSetKey: string;
  selectedBrandKeys: string;
}

export const GET_VISUAL_MAPPING_DATA = gql`
  query getVisualMap($id: ID, $sessionKey: ID, $input: VisualMapWidget) {
    competitiveSet(id: $id) {
      session(sessionKey: $sessionKey) {
        visualMapWidget(input: $input) {
          visualMapMetrics {
            name
            description
            variableId
            visualMapBrands {
              brandKey
              name
              logoUrl
              relativeScoreTile
              relativeScoreDelta
              relativeChangeRate
              relativeCohortComparison
              isolatedScoreTile
              isolatedScoreDelta
              isolatedChangeRate
              isolatedCohortComparison
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query getUserSettings {
    user {
      name
      id
      userKey
      accounts {
        id
        accountKey
        users {
          name
          id
          userKey
          email
        }
      }
    }
  }
`;

export const GET_SHAREABLE_USERS = gql`
  query getShareableUsers {
    user {
      getShareableUsers {
        id
        userKey
        name
        email
      }
    }
  }
`;

export const GET_SHAREABLE_USERS_BY_SETS = gql`
  query getShareableUsersBySets($input: UserKeyList) {
    user {
      getShareableUsersBySets(input: $input) {
        id
        userKey
        name
        email
      }
    }
  }
`;
