import React, { useMemo, useEffect, useState, useContext } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import sortBy from 'lodash/sortBy';

import Grid from '@material-ui/core/Grid';

import SelectInput from '../../Molecules/SelectInput/SelectInput';
import TextInput from '../../Molecules/TextInput/TextInput';
import ModalFooter from '../../Molecules/ModalFooter/ModalFooter';
import ModalAlert from '../../Molecules/ModalAlert/ModalAlert';

import { CREATE_ADMIN_USER } from '../../../api/queries/Pages/AdminSettings';

import {
  getLowerRoles,
  USER_ROLES_KEYS,
  USER_ROLES,
} from '../../../constants/roles';

import BNContext from '../../../contexts/BNContext';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

import styles from './SettingsUserForm.module.scss';

export default function SettingsUserForm({
  className,
  onClose,
  onSuccess,
  onError,
  editData,
  brandsWithBrandAdmin,
  isAccountToppedOut,
  users,
  ...props
}) {
  const { competitiveSetID, accountList, accountKey, userRole } =
    useContext(BNContext);
  const FEATURE_FLAGS = useFeatureFlags();

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [openModalAlert, setOpenModalAlert] = useState(false);
  const [modalTextProps, setModalTextProps] = useState({});

  const [
    addUser,
    { data: addUserResp, error: addUserError, loading: addUserLoading },
  ] = useMutation(CREATE_ADMIN_USER);

  const userRoleOptions = useMemo(() => {
    if (isAccountToppedOut) {
      if (editData) {
        return [
          {
            label: USER_ROLES.filter((role) => role.id === editData.roleId)[0]
              .name,
            value: editData.roleId,
          },
          { label: 'Brand Viewer', value: USER_ROLES_KEYS.BRAND_VIEWER },
        ];
      }
      return [{ label: 'Brand Viewer', value: USER_ROLES_KEYS.BRAND_VIEWER }];
    }

    return getLowerRoles(userRole.id, { includeCurrent: true })
      .filter(
        (role) =>
          role.id < USER_ROLES_KEYS.BLUEOCEAN_ADMIN &&
          role.id !== USER_ROLES_KEYS.BILLING_ADMIN
      )
      .map((role) => ({
        label: role.name,
        value: role.id,
      }));
  }, [userRole?.id, isAccountToppedOut, editData]);

  const brandOptions = useMemo(() => {
    if (!accountList?.[0]?.competitiveSets || !competitiveSetID) {
      return;
    }
    const currentAccount = accountList.filter(
      (account) => account.accountKey === accountKey
    );

    const options = currentAccount[0].competitiveSets.map((competitiveSet) => ({
      label: competitiveSet.brand.name,
      value: competitiveSet.brand.brandKey,
      isCurrent: competitiveSet.competitiveSetKey === competitiveSetID ? 1 : 2,
    }));
    return sortBy(options, 'isCurrent');
  }, [competitiveSetID, accountList, accountKey]);

  const formSchema = yup.object().shape({
    email: yup.string().email().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    brandKey: yup.string().required(),
    roleId: yup.number().required(),
  });

  const { handleSubmit, control, errors, watch } = useForm({
    resolver: yupResolver(formSchema),
  });

  // require first and last names
  const watchedFields = watch(['email', 'firstName', 'lastName', 'brandKey', 'roleId']);

  useEffect(() => {
    formSchema.isValid(watchedFields).then((valid) => {
      setDisableSubmit(!valid);
    });
  }, [watchedFields, formSchema]);

  useDeepCompareEffectNoCheck(() => {
    if (!addUserResp) {
      return;
    }

    if (addUserResp.adminAddUser) {
      onSuccess();
      onClose();
    } else {
      onError();
    }
  }, [addUserResp]);

  useDeepCompareEffectNoCheck(() => {
    if (addUserError) {
      // eslint-disable-next-line no-console
      console.error(addUserError);
    }
  }, [addUserError]);

  const isLoading = useMemo(() => {
    return !brandOptions;
  }, [brandOptions]);

  const defaultBrandValue = useMemo(() => {
    if (editData && brandOptions) {
      return editData.brandKey;
    }

    if (brandOptions) {
      return brandOptions[0].value;
    }

    return '';
  }, [brandOptions, editData]);

  function onSubmit(data) {
    const incomingData = data;
    if (users) {
      const usersAndBrands = users.map((user) => {
        return {
          user: user.username,
          brands: user.brands,
        };
      });

      if (
        usersAndBrands.filter(
          (u) =>
            u.user === incomingData.email && u.brands === incomingData.brandKey
        ).length > 0
      ) {
        setModalTextProps({
          header: 'User is already in this competitive set',
          body: `${incomingData.email} is already a member of this competitive set.`,
        });
        setOpenModalAlert(true);
        return false;
      }
    }
    if (
      brandsWithBrandAdmin.includes(incomingData.brandKey) &&
      incomingData.roleId === 4
    ) {
      setModalTextProps({
        header: 'Duplicate Brand Admin',
        body: 'You cannot add a second brand admin to this brand',
      });
      setOpenModalAlert(true);
      return false;
    }

    if (editData && editData.burId) incomingData.burId = editData.burId;

    addUser({
      variables: {
        ...incomingData,
        accountKey,
      },
    });
  }

  return (
    <div className={classNames(styles.SettingsUserForm, className)} {...props}>
      {!isLoading && (
        <form
          className={styles.Form}
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="email"
                control={control}
                defaultValue={editData ? editData.username : ''}
                render={({ onChange, value }) => (
                  <TextInput
                    type="email"
                    inputId="email"
                    placeholder="Enter user company email"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required
                    helperText={
                      editData
                        ? errors.email && errors.email.message
                        : errors.email && errors.email.message
                        ? errors.email.message
                        : 'New users will receive a welcome email'
                    }
                    error={Boolean(errors.email)}
                    label="EMAIL"
                    disabled={Boolean(editData)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="brandKey"
                control={control}
                defaultValue={defaultBrandValue}
                render={({ onChange, value }) => (
                  <SelectInput
                    value={value}
                    inputId="brandKey"
                    onChange={(e) => onChange(e.target.value)}
                    required
                    label="BRAND"
                    menuItems={brandOptions}
                    disabled={!FEATURE_FLAGS.ADMIN_SETTINGS.USERS.FORM.BRANDS}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="firstName"
                control={control}
                defaultValue={editData ? editData.firstName : ''}
                render={({ onChange, value }) => (
                  <TextInput
                    type="string"
                    inputId="firstName"
                    placeholder="Enter first name"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required
                    label="FIRST NAME"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="lastName"
                control={control}
                defaultValue={editData ? editData.lastName : ''}
                render={({ onChange, value }) => (
                  <TextInput
                    type="string"
                    inputId="lastName"
                    placeholder="Enter last name"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    required
                    label="LAST NAME"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <Controller
                name="roleId"
                control={control}
                defaultValue={
                  editData && userRoleOptions ? editData.roleId : ''
                }
                render={({ onChange, value }) => (
                  <SelectInput
                    value={value}
                    inputId="roleId"
                    onChange={(e) => onChange(e.target.value)}
                    required
                    label="ROLE"
                    menuItems={userRoleOptions}
                  />
                )}
              />
            </Grid>
          </Grid>
          <ModalFooter
            onClose={onClose}
            actionText={editData ? 'Edit User' : 'Add User'}
            disableAction={disableSubmit}
            loading={addUserLoading}
          />
          <ModalAlert
            title={modalTextProps.header}
            open={openModalAlert}
            message={modalTextProps.body}
            onSecondaryClick={() => setOpenModalAlert(false)}
            onClose={() => setOpenModalAlert(false)}
          />
        </form>
      )}
    </div>
  );
}

SettingsUserForm.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  brandsWithBrandAdmin: PropTypes.arrayOf(PropTypes.string),
  isAccountToppedOut: PropTypes.bool,
  editData: PropTypes.shape({
    username: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    brandKey: PropTypes.string,
    roleId: PropTypes.number,
    burId: PropTypes.number,
  }),
  users: PropTypes.arrayOf(PropTypes.string),
};