import { GenericIconProps } from '../types';

const LineChartSingleIcon = (props: GenericIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 20H4V3H3V21H21V20Z"
      fill="#D7DDE2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7629 13.7116C16.0753 13.8157 16.419 13.7039 16.6104 13.436L21.6104 6.43599C21.8511 6.09893 21.7731 5.63051 21.436 5.38976C21.0989 5.149 20.6305 5.22707 20.3898 5.56413L15.7117 12.1134L10.2373 10.2885C9.91639 10.1816 9.56365 10.3026 9.37604 10.584L5.37604 16.584C5.14628 16.9287 5.23941 17.3943 5.58405 17.6241C5.9287 17.8539 6.39435 17.7607 6.62412 17.4161L10.3064 11.8927L15.7629 13.7116Z"
      fill="#20434A"
    />
  </svg>
);

export default LineChartSingleIcon;
