import { GenericIconProps } from './types';

export enum Archetype {
  Caregiver = 'Caregiver',
  Creator = 'Creator',
  Everyperson = 'Everyperson',
  Explorer = 'Explorer',
  Hero = 'Hero',
  Innocent = 'Innocent',
  Jester = 'Jester',
  Lover = 'Lover',
  Magician = 'Magician',
  Rebel = 'Rebel',
  Ruler = 'Ruler',
  Sage = 'Sage',
}

export interface ArchetypeIconProps extends GenericIconProps {
  archetype: Archetype;
}

const ArchetypeIcon = ({ archetype, ...props }: ArchetypeIconProps) => {
  const getArchetypeSvg = () => {
    switch (archetype) {
      case Archetype.Caregiver:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#2F529D" />
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M25.333 8.571v6.009l3.31 3.31-3.31 2.758v6.341l-2.975-1.735.002 5.933-.596.088a14.182 14.182 0 01-4.171 0l-.596-.088v-5.988l-2.974 1.82v-6.387l-3.309-2.983 3.31-3.084V8.571l5.654 3.959 5.655-3.959zm-4.37 11.704h-2.57v9.693a12.767 12.767 0 002.57 0v-9.693zm1.395 3.362l1.579.921v-4.564l2.637-2.198-2.637-2.638v-3.904l-4.259 2.98-4.258-2.98v3.919l-2.639 2.459 2.639 2.379v4.517l1.576-.966.001-7.904h5.362v7.98zm-1.395-6.583h-2.57v1.825h2.57v-1.825z"
              />
            </g>
          </svg>
        );
      case Archetype.Creator:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#008FB4" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M19.331 31.429v-.02c-6-.346-10.76-5.322-10.76-11.409 0-6.087 4.76-11.063 10.76-11.41v-.019h1.338v.02c6 .346 10.76 5.322 10.76 11.409 0 6.087-4.76 11.063-10.76 11.41v.019H19.33zm0-5.6h-7.57a10.08 10.08 0 007.57 4.24v-4.24zm8.907 0h-7.57v4.24a10.077 10.077 0 007.57-4.24zm-8.908-5.202H9.928a10.038 10.038 0 001.033 3.864h8.37v-3.864zm10.742 0h-9.404v3.863l8.371.001a10.038 10.038 0 001.033-3.863zM19.33 15.289h-8.256a10.028 10.028 0 00-1.14 4.002h9.396v-4.002zm9.596 0h-8.258v4.001l9.399.001a10.025 10.025 0 00-1.141-4.002zM19.33 9.931a10.076 10.076 0 00-7.409 4.02h7.408zm1.338 0v4.02h7.409a10.076 10.076 0 00-7.41-4.02z"
              />
            </g>
          </svg>
        );
      case Archetype.Everyperson:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#93438C" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M20.032 9.286l.488.443c5.87 5.337 8.83 9.664 8.83 13.096a9.318 9.318 0 01-18.636 0c0-3.432 2.961-7.759 8.831-13.096l.487-.443zm0 11.335c-2.606 3.034-3.903 5.244-3.903 6.5 0 1.961 1.736 3.573 3.903 3.573s3.903-1.612 3.903-3.572c0-1.188-1.156-3.224-3.48-6.003zm0-9.373c-5.262 4.872-7.87 8.765-7.87 11.577a7.855 7.855 0 002.862 6.07 4.724 4.724 0 01-.343-1.773c0-1.823 1.608-4.466 4.809-8.083l.542-.612.774.875c3.047 3.483 4.578 6.041 4.578 7.82 0 .624-.121 1.221-.342 1.772a7.854 7.854 0 002.86-6.07c0-2.707-2.418-6.417-7.296-11.04z"
              />
            </g>
          </svg>
        );
      case Archetype.Explorer:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#90AC37" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M23.68 9.286c3.508 0 5.96 1.12 5.96 3.406v10.755c0 2.826-3.084 5.639-9.146 8.621l-.01.005a.735.735 0 01-.202.062l-.06.006h-.091l-.06-.006-.046-.009a.729.729 0 01-.157-.054l-.008-.004-.638-.319c-5.64-2.864-8.508-5.577-8.508-8.302V12.692c0-2.285 2.451-3.406 5.96-3.406zm-4.221 1.435h-2.785c-2.808 0-4.524.785-4.524 1.97v10.756c0 1.942 2.408 4.262 7.309 6.81v-7.146h-3.737v-1.435l3.736-.001v-2.98h-2.377V17.26l2.377-.001V10.72zm4.22 0h-2.785v6.538h2.378v1.436h-2.378v2.98h3.737v1.436h-3.737v7.146c4.78-2.484 7.188-4.752 7.305-6.664l.005-.146V12.692c0-1.186-1.717-1.97-4.524-1.97z"
              />
            </g>
          </svg>
        );
      case Archetype.Hero:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#CC7121" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M19.707 8.571L30 14.883v10.234l-10.293 6.312-9.37-6.105-.007-.005-.33-.215V14.896l.33-.215.008-.005 9.369-6.105zm4.551 14.33l-4.397 2.637-4.13-2.632-3.642 1.875 7.64 4.978 8.145-4.996-3.616-1.862zm-4.376-6.78l-3.431 2.187v3.384l3.431 2.186 3.667-2.198v-3.36l-3.667-2.198zm-8.513.32v7.118l3.712-1.91V18.35l-3.712-1.91zm17.261 0l-3.712 1.91v3.297l3.712 1.911V16.44zm-8.902-6.2l-7.64 4.976 3.643 1.875 4.13-2.63 4.396 2.636 3.618-1.862-8.147-4.995z"
              />
            </g>
          </svg>
        );
      case Archetype.Innocent:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#14958F" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M20 8.571c6.312 0 11.429 5.117 11.429 11.429S26.312 31.429 20 31.429l-.078-.001h-.069C13.609 31.348 8.57 26.263 8.57 20c0-6.312 5.117-11.429 11.429-11.429zm5.92 11.936l-4.833 9.435a10.001 10.001 0 007.57-4.934l-.016-.023-2.72-4.478zm-12.202-.012l-2.55 4.198a10.006 10.006 0 007.491 5.218l-4.941-9.416zM20 10c-5.523 0-10 4.477-10 10 0 1.071.169 2.104.48 3.071l3.328-5.477L19.88 29.16l5.935-11.58 3.57 5.879a9.972 9.972 0 00.615-3.46c0-5.523-4.477-10-10-10zm0 4.483a3.67 3.67 0 110 7.34 3.67 3.67 0 010-7.34zm0 1.428a2.241 2.241 0 100 4.483 2.241 2.241 0 000-4.483z"
              />
            </g>
          </svg>
        );
      case Archetype.Jester:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#B63268" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M31.429 18.733v.732c0 6.312-5.117 11.429-11.429 11.429S8.571 25.777 8.571 19.465v-.732H31.43zm-6.814 1.464h-9.232v8.1a9.904 9.904 0 004.34 1.128l.277.004a9.917 9.917 0 004.616-1.132v-8.1zm-10.696 0h-3.856l.02.231a9.95 9.95 0 003.837 6.931v-7.162zm16.018 0H26.08l.002 7.161a9.954 9.954 0 003.831-6.885l.024-.275zM20 8.572a4.476 4.476 0 110 8.952 4.476 4.476 0 010-8.952zm0 1.465a3.011 3.011 0 100 6.022 3.011 3.011 0 000-6.022z"
              />
            </g>
          </svg>
        );
      case Archetype.Lover:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#CD4E4C" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M20 8.571c6.312 0 11.429 5.117 11.429 11.429S26.312 31.429 20 31.429 8.571 26.312 8.571 20 13.688 8.571 20 8.571zm8.01 5.44l-2.398 1.812a6.995 6.995 0 11-11.374.21l-2.4-1.813A9.969 9.969 0 0010 20c0 5.523 4.477 10 10 10s10-4.477 10-10c0-2.246-.74-4.32-1.99-5.989zM20 10a9.973 9.973 0 00-7.253 3.115l2.928 2.213.017.013.019.016.026.024.03.03.033.038a.628.628 0 01.038.052l.02.03c.01.02.021.039.03.059l.015.03.021.06.012.042c.005.017.008.035.012.053l.006.042c.002.02.004.042.004.064v.031c0 .02-.001.04-.004.06l-.007.054-.009.04c-.004.02-.01.038-.016.057l-.013.037c-.01.022-.02.046-.032.068a.699.699 0 01-.063.1l.053-.08a.698.698 0 01-.082.116l.04-.055-.011.02-.013.016-.016.02a5.568 5.568 0 108.278-.17.714.714 0 01.033-1.01l-.044.047a.684.684 0 01.114-.107l2.905-2.196A9.968 9.968 0 0020 10zm0 6.7a3.3 3.3 0 11.001 6.599A3.3 3.3 0 0120 16.7zm0 1.428a1.872 1.872 0 100 3.744 1.872 1.872 0 000-3.744z"
              />
            </g>
          </svg>
        );
      case Archetype.Magician:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#DDA000" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M20 8.571l3.346 3.347h4.735v4.734L31.429 20l-3.348 3.347v4.734h-4.734L20 31.429l-3.348-3.348H11.92v-4.735L8.57 20l3.347-3.347v-4.734h4.735L20 8.57zm0 2.009l-2.76 2.758h-3.9v3.903L10.578 20l2.76 2.758v3.903h3.901L20 29.42l2.759-2.76h3.902v-3.9l2.76-2.76-2.76-2.759V13.34h-3.903L20 10.578zm0 5.308a4.111 4.111 0 110 8.224 4.112 4.112 0 110-8.224zm0 1.42a2.692 2.692 0 100 5.383 2.692 2.692 0 000-5.383z"
              />
            </g>
          </svg>
        );
      case Archetype.Rebel:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#D3BC00" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M20 8.571c6.312 0 11.429 5.117 11.429 11.429S26.312 31.429 20 31.429c-2.689 0-5.16-.929-7.112-2.483a.69.69 0 01-.153-.122A11.408 11.408 0 018.571 20c0-6.312 5.117-11.429 11.429-11.429zm-1.947 16.122l-.156.286a9.759 9.759 0 01-1.583 2.098 9.24 9.24 0 01-1.709 1.343A9.946 9.946 0 0020 30a9.958 9.958 0 005.3-1.518 9.182 9.182 0 01-1.812-1.405 9.803 9.803 0 01-1.705-2.319 5.085 5.085 0 01-1.783.321c-.69 0-1.348-.137-1.947-.386zm8.654-12.11l-.195.094a7.931 7.931 0 00-2.014 1.455 8.356 8.356 0 00-1.386 1.854A5.07 5.07 0 0125.079 20a5.073 5.073 0 01-2.042 4.072 8.427 8.427 0 001.46 1.995 8.027 8.027 0 002.063 1.479A9.972 9.972 0 0030 20a9.974 9.974 0 00-3.293-7.418zm-13.482.061l-.015.014A9.975 9.975 0 0010 20c0 2.977 1.3 5.65 3.364 7.481a7.866 7.866 0 001.94-1.414c.66-.66 1.218-1.462 1.671-2.408l-.153.304A5.067 5.067 0 0114.921 20c0-1.568.71-2.97 1.827-3.902a8.365 8.365 0 00-1.444-1.966 7.98 7.98 0 00-2.079-1.488zM20 16.349a3.65 3.65 0 101.906.536l-.032-.01a.711.711 0 01-.128-.08A3.61 3.61 0 0020 16.349zM20 10c-2.056 0-3.966.62-5.555 1.684a9.23 9.23 0 011.87 1.437 9.827 9.827 0 011.651 2.223A5.064 5.064 0 0120 14.921c.662 0 1.294.126 1.873.356a9.768 9.768 0 011.615-2.156 9.298 9.298 0 011.973-1.5A9.96 9.96 0 0020 10z"
              />
            </g>
          </svg>
        );
      case Archetype.Ruler:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#0082C0" />
              <path
                d="M20 12.857c4.116 0 7.822 2.146 11.09 6.37l.338.438-.338.438c-3.268 4.225-6.974 6.37-11.09 6.37-4.117 0-7.823-2.145-11.09-6.37l-.339-.438.338-.437c3.268-4.225 6.974-6.371 11.09-6.371zm0 1.43c-3.495 0-6.69 1.774-9.613 5.378l.215.26c2.863 3.43 5.988 5.118 9.398 5.118 3.494 0 6.69-1.773 9.612-5.378-2.922-3.604-6.118-5.377-9.612-5.377zm0 1.412a3.966 3.966 0 110 7.933 3.966 3.966 0 010-7.933zm0 1.43a2.536 2.536 0 100 5.072 2.536 2.536 0 000-5.072z"
                fill="#FFF"
                fillRule="nonzero"
              />
            </g>
          </svg>
        );
      case Archetype.Sage:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            {...props}
          >
            <g fill="none" fillRule="evenodd">
              <circle cx={20} cy={20} r={20} fill="#009F68" />
              <path
                fill="#FFF"
                fillRule="nonzero"
                d="M19.9 9.286l10.615 5.66v10.107L19.9 30.714l-10.614-5.66V14.946L19.9 9.286zm-.746 11.846l-7.79 3.338 7.79 4.154v-7.492zm1.493-.044v7.536l7.834-4.18-7.834-3.356zm-9.868-4.185v6.194L18.006 20l-7.227-3.097zm18.243.044L21.897 20l7.125 3.053v-6.106zm-8.375-5.571v7.536l7.833-3.358-7.833-4.178zm-1.493 0l-7.788 4.154 7.788 3.338v-7.492z"
              />
            </g>
          </svg>
        );
      default:
        console.error('Invalid archetype: ', archetype);
        return null;
    }
  };

  return <>{getArchetypeSvg()}</>;
};

export default ArchetypeIcon;
