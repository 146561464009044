import { useState, useEffect, useMemo } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import findIndex from 'lodash/findIndex';

import Grid from '@material-ui/core/Grid';

import NavTabsVertical from '../../Molecules/NavTabsVertical/NavTabsVertical';
import useFeatureFlags from '../../../hooks/useFeatureFlags';
import useRouter from '../../../hooks/useRouter';

import styles from './AdminSettings.module.scss';

const SETTINGS_PATHS = {
  USERS: 'users',
  GLOBAL_MARKER_EVENTS: 'global-marker-events',
  BILLING: 'billing',
  MANAGE_ALL_BRANDS: 'manage-all-brands',
};

export default function AdminSettings({ className }) {
  const FEATURE_FLAGS = useFeatureFlags();
  const { updateRoute } = useRouter();

  const location = useLocation();

  const TAB_ITEMS = useMemo(() => {
    const result = [];

    if (FEATURE_FLAGS.ADMIN_SETTINGS.USERS.ROOT) {
      result.push({
        value: SETTINGS_PATHS.USERS,
        label: 'Users',
      });
    }

    if (FEATURE_FLAGS.ADMIN_SETTINGS.GLOBAL_MARKERS.ROOT) {
      result.push({
        value: SETTINGS_PATHS.GLOBAL_MARKER_EVENTS,
        label: 'Global Marker Events',
      });
    }

    if (FEATURE_FLAGS.ADMIN_SETTINGS.BILLING.ROOT) {
      result.push({
        value: SETTINGS_PATHS.BILLING,
        label: 'Billing',
      });
    }

    return result;
  }, [FEATURE_FLAGS]);

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(
      findIndex(TAB_ITEMS, (tab) => {
        return location.pathname.includes(tab.value);
      })
    );
  }, [location, TAB_ITEMS]);

  function handleTabChange(newPath) {
    if (typeof newPath === 'string') {
      updateRoute({ pathname: `/admin-settings/${newPath}` });
    }
  }

  return (
    <div
      className={classNames(
        styles.AdminSettings,
        'WorkspaceContainer',
        className
      )}
    >
      <div className={classNames(styles.Header, 'WorkspaceTitleContainer')}>
        <h1>Admin Setting</h1>
      </div>

      <div className={classNames(styles.Body, 'PageContainer')}>
        <Grid container spacing={4} className={styles.BodyContainer}>
          <Grid item xs={12} sm={3} md={2}>
            <NavTabsVertical
              className={styles.NavTabs}
              value={currentPage}
              onChange={handleTabChange}
              items={TAB_ITEMS}
            />
          </Grid>

          <Grid item xs={12} sm={9} md={10}>
            <div className={styles.Content}>
              <Outlet />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

AdminSettings.propTypes = {
  className: PropTypes.string,
};
