import { GenericIconProps } from './types';

const SubmarineIcon = (props: GenericIconProps) => (
  <svg
    width="67"
    height="43"
    viewBox="0 0 67 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#qljeb4jcia)">
      <rect
        x="31.982"
        y="12.796"
        width="26.119"
        height="11.101"
        rx="5.55"
        transform="rotate(-19.379 31.982 12.796)"
        fill="#2DADC6"
      />
      <path fill="#2DADC6" d="m42.549 7.284 1.848-.65.867 2.464-1.848.65z" />
      <path
        fill="#2DADC6"
        d="m40.884 6.486 3.08-1.083.433 1.232-3.08 1.083zM57.34 10.107l2.57-4.5 2.251 6.4-4.821-1.9z"
      />
      <circle
        cx="37.195"
        cy="15.808"
        r="2.612"
        transform="rotate(-19.379 37.195 15.808)"
        fill="#3D5D65"
      />
      <circle
        cx="43.138"
        cy="13.025"
        r="1.306"
        transform="rotate(-19.379 43.138 13.025)"
        fill="#3D5D65"
      />
      <circle
        cx="47.45"
        cy="11.509"
        r="1.306"
        transform="rotate(-19.379 47.45 11.509)"
        fill="#3D5D65"
      />
      <circle
        cx="51.761"
        cy="9.992"
        r="1.306"
        transform="rotate(-19.379 51.761 9.992)"
        fill="#3D5D65"
      />
    </g>
    <path
      d="M32.879 19.362 15.892 38.164 7.669 21.918l25.21-2.556z"
      fill="url(#d9h68durab)"
    />
    <defs>
      <linearGradient
        id="d9h68durab"
        x1="32.879"
        y1="19.362"
        x2="9.011"
        y2="31.423"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#77D5E7" />
        <stop offset="1" stop-color="#fff" stop-opacity="0" />
      </linearGradient>
      <filter
        id="qljeb4jcia"
        x="29.508"
        y="5.402"
        width="36.653"
        height="24.34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_107_4837"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_107_4837"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default SubmarineIcon;
