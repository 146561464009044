import { AnchorHTMLAttributes, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { CopyIcon, ShareIcon, TrashCanIcon } from '../../../../assets/icons';
import ModalConfirm from '../../../Molecules/ModalConfirm/ModalConfirm';
import useDashboardAPI from '../../../../hooks/useDashboardAPI';
import { ShareableDashboard, UserInfo } from '../types';
import styles from './DashboardListItem.module.scss';
import ShareDashboardModal from '../Modals/ShareDashboardModal/ShareDashboardModal';
import RevokeAccessModal from '../Modals/ShareDashboardModal/RevokeAccessModal';
import CustomLink from './CustomLink';

export interface DashboardListItemProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  dashboard: ShareableDashboard;
  updateList: (update: boolean) => void;
  isSharedDashboard?: boolean;
}

const DashboardListItem = ({
  dashboard,
  updateList,
  isSharedDashboard,
}: DashboardListItemProps) => {
  const {
    duplicateDashboard,
    deleteDashboard,
    shareDashboard,
    checkDashboardPermissions,
    duplicateSharedDashboard,
  } = useDashboardAPI();

  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isRevokeAccessModalOpen, setIsRevokeAccessModalOpen] =
    useState<boolean>(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const closeConfirmModal = () => setIsConfirmOpen(false);
  const openConfirmModal = () => setIsConfirmOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);

  const deleteMessage = `Are you sure you want to delete ${
    dashboard.name ?? 'dashboard'
  }?`;

  const createdBy = dashboard?.ownerName;
  const isViewOnly = !dashboard.capabilities.can_edit;
  const lastViewed = new Date(dashboard?.last_viewed_at);
  const lastViewedDateString = lastViewed.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
  const lastViewedTimeString = lastViewed.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const handleDelete = async () => {
    const isDeleted = await deleteDashboard(dashboard.id);
    if (isDeleted) {
      // TODO: alerting
      updateList(true);
    }
  };

  const handleDuplicate = async () => {
    if (dashboard.isDashboardShared) {
      duplicateSharedDashboard(dashboard.id)
        .then(() => updateList(true))
        // eslint-disable-next-line no-console
        .catch(console.error);
    } else {
      duplicateDashboard(dashboard.id)
        .then(() => updateList(true))
        // eslint-disable-next-line no-console
        .catch(console.error);
    }
  };

  const handleShare = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  const handleShareSubmit = async (
    dashboardId: string,
    invitees: UserInfo[]
  ) => {
    const response = await shareDashboard(
      dashboardId,
      dashboard.ownerName,
      invitees
    );

    if (response) {
      updateList(true);
    }
  };

  const handleSharedDashboardClick = async () => {
    // check to see if User has access to dashboard, if not, show modal
    try {
      await checkDashboardPermissions(dashboard.id);
    } catch (err) {
      setIsRevokeAccessModalOpen(true);
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <>
      <ModalConfirm
        title="You are about to delete this dashboard"
        open={isConfirmOpen}
        message={deleteMessage}
        onCancel={closeConfirmModal}
        onConfirm={handleDelete}
        className=""
        helper="This action cannot be undone."
        onClose={closeConfirmModal}
      />
      <div className={styles.DashboardListItem}>
        <div className={styles.NameDetails}>
          {isSharedDashboard ? (
            <CustomLink
              to={`/dashboards/${dashboard.id}`}
              onClick={() => handleSharedDashboardClick()}
            >
              {dashboard.name}
            </CustomLink>
          ) : (
            <Link to={`/dashboards/${dashboard.id}`} className={styles.Name}>
              {dashboard.name}
            </Link>
          )}

          {dashboard?.description ? (
            <div className={styles.Description}>{dashboard.description}</div>
          ) : (
            <div className={styles.Description}>No description available</div>
          )}
          <div className={styles.CreatorDetails}>
            <p className={styles.Owner}>{createdBy}</p>
            <p className={styles.LastViewed}>
              - Last viewed on {lastViewedDateString} at {lastViewedTimeString}
            </p>
          </div>
        </div>

        <div className={styles.Actions}>
          <div className={styles.TopRow}>
            {dashboard.capabilities?.can_delete && (
              <button
                type="button"
                onClick={openConfirmModal}
                title="Delete"
                className={styles.ActionItem}
              >
                <TrashCanIcon />
              </button>
            )}

            {dashboard.capabilities?.can_duplicate && (
              <button
                type="button"
                onClick={handleDuplicate}
                title="Duplicate"
                className={styles.ActionItem}
              >
                <CopyIcon />
              </button>
            )}
            {dashboard.capabilities.can_share && (
              <button
                type="button"
                onClick={handleShare}
                title="Share"
                className={cn(
                  styles.ActionItem,
                  styles.ShareIcon,
                  dashboard.isDashboardShared ? styles.SharedAction : ''
                )}
              >
                <ShareIcon />
              </button>
            )}
          </div>

          {isViewOnly && <p className={styles.ViewOnly}>View Only</p>}
        </div>
      </div>

      {/* Modals */}
      <ShareDashboardModal
        open={isShareModalOpen}
        dashboard={dashboard}
        handleOnClose={closeShareModal}
        handleShareSubmit={handleShareSubmit}
        updateList={updateList}
      />

      {isRevokeAccessModalOpen && (
        <RevokeAccessModal
          open={isRevokeAccessModalOpen}
          handleOnClose={() => setIsRevokeAccessModalOpen(false)}
          showError
        />
      )}
    </>
  );
};

export default DashboardListItem;
