import { GenericIconProps } from '../types';

const PDFIcon = (props: GenericIconProps) => (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="46" height="46" rx="8" fill="#294249" />
    <path
      d="M17.7228 24.7353H19.0333V21.7958H20.7333C21.111 21.7958 21.4239 21.6718 21.6718 21.4239C21.9197 21.176 22.0437 20.8631 22.0437 20.4853V18.7853C22.0437 18.4076 21.9197 18.0947 21.6718 17.8468C21.4239 17.5989 21.111 17.4749 20.7333 17.4749H17.7228V24.7353ZM19.0333 20.4853V18.7853H20.7333V20.4853H19.0333ZM23.602 24.7353H26.577C26.9312 24.7353 27.2381 24.6114 27.4978 24.3635C27.7576 24.1155 27.8874 23.8027 27.8874 23.4249V18.7853C27.8874 18.4076 27.7576 18.0947 27.4978 17.8468C27.2381 17.5989 26.9312 17.4749 26.577 17.4749H23.602V24.7353ZM24.9124 23.4249V18.7853H26.577V23.4249H24.9124ZM29.6228 24.7353H30.9333V21.7958H32.7041V20.4853H30.9333V18.7853H32.7041V17.4749H29.6228V24.7353ZM15.2083 32.9166C14.6416 32.9166 14.1458 32.7041 13.7208 32.2791C13.2958 31.8541 13.0833 31.3583 13.0833 30.7916V10.9583C13.0833 10.3916 13.2958 9.89575 13.7208 9.47075C14.1458 9.04575 14.6416 8.83325 15.2083 8.83325H35.0416C35.6083 8.83325 36.1041 9.04575 36.5291 9.47075C36.9541 9.89575 37.1666 10.3916 37.1666 10.9583V30.7916C37.1666 31.3583 36.9541 31.8541 36.5291 32.2791C36.1041 32.7041 35.6083 32.9166 35.0416 32.9166H15.2083ZM15.2083 30.7916H35.0416V10.9583H15.2083V30.7916ZM10.9583 37.1666C10.3916 37.1666 9.89575 36.9541 9.47075 36.5291C9.04575 36.1041 8.83325 35.6083 8.83325 35.0416V13.0833H10.9583V35.0416H32.9166V37.1666H10.9583ZM15.2083 10.9583V30.7916V10.9583Z"
      fill="white"
    />
  </svg>
);

export default PDFIcon;
