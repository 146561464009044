import type { DialogProps } from '@mui/material/Dialog';
import Dialog from '@mui/material/Dialog';
import React from 'react';

import { Button } from '@blueoceanai/react-component-library';
import { CloseIcon, AlertTriangleIcon } from '../../../../../assets/icons';

import styles from './RevokeAccessModal.module.scss';

interface RevokeAccessModalProps extends DialogProps {
  handleRemoveUser?: (user: string) => void;
  handleOnClose: () => void;
  username?: string;
  showError?: boolean;
  dashboardOwner?: string;
}

const RevokeAccessModal: React.FC<RevokeAccessModalProps> = ({
  handleOnClose,
  handleRemoveUser,
  username,
  showError,
  dashboardOwner,
  ...props
}) => {
  let headerText = '';
  let boldText = '';
  let additionalText = '';

  if (showError) {
    headerText = 'You need access';
    boldText = `Oops! Looks like you don't have access to this dashboard.`;
    additionalText = `Please contact the dashboard owner to gain access.`;
  } else {
    headerText = 'Revoke Access to Dashboard';
    boldText = `Are you sure you want to revoke access to this dashboard for ${username}`;
    additionalText =
      'They will no longer be able to view the original dashboard. To provide access, you must invite them again.';
  }

  const primaryButtonLabel = showError ? 'Okay' : 'Revoke Access';

  return (
    <Dialog
      className={styles.ModalContainer}
      onClose={handleOnClose}
      {...props}
    >
      <div className={styles.RevokeAccessModal}>
        <div className={styles.Header}>
          <div className={styles.Left}>
            <p>{headerText}</p>
          </div>
          <CloseIcon className={styles.Icon} onClick={handleOnClose} />
        </div>

        <div className={styles.TextSection}>
          <AlertTriangleIcon className={styles.AlertIcon} />
          <div>
            <p className={styles.BoldText}>{boldText}</p>
            <p className={styles.Text}>{additionalText}</p>
          </div>
        </div>

        {/* Footer */}
        <div className={styles.Footer}>
          {!showError && (
            <>
              <Button
                variant="secondary"
                className={styles.ActionButton}
                onClick={handleOnClose}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className={styles.ActionButton}
                onClick={() =>
                  handleRemoveUser && handleRemoveUser(username ?? '')
                }
              >
                {primaryButtonLabel}
              </Button>
            </>
          )}
          {showError && (
            <Button
              variant="primary"
              className={styles.ActionButton}
              onClick={handleOnClose}
            >
              Close
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default RevokeAccessModal;
