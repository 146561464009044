import { useEffect, useContext, useState, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import keyBy from 'lodash/keyBy';
import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';

import {
  GET_REPORTS,
  MARK_REPORT_DOWNLOADED,
} from '../../../api/queries/Molecules/PastReportsArchiveList';

import PastReportsArchiveListItem from '../../Atoms/PastReportsArchiveListItem/PastReportsArchiveListItem';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';

import BNContext from '../../../contexts/BNContext';

import styles from './PastReportsArchiveList.module.scss';
import { SubmarineIcon } from '../../../assets/icons';

export default function PastReportsArchiveList({ className, ...props }) {
  const { competitiveSetID, allSessions } = useContext(BNContext);

  const [reports, setReports] = useState(null);

  const [
    getReports,
    { called: getReportsCalled, loading: fetchingReports, data: reportsData },
  ] = useLazyQuery(GET_REPORTS, {
    fetchPolicy: 'no-cache',
  });

  const [markReportDownloaded] = useMutation(MARK_REPORT_DOWNLOADED);

  const allSessionsByKey = useMemo(() => {
    return keyBy(allSessions, 'key');
  }, [allSessions]);

  useEffect(() => {
    getReports({ variables: { id: competitiveSetID } });
  }, [getReports, competitiveSetID]);

  useEffect(() => {
    if (!reportsData?.user?.competitiveSet?.reports?.length) {
      return;
    }

    const transformedReportsResp =
      reportsData?.user?.competitiveSet?.reports?.map((report) => {
        return {
          id: report.reportVersionId,
          name: dayjs(allSessionsByKey[report.sessionKey].date).format(
            'MMMM D, YYYY'
          ),
          sessionDate: allSessionsByKey[report.sessionKey].date,
          contentUrl: report.contentUrl,
          read: report.read,
        };
      });

    setReports(orderBy(transformedReportsResp, 'sessionDate', 'desc'));
  }, [reportsData, allSessionsByKey]);

  const handleReportClick = useCallback(
    async (reportVersionId) => {
      try {
        await markReportDownloaded({
          variables: {
            reportVersionId,
          },
        });

        const reportsClone = cloneDeep(reports);

        reportsClone.forEach((report) => {
          if (report.id === reportVersionId) {
            // eslint-disable-next-line no-param-reassign
            report.read = true;
          }
        });

        setReports(reportsClone);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Error: markReportDownloaded', e);
      }
    },
    [markReportDownloaded, reports]
  );

  const loadedWithReports = getReportsCalled && reports?.length;
  const loadedWithNoReports = getReportsCalled && !reports?.length;

  return (
    <div
      className={classNames(styles.PastReportsArchiveList, className)}
      {...props}
    >
      {!getReportsCalled || fetchingReports ? (
        <div className={styles.LoadingContainer}>
          <ComponentLoader />
        </div>
      ) : (
        <>
          {loadedWithReports && (
            <div className={styles.ReportsListContainer}>
              {reports
                ? reports.map((report) => (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a
                      href={report.contentUrl}
                      target="_blank"
                      rel="noopener"
                      onClick={() => handleReportClick(report.id)}
                      key={report.id}
                    >
                      <PastReportsArchiveListItem
                        className={styles.ListItem}
                        reportName={report.name}
                        isRead={report.read}
                      />
                    </a>
                  ))
                : null}
            </div>
          )}

          {loadedWithNoReports && (
            <div className={styles.NoReportsContainer}>
              <SubmarineIcon
                className={styles.SubIcon}
                style={{ transform: 'scale(3.0)' }}
              />
              <p className={styles.Message}>There are no reports yet...</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

PastReportsArchiveList.propTypes = {
  className: PropTypes.string,
};
