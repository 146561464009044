export const prepareProfileMarkerEvents = (markers) => {
  const markerMap = markers.map((marker) => ({
    id: marker.id,
    label: marker.label,
    eventDate: marker.markerEventDate,
    displayTo: marker.isAccountLevel === 1 ? 'account' : 'brand',
    addedDate: marker.createdDate,
    private: marker.isPrivate === 1 ? true : 0,
  }));
  return markerMap;
};
