import React from 'react';
import PropTypes from 'prop-types';
import { Button, BrandLogo } from '@blueoceanai/react-component-library';

import ThemesSemiPieChart from '../../Molecules/ThemesSemiPieChart/ThemesSemiPieChart';
import ChartVolume from '../../Molecules/ChartVolume/ChartVolume';
import { SIZES, BUTTON_VARIANTS } from '../../../constants/props';

import styles from './ThemesBrandChart.module.scss';

export default function ThemesBrandChart({
  cardTitle,
  compareBrands,
  competitiveSetID,
  brandLogo,
  chartTimelineData,
  gaugeData,
}) {
  return (
    <div className={`${styles.ThemesBrandChart}`}>
      <div
        className={`${styles.ThemesCardHeaderItem} ${styles.ThemesCardItem}`}
      >
        <BrandLogo size={SIZES.MD} name={cardTitle} src={brandLogo} />
      </div>
      <div className={`${styles.ThemesCardItem}`}>
        <div className={`${styles.ThemesCardItemVolumeChart}`}>
          <ChartVolume type="area" data={chartTimelineData} />
        </div>
        <div className={`${styles.ThemesCardItemSemiPieChart}`}>
          <ThemesSemiPieChart
            chartType={gaugeData.type}
            value={gaugeData.value}
            competitorAverage={gaugeData.competitorAverage}
          />
        </div>
      </div>
      <div className={`${styles.ThemesCardItem} ${styles.ThemesCardItemLast}`}>
        <Button
          variant={BUTTON_VARIANTS.SECONDARY}
          onClick={() => compareBrands(competitiveSetID)}
        >
          Compare
        </Button>
      </div>
    </div>
  );
}

ThemesBrandChart.propTypes = {
  cardTitle: PropTypes.string,
  compareBrands: PropTypes.func,
  competitiveSetID: PropTypes.number,
  brandLogo: PropTypes.string,
  chartTimelineData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      date: PropTypes.string,
    })
  ),
  gaugeData: PropTypes.shape({
    value: PropTypes.number,
    type: PropTypes.string,
    competitorAverage: PropTypes.number,
  }),
};
