import { FC, AnchorHTMLAttributes, useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { animatePercentageChange } from '../../../../utils/score';

import colors from '../../../../constants/colors';
import styles from './ScoreTilePercent.module.scss';

export interface ScoreTilePercentProps
  extends AnchorHTMLAttributes<HTMLDivElement> {
  value: number;
  showDiff?: boolean;
  className?: string;
  valueAnimationDuration?: number;
}

const ScoreTilePercent: FC<ScoreTilePercentProps> = ({
  className,
  value,
  showDiff,
  valueAnimationDuration = 1000,
}) => {
  const [animatedValue, setAnimatedValue] = useState(0);
  const animationRequestId = useRef<{ cancel: () => void } | null>(null);

  const formattedValue = showDiff
    ? `${animatedValue.toFixed(0)}%`
    : `${animatedValue.toFixed(1)}%`;
  const backgroundColor =
    value < 0 ? colors.colorSemanticError30 : colors.colorSemanticSuccess30;

  useEffect(() => {
    animationRequestId.current = animatePercentageChange({
      nextValue: value || 0,
      animationDuration: valueAnimationDuration,
      onChange: setAnimatedValue,
    });
  }, [value, valueAnimationDuration]);

  return (
    <div
      className={classNames(className, styles.ScoreTilePercent)}
      style={{
        backgroundColor: showDiff ? backgroundColor : colors.colorGray20,
      }}
    >
      <div className={styles.Value}>{formattedValue}</div>
    </div>
  );
};

export default ScoreTilePercent;
