import * as React from 'react';

function IconShare(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          fill="#20434A"
          d="M20 11a.5.5 0 01.492.41l.008.09v6a2.501 2.501 0 01-2.336 2.495L18 20H6a2.501 2.501 0 01-2.495-2.336L3.5 17.5v-6a.5.5 0 01.992-.09l.008.09v6a1.5 1.5 0 001.356 1.493L6 19h12a1.5 1.5 0 001.493-1.356l.007-.144v-6a.5.5 0 01.5-.5zm-8.19-7.463a.503.503 0 01.544.11l3 2.999a.5.5 0 11-.708.706L12.5 5.204v8.293a.5.5 0 11-1 0v-8.29L9.354 7.352a.5.5 0 11-.708-.707l3-2.998a.494.494 0 01.163-.11z"
        />
      </g>
    </svg>
  );
}

export default IconShare;
