import { gql } from '@apollo/client';

export const GET_FIRST_AND_LAST_SESSION = gql`
  query getFirstLastSession($id: ID) {
    competitiveSet(id: $id) {
      userRole {
        id
        name
      }
      sessions {
        sessionKey
        sessionDate
        isClientVisible
      }
      firstAndLastSession {
        sessionKey
      }
    }
  }
`;

export const GET_ALL_BRANDS_BY_USERID = gql`
  query getAllBrandsByUserId {
    user {
      accounts {
        accountKey
        product_id
        competitiveSets {
          competitiveSetKey
          friendlyName
          brand {
            name
            logoUrl
            brandKey
            international_ratio
          }
        }
      }
    }
  }
`;

export const USER_AND_ACCOUNT_KEYS = gql`
  query getKeys {
    user {
      id
      email
      name
      userKey
      accounts {
        product_id
        accountKey
      }
    }
  }
`;

export const GET_METRICS_FAVORITE = gql`
  query getMetricsFavorites($id: ID, $sessionKey: ID) {
    user {
      competitiveSet(id: $id) {
        session(sessionKey: $sessionKey) {
          id
          blueScore {
            value
            favorite {
              ...Faves
            }
          }
          factors {
            name
            value
            favorite {
              ...Faves
            }
          }
          subFactors {
            name
            value
            favorite {
              ...Faves
            }
          }
          metrics {
            name
            value
            variableId
            favorite {
              ...Faves
            }
          }
        }
      }
    }
  }
  fragment Faves on Favorite {
    user_id
    brand_id
  }
`;

export const UPDATE_METRICS_FAVORITE = gql`
  mutation addFavorite($brandID: ID, $variableID: String, $isActive: Int) {
    addRemoveFavorite(
      favorite: {
        brandKey: $brandID
        variableId: $variableID
        isActive: $isActive
      }
    )
  }
`;
